import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { format, addYears } from "date-fns";
import { renewConText } from "../store";
import classnames from "classnames";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Label from "../../../../components/Forms/Label";
import Select from "../../../../components/Forms/Select";
import DatePicker from "../../../../components/Forms/DatePicker";
import { PolicyTypeMapping, RepairFacilityMapping } from "../../../../helpers/mappingData";
import { messages } from "../../../../helpers/messages";
import { LoadStatus } from "../../../../types/load";
import { Insurer } from "../../../../types/insurer";
import { Quote } from "../../../../types/quote";
import { Details } from "../../../../types/order";
import { PolicyType } from "../../../../types/policy";
import { RepairFacility } from "../../../../types/repairFacility";

interface Props {
  load: LoadStatus;
  register: any;
  errors: any;
  insurersData: Insurer[];
}

type CoveredDate = {
  start: Date;
  end: Date;
};

const policyTypeOptions: PolicyType[] = ["1", "2+", "3", "3+"];
const cmiPolicyTypeOptions: PolicyType[] = ["cmi"];
const repairFactoryOptions: RepairFacility[] = ["dealer", "garage"];
const CURRENT_DATE = new Date();
const CustomMotorProduct: React.FC<Props> = ({ load, insurersData, register, errors }) => {
  const { state, dispatch } = React.useContext(renewConText);
  const { type } = useParams<{ type: string }>();

  const vmiCoveredDate: CoveredDate = useMemo(() => {
    if (state.renew?.details?.cover_start_date) {
      const startDate = new Date(state.renew.details.cover_start_date);
      const endDate = new Date(state.renew?.details?.cover_end_date) || addYears(startDate, 1);

      return { start: startDate, end: endDate };
    }
    return { start: CURRENT_DATE, end: addYears(CURRENT_DATE, 1) };
  }, [state.renew?.details?.cover_start_date]);

  const cmiCoveredDate: CoveredDate = useMemo(() => {
    if (state.renew?.details?.addon_start_date) {
      const startDate = new Date(state.renew.details.addon_start_date);
      const endDate = new Date(state.renew?.details?.addon_end_date) || addYears(startDate, 1);

      return { start: startDate, end: endDate };
    }
    return { start: CURRENT_DATE, end: addYears(CURRENT_DATE, 1) };
  }, [state.renew?.details?.addon_start_date]);

  React.useEffect(() => {
    let payload = {
      isEdit: false,
      renew: {
        ...state.renew,
        addon_selling_price: "645.21",
        details: {
          ...state.renew.details,
          addon_start_date: format(new Date(), "yyyy-MM-dd"),
          addon_end_date: format(addYears(new Date(), 1), "yyyy-MM-dd"),
        },
      },
    };
    dispatch({
      type: "ADD",
      payload: payload,
    });
  }, []);

  const handleChangeQuote = (
    evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    name: keyof Quote
  ): void => {
    const quotes = [...state.renew.quotes] as Quote[];
    quotes[0] = {
      ...state.renew.quotes[0],
      [name]: evt.target.value,
    };

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          quotes,
        },
      },
    });
  };

  const handleChangeCoverDate = (date: Date): void => {
    const newYear: Date = addYears(date, 1);
    const details: Details = { ...state.renew.details };
    details.cover_start_date = format(date, "yyyy-MM-dd");
    details.cover_end_date = format(newYear, "yyyy-MM-dd");

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          details,
        },
      },
    });
  };

  const handleChangeAddonCoverDate = (date: Date): void => {
    const newYear: Date = addYears(date, 1);
    const details: Details = { ...state.renew.details };
    details.addon_start_date = format(date, "yyyy-MM-dd");
    details.addon_end_date = format(newYear, "yyyy-MM-dd");

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          details,
        },
      },
    });
  };

  return (
    <div>
      <div className="mt-6 mb-3 text-lg font-bold text-secondary">
        ข้อมูลประกันภัยรถยนต์ภาคสมัครใจ
      </div>
      <div className="flex flex-wrap items-center -mx-2">
        <div className="w-1/3 px-2 mb-4">
          <Label htmlFor="renew[product][insurer_code]" required>
            บริษัทประกัน
          </Label>
          <Select
            id="renew[product][insurer_code]"
            name="renew[product][insurer_code]"
            value={state.renew?.quotes[0].insurer_code}
            onChange={(evt) => handleChangeQuote(evt, "insurer_code")}
            inputRef={register({ ...messages.required })}
            error={errors?.renew?.product?.insurer_code}
          >
            {load === "loaded" &&
              insurersData.map((insurer, index) => (
                <option key={index} value={insurer.code}>
                  {insurer.name}
                </option>
              ))}
          </Select>
        </div>
        <div className="w-1/3 px-2 mb-4">
          <Label htmlFor="renew[product][policy_type]" required>
            ประเภทความคุ้มครอง
          </Label>
          <Select
            id="renew[product][policy_type]"
            name="renew[product][policy_type]"
            value={state.renew?.quotes[0].policy_type}
            onChange={(evt) => handleChangeQuote(evt, "policy_type")}
            inputRef={register({ ...messages.required })}
            error={errors?.renew?.product?.policy_type}
          >
            {type !== "cmi" ? (
              <>
                {policyTypeOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {PolicyTypeMapping[option]}
                  </option>
                ))}
              </>
            ) : (
              <>
                {cmiPolicyTypeOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {PolicyTypeMapping[option]}
                  </option>
                ))}
              </>
            )}
          </Select>
        </div>
        <div className="w-1/3 px-2 mb-4">
          <Label htmlFor="renew[product][details][repair_facility]" required>
            ประเภทความคุ้มครอง
          </Label>
          <Select
            id="renew[product][details][repair_facility]"
            name="renew[product][details][repair_facility]"
            value={state.renew?.quotes[0].repair_facility}
            onChange={(evt) => handleChangeQuote(evt, "repair_facility")}
            inputRef={register({ ...messages.required })}
            error={errors?.renew?.product?.details?.repair_facility}
          >
            {repairFactoryOptions.map((option, index) => (
              <option key={index} value={option}>
                {RepairFacilityMapping[option]}
              </option>
            ))}
          </Select>
        </div>
        {type !== "cmi" && (
          <>
            <div className="w-1/2 px-2 mb-4">
              <Label htmlFor="renew_cover_start_date" required>
                วันที่เริ่มคุ้มครอง
              </Label>
              <DatePicker
                id="renew_cover_start_date"
                selected={vmiCoveredDate["start"]}
                onChange={(date: Date): void => handleChangeCoverDate(date)}
                dateFormat="dd/MM/yyyy"
              />
              <input
                type="hidden"
                name="renew[order_details][cover_start_date]"
                ref={register}
                value={state.renew?.details?.cover_start_date}
              />
            </div>
            <div className="w-1/2 px-2 mb-4">
              <Label htmlFor="renew_cover_end_date" required>
                วันที่สิ้นสุดคุ้มครอง
              </Label>
              <DatePicker
                id="renew_cover_end_date"
                name="renew_cover_end_date"
                selected={vmiCoveredDate["end"]}
                onChange={() => false}
                minDate={new Date()}
                disabled
                dateFormat="dd/MM/yyyy"
              />
              <input
                type="hidden"
                name="renew[order_details][cover_end_date]"
                ref={register}
                value={state.renew?.details?.cover_end_date}
              />
            </div>
            <div className="w-full pt-4 px-2 mb-4">
              {state.renew?.checked_addon && (
                <input type="hidden" name="renew[cmi]" ref={register} value="checked" />
              )}
              <button
                type="button"
                disabled={type === "cmi"}
                onClick={() =>
                  dispatch({
                    type: "ADD",
                    payload: {
                      isEdit: true,
                      renew: {
                        ...state.renew,
                        checked_addon: !state.renew?.checked_addon,
                      },
                    },
                  })
                }
                className={classnames([
                  "flex items-center px-4 py-3 text-base font-semibold bg-opacity-25 border-2 rounded-md shadow-lg focus:outline-none",
                  state.renew?.checked_addon
                    ? "focus:ring-offset-secondary-dark border-secondary bg-secondary"
                    : "bg-white border-gray-200",
                  type === "cmi" && "cursor-not-allowed",
                ])}
              >
                {state.renew?.checked_addon ? (
                  <CheckBoxIcon className="mr-2 text-secondary" />
                ) : (
                  <CheckBoxOutlineBlankIcon className="mr-2" />
                )}
                พ.ร.บ.
                <span className="ml-2">{state.renew?.addon_selling_price} ฿</span>
              </button>
            </div>
          </>
        )}
        {(state.renew?.checked_addon || type === "cmi") && (
          <>
            <div className="w-full mt-6 mb-3 text-lg font-bold text-secondary">
              ข้อมูลประกันภัยรถยนต์ภาคบังคับ (พ.ร.บ.)
            </div>
            <div className="w-full flex flex-wrap -mx-2">
              <div className="w-1/2 px-2 mb-4">
                <Label htmlFor="renew[insured_category" required>
                  วันที่เริ่มคุ้มครอง พ.ร.บ.
                </Label>
                <DatePicker
                  id="renew_addon_start_date"
                  name="renew_addon_start_date"
                  selected={cmiCoveredDate.start}
                  onChange={(date: Date): void => handleChangeAddonCoverDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
                <input
                  type="hidden"
                  name="renew[order_details][addon_start_date]"
                  ref={register}
                  value={state.renew?.details?.addon_start_date}
                />
              </div>
              <div className="w-1/2 px-2 mb-4">
                <Label htmlFor="renew[insured_category]" required>
                  วันที่สิ้นสุดคุ้มครอง พ.ร.บ.
                </Label>
                <DatePicker
                  id="renew[insured_category]"
                  name="renew[insured_category]"
                  selected={cmiCoveredDate.end}
                  onChange={() => false}
                  disabled
                  dateFormat="dd/MM/yyyy"
                />
                <input
                  type="hidden"
                  name="renew[order_details][addon_end_date]"
                  ref={register}
                  value={state.renew?.details?.addon_end_date}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomMotorProduct;
