import React from "react";

import "./Checkbox.scss";

const Checkbox = ({ label, icon, value, checked, onChangeValue }) => {
  return (
    <div className="checkbox-component">
      <button
        className={`checkbox-component-button ${checked ? "checked" : ""}`}
        value={value}
        onClick={() => onChangeValue(checked, value)}
      >
        {icon ? (
          <>
            <span className="check-icon">
              <img src={icon} alt="" />
            </span>
            {label}
          </>
        ) : (
          label
        )}
        <span className="checkmark"></span>
      </button>
    </div>
  );
};

export default Checkbox;
