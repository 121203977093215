import React, { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useOnClickOutside from "~/src/hooks/useOnClickOutside";

type Props = {
  open: boolean;
  children: React.ReactNode | React.ReactNode[];
  closeComponent?: React.ReactNode;
  persistent?: boolean;
  onClose?: () => void;
};

const CommonModal: React.FC<Props> = ({
  open,
  closeComponent,
  persistent,
  children,
  onClose,
}): JSX.Element => {
  const contentModal = useRef<HTMLDivElement>(null);
  persistent && useOnClickOutside(contentModal, () => onClose?.());

  return (
    open && (
      <div
        className="fixed w-full bg-opacity-40 bg-black flex h-full left-0 top-0 items-center justify-center"
        style={{ zIndex: 9999 }}
      >
        <div
          ref={contentModal}
          className="relative flex flex-col justify-center items-center bg-white border rounded-2xl shadow-2xl p-6"
        >
          <div className="absolute right-0 top-0 p-16">
            {closeComponent ?? (
              <CloseIcon className="cursor-pointer" color="action" onClick={() => onClose?.()} />
            )}
          </div>
          {children}
        </div>
      </div>
    )
  );
};

export default CommonModal;
