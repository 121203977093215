import React from "react";

import { FormGroup, TextField, LocationSearch, Switcher, Checkbox } from "~src/components/form";

import { messages } from "~src/helpers/messages";
import { deliveryAddressTypeOptions, deliveryTypeOptions } from "~src/helpers/options";
import { getInsuredDeliveryTypeAsArray } from "~src/helpers/insuredHelper";
import { InsuredsAttributes, DeliveryAddressAttributes } from "~src/types/checkoutBasicForm";
import { ProductType } from "~src/types/product";
import { PolicyType } from "~src/types/policy";

interface AddressDeliveryInsuranceProps {
  copied: boolean;
  attrPrefixDeliveryAddress: string;
  attrPrefixInsured: string;
  insuredsAttributes?: InsuredsAttributes;
  deliveryAddressAttributes?: DeliveryAddressAttributes;
  errors?: any;
  policyType?: PolicyType;
  register: (msg) => void;
  handleChangePolicyDeliveryField: (event: React.FormEvent, attrName: string) => void;
  handleChangeDeliveryAddressType: (event: React.FormEvent) => void;
  handleChangeDeliveryType: (event: React.FormEvent) => void;
}

const AddressDeliveryInsurance: React.FC<AddressDeliveryInsuranceProps> = ({
  copied,
  attrPrefixDeliveryAddress,
  attrPrefixInsured,
  insuredsAttributes,
  deliveryAddressAttributes,
  policyType,
  errors,
  register,
  handleChangePolicyDeliveryField,
  handleChangeDeliveryAddressType,
  handleChangeDeliveryType,
}) => {
  const deliveryTypes = getInsuredDeliveryTypeAsArray(insuredsAttributes?.delivery_type);

  const isDeliveryTypeChecked = (typeId: number) => {
    return deliveryTypes.includes(typeId);
  };

  const renderDeliveryTypeOptions = () => {
    let chekboxs = [];
    let options: { label: string; value: number }[] =
      policyType !== "cmi" ? deliveryTypeOptions : [deliveryTypeOptions[0]];
    chekboxs.push(
      <>
        {options.map((option, index) => (
          <div key={index} className="col-md-6">
            <Checkbox
              id={`insured_delivery_type_${option.value}`}
              name={`${attrPrefixInsured}[delivery_type][]`}
              value={option.value}
              label={option.label}
              emailLabel={index === 0 && insuredsAttributes?.email}
              checked={isDeliveryTypeChecked(option.value)}
              onChange={handleChangeDeliveryType}
              inputRef={register}
            />
          </div>
        ))}
      </>
    );
    return chekboxs;
  };

  return (
    <>
      <h2>
        ที่อยู่ในการจัดส่งกรมธรรม์ <span className="text-red-700 ml-1">*</span>
      </h2>
      <div className="row mb-4">{renderDeliveryTypeOptions()}</div>
      {deliveryTypes.includes(2) && (
        <>
          <div className="row">
            <div className="col-md-6">
              <FormGroup require>
                <Switcher
                  id="delivery_address_type"
                  name={`${attrPrefixInsured}[delivery_address_type]`}
                  active={insuredsAttributes?.delivery_address_type}
                  onChange={(evt) => handleChangeDeliveryAddressType(evt)}
                  labels={deliveryAddressTypeOptions}
                />
                <input
                  type="hidden"
                  name={`${attrPrefixInsured}[delivery_address_type]`}
                  value={insuredsAttributes?.delivery_address_type}
                  ref={register}
                />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <FormGroup
                label="ชื่อผู้รับ - ที่อยู่ / บริษัท"
                htmlForLabel="insured_address"
                require
              >
                <TextField
                  id="insured_address"
                  name={`${attrPrefixDeliveryAddress}[address]`}
                  value={deliveryAddressAttributes?.address}
                  onChange={(evt) => handleChangePolicyDeliveryField(evt, "address")}
                  inputRef={register(messages.required)}
                  readOnly={copied}
                />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <LocationSearch
              register={register}
              errors={errors}
              data={deliveryAddressAttributes}
              nameInput={attrPrefixDeliveryAddress}
              keys="delivery_address_attributes"
              validateKey="delivery_address_attributes"
              readOnly={copied}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AddressDeliveryInsurance;
