import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";

import dictionary from "~src/data.yaml";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";

// import qrLogo from "/assets/payments/thaiqrlogo.png";
import visaLogo from "/assets/payments/visa.png";
import masterCard from "/assets/payments/mastercard.png";
import scbLogo from "/assets/payments/scb.png";
import bblLogo from "/assets/payments/bbl.png";
import ktbLogo from "/assets/payments/ktb.png";
import krungsriLogo from "/assets/payments/krungsri.png";
import kbankLogo from "/assets/payments/kbank.png";
import pciLogo from "/assets/payments/pci_dss.png";
import safePayment from "/assets/payments/safe-payment.png";

import { Button } from "/src/components/form";
import CreditCardErrorModal from "~src/components/modal/payments/CreditCardErrorModal";

const Credit = (props) => {
  const {
    summary,
    order,
    payment,
    isLoading,
    kbankBackyardElement,
    kbankCCFormElement,
    kbankCCLastResponse,
    disabled,
    lastErrors,
  } = props;
  const { order_id: orderId } = order;
  const {
    reference_order: transactionNumber,
    transaction_state: transactionState,
    code,
    is_pending: isPending,
    is_card_info_error: isCardInfoError,
    is_card_auth_error: isCardAuthError,
    is_temporary_error: isTemporaryError,
  } = kbankCCLastResponse?.provider_result || {};

  const history = useHistory();

  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  const kbankCcWrapperRef = React.useRef();
  // const kbankCcFormRef = React.useRef();

  const kbankCcWrapperRefCallback = useCallback(
    (element) => {
      console.log("kbankCcWrapper:", element);
      kbankCcWrapperRef.current = element || kbankCcWrapperRef.current;

      if (isLoading) {
        // TEST
        console.log("Script is still loading in KBank CC Form:", kbankCCFormElement);
        return;
      }

      if (!element) {
        return;
      }

      let kbankCCForm;
      if (element && kbankCCFormElement) {
        kbankCCForm = kbankCCFormElement;
        // kbankBackyardElement.getElementsById("kbankCCForm")?.[0];
        // kbankCcFormRef.current = kbankCCForm;

        // TEST
        console.log("Moving KBank CC Form:", kbankCCForm, "into wrapper:", element);

        element.appendChild(kbankCCForm);
        kbankCCForm.classList.remove("hidden");
      }

      // cleanup
      // return () => {
      //   if (kbankCCForm && kbankBackyardElement) {
      //     //TEST
      //     console.log(
      //       "Moving KBank CC Form:",
      //       kbankCCForm,
      //       "back into backyard:",
      //       kbankBackyardElement
      //     );
      //     kbankCCForm.classList.add("hidden");
      //     kbankBackyardElement.appendChild(kbankCCForm);
      //     // if (kbankCcFormRef.current === kbankCCForm) {
      //     //   kbankCcFormRef.current = undefined;
      //     // }
      //   }
      // };
    },
    [isLoading, kbankBackyardElement, kbankCCFormElement]
  );

  const lastErrorMessages = useMemo(() => lastErrors.map((err) => err.message), [lastErrors]);

  const errorInfoLines = useMemo(
    () => [
      // { key: "transaction_number", value: transactionNumber },
      { key: "transaction_state", value: transactionState },
      { key: "error_code", value: code },
    ],
    [transactionNumber, transactionState, code]
  );

  const handleCloseErrorModal = useCallback(
    () => setIsOpenErrorModal(false),
    [setIsOpenErrorModal]
  );

  const gotoCheckoutSummary = useCallback(() => {
    history.push({
      pathname: `/checkout/${orderId}/summary`,
      state: {
        orderId,
      },
    });
  }, [history, orderId]);

  useEffect(() => {
    console.log("CreditCardPayment mounted");

    // cleanup
    return () => {
      console.log("CreditCardPayment unmounted");

      if (kbankCCFormElement && kbankBackyardElement) {
        // TEST
        console.log(
          "Moving KBank CC Form:",
          kbankCCFormElement,
          "back into backyard:",
          kbankBackyardElement
        );
        kbankBackyardElement.appendChild(kbankCCFormElement);
        kbankCCFormElement.classList.add("hidden");
        // if (kbankCcFormRef.current === kbankCCForm) {
        //   kbankCcFormRef.current = undefined;
        // }
      }
    };
  }, []);

  useEffect(() => {
    if (disabled) {
      if (kbankCCFormElement) {
        kbankCCFormElement.setAttribute("disabled", "disabled");
        kbankCCFormElement.classList.add("disabled");
        kbankCCFormElement.classList.add("hidden");
      }
    }

    // const kbankCCForm = document.getElementById("kbankCCForm");
    // const kbankCCWrapper = document.getElementById("kbankCCWrapper");
    // const kbankBackyard = document.getElementById("kbankBackyard");
    // return () => {
    //   kbankBackyard.appendChild(kbankCCForm);
    //   kbankCCForm.classList.add("hidden");
    // };
  }, [disabled, kbankCCForm]);

  useEffect(() => {
    if (lastErrors && lastErrors.length) {
      setIsOpenErrorModal(true);
    }
  }, [lastErrors]);

  return (
    <div className="payment-methods-wrap cc">
      <div className="payment-methods-body">
        <div className="payment-total">
          <span>
            ยอดเงินที่ต้องชำระทั้งหมด
            {order.quotation.product_type !== "travel" && ` (รวมค่าธรรมเนียม 2%)`}{" "}
          </span>

          <span className="total-amount">
            {formatNumberWithCurrency(summary.paymentTotalAmountIncludedCCFee)}
          </span>
        </div>
      </div>
      <hr />
      <div>
        <p>เรารับชำระบัตรเครดิตทุกธนาคาร</p>
        <div className="list-bank">
          <img src={visaLogo} alt="Visa" className="bank-logo-visa" />
          <img src={masterCard} alt="MasterCard" className="bank-logo-master-card" />
          <img src={scbLogo} alt="scb" className="bank-logo" />
          <img src={bblLogo} alt="bbl" className="bank-logo" />
          <img src={ktbLogo} alt="ktb" className="bank-logo" />
          <img src={krungsriLogo} alt="krungsri" className="bank-logo" />
          <img src={kbankLogo} alt="kbank" className="bank-logo" />
          <img src={pciLogo} alt="pci-dds" className="bank-logo-pci" />
        </div>
      </div>
      <div className="list-bank">
        <img src={safePayment} alt="100% SAFE AND SECURE PAYMENTS" className="bank-logo-safe" />
      </div>
      <div id="kbankCCWrapper" ref={kbankCcWrapperRefCallback} className="pt-6 text-center">
        {/* {isLoading && <p>Loading...</p>} */}
      </div>
      {/* <ul
          id="kbankErrors"
          className="errors-list error w-full text-center text-bold text-red-500"
        >
          {lastErrors?.map((err, idx) => (
            <li key={idx}>{err.message || err || ""}</li>
          ))}
        </ul> */}

      <div className="row">
        <div className="motor-info-page-actions space-between w-full">
          <Button type="button" role="button" onClick={(e) => gotoCheckoutSummary()}>
            ย้อนกลับ
          </Button>
        </div>
      </div>
      {isOpenErrorModal && (
        <CreditCardErrorModal
          order={order}
          onClose={handleCloseErrorModal}
          payment={payment}
          transactionNumber={transactionNumber}
          transactionState={transactionState}
          isPending={isPending}
          isCardAuthError={isCardAuthError}
          isCardInfoError={isCardInfoError}
          isTempError={isTemporaryError}
          code={code}
          messages={lastErrorMessages}
          infoLines={errorInfoLines}
        />
      )}
    </div>
  );
};

export default Credit;
