import React, { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { ButtonRadius } from "~src/components/form";
import { OrdersTableRowProps } from "~src/components/orders/OrdersListView";
import { PaySlipAction } from "~src/components/payments/PaymentsListView";

import "./table.scss";
import StaffOrdersAPIClient from "~src/api/staff/Orders";
import { useOrdersContext } from "~src/context/Orders";
import { usePageContext } from "~src/context/Page";
// import { parseDateEN } from "~src/helpers/dateTime";
// import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "~src/helpers/formatNumber";
import { Payment } from "~src/types/payment";
import { Order } from "~src/types/order";
import { useOrdersAPI } from "~src/api/Orders";

const ordersAPIFactory = (props) => new StaffOrdersAPIClient(props);

export interface StaffOrderPaymentActionsProps extends OrdersTableRowProps {
  payment: Payment;
  onShowPaySlipModal?: PaySlipAction;
  onConfirmPayment?: (payment: Payment, order: Order) => void;
}

export default function StaffOrderPaymentActions({
  payment,
  // paymentDerivedFields,
  order,
}: // onShowGeneratePaymentLinkModal,
StaffOrderPaymentActionsProps) {
  // const { displayStatus } = paymentDerivedFields || getPaymentDerivedFields(payment);
  const { reload } = useOrdersContext<StaffOrdersAPIClient>();
  const { setPreloading } = usePageContext();
  const { addToast } = useToasts();

  const setAlert = useCallback(
    (status, message) => {
      if (status === "success") {
        setPreloading(true, "กำลังเปลี่ยนหน้า");
        addToast(
          <div>
            <h4>ยืนยันการชำระเงินเรียบร้อย</h4>
            <p>ตรวจสอบสถานะได้ที่ติดตามกรมธรรม์</p>
          </div>,
          {
            appearance: "success",
            autoDismiss: true,
            onDismiss: () => {
              // const url = currentParams.keyword ? `?keyword=${currentParams.keyword}` : "";
              // window.location.href = url;
              window.location.reload();
            },
          }
        );
      } else {
        addToast(
          <div>
            <h4>{message}</h4>
            <p>ยืนยันการชำระเงินไม่เรียบร้อย</p>
          </div>,
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        // setAlert(message);
      }
    },
    [setPreloading, addToast]
  );

  const onAPIError = useCallback((err: Error) => {
    setAlert("error", err.message);
  }, []);
  const { ordersAPI } = useOrdersAPI({
    apiFactory: ordersAPIFactory,
    onError: onAPIError,
  });

  function handleConfirmPayment(payment: Payment, order: Order) {
    setPreloading(true);
    (async function () {
      try {
        const _resp = await confirmPayment(payment, order);
        setPreloading(false);
        reload();
        // window.location.reload();
      } catch (err) {
        console.error(err);
        setAlert("error", "Oops something went wrong");
      }
    })();
  }

  async function confirmPayment(payment: Payment, order: Order) {
    setPreloading(true);

    // TODO: Revise this later
    // if (!ordersAPIRef.current) {
    //   return await new Promise((resolve, reject) =>
    //     setTimeout(() => confirmPayment(payment, order).then(resolve).catch(reject), 1000)
    //   );
    // }

    return (ordersAPI as StaffOrdersAPIClient).confirmPayment(order.order_id, payment.token!);
  }

  return (
    <>
      {["bank_transfer", "installment"].includes(payment.pay_method) &&
        payment.status === "pending" &&
        payment.payslip_url && (
          <ButtonRadius
            className="continue-btn"
            onClick={() => handleConfirmPayment(payment, order)}
            color="primary"
            size="xs"
          >
            ยืนยันชำระ
          </ButtonRadius>
        )}
    </>
  );
}
