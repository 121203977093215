import React, { useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import InsuranceInfo from "./InsuranceInfo";
import InsuredInfo from "./InsuredInfo";
import AmountInfo from "./AmountInfo";
import CommissionInfo from "./CommissionInfo";
import ListInfo from "./ListInfo";
import AgentInfo from "./AgentInfo";
import RemarkInfo from "./RemarkInfo";
import ShowPaySlipModal from "../modal/orders/showPayslipModal";
import ImageUploadModal from "../modal/upload/ImageUploadModal";
import { Tabs, Tab, TabPanel } from "~src/components/tab";
import PayMentLinkCreateModal from "~src/components/modal/paymentLinkCreate/paymentLinkCreateModal";
import axios from "~src/helpers/configAxios";

import statusYml from "~src/status.yml";
import dictionary from "~src/data.yaml";
import { OrdersAPIFactory, useOrdersAPI } from "~src/api/Orders";
import { useAppContext } from "~src/context/App";
import { usePageContext } from "~src/context/Page";
import { useAuthContext } from "~src/context/Auth";
import APIError from "~src/models/APIError";
import APIResponse from "~src/models/APIResponse";
import { Attachment } from "~src/types/attachment";
import { Order } from "~src/types/order";
import { Payment } from "~src/types/payment";
import Modal from "~src/pages/staff/cms/components/Modal";
import AttachmentModal from "~src/components/modal/attachment/attachmentModal";

import "./orderdetail.scss";
import { useAuthenticate } from "~src/helpers/authen";
import { FlashAutoTwoTone, FormatColorResetSharp } from "@mui/icons-material";

export interface OrderDetailProps {
  order: Order;
  checkedAddon: boolean;
  installmentInfo: any;
  editable: boolean;
  uploadPaySlipAllowed?: boolean;
  uploadPayerIDCardAllowed?: boolean;
  ordersAPIFactory?: OrdersAPIFactory;
  onReload?: () => void;
  onUploadAttachment?: () => void;
  onError?: (err: Error) => void;
}

export default function OrderDetail({
  order,
  checkedAddon,
  installmentInfo,
  editable,
  uploadPaySlipAllowed,
  uploadPayerIDCardAllowed,
  ordersAPIFactory,
  onReload,
  onUploadAttachment,
  onError,
}: OrderDetailProps) {
  const { t } = useTranslation();
  const { appConfig, assets } = useAppContext();
  const { setAlert, setPreloading } = usePageContext();
  const { session } = useAuthContext();
  const { permissions } = session;
  const { ordersAPI } = useOrdersAPI({
    apiFactory: ordersAPIFactory,
    onError: onError || setAlert,
  });

  const [activeTab, setActiveTab] = useState(0);
  const [addOrRemoveCMI, setAddOrRemoveCmi] = useState({
    checked_addon: checkedAddon,
  });

  const [isShowGeneratePaymentLinkModal, setIsShowGeneratePaymentLinkModal] = useState(false);
  const [paymentForGenerateLink, setPaymentForGenerateLink] = useState<Payment | undefined>();

  const [isShowPaySlipModal, setIsShowPaySlipModal] = useState(false);
  const [paySlipPayment, setPaySlipPayment] = useState<Payment | undefined>();
  const [paySlip, setPaySlip] = useState<Attachment | string | undefined>();

  const [isShowPayerIDModal, setIsShowPayerIDModal] = useState(false);
  const [payerIDImage, setPayerIDImage] = useState<Attachment | string | undefined>();

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [attachmentModalType, setAttachmentModalType] = useState(null);
  const [attachmentModalName, setAttachmentModalName] = useState(null);
  const [attachmentModalFileType, setAttachmentModalFileType] = useState("image/*");
  const [attachmentModalImageUrl, setAttachmentModalImageUrl] = useState(null);

  const [isShowConfirmPayslipModal, setIsShowConfirmPayslipModal] = useState(false);
  const [confirmingPayment, setConfirmingPayment] = useState<Payment | undefined>();

  const allowEdit = editable && isOrderEditable(order.status);
  const allowEditCMI = allowEdit;
  const allowUploadAttachment = editable && isAttachmentUploadable(order);

  const { accessType, showPaidCommission } = useAuthenticate();
  const isStaff = accessType === "STAFF";

  const canUploadPaySlip =
    uploadPaySlipAllowed &&
    paySlipPayment &&
    !["cancelled", "failed"].includes(paySlipPayment.status);
  const canUploadPayerIDCard =
    uploadPayerIDCardAllowed && !["closed", "renew_closed"].includes(order.status);
  const allowEditRemark = editable && isRemarkEditable();

  function isOrderEditable(orderStatus) {
    const isEditAll =
      permissions.findIndex((p) => p.slug === "ORDER" && p.action === "EDIT_ALL") > -1;
    const isEditIncompleted =
      permissions.findIndex((p) => p.slug === "ORDER" && p.action === "EDIT_INCOMPLETE") > -1;

    if (isEditAll) return true;
    return isEditIncompleted && ["open", "waiting_payment", "waiting_verify"].includes(orderStatus);
  }

  function isAttachmentUploadable(order) {
    const isEditAll =
      permissions.findIndex((p) => p.slug === "ATTACHMENT" && p.action === "EDIT_ALL") > -1;
    const isEditIncompleted =
      permissions.findIndex((p) => p.slug === "ATTACHMENT" && p.action === "EDIT_INCOMPLETE") > -1;
    if (!order.payments_summary.is_installments) return false;
    if (isEditAll) return true;
    return isEditIncompleted && order.payments.findIndex((pm) => pm.status == "pending") > -1;
  }

  function isRemarkEditable() {
    const isEditAll =
      permissions.findIndex((p) => p.slug === "ORDER" && p.action === "EDIT_ALL") > -1;
    const isEditIncompleted =
      permissions.findIndex((p) => p.slug === "ORDER" && p.action === "EDIT_INCOMPLETE") > -1;

    return isEditIncompleted || isEditAll;
  }

  function handleShowGeneratePaymentLinkModal(payment: Payment) {
    unstable_batchedUpdates(() => {
      setIsShowGeneratePaymentLinkModal(true);
      setPaymentForGenerateLink(payment);
    });
  }

  function handleShowPaySlipModal(payment: Payment) {
    unstable_batchedUpdates(() => {
      setPaySlipPayment(payment);
      setPaySlip(payment.payslip || payment.payslip_url);
      setIsShowPaySlipModal(true);
    });
  }

  function handleShowPayerIDModal(image?: Attachment) {
    unstable_batchedUpdates(() => {
      setPayerIDImage(image);
      setIsShowPayerIDModal(true);
    });
  }

  function handleShowConfirmPayslipModal(payment: Payment) {
    unstable_batchedUpdates(() => {
      setConfirmingPayment(payment);
      setIsShowConfirmPayslipModal(true);
    });
  }

  function handleShowAttachmentModal(attachmentType: string) {
    let allowPdfType = [
      "payer_id_card",
      "agent_installment_contract",
      "customer_installment_contract",
    ];
    let name = attachmentType;
    let url = order?.payment_info?.[attachmentType + "_url"];
    let fileType = "image/*";
    if (attachmentType == "payer_id_card") {
      name = "order[payer_id_card]";
      url = order?.payer_id_card_url;
    }
    if (allowPdfType.includes(attachmentType)) fileType = "image/*,application/pdf";

    setAttachmentModalType(attachmentType);
    setAttachmentModalName(name);
    setAttachmentModalImageUrl(url);
    setAttachmentModalFileType(fileType);
    setShowAttachmentModal(true);
  }

  function handleCloseAttachmentModal() {
    setShowAttachmentModal(false);
    setAttachmentModalType(null);
    setAttachmentModalImageUrl(null);
  }

  async function handleUploadAttachment(data: FormData) {
    handleCloseAttachmentModal();
    if (attachmentModalType == "payer_id_card") {
      await handleUploadPayerIDCard(data);
      onUploadAttachment();
    } else {
      setAlert(undefined);
      setPreloading(true);

      const url = `/api/payment_infos/upload`;
      data.append("id", order?.payment_info?.id);

      axios
        .post(url, data)
        .then(() => {
          setPreloading(false);
          onReload && onReload();
          onUploadAttachment();
        })
        .catch((error) => {
          setPreloading(false);
          handleUploadError("upload_attachment", error);
        });
    }
  }

  async function handleConfirmPayment() {
    let respData: APIResponse<Order> | undefined;
    try {
      setPreloading(true);

      let data = new FormData();
      data.append("token", confirmingPayment.token);
      respData = await ordersAPI.confirmPayslip(order.order_id, data);

      setPreloading(false);
      setIsShowConfirmPayslipModal(false);
      onReload && onReload();
    } catch (err) {
      setPreloading(false);
      handleUploadError("confirm_payslip_error", err);
    }
  }

  const handleToggleCMI = (status) => {
    setAddOrRemoveCmi({
      ...addOrRemoveCMI,
      checked_addon: !status,
    });
  };

  async function handleUploadPayerIDCard(data: FormData): Promise<APIResponse<Order> | undefined> {
    let respData: APIResponse<Order> | undefined;
    try {
      setAlert(undefined);
      setPreloading(true);

      data.append("no_details_update", "true");
      respData = await ordersAPI.updateOrder(order.order_id, data);

      setPreloading(false);
      setIsShowPayerIDModal(false);
      onReload && onReload();
    } catch (err) {
      setPreloading(false);
      handleUploadError("upload_payer_id_card", err);
    }

    return respData;
  }

  function handlePaySlipUploaded(_respData: APIResponse<any>) {
    setIsShowPaySlipModal(false);
    onReload && onReload();
  }

  function handleUploadError(apiName: string, err: Error) {
    const details =
      err instanceof APIError ? (err.details ? { [apiName]: err.details } : undefined) : undefined;
    setAlert(err.message, details);
  }

  return (
    <div className="agent-order-detail-wrapper">
      <div className="row">
        <div className="col-lg-7 col-xl-8">
          <div className="card">
            <Tabs>
              <Tab
                label="ข้อมูลประกันภัย"
                onClick={() => setActiveTab(0)}
                active={activeTab === 0}
              />
              <Tab
                label="ข้อมูลผู้เอาประกัน"
                onClick={() => setActiveTab(1)}
                active={activeTab === 1}
              />
            </Tabs>
            <TabPanel active={activeTab === 0}>
              <InsuranceInfo
                order={order}
                orderId={order.order_id}
                orderNumber={order.number}
                quote={order.quotes[0]}
                paymentsSummary={order.payments_summary}
                policies={order.policies}
                productType={order.quotation?.product_type}
                contract={order.contract}
                details={order.details}
                criteria={order.quotation?.criteria}
                beneficiary={order.insureds[0]?.beneficiary}
                agentConfirmed={order.agent_confirmed}
                staffConfirmed={order.staff_confirmed}
                allowEdit={allowEdit}
                allowEditCMI={allowEditCMI}
                addOrRemoveCMI={addOrRemoveCMI}
                handleToggleCMI={handleToggleCMI}
              />
            </TabPanel>
            <TabPanel active={activeTab === 1}>
              <InsuredInfo
                orderId={order.order_id}
                allowEdit={allowEdit}
                productType={order.quotation?.product_type}
                policies={order.policies}
                insuredCategory={order.insured_category}
                insured={order.insureds[0]}
                contract={order.contract}
                beneficiary={order.insureds[0].beneficiary}
                quotation={order.quotation}
                details={order.details}
                quote={order.quotes[0]}
                checkedAddon={checkedAddon}
                vehicleDocuments={order.vehicle_documents}
                attachImagesStatus={order.car_inspection_type}
                ordersAPIFactory={ordersAPIFactory}
                insuresInfo={order.insureds}
                onError={onError || setAlert}
              />
            </TabPanel>
          </div>
        </div>
        <div className="col-lg-5 col-xl-4">
          <div className="card">
            <AmountInfo
              payment={order.payments_summary}
              includeWhTax={order.include_wh_tax}
              quotation={order.quotation}
            />
            {showPaidCommission && (
              <div className="mt-4">
                <CommissionInfo
                  payment={order.payments_summary}
                  productType={order.quotation?.product_type}
                />
              </div>
            )}
          </div>
          <div className="card">
            <ListInfo
              order={order}
              quotation={order.quotation}
              paymentsSummary={order.payments_summary}
              payments={order.payments}
              paymentInfo={order.payment_info}
              installmentInfo={installmentInfo}
              payerIDImage={order.payer_id_card}
              allowUploadAttachment={allowUploadAttachment}
              // TODO: Permanently enable this after deploying Installment+Payment Link feature
              onShowGeneratePaymentLinkModal={
                appConfig.paymentLinksEnabled ? handleShowGeneratePaymentLinkModal : undefined
              }
              onShowPaySlipModal={handleShowPaySlipModal}
              onShowPayerIDModal={handleShowPayerIDModal}
              onShowConfirmPayslipModal={handleShowConfirmPayslipModal}
              onShowAttachmentModal={handleShowAttachmentModal}
              // ordersAPIFactory={ordersAPIFactory}
              // onError={onError || setAlert}
            />
            {isShowPayerIDModal && (
              <ImageUploadModal
                image={typeof payerIDImage !== "string" ? payerIDImage : undefined}
                imageURL={typeof payerIDImage === "string" ? payerIDImage : undefined}
                onClose={() => setIsShowPayerIDModal(false)}
                readOnly={!canUploadPayerIDCard}
                iconName="perm_media"
                title={dictionary.order.payer_id_card_title}
                uploadFieldName="order[payer_id_card]"
                onUpload={handleUploadPayerIDCard}
                onError={(err) => handleUploadError("upload_payer_id_card", err)}
              >
                {/* <input type="hidden" name="order_id" value={order.order_id} /> */}
              </ImageUploadModal>
            )}
            {showAttachmentModal && (
              <AttachmentModal
                title={statusYml.attachmentType[attachmentModalType]}
                name={attachmentModalName}
                imageUrl={attachmentModalImageUrl}
                allowUpload={allowUploadAttachment}
                acceptedFileType={attachmentModalFileType}
                onBackdropClicked={handleCloseAttachmentModal}
                onUpload={handleUploadAttachment}
              />
            )}

            {isShowGeneratePaymentLinkModal && (
              <PayMentLinkCreateModal
                orderID={order.order_id}
                payment={paymentForGenerateLink}
                order={order}
                // ordersAPIFactory={ordersAPIFactory}
                onError={onError || setAlert}
                onClose={() => setIsShowGeneratePaymentLinkModal(false)}
              />
            )}
            {isShowPaySlipModal && (
              <ShowPaySlipModal
                orderID={order.order_id}
                payslip={typeof paySlip !== "string" ? paySlip : undefined}
                payslip_url={typeof paySlip === "string" ? paySlip : paySlip?.url}
                onClose={() => setIsShowPaySlipModal(false)}
                payment={paySlipPayment}
                editable={canUploadPaySlip}
                ordersAPIFactory={ordersAPIFactory}
                onError={onError || setAlert}
                onUploaded={handlePaySlipUploaded}
              />
            )}
            {isShowConfirmPayslipModal && (
              <Modal onBackdropClicked={() => setIsShowConfirmPayslipModal(false)}>
                <div className="grid grid-cols-1 divide-y px-3 pt-2 pb-4">
                  <div className="text-xl pb-2">ยืนยันความถูกต้องการชำระ</div>
                  <div className="text-red-500 py-2">
                    * เมื่อดำเนินการยืนยันแล้ว จะไม่สามารถยกเลิกได้
                  </div>
                  <div className="pt-3 flex justify-end gap-x-2">
                    <button
                      className="px-3 py-2 min-w-20 rounded-lg bg-primary text-white"
                      onClick={() => setIsShowConfirmPayslipModal(false)}
                    >
                      ยกเลิก
                    </button>
                    <button
                      className="px-3 py-2 min-w-20 rounded-lg bg-primary-color text-white"
                      onClick={handleConfirmPayment}
                    >
                      ยืนยัน
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          </div>
          <div className="card">
            <AgentInfo agent={order.agent} />
          </div>
          <div className="card">
            <RemarkInfo
              orderId={order.order_id}
              remark={order.remark}
              allowEdit={allowEditRemark}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
