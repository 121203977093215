import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert, GetApp, Publish } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useToasts } from "react-toast-notifications";
import queryString from "query-string";

import StaffOrderPaymentActions from "./StaffOrderPaymentsActions";
import { ButtonRadius } from "~src/components/form";
import {
  OrderActionsCell,
  OrderContractTypeCell,
  OrderCoverageDatesRangeCell,
  OrderEarningCommissionCell,
  OrderInsurerInfoCell,
  OrderPaymentsSummaryCell,
  OrderProductInfoCell,
  OrdersTableHeaderRowProps,
  OrdersTableLayoutProps,
  OrdersTableRowProps,
  OrderStatusAndExpireDateCell,
  OrderSumInsuredCell,
  OrderAttachmentStatusCell,
  OrderTotalAmountCell,
} from "~src/components/orders/OrdersListView";
import DownloadModal from "~src/components/modal/download";
import PaymentsListView, {
  getPaymentDerivedFields,
  PaymentAction,
  PaymentsTableRowProps,
  PaySlipAction,
} from "~src/components/payments/PaymentsListView";

import StaffOrdersAPIClient from "~src/api/staff/Orders";
import { useOrdersContext } from "~src/context/Orders";
import { usePageContext } from "~src/context/Page";
import { useAuthContext } from "~src/context/Auth";
import { reformatDateEN } from "~src/helpers/date";
// import { parseDateEN } from "~src/helpers/dateTime";
import { getInsuredByCategory, dateFormat } from "~src/helpers/helpers";
// import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "~src/helpers/formatNumber";
import { renderYaml } from "~src/helpers/renderYaml";
import { Payment } from "~src/types/payment";
import { Order } from "~src/types/order";

import "./table.scss";

export type StaffOrdersTableLayoutProps = OrdersTableLayoutProps;

export default function StaffOrdersTableLayout({
  initialPage,
  setCurrentPage,
  renderActions,
  onShowPaySlipModal,
  rowProps,
}: StaffOrdersTableLayoutProps) {
  const { isLoading, orders, response: ordersResponse } = useOrdersContext();
  const {
    current_page: currentPage = initialPage || 1,
    total_pages: pagesCount = 0,
    total_count: _totalRecords = 0,
  } = ordersResponse || {};

  const { session } = useAuthContext();
  const { permissions } = session;
  const allowUploadExcel = permissions.some(
    (p) => p.slug === "ORDER" && p.action === "IMPORT"
  );

  const [isDownloadModalOpened, setIsDownloadModalOpened] = useState(false);
  const [isUploadModalOpened, setIsUploadModalOpened] = useState(false);

  function handleOpenDownloadModal() {
    setIsDownloadModalOpened(true);
  }

  function handleOpenUploadModal() {
    setIsUploadModalOpened(true);
  }

  const handlePageClick = (e: any) => {
    const selectedPage = parseInt(e.selected) + 1;
    setCurrentPage(selectedPage);
  };

  return (
    <div className="table-list">
      <div className="view-order-component mt-4">
        <div className="table-grid">
          <StaffOrdersTableHeaderRow {...rowProps} 
            onShowDownloadModal={handleOpenDownloadModal}
            onShowUploadModal={allowUploadExcel ? handleOpenUploadModal : null}
          />
          {isLoading && (
            <div
              className={`grid-tbody-full ${rowProps?.className || ""} ${
                rowProps?.cellClassName || ""
              }`}
            >
              กำลังโหลด...
            </div>
          )}
          {!isLoading && (
            <>
              {orders.length == 0 && (
                <div
                  className={`grid-tbody-full ${rowProps?.className || ""} ${
                    rowProps?.cellClassName || ""
                  }`}
                >
                  ไม่พบข้อมูล
                </div>
              )}
              {orders.map((order, index) => (
                <StaffOrdersTableRow
                  {...rowProps}
                  key={index}
                  order={order}
                  renderActions={renderActions}
                  onShowPaySlipModal={onShowPaySlipModal}
                />
              ))}
            </>
          )}

          <StaffOrdersDownloadExcelModal
            id="downloadOrdersExcelFormId"
            isOpen={isDownloadModalOpened}
            onClose={() => setIsDownloadModalOpened(false)}
          />
        </div>
      </div>

      {pagesCount > 1 && (
        <ReactPaginate
          previousLabel="Prev"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          initialPage={Number(currentPage) - 1}
          pageCount={pagesCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName="gm-paginate"
          // DEPRECATED: https://github.com/AdeleD/react-paginate/issues/66
          // subContainerClassName={"pages pagination"}
          pageClassName="pages pagination"
          activeClassName="active"
          disableInitialCallback={true}
        />
      )}
    </div>
  );
}

export interface StaffOrdersDownloadExcelModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
}

export const StaffOrdersDownloadExcelModal = ({
  isOpen,
  onClose,
  id,
}: StaffOrdersDownloadExcelModalProps) => {
  const { setPreloading } = usePageContext();
  const { ordersAPI } = useOrdersContext<StaffOrdersAPIClient>();

  const [isDownloadModalDone, setIsDownloadModalDone] = useState(false);
  const [downloadModalMessage, setDownloadModalMessage] = useState("");
  const [downloadEmail, setDownloadEmail] = useState("");

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setIsDownloadModalDone(false);
    setDownloadEmail("");
    setDownloadModalMessage("");
  }, [isOpen]);

  function handleDownloadEmailChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setDownloadEmail(evt.currentTarget.value);
  }

  function handleDownloadFile(data) {
    setDownloadModalMessage("กำลังดำเนินการ...");
    setPreloading && setPreloading(true);
    setIsDownloadModalDone(true);
    (async function () {
      try {
        await downloadOrders(data);
        setDownloadModalMessage("ดำเนินการเสร็จสิ้น");
        setPreloading && setPreloading(false);
      } catch (err) {
        console.error(err);
        setDownloadModalMessage("พบปัญหาระหว่างดำเนินการ กรุณาลองใหม่ในภายหลัง");
        setPreloading && setPreloading(false);
      }
    })();
  }

  async function downloadOrders(data) {
    // TODO: Revise this later
    const queryParams = queryString.parse(window.location.search, {
      arrayFormat: "bracket",
    });

    delete queryParams.page;
    delete queryParams.per_page;

    for (const param of data.entries()) {
      queryParams[param[0]] = param[1];
    }

    return ordersAPI.downloadOrdersAsExcel(queryParams);
  }

  return (
    <DownloadModal
      id={id}
      open={isOpen}
      title="ดาวน์โหลดคำสั่งซื้อ"
      isDownloadDone={isDownloadModalDone}
      downloadMessage={downloadModalMessage}
      downloadEmail={downloadEmail}
      onClose={() => onClose()}
      onDownload={handleDownloadFile}
      onDownloadEmailChanged={handleDownloadEmailChange}
    />
  );
};

export interface StaffOrdersTableHeaderRowProps extends OrdersTableHeaderRowProps {
  onShowDownloadModal?: () => void;
  onShowUploadModal?: () => void;
}

export const StaffOrdersTableHeaderRow = ({
  onShowDownloadModal,
  onShowUploadModal,
  className,
  cellClassName,
  ...htmlProps
}: StaffOrdersTableHeaderRowProps) => {
  const [listMenuElement, setListMenuElement] = React.useState<HTMLElement | undefined>();

  const handleOpenListMenu = (evt: React.MouseEvent<HTMLElement>) => {
    setListMenuElement(evt.currentTarget);
  };

  const handleCloseListMenu = () => {
    setListMenuElement(null);
  };

  const handleSelectListMenu = (callback: () => void) => {
    setListMenuElement(null);
    callback();
  };

  return (
    <div {...htmlProps} className={`grid-th ${className || ""}`}>
      <div className={`${cellClassName || ""}`}>ข้อมูลคำสั่งซื้อ</div>
      <div className={`${cellClassName || ""}`}>ตัวแทน</div>
      <div className={`text-left ${cellClassName || ""}`}>ข้อมูลลูกค้า</div>
      <div className={`text-center ${cellClassName || ""}`}>ประเภทงาน</div>
      <div className={`${cellClassName || ""}`}>บริษัท</div>
      <div className={`text-center ${cellClassName || ""}`}>แผน</div>
      <div className={`${cellClassName || ""}`}>วันที่คุ้มครอง</div>
      <div className={`text-right ${cellClassName || ""}`}>ทุนประกัน</div>
      <div className={`text-right ${cellClassName || ""}`}>เบี้ยประกันที่ชำระ</div>
      <div className={`text-right ${cellClassName || ""}`}>ค่าตอบแทนสุทธิ</div>
      <div className={`text-center ${cellClassName || ""}`}>วันที่ทำรายการ</div>
      <div className={`text-center ${cellClassName || ""}`}>วันที่ชำระเงิน</div>
      <div className={`text-center ${cellClassName || ""}`}>จำนวนเอกสาร</div>
      <div className={`text-center ${cellClassName || ""}`}>การชำระเงิน</div>
      <div>
        <IconButton
          className="float-right max-w-full p-0"
          component="span"
          aria-haspopup="true"
          onClick={handleOpenListMenu}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={listMenuElement}
          keepMounted
          open={!!listMenuElement}
          onClose={handleCloseListMenu}
        >
          {onShowDownloadModal && (
            <MenuItem onClick={() => handleSelectListMenu(onShowDownloadModal)}>
              <GetApp className="mr-1" />
              ดาวน์โหลด Excel
            </MenuItem>
          )}
          {onShowUploadModal && (
            <MenuItem onClick={() => handleSelectListMenu(onShowUploadModal)}>
              <Publish className="mr-1" />
              อัพโหลด Excel
            </MenuItem>
          )}
        </Menu>
      </div>
    </div>
  );
};

export interface StaffOrdersTableRowProps extends OrdersTableRowProps {
  onShowPaySlipModal?: PaySlipAction;
  onConfirmPayment?: (payment: Payment, order: Order) => void;
}

export const StaffOrdersTableRow = ({
  order,
  renderActions,
  onShowPaySlipModal,
  onConfirmPayment,
  className,
  cellClassName,
  ...htmlProps
}: StaffOrdersTableRowProps) => {
  const [paymentRowsCollapsed, setPaymentRowsCollapsed] = useState(true);

  // TODO: Move into Orders Detail page
  // function handleShowGeneratePaymentLinkModal(payment: Payment) {
  //   console.log("TODO: Show Generate Payment link modal:", payment);
  // }

  return (
    <>
      <div {...htmlProps} className={`grid-tbody h-5.5r ${className || ""}`}>
        <OrderStatusAndExpireDateCell
          order={order}
          className={cellClassName}
          allowOrderAccess={true}
        />
        <OrderAgentNumberCell order={order} className={cellClassName} />
        <OrderInsuredInfoCell order={order} className={cellClassName} />
        <OrderContractTypeCell order={order} className={cellClassName} />
        <OrderInsurerInfoCell order={order} className={cellClassName} />
        <OrderProductInfoCell order={order} className={cellClassName} />
        <OrderCoverageDatesRangeCell order={order} className={cellClassName} />
        <OrderSumInsuredCell order={order} className={cellClassName} />
        <OrderTotalAmountCell order={order} className={cellClassName} />
        <OrderEarningCommissionCell order={order} className={cellClassName} />
        <OrderCreatedDateCell order={order} className={cellClassName} />
        <OrderFirstPaidDateCell order={order} className={cellClassName} />
        <OrderAttachmentStatusCell order={order} className={cellClassName} />
        <OrderPaymentsSummaryCell
          order={order}
          paymentRowsCollapsed={paymentRowsCollapsed}
          setPaymentRowsCollapsed={setPaymentRowsCollapsed}
          // renderPaymentActions={(payment) => (
          //   <StaffOrderPaymentActions payment={payment} order={order} />
          // )}
          onShowPaySlipModal={onShowPaySlipModal}
          className={cellClassName}
        />
        <OrderActionsCell
          order={order}
          renderActions={(order) => (
            <>
              {renderActions && renderActions(order)}
              {order.payments.length === 1 && (
                <StaffOrderPaymentActions payment={order.payments[0]} order={order} />
              )}
            </>
          )}
          className={cellClassName}
        />
      </div>
      {!paymentRowsCollapsed && (
        <div className="grid-tbody-full justify-center text-center pl-2">
          <PaymentsListView
            payments={order.payments}
            order={order}
            renderActions={(payment) => (
              <StaffOrderPaymentActions payment={payment} order={order} />
            )}
            onShowPaySlipModal={onShowPaySlipModal}
            className="w-full mt-0 ml-2 mb-2 pl-2 border-l-2 border-gray-400 text-secondary-color"
            layoutProps={{ rowProps: { cellClassName: "text-secondary-color" } }}
          />
        </div>
      )}
    </>
  );
};

export const OrderAgentNumberCell = ({ order }: OrdersTableRowProps) => {
  return <div>{order.agent_number}</div>;
};

export const OrderInsuredInfoCell = ({ order }: OrdersTableRowProps) => {
  const getShortInformation = () => {
    switch (order?.quotation?.product_type) {
      case "travel":
        return `${order.quotation?.short_desc_criteria} - ${order.quotation.additional_information?.destination_name}`;
      case "motor" || "motorbike":
        return `${order.quotation.criteria?.car_brand} ${order.quotation.criteria?.car_model} ทะเบียน ${order.plate_number}`;
      default:
        return "";
    }
  };

  const shortInformation = getShortInformation();

  return (
    <div className="flex content-start text-center">
      {getInsuredByCategory(order.insureds[0], order.insured_category)}
      {order.order_type !== "custom" && (
        <>
          <br />
          {shortInformation}
        </>
      )}
    </div>
  );
};

export const OrderCreatedDateCell = ({ order }: OrdersTableRowProps) => {
  return <div className="flex content-start text-center">{reformatDateEN(order.created_at)}</div>;
};

export const OrderFirstPaidDateCell = ({ order }: OrdersTableRowProps) => {
  return (
    <div className="flex content-start text-center">
      {order.payments[0] ? reformatDateEN(order.payments[0].paid_at) : "-"}
    </div>
  );
};

export interface StaffOrderActionsProps extends OrdersTableRowProps {
  order: Order;
  onShowGenerateCashInstallmentsModal?: (order: Order) => void;
}

export const StaffOrderActions = ({
  order,
  onShowGenerateCashInstallmentsModal,
}: StaffOrderActionsProps) => {
  function handleGenerateCashInstallmentsBtnClicked(e: React.MouseEvent<HTMLElement>) {
    // TEST
    console.log("handleGenerateCashInstallmentsBtnClicked:", e);
    onShowGenerateCashInstallmentsModal && onShowGenerateCashInstallmentsModal(order);
  }

  return (
    <>
      {["waiting_payment"].includes(order.status) && (
        <ButtonRadius
          className="continue-btn"
          onClick={(e) => handleGenerateCashInstallmentsBtnClicked(e)}
          disabled={!order.payer_id_card_url}
          color="primary"
          size="xs"
        >
          ผ่อนเงินสด
        </ButtonRadius>
      )}
    </>
  );
};
