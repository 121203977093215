import React from "react";
import PropTypes from "prop-types";

import "./label.scss";

const Label = (props) => {
  const { inline } = props;
  return (
    <div className={inline ? "staff-label inline" : "staff-label"}>
      <div className="staff-label-key">{props.title}</div>
      <div className="staff-label-value">{props.value}</div>
    </div>
  );
};

Label.propTypes = {
  title: PropTypes.string,
};

export default Label;
