import cn from "classnames";
import qs from "qs";
import React from "react";
import Collapse from "@material-ui/core/Collapse";
import {
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  subYears,
  format,
} from "date-fns";
import {
  buildChartData,
  buildTableData,
  buildTableTotals,
  ChartDatas,
  TableData,
  MonthNumber,
  Timeline,
} from "../../../../components/agent/dashboard/charts/buildData";
import { DashboardChart } from "../../../../components/agent/dashboard/charts/Chart";
import { DashboardChartHeader } from "../../../../components/agent/dashboard/charts/Header";
import {
  Table,
  TableTotals,
} from "../../../../components/agent/dashboard/charts/Table";
import { Icon } from "../../../../components/icons";
import useFetch from "../../../../hooks/useFetch";
import { CommisstionTable } from "../../../../components/agent/dashboard/table/CommisstionTable";
import axios from "../../../../helpers/configAxios";

import { reducer, initialState, ChartContext } from "./store";
import ErrorPage from "../../../../components/errorPage";

export interface CommisssionData {
  id: string;
  order_number: string;
  insured_title: string;
  insured_firstname: string;
  insured_lastname: string;
  product_type: string;
  insurer_code: string;
  insurer_icon: string;
  policy_type: string;
  sum_insured: string;
  total_amount: string;
  discount: string;
  earning_commission: string;
  plate_number: string;
  status: string;
  criteria: {
    car_brand: string;
    car_model: string;
  };
}

const Dashboard: React.FC = () => {
  const [timeline, setTimeline] = React.useState<Timeline>("annually");
  const [year, setYear] = React.useState(`${new Date().getFullYear()}`);
  const [display, setDisplay] = React.useState(() => {
    const defaultTab = "premiums";

    try {
      const searchParams = window.location.search;
      const query = qs.parse(searchParams, { ignoreQueryPrefix: true });
      const tab = query["view"];

      if (!["premiums", "commissions"].includes(tab)) return defaultTab;

      return tab;
    } catch {
      return defaultTab;
    }
  });

  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    if (state.dateFrom != "" && state.dateTo != "") {
      const url = `/api/dashboard/sales_summary?from=${state.dateFrom}&to=${state.dateTo}`;
      axios
        .get(url)
        .then((response) => {
          const commissionData = [];
          response.data.report.forEach((report) => {
            report.policies.forEach((policy) => {
              commissionData.push(policy);
            });
          });
          setCommissionDatas(commissionData.sort((a, b) => a.id - b.id));
        })
        .catch((error) => console.log(error));
    }
  }, [state]);

  const url = React.useMemo(() => {
    let date = new Date();
    let seletedDate = date.setFullYear(parseInt(year));

    const formatDate = "yyyy-MM-dd";
    const dateFrom =
      timeline === "annually"
        ? format(startOfYear(seletedDate), formatDate)
        : format(subYears(startOfMonth(seletedDate), 1), formatDate);
    const toDate =
      timeline === "annually"
        ? format(endOfYear(seletedDate), formatDate)
        : format(endOfMonth(seletedDate), formatDate);

    return `/api/dashboard/sales_summary?from=${dateFrom}&to=${toDate}`;
  }, [timeline, year]);
  const { response, error, isLoading } = useFetch(url);

  const [chartData, setChartData] = React.useState<ChartDatas | null>([]);
  const [tableData, setTableData] = React.useState<TableData[]>([]);
  const [tableTotals, setTableTotals] = React.useState<TableTotals>();
  const [commissionDatas, setCommissionDatas] = React.useState<
    CommisssionData[]
  >([]);
  const [
    isActiveCommissionTable,
    setIsActiveCommissionTable,
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    const endedAt: MonthNumber =
      timeline === "annually"
        ? "12"
        : (`${new Date().getMonth()}` as MonthNumber);
    (async () =>
      setChartData(
        await buildChartData(response?.summary, endedAt, timeline)
      ))();
    (async () =>
      setTableData(
        await buildTableData(response?.summary, endedAt, timeline)
      ))();
    (async () => setTableTotals(await buildTableTotals(response?.summary)))();
  }, [response]);

  return (
    <ChartContext.Provider value={{ state, dispatch }}>
      {error ? (
        <ErrorPage />
      ) : (
        <div className="flex flex-col">
          <a
            className={cn(
              " w-40 bg-white border border-gray-600 rounded-full py-2 px-5 m-3",
              "flex items-center justify-between",
              "text-gray-600",
              "focus:outline-none focus:shadow-outline"
            )}
            href="/dashboard"
          >
            <Icon name="Arrow" width={28} height={28} color="#777" />
            <div>กลับหน้าผลงานหลัก</div>
          </a>
          <div className="mx-5">
            <DashboardChartHeader
              filter={{ timeline, year }}
              onFilterChange={({ timeline, year }) => {
                setTimeline(timeline);
                setYear(year);
                setIsActiveCommissionTable(false);
              }}
            />
          </div>
          <div className="p-5 m-5 bg-white rounded-md">
            {chartData && display === "premiums" && (
              <DashboardChart data={chartData.premium} />
            )}
            {chartData && display === "commissions" && (
              <DashboardChart data={chartData.commission} />
            )}
            <Table
              timeline={timeline}
              initialDisplay={display}
              data={tableData}
              totals={tableTotals}
              isLoading={isLoading}
              year={year}
              onChangeDisplay={(value) => setDisplay(value)}
              onChangeIsActiveCommissionTable={(value) =>
                setIsActiveCommissionTable(value)
              }
            />
          </div>
          <Collapse in={isActiveCommissionTable}>
            <div className="p-5 m-5 bg-white rounded-md">
              <CommisstionTable data={commissionDatas} />
            </div>
          </Collapse>
        </div>
      )}
    </ChartContext.Provider>
  );
};

export default Dashboard;
