import React from "react";
import "./select.scss";

const SelectField = ({ options, inputRef, error, alignRight, require = true, ...rest }) => {
  console.log(`Allow empty: ${allowEmpty}`)
  return (
    <>
      <select
        {...rest}
        ref={inputRef}
        className={!error ? "form-select" : "form-select has-error"}
        dir={alignRight ? "rtl" : ""}
      >
        {require && <option value="">กรุณาเลือก</option>}
        {options.map((v, i) => (
          <option key={i} value={v.value}>
            {v.label}
          </option>
        ))}
      </select>
      {error ? <p className="form-input-error">{error}</p> : ""}
    </>
  );
};

export default SelectField;
