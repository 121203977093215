import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import Carousel from "./carousel";
import QuickMenuItem from "~src/components/agent/dashboard/QuickMenuItem";
import SummaryMenuItem from "~src/components/agent/dashboard/SummaryMenuItem";
import { OrderTable } from "~src/components/agent/dashboard/table/OrderTable";
import { QuotationTable } from "~src/components/agent/dashboard/table/QuotationTable";
import { RenewalTable } from "~src/components/agent/dashboard/table/RenewalTable";
import AgentData from "~src/components/agent/dashboard/AgenData";
import TrainingList from "~src/components/agent/dashboard/TrainingList";
import Referral from "~src/components/agent/dashboard/Referral";
import { Icon } from "~src/components/icons";

import { useAgentOrdersAPI } from "~src/api/agent/Orders";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { parseDateEN } from "~src/helpers/dateTime";
import { useAuthenticate } from "~src/helpers/authen";

import "./style.scss";
import { useDashboardAPI } from "~src/api/Dashboard";
import { useAuthContext } from "~src/context/Auth";
import useLoading from "~src/hooks/useLoading";
import { useAppDispatch, useAppSelector } from "~src/store";
import { fetchGenerateSecureTokenAction, secureTokenState } from "~src/features/travel/redux";
import BannerModal from "~src/features/banner/agent/BannerModal";
import { contractAcceptedSelector, fetchAgentContract } from "~src/features/contracts";
import AgentViewContractModal from "~src/features/contracts/AgentViewContractModal";
import { AgentViewPdpaModal, fetchAgentPdpa, pdpaAcceptedSelector } from "~src/features/pdpa";

const Tabs = [
  {
    label: "ใบเสนอราคา",
    title: "รายการใบเสนอราคาที่ใกล้หมดอายุ",
    link: "/quotations",
  },
  {
    label: "รายการคำสั่งซื้อ",
    title: "รายการคำสั่งซื้อที่ใกล้หมดอายุ",
    link: "/orders",
  },
  {
    label: "ใบเตือนต่ออายุ",
    title: "รายการใบเตือนที่ใกล้หมดอายุ",
    link: "/renewals",
  },
];

export default function Dashboard(props) {
  return (
    <PageContextProvider>
      <DashboardInner {...props} />
    </PageContextProvider>
  );
}

const DashboardInner = () => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [link, setLink] = React.useState("");
  const [travelLink, setTravelLink] = React.useState<string | boolean>(false);

  const { session } = useAuthContext();
  const { onError } = usePageContext();
  const { dashboardAPI } = useDashboardAPI({
    onError,
  });
  const { ordersAPI } = useAgentOrdersAPI({
    onError,
  });
  const { showPaidCommission } = useAuthenticate()

  const { isLoading, data } = useLoading(() => dashboardAPI.getDashboardInfo(), {
    isReady: !!dashboardAPI && !session.isInitializing,
    loadOnInit: true,
    onError,
  });

  const {
    profile,
    summary,
    expiring_orders: expiringOrders,
    expiring_quotations: expiringQuotations,
    expiring_renewals: expiringRenewals,
    promotions,
    trainings,
  } = data ?? {};
  const {
    next_payout_date: nextPayoutDate,
    commission_by_yearly: summaryCommissionByYearly,
    commission_to_pay: summaryCommissionToPay,
    premium_by_yearly: summaryPremiumByYearly,
  } = summary ?? {};

  const isAcceptedContract = useAppSelector(contractAcceptedSelector);
  const isAcceptedPdpa = useAppSelector(pdpaAcceptedSelector);
  const secureToken = useAppSelector(secureTokenState);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchAgentContract());
    dispatch(fetchAgentPdpa());
  }, [dispatch]);

  React.useEffect(() => {
    if (secureToken.status === "idle") {
      dispatch(fetchGenerateSecureTokenAction());
    } else if (secureToken.status === "succeeded") {
      let taLink =
        process.env.TRAVEL_ENABLED === "true" && process.env.APP_NAME === "hero"
          ? `/travels?token=${secureToken.token}`
          : false;

      setTravelLink(taLink);
    }
  }, [secureToken]);

  return (
    <>
      {!isAcceptedContract && <AgentViewContractModal />}
      {isAcceptedContract && !isAcceptedPdpa && <AgentViewPdpaModal />}
      {isAcceptedContract && isAcceptedPdpa && <BannerModal />}
      {/* <BannerModal /> */}
      <div className="dashboard__container">
        <div className="w-full p-5">
          <section className="flex -mx-2">
            <div className="w-full px-2">
              <h1 className="text-lg">
                ยินดีต้อนรับครับ : ผลงานของนายหน้า
                {profile && (
                  <strong className="ml-1">
                    {profile.firstname} {profile.lastname}
                  </strong>
                )}
              </h1>
              <div className="mt-2 grid grid-cols-6 gap-2">
                {secureToken.status === "succeeded" && (
                  <>
                    <QuickMenuItem icon={<Icon name="DashMotor" />} title="รถยนต์" href="/motors" />
                    <QuickMenuItem
                      icon={<Icon name="DashCompulsory" />}
                      title="พ.ร.บ. เดี่ยว"
                      href="/motors?tab=cmi"
                    />
                    <QuickMenuItem
                      icon={<Icon name="DashTA" />}
                      title="เดินทาง"
                      href={travelLink}
                    />
                    <QuickMenuItem
                      icon={<Icon name="DashPA" />}
                      title="อุบัติเหตุ"
                      href={t("quickMenuAccident")}
                    />
                    <QuickMenuItem
                      icon={<Icon name="DashFire" />}
                      title="อัคคีภัย"
                      href={t("quickMenuFire")}
                    />
                    <QuickMenuItem
                      icon={<Icon name="DashMisc" />}
                      title="ประกันภัยอื่นๆ"
                      href={t("quickMenuOther")}
                    />
                  </>
                )}
              </div>
              <div className="mt-2 grid grid-cols-3 gap-2">
                <SummaryMenuItem
                  title="เบี้ยประกันภัยรวมสะสม"
                  value={summaryPremiumByYearly}
                  isLoading={isLoading}
                  href="/dashboard/charts?view=premiums"
                />
                {showPaidCommission && (
                  <>
                    <SummaryMenuItem
                      title="ค่าตอบแทนสะสม"
                      value={summaryCommissionByYearly}
                      isLoading={isLoading}
                      href={showPaidCommission ? "/dashboard/charts?view=commissions" : undefined}
                    />
                    <SummaryMenuItem
                      title="ค่าตอบแทนที่จะได้รับรอบถัดไป"
                      value={summaryCommissionToPay}
                      isLoading={isLoading}
                      valueColor="primary"
                      sublabel={
                        <div>
                          <span className="text-xs text-gray-500">รอบทำจ่าย </span>
                          <span className="text-xs underline text-primary-color">
                            {parseDateEN(nextPayoutDate)}
                          </span>
                        </div>
                      }
                    />
                  </>
                )}
              </div>
              <div className="flex flex-col flex-1 mt-2 overflow-hidden bg-white rounded">
                <div className="flex items-center justify-between p-4 bg-white">
                  <span>{Tabs[tabIndex]?.title}</span>
                  <div className="inline-flex overflow-hidden border rounded divide-x">
                    {Tabs.map(({ label }, idx) => (
                      <button
                        type="button"
                        key={idx}
                        onClick={() => setTabIndex(idx)}
                        className={cn(
                          "py-1 px-4 focus:outline-none",
                          tabIndex === idx
                            ? "bg-secondary-color text-white"
                            : "bg-white text-secondary-color"
                        )}
                      >
                        {label}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="flex-1 overflow-scroll">
                  {tabIndex === 0 && (
                    <QuotationTable
                      data={expiringQuotations?.content}
                      isLoading={isLoading}
                      ordersAPI={ordersAPI}
                    />
                  )}
                  {tabIndex === 1 && (
                    <OrderTable data={expiringOrders?.content} isLoading={isLoading} />
                  )}
                  {tabIndex === 2 && (
                    <RenewalTable data={expiringRenewals?.content} isLoading={isLoading} />
                  )}
                </div>
                <div className="flex items-center justify-center bg-white border-t-px">
                  <a
                    className="px-10 py-1 my-3 bg-gray-400 rounded-full focus:outline-none focus:shadow-outline"
                    href={Tabs[tabIndex]?.link}
                  >
                    ดูรายการทั้งหมด
                  </a>
                </div>
              </div>
            </div>

            <div className="w-full px-2 overflow-scroll" style={{ width: "350px" }}>
              <div className="bg-white mb-1">
                <Carousel items={promotions} />
                <AgentData profile={profile} />
                <TrainingList data={trainings} />
              </div>

              {t("Referral") && (
                <div className="bg-white">
                  <Referral />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
