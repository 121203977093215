import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import { useAppDispatch, useAppSelector } from "~src/store";
import { closeViewModal, pdpaViewSelector } from "./pdpaSlice";

const PDPAViewModal = () => {
  const {
    open,
    data: { version, detail, questions },
  } = useAppSelector(pdpaViewSelector);
  const dispatch = useAppDispatch();
  return (
    <Dialog open={open} maxWidth={"lg"} onClose={() => dispatch(closeViewModal())}>
      <DialogTitle>Version {version}</DialogTitle>
      <DialogContent>
        <Box className="detail-wrapper" dangerouslySetInnerHTML={{ __html: detail }} />
        <List disablePadding>
          {questions.map((o, index) => (
            <ListItem disableGutters key={index}>
              <ListItemIcon>
                <TurnedInNotIcon />
              </ListItemIcon>
              <ListItemText>{o.question}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeViewModal())}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PDPAViewModal;
