export { default as reducer } from "./registerSlice";
export { default as AgentInfo } from "./AgentInfo";
export { default as AddressInfo } from "./AddressInfo";
export { default as BankInfo } from "./BankInfo";
export { default as RecommenderInfo } from "./RecommenderInfo";
export { default as HasMotorLicense } from "./HasMotorLicense";
export { default as MTLAgentInfo } from "./MTLAgentInfo";
export { default as UploadInfo } from "./UploadInfo";
export { default as Stepper } from "./Stepper";
export { default as Contract } from "./Contract";
export { default as Pdpa } from "./Pdpa";

export * from "./registerSlice";
