import React from "react";
import yaml from "/src/data";

const InsurerDetail = ({
  name,
  type = undefined,
  productName,
  repairFacility = undefined,
  className = "overflow-hidden truncate",
}) => {
  return (
    <div className="insurer">
      <div className="insurer-name">{name}</div>
      {repairFacility && (
        <div className="insurer-type">
          {`${yaml.policy_type[type]} ${yaml.repair_facility[repairFacility]}`}
        </div>
      )}
      <div className={`max-h-6 w-52 ${className} text-secondary`}>{productName}</div>
    </div>
  );
};

export default InsurerDetail;
