import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "~src/helpers/configAxios";

export const fetchBanners = createAsyncThunk("banner/fetchBanners", async () => {
  const response = await axios.get("/api/banners");
  return response.data;
});

export const fetchCreateBanner = createAsyncThunk(
  "banner/fetchCreateBanner",
  async (params: { title: string; image: File | null }) => {
    const formData = new FormData();
    formData.append("title", params.title);

    if (params.image) {
      formData.append("image", params.image);
    }
    const response = await axios.post("/api/banners", formData);
    return response.data;
  }
);

export const fetchPublishBanner = createAsyncThunk(
  "banner/fetchPublish",
  async (params: { id: number }) => {
    const response = await axios.get(`/api/banners/${params.id}/publish`);
    return response.data;
  }
);

export const fetchDeleteBanner = createAsyncThunk(
  "banner/fetchDelete",
  async (params: { id: number }) => {
    const response = await axios.delete(`/api/banners/${params.id}`);
    return response.data;
  }
);

export const fetchUpdateBanner = createAsyncThunk(
  "banner/fetchUpdateBanner",
  async (params: { id: number; title: string; image: File | null }) => {
    const formData = new FormData();
    formData.append("title", params.title);

    if (params.image) {
      formData.append("image", params.image);
    }
    const response = await axios.patch(`/api/banners/${params.id}`, formData);
    return response.data;
  }
);

export const fetchSorterBanner = createAsyncThunk(
  "banner/fetchSorterBanner",
  async (params: { id: number; sort: number }[]) => {
    const formData = new FormData();
    formData.append("sort", JSON.stringify(params));

    const response = await axios.post(`/api/banners/sorter`, formData);
    return response.data;
  }
);
