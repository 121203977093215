import cn from "classnames";
import React from "react";
import { setMonth, endOfMonth, format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  buildTableHeaders,
  buildTableRows,
  Display,
  MonthNumber,
  RowTemplate,
  sortedTableCells,
  TableData,
  Timeline,
} from "./buildData";
import { TableRow } from "./TableRow";
import { useAuthenticate } from "~src/helpers/authen";

import dataYml from "../../../../data.yaml";

import { ChartContext } from "../../../../pages/agent/dashboard/charts/store";

export type TableTotals = Record<Display, typeof RowTemplate | null>;

interface Props {
  data?: TableData[];
  totals?: TableTotals;
  initialDisplay?: Display;
  timeline: Timeline;
  year: string;
  isLoading?: boolean;
  onChangeDisplay?: (display: Display) => void;
  onChangeIsActiveCommissionTable?: (value: boolean) => void;
}

export const Table: React.FC<Props> = ({
  data = [],
  totals = { commissions: null, premiums: null },
  initialDisplay,
  timeline,
  year,
  isLoading = false,
  onChangeDisplay,
  onChangeIsActiveCommissionTable,
}) => {
  const { t } = useTranslation();
  const { showPaidCommission } = useAuthenticate();
  const [display, setDisplay] = React.useState<Display>(initialDisplay);
  const { state, dispatch } = React.useContext(ChartContext);
  const [isActive, setIsActive] = React.useState<boolean>(true);
  const [defaultMonthSelect, setDefaultMonthSelect] = React.useState<string>("");
  const [tooltips, setTooltips] = React.useState<string[]>([]);
  const [totalCommissions, setTotalCommissions] = React.useState<number>(0);

  React.useEffect(() => {
    if (totals?.premiums) {
      const newTooltips = [];
      const today = new Date();
      sortedTableCells(totals[display], timeline).format.forEach((value, index) => {
        const month = Math.floor((index + 1) / 2);
        let payoutDate = new Date(today.getFullYear(), month, 1);
        payoutDate =
          index % 2 === 0
            ? new Date(
                payoutDate.setDate(
                  Math.min(30, new Date(today.getFullYear(), month + 1, 0).getDate())
                )
              )
            : new Date(payoutDate.setDate(15));
        newTooltips.push(
          value === "-"
            ? ""
            : `รับค่าคอมวันที่\n${payoutDate.getDate().toString().padStart(2, "0")}/${(
                payoutDate.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}/${payoutDate.getFullYear()}`
        );
      });
      setTooltips(newTooltips);
    }
    if (totals?.commissions) {
      const sum = Object.keys(totals?.commissions).reduce((accumulator, key) => {
        return accumulator + Number(totals?.commissions[key]?.value?.midMonth) + Number(totals?.commissions[key]?.value?.endMonth);
      }, 0);
      console.log(sum);
      setTotalCommissions(sum);
    }
  }, [totals]);

  const getPolicyTypeLabel = (productType: string, policyType: string) => {
    const productTypeKey = productType.replace("Product", "").toLowerCase();
    if (
      dataYml.product_type?.[productTypeKey]?.[policyType] &&
      dataYml.product_type?.[productTypeKey]?.[policyType].length > 1
    )
      return dataYml.product_type[productTypeKey][policyType];

    return "";
  };

  const handleChangeDisplay = (display: Display): void => {
    setDisplay(display);
    onChangeDisplay(display);
  };

  const handleSetCommissionDisplay = (yearMonth: string) => {
    const [year, month, date] = yearMonth.split("-");
    const fromDate = setMonth(
      new Date(parseInt(year), parseInt(month), parseInt(date) <= 15 ? 1 : 16),
      parseInt(month) - 1
    );
    const endDate =
      parseInt(date) <= 15
        ? setMonth(new Date(parseInt(year), parseInt(month), 16), parseInt(month) - 1)
        : endOfMonth(fromDate);
    const formatDate = "yyyy-MM-dd";

    dispatch({
      type: "ADD",
      payload: {
        dateFrom: format(fromDate, formatDate),
        dateTo: format(endDate, formatDate),
      },
    });

    if (yearMonth === defaultMonthSelect) {
      const temp = isActive;
      setIsActive(!temp);
      onChangeIsActiveCommissionTable(!temp);
    } else {
      setIsActive(true);
      onChangeIsActiveCommissionTable(true);
    }
    setDefaultMonthSelect(yearMonth);
  };

  console.log("Show paid commission: " + showPaidCommission);

  const endedAt: MonthNumber =
    timeline === "annually" ? "12" : (`${new Date().getMonth()}` as MonthNumber);
  return (
    <div>
      {!isLoading && (
        <div className="flex w-64 h-24 mx-auto mt-5 text-sm text-center border rounded-lg">
          <button
            onClick={() => handleChangeDisplay("premiums")}
            className={cn("flex-1 rounded-l-lg border-r focus:outline-none", {
              "bg-secondary-color text-white": display === "premiums",
              "rounded-r-lg": !showPaidCommission
            })}
          >
            เบี้ยประกัน
          </button>
          {showPaidCommission && (
            <button
              onClick={() => handleChangeDisplay("commissions")}
              className={cn("flex-1 rounded-r-lg focus:outline-none", {
                "bg-secondary-color text-white": display === "commissions",
              })}
            >
              ค่าตอบแทนสุทธิ
            </button>
          )}
        </div>
      )}
      <div className="flex mt-5 overflow-y-visible overflow-x-auto border">
        <div>
          {isLoading ? (
            <>
              <TableRow isLoading={isLoading} />
              <TableRow isLoading={isLoading} />
            </>
          ) : (
            <>
              {data && (
                <>
                  <TableRow
                    role="header"
                    values={buildTableHeaders(data, timeline, display, year).headers}
                  />
                  <TableRow
                    role="header"
                    values={buildTableHeaders(data, timeline, display, year).subHeaders}
                    contentSize="small"
                  />
                </>
              )}
              {data.map((displayData, idx) => (
                <div key={idx}>
                  {displayData.policies?.map((policy, idx) => {
                    return (
                      <TableRow
                        key={idx}
                        values={[
                          idx === 0 ? dataYml.product_type[displayData.productType] : null,
                          getPolicyTypeLabel(displayData.productType, policy.policyType),
                          ...buildTableRows(
                            policy[display],
                            buildTableHeaders(data, timeline, display, year).template
                          ),
                          policy.totals[display],
                        ]}
                        contentSize="small"
                      />
                    );
                  })}
                </div>
              ))}
              <TableRow
                role="footer"
                yearMonth={buildTableHeaders(data, timeline, display, year).monthHeader}
                values={[
                  null,
                  "ยอดรวม",
                  ...sortedTableCells(totals[display], timeline).format,
                  ...sortedTableCells(totals[display], timeline).values,
                ]}
                tooltip={["", "", ...tooltips, ""]}
                onClick={(value) => handleSetCommissionDisplay(value)}
                contentSize="small"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
