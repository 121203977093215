import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@mui/icons-material";

export interface CommissionInfoModalProps {
  handleClose: any;
  commissionInfo: any;
  productInfo: any;
}

const CommissionInfoModal: React.FC = ({
  handleClose,
  commissionInfo,
  productInfo,
}: CommissionInfoModalProps) => {
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="down" timeout={2000} ref={ref} {...props} />;
  });

  const getUnit = (unitType: string): string => {
    switch (unitType) {
      case "BAHT":
        return "฿";
      case "PERCENT":
        return "%";
      default:
        return unitType;
    }
  };

  return (
    <Dialog open={true} TransitionComponent={Transition} keepMounted onClose={handleClose}>
      <div className="p-4 flex flex-col">
        <div className="flex">
          <img
            src={productInfo.insurer_img}
            className="flex-none h-20r w-20r shadow-lg rounded-lg"
          />
          <div className="flex flex-col px-4 py-3 content-center ">
            <div>{productInfo.insurer_name}</div>
            <div className="text-lg">{productInfo.product_name}</div>
          </div>
          <IconButton onClick={handleClose} component="span" className="flex-none h-fit">
            <Close />
          </IconButton>
        </div>
        <div className="flex flex-col mt-4 p-3 bg-gray-200 rounded-lg">
          <div className="flex justify-between">
            <div className="w-4/5">คอมมิชชั่นรับ</div>
            <div>{`${commissionInfo.received_commission} ${getUnit(
              commissionInfo.received_commission_unit
            )}`}</div>
          </div>
          <div className="flex justify-between mt-1">
            <div className="w-4/5">คอมมิชชั่น OV</div>
            <div>{`${commissionInfo.received_ov} ${getUnit(commissionInfo.received_ov_unit)}`}</div>
          </div>
          <div className="flex justify-between mt-1">
            <div className="w-4/5">คอมมิชชั่น Printing</div>
            <div>{`${commissionInfo.received_printing} ${getUnit(
              commissionInfo.received_printing_unit
            )}`}</div>
          </div>
          <div className="flex justify-between mt-1">
            <div className="w-4/5">คอมมิชชั่นจ่าย</div>
            <div>{`${commissionInfo.paid_commission} ${getUnit(
              commissionInfo.paid_commission_unit
            )}`}</div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CommissionInfoModal;
