import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import classNames from "classnames";
import ReactSelect from "react-select/creatable";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { format } from "date-fns";
import _isUndefined from "lodash/isUndefined";
import axios from "~/src/helpers/configAxios";
import { FormGroup, TextField, SelectField } from "~/src/components/form";
import InsurersInput from "~/src/components/Forms/InsurersInput";
import DatePicker from "~/src/components/Forms/DatePicker";
import {
  insuranceInfo,
  coverageInfo,
  mainTypeMotor,
  standardVmiCommission,
  standardCmiCommission,
} from "~/src/states/orderCustom";
import CustomDropdown from "~src/components/Forms/CustomDropdown";
import PackageNameInput from "~/src/components/Forms/PackageNameInput";
import { messages } from "~/src/helpers/messages";

const InsurancePanel: React.FC = () => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { control, errors, register, setValue, clearErrors } = useFormContext();
  const [{ mainType, subTypeOptions }, setInsuranceInfo] = useRecoilState(insuranceInfo);
  const [{ coverageFrom }, setCoverageInfo] = useRecoilState(coverageInfo);
  const { motorPanelOpen } = useRecoilValue(mainTypeMotor);
  const [customVmiCommission, setCustomVmiCommission] = useRecoilState(standardVmiCommission);
  const [customCmiCommission, setCustomCmiCommission] = useRecoilState(standardCmiCommission);
  const [packages, setPackages] = useState([]);

  const packageName = useWatch({
    control,
    name: `product.name`,
    defaultValue: "",
  });

  const orderType = useWatch({
    control,
    name: `order.contract`,
    defaultValue: "",
  });

  const agentUsername = useWatch({
    control,
    name: `order.agent_number`,
    defaultValue: "",
  });

  const productType = useWatch({
    control,
    name: `product.type`,
    defaultValue: "",
  });

  const insurerCode = useWatch({
    control,
    name: `product.insurer_code`,
    defaultValue: "",
  });

  const policyType = useWatch({
    control,
    name: `product.policy_type`,
    defaultValue: "",
  });

  useEffect(() => {
    updateDefaultCommissionInfo(packages);
  }, [packages]);

  useEffect(() => {
    if (orderType && agentUsername && insurerCode && policyType) {
      requestCommissionInfo({
        insurerCode: insurerCode,
        productType: productType,
        policyType: policyType,
        agentUsername: agentUsername,
        orderType: orderType,
      }).then((result) => {
        const resultPackages = result.data;
        const packageNamesSet = new Set(resultPackages?.map((p) => p.package_name));
        const uniquePackageNamesList = [...packageNamesSet];
        const newPackages = uniquePackageNamesList.map((packageName) => {
          return resultPackages.find((p) => p.package_name === packageName);
        });
        setPackages(newPackages);
      });

      requestCommissionInfo({
        insurerCode: insurerCode,
        productType: productType,
        policyType: "พ.ร.บ.",
        agentUsername: agentUsername,
        orderType: orderType,
      }).then((result) => {
        const resultPackages = result.data;
        const cmiPackage = resultPackages?.[0];

        if (!cmiPackage) {
          setCustomCmiCommission(null);
        } else {
          setCustomCmiCommission({
            received_commission: cmiPackage?.received_commission || 0,
            received_commission_unit: cmiPackage?.received_commission_unit || "PERCENT",
            received_ov: cmiPackage?.received_ov || 0,
            received_ov_unit: cmiPackage?.received_ov_unit || "PERCENT",
            received_printing: cmiPackage?.received_printing || 0,
            received_printing_unit: cmiPackage?.received_printing_unit || "PERCENT",
            paid_commission_percent: cmiPackage?.paid_commission || 0,
            paid_ov_percent: cmiPackage?.paid_ov || 0,
            paid_printing_percent: cmiPackage?.paid_printing || 0,
          });
        }
      });
    }
  }, [orderType, agentUsername, insurerCode, policyType]);

  useEffect(() => {
    setValue("product.policy_type", "");
  }, [mainType]);

  useEffect(() => {
    if (coverageFrom) {
      const newDate = new Date(
        coverageFrom.getFullYear() + 1,
        coverageFrom.getMonth(),
        coverageFrom.getDate()
      );
      setValue(`order.details.cover_end_date`, format(newDate, "dd/MM/yyyy"));
    }
  }, [coverageFrom]);

  const requestCommissionInfo = (params) => {
    const url = `/api/commissions/search`;
    return axios.post<any, AxiosResponse<any[]>>(url, {
      insurer_code: params.insurerCode || "",
      product_type: params.productType || "",
      policy_type: getSubmitPolicyType(params.productType, params.policyType),
      agent_code: params.agentUsername || "",
      order_type: params.orderType || "",
    });
  };

  const updateDefaultCommissionInfo = (packages) => {
    const defaultPackage = packages.find((p) => p.package_name == "default");
    let selectedPackage = packages.find((p) => p.package_name == packageName);
    if (!selectedPackage) {
      if (!defaultPackage) {
        setCustomVmiCommission(null);
        return;
      }
      selectedPackage = defaultPackage;
    }

    setCustomVmiCommission({
      received_commission: selectedPackage?.received_commission || 0,
      received_commission_unit: selectedPackage?.received_commission_unit || "PERCENT",
      received_ov: selectedPackage?.received_ov || 0,
      received_ov_unit: selectedPackage?.received_ov_unit || "PERCENT",
      received_printing: selectedPackage?.received_printing || 0,
      received_printing_unit: selectedPackage?.received_printing_unit || "PERCENT",
      paid_commission_percent: selectedPackage?.paid_commission || 0,
      paid_ov_percent: selectedPackage?.paid_ov || 0,
      paid_printing_percent: selectedPackage?.paid_printing || 0,
    });
  };

  const getSubmitPolicyType = (productType, policyType) => {
    const specialMotorProductTypes = [
      "รถอื่นๆ",
      "รถบรรทุกเกิน 4 ตัน",
      "Super Car",
      "Big Bike",
      "Motorcycle",
    ];

    if (specialMotorProductTypes.includes(productType)) return productType.concat(" ", policyType);
    return policyType;
  };

  return (
    <div className="p-4 w-full bg-white shadow rounded-5">
      <h6 className="flex items-center justify-between p-1 px-2 text-sm font-bold bg-gray-100">
        ข้อมูลกรมธรรม์
        <button
          type="button"
          onClick={() => setExpanded(!expanded)}
          className="flex items-center justify-center w-6 h-6 bg-white shadow outline-none rounded-5 focus:outline-none"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </button>
      </h6>

      <div className={classNames(expanded ? "block" : "hidden")}>
        <p className="pt-4 px-2 text-lg font-bold text-secondary pb-3">
          {`ข้อมูลประกันภัย ${mainType || ""}`}
        </p>

        <div className="grid grid-cols-2 gap-6 tablet:grid-cols-1">
          <div className="grid grid-cols-3 col-span-2 gap-4">
            <FormGroup label="บริษัทประกัน" require>
              <InsurersInput name="product.insurer_code" control={control} errors={errors} />
            </FormGroup>

            {subTypeOptions.length > 0 && (
              <FormGroup label="ประเภทความคุ้มครอง" require>
                <CustomDropdown
                  required
                  name="product.policy_type"
                  control={control}
                  errors={errors}
                  options={subTypeOptions}
                  callbackOnChange={(value: string) => {
                    setInsuranceInfo((prevState) => ({
                      ...prevState,
                      subType: value,
                    }));
                  }}
                />
              </FormGroup>
            )}

            {motorPanelOpen && (
              <FormGroup label="ประเภทการซ่อม" require>
                <SelectField
                  name="product.details.repair_facility"
                  style={{ minHeight: "38px" }}
                  inputRef={register(messages.required)}
                  error={errors?.product?.details?.repair_facility?.message}
                  require
                  options={[
                    { label: "อู่", value: "garage" },
                    { label: "ศูนย์", value: "dealer" },
                  ]}
                />
              </FormGroup>
            )}
          </div>

          <FormGroup label="วันที่เริ่มคุ้มครอง" require>
            <Controller
              name="order.details.cover_start_date"
              rules={messages.required}
              control={control}
              render={({ onChange, value }) => {
                return (
                  <>
                    <DatePicker
                      value={value}
                      dateFormat="dd/MM/yyyy"
                      maxYears={3}
                      onChange={(date: Date): void => {
                        onChange(format(date, "dd/MM/yyyy"));
                        setCoverageInfo((prevState) => ({
                          ...prevState,
                          coverageFrom: date,
                        }));
                      }}
                    />
                    {errors?.order?.details?.cover_start_date?.message && (
                      <span className="text-xs text-red-600">
                        {errors?.order?.details?.cover_start_date?.message}
                      </span>
                    )}
                  </>
                );
              }}
            />
          </FormGroup>

          <FormGroup label="วันที่สิ้นสุดวันคุ้มครอง" require>
            <Controller
              name="order.details.cover_end_date"
              rules={messages.required}
              control={control}
              render={({ onChange, value }) => {
                return (
                  <>
                    <DatePicker
                      readOnly={_isUndefined(coverageFrom)}
                      value={value}
                      minDate={coverageFrom}
                      maxYears={60}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        onChange(format(date, "dd/MM/yyyy"));
                        setCoverageInfo((prevState) => ({
                          ...prevState,
                          coverageTo: date,
                        }));
                      }}
                    />
                    {errors?.order?.details?.cover_end_date?.message && (
                      <span className="text-xs text-red-600">
                        {errors?.order?.details?.cover_end_date?.message}
                      </span>
                    )}
                  </>
                );
              }}
            />
          </FormGroup>

          <FormGroup label="ชื่อ package" className="w-full" require>
            <PackageNameInput
              name="product.name"
              control={control}
              errors={errors}
              setValue={setValue}
              register={register}
              clearErrors={clearErrors}
              packages={packages}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default InsurancePanel;
