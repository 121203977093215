import React from "react";
import "./home.scss";

interface Props {}

const MTBPrivacyPolictCovers = () => {
  return (
    <>
      <div className="text-indent-home mb-1">
        <li>ข้อมูลส่วนบุคคลที่เก็บรวบรวม </li>
        <li>วิธีการเก็บรวบรวมหรือการได้มาซึ่งข้อมูลส่วนบุคคลของท่าน</li>
        <li>วัตถุประสงค์ในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล</li>
        <li>วิธีการป้องกันข้อมูลส่วนบุคคลของท่าน</li>
        <li>ระยะเวลาในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน</li>
        <li>การเปิดเผยและการโอนข้อมูลส่วนบุคคลของท่าน</li>
        <li>การใช้ข้อมูลส่วนบุคคลที่มีความอ่อนไหวเป็นพิเศษ</li>
        <li>การใช้ข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ทางการตลาด</li>
        <li>สิทธิตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลของท่าน </li>
        <li>คุกกี้ </li>
        <li>เว็บบีคอน</li>
        <li>การใช้บริการวิเคราะห์ข้อมูลโดยบุคคลอื่น</li>
        <li>การเชื่อมโยงเว็บไซต์และการบริการอื่นๆ นโยบายความเป็นส่วนตัวบนเว็บไซด์อื่นๆ</li>
        <li>การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</li>
        <li>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล </li>
        <li>ช่องทางติดต่อบริษัทฯ</li>
        <li>ช่องทางติดต่อหน่วยงานผู้มีอํานาจ</li>
      </div>
      <p>ทั้งนี้ ท่านจะต้องอ่านและทําความเข้าใจนโยบายฉบับนี้อย่างละเอียด ดังนี้</p>
    </>
  );
};

const CollectionPersonalData = () => {
  return (
    <div className="text-indent-home mb-1">
      <li>
        ข้อมูลที่ MTB ได้รับจากท่านเมื่อท่านลงทะเบียนหรือกรอกข้อมูลในใบสมัครหรือแบบ ฟอร์มอื่นๆ
        เพื่อรับ หรือขอใช้บริการผลิตภัณฑ์/บริการของ MTB เช่น ชื่อ-นามสกุล
        หมายเลขบัตรประจําตัวประชาชนหรือบัตรประจําตัวอื่นๆ หมายเลขโทรศัพท์ วันเดือนปี เกิด เพศ
        ที่อยู่ไปรษณีย์ อีเมล รูปภาพ ข้อมูลด้านการเงิน ข้อมูลเกี่ยวกับประวัติการ ทํางาน
        ซึ่งอาจรวมถึงชื่อและที่อยู่ของนายจ้าง ข้อมูลสุขภาพ ลายมือชื่อเป็นต้น ทั้งนี้
        ขึ้นอยู่กับใบสมัคร/แบบฟอร์มที่ท่านกรอก
      </li>
      <li>
        ข้อมูลทางการเงิน เช่น รายได้ แหล่งที่มาของรายได้ เลขบัญชีธนาคาร ข้อมูลเกี่ยวกับ ภาษี
        รายละเอียดเกี่ยวกับการเคลื่อนไหวของบัญชีธนาคาร รายละเอียดเกี่ยวกับเงินกู้
        ข้อมูลเกี่ยวกับการลงทุน รายละเอียดเกี่ยวกับบัตรเครดิต และรายละเอียดหรือข้อมูล
        เกี่ยวกับการชําระเงินอื่น ๆ
      </li>
      <li>
        ข้อมูลเกี่ยวประวัติการถูกดําเนินคดีความของท่าน เช่น ประวัติอาชญากSSม บันทึก
        เกี่ยวกับการดําเนินคดีไม่ว่าทางแพ่ง หรือการดําเนินคดีอื่น ๆ รวมไปถึงรายงานของ ตํารวจ
        และคําสั่งศาลที่เกี่ยวข้อง
      </li>
      <li>
        รายละเอียดผลิตภัณฑ์และ/ หรือบริการต่าง ๆ ที่ท่านเคยซื้อจากบริษัทฯ หรือผู้
        ประกอบธุรกิจประกันอื่น ๆ เช่น หมายเลขกรมธรรม์ จํานวนเงินเอาประกัน การ
        เปลี่ยนแปลง/การทําธุรกรรมเกี่ยวกับกรมSSSฆ์ วิธีการจ่ายเบี้ยประกัน ประวัติการ
        ชําระเบี้ยประกัน หรือประวัติเกี่ยวกับการกู้ยืมเงิน ผู้รับประโยชน์ การเรียกร้องค่า
        สินไหมทดแทน รวมถึงการใช้สิทธิต่าง ๆ ภายใต้กรมSSS) หรือผลิตภัณฑ์ หรือ บริการอื่น ๆ ของเรา
        หรือผู้ประกอบธุรกิจประกันอื่น ๆ
      </li>
      <li>
        สถานะเกี่ยวกับการป้องกันและปราบปรามการฟอกเงินและการป้องกันและปราบปราม
        การสนับสนุนเงินแก่การก่อการร้าย และภาวะล้มละลาย กฎหมายสหรัฐอเมริกาว่าด้วย
        การป้องกันมิให้บุคคลธรรมดาและนิติบุคคลที่มีสถานะเป็นบุคคลอเมริกันหลีกเลี่ยง ภาษี (Foreign
        Account Tax Compliance Act: FACTA)
      </li>
      <li>
        ข้อมูลเกี่ยวกับธุรกรรมของท่านกับ MTB เช่น ข้อมูลการสมัครงาน ข้อมูลการสมัคร เป็นตัวแทนประกัน
        ข้อมูลการเข้าเสนอราคา ข้อมูลกรมธรรม์ ข้อมูลความคุ้มครอง ของกรมธรรม์ เบี้ยประกัน
        และประวัติการชําระเงินซึ่งรวมถึงข้อมูลบัตรเครดิตหรือ บัตรเดบิต
        หมายเลขบัญชีธนาคารหรือข้อมูลการธนาคารหรือการชําระเงินอื่นๆ รวม ทั้งวันที่และเวลาที่ชําระเงิน
        ข้อมูลการเข้ารักษาพยาบาล ข้อมูลการเข้าใช้บริการศูนย์ บริการลูกค้า MTB ทั้งนี้
        ขึ้นอยู่กับประเภทของการทําธุรกรรมของท่าน
      </li>
      <li>
        ข้อมูลจากการเข้าชมเว็บไซต์ gettgo.com เว็บไซต์อื่นๆ หรือแอปพลิเคชันที่ MTB เป็นผู้ดําเนินการ
        ข้อมูลการใช้เว็บไซต์และแอปพลิเคชันของ MTB ข้อมูลการใช้สื่อ สังคมออนไลน์ (Social Media)
        และการโต้ตอบกับโฆษณาออนไลน์ของ MTB เช่น รุ่นและประเภทของโปรแกรมคอมพิวเตอร์ที่ใช้เปิดดูเว็บ
        (browser type and version) ประเภทของอุปกรณ์ที่ท่านใช้เพื่อเข้าถึงการบริการ
        (เครื่องคอมพิวเตอร์ ส่วนบุคคล แล็ปท็อป หรือสมาร์ทโฟน) ข้อมูลประเภทระบบการปฏิบัติการและ
        แพลตฟอร์ม IP (Internet Protocol) address ของอุปกรณ์หรือเครื่องมือปลาย ทาง ข้อมูล Location
        ข้อมูลเกี่ยวกับการบริการและผลิตภัณฑ์ที่ท่านเข้าชมหรือค้นหา
      </li>
      <li>
        บันทึกการติดต่อของท่านกับ MTB ซึ่งเก็บในรูปแบบของบันทึกข้อความของผู้รับ บริการ
        การประเมินความพึงพอใจ การวิจัยและสถิติ หรือ การบันทึกเสียงสนทนา หมายเลขผู้โทรศัพท์เข้า
        หรือการฝากข้อความเสี่ยง (Voice Mail) หรือการบันทึก การบันทึกเสียวเฉพาะกรณี)
        ผ่านกล้องรักษาความปลอดภัย (CCTV) เมื่อ ท่านติดต่อกับศูนย์บริการลูกค้าของ MTB
        รวมไปถึงการให้ข้อมูลผ่านสื่อต่างๆ เช่น SMS Social Media แอปพลิเคชัน FAX Chat และ อีเมล
        เป็นต้น
      </li>
      <li>
        ข้อมูลที่ท่านให้ไว้กับ MTB เมื่อท่านมีการขอข้อมูลเพิ่มเติมเกี่ยวกับผลิตภัณฑ์หรือ บริการของ
        MTB หรือเพื่อให้ MTB ติดต่อท่านกลับไป
      </li>
      <li>
        ข้อมูลสําหรับการติดต่อและข้อมูลอื่นๆ เช่น ชื่อ หมายเลขบัตรประจําตัวประชาชนหรือ
        บัตรประจําตัวอื่นๆ หมายเลขโทรศัพท์ รวมทั้งข้อมูลประชากร (demographic information)
        ที่เกี่ยวกับท่านที่ท่านได้แจ้งต่อ MTB เมื่อท่านตกลงเข้าร่วมกิจกรรม การแข่งขัน
        การจับฉลากชิงโชค แคมเปญ หรือการสํารวจการวิจัย
      </li>
      <li>
        ข้อมูลโปรไฟล์สื่อสังคมออนไลน์ของท่านเมื่อท่านใช้ข้อมูลและรหัสการเข้าระบบของสื่อ สังคมออนไลน์
        (social media credential) เช่น Facebook Twitter TikTok ละ LINE
        เพื่อเชื่อมต่อหรือเข้าสู่บริการใดๆของ MTB เช่น บัญชีสื่อสังคมออนไลน์ (social media account
        ID) สิ่งที่สนใจ (interests) รายการที่ชอบ (Likes) และ รายชื่อเพื่อนของท่าน
        ท่านสามารถควบคุมการจัดเก็บความเป็นส่วนตัวนี้ผ่านการตั้ง
        ค่าบัญชีสื่อสังคมออนไลน์ที่จัดทําไว้ให้โดยผู้ให้บริการสื่อสังคมออนไลน์ของท่าน
      </li>
      <li>
        ข้อมูลที่ท่านให้ไว้กับบริษัทฯ เมื่อสร้างบัญชีออนไลน์ หรือบัญชีของแอปพลิเคชัน โดย
        ทั่วไปบริการเหล่านี้กําหนดไว้สําหรับเจ้าของที่ลงทะเบียนเท่านั้น หากท่านเลือกที่จะให้
        บุคคลอื่น เช่น สมาชิกในครอบครัวหรือตัวแทนของท่านใช้บัญชีของท่าน ท่านควรให้ บุคคลนั้นๆ
        ศึกษานโยบายนี้อย่างละเอียด
      </li>
      <li>
        ข้อมูลส่วนบุคคลอื่นๆ ตามที่กฎหมายระบุให้ต้องขอความยินยอมจากท่านก่อนทําการ เก็บรวบรวม
        บริษัทฯจะแจ้งให้ท่านทราบและขอความยินยอมจากท่านก่อนทําการเก็บ ข้อมูล
        หรือตามระยะเวลาที่กฎหมายกําหนด ข้อมูลส่วนบุคคลที่มีความอ่อนไหว บริษัทฯ
        มีความจําเป็นต้องเก็บรวบรวมข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Personal Data) เช่น
        ประวัติสุขภาพซึ่งอาจรวมถึงพฤติกรรมการสูบ บุหรี่ พฤติกรรมการดื่มแอลกอฮอล์ เพื่อการซื้อ หรือ
        ใช้ผลิตภัณฑ์ของเรา กล่าวคือ ข้อมูลดังกล่าวจําเป็นต่อการพิจารณารับประกัน
        ตลอดจนการให้บริการและการจ่ายค่า สินไหมทดแทน
        หากท่านไม่ยินยอมให้บริการประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อน ไหวดังกล่าว บริษัทฯ
        จะไม่สามารถให้ผลิตภัณฑ์ และให้บริการใด ๆ แก่ท่านได้
      </li>
    </div>
  );
};

const CollectingOrObtains = () => {
  return (
    <>
      <div className="text-indent-home mb-1">
        <li>
          ข้อมูลที่ท่านเป็นผู้ส่งมอบให้ MTB เช่น ข้อมูลที่ท่านติดต่อสื่อสารกับ MTB ไม่ว่าจะ
          เป็นการสื่อสารเป็นหนังสือหรือวาจา ข้อมูลที่ท่านใช้ในการสมัครลงทะเบียนหรือสร้าง
          โปรไฟล์กับเราเพื่อเข้าใช้บริการของเรา หรือข้อมูลที่ท่านให้ไว้กับ MTB ในใบคําขอเอา ประกัน
          รวมถึงเอกสารประกอบคําขอเอาประกัน แบบฟอร์มขอใช้บริการ ขอข้อมูล หรือ
          คําขออื่นใดเกี่ยวกับผลิตภัณฑ์หรือบริการจาก MTB แบบฟอร์มที่ท่านส่งมอบให้แก่ MTB
          ตลอดจนข้อมูลที่ MTB ได้จากท่านเมื่อมีการทําแบบสํารวจลูกค้าโดยความสมัคร ใจ
          หรือการให้ข้อเสนอแนะหรือข้อคิดเห็น (feedback) ผ่านทางกระดาษ โทรศัพท์ ข้อความ กระดานข้อความ
          (message board) หรือทางอีเมล หรือข้อมูลที่ท่านเข้าใช้ บริการ ณ ศูนย์บริการลูกค้า MTB
          และ/หรือ
        </li>
        <li>
          ข้อมูลที่ถูกจัดเก็บโดยอัตโนมัติเมื่อท่านเข้าใช้บริการของ MTB ด้วยเทคโนโลยีที่เรียก
          ว่าคุกกี้ หรือเทคโนโลยีอื่นที่คล้ายคลึงกัน เช่น ข้อมูลกิจกรรมการใช้เว็บไซต์ของ MTB
          และบนอุปกรณ์เคลื่อนที่ (mobile device type) ที่ท่านใช้เพื่อเข้าถึงบริการของ MTB
          กรุณาเยี่ยมชม www.aboutcookies.org เพื่ออ่านข้อมูลเพิ่มเติมเกี่ยวกับ เทคโนโลยี
          เหตุผลและวิธีการที่ MTB ใช้งานคุกกี และ/หรือ
        </li>
        <li>
          ข้อมูลเกี่ยวกับท่านที่ MTB ได้รับจากบริษัทในเครือและฉากบุคคลที่สามที่จําเป็นและ
          เกี่ยวข้องกับการให้บริการ เช่น ผู้ให้บริการสื่อสังคมออนไลน์ที่มีการเชื่อมต่อกับบริการ ของ
          MTB โรงพยาบาล และ/หรือ พันธมิตรทางธุรกิจ ตลอดจนผู้ให้บริการอื่นๆ ที่ บริษัทฯ
          เห็นว่าการให้บริการดังกล่าวจะเป็นประโยชน์ต่อท่าน
        </li>
        <li>
          เมื่อ MTB ได้รับข้อมูลส่วนบุคคลจากบุคคลภายนอกเกี่ยวกับตัวท่านจากแหล่งข้อมูล สาธารณะ
          (public records) และที่ไม่ใช่ข้อมูลสารารณะ (non-public records) และ/หรือ
          เมื่อบริษัทฯได้รับข้อมูลส่วนบุคคลจากบุคคลภายนอกเกี่ยวกับท่านเพื่อวัตถุประสงค์ใน
          การปฏิบัติตามกฎหมายและเพื่อวัตถุประสงค์เกี่ยวกับการกํากับดูแล ตลอดจนเพื่อ
          วัตถุประสงค์อื่นๆ ที่ชอบด้วยกฎหมาย เช่น บริษัทฯ อาจได้รับข้อมูลส่วนบุคคลของ
          ท่านจากคณะกรรมการกํากับและส่งเสริมการประกอบธุรกิจประกัน (คปภ.) หน่วยงาน ภาครัฐ
          และ/หรือหน่วยงานกํากับดูแลอื่นๆที่ใช้อํานาจตามกฎหมาย
        </li>
        <li>
          ข้อมูลที่จัดเก็บในรูปแบบข้อความ รวมไปถึงภาพและเสียง เมื่อท่านติดต่อกับบุคลากร
          เจ้าหน้าที่บริการลูกค้า พนักงานขาย ตัวแทน นายหน้า คนกลางประกัน ผู้รับจ้าง คู่ค้า
          ผู้ให้บริการ ผู้รับมอบอํานาจ ผู้กระทําการแทน หรือบุคคลอื่นหรือหน่วยงานอื่นที่ 1
          เกี่ยวข้องของบริษัทฯ (เรียกรวมกันว่า "บุคลากรและคู่ค้าของบริษัทฯ") ผ่านทาง เว็บไซต์
          แอปพลิเคชัน สื่อสังคมออนไลน์ โทรศัพท์ อีเมล การพบปะกันโดยตรง การ สัมภาษณ์ ข้อความสั้น
          (SMS) โทรสาร ไปรษณีย์ VDO Call Service หรือโดยวิธี การอื่นใด
        </li>
      </div>
      <p className="text-indent-home">
        เมื่อท่านส่งข้อมูลส่วนบุคคลให้แก่บริษัทฯ เพื่อเข้าร่วมในกิจกรรมทางการตลาด การ ประกวด
        การจับฉลากชิงโชค งานอีเว้นท์ หรือการแข่งขันต่าง ๆ ที่จัดขึ้นโดยหรือใน นามของเรา และ/
        หรือบุคลากร คู่ค้า หรือ พันธมิตรทางธุรกิจของเรา
      </p>
      <div className="text-indent-home">
        <li>
          การที่ท่านไม่ให้ข้อมูลส่วนบุคคลบางประเภท การไม่ให้ความยินยอม และ/หรือยกเลิก
          การให้ความยินยอมในการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูล อาจส่งผลให้ทางบริ ษัทฯ
          ไม่สามารถดําเนินการตามคําขอของท่านหรือให้บริการแก่ท่านได้
        </li>
        <li>
          เมื่อท่านให้ข้อมูลส่วนบุคคลใด ๆ ที่เกี่ยวข้องกับบุคคลภายนอกแก่บริษัทฯ (ซึ่งบุคคล
          ภายนอกดังกล่าว รวมถึงแต่ไม่จํากัดเพียงผู้เอาประกัน สมาชิกในครอบครัว ผู้ชําระ เบี้ยประกัน
          หรือผู้รับประโยชน์) ท่านรับรองและรับประกันความถูกต้องของข้อมูลส่วน บุคคลนั้น
          พร้อมทั้งรับรองและรับประกันว่าท่านได้แจ้งให้บุคคลเหล่านั้นทราบอย่างครบ
          ถ้วนแล้วเกี่ยวกับรายละเอียดตามนโยบายฉบับนี้
        </li>
        <li>
          ตราบเท่าที่กฎหมายอนุญาต ท่านตกลงเพิ่มเติมที่จะดําเนินการให้เราได้รับการชดใช้ อย่างเต็มที่
          ต่อความเสียหาย ความสูญเสีย การลงโทษ ค่าใช้จ่ายต่าง ๆ ซึ่งรวมไปถึง
          แต่ไม่จํากัดเพียงค่าใช้จ่ายทางกฎหมาย และค่าใช้จ่ายที่เกิดขึ้นเกี่ยวเนื่องกับการดําเนิน คดี
          ไม่ว่าในประการใด ๆ ซึ่งรวมถึงค่าปรับ เบี้ยปรับ ดอกเบี้ย เงินเพิ่ม หรือเงินอื่น ๆ
          ที่เรียกเก็บ เรียกปรับ หรือเก็บโดยหน่วยงานกํากับดูแลหรือหน่วยงานที่มีอํานาจออก กฎระเบียบ
          อันเกิดจากหรือเกี่ยวเนื่องกับการกระทําหรือการละเว้นไม่กระทําของท่าน () หรือของเจ้าหน้าที่
          wนักงาน ผู้ให้คําปรึกษา ตัวแทน หรือผู้แทนของท่าน) ความผิดหรือ
          ความประมาทเลินเล่อของท่านในการปฏิบัติหน้าที่เหล่านั้น หรือที่ส่งผลให้เราถูกกล่าวหา
          หรือลงโทษฐานฝ่าฝืนกฎหมายที่ใช้บังคับ
        </li>
      </div>
    </>
  );
};

const PurposeCollectingAndUsing = () => {
  return (
    <>
      <div className="text-indent-home">
        <li>
          เพื่อพิจารณาการรับประกันตามคําขอเอาประกันของท่าน เพื่อพิจารณาการรับประกัน
          ตามคําขอเอาประกันกลุ่มของท่าน หรือเพื่อดําเนินการทําประกันต่อสําหรับผลิตภัณฑ์
          และ/หรือบริการของบริษัทฯ ให้แก่ท่าน
        </li>
        <li>
          เพื่อการทําธุรกรรมของท่าน เช่น การชําระเบี้ยประกัน การให้บริการเกี่ยวกับกรมธรรม์ ประกัน
          การเรียกร้องค่าสินไหมทดแทน หรือการใช้สิทธิใด ๆ ภายใต้กรมSSSม์ของ ท่าน
          ซึ่งรวมถึงสิทธิในการรับช่วงสิทธิ และสิทธิที่ได้รับช่วงมาด้วยหากมี
        </li>
        <li>
          เพื่อติดต่อกับท่าน นําส่งเอกสารเพื่อการดําเนินการของผลิตภัณฑ์และบริการ
          และ/หรือเพื่อจัดการความสัมพันธ์ระหว่างบริษัทฯ กับท่าน
        </li>
        <li>
          เพื่อดําเนินการตามคําขอของท่านที่ส่งผ่านแบบฟอร์มของบริษัทฯ ผ่านทางตัวแทน ประกัน
          นายหน้าประกัน คนกลางประกัน ศนย์บริการลูกค้า สํานักงานสาขา เว็บไซต์
          หรือแอปwลิเคชั่นบนมือถือ เช่น การให้ใบเสนอราคาผลิตภัณฑ์ของบริษัทฯ
        </li>
        <li>
          เพื่อการบริหารจัดการบริการของบริษัทฯ สําหรับงานการดําเนินงานภายใน รวมถึงการ
          วินิจฉัยปัญหาและการแก้ไขปัญหา การตรวจสอบภายใน หรือ การสอบบัญชี
        </li>
        <li>เพื่อปรับเปลี่ยนการให้บริการให้สอดคล้องกับโปรไฟล์ของท่าน</li>
        <li>
          เพื่อให้คําแนะนําและข้อเสนอแก่ท่านเกี่ยวกับสินค้า และ/หรือ บริการของบริษัทฯ ที่ท่าน
          อาจสนใจ
        </li>
        <li>
          เพื่อวิเคราะห์ข้อมูลทางการเงินเพื่อนําเสนอผลิตภัณฑ์ทางการเงินให้ตรงตามความ ต้องการของท่าน
        </li>
        <li>
          เพื่อให้บริการ และปรับปรุงสินค้าและการบริการของบริษัทฯ รวมไปถึงถึงการดูแล บํารุงรักษา
          การจัดการ และการดําเนินการของผลิตภัณฑ์และบริการดังกล่าว
        </li>
        <li>
          เพื่อประโยชน์ในการดําเนินธุรกิจของบริษัทฯ เช่น การวิเคราะห์ข้อมูล การตรวจสอบ
          การออกแบบ/พัฒนาผลิตภัณฑ์ใหม่ การปรับปรุงหรือปรับเปลี่ยนบริการของบริษัทฯ
          การดูแนวโน้มการใช้งานด้านบริการของบริษัทฯ การดูประสิทธิภาพของแคมเปญส่ง
          เสริมการขายของบริษัทฯ การปรับโครงสร้างองค์กรของบริษัทฯ การขยายธุรกิจและ
          การทําธุรกรรมของบริษัทฯ
        </li>
        <li>
          เพื่อตรวจสอบหรือยืนยันตัวตนของท่านเมื่อท่านเข้าสู่บัญชี เพื่อให้มั่นใจว่าการสื่อสาร
          ทั้งหมดของบริษัทฯ กับท่านมีความปลอดภัยและเป็นความลับ
        </li>
        <li>
          บริษัทฯ อาจประมวลผลพฤติกรรมการใช้เว็บไซต์ แอปพลิเคชัน หรือแพลตฟอร์ สังคมออนไลน์
          เพื่อการวิเคราะห์การใช้งานเว็บไซต์ แอปพลิเคชัน หรือแพลตฟอร์มสื่อ สังคมออนไลน์ของท่าน
          และการทําความเข้าใจลักษณะการใช้งานที่ท่านชอบ เพื่อจัด ทําให้เว็บไซต์ แอปพลิเคชัน
          หรือแพลตฟอร์มสื่อสังคมออนไลน์เหล่านั้นตอบสนอง อย่างเหมาะสมกับท่านโดยเฉพาะ เพื่อการประเมิน
          หรือดําเนินการ และการปรับปรุงเว็บไซต์ แอปพลิเคชัน หรือแพลตฟอร์มสื่อสังคมออนไลน์เหล่านั้น
          หรือผลิตภัณฑ์และ / หรือบริการของเรา การแก้ไขปัญหาต่าง ๆ การแนะนําผลิตภัณฑ์และ/
          หรือบริการที่ เกี่ยวข้อง และการจัดโฆษณาบนเว็บไซต์ แอปพลิเคชัน และช่องทางอื่น ๆ ตามกลุ่ม
          เป้าหมาย
        </li>
        <li>
          เพื่อการส่งข้อมูลทางด้านการบริหารธุรกิจให้กับท่าน เช่น ข้อมูลเกี่ยวกับการบริการ
          การเปลี่ยนแปลงของข้อกําหนด เงื่อนไขและนโยบายของบริษัทฯ
        </li>
        <li>
          เพื่อการส่งข้อมูลทางการตลาด (marketing information) ให้กับท่านและการแจ้ง
          ให้ท่านทราบถึงผลิตภัณฑ์และบริการของบริษัทฯ หรือข้อเสนอเกี่ยวกับการส่งเสริม การขาย
          (promotional offers) ตามที่กล่าวต่อไปในหัวข้อ “การใช้ข้อมูลส่วนบุคคล
          เพื่อวัตถุประสงค์ทางการตลาด"
        </li>
        <li>
          เพื่อการทําวิจัยทางการตลาด การวิเคราะห์ข้อมูลขั้นสูง และการทําวิจัยเชิงสถิติหรือ
          คณิตศาสตร์ประกัน การรายงานหรือการประเมินผลทางการเงินที่จัดทําขึ้นโดยบริษัทฯ กลุ่มบริษัทฯ
          บุคลากรและคู่ค้าของบริษัทฯ หรือหน่วยงานกํากับดูแลที่เกี่ยวข้องกับ บริษัทฯ
        </li>
        <li>
          เพื่อเชิญท่านเข้าร่วมการสํารวจของบริษัทฯ เพื่อให้บริษัทฯ ปรับปรุงและให้บริการแก่
          ท่านได้ดียิ่งขึ้น ตลอดจนการจัดกิจกรรม และสัมมนากับท่าน
        </li>
        <li>ตรวจสอบข้อมูลเกี่ยวกับความเห็น หรือการร้องเรียนจากท่าน และเพื่อทําตามคําขอ ของท่าน</li>
        <li>ตรวจสอบข้อมูลเกี่ยวกับผู้สมัครงาน และเพื่อติดต่อกับผู้สมัครงานกับบริษัทฯ</li>
        <li>เพื่อการดําเนินการให้เป็นไปตามข้อกําหนดภายใต้นโยบายภายในของบริษัทฯที่ยึดถือ ปฏิบัติ</li>
        <li>
          เพื่อดําเนินให้เป็นไปตามข้อกําหนดของกฎหมาย กฎ ระเบียบ ข้อตกลง หรือนโยบายที่ ใช้บังคับ
          ซึ่งกําหนดขึ้นโดยหน่วยงานที่มีหน้าที่บังคับใช้กฎหมาย หน่วยงานรัฐ หน่วย
          งานที่มีหน้าที่ระงับข้อพิพาท หน่วยงานที่กํากับดูแลธุรกิจประกัน หรือหน่วยงานอื่นๆ
          ที่ใช้อํานาจตามกฎหมาย
        </li>
      </div>
      <div className="mt-2">
        <li>การดําเนินการใดๆ ที่บริษัทฯ เชื่อว่าจําเป็นหรือเหมาะสม </li>
        <ul className="text-indent-home">
          <li>เพื่อตรวจสอบ ป้องกันและตรวจจับการกระทําที่ผิดต่อกฎหมายหรือสงสัยว่าจะผิด กฎหมาย</li>
          <li>อยู่ภายใต้กฎหมายที่บังคับใช้</li>
          <li>เพื่อปฏิบัติตามกระบวนการทางกฎหมาย รวมถึงการพิจารณาคดีความ</li>
          <li>
            เพื่อตอบสนองต่อคําขอจากหน่วยงานรัฐบาล รัฐ
            รวมถึงหน่วยงานของรัฐและรัฐบาลต่างประเทศที่ท่านอาศัยอยู่
          </li>
          <li>เพื่อบังคับใช้ข้อกําหนดในการให้บริการและประกาศเกี่ยวกับข้อมูลส่วนบุคคลของบริษัทฯ</li>
          <li>
            เพื่อปกป้องการดําเนินงานของบริษัทฯ เพื่อปกป้องสิทธิความเป็นส่วนตัว
            ความปลอดภัยหรือทรัพย์สินของบริษัทฯ ท่านหรือบุคคลอื่น และ
          </li>
          <li>เพื่อให้บริษัทฯ สามารถหาวิธีการเยียวยา หรือจํากัดความเสียหายที่อาจเกิดขึ้นได้</li>
          <li>
            วัตถุประสงค์อื่นๆ ที่ไม่ได้กล่าวข้างต้น จะถูกระบุไว้เป็นการเฉพาะเมื่อบริษัทฯ
            มีการขอข้อมูลของท่าน หรือเป็นการเก็บรวบรวมและใช้ตามข้อยกเว้นตามกฎหมาย
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <li>บริษัทฯ จะใช้ข้อมูลส่วนบุคคลของท่านภายใต้วัตถุประสงค์ที่ระบุข้างต้นเมื่อ:</li>
        <ul className="text-indent-home">
          <li>ท่านได้ให้ความยินยอมไว้กับบริษัทฯ ตามกฎหมาย</li>
          <li>อยู่ภายใต้กฎหมายที่บังคับใช้</li>
          <li>
            เป็นการจําเป็นเพื่อการปฏิบัติตามสัญญาซึ่งท่านเป็นคู่สัญญา
            หรือเพื่อใช้ในการดําเนินการตามคําขอของท่านก่อนเข้าทําสัญญานั้น
          </li>
          <li>เป็นการป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล</li>
          <li>
            เป็นการจําเป็นเพื่อการปฏิบัติหน้าที่ในการดําเนินภารกิจเพื่อประโยชน์สาธารณะของบริษัทฯ
            หรือปฏิบัติหน้าที่ในการใช้อํานาจที่รัฐได้มอบหมายให้แก่บริษัทฯ
          </li>
          <li>
            เป็นการจําเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ หรือของบุคคลหรือนิติบุคคลอื่น
            เว้นแต่ประโยชน์ดังกล่าวมีความสําคัญน้อยกว่าสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของท่าน
          </li>
          <li>เป็นการจําเป็นเพื่อปฏิบัติตามกฎหมาย</li>
          <li>
            มีการจัดทําเอกสารประวัติศาสตร์หรือจดหมายเหตุเพื่อประโยชน์สาธารณะ หรือที่
            เกี่ยวกับการศึกษาวิจัยหรือสถิติ
            โดยบริษัทฯจะจัดให้มีมาตรการปกป้องที่เหมาะสมรองสิทธิและเสรีภาพของท่าน
          </li>
          <li>
            เป็นการจําเป็นเพื่อก่อตั้งสิทธิเรียกร้องตามกฎหมาย
            การปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย
            หรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย
          </li>
        </ul>
        <div className="text-indent-home mt-4">
          ท่านอาจเลือกที่จะไม่ให้บริษัทฯ เก็บรวบรวมข้อมูลบางประการที่บริษัทฯ ร้องขอ อย่างไรก็ดี
          การที่ท่านเลือกที่จะไม่ให้ข้อมูลบางประการดังกล่าว อาจเป็นอุปสรรคต่อความสามารถในการ
          ทําธุรกรรม หรือต่อการให้บริการระหว่างบริษัทฯ กับท่าน หรือต่อการตอบสนองต่อข้อร้องขอ อื่นๆ
          ของท่านได้ เช่น บริษัทฯ อาจไม่สามารถดําเนินการเพื่อเข้าทําสัญญาประกันกับท่านได้ หรือ
          บริษัทฯ อาจไม่สามารถให้บริการต่าง ๆ เกี่ยวกับผลิตภัณฑ์ หรือ บริการของบริษัทฯ
          รวมถึงการให้บริการเกี่ยวกับสัญญาประกัน หรือการเรียกร้องค่าสินไหมทดแทนให้แก่ท่านได้
          เว้นแต่กฎหมายและกฎระเบียบที่ใช้บังคับ รวมถึง พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคลจะอนุญาต
          ให้กระทําเป็นอย่างอื่น บริษัทฯ จะแจ้งและขอความยินยอมจากท่าน หากเราประสงค์จะใช้ข้อมูล
          ส่วนบุคคลของท่านเพื่อวัตถุประสงค์อื่นใดนอกเหนือไปจากที่ระบุไว้ในนโยบายความเป็นส่วน
          ตัวฉบับนี้ หรือนอกเหนือไปจากวัตถุประสงค์ที่เกี่ยวข้องโดยตรงกับนโยบายความเป็นส่วนตัวนี้
        </div>
      </div>
    </>
  );
};

const TechnologiesUsedOnline = () => {
  return (
    <div className="text-indent-home">
      <li>
        Secured Socket Layer (SSL) และ Transport Layer Security (TLS): เพื่อไม่ให้
        มีการตรวจพบข้อมูลใดๆ ในขณะที่ส่งผ่านทางอินเทอร์เน็ต บริษัทฯ ใช้เทคโนโลยีการ เข้ารหัส Secure
        Socket Layer (SSL) และโปรโตคอลการรักษาความปลอดภัย Transport Layer Security (TLS)
        บนเว็บไซต์ของบริษัทฯ ณ จุดที่บริษัทฯ รวบรวมข้อมูลส่วนบุคคลเพื่อนําไปเข้ารหัส (เช่น
        ข้อมูลการชําระเงิน และข้อมูลรับรอง การเข้าสู่ระบบ) ซึ่งบราวเซอร์ (browser)
        ที่นิยมส่วนใหญ่ได้แก่ Google Chrome, Firefox, Safari และ Internet Explorer
        สามารถรองรับเทคโนโลยีนี้ได้
      </li>
      <li>
        ซอฟต์แวร์สแกนไวรัสและมัลแวร์ (Virus and Malware Scan): บริษัทฯ มีการติด
        ตั้งซอฟต์แวร์สแกนไวรัสและมัลแวร์และอัปเดตเป็นระยะๆ ในคอมพิวเตอร์และ
        เซิร์ฟเวอร์ทั้งหมดของบริษัทฯ
      </li>
    </div>
  );
};

const PeriodCollectingData = () => {
  return (
    <div className="text-indent-home">
      <li>
        บริษัทฯ จะเก็บข้อมูลของท่านนานตราบเท่าที่จําเป็น
        โดยคํานึงถึงความจําเป็นและวัตถุประสงค์ที่บริษัทฯ จะต้องรวบรวมและประมวลผล
        ซึ่งรวมถึงการปฏิบัติตามข้อ กําหนดของกฎหมายที่ใช้บังคับ
      </li>
      <li>
        บริษัทฯ จะยังเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่านต่อไป แม้ว่าท่านจะ
        ยุติความสัมพันธ์กับบริษัทฯ เท่าที่จําเป็นตามข้อกําหนดของกฎหมาย เพื่อประโยชน์โดย ชอบตามกฎหมาย
        หรือทําการเก็บในรูปแบบที่ทําให้ระบุตัวตนตัวบุคคลไม่ได้ ไม่ว่าทาง ตรงหรือทางอ้อม เช่น
        เทคนิคการทํา “แอนโนนมัส" (Anonymous)
      </li>
      <li>
        ในส่วนของสัญญาประกันกันภัย บริษัทฯ อาจเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้
        นานเท่าที่จําเป็นต้องเก็บเพื่อการดําเนินการให้บรรลุตามวัตถุประสงค์ในการประมวลผล
        ข้อมูลส่วนบุคคลของท่านตามที่ระบุข้างต้น โดยบริษัทฯ จะเก็บรักษาข้อมูลส่วนบุคคล ของท่านไม่เกิน
        12 ปี นับแต่วันที่ท่านสิ้นสุดความสัมพันธ์ หรือ การติดต่อครั้งสุดท้าย กับบริษัทฯ โดยบริษัทฯ
        อาจเก็บรักษาข้อมูลส่วนบุคคลของท่านนานกว่าที่กําหนดหาก กฎหมายอนุญาต
      </li>
      <li>
        เพื่อให้สอดคล้องกับระยะเวลาและอายุความตามกฎหมายที่เกี่ยวข้อง โดยบริษัทฯ จะ
        ทําการจัดเก็บข้อมูลส่วนบุคคลของท่านไว้ในรูปแบบที่เหมาะสมตามประเภทของข้อมูล ส่วนบุคคล
        อย่างไรก็ตาม บริษัทฯ มีความจําเป็นต้องเก็บข้อมูลส่วนบุคคลของท่านต่อ
        ไปแม้จะพ้นกําหนดอายุความตามกฎหมายแล้ว หรือกรณีอยู่ระหว่างการดําเนินคดีตาม กฎหมาย เป็นต้น
      </li>
      <li>
        บริษัทฯจะมีการดําเนินการในขั้นตอนอันเหมาะสม เพื่อทําการลบหรือทําลาย หรือทําให้
        ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวท่าน ตามระยะเวลาเก็บรักษา
        ข้อมูลส่วนบุคคลข้างต้น
      </li>
    </div>
  );
};

const DisclosureData = () => {
  return (
    <div className="text-indent-home">
      <li>การปฏิบัติเพื่อให้สอดคล้องกับกฎหมายที่ใช้บังคับ</li>
      <li>
        การปฏิบัติตามคําร้องขอจากหน่วยงานรัฐบาลหรือหน่วยงานของรัฐ
        หรือเพื่อการประสานงานกับหน่วยงานต่างๆ ในเรื่องที่เกี่ยวข้องกับการปฏิบัติตามกฎหมาย
      </li>
      <li>การตอบสนองต่อความปลอดภัยของบุคคลหรือสาธารณะ</li>
      <li>
        การพิจารณาคดี การดําเนินคดี การสืบสวน สอบสวน และเรื่องทางกฎหมายอื่นๆ
        ที่มีข้อมูลส่วนบุคคลเข้ามาเกี่ยวข้อง
      </li>
      <li>การตรวจสอบเหตุการณ์ด้านความปลอดภัย</li>
      <li>การบังคับใช้ข้อกําหนดในการให้บริการของบริษัทฯ</li>
    </div>
  );
};

const ProtectionData = () => {
  return (
    <div className="text-indent-home">
      <li>
        สิทธิในการขอเข้าถึงและขอรับสําเนาข้อมูลส่วนบุคคลซึ่งอยู่ในความรับผิดชอบของบริษัทฯ
        ซึ่งจะมีการกําหนดขั้นตอนที่เหมาะสมเพื่อยืนยันตัวตนจากท่านก่อน
      </li>
      <li>
        สิทธิในการขอให้บริษัทฯ
        ดําเนินการปรับปรุงหรือแก้ไขข้อมูลส่วนบุคคลของท่านเพื่อให้มีความถูกต้อง เป็นปัจจุบัน สมบูรณ์
        และไม่ก่อให้เกิดความเข้าใจผิด
      </li>
      <li>สิทธิในการขอให้จํากัด/ระงับการใช้ และ/หรือ การประมวลผลข้อมูลส่วนบุคคลเป็นการ ชั่วคราว</li>
      <li>
        สิทธิในการคัดค้านการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับท่านรวมถึงสิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล
      </li>
      <li>
        สิทธิในการขอให้ดําเนินการลบหรือทําลาย หรือทําให้ข้อมูลส่วนบุคคลของท่านอยู่ในรูป
        แบบที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้
      </li>
      <li>
        สิทธิในการถอนความยินยอม หรือร้องขอให้เปลี่ยนแปลงขอบเขตความยินยอมของ ท่านที่เคยให้แก่บริษัทฯ
        ในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ทั้งนี้
        การถอนความยินยอมจะไม่ส่งผลกระทบต่อการเก็บรวบรวม ใช้ หรือเปิดเผย
        ข้อมูลส่วนบุคคลของท่านที่ได้ให้ความยินยอมไว้แล้ว
      </li>
      <li>
        สิทธิในการขอทราบความมีอยู่ ลักษณะของข้อมูลส่วนบุคคล
        และวัตถุประสงค์ของการนําข้อมูลส่วนบุคคลไปใช้ของบริษัทฯ
      </li>
      <li>
        สิทธิในการขอให้บริษัทฯ ส่งหรือโอนข้อมูลส่วนบุคคลไปยังท่าน
        หรือบุคคลหรือองค์กรอื่นตามที่ท่านกําหนด
      </li>
      <li>
        สิทธิในการขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลที่เกี่ยวกับท่านในกรณีที่เป็นข้อมูลซึ่งท่านไม่ได้ให้ความยินยอมในการรวบรวมหรือจัดเก็บ
      </li>
    </div>
  );
};

const UsingCookie = () => {
  return (
    <div className="text-indent-home">
      <li>
        ตามวัตถุประสงค์ บริษัทฯ ใช้คุกกี้เพื่อจะได้จดจําท่านบนหน้าเว็บไซด์ของบริษัทฯ
        และจดจําสิ่งที่ท่านตั้งค่าไว้ก่อนหน้า รวมถึงภาษาที่ท่านใช้และพื้นที่ที่ท่านอยู่ และมีการ
        ใช้คุกกี้ของบุคคลที่หนึ่งและบุคคลที่สามร่วมกัน
      </li>
      <li>
        เพื่อการโฆษณา บริษัทฯ ใช้คุกกี้เพื่อเก็บข้อมูลเกี่ยวกับหน้าเว็บไซต์ของเราที่ท่าน เยี่ยมชม
        เนื้อหาที่ท่านดู ลิงค์ที่กด และข้อมูลบราวเซอร์ อุปกรณ์ และ IP (Internet Protocol) Address
        ของท่าน บางครั้งบริษัทฯ ก็แบ่งปันข้อมูลแก่บุคคลที่สามเพื่อ วัตถุประสงค์ทางการโฆษณา
        บริษัทฯอาจจะแบ่งปันข้อมูลออนไลน์ที่รวบรวมผ่านคุกกี้ กับพันธมิตรด้านการโฆษณาของบริษัทฯ
        ซึ่งหมายความว่าเมื่อท่านเยี่ยมชมหน้า เว็บไซต์อื่น
        ท่านอาจเห็นโฆษณาตามรูปแบบการเรียกดูของท่านบนหน้าเว็บไซด์ของบ ริษัทฯ ทั้งนี้บริษัทฯ
        จะเปิดเผยตามสิทธิในสัญญาหรือตามกฎหมายเท่านั้น
      </li>
      <li>
        ช่วยให้บริษัทฯ จดจําความต้องการของท่านตามกิจกรรมในเว็บไซต์ก่อนหน้าหรือ
        ปัจจุบันซึ่งช่วยให้บริษัทฯ สามารถจัดหาบริการที่ดีขึ้นและเหมาะสําหรับการเข้าชมของ ท่านในอนาคต
      </li>
      <li>ช่วยให้บริษัทฯ สามารถวิเคราะห์ประสิทธิภาพของโฆษณา และวิเคราะห์การใช้งาน เว็บไซต์ได้</li>
      <li>
        รวบรวมข้อมูลโดยรวมเกี่ยวกับการเข้าชมเว็บไซต์และการโต้ตอบในเว็บไซต์เพื่อมอบ
        ประสบการณ์และเครื่องมือในเว็บไซต์ที่ดีขึ้นในอนาคต
      </li>
      <li>เพื่อความปลอดภัยและป้องกันการเข้าใช้บัญชีของท่านจากผู้ไม่ได้รับอนุญาต</li>
    </div>
  );
};

const Policy = () => {
  return (
    <div className="pt-20 page">
      <p className="text-lg font-bold">นโยบายความเป็นส่วนตัว</p>
      <p className="text-justify text-indent-home">
        บริษัท เมืองไทย โบรกเกอร์ จํากัด และ บริษัทในเครือ หมายรวมถึง บริษัทหรือองค์กรอื่นใดที่
        ควบคุม ถูกควบคุม หรืออยู่ภายใต้การควบคุมของบริษัทฯ ซึ่งอาจมีผู้ถือหุ้นหรือกรรมการ
        ส่วนใหญ่เป็นชุดเดียวกัน (ซึ่งต่อไปนี้รวมเรียกว่า “MTB" "บริษัทฯ” หรือ “เรา”) ตระหนักดีถึง
        สิทธิในความเป็นส่วนตัวและความรับผิดชอบของ MTB เกี่ยวกับเก็บรวบรวม ใช้ เปิดเผย
        ข้อมูลส่วนบุคคลของท่าน และ/หรือการส่ง การโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคลอื่น
      </p>
      <p className="text-justify text-indent-home mt-4">
        MTB ยึดค่านิยมที่ถือว่า ความไว้วางใจและความเชื่อมั่นที่ท่านมีให้แก่เรา
        เป็นสิ่งสําคัญที่สุดสิ่ง หนึ่งสําหรับเรา MTB
        จึงมุ่งมั่นที่จะดําเนินการให้มีการประมวลผลและคุ้มครองข้อมูลส่วน
        บุคคลของท่านอย่างถูกต้องและเหมาะสม ดังนั้น การสร้างความเข้าใจแก่ท่านในเรื่องของวิธี การ
        ขั้นตอนในการเก็บรวบรวม การใช้ การประมวลผล และการเปิดเผยข้อมูลส่วนบุคคลจึง
        เป็นอีกหนึ่งเรื่องที่สําคัญสําหรับ MTB
      </p>
      <p className="text-lg font-bold mt-4">นโยบายความเป็นส่วนตัวของ MTB ครอบคลุมถึง</p>
      <MTBPrivacyPolictCovers />
      <p className="text-lg font-bold mt-4">ข้อมูลส่วนบุคคลที่เก็บรวบรวม</p>
      <p className="text-justify text-indent-home">
        ประเภทของข้อมูลส่วนบุคคลที่ MTB เก็บรวบรวม การนํามาใช้ ประมวลผล หรือเปิดเผย โดย MTB
        จะขึ้นอยู่กับวัตถุประสงค์ของการให้ข้อมูลนั้นของท่าน ทั้งนี้ MTB มีความมุ่งมั่นที่จะ
        เก็บรวบรวมข้อมูลส่วนบุคคลเพียงเท่าที่จําเป็นหรือเกี่ยวข้องกับการเข้าทําสัญญา และ/หรือ
        การรับบริการ รวมถึงบริการเสริมจาก MTB เท่านั้น
      </p>
      <p className="mt-4">
        MTB เก็บรวบรวมข้อมูลส่วนบุคคลเมื่อท่านสมัครเพื่อเข้าใช้บริการ เมื่อท่านซื้อผลิตภัณฑ์ของ MTB
        หรือเมื่อท่านได้รับการบริการหรือได้ใช้บริการใดจาก MTB อันได้แก่ ข้อมูลดังต่อไปนี้
      </p>
      <CollectionPersonalData />
      <p className="text-lg font-bold mt-4">
        วิธีการเก็บรวบรวมหรือการได้มาซึ่งข้อมูลส่วนบุคคลของท่าน
      </p>
      <p>MTB มีการจัดเก็บ รวบรวมข้อมูลส่วนบุคคลของท่านด้วยกระบวนการดังต่อไปนี้</p>
      <CollectingOrObtains />
      <p className="text-lg font-bold mt-4">วัตถุประสงค์ในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล</p>
      <p className="text-justify">
        MTB ใช้ข้อมูลส่วนบุคคลที่ท่านให้ไว้เมื่อท่านเข้าทําประกันผ่านบริษัทฯ การเข้าเยี่ยมชมหรือใช้
        บริการของบริษัทฯ เพื่อตอบสนองวัตถุประสงค์ที่ท่านให้ข้อมูลและเพื่อส่งเสริมการเพิ่ม
        ประสบการณ์ที่ดีระหว่างบริษัทฯ และท่าน บริษัทฯ ใช้ข้อมูลส่วนบุคคลของท่านเพื่อ
        วัตถุประสงค์ดังต่อไปนี้
      </p>
      <PurposeCollectingAndUsing />
      <p className="text-lg font-bold mt-4">วิธีการป้องกันข้อมูลส่วนบุคคลของท่าน</p>
      <p className="text-justify text-indent-home">
        การปกป้องความเป็นส่วนตัวของท่านถือเป็นสิ่งสําคัญอย่างยิ่งสําหรับบริษัทฯ บริษัทฯ มีนโย
        บายและกระบวนการรักษาความมั่นคงปลอดภัยของข้อมูล และบริษัทฯ จํากัดสิทธิการเข้าถึง
        ข้อมูลส่วนบุคคลของท่านให้กับเฉพาะบุคคลที่จําเป็นต้องใช้ข้อมูลของท่านในการจัดหา
        ผลิตภัณฑ์และเพื่อการให้บริการของบริษัทฯ เช่น พนักงาน หรือตัวแทนของบริษัทฯ ซึ่งบคคล
        ที่บริษัทฯ อนุญาตให้เข้าถึงข้อมูลส่วนบุคคลของท่านนั้น จะต้องยึดมั่นและทําการปกป้อง
        ตลอดจนและรักษาความลับของข้อมูลส่วนบุคคลดังกล่าว บริษัทฯ มีมาตรการการป้องกันทั้ง
        ทางกายภาwและทางอิเล็กทรอนิกส์เพื่อให้เป็นไปตามมาตรฐานการกํากับดูแล ที่บังคับใช้เพื่อ
        ปกป้องข้อมูลส่วนบุคคลของท่าน
      </p>
      <p className="text-justify text-indent-home mt-4">
        ในกรณีที่บริษัทฯ มีการเข้าทําสัญญากับบุคคลที่สาม บริษัทฯ จะกําหนดมาตรการรักษาความ
        ปลอดภัยข้อมูลส่วนบุคคล การรักษาข้อมูลที่เป็นความลับที่เหมาะสม ทั้งนี้ เพื่อให้มั่นใจว่า
        ข้อมูลส่วนบุคคลของท่านจะได้รับความปลอดภัย
      </p>
      <p className="text-lg font-bold mt-4">
        เทคโนโลยีที่ใช้สําหรับการรักษาความปลอดภัยออนไลน์และเครือข่าย
      </p>
      <p className="text-justify text-indent-home">
        บริษัทฯ นํามาตรการด้านความปลอดภัยต่างๆที่หลากหลายมาใช้ด้วยความพยายามอย่างดี
        ที่สุดเพื่อให้แน่ใจ ว่าข้อมูลส่วนบุคคลของท่านที่อยู่ในระบบของบริษัทฯ นั้น มีความปลอดภัย
        ข้อมูลส่วนบุคคลของท่านจะถูก เก็บไว้ในเครือข่ายที่มีความปลอดภัยและสามารถเข้าถึงได้โดย
        wนักงานจํานวนจํากัดเท่านั้นซึ่งจะเป็นบุคคลที่มีสิทธิพิเศษในการเข้าถึงระบบดังกล่าว
        นอกจากมาตรการและวิธีการรักษาความปลอดภัยที่กล่าวข้างต้นแล้วบริษัทฯยังใช้เทคโนโลยี ระดับสูง
        ดังต่อไปนี้เพื่อปกป้องข้อมูลส่วนบุคคลของท่าน
      </p>
      <TechnologiesUsedOnline />
      <p className="text-lg font-bold mt-4">ระยะเวลาในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน</p>
      <PeriodCollectingData />
      <p className="text-lg font-bold mt-4">การเปิดเผยและการโอนข้อมูลส่วนบุคคลของท่าน</p>
      <p className="text-justify text-indent-home">
        บริษัทฯ จะรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นความลับ แต่ทั้งนี้ ในกรณีที่กฎหมายที่ใช้
        บังคับอนุญาตให้กระทําได้ หรือกรณีที่การเปิดเผยดังกล่าวมีความจําเป็นเพื่อให้สามารถบรรลุ
        ตามวัตถุประสงค์ของการประมวลผลข้อมูลส่วนบุคคล หรือวัตถุประสงค์ที่เกี่ยวข้องตามที่ระบุ ข้างต้น
        บริษัทฯ อาจให้ข้อมูลส่วนบุคคลของท่านแก่บุคคลต่าง ๆ ดังกรณีต่อไปนี้
      </p>
      <p className="text-lg font-bold mt-4">การเปิดเผยข้อมูลส่วนบุคคลระหว่างบริษัทฯ เอง</p>
      <p className="text-justify text-indent-home">
        บริษัทหนึ่งอาจเปิดเผยข้อมูลส่วนบุคคลของท่านกับอีกบริษัทหนึ่ง ทั้งนี้ เพื่อให้แน่ใจว่าการใช้
        บริการของท่านจะเป็นประโยชน์และเป็นประโยชน์ที่สุดเท่าที่จะเป็นไปได้ เพื่อสนับสนุนการดําเนิน
        ธุรกิจของบริษัทฯ ในการให้บริการแก่ท่าน และเพื่อวัตถุประสงค์อื่นใดตามที่ได้อธิบายไว้ใน
        นโยบายความเป็นส่วนตัวนี้ บริษัทในเครือดังกล่าว จะดําเนินการกับข้อมูลส่วนบุคคลของท่าน
        อย่างเป็นอิสระ หรือในนามของบริษัทฯตามนโยบายความเป็นส่วนตัวนี้ รวมถึงกฎหมายที่ เกี่ยวข้อง
      </p>
      <p className="text-lg font-bold mt-4">
        การเปิดเผยข้อมูลส่วนบุคคลระหว่างบริษัทฯ กับพันธมิตรทางธุรกิจ หรือบุคคลที่สาม
      </p>
      <p className="text-justify text-indent-home">
        บริษัทฯ มีการทํางานร่วมกับพันธมิตรทางธุรกิจหรือบุคคลที่สามที่ให้บริการในด้านต่างๆ แก่
        บริษัทฯ เช่น การวิเคราะห์ข้อมูล การประมวลผลข้อมูล การดําเนินการด้านบัตรเครดิต การให้
        บริการด้านเทคโนโลยีสารสนเทศและการจัดเตรียมโครงสร้างพื้นฐานที่เกี่ยวข้อง การพัฒนา
        แwลตฟอร์มบริการลูกค้า การส่งอีเมล/SMS การพัฒนาเว็บไซต์ การพัฒนาแอปพลิเคชันบน มือถือ
        การรับประกันต่อ การสํารวจความพึงพอใจ และการทําวิจัย และการบริหารความ สัมพันธ์ลูกค้า บริษัทฯ
        จะเปิดเผยข้อมูลส่วนบุคคลของท่านกับพันธมิตรทางธุรกิจหรือบุคคลที่
        สามเพื่อให้บุคคลดังกล่าวสามารถช่วยบริษัทฯ ให้บริการ/ส่งมอบการบริการของบริษัทฯ ตาม
        วัตถุประสงค์ที่ระบุไว้ในส่วน "วัตถุประสงค์ในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล" ข้างต้น
        และจะเปิดเผยแก่บุคคลใดก็ตามที่ได้รับอนุญาตให้เป็นคนกลางประกัน ตัวแทน นายหน้า สถาบันการเงิน
        ที่ปรึกษาทางการเงิน ผู้วางแผนการลงทุน และ/หรือผู้แนะนําการลงทุน (
        หมายความรวมถึงบุคคลที่ได้รับการว่าจ้าง หรือ เข้าทําสัญญาเพื่อปฏิบัติงานให้แก่บุคลากร
        และคู่ค้าของบริษัทฯดังกล่าว) เพื่อทําการเชิญชวน ชักชวน ชี้ช่อง จัดการให้ทํา เสนอขาย ขาย
        จัดจําหน่าย หรือให้บริการเกี่ยวกับผลิตภัณฑ์ และ/หรือบริการที่เสนอโดยบริษัทฯ หรือ
        บริษัทในเครือ หรือในกลุ่มบริษัทให้แก่ท่าน รวมถึง ส่งมอบผลิตภัณฑ์และ/ หรือบริการของเรา
        ที่มีการเสนอ/ขายให้แก่ท่าน เช่น ผู้ถือกรมธรรม์ ในกรณีของผลิตภัณฑ์ประกันแบบกลุ่ม
        บริษัทประกันต่อ บริษัทจัดการการลงทุน สถาบันจัดอันดับความน่าเชื่อถือ บริษัทต่าง ๆ ซึ่ง
        ทําการสืบสวน หรือคู่ค้ารายอื่น ๆ ในการเปิดเผยข้อมูลส่วนบุคคลดังกล่าว บริษัทฯ
        ดําเนินการเพื่อให้มั่นใจว่าข้อมูลส่วนบุคคล
        ของท่านจะได้รับความคุ้มครองและพันธมิตรทางธุรกิจหรือบุคคลที่สามที่บริษัทฯ เปิดเผย
        ข้อมูลจะดําเนินการตามที่บริษัทฯ กําหนด และจะไม่ใช้เพื่อวัตถุประสงค์หรือผลประโยชน์ของ
        บุคคลที่สามนั้นเอง มาตรการดังกล่าวอาจรวมถึงการทําสัญญากับพันธมิตรทางธุรกิจและ
        บุคคลที่สามตามที่บริษัทฯ เห็นสมควร ในกรณีที่ท่านให้ความยินยอม บริษัทฯ
        จะเปิดเผยข้อมูลของท่านกับบริษัทที่เป็นพันธมิตร (partners) หรือบุคคลอื่น เพื่อที่บริษัทฯ
        จะได้เสนอผลิตภัณฑ์หรือบริการไปยังท่าน บริษัทฯ
        จะเปิดเผยข้อมูลของท่านไปยังบริษัทที่เป็นพันธมิตรของบริษัทฯ หรือบุคคลอื่น ซึ่งท่าน
        สามารถตรวจสอบรายชื่อได้จาก gettgo.com ทั้งนี้ บุคคลอื่น หรือบริษัทฯ ที่เป็นพันธมิตร
        ของบริษัทฯ นั้น อาจมีการเพิ่มขึ้นหรือลดลงได้ ซึ่งบริษัทฯ จะทําการปรับปรุงข้อมูลให้เป็น
        ปัจจุบันเสมอ
      </p>
      <p className="text-lg font-bold mt-4">การเปิดเผยข้อมูลส่วนบุคคลในกรณีอื่น</p>
      <p className="text-justify text-indent-home">
        บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่านในกรณีจําเป็นอื่นๆ ซึ่งรวมถึง:
      </p>
      <DisclosureData />
      <p className="text-lg font-bold mt-4">
        การโอน ถ่าย และ/หรือส่งข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ
      </p>
      <p className="text-justify text-indent-home">
        ข้อมูลส่วนบุคคลของท่านอาจถูกโอน ถ่าย ถูกอัดเก็บไว้ หรือถูกประมวลผลโดยบริษัทฯ หรือ
        อาจถูกส่งให้แก่บุคคลหรือหน่วยงานใด ๆ ตามรายละเอียดข้างต้น ซึ่งอาจมีที่ตั้งหรืออาจให้
        บริการอยู่ในประเทศไทยหรือนอกประเทศไทย ทั้งนี้ บริษัทฯ จะกําหนดมาตรฐานในการทําข้อ ตกลง
        และ/หรือ สัญญาร่วมธุรกิจกับหน่วยงาน องค์กรที่จะได้รับข้อมูลส่วนบุคคลนั้น ใน
        มาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เป็นที่ยอมรับ และสอดคล้องกับกฎหมายที่เกี่ยวข้อง
        เพื่อให้มั่นใจว่าข้อมูลส่วนบุคคลของท่าน จะได้รับการคุ้มครองอย่างปลอดภัย
      </p>
      <p className="text-lg font-bold mt-4">
        กรณีที่บริษัทฯจะโอน ถ่าย และ/หรือ ส่งข้อมูลไปยังต่างประเทศ เช่น
      </p>
      <p className="text-justify text-indent-home">
        บริษัทฯ จะโอน ถ่าย และ/หรือ ส่งข้อมูลไปยังต่างประเทศต่อเมื่อปรากฏว่าผู้รับข้อมูลส่วน บุคคล
        มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอ เช่น การประมวลผลในระบบคลา วด์ (Cloud)
        โดยองค์กรที่มีมาตรฐานความมั่นคง จัดเก็บข้อมูลส่วนบุคคลในรูปแบบการเข้า รหัส หรือวิธีการอื่น ๆ
        ที่ไม่สามารถระบุตัวตนของเจ้าของข้อมูลส่วนบุคคลได้ และรูปแบบนั้นมี ความปลอดภัยระดับสากล
      </p>
      <p className="text-lg font-bold mt-4">การใช้ข้อมูลส่วนบุคคลที่มีความอ่อนไหวเป็นพิเศษ</p>
      <p className="text-justify text-indent-home">
        ในบางกรณี การเสนอขายผลิตภัณฑ์หรือบริการของบริษัทฯ ตลอดจนการดําเนินการตาม
        วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคลของบริษัทฯ บริษัทฯ มีความจําเป็นต้องเก็บ รวบรวม
        ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่มีความอ่อนไหวเป็นพิเศษ เช่น ข้อมูลส่วนบุคคลที่
        เกี่ยวกับเชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา
        พฤติกรรมทางเพศ ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ เป็นต้น
        ซึ่งในกรณีเช่นว่าบริษัทฯ จะขอความยินยอมจากท่านเพื่อเก็บรวบรวม ใช้ หรือเปิดเผย
        ข้อมูลที่มีความอ่อนไหวเป็นพิเศษนั้น บริษัทฯ จะแจ้งให้ท่านทราบโดยชัดแจ้งเพื่อดําเนินการ
        ตามวัตถุประสงค์ในการนั้นต่อไป เว้นแต่เป็นการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลที่มีความ
        อ่อนไหวเป็นพิเศษ ที่ได้รับยกเว้นไม่ต้องได้รับความยินยอมตามกฎหมาย
      </p>
      <p className="text-lg font-bold mt-4">การใช้ข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ทางการตลาด</p>
      <p className="text-justify text-indent-home">
        นอกจากวัตถุประสงค์ดังกล่าวข้างต้นและภายใต้ข้อกําหนดของกฎหมาย บริษัทฯ อาจจะใช้ชื่อ-นามสกุล
        และข้อมูลการติดต่อของท่าน เพื่อวัตถุประสงค์ทางการตลาด เช่น การส่งเนื้อหา ( ทางไปรษณีย์ อีเมล
        โทรศัwท์ และ/หรือด้วยวิธีการอื่นๆ) เพื่อเพิ่มสิทธิประโยชน์ที่ท่านจะได้รับ
        จากการเป็นลูกค้าของบริษัทฯ ผ่านการแนะนําของผลิตภัณฑ์และบริการที่เกี่ยวข้อง การ ประชาสัมพันธ์
        การส่งเสริมการขายและการสื่อสารการตลาดทางตรงเกี่ยวกับผลิตภัณฑ์และ/ หรือบริการของเรา
        ข้อมูลทางการตลาด คําแนะนําและข้อมูลในเรื่องต่างๆ ซึ่งรวมถึงการ ประกัน และ เงินบํานาญ
        ตลอดจนการบริหารความมั่งคั่ง การลงทุน การSนาคาร บริการ ทางการเงิน
        การรักษาทางการแพทย์/การรักษาเกี่ยวกับสุขภาพ ข้อมูลเกี่ยวกับสุขภาพ การ อ้างงาน การฝึกอบรม
        โปรแกรมให้รางวัล/ให้ผลประโยชน์สําหรับการเป็นลูกค้าที่มีความภักดี ต่อแบรนด์/ให้สิทธิพิเศษ
        กิจกรรมการกุศล การจัดกิจกรรมทางการตลาด การประกวด จับ ฉลากชิงโชค งานอีเว้นท์
        และการแข่งขันต่าง ๆ ซึ่งท่านเลือกที่จะเข้าร่วม โดยในการดําเนินการ ดังกล่าวนี้
      </p>
      <p className="mt-4 text-justify text-indent-home">
        บริษัทฯ อาจต้องเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคลากรและพันธมิตรทางธุรกิจของ บริษัทฯ
        เพื่อการดําเนินการใดๆ เพื่อให้บริษัทฯบรรลุวัตถุประสงค์นี้ โดยรายละเอียดเกี่ยวกับการ
        ประมวลผลข้อมูลส่วนบุคคลของท่านจะเป็นไปตามนโยบายฉบับนี้
      </p>
      <p className="mt-4 text-justify text-indent-home">
        ท่านสามารถเลือกที่จะไม่รับการสื่อสารการตลาดจากบริษัทฯได้ อนึ่งการได้รับการติดต่อ
        สื่อสารที่เกี่ยวข้องกับการให้บริการเกี่ยวกับกรมธรรม์ ผลิตภัณฑ์และ/หรือบริการเสริมที่ บริษัทฯ
        มีให้แก่ท่าน เช่น การนําส่งกรมธรรม์ การแจ้งเตือนการชําระเบี้ยประกัน การนําส่งใบ
        เสร็จรับเงินเบี้ยประกัน ไม่ใช่การสื่อสารการตลาด
      </p>
      <p className="mt-4 text-justify text-indent-home">
        <span className="font-bold">หากท่านประสงค์ที่จะไม่รับการสื่อสารการตลาดจากบริษัทฯ</span>{" "}
        ท่านสามารถติดต่อ dpo-office@gettgo.com หรือ หากท่านไม่ต้องการรับ
        “เฉพาะการสื่อสารอีเมลที่เกี่ยวข้อง กับการตลาด" จากบริษัทฯ อีก
        ท่านสามารถเลือกการเลิกรับอีเมลที่เกี่ยวกับการตลาดเหล่านี้ได้
        โดยคลิกที่ลิงก์ยกเลิกการรับข้อมูล (นทรubscribe) ที่ด้านล่างของอีเมลแต่ละฉบับ บริษัทฯ
        จะพยายามปฏิบัติตามคําขอของท่านโดยเร็วที่สุดเท่าที่จะเป็นไปได้
      </p>
      <p className="text-lg font-bold mt-4">สิทธิ์ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลของท่าน</p>
      <ProtectionData />
      <p className="mt-4 text-justify text-indent-home">
        บริษัทฯ จะดําเนินการให้แล้วเสร็จภายใน 30 (สามสิบ) วันหลังจากที่ท่านมีคําขอดังกล่าวมาถึง
        บริษัทฯ หากท่านต้องการที่จะใช้สิทธิดังกล่าวข้างต้น กรุณาติดต่อมายังบริษัทฯ ที่
        dpo-office@gettgo.com
      </p>
      <p className="mt-4 text-justify text-indent-home">
        เท่าที่กฎหมายและกฎระเบียบที่ใช้บังคับอนุญาต บริษัทฯอาจมีสิทธิเรียกเก็บค่าใช้จ่ายที่สมเหตุ
        สมผล สําหรับการดําเนินการเกี่ยวกับคําขอใช้สิทธิข้างต้นอย่างไรก็ดี บริษัทฯ ขอสงวนสิทธิ์ไม่
        ปฏิบัติตามคําขอใช้สิทธิของท่านได้ ภายใต้เงื่อนไขตามที่กฎหมายกําหนด ทั้งนี้ หากบริษัทฯ ไม่
        สามารถดําเนินการตามคําขอของท่านได้ บริษัทฯ จะทําการบันทึกการปฏิเสธคําขอพร้อมด้วย เหตุผลไว้
      </p>
      <p className="mt-4 text-justify text-indent-home">
        หากท่านขอให้บริษัทฯ ลบ ทําลาย หรือทําให้ข้อมูลส่วนบุคคลของท่านอยู่ในรูปแบบ ที่ไม่
        สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้ หรือกรณีการถอนความยินยอมที่ท่าน เคยให้ไว้
        อาจส่งผลกระทบต่อ 1) กรณีการปฏิบัติตามสัญญา เช่น สัญญาประกัน หรือ สัญญาจ้างงาน 2)
        กรณีการให้บริการอื่นๆ เนื่องจากจะไม่สามารถระบุตัวตนของท่านได้ จึง อาจทําให้บริษัทฯ
        ไม่สามารถดําเนินการให้บริการในบางส่วนที่จําเป็นต้องใช้ข้อมูลส่วนบุคคล ของท่าน
      </p>
      <p className="text-lg font-bold mt-4">คุกกี้</p>
      <p className="text-justify text-indent-home">
        คุกกี้ (Cookies) คือ ข้อมูล text files บนคอมพิวเตอร์ที่เก็บรวบรวม standard internet log
        information และข้อมูลพฤติกรรมการเยี่ยมชมเว็บไซต์ของท่าน
      </p>
      <p className="mt-4 text-justify text-indent-home">
        คุกกี้ (Cookies) เป็นไฟล์ขนาดเล็กที่เว็บไซต์หรือผู้ให้บริการมีการส่งไปอัดเก็บในคอมพิวเตอร์
        ของท่านผ่านทางเว็บบราวเซอร์ (ถ้าท่านอนุญาต)
        ซึ่งช่วยให้ระบบของเว็บไซต์หรือผู้ให้บริการบราวเซอร์ของท่าน
        และสามารถจับและจดจําข้อมูลบางอย่าง รวมถึงข้อมูลพฤติกรรมการ เยี่ยมชมเว็บไซต์ของท่าน
        อย่างไรก็ตามรหัสในไฟล์คุกกี้จะทําให้เว็บไซต์รู้เพียงว่าว่าท่านเป็นผู้ ใช้โดยเฉพาะ
        แต่จะไม่สามารถระบุชื่อหรือที่อยู่หรือข้อมูลอื่นของท่านได้ เว้นแต่ท่านจะได้ให้
        ข้อมูลดังกล่าวแก่เว็บไซต์หรือตั้งค่ากําหนดในบราวเซอร์โดยอัตโนมัติ ซึ่งทําให้เมื่อท่านเยี่ยม
        ชมเว็บไซต์ของบริษัทฯ บริษัทฯจะเก็บข้อมูลของท่านผ่านทางคุกกี้หรือเทคโนโลยีอื่นที่คล้ายกัน
        โดยอัตโนมัติ
      </p>
      <p className="mt-4 text-justify text-indent-home">
        ท่านสามารถเลือกที่จะไม่ยอมรับคุกกี้โดยเปลี่ยนการตั้งค่าในบราวเซอร์ของท่าน ท่านสามารถ
        เลือกให้คอมพิวเตอร์แจ้งเตือนท่านในแต่ละครั้งที่มีการส่งคุกกี้หรือท่านสามารถเลือกตั้งค่าที่จะ
        ปิดคุกกี้ทั้งหมดได้ สําหรับข้อมูลเพิ่มเติม กรุณาดูใน www.aboutcookies.org
      </p>
      <p className="text-lg font-bold mt-4">บริษัทฯ ใช้คุกกี้อย่างไร</p>
      <p className="text-justify text-indent-home">
        บริษัทฯ ใช้คุกกี้ในหลากหลายแง่เพื่อใช้พัฒนาการให้บริการบนเว็บไซต์ของบริษัทฯ รวมทั้ง
        ทําความเข้าใจว่าท่านใช้เว็บไซต์ของบริษัทฯ อย่างไร
      </p>
      <p className="text-lg font-bold mt-4">บริษัทฯ ใช้คุกกี้เพื่ออะไรบ้าง </p>
      <p className="text-justify text-indent-home">
        มีคุกกี้อยู่หลายประเภท อย่างไรก็ตาม เว็บไซต์ของบริษัทฯ ใช้คุกกี้เพื่อวัตถุประสงค์ดังต่อไปนี้
      </p>
      <UsingCookie />
      <p className="text-lg font-bold mt-4">การตั้งค่าคุกกี้</p>
      <p className="text-justify text-indent-home">
        ท่านสามารถตั้งค่าบราวเซอร์ของท่านไม่ให้ยอมรับการใช้คุกกี้ได้ นอกจากนั้นเว็บไซต์ที่ระบุไปใน
        ข้างต้นยังบอกถึงวิธีนําคุกกี้ออกจากบราวเซอร์ของท่าน อย่างไรก็ตาม ในบางกรณี การตั้ง
        ค่าดังกล่าวอาจทําให้ไม่สามารถใช้บางฟีเจอร์บนหน้าเว็บไซต์ของบริษัทฯได้
      </p>
      <p className="mt-4 text-justify text-indent-home">
        หากท่านเลือกปฏิเสธที่จะใช้คุกกี้ที่บริษัทฯ ใช้สําหรับวัตถุประสงค์เพื่อการโฆษณา บริษัทฯ
        จะหยุดการใช้คุกกี้นั้น
        แต่ทั้งนี้ไม่ได้หมายความว่าท่านจะไม่เห็นโฆษณาเมื่อท่านเยี่ยมชมเว็บไซต์ของบริษัทฯ
        แต่หมายความเพียงว่าโฆษณาที่ท่านเห็นอาจจะไม่ตรงกับความสนใจของท่าน
      </p>
      <p className="text-lg font-bold mt-4">เว็บบีคอน</p>
      <p className="text-justify text-indent-home">
        เว็บบีคอน (web beacons) หรือรู้จักในชื่อ pixel tags หรือ clear gif หรือ transparent gif
        เป็นกราฟิกขนาด 1x1 พิกเซลที่มองไม่เห็นซึ่งอาจถูกวางอยู่บนหน้าเว็บไซต์สักหน้าของ MTB
        เว็บบีคอนมีการใช้เพื่อวัตถุประสงค์หลายประการ แต่ไม่จํากัดเพียงเพื่อการนับผู้เข้าชม เว็บไซต์
        เพื่อติดตามรูปแบบการเข้าชมของผู้เข้าชมขณะที่พวกเขาสํารวจเว็บไซต์ และเพื่อ
        ติดตามว่ามีการเปิดอีเมลที่ส่งหรือดูเนื้อหาหรือไม่ โดยทั่วไปเว็บบคอนจะถูกใช้งานร่วมกับคุกกี้
        เพื่อติดตามกิจกรรมการเข้าเยี่ยมชมในหน้าของอุปกรณ์นั้นๆ หากท่านปิดการใช้งานคุกกี้ เว็บ
        มีคอนจะยังคงตรวจจับการเข้าถึงเว็บไซต์แบบไม่ระบุตัวตนได้
      </p>
      <p className="text-lg font-bold mt-4">การใช้บริการวิเคราะห์ข้อมูลโดยบุคคลอื่น</p>
      <p className="text-justify text-indent-home">
        บริษัทฯ อาจใช้บริการวิเคราะห์ข้อมูลของบุคคลที่สาม ได้แก่ Google Analytics บนเว็บไซต์
        ของบริษัทฯ ซึ่งรวมถึงแต่ไม่จํากัดเฉพาะเพียงเว็บไซต์บริษัทฯ และหน้า Facebook ของบริษัทฯ
        Google Analytics ใช้เทคโนโลยีต่างๆ เช่น คุกกี้เพื่อช่วยวิเคราะห์และพัฒนา
        ประสบการณ์การเยี่ยมชมเว็บไซต์ ข้อมูลที่สร้างโดยคุกกี้เกี่ยวกับการใช้งานบนเว็บไซต์ MTB
        ของท่าน (เช่นที่อยู่ IP, URL ที่เข้าชม, วันที่และเวลาที่มีการดู) จะถูกส่งและจัดเก็บโดย
        Google บนเซิร์ฟเวอร์ของทาง Google ซึ่ง Google จะใช้ข้อมูลดังกล่าวในการติดตามและ
        จัดทํารายงานเกี่ยวกับกิจกรรมของเว็บไซต์และให้บริการอื่นๆ ที่เกี่ยวข้องกับการใช้งาน
        เว็บไซต์และการใช้อินเทอร์เน็ต Google สามารถถ่ายโอนข้อมูลนี้ไปยังบุคคลที่สามตามที่
        กฎหมายกําหนดหรือเมื่อบุคคลที่สามดังกล่าวประมวลผลข้อมูลในนามของ Google สําหรับ
        ข้อมูลเพิ่มเติมเกี่ยวกับนโยบายส่วนบุคคลของ Google เกี่ยวกับ Google Analytics โปรดดูที่
        http://www.google.com/analytics/learn/privacy.html ท่านสามารถเลือกไม่ใช้ Google Analytics
        โดยได้โดยใช้โปรแกรมเสริมของทาง Google ที่ https://tools.google.com/dlpage/gaoptout?hl+en=GB
      </p>
      <p className="text-lg font-bold mt-4">
        การเชื่อมโยงเว็บไซต์และการบริการอื่นๆ นโยบายความเป็นส่วนตัวบนเว็บไซต์อื่นๆ
      </p>
      <p className="text-justify text-indent-home">
        นโยบายความเป็นส่วนตัวของบริษัทฯ ใช้แค่กับเว็บไซต์ของบริษัทฯ เท่านั้น บางส่วนของ
        เว็บไซต์หรือแอปพลิเคชันของบริษัทฯ อาจมีลิงก์เชื่อมโยงไปยังเว็บไซต์หรือบริการของบุคคล ที่สาม
        เช่น เครือข่ายสังคม ซึ่งเว็บไซต์เหล่านั้นอาจไม่ได้ดําเนินการตามนโยบายความเป็นส่วน ตัวนี้
        บริษัทฯ ไม่มีความรับผิดชอบต่อเว็บไซต์และบริการของบุคคลที่สาม หรือแนวทางปฏิบัติ
        ด้านข้อมูลส่วนบุคคลของบุคคลที่สาม ท่านควรตรวจสอบประกาศเกี่ยวกับนโยบายความเป็น
        ส่วนตัวของเว็บไซต์หรือบริการเหล่านั้นเพื่อทําความเข้าใจเกี่ยวกับนโยบายในการรวบรวม การ ใช้
        การโอนย้าย และการเปิดเผยข้อมูลส่วนบุคคลอย่างละเอียด
      </p>
      <p className="text-lg font-bold mt-4">การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</p>
      <p className="text-justify text-indent-home">
        บริษัทฯ สงวนสิทธิ์ในการแก้ไขนโยบายความเป็นส่วนตัวนี้ได้ตลอดเวลา หรือปรับเปลี่ยน
        นโยบายฉบับนี้ เท่าที่กฎหมายอนุญาต และท่านสามารถดูแก้ไขนโยบายความเป็นส่วนตัวได้ที่
        gettgo.com/privacy-policy หากมีการเปลี่ยนแปลงในนโยบายความเป็นส่วนตัวของบริ ษัทฯ บริษัทฯ
        จะประกาศเกี่ยวกับการเปลี่ยนแปลงดังกล่าวในเว็บไซต์ของบริษัทฯ หรือแจ้ง
        ให้ท่านทราบผ่านทางอีเมลที่ระบุไว้ในบัญชีของท่าน เพื่อให้ท่านรับรู้ถึงข้อมูลที่บริษัทฯ เก็บ
        รวบรวม ใช้ และภายใต้สถานการณ์ใดที่บริษัทฯ อาจเปิดเผยข้อมูลดังกล่าว ท่านสามารถตรวจ
        สอบเวลาที่บริษัทฯ แก้ไขนโยบายความเป็นส่วนตัวนี้ได้โดยการอ้างจากวันที่แก้ไขล่าสุดได้ระบุที่
        ด้านล่างของนโยบายความเป็นส่วนตัวนี้ การเปลี่ยนแปลงในประกาศเกี่ยวกับข้อมูลส่วนบุคคล
        มีผลผูกพันกับท่านเมื่อท่านเข้าใช้งานเว็บไซต์หรือบริการของบริษัทฯ หลังจากที่มีการประกาศ
        เรื่องการเปลี่ยนแปลงแล้ว บริษัทฯขอให้ท่านตรวจสอบนโยบายความเป็นส่วนตัวของบริษัทฯ เป็นระยะๆ
        เพื่อทราบถึงกับหลักปฏิบัติเกี่ยวกับนโยบายความเป็นส่วนตัวของบริษัทฯ บริษัทฯ
        มีการทบทวนนโยบายความเป็นส่วนตัวอยู่ประจําและจะนําข้อมูลใหม่มาปรับปรุงบนหน้าเว็บไซต์
        นโยบายความเป็นส่วนตัวนี้ปรับปรุงครั้งล่าสุดเมื่อวันที่ 27 เมษายน 2563
      </p>
      <p className="text-lg font-bold mt-4">เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</p>
      <p className="text-justify text-indent-home">
        บริษัทฯ ได้แต่งตั้งเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลเพื่อตรวจสอบการดําเนินการที่เกี่ยวกับ
        การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลให้สอดคล้องกับพระราชบัญญัติคุ้มครอง
        ข้อมูลส่วนบุคคล พ.ศ. 2562 และนโยบาย ระเบียบ ประกาศ คําสั่ง ของบริษัทฯ
        รวมทั้งสานงานและให้ความร่วมมือกับสํานักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
      </p>
      <p className="mt-4 text-justify">
        ท่านสามารถติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลได้ตามข้อมูลการติดต่อได้ที่
        dpo-office@gettgo.com
      </p>
      <p className="text-lg font-bold mt-4">ช่องทางติดต่อบริษัทฯ</p>
      <p className="text-justify text-indent-home">
        หากท่านมีคําถามหรือข้อสงสัย เกี่ยวกับนโยบายความเป็นส่วนตัวนี้ หรือการจัดการดูแลข้อมูล
        ของท่าน ท่านสามารถติดต่อมายัง dpo-office@gettgo.com
      </p>
      <p className="text-lg font-bold mt-4">ช่องทางติดต่อหน่วยงานผู้มีอํานาจ</p>
      <p className="text-justify text-indent-home">
        หากท่านต้องการรายงานเรื่องร้องเรียน หรือหากท่านรู้สึกว่าบริษัทฯ ไม่ตอบข้อกังวลของท่าน
        ในลักษณะที่น่าพึงพอใจ ท่านมีสิทธิที่จะเสนอข้อร้องเรียนเกี่ยวกับการประมวลผลข้อมูลส่วน
        บุคคลของบริษัทฯ ต่อสํานักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล ตามขั้นตอนที่ กําหนดไว้ใน
        พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล
      </p>
    </div>
  );
};

export default Policy;
