import React from "react";

import Label from "/src/components/staff/Label";

const Insured = (props) => {
  const { data } = props;
  return (
    <div className="row">
      <div className="col-12">
        <Label
          title="ชื่อ-นามสกุล"
          value={`${data.title} ${data.firstname} ${data.lastname}`}
        />
      </div>
      <div className="col-md-6">
        <Label title="วัน/เดือน/ปีเกิด" value={data?.birthday ?? "-"} />
      </div>
      <div className="col-md-6">
        <Label title="เลขบัตรประชาชน" value={data?.idcard ?? "-"} />
      </div>
    </div>
  );
};

export default Insured;
