import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import queryString from "query-string";
import { Dialog, IconButton } from "@material-ui/core";
import { Publish, Close, Refresh } from "@mui/icons-material";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import useHttp from "../../../hooks/useHttp";
import DatePickerRange from "~src/components/datePickerRange/DatePickerRange";
import { dateFormat } from "~src/helpers/dateTime";
import { userStatusOptions } from "~src/helpers/options";

import { urlStringify, defaultValue } from "../../../helpers/helpers";
import { messages } from "../../../helpers/messages";

import { TextField, FormGroup } from "../../../components/form";
import SectionHeader from "../../../components/layout/staff/SectionHeader";
import SearchForm from "../../../components/staff/SearchForm";
import ErrorPage from "../../../components/errorPage";
import Preloading from "../../../components/loading/Preloading";
import DownloadModal from "../../../components/modal/download";
import AgentTable from "./table";

import useFetch from "../../../hooks/useFetch";

const BASE_URL = "/staffs/agent-list";
const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

interface UploadIFormInputs {
  agentsFile: string;
}

interface AgentListProps {
  location: any;
}

const AgentList: React.FC<AgentListProps> = ({ location }) => {
  const uploadForm = useForm<UploadIFormInputs>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const { post } = useHttp();

  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });
  const url = `/api/agents?${urlStringify({
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    ...queryParams,
  })}`;

  const [preLoading, setPreLoading] = useState(false);
  const [queryStr] = useState(queryParams);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataLists, setDataLists] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [emailDuplicatesList, setEmailDuplicatesList] = useState([]);
  const [usernameDuplicatesList, setUsernameDuplicatesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(queryParams.page ?? DEFAULT_PAGE);
  const [isError, setIsError] = useState(false);
  const [agentsFile, setAgentsFile] = useState(null);
  const [isUploadModalOpened, setIsUploadModalOpened] = useState(false);
  const [isResultModalOpened, setIsResultModalOpened] = useState(false);
  const [isDownloadModalOpened, setIsDownloadModalOpened] = useState(false);
  const [isDownloadModalDone, setIsDownloadModalDone] = useState(false);
  const [downloadModalMessage, setDownloadModalMessage] = useState("");
  const [downloadEmail, setDownloadEmail] = useState("");
  const [roleOptions, setRoleOptions] = useState([]);
  const [filterState, setFilterState] = useState({
    keyword: queryParams.keyword ?? "",
    role: [],
    status: [],
    dateFrom: queryParams.expire_date_from ?? "",
    dateTo: queryParams.expire_date_to ?? "",
  });
  const { response, error, isLoading } = useFetch(url);
  const roleFetch = useFetch(`/api/roles`);

  const downloadAgentsExcelFormId = "DownloadAgentsExcelForm";

  useEffect(() => {
    if (!isLoading && !roleFetch.isLoading) {
      const results = response?.content;
      const totalRecords = response?.total_count;
      const totalPages = response?.total_pages;
      const roleData = roleFetch.response.content;
      const roleOptions = roleData.map((role) => {
        return {
          label: role.name,
          value: role.code,
        };
      });

      setTotalRecords(totalRecords);
      setPageCount(totalPages);
      setDataLists(results);
      setRoleOptions(roleOptions);
      setIsError(false);
    }
    if (error) {
      setIsError(true);
    }
    const roleParams = queryParams.role;
    const statusParams = queryParams.status;
    const role = defaultValue(roleParams, roleOptions);
    const status = defaultValue(statusParams, userStatusOptions);
    setFilterState({
      ...filterState,
      role,
      status,
    });
  }, [
    response,
    error,
    isLoading,
    roleFetch.response,
    roleFetch.error,
    roleFetch.isLoading,
    pageCount,
    queryStr,
    activePage,
  ]);

  const getSearchParams = (): any => {
    const { keyword, role, status, dateFrom, dateTo } = filterState;
    return {
      keyword,
      role: role.map((s) => s.value),
      status: status.map((s) => s.value),
      expire_date_from: dateFrom,
      expire_date_to: dateTo,
      per_page: PER_PAGE,
      page: 1,
    };
  };

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterState({
      ...filterState,
      keyword: e.target.value,
    });
  };

  const handleAddAgent = (): void => {
    window.location.href = `/staffs/agent/add`;
  };

  const handleClickSearch = (): void => {
    const params = getSearchParams();
    const queries = urlStringify(params);

    window.location.href = `${BASE_URL}?${queries}`;
  };

  const handleKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") handleClickSearch();
  };

  const handleChangeStatus = (optionData: any): void => {
    const status = optionData ? [...optionData] : [];
    setFilterState({ ...filterState, status });
  };

  const handleChangeRole = (optionData: any): void => {
    const role = optionData ? [...optionData] : [];
    setFilterState({ ...filterState, role });
  };

  const handlePageClick = (e: any): void => {
    const selectedPage = parseInt(e.selected) + 1;
    const params = getSearchParams();
    params.page = selectedPage;
    const queries = urlStringify(params);
    window.location.href = `${BASE_URL}?${queries}`;
  };

  const handleOpenAgent = (agent: any): void => {
    window.location.href = `/staffs/agent/${agent.id}/details`;
  };

  const handleEditAgent = (agent: any): void => {
    window.location.href = `/staffs/agent/${agent.id}/edit`;
  };

  const handleOpenUploadModal = (): void => {
    setAgentsFile(null);
    setIsUploadModalOpened(!isUploadModalOpened);
  };

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAgentsFile(e.target.files[0]);
  };

  const handleUploadFile = (): void => {
    const data = getUpdateAgentsExcelData();
    setIsUploadModalOpened(false);
    setPreLoading(true);
    setUploadStatus("unknown");
    postUpdateAgents(data)
      .then((result) => {
        setPreLoading(false);
        const emailDuplicateSet = [...new Set(result.data.content.duplicate_email)];
        const usernameDuplicateSet = [...new Set(result.data.content.duplicate_username)];
        setUploadStatus(result.data.content.status);
        setEmailDuplicatesList(emailDuplicateSet);
        setUsernameDuplicatesList(usernameDuplicateSet);
        setIsResultModalOpened(true);
      })
      .catch(() => {
        setPreLoading(false);
        setIsResultModalOpened(true);
      });
  };

  const getUpdateAgentsExcelData = (): FormData => {
    const formData = new FormData();
    if (agentsFile !== undefined) {
      formData.append(`file`, agentsFile);
    }
    return formData;
  };

  const postUpdateAgents = (data: Object): any => {
    const url = `/api/agents/upload_user_excel`;
    return post(url, data);
  };

  const handleOpenDownloadModal = (): void => {
    setIsDownloadModalDone(false);
    setDownloadEmail("");
    setIsDownloadModalOpened(!isDownloadModalOpened);
  };

  const handleDownloadFile = (data): void => {
    const params = getSearchParams();
    data.append("keyword", params.keyword);
    data.append("expire_date_from", params.expire_date_from);
    data.append("expire_date_to", params.expire_date_to);

    params.role.forEach((role) => {
      data.append("role[]", role);
    });

    params.status.forEach((status) => {
      data.append("status[]", status);
    });

    setDownloadModalMessage("กำลังดำเนินการ...");
    setPreLoading(true);
    setIsDownloadModalDone(true);
    postDownloadAgents(data)
      .then(() => {
        setDownloadModalMessage("ดำเนินการเสร็จสิ้น");
        setPreLoading(false);
      })
      .catch(() => {
        setDownloadModalMessage("พบปัญหาระหว่างดำเนินการ กรุณาลองใหม่ในภายหลัง");
        setPreLoading(false);
      });
  };

  const postDownloadAgents = (data: Object): any => {
    const url = `/api/agents/export_user_excel`;
    return post(url, data);
  };

  const onDateChange = (dateFrom, dateTo) => {
    setFilterState({
      ...filterState,
      dateFrom: dateFormat(dateFrom),
      dateTo: dateFormat(dateTo),
    });
  };

  const resetValueCallback = () => {
    setFilterState({
      ...filterState,
      dateFrom: null,
      dateTo: null,
    });
  };

  return (
    <div>
      {isError ? (
        <ErrorPage />
      ) : (
        <div className="flex flex-col p-4">
          <SectionHeader
            className="w-full"
            title="รายชื่อ Hero"
            breadcrumbs={[{ url: "#", label: "รายชื่อตัวแทน", current: true }]}
            searchComponent={
              <SearchForm
                type="text"
                placeholder="ค้นหารหัส Hero, ชื่อ หรือ นามสกุล"
                onChange={handleChangeSearch}
                handleClick={handleClickSearch}
                handleKeyPress={handleKeyPressSearch}
                value={filterState.keyword}
              />
            }
          />
          <div className="h-5">
            {totalRecords > 0 && (
              <div className="flex justify-end mt-1 mb-2 text-1xs text-custom-gray-md">
                {queryStr?.search && (
                  <span className="mr-1">
                    คำค้นหา:
                    <span className="mx-1 font-bold">"{queryStr.search}"</span>
                    พบ
                  </span>
                )}
                {totalRecords < 20 ? (
                  <span>
                    ทั้งหมด
                    <span className="mx-1 font-bold">{totalRecords}</span>
                    รายการ
                  </span>
                ) : (
                  <span>รายการทั้งหมด 20+ รายการ</span>
                )}
              </div>
            )}
          </div>
          <div className="grid grid-cols-4 gap-x-2">
            <div>
              <div>ประเภท</div>
              <Select
                options={roleOptions}
                onChange={handleChangeRole}
                value={filterState.role}
                isMulti
              />
            </div>
            <div>
              <div>สถานะ</div>
              <Select
                options={userStatusOptions}
                onChange={handleChangeStatus}
                value={filterState.status}
                isMulti
              />
            </div>

            <div className="w-64">
              <label>วันที่หมดอายุบัตร</label>
              <DatePickerRange
                resetValueCallback={resetValueCallback}
                onDateChange={onDateChange}
                values={[filterState.dateFrom, filterState.dateTo]}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className="px-4 py-2 mt-3 text-base text-white transition duration-300 ease-in-out rounded-full shadow bg-secondary-color focus:outline-none hover:bg-gray-900"
              onClick={handleClickSearch}
            >
              ค้นหา
            </button>
          </div>
          <AgentTable
            className="mt-4"
            isLoading={isLoading}
            agents={dataLists}
            onDownloadExcel={handleOpenDownloadModal}
            onUploadExcel={handleOpenUploadModal}
            onOpenAgent={handleOpenAgent}
            onEditAgent={handleEditAgent}
            onAddAgent={handleAddAgent}
          />
          {pageCount > 1 && (
            <ReactPaginate
              previousLabel="Prev"
              nextLabel="Next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              initialPage={Number(currentPage) - 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="gm-paginate"
              subContainerClassName="pages pagination"
              activeClassName="active"
              disableInitialCallback
            />
          )}
        </div>
      )}
      <Dialog open={isUploadModalOpened} onClose={handleOpenUploadModal}>
        <div className="rounded-3xl">
          <div className="flex flex-row items-center justify-between w-full py-1 pl-4 text-lg text-white border-b bg-primary-color border-custom-gray-200">
            อัพโหลดรายชื่อ Hero
            <IconButton component="span" onClick={handleOpenUploadModal}>
              <Close />
            </IconButton>
          </div>
          <div className="px-4 py-6">
            <form
              autoComplete="off"
              onSubmit={uploadForm.handleSubmit(handleUploadFile)}
              encType="multipart/form-data"
            >
              <div>หมายเหตุ: รองรับชนิดของไฟล์เป็น .xlsx เท่านั้น</div>
              <div className="flex justify-center w-full mt-4">
                <FormGroup>
                  <TextField
                    type="file"
                    className="truncate bg-white hover:bg-custom-gray-sm"
                    id="file"
                    name="file"
                    inputRef={uploadForm.register({
                      ...messages.uploadFileRequired,
                      ...messages.allowExcelFileType,
                    })}
                    error={uploadForm.errors?.agentsFile?.message}
                    onChange={handleFileSelected}
                  />
                </FormGroup>
              </div>
              <div className="h-5 mt-2">
                {uploadForm.errors?.agentsFile && (
                  <div className="text-xs text-red-600">
                    {uploadForm.errors?.agentsFile?.message}
                  </div>
                )}
              </div>
              <div className="flex justify-center w-full mt-2">
                {agentsFile === null ? (
                  <button
                    className="px-4 py-2 font-bold text-white rounded-full shadow opacity-50 cursor-not-allowed bg-primary-color"
                    disabled
                  >
                    <Publish /> เพิ่มรายชื่อ Hero
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="px-4 py-2 font-bold text-white transition duration-300 ease-in-out rounded-full shadow bg-primary-color focus:outline-none hover:bg-primary-color-dark disabled:opacity-50"
                  >
                    <Publish /> เพิ่มรายชื่อ Hero
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Dialog>
      <Dialog open={isResultModalOpened} onClose={() => setIsResultModalOpened(false)}>
        <div className="rounded-3xl">
          <div className="flex flex-row items-center justify-between w-full py-1 pl-4 text-xl text-white border-b bg-primary-color border-custom-gray-200">
            อัพโหลดรายชื่อ Hero
            <IconButton component="span" onClick={() => setIsResultModalOpened(false)}>
              <Close />
            </IconButton>
          </div>
          <div className="px-4 py-6">
            {uploadStatus === "success" ? (
              <div className="text-lg">อัพโหลดรายชื่อเสร็จสิ้น</div>
            ) : (
              <>
                <div className="mb-2 text-lg">อัพโหลดรายชื่อผิดพลาด</div>
                {uploadStatus === "unknown" ? (
                  <div>การเชื่อมต่อมีปัญหา โปรดลองใหม่ในภายหลัง</div>
                ) : (
                  <div>โปรดตรวจสอบความถูกต้องของเอกสาร</div>
                )}
              </>
            )}
            {emailDuplicatesList?.length > 0 && (
              <>
                <div className="mt-3 mb-1">
                  ไม่สามารถเพิ่ม Hero ที่มี E-mail ดังนี้ได้ เนื่องจากมีถูกใช้งานไปแล้วในระบบ
                </div>
                <ul>
                  {emailDuplicatesList?.map((email) => (
                    <li className="ml-3" key={email}>
                      •<span className="ml-2">{email}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {usernameDuplicatesList?.length > 0 && (
              <>
                <div className="mt-3 mb-1">
                  ไม่สามารถเพิ่ม Hero ที่มี รหัสตัวแทน ดังนี้ได้ เนื่องจากมีถูกใช้งานไปแล้วในระบบ
                </div>
                <ul>
                  {usernameDuplicatesList?.map((username) => (
                    <li className="ml-3" key={username}>
                      •<span className="ml-2">{username}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}
            <div className="flex justify-center mt-6">
              <button
                className="px-4 py-2 font-bold text-white transition duration-300 ease-in-out rounded-full shadow bg-primary-color focus:outline-none hover:bg-primary-color-dark disabled:opacity-50"
                onClick={handleClickSearch}
              >
                <Refresh /> โหลดข้อมูลใหม่
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <DownloadModal
        id={downloadAgentsExcelFormId}
        title="ดาวน์โหลดรายชื่อ Hero"
        open={isDownloadModalOpened}
        isDownloadDone={isDownloadModalDone}
        downloadMessage={downloadModalMessage}
        downloadEmail={downloadEmail}
        onClose={() => setIsDownloadModalOpened(false)}
        onDownload={handleDownloadFile}
        onDownloadEmailChanged={(e) => setDownloadEmail(e.target.value)}
      />
      {preLoading && <Preloading />}
    </div>
  );
};

export default AgentList;
