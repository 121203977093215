import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormGroup, ButtonRadius, SelectField, DatePickerField } from "/src/components/form";
import { messages } from "~src/helpers/messages";

import axios, { AxiosResponse } from "~/src/helpers/configAxios";
import useFetch from "~src/hooks/useFetch";
import { AlertNotification } from "../../../../components/alert/alertNotification";
import { CheckCircleOutline, Close } from "@mui/icons-material";

const AssignSaleModal = (props) => {
  const [loaded, setLoaded] = useState(true);
  const [leadId, setLeadId] = useState(props.leadId);
  const [sale, setSale] = useState(props.saleId);
  const [saleList, setSaleList] = useState([]);
  const [isError, setIsError] = useState(false);
  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [submitBtn, setSubmitBtn] = useState(false);
  const [alertText, setAlertText] = useState("ดำเนินการเสร็จสิ้น");
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertType, setAlertType] = useState("success");

  const url = `/api/sales`;
  const { response, error, isLoading } = useFetch(url);

  useEffect(() => {
    if (!isLoading && !error) {
      const results = response?.content;
      setLoaded(false);
      setIsError(false);

      const sales_list = [];
      results.map((sale, index) => sales_list.push({ label: sale.email, value: sale.id }));
      setSaleList(sales_list);
    }

    if (error) {
      setIsError(true);
    }
  }, [response, error, isLoading]);

  const handleChangeSale = (evt) => {
    const { value } = evt.target;
    setSale(value);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    setSubmitBtn(true);
    const url = `/api/leads/${leadId}/update_sale`;

    const dataFormat = { lead: { user_id: sale } };

    axios.post(url, JSON.stringify(dataFormat)).then((response) => {
      if (response.data.content.success) {
        popUpAlert("success", "อัพเดทข้อมูลสำเร็จ");
        setTimeout(() => {
          setSubmitBtn(false);
          window.location.reload();
        }, 1500);
      } else {
        setSubmitBtn(false);
        popUpAlert("error", "อัพเดทข้อมูลไม่สำเร็จ");
      }
    });
  };

  const hideAlert = () => {
    setIsAlertActive(false);
  };

  const popUpAlert = (type, message) => {
    hideAlert();
    setIsAlertActive(true);
    setAlertType(type);
    setAlertText(message);
  };

  return (
    <div className="renew-modal">
      <div className="renew-modal-content">
        <button className="close" type="button" onClick={() => props.cbCloseModal()}>
          <span className="material-icons">close</span>
        </button>
        <div>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup label="สถานะ">
              <SelectField
                name="status"
                id="status"
                inputRef={register(messages.required)}
                error={!!errors.status && errors.status.message}
                onChange={handleChangeSale}
                options={saleList}
                value={sale}
              />
            </FormGroup>

            <div className="mt-4 text-center">
              <FormGroup>
                <ButtonRadius type="submit" color="primary" disabled={submitBtn}>
                  อัพเดท
                </ButtonRadius>
              </FormGroup>
            </div>
          </form>
        </div>
      </div>

      <AlertNotification
        className="bottom-0 right-0 px-4 py-3 m-5 mr-3"
        type={alertType}
        active={isAlertActive}
        enterAnimation="slideInRight"
        exitAnimation="slideOutRight"
        closeDelay={3000}
      >
        <CheckCircleOutline className="mr-3" />
        <span className="inline-block align-bottom">{alertText}</span>
        <button className="ml-3" onClick={hideAlert}>
          <Close />
        </button>
      </AlertNotification>
    </div>
  );
};

export default AssignSaleModal;
