import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~src/components/form";
import { useAppContext } from "~src/context/App";
import { formatNumberWithCurrency } from "~src/helpers/formatNumber";
import { CheckoutSummaryState } from "~src/models/CheckoutState";

interface BankTransferProps {
  onGoBack: any;
  gotoUploadPayslip: any;
  summary: CheckoutSummaryState;
  disabled?: boolean;
}

const BankTransfer: React.FC<BankTransferProps> = ({
  onGoBack,
  gotoUploadPayslip,
  summary,
  disabled,
}) => {
  const { t } = useTranslation();

  const { assets } = useAppContext();
  const KBankIcon = assets.payments.kbankLogo;

  return (
    <>
      <div className="payment-methods-wrap flex flex-col gap-1">
        <div className="banktransfer-wrap payment-methods-info flex flex-row justify-between tablet:flex-col">
          <p> ขอบคุณสำหรับการสั่งซื้อ </p>
          <p className="text-error"> รหัสการสั่งซื้อ #{`${summary?.orderNumber}`} </p>
        </div>
        <br />
        <p>
          ยอดที่ต้องชำระทั้งหมด{"  "}
          <span className="total-amount">
            {formatNumberWithCurrency(summary?.paymentTotalAmount)}
          </span>
        </p>

        <p className="text-sm font-thin">
          หากท่านชำระเงินผ่านการโอนเรียบร้อยแล้ว กรุณาส่งหลักฐานการชำระเงินกลับมาให้เจ้าหน้าที่ทาง
          Line: {t("lineAccount")} บริษัทฯ จะไม่สามารถยืนยันรับยอดเพื่อดำเนินการออกกรมธรรม์ได้
          หากไม่ได้รับสลิปโอนเงิน{" "}
        </p>
        <div className="flex flex-row justify-center mt-3 py-12 bg-white w-full gap-3">
          <img className="bank-logo" src={KBankIcon} alt="KBank account for Gettmore" />
          <div className="flex-col font-thin">
            <p className="text-sm"> ธนาคารกสิกรไทย: สาขาถนนรัชดาภิเษก-ห้วยขวาง </p>
            <p className="text-sm"> ชื่อบัญชี: บจก.เมืองไทย โบรกเกอร์ </p>
            <p className="text-sm"> บัญชีประเภท: กระแสรายวัน </p>
            <p className="text-sm"> เลขที่บัญชี: 057-3-39909-9 </p>
          </div>
        </div>
        <div className="flex justify-center">
          <p className="text-center text-sm font-thin">
            หากท่านต้องการติดต่อเจ้าหน้าที่เพื่อขอความช่วยเหลือ สามารถติดต่อได้ที่ Line:{" "}
            {t("lineAccount")} หรือที่เบอร์ 02-026-6387 เวลา 9.00 น. - 18.00 น. จ. - ศ.{" "}
            (ยกเว้นวันหยุดนักขัตฤกษ์)
          </p>
        </div>
      </div>
      <div className="row">
        <div className="motor-info-page-actions space-between w-full">
          <Button type="button" role="button" onClick={() => onGoBack()}>
            ย้อนกลับ
          </Button>
          <Button
            type="button"
            color="primary"
            role="button"
            disabled={disabled}
            onClick={() => gotoUploadPayslip()}
          >
            ส่งหลักฐานการชำระเงิน
          </Button>
        </div>
      </div>
    </>
  );
};

export default BankTransfer;
