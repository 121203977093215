import React, { useEffect, useState } from "react";
import Modal from "./components/Modal";
import Create from "./components/Create";
import Edit from "./components/Edit";
import axios from "../../../helpers/configAxios";

interface Data {
  id: number;
  title: string;
  url: string;
  image_url: string;
}

const Promotion = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
  const [editModal, setEditModal] = React.useState({
    id: undefined,
    isShow: false,
  });
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<Data[]>([]);
  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        const res = await axios.get("/api/promotions");
        setLoading(false);
        setData(res.data.content);
      } catch (error) {
        console.error(error);
      }
    }
    setLoading(true);

    fetchData();
  }, []);

  const handleOpenEditModal = (id: number): void => {
    setEditModal({
      id,
      isShow: true,
    });
  };

  const handleDelete = async (id: number): Promise<void> => {
    try {
      const res = await axios.delete(`/api/promotions/${id}`);
      if (res.data.success) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = (): void => {
    setIsCreateModalOpen(false);
  };

  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <h1>Promotions</h1>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="border border-pink-500 bg-pink-500 text-white rounded-md px-3 py-2 mx-1 transition duration-500 ease select-none hover:bg-pink-600 focus:outline-none focus:shadow-outline"
        >
          Add New Promotion
        </button>
      </div>

      <div className="mt-4">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th style={{ width: 60 }} className="text-center p-3">
                #
              </th>
              <th style={{ width: 150 }} className="p-3">
                Image
              </th>
              <th className="p-3">Title</th>
              <th className="p-3">URL</th>
              <th style={{ width: 200 }} className="p-3"></th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={5} className="text-center bg-white p-3 border-b-2">
                  Loading
                </td>
              </tr>
            )}
            {!loading && (
              <>
                {data.length === 0 && (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center bg-white p-3 border-b-2"
                    >
                      No Data
                    </td>
                  </tr>
                )}

                {data.map((o, i) => (
                  <tr key={i}>
                    <td className="text-center bg-white p-3 border-b-2">
                      {i + 1}
                    </td>
                    <td className="text-center bg-white p-3 border-b-2">
                      <img
                        src={o.image_url}
                        alt={o.title}
                        className="max-w-full rounded shadow"
                      />
                    </td>
                    <td className="text-center bg-white p-3 border-b-2">
                      {o.title}
                    </td>
                    <td className="text-center bg-white p-3 border-b-2">
                      <a
                        href={o.url}
                        target="_blank"
                        className="text-blue-500 underline"
                      >
                        {o.url}
                      </a>
                    </td>
                    <td className="text-right bg-white p-3 border-b-2">
                      <button
                        onClick={() => handleOpenEditModal(o.id)}
                        className="border border-gray-200 bg-gray-100 text-gray-800 rounded-md px-3 py-2 mx-1 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline"
                      >
                        <span className="material-icons">create</span>
                      </button>
                      <button
                        onClick={() => handleDelete(o.id)}
                        className="border border-red-400 bg-red-400 text-white rounded-md px-3 py-2 mx-1 transition duration-500 ease select-none hover:bg-red-500 focus:outline-none focus:shadow-outline"
                      >
                        <span className="material-icons">delete</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      {isCreateModalOpen && (
        <Modal>
          <Create onClose={handleCloseModal} />
        </Modal>
      )}

      {editModal?.isShow && (
        <Modal>
          <Edit
            editId={editModal?.id}
            onClose={() => setEditModal({ id: undefined, isShow: false })}
          />
        </Modal>
      )}
    </div>
  );
};

export default Promotion;
