import React, { useEffect, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import { Insurer } from "~/src/types/insurer";
import { PerPageResponse, Option } from "~/src/types/basic";
import useAxios from "~/src/hooks/useAxios";
import CustomDropdown from "~src/components/Forms/CustomDropdown";

interface Props extends Partial<Pick<UseFormMethods, "control" | "errors">> {
  name: string;
  className?: string;
}

const InsurersInput: React.FC<Props> = ({ name, className, control, errors }): JSX.Element => {
  const insurersData = useAxios<PerPageResponse<Insurer>>(`/api/insurers`);
  const [options, setOptions] = useState<Option<string>[]>([]);

  const convertDataToOption = (insurersInfo: Insurer[]): Option<string>[] => {
    return insurersInfo.map((insurer) => {
      return {
        label: `[${insurer.code}] ${insurer.name}`,
        value: insurer.code,
      };
    });
  };

  useEffect(() => {
    if (insurersData?.status === "loaded") {
      const results = convertDataToOption(insurersData.data.content);
      setOptions(results);
    }
  }, [insurersData?.status]);

  return (
    <CustomDropdown
      required
      isLoading={insurersData?.status === "loading"}
      isSearchable
      className={className}
      name={name}
      control={control}
      errors={errors}
      options={options}
    />
  );
};

export default InsurersInput;