import React from "react";
import { UseFormMethods, Controller } from "react-hook-form";
import { Menu, MenuItem } from "@material-ui/core";
import _find from "lodash/find";
import _get from "lodash/get";
import { Option } from "~/src/types/basic";
import { messages } from "~/src/helpers/messages";

interface Props extends Partial<Pick<UseFormMethods, "errors" | "control">> {
  className?: string;
  id?: string;
  name: string;
  options?: Option<string | number>[];
  required?: boolean;
  callbackOnChange?: (value: Option<string | number>["value"]) => void;
  onItemSelected?: (value: Option<string | number>["value"]) => void;
}

const MiniDropdown: React.FC<Props> = ({
  id,
  name,
  className,
  errors,
  control,
  options = [],
  required = false,
  callbackOnChange,
  onItemSelected,
}): JSX.Element => {
  const [listMenuElement, setListMenuElement] = React.useState<HTMLElement | undefined>();
  const error = _get(errors, name);

  const onItemClicked = (value) => {
    setListMenuElement(null);
    if (onItemSelected) onItemSelected(value);
  }

  return (
    <>
      <Controller
        id={id}
        name={name}
        rules={required ? messages.required : null}
        control={control}
        render={({ onChange, value }) => {
          const option = _find(options, { value });
          return (
            <>
              <button 
                id={`${id}-button`} 
                className={`w-8 h-8 text-sm rounded-md border hover:bg-gray-100 transition duration-150" type="button ${className}`}
                onClick={(evt) => setListMenuElement(evt.currentTarget)}
                type="button"
              >
                <span>{option?.label}</span>
              </button>
              <Menu
                id={`${id}-menu`}
                className="text-sm"
                anchorEl={listMenuElement}
                open={Boolean(listMenuElement)}
                onClose={() => setListMenuElement(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {options.map(o => {
                  return (
                    <MenuItem onClick={() => {
                      onItemClicked(o.value);
                      if (o.value !== value) {
                        callbackOnChange(o.value);
                        onChange(o.value)
                      }
                    }}>
                      {o.label}
                    </MenuItem>
                  )
                })}
              </Menu>
            </>
          );
        }}
      />
      {error?.message && <p className="text-xs text-red-600">{error?.message}</p>}
    </>
  );
};

export default MiniDropdown;