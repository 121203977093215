import React from "react";
import Proptypes from "prop-types";

import "./index.scss";

const SummaryBox = ({ label, value, color }) => {
  return (
    <div className={color == "primary" ? "summary-box primary" : "summary-box"}>
      <div className="summary-box-label">{label}</div>
      <div className={"summary-box-value"}>{value}</div>
    </div>
  );
};

SummaryBox.proptypes = {
  label: Proptypes.string,
  value: Proptypes.string,
};

export default SummaryBox;
