import React, { Suspense, useEffect, useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";

import Header from "../header";
import Sidebar from "../sidebar/Staff";

import { useAuthContext } from "../../../context/Auth";
import { isJsonString } from "~src/helpers/helpers";
import { Notification } from "~src/features/notification";

export interface StaffProtectedLayoutProps {
  // isAuthenticated: () => boolean;
  logo: any;
  sidebar: boolean;
  header: boolean;
  isPortrait?: boolean;
}

const StaffProtectedLayout = ({
  // isAuthenticated,
  logo,
  sidebar,
  header,
  children,
}: React.PropsWithChildren<StaffProtectedLayoutProps>) => {
  const [isShowSidebar, setIsShowSidebar] = useState<boolean>(sidebar || true);
  const { session, dispatch: dispatchAuth, validateAuth } = useAuthContext();

  useEffect(() => {
    dispatchAuth({
      type: "MODIFY",
      payload: {
        ...session,
        username: Cookies.get("username") || null,
        role: isJsonString(Cookies.get("role")) ? JSON.parse(Cookies.get("role")) : null,
        title: Cookies.get("title") || null,
        firstname: Cookies.get("firstname") || null,
        lastname: Cookies.get("lastname") || null,
        permissions: isJsonString(Cookies.get("permissions"))
          ? JSON.parse(Cookies.get("permissions"))
          : null,
      },
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("sidebar") === null) {
      localStorage.setItem("sidebar", `${true}`);
    } else {
      setIsShowSidebar(localStorage.getItem("sidebar") === "true");
    }
  }, [isShowSidebar]);

  const fnShowSidebar = (status) => {
    localStorage.setItem("sidebar", status);
    setIsShowSidebar(status);
  };

  return (
    <>
      {header && <Header logo={logo} />}
      <section className={header && "page-wrapper"}>
        {sidebar && <Sidebar setShow={fnShowSidebar} />}
        {sidebar ? (
          <div className="content-wrapper" style={{ marginLeft: 100 }}>
            {validateAuth() ? children : <Redirect to="/login" />}
          </div>
        ) : (
          <div>{validateAuth() ? children : <Redirect to="/login" />}</div>
        )}
      </section>
      <Notification />
    </>
  );
};

export default StaffProtectedLayout;
