import cn from "classnames";
import Decimal from "decimal.js";
import React from "react";
import { Link } from "react-router-dom";
import { MoreVert } from "@mui/icons-material";

import { formatNumberWithCurrency } from "../../../helpers/formatNumber";

export interface SummaryMenuItemProps {
  title?: string;
  value?: number | Decimal;
  sublabel?: React.ReactNode;
  valueColor?: "primary" | "secondary";
  isLoading?: boolean;
  href?: string;
  target?: string;
}

export default function SummaryMenuItem({
  title,
  value = 0,
  valueColor = "secondary",
  sublabel,
  isLoading = true,
  href,
  target,
}: SummaryMenuItemProps) {
  const [whole, fractional] = React.useMemo(() => {
    const formattedValue = formatNumberWithCurrency(value, "");
    return formattedValue.split(".");
  }, [value]);

  return (
    <a
      className={`block p-3 overflow-hidden transition duration-500 ease-in-out bg-white rounded-lg ${href ? "cursor-pointer hover:bg-blue-200 hover:shadow-lg" : "cursor-not-allowed"}`}
      style={{ height: 100 }}
      href={href}
      target={target}
    >
      <div className="flex justify-between">
        <span className="text-sm font-bold text-center">{title}</span>
        {href && (
          <span className="text-primary-color">
            ดูรายละเอียด <MoreVert />
          </span>
        )}
      </div>
      {isLoading ? (
        <div className="w-9/12 h-5 mt-4 ml-auto bg-gray-400 rounded animate-pulse" />
      ) : (
        <>
          <div
            className={cn(
              "text-right",
              { "text-secondary-color": valueColor === "secondary" },
              { "text-primary-color": valueColor === "primary" }
            )}
          >
            {(whole !== "-" || !!href) ? (
              <>
                <span className="text-3xl">{whole}</span>
                {fractional && <span className="text-lg">.{fractional}</span>}
                <span className="text-2xl">฿</span>
              </>
            ) : (
              <span className="text-lg text-gray-500">ไม่มี</span>
            )}
          </div>
          {(whole !== "-" && sublabel) && <div className="text-right">{sublabel}</div>}
        </>
      )}
    </a>
  );
}
