import React, { useState } from "react";
import { UseFormMethods } from "react-hook-form";
import { FormGroup, TextField } from "~/src/components/form";
import { messages } from "~/src/helpers/messages";

interface Props extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  name?: string;
}

const LegalEntityForm: React.FC<Props> = ({ name, register, errors }): JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  return (
    <>
      <FormGroup label="ชื่อบริษัทหรือองค์กร" className="col-span-2" require>
        <TextField
          name={`${name}.company_name`}
          inputRef={register(messages.required)}
          error={errors?.company_name?.message}
        />
      </FormGroup>
      <FormGroup label="เลขประจำตัวผู้เสียภาษีของบริษัท/องค์กร" className="col-span-3" require>
        <TextField
          maxLength={13}
          type="number"
          name={`${name}.company_tax`}
          inputRef={register(messages.required)}
          error={errors?.company_tax?.message}
        />
      </FormGroup>
      <FormGroup label="เบอร์มือถือ" className="col-span-2">
        <TextField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (/\d/.test(e.target.value) || e.target.value == "") setPhoneNumber(e.target.value);
          }}
          name={`${name}.phone_number`}
          maxLength={10}
          inputRef={register({ ...messages.mobileNumber })}
          error={errors?.phone_number?.message}
          value={phoneNumber}
        />
      </FormGroup>
      <FormGroup label="อีเมล" className="col-span-3">
        <TextField
          name={`${name}.email`}
          maxLength={64}
          inputRef={register(messages.email)}
          error={errors?.email?.message}
        />
      </FormGroup>
    </>
  );
};

export default LegalEntityForm;