import { addYears } from "date-fns";
import Decimal from "decimal.js";
import safeStringify from "fast-safe-stringify";
import { History } from "history";

import { PaymentEventResponse } from "~src/api/Payments";
import { decimalToString } from "~src/helpers/formatNumber";
import { CheckoutSummaryState } from "~src/models/CheckoutState";
import { Address } from "~src/types/address";
import { Insured } from "~src/types/insured";
import { Order, OrderPaymentsSummary, Status as OrderStatus } from "~src/types/order";
import { Payment, PaymentStatus } from "~src/types/payment";
import { Quotation } from "~src/types/quotation";
import { Quote } from "~src/types/quote";

// TODO: Refactor/Consolidate Checkout data interfaces later

export interface State extends Record<string, any> {
  // extends StringifiableRecord {
  orderID?: number;
  orderNumber?: string;
  status?: OrderStatus;
  paymentStatus?: PaymentStatus;
  summary?: CheckoutSummaryState;
  insuredCategory?: string;
  insureds?: Insured[];
  customerName?: string;
  shortDescCriteria?: string;
  shortDescPolicy?: string;
  insurerCode?: string;
  amount?: string;
  paidAt?: string | Date;
  paymentMethod?: string;
  payment?: Partial<Payment>;
  isRedirectingToKbankCC?: boolean;
}

export interface OrderState {
  details: Record<string, any>;
}

export function initState(
  summary?: Partial<CheckoutSummaryState>,
  order?: Order,
  paymentMethod?: string
): State {
  const { paymentTotalAmount } = summary || ({} as Partial<CheckoutSummaryState>);
  const {
    order_id: orderID,
    number: orderNumber,
    status,
    insureds = [],
    details = {},
    insured_category: insuredCategory,
    payments_summary = {} as Partial<OrderPaymentsSummary>,
    payments = [],
    quotation = {} as Partial<Quotation>,
    quotes = [],
  } = order || ({} as Partial<Order>);
  const { customer_name: customerName, short_desc_criteria: shortDescCriteria } = quotation;
  const { insurer_code: insurerCode, short_desc_policy: shortDescPolicy } =
    quotes[0] || ({} as Partial<Quote>);
  const amount = payments_summary.payment_total_paid_amount
    ? decimalToString(payments_summary.payment_total_paid_amount)
    : paymentTotalAmount;

  return {
    orderID,
    orderNumber,
    status,
    insuredCategory,
    customerName,
    paymentMethod: paymentMethod || payments?.[0]?.pay_method,
    amount,
    insurerCode,
    ...quotation.additional_information,
    insureds,
    details,
    shortDescCriteria,
    shortDescPolicy,
    summary: {
      orderNumber,
      ...summary,
    },
  };
}

export interface ResetAction {
  type: "RESET";
  payload: State;
}

const LOCAL_STORAGE_KEY = "thankyouPageState";

export function restoreStateFromStorage(): State | undefined {
  const itemStr = window.localStorage ? localStorage.getItem(LOCAL_STORAGE_KEY) : undefined;

  return itemStr ? JSON.parse(itemStr) : undefined;
}

export function clearStateFromStorage() {
  if (window.localStorage) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
}

export function saveStateToStorage(state: State) {
  if (window.localStorage) {
    localStorage.setItem(LOCAL_STORAGE_KEY, safeStringify(state));
  }
}

export function createResetAction(initialState: State): ResetAction {
  return {
    type: "RESET",
    payload: initialState,
  };
}

export interface UpdateAction {
  type: "UPDATE";
  payload?: Partial<State>;
  saveToStorage?: boolean;
}

export function createUpdateAction(payload: Partial<State>, saveToStorage?: boolean): UpdateAction {
  return {
    type: "UPDATE",
    payload,
    saveToStorage,
  };
}

// TODO: Revise this later
export function createUpdateActionWithPayment(payment: Payment): UpdateAction {
  const { required_amount, link, payslip, ...otherPaymentProps } = payment || {};

  return {
    type: "UPDATE",
    payload: {
      amount: payment.paid_amount,
      paymentStatus: payment.status,
      paymentMethod: payment.pay_method,
      paidAt: payment.paid_at,
      payment: { ...otherPaymentProps, required_amount: decimalToString(required_amount) },
    },
  };
}

export interface PaymentEventAction {
  type: "PAYMENT_EVENT";
  payload: PaymentEventResponse;
}

export function createPaymentEventAction(evt: PaymentEventResponse): PaymentEventAction {
  return {
    type: "PAYMENT_EVENT",
    payload: evt,
  };
}

// export interface GoToPageAction {
//   type: "GO_TO_PAGE";
//   payload: History;
// }

export type Action = ResetAction | UpdateAction | PaymentEventAction; // | GoToPageAction;

export default function ThankyouPageReducer(state: State, action: Action): State {
  const { type, payload } = action;

  let nextState: State;
  switch (type) {
    case "RESET":
      nextState = payload;
      break;
    case "UPDATE":
      nextState = {
        ...state,
        ...payload,
      };
      // TODO: Revise this later
      if (action.saveToStorage) {
        saveStateToStorage(nextState);
      }
      break;
    case "PAYMENT_EVENT":
      const {
        status,
        payment_status: paymentStatus,
        payment_method: paymentMethod,
        amount,
        paid_at: paidAt,
        short_desc_criteria: shortDescCriteria,
        short_desc_policy: shortDescPolicy,
      } = payload;

      nextState = {
        ...state,
        status: status as OrderStatus,
        paymentStatus,
        paymentMethod,
        amount,
        paidAt,
        shortDescCriteria,
        shortDescPolicy,
      };
      break;
    // case "GO_TO_PAGE":
    //   // TEST
    //   console.log("Going to Thankyou Page with state:", state);

    //   const history = payload;
    //   history.push({
    //     pathname: "/thankyou",
    //     state,
    //   });
    //   return state;
    default:
      nextState = state;
      break;
  }

  return nextState;
}
