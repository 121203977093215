import "babel-polyfill";
import axios from "/src/helpers/configAxios";

//DEPRECATED: Use /src/api/* instead
const apiUrl = process.env.API_URL;
const useHttp = () => {
  const post = (url, payload = {}) => axios.post(`${apiUrl}${url}`, payload);
  const postHeader = (url, payload = {}, header) => axios.post(`${url}`, payload, header);
  const get = (url, payload = {}) => axios.get(`${apiUrl}${url}`, payload);
  const patch = (url, payload = {}) => axios.patch(`${apiUrl}${url}`, payload);

  const asyncGet = async (url, payload = {}, header) => await axios.get(`${url}`, payload, header);
  const asyncPost = async (url, payload = {}, header) =>
    await axios.post(`${url}`, payload, header);

  return { post, get, postHeader, asyncGet, asyncPost, patch };
};

export default useHttp;
