import React from "react";

const CompareButton = ({ id, inputRef, classes, ...rest }) => {
  return (
    <button id={id} ref={inputRef} className={`flex flex-col items-center ${classes}`} {...rest}>
      <i className="material-icons">equalizer</i>
      <span className="text-xs">เปรียบเทียบ</span>
    </button>
  );
};

export default CompareButton;
