import React from "react";
import { Icon } from "../../../../components/icons";
import { useTranslation } from "react-i18next";

type Timeline = "annually" | "12month";

interface Filter {
  timeline: "annually" | "12month";
  year: string;
}

interface Props {
  filter?: Filter;
  onFilterChange?: (filter: Filter) => void;
}

export const DashboardChartHeader: React.FC<Props> = ({
  filter = {},
  onFilterChange,
}) => {
  const { timeline = "", year } = filter;
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex flex-col items-start mb-3 lg:flex-row lg:items-center">
        <div className="mr-3 text-xl">รายละเอียดสรุปผลงานของท่าน</div>
        <div className="text-gray-500">
          <div className="inline-block mr-1">
            <Icon name="Info" width={12} height={12} color="#999" />
          </div>
          <div className="inline-block">
            คำนวณจากผลงานที่แจ้งผ่านระบบ {t('fullAppName')} หรือผ่านทีมงาน {t('support')}
            เท่านั้น
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 space-y-2 md:flex-row md:space-x-3 md:space-y-0">
        <div className="flex items-center space-x-3">
          <label>ตัวกรองช่วงเวลา:</label>
          <div
            className="flex flex-1 px-4 bg-white border rounded-full"
            style={{ minWidth: 180 }}
          >
            <select
              className="flex-1 h-24 bg-transparent focus:outline-none"
              value={timeline}
              onChange={({ currentTarget }) =>
                onFilterChange?.({
                  timeline: currentTarget.value as Timeline,
                  year: `${new Date().getFullYear()}`,
                })
              }
            >
              <option value="annually">รายปี</option>
              <option value="12month">12 เดือนย้อนหลัง</option>
            </select>
          </div>
        </div>
        {timeline === "annually" && (
          <div className="flex items-center space-x-3">
            <label>เลือกปี:</label>
            <div
              className="flex flex-1 px-4 bg-white border rounded-full"
              style={{ minWidth: 180 }}
            >
              <select
                className="flex-1 h-24 bg-transparent focus:outline-none"
                value={year}
                onChange={({ currentTarget }) =>
                  onFilterChange?.({
                    timeline,
                    year: currentTarget.value,
                  })
                }
              >
                {Array.from({ length: 10 }).map((_, idx) => (
                  <option key={idx} value={new Date().getFullYear() - idx}>
                    {new Date().getFullYear() - idx}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
