import React from "react";

import { FormGroup, UploadField } from "/src/components/form";

import exampleSignIdentityCard from "/assets/example-sign-identify-card.jpeg";

const IdCard = ({ id, name, errors, register, formID = "uploadIdCard" }) => {
  return (
    <>
      <h4 className="mt-6">แนบสำเนาบัตรประชาชน </h4>
      <p className="details">
        พร้อมเซ็น &quot;ใช้ในการผ่อนรถทะเบียน ………… เท่านั้น หากชำระเกินเวลาที่กำหนด 7 วัน
        มีความประสงค์ให้ยกเลิกความคุ้มครองโดยทันที
        และขอสละสิทธิ์ในการขอรับคืนเบี้ยจากการยกเลิก&quot;
      </p>
      <div className="mt-4">
        <FormGroup htmlForLabel={id}>
          <UploadField id={id} name={name} accept="image/*" inputRef={register} errors={errors} />
          <br />
          <img src={exampleSignIdentityCard} />
        </FormGroup>
      </div>
    </>
  );
};

export default IdCard;
