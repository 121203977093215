import React from "react";
import { getYear, getMonth } from "date-fns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import _ from "lodash";
import DatePick, { ReactDatePickerProps } from "react-datepicker";
import "./css/Datepicker.css";

interface Months {
  short: string;
  full: string;
}

const months: Months[] = [
  { short: "ม.ค.", full: "มกราคม" },
  { short: "ก.พ.", full: "กุมภาพันธ์" },
  { short: "มี.ค.", full: "มีนาคม" },
  { short: "เม.ย.", full: "เมษายน" },
  { short: "พ.ค.", full: "พฤษภาคม" },
  { short: "มิ.ย.", full: "มิถุนายน" },
  { short: "ก.ค.", full: "กรกฎาคม" },
  { short: "ส.ค.", full: "สิงหาคม" },
  { short: "ก.ย.", full: "กันยายน" },
  { short: "ต.ค.", full: "ตุลาคม" },
  { short: "พ.ย.", full: "พฤศจิกายน" },
  { short: "ธ.ค.", full: "ธันวาคม" },
];

interface Props extends ReactDatePickerProps {
  maxYears?: number;
  yearRange?: number;
}

const DatePicker: React.FC<Props> = ({ ...rest }) => {
  const maxYears = rest.maxYears ?? 1;
  const yearRange = rest.yearRange ?? 120;
  const years = _.range(getYear(new Date()) - yearRange, getYear(new Date()) + maxYears + 1, 1);

  return (
    <div className="relative flex">
      <DatePick
        renderCustomHeader={({ date, changeYear, changeMonth }) => (
          <div className="flex p-2">
            <div className="w-1/2 px-2">
              <select
                value={getMonth(date)}
                onChange={({ target: { value } }) => changeMonth(parseInt(value))}
                className="w-full p-1 text-base shadow-sm rounded-5"
              >
                {months.map((option, index) => (
                  <option key={index} value={index}>
                    {option.full}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-1/2 px-2">
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(parseInt(value))}
                className="w-full p-1 text-base shadow-sm rounded-5"
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {...rest}
        className="w-full px-2 py-2 border border-gray-300 outline-none rounded-5 focus-within:border-secondary"
      />
      <div
        className="absolute right-0 flex items-center justify-center px-2 py-2 rounded-r-5"
        style={{ height: 37 }}
      >
        <CalendarTodayIcon className="text-gray-500" />
      </div>
    </div>
  );
};

export default DatePicker;
