import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { Dialog, IconButton } from "@material-ui/core";
import { Publish, Close } from "@mui/icons-material";

import { TextField, FormGroup } from "../../form";
import { messages } from "../../../helpers/messages";

interface DownloadModalProps {
  id: string;
  open: boolean;
  title: string;
  isDownloadDone: boolean;
  downloadMessage: string;
  downloadEmail: string;
  onClose: any;
  onDownload: any;
  onDownloadEmailChanged: any;
}

interface DownloadIFormInputs {
  email: string;
}

const DownloadModal: React.FC<DownloadModalProps> = ({
  id,
  open = false,
  isDownloadDone = false,
  downloadMessage = "",
  downloadEmail = "",
  title = "",
  onClose,
  onDownload,
  onDownloadEmailChanged = null,
}) => {
  const downloadForm = useForm<DownloadIFormInputs>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleDownloadSubmit = () => {
    const formElement: HTMLFormElement = document.querySelector(`form[id="${id}"]`);
    onDownload(new FormData(formElement));
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <div className="rounded-3x">
        <div className="flex flex-row items-center justify-between py-1 pl-4 text-lg text-white border-b bg-primary-color border-custom-gray-200">
          {title}
          <IconButton component="span" onClick={() => onClose(false)}>
            <Close />
          </IconButton>
        </div>
        <div className="px-4 py-6">
          {isDownloadDone ? (
            <>
              <div>{downloadMessage}</div>
              <div className="flex justify-center w-full mt-4">
                <button
                  onClick={() => {
                    onClose(false);
                  }}
                  className="px-4 py-2 font-bold text-white transition duration-300 ease-in-out rounded-full shadow bg-primary-color focus:outline-none hover:bg-primary-color-dark disabled:opacity-50"
                >
                  ยืนยัน
                </button>
              </div>
            </>
          ) : (
            <form
              id={id}
              autoComplete="off"
              onSubmit={downloadForm.handleSubmit(handleDownloadSubmit)}
              encType="multipart/form-data"
            >
              <div className="flex justify-center w-full">
                <FormGroup label="อีเมล์ผู้รับไฟล์" htmlForLabel="email" require>
                  <TextField
                    id="email"
                    name="email"
                    value={downloadEmail}
                    onChange={onDownloadEmailChanged}
                    inputRef={downloadForm.register(messages.email)}
                    error={downloadForm.errors.email?.message}
                  />
                </FormGroup>
              </div>
              <div className="flex justify-center w-full mt-2">
                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white transition duration-300 ease-in-out rounded-full shadow bg-primary-color focus:outline-none hover:bg-primary-color-dark disabled:opacity-50"
                >
                  <Publish /> ส่ง
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default DownloadModal;
