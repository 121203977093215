import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { PDPATable, PDPAViewModal } from "~src/features/pdpa";

const PDPAListPage = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" component="h1">
          PDPA
        </Typography>

        <Button href="/staffs/pdpas/create" variant="contained" disableElevation>
          New PDPA
        </Button>
      </Box>

      <PDPATable />
      <PDPAViewModal />
    </Box>
  );
};

export default PDPAListPage;
