import React from "react";

import dataYml from "~/src/data.yaml";
import { useAuthenticate } from "~src/helpers/authen";
import { reformatDateEN } from "~src/helpers/date";
import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "~src/helpers/formatNumber.js";
import { renderYaml } from "../../../../helpers/renderYaml";

interface Props {
  data?: any[];
  isLoading?: boolean;
}

export const RenewalTable: React.FC<Props> = ({ data = [], isLoading = false }) => {
  const { showPaidCommission } = useAuthenticate();

  return (
    <section className="flex flex-col h-full overflow-hidden bg-white">
      <div className="flex text-xs font-light text-center text-gray-600 bg-white border-t border-b divide-x">
        <div className="w-3/12">ข้อมูลลูกค้า</div>
        <div className="w-1/12">บริษัท</div>
        <div className="w-1/12">แผน</div>
        <div className="w-2/12">วันที่คุ้มครอง</div>
        <div className="w-2/12">ทุนประกัน</div>
        <div className="w-2/12">เบี้ยประกันที่ชำระ</div>
        {showPaidCommission && <div className="w-2/12">ค่าตอบแทนสุทธิ</div>}
        <div className="w-1/12" />
      </div>
      <div className="flex-1 overflow-scroll divide-y">
        {isLoading
          ? Array.from({ length: 2 }).map(() => (
            <div className="flex py-3">
              <div className="w-3/12 p-1 space-y-2">
                <div className="w-9/12 h-2 bg-gray-400 animate-pulse" />
                <div className="w-8/12 h-2 bg-gray-400 animate-pulse" />
                <div className="w-9/12 h-2 bg-gray-400 animate-pulse" />
                <div className="w-8/12 h-2 bg-gray-400 animate-pulse" />
              </div>
              <div className="w-1/12 p-1">
                <div className="w-8 h-8 mx-auto bg-gray-400 animate-pulse" />
              </div>
              <div className="w-1/12 p-1 text-center space-y-2">
                <div className="w-8 h-2 mx-auto bg-gray-400 animate-pulse" />
                <div className="w-8 h-2 mx-auto bg-gray-400 animate-pulse" />
              </div>
              <div className="w-2/12 p-1 space-y-2">
                <div className="w-2/12 h-2 bg-gray-400 animate-pulse" />
                <div className="w-8/12 h-2 bg-gray-400 animate-pulse" />
                <div className="w-2/12 h-2 bg-gray-400 animate-pulse" />
                <div className="w-8/12 h-2 bg-gray-400 animate-pulse" />
              </div>
              <div className="w-2/12 p-1 text-center">
                <div className="w-8/12 h-2 mx-auto bg-gray-400 animate-pulse" />
              </div>
              <div className="w-2/12 p-1 font-bold space-y-2">
                <div className="w-8/12 h-2 ml-auto bg-gray-400 animate-pulse" />
                <div className="w-6/12 h-2 ml-auto bg-gray-400 animate-pulse" />
              </div>
              <div className="w-2/12 p-1 text-center">
                <div className="w-8/12 h-2 mx-auto bg-gray-400 animate-pulse" />
              </div>
              <div className="w-1/12 p-1" />
            </div>
          ))
          : data.map((renewal, idx) => (
            <div key={idx} className="flex py-2 text-xs">
              <div className="w-3/12 p-1">
                <div>{renewal.quotation.customer_name}</div>
                <div>{`${renewal.quotation.criteria?.car_brand} ${renewal.quotation.criteria?.car_model}`}</div>
                <div>
                  <span className="mr-1 text-gray-500">ทะเบียน:</span>
                  <span>{renewal.plate_number}</span>
                </div>
              </div>
              <div className="w-1/12 p-1">
                <img
                  style={{ width: 30, height: 30, margin: "auto" }}
                  src={renewal.quotes?.[0]?.insurer_icon}
                />
              </div>
              <div className="w-1/12 p-1 text-center">
                {renewal.quotes?.[0].addon != undefined ? (
                  <>
                    ชั้น {renewal.quotes?.[0]?.policy_type} <br />
                    พ.ร.บ.
                  </>
                ) : (
                  <>
                    {renderYaml(
                      dataYml.product_type[renewal.quotation?.product_type][
                      renewal.quotes?.[0]?.policy_type
                      ]
                    )}
                  </>
                )}
              </div>
              <div className="w-2/12 p-1">
                <div>
                  <div className="mr-1 text-gray-500">เริ่ม:</div>
                  {renewal.quotes?.[0].addon === undefined
                    ? reformatDateEN(renewal?.cover_start_date)
                    : reformatDateEN(renewal?.addon_start_date)}
                </div>
                <div>
                  <div className="mr-1 text-gray-500">ถึง:</div>
                  {renewal.quotes?.[0].addon === undefined
                    ? reformatDateEN(renewal?.cover_end_date)
                    : reformatDateEN(renewal?.addon_end_date)}
                </div>
              </div>
              <div className="w-2/12 p-1 text-center">
                {formatNumberWithCurrency2(renewal.quotes?.[0]?.sum_insured)}
              </div>
              <div className="w-2/12 p-1 font-bold text-right">
                <div className="align-middle">
                  <i className="mr-1 text-xs icon-motor" />
                  {formatNumberWithCurrency(renewal.quotes?.[0]?.selling_price)}
                </div>
                <div>
                  <span className="px-1 mr-2 text-gray-600 bg-gray-400 rounded-full text-3xs">
                    พ.ร.บ.
                  </span>
                  {formatNumberWithCurrency(renewal.quotes?.[0]?.addon?.selling_price)}
                </div>
              </div>
              {showPaidCommission && (
                <div className="w-2/12 p-1 text-center">
                  {formatNumberWithCurrency(renewal.payments_summary.payment_earning_commission)}
                </div>
              )}
              <div className="w-1/12 p-1">
                <a
                  className="px-3 py-1 text-white rounded-full shadow-lg bg-primary-color focus:shadow-outline focus:outline-none"
                  href={`/checkout/${renewal.number}/step-1`}
                >
                  ซื้อ
                </a>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};
