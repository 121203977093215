import React from "react";
// import PropTypes from "prop-types";

import "./search-form.scss";

export type SearchFormInputComponent = "input";

export interface SearchFormProps extends React.ComponentPropsWithoutRef<SearchFormInputComponent> {
  handleClick: (e: React.MouseEvent<HTMLElement>) => void,
  handleKeyPress: (e: React.KeyboardEvent<HTMLElement>) => void,
}

function SearchForm({ 
  handleClick, 
  handleKeyPress, 
  ...htmlProps 
}: SearchFormProps) {
  
  return (
    <div className="staff-search-form">
      <input className="search-input" {...htmlProps} onKeyPress={handleKeyPress} />
      <button
        type="button"
        className="search-button"
        onClick={handleClick}
      >
        <span className="material-icons">search</span>
      </button>
    </div>
  );
}

SearchForm.propTypes = {};

export default SearchForm;
