import Decimal from "decimal.js";
import _, { create } from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "@material-ui/core";
import { Publish } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import List from "./List";
import { ButtonRadius } from "../form";
import ShowPaySlipButton from "../payments/ShowPaySlipButton";
import InstallmentTable from "~src/components/table/InstallmentTable";
import ImagePreviewer from "~src/components/form/imagePreviewer/ImagePreviewer";

import statusYml from "~src/status.yml";
import { parseDate } from "~src/helpers/date";
import { getWhTaxStatusText, checkEmpty, dateFormat, dateTimeFormat } from "~src/helpers/helpers";
import { reformatDateEN } from "~src/helpers/date";
import InstallmentInfo from "~src/models/InstallmentInfo";
import { Attachment } from "~src/types/attachment";
import { Order, OrderPaymentsSummary } from "~src/types/order";
import { Payment, PaymentInfo } from "~src/types/payment";
import { Quotation } from "~src/types/quotation";
import { formatNumberWithCurrency2 } from "~src/helpers";
import { useAuthenticate } from "~src/helpers/authen";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#192D40",
    },
  },
});

export interface ListInfoProps {
  order?: Order;
  quotation?: Quotation;
  paymentsSummary?: OrderPaymentsSummary;
  payments?: Payment[];
  paymentInfo?: PaymentInfo;
  installmentInfo?: InstallmentInfo[];
  payerIDImage?: Attachment;
  allowUploadAttachment?: boolean;
  onShowGeneratePaymentLinkModal?: (payment: Payment) => void;
  onShowPaySlipModal?: (payment: Payment) => void;
  onShowPayerIDModal?: (payerID?: Attachment) => void;
  onShowConfirmPayslipModal?: (payment: Payment) => void;
  onShowAttachmentModal?: (attachmentType: string) => void;
}

export default function ListInfo({
  order,
  quotation,
  paymentsSummary,
  paymentInfo,
  payments,
  installmentInfo,
  payerIDImage,
  allowUploadAttachment,
  onShowGeneratePaymentLinkModal,
  onShowPaySlipModal,
  onShowPayerIDModal,
  onShowConfirmPayslipModal,
  onShowAttachmentModal,
}: ListInfoProps) {
  const { t } = useTranslation();

  const [installmentPayments, nonInstallmentPayments] = useMemo(() => {
    const _installmentPayments: Payment[] = [];
    const _nonInstallmentPayments: Payment[] = [];
    if (!payments) {
      return [_installmentPayments, _nonInstallmentPayments];
    }

    for (const payment of payments) {
      if (
        paymentsSummary?.is_installments &&
        // installmentPlan &&
        payment.pay_method.split("_")[0] === "installment"
      ) {
        _installmentPayments.push(payment);
      } else {
        _nonInstallmentPayments.push(payment);
      }
    }

    return [_installmentPayments, _nonInstallmentPayments];
  }, [payments, paymentsSummary?.is_installments]); // , installmentPlan

  return (
    <div>
      <h6 className="bg">รายละเอียดรายการ</h6>
      <div className="list-content-2">
        <List
          label="เลขที่ใบเสนอราคา"
          value={checkEmpty(quotation?.number)}
          type={2}
          align="right"
        />
        <List
          label="วันที่สร้างใบเสนอราคา"
          value={dateTimeFormat(quotation?.created_at)}
          type={2}
          align="right"
        />
        <List
          label="วันที่สร้างคำสั่งซื้อ"
          value={reformatDateEN(parseDate(order.created_at))}
          type={2}
          align="right"
        />
        {process.env.APP_NAME !== "mti" && order.insured_category === "legal_entity" && (
          <List
            label="การหักภาษี ณ ที่จ่าย"
            value={getWhTaxStatusText(order.include_wh_tax)}
            type={2}
            align="right"
          />
        )}
        {nonInstallmentPayments.map((payment) => (
          <ListInfoNonInstallmentPaymentItem
            key={payment.token}
            payment={payment}
            onShowPaySlipModal={() => onShowPaySlipModal && onShowPaySlipModal(payment)}
          />
        ))}
        {paymentsSummary?.is_installments && (
          <ListInfoInstallmentPayments
            payments={installmentPayments}
            paymentsSummary={paymentsSummary}
            payerIdCardUrl={order.payer_id_card_url}
            payerIDImage={payerIDImage}
            paymentInfo={paymentInfo}
            allowUploadAttachment={allowUploadAttachment}
            onShowGeneratePaymentLinkModal={onShowGeneratePaymentLinkModal}
            onShowPayerIDModal={onShowPayerIDModal}
            onShowPaySlipModal={onShowPaySlipModal}
            onShowConfirmPayslipModal={onShowConfirmPayslipModal}
            onShowAttachmentModal={onShowAttachmentModal}
          />
        )}
        <List label="" value={order.details.remark_6 || ""} type={2} align="right" />
      </div>
    </div>
  );
}

export interface ListInfoInstallmentPaymentsProps {
  payments: Payment[];
  paymentsSummary: OrderPaymentsSummary;
  paymentInfo: PaymentInfo;
  payerIdCardUrl?: string;
  payerIDImage?: Attachment;
  allowUploadAttachment?: boolean;
  onShowGeneratePaymentLinkModal?: (payment: Payment) => void;
  onShowPayerIDModal?: (image?: Attachment) => void;
  onShowPaySlipModal?: (payment: Payment) => void;
  onShowConfirmPayslipModal?: (payment: Payment) => void;
  onShowAttachmentModal?: (attachmentType: string) => void;
}

export var ListInfoInstallmentPayments = ({
  payments,
  paymentsSummary,
  paymentInfo,
  payerIdCardUrl,
  payerIDImage,
  allowUploadAttachment,
  onShowGeneratePaymentLinkModal,
  onShowPayerIDModal,
  onShowPaySlipModal,
  onShowConfirmPayslipModal,
  onShowAttachmentModal,
}: ListInfoInstallmentPaymentsProps) => {
  const { t } = useTranslation();
  const { accessType } = useAuthenticate();
  const isStaff = accessType === "STAFF";

  const isPaymentTypeInstallment = (paymentType: string) => {
    return paymentType.split("_")[0] === "installment";
  };

  return (
    <>
      <List
        label="วิธีชำระ"
        value={
          <>
            {payments[0]?.pay_method ? _.get(statusYml.paymentType, payments[0].pay_method) : "-"}{" "}
          </>
        }
        type={2}
        align="right"
      />
      <div className="grid grid-cols-3 gap-4 mt-3">
        <ImagePreviewer
          id="payer_id_card"
          name="payer_id_card"
          title="บัตรประชาชน"
          value={payerIdCardUrl}
          onClickImage={() => onShowAttachmentModal("payer_id_card")}
        />

        {isPaymentTypeInstallment(payments[0].pay_method) && (
          <>
            {(allowUploadAttachment || paymentInfo?.agent_installment_contract_url) && (
              <ImagePreviewer
                id="agent_installment_contract"
                name="agent_installment_contract"
                title="สัญญาผ่อนนายหน้า"
                value={paymentInfo?.agent_installment_contract_url}
                onClickImage={() => onShowAttachmentModal("agent_installment_contract")}
              />
            )}
            {(allowUploadAttachment || paymentInfo?.customer_installment_contract_url) && (
              <ImagePreviewer
                id="customer_installment_contract"
                name="customer_installment_contract"
                title="สัญญาผ่อนลูกค้า"
                value={paymentInfo?.customer_installment_contract_url}
                onClickImage={() => onShowAttachmentModal("customer_installment_contract")}
              />
            )}
            {(allowUploadAttachment || paymentInfo?.installment_selfie_image_url) && (
              <ImagePreviewer
                id="installment_selfie_image"
                name="installment_selfie_image"
                title="Selfie"
                value={paymentInfo?.installment_selfie_image_url}
                onClickImage={() => onShowAttachmentModal("installment_selfie_image")}
              />
            )}
          </>
        )}
      </div>
      <List
        label="จำนวนงวดที่ผ่อนชำระ"
        value={paymentsSummary.installment_terms || "-"}
        type={2}
        align="right"
      />
      <InstallmentTable
        totalTerms={paymentsSummary.installment_terms}
        paidTerms={paymentsSummary.installment_paid_terms}
        totalAmount={new Decimal(paymentsSummary.sum_selling_price)}
        discountAmount={new Decimal(paymentsSummary.sum_discount)}
        commissionAmount={new Decimal(paymentsSummary.commission_gain)}
        payments={payments}
        onShowGeneratePaymentLinkModal={onShowGeneratePaymentLinkModal}
        onShowPaySlipModal={onShowPaySlipModal}
        onShowConfirmPayslipModal={onShowConfirmPayslipModal}
        // ordersAPI={ordersAPI}
        className="w-full"
      />
    </>
  );
};

export interface ListInfoNonInstallmentPaymentItemProps {
  payment: Payment;
  onShowPaySlipModal?: (evt: any) => void;
}

export const ListInfoNonInstallmentPaymentItem = ({
  payment,
  onShowPaySlipModal,
}: ListInfoNonInstallmentPaymentItemProps) => {
  const { t } = useTranslation();
  const isCreditTerm = payment?.pay_method == "credit_term"

  return (
    <div className="payment-info mb-1">
      <List
        label="วิธีชำระ"
        value={
          <>
            {payment.pay_method && statusYml.paymentType[payment.pay_method]
              ? `${statusYml.paymentType[payment.pay_method]}`
              : "-"}
            {payment.payslip || payment.payslip_url ? (
              <ShowPaySlipButton
                title={payment.pay_method === "bank_transfer" ? "ใบสลิปการโอน" : "ใบสลิป"}
                disabled={!onShowPaySlipModal}
                onClick={onShowPaySlipModal}
              />
            ) : payment.pay_method && ["bank_transfer"].includes(payment.pay_method) ? (
              <ButtonRadius
                classNameOption={t("paySlipAttach") ? "" : "hidden"}
                color="primary"
                size="xxs"
                disabled={!onShowPaySlipModal}
                onClick={onShowPaySlipModal}
              >
                <i className="material-icons slip-icon">perm_media</i> แนบสลิป
              </ButtonRadius>
            ) : null}
          </>
        }
        type={2}
        align="right"
      />
      {payment.payslip_url && (
        <List
          label="วันที่แนบสลิป"
          value={reformatDateEN(payment.attached_at)}
          type={2}
          align="right"
        />
      )}

      {!isCreditTerm && <List label="วันที่ชำระเงิน" value={reformatDateEN(payment.paid_at)} type={2} align="right" />}
      {payment.description && payment.description !== "" && (
        <List label="หมายเหตุ" value={payment.description} type={2} align="right" />
      )}
      {!isCreditTerm && 
        <List
          label="จำนวนที่ชำระ"
          value={formatNumberWithCurrency2(payment.paid_amount)}
          type={2}
          align="right"
        />
      }
    </div>
  );
};
