import React from "react";
import classnames from "classnames";

const Col: React.FC<{
  component?: "th" | "td";
  classes?: string;
  text?: string | JSX.Element;
}> = ({ component = "th", classes, text }) => {
  return (
    <>
      {component === "th" && (
        <th
          className={classnames(
            "px-4 py-2 font-thin text-left text-sm",
            classes
          )}
        >
          {text}
        </th>
      )}
      {component === "td" && (
        <td
          className={classnames(
            "px-4 py-2 font-bold text-sm bg-white border-b-2 border-gray-200",
            classes
          )}
        >
          {text}
        </td>
      )}
    </>
  );
};

export default Col;
