function formingCoverageFor(product, content) {
  switch (product) {
    case "motor":
      return motorCoverages(content);
      break;

    default:
  }

  function motorCoverages(content) {
    const { details, coverages, sum_insured } = content;
    let data = [
      {
        title: "ความคุ้มครองรถยนต์ผู้เอาประกันภัย",
        ทุนประกัน: sum_insured,
        ค่าเสียหายส่วนแรก: coverages?.excess_damage_coverage,
        ประเภทการซ่อม: details?.repair_facility,
        รถยนต์สูญหาย: coverages?.fire_theft_damage_coverage,
        ไฟไหม้: coverages?.fire_theft_damage_coverage,
        น้ำท่วม: coverages?.natural_disaster_coverage,
      },
      {
        title: "ความคุ้มครองบุคคลภายนอก",
        "ความบาดเจ็บทางร่างกาย หรือเสียชีวิตต่อคน":
          coverages?.third_party_bodily_injury_coverage_per_person,
        "ความบาดเจ็บทางร่างกาย หรือเสียชีวิตต่อครั้ง":
          coverages?.third_party_bodily_injury_coverage_per_incident,
        "ทรัพย์สินบุคคลภายนอก ต่อครั้ง":
          coverages?.third_party_property_damage_coverage_per_incident,
      },
      {
        title: "ความคุ้มครองเพิ่มเติม",
        อุบัติเหตุส่วนบุคคล: coverages?.driver_personal_accident_coverage,
        คุ้มครองคนโดยสาร: coverages?.passengers_in_vehicle_covered,
        "ค่ารักษาพยาบาล (ต่อคน)": coverages?.medical_coverage,
        "ประกันตัวผู้ขับขี่ (ต่อครั้ง)": coverages?.bail_bond_coverage,
      },
    ];

    return data;
  }
}

export default formingCoverageFor;
