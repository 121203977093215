import React from "react";
import { Box, Button, Divider, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import { useAppDispatch, useAppSelector } from "~src/store";
import { closeModal, selectModalType } from "../bannerSlice";
import { fetchCreateBanner, fetchUpdateBanner } from "../apis/banners";

interface Inputs {
  title: string;
  image: File | null;
}

const Form: React.FC<{ id?: number; title?: string }> = ({ id, title }) => {
  const modalType = useAppSelector(selectModalType);
  const dispatch = useAppDispatch();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (data.image) {
      data.image = data.image?.[0] || null;
    }

    if (modalType === "create") {
      dispatch(fetchCreateBanner(data));
    } else {
      dispatch(fetchUpdateBanner({ id, ...data }));
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mb: 3 }}>
        <InputLabel>Title</InputLabel>
        <Controller
          control={control}
          name="title"
          rules={{ required: "Title field is not empty" }}
          render={(props) => <OutlinedInput {...props} fullWidth />}
        />
        {!!errors.title && <FormHelperText error>{errors.title.message}</FormHelperText>}
      </Box>
      <Box sx={{ mb: 3 }}>
        <Button
          variant="outlined"
          component="label"
          color="inherit"
          size="large"
          startIcon={<CameraAltIcon />}
        >
          Choose Image
          <input type="file" hidden name="image" ref={register} />
        </Button>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Box sx={{ mb: 3, display: "flex", justifyContent: "space-between" }}>
        <Button
          type="button"
          variant="contained"
          size="large"
          color="inherit"
          disableElevation
          onClick={() => dispatch(closeModal())}
        >
          Close
        </Button>
        <Button type="submit" variant="contained" size="large" disableElevation>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Form;
