module.exports = {
    "openMenu": "/motors",
    "quickMenuTravel": false,
    "quickMenuAccident": false,
    "quickMenuFire": false,
    "quickMenuOther": false,
    "registerButton": false,
    "menuCommission": false,
    "Referral": false,
    "tabSearchCommercial": false,
    "policyFromOldSystem": false,
    "insurersFilter": false,
    "agentCheckboxConfirm": true,
    "staffCheckboxConfirm": true,
    "receiptAgentDashBoard": true,
    "modalEndOfYearWarning": false,
    "buttonLine": false,
    "adjustSumInsured": true,
    "importRenewalFile": false,
    "armoryTab": false,
    "commissionShow": false,
    "paySlipAttach": false,
    "vehicleType": true,
    "partner": false,
    "customQuote": false,
    "trackingPolicy": false,
    "whTax": false
};