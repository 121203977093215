import React from "react";
import classNames from "classnames";

const Group = ({ children, className = "" }) => {
  return <div className={classNames("checkout-summary-group", className)}>{children}</div>;
};

const Header = ({ label, icon, color }) => {
  return (
    <h4 className={color}>
      {icon} {label}
    </h4>
  );
};

const List = ({ label, value }) => {
  return (
    <>
      <div className="field-title">{label}</div>
      <div className="field-value">{value}</div>
    </>
  );
};

export { Group, Header, List };
