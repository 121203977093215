import React, { useState, useEffect } from "react";

interface AlertNotificationProps {
  enterAnimation: string;
  exitAnimation: string;
  active: boolean;
  setActive?: any;
  className?: string;
  type?: StyleType;
  closeDelay?: number;
}

type StyleType = "success" | "error"

const AlertNotification: React.FC<AlertNotificationProps> = ({
  enterAnimation,
  exitAnimation,
  active,
  setActive,
  className = "",
  type = "",
  closeDelay = 0,
  children,
  ...rest
}) => {
  const [animation, setAnimation] = useState(enterAnimation);
  const [visible, setVisible] = useState(false);
  let timeout = null;

  const TYPE_STYLE = {
    info: "rounded text-gray-700 bg-gray-100 border border-gray-400",
    success: "rounded text-green-700 bg-green-100 border border-green-400",
    error: "rounded text-red-700 bg-red-100 border border-red-400"
  }

  const cancelTimeout = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }

  useEffect(() => {
    if (active) {
      setVisible(true);
      setAnimation(enterAnimation);
      timeout = setTimeout(() => {
        if (active) {
          cancelTimeout();
          setAnimation(exitAnimation);
          if (setActive) setActive(false);
        }
      }, closeDelay)
    } else {
      cancelTimeout();
      setAnimation(exitAnimation);
      if (setActive) setActive(false);
    }
  }, [active]);

  const style = TYPE_STYLE[type] ?? ""
  return (
    <div className={`fixed animated ${visible ? "visible" : "invisible"} ${animation} ${className} ${style}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export { 
  AlertNotification,
  StyleType
};