import React, { useEffect, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import _get from "lodash/get";
import { messages } from "~/src/helpers/messages";
import { Option } from "~/src/types/basic";
import { FormGroup, SelectField } from "~/src/components/form";
import { getProvinces } from "~/src/pages/agent/motor/Services";

interface Props extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  name: string;
  className?: string;
  setLoading?: (status: boolean) => void;
  callbackOnChange?: (e: React.ChangeEvent) => void;
}

const ProvinceInput: React.FC<Props> = ({
  name,
  className,
  errors,
  register,
  setLoading,
  callbackOnChange,
}): JSX.Element => {
  const [optionProvinces, setOptionProvinces] = useState<Option<string>[]>([]);
  const errorMsg = _get(errors, name)?.message;

  const convertDataToOption = (provinces: string[]): Option<string>[] => {
    return provinces.map((province) => {
      return {
        label: province,
        value: province,
      };
    });
  };

  const fetchProvinces = async () => {
    try {
      setLoading?.(true);
      const response = await getProvinces();
      const resultsOption = convertDataToOption(response.data.provinces);
      setOptionProvinces(resultsOption);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading?.(false);
    }
  };

  useEffect(() => {
    fetchProvinces();
  }, []);

  return (
    <FormGroup label="ทะเบียนจังหวัด" className={className}>
      <SelectField
        name={name}
        style={{ minHeight: "38px" }}
        inputRef={register(messages.required)}
        error={errorMsg}
        onChange={callbackOnChange}
        options={optionProvinces}
      />
    </FormGroup>
  );
};

export default ProvinceInput;