import React, { useState, useEffect, useRef } from "react";
import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "~src/helpers/formatNumber";
import { parseDateEN } from "~src/helpers/dateTime";
import { urlStringify, getInsuredByCategory } from "~src/helpers/helpers";
import SectionHeader from "~src/components/layout/staff/SectionHeader";
import SearchForm from "~src/components/staff/SearchForm";
import ErrorPage from "~src/components/errorPage";
import dataYml from "~src/data.yaml";
import { renderYaml } from "~src/helpers/renderYaml";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import useInput from "~src/hooks/useInput";
import useFetch from "~src/hooks/useFetch";
import PolicyStatus from "~src/components/badges/policy/PolicyStatus";
import { JacketUrl, PolicyCopyUrl, ReceiptUrl } from "~src/components/orderDetail/DocumentInfo";
import { useTranslation } from "react-i18next";
import { useAuthenticate } from "~src/helpers/authen";
import { LocalShippingOutlined } from "@mui/icons-material";
import { ToastProvider, useToasts } from "react-toast-notifications";
import PolicyTrackingModal from "~src/components/modal/PolicyTrackingModal";

import { getTotalAmountDetails } from "~src/helpers/quotationHelper";
import "./policy.scss";
import "babel-polyfill";
import useSecureLink from "~src/hooks/useSecureLink";
import Preloading from "~src/components/loading/Preloading";

const BASE_URL = "/all-policies";
const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const ProductTypeIcon = ({ type }) => {
  switch (type) {
    case "motor":
      return <i className="icon icon-3-Motor" />;
    case "motorbike":
      return <i className="icon icon-3-motorbike" />;
    case "travel":
      return <i className="icon icon-3-travel" />;
    case "fire":
      return <i className="icon icon-3-fire" />;
    case "marine":
      return <i className="icon icon-3-marine" />;
    case "home":
      return <i className="icon icon-3-House" />;
    case "sme":
      return <i className="icon icon-3-SME" />;
    case "iar":
      return <i className="icon icon-3-IAR" />;
    case "golfer":
      return <i className="icon icon-3-golf" />;
    case "engineer":
      return <i className="icon icon-3-engineer" />;
    case "personalaccident":
      return <i className="icon icon-3-PA" />;
    case "health":
      return <i className="icon icon-3-health" />;
    case "cancer":
      return <i className="icon icon-3-cancer" />;
    case "miscellaneous":
      return <i className="icon icon-3-misc" />;
    default:
      return <i className="icon gettmore-icon-policylist" />;
  }
};

const All = ({ location }) => {
  const { secureLinkLoading } = useSecureLink();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const { t } = useTranslation();
  const { showPaidCommission } = useAuthenticate();
  const [loaded, setLoaded] = useState(true);
  const [packages, setPackages] = useState([]);
  const [isError, setIsError] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(queryParams.page ?? DEFAULT_PAGE);
  const [isOpenTracking, setIsOpenTracking] = useState(false);
  const currentClickPolicyId = useRef(undefined);
  const currentClickOrderNumber = useRef(undefined);
  const trackingCount = useRef(0);
  const { addToast } = useToasts();

  const { value: search_value, bind: handleChaneSearch } = useInput(queryParams?.keyword ?? "");

  const url = `/api/policies?status=all&${urlStringify({
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    ...queryParams,
  })}`;
  const { response, error, isLoading } = useFetch(url);

  useEffect(() => {
    if (!isLoading && !error) {
      const results = response?.content;
      const totalPolicies = response.total_count;
      const totalPages = response.total_pages;
      setTotalRecords(totalPolicies);
      setPageCount(totalPages);
      setPackages(results);
      setLoaded(false);
      setIsError(false);
    }

    if (error) {
      setIsError(true);
    }
  }, [response, error, isLoading]);

  const handleClickSearch = () => {
    performSearch();
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") performSearch();
  };

  const performSearch = () => {
    const queries = urlStringify({
      keyword: search_value,
      per_page: PER_PAGE,
      page: 1,
    });
    window.location.href = `${BASE_URL}?${queries}`;
  };

  const handlePageClick = (e) => {
    const selectedPage = parseInt(e.selected) + 1;
    const queries = urlStringify({
      keyword: search_value,
      per_page: PER_PAGE,
      page: selectedPage,
    });
    window.location.href = `${BASE_URL}?${queries}`;
  };

  const getDisplayAmount = (quotation, total_amount) => {
    return quotation.criteria?.traveller >= 1
      ? total_amount / quotation.criteria.traveller
      : total_amount;
  };

  const getDisplayCommission = (quotation, earning_commission) => {
    return quotation.criteria?.traveller >= 1
      ? earning_commission / quotation.criteria.traveller
      : earning_commission;
  };

  return (
    <div className="policy-page">
      <PolicyTrackingModal
        queryKey={`tracking-${trackingCount.current.toString()}`}
        open={isOpenTracking}
        onClose={() => setIsOpenTracking(false)}
        addToast={addToast}
        orderNumber={currentClickOrderNumber.current}
        policyId={currentClickPolicyId.current}
      />
      {isError ? (
        <ErrorPage />
      ) : (
        <>
          {secureLinkLoading && <Preloading />}
          <SectionHeader
            title="กรมธรรม์ทั้งหมด"
            breadcrumbs={[{ url: "#", label: "กรมธรรม์", current: true }]}
            searchComponent={
              <SearchForm
                type="text"
                placeholder="ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่คำสั่งซื้อ"
                {...handleChaneSearch}
                handleClick={handleClickSearch}
                handleKeyPress={handleKeyPressSearch}
              />
            }
          />

          <section>
            <div className="table-grid2">
              <div className="grid-th">
                <div>ข้อมูลคำสั่งซื้อ</div>
                <div>ข้อมูลลูกค้า</div>
                {/* <div style={{ textAlign: "center" }}>ประเภทงาน</div> */}
                <div>บริษัท</div>
                <div style={{ textAlign: "center" }}>แผน</div>
                <div>วันที่คุ้มครอง</div>
                <div style={{ textAlign: "right" }}>ทุนประกัน</div>
                <div style={{ textAlign: "right" }}>
                  {showPaidCommission ? "ส่วนลด" : "" }
                </div>
                <div style={{ textAlign: "right" }}>เบี้ยประกันที่ชำระ</div>
                {process.env.APP_NAME === "mti" && (
                  <div style={{ textAlign: "center" }}>เลขรับแจ้ง (NOS)</div>
                )}
                <div style={{ textAlign: "right" }}>
                  {showPaidCommission ? "ค่าตอบแทนสุทธิ" : "" }
                </div>
                <div style={{ textAlign: "center" }} />
              </div>
              {isLoading && <div className="grid-tbody-full">กำลังโหลด...</div>}
              {!isLoading && packages.length == 0 && (
                <div className="grid-tbody-full">ไม่พบข้อมูล</div>
              )}
              {packages.map((obj, index) => {
                const {
                  order_id,
                  order_number,
                  nos_number,
                  insured_title,
                  insured_firstname,
                  insured_lastname,
                  insured_phone_number,
                  insured_category,
                  company_name,
                  product_type,
                  insurer_code,
                  insurer_icon,
                  policy_type,
                  policy_no,
                  sum_insured,
                  coverage_from,
                  coverage_to,
                  total_amount,
                  commission_amount,
                  earning_commission,
                  status,
                  plate_number,
                  discount,
                  quotation,
                  payment,
                  policy_url,
                  jacket_url,
                  receipt_url,
                  message,
                  remark,
                  order_type,
                } = obj;
                const totalAmountDetail = getTotalAmountDetails(
                  quotation.criteria,
                  product_type,
                  total_amount
                );

                return (
                  <React.Fragment key={index}>
                    <div className="grid-tbody">
                      <div>
                        <ProductTypeIcon type={product_type} />
                        {` `}
                        <a href={`/policies/${order_id}/details`} className="order-detail-link">
                          {`${order_number}`}
                        </a>
                        <PolicyStatus status={status} modalBodyText={message} />
                      </div>
                      <div>
                        {getInsuredByCategory(
                          {
                            title: insured_title,
                            firstname: insured_firstname,
                            lastname: insured_lastname,
                            company_name,
                          },
                          insured_category
                        )}
                        <br />
                        {order_type !== "custom" ? (
                          <>
                            {quotation?.criteria?.car_brand !== undefined ? (
                              <>
                                {`${quotation?.criteria?.car_brand} ${quotation.criteria.car_model}`}
                              </>
                            ) : (
                              "-"
                            )}
                            <br />
                            {product_type === "motor" || product_type === "motorbike"
                              ? `ทะเบียน ${plate_number}`
                              : ""}
                          </>
                        ) : null}
                      </div>
                      <div>
                        <img className="insurer-img" src={insurer_icon} />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {renderYaml(dataYml.product_type[product_type][policy_type])}
                      </div>
                      <div>
                        เริ่ม: {parseDateEN(coverage_from)}
                        <br />
                        ถึง: {parseDateEN(coverage_to)}
                      </div>
                      <div style={{ textAlign: "right" }}>
                        {formatNumberWithCurrency2(sum_insured)}
                      </div>
                      <div style={{ textAlign: "right" }}>
                        {showPaidCommission ? formatNumberWithCurrency(discount) : "" }
                      </div>
                      <div style={{ textAlign: "right" }}>
                        {formatNumberWithCurrency(getDisplayAmount(quotation, total_amount))}
                      </div>
                      {process.env.APP_NAME === "mti" && (
                        <div className="text-primary" style={{ textAlign: "center" }}>
                          {nos_number}
                        </div>
                      )}

                      
                      <div className="text-primary" style={{ textAlign: "right" }}>
                        {showPaidCommission ? formatNumberWithCurrency(getDisplayCommission(quotation, earning_commission)) : "" }
                      </div>

                      <div className="text-secondary" style={{ textAlign: "center" }}>
                        {policy_no}
                        {!!policy_url && <PolicyCopyUrl policyUrl={policy_url} />}
                        {!!jacket_url && <JacketUrl jacketUrl={jacket_url} />}
                        {!!receipt_url && t("receiptAgentDashBoard") && (
                          <ReceiptUrl receiptUrl={receipt_url} />
                        )}
                        {t("trackingPolicy") && obj.status === "success" && (
                          <button
                            type="button"
                            className="flex mt-2 text-xs aling-items-center"
                            onClick={() => {
                              trackingCount.current += 1;
                              currentClickPolicyId.current = obj.id;
                              currentClickOrderNumber.current = order_number;
                              setIsOpenTracking(true);
                            }}
                          >
                            <LocalShippingOutlined
                              className="mr-1 text-secondary-1"
                              fontSize="small"
                            />
                            สถานะการจัดส่งกรมธรรม์
                          </button>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </section>

          <div className="policy-page-component">
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel="Prev"
                nextLabel="Next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pageCount}
                initialPage={Number(currentPage) - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="gm-paginate"
                subContainerClassName="pages pagination"
                activeClassName="active"
                disableInitialCallback={true}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

const WrapToastProvider = ({ location }) => {
  return (
    <ToastProvider>
      <All location={location} />
    </ToastProvider>
  );
};

export default WrapToastProvider;
