import React from "react";

import "./index.scss";

export interface ShowPaySlipButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  title: string;
}

export default function ShowPaySlipButton({
  title = "ใบสลิป",
  className = "",
  onClick,
  ...htmlProps
}: ShowPaySlipButtonProps) {
  return (
    <button
      {...htmlProps}
      className={`btn-pay-slip preview-pay-slip ${className}`}
      onClick={onClick}
    >
      <i className={`material-icons slip-icon`}>perm_media</i> {title}
    </button>
  );
}
