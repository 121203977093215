import React from "react";

const List = ({
  label,
  labelBold = false,
  value,
  bold = false,
  smallText = false,
  align = null,
  color = null,
  type = 1,
}) => {
  return (
    <div className="row">
      <div className={`col-6 mt-3 label ${labelBold ? "bold" : null} type-${type}`}>
        {label}
        {smallText && <small className="block">{smallText}</small>}
      </div>
      <div
        className={`col-6 mt-3 ${bold ? "bold" : null} ${align && `text-${align}`} ${color ? `text-${color}` : null
          }`}
      >
        {value}
      </div>
    </div>
  );
};

export default List;
