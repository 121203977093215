import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { Close, Publish } from "@mui/icons-material";

import { messages } from "~src/helpers/messages";
import { FormGroup, TextField } from "~src/components/form";

export interface UploadOrderModalProps {
  id?: string;
  headerText?: string;
  bodyText?: string;
  buttonText?: string;
  isOpen: boolean;
  data?: Object;
  onClose: () => void;
  onFileSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUpload: () => void;
}

interface UploadIFormInputs {
  file: string;
}

export const UploadOrderModal: React.FC<UploadOrderModalProps> = ({
  id,
  headerText,
  bodyText,
  buttonText,
  isOpen,
  data = {},
  onClose,
  onFileSelected,
  onUpload,
}) => {
  const [file, setFile] = useState(null);
  const uploadForm = useForm<UploadIFormInputs>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFile(e.target.files[0]);
    onFileSelected(e)
  };

  const handleGoToPolicies = (): void => {
    window.location.href = "/staffs/policies?created_from=&created_to=&keyword=&page=1&per_page=10&policy_status[]=waiting_approval";
  };

  const handleGoToUploadHistory = (): void => {
    window.location.href = `/staffs/import-histories/${data["content"]["import_history_id"]}`
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="rounded-3xl">
        <div className="flex flex-row items-center justify-between w-full py-1 pl-4 text-lg text-white border-b bg-primary-color border-custom-gray-200">
          {headerText}
          <IconButton component="span" onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div className="px-4 py-6">
          {Object.keys(data).length === 0 ? (
            <form
              id={id}
              autoComplete="off"
              onSubmit={uploadForm.handleSubmit(onUpload)}
              encType="multipart/form-data"
            >
              <div>{bodyText}</div>
              <div className="flex justify-center w-full mt-4">
                <FormGroup>
                  <TextField
                    type="file"
                    className="truncate bg-white hover:bg-custom-gray-sm"
                    id="file"
                    name="file"
                    inputRef={uploadForm.register({
                      ...messages.uploadFileRequired,
                      ...messages.allowExcelFileType,
                    })}
                    error={uploadForm.errors?.file?.message}
                    onChange={handleFileSelected}
                  />
                </FormGroup>
              </div>
              <div className="flex justify-center w-full mt-2">
                {file === null ? (
                  <button
                    className="px-4 py-2 font-bold text-white rounded-full shadow opacity-50 cursor-not-allowed bg-primary-color"
                    disabled
                  >
                    <Publish /> {buttonText}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="px-4 py-2 font-bold text-white transition duration-300 ease-in-out rounded-full shadow bg-primary-color focus:outline-none hover:bg-primary-color-dark disabled:opacity-50"
                  >
                    <Publish /> {buttonText}
                  </button>
                )}
              </div>
            </form>
          ) : (
            data["success"] ? (
              <div>
                <div className="flex text-lg justify-center">
                  <span>ดำเนินการเสร็จสิ้น</span>
                </div>
                <div className="mt-2 px-4 py-2">
                  นำคำสั่งซื้อจาก <span className="text-blue-600">{data["content"]["filename"]}</span> เข้าระบบแล้ว
                </div>
                <div className="px-4 ">
                  สำเร็จ <span className="text-green-600">{data["content"]["order_count"]}</span> งาน
                  พบปัญหา <span className="text-red-600">{data["content"]["error_count"]}</span> งาน
                </div>
                <div className="flex justify-center mt-4">
                  <button 
                    className="w-32 px-4 py-2 mr-3 font-bold text-white transition duration-300 ease-in-out rounded shadow bg-blue-600 focus:outline-none hover:bg-blue-800"
                    type="button"
                    onClick={handleGoToUploadHistory}
                  >
                    ดูรายละเอียด
                  </button>
                  <button 
                    className="w-32 px-4 py-2 font-bold text-white transition duration-300 ease-in-out rounded shadow bg-primary-color focus:outline-none hover:bg-primary-color-dark"
                    type="button"
                    onClick={handleGoToPolicies}
                  >
                    ดูกรมธรรม์
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div>พบปัญหาในการนำคำสั่งซื้อเข้าระบบ กรุณาติดต่อเจ้าหน้าที่</div>
                <div className="mt-2 px-4 py-2 border rounded-sm border-gray-200 bg-gray-100">
                  {data["message"]}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UploadOrderModal;