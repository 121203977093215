import React, { ChangeEvent } from "react";

import { parseDate, dateFormat } from "~/src/helpers/dateTime";

import {
  FormGroup,
  TextField,
  DatePickerField,
  SelectField,
  Switcher,
} from "~/src/components/form";

import {
  titleAgentTHOptions,
  activeStatusOptions,
  bankOptions,
  mtlSalesBranchOptions,
  educationLevelOptions,
} from "~/src/helpers/options";
import { messages } from "~/src/helpers/messages";
import { useAppDispatch, useAppSelector } from "~src/store";
import { agentInfoSelector, handleChangeAgentInfo } from "./registerSlice";
import { useFormContext } from "react-hook-form";

const BankInfo = () => {
  const methods = useFormContext();
  const agentInfo = useAppSelector(agentInfoSelector);
  const dispatch = useAppDispatch();

  const handleChangeBankAccountInput = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value.replace(/[^0-9]/gi, "");
    const count = value.split("");
    if (count.length > 10) return;
    else dispatch(handleChangeAgentInfo("bank_account_id", value));
  };

  return (
    <div>
      <div className="flex items-center justify-between w-full col-span-1 col-start-1 text-xl bg-custom-gray-sm mb-3">
        <span className="p-2">ข้อมูลบัญชีธนาคาร</span>
      </div>
      <div className="grid grid-cols-2">
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="เลขที่บัญชีธนาคาร" htmlForLabel="bank_account_id" require>
              <TextField
                id="bank_account_id"
                name="bank_account_id"
                onChange={handleChangeBankAccountInput}
                value={agentInfo.bank_account_id}
                inputRef={methods.register({
                  ...messages.required,
                  ...messages.bank_account_id,
                })}
                error={methods.errors.bank_account_id?.message}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2">
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="ชื่อบัญชีธนาคาร" htmlForLabel="bank_account" require>
              <TextField
                id="bank_account"
                name="bank_account"
                inputRef={methods.register({
                  ...messages.required,
                  ...messages.bank_account,
                })}
                error={methods.errors.bank_account?.message}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  dispatch(handleChangeAgentInfo("bank_account", e.target.value))
                }
                value={agentInfo.bank_account}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2">
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="ธนาคารกสิกรไทย เท่านั้น" htmlForLabel="bank_account_provider" require>
              <SelectField
                id="bank_account_provider"
                name="bank_account_provider"
                inputRef={methods.register(messages.requiredSelect)}
                error={methods.errors.bank_account_provider?.message}
                require
                options={bankOptions}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  dispatch(handleChangeAgentInfo("bank_account_provider", e.target.value))
                }
                value={agentInfo.bank_account_provider}
              />
            </FormGroup>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="สาขาธนาคาร" htmlForLabel="bank_account_branch" require>
              <TextField
                id="bank_account_branch"
                name="bank_account_branch"
                inputRef={methods.register({
                  ...messages.required,
                  ...messages.bank_account_branch,
                })}
                error={methods.errors.bank_account_branch?.message}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  dispatch(handleChangeAgentInfo("bank_account_branch", e.target.value))
                }
                value={agentInfo.bank_account_branch}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankInfo;
