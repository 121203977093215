import React, { useState } from "react";
import classnames from "classnames";
import { format } from "date-fns";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { InsuredCategoryMapping } from "../../../../helpers/mappingData";
import Label from "../../../../components/Forms/Label";
import Select from "../../../../components/Forms/Select";
import TextInput from "../../../../components/Forms/TextInput";
import DatePicker from "../../../../components/Forms/DatePicker";
import { messages } from "../../../../helpers/messages";
import { CustomerAddress } from "~src/types/customerInfo";

import { renewConText } from "../store";
import { Insured } from "../../../../types/insured";
import { Order } from "../../../../types/order";
import AddressForm from "../../OrderCustom/components/form/AddressForm";

interface Props {
  onToggle: () => void;
  active: boolean;
  register?: any;
  errors?: any;
  control?: any;
  setValue?: any;
  clearErrors?: any;
}

const titleLists: string[] = ["คุณ", "บริษัท", "นาย", "นาง", "นางสาว", "เด็กชาย", "เด็กหญิง"];

const InsuredInfo: React.FC<Props> = ({
  onToggle,
  active,
  errors,
  register,
  control,
  setValue,
  clearErrors,
}) => {
  const { state, dispatch } = React.useContext(renewConText);
  const [initialAddress, setInitialAddress] = useState(null);

  const handleChangeContent = (
    evt: React.ChangeEvent<HTMLSelectElement>,
    name: keyof Order
  ): void => {
    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          [name]: evt.target.value,
        },
      },
    });
  };

  const handleChangeInsured = (
    evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    name: keyof Insured
  ): void => {
    const insureds = [];
    insureds[0] = {
      ...state.renew.insureds[0],
      [name]: evt.target.value,
    };
    updateInsured(insureds);
  };

  const handleChangeInsuredBirthDate = (date: Date): void => {
    const insureds: Insured[] = [];
    insureds[0] = {
      ...state.renew.insureds[0],
      birthday: format(date, "yyyy-MM-dd"),
    };
    updateInsured(insureds);
  };

  const updateInsured = (insureds: Insured[]): void => {
    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          insureds,
        },
      },
    });
  };

  const handleChangeAddress = (address: CustomerAddress): void => {
    const addresses: CustomerAddress[] = [];
    addresses[0] = {
      ...address
    }
    const deliveryAddressIndex = state.renew.insureds[0].addresses.findIndex(a => a.address_type == "delivery");
    if (deliveryAddressIndex >= 0) {
      addresses.push({
        ...state.renew.insureds[0].addresses[deliveryAddressIndex]
      });
    }
    const insureds: Insured[] = [];
    insureds[0] = {
      ...state.renew.insureds[0],
      addresses: addresses
    };
    
    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          insureds
        }
      }
    })
  };

  return (
    <div className="p-4 mb-4 bg-white shadow rounded-5">
      <h6 className="flex items-center justify-between p-1 px-2 text-sm font-bold bg-gray-100">
        ข้อมูลผู้เอาประกัน
        <button
          type="button"
          onClick={onToggle}
          className="flex items-center justify-center w-6 h-6 bg-white shadow outline-none rounded-5 focus:outline-none"
        >
          {active ? <RemoveIcon /> : <AddIcon />}
        </button>
      </h6>
      <div className={classnames(["px-4 mt-3", !active ? "hidden" : "block"])}>
        <div className="w-full">
          <div className="flex flex-wrap items-center">
            <Label htmlFor="renew[insured_category" required>
              ประเภทผู้เอาประกัน
            </Label>
            <Select
              name="renew[insured_category]"
              id="renew[insured_category"
              value={state.renew?.insured_category}
              onChange={(evt) => handleChangeContent(evt, "insured_category")}
              inputRef={register({ ...messages.required })}
              error={errors?.renew?.insured_category?.message}
            >
              <option value="">กรุณาเลือก</option>
              <>
                {["person", "legal_entity"].map((option, index) => (
                  <option key={index} value={option}>
                    {InsuredCategoryMapping[option]}
                  </option>
                ))}
              </>
            </Select>
          </div>

          <div className="flex flex-wrap mt-6 -mx-2">
            <div className="w-3/12 px-3 mb-4">
              <Label htmlFor="renew[insured][title]" required>
                คำนำหน้าชื่อ
              </Label>
              <Select
                name="renew[insured][title]"
                id="renew[insured][title]"
                value={state.renew?.insureds[0].title}
                onChange={(evt) => handleChangeInsured(evt, "title")}
                inputRef={register({ ...messages.required })}
                error={errors?.renew?.insured?.title?.message}
              >
                {titleLists.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-4/12 px-2 mb-4">
              <Label htmlFor="renew[insured][firstname]" required>
                ชื่อจริง
              </Label>
              <TextInput
                id="renew[insured][firstname]"
                name="renew[insured][firstname]"
                value={state.renew?.insureds[0].firstname}
                inputRef={register({ ...messages.required })}
                error={errors?.renew?.insured?.firstname?.message}
                onChange={(evt) => handleChangeInsured(evt, "firstname")}
              />
            </div>
            <div className="w-5/12 px-2 mb-4">
              <Label htmlFor="renew[insured][lastname]" required>
                นามสกุล
              </Label>
              <TextInput
                id="renew[insured][lastname]"
                name="renew[insured][lastname]"
                value={state.renew?.insureds[0].lastname}
                inputRef={register({ ...messages.required })}
                error={errors?.renew?.insured?.lastname?.message}
                onChange={(evt) => handleChangeInsured(evt, "lastname")}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-2">
            <div className="w-1/2 px-2 mb-4">
              <Label htmlFor="renew[insured][birthday]" required>
                วัน เดือน ปี เกิด
              </Label>
              <DatePicker
                id="renew[insured][birthday]"
                dateFormat="dd/MM/yyyy"
                selected={state.renew?.insureds && new Date(state.renew?.insureds[0].birthday)}
                onChange={(date: Date): void => handleChangeInsuredBirthDate(date)}
              />
              <input
                type="hidden"
                name="renew[insured][birthday]"
                ref={register}
                value={state.renew?.insureds[0].birthday.toString()}
              />
            </div>

            <div className="w-1/2 px-2 mb-4">
              <Label htmlFor="renew[insured][idcard]" required>
                เลขประจำตัวบัตรประชาชน
              </Label>
              <TextInput
                id="renew[insured][idcard]"
                name="renew[insured][idcard]"
                value={state.renew?.insureds[0].idcard}
                inputRef={register({
                  ...messages.required,
                  ...messages.validateIdCard,
                })}
                error={errors?.renew?.insured?.idcard?.message}
                onChange={(evt) => handleChangeInsured(evt, "idcard")}
              />
            </div>

            <div className="w-1/2 px-2 mb-4">
              <Label htmlFor="renew[insured][phone_number]" required>
                เบอร์มือถือ
              </Label>
              <TextInput
                id="renew[insured][phone_number]"
                name="renew[insured][phone_number]"
                value={state.renew?.insureds[0].phone_number}
                inputRef={register({
                  ...messages.required,
                  ...messages.mobileNumber,
                })}
                error={errors?.renew?.insured?.phone_number?.message}
                onChange={(evt) => handleChangeInsured(evt, "phone_number")}
              />
            </div>
          </div>
          <div className="grid grid-cols-4 col-span-5 gap-4">
            <AddressForm
              name="renew[insured]"
              setValue={setValue}
              register={register}
              clearErrors={clearErrors}
              errors={errors?.renew?.insured}
              control={control}
              value={state.renew?.insureds[0]?.addresses?.[0]}
              onChange={handleChangeAddress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuredInfo;
