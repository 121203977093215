import { te } from "date-fns/locale";
import { formatNumberWithCurrency } from "../../../../helpers/formatNumber";

export interface RawData {
  order_qty: number;
  product_type: string;
  policy_type: string;
  monthly_commission: string;
  monthly_premium: string;
  year_month: string;
}


export type Display = "premiums" | "commissions";
export type Timeline = "annually" | "12month";

export interface TableData {
  productType: string;
  policies: {
    policyType: string;
    commissions: any[];
    premiums: any[];
    totals: Record<Display, string>;
  }[];
}

export interface ChartData {
  month: string;
  motorValue: number;
  otherValue: number;
  year: string;
}

export interface ChartDatas {
  premium: ChartData[];
  commission: ChartData[];
}

export interface TableHeader {
  headers: string[];
  subHeaders: string[];
  template: string[];
  monthHeader:string[];
}

export const RowTemplate = {
  "01": {
    month: "01",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "02": {
    month: "02",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "03": {
    month: "03",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "04": {
    month: "04",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "05": {
    month: "05",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "06": {
    month: "06",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "07": {
    month: "07",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "08": {
    month: "08",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "09": {
    month: "09",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "10": {
    month: "10",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "11": {
    month: "11",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
  "12": {
    month: "12",
    value: { endMonth: "0", midMonth: "0" },
    year: "",
  },
};

export type MonthNumber = keyof typeof RowTemplate;

export const MonthMapping: Record<
  MonthNumber,
  { short: string; full: string }
> = {
  "01": { short: "ม.ค.", full: "มกราคม" },
  "02": { short: "ก.พ.", full: "กุมภาพันธ์" },
  "03": { short: "มี.ค.", full: "มีนาคม" },
  "04": { short: "เม.ย.", full: "เมษายน" },
  "05": { short: "พ.ค.", full: "พฤษภาคม" },
  "06": { short: "มิ.ย.", full: "มิถุนายน" },
  "07": { short: "ก.ค.", full: "กรกฎาคม" },
  "08": { short: "ส.ค.", full: "สิงหาคม" },
  "09": { short: "ก.ย.", full: "กันยายน" },
  "10": { short: "ต.ค.", full: "ตุลาคม" },
  "11": { short: "พ.ย.", full: "พฤศจิกายน" },
  "12": { short: "ธ.ค.", full: "ธันวาคม" },
};

export interface RowDataTemplate {
  month: string;
  value: string | number;
  yearMonth: string;
}

export interface TableCommissionData {
    month:string
    policies:any[]
}


export const buildChartData = async (
  data: RawData[],
  endedAt: MonthNumber = "12",
  timeline: Timeline
): Promise<ChartDatas> => {
  return new Promise((resolve) => {
    if (!data) {
      resolve(null);
      return;
    }

    const months = Object.keys(RowTemplate) as MonthNumber[];
    const premium = createTemplate("premium", months, data, endedAt, timeline);
    const commission = createTemplate(
      "commission",
      months,
      data,
      endedAt,
      timeline
    );

    resolve({ premium, commission });
  });
};

const createTemplate = (
  type: string,
  months,
  data: RawData[],
  endedAt: MonthNumber,
  timeline: Timeline
): ChartData[] => {
  const template: Partial<Record<MonthNumber, ChartData>> = months.reduce(
    (obj, m) => ({
      ...obj,
      [m]: {
        month: `${("0" + m).slice(-2)}`,
        monthShort: `${MonthMapping[m].short}`,
        motorValue: 0,
        otherValue: 0,
        year: "",
      },
    }),
    {}
  );

  for (const item of data) {
    const updatingKey = /motor/i.test(item.product_type)
      ? "motorValue"
      : "otherValue";

    template[getMonth(item.year_month)]["year"] = item.year_month;
    template[getMonth(item.year_month)][updatingKey] = sum(
      template[getMonth(item.year_month)][updatingKey],
      item[`monthly_${type}`]
    );
  }

  const templateEntries = Object.entries(template).sort();
  const splitIndex = templateEntries.findIndex(([k]) => k === endedAt);

  let result = [
    ...templateEntries.slice(splitIndex + 1).map((v) => v[1]),
    ...templateEntries.slice(0, splitIndex + 1).map((v) => v[1]),
  ];

  if (timeline === "12month") {
    result = sortRowTemplate12MonthChart(result);
  }

  return result;
};

export const sortedTableCells = (
  row: any,
  timeline: Timeline
): { format: any[]; values: any[] } => {
  if (!row) return { format: [], values: [] };
  const dynamicTotalRows = [];
  const dynamicTotals = [];
  const cellEntries = Object.entries(row).sort((a, b) =>
    a[0].localeCompare(b[0])
  );
  const splitIndex = cellEntries.findIndex((c) => `${c[0]}` === "12");

  let result = [
    ...cellEntries.slice(splitIndex + 1).map((v) => v[1]),
    ...cellEntries.slice(0, splitIndex + 1).map((v) => v[1]),
  ];

  if (timeline === "12month") {
    result = sortRowTemplate12Month(result);
  }

  result.forEach((o) => {
    dynamicTotalRows.push(formatNumberWithCurrency(parseFloat(o.value.midMonth)));
    dynamicTotalRows.push(formatNumberWithCurrency(parseFloat(o.value.endMonth)));
    dynamicTotals.push(parseFloat(o.value.midMonth));
    dynamicTotals.push(parseFloat(o.value.endMonth));
  });

  const total = formatNumberWithCurrency(
    parseFloat(dynamicTotals.reduce((pre, curr) => pre + parseFloat(curr), 0))
  );

  return {
    format: [...dynamicTotalRows],
    values: [total],
  };
};

export const sumTableRow = (row) =>
  formatNumberWithCurrency(
    row.reduce((pre, cur) => pre + parseFloat(cur.value), 0)
  );

export const buildTableHeaders = (
  data: any[],
  timeline: Timeline,
  display: Display,
  selectedYear: string
): TableHeader => {
  const dataList = [];
  const dynamicHeaders = [];
  const dynamicSubHeaders = [];
  const dynamicTemplate = [];
  const dynamicYearMonth = [];
  let stampYear = selectedYear;

  data.forEach((l) => {
    l.policies.forEach((e) =>
      dataList.push(e[display].filter((r) => r.year !== ""))
    );
  });

  const concatData = [].concat.apply([], dataList);
  const reduceData = replaceDuplicate(concatData, "year");
  const template = [...reduceData];

  for (let i = 0; i < 12; i++) {
    template.push({ month: ("0" + (i + 1)).slice(-2), value: "0", year: "" });
  }

  let reduceTemplate = replaceDuplicate(template, "month");

  if (timeline === "12month") {
    reduceTemplate.sort((a, b) => a.year.localeCompare(b.year));

    stampYear = (
      parseInt(getYear(reduceTemplate[reduceTemplate.length - 1].year)) - 1
    ).toString();

    reduceTemplate = sortRowTemplate12Month(reduceTemplate);
  } else {
    const templateCurrentYear = reduceTemplate.filter(
      (o) => parseInt(getYear(o.year)) <= new Date().getFullYear()
    );

    templateCurrentYear.length > 0
      ? reduceTemplate.sort((a, b) => a.month.localeCompare(b.month))
      : reduceTemplate;
  }

  reduceTemplate.forEach((o) => {
    const addYear = o.year === "" ? stampYear : getYear(o.year);
    const lastDayOfMonth = new Date(parseInt(addYear), o.month, 0);
    dynamicHeaders.push(`${MonthMapping[o.month].full} ${addYear}`);
    dynamicSubHeaders.push(...[
      '1 - 15',
      `16 - ${lastDayOfMonth.getDate()}`
    ])
    dynamicTemplate.push(o.month);
    dynamicYearMonth.push(
      ...[
        `${addYear}-${o.month}-15`,
        `${addYear}-${o.month}-${lastDayOfMonth.getDate()}`
      ]
    );
  });

  return {
    headers: ["ประกันภัย", "เดือนแจ้งงาน", ...dynamicHeaders, "ยอดสะสม"],
    subHeaders: ["", "รอบแจ้งงาน", ...dynamicSubHeaders, ""],
    template: [...dynamicTemplate],
    monthHeader:[...dynamicYearMonth],
  };
};

export const buildTableRows = (data: any[], template: string[]): string[] => {
  const dataRows = [];
  const dynamicRows = [];

  template.forEach((o) => dataRows.push(data.filter((m) => m.month === o)[0]));
  dataRows.forEach((p) =>
    dynamicRows.push(
      ...[
        formatNumberWithCurrency(parseFloat(p.value.midMonth)),
        formatNumberWithCurrency(parseFloat(p.value.endMonth)),
      ]
    )
  );

  return [...dynamicRows];
};

export const buildTableData = async (
  data: RawData[],
  endedAt: MonthNumber = "12",
  timeline: Timeline
): Promise<TableData[]> => {
  return new Promise((resolve) => {
    const productTypes = uniq(pluck(data, "product_type"));
    const tableData: TableData[] = productTypes.map((productType) => ({
      productType,
      policies: [],
    }));

    for (const item of tableData) {
      const dataByTypes = filterBy(data, "product_type", item.productType);
      const policyTypes = uniq(pluck(dataByTypes, "policy_type"));

      for (const pType of policyTypes) {
        const dataByPolicy = filterBy(dataByTypes, "policy_type", pType);
        const commissions = { ...RowTemplate };
        const premiums = { ...RowTemplate };

        for (const data of dataByPolicy) {
          commissions[getMonth(data.year_month)] = {
            month: getMonth(data.year_month),
            value: {
              midMonth: sum(
                commissions[getMonth(data.year_month)].value.midMonth,
                parseInt(getDate(data.year_month)) <= 15 ? data.monthly_commission : "0"
              ),
              endMonth: sum(
                commissions[getMonth(data.year_month)].value.endMonth,
                parseInt(getDate(data.year_month)) > 15 ? data.monthly_commission : "0"
              )
            },
            year: data.year_month,
          };
          premiums[getMonth(data.year_month)] = {
            month: getMonth(data.year_month),
            value: {
              midMonth: sum(
                premiums[getMonth(data.year_month)].value.midMonth,
                parseInt(getDate(data.year_month)) <= 15 ? data.monthly_premium : "0"
              ),
              endMonth: sum(
                premiums[getMonth(data.year_month)].value.endMonth,
                parseInt(getDate(data.year_month)) > 15 ? data.monthly_premium : "0"
              )
            },
            year: data.year_month,
          };
        }

        item.policies.push({
          policyType: pType,
          commissions: buildRows(commissions, endedAt),
          premiums: buildRows(premiums, endedAt),
          totals: {
            commissions: sumTableRow(buildRows(commissions, endedAt)),
            premiums: sumTableRow(buildRows(premiums, endedAt)),
          },
        });
      }
    }
    
    resolve(tableData);
  });
};


const buildRows = (o, endedAt) => {
  const templateEntries = Object.entries(o).sort();
  const splitIndex = templateEntries.findIndex(([k]) => k === endedAt);

  return [
    ...templateEntries.slice(splitIndex + 1).map((v) => v[1]),
    ...templateEntries.slice(0, splitIndex + 1).map((v) => v[1]),
  ];
};

const sortRowTemplate12Month = (rowTemplate) => {
  const templateCurrentYear = rowTemplate.filter(
    (o) => parseInt(getYear(o.year)) == new Date().getFullYear()
  );

  templateCurrentYear.forEach((o, i) => {
    if (templateCurrentYear.length === 0) return rowTemplate;
    else {
      if (templateCurrentYear[templateCurrentYear.length - 2] === undefined) {
        for (
          let i = 1;
          i <
          parseInt(templateCurrentYear[templateCurrentYear.length - 1].month);
          i++
        ) {
          rowTemplate.splice(0, 1);
          rowTemplate.splice(11 - i, 0, {
            month: ("0" + i).slice(-2),
            value: "0",
            year: `${new Date().getFullYear()}-${("0" + i).slice(-2)}`,
          });
        }

        rowTemplate.forEach((o, i) => {
          if (o.year === "") {
            return (rowTemplate[i].year = `${new Date().getFullYear() - 1}-${
              o.month
            }`);
          }
        });
      }
    }
  });

  return rowTemplate.sort((a, b) => a.year.localeCompare(b.year));
};

const sortRowTemplate12MonthChart = (rowTemplate) => {
  const templateCurrentYear = rowTemplate.filter(
    (o) => parseInt(getYear(o.year)) == new Date().getFullYear()
  );

  templateCurrentYear.forEach((o, i) => {
    if (templateCurrentYear.length === 0) return rowTemplate;
    else {
      if (templateCurrentYear[templateCurrentYear.length - 2] === undefined) {
        for (
          let i = 1;
          i <
          parseInt(templateCurrentYear[templateCurrentYear.length - 1].month);
          i++
        ) {
          rowTemplate.splice(0, 1);
          rowTemplate.splice(11 - i, 0, {
            month: ("0" + i).slice(-2),
            monthShort: MonthMapping[("0" + i).slice(-2)].short,
            motorValue: 0,
            otherValue: 0,
            year: `${new Date().getFullYear()}-${("0" + i).slice(-2)}`,
          });
        }

        rowTemplate.forEach((o, i) => {
          if (o.year === "") {
            return (rowTemplate[i].year = `${new Date().getFullYear() - 1}-${
              o.month
            }`);
          }
        });
      }
    }
  });

  return rowTemplate.sort((a, b) => a.year.localeCompare(b.year));
};

export const buildTableTotals = (
  data: RawData[]
): Promise<Record<Display, typeof RowTemplate>> => {
  return new Promise((resolve) => {
    let commissions = { ...RowTemplate };
    let premiums = { ...RowTemplate };

    for (const d of data) {
      commissions[getMonth(d.year_month)] = {
        month: getMonth(d.year_month),
        value: {
          midMonth: sum(
            commissions[getMonth(d.year_month)].value.midMonth,
            parseInt(getDate(d.year_month)) <= 15 ? d.monthly_commission : "0"
          ),
          endMonth: sum(
            commissions[getMonth(d.year_month)].value.endMonth,
            parseInt(getDate(d.year_month)) > 15 ? d.monthly_commission : "0"
          )
        },
        year: d.year_month,
      };
      premiums[getMonth(d.year_month)] = {
        month: getMonth(d.year_month),
        value: {
          midMonth: sum(
            premiums[getMonth(d.year_month)].value.midMonth,
            parseInt(getDate(d.year_month)) <= 15 ? d.monthly_premium : "0"),
          endMonth: sum(
            premiums[getMonth(d.year_month)].value.endMonth,
            parseInt(getDate(d.year_month)) > 15 ? d.monthly_premium : "0")
        },
        year: d.year_month,
      };
    }
    resolve({ commissions, premiums });
  });
};

const uniq = <T>(args: T[]): T[] => [...new Set(args)];

const pluck = <T, K extends keyof T>(arr: T[], key: K): T[K][] =>
  arr.map((i) => i[key]);

const filterBy = <T, K extends keyof T>(arr: T[], key: K, value: T[K]): T[] =>
  arr.filter((i) => i[key] === value);

const replaceDuplicate = (o: RowDataTemplate[], key: string) =>
  o.reduce(
    (acc, current) =>
      acc.find((item) => item[key] === current[key])
        ? acc
        : acc.concat([current]),
    []
  );

export const sum = (a: string | number, b: string | number): number =>
  parseFloat(`${a}`) + parseFloat(`${b}`);

const formatCells = (
  cells: typeof RowTemplate,
  endedAt: MonthNumber = "12"
): string[] => 
  sortedTableCells(cells, endedAt).map((v) => formatNumberWithCurrency(v));

export const getDate = (yearMonth: string) => yearMonth.split("-")[2];
export const getMonth = (yearMonth: string) => yearMonth.split("-")[1];
export const getYear = (yearMonth: string) => yearMonth.split("-")[0];
