import React, { useState, useEffect } from 'react'
import { getFinancialInstitution } from '~/src/api/FinancialInstitution';
import { messages } from '~/src/helpers/messages';
import { FormGroup, Switcher, SelectField } from "~/src/components/form";
import { InsuredsAttributes, Beneficiary } from '~/src/types/checkoutBasicForm';

interface BeneficiaryInformationInputProps {
  beneficiary: Beneficiary;
  attrPrefixBenef: string;
  insuredsAttributes?: InsuredsAttributes;
  errors: any;
  register: (msg) => void;
  handleChangeBeneficially: (event: React.FormEvent, attrName: string) => void;
}

const BeneficiaryInformationInput: React.FC<BeneficiaryInformationInputProps> = ({
  beneficiary,
  attrPrefixBenef,
  insuredsAttributes,
  errors,
  register,
  handleChangeBeneficially,
}) => {
  const [financeNameOptions, setFinanceNameOptions] = useState([]);
  const optionsBeneficiary = [
    {
      label: "ทายาทโดยธรรม",
      value: "legal_heir",
    },
    {
      label: "สถาบันการเงิน",
      value: "finance",
    },
  ]

  useEffect(() => {
    getFinancialInstitution().then(result => {
      setFinanceNameOptions(result.data.content.map((institution) => (
        { label: institution.name, 
          value: institution.code 
        }
      )));
    });
  }, [])

  return (
    <>
      <h2>ข้อมูลผู้รับผลประโยชน์</h2>
      <div className="row">
        <div className="col-md-6">
          <FormGroup label="ผู้รับผลประโยชน์" require>
            <Switcher
              id="beneficiary"
              name={`${attrPrefixBenef}[receiver]`}
              active={insuredsAttributes?.beneficiary.receiver}
              onChange={(evt) => handleChangeBeneficially(evt, "receiver")}
              labels={optionsBeneficiary}
              inputRef={register}
            />
          </FormGroup>
          <input
            type="hidden"
            name={`${attrPrefixBenef}[id]`}
            value={beneficiary.id}
            ref={register}
          />
          <input
            type="hidden"
            name={`${attrPrefixBenef}[receiver]`}
            value={insuredsAttributes?.beneficiary.receiver}
            ref={register}
          />
        </div>
      </div>

      {insuredsAttributes?.beneficiary.receiver == "finance" &&
        <div className="beneficiary_box">
          <div className="row">
            <div className="col-md-6">
              <FormGroup
                label="สถาบันการเงิน"
                htmlForLabel="financial_institution_code"
                require
              >
                <SelectField
                  id="financial_institution_code"
                  name={`${attrPrefixBenef}[financial_institution_code]`}
                  value={insuredsAttributes?.beneficiary.financial_institution_code}
                  onChange={(evt) => handleChangeBeneficially(evt, "financial_institution_code")}
                  inputRef={register(messages.requiredSelect)}
                  options={financeNameOptions}
                  error={errors?.order?.insureds_attributes?.beneficiary_attributes?.financial_institution_code?.message}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      }

    </>
  )
}

export default BeneficiaryInformationInput