import React from "react";

const ProductTypeIcon = ({ type }) => {
  switch (type) {
    case "motor":
      return <i className="icon icon-Motor mr-1"></i>;
    case "travel":
      return <i className="icon icon-travel mr-1"></i>;
    default:
      return <i className="icon gettmore-icon-policylist mr-1"></i>;
  }
};

export default ProductTypeIcon;
