import React, { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { parseDate, dateFormat } from "~/src/helpers/dateTime";
import { FormGroup, TextField, DatePickerField, Switcher } from "~/src/components/form";

import { messages } from "~/src/helpers/messages";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  agentInfoSelector,
  agentLicenseSelector,
  handleChangeAgentLicense,
  handleChangeHasMotorLicense,
} from "./registerSlice";

const HasMotorLicense = () => {
  const agentForm = useFormContext();
  const agentInfo = useAppSelector(agentInfoSelector);
  const license = useAppSelector(agentLicenseSelector);
  const dispatch = useAppDispatch();

  return (
    <div>
      <div className="flex items-center justify-between w-full col-span-1 col-start-1 text-xl bg-custom-gray-sm mb-3">
        <span className="p-2">ข้อมูลใบอนุญาตประกันวินาศภัย</span>
        <div className="m-3 justify-self-end" data-a={agentInfo.has_motor_license}>
          <Switcher
            id="has_motor_license"
            name="has_motor_license"
            inputRef={agentForm.register}
            error={agentForm.errors.has_motor_license?.message}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              dispatch(handleChangeHasMotorLicense(event.target.value === "true"))
            }
            active={agentInfo.has_motor_license}
            labels={[
              { label: "มี", value: true },
              { label: "ไม่มี", value: false },
            ]}
          />
        </div>
      </div>

      {agentInfo.has_motor_license && (
        <>
          <div className="flex flex-col py-3 space-y-2">
            <div className="grid grid-rows-2 gap-x-3">
              <TextField
                id="id"
                name={`licenses_attributes[0].id`}
                type="hidden"
                value={license.id}
                inputRef={agentForm.register}
              />
              <input
                id="insurance_type"
                type="hidden"
                name={`licenses_attributes[0].insurance_type`}
                value="non-life"
                ref={agentForm.register}
              />
              <div className="col-span-1 col-start-1">
                <FormGroup label="เลขที่ใบอนุญาต" htmlForLabel="license_no" require>
                  <TextField
                    id="license_no"
                    name={`licenses_attributes[0].license_no`}
                    value={license.license_no}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      dispatch(handleChangeAgentLicense("license_no", e.target.value))
                    }
                    inputRef={agentForm.register(messages.required)}
                    error={agentForm.errors.licenses?.[0]?.license_no?.message}
                  />
                </FormGroup>
              </div>
              <div className="col-span-1 col-start-1">
                <FormGroup label="วันที่ออกใบอนุญาต" htmlForLabel="issued_date" require>
                  <DatePickerField
                    type="text"
                    id="issued_date"
                    name={`licenses_attributes[0].issued_date`}
                    defaultView="decade"
                    maxDate={parseDate(dateFormat(new Date()))}
                    callbackvalue={(date: string) =>
                      dispatch(handleChangeAgentLicense("issued_date", date))
                    }
                    inputRef={agentForm.register(messages.required)}
                    value={license.issued_date}
                    error={agentForm.errors.licenses?.[0]?.issued_date?.message}
                  />
                </FormGroup>
              </div>

              <div className="col-span-1">
                <FormGroup label="วันหมดอายุใบอนุญาต" htmlForLabel="expired_date" require>
                  <DatePickerField
                    type="text"
                    id="expired_date"
                    name={`licenses_attributes[0].expired_date`}
                    defaultView="decade"
                    minDate={parseDate(license.issued_date)}
                    callbackvalue={(date: string) =>
                      dispatch(handleChangeAgentLicense("expired_date", date))
                    }
                    inputRef={agentForm.register(messages.required)}
                    value={license.expired_date}
                    error={agentForm.errors.licenses?.[0]?.expired_date?.message}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HasMotorLicense;
