import React, { useEffect, useState } from "react";
import _ from "lodash";
import queryString from "query-string";
import ReactPaginate from "react-paginate";

import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert, Info, GetApp } from "@mui/icons-material";

import axios from "~src/helpers/configAxios";
import { urlStringify } from "~src/helpers/helpers";
import { reformatDateTimeEN } from "~src/helpers/date";
import useFetch from "~src/hooks/useFetch";
import ErrorPage from "~src/components/errorPage";
import SectionHeader from "~src/components/layout/staff/SectionHeader";
import SearchForm from "~src/components/staff/SearchForm";
import ImportHistoryInfo from "~src/models/ImportHistoryInfo";

const BASE_URL = "/staffs/import-histories";
const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

interface ImportHistoryListProps {
  location: any;
}

const ImportHistoryList: React.FC<ImportHistoryListProps> = ({ location }) => {
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });
  const historiesFetch = useFetch(`/api/import_histories?${urlStringify({
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    type: 'Order',
    ...queryParams,
  })}`);

  const [isError, setIsError] = useState(false);
  const [queryStr] = useState(queryParams);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(queryParams.page ?? DEFAULT_PAGE);
  const [pageCount, setPageCount] = useState(0);
  const [filterState, setFilterState] = useState({
    keyword: queryParams.keyword ?? ""
  });
  const [importHistories, setImportHistories] = useState<ImportHistoryInfo[]>([]);
  const [menuElement, setMenuElement] = React.useState({});

  useEffect(() => {
    if(!historiesFetch.isLoading) {
      const historiesData = historiesFetch.response.content.import_histories;
      const totalPages = historiesFetch.response.content.total_pages;
      
      setImportHistories(historiesData)
      setPageCount(totalPages);
    } else if (historiesFetch.error) {
      setIsError(true);
    }
  }, [
    historiesFetch.response,
    historiesFetch.error,
    historiesFetch.isLoading,
  ]);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterState({
      ...filterState,
      keyword: e.target.value,
    });
  };

  const handleClickSearch = (): void => {
    const params = getSearchParams();
    const queries = urlStringify(params);

    window.location.href = `${BASE_URL}?${queries}`;
  };

  const handleKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") handleClickSearch();
  };

  const handlePageClick = (e: any): void => {
    const selectedPage = parseInt(e.selected) + 1;
    const params = getSearchParams();
    params.page = selectedPage;
    const queries = urlStringify(params);
    window.location.href = `${BASE_URL}?${queries}`;
  };

  const handleOpenMenu = (e) => {
    const menuId = e.currentTarget.id;
    setMenuElement({
      ...menuElement,
      [menuId]: e.currentTarget,
    });
  };

  const handleCloseMenu = () => {
    setMenuElement({});
  };

  const handleOpenDetail = (id: number) => {
    window.location.href = `/staffs/import-histories/${id}`
  }

  const handleDownloadImportFile = (id: number) => {
    setMenuElement({});
    
    axios
      .get(`/api/import_histories/${id}/file`, {
        responseType: "arraybuffer",
      })
      .then((result) => {
        const filenameRegex = /filename\*?=([^']*'')?([^;]*)/;
        const filename = decodeURI(filenameRegex.exec(result.headers["content-disposition"])[2]);
        const uriString =
          "data:" +
          result.headers["content-type"] +
          ";base64," +
          encodeURI(Buffer.from(result.data, "binary").toString("base64"));
        let a = document.createElement("a");
        a.href = uriString;
        a.download = decodeURI(filename);
        a.click();
      })
      .catch((error) => {
        alert("File not found");
      });
  }

  const getSearchParams = (): any => {
    const { keyword } = filterState;
    return {
      keyword,
      per_page: PER_PAGE,
      page: 1,
    };
  };

  return (
    <div>
      {isError ? (
        <ErrorPage />
      ) : (
        <div className="flex flex-col p-4">
          <SectionHeader
            className="w-full"
            title="ประวัติการนำเข้า"
            breadcrumbs={[{ url: "#", label: "ประวัติการนำเข้า", current: true }]}
            searchComponent={
              <SearchForm
                type="text"
                placeholder="ค้นหาชื่อไฟล์"
                onChange={handleChangeSearch}
                handleClick={handleClickSearch}
                handleKeyPress={handleKeyPressSearch}
                value={filterState.keyword}
              />
            }
          />
          <div className="h-5">
            {totalRecords > 0 && (
              <div className="flex justify-end mt-1 mb-2 text-1xs text-custom-gray-md">
                {queryStr?.search && (
                  <span className="mr-1">
                    คำค้นหา:
                    <span className="mx-1 font-bold">"{queryStr.search}"</span>
                    พบ
                  </span>
                )}
                {totalRecords < 20 ? (
                  <span>
                    ทั้งหมด
                    <span className="mx-1 font-bold">{totalRecords}</span>
                    รายการ
                  </span>
                ) : (
                  <span>รายการทั้งหมด 20+ รายการ</span>
                )}
              </div>
            )}
          </div>
          <table className={"table-auto text-left mt-4"}>
            <thead className="text-1xs text-custom-gray-md">
              <tr>
                <th className="px-1 py-2 font-normal">วันที่</th>
                <th className="px-1 py-2 font-normal">ชื่อไฟล์</th>
                <th className="px-1 py-2 font-normal content-center">ประเภท</th>
                <th className="px-1 py-2 font-normal">นำเข้าสำเร็จ</th>
                <th className="px-1 py-2 font-normal">นำเข้าผิดพลาด</th>
                <th className="w-4 px-1 py-2 font-normal"></th>
              </tr>
            </thead>
            <tbody className="text-xs divide-y-4 divide-custom-gray-sm">
              {historiesFetch.isLoading ? (
                <tr className="mb-1 bg-white">
                  <td colSpan={10} className="px-1 py-2 text-center">
                    กำลังโหลด...
                  </td>
                </tr>
              ) : (
                <>
                  {importHistories.length == 0 ? (
                    <tr className="mb-1 bg-white">
                      <td colSpan={10} className="px-1 py-2 text-center">
                        ไม่พบข้อมูล
                      </td>
                    </tr>
                  ) : (
                    <>
                      {importHistories.map((history, index) => (
                        <tr className="mb-1 transition duration-300 bg-white hover:bg-primary-color hover:bg-opacity-50">
                          <td className="px-1 py-2 font-normal">{reformatDateTimeEN(new Date(history.created_at))}</td>
                          <td className="px-1 py-2 font-normal">{history.filename}</td>
                          <td className="px-1 py-2 font-normal content-center">{history.import_type}</td>
                          <td className="px-1 py-2 font-normal text-green-600">{history.order_count}</td>
                          <td className="px-1 py-2 font-normal text-red-600">{history.error_count}</td>
                          <td className="w-4">
                            <IconButton id={index} component="span" ria-haspopup="true" onClick={handleOpenMenu}>
                              <MoreVert />
                            </IconButton>
                            <Menu
                              id={index}
                              anchorEl={menuElement[index]}
                              keepMounted
                              open={Boolean(menuElement[index])}
                              onClose={handleCloseMenu}
                            >
                              <MenuItem onClick={() => handleOpenDetail(history.id)}>
                                <Info className="mr-1" />
                                รายละเอียด
                              </MenuItem>
                              <MenuItem onClick={() => handleDownloadImportFile(history.id)}>
                                <GetApp className="mr-1" />
                                ดาวน์โหลดไฟล์
                              </MenuItem>
                            </Menu>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
          {pageCount > 1 && (
            <ReactPaginate
              previousLabel="Prev"
              nextLabel="Next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              initialPage={Number(currentPage) - 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="gm-paginate"
              subContainerClassName="pages pagination"
              activeClassName="active"
              disableInitialCallback
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ImportHistoryList;