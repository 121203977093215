import React from "react";

interface Props {
  items: CarouselData[];
  duration?: number;
}

interface CarouselData {
  title: string;
  image_url: string;
  url: string;
}

const Carousel: React.FC<Props> = ({ items, duration = 5000 }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const lastIndex = items ? items.length - 1 : 0;
      setCurrentSlide((currentState) => (currentState === lastIndex ? 0 : currentState + 1));
    }, duration);

    return () => clearInterval(interval);
  }, [items]);

  const handlePage = (actionType: string): void => {
    if (actionType === "next") setCurrentSlide((currentState) => currentState + 1);
    else setCurrentSlide((currentState) => currentState - 1);
  };

  return (
    <div className="pb-2 mt-4">
      <div className="relative overflow-hidden rounded">
        {!items ? (
          <div style={{ paddingBottom: "100%" }} className="relative bg-white animate-pulse">
            <div className="absolute top-0 left-0 w-full h-full bg-gray-300" />
            <div className="absolute bottom-0 w-full h-10 p-5 pb-10 bg-white">
              <div className="w-full h-5 bg-gray-300"></div>
            </div>
          </div>
        ) : (
          <>
            {items.length === 0 && (
              <div>
                <div style={{ paddingBottom: "100%" }} className="relative bg-white">
                  <div className="absolute top-0 left-0 w-full h-full bg-gray-300 justify-center items-center flex">
                    ไม่พบข้อมูลโปรโมชั่น
                  </div>
                </div>
              </div>
            )}
            {items.map((o, i) => (
              <React.Fragment key={i}>
                {currentSlide === i && (
                  <>
                    <div className="relative">
                      <div className="relative bg-black" style={{ paddingBottom: "100%" }}>
                        <img
                          src={o.image_url}
                          alt={o.title}
                          className="absolute max-w-none animated fadeIn"
                          style={{
                            width: "100%",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </div>
                      <div className="absolute bottom-0 w-full p-5 pb-12 text-center text-white bg-gradient">
                        {o.title}
                      </div>
                    </div>
                    <a
                      href={o.url}
                      className="absolute top-0 left-0 w-full h-full"
                      target="_blank"
                    ></a>
                  </>
                )}
              </React.Fragment>
            ))}
            {items.length > 1 && (
              <div className="absolute bottom-0 flex w-full pb-2">
                <button
                  onClick={() =>
                    currentSlide === 0 ? setCurrentSlide(items.length - 1) : handlePage("prev")
                  }
                  className="flex items-center justify-center w-8 h-8 mx-1 text-gray-700 bg-white rounded-full focus:outline-none"
                >
                  <span className="material-icons">chevron_left</span>
                </button>
                <button
                  onClick={() =>
                    currentSlide === items.length - 1 ? setCurrentSlide(0) : handlePage("next")
                  }
                  className="flex items-center justify-center w-8 h-8 mx-1 text-gray-700 bg-white rounded-full focus:outline-none"
                >
                  <span className="material-icons">chevron_right</span>
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Carousel;
