import { atom } from "recoil";

// TODO "hero" | "mti"
interface AppConfig {
  appName: string;
  motor: {
    maxVmiCoverageStartDate: number;
    maxCmiCoverageStartDate: number;
  };
}

const mtiMaxConverageStartDate = 60;
const heroMaxConverageStartDate = 60;

const mtiMaxCmiConverageStartDate = 90;
const heroMaxCmiConverageStartDate = 90;

const appName = process.env.APP_NAME || "hero";

const config = atom<AppConfig>({
  key: "config",
  default: {
    appName,
    motor: {
      maxVmiCoverageStartDate:
        appName === "mti" ? mtiMaxConverageStartDate : heroMaxConverageStartDate,
      maxCmiCoverageStartDate:
        appName === "mti" ? mtiMaxCmiConverageStartDate : heroMaxCmiConverageStartDate,
    },
  },
});

export default config;
