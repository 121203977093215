import React from 'react'

const Dashboard = () => {
    return (
        <div className="flex h-full justify-center align-items-center" style={{height: "100vh"}}>
            <h1>Staff Dashboard</h1>
        </div>
    )
}

export default Dashboard
