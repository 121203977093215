import React from "react";

import {
  formatNumberWithCurrency,
  formatNumberWithCurrency2,
} from "/src/helpers/formatNumber";

import Label from "/src/components/staff/Label";

const Insurance = (props) => {
  const { data } = props;
  return (
    <div className="grid">
      <Label
        title="หมายเลขกรมธรรม์และพ.ร.บ."
        value={
          <>
            <span className="block-icon icon icon-motor"></span>{" "}
            <strong>{data.policy_details[0]?.policy_number}</strong>
            {data.policy_details?.[1] ? (
              <>
                <span className="cmi-label">พ.ร.บ.</span>{" "}
                <strong>{data.policy_details[1].policy_number}</strong>
              </>
            ) : null}
          </>
        }
      />
      <Label
        title="บริษัทและแผนประกัน"
        value={
          <>
            {data.insurer_code}, ชั้น {data.policy_details[0]?.product_type}
            {/* <span className="cmi-label-success">
              <span className="material-icons">done</span>รวมพ.ร.บ.
            </span> */}
          </>
        }
      />
      <Label
        title="ทุนประกัน"
        value={formatNumberWithCurrency2(data.policy_details[0]?.sum_insured)}
      />
      <Label title="ตัวแทน" value={data.agent_code} />
      <Label
        title="ลูกค้า"
        value={`${data.title} ${data.firstname} ${data.lastname}`}
      />
      <Label
        title="วันที่คุ้มครอง"
        value={`${data.policy_details[0]?.cover_start_date} - ${data.policy_details[0]?.cover_end_date}`}
      />
    </div>
  );
};

export default Insurance;
