import React from "react";
import { formatNumberWithCurrency2 } from "/src/helpers/formatNumber";
import yaml from "/src/data";

CoverageList;

const CoverageList = ({ label, value, type }) => {
  return (
    <ul className="flex flex-wrap text-sm">
      <li className="key flex-1">{label}</li>
      {type == "string" ? (
        <li className="value">{value}</li>
      ) : (
        <>
          {value == "garage" || value == "dealer" ? (
            <li className="value font-bold">{yaml.repair_facility[value]}</li>
          ) : (
            //  <li className="value">{value}</li>
            <li className="value text-right font-bold">{formatNumberWithCurrency2(value)}</li>
          )}
        </>
      )}
    </ul>
  );
};

export default CoverageList;
