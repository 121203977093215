// eslint-disable-next-line import/prefer-default-export
export const toFullAddress = (address) => {
  if (!address) return "-";

  let addressStr = "";
  if (address) {
    if (address.address) addressStr += `${address.address} `;

    let provinceLabel = "จังหวัด";
    let districtLabel = "อำเภอ";
    let subDistrictLabel = "ตำบล";
    if (address.province === "กรุงเทพมหานคร") {
      provinceLabel = "";
      districtLabel = "เขต";
      subDistrictLabel = "แขวง";
    }
    if (address.sub_district)
      addressStr += `${subDistrictLabel} ${address.sub_district ? address.sub_district : ""} `;
    if (address.district)
      addressStr += `${districtLabel} ${address.district ? address.district : ""} `;
    if (address.province)
      addressStr += `${provinceLabel} ${address.province ? address.province : ""} `;
    if (address.postcode) addressStr += `${address.postcode} `;
  }

  return addressStr;
};
