import React from "react";
import Calendar from "./Calendar";
import Input from "./Input";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import "./DatePickerRange.scss";

const DatePickerRange = ({ resetValueCallback, onDateChange, values }) => {
  const dropDownRef = React.useRef();
  const buttonRef = React.useRef();
  const [openCalendar, setOpenCalendar] = React.useState(false);
  const [state, setState] = React.useState({
    startDate: values[0] ? new Date(values[0]) : null,
    endDate: values[1] ? new Date(values[1]) : null,
    defaultActiveStartDate: new Date(2020, 0, 1),
  });

  useOnClickOutside(dropDownRef, (e) => {
    if (!buttonRef.current.contains(e.target)) {
      setOpenCalendar(false);
    }
  });

  const toggleModelCalendar = () => {
    setOpenCalendar(!openCalendar);
  };

  const handleSelectedDateChange = (date) => {
    const [startDate, endDate] = date;
    setState({
      ...state,
      startDate,
      endDate,
    });
    setOpenCalendar(false);
    onDateChange(startDate, endDate);
  };

  const resetValue = () => {
    state.startDate = null;
    state.endDate = null;
    setState({ ...state });

    resetValueCallback();
  };

  return (
    <div className="w-full">
      <div
        ref={buttonRef}
        role="button"
        onClick={toggleModelCalendar}
        className="bg-white rounded-lg px-3 pt-2 p grid border-solid border border-gray-400 datepicker-range--box"
      >
        <Input
          startDate={state.startDate}
          endDate={state.endDate}
          labelStartDate="วันที่เริ่ม"
          labelEndDate="วันที่สิ้นสุด"
        />
      </div>
      {openCalendar && (
        <div ref={dropDownRef}>
          <Calendar
            onDateChange={handleSelectedDateChange}
            focusedInput={state.focusedInput}
            values={state.startDate && state.endDate ? [state.startDate, state.endDate] : null}
          />
        </div>
      )}
      {resetValueCallback && (
        <button type="button" onClick={resetValue} className="float-right text-xs text-blue-600">
          ล้างค่า
        </button>
      )}
    </div>
  );
};

export default DatePickerRange;
