import React from "react";

import { FormGroup, TextField, DatePickerField } from "~src/components/form";

import { parseDate, dateFormat } from "~src/helpers/dateTime";
import { messages } from "~src/helpers/messages";
import { InsuredsAttributes } from "~src/types/checkoutBasicForm";

interface PersonalAdditionInputProps {
  attrPrefixInsured: string;
  insuredsAttributes?: InsuredsAttributes;
  errorsInsuredsAttr?: any;
  register: (msg) => void;
  handleChangeField: (event: React.FormEvent, attrName: string) => void;
  handleChangeFieldIdCard: (event: React.FormEvent, attrName: string) => void;
  callbackvalueBirthDate: (date: string) => void;
}

const PersonalAdditionInput: React.FC<PersonalAdditionInputProps> = ({
  attrPrefixInsured,
  insuredsAttributes,
  errorsInsuredsAttr,
  register,
  handleChangeField,
  handleChangeFieldIdCard,
  callbackvalueBirthDate,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <FormGroup label="วันเดือนปีเกิด" htmlForLabel="insured_birthday" require>
            <DatePickerField
              type="text"
              id="insured_birthday"
              name={`${attrPrefixInsured}[birthday]`}
              defaultView="decade"
              maxDate={parseDate(dateFormat(new Date()))}
              onChange={(evt) => handleChangeField(evt, "birthday")}
              callbackvalue={callbackvalueBirthDate}
              inputRef={register({
                ...messages.required,
                ...messages.validateBirthday,
              })}
              value={insuredsAttributes?.birthday}
              error={errorsInsuredsAttr?.birthday?.message}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup
            label="เลขที่บัตรประชาชน / เลขประจําตัวผู้เสียภาษี"
            htmlForLabel="insured_idcard"
            require
          >
            <TextField
              id="insured_idcard"
              name={`${attrPrefixInsured}[idcard]`}
              value={insuredsAttributes?.idcard}
              onChange={(evt) => handleChangeFieldIdCard(evt, "idcard")}
              inputRef={register({
                ...messages.required,
                ...messages.validateIdCard,
              })}
              error={errorsInsuredsAttr?.idcard?.message}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <FormGroup label="เบอร์มือถือ" htmlForLabel="insured_phone_number" require>
            <TextField
              id="insured_phone_number"
              name={`${attrPrefixInsured}[phone_number]`}
              value={insuredsAttributes?.phone_number}
              onChange={(evt) => handleChangeField(evt, "phone_number")}
              inputRef={register({
                ...messages.required,
                ...messages.mobileNumber,
              })}
              error={errorsInsuredsAttr?.phone_number?.message}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup label="อีเมล" htmlForLabel="insured_email">
            <TextField
              id="insured_email"
              name={`${attrPrefixInsured}[email]`}
              value={insuredsAttributes?.email}
              onChange={(evt) => handleChangeField(evt, "email")}
              inputRef={register(messages.email)}
              error={errorsInsuredsAttr?.email?.message}
            />
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default PersonalAdditionInput;
