import React, { useState } from "react";
import "./formGroup.scss";

const FormGroup = ({
  label = "",
  labelType = "",
  htmlForLabel = "",
  labelClassName = "",
  children = null,
  require = false,
  className = "",
  errorRequire = "",
}) => {
  const [stateLabel] = useState(label || false);
  const [stateLabelType] = useState(labelType || "block");
  const [stateHtmlFor] = useState(htmlForLabel || false);

  let labelTypeClass;
  switch (stateLabelType) {
    case "block":
      labelTypeClass = "label-block";
      break;
    case "flex":
      labelTypeClass = "label-flex";
      break;
    default:
      labelTypeClass = "label-inline";
      break;
  }

  return (
    <div className={`from-group ${className}`}>
      {stateLabel ? (
        <label htmlFor={stateHtmlFor || ""} className={`${labelTypeClass} ${labelClassName}`}>
          {stateLabel} {require ? <span>*</span> : ""}{" "}
          {errorRequire ? <span>{errorRequire}</span> : ""}
        </label>
      ) : (
        ""
      )}
      {children}
    </div>
  );
};

export default FormGroup;
