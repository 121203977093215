import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Stack,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { closeViewModal, contractViewSelector, fetchAcceptContract } from "~src/features/contracts";
import { useAppDispatch, useAppSelector } from "~src/store";
import { unEscapeHtml } from "~src/helpers/helpers";

const AgentViewContractModal = () => {
  const [accept, setAccept] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { open, data, status } = useAppSelector(contractViewSelector);
  const dispatch = useAppDispatch();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!accept) {
      alert("กรุณากดยอมรับ สัญญาสมาชิก");
      return;
    }
    setLoading(true);
    dispatch(fetchAcceptContract({ contract_id: data.id }));
  };

  return (
    <Dialog open={open} maxWidth={"lg"} scroll="body">
      <DialogContent sx={{ p: 5 }}>
        {status === "fulfilled" && (
          <>
            <Box
              className="detail-wrapper"
              dangerouslySetInnerHTML={{ __html: unEscapeHtml(data.detail) }}
            />

            <Box component="form" onSubmit={onSubmit}>
              <Box justifyContent="center" display="flex">
                <FormControlLabel
                  label="ยอมรับ สัญญาสมาชิก"
                  control={<Checkbox onChange={() => setAccept(!accept)} />}
                />
              </Box>
              <Box justifyContent="center" display="flex">
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disableElevation
                  disabled={loading}
                >
                  ตกลง
                </Button>
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AgentViewContractModal;
