import React from "react";
import ShowPaySlipModal from "~src/components/modal/orders/showPayslipModal";
import SecureLink from "~src/components/secureLink";
import useSecureLink from "~src/hooks/useSecureLink";

export const JacketUrl = ({ jacketUrl }: { jacketUrl: string }): JSX.Element => {
  const { secureDownloads } = useSecureLink();
  return (
    <button
      type="button"
      onClick={() => secureDownloads([jacketUrl])}
      className="flex mt-2 text-xs aling-items-center"
    >
      <i className="material-icons slip-icon text-base mr-5"> perm_media </i>
      เงื่อนไขกรมธรรม์
    </button>
  );
};

export const ReceiptUrl = ({ receiptUrl }: { receiptUrl: string }): JSX.Element => {
  return (
    <SecureLink src={receiptUrl} className="flex mt-2 text-xs aling-items-center">
      <i className="material-icons slip-icon text-base mr-5"> perm_media </i>
      ใบเสร็จ
    </SecureLink>
  );
};

export const PolicyCopyUrl = ({ policyUrl }: { policyUrl: string }): JSX.Element => {
  const { secureDownloads } = useSecureLink();
  return (
    <button
      type="button"
      onClick={() => secureDownloads([policyUrl])}
      className="flex mt-2 text-xs aling-items-center"
    >
      <i className="material-icons slip-icon text-base mr-5"> perm_media </i>
      เอกสารกรมธรรม์
    </button>
  );
};

export const PayslipUrl = ({
  orderID,
  payslipUrl,
}: {
  orderID: number;
  payslipUrl: string;
}): JSX.Element => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <>
      {open && (
        <ShowPaySlipModal
          orderID={orderID}
          payslip_url={payslipUrl}
          onClose={() => setOpen(false)}
        />
      )}
      <button onClick={() => setOpen(true)} className="flex mt-2 text-xs aling-items-center">
        <i className="material-icons slip-icon text-base mr-5"> assignment </i>
        ใบสลิปการโอน
      </button>
    </>
  );
};
