import React from "react";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";

const Price = ({ value, suffix, className = "" }) => {
  return (
    <div className={`price ${className}`}>
      <span>{formatNumberWithCurrency(value)}</span>
      <span className="text-sm font-normal ml-2">{suffix}</span>
    </div>
  );
};

export default Price;
