import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";

import StaffMenu from "./StaffMenu";
import { useAuthContext } from "../../../context/Auth";
import { isJsonString } from "~src/helpers/helpers";

export interface SidebarProps {
  setShow?: (state: boolean) => void;
}

const Sidebar = ({ setShow: _setShow }: SidebarProps) => {
  const { session, dispatch: dispatchAuth } = useAuthContext();

  useEffect(
    () =>
      dispatchAuth({
        type: "MODIFY",
        payload: {
          ...session,
          username: Cookies.get("username") || null,
          role: isJsonString(Cookies.get("role")) ? JSON.parse(Cookies.get("role")) : null,
          permissions: isJsonString(Cookies.get("permissions")) ? JSON.parse(Cookies.get("permissions")) : null,
        },
      }),
    []
  );

  return (
    <div
      className="fixed flex h-full overflow-y-auto bg-secondary-color sidebar-scroll"
      style={{ width: 100 }}
    >
      <StaffMenu />
    </div>
  );
};

export default Sidebar;
