import React, { useState, useRef } from "react";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import ButtonToggleDropdown from "./ButtonToggleDropdown";
import Checkbox from "./Checkbox";

const DropDownListCheckBox = ({ label, options, active, onChangeValue }) => {
  const buttonRef = useRef();
  const dropDownRef = useRef();
  const [isToggleDropdown, setToggleDropdown] = useState(false);

  useOnClickOutside(dropDownRef, () => {
    if (!buttonRef.current.contains(event.target)) {
      setToggleDropdown(false);
    }
  });

  const handleToggleDropdown = () => {
    setToggleDropdown(!isToggleDropdown);
  };

  return (
    <div className={`select-component`}>
      <ButtonToggleDropdown
        buttonRef={buttonRef}
        active={active}
        label={label}
        onClick={handleToggleDropdown}
      />
      {isToggleDropdown && (
        <div className={`select-component-list`} ref={dropDownRef}>
          {options.map((option, index) => (
            <Checkbox
              key={index}
              label={option.label}
              value={option.value}
              checked={option.isChecked}
              onChangeValue={onChangeValue}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDownListCheckBox;
