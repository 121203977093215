import Decimal from "decimal.js";
import React, { useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
// import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import { FormGroup, TextField } from "../form";
import ButtonRadius from "../form/button/ButtonRadius";

import dictionary from "~src/data.yaml";
import { usePaymentsAPI } from "~src/api/Payments";
import GenerateSecureLinkResult from "~src/models/GenerateSecureLinkResult";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { parseDate, reformatDateEN, toISODate } from "~src/helpers/date";
import { formatNumberWithCurrency } from "~src/helpers/formatNumber";
import { messages } from "~src/helpers/messages";
import APIResponse from "~src/models/APIResponse";
import { Payment } from "~src/types/payment";

import "./generatePaymentLink.scss";

export interface GeneratePaymentLinkProps {
  orderID: number;
  amount: Decimal;
  payment?: Payment;
  totalAmount?: Decimal;
  isInstallments?: boolean;
  installmentTerms?: number;
  generateOnInit?: boolean;
  className?: string;
  formID?: string;
  onGenerated?: (genResult: GenerateSecureLinkResult) => void;
  onError?: (err: Error) => void;
}

export default function GeneratePaymentLink(props) {
  return (
    <PageContextProvider>
      <GeneratePaymentLinkInner {...props} />
    </PageContextProvider>
  );
}

interface GeneratePaymentLinkInnerProps extends GeneratePaymentLinkProps { }

function GeneratePaymentLinkInner({
  orderID,
  amount,
  payment,
  totalAmount,
  isInstallments,
  installmentTerms,
  generateOnInit,
  className = "",
  formID = "generatePaymentLinkForm",
  onGenerated,
  onError,
}: GeneratePaymentLinkInnerProps) {
  const { setAlert, setPreloading } = usePageContext();
  const { addToast } = useToasts();
  const { paymentsAPI } = usePaymentsAPI({ onError });

  const [genResult, setGenResult] = useState<GenerateSecureLinkResult | undefined>();
  const [linkText, setLinkText] = useState<string | undefined>();
  const [isBusy, setIsBusy] = useState(false);
  const [isCopyPending, setIsCopyPending] = useState(false);

  const genLinkDebounceRef = useRef<ReturnType<typeof setTimeout>>();
  const linkTextAreaRef = useRef<HTMLTextAreaElement>();

  // const { register, errors, handleSubmit } = useForm({
  //   mode: "onSubmit",
  //   reValidateMode: "onSubmit",
  // });

  useDeepCompareEffectNoCheck(() => {
    if (!orderID || !paymentsAPI) {
      return;
    }
    if (!generateOnInit) {
      return;
    }
    // if (isBusy) {
    //   return;
    // }

    (async function () {
      if (!isResultValid()) {
        console.log("Current result:", genResult, "; Generating link on init");
        await generateLink();
      }
    })();
  }, [orderID, payment, amount, generateOnInit, paymentsAPI]); // , isBusy // ,previousLink

  useDeepCompareEffectNoCheck(() => {
    if (!linkText || !isCopyPending) {
      return;
    }

    (async function () {
      await copyLinkToClipBoard();
    })();
  }, [linkText, isCopyPending, linkTextAreaRef.current]);

  function handleCopyLink(_evt: React.MouseEvent<HTMLElement>) {
    //TEST
    console.log("handleCopyLink:", _evt);

    (async function () {
      try {
        const _genResult = isResultValid() ? genResult : await generateLink();
        unstable_batchedUpdates(() => {
          setIsCopyPending(true);
          setIsBusy(true);
        });
      } catch (err) {
        if (paymentsAPI.isAbortError(err)) {
          return;
        }
        // console.error(err);
        unstable_batchedUpdates(() => {
          // setAlert(err.message, err.details ? { generate_payment_link: err.details } : undefined);
          setIsCopyPending(false);
          setIsBusy(false);
        });
        // onError && onError(err);
      }
    })();

    // setIsLoaded(false);
    // post(`/orders/${orderId}/payments/payslip`, data).then((response) => {
    //   setIsLoaded(true);
    // });
  }

  function handleSmsLink(_evt: React.MouseEvent<HTMLElement>) {
    //TEST
    console.log("handleSmsLink:", _evt);

    (async function () {
      if (!isResultValid()) {
        await generateLink();
      }

      //TODO: Implement this
      console.log("TODO: handleSmsLink");
    })();
  }

  async function generateLink() {
    const params = {
      orderID,
      payment: payment ? { token: payment.token } : undefined,
    };

    let respData: APIResponse<GenerateSecureLinkResult> | undefined;
    try {
      unstable_batchedUpdates(() => {
        setAlert(undefined);
        setPreloading(true);
        setIsBusy(true);
      });

      //TODO: Revise this later
      genLinkDebounceRef.current = setTimeout(() => {
        setIsBusy(false);
        genLinkDebounceRef.current && clearTimeout(genLinkDebounceRef.current);
      }, 5000);

      respData = await paymentsAPI.generateLink(params);
      unstable_batchedUpdates(() => {
        setPreloading(false);
        setIsBusy(false);
        setGenResult(respData.content);
        setLinkText(getLinkTextFromResult(respData.content));
      });
      genLinkDebounceRef.current && clearTimeout(genLinkDebounceRef.current);
      onGenerated && onGenerated(respData.content);
    } catch (err) {
      if (paymentsAPI.isAbortError(err)) {
        return;
      }
      console.error(err);
      unstable_batchedUpdates(() => {
        // setAlert(err.message, err.details ? { generate_payment_link: err.details } : undefined);
        // setIsBusy(false);
        setPreloading(false);
      });
      // genLinkDebounceRef.current && clearTimeout(genLinkDebounceRef.current);
      onError && onError(err);
    }

    return respData?.content;
  }

  function getLinkTextFromResult(_genResult: GenerateSecureLinkResult) {
    if (!_genResult) {
      return undefined;
    }

    const { description, url, token } = _genResult;
    let _linkText = description || url;
    if (!_linkText) {
      if (!token) {
        console.warn("Generated result is empty:", _genResult);
        return undefined;
      }
      _linkText = paymentsAPI.getLinkURL(token);
    }

    return _linkText;
  }

  async function copyLinkToClipBoard() {
    try {
      unstable_batchedUpdates(() => {
        setIsBusy(true);
        setIsCopyPending(false);
      });

      let permResult: PermissionStatus | undefined;
      if ("clipboard" in navigator) {
        //Chrome vs Firefox
        for (const permName of ["clipboard-write", "clipboardWrite"]) {
          try {
            const permProm: Promise<PermissionStatus> | any = navigator.permissions.query({
              name: permName as PermissionName,
            });
            permResult =
              permProm && typeof permProm.then === "function" ? await permProm : permProm;
            if (permResult) {
              break;
            }
          } catch (err) {
            console.warn(`navigator.permissions.query[${permName}] => ${permResult}:`, err);
          }
        }
      }

      if (!permResult || (permResult.state !== "granted" && permResult.state !== "prompt")) {
        linkTextAreaRef.current.select();
        document.execCommand("copy");

        // swal({
        //   title: 'Please copy this link manually',
        //   text: url,
        //   type: 'info',
        //   confirmButtonColor: '#4FBCC1'
        // });
        setIsBusy(false);
        addToast("Link copied", {
          appearance: "success",
        });

        return;
      }

      await navigator.clipboard.writeText(linkText);
      setIsBusy(false);
      addToast("Link copied", {
        appearance: "success",
      });
    } catch (err) {
      console.error(err);
      setIsBusy(false);
      onError && onError(err);
    }
  }

  function isResultValid() {
    if (!genResult || !genResult.token) {
      return false;
    }

    const now = new Date();
    return !genResult.expires_at || now < parseDate(genResult.expires_at);
  }

  return (
    <div className={`generate-payment-link flex flex-col w-full ${className}`}>
      <form id={formID} className="flex flex-1 flex-col">
        {isInstallments && (
          <div className="installment-info flex flex-col w-full items-start">
            <p>
              {dictionary.payment.installment} {dictionary.payment.installment_term}{" "}
              {payment.installment_seq_no}/{installmentTerms}
            </p>
            <p>
              {dictionary.payment.total_amount}: {formatNumberWithCurrency(totalAmount)}
            </p>
            <p>
              {dictionary.payment.due_date}: {reformatDateEN(payment.installment_due_at)}
            </p>
          </div>
        )}
        <FormGroup
          label={dictionary.payment.amount}
          labelType="flex"
          htmlForLabel={`${formID}_amount`}
          labelClassName="w-full md:w-4/12"
        >
          <TextField
            id={`${formID}_amount`}
            name="amount"
            className="generate-payment-link-amount w-full md:8/12"
            value={formatNumberWithCurrency(amount)}
            readOnly
            disabled
          />
        </FormGroup>
        <h3>
          {dictionary.payment.please_copy_send_link}
          {/* You can share his link to your customer for payment */}
        </h3>
        <textarea
          className="input-link flex flex-grow w-full"
          name="link"
          placeholder="http://"
          ref={linkTextAreaRef}
          readOnly
          value={linkText || ""}
        />
        <div className="input-link-actions flex flex-row w-full justify-center gap-x-2">
          <ButtonRadius
            color="primary"
            classNameOption="button-copy-link"
            disabled={isBusy}
            onClick={handleCopyLink}
          >
            <i className="material-icons">content_copy</i> Copy
          </ButtonRadius>
          <ButtonRadius color="primary" classNameOption="button-sms-link" disabled>
            <i className="material-icons">send</i> SMS
          </ButtonRadius>
        </div>
      </form>
    </div>
  );
}
