import React, { useState } from "react";
import { CardDetailsProp } from "~src/types/product";
import InsurerDetail from "~src/components/card/InsurerDetail";
import InsurerImg from "~src/components/card/InsurerImg";
import Price from "~src/components/card/Price";
import Coverage from "~src/components/card/Coverage";
import Commission from "~src/components/card/Commission";
import CompareButton from "~src/components/card/CompareButton";
import CoverageModal from "./CoverageModal";

const CardDetails: React.FC<CardDetailsProp> = ({
  type,
  index,
  details,
  packageObj,
  showComission,
  multiply = 1,
  canCreateQuotation = true,
  linkCreate,
  onCallback,
}): JSX.Element => {
  const [openModalCoverage, setOpenModalCoverage] = useState<boolean>(false);

  const footerButton = (): JSX.Element => {
    switch (type) {
      case "by-insured":
        return (
          <>
            <button
              type="button"
              onClick={() => setOpenModalCoverage(true)}
              className="rounded-bl-5 flex-1 cursor-pointer bg-gray-100 text-primary p-3"
            >
              ดูรายละเอียด
            </button>
            <button
              type="button"
              onClick={onCallback}
              className="rounded-br-5 flex-1 cursor-pointer bg-primary text-white p-3 flex flex-row justify-center"
            >
              <i className="material-icons mr-1 text-base">add_shopping_cart</i>
              ซื้อเลย
            </button>
          </>
        );
      default:
        return (
          <>
            <button
              type="button"
              onClick={() => setOpenModalCoverage(true)}
              className="rounded-bl-5 flex-1 cursor-pointer bg-gray-100 text-primary p-3"
            >
              ดูรายละเอียด
            </button>
            {canCreateQuotation && (
              <button
                type="button"
                className="rounded-br-5 flex-1 cursor-pointer bg-primary text-white p-3 flex flex-row justify-center"
              >
                <i className="material-icons mr-1 text-base">add_shopping_cart</i>
                <a href={linkCreate}>สร้างใบเสนอราคา</a>
              </button>
            )}
          </>
        );
    }
  };

  return (
    <div
      id={`product-card-${index}`}
      className="shadow-md bg-white p-4 rounded-5 mt-4 transition duration-500 ease-in-out hover:scale-105"
    >
      {openModalCoverage && (
        <CoverageModal
          multiply={multiply}
          packageObj={packageObj}
          index={index}
          details={details}
          isOpen={openModalCoverage}
          onClose={() => setOpenModalCoverage(false)}
        />
      )}
      <div id="card-header" className="flex flex-col gap-2">
        <div className="flex flex-row gap-3">
          <InsurerImg
            style={{ width: "60px", height: "60px" }}
            src={packageObj.insurerImg}
            alt={packageObj.insurerName}
          />
          <InsurerDetail name={packageObj.insurerName} productName={packageObj.productName} />
        </div>

        <div className="flex flex-row justify-between">
          <Price
            className="font-extrabold text-lg"
            value={packageObj.standardPrice * multiply}
            suffix={packageObj.priceSuffix()}
          />

          {/* <CompareButton
            id={`compareBtn_${index}`}
            inputRef={compareRef}
            classes={active ? "active" : ""}
            onClick={handleCompare}
          /> */}
        </div>

        {showComission && <Commission value={packageObj.commission * multiply} />}
      </div>

      <hr className="mt-2" />

      <div id="card-body">
        <Coverage data={packageObj.cardDetails()} />
      </div>

      <div id="card-footer" className="flex flex-row mt-3 -mx-4 -mb-4">
        {footerButton()}
      </div>
    </div>
  );
};

export default CardDetails;
