import { Box, Typography } from "@mui/material";

import React from "react";

const steps = ["สมัครตัวแทน", "สัญญา/เงื่อนไขสมาชิก", "PDPA Consent"];

const Stepper = ({ active }) => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography textAlign="center" variant="h1">
        สมัครสมาชิก
      </Typography>

      <Box sx={{ width: "100%" }}>
        <ul className="stepper">
          {steps.map((step, index) => (
            <li key={index} className={`${index === active ? "stepper-list active" : "stepper-list"} text-center text-sm px-4 xs:px-6 md:text-lg`}>
              {step}
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default Stepper;
