import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import mtiWords from '/src/words/mti/data.json'
import heroWords from '/src/words/hero/data.json'

import mtiComponents from '/src/words/mti/components.json'
import heroComponents from '/src/words/hero/components.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      hero: { translation: { ...heroWords, ...heroComponents } },
      mti: { translation: { ...mtiWords, ...mtiComponents } },
    },
    lng: process.env.APP_NAME || 'hero',
    fallbackLng: process.env.APP_NAME || 'hero',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;