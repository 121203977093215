import React, { Dispatch } from "react";
import { Order } from "../../../types/order";

export interface RenewState extends Order {
  addon_selling_price: string;
  checked_addon: boolean;
}
export interface State {
  isEdit?: boolean;
  renew: RenewState;
}

type ActionType = "ADD" | "RESET";

interface Action {
  type: ActionType;
  payload?: State;
}

interface Context {
  state: State;
  dispatch: Dispatch<Action>;
}

const initialState = { isEdit: false } as State;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ADD":
      return {
        ...action.payload,
      };
    case "RESET":
      return initialState;
  }
};

const renewConText = React.createContext<Context>({
  state: initialState,
  dispatch: () => null,
});
const RenewContextProvider = renewConText.Provider;

export { reducer, initialState, renewConText, RenewContextProvider };
export default initialState;
