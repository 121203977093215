import "babel-polyfill";

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import AppConfig from "~src/config/AppConfig";

export interface LoginAPIClientProps {
  appConfig: AppConfig;
}

export default class LoginAPIClient {
  private props: LoginAPIClientProps;
  private _axios: AxiosInstance;
  private abortControllers: AbortController[] = [];

  constructor(props: LoginAPIClientProps) {
    this.props = props;
    this._axios = axios.create({
      baseURL: props.appConfig.apiURL,
    });
  }

  public async cleanup() {
    this.abortControllers.forEach((abc) => abc.abort("LoginAPIClient cleaning up"));
    this.abortControllers = [];
  }

  public async login(data) {
    const url = "users/sign_in";
    const controller = new AbortController();
    this.abortControllers.push(controller);

    return await this._axios.post(url, data, { signal: controller.signal });
  }
}
