import { Criteria } from "~src/types/criteria";
import { formatNumberWithCurrency } from "./formatNumber";

export const getTotalAmountDetails = (
  criteria: Criteria,
  productType: string,
  totalAmount
): string | null => {
  switch (productType) {
    case "travel":
      const traveller: number = criteria?.traveller ?? 1;
      if (traveller == 1) return null;

      return `ค่าเบี้ย ${formatNumberWithCurrency(totalAmount / traveller)} ต่อคน`;
    default:
      return null;
  }
};
