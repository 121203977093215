import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dateFormat } from "~src/helpers";
import AgentInfo from "~src/models/AgentInfo";
import LicenseInfo from "~src/models/LicenseInfo";
import { RootState } from "~src/store";
import { fetchNewAgentContract } from "../contracts";
import { mockEmail } from "~src/helpers/helpers";
import { fetchNewAgentPdpa } from "../pdpa";
import { get, has } from "lodash";
import axiosInstance from "~src/helpers/configAxios";
import { setLocationSelected } from "../locations";

type AgenRole = "knight" | "hero";

interface RegisterResponse {
  messages?: string;
  status_code?: string;
  success?: boolean;
}
 
interface LicensesAttributesPayload {
  expired_date: string;
  id: string;
  insurance_type: string;
  issued_date: string;
  license_no: string;
}

export interface AgentInfoPayload {
  account_book_file: FileList;
  address: string;
  bank_account: string;
  bank_account_branch: string;
  bank_account_id: string;
  bank_account_provider: string;
  citizen_card_file: FileList;
  citizen_id: string;
  district: string;
  dob: string;
  education_level: string;
  email: string;
  firstname: string;
  has_motor_license: string;
  lastname: string;
  mobile_no: string;
  motor_license_file: FileList;
  postcode: string;
  province: string;
  recommender_code: string;
  road: string;
  sub_district: string;
  title: string;
  licenses_attributes?: LicensesAttributesPayload[];
  has_agent_card: boolean;
  mtl_agent_code?: string;
  mtl_sales_branch?: string;
}
interface ContractPayload {
  contract_id: number;
}
interface PDPAQuestionPayload {
  id: number;
  answer: boolean;
}

interface PDPAPayload {
  id: number;
  questions: PDPAQuestionPayload[];
}

interface RegisterPayload {
  data: AgentInfoPayload;
  contract: ContractPayload;
  pdpa: PDPAPayload;
}

export const fetchCreateNewAgent = createAsyncThunk(
  "register/fetchCreateNewAgent",
  async (param: RegisterPayload, { rejectWithValue }) => {
    const { data, contract, pdpa } = param;

    const formData = new FormData();

    formData.append("contract[contract_id]", contract.contract_id.toString());
    formData.append("pdpa[id]", pdpa.id.toString());

    pdpa.questions.forEach((q, index) => {
      formData.append(`pdpa[questions][${index}][id]`, q.id.toString());
      formData.append(`pdpa[questions][${index}][answer]`, q.answer ? "1" : "0");
    });

    if (data.account_book_file.length > 0) {
      formData.append("account_book_file", data.account_book_file[0]);
    }

    if (data.citizen_card_file.length > 0) {
      formData.append("citizen_card_file", data.citizen_card_file[0]);
    }

    if (data.motor_license_file.length > 0) {
      formData.append("motor_license_file", data.motor_license_file[0]);
    }

    formData.append("bank_account", data.bank_account);
    formData.append("bank_account_branch", data.bank_account_branch);
    formData.append("bank_account_id", data.bank_account_id);
    formData.append("bank_account_provider", data.bank_account_provider);
    formData.append("citizen_id", data.citizen_id);
    formData.append("dob", data.dob);
    formData.append("education_level", data.education_level);
    formData.append("email", data.email);
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("mobile_no", data.mobile_no);
    formData.append("recommender_code", data.recommender_code);
    formData.append("title", data.title);
    formData.append("address", data.address);
    formData.append("road", data.road);
    formData.append("sub_district", data.sub_district);
    formData.append("province", data.province);
    formData.append("district", data.district);
    formData.append("postcode", data.postcode);
    formData.append("has_agent_card", data.has_agent_card ? "1" : "0");

    if (has(data, "licenses_attributes")) {
      data.licenses_attributes.forEach((license) => {
        // formData.append("licenses_attributes[][id]", license.id);
        formData.append("licenses_attributes[][license_no]", license.license_no);
        formData.append("licenses_attributes[][issued_date]", license.issued_date);
        formData.append("licenses_attributes[][expired_date]", license.expired_date);
        formData.append("licenses_attributes[][insurance_type]", license.insurance_type);
      });
    }

    if (has(data, "mtl_agent_code")) {
      formData.append("mtl_agent_code", data.mtl_agent_code);
      formData.append("mtl_sales_branch", data.mtl_sales_branch);
    }

    try {
      const response = await axiosInstance.post("/api/agents", formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  fetchStatus: "idle" as "idle" | "loading" | "succeeded" | "failed",
  errorMessage: "",
  step: 0,
  agentRole: "knight" as AgenRole,
  acceptedContract: false,
  contract_id: 0,
  data: {
    title: "",
    firstname: "",
    lastname: "",
    dob: dateFormat(new Date()),
    citizen_id: "",
    education_level: "",
    citizen_card_file_url: "",
    account_book_file_url: "",
    motor_license_file_url: "",
    mobile_no: "",
    email: "",
    address: "",
    road: "",
    sub_district: "",
    district: "",
    province: "",
    postcode: "",
    bank_account_id: "",
    bank_account: "",
    bank_account_provider: "กสิกร",
    bank_account_branch: "",
    recommender_code: "",
    has_motor_license: false,
    has_agent_card: false,
    mtl_agent_code: "",
    mtl_sales_branch: "MTLP 1",
    licenses: [
      {
        id: 0,
        license_no: "",
        insurance_type: "non-life",
        issued_date: dateFormat(new Date()),
        expired_date: dateFormat(new Date()),
        renewal_times: 0,
      },
    ],
  } as AgentInfo,
  formData: {} as AgentInfoPayload,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setStep: (state, { payload }: PayloadAction<number>) => {
      state.step = payload;
    },
    setAgentRole: (state, { payload }: PayloadAction<AgenRole>) => {
      state.agentRole = payload;
    },
    setAgentInfo: (state, { payload }: PayloadAction<{ key: keyof AgentInfo; value: string }>) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.key]: payload.value,
        },
      };
    },
    setHasMotorLicense: (state, { payload }: PayloadAction<boolean>) => {
      state.data.has_motor_license = payload;
    },
    setAgentLicense: (
      state,
      { payload }: PayloadAction<{ key: keyof LicenseInfo; value: string }>
    ) => {
      state.data.licenses[0] = {
        ...state.data.licenses[0],
        [payload.key]: payload.value,
      };
    },
    setHasAgentCard: (state, { payload }: PayloadAction<boolean>) => {
      state.data.has_agent_card = payload;
    },
    setRegisterData: (state, { payload }) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    },
    setAcceptedContract: (state, { payload }: PayloadAction<boolean>) => {
      state.acceptedContract = payload;
    },
    setContractId: (state, { payload }: PayloadAction<number>) => {
      state.contract_id = payload;
    },
    setCitizenCardFileUrl: (state, { payload }: PayloadAction<string>) => {
      state.data.citizen_card_file_url = payload;
    },
    setAccountBookFileUrl: (state, { payload }: PayloadAction<string>) => {
      state.data.account_book_file_url = payload;
    },
    setMotorLicenseFileUrl: (state, { payload }: PayloadAction<string>) => {
      state.data.motor_license_file_url = payload;
    },
    setFormData: (state, { payload }: PayloadAction<AgentInfoPayload>) => {
      state.formData = payload;
    },
    setErrorMessage: (state, { payload }: PayloadAction<string>) => {
      state.errorMessage = payload;
    },
    clearError: (state) => {
      state.errorMessage = "";
    },
  },
  extraReducers: (duilder) => {
    duilder
      .addCase(fetchCreateNewAgent.pending, (state) => {
        state.fetchStatus = "loading";
        state.errorMessage = "";
      })
      .addCase(fetchCreateNewAgent.fulfilled, (state, { payload }) => {
        const { messages, success } = payload as RegisterResponse;

        if (success) {
          state.fetchStatus = "succeeded";
          window.location.href = "/register/thankyou";
        } else {
          state.fetchStatus = "failed";
          state.errorMessage = messages;
        }
      })
      .addCase(fetchCreateNewAgent.rejected, (state, { payload }) => {
        state.fetchStatus = "failed";
        state.errorMessage = "ขออภัยครับ ดูเหมือนอาจเกิดปัญหาบางอย่างเกี่ยวกับระบบ";
      });
  },
});

export const {
  setStep,
  setAgentRole,
  setAgentInfo,
  setRegisterData,
  setHasMotorLicense,
  setAgentLicense,
  setHasAgentCard,
  setAcceptedContract,
  setContractId,
  setCitizenCardFileUrl,
  setAccountBookFileUrl,
  setMotorLicenseFileUrl,
  setFormData,
  setErrorMessage,
  clearError,
} = registerSlice.actions;

// Selectors
export const agenRoleSelector = (state: RootState) => state.register.agentRole;
export const stepSelector = (state: RootState) => state.register.step;
export const agentInfoSelector = (state: RootState) => state.register.data;
export const agentLicenseSelector = (state: RootState) => state.register.data.licenses[0];
export const contractViewSelector = (state: RootState) => state.contract.view;
export const pdpaViewSelector = (state: RootState) => state.pdpa.view;
export const acceptedContractSelector = (state: RootState) => state.register.acceptedContract;
export const responseStatusSelector = (state: RootState) => state.register.fetchStatus;
export const errorMessgaeSelector = (state: RootState) => state.register.errorMessage;

// Actions
export const handleChangeStep = (step: number) => (dispatch) => {
  if (step === 0) {
    dispatch(setAcceptedContract(false));
  }
  dispatch(setStep(step));
};
export const handleChangeAcceptContract = (value: boolean) => (dispatch) => {
  dispatch(setAcceptedContract(value));
};
export const handleChangeAgentInfo = (key: keyof AgentInfo, value: string) => (dispatch) => {
  dispatch(setAgentInfo({ key, value }));
};
export const handleChangeHasMotorLicense = (value: boolean) => (dispatch) => {
  dispatch(setHasMotorLicense(value));
  dispatch(setAgentRole(value ? "hero" : "knight"));
};
export const handleChangeAgentLicenseNumber = (value: string) => (dispatch) => {
  dispatch(
    setAgentLicense({
      key: "license_no",
      value,
    })
  );
};
export const handleChangeAgentLicense = (key: keyof LicenseInfo, value: string) => (dispatch) => {
  dispatch(
    setAgentLicense({
      key,
      value,
    })
  );
};
export const handleChangeHasAgentCard = (value: boolean) => (dispatch) => {
  dispatch(setHasAgentCard(value));
};

export const getContractNewAgent = (role: AgenRole) => (dispatch) => {
  dispatch(fetchNewAgentContract({ role }));
};
export const handleChangeContractId = (id: number) => (dispatch) => {
  dispatch(setContractId(id));
  dispatch(setStep(2));
};

export const handleChangeImageUrl =
  (url: string, name: "citizen_card_file" | "motor_license_file" | "account_book_file") =>
  (dispatch) => {
    if (name === "citizen_card_file") {
      dispatch(setCitizenCardFileUrl(url));
    } else if (name === "motor_license_file") {
      dispatch(setMotorLicenseFileUrl(url));
    } else {
      dispatch(setAccountBookFileUrl(url));
    }
  };

export const buildAgentInfoFormData = (data: AgentInfoPayload) => (dispatch, getState) => {
  const state = getState();
  const stateData: AgentInfo = state.register.data;

  dispatch(clearError());

  if (data.citizen_card_file.length === 0) {
    dispatch(setErrorMessage("กรุณาแนบไฟล์บัตรประชาชน"));
    return;
  }

  if (stateData.has_motor_license) {
    if (data.motor_license_file.length === 0) {
      dispatch(setErrorMessage("กรุณาแนบไฟล์รูปบัตรนายหน้า (วินาศภัย)"));
      return;
    }
  }

  data.has_agent_card = stateData.has_agent_card;

  dispatch(setFormData(data));
  dispatch(setStep(1));
};

export const getPdpaNewAgent = () => (dispatch) => {
  dispatch(fetchNewAgentPdpa());
};

export const handleSubmit = (pdpaParams: PDPAPayload) => (dispatch, getState) => {
  const state = getState();
  const formData = get<AgentInfoPayload>(state, "register.formData", {} as AgentInfoPayload);
  const contract_id = get<number>(state, "register.contract_id", 0);
  const contract = { contract_id };

  dispatch(clearError());
  dispatch(fetchCreateNewAgent({ data: formData, contract, pdpa: pdpaParams }));
};

export const handleMockAgentData = () => (dispatch) => {
  const agentInfoData: { key: keyof AgentInfo; value: string }[] = [
    {
      key: "title",
      value: "นาย",
    },
    {
      key: "firstname",
      value: "Lionel",
    },
    {
      key: "lastname",
      value: "Messi",
    },
    {
      key: "dob",
      value: "1988-07-09",
    },
    {
      key: "citizen_id",
      value: "1111111111119",
    },
    {
      key: "education_level",
      value: "primary",
    },
    {
      key: "mobile_no",
      value: "0999999999",
    },
    {
      key: "email",
      value: mockEmail(),
    },
    {
      key: "address",
      value: "33/33",
    },
    {
      key: "road",
      value: "สุทธิสาร",
    },
    {
      key: "sub_district",
      value: "ห้วยขวาง",
    },
    {
      key: "district",
      value: "ห้วยขวาง",
    },
    {
      key: "province",
      value: "กรุงเทพมหานคร",
    },
    {
      key: "postcode",
      value: "10310",
    },
    {
      key: "bank_account_id",
      value: "1112111119",
    },
    {
      key: "bank_account",
      value: "Lionel Messi",
    },
    {
      key: "bank_account_branch",
      value: "Argentina",
    },
  ];

  agentInfoData.forEach((o) => {
    dispatch(
      setAgentInfo({
        key: o.key,
        value: o.value,
      })
    );
  });

  dispatch(
    setLocationSelected({
      sub_district: "ห้วยขวาง",
      district: "ห้วยขวาง",
      province: "กรุงเทพมหานคร",
      zipcode: 10310,
      sub_district_code: 0,
      district_code: 0,
      province_code: 0,
    })
  );
};

export default registerSlice.reducer;
