import React from "react";
import { Button } from "~src/components/form";

const KnightLicence = ({ onClose }) => {
  return (
    <div className="w-full h-full fixed top-0 left-0 flex justify-center items-center">
      <div className="absolute w-full h-full bg-black top-0 left-0 opacity-90"></div>
      <div className="bg-white w-10/12 h-screen relative z-10 overflow-y-auto">
        <div className="m-60">
          {/* <h1>
            บริษัท เมืองไทย โบรกเกอร์ จำกัด (สำนักงานใหญ่) <br />
            MUANG THAI BROKER CO., LTD (Head Office)
          </h1>
          <div className="text-xs font-light">
            252/280 อาคาร เมืองไทย-ภัทร คอมเพล็กซ์ อาคารคอนโด The Garage ชั้น 11 ถนนรัชดาภิเษก
            แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร 10310 โทร.(662) 274-9400 #5578 <br />
            252/280 MUANGTHAI PHATRA COMPLEX BUILDING, RESIDENCE TOWER, 11th FLOOR THE GARAGE,
            RACHADAPHISEK ROAD, HUAYKWANG, HUAYKWANG, BANGKOK 10310 Tel. (662) 274-9400 #5578
          </div> */}

          <h3 className="text-center my-16 text-secondary text-lg font-bold">
            ข้อตกลงและเงื่อนไขการเป็นสมาชิก gettgo hero
          </h3>

          <h4 className="mt-16 font-bold">1. คำจำกัดความ</h4>
          <ol className="ml-10 font-light">
            <li>
              1.1 <strong className="font-bold">“บริษัท”</strong> หมายความถึง บริษัท เมืองไทย
              โบรกเกอร์ จำกัดและ/หรือบริษัทในเครือ
            </li>
            <li>
              1.2 <strong className="font-bold">"gettgo hero"</strong> หมายความถึง
              ระบบเสนอขายผลิตภัณฑ์ประกันภัยและประกันชีวิต รวมถึงผลิตภัณฑ์การเงินต่างๆ
              ที่ให้บริการแก่สมาชิกหรือบุคคลที่ได้รับแต่งตั้งจากบริษัทและ/หรือผลิตภัณฑ์ใดที่บริษัทอาจมีขึ้นในภายหน้าโดยเป็นไปตามเงื่อนไขและข้อกำหนดของบริษัท
            </li>
            <li>
              1.3 <strong className="font-bold">"สมาชิก"</strong> หมายความถึง
              บุคคลผู้มีคุณสมบัติครบถ้วนตามที่กำหนดและได้รับการพิจารณาอนุมัติจากทางบริษัท
              โดยจะได้รับสิทธิประโยชน์ต่างๆ
              และมีหน้าที่ตามที่กำหนดภายใต้ข้อตกลงและเงื่อนไขการเป็นสมาชิก gettgo hero นี้
            </li>
            <li>
              1.4 <strong className="font-bold">"รหัสประจำตัว"</strong> (Username) หมายความถึง
              ชุดข้อความหรือตัวอักษรหรือตัวเลข ซึ่งสามารถระบุบ่งชี้ตัวตนของสมาชิกแต่ละรายได้
              โดยสมาชิกเป็นผู้กำหนดขึ้นเองหรือได้รับจากบริษัท
              เพื่อใช้ในการเข้าใช้งานเว็บไซต์หรือช่องทางต่างๆรวมถึงการใช้บริการต่างๆของ gettgo hero{" "}
            </li>
            <li>
              1.5 <strong className="font-bold">"รหัสผ่าน"</strong> (Password) หมายความถึง
              ชุดข้อความหรือตัวอักษรหรือตัวเลข ซึ่งสมาชิกกำหนดขึ้นหรือได้รับจากบริษัท
              เพื่อใช้ในการเข้าใช้งานเว็บไซต์หรือช่องทางต่างๆของ gettgo hero
            </li>
            <li>
              1.6 <strong className="font-bold">“ข้อมูลส่วนบุคคล”</strong> หมายถึง ข้อมูลส่วนบุคคล
              (เช่น ชื่อ-สกุล อายุ เพศ อาชีพ คุณสมบัติ ตำแหน่งงาน ประเภทธุรกิจ สัญชาติ
              เลขบัตรประจำตัวประชาชน ที่อยู่ เบอร์โทรศัพท์ เป็นต้น) ข้อมูลทางชีวภาพ (เช่น ลายนิ้วมือ
              ใบหน้า เป็นต้น) ข้อมูลทางการเงิน (เช่น เลขบัญชีธนาคาร
              ข้อมูลทางการเงินอื่นใดที่จำเป็นที่บริษัทกำหนด เป็นต้น) ข้อมูลการเป็นสมาชิก (เช่น
              รายละเอียดข้อมูลบัญชีสมาชิก หมายเลขสมาชิกหรือรหัสสมาชิก) ข้อมูลการทำธุรกรรม
              ข้อมูลการลงทุนและสินทรัพย์ทุกประเภท ข้อมูลเกี่ยวกับบริการ รวมถึงข้อมูลการใช้บริการ
              หรือสิทธิประโยชน์ต่างๆของสมาชิกภายใต้ข้อตกลงและเงื่อนไขฉบับนี้ ข้อมูลบริการอื่นๆ
              และ/หรือข้อมูลใดๆ ของสมาชิก ที่ให้ไว้แก่บริษัท หรือที่สมาชิกได้รับ
              หรือเข้าถึงได้จากแหล่งอื่น
              ข้อมูลอื่นใดที่จำเป็นที่บริษัทกำหนดเพื่อให้สมาชิกสามารถใช้สิทธิและปฏิบัติตามข้อตกลงและเงื่อนไขการเป็นสมาชิกของบริษัทฉบับนี้ได้
              ซึ่งสมาชิกจะต้องให้ข้อมูลข้างต้นแก่บริษัทในวันที่สมัครเข้าเป็นสมาชิก
              และบริษัทสงวนสิทธิในการเรียกข้อมูลที่จำเป็นเพิ่มเติมจากสมาชิกภายหลังจากที่ได้เข้าเป็นสมาชิกของ
              gettgo hero แล้วได้
            </li>
            <li>
              1.7 <strong className="font-bold">“เหตุุสุดวิสัย”</strong> หมายถึง
              เหตุใดๆอันอยู่นอกเหนือความควบคุมของบริษัท ซึ่งเป็นอุปสรรคต่อการให้บริการ
              ตามข้อกำหนดและเงื่อนไขการเป็นสมาชิกนี้ โดยเป็นเหตุให้เกิดข้อขัดข้องหรือข้อผิดพลาดต่าง
              ซึ่งรวมถึง ความขัดข้องของระบบสื่อสาร หรือกระแสไฟฟ้า หรือระบบคอมพิวเตอร์
              หรือระบบอินเทอร์เน็ต
              หรือเกิดจากความผิดพลาดของผู้ให้บริการเครือข่ายโทรศัพท์เคลื่อนที่หรือระบบอื่นใด
              หรือเหตุขัดข้องหรืออุปสรรคในการเชื่อมต่อกับระบบ
              หรือการถูกโจรกรรมข้อมูลของสมาชิกซึ่งเกิดจากการกระทำของบุคคลภายนอก
              หรือเกิดจากไวรัสคอมพิวเตอร์หรือข้อมูลที่เป็นอันตรายต่างๆ
              ซึ่งเป็นเหตุให้สมาชิกไม่สามารถใช้บริการได้ตามข้อตกลงและเงื่อนไขการเป็นสมาชิกนี้
              และเหตุดังกล่าวไม่ได้เกิดขึ้นจากความผิดของบริษัท
            </li>
          </ol>
          <h4 className="mt-16 font-bold">2. ขอบเขตการบริการและเงื่อนไข</h4>
          <ol className="ml-10 font-light">
            <li>
              2.1
              สมาชิกสามารถใช้บริการผ่านเว็บไซต์หรือช่องทางอื่นได้ตามขั้นตอนและวิธีการที่บริษัทกำหนด
              หรือตามที่บริษัทจะได้กำหนดในอนาคต
            </li>
            <li>
              2.2 สมาชิกยืนยันว่าข้อมูลที่กรอกไว้ให้กับบริษัทเป็นข้อมูลที่ถูกต้องแท้จริงทุกประการ
            </li>
            <li>
              2.3 ในการเข้าใช้บริการเว็บไซต์หรือช่องทางอื่น
              สมาชิกจะต้องเข้าใช้บริการด้วยรหัสประจำตัวและรหัสผ่านของสมาชิกตามวิธีการและเงื่อนไขที่บริษัทกำหนด
            </li>
            <li>
              2.4 สมาชิกจะต้องทำการเก็บรักษาข้อมูลการเป็นสมาชิก รวมถึง
              รหัสประจำตัวและรหัสผ่านของสมาชิกไว้เป็นข้อมูลลับเฉพาะ
              โดยไม่เปิดเผยแก่บุคคลอื่นใดโดยเด็ดขาด
            </li>
            <li>
              2.5 หากมีกรณีที่บุคคลใดสามารถเข้าถึงข้อมูลข้างต้นของสมาชิกได้
              สมาชิกจะรับผิดชอบในความเสียหายใดก็ตามที่เกิดขึ้นทั้งต่อบริษัท และ/หรือ บุคคลภายนอก
              รวมถึงจะไม่เรียกร้องให้บริษัทรับผิดชอบต่อผลกระทบใดก็ตามที่เกิดขึ้นหรืออาจเกิดขึ้นได้
              การกระทำการใดๆของสมาชิก และ/หรือบุคคลภายนอก
              ซึ่งได้กระทำโดยใช้รหัสประจำตัวและรหัสผ่านของสมาชิก
              ย่อมถือว่าการกระทำนั้นมีผลสมบูรณ์ผูกพันสมาชิกนั้น
            </li>
          </ol>
          <h4 className="mt-16 font-bold">3. หน้าที่ของสมาชิก</h4>
          <ol className="ml-10 font-light">
            <li>
              3.1 สมาชิกต้องปฏิบัติตามหลักเกณฑ์ เงื่อนไข
              ข้อบังคับและข้อปฏิบัติของการเป็นสมาชิกอย่างเคร่งครัด
            </li>
            <li>
              3.2 สมาชิกห้ามกระทำการอันใดซึ่งเป็นการหลอกลวง จูงใจ โฆษณาอันเป็นเท็จ
              หรือกล่าวอ้างเกินความจริง เพื่อชักจูงให้บุคคลอื่นสมัครสมาชิก
              หรือซื้อผลิตภัณฑ์และบริการที่บริษัทเป็นผู้จัดหา
            </li>
            <li>
              3.3 บริษัทมีสิทธิที่จะหักภาษีต่างๆ จากค่าการตลาด และ/หรือผลประโยชน์ใดๆ
              ที่บริษัทตกลงจ่ายให้แก่สมาชิกตามจำนวนที่กฎหมาย หรือกฎระเบียบที่เกี่ยวข้องกับการหักภาษี
              ณ ที่จ่ายกำหนดไว้ หรือภาษีอื่นๆ ที่เกี่ยวข้อง (ถ้ามี)
            </li>
          </ol>
          <h4 className="mt-16 font-bold">4. สิทธิประโยชน์ที่สมาชิกจะได้รับ</h4>
          <ol className="ml-10 font-light">
            <li>
              4.1 สมาชิกสามารถแนะนำผลิตภัณฑ์ต่างๆ ให้แก่บุคคลอื่น
              ภายใต้ข้อตกลงและเงื่อนไขการเป็นสมาชิกฉบับนี้
              และภายใต้เงื่อนไขหรือข้อกำหนดอื่นใดที่บริษัทได้กำหนดไว้
            </li>
            <li>
              4.2 สมาชิกสามารถเข้าใช้ระบบเสนอขายผ่านทางเว็บไซต์และช่องทางอื่นของบริษัท
              เพื่อศึกษาข้อมูล เปรียบเทียบแผน เสนอขาย
              หรือกระทำการใดๆตามสิทธิที่สมาชิกมีอยู่ภายใต้เงื่อนไขและข้อกำหนดของบริษัท
              โดยต้องไม่ก่อให้เกิดความเสียหายแก่บริษัทไม่ว่าในทางใดทางหนึ่ง
            </li>
            <li>
              4.3 สมาชิกสามารถเข้าร่วมกิจกรรมและโครงการพิเศษต่างๆ ที่บริษัทจัดขึ้น
              ภายใต้เงื่อนไขและข้อกำหนดของบริษัท
            </li>
            <li>4.4 สมาชิกจะได้รับสิทธิประโยชน์ตามที่บริษัทกำหนดทุกประการ</li>
          </ol>
          <h4 className="mt-16 font-bold">5. ความรับผิดของสมาชิก</h4>
          <ol className="ml-10 font-light">
            <li>
              5.1
              สมาชิกตกลงรับผิดในบรรดาความเสียหายและค่าใช้จ่ายใดที่บริษัทอาจได้รับอันเนื่องมาจากหรือที่เกี่ยวกับข้อกำหนดนี้
              หรือเกี่ยวกับการกระทำหรืองดเว้นกระทำการของสมาชิก
              รวมตลอดถึงบรรดาความเสียหายและค่าใช้จ่ายใดๆ
              ที่บริษัทอาจได้รับอันเนื่องมาจากเรียกร้องหรือฟ้องร้องโดยบุคคลอื่นให้บริษัทต้องรับผิดเกี่ยวกับข้อกำหนดนี้
              ซึ่งมิได้เกิดขึ้นจากการกระทำหรืองดเว้นกระทำการโดยจงใจหรือประมาทเลินเล่ออย่างร้ายแรงของบริษัทหรือพนักงานหรือลูกจ้างของบริษัทแต่ประการใด
            </li>
            <li>
              5.2 กรณีที่สมาชิกกระทำการใดอันเป็นการผิดต่อกฎหมาย โดยใช้ Username ของสมาชิก
              บริษัทมีสิทธิที่จะระงับความเป็นสมาชิกของสมาชิกซึ่งเป็นเจ้าของ Username นั้นได้โดยทันที
              และหากการกระทำดังกล่าวก่อให้เกิดความเสียหายใดขึ้นกับบริษัท
              บริษัทมีสิทธิในการเรียกร้องค่าเสียหายจากสมาชิกดังกล่าวได้เต็มจำนวน
            </li>
            <li>
              5.3 สมาชิกไม่สามารถเรียกร้อง
              รับเงินหรือกระทำการอันใดที่เกี่ยวกับการชำระเบี้ยประกันภัยจากผู้เอาประกันทั้งสิ้น
              ไม่ว่าจะเป็นการกระทำแทนบริษัทก็ตาม หากสมาชิกไม่ปฏิบัติตามข้อตกลงดังกล่าว
              สมาชิกต้องรับผิดเป็นการเฉพาะตัว
            </li>
          </ol>
          <h4 className="mt-16 font-bold">6. ความรับผิดของบริษัท</h4>
          <ol className="ml-10 font-light">
            <li>
              6.1 บริษัทจะรับผิดชอบเมื่อเกิดความเสียหายต่อสมาชิก
              ต่อเมื่อมีข้อพิสูจน์ว่าความเสียหายนั้นเกิดจากความประมาทเลินเล่ออย่างร้ายแรงของบริษัท
              อย่างไรก็ตาม สมาชิกซึ่งมีสิทธิเรียกร้องค่าเสียหายข้างต้นจากบริษัทได้
              จะต้องเป็นสมาชิกที่ปฏิบัติตามข้อกำหนดและข้อตกลงของบริษัทครบถ้วนสมบูรณ์แล้วเท่านั้น
            </li>
            <li>
              6.2 กรณีที่ความเสียหายเกิดขึ้นจากเหตุสุดวิสัย
              จะไม่ถือว่าเป็นความรับผิดของบริษัทในทุกกรณี
            </li>
          </ol>
          <h4 className="mt-16 font-bold">7. การชดใช้ค่าเสียหาย</h4>
          <ol className="ml-10 font-light">
            <li>
              สมาชิกตกลงที่จะชดใช้ค่าเสียหายแต่เพียงผู้เดียวต่อบริษัท รวมถึงบริษัทในเครือ กรรมการ
              พนักงาน ผู้อนุญาตให้ใช้สิทธิ ผู้ให้บริการข้อมูลภายนอก ให้พ้นจากการเรียกร้องสิทธิ
              หรือการฟ้องร้องตามกฎหมายใดๆ ซึ่งรวมถึงค่าทนายความค่าธรรมเนียมอื่นๆในทางกฎหมาย
              และค่าใช้จ่ายต่างๆ
              อันเกิดขึ้นจากหรือเกี่ยวข้องกับการใช้บริการเว็บไซต์หรือช่องทางอื่นของบริษัท
              หรือการให้ข้อมูลโดยละเมิดข้อตกลงและเงื่อนไขตามที่ระบุไว้ในข้อตกลงฉบับนี้
              หรือการละเมิดสิทธิใดๆ ต่อบุคคลที่สาม หรือการกระทำใด
              ๆที่เกี่ยวข้องกับเว็บไซต์หรือช่องทางอื่นอันก่อให้เกิดความเสียหายต่อบริษัท หรือบุคคลใด
              ๆ
            </li>
          </ol>
          <h4 className="mt-16 font-bold">8. การเก็บและใช้ข้อมูลส่วนบุคคล </h4>
          <ol className="ml-10 font-light">
            <li>
              8.1 สมาชิกยินยอมให้บริษัท เก็บรวบรวม ใช้ ติดต่อสอบถาม
              หรือเปิดเผยรายละเอียดในข้อมูลส่วนบุคคลบางประการหรือทั้งหมดเกี่ยวกับสมาชิก
              ที่บริษัทได้รับมาเนื่องจากการลงทะเบียนเป็นสมาชิกและการใช้บริการใดๆกับบริษัท
              หรือกับบุคคลอื่นซึ่งเกี่ยวข้องกับข้อตกลงและเงื่อนไขการเป็นสมาชิกนี้
              ได้ตามความจำเป็นและเหมาะสม
              เพื่อวัตถุประสงค์ในการปรับปรุงและพัฒนาการให้บริการได้อย่างมีประสิทธิภาพมากยิ่งขึ้น
              และส่งเสริมการขายและการตลาด โดยบริษัทไม่จำต้องได้รับความยินยอมจากสมาชิกก่อน
            </li>
            <li>
              8.2 สมาชิกมีสิทธิขอเข้าถึงข้อมูล
              หรือขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลของสมาชิก
              รวมถึงมีสิทธิขอให้บริษัทลบหรือทำลาย ระงับใช้
              หรือเปลี่ยนแปลงข้อมูลดังกล่าวให้อยู่ในรูปแบบข้อมูลที่ไม่สามารถระบุตัวตนสมาชิกได้
              และมีสิทธิขอให้ บริษัทดำเนินการให้ข้อมูลดังกล่าวถูกต้องและเป็นปัจจุบันได้
            </li>
            <li>
              8.3 สมาชิกยอมรับว่าบริษัทสามารถแก้ไข เพิ่มเติม หรือเปลี่ยนแปลง และให้ข้อมูลใดเกี่ยวกับ
              gettgo hero ภายใต้ข้อตกลงและเงื่อนไขการเป็นสมาชิกนี้
              ที่เปลี่ยนแปลงผ่านทางอินเทอร์เน็ตได้โดยไม่ต้องแจ้งให้สมาชิกทราบล่วงหน้าตามที่บริษัทเห็นสมควร{" "}
            </li>
            <li>
              8.4 เอกสารหรือข้อมูลใดที่ส่งไปตามที่อยู่หรือที่ตั้งสำนักงาน หมายเลขโทรศัพท์
              หรืออีเมลที่สมาชิกแจ้งไว้ ให้ถือว่าบริษัทได้ส่งโดยชอบตามกฎหมายแล้ว
              และถือว่าสมาชิกได้รับทราบการบอกกล่าวนั้นแล้ว
            </li>
          </ol>
          <h4 className="mt-16 font-bold">9. ทรัพย์สินทางปัญญา</h4>
          <ol className="ml-10 font-light">
            <li>
              บรรดาข้อมูลที่ปรากฏบนเว็บไซต์หรือช่องทางอื่นซึ่งเป็นการให้บริการของบริษัทในรูปแบบใดก็ตามที่สมาชิกสามารถเข้าถึงได้
              เช่น ข้อความ รูปภาพ ชื่อทางการค้า เครื่องหมายการค้า เครื่องหมายอื่นใด
              การออกแบบหรือแสดงผลข้อมูลในลักษณะต่างๆ ฯลฯ
              โดยบรรดาข้อมูลทั้งหมดข้างต้นเป็นทรัพย์สินทางปัญญาของบริษัทซึ่งขอสงวนสิทธิมิให้สมาชิกนำไปใช้
              เว้นแต่จะได้รับความยินยอมจากบริษัทเป็นกรณีไป
            </li>
          </ol>
          <h4 className="mt-16 font-bold">10. ข้อมูลการติดต่อและการส่งคำบอกกล่าว</h4>
          <ol className="ml-10 font-light">
            <li>
              10.1 สมาชิกยินยอมที่จะรับข้อมูลข่าวสารเกี่ยวกับข้อมูลข่าวสาร การบริการ ของ gettgo hero
              หรือบริการอื่นใดที่เกี่ยวข้องกับบริษัท ทางหมายเลขโทรศัพท์
              หรืออีเมลที่สมาชิกได้ให้ไว้แก่ทางบริษัท
            </li>
            <li>
              10.2 กรณีมีการเปลี่ยนแปลงข้อมูลการติดต่อหรือข้อมูลส่วนตัวใด
              สมาชิกมีหน้าที่ต้องแจ้งให้บริษัททราบโดยสามารถเข้าไปทำการแก้ไขเปลี่ยนแปลงข้อมูลด้วยตนเองผ่านทางเว็บไซต์หรือช่องทางอื่น
              หรือแจ้งผ่านช่องทางการติดต่อใดที่บริษัทกำหนด
            </li>
          </ol>
          <h4 className="mt-16 font-bold">11. การรักษาสภาพหรือการต่ออายุสมาชิก</h4>
          <ol className="ml-10 font-light">
            <li>
              ความเป็นสมาชิกนั้นมีกำหนดระยะเวลา 1 ปี นับแต่วันที่บริษัทรับเป็นสมาชิก
              โดยจะต้องจ่ายค่าต่อสมาชิกก่อนวันครบกำหนด
              ซึ่งอาจมีการปรับเปลี่ยนได้ตามที่บริษัทเห็นสมควรโดยจะประกาศผ่านทางเว็บไซต์หรือช่องทางอื่นของบริษัท
            </li>
          </ol>
          <h4 className="mt-16 font-bold">12. การยกเลิกหรือการสิ้นสุดความเป็นสมาชิก</h4>
          <ol className="ml-10 font-light">
            <li>
              สมาชิกรับทราบและตกลงว่าบริษัทสามารถยกเลิกการเป็นสมาชิกได้ทุกเมื่อ
              โดยไม่ต้องบอกกล่าวล่วงหน้า
              ทั้งนี้หากสมาชิกได้กระทำการอันใดที่เสื่อมเสียชื่อเสียงแก่บริษัท
              สมาชิกจะต้องรับผิดเป็นการเฉพาะตัวและจะไม่เรียกร้อง หรือฟ้องร้อง
              หรือดำเนินคดีกับบริษัททั้งสิ้น
            </li>
          </ol>
          <h4 className="mt-16 font-bold">13. ผลของการสิ้นสุดความเป็นสมาชิก</h4>
          <ol className="ml-10 font-light">
            <li>
              สมาชิกยอมรับว่าหากมีการยกเลิกการเป็นสมาชิกนั้น สมาชิกที่มีเงินที่ยังคงค้าง
              หรือเงินค้ำประกันไว้อยู่กับ “บริษัท เมืองไทย โบรกเกอร์ จำกัด
              สมาชิกยินยอมให้บริษัทหักหนี้อื่นใดที่ยังคงค้างอยู่กับบริษัทก่อน
              ทั้งนี้หากมียอดหนี้เกินจากเงินที่มีอยู่กับบริษัทผู้สมัครยินยอมที่จะจ่ายเงินส่วนต่างดังกล่าวให้กับบริษัท
              และให้ถือเอาการตัดสินใจของบริษัทเป็นที่สุด
            </li>
          </ol>
          <h4 className="mt-16 font-bold">14. การโอนสิทธิความเป็นสมาชิก</h4>
          <ol className="ml-10 font-light">
            <li>
              สมาชิกรับทราบและตกลงว่าการเป็นสมาชิกของบริษัทเป็นสิทธิเฉพาะตัวไม่สามารถโอนสิทธิให้แก่บุคคลอื่นได้
            </li>
          </ol>
          <h4 className="mt-16 font-bold">15. การสละสิทธิ หรือการเยียวยาตามข้อกำหนดและเงื่อนไข</h4>
          <ol className="ml-10 font-light">
            <li>
              15.1 กรณีที่บริษัทไม่ใช้สิทธิหรือล่าช้าในการใช้สิทธิหรือการแก้ไขเยียวยาใด
              ภายใต้ข้อกำหนดและเงื่อนไขการเป็นสมาชิกฉบับนี้
              ไม่ถือว่าบริษัทสละสิทธิหรือสละการแก้ไขเยียวยานั้น
            </li>
            <li>
              15.2 ไม่ถือว่าการใช้สิทธิหรือการแก้ไขเยียวยาแต่เพียงอย่างเดียวหรือแต่บางส่วน
              เป็นการตัดการใช้สิทธิหรือการแก้ไขเยียวยานั้นในครั้งต่อไปหรือเป็นการตัดการใช้สิทธิหรือการแก้ไขเยียวยาในส่วนอื่นๆ
            </li>
            <li>
              15.3
              ไม่ถือว่าการสละสิทธิในการละเมิดข้อตกลงใดในข้อกำหนดและเงื่อนไขการเป็นสมาชิกฉบับนี้เป็นการสละสิทธิในการละเมิดข้อตกลงนั้นหรือข้อตกลงอื่นๆสำหรับการละเมิดที่เกิดขึ้นภายหลัง
            </li>
            <li>
              15.4 การยกเลิกการเป็นสมาชิกไม่ว่าด้วยเหตุผลใดก็ตาม
              จะไม่ถือว่าบริษัทสละสิทธิในการเรียกร้องเงินหรือประโยชน์อื่นใดที่บริษัทมีสิทธิในการเรียกร้องจากสมาชิก
            </li>
          </ol>
          <h4 className="mt-16 font-bold">16. การแก้ไขเปลี่ยนแปลงข้อกำหนดและเงื่อนไข</h4>
          <ol className="ml-10 font-light">
            <li>
              16.1 บริษัทสงวนสิทธิในการแก้ไขเปลี่ยนแปลงข้อกำหนดและเงื่อนไขการเป็นสมาชิกฉบับนี้ได้
              โดยอาจเป็นไปเพื่อให้สอดคล้องกับกฎหมาย กฎเกณฑ์ หรือกฎระเบียบต่างๆที่เกี่ยวข้อง
              หรือเพื่อให้สอดคล้องกับการเปลี่ยนแปลงของนโยบายหรือรูปแบบและวิธีการให้บริการของบริษัท
              โดยในกรณีที่มีการแก้ไขเปลี่ยนแปลงในสาระสำคัญ
              จะมีการแจ้งให้สมาชิกทราบถึงการแก้ไขเปลี่ยนแปลงดังกล่าวนั้นล่วงหน้า
              กรณีที่มีการแก้ไขเปลี่ยนแปลงข้างต้น
              เมื่อสมาชิกได้เข้าใช้บริการใดภายใต้ข้อกำหนดและเงื่อนไขการเป็นสมาชิกฉบับนี้ภายหลังจากที่มีการแก้ไขเปลี่ยนแปลงแล้ว
              ให้ถือว่าสมาชิกยอมรับข้อกำหนดและเงื่อนไขตามที่ได้มีการแก้ไขเปลี่ยนแปลงแล้ว
            </li>
            <li>
              16.2 เมื่อบริษัทมีการประกาศยกเลิก แก้ไข หรือเปลี่ยนแปลงข้อกำหนดหรือเงื่อนไขใด
              หากบริษัทได้ติดประกาศไว้ที่สำนักงานบริษัท หรือบนเว็บไซต์หรือช่องทางอื่นของบริษัท
              หรือด้วยวิธีการใดที่บริษัทเห็นสมควร ให้ถือว่าบริษัทได้แจ้งสมาชิกทราบแล้ว
              และถือว่าสมาชิกตกลงยินยอมผูกพันตนตามการยกเลิก แก้ไข
              หรือเปลี่ยนแปลงข้อกำหนดและเงื่อนไขนั้น
            </li>
          </ol>
          <h4 className="mt-16 font-bold">17. กฎหมายที่ใช้บังคับ</h4>
          <ol className="ml-10 font-light">
            <li>
              17.1
              ข้อกำหนดและเงื่อนไขการเป็นสมาชิกฉบับนี้ให้บังคับและตีความตามกฎหมายแห่งราชอาณาจักรไทย
            </li>
            <li>
              17.2 หากมีข้อพิพาทเกิดขึ้นระหว่างบริษัทกับสมาชิก
              ทั้งสองฝ่ายตกลงจะเจรจาร่วมกันเพื่อระงับข้อพิพาทกันก่อน หากเวลาล่วงเลยไปเกินกว่า 30
              วันแล้ว ยังไม่สามารถระงับข้อพิพาทที่เกิดขึ้นได้
              ฝ่ายใดฝ่ายหนึ่งมีสิทธิฟ้องร้องคดีต่อศาลได้
            </li>
          </ol>

          <p className="mt-16">
            - ข้าพเจ้าได้ทำการลงทะเบียนเพื่อเป็นสมาชิกของ gettgo hero โดยข้าพเจ้าได้รับทราบ
            ยอมรับและตกลงผูกพันในข้อตกลงและเงื่อนไขตามที่ปรากฎดังกล่าวข้างต้นและตามประกาศของบริษัทที่จะได้เผยแพร่ต่อไป
          </p>
          <p>- ข้าพเจ้าขอรับรองว่า รายละเอียดข้างต้นเป็นความจริงทุกประการ</p>

          <div className="text-center">
            <Button onClick={onClose} color="secondary">
              ตกลง
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnightLicence;
