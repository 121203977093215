import React from "react";

const InsurerImg = ({ src, alt, style }) => {
  return (
    <div className="img vir" style={style}>
      {src ? <img src={src} alt={alt} /> : null}
    </div>
  );
};

export default InsurerImg;
