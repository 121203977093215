import Cookies from "js-cookie";
import React, { createContext, Dispatch, Reducer, useContext, useReducer } from "react";

import { useAuthenticate } from "~src/helpers/authen";
import AuthReducer, {
  initialAuthState,
  State as AuthState,
  Action as AuthAction,
} from "~src/reducer/AuthReducer";
import { isJsonString } from "~src/helpers/helpers";

// TODO: Refactor/Reconsolidate Auth codes (context/reducer/validateAuth)

const COOKIE_TOKEN_KEY = "access_token";

export interface AuthContextProps {
  session: AuthState;
  dispatch: Dispatch<any>;
  validateAuth: () => boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);
export default AuthContext;

export interface AuthContextProviderProps {}

// TODO: Refactor/Reconsolidate Auth codes (context/reducer/validateAuth/etc.)
export function AuthContextProvider({
  children,
}: React.PropsWithChildren<AuthContextProviderProps>) {
  const [session, dispatch] = useReducer<Reducer<AuthState, AuthAction>>(
    AuthReducer,
    restoreSessionState()
  );
  const { validateAuth } = useAuthenticate();

  return (
    <AuthContext.Provider value={{ session, dispatch, validateAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

// TODO: Revise this later
function restoreSessionState(): AuthState {
  const apiToken = Cookies.get(COOKIE_TOKEN_KEY);
  
  return {
    ...initialAuthState,
    isInitializing: !apiToken,
    apiToken,
    username: Cookies.get("username") || initialAuthState.username,
    role: isJsonString(Cookies.get("role")) ? JSON.parse(Cookies.get("role")) : null || initialAuthState.role,
    title: Cookies.get("title") || initialAuthState.title,
    firstname: Cookies.get("firstname") || initialAuthState.firstname,
    lastname: Cookies.get("lastname") || initialAuthState.lastname,
    show_paid_commission: Cookies.get("show_paid_commission") || initialAuthState.show_paid_commission,
    permissions: (isJsonString(Cookies.get("permissions")) ? JSON.parse(Cookies.get("permissions")) : null) || initialAuthState.permissions,
  };
}

export function useAuthContext(): AuthContextProps {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("AuthContext provider not found");
  }

  return context;
}
