import React, { createContext, Dispatch, Reducer, useContext, useReducer } from "react";

import CheckoutReducer, {
  initCheckoutState,
  State as CheckoutState,
  Action as CheckoutAction,
} from "~src/reducer/CheckoutReducer";

// TODO: Refactor/Reconsolidate Checkout states

export interface CheckoutContextProps {
  state: CheckoutState;
  dispatch: Dispatch<CheckoutAction>;
}

const CheckoutContext = createContext<CheckoutContextProps | undefined>(undefined);
export default CheckoutContext;

export interface CheckoutProviderProps {}

export function CheckoutProvider({ children }: React.PropsWithChildren<CheckoutProviderProps>) {
  const [state, dispatch] = useReducer<Reducer<CheckoutState, CheckoutAction>>(
    CheckoutReducer,
    initCheckoutState("")
  );

  return (
    <CheckoutContext.Provider value={{ state, dispatch }}>{children}</CheckoutContext.Provider>
  );
}

// DEPRECATED: Use useCheckoutContext() instead
// export const CheckoutConsumer = CheckoutContext.Consumer;

export function useCheckoutContext(): CheckoutContextProps {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error("CheckoutContext Provider not found");
  }

  return context;
}
