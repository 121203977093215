import React from "react";
import CommonModal from "~/src/components/modal/common";
import InsurerDetail from "~src/components/card/InsurerDetail";
import InsurerImg from "~src/components/card/InsurerImg";
import Product from "~src/models/Product";
import Price from "~src/components/card/Price";

interface Props {
  multiply?: number;
  packageObj: Product;
  index?: number;
  isOpen?: boolean;
  onClose?: () => void;
  details?: string;
}

const CoverageModal: React.FC<Props> = ({
  multiply = 1,
  packageObj,
  index,
  isOpen,
  onClose,
  details,
}): JSX.Element => {
  const coverageDetails = packageObj.coverageDetails();

  return (
    <CommonModal open={isOpen} persistent={true} onClose={() => onClose?.()}>
      <div
        className="animate-fade-in duration-700 flex flex-col justify-center items-center gap-20"
        key={index}
      >
        <div className="w-full flex gap-4 justify-between">
          <div className="flex">
            <InsurerImg
              style={{ width: "80px", height: "80px" }}
              src={packageObj.insurerImg}
              alt={packageObj.insurerName}
            />
            <InsurerDetail
              name={packageObj.insurerName}
              productName={`แผน: ${packageObj.productName}`}
              className="overflow-visible flex"
            />
          </div>
          <Price
            className="font-extrabold text-lg self-end"
            value={packageObj.standardPrice * multiply}
            suffix={packageObj.priceSuffix()}
          />
        </div>

        <div
          className="flex flex-col w-full gap-20 bg-gray-200 p-4 rounded-5 overflow-y-auto"
          style={{ height: "26rem" }}
        >
          {coverageDetails.map((detail, indexDetail) => (
            <div id={`coverage-detail-${indexDetail}`}>
              <p className="text-base font-extrabold col-span-2"> {detail.coverageType} </p>
              {detail?.details.map((title, id) => (
                <div id={`coverage-detail-type${id}`} className="grid grid-cols-2 pt-2 font-light">
                  <div>{title.name}</div>
                  <div className="text-right">{title.sumInsured}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </CommonModal>
  );
};

export default CoverageModal;
