import _filter from "lodash/filter";
import _map from "lodash/map";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { unstable_batchedUpdates } from "react-dom";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import { ButtonRadius, UploadField } from "~src/components/form";
import FormGroup from "~src/components/form/fromGroup/FormGroup";

import dictionary from "~src/data.yaml";
import OrdersAPIClient, { OrdersAPIFactory, useOrdersAPI } from "~src/api/Orders";
import { useAppContext } from "~src/context/App";
import { usePageContext } from "~src/context/Page";
import { createAttachmentFromFile } from "~src/helpers/attachments";
import { messages } from "~src/helpers/messages";
import APIResponse from "~src/models/APIResponse";
import { Attachment } from "~src/types/attachment";
import { Payment } from "~src/types/payment";

import "./showPayslipModal.scss";
import SecureLinkImg from "~src/components/secureLinkImg";
// import placeholderImage from "~assets/image-not-available.png";

// function extractFileField(file) {
//   return {
//     url: URL.createObjectURL(file),
//     filename: file.name,
//     dataType: file.type,
//     imageFrom: "local",
//   };
// };

//TODO: Revise this later
const createPaySlipFromURL = (url?: string) => (url ? { url, dataType: "image/?" } : undefined);

export interface ShowPaySlipModalProps {
  orderID: number;
  onClose: () => void;
  payslip?: Attachment;
  payslip_url?: string;
  payment?: Payment;
  editable?: boolean;
  uploadFormID?: string;
  ordersAPIFactory?: OrdersAPIFactory;
  onUploaded?: (respData?: APIResponse<any>) => void;
  onError?: (err: Error) => void;
}

const ShowPaySlipModal = (props: ShowPaySlipModalProps) => {
  const {
    onClose,
    payslip,
    payslip_url,
    payment,
    orderID,
    editable = false,
    uploadFormID = "showPaySlipForm",
    ordersAPIFactory,
    onUploaded,
    onError,
  } = props;
  const amount = payment?.required_amount;

  const { assets } = useAppContext();
  // HACK: Workaround for Parcel bundler Error: Cannot find module 'assets/...' with some asset files
  const { imageNotAvailable: placeholderImage } = assets;

  const { setAlert, setPreloading } = usePageContext();
  const { ordersAPI } = useOrdersAPI({
    apiFactory: ordersAPIFactory,
    onError,
  });
  const { register, errors, handleSubmit } = useForm<any>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [formElement, setFormElement] = useState<HTMLFormElement | undefined>();
  //TODO: Revise this later
  const [imageAttachment, setImageAttachment] = useState<Attachment | undefined>(
    payslip || createPaySlipFromURL(payslip_url)
  );
  const [imageFile, setImageFile] = useState<File | Object | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewImageSrc, setPreviewImageSrc] = useState<any>(undefined);

  const submitBtnRef = useRef<HTMLFormElement>();

  useDeepCompareEffectNoCheck(() => {
    setImageAttachment(payslip || createPaySlipFromURL(payslip_url));
  }, [payslip, payslip_url]);

  useDeepCompareEffectNoCheck(() => {
    const _attachment = imageFile ? createAttachmentFromFile(imageFile) : undefined;
    _attachment && setImageAttachment(_attachment);
  }, [imageFile]);

  const handleChangeUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;

    setImageFile(files[0]);

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      setPreviewImageSrc(e.target.result);
    };

    fileReader.readAsDataURL(files[0]);
  };

  async function handleUploadSubmit(_, evt: React.BaseSyntheticEvent) {
    const data = new FormData(evt.target);

    try {
      unstable_batchedUpdates(() => {
        setAlert(undefined);
        setPreloading(true);
        setIsSubmitting(true);
      });

      const respData = await ordersAPI.addPayslip(orderID, data);
      unstable_batchedUpdates(() => {
        setIsSubmitting(false);
        setPreloading(false);
      });
      onUploaded && onUploaded(respData);
    } catch (err) {
      if (ordersAPI.isAbortError(err)) {
        return;
      }
      unstable_batchedUpdates(() => {
        setAlert(err.message, err.details ? { submit_payslip: err.details } : undefined);
        setIsSubmitting(false);
        setPreloading(false);
      });
    }
  }

  //TEST
  // console.log("image:", imageAttachment, "; placeholder:", placeholderImage);
  console.log("Components:", { FormGroup, UploadField });

  return (
    <div className="showPayslipModal modal">
      <div className="modal-overlay" onClick={() => onClose()}></div>
      <div className="modal-box">
        <div className="header">
          <div className="icon">
            <i className="material-icons slip-icon">perm_media</i>
          </div>
          <h2>{dictionary.order.payslip_title}</h2>
          <div className="buttons">
            <button className="material-icons close-icon" role="button" onClick={() => onClose()}>
              close
            </button>
          </div>
        </div>
        <div className="payslip-image-frame scrollable-content">
          {(!imageFile && payslip_url) ? (
            <SecureLinkImg
              src={payslip_url || placeholderImage}
              alt={imageAttachment?.filename || "Payslip Image"}
            />
          ) : (
           <>
             {previewImageSrc && (
               <img src={previewImageSrc} alt={imageAttachment?.filename || "Payslip Image"}/>
             )}
           </>
          )}

          {imageAttachment?.filename && <p>{imageAttachment.filename}</p>}
        </div>
        {editable && (
          <div className="payslip-upload-form flex flex-col">
            <form
              id={uploadFormID}
              autoComplete="off"
              ref={(el) => setFormElement(el)}
              onSubmit={handleSubmit(handleUploadSubmit)}
              encType="multipart/form-data"
            >
              <input type="hidden" name="payment[token]" value={payment?.token} />
              <input type="hidden" name="payment[amount]" value={amount} />
              <input type="hidden" name="payment[payment_method]" value={payment?.pay_method} />
              <FormGroup require={true}>
                {/* label="Pay Slip" htmlForLabel={`${uploadFormID}_payslip`} */}
                <UploadField
                  id={`${uploadFormID}_payslip`}
                  name="payment[payslip]"
                  inputRef={register(messages.required)}
                  onChange={handleChangeUpload}
                />
              </FormGroup>
              <ButtonRadius
                color="primary"
                type="submit"
                inputRef={submitBtnRef}
                disabled={!imageFile || !formElement || !ordersAPI || isSubmitting}
              >
                <i className="material-icons">add_photo_alternate</i> Upload
              </ButtonRadius>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowPaySlipModal;
