import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "~src/store";

export interface NotificationState {
  open: boolean;
  type: AlertColor;
  message: string | string[];
}

const initialState: NotificationState = {
  open: false,
  type: "success",
  message: "",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showErrorNotification: (state, { payload }) => {
      state.open = true;
      state.type = "error";
      state.message = payload.message;
    },
    hideNotification: (state) => {
      state.open = false;
    },
  },
});

export const { showErrorNotification, hideNotification } = notificationSlice.actions;

export const notificationSelector = (state: RootState) => state.notification;

export default notificationSlice.reducer;
