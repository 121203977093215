import _ from "lodash";
import queryString from "query-string";
import React, { useState } from "react";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import useDeepCompareEffect from "use-deep-compare-effect";

import StaffOrdersTableLayout, { StaffOrdersTableLayoutProps } from "./table/Table";
import DatePickerRange from "~src/components/datePickerRange/DatePickerRange";
import { ButtonRadius } from "~src/components/form";
import SectionHeader from "~src/components/layout/staff/SectionHeader";
import SearchForm from "~src/components/staff/SearchForm";
import PaymentMethodFilter from "./components/PaymentMethodFilter";
import OrdersListView from "~src/components/orders/OrdersListView";

import { FindOrdersResponse, OrdersAPIClientProps } from "~src/api/Orders";
import StaffOrdersAPIClient from "~src/api/staff/Orders";
import { OrdersContextProvider, useOrdersContext } from "~src/context/Orders";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { toISODate } from "~src/helpers/date";
import { dateFormat } from "~src/helpers/dateTime";
import useInput from "~src/hooks/useInput";
import usePaginatedURL from "~src/hooks/usePaginatedURL";
import { FindOrdersParams } from "~src/models/params/FindOrdersParams";
import { PaginationParams } from "~src/models/params/PaginationParams";
import { Status as OrderStatus } from "~src/types/order";
import { InstallmentAttachmentStatus } from "~src/types/payment";

import "./ordersList.scss";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;
const MIN_DATE = '2023-06-16';
const MAX_DATE = new Date().toISOString().split('T')[0];

function getSearchParamsWithDefaults(
  filters: Partial<FindOrdersParams> | undefined,
  paginationParams: PaginationParams
) {
  return {
    ...filters,
    keyword: filters?.keyword ?? "",
    payment_method: ["installment", "installment_kbank_cc", "installment_two_c_two_p_cc"],
    attachment_status: filters?.attachment_status ?? [],
    order_status: filters?.order_status ?? [],
    created_from: filters?.created_from ?? null,
    created_to: filters?.created_to ?? null,
    page: paginationParams.page,
    per_page: paginationParams.per_page,
  };
}

interface OrderStatusOption {
  value: OrderStatus;
  label: string;
}

const statusOptions: OrderStatusOption[] = [
  { value: "open", label: "รอกรอกข้อมูล" },
  { value: "waiting_payment", label: "รอชำระเงิน" },
  { value: "waiting_verify", label: "รอการตรวจสอบ" },
  { value: "completed", label: "ชำระเงินเรียบร้อย" },
  { value: "closed", label: "คำสั่งซื้อหมดอายุ" },
];
const statusOptionsMap = statusOptions.reduce(
  (m, opt) => ({
    ...m,
    [opt.value]: opt,
  }),
  {}
);

export const ordersAPIFactory = (props: OrdersAPIClientProps) =>
  new StaffOrdersAPIClient({ ...props });

export interface StaffOrdersPageProps {
  location: Location;
}

export default function StaffOrdersPage(props: StaffOrdersPageProps) {
  return (
    <PageContextProvider withQuery location={props.location}>
      <StaffOrdersPageInner {...props} />
    </PageContextProvider>
  );
}

type StaffOrdersPageInnerProps = StaffOrdersPageProps;

var StaffOrdersPageInner = ({}: StaffOrdersPageInnerProps) => {
  const { queryParams, setQueryParams, onError: onPageError } = usePageContext();
  const { searchParams, setSearchParams, paginationParams, setPaginationParams } = usePaginatedURL({
    queryParams: {
      ...queryParams,
      created_from: MIN_DATE,
      created_to: MAX_DATE
    },
    setQueryParams,
    defaultPage: DEFAULT_PAGE,
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });
  const { addToast } = useToasts();

  const { value: keyword, bind: handleChangeKeyword } = useInput(searchParams.keyword ?? "");

  const [ordersContextParams, setOrdersContextParams] = useState(
    getSearchParamsWithDefaults(searchParams, paginationParams)
  );
  useDeepCompareEffect(() => {
    setOrdersContextParams(getSearchParamsWithDefaults(searchParams, paginationParams));
  }, [searchParams, paginationParams]);

  function handleSearch(params: Partial<FindOrdersParams>) {
    const { created_from, created_to, page, per_page, ...otherParams } = params;

    setSearchParams({
      ...searchParams,
      ...otherParams,
      created_from: created_from ? toISODate(created_from) : undefined,
      created_to: created_to ? toISODate(created_to) : undefined,
    });
    setPaginationParams({
      page: page || paginationParams.page,
      per_page: per_page || paginationParams.per_page,
    });
  }

  const handleSearchBtnClick = (_evt: React.MouseEvent<HTMLElement>) => {
    handleSearch({ ...searchParams, keyword });
  };

  const handleSearchBarKeyPress = (evt: React.KeyboardEvent<HTMLElement>) => {
    if (evt.key === "Enter") handleSearch({ ...searchParams, keyword });
  };

  function handleFiltersSubmit(newValue: Partial<FindOrdersParams>) {
    handleSearch({ ...newValue, page: 1 });
  }

  function handleError(err: Error) {
    addToast(
      <div>
        <h4>ERROR</h4>
        <p>{err.message}</p>
      </div>,
      {
        appearance: "error",
        autoDismiss: true,
      }
    );

    onPageError(err);
  }

  return (
    <OrdersContextProvider
      searchParams={ordersContextParams}
      apiFactory={ordersAPIFactory}
      onError={handleError}
      loadOnInit={true}
    >
      <div className="orders-page">
        <OrdersListView
          currentParams={ordersContextParams}
          onSearch={handleSearch}
          onError={handleError}
          renderSectionHeader={() => (
            <SectionHeader
              title="รายการคำสั่งซื้อ"
              breadcrumbs={[{ url: "#", label: "รายการซื้อขาย", current: true }]}
              searchComponent={
                <SearchForm
                  type="text"
                  placeholder="ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่คำสั่งซื้อ"
                  {...handleChangeKeyword}
                  handleClick={handleSearchBtnClick}
                  handleKeyPress={handleSearchBarKeyPress}
                />
              }
            />
          )}
          renderTableLayout={(props) => (
            <StaffOrderResultsArea
              {...props}
              currentParams={{ ...searchParams, ...paginationParams }}
              onFiltersSubmit={handleFiltersSubmit}
            />
          )}
        />
      </div>
    </OrdersContextProvider>
  );
};

export interface StaffOrderResultsAreaProps extends StaffOrdersTableLayoutProps {
  currentParams?: queryString.StringifiableRecord;
  onFiltersSubmit: (value: Partial<FindOrdersParams>) => void;
}

export const StaffOrderResultsArea = ({
  currentParams,
  onFiltersSubmit,
  ...otherProps
}: StaffOrderResultsAreaProps) => {
  const { isLoading, response: ordersResponse } = useOrdersContext();
  const keyword = currentParams.keyword ? `${currentParams.keyword}` : undefined;

  return (
    <>
      <StaffOrderResultsStatsBox
        isLoading={isLoading}
        keyword={keyword}
        response={ordersResponse}
      />
      <StaffOrdersFiltersForm value={currentParams} onSubmit={onFiltersSubmit} />
      <StaffOrdersTableLayout {...otherProps} />
    </>
  );
};

export interface StaffOrderResultsStatsBoxProps {
  isLoading: boolean;
  keyword?: string;
  response?: FindOrdersResponse;
}

export const StaffOrderResultsStatsBox = ({
  isLoading,
  keyword,
  response,
}: StaffOrderResultsStatsBoxProps) => {
  return (
    <div className="result-box">
      {!isLoading && response && response.total_count > 0 ? (
        <>
          {keyword ? (
            <span>
              คำค้นหา:
              <strong style={{ padding: "0 5px" }}>"{keyword}" พบ</strong>
            </span>
          ) : null}
          <span>
            {response.total_count > 20 ? (
              <span>รายการทั้งหมด 20+ รายการ</span>
            ) : (
              <>
                <span>ทั้งหมด</span>
                <strong> {response.total_count}</strong> <span>รายการ</span>
              </>
            )}
          </span>
        </>
      ) : null}
    </div>
  );
};

export interface StaffOrdersFiltersFormProps {
  value: Partial<FindOrdersParams>;
  onValueChange?: (value: Partial<FindOrdersParams>) => void;
  onSubmit?: (value: Partial<FindOrdersParams>) => void;
}

export const StaffOrdersFiltersForm = ({
  value,
  onValueChange,
  onSubmit,
}: StaffOrdersFiltersFormProps) => {
  const [filtersState, setFiltersState] = useState(value);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<OrderStatusOption[]>([]);

  useDeepCompareEffect(() => {
    setFiltersState(value);
    setSelectedStatusOptions(
      value.order_status?.map((status) => statusOptionsMap[status]).filter((opt) => !!opt) ?? []
    );
  }, [value]);

  function handleStatusOptionsChange(selectedOpts, evt: any) {
    const _newFiltersState = {
      ...filtersState,
      order_status: selectedOpts.map((opt) => opt.value) as OrderStatus[],
    };
    setFiltersState(_newFiltersState);
    onValueChange && onValueChange(_newFiltersState);
    setSelectedStatusOptions(selectedOpts);
  }

  function handleAttachmentStatusChanged(newStatuses: any) {
    const _newFiltersState = {
      ...filtersState,
      attachment_status: newStatuses as InstallmentAttachmentStatus[],
    };
    setFiltersState(_newFiltersState);
    onValueChange && onValueChange(_newFiltersState);
  }

  function handleDatesChange(dateForm: Date | undefined, dateTo: Date | undefined) {
    const _newFiltersState = {
      ...filtersState,
      created_from: dateFormat(dateForm),
      created_to: dateFormat(dateTo),
    };

    setFiltersState(_newFiltersState);
    onValueChange && onValueChange(_newFiltersState);
  }

  const resetValueCallback = () => {
    setFiltersState({
      ...filtersState,
      created_from: null,
      created_to: null,
    });
  };

  function handleSearchClick(evt: React.MouseEvent<HTMLElement>) {
    onSubmit && onSubmit(filtersState);
  }

  return (
    <section className="filter-area">
      <div className="grid grid-cols-4 gap-4">
        <div>
          <PaymentMethodFilter
            className="z-50"
            values={filtersState.payment_method || []}
            onStatusChanged={handleAttachmentStatusChanged}
          />
        </div>
        <div>
          <label htmlFor="">วันที่ทำรายการ</label>
          <DatePickerRange
            resetValueCallback={resetValueCallback}
            onDateChange={handleDatesChange}
            values={[filtersState.created_from, filtersState.created_to]}
          />
        </div>
      </div>
      <div className="text-center col-12 mt-4">
        <ButtonRadius color="secondary" onClick={handleSearchClick}>
          ค้นหา
        </ButtonRadius>
      </div>
    </section>
  );
};
