import React from "react";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import useAxios from "../../../hooks/useAxios";
import SectionHeader from "../../../components/layout/staff/SectionHeader";
import Search from "./components/Search";
import Tab from "./components/Tab";
import Table from "./table/RenewDocTable";
import CancelOrderModal from "../../../components/modal/orders/CancelOrder";
import { Order } from "../../../types/order";
import { urlStringify } from "../../../helpers/helpers";
import axios from "../../../helpers/configAxios";

export interface ICancelOrderModal {
  open?: boolean;
  orderId: number | null;
}

interface Data {
  current_page: number;
  total_count: number;
  total_pages: number;
  content: Order[];
}

const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const RenewDoc = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });
  const keyword = queryParams?.keyword ?? "";
  const [searchValue, setSearchValue] = React.useState<string>(
    keyword.toString()
  );
  const response = useAxios<Data>(
    `/api/orders?status=renew&order_status[]=open&${urlStringify(
      Object.assign(
        {
          page: DEFAULT_PAGE,
          per_page: PER_PAGE,
          keyword: keyword,
        },
        queryParams
      )
    )}`
  );

  const [cancelOrderState, setCancelOrderState] =
    React.useState<ICancelOrderModal>({
      open: false,
      orderId: null,
    });

  const handlePageClick = (page: { selected: number }): void => {
    const selectedPage = page.selected + 1;
    let queries = urlStringify({
      page: selectedPage,
      per_page: PER_PAGE,
      keyword: keyword,
    });
    window.location.href = `${location.pathname}?${queries}`;
  };

  const handleSearch = (e: React.FormEvent): void => {
    e.preventDefault();
    const queries = urlStringify({
      keyword: searchValue,
      per_page: PER_PAGE,
      page: 1,
    });
    window.location.href = `${location.pathname}?${queries}`;
  };

  const handleCancelOrder = (data: { notice: string }) => {
    let jsonData = { ...data, order_id: cancelOrderState.orderId };
    axios
      .post(`/api/orders/cancel`, jsonData)
      .then(() => window.location.reload())
      .catch((err) => console.log(err));
  };

  const handleEditOrder = (order: Order) => {
    const mainQuote = order.quotes.find(q => q.policy_type !== 'cmi') || order.quotes.find(q => q.policy_type === 'cmi')
    window.location.href = `renewal/${order.order_id}/edit/${mainQuote.policy_type}`
  }

  return (
    <div className="p-4">
      <SectionHeader
        title="ใบเตือนต่ออายุ"
        breadcrumbs={[{ url: "#", label: "กรมธรรม์", current: true }]}
        searchComponent={
          <Search
            value={searchValue}
            onInputChange={(e) => setSearchValue(e.target.value)}
            onSubmit={handleSearch}
          />
        }
      />
      <Tab />
      <div className="relative p-4 bg-white shadow z-1">
        <Table
          loadStatus={response.status}
          data={response.data?.content}
          onCancelOrder={(id) =>
            setCancelOrderState({
              ...cancelOrderState,
              open: true,
              orderId: id,
            })
          }
          onEditOrder={handleEditOrder}
        />
        {response.status === "loaded" && response.data?.total_pages > 1 && (
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            initialPage={response.data?.current_page - 1}
            pageCount={response.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"gm-paginate"}
            // subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            disableInitialCallback={true}
          />
        )}
      </div>

      <CancelOrderModal
        open={cancelOrderState.open}
        onSubmit={handleCancelOrder}
        onClose={() => setCancelOrderState({ open: false, orderId: null })}
      />
    </div>
  );
};

export default RenewDoc;
