import React, { useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { ErrorBoundary } from "react-error-boundary";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import jsonToFormData from "@ajoelp/json-to-formdata";

import axios from "~/src/helpers/configAxios";

import SectionHeader from "~/src/components/layout/staff/SectionHeader";
import ErrorFallback from "~/src/components/Forms/ErrorFallback";
import TypeDetailsPanel from "./components/panel/TypeDetailsPanel";
import InsurancePanel from "./components/panel/InsurancePanel";
import VehiclePanel from "./components/panel/VehiclePanel";
import RemarkPanel from "./components/panel/RemarkPanel";
import InsuredInfoPanel from "./components/panel/InsuredInfoPanel";
import DeliveryPanel from "./components/panel/DeliveryPanel";
import PremiumPanel from "./components/panel/PremiumPanel";
import PaymentPanel from "./components/panel/PaymentPanel";
import {
  loadingInfo,
  mainTypeMotor,
  addonInfo,
  agentInfoResponseAtom,
  agentInfoAtom,
} from "~/src/states/orderCustom";
import Loading from "~/src/components/loading";
import { Button } from "~/src/components/form";
import Preloading from "~/src/components/loading/Preloading";

const Create: React.FC = (): JSX.Element => {
  const isLoading = useRecoilValue(loadingInfo);
  const { motorPanelOpen } = useRecoilValue(mainTypeMotor);
  const { addonPrice } = useRecoilValue(addonInfo);
  const [preloading, setPreloading] = useState(false);
  const agents = useRecoilValue(agentInfoResponseAtom);
  const setAgentInfo = useSetRecoilState(agentInfoAtom);

  const history = useHistory();
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      order: {
        agent_number: "",
        contract: "new",
        remark: "",
        insured_category: "person",
        details: {
          plate_number: "",
          chassis_number: "",
          cover_start_date: "",
          cover_end_date: "",
          addon_start_date: "",
          addon_end_date: "",
        },
        insureds_attributes: [
          {
            title: "",
            firstname: "",
            lastname: "",
            birthday: "",
            email: "",
            phone_number: "",
            idcard: "",
            company_name: "",
            company_tax: "",
            delivery_type: 1,
            delivery_address_type: "insured_address",
            addresses_attributes: [
              {
                address: "",
                province: "",
                district: "",
                sub_district: "",
                postcode: "",
                address_type: "insured",
              },
              {
                address: "",
                province: "",
                district: "",
                sub_district: "",
                postcode: "",
                address_type: "delivery",
              },
            ],
          },
        ],
      },
      product: {
        insurer_code: "",
        name: "",
        type: "",
        policy_type: "",
        selling_price: "",
        insurer_gross_amount: "",
        insurer_stamp_amount: "",
        insurer_vat_amount: "",
        sum_insured: "",
        standard_price: "",
        details: {
          remark: "",
          repair_facility: "garage",
        },
      },
      quote: {
        discount: "",
        coverage_from: "",
        coverage_to: "",
        received_commission: "",
        received_ov: "",
        received_printing: "",
        paid_commission_percent: "",
        paid_ov_percent: "",
        paid_printing_percent: "",
      },
      quotation: {
        criteria: {
          car_brand: "",
          car_model: "",
          year: "",
          car_submodel: "",
          province: "",
        },
      },
      addon: {
        commission: {
          received_commission: "",
          received_ov: "",
          received_printing: "",
          paid_commission_percent: "",
          paid_ov_percent: "",
          paid_printing_percent: "",
        },
        discount: "",
        details: {
          addon_start_date: "",
          addon_end_date: "",
        },
      },
    },
  });

  const agent_number = useWatch({ control: methods.control, name: "order.agent_number" });

  React.useEffect(() => {
    if (agent_number) {
      const agent = agents.filter((o) => {
        return o.username === agent_number;
      });
      setAgentInfo({
        ...agent[0],
      });
    }
  }, [agent_number]);

  const reworkTypeData = (data) => {
    const reworkingType = ["Super Car", "รถบรรทุกเกิน 4 ตัน", "Big Bike", "รถจักรยานยนต์"];
    if (!reworkingType.includes(data.product.type)) return data;

    data.product.policy_type = `${data.product.type} ${data.product.policy_type}`;
    return data;
  };

  const reworkPaymentAttachmentData = (data) => {
    if (data.payment.payslip && data.payment.payslip.length > 0) {
      data.payment.payslip = data.payment.payslip[0];
    }

    const paymentInfoKeys = [
      "agent_installment_contract",
      "customer_installment_contract",
      "installment_selfie_image"
    ]
    
    paymentInfoKeys.forEach((key) => {
      if (data?.payment_info?.[key] && data.payment_info?.[key]?.length > 0) {
        data.payment_info[key] = data?.payment_info?.[key][0];
      }
    })

    if (data.order?.payer_id_card && data.order?.payer_id_card?.length > 0) {
      data.order.payer_id_card = data.order?.payer_id_card[0];
    }

    return data;
  };

  const reworkPaymentTimeData = (data) => {
    if (data.payment.paid_at_time) {
      data.payment.paid_at_time = `${data.payment.paid_at_time.getHours()}:${data.payment.paid_at_time.getMinutes()}`;
    }
    return data;
  };

  const onSubmit = (data) => {
    reworkTypeData(data);
    reworkPaymentAttachmentData(data);
    reworkPaymentTimeData(data);
    const formData = jsonToFormData(data, {
      arrayIndexes: false,
      excludeNull: false,
    });

    setPreloading(true);
    postCreateCustomOrder(formData);
  };

  const onError = (error) => {
    throw new Error(`Error: ${error}`);
  };

  const postCreateCustomOrder = (data) => {
    const url = `/api/orders/create/custom`;
    axios
      .post(url, data)
      .then(() => {
        setPreloading(false);
        history.push(`/staffs/policies`);
      })
      .catch((error) => {
        setPreloading(false);
        throw new Error(`Error: ${error}`);
      });
  };

  return (
    <div className="w-full h-full p-16 bg-gray-1">
      {isLoading && <Loading />}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SectionHeader
          title="เพิ่มงานนอกระบบ"
          breadcrumbs={[{ url: "#", label: "รายการซื้อขาย", current: true }]}
        />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-row gap-4 mt-4 laptop:flex-col">
              <div className="flex flex-col w-7/12 gap-4 laptop:w-full">
                <TypeDetailsPanel />
                <InsurancePanel />
                <PremiumPanel
                  label="รายละเอียดค่าเบี้ยประกันภัย"
                  addonPrice={addonPrice}
                  onError={onError}
                />
                <PaymentPanel />
              </div>

              <div className="flex flex-col w-5/12 gap-4 h-fit laptop:w-full">
                {motorPanelOpen && <VehiclePanel />}
                <InsuredInfoPanel />
                <DeliveryPanel />
                <RemarkPanel />
              </div>
            </div>
            <div className="pt-4 flex flex-row gap-4">
              <Button type="submit" color="primary">
                บันทึก
              </Button>
            </div>
          </form>
        </FormProvider>
      </ErrorBoundary>
      {preloading && <Preloading />}
    </div>
  );
};

export default Create;
