import React from "react";
import { differenceInDays, differenceInMonths } from "date-fns";
import { parseDateEN } from "../../../helpers/dateTime";
import UserProfile from "~src/types/userProfile";
export interface AgentDataProps {
  profile: UserProfile;
}

export default function AgentData({ profile }: AgentDataProps) {
  const differenceInDayMonths = (expiredDate: Date): string => {
    let diffMonth = differenceInMonths(expiredDate, new Date());
    let diffDays = differenceInDays(expiredDate, new Date());

    if (diffMonth > 0) {
      return `อีก ${diffMonth} เดือนจะหมดอายุ`;
    } else if (diffMonth === 0 && diffDays > 0) {
      return `อีก ${diffDays} วันจะหมดอายุ`;
    } else {
      return "หมดอายุแล้ว";
    }
  };

  return (
    <div className="px-2 pb-2 border-b border-primary-color">
      {profile && (
        <>
          <div className="flex">
            <div className="w-12">
              <div className="w-12 h-12 overflow-hidden bg-gray-200 rounded-full shadow">
                <img src={profile.image_url} alt={profile.firstname} />
              </div>
            </div>
            <div className="w-full ml-2">
              <strong className="block text-xs">
                {profile.firstname} {profile.lastname}
              </strong>
              {profile?.license && profile.license.length > 0 && (
                <>
                  <strong className="block text-1xs">{profile.license[0].license_no}</strong>
                  <div className="flex w-full text-1xs">
                    <div className="w-1/2">วันหมดอายุ</div>
                    <div className="w-1/2 text-right">
                      <strong className="block">{parseDateEN(profile.license[0].expiry)}</strong>
                      {profile.license[0].notify && (
                        <small className="block -mt-1 text-red-400">
                          {differenceInDayMonths(new Date(profile.license[0].expiry))}
                        </small>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
