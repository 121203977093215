import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import useFetch from "/src/hooks/useFetch";
import useHttp from "/src/hooks/useHttp";
import Preloading from "/src/components/loading/Preloading";
import SectionComponent from "/src/components/staff/Section";
import MotorForm from "./form/motor/MotorForm";
import MotorInsuredInfo from "./info/motor/Insured";
import VehicleInfo from "./info/motor/Vehicle";
import PolicyInfo from "./info/motor/Policy";
import MotorInsurerForm from "./info/motor/Insurance";
import "./form.scss";

const RenewForm = (props) => {
  const { match } = props;
  const history = useHistory();
  const [preloading, setPreloading] = useState(false);

  const { addToast } = useToasts();

  const { response, error, isLoading } = useFetch(`/api/renewals/${match?.params?.id}`);

  const { register, errors, handleSubmit, reset } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const { post } = useHttp();

  const onSubmit = () => {
    setPreloading(true);
    const formElement = document.querySelector("form");
    const formData = new FormData(formElement);
    post(`/api/renewals`, formData)
      .then((response) => {
        setPreloading(false);

        const { success, message } = response.data;

        if (success)
          addToast("สร้างใบเตือนต่ออายุสำเร็จ", {
            appearance: "success",
            autoDismiss: true,
            onDismiss: () => {
              history.push("/staffs/renewals");
            },
          });
        else
          addToast("สร้างใบเตือนต่ออายุไม่สำเร็จ", {
            appearance: "error",
            autoDismiss: true,
          });
      })
      .catch((error) => {
        setPreloading(false);
        addToast("Something wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
        console.log(error);
      });
  };

  return (
    <>
      {preloading ? <Preloading /> : null}
      {isLoading ? (
        <Preloading />
      ) : (
        <div className="renew-form">
          <div className="row">
            <div className="col-12">
              <div className="renew-form-top">
                <MotorInsurerForm data={response?.content ?? {}} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="renew-form-left">
                <SectionComponent
                  title="ข้อมูลผู้เอาประกัน"
                  component={<MotorInsuredInfo data={response?.content ?? {}} />}
                />
                <SectionComponent
                  title="ข้อมูลรถยนต์"
                  component={<VehicleInfo data={response?.content ?? {}} />}
                />
                <SectionComponent
                  title="กรมธรรม์และความคุ้มครองเดิม"
                  component={<PolicyInfo data={response?.content ?? {}} />}
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="renew-form-right">
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                  encType="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="renew[agent_code]"
                    value={response?.content?.agent_code}
                  />
                  <MotorForm
                    data={response?.content ?? {}}
                    register={register}
                    errors={errors}
                    reset={reset}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Form = (props) => {
  const { match } = props;
  return (
    <ToastProvider autoDismissTimeout={1000}>
      <RenewForm match={match} />
    </ToastProvider>
  );
};

export default Form;
