import React, { Fragment } from "react";
import _ from "lodash";
import { formatNumberWithCurrency2 } from "/src/helpers/formatNumber";
import "/src/components/loading/placeholder-loading.scss";

const Coverages = ({ descriptions, packageObjs, loaded }) => {
  const maxCompare = 3;
  const emptyCompareSlot = [];
  for (let i = 0; i < maxCompare - packageObjs.length; i++) {
    emptyCompareSlot.push(<div key={i} className="col-md-3"></div>);
  }

  function renderCoverages(objectKey, obj, coveragesData) {
    switch (objectKey) {
      case "sum_insured":
        return formatNumberWithCurrency2(obj.sum_insured);
      case "repair_facility":
        return obj.details.repair_facility === "garage" ? "อู่" : "ศูนย์";
      case "passengers_in_vehicle_covered":
        return coveragesData + "  คน";
      default:
        return formatNumberWithCurrency2(coveragesData);
    }
  }

  return (
    <>
      {descriptions.map((description, index) => {
        return loaded ? (
          <Fragment key={index}>
            <h2>{description.header}</h2>
            <div className="box-grid">
              <div className="grid-3">
                <ul className="compare-cover">
                  {description.subHeaders.map((subHeader, i) => (
                    <li key={`sub-${index}-${i}`}>{subHeader}</li>
                  ))}
                </ul>
              </div>
              {packageObjs.map((o, t) => (
                <div key={`list-${index}-${t}`} className="grid-3 grid-bg">
                  <div className="compare-page-coverages-list">
                    <ul className="compare-cover">
                      {description.allowKeys.map((objectKey, ind) => (
                        <li
                          key={`list-${index}-${t}-${ind}`}
                          className="coverages-value"
                        >
                          {renderCoverages(
                            objectKey,
                            o,
                            o.coverages[objectKey]
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
              {emptyCompareSlot}
            </div>
          </Fragment>
        ) : (
          <Fragment key={index}>
            <h2>{description.header}</h2>
            <div className="box-grid">
              <div className="grid-3">
                <ul className="compare-cover">
                  {description.subHeaders.map((subHeader, i) => (
                    <li key={`sub-${index}-${i}`}>{subHeader}</li>
                  ))}
                </ul>
              </div>
              {packageObjs.map((o, t) => (
                <div key={`list-${index}-${t}`} className="grid-3 grid-bg">
                  <div className="compare-page-coverages-list">
                    <ul className="compare-cover">
                      {description.allowKeys.map((objectKey, ind) => (
                        <li
                          key={`list-${index}-${t}-${ind}`}
                          className="coverages-value"
                        >
                          <div className="ph-card-content-s">
                            <div></div>
                          </div>
                          {/* {renderCoverages(
                            objectKey,
                            o,
                            o.coverages[objectKey]
                          )} */}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
              {emptyCompareSlot}
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export default Coverages;
