import React, { CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Button } from "~src/components/form/button";
import CreditCardAlert from "~src/components/payments/CreditCardAlert";

import dictionary from "~src/data.yaml";
import { PageContextProvider, usePageContext } from "~src/context/Page";

import "./payments.scss";

export interface PaymentErrorPageProps {
  location: any;
}

export default function PaymentErrorPage(props: PaymentErrorPageProps) {
  return (
    // <PageContextProvider withQuery location={props.location}>
    <PageContextProvider withQuery location={props.location}>
      <PaymentErrorPageInner {...props} />
    </PageContextProvider>
  );
}

export interface PaymentErrorPageState {
  orderId?: number;
  orderNumber?: string;
  transactionNumber?: string;
  transactionState?: string;
  amount?: string;
  code?: string;
  messages?: string[];
}

interface PaymentErrorPageInnerProps extends PaymentErrorPageProps {}

function PaymentErrorPageInner(props: PaymentErrorPageInnerProps) {
  const location = useLocation<PaymentErrorPageState>();
  const { queryParams } = usePageContext();
  const { t } = useTranslation();

  if (location.state) {
    // TEST
    console.log("PaymentErrorPageInner: location.state:", location.state);
  } else if (queryParams?.orderId) {
    // TEST
    console.log("PaymentErrorPageInner: queryParams:", queryParams);
    location.state = { ...queryParams, orderId: Number(queryParams.orderId) };
  }
  location.state = location.state || {};
  const { orderId, orderNumber, transactionNumber, transactionState, amount, code, messages } =
    location.state;

  const errorInfoLines = useMemo(
    () => [
      { key: "order_number", value: orderNumber },
      // { key: "transaction_number", value: transactionNumber },
      { key: "transaction_state", value: transactionState },
      { key: "amount", value: amount ?? "-" },
      { key: "error_code", value: code },
    ],
    [orderNumber, transactionNumber, transactionState, amount, code]
  );

  return (
    <section
      id="payment-error"
      className="payment-wrapper payment-error-page flex flex-col w-full md:w-2/3 lg:w-1/2 m-16 p-8 mx-auto text-center"
    >
      <form className="error-report-form">
        <input type="hidden" name="order_id" value={orderId} />
        <CreditCardAlert
          code={code}
          messages={messages}
          transactionState={transactionState}
          infoLines={errorInfoLines}
          className="flex flex-col w-full"
          renderActions={() => (
            <div className="result-button-block flex flex-row w-full justify-center">
              <Button
                color="primary"
                component="link"
                role="button"
                to="/orders"
                classNameOption="btn-orders"
              >
                {dictionary.payment.to_orders}
              </Button>
              {/* <input type="submit" value="Send Error Report" /> */}
            </div>
          )}
        ></CreditCardAlert>
      </form>
    </section>
  );
}

// Backup
// interface ErrorInfoLinesProps {
//   state: PaymentErrorPageState;
//   className?: string;
// }
//
// function ErrorInfoLines({ state, className = "" }: ErrorInfoLinesProps) {
//   const data: Record<string, any>[] = [
//     { key: "order_number", value: state.orderNumber },
//     { key: "transaction_number", value: state.transactionNumber },
//     { key: "transaction_state", value: state.transactionState },
//     { key: "amount", value: state.amount ?? "-" },
//     { key: "error_code", value: state.code },
//   ];
//   data.forEach((line) => {
//     line.title = dictionary.payment[line.key];
//   });

//   return (
//     <div className={`error-info-lines flex flex-col w-full items-stretch ${className}`}>
//       {data.map((line) => (
//         <h6 key={line.key} className="flex flex-row w-full gap-x-1">
//           <span className="list-key flex w-1/2 justify-end text-right">{line.title} :</span>
//           <span className="list-value flex w-1/2 justify-start text-left">{line.value}</span>
//         </h6>
//       ))}
//     </div>
//   );
// }

// Backup
/* <div className="card-payment-error-title flex flex-col w-full justify-center">
        <h1 className="flex flex-row justify-center text-center gap-2 mb-8">
          <i className="material-icons text-red-500">highlight_off</i>{" "}
          {dictionary.payment.payment_error}
        </h1>
      </div>
      <div className="card-payment-error-body flex flex-col w-full justify-center text-center items-stretch gap-3">
        {/* <img
            src="https://storage.googleapis.com/asia.artifacts.gm-gettmore-project.mtb.co.th/images/Error-img.png"
            className="error-img"
            alt="Error"
          /> * /}
        <div className="flex flex-col w-full justify-center items-stretch">
          <h6 className="flex flex-row w-full justify-center text-center">
            เหตุการณ์นี้อาจเป็นเพราะ
          </h6>
          <ul className="flex flex-col w-full justify-center">
            <li className="flex flex-row justify-center">- ใช้เวลาการดำเนินการเกินกำหนด</li>
            <li className="flex flex-row justify-center">- ข้อผิดพลาดบางประการของบัตรเครดิต</li>
          </ul>
        </div>
      </div>
      <div className="card-payment-error-footer flex flex-col w-full justify-center text-center items-stretch gap-3">
        <hr />
        <p className="flex flex-row justify-center text-gray-400">
          หากต้องการความช่วยเหลือ สามารถติดต่อได้ที่ Line: {t("lineAccount")} หรือ
          {t("contactMail")}
        </p>
        <p className="flex flex-row justify-center text-gray-400">หรือ ลองดำเนินการใหม่อีกครั้ง</p>

        <div className="result-button-block flex flex-row w-full justify-center">
          <Button
            color="primary"
            component="link"
            role="button"
            to={`/orders/${orderId}/details`}
            disabled={!orderId || isNaN(orderId)}
            classNameOption="btn-order-details"
          >
            {dictionary.payment.to_order_detail}
          </Button>
        </div>
      </div> */
