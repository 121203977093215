import React from "react";
import { messages } from "~/src/helpers/messages";
import { FormGroup, TextField, SelectField } from "~/src/components/form";
import { InsuredsAttributes } from "~/src/types/checkoutBasicForm";

interface LegalEntityInputProps {
  insuredId: number | string;
  attrPrefixInsured: string;
  insuredsAttributes?: InsuredsAttributes;
  errorsInsuredsAttr?: any;
  options?: any;
  additionalFieldsEnabled?: any;
  register: (msg) => void;
  handleChangeField: (event: React.FormEvent, attrName: string) => void;
  handleChangeFieldIdCard: (event: React.FormEvent, attrName: string) => void;
}

const LegalEntityInput: React.FC<LegalEntityInputProps> = ({
  insuredId,
  attrPrefixInsured,
  insuredsAttributes,
  errorsInsuredsAttr,
  options,
  additionalFieldsEnabled = false,
  register,
  handleChangeField,
  handleChangeFieldIdCard,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <FormGroup label="คำนำหน้าชื่อ" htmlForLabel="company_title" require>
            <input
              type="hidden"
              name={`${attrPrefixInsured}[id]`}
              value={`${insuredId}`}
              ref={register}
            />
            <SelectField
              id="company_title"
              name={`${attrPrefixInsured}[company_title]`}
              value={insuredsAttributes?.company_title || ""}
              onChange={(evt) => handleChangeField(evt, "company_title")}
              inputRef={register(messages.requiredSelect)}
              error={errorsInsuredsAttr?.title?.message}
              options={options?.selectFields}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <FormGroup label="ชื่อบริษัทหรือองค์กร" htmlForLabel="company_name" require>
            <input
              type="hidden"
              name={`${attrPrefixInsured}[id]`}
              value={`${insuredId}`}
              ref={register}
            />
            <TextField
              id="company_name"
              name={`${attrPrefixInsured}[company_name]`}
              value={insuredsAttributes?.company_name || ""}
              onChange={(event) => handleChangeField(event, "company_name")}
              inputRef={register(messages.required)}
              error={errorsInsuredsAttr?.company_name?.message}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup
            label="เลขประจำตัวผู้เสียภาษีของบริษัท/องค์กร"
            htmlForLabel="company_tax"
            require
          >
            <TextField
              id="company_tax"
              name={`${attrPrefixInsured}[company_tax]`}
              value={insuredsAttributes?.company_tax || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeFieldIdCard(e, "company_tax");
              }}
              inputRef={register({ ...messages.required, ...messages.validateCompanyTax })}
              error={errorsInsuredsAttr?.company_tax?.message}
              maxLength={13}
            />
          </FormGroup>
        </div>
        {additionalFieldsEnabled && (
          <>
            <div className="col-md-6">
              <FormGroup
                label="เบอร์โทรศัพท์ของบริษัท/องค์กร"
                htmlForLabel="company_phone_number"
                require
              >
                <TextField
                  id="company_phone_number"
                  name={`${attrPrefixInsured}[phone_number]`}
                  value={insuredsAttributes?.phone_number || ""}
                  onChange={(event) => handleChangeField(event, "phone_number")}
                  inputRef={register({
                    ...messages.required,
                    ...messages.mobileNumber,
                  })}
                  error={errorsInsuredsAttr?.phone_number?.message}
                />
              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup
                label="อีเมลของบริษัท/องค์กร"
                htmlForLabel="company_email"
              >
                <TextField
                  id="email"
                  name={`${attrPrefixInsured}[email]`}
                  value={insuredsAttributes?.email || ""}
                  onChange={(event) => handleChangeField(event, "email")}
                  inputRef={register(messages.email)}
                  error={errorsInsuredsAttr?.email?.message}
                />
              </FormGroup>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LegalEntityInput;
