import axios from "/src/helpers/configAxios";
import React, { useState, useEffect, useContext } from "react";
import CurrencyFormat from "react-currency-format";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import { CoverageModal } from "/src/components/modal";
import { CoverageModalCmi } from "/src/components/modal/coverage/coverageModal";
import formingCoveragesFor from "/src/helpers/formingCoverage";
import useInsurerIcons from "/src/helpers/useInsurerIcons";
import yaml from "/src/data";
import { useTranslation } from "react-i18next";
import { useAuthenticate } from "~src/helpers/authen";

import CheckoutContext from "/src/context/Checkout";

import "./insurancePlanSummary.scss";

const InsurancePlanSummary = (props) => {
  const {
    productType,
    productId,
    insurerCode,
    insurerIcon,
    insurerName,
    title,
    subTitle,
    details,
    sumSellingPrice,
    discount,
    commission,
    creditCardFee,
    paymentTotalAmount,
    paymentTotalAmountIncludedCCFee,
    sellingPrice,
    sellingPriceAddon,
    policyType,
    repairFacility,
    estimateWhTax,
    estimateAddonWhTax,
  } = props.summary;

  const { addon, includeWhTax, insuredCategory } = props;
  const { t } = useTranslation();
  const { showPaidCommission } = useAuthenticate();
  const { addon_selling_price, checked_addon } = props.addOrRemoveCMI;
  const { quoteInfo } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModelCmi, setIsOpenModelCmi] = useState(false);
  const [fullCoverages, setFullCoverages] = useState([]);
  const [productInfo, setProductInfo] = useState("");
  const [state, setState] = useState({
    shown: true,
    showAdditionalDetails: true,
  });
  const { state: checkoutState } = useContext(CheckoutContext);
  const [whTax, setWhTax] = useState(0);

  const fullInsurerName = yaml.insurers[insurerCode];
  let productDescription = "";
  switch (productType) {
    case "motor":
      productDescription = `${yaml.policy_type[policyType]} ${yaml.repair_facility[repairFacility]}`;
      break;
  }

  useEffect(() => {
    fetchCoverageData();
    setWhTax(calculateEstimatedWhValue(includeWhTax, insuredCategory));
  }, []);

  useEffect(() => {
    setWhTax(
      calculateEstimatedWhValue(checkoutState.order.include_wh_tax, checkoutState.insured_category)
    );
  }, [
    checkoutState.insured_category,
    checkoutState.order.include_wh_tax,
    props.addOrRemoveCMI.checked_addon,
  ]);

  function fetchCoverageData() {
    const url = `${process.env.API_URL}/api/products/${productId}`;
    axios
      .get(url)
      .then((response) => {
        const { content } = response.data;
        setProductInfo(content);
        setFullCoverages(formingCoveragesFor("motor", content));
        // setRepairFacility(content.details.repair_facility)
      })
      .catch((error) => {
        handleFetchError(error);
      });
  }

  const calculateEstimatedWhValue = (includeWhTax, insuredCategory) => {
    return includeWhTax && insuredCategory === "legal_entity"
      ? checked_addon
        ? parseFloat(estimateWhTax) + parseFloat(estimateAddonWhTax)
        : parseFloat(estimateWhTax)
      : 0;
  };

  const handleChange = () => {
    if (state.shown) {
      setState({
        ...state,
        shown: false,
        showAdditionalDetails: false,
      });
    } else {
      setState({
        ...state,
        shown: true,
        showAdditionalDetails: true,
      });
    }
  };

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleOpenModalCMI = () => {
    setIsOpenModelCmi(true);
  };

  return (
    <>
      <div className="motor-discount-conponent">
        <div className="plan-header">สรุปแผนประกันที่คุณเลือก</div>
        <div className="motor-component-card">
          <div className="motor-component mb-4">
            <div className="row items-center mb-2">
              <div className="col-md-3">
                <img className="image" src={insurerIcon} />
              </div>
              <div className="col-md-8">
                <div className="pb-2">{insurerName}</div>
                <div className="font-secondary text-xs font-thin">
                  {quoteInfo[0].policy_type !== "cmi" ? productDescription : "พ.ร.บ."}
                </div>
              </div>
            </div>
            {quoteInfo[0].policy_type !== "cmi" && (
              <button className="w-full text-xs" onClick={handleOpenModal}>
                <div className="flex justify-between pl-12 pr-12 text-detail">
                  <i className="material-icons">notes</i>
                  อ่านรายละเอียดความคุ้มครอง
                </div>
              </button>
            )}
            {quoteInfo[0].policy_type === "cmi" && (
              <button className="w-full text-xs" onClick={handleOpenModalCMI}>
                <div className="flex justify-between pl-12 pr-12 text-detail">
                  <i className="material-icons">notes</i>
                  อ่านรายละเอียดความคุ้มครอง
                </div>
              </button>
            )}
          </div>
          <div className="flex justify-between pb-2 font-primary">
            <div className="w-full px-2 py-1">{`ยี่ห้อ/รุ่น: `}</div>
            <div className="w-full xl:pl-20 py-1">{details}</div>
          </div>
          <hr />
          <div className="flex justify-between px-2 py-1 font-thin">
            <div>เบี้ยประกันภัยรวม</div>
            <div>{formatNumberWithCurrency(sellingPrice)}</div>
          </div>
          {checked_addon && (
            <div className="flex justify-between px-2 py-1 font-thin">
              <div>เบี้ยพ.ร.บ.</div>
              <div>{formatNumberWithCurrency(addon_selling_price)}</div>
            </div>
          )}

          {checked_addon && (
            <div className="flex justify-between px-2 py-1 font-thin">
              <div>เบี้ยประกันภัยรวมทั้งสิ้น</div>
              <div>
                {formatNumberWithCurrency(
                  parseFloat(sellingPrice) + parseFloat(addon_selling_price || 0)
                )}
              </div>
            </div>
          )}
          {showPaidCommission && (
            <div className="flex justify-between px-2 py-1">
              <div className="font-light">ส่วนลดลูกค้า</div>
              <div>{`${formatNumberWithCurrency(discount)}`}</div>
            </div>
          )}
          {process.env.APP_NAME !== "mti" &&
            checkoutState.insured_category === "legal_entity" &&
            t("whTax") && (
              <div className="flex justify-between px-2 py-1">
                <div className="font-light">ภาษีหัก ณ ที่จ่าย 1%</div>
                <div>
                  {whTax > 0 && "-"} {`${formatNumberWithCurrency(whTax)}`}
                </div>
              </div>
            )}
          <hr />
          <div className="flex justify-between pb-2">
            <div className="font-bold text-base">เบี้ยประกันที่ต้องชำระ</div>
            <div className="text-primary font-bold text-xl">
              {checked_addon
                ? formatNumberWithCurrency(
                    parseFloat(sellingPrice || 0) +
                      parseFloat(addon_selling_price || 0) -
                      parseFloat(discount || 0) -
                      parseFloat(whTax || 0)
                  )
                : formatNumberWithCurrency(
                    parseFloat(sellingPrice || 0) -
                      parseFloat(discount || 0) -
                      parseFloat(whTax || 0)
                  )}
            </div>
          </div>
          <div className="flex justify-between text-sm pb-2 -mt-4 font-thin">
            <div>รวมภาษีและอากรแสตมป์</div>
            <div>ต่อปี</div>
          </div>
        </div>
      </div>
      {isOpenModal && (
        <CoverageModal
          handleClose={handleCloseModal}
          productInfo={productInfo}
          coverages={fullCoverages}
          details={details}
        />
      )}
      {isOpenModelCmi && (
        <CoverageModalCmi
          handleClose={() => setIsOpenModelCmi(false)}
          productInfo={productInfo}
          coverages={fullCoverages}
          details={details}
        />
      )}
    </>
  );
};

function validateFetchReponse(response) {
  if (response.ok) {
    return response.json();
  }
  return Promise.reject(`error: ${response.status}`);
}
function handleFetchError(error) {
  // TODO: handling fetch error here
  console.log("error", error);
}

export default InsurancePlanSummary;
