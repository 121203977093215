import React from "react";

import CoverageList from "./CoverageList";

const Coverage = ({ data }) => {
  return (
    <>
      {data.map((o, i) => (
        <CoverageList key={i} label={Object.keys(o)} value={Object.values(o)} />
      ))}
    </>
  );
};

export default Coverage;
