const renderLocation = ({ address, road = "", province, district, sub_district, postcode }) => {
  let prefixSubDistrict;
  let prefixDistrict;
  if (province === "กรุงเทพมหานคร") {
    prefixSubDistrict = "แขวง";
    prefixDistrict = "เขต";
  } else {
    prefixSubDistrict = "ตำบล";
    prefixDistrict = "อำเภอ";
  }

  return province
    ? `${address} ${road && `ถนน${road}`} ${sub_district ? prefixSubDistrict + sub_district : ""} ${
        district ? prefixDistrict + district : ""
      } จังหวัด${province} ${postcode || ""}`
    : "-";
};

export default renderLocation;
