import LegalEntityInput from "./LegalEntityInput";
import InformationInput from "./InformationInput";
import PersonalAdditionInput from "./PersonalAdditionInput";
import AddressInput from "./AddressInput";
import AddressDeliveryInsurance from "./AddressDeliveryInsurance";
import BeneficiaryInformationInput from "./BeneficiaryInformationInput";

export {
  LegalEntityInput,
  InformationInput,
  PersonalAdditionInput,
  AddressInput,
  AddressDeliveryInsurance,
  BeneficiaryInformationInput
}