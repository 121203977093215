import React from "react";
import { Box, Card, CardContent, Button, FormControlLabel, Checkbox } from "@mui/material";
import { useAppDispatch, useAppSelector } from "~src/store";
import { fetchNewAgentContract } from "./apis";
import { contractViewSelector } from "./contractSlice";
import { unEscapeHtml } from "~src/helpers/helpers";

const ContractCard = ({ onOk, agentRole }) => {
  const [accept, setAccept] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { open, data, status } = useAppSelector(contractViewSelector);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(fetchNewAgentContract({ role: agentRole }));
  }, []);

  const handleOk = (id) => {
    if (!accept) {
      alert("กรุณากดยอมรับ สัญญาสมาชิก");
      return;
    }

    onOk(id);
  };

  return (
    <Box sx={{ px: 4 }}>
      <Card variant="outlined">
        <CardContent sx={{ height: `calc(100vh - 200px)`, overflowY: "auto" }}>
          {status === "fulfilled" && (
            <>
              <Box
                className="detail-wrapper"
                dangerouslySetInnerHTML={{ __html: unEscapeHtml(data.detail) }}
              />

              <Box>
                <Box justifyContent="center" display="flex">
                  <FormControlLabel
                    label="ยอมรับ สัญญาสมาชิก"
                    control={<Checkbox onChange={() => setAccept(!accept)} />}
                  />
                </Box>
                <Box justifyContent="center" display="flex">
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={() => handleOk(data.id)}
                  >
                    ตกลง
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ContractCard;
