import React from "react";
import axios from "~src/helpers/configAxios";
import { isEmpty } from "lodash";

const useSecureLink = () => {
  const [imageSrc, setImageSrc] = React.useState("");
  const [secureLinkLoading, setSecureLinkLoading] = React.useState(false);

  const secureDownloads = (urls) => {
    urls.forEach((url) => {
      if (isEmpty(url)) {
        alert("File not found.");
        return;
      }

      setSecureLinkLoading(true);

      axios
        .get(url, {
          responseType: "blob",
        })
        .then((result) => {
          setSecureLinkLoading(false);
          const filenameRegex = /filename\*?=([^']*'')?([^;]*)/;
          const filename = decodeURI(filenameRegex.exec(result.headers["content-disposition"])[2]);

          const blob = new Blob([result.data], { type: result.headers["content-type"] });
          const uriString = window.URL.createObjectURL(blob);

          let a = document.createElement("a");
          a.href = uriString;
          a.setAttribute("target", "_blank");
          a.download = decodeURI(filename);
          a.click();

          setTimeout(function () {
            window.URL.revokeObjectURL(uriString);
          }, 1500);
        })
        .catch(({ response }) => {
          setSecureLinkLoading(false);
          console.log(response);
          alert("Internal server error");
        });
    });
  };

  const getSecureImageBase64 = (url) => {
    axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((result) => {
        const base64String =
          "data:" +
          result.headers["content-type"] +
          ";base64," +
          Buffer.from(result.data, "binary").toString("base64");
        setImageSrc(base64String);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { imageSrc, secureLinkLoading, secureDownloads, getSecureImageBase64 };
};

export default useSecureLink;
