import React from "react";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";

const Commission = ({ value }) => {
  return (
    <div className="flex flex-nowrap justify-between">
      <span>ค่าตอบแทน</span>
      <span className="rounded-20 px-1 text-right bg-primary-color text-white">
        {formatNumberWithCurrency(value)}
      </span>
    </div>
  );
};

export default Commission;
