import React from "react";
import "./checkbox.scss";
const Checkbox = ({ id, inputRef, color, label, ...rest }) => {
  return (
    <label
      htmlFor={id}
      className="custom-control-checkbox custom-checkbox custom-control-inline"
    >
      {label}
      <input type="checkbox" ref={inputRef} id={id} {...rest} />
      <span className={color == "primary" ? "checkmark checkmark-primary" : "checkmark"}></span>
    </label>
  );
};

export default Checkbox;
