import React, { useState } from "react";
import { ButtonRadius, FormGroup, TextField } from "~src/components/form";
import { useForm } from "react-hook-form";
import { AlertNotification } from "../../../../components/alert/alertNotification";
import axios from "~src/helpers/configAxios";
import { CheckCircleOutline, Close } from "@mui/icons-material";

const UpdateCustomerModal = (props) => {
  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);
  const [customerId, setCustomerId] = useState(props.customerId);

  const [alertText, setAlertText] = useState("ดำเนินการเสร็จสิ้น");
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertType, setAlertType] = useState("success");

  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const hideAlert = () => {
    setIsAlertActive(false);
  };

  const popUpAlert = (type, message) => {
    hideAlert();
    setIsAlertActive(true);
    setAlertType(type);
    setAlertText(message);
  };

  const handleChange = (evt, callback) => {
    const { value } = evt.target;
    callback(value);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    const url = `/api/customers/${customerId}`;
    axios.patch(url, JSON.stringify(data)).then((response) => {
      if (response.data.content.success) {
        popUpAlert("success", "อัพเดทสำเร็จ");
        setTimeout(() => {
          window.location.href = "/lead";
        }, 1500);
      } else {
        popUpAlert("error", "อัพเดทไม่สำเร็จ");
      }
    });
  };

  return (
    <div className="renew-modal">
      <div className="renew-modal-content">
        <button className="close" type="button" onClick={() => props.cbCloseModal()}>
          <span className="material-icons">close</span>
        </button>
        <div>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              ชื่อ
            </label>
            <TextField
              type="text"
              className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
              name="customer[first_name]"
              value={firstName}
              inputRef={register}
              onChange={(evt) => handleChange(evt, setFirstName)}
            />

            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              นามสกุล
            </label>
            <TextField
              type="text"
              className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
              name="customer[last_name]"
              value={lastName}
              inputRef={register}
              onChange={(evt) => handleChange(evt, setLastName)}
            />

            <div className="mt-4 text-center">
              <FormGroup>
                <ButtonRadius type="submit" color="primary">
                  อัพเดท
                </ButtonRadius>
              </FormGroup>
            </div>
          </form>
        </div>
      </div>

      <AlertNotification
        className="bottom-0 right-0 px-4 py-3 m-5 mr-3"
        type={alertType}
        active={isAlertActive}
        setActive={setIsAlertActive}
        enterAnimation="slideInRight"
        exitAnimation="slideOutRight"
        closeDelay={4000}
      >
        <CheckCircleOutline className="mr-3" />
        <span className="inline-block align-bottom">{alertText}</span>
        <button className="ml-3" onClick={hideAlert}>
          <Close />
        </button>
      </AlertNotification>
    </div>
  );
};

export default UpdateCustomerModal;
