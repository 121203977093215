import { AxiosResponse } from "axios";
import { useCallback } from "react";

import OrdersAPIClient, { OrdersAPIClientProps } from "../Orders";
import { ProtectedAPIClientProps, useProtectedAPI } from "../ProtectedAPIClient";
import APIResponse from "~src/models/APIResponse";

export interface StaffOrdersAPIClientProps extends OrdersAPIClientProps {}

export default class StaffOrdersAPIClient extends OrdersAPIClient {
  constructor(props: StaffOrdersAPIClientProps) {
    super(props);
  }

  public async confirmPayment(orderID: number, paymentToken: string): Promise<APIResponse<any>> {
    try {
      //TODO: Migrate controller into /api/orders/
      const url = `${this.props.appConfig.backendURL}/api/orders/${orderID}/payments/payslip`;

      const resp = await this.withAbort(({ signal }) =>
        this._axios.patch<any, AxiosResponse<APIResponse<any>>>(
          url,
          {
            token: paymentToken,
          },
          { signal }
        )
      );
      this.checkAPIResponse(resp);

      return resp.data;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }
}

export const defaultStaffOrdersAPIFactory = (props: StaffOrdersAPIClientProps) =>
  new StaffOrdersAPIClient(props);

export interface UseStaffOrdersAPIOptions {
  apiFactory?: (props: StaffOrdersAPIClientProps) => StaffOrdersAPIClient;
  onError?: (err: Error) => void;
}

export interface UseStaffOrdersAPIState {
  ordersAPI?: StaffOrdersAPIClient;
  error?: Error;
}

export function useStaffOrdersAPI(opts: UseStaffOrdersAPIOptions = {}): UseStaffOrdersAPIState {
  const { onError } = opts;

  const apiFactory = useCallback(
    (baseProps: ProtectedAPIClientProps) => {
      const apiProps = { ...baseProps };

      if (opts.apiFactory) {
        return opts.apiFactory(apiProps);
      }

      return new StaffOrdersAPIClient(apiProps);
    },
    [opts.apiFactory]
  );

  const {
    api: ordersAPI,
    error,
    ...otherState
  } = useProtectedAPI<StaffOrdersAPIClientProps, StaffOrdersAPIClient>({
    apiFactory,
    onError,
  });

  return { ...otherState, ordersAPI, error };
}
