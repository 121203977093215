import React from "react";

import img from "/assets/recommend-icon.svg";

const RecommendIcon = () => {
  return (
    <div className="recommend-icon">
      <img src={img} alt="" />
    </div>
  );
};

export default RecommendIcon;
