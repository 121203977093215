import _ from "lodash";
import React from "react";
import errors from "/src/errors.yml";

import "./alert.scss";

const Alert = ({ message, details = {} }) => {
  const messageError = () => {
    // if (details && Object.keys(details).length === 0) return message;
    const msgArr = [];
    message && msgArr.push(message);
    Object.entries(details).forEach(([field, errorTypes]) => {
      const errTypesArr = Array.isArray(errorTypes)
        ? errorTypes
        : errorTypes
          ? [errorTypes]
          : undefined;
      (errTypesArr || ["unknown"]).forEach((errorType) => {
        errorType = errorType.error || errorType;
        msgArr.push(_.get(errors, `${errorType}.${field}`, `${field}: ${errorType}`));
      });
    });

    return msgArr.join(", ");
  };

  return (
    <div className="alert-msg animated fadeInRight delay-05s">
      <i className="material-icons">error_outline</i> {messageError()}
    </div>
  );
};

export default Alert;
