import { AxiosResponse } from "axios";
import axios from "~src/helpers/configAxios";
import { TrackingData } from "~src/types/policy";

export const fetchTrackingPolicyData = (policyId: string): Promise<AxiosResponse<TrackingData>> => {
  const encodedPolicyId = encodeURIComponent(policyId);
  const url = `/api/policies/${encodedPolicyId}/tracking`;

  return axios.get<TrackingData>(url);
};

export const fetchOther = () => {
  //   const encodedPolicyId = encodeURIComponent(policyId);
  //   const url = `/api/policies/${encodedPolicyId}/tracking`;
  //   return axios.get(url);
};
