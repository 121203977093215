import React from "react";
import PropTypes from "prop-types";

import { addYears } from "date-fns";
import {
  dateFormat,
  parseDate,
  parseDateTH,
  parseDateEN,
} from "/src/helpers/dateTime";
import {
  formatNumberWithCurrency,
  formatNumberWithCurrency2,
} from "/src/helpers/formatNumber";

import { Button } from "/src/components/form";

import "./table.scss";

const PATH = "/staffs/renewals";

const Table = (props) => {
  const { loading, data } = props;
  return (
    <div>
      <div className="table-grid">
        <div className="table-grid-head">
          <div>เลขที่กรมธรรม์เดิม</div>
          <div>รหัสตัวแทน</div>
          <div>ข้อมูลลูกค้า</div>
          <div>บริษัท</div>
          <div>แผน</div>
          <div>วันที่คุ้มครอง</div>
          <div>ทุนประกัน</div>
          <div>เบี้ยประกันที่ชำระ</div>
          <div></div>
        </div>

        {loading && <div className="table-grid-body-full">กำลังโหลด...</div>}
        {!loading && (
          <>
            {data.length == 0 && (
              <div className="table-grid-body-full">ไม่พบข้อมูล</div>
            )}

            {data.map((o, i) => (
              <div className="table-grid-body" key={i}>
                <div>{o.policy_details?.[0]?.policy_number}</div>
                <div>{o.agent_code}</div>
                <div>
                  {`${o.title} ${o.firstname} ${o.lastname}`}
                  <br />
                  {`${o.car_brand} ${o.car_model} ${o.car_year}`}
                  <br />
                  เลขทะเบียน {o.plate_number}
                </div>
                <div>
                  <img src={o.insurer_icon} alt="" />
                </div>
                <div>
                  ชั้น {o.policy_details?.[0]?.product_type}
                  <br /> {!!o.policy_details[1] && `(พ.ร.บ.)`}
                </div>
                <div>
                  <span>เริ่ม:</span>{" "}
                  {parseDateEN(o.policy_details?.[0]?.cover_start_date)}
                  <br />
                  <span>ถึง:</span>{" "}
                  {parseDateEN(o.policy_details?.[0]?.cover_end_date)}
                </div>
                <div>
                  {formatNumberWithCurrency2(o.policy_details[0]?.sum_insured)}
                </div>
                <div>
                  <span className="num-value">
                    {formatNumberWithCurrency(
                      o.policy_details?.[0]?.selling_price
                    )}
                  </span>{" "}
                  <br />
                  {o.policy_details?.[1] ? (
                    <>
                      <span className="cmi-label">พ.ร.บ.</span>{" "}
                      <span className="num-value">
                        {formatNumberWithCurrency(
                          o.policy_details[1].selling_price
                        )}
                      </span>
                    </>
                  ) : null}
                </div>
                <div className="actions">
                  <Button
                    to={`${PATH}/${o.id}`}
                    component="link"
                    color="primary"
                    size="xs"
                    fullwidth
                  >
                    <i className="material-icons">timer</i>
                    แจ้งต่ออายุ
                  </Button>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default Table;
