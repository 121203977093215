import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Label from "../../../../components/Forms/Label";
import TextInput from "../../../../components/Forms/TextInput";
import Select from "../../../../components/Forms/Select";

import { renewConText } from "../store";
import { messages } from "../../../../helpers/messages";
import { MotorCriteria } from "../../../../types/criteria";
import { Details, OrderPaymentsSummaryLineItem } from "../../../../types/order";
import { Payment } from "../../../../types/payment";
import {
  useCarBrands,
  useProvinces,
  getCarModels,
  getYears,
  getCodeNames,
  getCarInfo,
  SubModel,
} from "../hooks/useVehicles";
import { checkCmiPrice, calculatePrice } from "../../../../helpers/helpers";

interface Props {
  onToggle: () => void;
  active: boolean;
  errors: any;
  register: any;
}

interface VehicleState {
  models: string[] | undefined;
  years: number[] | undefined;
  sub_models: SubModel[];
}

const VehicleInfo: React.FC<Props> = ({ onToggle, active, errors, register }) => {
  const { state, dispatch } = React.useContext(renewConText);
  const [isLoading, setIsLoading] = useState(false);
  const { type } = useParams<{ type: string }>();
  const carBrands = useCarBrands();
  const provinces = useProvinces();

  const [loadVehicleDefault, setLoadVehicleDefault] = React.useState<boolean>(true);
  const [vehicleState, setVehicleState] = React.useState<VehicleState>({
    models: [],
    years: [],
    sub_models: [],
  });

  useEffect(() => {
    defaultCarDetails();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getCarInfo(
      state.renew?.quotation.criteria.car_brand,
      state.renew?.quotation.criteria.car_model,
      state.renew?.quotation.criteria.year,
      state.renew?.quotation.criteria.code_name
    ).then((result) => {
      if (result.data.vehicles.length > 0) {
        const price = checkCmiPrice(null, result.data.vehicles[0].vehicle_type);
        const cmiPrices = calculatePrice(parseFloat(price), "selling_price");

        const lineItemAs: OrderPaymentsSummaryLineItem[] = [];
        state.renew?.payments_summary?.line_itemas.forEach(
          (lineItem: OrderPaymentsSummaryLineItem) => {
            if (type === "cmi" && lineItem.policy_type === "cmi") {
              (lineItem as any) = Object.assign(lineItem, cmiPrices);
            }
            lineItemAs.push(lineItem);
          }
        );
        dispatch({
          type: "ADD",
          payload: {
            isEdit: true,
            renew: {
              ...state.renew,
              addon_selling_price: price,
              payments_summary: {
                ...state.renew.payments_summary,
                line_itemas: lineItemAs,
              },
            },
          },
        });
      }
      setIsLoading(false);
    });
  }, [
    state.renew?.quotation.criteria.car_brand,
    state.renew?.quotation.criteria.car_model,
    state.renew?.quotation.criteria.year,
    state.renew?.quotation.criteria.code_name,
  ]);

  const defaultCarDetails = async () => {
    const { car_brand, year, car_model } = state.renew.quotation.criteria;

    if (car_brand) {
      await getCarModels(car_brand).then((res) => {
        vehicleState.models = res.data.models;
      });
    }

    if (car_model) {
      await getYears(car_brand, car_model).then((res) => {
        vehicleState.years = res.data.years;
      });
    }

    if (year) {
      await getCodeNames(car_brand, car_model, year).then(
        (res) => (vehicleState.sub_models = res.data.sub_models)
      );
    }

    setVehicleState(vehicleState);
    setLoadVehicleDefault(false);
  };

  const handleChangeCriteria = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    name: keyof MotorCriteria
  ): void => {
    const { car_brand, car_model } = state.renew.quotation.criteria;
    const criteria: MotorCriteria = { ...state.renew.quotation.criteria };

    switch (name) {
      case "car_brand":
        getCarModels(evt.target.value).then((res) => {
          setVehicleState({ ...vehicleState, models: res.data.models });
        });
        criteria.car_brand = evt.target.value;
        criteria.car_model = "";
        criteria.year = "";
        criteria.code_name = "";
        break;
      case "car_model":
        getYears(car_brand, evt.target.value).then((res) => {
          setVehicleState({ ...vehicleState, years: res.data.years });
        });
        criteria.car_model = evt.target.value;
        criteria.year = "";
        criteria.code_name = "";
        break;
      case "year":
        getCodeNames(car_brand, car_model, evt.target.value).then((res) =>
          setVehicleState({ ...vehicleState, sub_models: res.data.sub_models })
        );
        criteria.year = evt.target.value;
        break;
      case "code_name":
        criteria.code_name = evt.target.value;
        break;
      case "province":
        criteria.province = evt.target.value;
        break;
    }

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          addon_selling_price: checkCmiPrice(null, criteria.vehicle_type),
          quotation: {
            ...state.renew.quotation,
            criteria,
          },
        },
      },
    });
  };

  const handleChangeDetail = (
    evt: React.ChangeEvent<HTMLInputElement>,
    name: keyof Details
  ): void => {
    const details: Details = {
      ...state.renew.details,
      [name]: evt.target.value,
    };

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          details,
        },
      },
    });
  };

  const handleChangeChassis = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    let { value } = evt.target;
    value = value.replace(/[^A-Z0-9]/gi, "").toUpperCase();
    const details: Details = {
      ...state.renew.details,
      chassis_number: value,
    };

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          details,
        },
      },
    });
  };

  return (
    <div className="p-4 mb-4 bg-white shadow rounded-5">
      <h6 className="flex items-center justify-between p-1 px-2 text-sm font-bold bg-gray-100">
        ข้อมูลรถยนต์
        <button
          type="button"
          onClick={onToggle}
          className="flex items-center justify-center w-6 h-6 bg-white shadow outline-none rounded-5 focus:outline-none"
        >
          {active ? <RemoveIcon /> : <AddIcon />}
        </button>
      </h6>
      <div className={classnames(["px-4 mb-3 mt-4"], !active ? "hidden" : "block")}>
        {!loadVehicleDefault && (
          <div className="flex flex-wrap -mx-2">
            <div className="w-1/3 px-2 mb-4">
              <Label htmlFor="renew[criteria][car_brand]">ยี่ห้อรถยนต์</Label>
              <Select
                id="renew[criteria][car_brand]"
                name="renew[criteria][car_brand]"
                value={state.renew?.quotation.criteria?.car_brand}
                onChange={(evt) => handleChangeCriteria(evt, "car_brand")}
                inputRef={register({ ...messages.required })}
                error={errors?.renew?.criteria?.car_brand?.message}
                disabled={isLoading}
              >
                <option value="">กรุณาเลือก</option>
                {carBrands?.map((brand, index) => (
                  <option value={brand} key={index}>
                    {brand}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-1/3 px-2 mb-4">
              <Label htmlFor="renew[criteria][car_model]">รุ่น</Label>
              <Select
                id="renew[criteria][car_model]"
                name="renew[criteria][car_model]"
                value={state.renew?.quotation.criteria?.car_model}
                onChange={(evt) => handleChangeCriteria(evt, "car_model")}
                inputRef={register({ ...messages.required })}
                error={errors?.renew?.criteria?.car_model?.message}
                disabled={isLoading}
              >
                <option value="">กรุณาเลือก</option>
                {vehicleState.models?.map((model, index) => (
                  <option value={model} key={index}>
                    {model}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-1/3 px-2 mb-4">
              <Label htmlFor="renew[criteria][year]">ปีที่จดทะเบียน</Label>
              <Select
                id="renew[criteria][year]"
                name="renew[criteria][year]"
                value={state.renew?.quotation.criteria?.year}
                onChange={(evt) => handleChangeCriteria(evt, "year")}
                inputRef={register({ ...messages.required })}
                error={errors?.renew?.criteria?.year?.message}
                disabled={isLoading}
              >
                <option value="">กรุณาเลือก</option>
                {vehicleState.years?.map((year, index) => (
                  <option value={year} key={index}>
                    {year}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-1/3 px-2 mb-4">
              <Label htmlFor="renew[criteria][car_submodel]">รุ่นย่อย</Label>
              <Select
                id="renew[criteria][car_submodel]"
                name="renew[criteria][car_submodel]"
                value={state.renew?.quotation.criteria?.code_name}
                onChange={(evt) => handleChangeCriteria(evt, "code_name")}
                inputRef={register({ ...messages.required })}
                error={errors?.renew?.criteria?.code_name?.message}
                disabled={isLoading}
              >
                <option value="">กรุณาเลือก</option>
                {vehicleState.sub_models?.map((sub_model, index) => (
                  <option value={sub_model.code_name} key={index}>
                    {sub_model.sub_model}
                  </option>
                ))}
              </Select>
              <input
                type="hidden"
                name="renew[criteria][code_name]"
                value={state.renew?.quotation.criteria?.code_name}
                ref={register()}
              />
            </div>

            <div className="w-1/2 px-2 mb-4">
              <Label htmlFor="renew[criteria][province]">จังหวัดจดทะเบียน</Label>
              <Select
                id="renew[criteria][province]"
                name="renew[criteria][province]"
                value={state.renew?.quotation.criteria?.province}
                onChange={(evt) => handleChangeCriteria(evt, "province")}
                inputRef={register({ ...messages.required })}
                error={errors?.renew?.criteria?.province?.message}
                disabled={isLoading}
              >
                {provinces?.map((province, index) => (
                  <option value={province} key={index}>
                    {province}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-1/2 px-2 mb-4">
              <Label htmlFor="renew[order_details][plate_number]">ทะเบียนรถยนต์</Label>
              <TextInput
                id="renew[order_details][plate_number]"
                name="renew[order_details][plate_number]"
                value={state.renew?.details.plate_number}
                onChange={(evt) => handleChangeDetail(evt, "plate_number")}
                inputRef={register({ ...messages.required })}
                error={errors?.renew?.order_details?.plate_number?.message}
              />
            </div>
            <div className="w-1/2 px-2 mb-4">
              <Label htmlFor="renew[order_details][chassis_number]">
                หมายเลขตัวถังรถยนต์ (คัสซี)
              </Label>
              <TextInput
                id="renew[order_details][chassis_number]"
                name="renew[order_details][chassis_number]"
                value={state.renew?.details.chassis_number}
                onChange={(evt) => handleChangeChassis(evt)}
                inputRef={register({ ...messages.required })}
                error={errors?.renew?.order_details?.chassis_number?.message}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleInfo;
