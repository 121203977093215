import React from "react";
import { AxiosRequestConfig } from "axios";
import axios from "../helpers/configAxios";
import { LoadStatus } from "../types/load";

export interface State<T> {
  status: LoadStatus;
  data?: T;
}

const useAxios = <T = unknown>(url: string, options?: AxiosRequestConfig): State<T> => {
  const [state, setState] = React.useState<State<T>>({
    status: "init",
  });

  React.useEffect(() => {
    setState({ status: "loading" });
    (async (): Promise<void> => {
      await axios
        .get(url, options)
        .then((response) => {
          setState({
            status: "loaded",
            data: response.data,
          });
        })
        .catch((error) => {
          console.error(error);
          setState({ status: "error" });
        });
    })();
  }, []);

  return state;
};

export const usePatch = <T = unknown, P = unknown>(): [
  State<T>,
  (url: string, params: P, options?: AxiosRequestConfig) => void
] => {
  const [state, setState] = React.useState<State<T>>({
    status: "init",
  });

  const patch = React.useCallback((url: string, params: P, options?: AxiosRequestConfig) => {
    axios
      .patch(url, params, options)
      .then((response) => {
        setState({
          status: "loaded",
          data: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
        setState({ status: "error" });
      });
  }, []);
  return [state, patch];
};

export default useAxios;
