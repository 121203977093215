import Cookies from "js-cookie";
import { useState } from "react";

import { isJsonString } from "~src/helpers/helpers";

export const saveToken = (data) => {
  Object.keys(data).map((k) => {
    Cookies.set(k, data[k], { expires: 1, sameSite: "lax" });
  });
};

const staffToken = () => {
  return Cookies.get("access_token");
};

const isStaffTokenValid = () => {
  if (staffToken() == undefined) {
    return false;
  }
  const created_at = Cookies.get("created_at");
  const expires_in = Cookies.get("expires_in");
  return created_at + expires_in < Math.floor(Date.now() / 1000) ? false : true;
};

const getRole = () => {
  const permissions =
    (isJsonString(Cookies.get("permissions")) ? JSON.parse(Cookies.get("permissions")) : null) ||
    [];
  const accessType = permissions.find((p) => p.slug == "ACCESS");

  if (permissions === undefined || permissions === null || permissions.length <= 0) return false;
  if (accessType.action === "STAFF") return "staff";
  if (accessType.action === "AGENT") return "agent";
  if (accessType.action === "DIRECT") return "direct";

  return false;
};

const getAccessType = () => {
  const permissions =
    (isJsonString(Cookies.get("permissions")) ? JSON.parse(Cookies.get("permissions")) : null) ||
    [];
  const accessType = permissions.find((p) => p.slug == "ACCESS");

  if (permissions === undefined || permissions === null || permissions.length <= 0) return false;

  return accessType.action;
};

const getPermission = () => {
  return (
    (isJsonString(Cookies.get("permissions")) ? JSON.parse(Cookies.get("permissions")) : null) || []
  );
};

const getRoleCode = () => {
  return (isJsonString(Cookies.get("role")) ? JSON.parse(Cookies.get("role")) : null)?.code;
};

const getShowPaidCommission = () => {
  return Cookies.get("show_paid_commission") == "true";
}

export const useAuthenticate = () => {
  const [auth, setAuth] = useState(false);

  const validate = () => {
    let authStatus = isStaffTokenValid();
    return authStatus;
  };

  return {
    auth,
    validateAuth: validate,
    role: getRole(),
    permissions: getPermission(),
    accessType: getAccessType(),
    roleCode: getRoleCode(),
    showPaidCommission: getShowPaidCommission()
  };
};
