import React from "react";
import classnames from "classnames";

interface Props {
  name: string;
  id: string;
  align?: "left" | "right" | "center";
  inputRef?: any | undefined;
  error?: any;
  value?: string;
  readOnly?: boolean;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileInput: React.FC<Props> = ({
  name,
  id,
  align = "left",
  onChange,
  inputRef,
  error,
  readOnly,
}) => {
  return (
    <>
      <input
        type="file"
        id={id}
        ref={inputRef}
        name={name}
        className={classnames(
          "w-full px-2 py-2 border border-gray-300 outline-none rounded-5 focus-within:border-secondary",
          `text-${align}`,
          error && "focus:border-red-500 border-red-500"
        )}
        onChange={onChange}
        readOnly={readOnly}
      />
      {error && <p className="text-red-500">{error}</p>}
    </>
  );
};

export default FileInput;
