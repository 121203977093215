import { useState, useEffect } from "react";
import "babel-polyfill";
import axios from "/src/helpers/configAxios";

const useFetchPolicy = (url, payload = {}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sumMonthly, setSumMonthly] = useState("");
  const [sumYearly, setSumYearly] = useState("");
  const [amountQuotations, setAmountQuotations] = useState("");
  const [sumCommission, setSumCommission] = useState("");

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      await axios
        .get(`${process.env.API_URL}${url}`, payload)
        .then((res) => {
          const {
            content,
            summary_premium_by_monthly,
            summary_premium_by_yearly,
            amount_quotations_by_month,
            summary_commission_by_month,
          } = res.data;
          setResponse(content);
          setSumMonthly(summary_premium_by_monthly);
          setSumYearly(summary_premium_by_yearly);
          setAmountQuotations(amount_quotations_by_month);
          setSumCommission(summary_commission_by_month);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(true);
          setError(error);
        });
    }
    fetchData();
  }, []);

  return {
    response,
    error,
    isLoading,
    sumMonthly,
    sumYearly,
    amountQuotations,
    sumCommission,
  };
};

export default useFetchPolicy;
