const initMultipleQuotationState = {
  descriptions_insurer: [
    {
      header: [
        "แพ็คเกจ และประเภทประกันภัย",
        "ค่าเบี้ยประกันภัย",
        "ส่วนลดลูกค้า",
        "ส่วนลดสูงสุด",
        " ",
        "เบี้ยประกันที่ต้องชำระ",
      ],
    },
  ],
  descriptions_coverages: [
    {
      header: "ความคุ้มครองรถยนต์ผู้เอาประกันภัย",
      icon: "icon-motor",
      subHeaders: [
        "ทุนประกัน",
        "ค่าเสียหายส่วนแรก",
        "ประเภทการซ่อม",
        "รถยนต์สูญหาย",
        "ไฟไหม้",
        "น้ำท่วม",
      ],
      allowKeys: [
        "sum_insured",
        "excess_damage_coverage",
        "repair_facility",
        "fire_theft_damage_coverage",
        "fire_theft_damage_coverage",
        "natural_disaster_coverage",
      ],
    },
    {
      header: "ความคุ้มครองบุคคลภายนอก",
      icon: "icon-person",
      subHeaders: [
        "ความบาดเจ็บทางร่างกาย หรือเสียชีวิตต่อคน",
        "ความบาดเจ็บทางร่างกาย หรือเสียชีวิตต่อครั้ง",
        "ทรัพย์สินบุคคลภายนอก ต่อครั้ง",
      ],
      allowKeys: [
        "third_party_bodily_injury_coverage_per_person",
        "third_party_bodily_injury_coverage_per_incident",
        "third_party_property_damage_coverage_per_incident",
      ],
    },
    {
      header: "ความคุ้มครองเพิ่มเติม",
      icon: "icon-star",
      subHeaders: [
        "อุบัติเหตุส่วนบุคคล",
        "คุ้มครองคนโดยสาร",
        "ค่ารักษาพยาบาล",
        "ประกันตัวผู้ขับขี่",
      ],
      allowKeys: [
        "driver_personal_accident_coverage",
        "passengers_in_vehicle_covered",
        "medical_coverage",
        "bail_bond_coverage",
      ],
    },
  ],
  packageObjs: [],
  cptl: "",
};

const multipleQuotationReducer = (state, action) => {};

export { initMultipleQuotationState, multipleQuotationReducer };
