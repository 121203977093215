import React, { useEffect, useRef, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import { AxiosResponse } from "axios";
import classnames from "classnames";
import { Options } from "~/src/types/basic";
import { SumInsuredResponse } from "~/src/types/searchPackage";
import { formatNumberWithCurrency2 } from "~/src/helpers/formatNumber";
import { SelectField } from "~/src/components/form";
import { getSumInsuredInfo } from "~/src/pages/agent/motor/Services";
import Loading from "~/src/components/loading";

interface Prop extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  className?: string;
  id: string;
  name: string;
  vehicleCodeName: string;
  isNoSubModel?: number;
  value?: string;
  handleChange?: (e: React.ChangeEvent) => void;
}

const AdjustSumInsured: React.FC<Prop> = ({
  id,
  name,
  className,
  vehicleCodeName,
  value = "",
  isNoSubModel = 0,
  errors,
  handleChange,
  register,
}): JSX.Element => {
  const [options, setOptions] = useState<Options<string>[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [sumInsuredInfo, setSumInsuredInfo] = useState<SumInsuredResponse["sum_insureds"]>();
  const defaultSuggestIndex = useRef<number>();

  const convertDataToOption = (): void => {
    if (!sumInsuredInfo?.ranges) return;

    const results = sumInsuredInfo.ranges.map((sumInsured, index) => {
      let priceText: string = formatNumberWithCurrency2(sumInsured);
      if (sumInsured === sumInsuredInfo.suggest) {
        defaultSuggestIndex.current = index;
        priceText = `${priceText} (ทุนแนะนำ)`;
      }

      return {
        label: priceText,
        value: sumInsured,
      };
    });
    setOptions(results);
  };

  const fetchSumInsuredInfo = async () => {
    try {
      setLoading(true);
      const response: AxiosResponse<SumInsuredResponse> = await getSumInsuredInfo(
        vehicleCodeName,
        isNoSubModel
      );
      setSumInsuredInfo(response.data.sum_insureds);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setSumInsuredInfo(undefined);
      setOptions(undefined);
    };
  }, []);

  useEffect(() => {
    if (vehicleCodeName) fetchSumInsuredInfo();
  }, [vehicleCodeName]);

  useEffect(() => {
    convertDataToOption();
  }, [sumInsuredInfo]);

  return (
    <>
      <SelectField
        className={classnames(className, "text-center border-grayCustom")}
        id={id}
        name={name}
        inputRef={register}
        onChange={handleChange}
        options={options}
        disabled={!options}
        error={!!errors?.[name] && errors?.[name].message}
        value={
          value === ""
            ? options?.[defaultSuggestIndex.current]?.value ?? options?.[0]?.value
            : value
        }
      />
      {loading && <Loading />}
    </>
  );
};

export default AdjustSumInsured;
