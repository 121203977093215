import React from "react";
import { useToasts } from "react-toast-notifications";

interface Props {
  title?: string;
  icon?: React.ReactElement;
  href?: string | boolean;
  width?: string;
  heightStyle?: number;
  subtitle?: string;
  disabled?: boolean;
}

const QuickMenuItem: React.FC<Props> = ({
  title,
  icon,
  href,
  width,
  heightStyle,
  subtitle,
  disabled = false,
}) => {
  const { addToast } = useToasts();

  const setAlert = (text, appearance) => {
    addToast(
      <div>
        <h4>{text}</h4>
      </div>,
      {
        appearance,
        autoDismiss: true,
      }
    );
  };

  return (
    <>
      {disabled ? (
        <div
          className={`flex flex-col items-center bg-white rounded-lg overflow-hidden cursor-pointer transition duration-500 ease-in-out hover:bg-blue-400 hover:shadow-lg ${width}`}
          style={{ height: heightStyle }}
          onClick={!href ? () => setAlert("Coming Soon", "info") : undefined}
        >
          <div className="text-center my-1 text-secondary-color">{title}</div>
          <div className="my-auto">
            {icon ? (
              <div>{icon}</div>
            ) : (
              <img src="https://via.placeholder.com/25.png" style={{ width: 25, height: 25 }} />
            )}
          </div>

          <div className="text-white w-full text-center mt-auto bg-secondary-color">
            {subtitle ?? "เช็คเบี้ย"}
          </div>
        </div>
      ) : (
        <a
          className={`flex flex-col items-center bg-white rounded-lg overflow-hidden cursor-pointer transition duration-500 ease-in-out hover:bg-blue-400 hover:shadow-lg ${width}`}
          style={{ height: heightStyle }}
          href={href}
          onClick={!href ? () => setAlert("Coming Soon", "info") : undefined}
        >
          <div className="text-center my-1 text-secondary-color">{title}</div>
          <div className="my-auto">
            {icon ? (
              <div>{icon}</div>
            ) : (
              <img src="https://via.placeholder.com/25.png" style={{ width: 25, height: 25 }} />
            )}
          </div>

          <div className="text-white w-full text-center mt-auto bg-secondary-color">
            {subtitle ?? "เช็คเบี้ย"}
          </div>
        </a>
      )}
    </>
  );
};

export default QuickMenuItem;
