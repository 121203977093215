import React from "react";
import { rangeDate } from "~src/helpers/dateTime";
import { useAppSelector } from "~src/store";
import { getCountryNameState, travelInfoState } from "../redux";

const TravelInfo = () => {
  const state = useAppSelector(travelInfoState);
  const countryName = useAppSelector(getCountryNameState);

  return (
    <div className="col-md-6">
      <div className="travel-search-page-count">
        พบ <span className="lenght"> {state.count} </span> แผนประกันสำหรับ
        <span className="model">
          {countryName}, {state.travelers} คน, {rangeDate(state.startDate, state.endDate)}
        </span>
      </div>
    </div>
  );
};

export default TravelInfo;
