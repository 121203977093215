import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormGroup, TextField } from "../form";
import UploadVehicleDocument from "~src/components/agent/checkout/vehicle/UploadVehicleDocument";
import UploadVehicleImage from "~src/components/agent/checkout/vehicle/UploadVehicleImage";

import { messages } from "../../helpers/messages";

interface Vehicle {
  plate_number: string;
  chassis_number: string;
}

interface EditInsuredCardProps {
  submitId: string;
  vehicle: Vehicle;
  vehicleDocuments: any;
  vehicleImages: any;
  province: string;
  isPolicyType_1: boolean;
  allowEdit: boolean;
  attachImagesStatus: string;
  formRef: (el: HTMLFormElement) => void;
  onSubmit: (arg: any) => void;
  onVehicleChanged: (args: any) => void;
}

interface IFormInputs {
  order: {
    details: {
      plate_number: string;
      chassis_number: string;
    };
    vehicle_document: string;
  };
}

export default function EditVehicleCard({
  submitId,
  vehicle,
  vehicleDocuments,
  attachImagesStatus,
  vehicleImages,
  allowEdit,
  province,
  isPolicyType_1,
  formRef,
  onSubmit,
  onVehicleChanged,
}: EditInsuredCardProps) {
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm<IFormInputs>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleChangePlateNumber = (e) => {
    let { value } = e.target;
    value = value.replace(/[^ก-ฮA-Za-z0-9]/gi, "");
    onVehicleChanged({
      ...vehicle,
      plate_number: value,
    });
  };

  const handleChangeChassisNumber = (e) => {
    let { value } = e.target;
    value = value.replace(/[^A-Z0-9]/gi, "").toUpperCase();
    onVehicleChanged({
      ...vehicle,
      chassis_number: value,
    });
  };

  return (
    <div
      className="block text-left align-bottom bg-white rounded-lg"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <form
        id={submitId}
        ref={formRef}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="container grid items-start grid-cols-1 mt-0 ml-0 text-left gap-x-4 gap-y-0 justify-items-stretch sm:grid-cols-2">
              <h6 className="col-span-2">กรอกข้อมูลรถยนต์</h6>
              <div className="col-span-1">
                <FormGroup
                  label="ทะเบียนรถยนต์"
                  htmlForLabel="order[details][plate_number]"
                  require
                >
                  <TextField
                    id="order[details][plate_number]"
                    name="order[details][plate_number]"
                    onChange={handleChangePlateNumber}
                    maxLength={10}
                    value={vehicle.plate_number}
                    inputRef={register(messages.required)}
                    readOnly={!allowEdit}
                    error={errors.order?.details?.plate_number?.message}
                  />
                  <span className="help-txt text-xs">ตัวอย่าง: กข1234 หรือ 1กข1234</span>
                </FormGroup>
                <FormGroup
                  label="จังหวัดที่จดทะเบียน"
                  htmlForLabel="order[details][plate_province]"
                  require
                >
                  <TextField
                    id="order[details][plate_province]"
                    name="order[details][plate_province]"
                    inputRef={register}
                    value={province}
                    readOnly
                  />
                </FormGroup>
                <FormGroup
                  label="หมายเลขตัวถังรถยนต์(คัสซี)"
                  htmlForLabel="order[details][chassis_number]"
                  require
                >
                  <TextField
                    id="order[details][chassis_number]"
                    name="order[details][chassis_number]"
                    onChange={handleChangeChassisNumber}
                    field="chassis-number"
                    inputRef={register(messages.required)}
                    value={vehicle.chassis_number}
                    readOnly={!allowEdit}
                    error={errors.order?.details?.chassis_number?.message}
                    maxLength={t("maxChassisNumber")}
                  />
                  <span className="help-txt text-xs">ตัวอย่าง: MMTJJKK10FH006002</span>
                </FormGroup>
              </div>
              <div className="col-span-1">
                <div className="vehicle-registration-box">
                  <div className="vehicle-registration-layout">
                    <div className="top">
                      {vehicle.plate_number !== undefined && vehicle.plate_number !== ""
                        ? vehicle.plate_number
                        : ""}
                    </div>
                    <div className="bottom">{province}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isPolicyType_1 && (
            <div className="row">
              <div className="col-12">
                <UploadVehicleImage
                  id="vehicle_image"
                  name="order[vehicle_image]"
                  radioName="order[car_inspection_type]"
                  appName={t("appName")}
                  attachImagesStatus={attachImagesStatus}
                  vehicleImages={vehicleImages}
                  errors={errors.order}
                  register={register}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12">
              <UploadVehicleDocument
                id="vehicle_document"
                name="order[vehicle_document]"
                vehicleDocuments={vehicleDocuments}
                errors={errors.order}
                register={register}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
