import React from "react";
import { UseFormMethods } from "react-hook-form";
import _get from "lodash/get";
import { messages } from "~/src/helpers/messages";
import { FormGroup, SelectField } from "~/src/components/form";
import { vehicleType } from "~/src/helpers/options";

interface Props extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  name: string;
  className?: string;
  callbackOnChange?: (e: React.ChangeEvent) => void;
}

const VehicleTypeInput: React.FC<Props> = ({
  name,
  className,
  errors,
  register,
  callbackOnChange,
}): JSX.Element => {
  const errorMsg = _get(errors, name)?.message;

  return (
    <FormGroup label="ประเภทรถยนต์" className={className}>
      <SelectField
        name={name}
        style={{ minHeight: "38px" }}
        inputRef={register(messages.required)}
        error={errorMsg}
        onChange={callbackOnChange}
        options={vehicleType}
      />
    </FormGroup>
  );
};

export default VehicleTypeInput;
