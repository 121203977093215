import { addSeconds, differenceInSeconds } from "date-fns";
import Decimal from "decimal.js";
// import queryString from "query-string";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import { Button, ButtonRadius } from "~src/components/form";
import CheckSignIcon from "~src/components/icons/svg/CheckSignIcon";
import ProductTypeIcon from "~src/components/icons/ProductTypeIcon";

import dictionary from "~src/data.yaml";
import PaymentsAPIClient, { PaymentEventResponse } from "~src/api/Payments";
import { useAppContext } from "~src/context/App";
import { usePageContext } from "~src/context/Page";
import { parseDateTime, reformatDateEN, reformatDateTimeEN } from "~src/helpers/date";
import { formatNumberWithCurrency } from "~src/helpers/formatNumber";
import { getInsuredFullName } from "~src/helpers/insuredHelper";
import APIError from "~src/models/APIError";
import { Order, OrderPaymentsSummary, Status as OrderStatus } from "~src/types/order";
import { Payment, PaymentStatus } from "~src/types/payment";
import { QRInfo } from "~src/types/qrInfo";
import { Quote } from "~src/types/quote";

import qrLogo from "~assets/payments/thaiqrlogo.png";

const DEFAULT_COUNTDOWN_SECS = 5 * 60;

interface QRCountdownState {
  isStarted: boolean;
  untilTime?: Date;
  remainingSecs?: number;
  countdownText?: string;
}

// TODO: Refactor this into helpers/* ??
const getCountdownRemainingSecs = (expDateTime: Date, refDateTime: Date = new Date()) => {
  const diffSecs = expDateTime ? differenceInSeconds(expDateTime, refDateTime) : 0;

  //TEST
  // console.log("getCountdownRemainingSecs:", refDateTime, "-", expDateTime, " => ", diffSecs);

  return diffSecs;
};

// TODO: Refactor this into models/* ??
export interface OrderInfoState {
  number?: string;
  status?: OrderStatus;
  customerName?: string;
  mainInsuredName?: string;
  mainProductType?: string;
  mainProductName?: string;
  insurerCode?: string;
  paymentsSummary?: OrderPaymentsSummary;
  shortDescCriteria?: string;
  shortDescPolicy?: string;
  details?: Record<string, any>;
  quotes?: Quote[];
}

// TODO: Refactor this into helpers/* ??
const getOrderInfoStateFromOrder = (order: Partial<Order>) =>
  ({
    number: order.number,
    status: order.status,
    customerName: order.quotation.customer_name,
    mainInsuredName: order.insureds ? getInsuredFullName(order.insureds[0]) : undefined,
    mainProductType: order.quotation.product_type,
    mainProductName: order.quotes?.[0]?.product_name,
    insurerCode: order.quotes?.[0]?.insurer_code,
    paymentsSummary: order.payments_summary,
    shortDescCriteria: order.quotation.short_desc_criteria,
    details: order.details,
    quotes: order.quotes,
  } as OrderInfoState);

export interface QRPaymentProps {
  orderID: number;
  amount: Decimal;
  totalAmount: Decimal;
  payment?: Payment;
  order?: Partial<Order>;
  previousQRInfo?: QRInfo;
  isInstallments?: boolean;
  installmentTerms?: number;
  installmentPaidTerms?: number;
  paymentsAPI?: PaymentsAPIClient;
  isShowOrderInfoLines?: boolean;
  disabled?: boolean;
  onGoBack?: () => void;
  onSuccess?: (payment: Payment, orderInfo: OrderInfoState, evtData: PaymentEventResponse) => void;
  onError?: (err: Error) => void;
  className?: string;
}

const QRPayment: React.FC<QRPaymentProps> = ({
  orderID,
  amount,
  payment,
  order,
  previousQRInfo,
  paymentsAPI,
  isShowOrderInfoLines = true,
  disabled,
  onGoBack,
  onSuccess,
  onError,
  className = "",
}) => {
  const { assets } = useAppContext();
  // HACK: Workaround for Parcel bundler Error: Cannot find module 'assets/...' with some asset files
  const { imageNotAvailable: noneImage } = assets;
  const { setAlert, setPreloading, onError: onPageError } = usePageContext();

  // let history = useHistory();

  const [loadingBtn, setLoadingBtn] = useState(false);
  const [currentPayment, setCurrentPayment] = useState<Payment | undefined>(payment);
  const [orderInfo, setOrderInfo] = useState<OrderInfoState>(() => ({}));

  const [isShowQR, setIsShowQR] = useState(false);
  const [qrInfo, setQrInfo] = useState<QRInfo | undefined>();
  const [qrCountdownState, setQrCountdownState] = useState<QRCountdownState>({
    isStarted: false,
  });

  const qrIdRef = useRef<HTMLDivElement>();
  const btnQrGen = useRef<HTMLButtonElement>();
  const countdownIntervalRef = useRef<ReturnType<typeof setTimeout>>();
  const subscriptionRef = useRef<any>();
  const unsubscribeFnRef = useRef<(reason: any) => void>();

  const handleGenerateQR = useCallback(async () => {
    if (disabled) {
      return;
    }

    try {
      setAlert(undefined);
      setLoadingBtn(true);
      const paymentResp = await paymentsAPI.createQRPayment({
        orderID,
        payment: { token: currentPayment?.token },
      });
      const _payment = paymentResp.content?.payment?.token
        ? {
            ...currentPayment,
            ...paymentResp.content.payment,
          }
        : currentPayment;
      if (!_payment) {
        throw new APIError("Payment Not Found", { statusCode: 404 });
      }

      // check and cancel previous one
      if (qrInfo) {
        await cancelQR(qrInfo.id);
      }

      setIsShowQR(false);

      const newQRResult = await paymentsAPI.generateQRCode(orderID, _payment.token);

      unstable_batchedUpdates(() => {
        setQrInfo(newQRResult);
        setCurrentPayment(_payment);
        setLoadingBtn(false);
      });
    } catch (err) {
      console.error(err);
      setLoadingBtn(false);
      setAlert(err.message, err.details);
      onError && onError(err);
    }
  }, []);

  const cancelQR = useCallback(
    async (qrID: string) => {
      if (disabled) {
        return;
      }
      //   let qrCancelUrl = `${process.env.KBANK_API_QR_URL}/${qrID}/cancel`;
      //   let header = { "x-api-key": process.env.KBANK_API_SKEY };

      const resp = await paymentsAPI.cancelQRCode({
        orderID,
        paymentToken: currentPayment?.token,
        qrID,
      });

      if (qrCountdownState.isStarted) {
        stopCountdown();
      }
    },
    [disabled, paymentsAPI, orderID, currentPayment?.token, qrCountdownState.isStarted]
  );

  const tickCountdown = useCallback(() => {
    setQrCountdownState((st) => {
      const _remainingSecs = st.untilTime
        ? getCountdownRemainingSecs(st.untilTime)
        : st.remainingSecs;
      if (st.isStarted && _remainingSecs >= 1) {
        const ss = Math.floor(_remainingSecs % 60);
        const mm = Math.round((_remainingSecs - ss) / 60);
        const secondsStr = `0${ss}`.slice(-2);

        return {
          ...st,
          remainingSecs: _remainingSecs,
          countdownText: `${mm}:${secondsStr}`,
        };
      }

      console.log(
        "Countdown expired since:",
        st.untilTime,
        "; Clearing interval:",
        countdownIntervalRef.current
      );
      countdownIntervalRef.current && clearInterval(countdownIntervalRef.current);
      countdownIntervalRef.current = undefined;
      setIsShowQR(false);
      setQrInfo({ ...qrInfo, id: undefined, image_with_base64: undefined });

      return {
        ...st,
        isStarted: false,
        remainingSecs: 0,
      };
    });
  }, []);

  const stopCountdown = useCallback(() => {
    setQrCountdownState((st) => {
      console.log(
        `Stopping countdown interval at ${st.remainingSecs} s; Clearing interval:`,
        countdownIntervalRef.current
      );
      countdownIntervalRef.current && clearInterval(countdownIntervalRef.current);
      countdownIntervalRef.current = undefined;

      return {
        ...st,
        isStarted: false,
        intervalID: undefined,
        remainingSecs: 0,
        countdownText: "-:--",
      };
    });
  }, []);

  const handlePaymentEventResponse = useCallback(
    (data: PaymentEventResponse) => {
      // TEST
      console.log(`Data from PaymentChannel [${orderID}/${currentPayment?.token}]:`, data);

      const {
        status: orderStatus,
        payment_status,
        amount,
        paid_at,
        short_desc_criteria,
        short_desc_policy,
      } = data;

      let paidDateTime = paid_at ? parseDateTime(paid_at) : undefined;
      if (!paidDateTime && payment_status === "paid") {
        paidDateTime = new Date();
      }

      const _payment = {
        ...currentPayment,
        status: payment_status as PaymentStatus,
        paid_amount: amount || currentPayment.paid_amount,
        paid_at: paidDateTime || currentPayment.paid_at,
      };
      const _orderInfo = {
        ...orderInfo,
        status: (orderStatus ? (orderStatus as OrderStatus) : undefined) || orderInfo.status,
        shortDescCriteria: short_desc_criteria,
        shortDescPolicy: short_desc_policy,
      };

      unstable_batchedUpdates(() => {
        setCurrentPayment(_payment);
        setOrderInfo(_orderInfo);
        if (payment_status !== "pending") {
          stopCountdown();
        }
      });

      if (payment_status === "paid") {
        onSuccess && onSuccess(_payment, _orderInfo, data);
      }
    },
    [orderID, currentPayment, orderInfo, stopCountdown, onSuccess]
  );

  const getOrderInfoStateFromOrder = useCallback<() => OrderInfoState>(() => {
    return {
      number: order.number,
      status: order.status,
      customerName: order.quotation.customer_name,
      mainInsuredName: order.insureds ? getInsuredFullName(order.insureds[0]) : undefined,
      mainProductType: order.quotation.product_type,
      mainProductName: order.quotes?.[0]?.product_name,
      insurerCode: order.quotes?.[0]?.insurer_code,
      paymentsSummary: order.payments_summary,
      shortDescCriteria: order.quotation.short_desc_criteria,
      details: order.details,
      quotes: order.quotes,
    };
  }, [order]);

  const getCountdownRemainingSecs = useCallback(
    (expDateTime: Date, refDateTime: Date = new Date()) => {
      const diffSecs = expDateTime ? differenceInSeconds(expDateTime, refDateTime) : 0;

      // TEST
      // console.log("getCountdownRemainingSecs:", refDateTime, "-", expDateTime, " => ", diffSecs);

      return diffSecs;
    },
    []
  );

  //   function gotoThankyou() {
  //     history.push({
  //       pathname: `/thankyou`,
  //       search: queryString.stringify({
  //         order_id: order.order_id,
  //         orderNumber: order.number,
  //         customerName: quotation?.customer_name,
  //         shortDescCriteria: quotation?.short_desc_criteria,
  //         shortDescPolicy: quotation?.short_desc_policy,
  //         insurerCode: mainQuote?.insurer_code,
  //         amount: amount.toFixed(2),
  //         paymentMethod: payment?.pay_method,
  //         insureds: mainInsured
  //           ? `${mainInsured.title || ""}${mainInsured.firstname} ${mainInsured.lastname}`
  //           : "",
  //       }),
  //     });
  //   }

  useDeepCompareEffectNoCheck(() => {
    setCurrentPayment(payment);
    // if (payment.status === "paid") {
    //   setTimeout(gotoThankyou, 1000);
    // }
  }, [payment]);
  useDeepCompareEffectNoCheck(() => {
    order &&
      setOrderInfo({
        ...orderInfo,
        ...getOrderInfoStateFromOrder(order),
      });
  }, [order]);

  useDeepCompareEffectNoCheck(() => {
    if (!previousQRInfo) {
      return;
    }
    if (currentPayment.status == "paid") {
      return;
    }

    // Cancel previous one before getting fresh QR
    (async function () {
      try {
        setAlert(undefined);
        setLoadingBtn(true);
        await cancelQR(previousQRInfo.id);
        setLoadingBtn(false);
      } catch (err) {
        console.error(err);
        setLoadingBtn(false);
        setAlert(err.message, err.details);
      }
    })();
  }, [previousQRInfo, currentPayment]);

  useDeepCompareEffectNoCheck(() => {
    if (qrCountdownState.isStarted) {
      stopCountdown();
    }

    if (!qrInfo) {
      setIsShowQR(false);
      return;
    }

    unstable_batchedUpdates(() => {
      setQrCountdownState((st) => {
        const _untilTime = qrInfo.expires_at
          ? parseDateTime(qrInfo.expires_at)
          : addSeconds(new Date(), qrInfo.expire_time_seconds || DEFAULT_COUNTDOWN_SECS);
        const _remainingSecs = getCountdownRemainingSecs(_untilTime);
        countdownIntervalRef.current = setInterval(tickCountdown, 1000);

        return {
          ...st,
          isStarted: true,
          untilTime: _untilTime,
          remainingSecs: _remainingSecs,
          countdownText: "-:--",
        };
      });
      setIsShowQR(true);
    });

    // cleanup
    return () => {
      countdownIntervalRef.current && clearInterval(countdownIntervalRef.current);
      countdownIntervalRef.current = undefined;
    };
  }, [qrInfo]);

  useEffect(() => {
    if (!qrInfo?.id) {
      return;
    }

    let subs;
    let unsubscribe;
    try {
      [subs, unsubscribe] = paymentsAPI.subscribePaymentChannel(
        orderID,
        currentPayment?.token,
        handlePaymentEventResponse
      );
      console.log("Subscribed to PaymentChannel:", subs);
      subscriptionRef.current = subs;
      unsubscribeFnRef.current = unsubscribe;
    } catch (err) {
      console.error(err);
      setAlert(err.message, err.details);
    }

    // cleanup
    return () => {
      if (subs && unsubscribe) {
        unsubscribe("qr.id changed");
        if (subscriptionRef.current === subs) {
          subscriptionRef.current = undefined;
        }
        if (unsubscribeFnRef.current === unsubscribe) {
          unsubscribeFnRef.current = undefined;
        }
      }
    };
  }, [qrInfo?.id]);

  return (
    <>
      <div
        className={`payment-methods-wrap flex flex-col items-stretch max-width-screen ${className}`}
      >
        <div className="payment-total">
          <h4 className="text-center text-xl">{dictionary.payment.amount}</h4>
          <h2 className="pay-amount text-center text-3xl">{formatNumberWithCurrency(amount)}</h2>
        </div>
        {isShowOrderInfoLines && (
          <OrderInfoLines orderInfo={orderInfo} payment={payment} className="md:w-4/6" />
        )}
        {currentPayment?.status === "paid" ? (
          <div className="mini-thankyou-card flex flex-col w-full justify-center">
            <div className="flex flex-row w-full justify-center">
              <CheckSignIcon className="w-256 h-256 md:w-132 md:h-132" width={132} height={132} />
            </div>
            <h4 className="mini-thankyou-card flex flex-row w-full justify-center text-center text-xl">
              {dictionary.payment.success}
            </h4>
            <p className="flex flex-row w-full justify-evenly gap-x-4">
              <span className="list-key flex w-3/6 justify-end text-right">
                {dictionary.payment.payment_date} :
              </span>
              <span className="list-value flex flex-grow justify-start text-left">
                {reformatDateEN(currentPayment.paid_at)}
              </span>
            </p>
            {/* <p className="flex flex-row w-full justify-center text-sm text-body">
                {orderInfo.shortDescCriteria}
              </p>
              <p className="flex flex-row w-full justify-center text-sm text-body">
                {orderInfo.shortDescPolicy}
              </p> */}
            <p className="mini-thankyou-card-footer flex flex-row w-full text-grey-small pt-20 pl-20 pr-20 font-thin">
              {dictionary.payment.please_contact_your_agent}
            </p>
          </div>
        ) : (
          <>
            <hr className="flex" />

            <div className="qr flex" ref={qrIdRef} data-qr-id={qrInfo?.id}>
              <div className="col-md-12 col-center">
                <div className={!isShowQR ? `qr-canvas mt-4` : `qr-canvas mt-4 hidden`}>
                  <div className="col-md-12 col-center">
                    <p className="text-sm font-normal">สแกน QR Code ผ่าน Application</p>
                    <p className="mb-12 text-xs font-light">(รับเงินได้จากทุกธนาคาร)</p>
                  </div>
                  <Button
                    color="primary"
                    inputRef={btnQrGen}
                    onClick={() => handleGenerateQR()}
                    disabled={disabled || loadingBtn}
                    classNameOption={qrCountdownState.isStarted ? "hidden" : ""}
                  >
                    {!loadingBtn ? "กดที่นี่เพื่อรับ QR CODE" : "กรุณารอสักครู่..."}
                    {/* {!loadingBtn ? "ชำระเงินผ่าน QR" : "กรุณารอซักครู่..."} */}
                  </Button>

                  <p className="text-grey-small pt-20 pl-20 pr-20 font-thin">
                    <strong>หมายเหตุ:</strong> {dictionary.payment.qr_footer_remark}
                  </p>
                </div>
                <div className={`qr-canvas flex flex-1 justify-center ${isShowQR ? "" : "hidden"}`}>
                  <div className="flex flex-wrap flex-col md:flex-row w-full md:w-3/6 justify-center">
                    {/* row */}
                    <div className="flex flex-1 flex-col">
                      {/* col-md-3 */}
                      <div className="thai-qr-frame">
                        <div className="thai-qr-frame-header my-2">
                          <img className="thai-qr-frame-header-logo" src={qrLogo} alt="" />
                        </div>

                        <div className="thai-qr-frame-body">
                          <div className="thai-qr-frame-body-qr-image">
                            {qrInfo?.image_with_base64 ? (
                              <img
                                src={`data:image/jpeg;base64,${qrInfo.image_with_base64}`}
                                id="qr-canvas"
                                className="w-256 h-256 md:w-132 md:h-132"
                                width={132}
                                height={132}
                              />
                            ) : (
                              <img
                                src={noneImage}
                                id="qr-canvas"
                                className="w-256 h-256 md:w-132 md:h-132"
                                width={132}
                                height={132}
                              />
                            )}
                          </div>

                          <div className="thai-qr-frame-body-text">
                            <p className="thai-qr-frame-body-text-header-qr">
                              {dictionary.payment.receiver_account_name}
                            </p>
                            <p className="thai-qr-frame-body-text-body">
                              จำนวน <strong>{amount.toFixed(2)}</strong> บาท
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col col-center text-sm">
                      {qrInfo && (
                        <p className="my-2">
                          <ButtonRadius
                            component="a"
                            href={`data:image/jpeg;base64,${qrInfo?.image_with_base64}`}
                            download={qrInfo?.id}
                            classNameOption=""
                          >
                            <i className="material-icons">download</i> Download
                          </ButtonRadius>
                        </p>
                      )}
                      <p>กรุณาสแกน QR Code ผ่าน Application</p>
                      <p className="mb-12 text-xs">(รับเงินได้จากทุกธนาคาร)</p>
                      {!qrCountdownState.isStarted ? (
                        <Button
                          color="primary"
                          inputRef={btnQrGen}
                          onClick={() => handleGenerateQR()}
                          disabled={loadingBtn}
                        >
                          {!loadingBtn ? "กดเพื่อรับ QR Code ใหม่" : "กรุณารอซักครู่..."}
                        </Button>
                      ) : (
                        <>
                          <p className="mb-4">
                            <small>
                              {dictionary.payment.order_status}:{" "}
                              <span className="bullet"> รอชำระเงิน</span>
                            </small>
                          </p>
                          <p>กรุณาทำรายการภายใน {qrCountdownState.countdownText} นาที</p>
                        </>
                      )}
                      <p className="text-grey-small pt-20 pl-20 pr-20 font-thin">
                        <strong>หมายเหตุ:</strong> {dictionary.payment.qr_footer_remark}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex flex-row w-full">
        {onGoBack && (
          <div className="motor-info-page-actions space-between w-full">
            <Button type="button" role="button" onClick={(e) => onGoBack()}>
              ย้อนกลับ
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default QRPayment;

interface OrderInfoLinesProps {
  orderInfo: OrderInfoState;
  payment: Payment;
  className?: string;
}

interface OrderInfoLineItem {
  label: string;
  value: React.ReactNode;
}

const OrderInfoLines: React.FC<OrderInfoLinesProps> = ({ orderInfo, payment, className = "" }) => {
  const { mainProductType, shortDescCriteria, details } = orderInfo;
  const { is_installments, installment_terms, payment_total_amount } =
    orderInfo.paymentsSummary || {};
  const mainQuote = orderInfo.quotes?.[0];
  const { coverage_from, coverage_to, short_desc_policy } = mainQuote || {};

  let dataCols1: OrderInfoLineItem[] = [
    {
      label: dictionary.payment.order_number,
      value: (
        <span className="list-value flex flex-grow justify-start spacing-x-2 text-left">
          <ProductTypeIcon type={orderInfo.mainProductType} /> {orderInfo.number}
        </span>
      ),
    },
    {
      label: dictionary.payment.customer_name,
      value: orderInfo.mainInsuredName || orderInfo.customerName,
    },
    // { label: dictionary.payment.customer_name, value: orderInfo.customerName },
    // { label: dictionary.payment.product_name, value: orderInfo.mainProductName },
  ];
  if (is_installments) {
    dataCols1 = [
      ...dataCols1,
      {
        label: `${dictionary.payment.installment} ${dictionary.payment.installment_term}`,
        value: `${payment.installment_seq_no}/${installment_terms}`,
      },
      {
        label: dictionary.payment.total_amount,
        value: formatNumberWithCurrency(payment_total_amount),
      },
      {
        label: dictionary.payment.due_date,
        value: reformatDateEN(payment.installment_due_at),
      },
    ];
  } else {
    dataCols1 = [];
  }

  let dataCols2: OrderInfoLineItem[];
  if (mainProductType === "motor") {
    dataCols2 = [
      {
        label: dictionary.payment.vehicle_info,
        value: shortDescCriteria || "-",
      },
      {
        label: dictionary.payment.vehicle_license_plate,
        value: details?.plate_number || "-",
      },
      {
        label: dictionary.payment.coverage_date,
        value:
          coverage_from || coverage_to
            ? `${reformatDateEN(coverage_from)} - ${reformatDateEN(coverage_to)}`
            : "-",
      },
      {
        label: dictionary.payment.policy_type,
        value: short_desc_policy || "-",
      },
    ];
  } else {
    dataCols2 = [];
  }

  // TODO: Revise this later
  const data = [...dataCols1, ...dataCols2];

  // for (let i = 0; i < dataCols1.length && i < dataCols2.length; i++) {
  //   if (i < dataCols1.length) {
  //     data.push(dataCols1[i]);
  //   }
  //   if (i < dataCols2.length) {
  //     data.push(dataCols2[i]);
  //   }
  // }

  return (
    <div className={`order-info-lines flex flex-col w-full items-stretch ${className}`}>
      {/* <div className="flex flex-row w-full justify-evenly gap-x-4">
        {[dataCols1, dataCols2].map((dataCol, colIdx) => (
          <div key={`col_${colIdx}`} className="flex flex-col w-full md:w-1/2"> */}
      <div className="flex flex-col w-full gap-x-4">
        {data.map((item, rowIdx) => (
          <p key={`row_${rowIdx}`} className="flex flex-row w-full gap-x-1">
            <span className="list-key flex w-3/6 justify-end text-right">{item.label} :</span>
            <span className="list-value flex w-3/6 justify-start text-left">{item.value}</span>
          </p>
        ))}
      </div>
      {/*
          </div>
        ))}
      </div> */}
    </div>
  );
};
