import axios from "axios";
import cookie from "js-cookie";
import { logout } from "~src/utils/logout";

//DEPRECATED: Use /src/api/* instead
const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = cookie.get("access_token");
    if (token !== undefined) {
      const bearer = `Bearer ${token}`;
      config.headers.Authorization = bearer;
      return config;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (401 === error.response.status) {
      logout()
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
