import fetch, { AxiosResponse } from "axios";
import React, { useCallback } from "react";

import PaymentsAlertModal, {
  PaymentsAlertModalProps,
} from "~src/components/modal/payments/PaymentsAlertModal";

import dictionary from "~src/data.yaml";
import { formatNumberWithCurrency } from "~src/helpers/formatNumber";
import axios from "~src/helpers/configAxios";
import { CheckoutSummaryState } from "~src/models/CheckoutState";
import { Order } from "~src/types/order";

import logo from "~assets/2C2P_Logo.png";

export interface Props {
  orderId: number;
  order: Order;
  summary: CheckoutSummaryState;
  disabled?: boolean;
  onError?: (err: Error) => void;
}

// TODO: Refactor into PaymentsAPI

interface PaymentParams {
  amount: string;
  currency: string;
  hash_value: string;
  merchant_id: string;
  order_id: string;
  payment_url: string;
  payment_description: string;
  result_url_1: string;
  user_defined_1: string;
  user_defined_2: string;
  user_defined_3: string;
  user_defined_4: string;
  version: string;
}

export default function TwoCTwoP({ orderId, order, disabled, summary, onError }: Props) {
  const [state, setState] = React.useState<PaymentParams | null>(null);
  const forRef = React.useRef(null);

  const handlePay = useCallback(async () => {
    if (disabled) {
      return;
    }

    try {
      const resp = await axios.post<any, AxiosResponse<PaymentParams>>(
        `/orders/${orderId}/payments/two_c_two_p`,
        {
          // payable_type: "Order",
          // amount: summary.paymentTotalAmount,
          payment_description: `${summary.insurerCode} - ${summary.orderNumber}`,
          // currency: "THB",
          // payable_id: orderId,
        }
      );

      const {
        amount,
        currency,
        hash_value,
        merchant_id,
        order_id,
        payment_url,
        payment_description,
        result_url_1,
        user_defined_1,
        user_defined_2,
        user_defined_3,
        user_defined_4,
        version,
      } = resp.data;

      setState({
        amount,
        currency,
        hash_value,
        merchant_id,
        order_id,
        payment_url,
        payment_description,
        result_url_1,
        user_defined_1,
        user_defined_2,
        user_defined_3,
        user_defined_4,
        version,
      });
    } catch (err) {
      console.error(err);
      onError && onError(err);
    }
  }, [orderId, onError]);

  React.useEffect(() => {
    if (state) {
      forRef.current.submit();
    }
  }, [state]);

  return (
    <div className="p-4 payment-methods-wrap">
      <div className="payment-methods-body">
        <div className="payment-total">
          <span>ยอดเงินที่ต้องชำระทั้งหมด</span>
          <span className="total-amount">
            {formatNumberWithCurrency(summary.paymentTotalAmount)}
          </span>
        </div>
      </div>
      <hr />
      <div className="flex justify-center">
        <img src={logo} alt="" />
      </div>
      <div className="flex justify-center">
        <button
          type="button"
          className="px-6 py-2 text-white bg-green-700 shadow rounded-5"
          disabled={disabled}
          onClick={handlePay}
        >
          ชำระเงิน
        </button>

        {state && (
          <form
            id="2c2pForm"
            action={state.payment_url}
            method="post"
            className="hidden"
            ref={forRef}
          >
            <input type="hidden" name="amount" value={state.amount} />
            <input type="hidden" name="currency" value={state.currency} />
            <input type="hidden" name="hash_value" value={state.hash_value} />
            <input type="hidden" name="merchant_id" value={state.merchant_id} />
            <input type="hidden" name="order_id" value={state.order_id} />
            <input type="hidden" name="payment_option" value="CC" />
            <input type="hidden" name="payment_description" value={state.payment_description} />
            <input type="hidden" name="result_url_1" value={state.result_url_1} />
            <input type="hidden" name="user_defined_1" value={state.user_defined_1} />
            <input type="hidden" name="user_defined_2" value={state.user_defined_2} />
            <input type="hidden" name="user_defined_3" value={state.user_defined_3} />
            <input type="hidden" name="user_defined_4" value={state.user_defined_4} />
            <input type="hidden" name="version" value={state.version} />
          </form>
        )}
      </div>
    </div>
  );
}
