import { Attachment } from "~src/types/attachment";

export function createAttachmentFromFile(file: any): Attachment {
  return {
    url: URL.createObjectURL(file),
    filename: file.name,
    dataType: file.type,
    // dataType:
    // imageFrom: "local",
  };
}
