import { Box, Dialog, DialogContent, DialogTitle, DialogActions, Button } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "~src/store";
import { closeViewModal, contractViewSelector } from "./contractSlice";
import { unEscapeHtml } from "~src/helpers/helpers";

const ContractViewModal = () => {
  const {
    open,
    data: { version, detail },
  } = useAppSelector(contractViewSelector);
  const dispatch = useAppDispatch();
  return (
    <Dialog open={open} maxWidth={"lg"} onClose={() => dispatch(closeViewModal())}>
      <DialogTitle>Version {version}</DialogTitle>
      <DialogContent>
        <Box
          className="detail-wrapper"
          dangerouslySetInnerHTML={{ __html: unEscapeHtml(detail) }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeViewModal())}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContractViewModal;
