import Product from "~src/models/Product";
import { TravelProductDto } from "~src/types/travel";
import { CoverageDetailsProp } from "~src/types/product";
import { msigCoverageTravel, mtiCoverageTravel } from "~src/helpers/coverageInsurer";

export default class TravelProduct extends Product {
  coverages: {
    hijackingCoverage?: number;
    dismembermentCoverage?: number;
    dismembermentCoverage114?: number;
    dismembermentCoverage1575?: number;
    dismembermentCoverage7680?: number;
    overseasMedicalExpensesCoverage?: number;
    followUpMedicalExpensesCoverage?: number;
    emergencyEvacuationAndRepatriationCoverage?: number;
    mortalRemainRepatriationCoverage?: number;
    tripCancellationPostponementCoverage?: number;
    tripCurtailmentCoverage?: number;
    flightDelayCoverage?: number;
    lossOrDamageBaggageCoverage?: number;
    baggageDelayCoverage?: number;
    personalLiabilityCoverage?: number;
    hospitalVisitationCoverage?: number;
    overseasDailyHospitalConfinementBenefitCoverage?: number;
    damageOrLossMoneyCoverage?: number;
    damageOrLossTravelDocumentCoverage?: number;
    missedConnectingFlightCoverage?: number;
    rentalCarExcessCoverage?: number;
    lossOrDamageLaptopCoverage?: number;
    optional: {
      [key: string]: number;
    };
  };

  constructor(travelProductDto: TravelProductDto) {
    const { coverages } = travelProductDto;

    super(travelProductDto);

    this.coverages = { optional: {} };
    this.coverages.hijackingCoverage = coverages.hijacking_coverage;
    this.coverages.dismembermentCoverage = coverages.dismemberment_coverage;
    this.coverages.overseasMedicalExpensesCoverage = coverages.overseas_medical_expenses_coverage;
    this.coverages.followUpMedicalExpensesCoverage = coverages.follow_up_medical_expenses_coverage;
    this.coverages.mortalRemainRepatriationCoverage = coverages.mortal_remain_repatriation_coverage;
    this.coverages.tripCurtailmentCoverage = coverages.trip_curtailment_coverage;
    this.coverages.flightDelayCoverage = coverages.flight_delay_coverage;
    this.coverages.lossOrDamageBaggageCoverage = coverages.loss_or_damage_baggage_coverage;
    this.coverages.baggageDelayCoverage = coverages.baggage_delay_coverage;
    this.coverages.personalLiabilityCoverage = coverages.personal_liability_coverage;
    this.coverages.hospitalVisitationCoverage = coverages.hospital_visitation_coverage;
    this.coverages.damageOrLossMoneyCoverage = coverages.damage_or_loss_money_coverage;
    this.coverages.missedConnectingFlightCoverage = coverages.missed_connecting_flight_coverage;
    this.coverages.rentalCarExcessCoverage = coverages.rental_car_excess_coverage;
    this.coverages.lossOrDamageLaptopCoverage = coverages.loss_or_damage_laptop_coverage;
    this.coverages.emergencyEvacuationAndRepatriationCoverage =
      coverages.emergency_evacuation_and_repatriation_coverage;
    this.coverages.tripCancellationPostponementCoverage =
      coverages.trip_cancellation_postponement_coverage;
    this.coverages.overseasDailyHospitalConfinementBenefitCoverage =
      coverages.overseas_daily_hospital_confinement_benefit_coverage;
    this.coverages.damageOrLossTravelDocumentCoverage =
      coverages.damage_or_loss_travel_document_coverage;

    // optional coverages
    this.coverages.optional.cashAdvanceCoverage = coverages.optional?.cash_advance_coverage;
    this.coverages.optional.compassionateOverseasVisit =
      coverages.optional?.compassionate_overseas_visit;
    this.coverages.optional.dismembermentCoverage114 =
      coverages.optional?.dismemberment_coverage_1_14;
    this.coverages.optional.dismembermentCoverage1575 =
      coverages.optional?.dismemberment_coverage_15_75;
    this.coverages.optional.dismembermentCoverage7680 =
      coverages.optional?.dismemberment_coverage_76_80;
    this.coverages.optional.dismembermentCoverage175 =
      coverages.optional?.dismemberment_coverage_1_75;
    this.coverages.optional.followUpMedicalExpensesCoverage114 =
      coverages.optional?.follow_up_medical_expenses_coverage_1_14;
    this.coverages.optional.followUpMedicalExpensesCoverage1575 =
      coverages.optional?.follow_up_medical_expenses_coverage_15_75;
    this.coverages.optional.followUpMedicalExpensesCoverage175 =
      coverages.optional?.follow_up_medical_expenses_coverage_1_75;
    this.coverages.optional.followUpMedicalExpensesCoverage7680 =
      coverages.optional?.follow_up_medical_expenses_coverage_76_80;
    this.coverages.optional.holeInOneRewardMaximumPayable =
      coverages.optional?.hole_in_one_reward_maximum_payable;
    this.coverages.optional.lossOrDamageGolf = coverages.optional?.loss_or_damage_golf;
    this.coverages.optional.overseasDailyHospitalConfinementBenefit =
      coverages.optional?.overseas_daily_hospital_confinement_benefit;
    this.coverages.optional.overseasMedicalExpensesCoverage114 =
      coverages.optional?.overseas_medical_expenses_coverage_1_14;
    this.coverages.optional.overseasMedicalExpensesCoverage1575 =
      coverages.optional?.overseas_medical_expenses_coverage_15_75;
    this.coverages.optional.overseasMedicalExpensesCoverage175 =
      coverages.optional?.overseas_medical_expenses_coverage_1_75;
    this.coverages.optional.overseasMedicalExpensesCoverage7680 =
      coverages.optional?.overseas_medical_expenses_coverage_76_80;
    this.coverages.optional.rentalCarExcessMaximumPayable =
      coverages.optional?.rental_car_excess_maximum_payable;
    this.coverages.optional.returnOfChildren = coverages.optional?.return_of_children;
  }

  priceSuffix = (): string => "";

  coverageDetails = (): CoverageDetailsProp[] => {
    let medicalExpensesCoverageDetials;

    switch (this.insurerCode) {
      case "MSIG":
        medicalExpensesCoverageDetials = msigCoverageTravel(this);
        break;
      case "MTI":
        medicalExpensesCoverageDetials = mtiCoverageTravel(this);
        break;
      default:
        medicalExpensesCoverageDetials = this.normalMedicalExpensesCoverageDetials();
        break;
    }

    return [
      ...medicalExpensesCoverageDetials,

      {
        coverageType: "ความคุ้มครองในการเดินทาง",
        details: [
          {
            name: "ความล่าช้าของเที่ยวบิน",
            sumInsured: this.displayValue(this.coverages.flightDelayCoverage),
          },
          {
            name: "การบอกเลิกการเดินทาง",
            sumInsured: this.displayValue(this.coverages.tripCancellationPostponementCoverage),
          },
          {
            name: "การพลาดต่อเที่ยวบิน",
            sumInsured: this.displayValue(this.coverages.missedConnectingFlightCoverage),
          },
          {
            name: "การลดจำนวนวันเดินทาง",
            sumInsured: this.displayValue(this.coverages.tripCurtailmentCoverage),
          },
        ],
      },

      {
        coverageType: "ความคุ้มครองสัมภาระ และทรัพย์สิน",
        details: [
          {
            name: "สัมภาระสูญหาย/เสียหาย",
            sumInsured: this.displayValue(this.coverages.lossOrDamageBaggageCoverage),
          },
          {
            name: "เงินสดถูกขโมย",
            sumInsured: this.displayValue(this.coverages.damageOrLossMoneyCoverage),
          },
          {
            name: "เอกสารเดินทาง",
            sumInsured: this.displayValue(this.coverages.damageOrLossTravelDocumentCoverage),
          },
          {
            name: "คอมพิวเตอร์โน๊ตบุ๊ค",
            sumInsured: this.displayValue(this.coverages.lossOrDamageLaptopCoverage),
          },
          {
            name: "ความล่าช้าของกระเป๋าเดินทาง",
            sumInsured: this.displayValue(this.coverages.baggageDelayCoverage),
          },
        ],
      },

      {
        coverageType: "ความคุ้มครองเพิ่มเติม",
        details: [
          {
            name: "ความเสียหายส่วนแรกของรถเช่า",
            sumInsured: this.displayValue(this.coverages.rentalCarExcessCoverage),
          },
          {
            name: "การจี้เครื่องบิน",
            sumInsured: this.displayValue(this.coverages.hijackingCoverage),
          },
          {
            name: "การไปเยี่ยมผู้เอาประกันภัย",
            sumInsured: this.displayValue(this.coverages.hospitalVisitationCoverage),
          },
          {
            name: "ความรับผิดตามกฎหมายต่อบุคคลภายนอก",
            sumInsured: this.displayValue(this.coverages.personalLiabilityCoverage),
          },
          {
            name: "ผลประโยชน์การขยายระยะเวลาโดยอัตโนมัติ",
            sumInsured: "-",
          },
          {
            name: "ส่งผู้เยาว์กลับประเทศ",
            sumInsured: this.displayValue(this.coverages?.optional?.returnOfChildren),
          },
          {
            name: "ค่าโทรศัพท์ฉุกเฉิน",
            sumInsured: "-",
          },
        ],
      },
    ];
  };

  cardDetails = () => {
    return [
      { ชดเชยกรณีเสียชีวิต: this.coverages.dismembermentCoverage },
      { ค่ารักษาพยาบาล: this.coverages.overseasMedicalExpensesCoverage },
      {
        เคลื่อนย้ายฉุกเฉิน: this.coverages.emergencyEvacuationAndRepatriationCoverage,
      },
      { "สัมภาระสูญหาย/เสียหาย": this.coverages.lossOrDamageBaggageCoverage },
      {
        การบอกเลิกการเดินทาง: this.coverages.tripCancellationPostponementCoverage,
      },
    ];
  };

  private normalMedicalExpensesCoverageDetials = (): CoverageDetailsProp => {
    return {
      coverageType: "ความคุ้มครองค่ารักษาพยาบาล",
      details: [
        {
          name: "ค่ารักษาพยาบาล",
          sumInsured: this.displayValue(this.coverages.overseasMedicalExpensesCoverage),
        },
        {
          name: "ค่ารักษาพยาบาลต่อเนื่องหลังจากกลับไทย",
          sumInsured: this.displayValue(this.coverages.followUpMedicalExpensesCoverage),
        },
        {
          name: "เคลื่อนย้ายฉุกเฉิน",
          sumInsured: this.displayValue(this.coverages.emergencyEvacuationAndRepatriationCoverage),
        },
        {
          name: "ค่าชดเชยรายวัน",
          sumInsured: this.displayValue(
            this.coverages.overseasDailyHospitalConfinementBenefitCoverage
          ),
        },
      ],
    };
  };
}
