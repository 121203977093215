import React, { useState, useEffect } from "react";

import _ from "lodash";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { useAuthenticate } from "~src/helpers/authen";

import {
  _chunk,
  formatNumberWithCurrency,
  formatNumberWithCurrency2,
  parseDateTH,
  parseDateEN,
  renderYaml,
} from "/src/helpers";
import { getInsuredByCategory } from "/src/helpers/helpers";

import TABLE, { THEAD, TBODY, TR, TH, TD } from "/src/components/table";
import SummaryBox from "/src/components/summaryBox";
import Badges from "/src/components/badges";

import SectionHeader from "/src/components/layout/staff/SectionHeader";
import SearchForm from "/src/components/staff/SearchForm";
import ErrorPage from "/src/components/errorPage";

import yml from "/src/status.yml";
import dataYml from "/src/data.yaml";

import useFetchPolicy from "./useFetchPolicy";
import useInput from "/src/hooks/useInput";

import "./policy.scss";

const BASE_URL = "/policies/follow-up";
const PER_PAGE = 10;

const ProductTypeIcon = ({ type }) => {
  switch (type) {
    case "motor":
      return <i className="icon icon-3-Motor" />;
    case "motorbike":
      return <i className="icon icon-3-motorbike" />;
    case "travel":
      return <i className="icon icon-3-travel" />;
    case "fire":
      return <i className="icon icon-3-fire" />;
    case "marine":
      return <i className="icon icon-3-marine" />;
    case "home":
      return <i className="icon icon-3-House" />;
    case "sme":
      return <i className="icon icon-3-SME" />;
    case "iar":
      return <i className="icon icon-3-IAR" />;
    case "golfer":
      return <i className="icon icon-3-golf" />;
    case "engineer":
      return <i className="icon icon-3-engineer" />;
    case "personalaccident":
      return <i className="icon icon-3-PA" />;
    case "health":
      return <i className="icon icon-3-health" />;
    case "cancer":
      return <i className="icon icon-3-cancer" />;
    case "miscellaneous":
      return <i className="icon icon-3-misc" />;
    default:
      return <i className="icon gettmore-icon-policylist" />;
  }
};

const Phone = ({ phoneNumber }) => {
  return (
    <a href={`tel:${phoneNumber}`} className="link-tel">
      <span className="phone">
        <i className="hero-icon-phone" />
        {phoneNumber}
      </span>
    </a>
  );
};

const FollowUp = ({ location }) => {
  const { t } = useTranslation();
  const { showPaidCommission } = useAuthenticate();
  const queryParams = queryString.parse(location.search);
  const keywordParam = queryParams?.keyword ? `&keyword=${queryParams.keyword}` : "";

  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [isError, setIsError] = useState(false);

  const { response, error, isLoading, sumMonthly, sumYearly, amountQuotations, sumCommission } =
    useFetchPolicy(`/api/policies?status=inprogress${keywordParam}`);

  const { value: search_value, bind: handleChaneSearch } = useInput(queryParams?.keyword ?? "");

  useEffect(() => {
    if (!isLoading && !error) {
      const groups = _chunk(response);
      const indexPage = 0;

      setAllData(response);
      setPageCount(groups.length);
      setData(groups?.[indexPage] || []);
      setIsError(false);
    }

    if (error) {
      setIsError(true);
    }
  }, [response, error, isLoading]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const groups = _chunk(allData, PER_PAGE);
    setData(groups?.[selectedPage] || []);
  };

  const handleClickSearch = () => {
    window.location.href = `${BASE_URL}?keyword=${search_value}`;
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") window.location.href = `${BASE_URL}?keyword=${search_value}`;
  };

  return (
    <div className="policy-page">
      {isError ? (
        <ErrorPage />
      ) : (
        <>
          <h6>สรุปยอดขายเบื้องต้น</h6>
          <div className="policy-page-title">
            <div className="title-bottom">
              <span className="gettmore-icon-info mr-1" />
              การคำนวณนับจากยอดขายงานใหม่และงานต่ออายุตั้งแต่วันที่ 20 ส.ค. 2563 เป็นต้นไป
            </div>
          </div>
          <section className="row">
            {!isLoading && (
              <>
                <div className="col-md-3">
                  <SummaryBox
                    label="เบี้ยประกันภัยรวมสะสมของเดือนนี้"
                    value={!isError ? formatNumberWithCurrency(sumMonthly) : null}
                  />
                </div>
                <div className="col-md-3">
                  <SummaryBox
                    label="เบี้ยประกันภัยรวมสะสมของปีนี้"
                    value={!isError ? formatNumberWithCurrency(sumYearly) : null}
                  />
                </div>
                <div className="col-md-3">
                  <SummaryBox
                    label="รายการที่ชำระเงินสำเร็จของเดือนนี้"
                    value={!isError ? `${amountQuotations} ใบ` : null}
                  />
                </div>
                {showPaidCommission && (
                  <div className="col-md-3">
                    <SummaryBox
                      label="ค่าตอบแทนสุทธิสะสมของเดือนนี้"
                      value={!isError ? formatNumberWithCurrency(sumCommission) : null}
                      color="primary"
                    />
                    <div className="remark mt-2">
                      <span>
                        * ค่าตอบแทนสะสมยังไม่รวมรายการที่ยกเลิก/สลักหลัง
                        <br />
                        และชำระตรงที่บริษัทประกัน
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </section>
          <SectionHeader
            title="ติดตามกรมธรรม์"
            breadcrumbs={[{ url: "#", label: "รายการซื้อขาย", current: true }]}
            searchComponent={
              <SearchForm
                type="text"
                placeholder="ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่คำสั่งซื้อ"
                {...handleChaneSearch}
                handleClick={handleClickSearch}
                handleKeyPress={handleKeyPressSearch}
              />
            }
          />
          <div className="policy-page-title">
            <div className="title-bottom">
              <span className="gettmore-icon-info mr-1" />
              รายการกรมธรรม์ทั้งหมดที่ยังไม่ได้รับสำเนาเล่มกรมธรรม์
            </div>
          </div>
          <section>
            <div className="table-grid">
              <div className="grid-th">
                <div>ข้อมูลคำสั่งซื้อ</div>
                <div>ข้อมูลลูกค้า</div>
                <div style={{ textAlign: "center" }}>ประเภทงาน</div>
                <div>บริษัท</div>
                <div style={{ textAlign: "center" }}>แผน</div>
                <div>วันที่คุ้มครอง</div>
                <div style={{ textAlign: "right" }}>ทุนประกัน</div>
                {showPaidCommission && <div style={{ textAlign: "right" }}>ส่วนลดลูกค้า</div>}
                <div style={{ textAlign: "right" }}>เบี้ยประกันที่ชำระ</div>
                {showPaidCommission && <div style={{ textAlign: "right" }}>ค่าตอบแทนสุทธิ</div>}
              </div>
              {isLoading && <div className="grid-tbody-full">กำลังโหลด...</div>}
              {!isLoading && data.length == 0 && <div className="grid-tbody-full">ไม่พบข้อมูล</div>}
              {data.map((obj, index) => {
                const {
                  order_id,
                  order_number,
                  insured_title,
                  insured_firstname,
                  insured_lastname,
                  insured_phone_number,
                  insured_category,
                  company_name,
                  product_type,
                  insurer_code,
                  insurer_icon,
                  policy_type,
                  sum_insured,
                  coverage_from,
                  coverage_to,
                  total_amount,
                  commission_amount,
                  earning_commission,
                  status,
                  plate_number,
                  discount,
                  quotation,
                  contract,
                  remark,
                  order_type,
                } = obj;
                return (
                  <React.Fragment key={index}>
                    <div className="grid-tbody">
                      <div>
                        {obj.product_type === "motor" || obj.product_type === "cmi" ? (
                          <>
                            <ProductTypeIcon type={product_type} />
                            {` `}
                            <a href={`./${order_id}/details`} className="order-detail-link">
                              {`${order_number}`}
                            </a>
                          </>
                        ) : (
                          <>
                            <ProductTypeIcon type={product_type} />
                            {` ${order_number}`}
                          </>
                        )}
                        <Badges status={`policy-${status}`}>
                          {yml.status.policy.agent[status]}
                        </Badges>
                      </div>
                      <div>
                        {getInsuredByCategory(
                          {
                            title: insured_title,
                            firstname: insured_firstname,
                            lastname: insured_lastname,
                            company_name,
                          },
                          insured_category
                        )}
                        <br />
                        {order_type !== "custom" ? (
                          <>
                            {quotation.criteria?.car_brand !== undefined ? (
                              <>
                                {`${quotation.criteria?.car_brand} ${quotation.criteria?.car_model}`}
                              </>
                            ) : (
                              "-"
                            )}
                            <br />
                            {product_type === "motor" || product_type === "motorbike"
                              ? `ทะเบียน ${plate_number}`
                              : ""}
                          </>
                        ) : null}
                      </div>
                      <div style={{ textAlign: "center" }}>{dataYml.order.contract[contract]}</div>
                      <div>
                        <img className="insurer-img" src={insurer_icon} />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {renderYaml(dataYml.product_type[product_type][policy_type])}
                      </div>
                      <div>
                        เริ่ม: {parseDateEN(coverage_from)}
                        <br />
                        ถึง: {parseDateEN(coverage_to)}
                      </div>
                      <div style={{ textAlign: "right" }}>
                        {formatNumberWithCurrency2(sum_insured)}
                      </div>
                      <div style={{ textAlign: "right" }}>{formatNumberWithCurrency(discount)}</div>
                      <div style={{ textAlign: "right" }}>
                        {formatNumberWithCurrency(total_amount)}
                      </div>
                      {showPaidCommission && (
                        <div className="text-primary" style={{ textAlign: "right" }}>
                          {formatNumberWithCurrency(earning_commission)}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </section>
          <div>
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel="Prev"
                nextLabel="Next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="gm-paginate"
                subContainerClassName="pages pagination"
                activeClassName="active"
                disableInitialCallback={true}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FollowUp;
