import React from "react";
import { UseFormMethods, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import classNames from "classnames";
import _find from "lodash/find";
import _get from "lodash/get";
import { Option } from "~/src/types/basic";
import { messages } from "~/src/helpers/messages";

interface Props extends Partial<Pick<UseFormMethods, "errors" | "control">> {
  className?: string;
  id?: string;
  name: string;
  options?: Option<string | number>[];
  isSearchable?: boolean;
  required?: boolean;
  isLoading?: boolean;
  value?: string;
  callbackOnInputChange?: (value: Option<string | number>["value"]) => void;
  callbackOnChange?: (value: Option<string | number>["value"]) => void;
}

const CustomDropdown: React.FC<Props> = ({
  id,
  name,
  className,
  errors,
  control,
  options = [],
  isSearchable = false,
  required = false,
  isLoading = false,
  value,
  callbackOnInputChange,
  callbackOnChange,
}): JSX.Element => {
  const error = _get(errors, name);
  return (
    <>
      <Controller
        id={id}
        name={name}
        rules={required ? messages.required : null}
        control={control}
        render={({ onChange, value }) => {
          const optionValue = _find(options, { value });
          return (
            <ReactSelect
              classNamePrefix="custom-react-select"
              isLoading={isLoading}
              readOnly={isLoading}
              className={classNames("border-none", className)}
              placeholder="กรุณาเลือก"
              isSearchable={isSearchable}
              options={options}
              value={optionValue || value}
              onChange={(selectedOption) => {
                callbackOnChange?.(selectedOption.value);
                onChange(selectedOption.value);
              }}
              onInputChange={(str: string) => {
                callbackOnInputChange?.(str);
              }}
            />
          );
        }}
      />
      {error?.message && <p className="text-xs text-red-600">{error?.message}</p>}
    </>
  );
};

export default CustomDropdown;