import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import QueryString from "query-string";
import { ErrorBoundary } from "react-error-boundary";
import { matchPath, useHistory } from "react-router-dom";
import classnames from "classnames";
import { Edit } from "@mui/icons-material";
import Stepper from "~src/components/stepper/Stepper";
import { reformatDateEN } from "~src/helpers/date";
import { travelStep } from "~src/helpers/options";
import {
  insuredsInfoAtom,
  orderInfoAtom,
  paymentsSummaryInfoAtom,
  quotationInfoAtom,
  quotesInfoAtom,
  taxConsentAtom,
} from "~src/states/orderCheckout";
import { formatNumberWithCurrency } from "~src/helpers";
import TravelProduct from "~src/models/TravelProduct";
import ErrorFallback from "~src/components/Forms/ErrorFallback";
import Loading from "~src/components/loading";
import { OrderInfoCheckout } from "~src/types/order";
import useAxios from "~src/hooks/useAxios";
import SummaryInsured from "~src/components/checkoutForm/SummaryInsured";
import { Group, List } from "~src/pages/agent/checkout/summary/SummaryList";
import { ButtonRadius } from "~src/components/form";
import { getCheckoutSummaryStateFromOrderResponse } from "~src/helpers/checkout";
import InsurancePlanSummary3 from "~/src/components/insurancePlanSummary/insurancePlanSummary3";
import { PublicPaymentSummaryState } from "~src/models/CheckoutState";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  closeCoverageModal,
  coverageModalState,
  fetchProductByIdAction,
  openCoverageModal,
  resetProducts,
  setTravelers,
  travelProductState,
} from "~src/features/travel/redux";
import CommonModal from "~src/components/modal/common";
import CoverageModal from "~src/features/travel/CoverageModal";
import { useAuthenticate } from "~src/helpers/authen";

const Summary: React.FC = (): JSX.Element => {
  const orderId: string = matchPath(window.location.pathname, {
    path: "/public/travels/checkout/:order_id/summary",
    exact: true,
  }).params?.order_id;

  const history = useHistory();
  const { showPaidCommission } = useAuthenticate();
  const { search } = window.location;
  const quotesInfo = useRecoilValue(quotesInfoAtom);
  const quotationInfo = useRecoilValue(quotationInfoAtom);
  const { paymentSummary, lineItemAs } = useRecoilValue(paymentsSummaryInfoAtom);
  const [querires] = useState(QueryString.parse(search));
  const [productObj, setProductObj] = useState<TravelProduct>();
  const [orderInfo, setOrderInfo] = useRecoilState(orderInfoAtom);
  const insuredsInfo = useRecoilValue(insuredsInfoAtom);
  const taxConsent = useRecoilValue(taxConsentAtom);

  const coverageModal = useAppSelector(coverageModalState);
  const product = useAppSelector(travelProductState);
  const dispatch = useAppDispatch();

  const criteriaSummaryInfo = () => {
    const { criteria, short_desc_criteria, additional_information } = quotationInfo;

    return {
      ประเทศปลายทาง: additional_information.destination_name,
      ผู้เดินทาง: `${short_desc_criteria}`,
      วันที่: `${reformatDateEN(criteria?.from_date)} - ${reformatDateEN(criteria?.to_date)}`,
    };
  };

  const priceSummaryInfo = () => {
    let info = {
      "เบี้ยประกันภัยสุทธิ": formatNumberWithCurrency(lineItemAs[0].premium_gross),
      "อากรแสตมป์": formatNumberWithCurrency(lineItemAs[0].premium_stamp),
      "ภาษีมูลค่าเพิ่ม": formatNumberWithCurrency(lineItemAs[0].premium_vat),
      "เบี้ยประกันภัยรวมทั้งสิ้น": formatNumberWithCurrency(lineItemAs[0].selling_price),
      "ส่วนลดลูกค้า": formatNumberWithCurrency(lineItemAs[0].discount),
    };
    if (!showPaidCommission) delete info["ส่วนลดลูกค้า"];
    return info;
  };

  const onSubmit = () => {
    // call ref id

    const state: PublicPaymentSummaryState = {
      orderID: orderId,
      summary: getCheckoutSummaryStateFromOrderResponse(orderInfo),
      quotesInfo,
      quotationInfo,
      paymentSummary,
      lineItemAs,
      criteriaSummary: criteriaSummaryInfo(),
      priceSummary: priceSummaryInfo(),
    };

    history.push({
      pathname: `/public/payments`,
      search: `token=${querires.token}`,
      state,
    });
    // window.location.href = `/public/payments?token=${querires.token}`;
  };

  const { status, data } = useAxios<OrderInfoCheckout>(
    `/api/orders/${orderId}/info?token=${querires.token}`
  );

  useEffect(() => {
    if (product.status === "succeeded") {
      dispatch(openCoverageModal(product.data));
    }
  }, [product]);

  const fetchProduct = () => {
    const traveller = quotationInfo.criteria.traveller;

    dispatch(
      fetchProductByIdAction({ id: quotesInfo[0].product_id, token: querires.token.toString() })
    );
    dispatch(setTravelers(traveller));
  };

  useEffect(() => {
    if (status === "loaded") setOrderInfo(data);
  }, [status]);

  if (status !== "loaded" || !orderInfo) {
    return <Loading />;
  }

  const currentStepCheckout = () => {
    const temp = [...travelStep];
    temp[0].active = false;
    temp[1].active = true;
    return temp;
  };

  const handleCloseCoverageModal = () => {
    dispatch(closeCoverageModal());
    dispatch(resetProducts());
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <CommonModal open={coverageModal.open} onClose={handleCloseCoverageModal}>
        <CoverageModal />
      </CommonModal>
      <div className="motor-info-page">
        <div className="row">
          <div className="col-12">
            <h1>
              <i className="material-icons">lock </i> ยืนยันคำสั่งซื้อ{" "}
            </h1>
            <Stepper steppers={currentStepCheckout()} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            {insuredsInfo &&
              insuredsInfo.map((insure, index) => {
                return (
                  <div
                    className={classnames(
                      "relative bg-white rounded-5 py-4 px-8 shadow-md",
                      index > 0 ? "mt-6" : null
                    )}
                    key={`insures-summary_${insure.id}`}
                  >
                    {index === 0 && (
                      <>
                        <h6>ยืนยันข้อมูล</h6>
                        <p className="text-primary">
                          ** กรุณาตรวจสอบข้อมูลของคุณ ให้ถูกต้อง ก่อนทำธุรกรรมทุกครั้ง
                          เพราะข้อมูลเหล่านี้มีผลต่อ การคุ้มครอง และปรากฎอยู่บนกรมธรรม์
                        </p>
                      </>
                    )}
                    <div className="mt-4">
                      <div className="flex justify-between items-center">
                        <p className="text-primary text-lg">
                          <u>
                            ข้อมูลผู้เอาประกัน {insuredsInfo.length > 1 && `คนที่ ${index + 1}`}
                          </u>
                        </p>
                        <a
                          href={`/public/travels/checkout/${orderId}/step-1?token=${querires.token}`}
                          className="text-base"
                        >
                          <Edit className="mr-1" />
                          แก้ไข
                        </a>
                      </div>
                      <SummaryInsured insures={insure} />
                    </div>
                  </div>
                );
              })}
            <div className="relative bg-white rounded-5 py-4 px-8 shadow-md mt-6">
              <p className="text-primary text-base">
                <u>ระยะเวลาเอาประกันภัย</u>
              </p>
              <Group className="mt-2">
                {Object.keys(criteriaSummaryInfo()).map((key) => (
                  <List label={key} value={criteriaSummaryInfo()[key]} />
                ))}
              </Group>

              <div className="flex justify-between mt-8">
                <ButtonRadius
                  component="link"
                  to={`./step-1?token=${querires.token}`}
                  type="submit"
                >
                  ย้อนกลับ
                </ButtonRadius>
                <ButtonRadius component="button" onClick={onSubmit} type="submit" color="primary">
                  บันทึกและดำเนินการต่อ
                </ButtonRadius>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <InsurancePlanSummary3
              productId={quotesInfo[0].product_id}
              insurerImg={quotesInfo[0].insurer_icon}
              insurerName={quotesInfo[0].insurer_name}
              productName={quotesInfo[0].product_name}
              criteriaSummaryInfo={criteriaSummaryInfo()}
              priceSummaryInfo={priceSummaryInfo()}
              totalSummary={formatNumberWithCurrency(paymentSummary.payment_total_amount)}
              onCallback={fetchProduct}
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Summary;
