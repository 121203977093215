import React from "react";
import { TextField, FormGroup } from "/src/components/form";
import { messages } from "/src/helpers/messages";

const CustomerForm = ({ register, errors }) => {
  return (
    <>
      <FormGroup htmlForLabel="name" label="ชื่อลูกค้า" require>
        <TextField
          type="text"
          name="name"
          id="name"
          inputRef={register({ ...messages.required, ...messages.englishThaiCharacter })}
          error={!!errors.name && errors.name.message}
        />
      </FormGroup>
      <FormGroup htmlForLabel="phone" label="เบอร์โทรลูกค้า" require>
        <TextField
          type="text"
          name="phone"
          id="phone"
          maxLength={10}
          inputRef={register({
            ...messages.required,
            ...messages.mobileNumber,
          })}
          error={!!errors.phone && errors.phone.message}
        />
      </FormGroup>
    </>
  );
};

export default CustomerForm;
