import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "~src/store";
import {
  fetchAcceptPdpa,
  fetchAcceptPdpaWithAnswers,
  fetchAgentPdpa,
  fetchCreatePDPA,
  fetchListPDPA,
  fetchNewAgentPdpa,
  fetchShowPDPA,
} from "./apis";
import { unEscapeHtml } from "~src/helpers/helpers";
import { format } from "date-fns";
import { isEmpty } from "lodash";

const initialState = {
  status: "idle",
  accepted: false,
  data: {
    count: 0,
    content: [],
  },
  error: "" as string | string[],
  form: {
    mode: "create",
    status: "idle",
    data: {
      id: "",
      version: "",
      detail: "",
      effective_date: format(new Date(), "dd/MM/yyyy"),
      pdpa_questions_attributes: [
        {
          id: "",
          question: "",
          _destroy: false,
        },
      ],
    },
    error: "" as string | string[],
  },
  view: {
    open: false,
    status: "idle",
    data: {
      id: "",
      version: "",
      detail: "",
      effective_date: "",
      questions: [],
      answers: [],
    },
  },
};

const pdpaSlice = createSlice({
  name: "pdpa",
  initialState,
  reducers: {
    changeRole: (state, { payload }: PayloadAction<"hero" | "knight">) => {
      // state.form.data.role = payload;
    },
    setDetail: (state, { payload }: PayloadAction<string>) => {
      state.form.data.detail = payload;
    },
    setFormData: (state, { payload }: PayloadAction<{ name: string; value: string }>) => {
      state.form.data = {
        ...state.form.data,
        [payload.name]: payload.value,
      };
    },
    setEffectiveDate: (state, { payload }) => {
      state.form.data.effective_date = format(new Date(payload), "dd/MM/yyyy");
    },
    clearError: (state) => {
      state.form.status = "idle";
      state.form.error = "";
    },
    setQustionValue: (state, { payload }) => {
      const { index, value } = payload;
      state.form.data.pdpa_questions_attributes[index].question = value;
    },
    addNewQuestion: (state) => {
      state.form.data.pdpa_questions_attributes.push({
        id: "",
        question: "",
        _destroy: false,
      });
    },
    deleteQuestion: (state, { payload }) => {
      const { pdpa_questions_attributes } = state.form.data;

      state.form.data.pdpa_questions_attributes[payload] = {
        ...pdpa_questions_attributes[payload],
        _destroy: true,
      };
    },
    openViewModal: (state, { payload }: PayloadAction<any>) => {
      const detail = unEscapeHtml(payload.detail);
      state.view.open = true;
      state.view.data = { ...payload, detail };
    },
    closeViewModal: (state) => {
      state.view.open = false;
    },
    closeAgentViewModal: (state) => {
      let data = {
        id: "",
        version: "",
        detail: "",
        effective_date: "",
        questions: [],
        answers: [],
      };

      state.accepted = true;
      state.view = {
        ...state.view,
        open: false,
        status: "idle",
        data,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchListPDPA.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchListPDPA.fulfilled, (state, { payload }) => {
        state.status = "fulfilled";
        state.data = payload;
      });

    builder
      .addCase(fetchCreatePDPA.pending, (state) => {
        state.form.status = "pending";
      })
      .addCase(fetchCreatePDPA.fulfilled, (state) => {
        state.form.status = "fulfilled";
      })
      .addCase(fetchCreatePDPA.rejected, (state, { payload }) => {
        state.form.status = "rejected";
        state.form.error = payload.message;
      });

    builder
      .addCase(fetchShowPDPA.pending, (state) => {
        state.form.status = "pending";
      })
      .addCase(fetchShowPDPA.fulfilled, (state, { payload }) => {
        const data = { ...payload.content };
        const [day, month, year] = data.effective_date.split("-");
        const formattedDate = `${day}/${month}/${year}`;

        data.detail = unEscapeHtml(data.detail);
        data.effective_date = formattedDate;
        data.pdpa_questions_attributes = data.questions;

        state.form = {
          ...state.form,
          mode: "edit",
          status: "fulfilled",
          data,
        };
      });

    builder
      .addCase(fetchAgentPdpa.pending, (state) => {
        state.view.status = "pending";
      })
      .addCase(fetchAgentPdpa.fulfilled, (state, { payload }) => {
        let accepted = true;
        let data = {
          id: "",
          version: "",
          detail: "",
          effective_date: "",
          questions: [],
          answers: [],
        };
        let open = false;
        if (!isEmpty(payload.content)) {
          accepted = false;
          data = { ...payload.content };
          data.detail = unEscapeHtml(data.detail);
          open = true;
        }
        state.accepted = accepted;
        state.view = {
          ...state.view,
          open,
          status: "fulfilled",
          data,
        };
      });

    builder
      .addCase(fetchNewAgentPdpa.pending, (state) => {
        state.view.status = "pending";
      })
      .addCase(fetchNewAgentPdpa.fulfilled, (state, { payload }) => {
        let accepted = true;
        let data = {
          id: "",
          version: "",
          detail: "",
          effective_date: "",
          questions: [],
          answers: [],
        };
        let open = false;
        if (!isEmpty(payload.content)) {
          accepted = false;
          data = { ...payload.content };
          data.detail = unEscapeHtml(data.detail);
          open = true;
        }
        state.accepted = accepted;
        state.view = {
          ...state.view,
          open,
          status: "fulfilled",
          data,
        };
      });

    builder.addCase(fetchAcceptPdpa.fulfilled, (state) => {
      let data = {
        id: "",
        version: "",
        detail: "",
        effective_date: "",
        role: "hero",
        questions: [],
        answers: [],
      };

      state.accepted = true;
      state.view = {
        ...state.view,
        open: false,
        status: "idle",
        data,
      };
    });

    builder.addCase(fetchAcceptPdpaWithAnswers.fulfilled, (state, { payload }) => {
      let accepted = true;
      let data = {
        id: "",
        version: "",
        detail: "",
        effective_date: "",
        questions: [],
        answers: [],
      };
      let open = false;
      if (!isEmpty(payload.content)) {
        accepted = false;
        data = { ...payload.content };
        data.detail = unEscapeHtml(data.detail);
        open = true;
      }
      state.accepted = accepted;
      state.view = {
        ...state.view,
        open,
        status: "fulfilled",
        data,
      };
    });
  },
});

export const {
  changeRole,
  setDetail,
  setFormData,
  setEffectiveDate,
  setQustionValue,
  clearError,
  openViewModal,
  closeViewModal,
  addNewQuestion,
  deleteQuestion,
  closeAgentViewModal,
} = pdpaSlice.actions;

export const pdpaStatusSelector = (state: RootState) => state.pdpa.status;
export const pdpaDataSelector = (state: RootState) => state.pdpa.data;
export const padaFormSelector = (state: RootState) => state.pdpa.form;
export const pdpaViewSelector = (state: RootState) => state.pdpa.view;
export const pdpaQuestionsSelector = (state: RootState) =>
  state.pdpa.form.data.pdpa_questions_attributes;
export const pdpaAcceptedSelector = (state: RootState) => state.pdpa.accepted;

export default pdpaSlice.reducer;
