import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import dictionary from "~src/data.yaml";
import kbankCCPaymentsData from "~src/data/kbank_cc_payments.yaml";
import ErrorGroup from "~src/models/KBankPaymentErrorGroup";
import TransactionState from "~src/models/KBankPaymentTransactionState";

import "./index.scss";
import "./creditCardAlert.scss";

export interface CreditCardAlertProps extends React.ComponentPropsWithoutRef<"div"> {
  code?: string;
  icon?: string;
  infoLines?: ErrorInfoLineData[];
  isCardAuthError?: boolean;
  isCardInfoError?: boolean;
  isInsufficientFundsError?: boolean;
  isPending?: boolean;
  isTempError?: boolean;
  messages?: string[];
  title?: string;
  transactionNumber?: string;
  transactionState?: string;
  renderActions?: () => React.ReactNode;
}

export default function CreditCardAlert({
  code,
  icon = "highlight_off",
  infoLines,
  isCardAuthError,
  isCardInfoError,
  isInsufficientFundsError,
  isPending,
  isTempError,
  messages = [],
  title = dictionary.payment.payment_error,
  transactionNumber,
  transactionState,
  className = "",
  renderActions,
  children,
  ...htmlProps
}: React.PropsWithChildren<CreditCardAlertProps>) {
  const { t } = useTranslation();

  const codeToErrorGroupsMap = useMemo(() => {
    const m = {};
    Object.entries(kbankCCPaymentsData.kbank_cc_error_groups).forEach(
      ([name, codes]: [string, string[]]) => {
        // TEST
        // console.log(`CreditCardAlert: kbankCCPaymentsData.kbank_cc_error_groups[${name}]:`, codes);

        // WORKAROUND: YAML List entry was parsed as number, although wrapped in quotes
        codes.forEach((c) => (m[c] = name));
      }
    );
    // TEST
    console.log("CreditCardAlert: codeToErrorGroupsMap:", m);

    return m;
  }, [kbankCCPaymentsData.kbank_cc_error_groups]);

  const errorGroup = useMemo(() => codeToErrorGroupsMap[code], [codeToErrorGroupsMap, code]);

  const hintLines = useMemo(() => {
    // TEST
    console.log(
      `CreditCardAlert: errorGroup: ${errorGroup}, transactionState: ${transactionState}`
    );

    if (isInsufficientFundsError || errorGroup === ErrorGroup.InsufficientFunds) {
      return [
        dictionary.payment.insufficient_funds,
        dictionary.payment.please_change_card_and_try_again,
      ];
    }
    if (isTempError || errorGroup === ErrorGroup.Temporary) {
      return [dictionary.payment.please_try_again];
    }
    if (isCardInfoError || errorGroup === ErrorGroup.CardInfo) {
      return [dictionary.payment.please_check_card_info];
    }
    if (isCardAuthError || errorGroup === ErrorGroup.CardAuth) {
      return [dictionary.payment.please_contact_card_issuer];
    }
    if (
      isPending ||
      errorGroup === ErrorGroup.Pending ||
      transactionState === TransactionState.PreAuth
    ) {
      return [dictionary.payment.pending, dictionary.payment.please_try_again];
    }

    return [dictionary.payment.please_try_again];
  }, [code, errorGroup, isCardAuthError, isCardInfoError, isPending, isTempError]);

  return (
    <div {...htmlProps} className={`credit-card-error error ${className}`}>
      <div className="error-header flex flex-col w-full justify-center">
        <h1 className="flex flex-row justify-center text-center gap-2 mb-8">
          <i className="error-icon material-icons text-red-500">{icon}</i> {title}
        </h1>
      </div>
      <div className="error-details flex flex-col w-full">
        {messages &&
          messages.map((msg, idx) => (
            <input key={`msg_${idx}`} type="hidden" name="msg" value={msg} />
          ))}
        {infoLines && (
          <ul className={`info-lines flex flex-col w-full items-stretch mb-2 ${className}`}>
            {infoLines.map((lineData) => (
              <ErrorInfoLine key={lineData.key} data={lineData} />
            ))}
          </ul>
        )}
        <ul className="hint-lines flex flex-col w-full justify-center text-center items-stretch gap-3 mb-2">
          {hintLines.map((line) => (
            <li key={line}>{line}</li>
          ))}
        </ul>
        {children}
      </div>
      <div className="error-footer flex flex-col w-full justify-center text-center items-stretch gap-3">
        <hr />
        <p className="flex flex-row justify-center text-gray-400">
          หากต้องการความช่วยเหลือ สามารถติดต่อได้ที่ Line: {t("lineAccount")} หรือ{" "}
          {t("contactMail")}
        </p>
        {renderActions && renderActions()}
      </div>
    </div>
  );
}

export interface ErrorInfoLineData {
  key: string;
  title?: string;
  value?: any;
}

export interface ErrorInfoLineProps {
  data: ErrorInfoLineData;
}

export function ErrorInfoLine({ data }: ErrorInfoLineProps) {
  const { key, title, value } = data;

  return (
    <li className="flex flex-row w-full gap-x-1">
      <input type="hidden" name={key} value={value} />
      <span className="list-key flex w-1/2 justify-end text-right">
        {title || dictionary.payment[key]} :
      </span>
      <span className="list-value flex w-1/2 justify-start text-left">{value || "-"}</span>
    </li>
  );
}
