import Decimal from "decimal.js";
import { addMonths, Duration } from "date-fns";
import _some from "lodash/some";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { UploadPayslipPageState } from "../../checkout/orders/UploadPayslip";
import { Button, Checkbox, DatePickerField, FormGroup } from "~src/components/form";
import InstallmentCard from "~src/components/card/InstallmentCard";
import { PaymentsAlertModalProps } from "~src/components/modal/payments/PaymentsAlertModal";
import InstallmentTable from "~src/components/table/InstallmentTable";

import dictionary from "~src/data.yaml";
import { useAgentOrdersAPI } from "~src/api/agent/Orders";
import { useAppContext } from "~src/context/App";
import { usePageContext } from "~src/context/Page";
import { parseDate, toISODate } from "~src/helpers/date";
import { isScrollBottomEnd } from "~src/helpers/helpers";
import { messages } from "~src/helpers/messages";
import { activeStatusOptions } from "~src/helpers/options";
import { CheckoutSummaryState } from "~src/models/CheckoutState";
import { Payment } from "~src/types/payment";
import { Order } from "~src/types/order";

import NotiQR from "~assets/noti-qrcode.png";

import NotiQR from "~assets/noti-qrcode.png";

// import KBankIcon from "~assets/payments/kbank-logo.png";

export interface InstallmentProps {
  orderId: number;
  order: Order;
  summary: CheckoutSummaryState;
  disabled?: boolean;
  onActiveInstallmentCardChanged?: (idx: number) => void;
}

export default function Installment({
  orderId,
  order,
  disabled,
  summary,
  onActiveInstallmentCardChanged,
}: InstallmentProps) {
  const history = useHistory();

  const { appConfig, assets } = useAppContext();
  const KBankIcon = assets.payments.kbankLogo;

  const { setAlert, setPreloading } = usePageContext();
  const { ordersAPI } = useAgentOrdersAPI({
    onError: setAlert,
  });
  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const { installmentInfo } = summary;
  const amount = summary.sellingPrice;
  const startDate = new Date();

  const [disableAcceptCheckBox, setDisableAcceptCheckbox] = useState(false);
  const [acceptCondition, setAcceptCondition] = useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const notActiveAllInstallmentPlan = !_some(installmentInfo, "is_active");

  const [formElement, setFormElement] = useState(undefined);
  // const [startDate, setStartDate] = useState(new Date());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [activeInstallmentPlan, installmentTableData] = useMemo(() => {
    const _activePlan = installmentInfo[activeCardIndex];

    const termsStr = _activePlan?.payment_count;
    const decimalValues = {
      totalAmount: new Decimal(_activePlan?.total_payment ?? 0),
      commissionAmount: new Decimal(
        installmentInfo[activeCardIndex]?.reduced_commission_amount ?? 0
      ),
      discountAmount: new Decimal(installmentInfo[activeCardIndex]?.discount_amount ?? 0),
    };
    // TODO: Generate Payment Terms preview from backend?
    const totalTerms = termsStr ? Number(termsStr) : 1;

    const payments = [];
    let nextDate = startDate;
    for (let seqNo = 1; seqNo <= totalTerms; seqNo++) {
      const nextAmt =
        seqNo <= 1
          ? _activePlan?.first_month?.payment_amount
          : _activePlan?.other_month?.payment_amount;

      payments.push({
        // status: undefined,
        payment_count: seqNo,
        installment_seq_no: seqNo,
        installment_amount: nextAmt, // ? new Decimal(nextAmt) : new Decimal(0),
        installment_due_at: nextDate,
        paid_amount: new Decimal(0),
      });
      nextDate = addMonths(nextDate, 1);
    }

    return [
      _activePlan,
      {
        ...decimalValues,
        firstPaymentAmount: _activePlan?.first_month?.payment_amount,
        otherPaymentAmount: _activePlan?.other_month?.payment_amount,
        totalTerms,
        paidTerms: 0,
        payments,
        startDate,
        isStatusHidden: true,
      },
    ];
  }, [installmentInfo, activeCardIndex, startDate]);

  const InstallmentCards = useCallback(() => {
    return (
      <>
        {installmentInfo.map((info, index) => (
          <InstallmentCard
            key={`installmentCard-${index}`}
            cardIndex={index}
            activeCardIndex={activeCardIndex}
            setActiveCardIndex={onSetActiveCardIndex}
            paymentCount={info.payment_count}
            paymentAmount={info.other_month?.payment_amount}
            minPaymentAmount={info.min_payment}
            isActive={info.is_active && info.first_month?.is_active && info?.other_month.is_active}
          />
        ))}
      </>
    );
  }, [installmentInfo, acceptCondition, activeCardIndex]);

  const ConditionText = () => {
    return (
      <>
        <p className="text-left text-lg mb-2">
          <strong> เงื่อนไขการผ่อนชำระ </strong>
        </p>
        <p>{`1. สามารถเลือกผ่อนเงินสดได้ 3 รูปแบบ คือ 3 เดือน 6 เดือน และ 10 เดือน `}</p>
        <p>{`2. รายการคำสั่งซื้อที่สามารถเลือกชำระโดยการผ่อนเงินสดได้นั้น จะต้องเป็นไปตามเงื่อนไขดังนี้ `}</p>
        <p className="ml-4">{`a. ประกันรถยนต์ภาคสมัครใจเท่านั้น ไม่นับรวม พรบ. (พรบ. จะต้องชำระเต็มจำนวน) `}</p>
        <p className="ml-4">{`b. ประกันรถยนต์ภาคสมัครใจของ 5 บริษัท ประกอบด้วย MTI TNI VIR TMSTH MSIG `}</p>
        <p className="ml-4">{`c. ยอดชำระต่องวดขั้นต่ำ เมื่อคำนวนแล้ว จะต้องไม่น้อยกว่า 1,000 บาท `}</p>
        <p>{`3. การชำระเบี้ยประกัน จะแบ่งเป็นการชำระงวดแรก 25% ของเบี้ยประกันรวม และส่วนที่เหลือจะแบ่งชำระโดยการหารตามจำนวนงวดที่เหลือ `}</p>
        <p>{`4. สมาชิกจะต้องอัพโหลดหรือส่งสำเนาบัตรประชาชนลูกค้า พร้อมระบุข้อความ... `}</p>
        <p>{`5. ลูกค้าจะต้องตอบรับการติดต่อจากเจ้าหน้าที่ของบริษัท เพื่อยืนยันความประสงค์ในการผ่อนเงินสด ซึ่งหากไม่สามารถติดต่อได้ บริษัทจำเป็นที่จะต้องยกเลิกรายการคำสั่งซื้อดังกล่าว `}</p>
        <p>{`6. กรณีที่เกินกำหนดชำระ 7 วัน บริษัทจำเป็นจะต้องแจ้งยกเลิกกรมธรรม์ทันที โดยจะส่ง SMS แจ้งให้สมาชิกและลูกค้าทราบ `}</p>
        <p>{`7. รายการคำสั่งซื้อที่เป็นการผ่อนเงินสด สมาชิกจะถูกปรับลดผลตอบแทนจากการขาย ดังนี้ `}</p>
        <p className="ml-4">{`a. กรณีเลือกผ่อนเงินสด 3 เดือน ถูกปรับลด 2% `}</p>
        <p className="ml-4">{`b. กรณีเลือกผ่อนเงินสด 6 เดือน ถูกปรับลด 4% `}</p>
        <p className="ml-4">{`c. กรณีเลือกผ่อนเงินสด 10 เดือน ถูกปรับลด 8% `}</p>
        <p>{`8. รายการคำสั่งซื้อที่เป็นการผ่อนเงินสด สมาชิกจะได้รับผลตอบแทนจากการขายก็ต่อเมื่อ `}</p>
        <p className="ml-4">{`a. กรณีเลือกผ่อนเงินสด 3 เดือน จะได้รับผลตอบแทนจากการขายเมื่อมีการชำระเบี้ยประกันครบ 3 งวด `}</p>
        <p className="ml-4">{`b. กรณีเลือกผ่อนเงินสด 6 เดือน หรือ 10 เดือน   จะได้รับผลตอบแทนจากการขายเมื่อมีการชำระเบี้ยประกันงวดที่ 4 `}</p>
        <p>{`9. กรณีที่มีการยกเลิกกรมธรรม์โดยที่ยังชำระเบี้ยประกันไม่ครบ บริษัทจำเป็นจะต้องเรียกคืนผลตอบแทนจากการขายรายการนั้น โดยอาจเป็นการหักผลตอบแทนในรอบถัดไป หรือแจ้งขอให้ชำระคืนเข้ามา แล้วแต่กรณี `}</p>
        <p>
          10. กรณีที่สมาชิกท่านใดมีรายการค้างจ่าย 3 รายการ บริษัทขอสงวนสิทธิในการรับงานผ่อนเงินสด
          จนกว่าจะได้รับชำระค่างวดรายการที่ค้างจ่ายอยู่จนครบ
        </p>
        <p>11. กรณีวันกำหนดชำระเป็นวันที่ 29-31 ให้ชำระ ณ ​วันสิ้นเดือน ของเดือนนั้น ๆ</p>
      </>
    );
  };

  const onSetActiveCardIndex = (index) => {
    setActiveCardIndex(index);
    if (onActiveInstallmentCardChanged) onActiveInstallmentCardChanged(index);
  };

  // function handleChangeStartDate(newVal) {
  //   setStartDate(parseDate(newVal));
  // }

  const gotoCheckoutSummary = () => {
    history.push({
      pathname: `/checkout/${orderId}/summary`,
      state: {
        orderId,
      },
    });
  };

  // DEPRECATED: Remove this after deploying Installment+Payment Link feature
  const gotoUploadPayslip = (payment?: Payment) => {
    const installment = installmentInfo[activeCardIndex];

    const state: UploadPayslipPageState = {
      orderId,
      summary,
      paymentMethod: "installment",
      totalAmount: installment.first_month.payment_amount,
      payment,
      installment,
      startDate,
    };

    history.push({
      pathname: `/checkout/orders/${orderId}/upload-payslip`,
      state,
    });
  };

  function handleCreateInstallment() {
    if (disabled) {
      return;
    }

    const data = new FormData(formElement);
    data.append("payment[payment_method]", "installment");
    data.append("payment[payment_count]", `${activeInstallmentPlan?.payment_count}`);

    setAlert(undefined);
    setPreloading(true);
    setIsSubmitting(true);
    (async function () {
      try {
        const respData = await ordersAPI.createInstallment(orderId, data);
        // TEST
        console.log("createInstallment Response:", respData);

        // TODO: Permanently enable this after deploying Installment+Payment Link feature
        if (appConfig.paymentLinksEnabled) {
          history.push(`/checkout/orders/${orderId}/first-payment`);
        } else {
          gotoUploadPayslip(respData.content?.[0]);
        }
        // history.push(`/orders/${orderId}/details`);
      } catch (err) {
        setAlert(err.message, err.details ? { create_installment: err.details } : undefined);
        setIsSubmitting(false);
        setPreloading(false);
      }
    })();
  }

  const handleScroll = ({ target }) => {
    if (disableAcceptCheckBox && isScrollBottomEnd(target)) setDisableAcceptCheckbox(false);
  };

  return (
    <>
      <div className="payment-methods-wrap">
        <div className="max-h-72 overflow-y-auto font-thin">
          <ConditionText />
        </div>

        <div className="flex justify-center py-4">
          <Checkbox
            name="accept_condition"
            id="accept_condition_installment"
            label="Check for Accept condition / ยอมรับเงื่อนไขการผ่อนชำระ"
            disabled={disabled || disableAcceptCheckBox}
            checked={acceptCondition}
            onChange={() => setAcceptCondition(!acceptCondition)}
          />
        </div>

        {acceptCondition && (
          <div className="flex flex-wrap mt-4 justify-center animated fadeIn">
            <InstallmentCards />
          </div>
        )}

        {acceptCondition && notActiveAllInstallmentPlan && (
          <div className="flex flex-wrap justify-center my-4 text-xs text-red-500">
            <strong>
              รายการคำสั่งซื้อนี้ ไม่อยู่ในเงื่อนไขการผ่อนเงินสด
              กรุณาตรวจสอบรายละเอียดและทำรายการใหม่อีกครั้ง
            </strong>
          </div>
        )}

        {acceptCondition && activeInstallmentPlan && (
          <>
            <div className="flex flex-wrap justify-center my-4">
              <InstallmentTable {...installmentTableData} className="w-10/12" />
            </div>
            <div className="installment-wrap payment-methods-info w-6/12 mt-8">
              <form id="cashInstallment" ref={setFormElement}>
                <input
                  type="hidden"
                  name="payment[payment_count]"
                  value={activeInstallmentPlan?.payment_count}
                />
                {/* <FormGroup label="วันกำหนดชำระงวดแรก" htmlForLabel="start_date" require>
                <DatePickerField
                  type="text"
                  id="start_date"
                  name="start_date"
                  defaultView="month"
                  onChange={(evt) => handleChangeStartDate(evt.currentTarget.value)}
                  callbackvalue={handleChangeStartDate}
                  inputRef={register({
                    ...messages.required,
                    ...messages.validateCoverageDate,
                  })}
                  value={startDate ? toISODate(startDate) : ""}
                  error={errors.order?.details?.cover_start_date?.message}
                />
              </FormGroup> */}
              </form>
              {/* DEPRECATED: Remove this after deploying Installment+Payment Link feature */}
              {!appConfig.paymentLinksEnabled && (
                <div className="banktransfer-grid py-2">
                  <div className="mr-4">
                    <img className="bank-logo" src={KBankIcon} alt="KBank account for Gettmore" />
                  </div>
                  <div className="flex flex-wrap">
                    <p>
                      <strong className="bank-account-label-account-number">057-3-399-099</strong>{" "}
                    </p>
                    <p className="bank-account-label-company w-full text-xs font-light">
                      บจก.เมืองไทย โบรกเกอร์ (กระแสรายวัน)
                    </p>
                    <p className="mb-4 bank-account-label-kbank text-xs font-light">
                      ธนาคารกสิกรไทย สาขาถนนรัชดาภิเษก-ห้วยขวาง
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <div className="flex justify-center my-2">
          <img src={NotiQR} width={500} />
        </div>
      </div>
      <div className="row">
        <div className="motor-info-page-actions space-between w-full">
          <Button type="button" role="button" onClick={(e) => gotoCheckoutSummary()}>
            ย้อนกลับ
          </Button>
          {/* DEPRECATED: Remove false clause after deploying Installment+Payment Link feature */}
          {appConfig.paymentLinksEnabled ? (
            <Button
              type="button"
              color="primary"
              onClick={(e) => handleCreateInstallment()}
              disabled={disabled || !activeInstallmentPlan || !formElement || isSubmitting}
            >
              {dictionary.checkout.to_payment}
            </Button>
          ) : (
            <Button
              type="button"
              color="primary"
              role="button"
              onClick={(e) => handleCreateInstallment()}
              disabled={disabled || !activeInstallmentPlan || !formElement || isSubmitting}
            >
              {dictionary.checkout.to_upload_payslip}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
