import React, { useState, useEffect, useContext } from "react";

import filterContext from "./store.js";

import Select from "../../../../components/filter/Select";

const optionsLists = [
  {
    label: "ชั้น 1",
    value: "1",
    isChecked: false,
  },
  {
    label: "ชั้น 2+",
    value: "2+",
    isChecked: false,
  },
  {
    label: "ชั้น 3+",
    value: "3+",
    isChecked: false,
  },
  {
    label: "ชั้น 3",
    value: "3",
    isChecked: false,
  },
];

const PolicyTypeFilter = ({ fnCallbackFilterValues, active }) => {
  const [options, setOptions] = useState(optionsLists);
  const [state, dispatch] = useContext(filterContext);
  const [activeButton] = useState(active);

  useEffect(() => {
    const opts = [...options];
    state.filterPolicyTypes.map((value) => {
      opts.filter((o) => {
        if (o.value === value) o.isChecked = true;
      });
      setOptions(opts);
    });
  }, [state]);

  const handleChange = (isChecked, value) => {
    const opts = [...options];
    let filterPolicyTypes = [];

    opts.filter((o) => {
      if (o.value === value) {
        o.isChecked = !isChecked;
      }
      if (o.isChecked) filterPolicyTypes.push(o.value);
    });

    setOptions(opts);
    dispatch({
      type: "UPDATE",
      payload: {
        ...state,
        filterPolicyTypes,
      },
    });

    fnCallbackFilterValues("policyType", filterPolicyTypes);
  };
  return (
    <div>
      <Select
        type="checkbox"
        label={
          state.filterPolicyTypes.length > 0 ? (
            <>
              ชั้น
              <span className="text-primary inline-block ml-1">
                {state.filterPolicyTypes.join(", ")}
              </span>
            </>
          ) : (
            "ไม่ระบุประเภท"
          )
        }
        options={options}
        active={activeButton}
        onChangeValue={handleChange}
      />
    </div>
  );
};

export default PolicyTypeFilter;
