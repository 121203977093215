import { parse, isValid, format, formatISO, formatDistanceToNowStrict, getYear } from "date-fns";
import th from "date-fns/locale/th";

export const BUDDHIST_YEARS_OFFSET = 543;
export const FIRST_DATE_NEW_YEAR = new Date(new Date().getFullYear() + 1, 0, 1);
export const ISO_DATE_FORMAT = "yyyy-MM-dd";
export const ISO_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss[.SSS][XXX]";

export type Format = "dd/MM/yyyy" | typeof ISO_DATE_FORMAT | typeof ISO_DATETIME_FORMAT;

export const isLastMonth = new Date().getMonth() + 1 >= 11;

export function reformatDateTH(
  date: string | Date | null | undefined,
  outFormat: string = "dd MMM yyyy",
  inFormat: string = ISO_DATE_FORMAT
): string {
  const date2 = parseDate(date, inFormat);
  if (!date2) {
    return "-";
  }

  const year = getYear(date2) + BUDDHIST_YEARS_OFFSET;

  return format(date2, outFormat.replace("yyyy", `${year}`), {
    locale: th,
  });
}

export function reformatDateEN(
  date: string | Date | null | undefined,
  outFormat: string = "dd/MM/yyyy",
  inFormat: string = ISO_DATE_FORMAT
): string {
  const date2 = parseDate(date, inFormat);
  if (!date2) {
    return "-";
  }

  return format(date2, outFormat);
}

export function reformatDateTimeEN(
  date: string | Date | null | undefined,
  outFormat: string = "dd/MM/yyyy HH:mm:ss",
  inFormat: string = ISO_DATETIME_FORMAT
): string {
  const date2 = parseDate(date, inFormat);
  if (!date2) {
    return "-";
  }

  return format(date2, outFormat);
}

export function parseDate(
  date: string | Date | null | undefined,
  dateFormat: string = ISO_DATE_FORMAT
): Date {
  if (!date) {
    return undefined;
  }

  const date2 = date instanceof Date ? date : parse(date, dateFormat, new Date());

  if (!isValid(date2)) {
    return undefined;
  }

  return date2;
}

export function parseDateTime(
  date: string | Date | null | undefined,
  dateFormat: string = ISO_DATETIME_FORMAT
): Date {
  if (!date) {
    return undefined;
  }

  if (date instanceof Date) {
    return date as Date;
  }

  const date2 =
    dateFormat === ISO_DATETIME_FORMAT ? new Date(date) : parse(date, dateFormat, new Date());

  if (!isValid(date2)) {
    return undefined;
  }

  return date2;
}

export function toISODate(
  date: string | Date | null | undefined,
  dateFormat: Format = "yyyy-MM-dd"
): string | undefined {
  if (!date) {
    return undefined;
  }

  const date2 = date instanceof Date ? date : parse(date, dateFormat, new Date());

  return formatISO(date2, { representation: "date" });
}

export function toISODateTime(
  date: string | Date | null | undefined,
  dateFormat: Format = "yyyy-MM-dd"
): string | undefined {
  if (!date) {
    return undefined;
  }

  const date2 = date instanceof Date ? date : parse(date, dateFormat, new Date());

  return date2.toISOString();
}

export const differenceDays = (dateLeft: string): string | null => {
  if (isValid(new Date(dateLeft))) {
    return formatDistanceToNowStrict(new Date(dateLeft), { locale: th });
  } else {
    return null;
  }
};
