// import { createConsumer } from "@rails/actioncable";
// import Cookies from "js-cookie";
import _isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";

import QRPayment from "~src/components/payments/QRPayment";
import Stepper from "~/src/components/stepper/Stepper";

import { PageContextProvider, usePageContext } from "~src/context/Page";

import "./payments.scss";
import { GetLinkInfoResponse, usePaymentsAPI } from "~src/api/Payments";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import Decimal from "decimal.js";

let consumer;

export interface PaymentLinkProps {
  location: Location;
}

export default function PaymentLink(props) {
  return (
    <PageContextProvider loginPath="" mainPagePath="">
      <PaymentLinkInner {...props} />
    </PageContextProvider>
  );
}

interface PaymentLinkInnerInnerProps extends PaymentLinkProps { }

function PaymentLinkInner({ location }: PaymentLinkInnerInnerProps) {
  const { t } = useTranslation();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });
  const token = queryParams["token"];

  const { onError, setAlert, setPreloading } = usePageContext();
  const { paymentsAPI } = usePaymentsAPI({
    onError: setAlert,
  });

  const [isLinkLoading, setIsLinkLoading] = useState(true);
  const [linkInfo, setLinkInfo] = useState<GetLinkInfoResponse | undefined>();
  const [amount, setAmount] = useState<Decimal | undefined>();

  useDeepCompareEffectNoCheck(() => {
    if (!token || !paymentsAPI) {
      return;
    }

    (async function () {
      try {
        setAlert(undefined);
        setIsLinkLoading(true);
        const respData = await paymentsAPI.getLinkInfo(`${token}`);
        unstable_batchedUpdates(() => {
          setIsLinkLoading(false);
          setLinkInfo(respData?.content);
        });
      } catch (err) {
        console.error(err);
        setIsLinkLoading(false);
        onError(err);
      }
    })();
  }, [token, paymentsAPI]);

  useDeepCompareEffectNoCheck(() => {
    setPreloading(isLinkLoading);

    if (isLinkLoading || !linkInfo) {
      return;
    }

    const _amount = linkInfo.payment
      ? linkInfo.payment.status === "paid"
        ? new Decimal(linkInfo.payment.paid_amount)
        : new Decimal(linkInfo.payment.required_amount)
      : new Decimal(linkInfo.order.payments_summary?.payment_total_amount);
    setAmount(_amount);
  }, [isLinkLoading, linkInfo]);

  // const [state, setState] = useState({
  //   currentTab: defaultCurrentTab,
  //   starCountDown: false,
  //   qrId: "",
  //   qrDisplay: false,
  //   qrImage: "",
  //   countdownTime: new Date().getTime(),
  //   intervalId: null,
  //   minutes: 5,
  //   seconds: "00",
  // });

  const totalAmount = linkInfo?.order?.payments_summary
    ? new Decimal(linkInfo.order.payments_summary.payment_total_amount)
    : new Decimal(Number.NaN);

  return (
    <div className="payment-wrapper">
      <div className="flex flex-col justify-center">
        <h1 className="flex flex-row justify-center text-center">
          <i className="material-icons">qr_code</i>ชำระเบี้ยประกันภัย
        </h1>
      </div>
      <div className="flex gap-3 tablet:flex-col laptop:flex-col justify-center items-stretch">
        <div className="flex flex-col w-full md:w-3/6">
          {!isLinkLoading && linkInfo && amount && (
            <QRPayment
              orderID={linkInfo.order.order_id || linkInfo.metadata.order_id}
              amount={amount}
              totalAmount={totalAmount}
              isInstallments={linkInfo.order?.payments_summary?.is_installments}
              installmentTerms={linkInfo.order?.payments_summary?.installment_terms}
              installmentPaidTerms={linkInfo.order?.payments_summary?.installment_paid_terms}
              payment={linkInfo.payment}
              order={linkInfo.order}
              previousQRInfo={linkInfo.qr}
              paymentsAPI={paymentsAPI}
              onError={(err) => setAlert(err.message)}
              className="flex-1"
            />
          )}
        </div>
      </div>
    </div>
  );
}
