import React, { useState, BaseSyntheticEvent, useEffect } from "react";
import queryString from "query-string";
import { useForm } from "react-hook-form";

import { IconButton } from "@material-ui/core";
import { Close } from "@mui/icons-material";

import { FormGroup, TextField, SelectField } from "~/src/components/form";
import useFetch from "~/src/hooks/useFetch";
import { urlStringify } from "~/src/helpers/helpers";
import { messages } from "~/src/helpers/messages";
import PartnerInfo from "~/src/models/PartnerInfo";
import PartnerTypeInfo from "~src/models/PartnerTypeInfo";

interface PartnerEditorProps {
  formId: string;
  partner?: any;
  partnerTypes: Array<PartnerTypeInfo>;
  onSubmit: (data: Object, event: BaseSyntheticEvent) => void;
  onCancel: (event: BaseSyntheticEvent) => void;
}

const PartnerEditor: React.FC<PartnerEditorProps> = ({
  formId,
  partner,
  partnerTypes,
  onSubmit,
  onCancel,
}) => {
  const originalPartnerInfo = Object.assign({}, partner);
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfo>(partner);
  const [editState, setEditState] = useState<boolean>(false);
  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleChangePartnerInfo = (e, name) => {
    const { value } = e.target;
    const newPartnerInfo = {
      ...partnerInfo,
      [name]: value,
    };
    let isEdited = false;
    for (let key in newPartnerInfo) {
      if (newPartnerInfo[key] !== originalPartnerInfo[key]) {
        isEdited = true;
        break;
      }
    }
    setEditState(isEdited);
    setPartnerInfo(newPartnerInfo);
  };

  return (
    <form id={formId} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center justify-between w-full col-span-2 text-lg bg-custom-gray-sm">
        <span className="p-2">{partnerInfo.id ? <>แก้ไขข้อมูลกลุ่ม</> : <>สร้างกลุ่ม</>}</span>
        <div>
          <button
            className={`h-full px-3 py-3 text-base transition duration-200 
              bg-opacity-0 bg-custom-gray-md rounded-3xl text-primary-color focus:outline-none 
              ${editState ? "hover:bg-opacity-25" : "cursor-not-allowed text-custom-gray-md"}`}
            form={formId}
            type="submit"
            value="Submit"
            disabled={!editState}
          >
            บันทึก
          </button>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </div>
      </div>
      <div className="px-2 py-3 grid grid-cols-2 gap-2">
        <input name="id" ref={register} value={partnerInfo.id} type="hidden" />
        <div className="col-span-1" key="code">
          <FormGroup className="mb-0" label="รหัสกลุ่ม" htmlForLabel="code" require>
            <TextField
              id="code"
              name={`code`}
              value={partnerInfo.code}
              onChange={(e) => handleChangePartnerInfo(e, "code")}
              inputRef={register(messages.required)}
              error={errors.code?.message}
            />
          </FormGroup>
          <div className="mt-1 italic text-xs text-red-600">
            {`* ใช้รหัสจากระบบ NetSuite (AGN-${partnerInfo.code})`}
          </div>
        </div>
        <div className="col-span-1" key="name">
          <FormGroup label="ชื่อ" htmlForLabel="name" require>
            <TextField
              id="name"
              name={`name`}
              value={partnerInfo.name}
              onChange={(e) => handleChangePartnerInfo(e, "name")}
              inputRef={register(messages.required)}
              error={errors.name?.message}
            />
          </FormGroup>
        </div>
        <div className="col-span-1" key="email">
          <FormGroup label="E-mail" htmlForLabel="email">
            <TextField
              id="email"
              name={`email`}
              value={partnerInfo.email}
              onChange={(e) => handleChangePartnerInfo(e, "email")}
              inputRef={register}
              error={errors.email?.message}
            />
          </FormGroup>
        </div>
        <div className="col-span-1" key="partnerCode">
          <FormGroup label="ประเภทกลุ่ม" htmlForLabel="partner_type" require>
            <SelectField
              id="partner_type"
              name={`partner_type`}
              value={partnerInfo.partner_type?.code}
              onChange={(e) => handleChangePartnerInfo(e, "partner_type")}
              inputRef={register(messages.requiredSelect)}
              error={errors.partner_type?.code?.message}
              options={partnerTypes.map((pt) => {
                return { value: pt.code, label: pt.name };
              })}
            />
          </FormGroup>
        </div>
      </div>
    </form>
  );
};

export default PartnerEditor;
