import "babel-polyfill";

import { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";

import { useAppContext } from "~src/context/App";
import axios from "/src/helpers/configAxios";

const useFetch = (url, payload = {}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //TODO: Refactor/Consolidate each part to use its own API/Context
  const { appConfig } = useAppContext();

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      await axios
        .get(`${appConfig.backendURL}${url}`, payload)
        .then((res) => {
          unstable_batchedUpdates(() => {
            setIsLoading(false);
            setResponse(res.data);
          });
        })
        .catch((error) => {
          unstable_batchedUpdates(() => {
            setIsLoading(true);
            setError(error);
          });
        });
    }
    fetchData();
  }, [url]);

  return { response, error, isLoading };
};

export default useFetch;
