import React, { useState } from "react";
import PropTypes from "prop-types";

import "./table.scss";
import Cookies from "js-cookie";
import { formatNumberWithCurrency } from "~src/helpers";
import { ButtonRadius } from "~src/components/form";
import AssignSaleModal from "~src/pages/agent/lead/modal/AssignSaleModal";
import UpdateStatusModal from "~src/pages/agent/lead/modal/UpdateStatusModal";

const RenewLeadTable = (props) => {
  const { loading, data, setSortBy, sortBy, handleClick } = props;
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false);
  const [isUpdateSaleModalOpen, setIsUpdateSaleModalOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [leadId, setLeadId] = useState("");
  const [saleId, setSaleId] = useState("");
  const role = Cookies.get("role");
  const role_json = JSON.parse(role).code;

  const handleUpdateSale = (lead_id, current_sale) => {
    setLeadId(lead_id);
    setSaleId(current_sale);
    setIsUpdateSaleModalOpen(true);
  };

  const handleUpdateStatusClick = (lead_id, current_status) => {
    setStatus(current_status);
    setLeadId(lead_id);
    setIsUpdateStatusModalOpen(true);
  };

  const updateStatusCloseModal = () => {
    setIsUpdateStatusModalOpen(!isUpdateStatusModalOpen);
  };

  const assignSaleCloseModal = () => {
    setIsUpdateSaleModalOpen(!isUpdateSaleModalOpen);
  };

  const handleShowActivity = (lead_id) => {
    window.location.href = `/lead/${lead_id}`;
  };

  return (
    <div>
      <div className="renew-lead-table">
        <div className="table-grid">
          <div className="grid-th">
            <div>ชื่อลูกค้า</div>
            <div>เบอร์โทรศัพท์</div>
            <div>แผน</div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setSortBy(!sortBy);
                handleClick();
              }}
              aria-hidden="true"
            >
              วันคุ้มครอง <i className="material-icons text-xs">unfold_more</i>
            </div>
            <div>สถานะ</div>
            <div>ผู้รับผิดชอบ</div>
            {role_json == "supervisor" && <div>วันที่ได้lead</div>}
            <div>วันที่ได้รับมอบหมาย</div>
            <div style={{ textAlign: "center" }} />
          </div>

          {loading && <div className="renew-grid-body-full">กำลังโหลด...</div>}
          {!loading && (
            <>
              {data && data.length == 0 && <div className="renew-grid-body-full">ไม่พบข้อมูล</div>}
              {data &&
                data.map((lead, index) => (
                  <div className="grid-tbody" key={index}>
                    <div>
                      {lead.insured_name} <br />
                      ออเดอร์: {lead.order_number} <br />
                      เลขตัวถัง: {lead.chassis_number} <br />
                      ทะเบียน: {lead.license_plate} <br />
                      รุ่น: {lead.car_brand} {lead.car_model} <br />
                      รุ่นย่อย/ปี: {lead.sub_model}/{lead.year} <br />
                    </div>
                    <div>{lead.mobile_phone}</div>
                    <div>
                      ประเภท: {lead.type_of_insurance} <br />
                      บริษัท: {lead.insurer} <br />
                      ชั้นประกัน: {lead.package} <br />
                      วิธีชำระ: {lead.payment_method} <br />
                      เบี้ยรวม: {formatNumberWithCurrency(parseFloat(lead.total_premium))} <br />
                      เบี้ยตามใบเตือน:{" "}
                      {formatNumberWithCurrency(parseFloat(lead.total_premium_quote))} <br />
                    </div>
                    <div>
                      {lead.start_date && lead.end_date && (
                        <>
                          {lead.start_date} - {lead.end_date}
                        </>
                      )}
                    </div>
                    <div>
                      {lead.status}
                      <br />
                      <br />
                      {lead.status_key == "follow_up" && (
                        <>
                          วันที่: {lead.followup_date}
                          <br />
                          <br />
                        </>
                      )}
                      {lead.sub_status && (
                        <>
                          {lead.sub_status}
                          <br />
                          <br />
                        </>
                      )}
                      {lead.activity_remark && <>{lead.activity_remark}</>}
                    </div>
                    <div>{lead.assigned}</div>
                    {role_json == "supervisor" && <div>{lead.received_date}</div>}
                    <div>{lead.assigned_date}</div>
                    <div>
                      <ButtonRadius
                        type="button"
                        color="primary"
                        size="xs"
                        onClick={() => handleUpdateStatusClick(lead.id, lead.status_key)}
                      >
                        อัพเดทสถานะ
                      </ButtonRadius>

                      {role_json == "supervisor" && (
                        <ButtonRadius
                          type="button"
                          color="primary"
                          size="xs"
                          onClick={() => handleUpdateSale(lead.id, lead.sale_id)}
                        >
                          เปลี่ยน sale
                        </ButtonRadius>
                      )}

                      {role_json == "supervisor" && (
                        <div>
                          <ButtonRadius
                            type="button"
                            color="primary"
                            size="xs"
                            onClick={() => handleShowActivity(lead.id)}
                          >
                            log
                          </ButtonRadius>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>

      {isUpdateStatusModalOpen && (
        <UpdateStatusModal leadId={leadId} status={status} cbCloseModal={updateStatusCloseModal} />
      )}

      {isUpdateSaleModalOpen && (
        <AssignSaleModal leadId={leadId} saleId={saleId} cbCloseModal={assignSaleCloseModal} />
      )}
    </div>
  );
};

RenewLeadTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default RenewLeadTable;
