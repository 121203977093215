import React from "react";
import * as SVG from "./svg";
import { SvgProps } from "./svg/SvgProps";

export type IconName = keyof typeof SVG;

export interface IconProps extends SvgProps {
  name: IconName;
}

export const Icon: React.FC<IconProps> = ({ name, width, height, color }) => {
  const IconElement = SVG[name];

  return <IconElement width={width} height={height} color={color} />;
};

export const AllIcons: React.FC = () => {
  const iconNames = Object.keys(SVG).sort() as IconName[];

  return (
    <div className="overflow-scroll bg-white p-3">
      <div className="font-bold text-3xl mb-6">All Icons</div>
      <div className="grid grid-cols-4 gap-4">
        {iconNames.map((name, i) => (
          <div
            className="flex flex-col items-center justify-between p-2 rounded hover:bg-green-200"
            key={i}
          >
            <Icon name={name} />
            <div className="text-sm">{name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
