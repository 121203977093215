import React, { useRef } from "react";
import {
  Box,
  FormGroup,
  Paper,
  Stack,
  TextField,
  FormControlLabel,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  changeRole,
  setDetail,
  setFormData,
  setEffectiveDate,
  contractFormSelector,
  clearError,
} from "./contractSlice";
import { fetchCreateContract, fetchUpdateContract } from "./apis";
import { parse } from "date-fns";
import { showErrorNotification, notificationSelector } from "../notification";
import Preloading from "~src/components/loading/Preloading";
import EditorToolbar, { modules, formats } from "~src/components/EditorToolbar";

const ContractForm = () => {
  const contract = useAppSelector(contractFormSelector);
  const notification = useAppSelector(notificationSelector);
  const dispatch = useAppDispatch();

  const [preload, setPreload] = React.useState(false);

  React.useEffect(() => {
    if (!notification.open && contract.status === "rejected") {
      dispatch(clearError());
    }
  }, [contract.status, notification.open, dispatch]);

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as "hero" | "knight";
    dispatch(changeRole(value));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    dispatch(setFormData({ name, value }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPreload(true);

    const { effective_date, ...restData } = contract.data;

    const [day, month, year] = effective_date.split("/");
    const payload = {
      ...restData,
      effective_date: `${year}-${month}-${day}`,
    };

    if (contract.mode === "create") {
      dispatch(fetchCreateContract(payload));
    } else {
      const { id } = contract.data;
      dispatch(fetchUpdateContract({ id, payload }));
    }
  };

  if (contract.status === "rejected") {
    dispatch(showErrorNotification({ message: contract.error }));
  }

  return (
    <Paper elevation={0} sx={{ mt: 3, p: 4 }}>
      <>
        {preload && <Preloading />}
        <Box component="form" onSubmit={onSubmit}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={2} direction="row" alignItems="center">
              <TextField
                label="Version"
                name="version"
                onChange={handleChange}
                value={contract.data.version}
              />

              <DatePicker
                label="Effective Date"
                format="dd/MM/yyyy"
                value={parse(contract.data.effective_date, "dd/MM/yyyy", new Date())}
                onChange={(newValue) => dispatch(setEffectiveDate(newValue))}
              />
            </Stack>

            <Box sx={{ my: 2 }}>
              <FormControl>
                <FormLabel>Agent role</FormLabel>
                <RadioGroup row name="role" value={contract.data.role} onChange={handleChangeRole}>
                  <FormControlLabel value="hero" control={<Radio />} label="Hero" />
                  <FormControlLabel value="knight" control={<Radio />} label="Knight" />
                </RadioGroup>
              </FormControl>
            </Box>

            <FormGroup sx={{ minHeight: 300, mb: 2, "& .ql-container": { minHeight: 300 } }}>
              <EditorToolbar />
              <ReactQuill
                formats={formats}
                modules={modules}
                theme="snow"
                value={contract.data.detail}
                onChange={(value) => dispatch(setDetail(value))}
                style={{ minHeight: 300 }}
              />
            </FormGroup>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained" size="large" disableElevation>
                Submit
              </Button>
            </Box>
          </LocalizationProvider>
        </Box>
      </>
    </Paper>
  );
};

export default ContractForm;
