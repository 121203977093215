import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonRadius } from "~/src/components/form";
import HighLightText from "~/src/components/highlightText";
import CommonModal from "~/src/components/modal/common";
import QrCodeModal from "~src/components/modal/info/QrCodeModal";

type Props = {
  open: boolean;
  nextStep?: boolean;
  onClose?: () => void;

  iconOne: string;
  iconTwo: string;
  iconQR: string;
};

const TextData = [
  { highlight: false, text: "สวัสดีครับ หากคุณต้องการเริ่มวันคุ้มครอง", newline: true },
  { highlight: false, text: "ตั้งแต่" },
  { highlight: true, text: `วันที่ 1 มกราคม ${new Date().getFullYear() + 544} ` },
  { highlight: false, text: "เป็นต้นไป" },
];

const EndOfYearModal: React.FC<Props> = ({
  open,
  iconOne,
  iconTwo,
  iconQR,
  nextStep = true,
  onClose,
}): JSX.Element => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [lineOpen, setLineOpen] = useState<boolean>(false);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = (): void => {
    onClose?.();
    setIsOpen(false);
    setLineOpen(false);
  };

  return lineOpen ? (
    <QrCodeModal open={lineOpen} qrIcon={iconQR} onClose={handleClose} />
  ) : (
    <CommonModal open={isOpen} persistent={true} onClose={handleClose}>
      <div className="animate-fade-in duration-700 flex flex-col justify-center items-center gap-20">
        <img src={iconOne} width={160} height={160} />
        <div className="text-center text-base text-primary">
          <HighLightText headerText={TextData} />
          <p className="pt-20"> {`กรุณาติดต่อเจ้าหน้าที่ ผ่านทาง Line : ${t("lineAccount")}`}</p>
        </div>

        <div className="flex flex-row justify-center w-full gap-20">
          <ButtonRadius classNameOption="w-40 cursor-pointer" onClick={() => setLineOpen(true)}>
            ซื้อผ่าน Line
            <img src={iconTwo} className="ml-1 h-30" />
          </ButtonRadius>

          {nextStep && (
            <ButtonRadius type="submit" classNameOption="w-40" color="secondary">
              ดำเนินการต่อ
            </ButtonRadius>
          )}
        </div>
      </div>
    </CommonModal>
  );
};

export default EndOfYearModal;
