import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Badge from "../../../../components/Badge/Policy";
import cns from "classnames";
import { renewConText } from "../store";
import { PolicyTypeMapping } from "../../../../helpers/mappingData";
import { LineItemAs } from "../../../../types/payment";
import { formatNumberWithCurrency } from "../../../../helpers/formatNumber";

import useTotalPremium from "../hooks/useTotalPremium";
import { PolicyStatus } from "~src/types/policy";

const Header = () => {
  const { state } = React.useContext(renewConText);
  const { type, action } = useParams<{ type: string; action: string }>();
  const history = useHistory();

  let status: PolicyStatus;
  switch(action) {
    case "create": {
      const policy = state.renew?.policies.find((o) => o.type === type);
      status = policy?.status;
    }
    case "edit": {
      status = "issuing"
    }
  }

  const total = useTotalPremium(state, type);

  return (
    <div className="sticky p-4 bg-white shadow-lg z-1" style={{ top: 63 }}>
      <div className="flex justify-between">
        <div className="flex">
          <div>
            <small className="block">รายการคำสั่งซื้อ</small>
            <div className="flex align-items-center">
              <h6 className="mr-3">
                <i className="mr-1 icon icon-Motor"></i>
                {state.renew?.number}
              </h6>
            </div>
          </div>
          <div className="mr-3">
            <small>{PolicyTypeMapping[type]}</small>
            <br />
            <Badge status={status} />
          </div>
        </div>
        <div>
          <div className="flex">
            <div className="mr-2">
              <small>เบี้ยประกันที่ต้องชำระทั้งสิ้น</small>
              <div className="text-lg font-semibold text-right text-secondary">
                {type === "cmi" ?
                  state.renew?.addon_selling_price :
                  formatNumberWithCurrency(total.totalAmount)
                }
              </div>
            </div>
            <button
              type="submit"
              className={cns([
                "px-4 py-2 mx-2 text-base font-semibold text-center text-white transition duration-200 ease-in rounded-lg shadow-md focus:ring-indigo-500 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2",
                state.isEdit
                  ? "bg-secondary hover:bg-secondary-dark"
                  : "bg-gray-100 hover:bg-gray-200 text-gray-400 cursor-not-allowed",
              ])}
              disabled={!state.isEdit}
            >
              บันทึกใบแจ้งเตือน
            </button>
            <button
              type="button"
              onClick={() => history.goBack()}
              className="px-4 py-2 mx-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-gray-600 rounded-lg shadow-md hover:bg-gray-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
