import React from "react";
import { Order } from "~src/types/order";
import { ButtonRadius } from "~src/components/form";

type ContinueOrderButtonProp = {
  size?: string;
  order: Order;
};

const ContinueOrderButton: React.FC<ContinueOrderButtonProp> = ({ order, size }): JSX.Element => {
  const { quotation } = order;

  if (
    order.contract === "renew" ||
    order.payments_summary.is_installments ||
    !["open", "waiting_payment"].includes(order.status)
  ) {
    return null;
  }

  const isPublicLink = !!quotation?.criteria?.token;
  const isMotor = quotation?.product_type === "motor";

  const pathToCheckout = (): string => {
    const token = quotation?.criteria?.token;
    let checkoutURL = `/checkout/${order.order_id}/step-1`;
    let prefix = "";

    if (isPublicLink) {
      prefix = `/public`;
      checkoutURL = `${checkoutURL}?token=${token}`;
    }

    if (!isMotor) prefix = `${prefix}/${quotation?.product_type}s`;

    return `${prefix}${checkoutURL}`;
  };

  return (
    <ButtonRadius
      color="primary"
      component="link"
      to={pathToCheckout()}
      target={isPublicLink ? "_blank" : ""}
      size={size}
    >
      ทำต่อ
    </ButtonRadius>
  );
};

export default ContinueOrderButton;
