import Decimal from "decimal.js";
import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { match as Match, useHistory } from "react-router";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import { ButtonRadius } from "~src/components/form";
import GeneratePaymentLink from "~src/components/payments/GeneratePaymentLink";

import dictionary from "~src/data.yaml";
// import { useAgentOrdersAPI } from "~src/api/agent/Orders";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import useOrder from "~src/hooks/useOrder";
import { Payment } from "~src/types/payment";

import "./upload-payslip.scss";

// const ordersAPIFactory = (props) => new AgentOrdersAPIClient(props);

export interface FirstPaymentLinkPageProps {
  match: Match<FirstPaymentLinkPageParams>;
}

export interface FirstPaymentLinkPageParams {
  order_id: string;
}

export default function FirstPaymentLinkPage(props) {
  return (
    <PageContextProvider>
      <FirstPaymentLinkPageInner {...props} />
    </PageContextProvider>
  );
}

interface FirstPaymentLinkPageInnerProps extends FirstPaymentLinkPageProps { }

function FirstPaymentLinkPageInner({ match }: FirstPaymentLinkPageInnerProps) {
  const { order_id: orderID } = match.params;

  const history = useHistory();

  const { onError, setAlert, setPreloading } = usePageContext();

  const { isLoading, order } = useOrder(Number(orderID), {
    onError,
  });

  const [amount, setAmount] = useState<Decimal | undefined>();
  const [totalAmount, setTotalAmount] = useState<Decimal | undefined>();
  const [firstPayment, setFirstPayment] = useState<Payment | undefined>();
  // const [previousURL, setPreviousURL] = useState<string | undefined>();

  useDeepCompareEffectNoCheck(() => {
    if (isLoading || !order) {
      return;
    }

    const payment1 = order.payments[0];
    // order.payments_summary?.installment_terms}

    const _amount = payment1
      ? new Decimal(payment1.required_amount)
      : new Decimal(order.payments_summary.payment_total_amount);
    const _totalAmount = new Decimal(order.payments_summary.payment_total_amount);

    unstable_batchedUpdates(() => {
      setAmount(_amount);
      setTotalAmount(_totalAmount);
      setFirstPayment(payment1);
      // setPreviousURL(payment1?.link);
    });
  }, [isLoading, order]);

  useEffect(() => {
    setPreloading(isLoading);
  }, [isLoading]);

  return (
    <>
      {!isLoading && amount && (
        <>
          {order ? (
            <section className="checkout-first-payment flex flex-row w-full min-h-75vh justify-center">
              <div className="flex w-full md:w-4/6 items-stretch">
                <div className="flex flex-1 flex-col w-full">
                  <div className="card flex flex-col flex-grow w-full">
                    <h1 className="flex flex-row">
                      <span className="hero-icon hero-icon-clipboard"></span>
                      {dictionary.payment.generate_link}
                    </h1>
                    <hr className="flex" />
                    <GeneratePaymentLink
                      orderID={order.order_id}
                      amount={amount}
                      // paymentsAPI={paymentsAPI}
                      payment={firstPayment}
                      totalAmount={totalAmount} //_included_cc_fee
                      isInstallments={order.payments_summary?.is_installments}
                      installmentTerms={order.payments_summary?.installment_terms}
                      // previousURL={previousURL}
                      generateOnInit={true}
                      onError={onError}
                      className="flex-1 flex-grow"
                    />
                  </div>

                  <div className="first-payment-page-actions flex flex-row space-between w-full mt-16">
                    <ButtonRadius
                      component="link"
                      to={`/orders/${order.order_id}/details`}
                      color="primary"
                      classNameOption="order-detail-link"
                    >
                      {dictionary.checkout.to_order}
                    </ButtonRadius>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div>
              <h1 className="mt-4 text-center">Order is not available.</h1>
              {onError && onError(new Error("Order is not available"))}
            </div>
          )}
        </>
      )}
    </>
  );
}
