import React, { useEffect, useReducer, useState } from "react";
import QueryString from "query-string";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CardLoading from "~src/components/loading/CardLoading";
import CardDetails from "~src/components/products/CardDetails";
import { CompareProvider } from "~src/context/Compare";
import useAxios from "~src/hooks/useAxios";
import TravelProduct from "~src/models/TravelProduct";
import { compareReducer, initCompareState } from "~src/reducer/CompareReducer";
import { TravelProductDto } from "~src/types/travel";
import CompareBar from "/src/components/compare";
import { rangeDate } from "~src/helpers/dateTime";
import { TravelCriteria } from "~/src/types/criteria";
import { CreateQuotationPayload } from "~/src/types/quotation";
import { createQuotation, quotationPlaceOrder } from "~src/api/Quotations";
import Preloading from "~src/components/loading/Preloading";
import ContactCard from "~src/components/card/ContactCard";

const Search: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const [travelPackages, setTravelPackages] = useState<TravelProduct[]>([]);
  const [showComission] = useState<boolean>(false);
  const { search } = window.location;
  const [querires] = useState(QueryString.parse(search));
  const queryParams = new URLSearchParams(search).toString();
  const useCompareState = useReducer(compareReducer, initCompareState);
  const [loading, isLoading] = useState<boolean>(false);
  const searchResponse = useAxios<TravelProductDto[]>(`/api/travels/search?${queryParams}`);

  const travelInfo = `${querires.destination} ${querires.traveller} คน ${rangeDate(
    querires.from_date,
    querires.to_date
  )}`;

  useEffect(() => {
    if (searchResponse.status === "loaded" && searchResponse.data) {
      setTravelPackages(searchResponse.data.map((d) => new TravelProduct(d)));
    }
  }, [searchResponse]);

  const createQuotationPayload = (product: TravelProduct): CreateQuotationPayload => {
    return {
      token: querires.token,
      product_ids: product.id,
      policy_type: product.insurerClass,
      customer_phone: null,
      customer_name: "self buy from insured",
      product_type: "travel",
      criteria: querires,
      quotes: [
        {
          product_id: product.id,
          discount: 0,
        },
      ],
    };
  };

  const quotationPlaceOrderPayload = (quotationId: number, quoteId: number) => {
    return { quotation_id: quotationId, quote_ids: [quoteId], token: querires.token };
  };

  const selfBuyInsured = async (product: TravelProduct) => {
    try {
      isLoading(true);
      const quotationPayload = createQuotationPayload(product);
      const respQuotation = await createQuotation(quotationPayload);
      const quotation = respQuotation.data.content;

      const placeOrderPayload = quotationPlaceOrderPayload(quotation.id, quotation.quotes?.[0]?.id);
      const respOrder = await quotationPlaceOrder(placeOrderPayload);
      const order = respOrder.data.content;

      history.push({
        pathname: `/public/travels/checkout/${order.order_id}/step-1`,
        search: `token=${querires.token}`,
      });
    } catch (err) {
      console.error(err);
    } finally {
      isLoading(false);
    }
  };

  return (
    <CompareProvider value={useCompareState}>
      {loading && <Preloading />}
      <CompareBar />
      <div className="travel-search-page">
        <h1>
          <i className="material-icons">card_travel</i>ประกันเดินทาง
        </h1>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <div className="travel-search-page-count">
                  พบ <span className="lenght">{travelPackages.length ?? 0} </span> แผนประกันสำหรับ
                  <span className="model">
                    {querires.destination}, {querires.traveller} คน,{" "}
                    {rangeDate(querires.from_date, querires.to_date)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              {searchResponse.status === "loading" ? (
                <CardLoading items={8} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" />
              ) : (
                <>
                  {travelPackages.map((packageObj, index) => (
                    <div
                      key={packageObj.id}
                      className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                    >
                      <CardDetails
                        type="by-insured"
                        index={index}
                        multiply={parseInt(querires.traveller || "1", 10)}
                        details={travelInfo}
                        packageObj={packageObj}
                        showComission={showComission}
                        linkCreate={`/public/travels/checkout${search}&product_id=${packageObj.id}&product_type=travel`}
                        onCallback={() => selfBuyInsured(packageObj)}
                      />
                    </div>
                  ))}

                  {travelPackages.length === 0 && (
                    <ContactCard productType="travel" info={travelInfo} />
                  )}
                </>
              )}

              {searchResponse.status === "error" ||
                (travelPackages.length === 0 && (
                  <div className="col-12">
                    <div>
                      <h5 className="my-4 mt-20 text-center">
                        ขออภัยครับ ไม่พบแผนประกันภัยที่ท่านค้นหา
                      </h5>
                      <p className="text-center">
                        กรุณาติดต่อ Line: {t("lineAccount")} หรือ โทร {t("phoneNumberFormat")} เวลา:{" "}
                        {t("timeToContact")} ({t("dayToContact")})
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </CompareProvider>
  );
};

export default Search;
