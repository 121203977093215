import React, { ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { format, set } from "date-fns";
import DatePicker from "react-datepicker";
import Preloading from "../../../../components/loading/Preloading";
import Form, {
  TextField,
  SelectField,
  TextareaField,
  TimeDropdown,
} from "./form";
import axios from "../../../../helpers/configAxios";

import "./datePicker.css";

interface Props {
  id: number;
  onClose: () => void;
}

interface Data {
  id: number;
  title: string;
  place: string;
  from_date: string;
  to_date: string;
  start_time: string;
  end_time: string;
  people: string;
  status: string;
  link: string;
  category: string;
  sub_category: string;
  speaker: string;
  remark: string;
  description: string;
}

const Edit: React.FC<Props> = ({ id, onClose }) => {
  const { register, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const [data, setData] = React.useState<Data | null>(null);
  const [fromDate, setFromDate] = React.useState<Date>(new Date());
  const [toDate, setToDate] = React.useState<Date>(new Date());
  const [preload, setPreload] = React.useState<boolean>(false);
  const [startTime, setStartTime] = React.useState<[string, string]>([
    "0",
    "0",
  ]);
  const [endTime, setEndTime] = React.useState<[string, string]>(["0", "0"]);

  React.useEffect(() => {
    (async (): Promise<void> => {
      try {
        const res = await axios.get(`/api/training_course/${id}`);
        const startTimeData: string[] = format(
          new Date(res.data.content.from_date),
          "H:m"
        ).split(":");
        const endTimeData: string[] = format(
          new Date(res.data.content.to_date),
          "H:m"
        ).split(":");

        setData(res.data.content);
        setFromDate(new Date(res.data.content.from_date));
        setToDate(new Date(res.data.content.to_date));
        setStartTime([startTimeData[0], startTimeData[1]]);
        setEndTime([endTimeData[0], endTimeData[1]]);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (data: Data): Promise<void> => {
    setPreload(true);

    const setTimeFromDate = set(new Date(fromDate), {
      hours: parseInt(startTime[0]),
      minutes: parseInt(startTime[1]),
      seconds: 0,
    });
    const setTimeToDate = set(new Date(toDate), {
      hours: parseInt(endTime[0]),
      minutes: parseInt(endTime[1]),
      seconds: 0,
    });

    const payload: Data = {
      ...data,
      from_date: setTimeFromDate.toUTCString(),
      to_date: setTimeToDate.toUTCString(),
    };

    try {
      const res = await axios.patch(`/api/training_course/${id}`, payload);
      if (res.data.success) {
        setPreload(false);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {data && (
        <>
          <h5 className="mb-3">Edit Trainnig</h5>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap py-5 bg-gray-200 rounded-lg">
              <div className="w-full pb-2">
                <div className="px-5">
                  <TextField
                    required
                    label="ชื่อการอบรม"
                    name="title"
                    inputRef={register({ required: true })}
                    value={data.title}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full pb-2">
                <div className="px-5">
                  <TextField
                    required
                    label="สถานที่"
                    name="place"
                    inputRef={register({ required: true })}
                    value={data.place}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-1/2 pb-2">
                <div className="px-5">
                  <label htmlFor="" className="w-full mb-1">
                    วันที่เริ่ม
                    <span className="text-red-400">*</span>
                  </label>
                  <DatePicker
                    name="from_date"
                    dateFormat="dd-MM-yyyy"
                    selected={fromDate}
                    onChange={(date: Date) => setFromDate(date)}
                    className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                  />
                </div>
              </div>
              <div className="w-1/2 pb-2">
                <div className="px-5">
                  <label htmlFor="" className="w-full mb-1">
                    วันที่สิ้นสุด
                    <span className="text-red-400">*</span>
                  </label>
                  <DatePicker
                    name="to_date"
                    dateFormat="dd-MM-yyyy"
                    selected={toDate}
                    onChange={(date: Date) => setToDate(date)}
                    className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                  />
                </div>
              </div>
              <div className="w-1/2 pb-2">
                <div className="px-5">
                  <TimeDropdown
                    label="เวลาเริ่มการอบรม"
                    required
                    value={startTime}
                    onChangeHour={(e) => {
                      let [h, m] = startTime;
                      h = e.target.value;
                      setStartTime([h, m]);
                    }}
                    onChangeMinute={(e) => {
                      let [h, m] = startTime;
                      m = e.target.value;
                      setStartTime([h, m]);
                    }}
                  />
                </div>
              </div>
              <div className="w-1/2 pb-2">
                <div className="px-5">
                  <TimeDropdown
                    label="เวลาจบการอบรม"
                    required
                    value={endTime}
                    onChangeHour={(e) => {
                      let [h, m] = endTime;
                      h = e.target.value;
                      setEndTime([h, m]);
                    }}
                    onChangeMinute={(e) => {
                      let [h, m] = endTime;
                      m = e.target.value;
                      setEndTime([h, m]);
                    }}
                  />
                </div>
              </div>
              <div className="w-1/2 pb-2">
                <div className="px-5">
                  <TextField
                    required
                    label="จำนวนผู้เข้าร่วมอบรม (คน)"
                    name="people"
                    inputRef={register({ required: true })}
                    value={data.people}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-1/2 pb-2">
                <div className="px-5">
                  <SelectField
                    required
                    label="สถานะ"
                    options={[
                      { label: "ดราฟท์", value: "draft" },
                      { label: "เปิดรับสมัคร", value: "open" },
                      { label: "ปิดรับสมัคร", value: "closed" },
                      { label: "ยกเลิกการอบรม", value: "cancel" },
                    ]}
                    name="status"
                    inputRef={register({ required: true })}
                    value={data.status}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="px-5">
                  <TextField
                    required
                    label="Link สมัคร"
                    name="link"
                    inputRef={register({ required: true })}
                    value={data.link}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-1/2">
                <div className="px-5">
                  <SelectField
                    required
                    label="Category"
                    name="category"
                    options={[
                      { label: "Sword", value: "Sword" },
                      { label: "Shield", value: "Shield" },
                    ]}
                    inputRef={register({ required: true })}
                    value={data.category}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-1/2">
                <div className="px-5">
                  <TextField
                    required
                    label="Sub Category"
                    name="sub_category"
                    inputRef={register({ required: true })}
                    value={data.sub_category}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="px-5">
                  <TextField
                    label="ชื่อวิทยากร"
                    name="speaker"
                    inputRef={register}
                    value={data.speaker}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="px-5">
                  <TextareaField
                    label="Internal Remark"
                    name="remark"
                    inputRef={register}
                    value={data.remark}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="px-5">
                  <TextareaField
                    label="Description"
                    name="description"
                    inputRef={register}
                    value={data.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="sticky bottom-0 flex justify-between w-full px-5 py-5 bg-white">
              <button
                type="button"
                className="px-4 py-3 bg-gray-400 rounded shadow"
                onClick={onClose}
              >
                Close
              </button>
              <button
                type="submit"
                className="px-4 py-3 text-white bg-gray-300 bg-pink-600 rounded shadow disabled:opacity-50"
                disabled={preload}
              >
                {!preload ? "Save" : "Loading"}
              </button>
            </div>
          </Form>
          {preload && <Preloading />}
        </>
      )}
    </div>
  );
};

export default Edit;
