import React, { useState, useEffect, useReducer } from "react";
import axios from "/src/helpers/configAxios";
import QueryString from "query-string";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "/src/components/form";

import "./compare.scss";

// import { getCompares } from "./services";
import SearchModal from "/src/components/modal/search/SearchModal";
import CompareProducts from "./CompareProducts";
import Coverages from "./Coverages";
import Preloading from "/src/components/loading/Preloading";

const Compare = (props) => {
  let query = new URLSearchParams(useLocation().search);
  let params = {};
  query.forEach((value, key) => {
    params[key] = value;
  });

  let carTitle = `${params.car_brand} ${params.year} ${params.car_model}`;

  const [fetchLoading, setFetchLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [packageObjs, setPackagObjs] = useState([]);
  const [locationsSearch, setLocationSearch] = useState(location.search);
  const [addNewCompare, setAddNewCompare] = useState(false);
  const [compareIds, setCompareIds] = useState(
    QueryString.parse(locationsSearch).compares || ""
  );
  const [productType, setProductType] = useState("motor");

  useEffect(() => {
    fatchProductsData();
  }, []);

  function fatchProductsData() {
    setFetchLoading(true);
    const url = `${process.env.API_URL}/api/products`;
    let gets = [];
    if (typeof compareIds === "object") {
      compareIds.map(
        (id, index) => (gets[index] = axios.get(url, { params: { ids: id } }))
      );
    } else {
      gets[0] = axios.get(url, { params: { ids: compareIds } });
    }
    Promise.all(gets).then((response) => {
      let compares = [...packageObjs];
      response.map((o, i) => (compares[i] = o.data.content[0]));
      setFetchLoading(false);
      setPackagObjs(compares);
      setLoaded(true);
    });
  }

  const fnRemove = (id, index) => {
    const ids = _.remove(compareIds, (o) => {
      return o != id;
    });
    let QueryStrings = QueryString.parse(locationsSearch);
    delete QueryStrings.compares;
    window.location.href =
      "/compares?" +
      QueryString.stringify({
        ...QueryStrings,
        compares: ids,
      });
  };

  const handleAddNewCompare = () => {
    setAddNewCompare(true);
  };

  const fnClose = () => {
    setAddNewCompare(false);
  };

  const maxCompare = 3;
  const emptyCompareSlot = [];
  for (let i = 0; i < maxCompare - packageObjs.length; i++) {
    emptyCompareSlot.push(
      <div key={i} className="col-md-3">
        <div className="compare-page-top-empty">
          <button
            className="compare-products-btn"
            onClick={handleAddNewCompare}
          >
            <i className="material-icons text-lg mr-2">add</i>
            เลือกประกันอื่น ๆ เพื่อเปรียบเทียบ
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="compare-page">
      <div className="compare-page-top">
        <div className="row">
          <div className="col-12">
            <h6 className="mb-4">
              <i className="icon-Motor inline-block mr-1"></i>
              เปรียบเทียบแผนประกันภัยรถยนต์ภาคสมัครใจ
            </h6>
          </div>
          <div className="col-md-3">
            <div className="space-info">
              <div className="text-2">สำหรับรถยนต์</div>
              <div className="title">{carTitle}</div>
              <div className="mt-5">
                <Button
                  classNameOption="make-group-quotation-btn"
                  color="primary"
                  component="link"
                  to={`/quotations/multipleQuotation${locationsSearch}`}
                >
                  สร้างใบเสนอราคา
                </Button>
              </div>
              {/* </Link> */}
            </div>
          </div>
          <CompareProducts
            packageObjs={packageObjs}
            fnRemove={fnRemove}
            loaded={loaded}
          />
          {loaded ? emptyCompareSlot : ""}
        </div>
      </div>
      <div className="compare-page-body">
        {(() => {
          switch (productType) {
            case "motor":
              return (
                <>
                  <Coverages
                    descriptions={[
                      {
                        header: "ความคุ้มครองรถยนต์ผู้เอาประกันภัย",
                        subHeaders: [
                          "ทุนประกัน",
                          "ค่าเสียหายส่วนแรก",
                          "ประเภทการซ่อม",
                          "รถยนต์สูญหาย",
                          "ไฟไหม้",
                          "น้ำท่วม",
                        ],
                        allowKeys: [
                          "sum_insured",
                          "excess_damage_coverage",
                          "repair_facility",
                          "fire_theft_damage_coverage",
                          "fire_theft_damage_coverage",
                          "natural_disaster_coverage",
                        ],
                      },
                      {
                        header: "ความคุ้มครองบุคคลภายนอก",
                        subHeaders: [
                          "ความบาดเจ็บทางร่างกาย หรือเสียชีวิตต่อคน",
                          "ความบาดเจ็บทางร่างกาย หรือเสียชีวิตต่อครั้ง",
                          "ทรัพย์สินบุคคลภายนอก ต่อครั้ง",
                        ],
                        allowKeys: [
                          "third_party_bodily_injury_coverage_per_person",
                          "third_party_bodily_injury_coverage_per_incident",
                          "third_party_property_damage_coverage_per_incident",
                        ],
                      },
                      {
                        header: "ความคุ้มครองเพิ่มเติม",
                        subHeaders: [
                          "อุบัติเหตุส่วนบุคคล",
                          "คุ้มครองคนโดยสาร",
                          "ค่ารักษาพยาบาล",
                          "ประกันตัวผู้ขับขี่",
                        ],
                        allowKeys: [
                          "driver_personal_accident_coverage",
                          "passengers_in_vehicle_covered",
                          "medical_coverage",
                          "bail_bond_coverage",
                        ],
                      },
                    ]}
                    packageObjs={packageObjs}
                    loaded={loaded}
                  />
                </>
              );
          }
        })()}

        {addNewCompare ? (
          <SearchModal
            fnClose={fnClose}
            compareIds={compareIds}
            carTitle={carTitle}
          />
        ) : (
          ""
        )}

        {fetchLoading ? <Preloading /> : null}
      </div>
    </div>
  );
};

export default Compare;
