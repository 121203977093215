import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { ContractForm } from "~src/features/contracts";

const ContractCreatePage = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" component="h1">
          สร้างสัญญา / ข้อกำหนดและเงื่อนไข
        </Typography>

        <Button href="/staffs/contracts" variant="contained" disableElevation color="inherit">
          ย้อนกลับ
        </Button>
      </Box>

      <ContractForm />
    </Box>
  );
};

export default ContractCreatePage;
