import { lazy } from "react";
import Payments from "~src/pages/agent/payment/payments";
import DummyPayments from "~src/pages/agent/payment/dummy_payments";

const dashboards = [
  {
    path: `/dashboard`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Dashboard",
    component: lazy(() => import("~src/pages/agent/dashboard")),
  },
  {
    path: `/dashboard/charts`,
    exact: true,
    sidebar: true,
    header: true,
    title: "DashboardCharts",
    component: lazy(() => import("~src/pages/agent/dashboard/charts")),
  },
];

const motors = [
  {
    path: `/motors`,
    exact: true,
    sidebar: true,
    header: true,
    title: "MotorMainPage",
    component: lazy(() => import("../pages/agent/motor")),
  },
  {
    path: `/motors/compulsory`,
    exact: true,
    sidebar: true,
    header: true,
    title: "CompulsorySearchPage",
    component: lazy(() => import("~src/pages/agent/motor/Compulsory")),
  },
  {
    path: `/motors/search`,
    exact: true,
    sidebar: true,
    header: true,
    title: "MotorSearchPage",
    component: lazy(() => import("~src/pages/agent/motor/Search")),
  },
];

const travels = [
  {
    path: `/travels`,
    exact: true,
    sidebar: true,
    header: true,
    title: "TravelSearchPage",
    component: lazy(() => import("~src/pages/agent/travel/TravelFormPage")),
  },
  {
    path: `/travels/search`,
    exact: true,
    sidebar: true,
    header: true,
    title: "TravelSearchPage",
    component: lazy(() => import("~src/pages/agent/travel/TravelSearchPage")),
  },
];

const quotations = [
  {
    path: `/quotations`,
    exact: true,
    sidebar: true,
    header: true,
    title: "ViewQuotation",
    component: lazy(() => import("~src/pages/agent/quotation")),
  },
  {
    path: `/quotations/createQuotation`,
    title: "Create quotation",
    exact: true,
    sidebar: true,
    header: true,
    component: lazy(() => import("~src/pages/agent/quotation/CreateSingle")),
  },
  {
    path: `/quotations/multipleQuotation`,
    title: "Create Multiple Quotation",
    exact: true,
    sidebar: true,
    header: true,
    component: lazy(() => import("~src/pages/agent/quotation/multipleQuotation")),
  },
];

const quotationsV2 = [
  {
    path: `/quotations/v2/createQuotation`,
    title: "Create quotation",
    exact: true,
    sidebar: true,
    component: lazy(() => import("../pages/agent/quotation/v2/createQuotation")),
  },
];

const policies = [
  {
    path: `/all-policies`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Policy",
    component: lazy(() => import("~src/pages/agent/policy/All")),
  },
  {
    path: `/policies/follow-up`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Policy",
    component: lazy(() => import("~src/pages/agent/policy/FollowUp")),
  },
  {
    path: `/policies/:id/details`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Policy",
    component: lazy(() => import("~src/pages/agent/policy/Details")),
  },
];

const orders = [
  {
    path: `/orders`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Orders",
    component: lazy(() => import("~src/pages/agent/order")),
  },
  {
    path: `/orders/:id/details`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Orders",
    component: lazy(() => import("~src/pages/agent/order/Details")),
  },
];

const commission = [
  {
    path: `/commission`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Commission",
    component: lazy(() => import("~src/pages/agent/commission/commission")),
  },
];

const checkouts = [
  {
    path: `/checkout/:order_id/step-1`,
    exact: true,
    header: true,
    title: "CheckoutInfo",
    component: lazy(() => import("~src/pages/agent/checkout/InfoStep1")),
  },
  {
    path: `/checkout/:order_id/step-2`,
    exact: true,
    header: true,
    title: "CheckoutInfo",
    component: lazy(() => import("~src/pages/agent/checkout/InfoStep2")),
  },
  {
    path: `/checkout/:order_id/summary`,
    exact: true,
    header: true,
    title: "CheckoutInfo",
    component: lazy(() => import("~src/pages/agent/checkout/Summary")),
  },
  {
    path: `/checkout/orders/:order_id/first-payment`,
    exact: true,
    header: true,
    title: "FirstPayment",
    component: lazy(() => import("~src/pages/agent/checkout/orders/FirstPayment")),
  },
  {
    path: `/checkout/orders/:order_id/upload-payslip`,
    exact: true,
    header: true,
    title: "Commission",
    component: lazy(() => import("~src/pages/agent/checkout/orders/UploadPayslip")),
  },
];

const comparies = [
  {
    path: `/compares`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Compare",
    component: lazy(() => import("~src/pages/agent/compare/Compare")),
  },
];

const payments = [
  {
    path: `/payments`,
    exact: true,
    header: true,
    title: "Payment | Credit Card | QR Code | Bank Transfer",
    component: Payments,
  },
  {
    path: `/payment-error`,
    exact: true,
    header: true,
    title: "Payment Error",
    component: lazy(() => import("~src/pages/agent/payment/paymentError")),
  },
];

const dummyPayments = [
  {
    path: `/dummy_payments`,
    exact: true,
    header: true,
    title: "Payment | Credit Card | QR Code | Bank Transfer",
    component: DummyPayments,
  },
];

const renewals = [
  {
    path: `/renewals`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Payment | QR Code",
    component: lazy(() => import("~src/pages/agent/renewals")),
  },
];

const thankyou = [
  {
    path: `/thankyou`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Thankyou for your payment",
    component: lazy(() => import("~src/pages/agent/payment/thankyou")),
  },
];

const contact = [
  {
    path: `/contact-us`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Contact Us",
    component: lazy(() => import("~src/pages/agent/contact")),
  },
];

const lead = [
  {
    path: "/lead",
    exact: true,
    sidebar: true,
    header: true,
    title: "Lead",
    component: lazy(() => import("~src/pages/agent/lead")),
  },
  {
    path: "/old_lead",
    exact: true,
    sidebar: true,
    header: true,
    title: "Old Lead",
    component: lazy(() => import("~src/pages/agent/lead")),
  },
  {
    path: "/renew_lead",
    exact: true,
    sidebar: true,
    header: true,
    title: "RenewLead",
    component: lazy(() => import("~src/pages/agent/renew_lead")),
  },
  {
    path: "/upsell_lead",
    exact: true,
    sidebar: true,
    header: true,
    title: "Upsell",
    component: lazy(() => import("~src/pages/agent/renew_lead")),
  },
  {
    path: `/lead/:id`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Lead",
    component: lazy(() => import("~src/pages/agent/lead/Details")),
  },
  {
    path: `/leads_summary`,
    exact: true,
    sidebar: true,
    header: true,
    title: "LeadSummary",
    component: lazy(() => import("~src/pages/agent/lead/SaleSummary")),
  },
];

// const customer = [
//   {
//     path: '/customer',
//     exact: true,
//     sidebar: true,
//     title: 'Customer',
//     component: lazy(() => import("~src/pages/agent/customer")),
//   }
// ]

const ensuremore = [
  {
    path: `/ensuremore-motor`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Ensuremore | Motor",
    component: lazy(() => import("~src/pages/agent/ensureMotor")),
  },
];

const register = [
  {
    path: `/register`,
    title: "Register",
    component: lazy(() => import("~src/pages/agent/register")),
  },
];

const agent = [
  {
    path: `/agent/:id`,
    exact: true,
    sidebar: true,
    header: true,
    title: "Profile",
    component: lazy(() => import("~src/pages/staff/agent/Details")),
  },
];

const search = [
  {
    path: `/search`,
    title: "Search Product",
    exact: true,
    sidebar: true,
    header: true,
    component: lazy(() => import("../pages/agent/search")),
  },
];

// const tutorialvideo = [
//   {
//     path: `/tutorial-video`,
//     exact: true,
//     sidebar: true,
//     title: "Tutorial Video",
//     component: lazy(() => import("~src/pages/agent/tutorialvideo")),
//   },
// ];

const defaultRoutes = [
  ...dashboards,
  ...motors,
  ...travels,
  ...quotations,
  ...policies,
  ...orders,
  ...commission,
  ...checkouts,
  ...comparies,
  ...payments,
  ...dummyPayments,
  ...renewals,
  ...thankyou,
  ...contact,
  ...ensuremore,
  ...register,
  ...lead,
  ...search,
  // ...customer,
  ...agent,
  ...quotationsV2,
  // ...tutorialvideo,
];

export { defaultRoutes };
