import React from "react";
import Cookies from "js-cookie";
import SectionHeader from "../../../components/layout/staff/SectionHeader";

import "./commission.scss";
import { Modal } from "./modal/Modal";
import { isJsonString } from "~src/helpers/helpers";

enum Role {
  Knight = "knight",
  Hero = "hero",
}

const commissionHero = `${process.env.BUCKET_URL}/commission-table-hero-20230110.jpg`;
const commissionKnight = `${process.env.BUCKET_URL}/commission-table-knight-20230110.jpg`;

const Commission = () => {
  const role = isJsonString(Cookies.get("role")) ? JSON.parse(Cookies.get("role")) : null;
  const [isPreView, setIsPreview] = React.useState<boolean>(false);

  return (
    <div>
      {isPreView && (
        <Modal onClick={() => setIsPreview(false)}>
          <img
            src={role.code === Role.Knight ? commissionKnight : commissionHero}
            style={{ maxWidth: "100%" }}
            className="pb-10 mx-auto "
          />
        </Modal>
      )}

      <div className="p-4">
        <SectionHeader
          title="อัตราค่าตอบแทน"
          breadcrumbs={[{ url: "#", label: "บริการสมาชิก", current: true }]}
        />
        <div className="w-full p-6 m-auto overflow-x-scroll text-sm bg-white rounded-sm shadow-lg commission">
          {role.code === Role.Knight ? (
            <img
              src={commissionKnight}
              onClick={() => setIsPreview(true)}
              className="cursor-pointer"
            />
          ) : (
            <img
              src={commissionHero}
              onClick={() => setIsPreview(true)}
              className="cursor-pointer"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Commission;
