import React from "react";
import "./checkbox.scss";
import classnames from "classnames";
const Checkbox = ({
  id,
  inputRef = null,
  size = "",
  label,
  emailLabel = "",
  checkedAria = false,
  disabled = false,
  ...rest
}) => {
  return (
    <label
      htmlFor={id}
      className={`custom-control-checkbox custom-checkbox custom-control-inline 
        ${size === "small" ? size : ""}
        ${size === "small" && checkedAria ? "checked" : ""}
        ${disabled ? "disabled" : ""}`}
    >
      <span>{label}</span>
      {emailLabel && (
        <span className="ml-1 text-gray-400 ">
          (
          <a href={`mailto:${emailLabel}`} className="underline">
            {emailLabel}
          </a>
          )
        </span>
      )}
      <input type="checkbox" disabled={disabled} ref={inputRef} id={id} {...rest} />
      <span className="checkmark" />
      {/* <label className="custom-control-label" htmlFor={id}>
        {label}
      </label> */}
    </label>
  );
};

const ButtonCheckbox = ({ id, inputRef, label, className, active, ...rest }) => {
  return (
    <label
      htmlFor={id}
      className={classnames(
        "custom-control-checkbox custom-checkbox custom-button-checkbox custom-control-inline",
        { active },
        className?.label
      )}
    >
      {label}
      <input type="checkbox" ref={inputRef} id={id} {...rest} />
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
export { Checkbox, ButtonCheckbox };
