import React from "react";
import InsurerDetail from "~src/components/card/InsurerDetail";
import InsurerImg from "~src/components/card/InsurerImg";
import Price from "~src/components/card/Price";
import { useAppSelector } from "~src/store";
import { coverageModalState, travelersState, travelTypeState } from "./redux";

import CoverageOptionalList from "./components/CoverageOptionalList";
import CoverageList from "./components/CoverageList";

const CoverageModal = () => {
  const state = useAppSelector(coverageModalState);
  const traveller = useAppSelector(travelersState);
  const travelType = useAppSelector(travelTypeState);
  const { data } = state;

  return (
    <div className="animate-fade-in duration-700 flex flex-col justify-center items-center gap-20">
      <div className="w-full flex gap-4 justify-between">
        <div className="flex">
          <InsurerImg
            style={{ width: "80px", height: "80px" }}
            src={data.insurer_img || data.insurer_icon}
            alt={data.insurer_name}
          />
          <InsurerDetail
            name={data.insurer_name}
            productName={`แผน: ${data.product_name}`}
            className="overflow-visible flex"
          />
        </div>
        <Price
          className="font-extrabold text-lg self-end"
          value={Number(data.standard_price) * Number(traveller)}
          suffix={""}
        />
      </div>
      {travelType === "yearly" && (
        <div className="bg-secondary w-full p-4 rounded" style={{ color: "#fff" }}>
          หมายเหตุ: จำนวนวันเดินทางที่คุ้มครองสูงสุดต่อครั้ง 120 วัน
        </div>
      )}
      <div
        className="flex flex-col w-full max-w-lg gap-20 bg-gray-200 p-4 rounded-5 overflow-y-auto"
        style={{ height: "26rem" }}
      >
        <div>
          <div className="font-light col-span-2">
            1. ผลประโยชน์การเสียชีวิต การสูญเสียอวัยวะ สายตา
            หรือทุพพลภาพถาวรสิ้นเชิงเนื่องจากอุบัติเหตุ
          </div>
          <CoverageOptionalList keyMap="dismemberment_coverage_1_14" />
          <CoverageOptionalList keyMap="dismemberment_coverage_15_75" />
          <CoverageOptionalList keyMap="dismemberment_coverage_76_80" />

          <div className="font-light col-span-2 mt-16">2. ค่ารักษาพยาบาล จ่ายตามจริงสูงสุด</div>
          <CoverageOptionalList keyMap="overseas_medical_expenses_coverage_1_14" />
          <CoverageOptionalList keyMap="overseas_medical_expenses_coverage_15_75" />
          <CoverageOptionalList keyMap="overseas_medical_expenses_coverage_76_80" />

          <div className="row  pt-2 font-light">
            <div className="col-8">
              คุ้มครองค่ารักษาพยาบาลสืบเนื่องจากต่างประเทศหลังจากกลับถึงประเทศไทย
              (ถือเป็นส่วนหนึ่งของค่ารักษาพยาบาล) ตามจริงต่อครั้งสูงสุด
            </div>
          </div>
          <CoverageOptionalList keyMap="follow_up_medical_expenses_coverage_1_14" />
          <CoverageOptionalList keyMap="follow_up_medical_expenses_coverage_15_75" />
          <CoverageOptionalList keyMap="follow_up_medical_expenses_coverage_76_80" />
          <div className="row pt-2 font-light">
            <div className="col-8">
              ผลประโยชน์ชดเชยค่าเดินทางเพื่อรักษาพยาบาลแบบผู้ป่วยนอกในต่างประเทศ
              (ถือเป็นส่วนหนึ่งของค่ารักษาพยาบาล)
            </div>
            <div className="col-4 text-right">1,000 ฿</div>
          </div>
          <CoverageList prefixNum="3." keyMap="emergency_evacuation_and_repatriation_coverage" />
          <CoverageList prefixNum="4." keyMap="mortal_remain_repatriation_coverage" />
          <CoverageList prefixNum="5." keyMap="trip_cancellation_postponement_coverage" />
          <CoverageList prefixNum="6." keyMap="trip_curtailment_coverage" />
          <CoverageList prefixNum="7." keyMap="flight_delay_coverage" />
          <CoverageList prefixNum="8." keyMap="loss_or_damage_baggage_coverage" />
          <CoverageList prefixNum="9." keyMap="baggage_delay_coverage" />
          <CoverageList prefixNum="10." keyMap="personal_liability_coverage" />
          <CoverageOptionalList prefixNum="11." keyMap="compassionate_overseas_visit" />
          <CoverageOptionalList
            prefixNum="12."
            keyMap="overseas_daily_hospital_confinement_benefit"
          />
          <CoverageList prefixNum="13." keyMap="damage_or_loss_money_coverage" />
          <CoverageList prefixNum="14." keyMap="damage_or_loss_travel_document_coverage" />
          <CoverageList prefixNum="15." keyMap="missed_connecting_flight_coverage" />
          <CoverageOptionalList prefixNum="16." keyMap="hole_in_one_reward_maximum_payable" />
          <CoverageOptionalList prefixNum="17." keyMap="rental_car_excess_maximum_payable" />
          <CoverageList prefixNum="18." keyMap="loss_or_damage_laptop_coverage" />
          <CoverageList prefixNum="19." keyMap="hijacking_coverage" />
          <div className="row pt-2 font-light">
            <div className="col-8">20. ผลประโยชน์ชดเชยค่าโทรศัพท์ฉุกเฉินจ่ายตามจริงสูงสุด</div>
            <div className="col-4 text-right">500 ฿</div>
          </div>
          <div className="row pt-2 font-light">
            <div className="col-8">
              21. การขยายระยะเวลาคุ้มครองโดยอัตโนมัติ จากสาเหตุที่กรมธรรม์ระบุไว้ (วัน)
            </div>
            <div className="col-4 text-right">5 วัน</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverageModal;
