import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import "./button.scss";

const ButtonRadius = (props) => {
  const {
    children,
    fullwidth,
    color,
    variant,
    component,
    size,
    classNameOption,
    inputRef,
    ...rest
  } = props;
  const fullWidth = fullwidth ? "btn-block" : "";

  let setColor = "";
  let setVariant = "";
  let setSize = "";
  let setComponent = [];
  switch (color) {
    case "primary":
      setColor = "btn-primary";
      break;
    case "secondary":
      setColor = "btn-secondary";
      break;
    default:
      setVariant = "";
      break;
  }

  switch (variant) {
    case "default":
      setVariant = "btn-radius-default";
      break;
    case "outlined":
      setVariant = "btn-radius-outlined";
      break;
    case "none":
      setVariant = "btn-radius-none";
      break;
    default:
      setVariant = "btn-radius-default";
      break;
  }

  switch (size) {
    case "default":
      setSize = "";
      break;
    case "xxs":
      setSize = "btn-xxs";
      break;
    case "xs":
      setSize = "btn-xs";
      break;
    case "lg":
      setSize = "btn-lg";
      break;
    default:
      setSize = "";
      break;
  }

  switch (component) {
    case "link":
      setComponent = (
        <Link
          {...rest}
          ref={inputRef}
          className={classnames([
            "btn-radius focus:outline-none",
            setVariant,
            fullWidth,
            setColor,
            setSize,
            classNameOption,
          ])}
        >
          <span>{children}</span>
        </Link>
      );
      break;
    case "a":
      setComponent = (
        <a
          {...rest}
          ref={inputRef}
          className={classnames([
            "btn-radius focus:outline-none",
            setVariant,
            fullWidth,
            setColor,
            setSize,
            classNameOption,
          ])}
        >
          <span>{children}</span>
        </a>
      );
      break;
    default:
      setComponent = (
        <button
          {...rest}
          className={classnames([
            "btn-radius focus:outline-none",
            setVariant,
            fullWidth,
            setColor,
            setSize,
            classNameOption,
          ])}
        >
          <span>{children}</span>
        </button>
      );
      break;
  }

  return <>{setComponent}</>;
};

export default ButtonRadius;
