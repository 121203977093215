import React, { lazy, Reducer, Suspense, useEffect, useReducer } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { ThemeProvider } from "@mui/material";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";

import ErrorPage from "./components/errorPage";
import Loading from "./components/loading/Preloading";

import { AuthContextProvider, useAuthContext } from "./context/Auth";
import { publicRoutes } from "./routes/public";
import { defaultRoutes } from "./routes/agent";
import staffRoutes from "./routes/staff";

import theme from "../theme";
import useLogo from "~assets/logo/gettgo-hero-logo.svg";
import heroFavicon from "../assets/favicon/hero.ico";
import mtiFavicon from "../assets/favicon/mti.ico";
import { useAuthenticate } from "./helpers/authen";
import { Provider } from "react-redux";
import { store } from "./store";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
  const sentryDNS =
    process.env.APP_NAME !== "mti"
      ? "https://d0f6eaa5f65e4c298031214bb70d3cb2@o544521.ingest.sentry.io/5665852"
      : "https://3a60e56634934d53ab2fa14f08c95365@o544521.ingest.sentry.io/6011178";

  Sentry.init({
    dsn: sentryDNS,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AgentLayout = lazy(() => import("./components/layout/protected/Agent"));
const StaffLayout = lazy(() => import("./components/layout/protected/Staff"));
const PublicPageLayout = lazy(() => import("./components/layout/public/PublicPage"));
const LoginPage = lazy(() => import("./pages/login"));
const HomePage = lazy(() => import("./pages/home"));
const PolicyPage = lazy(() => import("./pages/home/Policy"));
const CookieConsent = lazy(() => import("./pages/home/CookieConsent"));

export const App = () => {
  useEffect(() => {
    let link: HTMLLinkElement | undefined = document.querySelector("link[rel*='icon']");
    if (!link) {
      link = document.createElement("link");
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = process.env.APP_NAME === "mti" ? mtiFavicon : heroFavicon;
    document.title =
      process.env.APP_NAME === "mti"
        ? "MTi Agent Click"
        : "gettgo hero จุดพลังนักขาย ที่ให้คุณมากกว่าตัวเงิน";

    // cleanup
    return () => {
      link && link.remove();
    };
  }, [process.env.APP_NAME]);

  return (
    <AuthContextProvider>
      <AppMainLayout />
    </AuthContextProvider>
  );
};

export interface AppMainLayoutProps {}

export function AppMainLayout({}: AppMainLayoutProps) {
  const { validateAuth } = useAuthContext();
  const { accessType } = useAuthenticate();

  let path = "";
  switch(accessType) {
    case "AGENT": path = "/dashboard"; break;
    case "DIRECT": path = "/all-policies"; break;
    case "STAFF": path = "/staffs"; break;
  }

  return (
    <Provider store={store}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route exact path="/">
                    {validateAuth() ? (
                      <Redirect to={path} />
                    ) : (
                      <HomePage />
                    )}
                  </Route>
                  {/* TODO: Reconsolidate validateAuth() */}
                  <Route exact path="/login" render={(props) => <LoginPage {...props} />} />
                  <Route exact path="/privacy-policy">
                    <PolicyPage />
                  </Route>
                  <Route exact path="/cookie-consent">
                    <CookieConsent />
                  </Route>
                  {publicRoutes.map((route, index) => {
                    const { path, exact, title, sidebar, header } = route;
                    return (
                      route.component && (
                        <Route
                          key={index}
                          path={path}
                          exact={exact}
                          // name={title}
                          render={(props) => (
                            <PublicPageLayout
                              logo={useLogo}
                              sidebar={sidebar}
                              header={header}
                              {...props}
                            >
                              <route.component {...props} />
                            </PublicPageLayout>
                          )}
                        />
                      )
                    );
                  })}
                  {defaultRoutes.map((route, index) => {
                    const { path, exact, title, sidebar, header } = route;
                    return (
                      route.component && (
                        <Route
                          key={index}
                          path={path}
                          exact={exact}
                          // name={title}
                          render={(props) => (
                            <AgentLayout
                              logo={useLogo}
                              sidebar={sidebar}
                              header={header}
                              // isAuthenticated={() => validateAuth()}
                              {...props}
                            >
                              <route.component {...props} />
                            </AgentLayout>
                          )}
                        />
                      )
                    );
                  })}
                  {accessType === "STAFF" && (
                    <>
                      {staffRoutes.map((route, index) => {
                        const { path, exact, title, sidebar, header } = route;
                        return route.component ? (
                          <Route
                            key={index}
                            path={path}
                            exact={exact}
                            // name={title}
                            render={(props) => (
                              <StaffLayout
                                logo={useLogo}
                                sidebar={sidebar}
                                header={header}
                                // isAuthenticated={() => validateAuth()}
                                {...props}
                              >
                                <route.component {...props} />
                              </StaffLayout>
                            )}
                          />
                        ) : null;
                      })}
                    </>
                  )}
                  <Route path="*">
                    <AgentLayout logo={useLogo} sidebar={true} header={true}>
                      {/* isAuthenticated={() => validateAuth()} */}
                      <ErrorPage />
                    </AgentLayout>
                  </Route>
                </Switch>
              </Suspense>
            </BrowserRouter>
          </ThemeProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </Provider>
  );
}

export default App;
