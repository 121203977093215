import React, { useState } from "react";
import { Attachment, PictureAsPdf } from "@mui/icons-material";
import SecureLinkImg from "~src/components/secureLinkImg";
import axios from "~src/helpers/configAxios";

interface ImagePreviewerProps {
  id: string;
  name: string;
  title: string;
  className?: string;
  value?: string;
  onClickImage?: any;
}

const ImagePreviewer: React.FC<ImagePreviewerProps> = ({
  id,
  name,
  title,
  className,
  value,
  onClickImage,
  ...rest
}) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const handleError = () => {
    setIsInvalid(true);
  };

  const handlePreview = () => {
    if (!!onClickImage) {
      onClickImage();
    } else {
      axios
        .get(value, {
          responseType: "arraybuffer",
        })
        .then((result) => {
          const filenameRegex = /filename\*?=([^']*'')?([^;]*)/;
          const filename = decodeURI(filenameRegex.exec(result.headers["content-disposition"])[2]);
          const uriString =
            "data:" +
            result.headers["content-type"] +
            ";base64," +
            encodeURI(Buffer.from(result.data, "binary").toString("base64"));
          let a = document.createElement("a");
          a.href = uriString;
          a.download = decodeURI(filename);
          a.click();
        });
    }
  };

  return (
    <div className={` ${className}`} {...rest}>
      <div className="flex justify-center">
        <button
          className="w-20r h-20r rounded-lg border-2 flex justify-center border-blue-400 text-blue-400 hover:shadow-md hover:border-blue-600 hover:text-blue-600 duration-200 bg-white"
          onClick={handlePreview}
          type="button"
        >
          {value ? (
            isInvalid ? (
              <PictureAsPdf className="self-center" />
            ) : (
              <SecureLinkImg
                src={value}
                className="rounded-lg max-h-full self-center"
                onError={handleError}
              />
            )
          ) : (
            <Attachment className="self-center" />
          )}
        </button>
      </div>
      <div className="text-center mt-2">{title}</div>
    </div>
  );
};

export default ImagePreviewer;
