import React from "react";
import { useTranslation } from "react-i18next";
import { MenulistIcon } from "./Menu";
import { useAuthContext } from "~src/context/Auth";

const STAFF_PATH = "/staffs";

const StaffMenu = () => {
  const { t } = useTranslation();
  
  const { session } = useAuthContext();
  const { permissions } = session;
  const allowImportOrder = permissions.some(
    (p) => p.slug === "ORDER" && p.action === "IMPORT"
  );

  return (
    <ul>
      <li>
        <div className="py-3 text-xs font-bold text-center text-white bg-white bg-opacity-25">
          การซื้อขาย
        </div>
      </li>
      <MenulistIcon
        icon="Order"
        label="คำสั่งซื้อ"
        link={`${STAFF_PATH}/orders`}
        active={!!window.location.href.match(/orders/g)}
      />
      <MenulistIcon
        icon="Order"
        label="ติดตามเอกสาร"
        link={`${STAFF_PATH}/installments`}
        active={!!window.location.href.match(/installments/g)}
      />
      {t("customQuote") && (
        <MenulistIcon
          icon="Document"
          label="งานนอกระบบ"
          link={`${STAFF_PATH}/order-custom/create`}
          active={!!window.location.href.match(/order-custom/g)}
        />
      )}
      <MenulistIcon
        icon="Renew"
        label="ใบเตือน"
        link={`${STAFF_PATH}/renewals-list`}
        active={!!window.location.href.match(/renewals-list$/g)}
      />
      <li>
        <div className="py-3 text-xs font-bold text-center text-white bg-white bg-opacity-25">
          กรมธรรม์
        </div>
      </li>
      <MenulistIcon
        icon="Policytracking"
        label="กรมธรรม์"
        link={`${STAFF_PATH}/policies`}
        active={!!window.location.href.match(/policies$/g)}
      />
      <MenulistIcon
        icon="Endrosement"
        label="กรมธรรม์รอต่ออายุ"
        link={`${STAFF_PATH}/renewal`}
        active={!!window.location.href.match(/renewal$/g)}
      />
      {t("policyFromOldSystem") && (
        <MenulistIcon
          icon="Endrosement"
          label="กรมธรรม์รอต่ออายุ (เก่า)"
          link={`${STAFF_PATH}/renewals`}
          active={!!window.location.href.match(/renewals$/g)}
        />
      )}
      <li>
        <div className="py-3 text-xs font-bold text-center text-white bg-white bg-opacity-25">
          Content Manager
        </div>
      </li>
      <MenulistIcon icon="PromotionIcon" label="Promotion" link={`${STAFF_PATH}/cms/promotion`} />
      <MenulistIcon
        icon="Image"
        label="Banners"
        link={`${STAFF_PATH}/banner`}
        active={!!window.location.href.match(/banner$/g)}
      />
      <MenulistIcon icon="TrainingIcon" label="Training" link={`${STAFF_PATH}/trainings`} />
      {allowImportOrder && (
        <>
          <li>
            <div className="py-3 text-xs font-bold text-center text-white bg-white bg-opacity-25">
              Logging
            </div>
          </li>
          <MenulistIcon
            icon="Document"
            label="ประวัติการนำเข้า"
            link={`${STAFF_PATH}/import-histories`}
            active={!!window.location.href.match(/import-histories/g)}
          />
        </>
      )}
      <li>
        <div className="py-3 text-xs font-bold text-center text-white bg-white bg-opacity-25">
          User Manager
        </div>
      </li>
      <MenulistIcon
        icon="PersonIcon"
        label="Hero"
        link={`${STAFF_PATH}/agent-list`}
        active={!!window.location.href.match(/agent-list$/g)}
      />
      <MenulistIcon
        icon="Book"
        label="สัญญา"
        link={`${STAFF_PATH}/contracts`}
        active={!!window.location.href.match(/contracts$/g)}
      />
      <MenulistIcon
        icon="Book"
        label="PDPA"
        link={`${STAFF_PATH}/pdpas`}
        active={!!window.location.href.match(/pdpas$/g)}
      />
      {t("partner") && (
        <MenulistIcon
          icon="PartnerIcon"
          label="Partner"
          link={`${STAFF_PATH}/partner-list`}
          active={!!window.location.href.match(/partner-list$/g)}
        />
      )}
    </ul>
  );
};

export default StaffMenu;
