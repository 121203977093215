import React from "react";
import "./switcher.scss";
const Switcher = ({ labels, name, id, active, ...rest }) => {
  return (
    <div className="d-flex">
      <div className="switcher">
        {labels.map((label, index) => (
          <label key={index} className={label.value == active ? "active" : ""}>
            {label.label}
            <input
              type="radio"
              name={name}
              id={`${id}_${index}`}
              value={label.value}
              checked={active == label.value}
              {...rest}
            />
          </label>
        ))}
      </div>
    </div>
  );
};

export default Switcher;
