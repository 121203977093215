import Decimal from "decimal.js";
import _isEmpty from "lodash/isEmpty";
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import Tab from "./tab";
import BankTransferMethod from "./method/bankTransfer";
import CreditMethod from "./method/credit";
import InstallmentMethod from "./method/installment";
import TwoCTwoP from "./method/2c2p";
import UserCreditMethod from "./method/userCredit";
import InsurancePlanSummary2 from "~/src/components/insurancePlanSummary/insurancePlanSummary2";
import QRPayment from "~src/components/payments/QRPayment";
import useKbankPaymentButton from "~src/components/payments/useKbankPaymentButton";
import Stepper from "~/src/components/stepper/Stepper";

import dictionary from "~src/data.yaml";
import PaymentsAPIClient, {
  CreateCCPaymentContentResponse,
  CreateCCPaymentResponse,
  PaymentEventResponse,
  usePaymentsAPI,
  UsePaymentsAPIOptions,
} from "~src/api/Payments";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { ThankyouPageContextProvider, useThankyouPageContext } from "~src/context/ThankyouPage";
import useOrder from "~src/hooks/useOrder";
import APIError from "~src/models/APIError";
import { CheckoutSummaryState } from "~src/models/CheckoutState";
import {
  createPaymentEventAction,
  createResetAction,
  createUpdateAction,
  createUpdateActionWithPayment,
  initState as initThankyouPageState,
  restoreStateFromStorage as restoreThankyouPageState,
  clearStateFromStorage as clearThankyouPageStateFromStorage,
  saveStateToStorage as saveThankyouPageState,
} from "~src/reducer/ThankyouPageReducer";
import { CMI } from "~src/types/cmi";
import { Insured } from "~src/types/insured";
import { Order, Status as OrderStatus } from "~src/types/order";
import { Payment } from "~src/types/payment";
import { Quote } from "~src/types/quote";

import "./payments.scss";
import PaymentsAlertModal, {
  PaymentsAlertModalProps,
} from "~src/components/modal/payments/PaymentsAlertModal";
import Button from "~src/components/form/button/Button";

export interface PaymentsPageState {
  orderID?: number;
  orderNumber?: string;
  status?: OrderStatus;
  summary?: CheckoutSummaryState;
  customerName?: string;
  insuredCategory?: string;
  insureds?: Insured[];
  quoteInfo?: Quote[];
  amount?: string;
  paymentMethod?: string;
  addOrRemoveCMI?: Partial<CMI>;
  shortDescCriteria?: string;
  shortDescPolicy?: string;
  insurerCode?: string;
  includeWhTax?: boolean;
}

export interface PaymentsPageProps {}

export default function Payments(props: PaymentsPageProps) {
  return (
    <PageContextProvider>
      <PaymentsInner {...props} />
    </PageContextProvider>
  );
}

type PaymentsInnerProps = PaymentsPageProps;

var PaymentsInner = ({}: PaymentsInnerProps) => {
  const location = useLocation<PaymentsPageState>();

  const locStateKey = "payments_location_state";
  if (location.state) {
    localStorage.setItem(locStateKey, JSON.stringify(location.state));
  } else if (window.localStorage) {
    const locStateStr = localStorage.getItem(locStateKey);
    location.state = locStateStr ? JSON.parse(locStateStr) : {};
  }

  const {
    orderID,
    orderNumber,
    summary,
    insuredCategory,
    quoteInfo,
    insureds,
    addOrRemoveCMI,
    includeWhTax,
  } = location.state;

  const history = useHistory();
  const { onError, setAlert, setPreloading } = usePageContext();
  // TODO: Refactor/Consolidate flow here
  // const { paymentsAPI } = usePaymentsAPI({
  //   onRedirect: (url) => history.push(url),
  //   onError: (err) => setAlert(err.message),
  // });

  const { isLoading: isOrderLoading, order } = useOrder(orderID, { suffix: "info", onError });

  const defaultTab = useMemo(
    () => (process.env.APP_NAME === "mti" ? "2c2p" : "qr") /* "bankTransfer" */,
    []
  );

  const [state, setState] = useState(() => ({
    currentTab: defaultTab,
    activeInstallmentCardIndex: -1,
    isKBankCCButtonLoading: false,
    startCountDown: false,
    qrId: "",
    qrDisplay: false,
    qrImage: "",
    countdownTime: new Date().getTime(),
    intervalId: null,
    minutes: 5,
    seconds: "00",
  }));

  const firstPayment = useMemo<Payment | undefined>(() => {
    if (isOrderLoading || !order) {
      return undefined;
    }

    return order.payments[0];
  }, [isOrderLoading, order]);

  const countdownIntervalRef = useRef();
  // const kbankCcFormRef = useRef();
  // const kbankCcScriptTagRef = useRef();

  const handleSetIsKbankCCButtonLoading = useCallback((loading: boolean) => {
    setState((st) => ({
      ...st,
      isKBankCCButtonLoading: loading,
    }));
  }, []);

  const handleTabChanged = useCallback((selectedTab: string) => {
    setState((st) => ({
      ...st,
      currentTab: selectedTab,
    }));
  }, []);

  const handleActiveInstallmentCardChanged = useCallback((index: number) => {
    setState((st) => ({
      ...st,
      activeInstallmentCardIndex: index,
    }));
  }, []);

  const _firstLoad = window.localStorage ? localStorage["payment-firstLoad"] : false;

  useEffect(() => {
    //   // To reload the page once only at the first render
    //   // It is due to the KBank cc payment button script tag loading.
    //   if (window.localStorage) {
    //     if (!firstLoad) {
    //       localStorage["payment-firstLoad"] = true;
    //       // TODO: Revise this later
    //       console.log(
    //         "reloading page to complete KBank CC script:",
    //         kbankCcScriptTagRef.current?.src
    //       );
    //       history.push({
    //         pathname: locPath,
    //         state: locState,
    //       });
    //       //window.location.reload();
    //     }
    //     // else {
    //     //   setIsCcScriptLoadCompleted(true);
    //     //   localStorage.removeItem("payment-firstLoad");
    //     // }
    //   }
    // return () => {
    //   // reset loading counter when unmounting
    //   console.log("Payments page unmounted");
    //   setKbankCcState({ ...kbankCcState, isLoading: true, loadingCounter: 1 });
    // };
  }, []);

  useEffect(() => {
    setPreloading(isOrderLoading || state.isKBankCCButtonLoading); // || other required states
  }, [isOrderLoading, state.isKBankCCButtonLoading]);

  // useEffect(() => {
  //   // TODO: Revise this later
  //   let subs;
  //   let unsubscribe;
  //   if (orderID && !isOrderLoading && !isKbankCCButtonLoading) {
  //     [subs, unsubscribe] = paymentsAPI.subscribePaymentChannel(
  //       orderID,
  //       undefined,
  //       handlePaymentEventResponse
  //     );
  //     subscriptionRef.current = subs;
  //     unsubscribeFnRef.current = unsubscribe;
  //   }
  //   // if (consumer === undefined) subScribePaymentChannel();

  //   //cleanup
  //   return () => {
  //     if (subs && unsubscribe) {
  //       console.log("Cleaning up Subscription for PaymentChannel:", subs);
  //       //TEST
  //       console.log("unsubscribe:", unsubscribe);
  //       unsubscribe("page unmounted");
  //       if (subscriptionRef.current === subs) {
  //         subscriptionRef.current = undefined;
  //       }
  //       if (unsubscribeFnRef.current === unsubscribe) {
  //         unsubscribeFnRef.current = undefined;
  //       }
  //     }
  //   };
  // }, [orderID, isOrderLoading, isKbankCCButtonLoading]);

  // DEPRECATED: QRPayment component has its own countdown
  // const tick = () => {
  //   const currentTime = new Date().getTime();
  //   const countdownDate = state.countdownTime;
  //   const distanceToDate = countdownDate - currentTime;

  //   if (distanceToDate > 1) {
  //     const minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
  //     let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);
  //     seconds = `0${seconds}`.slice(-2);

  //     setState({ ...state, minutes, seconds });
  //   } else {
  //     countdownIntervalRef.current && clearInterval(countdownIntervalRef.current);
  //     setState({
  //       ...state,
  //       startCountDown: false,
  //       qrImage: "",
  //     });
  //   }
  // };

  // TODO: Revise this later
  useDeepCompareEffectNoCheck(() => {
    // DEPRECATED: QRPayment component has its own countdown
    // if (state.currentTab !== "qr") {
    //   countdownIntervalRef.current = setInterval(tick, 1000);
    // }

    // cleanup
    return () => {
      countdownIntervalRef.current && clearInterval(countdownIntervalRef.current);
    };
  }, [state]);

  const updateQRInfo = (data) => {
    setState({ ...state, ...data });
  };

  const gotoCheckoutSummary = useCallback(() => {
    history.push({
      pathname: `/checkout/${orderID}/summary`,
      state: {
        orderId: orderID,
      },
    });
  }, [history, orderID]);

  const gotoUploadPayslip = (paymentMethod) => {
    const stateUpload = {
      orderID,
      summary,
      paymentMethod,
      totalAmount: summary.paymentTotalAmount,
    };

    history.push({
      pathname: `/checkout/orders/${orderID}/upload-payslip`,
      state: stateUpload,
    });
  };

  function getLabelSteppers(step) {
    const result = [
      { label: "ข้อมูลผู้ถือกรมธรรม์", active: false },
      { label: "ข้อมูลรถยนต์", active: false },
      { label: "ยืนยันข้อมูล", active: false },
      { label: "ชำระเงิน", active: false },
    ];
    result[step].active = true;

    return result;
  }

  return (
    <div className="payment-wrapper">
      <div className="col-12">
        <h1 className="text-center">
          <i className="material-icons">lock</i>ยืนยันคำสั่งซื้อ
        </h1>
        <Stepper steppers={getLabelSteppers(3)} />
      </div>
      <div className="flex flex-row gap-3 tablet:flex-col laptop:flex-col">
        <div className="col-8">
          {!isOrderLoading && order && (
            <ThankyouPageContextProvider
              summary={summary}
              order={order}
              setPreloading={setPreloading}
            >
              <PaymentsPageTabs
                summary={summary}
                order={order}
                payment={firstPayment}
                defaultTab={defaultTab}
                setIsKbankCCButtonLoading={handleSetIsKbankCCButtonLoading}
                onTabChanged={handleTabChanged}
                onActiveInstallmentCardChanged={handleActiveInstallmentCardChanged}
                gotoCheckoutSummary={gotoCheckoutSummary}
                gotoUploadPayslip={gotoUploadPayslip}
              />
            </ThankyouPageContextProvider>
          )}
        </div>
        <div className="col-4">
          <InsurancePlanSummary2
            summary={summary}
            insuredCategory={insuredCategory}
            includeWhTax={includeWhTax}
            addOrRemoveCMI={addOrRemoveCMI}
            quoteInfo={quoteInfo}
            currentTab={state.currentTab}
            activeInstallmentCardIndex={state.activeInstallmentCardIndex}
          />
        </div>
      </div>
    </div>
  );
};

interface PaymentsPageTabsProps {
  summary: CheckoutSummaryState;
  order: Order;
  payment?: Payment;
  defaultTab?: string;
  setIsKbankCCButtonLoading?: (state: boolean) => void;
  gotoCheckoutSummary?: () => void;
  onTabChanged?: (selectedTab: string) => void;
  gotoUploadPayslip: (paymentMethod: string) => void;
  onActiveInstallmentCardChanged?: (idx: number) => void;
}

var PaymentsPageTabs = ({
  summary,
  order,
  payment,
  defaultTab,
  setIsKbankCCButtonLoading,
  gotoCheckoutSummary,
  onTabChanged,
  onActiveInstallmentCardChanged,
  gotoUploadPayslip,
}: PaymentsPageTabsProps) => {
  const history = useHistory();
  const { onError, setAlert, setPreloading, reloadPage } = usePageContext();
  const {
    dispatch: dispatchThankyou,
    gotoPage: gotoThankyou,
    state: thankyouPageState,
  } = useThankyouPageContext();

  // TEST
  // useWhatChanged([setAlert], "setAlert", "PaymentsPageTabs#1", "PaymentsPageTabs#1");
  // TODO: Refactor/Consolidate flow here
  const paymentsAPIOpts = useMemo(
    () => ({
      redirect: (url) => {
        window.location.href = url;
        // history.push(url);
      },
      onError: (err) => setAlert(err.message),
    }),
    [setAlert]
  );

  // TEST
  // useWhatChanged([paymentsAPIOpts], "paymentsAPIOpts", "PaymentsPageTabs#2", "PaymentsPageTabs#2");
  const { paymentsAPI } = usePaymentsAPI(paymentsAPIOpts);

  const { orderNumber, paymentTotalAmount } = summary;
  const { order_id: orderID, payments_summary } = order;

  const [isShowAlertModal, setIsShowAlertModal] = React.useState(false);
  const [alertMessages, setAlertMessages] = React.useState<string[]>([]);
  const [alertModalProps, setAlertModalProps] = React.useState<Partial<PaymentsAlertModalProps>>();
  // TODO: Revise this later
  const [kbankBackyardElement, setKbankBackyardElement] = useState<HTMLElement>();
  const [kbankErrors, setKbankErrors] = useState<Error[]>([]);
  const [kbankCCLastResponse, setKBankCCLastResponse] = useState<
    CreateCCPaymentResponse | undefined
  >();

  const kbankPaymentButtonOpts = useMemo(
    () => ({
      amount: summary.paymentTotalAmountIncludedCCFee,
      orderID,
      orderNumber,
      reloadPage,
      onLoaded: (_el, tempFormElement) => {
        tempFormElement && tempFormElement.classList.add("hidden");
      },
      onError: (err) => setAlert(err, { useKbankPaymentButton: (err as APIError<any>).details }),
    }),
    [summary.paymentTotalAmountIncludedCCFee, orderID, orderNumber, reloadPage, setAlert]
  );

  const {
    isLoading: isKbankCCButtonLoading,
    loadingCounter: kbankCCButtonLoadingCounter,
    formElement: kbankCCFormElement,
    formRefCallback: kbankCCFormRefCallback,
  } = useKbankPaymentButton(kbankPaymentButtonOpts);

  const [currentTab, setCurrentTab] = useState(defaultTab);
  const [isValidChannel, setIsValidChannel] = useState(true);
  const subscriptionRef = useRef();
  const unsubscribeFnRef = useRef();

  const tabAlerters = useMemo(() => {
    const _map: Record<string, (Error) => void> = {};
    for (const tab of ["2c2p", "qr", "usercredit"]) {
      _map[tab] = (err: Error) => setAlert(err.message, { [tab]: (err as APIError<any>).details });
    }

    return _map;
  }, []);

  const gotoOrderDetail = useCallback(() => {
    history.push(`/orders/${orderID}/details`);
  }, [history, orderID]);

  const handleShowAlertModal = useCallback(
    (messages: string[], props?: Partial<PaymentsAlertModalProps>) => {
      setAlertMessages(messages);
      setAlertModalProps(props || {});
      setIsShowAlertModal(true);
    },
    []
  );

  const alertModalActionsRenderer = useCallback(() => {
    return (
      <Button type="button" color="primary" role="button" onClick={(e) => gotoOrderDetail()}>
        {dictionary.payment.to_order_detail}
      </Button>
    );
  }, []);

  const handleTabChange = (selectedTab) => {
    setCurrentTab(selectedTab);
    document.querySelectorAll(".payment-methods-tab").forEach((t) => {
      t.classList.remove("tab-on");
    });
    const headTab = document.getElementById(`tab-${selectedTab}`);
    headTab.classList.add("tab-on");

    onTabChanged && onTabChanged(selectedTab);
  };

  function handlePaymentEventResponse(evt: PaymentEventResponse) {
    dispatchThankyou(createPaymentEventAction(evt));

    // if (evt.status === "completed") {
    if (evt.payment_status === "paid") {
      gotoThankyou();
    }
  }

  function handleSetKbankBackyardRef(element) {
    if (!element) {
      return;
    }

    setKbankBackyardElement(element);
  }

  function handleKbankCCFormSubmit(evt: React.FormEvent) {
    evt.preventDefault();

    if (order.payments_summary.is_installments) {
      handleShowAlertModal(dictionary.payment.cash_installment_already_set_up);
      return;
    }

    const _formEl = evt.target as HTMLElement;
    const formInputs = _formEl.querySelectorAll("input");
    const formData: Record<string, any> = [...formInputs].reduce(
      (m, el) => ({ ...m, [el.name]: el.value }),
      {}
    );
    const formBtns = _formEl.querySelectorAll("button");

    (async function () {
      try {
        unstable_batchedUpdates(() => {
          setKbankErrors([]);
          setAlert(undefined);
          setPreloading(true);
        });
        const respData = await paymentsAPI.createCCPayment({
          ...formData,
          orderID,
          token: formData.token || "",
        });
        setKBankCCLastResponse(respData);

        // TODO: Revise this later
        if (respData.content) {
          dispatchThankyou(createUpdateActionWithPayment(respData.content.payment));
          dispatchThankyou(
            createUpdateAction({ isRedirectingToKbankCC: respData.redirecting }, true)
          );
          if (!respData.redirecting && respData.content.payment.status === "paid") {
            gotoThankyou();
          }
          // setPreloading(false);
          // setTimeout(() => history.go(0), 1);
        }
      } catch (err) {
        console.error(err);
        // TODO: Revise this later
        let userErrMsgs: string[] = [];
        let statusCode = 0;
        if (err instanceof APIError && err.response) {
          statusCode = err.response.status;
          const respData = err.response.data?.content;
          setKBankCCLastResponse(respData);
          if (respData && "provider_result" in respData) {
            const providerResult = (respData as CreateCCPaymentContentResponse).provider_result;
            if (providerResult) {
              userErrMsgs = [
                `[${providerResult.code}] ${providerResult.message} - ${providerResult.transaction_state}`,
              ];
            }
          }
        } else {
          userErrMsgs = Array.isArray(err.message) ? err.message : [err.message];
        }
        const _kbankErrors = userErrMsgs.map(
          (errMsg) => new APIError(errMsg, { statusCode, cause: err })
        );

        // alert(`${dictionary.payment.credit_card_error}\n${userErrMsgs.join("\n")}`);
        unstable_batchedUpdates(() => {
          setKbankErrors(_kbankErrors);
          // setAlert(err.message, err.details);
          setPreloading(false);
        });

        // setTimeout(reloadPage, 100);
        for (let i = 0; i < formBtns.length; i++) {
          const btn = formBtns.item(i);
          btn.classList.remove("processing");
        }
      }
    })();
  }

  useEffect(() => {
    const restoredThankyouState = restoreThankyouPageState();
    if (restoredThankyouState) {
      dispatchThankyou(createResetAction(restoredThankyouState));
      clearThankyouPageStateFromStorage();
    } else {
      dispatchThankyou(
        createResetAction(initThankyouPageState(summary, order, order.payments?.[0]?.pay_method))
      );
    }

    // TODO: Revise the flow here later
    let subs;
    let unsubscribe;
    let firstPaidPayment = order.payments.find((payment) => payment.status === "paid");
    if (firstPaidPayment || order.status === "waiting_verify") {
      firstPaidPayment = firstPaidPayment || order.payments.find((payment) => !!payment.payslip);

      dispatchThankyou(createUpdateActionWithPayment(firstPaidPayment));

      gotoThankyou();
    } else {
      payment && dispatchThankyou(createUpdateActionWithPayment(payment));
    }

    [subs, unsubscribe] = paymentsAPI.subscribePaymentChannel(
      orderID,
      undefined,
      handlePaymentEventResponse
    );
    subscriptionRef.current = subs;
    unsubscribeFnRef.current = unsubscribe;
    // cleanup
    return () => {
      if (subs && unsubscribe) {
        unsubscribe("page unmounted");
        if (subscriptionRef.current === subs) {
          subscriptionRef.current = undefined;
        }
        if (unsubscribeFnRef.current === unsubscribe) {
          unsubscribeFnRef.current = undefined;
        }
      }
    };
  }, []);

  useEffect(() => {
    setIsKbankCCButtonLoading && setIsKbankCCButtonLoading(isKbankCCButtonLoading);
  }, [isKbankCCButtonLoading]);

  useEffect(() => {
    if (order.payments_summary.is_installments) {
      handleShowAlertModal([
        dictionary.payment.invalid_payment_channel,
        dictionary.payment.cash_installment_already_set_up,
      ]);
      setIsValidChannel(false);
      return;
    }

    setIsValidChannel(true);
  }, [currentTab, order.payments_summary.is_installments]);

  useDeepCompareEffectNoCheck(() => {
    if (thankyouPageState.isRedirectingToKbankCC) {
      dispatchThankyou(createUpdateAction({ isRedirectingToKbankCC: false }));
      clearThankyouPageStateFromStorage();

      unstable_batchedUpdates(() => {
        setKbankErrors([new APIError("Transaction Incomplete", { statusCode: 400 })]);
        setKBankCCLastResponse({
          success: false,
          content: {
            payment,
            provider_result: {
              is_pending: true,
              is_card_info_error: false,
              is_card_auth_error: false,
            },
          },
        });
      });
    }
  }, [thankyouPageState.isRedirectingToKbankCC, thankyouPageState.payment]);

  return (
    <div className="payment-methods">
      <div className="payment-methods-tabs">
        <div className="flex justify-between">
          <h6>เลือกวิธีชำระเงิน</h6>
          <p className="pt-2 text-xs order-number ">
            Order: <span>{orderNumber}</span>
          </p>
        </div>
        <div className="payment-methods-tab-head">
          {process.env.APP_NAME === "mti" ? (
            <>
              <Tab
                role="button"
                id="tab-2c2p"
                className="payment-methods-tab tab-on"
                onClick={() => handleTabChange("2c2p")}
                label="QR Code/บัตรเคริต"
              />
              <Tab
                role="button"
                id="tab-usercredit"
                className="payment-methods-tab"
                onClick={() => handleTabChange("usercredit")}
                label="User Credits"
              />
            </>
          ) : (
            <>
              <Tab
                role="button"
                id="tab-cc"
                className={`payment-methods-tab ${isKbankCCButtonLoading ? "disabled" : ""}`}
                onClick={() => handleTabChange("cc")}
                label="บัตรเครดิต"
              />
              <Tab
                role="button"
                id="tab-qr"
                className="payment-methods-tab tab-on"
                onClick={() => handleTabChange("qr")}
                label="QR Code"
              />
              <Tab
                role="button"
                id="tab-bankTransfer"
                className="payment-methods-tab"
                onClick={() => handleTabChange("bankTransfer")}
                label="โอนเงิน"
              />
              {/* {!_isEmpty(summary.installmentInfo) && (
                <Tab
                  role="button"
                  id="tab-installment"
                  className="payment-methods-tab"
                  onClick={() => handleTabChange("installment")}
                  label="ผ่อนชำระ"
                />
              )} */}
            </>
          )}
        </div>
      </div>
      {currentTab === "bankTransfer" ? (
        <BankTransferMethod
          summary={summary}
          disabled={!isValidChannel}
          onGoBack={gotoCheckoutSummary}
          gotoUploadPayslip={() => gotoUploadPayslip("bank_transfer")}
        />
      ) : null}

      {currentTab === "qr" ? (
        <QRPayment
          orderID={orderID}
          amount={new Decimal(paymentTotalAmount)}
          totalAmount={new Decimal(paymentTotalAmount)}
          order={order}
          isInstallments={false}
          isShowOrderInfoLines={false}
          paymentsAPI={paymentsAPI}
          disabled={!isValidChannel}
          onGoBack={gotoCheckoutSummary}
          onSuccess={(_p, _o, data) => handlePaymentEventResponse(data)}
          onError={tabAlerters.qr}
        />
      ) : // <QRMethod
      //   summary={summary}
      //   orderId={orderId}
      //   state={state}
      //   updateQRInfo={updateQRInfo}
      //   paymentsAPI={paymentsAPI}
      // />
      null}

      {currentTab === "cc" ? (
        <CreditMethod
          summary={summary}
          order={order}
          isLoading={isKbankCCButtonLoading}
          kbankBackyardElement={kbankBackyardElement}
          kbankCCFormElement={kbankCCFormElement}
          kbankCCLastResponse={kbankCCLastResponse}
          disabled={!isValidChannel}
          lastErrors={kbankErrors}
        />
      ) : null}

      {currentTab === "2c2p" && (
        <TwoCTwoP
          orderId={orderID}
          order={order}
          summary={summary}
          disabled={!isValidChannel}
          onError={tabAlerters["2c2p"]}
        />
      )}

      {currentTab === "usercredit" && (
        <UserCreditMethod
          orderId={orderID}
          order={order}
          summary={summary}
          disabled={!isValidChannel}
          onError={tabAlerters.usercredit}
        />
      )}

      {currentTab === "installment" && (
        <InstallmentMethod
          orderId={orderID}
          order={order}
          summary={summary}
          disabled={!isValidChannel}
          onActiveInstallmentCardChanged={onActiveInstallmentCardChanged}
        />
      )}

      {isShowAlertModal && (
        <PaymentsAlertModal
          onClose={() => setIsShowAlertModal(false)}
          order={order}
          payment={payment}
          title={alertModalProps.title}
          icon={alertModalProps.icon}
          messages={alertMessages}
          renderActions={alertModalProps.renderActions || alertModalActionsRenderer}
        />
      )}

      <div id="kbankBackyard" ref={handleSetKbankBackyardRef}>
        <form
          id="kbankCCForm"
          method="POST"
          action=""
          ref={kbankCCFormRefCallback}
          className="hidden"
          onSubmit={handleKbankCCFormSubmit}
        />
      </div>
    </div>
  );
};
