import React from "react";

interface Props {
  className?: string;
  label: any;
  require?: boolean;
  htmlForLabel?: string;
  children?: any;
  prefix?: any;
  subfix?: any;
}

const InlineForm: React.FC<Props> = ({ label, require, htmlForLabel, className, children, prefix, subfix }) => {
  let mainColumnSize = 12;
  if (subfix) mainColumnSize -= 2;
  return (
    <div className="row justify-content-between align-items-center space-bottom px-2">
      <div className="col-md-8">
        <label className={`${className}`} htmlFor={htmlForLabel}>
          {(typeof label === 'string' || label instanceof String) ?
            <>{label} {require ? <span className="text-error">*</span> : null}</>
          : label}
        </label>
      </div>
      <div className="col-md-4 flex">
        {prefix && <div className="mr-1 flex-none px-0 grid">{prefix}</div>}
        <div className={`flex-grow px-0`}>
          {children}
        </div>
        {subfix && <div className="ml-1 flex-none px-0 grid justify-items-end">{subfix}</div>}
      </div>
    </div>
  );
};

export default InlineForm;
