import React from "react";

import "./stepper.scss";

const Stepper = ({ steppers }) => {
  return (
    <ul className="stepper">
      {steppers.map((step, index) => (
        <li key={index} className={step.active ? "stepper-list active" : "stepper-list"}>{step.label}</li>
      ))}
    </ul>
  );
};

export default Stepper;
