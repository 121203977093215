import { AxiosResponse } from "axios";
import axios from "~src/helpers/configAxios";
import { ProductDto } from "~src/types/product";
import {
  CreateQuotationPayload,
  PlaceOrderParam,
  PlaceOrderResponse,
  QuotationResponse,
  TravelProductResponse,
  TravelSearchProductResponse,
} from "./redux";

const postGenerateSecureToken = (): Promise<AxiosResponse<{ link_url: string; token: string }>> => {
  const url = `/api/v1/secure_links/generate`;
  const params = {
    resource_type: "public",
    endpoint: "/travels",
  };
  return axios.post(url, params);
};

const getCountries = () => {
  return axios.get(`api/countries`);
};

const getTravelProducts = (
  queries: string
): Promise<AxiosResponse<TravelSearchProductResponse>> => {
  return axios.get(`/api/travels/search?${queries}`);
};

const getTravelProductById = (
  id: number,
  token: string
): Promise<AxiosResponse<{ content: TravelProductResponse }>> => {
  return axios.get(`/api/products/${id}?token=${token}`);
};

const creatQuotation = (
  params: CreateQuotationPayload
): Promise<AxiosResponse<QuotationResponse>> => {
  return axios.post(`api/quotations`, params);
};

const placeOrder = (param: PlaceOrderParam): Promise<AxiosResponse<PlaceOrderResponse>> => {
  return axios.post(`api/quotations/place_order`, param);
};

export default {
  postGenerateSecureToken,
  getCountries,
  getTravelProducts,
  getTravelProductById,
  creatQuotation,
  placeOrder,
};
