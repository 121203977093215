import React from "react";

const Tab = (props) => {
  const { label, ...rest } = props;
  return (
    <div {...rest}>
      <div>{label}</div>
    </div>
  );
};

export default Tab;
