import React from "react";
import CommonModal from "~src/components/modal/common";
import { useAppDispatch, useAppSelector } from "~src/store";
import ProductCard from "./components/ProductCard";
import { closeCoverageModal, coverageModalState, travelProductsData } from "./redux";
import CoverageModal from "./CoverageModal";

const ProductList = () => {
  const products = useAppSelector(travelProductsData);
  const coverageModal = useAppSelector(coverageModalState);

  const dispatch = useAppDispatch();

  return (
    <>
      {products.map((data, index) => (
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" key={index}>
          <ProductCard data={data} />
        </div>
      ))}

      <CommonModal open={coverageModal.open} onClose={() => dispatch(closeCoverageModal())}>
        <CoverageModal />
      </CommonModal>
    </>
  );
};

export default ProductList;
