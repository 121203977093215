import { TravelCoverages, TravelCoveragesOptional } from "~src/types/travel";

export const coverageMapping: Record<keyof TravelCoverages, string> = {
  hijacking_coverage: "การจี้เครื่องบิน",
  dismemberment_coverage: "",
  overseas_medical_expenses_coverage:
    "ผลประโยชน์เงินชดเชยระหว่างการพักรักษาตัวในโรงพยาบาลในฐานะผู้ป่วยในอันเนื่องมาจากอุบัติเหตุและการเจ็บป่วย ขณะเดินทางไปต่างประเทศ สูงสุดไม่เกิน 30 วัน วันละ",
  follow_up_medical_expenses_coverage: "",
  emergency_evacuation_and_repatriation_coverage:
    "การเคลื่อนย้ายเพื่อการรักษาพยาบาลฉุกเฉิน หรือการเคลื่อนย้ายกลับสู่ประเทศไทย จ่ายตามจริงสูงสุด",
  mortal_remain_repatriation_coverage: "การส่งศพกลับสู่ประเทศ จ่ายตามจริงสูงสุด",
  trip_cancellation_postponement_coverage: "การยกเลิกหรือการเลื่อนการเดินทาง จ่ายตามจริงสูงสุด",
  trip_curtailment_coverage: "การลดจำนวนวันเดินทาง จ่ายตามจริงสูงสุด",
  flight_delay_coverage: "การล่าช้าของเที่ยวบิน จ่ายค่าสินไหมทดแทนสูงสุดกรณีใดกรณีหนึ่ง",
  loss_or_damage_baggage_coverage:
    "ความสูญเสียหรือความเสียหายของกระเป๋าเดินทางและ/หรือทรัพย์สินส่วนตัว และ/หรืออุปกรณ์การเล่นกอล์ฟ จ่ายตามจริงสูงสุด",
  loss_or_damage_laptop_coverage:
    "ความสูญเสียหรือความเสียหายของคอมพิวเตอร์โน้ตบุ๊ค จ่ายตามจริงสูงสุด",
  baggage_delay_coverage: "การล่าช้าของกระเป๋าเดินทางสูงสุด",
  personal_liability_coverage: "ความรับผิดต่อบุคคลภายนอก จ่ายตามจริงสูงสุด",
  hospital_visitation_coverage: "",
  overseas_daily_hospital_confinement_benefit_coverage: "ค่าชดเชยรายวัน",
  damage_or_loss_money_coverage: "ความสูญเสียหรือความเสียหายของเงินส่วนตัว จ่ายตามจริงสูงสุด",
  damage_or_loss_travel_document_coverage:
    "ความสูญเสียหรือความเสียหายของเอกสารการเดินทาง จ่ายตามจริงสูงสุด",
  missed_connecting_flight_coverage: "การพลาดการต่อเที่ยวบินสูงสุด",
  rental_car_excess_coverage:
    "ความเสียหายส่วนแรกสำหรับรถเช่า (จากภัยที่ได้รับความคุ้มครองตามกรมธรรม์รถยนต์) จ่ายตามจริงสูงสุด",
  optional: "",
};

export const coverageMappingOptional: Record<keyof TravelCoveragesOptional, string> = {
  cash_advance_coverage: "",
  compassionate_overseas_visit: "การเดินทางเพื่อเยี่ยมผู้ป่วยที่โรงพยาบาล จ่ายตามจริงสูงสุด",
  dismemberment_coverage_1_14: "- ผู้เอาประกันที่มีอายุ 1-14 ปีบริบูรณ์",
  dismemberment_coverage_15_75: "- ผู้เอาประกันที่มีอายุ 15-75 ปีบริบูรณ์",
  dismemberment_coverage_76_80: "- ผู้เอาประกันที่มีอายุ 76-80 ปี บริบูรณ์",
  dismemberment_coverage_1_75: "- ผู้เอาประกันที่มีอายุ 1-75 ปี บริบูรณ์",
  follow_up_medical_expenses_coverage_1_14: "- ผู้เอาประกันที่มีอายุ 1-14 ปีบริบูรณ์",
  follow_up_medical_expenses_coverage_15_75: "- ผู้เอาประกันที่มีอายุ 15-75 ปีบริบูรณ์",
  follow_up_medical_expenses_coverage_1_75: "- ผู้เอาประกันที่มีอายุ 1-75 ปีบริบูรณ์",
  follow_up_medical_expenses_coverage_76_80: "- ผู้เอาประกันที่มีอายุ 76-80 ปีบริบูรณ์",
  hole_in_one_reward_maximum_payable: " ผลประโยชน์ รางวัลพิเศษสำหรับ โฮล-อิน-วัน จ่ายตามจริงสูงสุด",
  loss_or_damage_golf: "",
  overseas_daily_hospital_confinement_benefit:
    "เงินชดเชยระหว่างพักรักษาตัวในโรงพยาบาลในฐานะผู้ป่วยในอันเนื่องมาจากอุบัติเหตุและการเจ็บป่วย ขณะเดินทางไปต่างประเทศ",
  overseas_medical_expenses_coverage_1_14: "- ผู้เอาประกันที่มีอายุ 1-14 ปีบริบูรณ์",
  overseas_medical_expenses_coverage_15_75: "- ผู้เอาประกันที่มีอายุ 15-75 ปีบริบูรณ์",
  overseas_medical_expenses_coverage_1_75: "- ผู้เอาประกันที่มีอายุ 1-75 ปีบริบูรณ์",
  overseas_medical_expenses_coverage_76_80: "- ผู้เอาประกันที่มีอายุ 76-80 ปีบริบูรณ์",
  rental_car_excess_maximum_payable: "ความเสียหายส่วนแรกสำหรับรถเช่า",
  return_of_children: "ส่งผู้เยาว์กลับประเทศ",
};
