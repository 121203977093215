import React, { useState, useContext } from "react";

import filterContext from "./store.js";

import Select from "../../../../components/filter/Select";
import yaml from "../../../../data.yaml";

const optionsLists = [
  {
    label: "ทั้งหมด",
    value: "all",
    isChecked: false,
  },
  {
    label: "ซ่อมอู่",
    value: "garage",
    isChecked: false,
  },
  {
    label: "ซ่อมศูนย์",
    value: "dealer",
    isChecked: false,
  },
];

const RepairFacilityFilter = ({ fnCallbackFilterValues, active }) => {
  const [options, setOptions] = useState(optionsLists);
  const [state, dispatch] = useContext(filterContext);
  const [activeButton, setActiveButton] = useState(active);

  const handleChange = (value) => {
    const opts = [...options];
    let filterRepairFacility = [];

    opts.filter((o) => {
      o.isChecked = o.value === value && true;
      if (o.isChecked) filterRepairFacility = [o.value];
    });

    setOptions(opts);
    setActiveButton(true);
    dispatch({
      type: "UPDATE",
      payload: {
        ...state,
        filterRepairFacility,
      },
    });

    fnCallbackFilterValues("repairFacility", filterRepairFacility);
  };

  const renderLabel = (stateValues) => {
    if (stateValues.length == 0) {
      return "ประเภทการซ่อม";
    } else {
      let label = "";
      if (stateValues[0] === "all") {
        label = `${yaml.repair_facility.garage}, ${yaml.repair_facility.dealer}`;
      } else {
        label = `${yaml.repair_facility[stateValues[0]]}`;
      }
      return label;
    }
  };

  return (
    <div>
      <Select
        type="radio"
        label={
          activeButton ? (
            <span className="text-primary inline-block ml-1">
              {renderLabel(state.filterRepairFacility)}
            </span>
          ) : (
            renderLabel(state.filterRepairFacility)
          )
        }
        options={options}
        active={activeButton}
        onChangeValue={handleChange}
      />
    </div>
  );
};

export default RepairFacilityFilter;
