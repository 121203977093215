import QueryString from "query-string";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CheckSignIcon from "~src/components/icons/svg/CheckSignIcon";
import { formatNumberWithCurrency } from "/src/helpers";
import useFetch from "/src/hooks/useFetch";
import { parseDateEN } from "~src/helpers/dateTime";
import "./thankyou.scss";

const ButtonThankyou = ({ isPublic, isBankTransfer }) => {
  if (!isPublic) {
    if (isBankTransfer) {
      return (
        <a href="/orders" role="button" className="btn-follow-policy">
          รายการคำสั่งซื้อ
        </a>
      );
    }
    return (
      <a href="/policies/follow-up" role="button" className="btn-follow-policy">
        ติดตามกรมธรรม์
      </a>
    );
  }

  return "";
};

const PaymentMethod = ({ method, isPublic, state = null }) => {
  if (isPublic) {
    return (
      <>
        <p>{`คุณ ${state?.insureds[0]?.firstname} ${state?.insureds[0]?.lastname} เลขที่อ้างอิงคำสั่งซื้อ ${state?.orderNumber}`}</p>
        <p>บริษัทฯ ได้ส่งมอบรายละเอียดไปยังอีเมล {state?.insureds[0]?.email}</p>
      </>
    );
  }

  switch (method) {
    case "bank_transfer":
      return (
        <>
          <p>เมื่อเจ้าหน้าที่ยืนยันการชำระเงินเรียบร้อยแล้ว</p>
          <p>
            ท่านสามารถตรวจสอบรายการได้ที่เมนู <span />
            <span className="gettmore-icon-policytracking"> </span>
            <span className="font-medium">ติดตามกรมธรรม์</span>
          </p>
        </>
      );
    default:
      return (
        <>
          <p>เจ้าหน้าที่จะทำการตรวจสอบรายการของท่าน เพื่อประสานงานกับบริษัทประกันภัยต่อไป</p>
          <p>
            ท่านสามารถตรวจสอบรายการได้ที่เมนู <span />
            <span className="gettmore-icon-policytracking"> </span>
            <span className="font-medium">ติดตามกรมธรรม์</span>
          </p>
        </>
      );
  }
};

const InsuranceDetails = ({ data, state = null, isPublic }) => {
  if (!isPublic) {
    return (
      <div className="text-left">
        <p className="text-lg font-medium">{data.insureds}</p>
        <p className="text-xs text-grayCustom-1">{data.shortDescPolicy}</p>
        <p className="text-xs text-grayCustom-1">{data.shortDescCriteria}</p>
        <p className="card-thankyou-amount">
          {formatNumberWithCurrency(data.amount)} <span className="text-sm">ต่อปี</span>
        </p>
      </div>
    );
  }

  return (
    <div className="text-left">
      <p className="text-lg font-medium">{state?.summary.insurerName}</p>
      <p className="text-xs text-grayCustom-1">แผน: {state?.summary.title}</p>
      <p className="text-xs text-grayCustom-1">
        ผู้เดินทาง {state?.shortDescCriteria} ไป {state?.destination_name}
      </p>
      <p className="text-lg text-primary-color">
        {parseDateEN(state?.details.cover_start_date)} -{" "}
        {parseDateEN(state?.details.cover_end_date)}
      </p>
    </div>
  );
};

const Thankyou = (props) => {
  const { t } = useTranslation();
  const { search, pathname } = window.location;
  const [query] = useState(QueryString.parse(search));
  const insurerCode = query.insurerCode ?? props?.location.state?.insurerCode;
  const isPublic = pathname.includes("public");
  const [state] = useState(props?.location?.state ?? null);
  const [data, setData] = useState({});
  const [iconUrl, setIconUrl] = useState("");
  const [nextUrl, setNextUrl] = useState("");
  const [isBankTransfer, setIsBankTransfer] = useState(false);
  const { response, error, isLoading } = useFetch(`/api/insurers?code=${insurerCode}`);

  useEffect(() => {
    setData({
      ...query,
    });

    switch (query.paymentMethod) {
      case "bank_transfer":
        setNextUrl("/orders");
        setIsBankTransfer(true);
        break;
      case "qr":
        setNextUrl("/policies/follow-up");
        break;
      case "cc":
        setNextUrl("/policies/follow-up");
        break;
      default:
        setNextUrl("/orders");
        break;
    }
  }, []);

  return (
    <div className=" mt-28">
      <div className="p-8 mx-auto text-center card card-thankyou">
        <div className="card-thankyou-title pb-2">
          <CheckSignIcon />
          <h1 className="my-2">
            {isPublic ? "ชำระเงินเรียบร้อยแล้ว" : "ได้รับการแจ้งงานเรียบร้อยแล้ว"}
          </h1>
          {/* <PaymentMethod
            method={query.PaymentMethod}
            orderNumber={data.orderNumber}
            isPublic={isPublic}
            state={state}
          /> */}
        </div>
        <div className="mb-4">
          {!isPublic && (
            <p>
              เลขที่ใบสั่งซื้อ: <span className="font-semibold text-lg">{data.orderNumber}</span>
            </p>
          )}
          {isBankTransfer && (
            <div className="batch batch-verify">
              <span />
              <span>รอตรวจสอบรายการ</span>
            </div>
          )}
        </div>
        <div className="items-center justify-center flex">
          {/* {!isLoading && (
            <img src={response.content[0].icon} width="100" alt={response.content[0].name} />
          )} */}

          {/* <InsuranceDetails data={data} state={state} isPublic={isPublic} /> */}
        </div>
        <div className="card-thankyou-footer">
          {isPublic && (
            <p className="text-primary mb-4">
              กรมธรรม์อิเล็กทรอนิกส์ (e-policy) จะส่งให้ภายใน 1 วันทำการ{" "}
              <p>ตามเงื่อนไขที่บริษัทฯ กำหนด</p>
            </p>
          )}

          <p className="text-gray-400">
            หากต้องการความช่วยเหลือ สามารถติดต่อได้ที่ Line: {t("lineAccount")} หรือ{" "}
            {t("contactMail")}
          </p>

          <a href="/orders" role="button" className="btn-follow-policy">
            รายการคำสั่งซื้อ
          </a>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
