import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { Add, Save, Delete, ChevronLeft, CheckCircleOutline, Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { serialize } from "object-to-formdata";

import {
  FormGroup,
  TextField,
  DatePickerField,
  SelectField,
  Switcher,
} from "../../../../components/form";
import AddressForm from "../../OrderCustom/components/form/AddressForm";

import AgentInfo from "../../../../models/AgentInfo";
import LicenseInfo from "../../../../models/LicenseInfo";
import ResetPassword from "./form/ResetPassword";
import { useAuthenticate } from "~src/helpers/authen";

import axios from "../../../../helpers/configAxios";
import { messages } from "../../../../helpers/messages";
import {
  titleAgentTHOptions,
  activeStatusOptions,
  bankOptions,
  mtlSalesBranchOptions,
  educationLevelOptions,
} from "../../../../helpers/options";

import { checkEmpty } from "../../../../helpers/helpers";
import { parseDate, dateFormat } from "../../../../helpers/dateTime";

import SectionHeader from "../../../../components/layout/staff/SectionHeader";
import ErrorPage from "../../../../components/errorPage";

import { AlertNotification, StyleType } from "../../../../components/alert/alertNotification";

import useFetch from "../../../../hooks/useFetch";
import UploadImage from "~src/components/form/uploadImage/UploadImage";
import KnightLicence from "./modal/KnightLicence";
import HeroLicence from "./modal/HeroLicence";
import { ContractCard, contractAcceptedSelector } from "~src/features/contracts";
import { PdpaCard, pdpaAcceptedSelector } from "~src/features/pdpa";
import { useAppDispatch, useAppSelector } from "~src/store";
import Stepper from "./components/Stepper";
import { CustomerAddress } from "~src/types/customerInfo";

interface AgentEditorProps {
  match: any;
  location: any;
}

const AgentEditor: React.FC<AgentEditorProps> = ({ match, location }) => {
  const history = useHistory();

  const { code } = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const agentId = match.params.id == "add" ? undefined : match.params.id;
  const { accessType } = useAuthenticate();
  const isStaff = accessType === "STAFF";

  const [isError, setIsError] = useState(false);

  const agentForm = useForm<AgentInfo>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const agentFetch = agentId !== undefined && useFetch(`/api/agents/${agentId}`);
  const licenseFetch = agentId !== undefined && useFetch(`/api/agents/${agentId}/licenses`);
  const roleFetch = isStaff && useFetch(`/api/roles`);
  const partnerFetch = isStaff && useFetch(`/api/partners`);
  const [agentInfo, setAgentInfo] = useState<AgentInfo>(null);
  const [licensesInfo, setLicensesInfo] = useState<LicenseInfo[]>([
    {
      license_no: "",
      insurance_type: "non-life",
      issued_date: dateFormat(new Date()),
      expired_date: dateFormat(new Date()),
      renewal_times: 0,
    },
  ]);
  const [rolesInfo, setRolesInfo] = useState([]);
  const [partnersInfo, setPartnersInfo] = useState([]);

  const [roleOptions, setRoleOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);

  const [isLoadingAgent, setIsLoadingAgent] = useState(false);
  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState(0);
  const [alertText, setAlertText] = useState("ดำเนินการเสร็จสิ้น");
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertType, setAlertType] = useState<StyleType>("success");
  const [uploadFilesError, setUploadFilesError] = useState([]);
  const [openModalLicence, setOpenModalLicence] = useState(false);
  const [agentRole, setAgentRole] = useState("hero");

  const [stepActive, setStepActive] = useState(0);
  const [payload, setPayload] = useState(null);

  const { t } = useTranslation();

  const agentFormId = "AgentFormId";

  useEffect(() => {
    if (agentId === undefined) {
      let updateData: AgentInfo = {
        ...agentInfo,
        has_agent_card: false,
        has_agent_experience: false,
        has_motor_license: false,
        licenses: [
          {
            license_no: "",
            insurance_type: "non-life",
            issued_date: dateFormat(new Date()),
            expired_date: dateFormat(new Date()),
            renewal_times: 0,
          },
        ],
      };
      if (code !== undefined) updateData.recommender_code = code.toString();

      setAgentInfo(updateData);
    }
  }, []);

  useEffect(() => {
    if (agentId !== undefined && !agentFetch.isLoading && !licenseFetch.isLoading) {
      const agentData = agentFetch.response?.content;
      const licensesData = licenseFetch.response?.content?.licenses;

      agentData.recommender_code = code;
      agentData.licenses = licensesData;
      agentData.has_motor_license = licensesData?.length > 0;

      if (agentData.licenses.length <= 0)
        agentData.licenses = [
          {
            license_no: "",
            insurance_type: "non-life",
            issued_date: dateFormat(new Date()),
            expired_date: dateFormat(new Date()),
            renewal_times: 0,
          },
        ];

      setLicensesInfo(licensesData);
      setAgentInfo(agentData);

      setIsError(false);
    }
    if (agentFetch.error || licenseFetch.error) {
      setIsError(true);
    }
  }, [
    agentFetch.response,
    agentFetch.error,
    agentFetch.isLoading,
    licenseFetch.response,
    licenseFetch.error,
    licenseFetch.isLoading,
  ]);

  useEffect(() => {
    if (!roleFetch?.isLoading) setRolesInfo(roleFetch?.response?.content);
  }, [roleFetch?.response, roleFetch?.error, roleFetch?.isLoading]);

  useEffect(() => {
    if (!partnerFetch.isLoading) setPartnersInfo(partnerFetch?.response?.content);
  }, [partnerFetch?.response, partnerFetch?.error, partnerFetch?.isLoading]);

  useEffect(() => {
    if (!roleFetch) return;

    const roleOptions = rolesInfo?.map((role) => {
      return {
        label: role.name,
        value: role.code,
      };
    });

    setRoleOptions(roleOptions);
  }, [rolesInfo]);

  useEffect(() => {
    if (!partnerFetch) return;

    const partnerOptions = [
      {
        label: "ไม่มีกลุ่ม",
        value: "",
      },
      ...partnersInfo?.map((partner) => {
        return {
          label: partner.name,
          value: partner.code,
        };
      }),
    ];

    setPartnerOptions(partnerOptions);
  }, [partnersInfo]);

  const showAlert = (text: string): void => {
    setAlertText(text);
    setIsAlertActive(true);
  };

  const hideAlert = (): void => {
    setIsAlertActive(false);
  };

  const handleChangeAgentInput = (e, name) => {
    const { value } = e.target;

    setAgentInfo({
      ...agentInfo,
      [name]: value,
    });
  };

  const handleChangeBankAccountInput = (e, name) => {
    const value = e.target.value.replace(/[^0-9]/gi, "");
    const count = value.split("");
    if (count.length > 10) return;

    setAgentInfo({
      ...agentInfo,
      [name]: value,
    });
  };

  const handleChangeAgentBooleanInput = (e, name) => {
    const { value } = e.target;

    setAgentInfo({
      ...agentInfo,
      [name]: value === "true",
    });
  };

  const handleChangeAgentPartner = (e) => {
    const { value } = e.target;
    const partner = partnersInfo.find((p) => p.code === value);

    setAgentInfo({
      ...agentInfo,
      partner: partner,
    });
  };

  const handleChangeAgentRole = (e) => {
    const { value } = e.target;
    const role = rolesInfo.find((r) => r.code === value);

    setAgentInfo({
      ...agentInfo,
      role: role,
    });
  };

  const handleChangeAgentDate = (date, name) => {
    setAgentInfo({
      ...agentInfo,
      [name]: date,
    });
  };

  const handleChangeCitizenId = (e, name) => {
    const value = e.target.value.replace(/[^0-9]/gi, "");
    const count = value.split("");
    if (count.length > 13) return;

    setAgentInfo({
      ...agentInfo,
      [name]: value,
    });
  };

  const handleChangeUploadedImage = (url, name) => {
    setAgentInfo({
      ...agentInfo,
      [name]: url,
    });
  };

  const isRequiredFieldsValid = (data, requiredFileKeys) => {
    let filesError = [];

    for (const key in requiredFileKeys) {
      type key = keyof typeof agentInfo;
      const name = (requiredFileKeys[key] + "_url") as key;

      if (
        (!data[requiredFileKeys[key]] || data[requiredFileKeys[key]].length <= 0) &&
        !agentInfo[name]
      ) {
        filesError.push(requiredFileKeys[key]);
      }
    }
    setUploadFilesError(filesError);

    if (filesError.length > 0) return false;
    return true;
  };

  const handleSubmitAgentForm = (data, e) => {
    const fileKey = ["citizen_card_file", "account_book_file", "motor_license_file"];
    let requiredFileKeys = ["citizen_card_file", "account_book_file"];
    if (agentInfo?.has_motor_license) requiredFileKeys.push("motor_license_file");

    e.preventDefault();

    data.role = agentInfo?.role?.code;
    data.partner = agentInfo?.partner?.code;

    if (!agentInfo?.has_motor_license && licensesInfo.length > 0) {
      data.licenses_attributes = {};
      licensesInfo.forEach((l, index) => {
        if (l.id) {
          data.licenses_attributes[index] = {
            ...l,
            _destroy: true,
          };
        }
      });
    }

    data.has_agent_card = agentInfo?.has_agent_card;
    data.has_agent_experience = agentInfo?.has_agent_experience;

    for (const key in fileKey) {
      if (data[fileKey[key]].length > 0) data[fileKey[key]] = data[fileKey[key]][0];
      else delete data[fileKey[key]];
    }

    if (!agentId && !isStaff) {
      setPayload(data);
      setAgentRole(agentInfo?.has_agent_card ? "knight" : "hero");
      setStepActive(1);

      return;
    }

    hideAlert();
    setIsLoadingAgent(true);

    postUpdateAgent(agentId, data)
      .then((result) => {
        if (result.data?.success) {
          setAlertType("success");
          showAlert("ดำเนินการเสร็จสิ้น");

          if (isStaff) {
            const newAgentId = result.data.content?.new_agent_id || agentId;
            window.location.href = `/staffs/agent/${newAgentId}/details`;
          } else {
            window.location.href = `/register/thankyou`;
          }
        } else {
          setIsLoadingAgent(false);
          setAlertType("error");
          showAlert("พบความผิดพลาดในการดำเนินการ: " + result.data?.messages);
        }
      })
      .catch(() => {
        setIsLoadingAgent(false);
        setAlertType("error");
        showAlert("พบความผิดพลาดในการดำเนินการ กรุณาลองใหม่ในภายหลัง");
      });
  };

  const handleSubmitChangePassword = (data, e) => {
    data.role = agentInfo?.role.code;

    e.preventDefault();
    hideAlert();
    postUpdateAgent(agentId, serialize(data))
      .then(() => {
        setAlertType("success");
        showAlert("ดำเนินการเสร็จสิ้น");
      })
      .catch(() => {
        setIsLoadingAgent(false);
        setAlertType("error");
        showAlert("พบความผิดพลาดในการดำเนินการ กรุณาลองใหม่ในภายหลัง");
      });
  };

  const postUpdateAgent = (agentId, data) => {
    if (agentId) return axios.patch(`/api/agents/${agentId}`, serialize(data));
    else return axios.post(`/api/agents`, data);
  };

  const addEmptyLicense = (e) => {
    if (e) e.preventDefault();

    const newLicense = {
      license_no: "",
      insurance_type: "non-life",
      issued_date: dateFormat(new Date()),
      expired_date: dateFormat(new Date()),
      renewal_times: 0,
    };
    const newLicenseIndex = licensesInfo.length;
    setLicensesInfo((licenses) => [...licenses, newLicense]);
    setSelectedLicenseIndex(newLicenseIndex);
  };

  const handleChangeLicenseInput = (e, name, index) => {
    const { value } = e.target;
    const licenses = agentInfo.licenses;
    const targetLicense = licenses[index];
    targetLicense[name] = value;
    licenses.splice(index, 1, targetLicense);

    setAgentInfo({
      ...agentInfo,
      licenses: licenses,
    });
  };

  const handleChangeLicenseNumberInput = (e, name, index) => {
    const value = e.target.value;
    const count = value.split("");
    if (count.length > 10) return;

    const licenses = agentInfo.licenses;
    const targetLicense = licenses[index];
    targetLicense[name] = value;
    licenses.splice(index, 1, targetLicense);

    setAgentInfo({
      ...agentInfo,
      licenses: licenses,
    });
  };

  const handleChangeLicenseDate = (date, name, index) => {
    const licenses = agentInfo.licenses;
    const targetLicense = licenses[index];
    targetLicense[name] = date;
    licenses.splice(index, 1, targetLicense);
    setAgentInfo({
      ...agentInfo,
      licenses: licenses,
    });
  };

  const hanndleContrackOk = (id) => {
    setPayload({
      ...payload,
      contract: {
        contract_id: id,
      },
    });
    setStepActive(2);
  };

  const hanndlePdpaOk = (params) => {
    const data = payload;
    const serializedData = serialize(data);

    serializedData.append("pdpa[id]", params.id);

    params.questions.forEach((question, index) => {
      serializedData.append(`pdpa[questions][${index}][id]`, question.id);
      serializedData.append(`pdpa[questions][${index}][answer]`, question.answer);
    });

    if (data?.licenses_attributes) {
      data.licenses_attributes.forEach((license, index) => {
        serializedData.append(`licenses_attributes[${index}][id]`, license.id);
        serializedData.append(
          `licenses_attributes[${index}][insurance_type]`,
          license.insurance_type
        );
        serializedData.append(`licenses_attributes[${index}][issued_date]`, license.issued_date);
        serializedData.append(`licenses_attributes[${index}][expired_date]`, license.expired_date);
        serializedData.append(`licenses_attributes[${index}][license_no]`, license.license_no);
      });
    }

    postUpdateAgent(agentId, serializedData)
      .then((result) => {
        if (result.data?.success) {
          setAlertType("success");
          showAlert("ดำเนินการเสร็จสิ้น");

          if (isStaff) {
            const newAgentId = result.data.content?.new_agent_id || agentId;
            window.location.href = `/staffs/agent/${newAgentId}/details`;
          } else {
            window.location.href = `/register/thankyou`;
          }
        } else {
          setIsLoadingAgent(false);
          setAlertType("error");
          showAlert("พบความผิดพลาดในการดำเนินการ: " + result.data?.messages);
        }
      })
      .catch(() => {
        setIsLoadingAgent(false);
        setAlertType("error");
        showAlert("พบความผิดพลาดในการดำเนินการ กรุณาลองใหม่ในภายหลัง");
      });
  };
  const handleChangeAgentAddress = (address: CustomerAddress) => {
    setAgentInfo({
      ...agentInfo,
      ...address
    })
  }

  return (
    <div>
      {isError ? (
        <ErrorPage />
      ) : (
        <form
          id={agentFormId}
          autoComplete="off"
          onSubmit={agentForm.handleSubmit(handleSubmitAgentForm)}
        >
          <div
            className={`fixed w-screen h-screen z-50 flex items-center justify-center col-span-2 text-white text-center transition duration-200 bg-black opacity-0 md:col-span-1 pointer-events-none ${
              (agentFetch.isLoading || isLoadingAgent || licenseFetch.isLoading) &&
              "opacity-90 pointer-events-auto"
            }`}
          >
            <span className="inline-block p-4 align-center animate-pulse">กำลังโหลด...</span>
          </div>
          {isStaff ? (
            <>
              <SectionHeader
                className="w-full col-span-2 p-4"
                title={isStaff ? "แก้ไขข้อมูล Hero" : "สมัครตัวแทน"}
                breadcrumbs={isStaff ? [{ url: "#", label: "ข้อมูล Hero", current: true }] : []}
                searchComponent={
                  <button
                    className="mr-4 pr-3 pl-1 py-2 text-base transition duration-200 text-gray-500 rounded-lg focus:outline-none hover:bg-gray-200 hover:text-gray-600"
                    onClick={() => history.goBack()}
                    type="button"
                  >
                    <ChevronLeft className="mr-1" />
                    <span className="inline-block align-bottom">ยกเลิก</span>
                  </button>
                }
              />
            </>
          ) : (
            <Stepper active={stepActive} />
          )}
          {stepActive === 0 && (
            <>
              <div className="grid grid-cols-2 px-4 pb-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-7 col-span-2">
                  <div className="col-span-1">
                    <div className="col-span-2 px-3 py-4 mt-3 bg-white rounded-md shadow-lg md:col-span-1">
                      <div className="flex items-center justify-between w-full col-span-1 col-start-1 text-xl bg-custom-gray-sm mb-3">
                        <span className="p-2">ข้อมูลตัวแทน</span>
                      </div>
                      {isStaff && (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3">
                          {roleOptions.length == 2 ? (
                            <div className="col-span-2">
                              <FormGroup htmlForLabel="role" label="ประเภทตัวแทน">
                                <Switcher
                                  id="role"
                                  name="role"
                                  active={agentInfo?.role.code}
                                  onChange={handleChangeAgentRole}
                                  inputRef={agentForm.register}
                                  labels={roleOptions}
                                />
                              </FormGroup>
                            </div>
                          ) : (
                            <div className="col-span-1">
                              <FormGroup htmlForLabel="role" label="ประเภทตัวแทน">
                                <SelectField
                                  id="role"
                                  name="role"
                                  value={agentInfo?.role?.code}
                                  onChange={handleChangeAgentRole}
                                  inputRef={agentForm.register}
                                  error={agentForm.errors.role?.code?.message}
                                  options={roleOptions}
                                />
                              </FormGroup>
                            </div>
                          )}
                        </div>
                      )}
                      <div className="grid grid-cols-2 md:grid-cols-2 gap-x-3">
                        {t("partner") && isStaff && (
                          <div className="col-span-1 col-start-1">
                            <div className="w-full">
                              <FormGroup label="กลุ่มตัวแทน" htmlForLabel="partner">
                                <SelectField
                                  id="partner"
                                  name="partner"
                                  value={agentInfo?.partner?.code}
                                  onChange={handleChangeAgentPartner}
                                  inputRef={agentForm.register}
                                  error={agentForm.errors.partner?.code?.message}
                                  options={partnerOptions}
                                  require
                                />
                              </FormGroup>
                            </div>
                          </div>
                        )}
                        <div className="col-span-1 col-start-1">
                          <div className="w-full">
                            <FormGroup label="คำนำหน้าชื่อ" htmlForLabel="title">
                              <SelectField
                                id="title"
                                name="title"
                                value={agentInfo?.title}
                                onChange={(e) => handleChangeAgentInput(e, "title")}
                                inputRef={agentForm.register}
                                error={agentForm.errors.title?.message}
                                options={titleAgentTHOptions}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="col-span-1 col-start-1">
                          <FormGroup label="ชื่อ" htmlForLabel="firstname">
                            <TextField
                              id="firstname"
                              name="firstname"
                              value={agentInfo?.firstname}
                              onChange={(e) => handleChangeAgentInput(e, "firstname")}
                              inputRef={agentForm.register}
                              error={agentForm.errors.firstname?.message}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-span-1">
                          <FormGroup label="นามสกุล" htmlForLabel="lastname">
                            <TextField
                              id="lastname"
                              name="lastname"
                              value={agentInfo?.lastname}
                              onChange={(e) => handleChangeAgentInput(e, "lastname")}
                              inputRef={agentForm.register}
                              error={agentForm.errors.lastname?.message}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-span-1">
                          <FormGroup label="วันเดือนปีเกิด" htmlForLabel="dob">
                            <DatePickerField
                              type="text"
                              id="dob"
                              name="dob"
                              defaultView="decade"
                              maxDate={parseDate(dateFormat(new Date()))}
                              onChange={(e) => handleChangeAgentInput(e, "dob")}
                              callbackvalue={(date) => handleChangeAgentDate(date, "dob")}
                              inputRef={agentForm.register({
                                ...messages.validateBirthday,
                              })}
                              value={agentInfo?.dob ? agentInfo.dob : dateFormat(new Date())}
                              error={agentForm.errors.dob?.message}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-span-1">
                          <FormGroup
                            label="เลขที่บัตรประชาชน / เลขประจําตัวผู้เสียภาษี"
                            htmlForLabel="citizen_id"
                          >
                            <TextField
                              id="citizen_id"
                              name="citizen_id"
                              value={agentInfo?.citizen_id}
                              onChange={(e) => handleChangeCitizenId(e, "citizen_id")}
                              inputRef={agentForm.register({
                                ...messages.validateIdCard,
                              })}
                              error={agentForm.errors.citizen_id?.message}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-span-1">
                          <FormGroup label="ระดับการศึกษา" htmlForLabel="education_level">
                            <SelectField
                              id="education_level"
                              name="education_level"
                              value={agentInfo?.education_level}
                              onChange={(e) => handleChangeAgentInput(e, "education_level")}
                              inputRef={agentForm.register}
                              error={agentForm.errors.education_level?.message}
                              options={educationLevelOptions}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-span-1">
                          <FormGroup label="เบอร์ติดต่อ" htmlForLabel="mobile_no">
                            <TextField
                              id="mobile_no"
                              name="mobile_no"
                              value={agentInfo?.mobile_no}
                              onChange={(e) => handleChangeAgentInput(e, "mobile_no")}
                              inputRef={agentForm.register({
                                ...messages.mobileNumber,
                              })}
                              error={agentForm.errors.mobile_no?.message}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-span-1">
                          <FormGroup label="อีเมล" htmlForLabel="email">
                            <TextField
                              id="email"
                              name="email"
                              value={agentInfo?.email}
                              onChange={(e) => handleChangeAgentInput(e, "email")}
                              inputRef={agentForm.register({
                                ...messages.email,
                              })}
                              error={agentForm.errors.email?.message}
                            />
                          </FormGroup>
                        </div>
                        {isStaff && (
                          <div className="col-span-1">
                            <FormGroup label="สถานะ" htmlForLabel="status">
                              <SelectField
                                id="status"
                                name="status"
                                value={agentInfo?.status}
                                onChange={(e) => handleChangeAgentInput(e, "status")}
                                inputRef={agentForm.register}
                                error={agentForm.errors.status?.message}
                                options={activeStatusOptions}
                              />
                            </FormGroup>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-span-2 px-3 py-4 mt-3 bg-white rounded-md shadow-lg md:col-span-1">
                      <div className="flex items-center justify-between w-full col-span-1 col-start-1 text-xl bg-custom-gray-sm mb-3">
                        <span className="p-2">ที่อยู่ติดต่อได้</span>
                      </div>
                      <div className="grid grid-cols-2 md:grid-cols-2 gap-x-3">
                        <div className="col-span-2 grid grid-cols-4 gap-4">
                          <AddressForm
                            name=""
                            value={agentInfo}
                            onChange={handleChangeAgentAddress}
                            setValue={agentForm.setValue}
                            register={agentForm.register}
                            errors={agentForm.errors}
                            clearErrors={agentForm.clearErrors}
                            control={agentForm.control}
                            includeRoad
                            require={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="col-span-2 px-3 py-4 mt-3 bg-white rounded-md shadow-lg md:col-span-1">
                      <div className="grid grid-cols-2 md:grid-cols-2 gap-x-3">
                        <div className="flex mb-3 items-center justify-between w-full col-span-2 text-xl bg-custom-gray-sm">
                          <span className="p-2">ข้อมูลบัญชีธนาคาร</span>
                        </div>
                        <div className="col-span-1 col-start-1">
                          <FormGroup
                            label="เลขที่บัญชีธนาคาร"
                            htmlForLabel="bank_account_id"
                          >
                            <TextField
                              id="bank_account_id"
                              name="bank_account_id"
                              value={agentInfo?.bank_account_id}
                              onChange={(e) => handleChangeBankAccountInput(e, "bank_account_id")}
                              inputRef={agentForm.register({
                                ...messages.bank_account_id,
                              })}
                              error={agentForm.errors.bank_account_id?.message}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-span-1 col-start-1">
                          <FormGroup label="ชื่อบัญชีธนาคาร" htmlForLabel="bank_account">
                            <TextField
                              id="bank_account"
                              name="bank_account"
                              value={agentInfo?.bank_account}
                              onChange={(e) => handleChangeAgentInput(e, "bank_account")}
                              inputRef={agentForm.register({
                                ...messages.bank_account,
                              })}
                              error={agentForm.errors.bank_account?.message}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-span-1 col-start-1">
                          <FormGroup
                            label="ธนาคารกสิกรไทย เท่านั้น"
                            htmlForLabel="bank_account_provider"
                          >
                            <SelectField
                              id="bank_account_provider"
                              name="bank_account_provider"
                              value={agentInfo?.bank_account_provider}
                              onChange={(e) => handleChangeAgentInput(e, "bank_account_provider")}
                              inputRef={agentForm.register}
                              error={agentForm.errors.bank_account_provider?.message}
                              options={bankOptions}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-span-1">
                          <FormGroup label="สาขาธนาคาร" htmlForLabel="bank_account_branch">
                            <TextField
                              id="bank_account_branch"
                              name="bank_account_branch"
                              value={agentInfo?.bank_account_branch}
                              onChange={(e) => handleChangeAgentInput(e, "bank_account_branch")}
                              inputRef={agentForm.register({
                                ...messages.bank_account_branch,
                              })}
                              error={agentForm.errors.bank_account_branch?.message}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    {!agentId && (
                      <div className="col-span-2 px-3 py-4 mt-3 bg-white rounded-md shadow-lg md:col-span-1">
                        <div className="grid grid-cols-2 gap-x-3 gap-y-2">
                          <div className="flex items-center justify-between w-full col-span-2 text-xl bg-custom-gray-sm">
                            <span className="p-2">ข้อมูลผู้แนะนำ(ถ้ามี)</span>
                          </div>
                          <div className="col-span-1 col-start-1  ">
                            <FormGroup
                              label="รหัสสมาชิก (ผู้แนะนำ)"
                              htmlForLabel="recommender_code"
                            >
                              <TextField
                                id="recommender_code"
                                name="recommender_code"
                                value={agentInfo?.recommender_code}
                                onChange={(e) => handleChangeAgentInput(e, " recommender_code")}
                                inputRef={agentForm.register(messages.recommend_id)}
                                error={agentForm.errors.recommender_code?.message}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-span-1 px-3 py-4 mt-3 bg-white rounded-md shadow-lg md:col-span-2">
                      <div className="grid grid-cols-1 lg:grid-cols-4 items-center justify-between w-full col-span-2 col-start-1 text-xl bg-custom-gray-sm">
                        <span className="p-2 col-span-3">ข้อมูลใบอนุญาตประกันวินาศภัย</span>
                        <div className="m-3 justify-self-end">
                          <Switcher
                            id="has_motor_license"
                            name="has_motor_license"
                            active={agentInfo?.has_motor_license}
                            onChange={(e) => handleChangeAgentBooleanInput(e, "has_motor_license")}
                            inputRef={agentForm.register}
                            error={agentForm.errors.has_motor_license?.message}
                            labels={[
                              { label: "มี", value: true },
                              { label: "ไม่มี", value: false },
                            ]}
                          />
                        </div>
                      </div>
                      {agentInfo?.has_motor_license && (
                        <>
                          {selectedLicenseIndex >= 0 ? (
                            <div className="flex flex-col py-3 space-y-2">
                              <div className="grid grid-rows-2 gap-x-3">
                                <TextField
                                  id="id"
                                  name={`licenses_attributes[${selectedLicenseIndex}].id`}
                                  type="hidden"
                                  value={agentInfo.licenses[selectedLicenseIndex]?.id}
                                  inputRef={agentForm.register}
                                />
                                <input
                                  id="insurance_type"
                                  type="hidden"
                                  name={`licenses_attributes[${selectedLicenseIndex}].insurance_type`}
                                  value="non-life"
                                  ref={agentForm.register}
                                />
                                <div className="col-span-1 col-start-1">
                                  <FormGroup
                                    label="เลขที่ใบอนุญาต"
                                    htmlForLabel="license_no"
                                  >
                                    <TextField
                                      id="license_no"
                                      name={`licenses_attributes[${selectedLicenseIndex}].license_no`}
                                      value={agentInfo.licenses[selectedLicenseIndex].license_no}
                                      onChange={(e) =>
                                        handleChangeLicenseNumberInput(
                                          e,
                                          "license_no",
                                          selectedLicenseIndex
                                        )
                                      }
                                      inputRef={agentForm.register}
                                      error={
                                        agentForm.errors.licenses?.[selectedLicenseIndex]
                                          ?.license_no?.message
                                      }
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-span-1 col-start-1">
                                  <FormGroup
                                    label="วันที่ออกใบอนุญาต"
                                    htmlForLabel="issued_date"
                                  >
                                    <DatePickerField
                                      type="text"
                                      id="issued_date"
                                      name={`licenses_attributes[${selectedLicenseIndex}].issued_date`}
                                      defaultView="decade"
                                      maxDate={parseDate(dateFormat(new Date()))}
                                      onChange={(e) =>
                                        handleChangeLicenseInput(
                                          e,
                                          "issued_date",
                                          selectedLicenseIndex
                                        )
                                      }
                                      callbackvalue={(date) =>
                                        handleChangeLicenseDate(
                                          date,
                                          "issued_date",
                                          selectedLicenseIndex
                                        )
                                      }
                                      inputRef={agentForm.register}
                                      value={
                                        agentInfo.licenses[selectedLicenseIndex]?.issued_date
                                          ? agentInfo.licenses[selectedLicenseIndex].issued_date
                                          : dateFormat(new Date())
                                      }
                                      error={
                                        agentForm.errors.licenses?.[selectedLicenseIndex]
                                          ?.issued_date?.message
                                      }
                                    />
                                  </FormGroup>
                                </div>

                                <div className="col-span-1">
                                  <FormGroup
                                    label="วันหมดอายุใบอนุญาต"
                                    htmlForLabel="expired_date"
                                  >
                                    <DatePickerField
                                      type="text"
                                      id="expired_date"
                                      name={`licenses_attributes[${selectedLicenseIndex}].expired_date`}
                                      defaultView="decade"
                                      minDate={parseDate(
                                        agentInfo.licenses[selectedLicenseIndex].issued_date
                                      )}
                                      onChange={(e) =>
                                        handleChangeLicenseInput(
                                          e,
                                          "expired_date",
                                          selectedLicenseIndex
                                        )
                                      }
                                      callbackvalue={(date) =>
                                        handleChangeLicenseDate(
                                          date,
                                          "expired_date",
                                          selectedLicenseIndex
                                        )
                                      }
                                      inputRef={agentForm.register}
                                      value={
                                        agentInfo.licenses[selectedLicenseIndex]?.expired_date
                                          ? agentInfo.licenses[selectedLicenseIndex].expired_date
                                          : dateFormat(new Date())
                                      }
                                      error={
                                        agentForm.errors.licenses?.[selectedLicenseIndex]
                                          ?.expired_date?.message
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-col justify-center h-full mt-3">
                              {licensesInfo.length > 0 ? (
                                <table className="table-auto text-left">
                                  <thead className="text-1xs text-custom-gray-md">
                                    <tr>
                                      <th className="px-1 py-2 font-normal">เลขที่</th>
                                      <th className="px-1 py-2 font-normal">ประเภท</th>
                                      <th className="px-1 py-2 font-normal">วันที่ออก</th>
                                      <th className="px-1 py-2 font-normal">วันที่หมดอายุ</th>
                                      <th className="px-1 py-2 font-normal">ครั้งที่ออก</th>
                                    </tr>
                                  </thead>
                                  <tbody className="text-xs">
                                    {licensesInfo.map((license, index) => (
                                      <tr
                                        className="transition duration-200 bg-white cursor-pointer hover:bg-pink-100"
                                        onClick={() => setSelectedLicenseIndex(index)}
                                      >
                                        <td className="px-1 py-3 font-normal">
                                          {checkEmpty(license?.license_no)}
                                        </td>
                                        <td className="px-1 py-3 font-normal">
                                          {checkEmpty(license?.insurance_type)}
                                        </td>
                                        <td className="px-1 py-3 font-normal">
                                          {checkEmpty(license?.issued_date)}
                                        </td>
                                        <td className="px-1 py-3 font-normal">
                                          {checkEmpty(license?.expired_date)}
                                        </td>
                                        <td className="px-1 py-3 font-normal">
                                          {checkEmpty(license?.renewal_times)}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              ) : (
                                <div className="text-center text-gray-700">ไม่มีข้อมูลใบอนุญาต</div>
                              )}
                              <button
                                className="px-5 py-3 mt-3 text-white transition duration-300 ease-in-out bg-primary rounded-3xl hover:bg-gray-900"
                                onClick={addEmptyLicense}
                              >
                                <Add className="mr-1" />
                                <span className="inline-block align-bottom">เพิ่มใบอนุญาต</span>
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-span-2 px-3 py-4 mt-3 bg-white rounded-md shadow-lg md:col-span-1">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2">
                        <div className="grid grid-cols-1 lg:grid-cols-4 items-center justify-between w-full col-span-2 col-start-1 text-xl bg-custom-gray-sm">
                          <span className="p-2 col-span-3">คุณเป็นตัวแทนเมืองไทยประกันชีวิต</span>
                          <div className="m-3 justify-self-end">
                            <Switcher
                              id="has_agent_card"
                              name="has_agent_card"
                              active={agentInfo?.has_agent_card}
                              onChange={(e) => handleChangeAgentBooleanInput(e, "has_agent_card")}
                              inputRef={agentForm.register}
                              error={agentForm.errors.has_agent_card?.message}
                              labels={[
                                { label: "เป็น", value: true },
                                { label: "ไม่เป็น", value: false },
                              ]}
                            />
                          </div>
                        </div>
                        {agentInfo?.has_agent_card && (
                          <>
                            <div className="col-span-1">
                              <FormGroup
                                label="รหัสตัวแทนประกันชีวิต"
                                htmlForLabel="mtl_agent_code"
                              >
                                <TextField
                                  id="mtl_agent_code"
                                  name="mtl_agent_code"
                                  value={agentInfo?.mtl_agent_code}
                                  onChange={(e) => handleChangeAgentInput(e, "mtl_agent_code")}
                                  inputRef={agentForm.register(messages.mtl_agent_code)}
                                  error={agentForm.errors.mtl_agent_code?.message}
                                />
                              </FormGroup>
                            </div>
                            <div className="col-span-1">
                              <FormGroup label="ชื่อฝ่ายขาย" htmlForLabel="mtl_sales_branch">
                                <SelectField
                                  id="mtl_sales_branch"
                                  name="mtl_sales_branch"
                                  value={agentInfo?.mtl_sales_branch}
                                  onChange={(e) => handleChangeAgentInput(e, "mtl_sales_branch")}
                                  inputRef={agentForm.register}
                                  error={agentForm.errors.mtl_sales_branch?.message}
                                  options={mtlSalesBranchOptions}
                                />
                              </FormGroup>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-span-2 px-3 py-4 mt-3 bg-white rounded-md shadow-lg md:col-span-1">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2">
                        <div className="grid grid-cols-1 lg:grid-cols-4 items-center justify-between w-full col-span-2 col-start-1 text-xl bg-custom-gray-sm">
                          <span className="p-2 col-span-3">เอกสารเพิ่มเติม</span>
                        </div>
                        <div
                          id="uploadImage"
                          className="col-span-2 mt-2 grid grid-cols-3 lg:grid-cols-4 gap-4"
                        >
                          <FormGroup>
                            <UploadImage
                              id="citizen_card_file"
                              name="citizen_card_file"
                              title="บัตรประชาชน"
                              inputRef={agentForm.register}
                              className="text-xs"
                              value={agentInfo?.citizen_card_file_url}
                              error={uploadFilesError.includes("citizen_card_file_url")}
                              onFileSelected={(url) =>
                                handleChangeUploadedImage(url, "citizen_card_file_url")
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <UploadImage
                              id="motor_license_file"
                              name="motor_license_file"
                              title="รูปบัตรนายหน้า (วินาศภัย)"
                              inputRef={agentForm.register}
                              className="text-xs"
                              value={agentInfo?.motor_license_file_url}
                              error={
                                agentInfo?.has_motor_license
                                  ? uploadFilesError.includes("motor_license_file_url")
                                  : null
                              }
                              onFileSelected={(url) =>
                                handleChangeUploadedImage(url, "motor_license_file_url")
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <UploadImage
                              id="account_book_file"
                              name="account_book_file"
                              title="สมุดบัญชีเงินฝากหน้าแรก"
                              inputRef={agentForm.register}
                              className="text-xs"
                              value={agentInfo?.account_book_file_url}
                              error={uploadFilesError.includes("account_book_file_url")}
                              onFileSelected={(url) =>
                                handleChangeUploadedImage(url, "account_book_file_url")
                              }
                            />
                          </FormGroup>
                        </div>
                      </div>
                      {isStaff && agentId && agentInfo?.status !== "waiting_approval" && (
                        <div className="col-span-2 px-3 py-4 mt-3 bg-white rounded-md shadow-lg md:col-span-1">
                          <ResetPassword
                            formId="reset-password"
                            onSubmit={handleSubmitChangePassword}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-end mx-4 mb-4">
                <button
                  className={`px-3 py-2 w-full md:max-w-xs text-base transition duration-200 text-white bg-primary-color rounded-lg hover:bg-primary-color-dark`}
                  type="submit"
                  form={agentFormId}
                  value="Submit"
                >
                  {agentId ? "บันทึก" : "ดำเนินการสมัครสมาชิก"}
                </button>
              </div>
            </>
          )}
          {stepActive === 1 && <ContractCard onOk={hanndleContrackOk} agentRole={agentRole} />}
          {stepActive === 2 && <PdpaCard onOk={hanndlePdpaOk} />}
        </form>
      )}
      <AlertNotification
        className="bottom-0 right-0 px-4 py-3 m-5 mr-3"
        type={alertType}
        active={isAlertActive}
        setActive={setIsAlertActive}
        enterAnimation="slideInRight"
        exitAnimation="slideOutRight"
        closeDelay={5000}
      >
        <CheckCircleOutline className="mr-3" />
        <span className="inline-block align-bottom">{alertText}</span>
        <button className="ml-3" onClick={hideAlert}>
          <Close />
        </button>
      </AlertNotification>

      {openModalLicence && (
        <>
          {agentInfo?.has_motor_license ? (
            <HeroLicence onClose={() => setOpenModalLicence(false)} />
          ) : (
            <KnightLicence onClose={() => setOpenModalLicence(false)} />
          )}
        </>
      )}
    </div>
  );
};

export default AgentEditor;
