import { format, parse, addDays, addYears, subDays, addWeeks, differenceInYears } from "date-fns";
import moment from "moment";

export const dateFormat = (date, dateFormat = "yyyy-MM-dd") => {
  return format(date, dateFormat);
};

export const dateDuration = (dateFrom, dateTo) => {
  const dtForm = parseDate(dateFrom);
  const dtTo = parseDate(dateTo);
  return `${dtTo.getDate() - dtForm.getDate()} วัน`;
};

export const parseDate = (date, dateFormat = "yyyy-MM-dd") => {
  return parse(date, dateFormat, new Date());
};

// TODO: DEPRECATED: Refactor to use ./date.ts#reformatDateTH()
export const parseDateTH = (date, dateFormat = "yyyy-MM-dd") => {
  if (!date) {
    return "-";
  }

  const date2 = date instanceof Date ? date : parse(date, dateFormat, new Date());

  return date2.toLocaleDateString("th-TH");
};

// TODO: DEPRECATED: Refactor to use ./date.ts#reformatDateEN()
export const parseDateEN = (date, dateFormat = "yyyy-MM-dd") => {
  if (!date) {
    return "-";
  }

  const date2 = date instanceof Date ? date : parse(date, dateFormat, new Date());
  const formatDT = "dd/MM/yyyy";

  return `${format(date2, formatDT)}`;
};

export const yearFromDate = (date, yearNum) => {
  const tomorrow = addDays(date, 1);
  return addYears(tomorrow, yearNum);
};

export const toJsDate = (strDate) => {
  const today = new Date();

  if (strDate) {
    return parse(strDate, "yyyy-MM-dd", today);
  }
  return today;
};

export const addOneYear = (date) => {
  return addYears(date, 1);
};

export const isYouth = (date) => {
  const birthDay = toJsDate(date);
  const age = differenceInYears(birthDay, new Date()) * -1;
  return age < 18;
};

export const rangeDate = (fromDate, toDate) => {
  const fdDate = moment(parseDate(fromDate));
  const tdDate = moment(parseDate(toDate));
  const duration = tdDate.diff(fdDate, "days") + 1;
  return `${duration.toString()} วัน`;
};

export const isBirthdateEqualNow = (date) => {
  const birthDay = moment(parseDate(date));
  const duration = moment().diff(birthDay, "years") + 1;
  // const duration = moment.duration(moment() - birthDay, "milliseconds");
  return duration <= 1;
};
