import React, { useState, useReducer, useEffect } from "react";
import QueryString from "query-string";
import { useTranslation } from "react-i18next";
import { Switch, Button } from "~/src/components/form";
import { useAuthenticate } from "~src/helpers/authen";

import CardLoading from "~/src/components/loading/CardLoading";
import Card from "~/src/components/card/Card";
import ContactCard from "~/src/components/card/ContactCard";
import SectionHeader from "~/src/components/layout/staff/SectionHeader";
import CompareBar from "~/src/components/compare";
import ErrorPage from "~/src/components/errorPage";

import PolicyTypeFilter from "./filter/PolicyTypeFilter";
import RepairFacilityFilter from "./filter/RepairFacilityFilter";
import ExcessDamageCoverageFilter from "./filter/ExcessDamageCoverageFilter";
import InsurersFilter from "./filter/InsurersFilter";
import SortFilter from "./filter/SortFilter";

import axios from "~/src/helpers/configAxios";

import { CompareProvider } from "~/src/context/Compare";
import { initCompareState, compareReducer } from "~/src/reducer/CompareReducer";
import { FilterProvider, reducer, initialState } from "./filter/store";
import AdjustSumInsured from "~/src/components/Forms/AdjustSumInsured";

import "./search.scss";

const Search = ({ location }) => {
  const { search } = location;
  const { t } = useTranslation();
  const { showPaidCommission } = useAuthenticate();

  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productNotFound, setProductNotFound] = useState(false);
  const [showComission, setShowComission] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [activePolicyTypeButton, setActivePolicyTypeButton] = useState(false);
  const [activeInsurerButton, setActiveInsurerButton] = useState(false);
  const useCompareState = useReducer(compareReducer, initCompareState);
  const [state, dispatch] = useReducer(reducer, initialState);
  const queryParams = QueryString.parse(search);
  const [sumInsured, setSumInsured] = useState(queryParams.sum_insured);
  const carInfo = `${queryParams.car_brand} ${queryParams.car_model} ${queryParams.year}`;
  const carModel = queryParams.car_model;
  const vehicleCodeName = queryParams.code_name;
  const [policyTypeContact, setPolicyTypeContact] = useState(queryParams["policy_type[]"]);

  useEffect(() => {
    const paramsPolicyType = toArray(queryParams["policy_type[]"]);
    const searchParams = generateParams(queryParams);

    const fetchData = () => {
      const promiseProducts = axios.get(`/api/products/search2/motor?${searchParams}`);
      const promiseInsurers = axios.get(`/api/insurers?service=motor`);

      Promise.all([promiseProducts, promiseInsurers])
        .then((response) => {
          const [productsResponse, insurersResponse] = response;
          const [insurers, insurersCheckedLists] = setResponse(insurersResponse.data.content);

          dispatch({
            type: "UPDATE",
            payload: {
              ...state,
              carInfo,
              carModel,
              filterPolicyTypes: paramsPolicyType,
              insurersOptions: insurers,
              filterInsurers: insurersCheckedLists,
            },
          });
          setActivePolicyTypeButton(true);
          setActiveInsurerButton(true);
          setFilterLoading(false);
          makeResponse(productsResponse.data, false);
        })
        .catch((error) => {
          setFilterLoading(false);
          makeResponse(null, error);
        });
    };

    fetchData();
  }, []);

  const generateParams = (queryParams) => {
    const carModel = queryParams.car_model;
    const policyType = queryParams["policy_type[]"];

    const paramList = queryParams;
    paramList.policy_type = toArray(policyType);

    delete paramList["policy_type[]"];
    delete paramList.car_model;

    let searchParams = QueryString.stringify(paramList, {
      arrayFormat: "bracket",
    });

    searchParams = `${searchParams}&car_model=${carModel}`;

    return searchParams;
  };

  const toArray = (queryParams) => {
    if (Array.isArray(queryParams)) {
      return queryParams;
    }
    return [queryParams];
  };

  const setResponse = (responseData) => {
    const newObjs = [];
    responseData.forEach((obj) => {
      newObjs.push({
        ...obj,
        value: obj.code,
        label: obj.name,
        isChecked: true,
      });
    });
    const checkedLists = newObjs.filter((obj) => obj.isChecked).map((obj) => obj.code);

    return [newObjs, checkedLists];
  };

  const fnCallbackFilterValues = (type, values) => {
    let policyTypes = state.filterPolicyTypes;
    let repairFacility = state.filterRepairFacility;
    let excessDamageCoverage = state.filterExcessDamageCoverage;
    let insurers = state.filterInsurers;
    let sortObj = state.filterSort;

    switch (type) {
      case "policyType":
        policyTypes = values;
        break;
      case "repairFacility":
        repairFacility = values;
        break;
      case "excessDamageCoverage":
        excessDamageCoverage = values;
        break;
      case "insurers":
        insurers = values;
        break;
      case "sort":
        sortObj = values;
        break;
    }
  };

  const filtersResponse = async () => {
    const policyTypes = state.filterPolicyTypes;
    const repairFacility = state.filterRepairFacility;
    const excessDamageCoverage = state.filterExcessDamageCoverage;
    const insurers = state.filterInsurers;
    const sortObj = state.filterSort;
    const repairFacilities = repairFacility[0] === "all" ? ["garage", "dealer"] : repairFacility;
    const excessDamageCoverages = excessDamageCoverage[0] === "all" ? [] : excessDamageCoverage;
    const params = queryParams;

    params.sum_insured = sumInsured;
    params.policy_type = policyTypes;
    params.repair_facility = repairFacilities;
    params.excess_damage_coverage = excessDamageCoverages;
    params.insurers = insurers;
    delete params["policy_type[]"];
    delete params.car_model;
    setPolicyTypeContact(policyTypes);

    const sortParams = Object.values(sortObj);
    let searchParams = QueryString.stringify(params, {
      arrayFormat: "bracket",
    });
    searchParams = `${searchParams}&car_model=${state.carModel}&sort_by=${sortParams}`;
    setLoading(true);
    try {
      const results = await axios.get(`/api/products/search2/motor?${searchParams}`);
      makeResponse(results.data, false);
    } catch (error) {
      makeResponse(null, error);
    }
  };

  const makeResponse = (response, error) => {
    if (error) {
      setServerError(true);
      setLoading(false);
      setProducts([]);
      return;
    }

    if (response.error !== undefined) {
      setProducts([]);
      setProductNotFound(true);
      setLoading(false);
      setServerError(false);
    } else {
      setProducts(response);
      setLoading(false);
      setProductNotFound(false);
      setServerError(false);
    }
  };

  return (
    <CompareProvider value={useCompareState}>
      <FilterProvider value={[state, dispatch]}>
        <CompareBar />
        <div className="motor-search-page">
          <SectionHeader
            title="ประกันภัยรถยนต์ภาคสมัครใจ"
            breadcrumbs={[{ url: "#", label: "ซื้อขายประกันภัย", current: true }]}
            searchComponent={
              showPaidCommission && (
                <div className="motor-search-page-tools">
                  <div className="toggle-commission">
                    <Switch
                      id="showComission"
                      active={showComission}
                      onClick={() => setShowComission(!showComission)}
                    />
                    <div className="label">
                      แสดงค่าตอบแทน<br />(Commission)<br />ค่าตอบแทนที่แสดงยังไม่รวมภาษีหัก ณ ที่จ่าย (5%)
                    </div>
                  </div>
                </div>
              )
            }
            noBorder
          />
          {!filterLoading && (
            <div className="filters">
              <div className="filter-left">
                <div className="filter-left-row">
                  <div className="label">ตัวกรอง</div>
                  <PolicyTypeFilter
                    fnCallbackFilterValues={fnCallbackFilterValues}
                    active={activePolicyTypeButton}
                  />
                  <RepairFacilityFilter fnCallbackFilterValues={fnCallbackFilterValues} />
                  <ExcessDamageCoverageFilter fnCallbackFilterValues={fnCallbackFilterValues} />
                  {t("insurersFilter") && (
                    <InsurersFilter
                      optionsLists={state.insurersOptions}
                      fnCallbackFilterValues={fnCallbackFilterValues}
                      active={activeInsurerButton}
                    />
                  )}
                  {t("adjustSumInsured") && (
                    <AdjustSumInsured
                      className="rounded-3xl mr-1 w-fit px-1"
                      id="sum_insured"
                      name="sum_insured"
                      isNoSubModel={queryParams.unsure_sub_model}
                      handleChange={(e) => setSumInsured(e.target.value)}
                      vehicleCodeName={vehicleCodeName}
                      value={sumInsured}
                    />
                  )}
                  <Button
                    id="button-filter"
                    color="primary"
                    onClick={filtersResponse}
                    disabled={loading}
                  >
                    ค้นหา
                  </Button>
                </div>
              </div>
              <div className="filter-right">
                <div className="filter-right-row">
                  <div className="label">เรียงจาก</div>
                  <SortFilter fnCallbackFilterValues={fnCallbackFilterValues} />
                </div>
              </div>
            </div>
          )}

          <hr />

          <div className="row relative py-2">
            <div className="col-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="motor-search-page-count">
                    พบ <span className="lenght">{products.length} </span> แผนประกันสำหรับ
                    <span className="model">
                      {queryParams.car_brand !== undefined && `${state.carInfo}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="relative row">
                {loading ? (
                  <CardLoading items={8} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" />
                ) : (
                  <>
                    {products.map((product, index) => (
                      <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <Card
                          index={index}
                          productType="motor"
                          details={carInfo}
                          packageObj={product}
                          showComission={showComission}
                          linkCreate={`/quotations/createQuotation${search}&product_id=${product.id}&product_type=motor`}
                        />
                      </div>
                    ))}
                    {productNotFound && !serverError && (
                      <ContactCard
                        selectedType={policyTypeContact}
                        make={queryParams.car_brand}
                        model={queryParams.car_model}
                        year={queryParams.year}
                        province={queryParams.province}
                        productType="motor"
                      />
                    )}
                  </>
                )}

                {!loading && productNotFound && (
                  <div className="col-12">
                    <div>
                      <h5 className="my-4 mt-20 text-center">
                        ขออภัยครับ ไม่พบแผนประกันภัยที่ท่านค้นหา
                      </h5>
                      <p className="text-center">
                        กรุณาติดต่อ Line: {t("lineAccount")} หรือ โทร {t("phoneNumberFormat")} เวลา:{" "}
                        {t("timeToContact")} ({t("dayToContact")})
                      </p>
                    </div>
                  </div>
                )}

                {!loading && serverError && <ErrorPage />}
              </div>
            </div>
          </div>
        </div>
      </FilterProvider>
    </CompareProvider>
  );
};

export default Search;
