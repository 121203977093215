import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAppContext } from "~src/context/App";

const DEFAULT_MAX_BUTTON_CHECKS = 3;
const DEFAULT_BUTTON_CHECK_FIRST_DELAY = 500;
const DEFAULT_BUTTON_CHECK_INTERVAL = 500;
const DEFAULT_MAX_RELOADS = 1;
const DEFAULT_RELOAD_DELAY = 100;
const LOADING_COUNTER_LOCAL_STORAGE_KEY = "kbank-payment-loading-counter";

export interface UseKbankPaymentButtonOptions {
  amount: string;
  orderID: number | string;
  orderNumber: string;
  maxButtonChecks?: number;
  buttonCheckFirstDelay?: number;
  buttonCheckInterval?: number;
  maxReloads?: number;
  reloadDelay?: number;
  reloadPage?: () => void;
  onLoaded?: (scriptElement?: HTMLScriptElement, formElement?: HTMLFormElement) => void;
  onError?: (err: Error) => void;
}

interface UseKbankPaymentButtonInternalState {
  isLoading: boolean;
  loadingCounter: number;
  formElement?: HTMLFormElement;
  error?: Error;
}

export interface UseKbankPaymentButtonState extends UseKbankPaymentButtonInternalState {
  scriptData: Record<string, any>;
  formRefCallback: (element: HTMLFormElement) => void;
}

export default function useKbankPaymentButton({
  amount,
  orderID,
  orderNumber,
  maxButtonChecks = DEFAULT_MAX_BUTTON_CHECKS,
  buttonCheckFirstDelay = DEFAULT_BUTTON_CHECK_FIRST_DELAY,
  buttonCheckInterval = DEFAULT_BUTTON_CHECK_INTERVAL,
  maxReloads = DEFAULT_MAX_RELOADS,
  reloadDelay = DEFAULT_RELOAD_DELAY,
  reloadPage,
  onLoaded,
  onError,
}: UseKbankPaymentButtonOptions): UseKbankPaymentButtonState {
  const history = useHistory();

  const { appConfig } = useAppContext();

  // const [currentScriptData, setCurrentScriptData] = useState<Record<string, any>>(() =>
  //   getScriptData()
  // );
  const [state, setState] = useState<UseKbankPaymentButtonInternalState>(() => ({
    isLoading: true,
    loadingCounter: restoreLoadingCounter(),
  }));

  const currentScriptData = useMemo(
    () => ({
      apikey: process.env.KBANK_API_PKEY,
      mid: process.env.KBANK_MID,
      amount,
      currency: "THB",
      paymentMethods: "card",
      name: "Gettgo HERO",
      orderId: orderNumber,
    }),
    [appConfig.backendURL, process.env.KBANK_API_PKEY, process.env.KBANK_MID, amount, orderNumber]
  );

  const tempFormElementRef = useRef<HTMLFormElement>();
  const scriptRef = useRef<HTMLScriptElement>();
  const checkBtnTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const reloadTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const formRefCallback = useCallback(
    (element: HTMLFormElement) => {
      if (!element) {
        return;
      }

      tempFormElementRef.current = element;
      element.method = "POST";
      element.action = `${appConfig.backendURL}/orders/${orderID}/payments/kbank/cc`;
      setState((st) => ({
        ...st,
        formElement: element,
      }));

      // if (scriptRef.current) {
      //   scriptRef.current.onload = undefined;
      //   scriptRef.current.onsecuritypolicyviolation = undefined;
      //   scriptRef.current.onerror = undefined;
      //   scriptRef.current.remove();
      //   scriptRef.current = undefined;
      // }

      let scriptTag = scriptRef.current;
      if (!scriptTag) {
        const existingScriptTags = element.getElementsByTagName("script");
        for (let i = 0; i < existingScriptTags.length; i++) {
          const el = existingScriptTags.item(i);
          if (el.src === process.env.KBANK_API_JAVASCRIPT_URL) {
            scriptTag = el;
            break;
          }
        }
      }
      if (scriptTag) {
        // TODO: Revise this later
        scriptTag.remove();
      } else {
        scriptTag = document.createElement("script");
        scriptTag.src = process.env.KBANK_API_JAVASCRIPT_URL;
        // scriptTag.async = true;
        Object.assign(scriptTag.dataset, currentScriptData);
        scriptRef.current = scriptTag;
      }

      scriptTag.onload = handleScriptLoaded;
      scriptTag.onsecuritypolicyviolation = (evt) => {
        console.error("useKbankPaymentButton: onsecuritypolicyviolation:", evt);
        const err = new Error(typeof evt === "string" ? evt : evt.violatedDirective);
        setState((st) => ({
          ...st,
          error: err,
        }));
        onError && onError(err);
      };
      scriptTag.onerror = (evt) => {
        const err = new Error(typeof evt === "string" ? evt : evt.type);
        setState((st) => ({
          ...st,
          error: err,
        }));
        onError && onError(err);
      };

      element.classList.remove("hidden");
      element.appendChild(scriptTag);
    },
    [currentScriptData]
  );

  const handleReload = useCallback(() => {
    // TEST
    // console.log("useKbankPaymentButton: handleReload, state:", state);
    // TEST
    // throw new Error("reload");

    if (reloadPage) {
      reloadPage();
    } else {
      history.go(0);
    }
  }, [/* state, */ reloadPage, history]);

  const checkButton = useCallback(
    (remainingTries: number) => {
      if (!tempFormElementRef.current) {
        console.warn("useKbankPaymentButton: Form element already detached");
        return;
      }

      const foundButtons = tempFormElementRef.current?.getElementsByTagName("button");
      if (foundButtons?.[0]) {
        // reset loading counter for next (manual) reload
        saveLoadingCounter(0);
        setState((st) => ({
          ...st,
          formElement: st.formElement || tempFormElementRef.current,
          isLoading: false,
        }));

        onLoaded && onLoaded(scriptRef.current, tempFormElementRef.current);
      } else {
        console.warn(
          "useKbankPaymentButton: KBank Payment button not found in form:",
          tempFormElementRef.current,
          `; Remaining tries (${remainingTries})`
        );

        if (remainingTries > 0) {
          checkBtnTimeoutRef.current = setTimeout(
            () => checkButton(remainingTries - 1),
            buttonCheckInterval
          );
          return;
        }

        if (state.loadingCounter >= maxReloads) {
          console.warn(
            `useKbankPaymentButton: Exceeded max. reloads (${state.loadingCounter}/${maxReloads})` +
              "; window.KPayment:",
            window.KPayment
          );
          // reset loading counter for next (manual) reload
          saveLoadingCounter(0);
          // TODO: Revise this later (assume loading completed)
          setState((st) => ({
            ...st,
            formElement: st.formElement || tempFormElementRef.current,
            isLoading: false,
          }));
          // HACK: Fix KPayment Button not showing
          window.KPayment.create();

          onLoaded && onLoaded(scriptRef.current, tempFormElementRef.current);

          return;
        }

        setState((st) => {
          const nextCounter = st.loadingCounter + 1;
          saveLoadingCounter(nextCounter);

          return {
            ...st,
            formElement: st.formElement || tempFormElementRef.current,
            loadingCounter: nextCounter,
          };
        });

        // TEST
        // console.log("useKbankPaymentButton: Dummy reload:", new Error("trace"));
        reloadTimeoutRef.current = setTimeout(handleReload, reloadDelay);
      }
    },
    [saveLoadingCounter, onLoaded, state.loadingCounter, maxReloads, handleReload, reloadDelay]
  );

  const handleScriptLoaded = useCallback(
    (evt: any) => {
      reloadTimeoutRef.current && clearTimeout(reloadTimeoutRef.current);
      reloadTimeoutRef.current = undefined;

      if (!scriptRef.current) {
        console.warn("useKbankPaymentButton: Script element already unmounted");
        return;
      }

      // DEPRECATED: No more first reload
      // if (state.loadingCounter < 2) {
      //   const nextCounter = state.loadingCounter + 1;
      //   //TEST
      //   console.log(
      //     `useKbankPaymentButton: reloading page (${nextCounter}/${maxReloads}) to complete KBank CC script:`
      //   );
      //   saveLoadingCounter(nextCounter);
      //   setState({
      //     ...state,
      //     loadingCounter: nextCounter,
      //   });
      //   reloadTimeoutRef.current = setTimeout(handleReload, reloadDelay);

      //   return;
      // }

      checkBtnTimeoutRef.current = setTimeout(
        () => checkButton(maxButtonChecks),
        buttonCheckFirstDelay || 1
      );
    },
    [checkButton, maxButtonChecks, buttonCheckFirstDelay]
  );

  useEffect(() => {
    // setState((st) => ({ ...st, loadingCounter: restoreLoadingCounter() }));

    // cleanup
    return () => {
      if (scriptRef.current) {
        if (tempFormElementRef.current) {
          tempFormElementRef.current.removeChild(scriptRef.current);
        } else {
          scriptRef.current.remove();
        }
        scriptRef.current = undefined;
      }

      checkBtnTimeoutRef.current && clearTimeout(checkBtnTimeoutRef.current);
      checkBtnTimeoutRef.current = undefined;

      reloadTimeoutRef.current && clearTimeout(reloadTimeoutRef.current);
      reloadTimeoutRef.current = undefined;

      saveLoadingCounter(1);
    };
  }, []);

  // useDeepCompareEffectNoCheck(() => {
  //   setCurrentScriptData(getScriptData());
  // }, [process.env, orderID, orderNumber, amount]);

  // useEffect(() => {
  //   if (!state.isLoading && state.loadingCounter >= 2) {
  //     return;
  //   }

  //   // To reload the page once only at the first render
  //   // It is due to the KBank cc payment button script tag loading.
  //   console.log(
  //     `useKbankPaymentButton: reloading page (${state.loadingCounter}) to complete KBank CC script:`,
  //     scriptRef.current?.src,
  //     "; state:",
  //     state
  //   );
  //   setState((st) => ({
  //     ...st,
  //     isLoading: false,
  //   }));

  //   if (reloadPage) {
  //     reloadPage();
  //   } else {
  //     history.go(0);
  //   }
  // }, [state.isLoading, state.loadingCounter]);

  return { ...state, scriptData: currentScriptData, formRefCallback };
}

function restoreLoadingCounter() {
  let counter = 0;
  if (window.localStorage) {
    counter = Number(localStorage.getItem(LOADING_COUNTER_LOCAL_STORAGE_KEY) || "0");
  }

  return counter || 1;
}

function saveLoadingCounter(nextCounter) {
  if (window.localStorage) {
    localStorage.setItem(LOADING_COUNTER_LOCAL_STORAGE_KEY, `${nextCounter}`);
  }
}
