import React from "react";
interface Props {
  children: JSX.Element;
  onBackdropClicked?: any;
}
const Modal: React.FC<Props> = ({ children, onBackdropClicked }) => {
  return (
    <div
      className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center cursor-default"
      style={{ zIndex: 1000 }}
    >
      <div 
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 cursor-pointer" 
        onClick={onBackdropClicked}
      />
      <div className="bg-white rounded-lg shadow-sm w-1/2 z-10 p-5">
        {children}
      </div>
    </div>
  );
};

export default Modal;
