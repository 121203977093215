import React from "react";
import { format, addYears } from "date-fns";
import { renewConText } from "../store";
import Label from "../../../../components/Forms/Label";
import Select from "../../../../components/Forms/Select";
import DatePicker from "../../../../components/Forms/DatePicker";
import {
  PolicyTypeMapping,
  RepairFacilityMapping,
  ProductTypeMapping,
} from "../../../../helpers/mappingData";
import { messages } from "../../../../helpers/messages";
import { Insurer } from "../../../../types/insurer";
import { LoadStatus } from "../../../../types/load";
import { Quote } from "../../../../types/quote";
import { Details } from "../../../../types/order";

interface Props {
  load: LoadStatus;
  register: any;
  errors: any;
  insurersData: Insurer[];
}

const CustomOtherProduct: React.FC<Props> = ({
  load,
  insurersData,
  register,
  errors,
}) => {
  const { state, dispatch } = React.useContext(renewConText);

  const handleChangeQuote = (
    evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    name: keyof Quote
  ): void => {
    const quotes = [...state.renew.quotes] as Quote[];
    quotes[0] = {
      ...state.renew.quotes[0],
      [name]: evt.target.value,
    };

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          quotes,
        },
      },
    });
  };

  const handleChangeCoverDate = (date: Date): void => {
    const newYear: Date = addYears(date, 1);
    const details: Details = { ...state.renew.details };
    details.cover_start_date = format(date, "yyyy-MM-dd");
    details.cover_end_date = format(newYear, "yyyy-MM-dd");

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          details,
        },
      },
    });
  };

  return (
    <div>
      <div className="mt-6 mb-3 text-lg font-bold text-secondary">
        ข้อมูล
        {ProductTypeMapping[state.renew?.quotation.product_type]}
      </div>
      <div className="flex flex-wrap items-center -mx-2">
        <div className="w-1/3 px-2 mb-4">
          <Label htmlFor="renew[product][insurer_code]" required>
            บริษัทประกัน
          </Label>
          <Select
            id="renew[product][insurer_code]"
            name="renew[product][insurer_code]"
            value={state.renew?.quotes[0].insurer_code}
            onChange={(evt) => handleChangeQuote(evt, "insurer_code")}
            inputRef={register({ ...messages.required })}
            error={errors?.renew?.product?.insurer_code}
          >
            {load === "loaded" &&
              insurersData.map((insurer, index) => (
                <option key={index} value={insurer.code}>
                  {insurer.name}
                </option>
              ))}
          </Select>
        </div>

        <input
          type="hidden"
          ref={register}
          name="renew[product][policy_type]"
          value={state.renew?.quotes?.[0].policy_type}
        />

        <div className="w-full px-2 mb-4">
          <Label htmlFor="renew[product][insurer_code]" required>
            แผนประกัน
          </Label>
          <div>{state.renew?.quotes[0].product_name}</div>
        </div>

        <div className="w-1/2 px-2 mb-4">
          <Label htmlFor="renew_cover_start_date" required>
            วันที่เริ่มคุ้มครอง
          </Label>
          <DatePicker
            id="renew_cover_start_date"
            selected={
              state.renew?.details &&
              new Date(state.renew?.details?.cover_start_date ?? null)
            }
            onChange={(date: Date): void => handleChangeCoverDate(date)}
            // minDate={new Date(state.renew?.quotes?.[0].coverage_from)}
            dateFormat="dd/MM/yyyy"
          />
          <input
            type="hidden"
            name="renew[order_details][cover_start_date]"
            ref={register}
            value={state.renew?.details?.cover_start_date}
          />
        </div>
        <div className="w-1/2 px-2 mb-4">
          <Label htmlFor="renew_cover_end_date" required>
            วันที่สิ้นสุดคุ้มครอง
          </Label>
          <DatePicker
            id="renew_cover_end_date"
            name="renew_cover_end_date"
            selected={
              state.renew?.details &&
              new Date(state.renew?.details?.cover_end_date ?? null)
            }
            onChange={() => false}
            minDate={new Date()}
            disabled
            dateFormat="dd/MM/yyyy"
          />
          <input
            type="hidden"
            name="renew[order_details][cover_end_date]"
            ref={register}
            value={state.renew?.details?.cover_end_date}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomOtherProduct;
