import React from "react";
import cns from "classnames";

import { PolicyStatus } from "../../types/policy";

interface Props {
  status: PolicyStatus;
}

interface BadgeColor {
  bg: string;
  label: string;
}

export const Mapping: Record<PolicyStatus, BadgeColor> = {
  issuing: { bg: "bg-policyStatus-issuing", label: "กำลังดำเนินการ" },
  waiting_approval: { bg: "bg-policyStatus-waiting_approval", label: "รอยืนยันข้อมูล" },
  waiting_inspection: {
    bg: "bg-policyStatus-waiting_inspection",
    label: "รอตรวจสภาพรถ",
  },
  success: { bg: "bg-policyStatus-success", label: "จัดส่งสำเนากรมธรรม์" },
  failed: { bg: "bg-policyStatus-failed", label: "ส่งงานไม่ผ่าน" },
  failed_inspection: {
    bg: "bg-policyStatus-failed_inspection",
    label: "ตรวจสภาพรถไม่ผ่าน",
  },
  waiting_policy: {
    bg: "bg-policyStatus-waiting_policy",
    label: "รอออกกรมธรรม์",
  },
  cancel: { bg: "bg-policyStatus-cancel", label: "ยกเลิกกรมธรรม์" },
};

const Badge: React.FC<Props> = ({ status }) => {
  return (
    <div
      className={cns(
        status && Mapping[status].bg,
        "text-xs px-2 py-1 rounded-5 inline-flex items-center bg-opacity-30"
      )}
    >
      <span
        className={cns(
          status && Mapping[status].bg,
          "w-2 h-2 rounded-full mr-1"
        )}
      />
      {status && Mapping[status].label}
    </div>
  );
};

export default Badge;
