import React from "react";

interface LinkProps {
  href: string;
  target?: "_blank" | "_self" | "_parent" | "_top" | "framename";
  className?: string;
  children: string | JSX.Element;
}

const Link: React.FC<LinkProps> = ({ href, target, children, className }) => {
  return (
    <a href={href} target={target} className={className}>
      {children}
    </a>
  );
};

export default Link;
