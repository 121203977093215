import React from "react";

import "./Radio.scss";

const Radio = ({ label, value, checked, onChangeValue }) => {
  return (
    <div className="radio-component">
      <button
        className={`radio-component-button ${checked ? "checked" : ""}`}
        value={value}
        onClick={() => onChangeValue(value)}
      >
        {label}
        <span className="checkmark"></span>
      </button>
    </div>
  );
};

export default Radio;
