import React from "react";
import { dateFormat } from "../../helpers/dateTime";
const Input = ({ labelStartDate, labelEndDate, startDate, endDate }) => {
  return (
    <>
      <div className="text-left">
        {startDate ? dateFormat(startDate) : labelStartDate}
      </div>
      <div className="text-center">
        <span className="material-icons">arrow_right_alt</span>
      </div>
      <div className="text-left">
        {endDate ? dateFormat(endDate) : labelEndDate}
      </div>
    </>
  );
};

export default Input;
