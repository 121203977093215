import _ from "lodash";
import React, { useState, useEffect, useReducer, useMemo, useCallback } from "react";
import { matchPath } from "react-router";
// TEST
import { useWhatChanged } from "@simbathesailor/use-what-changed";

import useProductOrder from "./product_order";
import VehicleInfoForm from "./VehicleInfoForm";
import InsurancePlanSummary from "/src/components/insurancePlanSummary/insurancePlanSummary";
import Preloading from "/src/components/loading/Preloading";
import Stepper from "/src/components/stepper/Stepper";

import { useAgentOrdersAPI } from "/src/api/agent/Orders";
import { CheckoutProvider } from "/src/context/Checkout";
import { PageContextProvider, usePageContext } from "/src/context/Page";
import CheckoutReducer, { initCheckoutState } from "/src/reducer/CheckoutReducer";

import "./info.scss";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

export default function InfoStep2(props) {
  return (
    <PageContextProvider>
      <InfoStep2Inner {...props} />
    </PageContextProvider>
  );
}

function InfoStep2Inner(props) {
  const orderId = matchPath(props.location.pathname, {
    path: "/checkout/:order_id/step-2",
    exact: true,
  }).params.order_id;

  const { onError, setAlert, setPreloading } = usePageContext();

  const [addOrRemoveCMI, setAddOrRemoveCmi] = useState({});

  // TEST
  // useWhatChanged(
  //   [orderId, ordersAPI, onError],
  //   "orderId, ordersAPI, onError",
  //   "InfoStep2Inner#3",
  //   "InfoStep2Inner#3"
  // );
  const {
    isOrderAvailable,
    insureds,
    quotationInfo,
    quoteInfo,
    details,
    deliveryAddress,
    getLabelSteppers,
    getSummaryInfo,
    insuredCategory,
    cmiAddon,
    cmiObjs,
    contract,
    attachImagesStatus,
    vehicleDocuments,
    includeWhTax,
  } = useProductOrder(orderId, { onError });

  useEffect(() => {
    setPreloading(!isOrderAvailable);
  }, [isOrderAvailable]);

  useDeepCompareEffectNoCheck(() => {
    setAddOrRemoveCmi(cmiObjs);
  }, [cmiObjs]);

  const [checkoutState, checkoutDispatcher] = useReducer(
    CheckoutReducer,
    initCheckoutState(quotationInfo.product_type)
  );

  const handleToggleCMI = (status) => {
    setAddOrRemoveCmi({
      ...addOrRemoveCMI,
      checked_addon: !status,
    });
  };

  return (
    <CheckoutProvider value={{ state: checkoutState, dispatch: checkoutDispatcher }}>
      {isOrderAvailable && (
        <div className="motor-info-page">
          <div className="row">
            <div className="col-md-12">
              <h1>
                <i className="material-icons">lock</i>ยืนยันคำสั่งซื้อ
              </h1>
              <Stepper steppers={getLabelSteppers(1)} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <VehicleInfoForm
                orderId={orderId}
                details={details}
                cmiAddon={cmiAddon}
                addOrRemoveCMI={addOrRemoveCMI}
                province={quotationInfo.criteria?.province}
                attachImagesStatus={attachImagesStatus}
                quoteInfo={quoteInfo}
                vehicleDocuments={vehicleDocuments}
                insuredCategory={insuredCategory}
                handleToggleCMI={handleToggleCMI}
                vehicleType={quotationInfo.criteria?.vehicle_type}
                setAlert={setAlert}
                setPreloading={setPreloading}
                includeWhTax={includeWhTax}
                contract={contract}
              />
            </div>

            <div className="col-md-4">
              <InsurancePlanSummary
                addon={cmiAddon}
                quoteInfo={quoteInfo}
                addOrRemoveCMI={addOrRemoveCMI}
                summary={getSummaryInfo()}
                includeWhTax={includeWhTax}
                insuredCategory={insuredCategory}
              />
            </div>
          </div>
        </div>
      )}
    </CheckoutProvider>
  );
}
