import Decimal from "decimal.js";

export const formatNumberWithCurrency = (num, symbol = " ฿") => {
  if (!num && num !== 0) {
    return "-";
  }

  const num2 = num instanceof Decimal ? num : new Decimal(num);

  const str = !num2.isNaN() && num2.greaterThan(0) ? `${addCommas(num2)}${symbol}` : "-";
  // if (num2 === NaN) {
  //   str = num;
  // } else {
  //   str = num2 > 0 ? addCommas(parseFloat(num)) + symbol : "-";
  // }

  return str;
};

export const formatNumberWithCurrency2 = (num, symbol = " ฿") => {
  const num2 = typeof num === "number" ? num : parseInt(num);

  let str;
  if (num2 === NaN) {
    str = num;
  } else {
    str = parseInt(num) > 0 ? parseFloat(num).toLocaleString() + symbol : "-";
  }

  return str;
};

export const formatNumber = (num) => {
  let str;
  if (parseInt(num) === NaN) str = num;
  else {
    str = parseInt(num) > 0 ? parseFloat(num).toLocaleString() : "-";
  }
  return str;
};

export const formatPhone = (phone) => {
  return phone.replace(/(\d{2,3})(\d{3})(\d{4})/, "$1-$2-$3");
};

function addCommas(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const roundToTwo = (num) => {
  return +`${Math.round(`${num}e+2`)}e-2`;
};

export function decimalToString(num) {
  const num2 = num instanceof Decimal ? num : new Decimal(num);

  return num2.toFixed(2);
}
