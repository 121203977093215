import React, { useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";
import classnames from "classnames";
import QueryString from "query-string";
import BeneficiaryForm from "~src/components/checkoutForm/BeneficiaryForm";
import DeliveryForm from "~src/components/checkoutForm/DeliveryForm";
import InsureInfoForm from "~src/components/checkoutForm/InsureInfoForm";
import { ButtonRadius, Checkbox, Radio } from "~src/components/form";
import {
  insuredsInfoAtom,
  orderInfoAtom,
  quotationInfoAtom,
  quotesInfoAtom,
} from "~src/states/orderCheckout";
import { Insured } from "~src/types/insured";
import { AlertModal } from "~src/components/modal";
import Address from "./components/Address";
import { get, isEqual } from "lodash";

const FormInsure: React.FC<{ onSubmit: any }> = ({ onSubmit }): JSX.Element => {
  const { handleSubmit, watch, setValue, register, clearErrors, errors, getValues } =
    useFormContext();
  const { search } = window.location;
  const [querires] = useState(QueryString.parse(search));
  const insuredsInfo = useRecoilValue(insuredsInfoAtom);
  const orderInfo = useRecoilValue(orderInfoAtom);
  const quotationInfo = useRecoilValue(quotationInfoAtom);
  const quotsInfo = useRecoilValue(quotesInfoAtom);
  const [sameFirstAddress, setSameFirstAddress] = useState<any>({ [0]: false });
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [confirmed2, setConfirmed2] = useState<boolean>(false);
  const [showAlert, isShowAlert] = useState<boolean>(false);

  const prefix = `order.insureds_attributes[0].addresses_attributes[0]`;

  const firstAddress = watch(`${prefix}.address`);

  const firstSubDistrict = getValues(`${prefix}.sub_district`);
  const firstDistrict = getValues(`${prefix}.district`);
  const firstProvince = getValues(`${prefix}.province`);
  const firstPostcode = getValues(`${prefix}.postcode`);

  React.useEffect(() => {
    const insuredAddresses = [];
    for (let i = 0; i < insuredsInfo.length; i++) {
      const { address, sub_district, district, province, postcode } = get(
        insuredsInfo[i],
        "addresses[0]"
      );

      insuredAddresses.push({ address, sub_district, district, province, postcode });
    }

    insuredAddresses.forEach((address, i) => {
      if (i == 0) return;
      setSameFirstAddress({
        [i]: isEqual(insuredAddresses[0], address),
      });
    });
  }, [insuredsInfo]);

  React.useEffect(() => {
    if (firstAddress || (firstSubDistrict && firstDistrict && firstProvince && firstPostcode)) {
      for (let i = 1; i < insuredsInfo.length; i++) {
        const prefixInsured = `order.insureds_attributes[${i}].addresses_attributes[0]`;

        if (sameFirstAddress[i]) {
          setValue(`${prefixInsured}.address`, firstAddress);
          setValue(`${prefixInsured}.sub_district`, firstSubDistrict);
          setValue(`${prefixInsured}.district`, firstDistrict);
          setValue(`${prefixInsured}.province`, firstProvince);
          setValue(`${prefixInsured}.postcode`, firstPostcode);
        }

        clearErrors([
          `${prefixInsured}.address`,
          `${prefixInsured}.sub_district`,
          `${prefixInsured}.district`,
          `${prefixInsured}.province`,
          `${prefixInsured}.postcode`,
        ]);
      }
    }
  }, [firstAddress, firstSubDistrict, firstDistrict, firstProvince, firstPostcode]);

  const handleChageSameAddress = (index: number) => {
    setSameFirstAddress({ [index]: !sameFirstAddress[index] });
    const prefixInsured = `order.insureds_attributes[${index}].addresses_attributes[0]`;
    setValue(`${prefixInsured}.address`, firstAddress);
    setValue(`${prefixInsured}.sub_district`, firstSubDistrict);
    setValue(`${prefixInsured}.district`, firstDistrict);
    setValue(`${prefixInsured}.province`, firstProvince);
    setValue(`${prefixInsured}.postcode`, firstPostcode);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit((data) => {
        if (!confirmed || !confirmed2) return isShowAlert(true);
        return onSubmit?.(data);
      })}
    >
      <input type="hidden" name="token" ref={register} value={querires.token} />
      <input
        type="hidden"
        name="order.details.cover_start_date"
        ref={register}
        value={quotationInfo.criteria?.from_date}
      />
      <input
        type="hidden"
        name="order.details.cover_end_date"
        ref={register}
        value={quotationInfo.criteria?.to_date}
      />

      {insuredsInfo.map((insureds, index) => {
        return (
          <div
            className={classnames(
              "relative bg-white rounded-5 py-4 px-8 shadow-md",
              index > 0 ? "mt-6" : null
            )}
            key={`insures-${insureds.id}`}
          >
            <h2>ข้อมูลผู้เอาประกัน {insuredsInfo.length > 1 && `คนที่ ${index + 1}`}</h2>

            <InsureInfoForm
              productDetails={orderInfo?.content?.product_details}
              insuredsInfo={insureds}
              index={index}
              fieldName={`order.insureds_attributes[${index}]`}
              quotationInfo={quotationInfo}
              quotes={quotsInfo}
            />

            <div className="my-4">
              <h2>ที่อยู่ผู้เอาประกัน</h2>
              {index > 0 && (
                <div className="space-y-10">
                  <Radio
                    id={`insured-copy-${index}`}
                    label="ที่เดียวกับผู้เอาประกันคนที่ 1"
                    name={`copy.address.${index}`}
                    checked={sameFirstAddress[index]}
                    onChange={() => handleChageSameAddress(index)}
                  />
                  <Radio
                    id={`insured-custom-${index}`}
                    label="หรือ ระบุที่อยู่ใหม่"
                    name={`copy.address.${index}`}
                    checked={!sameFirstAddress[index]}
                    onChange={() => handleChageSameAddress(index)}
                  />
                </div>
              )}
              <input
                type="hidden"
                name={`order.insureds_attributes[${index}].addresses_attributes[0].id`}
                ref={register}
                value={insureds.addresses[0].id}
              />
              <div className="grid gap-4 col-span-8 mt-3">
                <Address
                  initialValue={insureds.addresses[0]}
                  register={register}
                  name={`order.insureds_attributes[${index}].addresses_attributes[0]`}
                  errors={errors?.order?.insureds_attributes?.[index]?.addresses_attributes?.[0]}
                  setValue={setValue}
                  getValues={getValues}
                  clearErrors={clearErrors}
                  readOnly={sameFirstAddress[index]}
                />
              </div>
            </div>

            <div className="my-4">
              <h2>ที่อยู่ในการจัดส่งกรมธรรม์</h2>
              <input
                type="hidden"
                name={`order.insureds_attributes[${index}].addresses_attributes[1].id`}
                ref={register}
                value={insureds.addresses[1].id}
              />
              <DeliveryForm
                insuredsInfo={insureds}
                index={index}
                fieldName={`order.insureds_attributes[${index}]`}
              />
            </div>

            <div className="mt-9">
              <hr />
            </div>

            <div className="my-4">
              <h2>ข้อมูลผู้รับผลประโยชน์</h2>
              <BeneficiaryForm
                insuredsInfo={insureds}
                fieldName={`order.insureds_attributes[${index}]`}
                index={index}
                defaultValue={insureds.beneficiary}
              />
            </div>
          </div>
        );
      })}

      <div className="relative bg-white rounded-5 py-4 px-8 shadow-md my-6">
        <div className="mt-11">
          <div className="form-input-error mb-6">
            คำเตือนของ สำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย (คปภ.)
            โปรดตอบคำถามตามความเป็นจริงทุกข้อ การปกปิดข้อความจริง หรือแถลงข้อความเป็นเท็จใดๆ
            อาจเป็นเหตุให้บริษัทปฏิเสธความรับผิดตามสัญญาประกันภัยได้ ตามประมวลกฎหมายแพ่งและพาณิชย์
            มาตรา 865
          </div>
          <Checkbox
            id="confirmation"
            onChange={(e) => setConfirmed(e.target.checked)}
            label={
              <>
                <div className="text-xs">
                  ข้าพเจ้าขอรับรองว่า ข้าพเจ้ามีสิทธิ มีอำนาจกระทำการแทน
                  และ/หรือแถลงข้อความเพื่อจัดให้มีการซื้อกรมธรรม์ให้กับข้าพเจ้าเอง หรือ
                  ให้กับผู้อื่น และยินยอมให้ทางบริษัทฯ ใช้และเข้าถึง E-mail ระบบข้อมูลอิเล็กทรอนิกส์
                  และข้อมูลส่วนบุคคลที่ให้ไว้ข้างต้น เพื่อวัตถุประสงค์ในการแจ้งผลการอนุมัติ
                  การให้บริการด้านการประกันภัย การตลาด การนำเสนอบริการและผลิตภัณฑ์ใหม่ๆ
                  การติดต่อสื่อสาร จัดเก็บ ใช้ และเปิดเผย
                  ข้อเท็จจริงเกี่ยวกับสุขภาพและข้อมูลของข้าพเจ้าต่อสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย
                  (คปภ.) เพื่อประโยชน์ในการกำกับดูแลธุรกิจประกันภัย และอื่นๆ
                </div>
                <div className="text-xs">
                  ข้าพเจ้าขอยืนยันว่าผู้เอาประกันภัยทุกรายมีสุขภาพสมบูรณ์ดี และปราศจากความพิการใดๆ
                  และไม่เดินทางเพื่อการรักษาพยาบาลใดๆ
                </div>
                <div className="text-xs">
                  คำแถลงตามรายการข้างบนเป็นความจริงทุกประการ
                  และให้ถือเป็นส่วนหนึ่งของสัญญาประกันภัยระหว่างข้าพเจ้ากับบริษัท
                </div>
              </>
            }
          />
          <div className="mt-4">
            <Checkbox
              id="confirmation2"
              onChange={(e) => setConfirmed2(e.target.checked)}
              label={
                <div className="text-xs">
                  ข้าพเจ้าในฐานะผู้ซื้อ ผู้เอาประกันภัย หรือผู้ถือกรมธรรม์ประกันภัยกลุ่ม
                  ขอรับรองว่ารายละเอียดต่าง ๆ
                  ข้างต้นนี้ถูกต้องและสมบูรณ์และมีความประสงค์ขอเอาประกันภัย
                  กับบริษัทฯตามเงื่อนไขของกรมธรรม์ประกันภัยที่บริษัทฯ
                  ได้ใช้สำหรับการประกันภัยนี้และข้าพเจ้ายินยอม
                  และ/หรือประสงค์ให้จัดส่งกรมธรรม์การเดินทางไปยังระบบข้อมูล
                  ในการรับข้อมูลทางอิเล็กทรอนิกส์(อีเมล์)ที่ได้ระบุไว้และประสงค์ให้บริษัทฯส่งเอกสารที่เกี่ยวข้องกับการประกันภัยของผู้เอาประกันภัยที่ไม่ได้มี
                  E-mail ระบุไว้
                  เป็นการเฉพาะมายังข้าพเจ้าและข้าพเจ้าจะนำส่งเอกสารที่เกี่ยวข้องให้กับผู้เอาประกันภัยเอง
                  อีกทั้งข้าพเจ้ายืนยันและตกลงที่จะให้ข้อมูลนี้เป็นมูลฐานของสัญญาระหว่างข้าพเจ้าและบริษัทฯ
                </div>
              }
            />
          </div>
          <div className="mt-6">
            คำเตือน: เงื่อนไขและข้อยกเว้นเป็นไปตามที่ระบุในกรมธรรม์ประกันภัย
            ผู้ซื้อควรทำความเข้าใจในรายละเอียดความคุ้มครองและเงื่อนไขก่อนตัดสินใจทำประกันภัยทุกครั้ง
            ข้อตกลงและข้อยกเว้น
            (ในแต่ละหมวดความคุ้มครองจะเป็นไปตามแผนการประกันภัยที่ท่านได้เลือกซื้อ)
            <a
              href="https://storage.googleapis.com/asia.artifacts.gm-gettmore-project.mtb.co.th/pdf/msig-traveleasy-online%20policy-wording.pdf"
              target="_blank"
              className="mx-2 text-secondary"
            >
              คลิกที่นี่
            </a>
          </div>
        </div>
        <div className="flex justify-end mt-9">
          <ButtonRadius type="submit" color="primary">
            บันทึกและดำเนินการต่อ
          </ButtonRadius>
        </div>
      </div>

      {showAlert && <AlertModal fnClose={() => isShowAlert(false)} />}
    </form>
  );
};

const BasicInsureInfo: React.FC<{ onSubmit: any }> = ({ onSubmit }): JSX.Element => {
  const method = useForm<Insured[]>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  return (
    <FormProvider {...method}>
      <FormInsure onSubmit={onSubmit} />
    </FormProvider>
  );
};

export default BasicInsureInfo;
