import classnames from "classnames";
import React from "react";
import Logo from "../../../../assets/logo/Group 2881.svg";
import comodoImg from "../../../../assets/footer/comodo.svg";
import paymentgatwayImg from "../../../../assets/footer/paymentgatway.svg";
import CallImg from "../../../../assets/footer/call.svg";
import FacebookImg from "../../../../assets/footer/facebook.svg";
import LinkedinImg from "../../../../assets/footer/linkedin.svg";
import LineImg from "../../../../assets/footer/line.svg";
import youtubeImg from "../../../../assets/footer/youtube-io.svg";

import { useTranslation } from "react-i18next";
import { Icon } from "../../icons";

import "./css/footer.scss";

interface MenuItemProps {
  href: string;
  className?: string;
}

interface SocialMenuWidgetProps {
  link?: string;
  icon: string;
}

const socialLinks = {
  facebook: "https://www.facebook.com/groups/gettgohero",
  linkedin: "https://www.linkedin.com/company/gettgo/",
  youtube: "https://www.youtube.com/channel/UCzuE2xXk9yFC6gfXjqwwLbA",
};

const MenuItem: React.FC<MenuItemProps> = ({ href, className, children }) => {
  return (
    <a href={href} className={classnames(className, "hover:underline-light w-fit")}>
      {children}
    </a>
  );
};

const SocialMenuWidget: React.FC<SocialMenuWidgetProps> = ({ link, icon }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={link}>
      <img src={icon} className="inline" height="35" width="35" />
    </a>
  );
};

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const lineLink = t("lineLink", { returnObjects: true });

  return (
    <footer className="flex justify-center w-full text-white bg-primary">
      <div className="flex-col main-section section-x-1/2 section-y max-w-screen-2xl">
        <div className="flex w-full">
          <div className="flex-grow w-1/2">
            <img src={Logo} className="mb-10 " width={160} />
            <div className="w-full leading-relaxed">
              <p className="text-base text-white">
                บริษัท เมืองไทย โบรกเกอร์ จำกัด (สำนักงานใหญ่)<br /> 252/73 อาคารเมืองไทย-ภัทร คอมเพล็กซ์ Tower B ชั้น 6 ถนนรัชดาภิเษก
                <br /> ห้วยขวาง ห้วยขวาง กรุงเทพมหานคร 10310
              </p>
            </div>
          </div>
          <div className="flex-grow w-1/2">
            <div className={classnames("bubble", "speech", "float-right")}>
              <div className="w-full leading-relaxed">
                <h1 className="text-lg text-white">ติดต่อ {t("support")}</h1>
                <p className="mb-10 text-base text-secondary">จ. - ศ. 9.00 - 18.00 น.</p>
                <div className="text-base">
                  <a href="tel:+020266387">
                    <img src={CallImg} height={30} width={30} className="inline ml-2 mr-2" />
                    {t("phoneNumberFormat")}
                  </a>
                </div>
                <div className="text-base">
                  <a href={lineLink.url}>
                    <img src={LineImg} height={44} width={44} className="inline" />
                    {t("lineAccount")}
                  </a>
                </div>
                <div className="text-base">
                  <a href={`mailto:${t("contactMail")}`} className="flex">
                    <span className="inline ml-2 mr-2">
                      <Icon name="Email" color="#fff" />
                    </span>
                    {t("contactMail")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mb-20 border-b mt-60 border-gray" />
        <div className="flex flex-wrap w-full md:flex-nowrap">
          <div className="items-center order-2 w-full mt-10 md:order-none md:w-1/3 md:mt-0">
            Secured by <img src={comodoImg} className="inline" />
          </div>
          <div className="items-center order-3 w-full mt-10 md:text-center md:w-1/3 md:order-none md:mt-0">
            Payment Partners <img src={paymentgatwayImg} className="inline mt-10" />
          </div>
          <div className="items-center order-1 w-full space-x-10 md:order-none md:text-right md:w-1/3">
            <SocialMenuWidget link={socialLinks.facebook} icon={FacebookImg} />
            <SocialMenuWidget link={socialLinks.linkedin} icon={LinkedinImg} />
            <SocialMenuWidget link={socialLinks.youtube} icon={youtubeImg} />
          </div>
        </div>
        <div className="flex flex-wrap self-start w-full mt-24 md:flex-nowrap">
          <p className="order-3 inline mb-10 mr-0 text-base text-white md:order-none md:mr-24 md:mb-0">{`Copyright © ${new Date().getFullYear()}`}</p>
          <MenuItem
            href="/privacy-policy"
            className="order-1 inline mb-10 mr-10 text-base text-white md:order-none md:mr-24 md:mb-0"
          >
            นโยบายความเป็นส่วนตัว
          </MenuItem>
          <MenuItem
            href="/cookie-consent"
            className="order-2 inline mb-10 mr-10 text-base text-white md:order-none md:mr-24 md:mb-0"
          >
            นโยบายความคุกกี้และการตั้งค่า
          </MenuItem>
          <MenuItem
            href="/terms"
            className="order-3 inline mb-10 mr-10 text-base text-white md:order-none md:mr-0 md:mb-0"
          >
            ข้อตกลงและเงื่อนไข
          </MenuItem>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
