import { format, parse } from "date-fns";
import queryString from "query-string";
import dataYml from "/src/data.yaml";
import { roundToTwo } from "/src/helpers/formatNumber";

import _ from "lodash";

const getInsuredByCategory = (
  { title = "", firstname, lastname, company_name = "" },
  category = "person"
) => {
  if (category === "legal_entity") return company_name;
  if (title === undefined || title === "") return "-";
  return `${title}${firstname} ${lastname}`;
};

const getDeliveryAddress = (insured) => {
  return insured.addresses[1] ?? insured;
};

const covarageDateFormat = (from, to) => {
  if (!from && !to) return "-";
  const dateFormat = "yyyy-MM-dd";
  const formatDT = "dd/MM/yyyy";
  return `${format(new Date(parse(from, dateFormat, new Date())), formatDT)} - ${format(
    new Date(parse(to, dateFormat, new Date())),
    formatDT
  )}`;
};

const dateFormat = (date) => {
  if (date === undefined || date === null || date === "") return "-";
  const formatDT = "dd/MM/yyyy";
  const dateFormat = "yyyy-MM-dd";
  return `${format(new Date(parse(date, dateFormat, new Date())), formatDT)}`;
};

const dateTimeFormat = (date) => {
  if (date === undefined || date === null || date === "") return "-";

  const dateFormat = "dd/MM/yyyy";
  return `${format(new Date(date), dateFormat)}`;
};

const checkEmpty = (string) => {
  return string === undefined || string === null || string === "" ? "-" : string;
};

const withholdingTax = (value, tax = 5) => {
  return value - value * (tax / 100);
};

const toCamelCaseKeys = (obj) => {
  return _.mapKeys(obj, _.rearg(_.camelCase, 1));
};

const urlStringify = (urlParams) => {
  return queryString.stringify(urlParams, {
    arrayFormat: "bracket",
  });
};

const searchByText = (collection, text, include) => {
  text = _.toLower(text);
  return _.filter(collection, (object) => {
    return _(object)
      .pick(include)
      .some((string) => {
        return _(string).toLower().includes(text);
      });
  });
};

const checkCmiPrice = (cmiPaymentDetail, vehicleType) => {
  if (cmiPaymentDetail) {
    return cmiPaymentDetail.selling_price;
  }
  if (vehicleType) {
    return dataYml.cmi_price[vehicleType];
  }
};

const defaultValue = (params, options) => {
  if (Array.isArray(params)) {
    return _.filter(options, (o) => _.includes(params, o.value));
  }
  return _.filter(options, (o) => o.value === params);
};

const isScrollBottomEnd = ({ scrollHeight, scrollTop, clientHeight }) => {
  return scrollHeight - scrollTop === clientHeight;
};

const removeFileFromFileList = (inputRef, index) => {
  const dt = new DataTransfer();
  const { files } = inputRef.current;

  for (let i = 0; i < files.length; i++) {
    if (index !== i) dt.items.add(files[i]);
  }

  inputRef.current.files = dt.files;
};

const convertFileListToArray = (formData, field, elementId) => {
  const element = document.getElementById(elementId);
  if (!element) return;

  for (let i = 0; i < element.files.length; i++) {
    formData.append(`${field}[${elementId}s][${i}]`, element.files[i]);
  }

  formData.delete(`${field}[${elementId}]`);
  return formData;
};

const calculateVatStampTotalFromPremiumGross = (premiumGross) => {
  const stamp = roundToTwo(Math.ceil(premiumGross * 0.004));
  const vat = roundToTwo((premiumGross + stamp) * 0.07);
  const sellingPrice = roundToTwo(premiumGross + stamp + vat);

  return { sellingPrice, stamp, vat };
};

const calculateVatStampGrossFromSellingPrice = (sellingPrice) => {
  const vat = roundToTwo((sellingPrice * 0.07) / 1.07);
  const stamp = roundToTwo(Math.ceil(((sellingPrice - vat) * 0.004) / 1.004));
  const grossAmount = roundToTwo(sellingPrice - stamp - vat);

  return { grossAmount, stamp, vat };
};

const calculatePrice = (value, type, addonPrice, basePrice = {}) => {
  const prices = (({ premium_gross, premium_stamp, premium_vat, selling_price, total_price }) => ({
    premium_gross,
    premium_stamp,
    premium_vat,
    selling_price,
    total_price,
  }))(basePrice);
  for (let key in prices) {
    if (typeof prices[key] === "string" || prices[key] instanceof String) {
      prices[key] = parseFloat(prices[key]);
    }
  }

  switch (type) {
    case "premium_gross": {
      prices.premium_gross = value || 0;
      prices.premium_stamp = Math.ceil(prices.premium_gross * 0.004);
      prices.premium_vat = roundToTwo((prices.premium_gross + prices.premium_stamp) * 0.07);
      prices.selling_price = prices.premium_gross + prices.premium_stamp + prices.premium_vat;
      prices.total_price = prices.selling_price + (addonPrice ? parseFloat(addonPrice) : 0);
      break;
    }
    case "selling_price": {
      prices.selling_price = value || 0;
      prices.premium_vat = roundToTwo((prices.selling_price * 0.07) / 1.07);
      prices.premium_stamp = Math.ceil(
        ((prices.selling_price - prices.premium_vat) * 0.004) / 1.004
      );
      prices.premium_gross = roundToTwo(
        prices.selling_price - prices.premium_vat - prices.premium_stamp
      );
      prices.total_price = roundToTwo(
        prices.selling_price + (addonPrice ? parseFloat(addonPrice) : 0)
      );
      break;
    }
    case "premium_stamp":
    case "premium_vat": {
      prices[type] = value || 0;
      prices.selling_price = prices.premium_gross + prices.premium_stamp + prices.premium_vat;
      prices.total_price = prices.selling_price + (addonPrice ? parseFloat(addonPrice) : 0) || 0;
      break;
    }
  }

  return prices;
};

const getWhTaxStatusText = (includeWhTax) => {
  return includeWhTax ? "หักภาษี ณ ที่จ่าย 1%" : "ไม่หักภาษี ณ ที่จ่าย";
};

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

function escapeHtml(unsafe) {
  return unsafe
    .replaceAll("&", "&amp;")
    .replaceAll("<", "&lt;")
    .replaceAll(">", "&gt;")
    .replaceAll('"', "&quot;")
    .replaceAll("'", "&#039;");
}

function unEscapeHtml(unsafe) {
  return unsafe
    .replaceAll("&amp;", "&")
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">")
    .replaceAll("&quot;", '"')
    .replaceAll("&#039;", "'");
}

function mockEmail() {
  const chars = "abcdefghijklmnopqrstuvwxyz1234567890";
  let string = "";
  for (var ii = 0; ii < 15; ii++) {
    string += chars[Math.floor(Math.random() * chars.length)];
  }
  return string + "@mail.com";
}

export {
  getInsuredByCategory,
  getDeliveryAddress,
  covarageDateFormat,
  dateFormat,
  dateTimeFormat,
  checkEmpty,
  withholdingTax,
  toCamelCaseKeys,
  urlStringify,
  searchByText,
  checkCmiPrice,
  defaultValue,
  isScrollBottomEnd,
  removeFileFromFileList,
  convertFileListToArray,
  calculateVatStampGrossFromSellingPrice,
  calculateVatStampTotalFromPremiumGross,
  calculatePrice,
  getWhTaxStatusText,
  isJsonString,
  escapeHtml,
  unEscapeHtml,
  mockEmail,
};
