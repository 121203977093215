import React, { useState, useContext } from "react";

import filterContext from "./store.js";

import Select from "../../../../components/filter/Select";

const InsurersFilter = ({ optionsLists, active, fnCallbackFilterValues }) => {
  const [options, setOptions] = useState(optionsLists);
  const [isAllChecked, setIsAllChecked] = useState(true);
  const [state, dispatch] = useContext(filterContext);
  const [activeButton, setActiveButton] = useState(active);

  const handleChange = (isChecked, value) => {
    const opts = [...options];
    let filterInsurers = [];

    if (value === "all") {
      opts.map((o) => {
        o.isChecked = !isChecked;
      });

      if (!isChecked) {
        opts.map((o) => {
          filterInsurers.push(o.value);
        });
      }

      setIsAllChecked(!isChecked);
      setActiveButton(!isChecked);
    } else {
      opts.filter((o) => {
        if (o.value === value) {
          o.isChecked = !isChecked;
        }

        if (o.isChecked) filterInsurers.push(o.value);
      });

      setActiveButton(true);
    }

    setOptions(opts);
    dispatch({
      type: "UPDATE",
      payload: {
        ...state,
        filterInsurers,
      },
    });

    fnCallbackFilterValues("insurers", filterInsurers);
  };

  const renderLabel = (stateValues) => {
    let label = "บริษัทประกันภัย";
    let count = 0;
    if (stateValues.length == 0) count = options.length;
    else count = stateValues.length;

    label = (
      <>
        <span className="text-primary inline-block mr-1">{`${count}`}</span>{" "}
        {label}
      </>
    );
    return label;
  };

  return (
    <div>
      <Select
        type="checkIcon"
        label={renderLabel(state.filterInsurers)}
        options={options}
        active={activeButton}
        toggleAllOption={{
          label: "เลือกทั้งหมด/ล้างตัวเลือก",
          value: "all",
          isChecked: isAllChecked,
        }}
        onChangeValue={handleChange}
      />
    </div>
  );
};

export default InsurersFilter;
