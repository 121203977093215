import React from "react";
import { formatNumberWithCurrency } from "~src/helpers";
import { ProductType } from "~src/types/product";

interface Props {
  totalAmount: string;
  insureds: number;
}

const TotalAmount: React.FC<Props> = ({ totalAmount, insureds = 1 }) => {
  return <div>{formatNumberWithCurrency(Number(totalAmount) / insureds)}</div>;
};

export default TotalAmount;
