import React from "react";

import Label from "/src/components/staff/Label";

const Vehicle = (props) => {
  const { data } = props;
  return (
    <div className="row">
      <div className="col-md-6">
        <Label title="ยี่ห้อรถยนต์" value={data?.car_brand ?? "-"} />
      </div>
      <div className="col-md-6">
        <Label title="รุ่น" value={data?.car_model ?? "-"} />
      </div>
      <div className="col-md-6">
        <Label title="ปีที่จดทะเบียน" value={data?.car_year ?? "-"} />
      </div>
      <div className="col-md-6">
        <Label title="จังหวัดจดทะเบียน" value={data?.plate_provience ?? "-"} />
      </div>
      <div className="col-md-6">
        <Label title="เลขทะเบียน" value={data?.plate_number ?? "-"} />
      </div>
    </div>
  );
};

export default Vehicle;
