import React from "react";
import ButtonRadius from "/src/components/form/button/ButtonRadius";
import Cookies from "js-cookie";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";

const Referral: React.FC = () => {
  const referal_code = Cookies.get("referal_code");
  const referralLink = `https://form.typeform.com/to/X337f9Fh#mtlpagent=refer&dept=refer&refercode=${referal_code}`
  const { addToast } = useToasts();

  const setAlert = (text) => {
    addToast(
      <div>
        <h4>{text}</h4>
      </div>,
      {
        appearance: "success",
        autoDismiss: true,
      }
    );
  };

  return (
    <div className="p-2">
      <h2 className="px-1 mb-1 text-sm font-bold">Referral Programs</h2>

      <CopyToClipboard text={referralLink}>
        <ButtonRadius
          variant="contained"
          color="secondary"
          size="xs"
          fullWidth={true}
          classNameOption="w-full"
          onClick={() => setAlert("คัดลอกลิ้งค์แล้ว!")}
        >
          คลิกที่นี่ เพื่อรับลิงค์แนะนำเพื่อน
        </ButtonRadius>
      </CopyToClipboard>

      <div className="px-1 text-2xs break-words">{referralLink}</div>
    </div>
  );
};

export default Referral;
