import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import locationJson from "~/src/assets/database.json";
import { RootState } from "~src/store";
import Fuse from "fuse.js";

interface Location {
  sub_district: string;
  district: string;
  province: string;
  zipcode: number;
  sub_district_code: number;
  district_code: number;
  province_code: number;
}

const initialState = {
  data: [...locationJson] as Location[],
  result: [] as Location[],
  selected: {
    sub_district: "",
    district: "",
    province: "",
    zipcode: 0,
    sub_district_code: 0,
    district_code: 0,
    province_code: 0,
  } as Location,
};

const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setResult: (state, { payload }) => {
      state.result = payload;
    },
    setLocationSelected: (state, { payload }: PayloadAction<Location>) => {
      state.selected = payload;
    },
  },
});

export const { setResult, setLocationSelected } = locationSlice.actions;

export const locationsSelector = (state: RootState) => state.locations.data;
export const locationResultSelector = (state: RootState) => state.locations.result;
export const locationSelectedSelector = (state: RootState) => state.locations.selected;

export const handleFilterLocation = (value: string, type: string) => (dispatch, getState) => {
  const state = getState();
  const locationData = state.locations.data;
  const selected = state.locations.selected;

  const options = {
    keys: [type],
    isCaseSensitive: true,
    useExtendedSearch: true,
  };

  const fuse = new Fuse(locationData, options);
  const regexPrefix = type === "zipcode" ? "^" : "'";
  const searchResult = fuse.search(`${regexPrefix}${value}`);

  const filteredLocations = searchResult.map((data) => data.item);

  dispatch(setLocationSelected({ ...selected, [type]: value }));
  dispatch(setResult(filteredLocations));
};

export const handleCallbackLocation = (data: Location) => (dispatch) => {
  dispatch(setLocationSelected(data));
};

export default locationSlice.reducer;
