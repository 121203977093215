import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import QueryString from "query-string";
import _ from "lodash";

import { formatNumberWithCurrency } from "/src/helpers/formatNumber";

import {
  FormGroup,
  TextField,
  Button,
  SelectField,
  Switcher,
  DatePickerField,
  Radio,
  LinkButton,
} from "/src/components/form";

import compareContext from "/src/context/Compare";

import yaml from "/src/data";

import "./compare.scss";

const Compare = () => {
  const history = useHistory();
  const [state, dispatch] = useContext(compareContext);
  const [locationSearch, setLocationSearch] = useState(location.search);

  const handleResetCompare = () => {
    dispatch({
      type: "RESET",
    });
  };

  const handleRemove = (id) => {
    const ids = _.remove(state.compareIds, (o) => {
      return o != id;
    });
    const objs = _.remove(state.compareLists, (o) => {
      return o.id != id;
    });
    dispatch({
      type: "REMOVE",
      payload: {
        ...state,
        compareIds: [...ids],
        compareLists: [...objs],
      },
    });
  };

  const redirectToCompare = () => {
    history.push(
      `/compares${locationSearch}&${QueryString.stringify({
        compares: state.compareIds,
      })}`
    );
  };

  const maxCompare = 3;
  const emptyCompareSlot = [];
  for (let i = 0; i < maxCompare - state.compareLists.length; i++) {
    emptyCompareSlot.push(
      <div key={i} className="col-md-3">
        <div className="compare-bar-box-list empty">
          เลือกประกันอื่นๆ <br />
          เพื่อเปรียบเทียบ
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        state.compareLists.length > 0 ? "compare-bar open" : "compare-bar"
      }
    >
      <div className="compare-bar-box">
        <div className="row">
          {state.compareLists.map((obj, index) => (
            <div key={index} className="col-md-3">
              <div className="compare-bar-box-list">
                <button
                  type="button"
                  className="compare-bar-box-list_close"
                  onClick={() => handleRemove(obj.id)}
                >
                  <i className="material-icons">close</i>
                </button>
                <div className="img">
                  <img src={obj.insurer_img} />
                </div>
                <div className="wrap">
                  <div className="title">{obj.insurer_name}</div>
                  <div className="sub-title">{obj.insurer_plan}</div>
                  <small>{`${yaml.policy_type[obj.insurer_class]} ${
                    yaml.repair_facility[obj.repair_facility]
                  }`}</small>
                  <div className="price">
                    {formatNumberWithCurrency(obj.standard_price)}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {emptyCompareSlot}
          <div className="col-md-3">
            <div className="compare-bar-box-actions">
              <div>
                <Button
                  fullwidth
                  color="primary"
                  onClick={redirectToCompare}
                  disabled={state.compareLists.length == 1 ? true : false}
                >
                  เปรียบเทียบราคา
                </Button>
              </div>
              <div>
                <Button fullwidth variant="none" onClick={handleResetCompare}>
                  ล้างค่าใหม่
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compare;
