import React, { useState, useEffect } from "react";
import SectionHeader from "/src/components/layout/staff/SectionHeader";
import ErrorPage from "/src/components/errorPage";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import Cookies from "js-cookie";
import RenewLeadTable from "./table/RenewLeadTable";
import useFetch from "/src/hooks/useFetch";
import { urlStringify } from "~src/helpers/helpers";
import { ButtonRadius, SelectField } from "~src/components/form";
import axios from "~src/helpers/configAxios";
import { dateFormat } from "/src/helpers/dateTime";
import DatePickerRange from "../../../components/datePickerRange/DatePickerRange";

import "./lead.scss";
import "babel-polyfill";
import SearchForm from "~src/components/staff/SearchForm";
import useInput from "~src/hooks/useInput";

const PER_PAGE = 10;
const DEFAULT_PAGE = 1;
const DEFAULT_STATUS = "";
const BASE_URL = window.location.pathname;

const ALLOW_LEADS_STATUS = [
  { label: "ได้รับมอบหมาย", value: "assigned" },
  { label: "ติดตาม", value: "follow_up" },
  { label: "สำเร็จ", value: "yes_sale" },
  { label: "ไม่สำเร็จ", value: "no_sale" },
  { label: "ไม่รับสาย", value: "callback" },
  { label: "รอชำระค่าเบี้ย", value: "waiting_for_payment" },
  { label: "ลูกค้าไม่ต้องการให้ติดต่อ", value: "do_not_contact" },
  { label: "ข้อมูลไม่อัพเดท", value: "not_update" },
  { label: "ข้อมูลซ้ำ", value: "duplicate" },
];

const All = ({ location }) => {
  const [saleList, setSaleList] = useState([]);

  useEffect(() => {
    axios.get("api/sales").then((res) => {
      const saleResponse = res.data.content;
      const salesListTemp = [];
      if (saleResponse && saleResponse?.length > 0) {
        saleResponse.map((sale, index) =>
          salesListTemp.push({ label: sale.email, value: sale.id })
        );
        setSaleList(salesListTemp);
      }
    });
  }, []);

  const [loaded, setLoaded] = useState(true);
  const [leads, setLead] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
  const role = Cookies.get("role");
  const role_json = JSON.parse(role).code;

  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const { value: search_value, bind: handleChangeSearch } = useInput(
    queryParams?.renew_keyword ?? ""
  );

  const [status, setStatus] = useState(queryParams.status_eq ?? DEFAULT_STATUS);
  const [filterSale, setFilterSale] = useState(queryParams.sale ?? "");
  const [sortBy, setSortBy] = useState(!!queryParams?.end_date_sort ?? null);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(queryParams.page ?? DEFAULT_PAGE);
  const paramsStateStart = Object.keys(queryParams).find((key) => key.includes("start"));
  const paramsStateEnd = Object.keys(queryParams).find((key) => key.includes("end"));
  const [filterState, setFilterState] = useState({
    dateType: paramsStateStart?.split("_")[0] ?? "dropped",
    startAt:
      queryParams?.[paramsStateStart] ??
      dateFormat(new Date(new Date().setMonth(new Date().getMonth() + -6))),
    endAt:
      queryParams?.[paramsStateEnd] ??
      dateFormat(new Date(new Date().setMonth(new Date().getMonth() + 6))),
  });

  const url = `/api/renew_leads?${urlStringify({
    page: currentPage,
    per_page: PER_PAGE,
    ...queryParams,
  })}`;

  const { response, error, isLoading } = useFetch(url);

  useEffect(() => {
    if (!isLoading && !error) {
      const results = response?.content;
      const totalPages = response.total_pages;
      setLead(results);
      setLoaded(false);
      setIsError(false);
      setPageCount(totalPages);
    }

    if (error) {
      setIsError(true);
    }
  }, [isLoading]);

  const getSearchParams = () => {
    const params = {
      renew_keyword: search_value,
      status_eq: status,
      sale: filterSale,
      per_page: PER_PAGE,
      page: 1,
      end_date_sort: sortBy && queryParams?.end_date_sort === "desc" ? "asc" : "desc",
      start_at: filterState.startAt,
      end_at: filterState.endAt,
    };
    params[`${filterState.dateType}_start_date`] = params.start_at;
    params[`${filterState.dateType}_end_date`] = params.end_at;
    delete params.start_at;
    delete params.end_at;

    return params;
  };

  const handlePageClick = (e) => {
    const selectedPage = parseInt(e.selected) + 1;

    const queries = urlStringify({
      per_page: PER_PAGE,
      page: selectedPage,
      renew_keyword: search_value,
      status_eq: status,
      sale: filterSale,
      end_date_sort: queryParams?.end_date_sort,
      start_at: filterState.startAt,
      end_at: filterState.endAt,
    });
    queries[`${filterState.dateType}_start_date`] = queries.start_at;
    queries[`${filterState.dateType}_end_date`] = queries.end_at;
    delete queries.start_at;
    delete queries.end_at;

    window.location.href = `${BASE_URL}?${urlStringify(queries)}`;
  };

  const handleChangeStatus = (evt) => {
    const status = evt.target.value;
    setStatus(status);
  };

  const handleChangeSale = (evt) => {
    const sale = evt.target.value;
    setFilterSale(sale);
  };

  const performSearch = () => {
    const queries = urlStringify(getSearchParams());
    window.location.href = `${BASE_URL}?${queries}`;
  };

  const handleClickSearch = () => {
    performSearch();
  };

  const handleClickFilter = () => {
    performSearch();
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") performSearch();
  };

  const handleChangeOption = (e) => {
    setFilterState({ ...filterState, dateType: e.target.value });
  };

  const onDateChange = (dateFrom, dateTo) => {
    setFilterState({
      ...filterState,
      startAt: dateFormat(dateFrom),
      endAt: dateFormat(dateTo),
    });
  };

  return (
    <div className="lead-page">
      {isError ? (
        <ErrorPage />
      ) : (
        <>
          <SectionHeader
            title="RenewLead"
            breadcrumbs={[{ url: "#", label: "lead", current: true }]}
            searchComponent={
              <SearchForm
                type="text"
                placeholder="ค้นหาชื่อลูกค้า, เบอร์โทร, เลขออเดอร์"
                {...handleChangeSearch}
                handleClick={handleClickSearch}
                handleKeyPress={handleKeyPressSearch}
              />
            }
          />
          <section className="grid grid-cols-5 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
            <div className="">
              <span>สถานะ</span>
              <SelectField
                onChange={handleChangeStatus}
                placeholder="ทั้งหมด"
                options={ALLOW_LEADS_STATUS}
                value={status}
              />
            </div>

            {role_json !== "sales" && (
              <div className="">
                <span>ผู้รับผิดชอบ</span>
                <SelectField
                  onChange={handleChangeSale}
                  placeholder="ทั้งหมด"
                  options={saleList}
                  value={filterSale}
                />
              </div>
            )}

            <div className="">
              <span>สถานะ</span>
              <SelectField
                onChange={handleChangeOption}
                options={[
                  { label: "วันที่ได้ lead", value: "dropped" },
                  { label: "วันที่ได้รับมอบหมาย", value: "assigned" },
                  { label: "วันคุ้มครองที่หมดอายุ", value: "covered" },
                ]}
                value={filterState.dateType}
                className="h-9"
              />
            </div>

            <div className="w-64">
              <span>วันที่ทำรายการ</span>
              <DatePickerRange
                // resetValueCallback={resetValueCallback}
                onDateChange={onDateChange}
                values={[filterState.startAt, filterState.endAt]}
              />
            </div>

            <div className="text-center self-center mb-2 lg:mb-0 lg:mt-2 sm:col-span-2 md:col-span-4 lg:col-auto lg:text-left">
              <ButtonRadius color="secondary" onClick={handleClickFilter}>
                ค้นหา
              </ButtonRadius>
            </div>
          </section>

          <RenewLeadTable
            loading={isLoading}
            data={leads}
            setSortBy={setSortBy}
            sortBy={sortBy}
            handleClick={performSearch}
          />
          {pageCount > 1 && (
            <ReactPaginate
              previousLabel="Prev"
              nextLabel="Next"
              breakLabel="..."
              breakClassName="break-me"
              initialPage={Number(currentPage) - 1}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="gm-paginate"
              subContainerClassName="pages pagination"
              activeClassName="active"
              disableInitialCallback={true}
            />
          )}
        </>
      )}
    </div>
  );
};

export default All;
