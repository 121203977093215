import "babel-polyfill";
import axios from "/src/helpers/configAxios";
import encodeURL from "/src/helpers/encodeURL";

export const getCarBrand = async () => {
  const url = "/api/vehicles";
  return await axios.get(url);
};

export const getCarModel = async (brand) => {
  const url = `/api/vehicles/${encodeURL(brand)}`;
  return await axios.get(url);
};

export const getCarYear = async (brand, model) => {
  const url = `/api/vehicles/${encodeURL(brand)}/${encodeURL(model)}`;
  return await axios.get(url);
};

export const getCarSubModel = async (brand, model, year) => {
  const url = `/api/vehicles/${encodeURL(brand)}/${encodeURL(model)}/${encodeURL(year)}`;
  return await axios.get(url);
};

export const getSumInsuredInfo = (vehicleCodeName, isNoSubModel = 0) => {
  const url = `/api/vehicles/${encodeURL(
    vehicleCodeName
  )}/sum_insured?unsure_sub_model=${isNoSubModel}`;
  return axios.get(url);
};

export const getProvinces = async () => {
  const url = "/api/locations";
  return await axios.get(url);
};

export const getSearchMotors = async (queries) => {
  const url = `${process.env.API_URL}/api/products/search2/motor${queries}`;
  return await axios.get(url);
};

export const GetAllCountries = async () => {
  const url = `${process.env.BUCKET_URL}/locations/countires.json`;
  return await axios.get(url);
};

export const GetLocalProvinces = async () => {
  const url = `${process.env.APP_URL}/locations/provinces.json`;
  return await axios.get(url);
};

export const GetDistricts = async () => {
  const url = `${process.env.APP_URL}/locations/districts.json`;
  return await axios.get(url);
};

export const GetSubDistricts = async () => {
  const url = `${process.env.APP_URL}/locations/subDistricts.json`;
  return await axios.get(url);
};

export const GetZipcodes = async () => {
  const url = `${process.env.APP_URL}/locations/zipcodes.json`;
  return await axios.get(url);
};
