import React from "react";

interface Props {
  text: string;
}

const ToolTip: React.FC<Props> = ({ text }) => {
  return (
    <div className="cursor-pointer h-fit group">
      <div className="relative self-start cursor-pointer group">
        <i className="material-icons text-base text-gray-600">help</i>
      </div>

      <div className="absolute z-0 hidden w-64 p-10 text-xs font-thin text-white cursor-text rounded-10 bg-primary-color group-hover:block">
        {text}
      </div>
    </div>
  );
};

export default ToolTip;
