import React from "react";
import { SvgProps } from "./SvgProps";

export const Line: React.FC<SvgProps> = ({ width = 44, height = 44 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        d="M39,20.11c0-7.61-7.63-13.8-17-13.8S5,12.5,5,20.11c0,6.81,6.05,12.53,14.22,13.61.55.12,1.3.36,1.49.83a3.35,3.35,0,0,1,.06,1.54s-.2,1.2-.24,1.46c-.08.43-.34,1.68,1.47.91s9.79-5.76,13.36-9.87h0A12.27,12.27,0,0,0,39,20.11"
        fill="#00b900"
      />
      <path
        d="M18.55,16.43h-1.2a.33.33,0,0,0-.33.33v7.41a.33.33,0,0,0,.33.33h1.2a.33.33,0,0,0,.33-.33V16.76a.33.33,0,0,0-.33-.33"
        fill="#fff"
      />
      <path
        d="M26.75,16.43H25.56a.33.33,0,0,0-.33.33v4.4l-3.39-4.58,0,0h0l0,0H20.37a.33.33,0,0,0-.33.33v7.41a.33.33,0,0,0,.33.33h1.19a.33.33,0,0,0,.33-.33v-4.4l3.4,4.59a.3.3,0,0,0,.08.08h1.38a.33.33,0,0,0,.33-.33V16.76a.33.33,0,0,0-.33-.33"
        fill="#fff"
      />
      <path
        d="M15.67,22.64H12.43V16.76a.33.33,0,0,0-.33-.33H10.91a.33.33,0,0,0-.33.33v7.41h0a.32.32,0,0,0,.09.23h0a.36.36,0,0,0,.23.09h4.76a.33.33,0,0,0,.33-.33V23a.33.33,0,0,0-.33-.33"
        fill="#fff"
      />
      <path
        d="M33.34,18.28a.33.33,0,0,0,.33-.33V16.76a.33.33,0,0,0-.33-.33H28.57a.33.33,0,0,0-.23.09h0a.33.33,0,0,0-.1.23h0v7.41h0a.33.33,0,0,0,.1.23h0a.36.36,0,0,0,.22.09h4.77a.33.33,0,0,0,.33-.33V23a.33.33,0,0,0-.33-.33H30.1V21.39h3.24a.33.33,0,0,0,.33-.33V19.87a.33.33,0,0,0-.33-.33H30.1V18.28Z"
        fill="#fff"
      />
    </svg>
  );
};
