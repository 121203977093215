import React, { useState } from "react";
import CommonModal from "~/src/components/modal/common";
import HighLightText from "~/src/components/highlightText";
import { ButtonRadius } from "~/src/components/form";

type Props = {
  errorMsg?: { message: string };
  resetErrorBoundary?: () => void;
};

const ErrorFallback: React.FC<Props> = ({ errorMsg, resetErrorBoundary }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const TextError = [
    { highlight: false, text: "การเชื่อมต่อมีปัญหา โปรดลองใหม่ในภายหลัง", newline: true },
    { highlight: true, text: errorMsg?.message },
  ];

  return (
    <CommonModal open={isOpen} persistent={false} onClose={() => setIsOpen(false)}>
      <div className="animate-fade-in duration-700 flex flex-col justify-center items-center gap-20">
        <div className="text-center text-base text-primary">
          <HighLightText headerText={TextError} />
        </div>

        <ButtonRadius
          classNameOption="w-40"
          color="secondary"
          onClick={() => {
            setIsOpen(false);
            resetErrorBoundary?.();
            if (typeof window !== "undefined") window.location.reload();
          }}
        >
          ลองใหม่
        </ButtonRadius>
      </div>
    </CommonModal>
  );
};

export default ErrorFallback;
