import React, { useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import classNames from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import { useRecoilValue } from "recoil";
import _get from "lodash/get";
import { TextField } from "~/src/components/staff/form";
import InlineForm from "~src/components/Forms/InlineForm";
import { formatNumberWithCurrency } from "~/src/helpers/formatNumber";
import { messages } from "~src/helpers/messages";
import dataYaml from "~src/data.yaml";
import { standardVmiCommission, standardCmiCommission } from "~/src/states/orderCustom";
import {
  calculateVatStampTotalFromPremiumGross,
  calculateVatStampGrossFromSellingPrice,
} from "~src/helpers/helpers";
import MiniDropdown from "~src/components/Forms/MiniDropdown";
import { commissionType } from "~src/helpers/options";

interface Props {
  name?: string;
  label: string;
  isAddon?: boolean;
  addonPrice?: number;
  expandedHeader?: boolean;
  callbackAddon?: (addonPrice: number) => void;
  onError?: (error: any) => void;
}

const PremiumPanel: React.FC<Props> = ({
  name = "",
  label,
  addonPrice = 0,
  callbackAddon,
  onError,
  expandedHeader = true,
  isAddon = false,
}): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [discountMax, setDiscountMax] = useState<number>(0);
  const [previousPaymentType, setPreviousPaymentType] = useState("");
  const [previousPaymentComReduction, setPreviousPaymentComReduction] = useState(0);
  const standardCommission = useRecoilValue(isAddon ? standardCmiCommission : standardVmiCommission);

  const { register, errors, control, setValue } = useFormContext();
  const errorNames = _get(errors, name);
  const reducedPaidCommissionInfo = dataYaml.payment_method_reduced_commission;

  const agentUsername = useWatch({
    control,
    name: `order.agent_number`,
    defaultValue: "",
  });

  const insurerCode = useWatch({
    control,
    name: `product.insurer_code`,
    defaultValue: "",
  });
    
  const packageName = useWatch({ 
    control,
    name: `product.name`,
    defaultValue: ""
  });

  const insurerGrossAmount = useWatch({
    control,
    name: `${name}.product.insurer_gross_amount`,
    defaultValue: "0",
  });

  const insurerStampAmount = useWatch({
    control,
    name: `${name}.product.insurer_stamp_amount`,
    defaultValue: "0",
  });

  const insurerVatAmount = useWatch({
    control,
    name: `${name}.product.insurer_vat_amount`,
    defaultValue: "0",
  });

  const insurerSellingPrice = useWatch({
    control,
    name: `${name}.product.selling_price`,
    defaultValue: "0",
  });

  const discount = useWatch({
    control,
    name: `${name}.quote.discount`,
    defaultValue: "0",
  });

  const productType = useWatch({
    control,
    name: `product.type`,
    defaultValue: "",
  });

  const policyType = useWatch({
    control,
    name: `product.policy_type`,
    defaultValue: "",
  });

  const paymentType = useWatch({
    control,
    name: `payment.type`,
    defaultValue: "",
  });

  const commissionPaid = useWatch({
    control,
    name: `${name}.quote.paid_commission_percent`,
    defaultValue: "",
  });

  const ovPaid = useWatch({
    control,
    name: `${name}.quote.paid_ov_percent`,
    defaultValue: "",
  });

  const printingPaid = useWatch({
    control,
    name: `${name}.quote.paid_printing_percent`,
    defaultValue: "",
  });

  const receivedCommission = useWatch({
    control,
    name: `${name}.quote.received_commission`,
    defaultValue: "0"
  });

  const receivedOv = useWatch({
    control,
    name: `${name}.quote.received_ov`,
    defaultValue: "0"
  });

  const receivedPrinting = useWatch({
    control,
    name: `${name}.quote.received_printing`,
    defaultValue: "0"
  });

  const receivedCommissionType = useWatch({
    control,
    name: `${name}.quote.received_commission_unit`,
    defaultValue: "PERCENT"
  });

  const receivedOvType = useWatch({
    control,
    name: `${name}.quote.received_ov_unit`,
    defaultValue: "PERCENT"
  });

  const receivedPrintingType = useWatch({
    control,
    name: `${name}.quote.received_printing_unit`,
    defaultValue: "PERCENT"
  });

  const paidCommission = useWatch({
    control,
    name: `${name}.quote.paid_commission_percent`,
    defaultValue: "0"
  });

  const paidOv = useWatch({
    control,
    name: `${name}.quote.paid_ov_percent`,
    defaultValue: "0"
  });

  const paidPrinting = useWatch({
    control,
    name: `${name}.quote.paid_printing_percent`,
    defaultValue: "0"
  });

  useEffect(() => {
    setValue(`${name}.quote.received_commission_unit`, "PERCENT");
    setValue(`${name}.quote.received_ov_unit`, "PERCENT");
    setValue(`${name}.quote.received_printing_unit`, "PERCENT");
  }, []);

  useEffect(() => {
    const total =
      addonPrice +
      parseFloat(insurerGrossAmount) +
      parseFloat(insurerStampAmount) +
      parseFloat(insurerVatAmount) -
      parseFloat(discount || "0");

    setTotalAmount(total);
    if (isAddon) {
      callbackAddon?.(total);
    }
  }, [insurerGrossAmount, insurerStampAmount, insurerVatAmount, discount, addonPrice]);

  useEffect(() => {
    const amount = (Number(insurerGrossAmount) * Number(commissionPaid)) / 100;
    const discountMaxValue = amount === 0 ? 0 : amount - (amount * 5 / 100);
    setDiscountMax(Number(discountMaxValue.toFixed(2)));
  }, [commissionPaid, insurerGrossAmount]);

  useEffect(() => {
    setValue(`${name}.quote.discount`, Number(discount) > discountMax ? discountMax : discount);
  }, [discount, discountMax]);

  useEffect(() => {
    setValue(`${name}.quote.received_commission`, null);
    setValue(`${name}.quote.received_ov`, null);
    setValue(`${name}.quote.received_printing`, null);
    setValue(`${name}.quote.paid_commission_percent`, null);
    setValue(`${name}.quote.paid_ov_percent`, null);
    setValue(`${name}.quote.paid_printing_percent`, null);

    setValue(`${name}.quote.received_commission_unit`, "PERCENT");
    setValue(`${name}.quote.received_ov_unit`, "PERCENT");
    setValue(`${name}.quote.received_printing_unit`, "PERCENT");
  }, [agentUsername, insurerCode, packageName, productType, policyType]);

  useEffect(() => {
    const previousReduction = previousPaymentComReduction;
    const currentReduction = parseInt(reducedPaidCommissionInfo[paymentType] || 0);
    const netReduction = currentReduction - previousReduction;
    const newCommission = Number(commissionPaid) - netReduction;
    const overflowReduction = Math.min(newCommission, 0);
    if (previousPaymentType === "" && commissionPaid !== "") {
      setValue(`${name}.quote.paid_commission_percent`, Math.max(newCommission, 0));
    } else {
      setValue(`${name}.quote.received_commission`, null);
      setValue(`${name}.quote.received_ov`, null);
      setValue(`${name}.quote.received_printing`, null);
      setValue(`${name}.quote.paid_commission_percent`, null);
      setValue(`${name}.quote.paid_ov_percent`, null);
      setValue(`${name}.quote.paid_printing_percent`, null);

      setValue(`${name}.quote.received_commission_unit`, "PERCENT");
      setValue(`${name}.quote.received_ov_unit`, "PERCENT");
      setValue(`${name}.quote.received_printing_unit`, "PERCENT");
    }
    setPreviousPaymentComReduction(currentReduction + overflowReduction);
    setPreviousPaymentType(paymentType);
  }, [paymentType]);

  const onChangePremiumGross = () => {
    const { sellingPrice, stamp, vat } = calculateVatStampTotalFromPremiumGross(
      parseFloat(insurerGrossAmount)
    );

    setValue(`${name}.product.selling_price`, sellingPrice);
    setValue(`${name}.product.insurer_stamp_amount`, stamp);
    setValue(`${name}.product.insurer_vat_amount`, vat);
  };

  const onChangeSellingPrice = () => {
    const { grossAmount, stamp, vat } = calculateVatStampGrossFromSellingPrice(
      parseFloat(insurerSellingPrice)
    );

    setValue(`${name}.product.insurer_gross_amount`, grossAmount);
    setValue(`${name}.product.insurer_stamp_amount`, stamp);
    setValue(`${name}.product.insurer_vat_amount`, vat);
  };

  const onChangeVatOrStampAmount = (e, key) => {
    const { value } = e.target;

    const startingValue = {
      insurer_stamp_amount: insurerStampAmount,
      insurer_vat_amount: insurerVatAmount,
    };
    startingValue[key] = value;

    const sellingPrice =
      (parseFloat(insurerGrossAmount) || 0) +
      (parseFloat(startingValue.insurer_stamp_amount) || 0) +
      (parseFloat(startingValue.insurer_vat_amount) || 0);
    setValue(`${name}.product.insurer_stamp_amount`, startingValue.insurer_stamp_amount);
    setValue(`${name}.product.insurer_vat_amount`, startingValue.insurer_vat_amount);
    setValue(`${name}.product.selling_price`, sellingPrice);
  };

  const onBlurOvPaid = () => {
    if (commissionPaid !== null && commissionPaid !== "") {
      setValue(`${name}.quote.paid_ov_percent`, ovPaid === null || ovPaid === "" ? 0 : ovPaid);
    }
  };

  const onBlurPrintingPaid = () => {
    if (commissionPaid !== null && commissionPaid !== "") {
      setValue(
        `${name}.quote.paid_printing_percent`,
        printingPaid === null || printingPaid === "" ? 0 : printingPaid
      );
    }
  };

  const convertReceivedCommission = (key, value, unit) => {
    if (!insurerGrossAmount || parseFloat(insurerGrossAmount) == 0) return;
    
    if (unit == "PERCENT")
      setValue(key, parseFloat(((parseFloat(value) / parseFloat(insurerGrossAmount)) * 100).toFixed(2)));
    if (unit == "BAHT")
      setValue(key, parseFloat(((parseFloat(value) * parseFloat(insurerGrossAmount)) / 100).toFixed(2)));
  }

  const getShortenCommissionUnit = (unit) => {
    switch(unit) {
      case "BAHT": return "฿";
      case "PERCENT": return "%";
      default: return "";
    }
  }

  const applyStandardCommission = () => {
    setValue(`${name}.quote.received_commission_unit`, standardCommission?.received_commission_unit || "PERCENT");
    setValue(`${name}.quote.received_ov_unit`, standardCommission?.received_ov_unit || "PERCENT");
    setValue(`${name}.quote.received_printing_unit`, standardCommission?.received_printing_unit || "PERCENT");
    setValue(`${name}.quote.received_commission`, standardCommission?.received_commission || 0);
    setValue(`${name}.quote.received_ov`, standardCommission?.received_ov || 0);
    setValue(`${name}.quote.received_printing`, standardCommission?.received_printing || 0);
    setValue(`${name}.quote.paid_commission_percent`, Math.max((standardCommission?.paid_commission_percent || 0) - parseInt(reducedPaidCommissionInfo[previousPaymentType] || 0), 0));
    setValue(`${name}.quote.paid_ov_percent`, standardCommission?.paid_ov_percent || 0);
    setValue(`${name}.quote.paid_printing_percent`, standardCommission?.paid_printing_percent || 0);
  }

  return (
    <div className="p-4 w-full bg-white shadow rounded-5">
      {expandedHeader ? (
        <h6 className="flex items-center justify-between p-1 px-2 text-sm font-bold bg-gray-100">
          {label}
          <button
            type="button"
            onClick={() => setExpanded(!expanded)}
            className="flex items-center justify-center w-6 h-6 bg-white shadow outline-none rounded-5 focus:outline-none"
          >
            {expanded ? <RemoveIcon /> : <AddIcon />}
          </button>
        </h6>
      ) : (
        <p className="text-sm">{label}</p>
      )}

      <div className={classNames(expanded ? "block" : "hidden")}>
        <div className="grid grid-rows-2 gap-4 mt-4 ml-4">
          {!isAddon && (
            <>
              <InlineForm label="ทุนประกัน" className="text-sm text-gray-600" require>
                <TextField
                  name={`${name}.product.sum_insured`}
                  type="number"
                  currency
                  step="any"
                  min="0"
                  inputRef={register(messages.required)}
                  onWheel={(event) => event.target.blur()}
                  error={errorNames?.product?.sum_insured?.message}
                />
              </InlineForm>

              <hr className="my-4" />
            </>
          )}

          <InlineForm label="เบี้ยประกันภัยสุทธิ" className="text-sm text-gray-600" require>
            <TextField
              name={`${name}.product.insurer_gross_amount`}
              type="number"
              currency
              step="any"
              min="0"
              inputRef={register(messages.required)}
              onChange={onChangePremiumGross}
              onWheel={(event) => event.target.blur()}
              error={errorNames?.product?.insurer_gross_amount?.message}
            />
          </InlineForm>

          <InlineForm label="อากรแสตมป์" className="text-sm text-gray-600" require>
            <TextField
              name={`${name}.product.insurer_stamp_amount`}
              type="number"
              step="any"
              currency
              min="0"
              inputRef={register(messages.required)}
              onChange={(e) => onChangeVatOrStampAmount(e, "insurer_stamp_amount")}
              onWheel={(event) => event.target.blur()}
              error={errorNames?.product?.insurer_stamp_amount?.message}
            />
          </InlineForm>

          <InlineForm label="ภาษีมูลค่าเพิ่ม" className="text-sm text-gray-600" require>
            <TextField
              name={`${name}.product.insurer_vat_amount`}
              type="number"
              step="any"
              currency
              min="0"
              inputRef={register(messages.required)}
              onChange={(e) => onChangeVatOrStampAmount(e, "insurer_vat_amount")}
              onWheel={(event) => event.target.blur()}
              error={errorNames?.product?.insurer_vat_amount?.message}
            />
          </InlineForm>

          <InlineForm label="เบี้ยประกัน" className="text-sm text-gray-600" require>
            <TextField
              name={`${name}.product.selling_price`}
              type="number"
              step="any"
              currency
              min="0"
              inputRef={register(messages.required)}
              onChange={onChangeSellingPrice}
              onWheel={(event) => event.target.blur()}
              error={errorNames?.product?.standard_price?.message}
            />
          </InlineForm>

          {addonPrice !== 0 && (
            <InlineForm label="เบี้ยประกัน พ.ร.บ." className="text-sm text-gray-600" require>
              <p className="text-xs text-right mr-2"> {formatNumberWithCurrency(addonPrice)} </p>
            </InlineForm>
          )}

          <InlineForm label="ส่วนลด" className="text-sm text-gray-600">
            <TextField
              name={`${name}.quote.discount`}
              type="number"
              step="any"
              min="0"
              max={discountMax}
              currency
              inputRef={register}
              onWheel={(event) => event.target.blur()}
              error={errorNames?.quote?.discount?.message}
            />
          </InlineForm>

          <InlineForm label="ส่วนลดสูงสุด" className="text-sm text-gray-600">
            <p className="text-right">{discountMax} ฿</p>
          </InlineForm>

          <hr className="my-4" />
          <div className="row px-2 grid grid-cols-12">
            <div className="pl-2 col-start-1 col-end-7 text-gray-400">ประเภทค่าคอมมิชชั่น</div>
            <div className="pr-2 col-start-7 col-end-10 text-gray-400 flex justify-end">ค่าคอมมิชชั่นมาตรฐาน</div>
          </div>
          <InlineForm 
            label="คอมรับ"
            className="text-sm text-gray-600" 
            require
            prefix={
              <div className={`w-12 h-8 pr-1 flex flex-wrap justify-end content-center 
                ${standardCommission?.received_commission === parseInt(receivedCommission) && standardCommission?.received_commission_unit == receivedCommissionType
                  ? "text-gray-400" : "text-secondary"}`}
              >
                {standardCommission?.received_commission} {getShortenCommissionUnit(standardCommission?.received_commission_unit)}
              </div>
            }
            subfix={
              <MiniDropdown 
                required
                name={`${name}.quote.received_commission_unit`}
                control={control}
                errors={errors}
                options={commissionType}
                callbackOnChange={(value) => {convertReceivedCommission(`${name}.quote.received_commission`, receivedCommission, value)}}
              />
            }
          >
            <TextField
              style={{ textAlign: "right" }}
              name={`${name}.quote.received_commission`}
              type="number"
              step="any"
              min="0"
              inputRef={register(messages.required)}
              onWheel={(event) => event.target.blur()}
              error={errorNames?.quote?.received_commission_percent?.message}
            />
          </InlineForm>

          <InlineForm 
            label="OV รับ" 
            className="text-sm text-gray-600" 
            require
            prefix={
              <div className={`w-12 h-8 pr-1 flex flex-wrap justify-end content-center 
                ${standardCommission?.received_ov === parseInt(receivedOv) && standardCommission?.received_ov_unit == receivedOvType 
                  ? "text-gray-400" : "text-secondary"}`}
              >
                {standardCommission?.received_ov} {getShortenCommissionUnit(standardCommission?.received_ov_unit)}
              </div>
            }
            subfix={
              <MiniDropdown 
                required
                name={`${name}.quote.received_ov_unit`}
                control={control}
                errors={errors}
                options={commissionType}
                callbackOnChange={(value) => {convertReceivedCommission(`${name}.quote.received_ov`, receivedOv, value)}}
              />
            }
          >
            <TextField
              style={{ textAlign: "right" }}
              name={`${name}.quote.received_ov`}
              type="number"
              step="any"
              min="0"
              inputRef={register(messages.required)}
              onWheel={(event) => event.target.blur()}
              error={errorNames?.quote?.received_ov_percent?.message}
            />
          </InlineForm>

          <InlineForm 
            label="Printing รับ" 
            className="text-sm text-gray-600" 
            require
            prefix={
              <div className={`w-12 h-8 pr-1 flex flex-wrap justify-end content-center 
                ${standardCommission?.received_printing === parseInt(receivedPrinting) && standardCommission?.received_printing_unit == receivedPrintingType
                  ? "text-gray-400" : "text-secondary"}`}
              >
                {standardCommission?.received_printing} {getShortenCommissionUnit(standardCommission?.received_printing_unit)}
              </div>
            }
            subfix={
              <MiniDropdown
                required
                name={`${name}.quote.received_printing_unit`}
                control={control}
                errors={errors}
                options={commissionType}
                callbackOnChange={(value) => {convertReceivedCommission(`${name}.quote.received_printing`, receivedPrinting, value)}}
              />
            }
          >
            <TextField
              name={`${name}.quote.received_printing`}
              type="number"
              step="any"
              min="0"
              style={{ textAlign: "right" }}
              inputRef={register(messages.required)}
              onWheel={(event) => event.target.blur()}
              error={errorNames?.quote?.received_printing_percent?.message}
            />
          </InlineForm>

          <InlineForm 
            label={
              <div className="row flex flex-col">
                <div className="pl-2 text-gray-600">คอมจ่าย(%) <span className="text-error">*</span></div>
                <div className=" text-xs pl-6 text-gray-500">ผ่อนเงินสด 3 เดือนหัก 1%</div>
                <div className=" text-xs pl-6 text-gray-500">ผ่อนเงินสด 6 เดือนหัก 3%</div>
                <div className=" text-xs pl-6 text-gray-500">ผ่อนเงินสด 10 เดือนหัก 8%</div>
              </div>
            }
            className="text-sm text-gray-600" 
            require
            prefix={
              <div className="flex flex-col ">
                <div className={`w-12 h-8 pr-1 flex flex-wrap justify-end content-center ${standardCommission?.paid_commission_percent === parseInt(paidCommission) ? "text-gray-400" : "text-secondary"}`}>
                  {standardCommission?.paid_commission_percent} {standardCommission?.paid_commission_percent || standardCommission?.paid_commission_percent == 0 ? "%" : ""}
                </div>
              </div>
            }
          >
            <TextField
              name={`${name}.quote.paid_commission_percent`}
              type="number"
              step="any"
              min="0"
              style={{ textAlign: "right" }}
              inputRef={register(messages.required)}
              onWheel={(event) => event.target.blur()}
              error={errorNames?.quote?.paid_commission_percent?.message}
            />
          </InlineForm>
          <InlineForm 
            label="OV จ่าย (%)" 
            className="text-sm text-gray-600"
            require
            prefix={
              <div className={`w-12 h-8 pr-1 flex flex-wrap justify-end content-center ${standardCommission?.paid_ov_percent === parseInt(paidOv) ? "text-gray-400" : "text-secondary"}`}>
                {standardCommission?.paid_ov_percent} {standardCommission?.paid_ov_percent || standardCommission?.paid_ov_percent == 0 ? "%" : ""}
              </div>
            }
          >
            <TextField
              name={`${name}.quote.paid_ov_percent`}
              type="number"
              step="any"
              min="0"
              style={{ textAlign: "right" }}
              inputRef={register(messages.required)}
              onWheel={(event) => event.target.blur()}
              onBlur={onBlurOvPaid}
              error={errorNames?.quote?.paid_ov_percent?.message}
            />
          </InlineForm>

          <InlineForm 
            label="Printing จ่าย (%)"
            className="text-sm text-gray-600"
            require
            prefix={
              <div className={`w-12 h-8 pr-1 flex flex-wrap justify-end content-center ${standardCommission?.paid_printing_percent === parseInt(paidPrinting) ? "text-gray-400" : "text-secondary"}`}>
                {standardCommission?.paid_printing_percent} {standardCommission?.paid_printing_percent || standardCommission?.paid_printing_percent == 0 ? "%" : ""}
              </div>
            }
          >
            <TextField
              name={`${name}.quote.paid_printing_percent`}
              type="number"
              step="any"
              min="0"
              style={{ textAlign: "right" }}
              inputRef={register(messages.required)}
              onWheel={(event) => event.target.blur()}
              onBlur={onBlurPrintingPaid}
              error={errorNames?.quote?.paid_printing_percent?.message}
            />
          </InlineForm>
          
          <div className="row grid grid-cols-12 px-2">
            <div className="col-start-10 col-span-3 flex justify-end mr-2">
              <button
                className={`p-2 rounded shadow ${standardCommission ? "text-white bg-secondary hover:bg-pink-700 cursor-pointer" : "text-gray-800 bg-gray-200 cursor-not-allowed"} hover:shadow-md ease-out transition duration-150`}
                type="button"
                onClick={applyStandardCommission}
                disabled={!standardCommission}
              >
                {standardCommission ? "ใช้ค่าคอมมิชชั่นจากระบบ" : "ไม่พบค่าคอมมิชชั่นในระบบ"}
              </button>
            </div>
          </div>

          <div className="text-xs text-red-600">
            * ค่าคอมมิชชั่นเริ่มต้นเปลี่ยนแปลงตามข้อมูล: ตัวแทน, ประเภทประกัน, บริษัทประกัน,
            ประเภทความคุ้มครอง, ประเภทการซ่อม และประเภทรถยนต์
          </div>

          {!isAddon && (
            <>
              <hr className="mt-4" />
              <div className="flex items-center w-full -mt-4">
                <p className="text-lg flex-1 font-semibold">เบี้ยประกันที่ต้องชำระทั้งสิ้น</p>
                <p className="flex-1 text-xl font-semibold text-right">
                  {formatNumberWithCurrency(totalAmount)}
                </p>
              </div>
              <hr />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PremiumPanel;
