// WORKAROUND: For https://github.com/parcel-bundler/parcel/issues/1762
// import "babel-plugin-polyfill-regenerator";
import "regenerator-runtime/runtime";
import React from "react";
import { createRoot } from "react-dom/client";

// import HelloGettMoreStaff from './components/hello/HelloGettMoreStaff.jsx';
import App from "./App";
import appConfigJSON from "./app-config.json";
import AppConfig from "./config/AppConfig";
import { AppContextProvider } from "./context/App";
import "./i18n";

import "./styles/app.scss";
// HACK: Workaround for Parcel bundler Error: Cannot find module 'assets/...' with some asset files
import imageNotAvailable from "~assets/image-not-available.png";
import paymentsKbankLogo from "~assets/payments/kbank-logo.png";

const assets: Record<string, any> = {
  imageNotAvailable,
  payments: {
    kbankLogo: paymentsKbankLogo,
  },
};

if (module.hot) {
  module.hot.accept();
}

//const kbankTempBtn = `
//  <div id="kbankBtnTemp" class="kbank-buttons-temp hidden">
//    <form id="kbankFormBtn" method="POST" action=""></form>
//  </div>
//`
//document.querySelector('body').insertAdjacentHTML('afterend', kbankTempBtn)

const container = document.getElementById("app");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <AppContextProvider baseConfig={appConfigJSON} assets={assets}>
      <App />
    </AppContextProvider>
  </React.StrictMode>
);
