import React, { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { FormGroup, TextField, SelectField, Switcher } from "~/src/components/form";
import { mtlSalesBranchOptions } from "~/src/helpers/options";

import { messages } from "~/src/helpers/messages";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  agentInfoSelector,
  handleChangeAgentInfo,
  handleChangeHasAgentCard,
} from "./registerSlice";

const MTLAgentInfo = () => {
  const methods = useFormContext();
  const agentInfo = useAppSelector(agentInfoSelector);
  const dispatch = useAppDispatch();
  return (
    <div>
      <div className="flex items-center justify-between w-full col-span-1 col-start-1 text-xl bg-custom-gray-sm mb-3">
        <span className="p-2">คุณเป็นตัวแทนเมืองไทยประกันชีวิต</span>
        <div className="m-3 justify-self-end">
          <Switcher
            id="has_motor_license"
            name="has_motor_license"
            inputRef={methods.register}
            error={methods.errors.has_motor_license?.message}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              dispatch(handleChangeHasAgentCard(event.target.value === "true"))
            }
            active={agentInfo.has_agent_card}
            labels={[
              { label: "มี", value: true },
              { label: "ไม่มี", value: false },
            ]}
          />
        </div>
      </div>
      {agentInfo.has_agent_card && (
        <div className="px-5 w-full">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 items-center justify-between w-full col-span-2 col-start-1 text-xl">
            <div className="col-span-1">
              <FormGroup label="รหัสตัวแทนประกันชีวิต" htmlForLabel="mtl_agent_code">
                <TextField
                  id="mtl_agent_code"
                  name="mtl_agent_code"
                  value={agentInfo.mtl_agent_code}
                  inputRef={methods.register(messages.mtl_agent_code)}
                  error={methods.errors.mtl_agent_code?.message}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    dispatch(handleChangeAgentInfo("mtl_agent_code", e.target.value))
                  }
                />
              </FormGroup>
            </div>

            <div className="col-span-1">
              <FormGroup label="ชื่อฝ่ายขาย" htmlForLabel="mtl_sales_branch">
                <SelectField
                  id="mtl_sales_branch"
                  name="mtl_sales_branch"
                  value={agentInfo.mtl_sales_branch}
                  inputRef={methods.register}
                  error={methods.errors.mtl_sales_branch?.message}
                  require
                  options={mtlSalesBranchOptions}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    dispatch(handleChangeAgentInfo("mtl_sales_branch", e.target.value))
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MTLAgentInfo;
