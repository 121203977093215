import React from "react";
import axios from "../../../helpers/configAxios";
import Modal from "./components/Modal";
import Create from "./components/Create";
import Edit from "./components/Edit";
import { format } from "date-fns";
import { parseDateTH } from "../../../helpers/dateTime";
import Badge from "./components/Badge";

interface Data {
  id: number;
  title: string;
  place: string;
  from_date: string;
  to_date: string;
  start_time: string;
  end_time: string;
  status: string;
  people: string;
  link: string;
  category: string;
  sub_category: string;
  remark: string;
  description: string;
}

const Training: React.FC = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState<boolean>(
    false
  );
  const [isEditModalOpen, setIsEditModalOpen] = React.useState<boolean>(false);
  const [editId, setEditId] = React.useState<number | null>(null);
  const [data, setData] = React.useState<Data[] | null>(null);

  React.useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`/api/training_course`);
        setData(res.data.content);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleEditModal = (id: number): void => {
    setIsEditModalOpen(true);
    setEditId(id);
  };

  const handleDelete = async (id: number): Promise<void> => {
    if (window.confirm("ยืนยันลบข้อมูล")) {
      try {
        const res = await axios.delete(`api/training_course/${id}`);
        if (res.data.success) {
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="p-5">
      <div className="flex items-center justify-between">
        <h1>คอร์สอบรม</h1>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="px-3 py-2 mx-1 text-white bg-pink-500 border border-pink-500 select-none rounded-md transition duration-500 ease hover:bg-pink-600 focus:outline-none focus:shadow-outline"
        >
          เพิ่ม
        </button>
      </div>
      <div className="mt-4">
        <table className="w-full table-auto">
          <thead>
            <tr>
              <th style={{ width: 40 }} className="p-3 text-xs text-center">
                #
              </th>
              <th className="p-3 text-xs">ชื่อการอบรม</th>
              <th className="p-3 text-xs" style={{ width: 200 }}>
                วันที่/เวลา
              </th>
              <th className="p-3 text-xs">สถานที่</th>
              <th className="p-3 text-xs">จำนวนผู้เข้าร่วม (คน)</th>
              <th className="p-3 text-xs">สถานะ</th>
              <th style={{ width: 100 }} className="p-3 text-xs"></th>
            </tr>
          </thead>
          <tbody>
            {data && data.length === 0 && (
              <tr>
                <td colSpan={7} className="p-3 text-center bg-white">
                  ไม่พบข้อมูล
                </td>
              </tr>
            )}
            {data &&
              data.map((o, i) => (
                <tr key={i}>
                  <td className="px-2 py-1 text-xs text-right bg-white border-b-2 border-r">
                    {i + 1}
                  </td>
                  <td className="px-2 py-1 text-xs text-left bg-white border-b-2 border-r">
                    <a
                      href={o.link}
                      target="_blank"
                      rel=""
                      className="underline hover:text-pink-600"
                    >
                      {o.title}
                    </a>
                  </td>
                  <td className="px-2 py-1 text-xs text-center bg-white border-b-2 border-r">
                    <span className="flex items-center justify-center">
                      <span>
                        {format(new Date(o.from_date), "dd/MM/yyyy")} -
                        {format(new Date(o.to_date), "dd/MM/yyyy")}
                        <br /> {format(new Date(o.from_date), "HH:mm")} -
                        {format(new Date(o.to_date), "HH:mm")} น.
                      </span>
                    </span>
                  </td>
                  <td className="px-2 py-1 text-xs text-left bg-white border-b-2 border-r">
                    <span className="flex items-center">{o.place}</span>
                  </td>
                  <td className="px-2 py-1 text-xs text-center bg-white border-b-2 border-r">
                    <strong>{o.people}</strong>
                  </td>
                  <td className="px-2 py-1 text-xs text-center bg-white border-b-2 border-r">
                    <Badge status={o.status} />
                  </td>
                  <td className="px-2 py-1 text-right bg-white border-b-2">
                    <button
                      type="button"
                      onClick={() => handleEditModal(o.id)}
                      className="items-center w-8 h-8 p-1 mx-1 text-gray-800 bg-gray-100 border border-gray-200 select-none inlie-flex rounded-md transition duration-500 ease hover:bg-gray-300 focus:outline-none"
                    >
                      <span className="text-xl material-icons">create</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDelete(o.id)}
                      className="inline-flex items-center w-8 h-8 p-1 mx-1 text-white bg-red-400 border border-red-400 select-none rounded-md ease duration-500 hover:bg-red-500 focus:outline-none focus:shadow-outline"
                    >
                      <span className="text-xl material-icons">delete</span>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {isCreateModalOpen && (
        <Modal>
          <Create onClose={() => setIsCreateModalOpen(false)} />
        </Modal>
      )}

      {isEditModalOpen && (
        <Modal>
          <>
            {editId && (
              <Edit
                id={editId}
                onClose={() => {
                  setIsEditModalOpen(false);
                  setEditId(null);
                }}
              />
            )}
          </>
        </Modal>
      )}
    </div>
  );
};

export default Training;
