import React from "react";
import classnames from "classnames";

interface Props {
  name: string;
  id: string;
  required?: boolean;
  value?: string;
  inputRef?: any | undefined;
  error?: any;
  onChange?: (evt: React.ChangeEvent<HTMLSelectElement>) => void;
  children: any;
  disabled?: boolean;
  className?: string;
}

const Select: React.FC<Props> = ({
  name,
  id,
  value,
  onChange,
  children,
  inputRef,
  error,
  disabled = false,
  className = "",
}) => {
  return (
    <>
      <select
        id={id}
        ref={inputRef}
        name={name}
        className={classnames(
          "w-full px-2 py-2 border border-gray-300 outline-none rounded-5 focus-within:border-secondary",
          error && "focus:border-red-500 border-red-500",
          className
        )}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {children}
      </select>
      {error && <p className="text-red-500">{error}</p>}
    </>
  );
};

export default Select;
