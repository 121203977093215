import React from "react";
import Header from "../../components/layout/public/Header";
import Hero from "../../components/agent/homepage/Hero";
import Dashboard from "../../components/agent/homepage/Dashboard";
import Services from "../../components/agent/homepage/Services";
import Experience from "../../components/agent/homepage/Experience";
import TopAgent from "../../components/agent/homepage/TopAgent";
import Footer from "../../components/layout/public/Footer";

const HeroHome = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Dashboard />
      <Services />
      <Experience />
      <TopAgent />
      <Footer />
    </div>
  )
}

export default HeroHome
