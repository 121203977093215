import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { useRecoilState, useRecoilValue } from "recoil";
import QueryString from "query-string";
import BasicInsureInfo from "./BasicInsureInfo";
import InsurancePlanSummary3 from "~/src/components/insurancePlanSummary/insurancePlanSummary3";
import ErrorFallback from "~/src/components/Forms/ErrorFallback";
import useAxios from "~src/hooks/useAxios";
import { OrderInfoCheckout } from "~src/types/order";
import Loading from "~/src/components/loading";
import { formatNumberWithCurrency } from "~src/helpers/formatNumber";
import {
  orderInfoAtom,
  quotesInfoAtom,
  paymentsSummaryInfoAtom,
  travelQuotationInfoAtom,
} from "~/src/states/orderCheckout";
import TravelProduct from "~src/models/TravelProduct";
import { reformatDateEN } from "~src/helpers/date";
import { travelStep } from "~src/helpers/options";
import Stepper from "~src/components/stepper/Stepper";
import axios from "~src/helpers/configAxios";
import CoverageModal from "~src/features/travel/CoverageModal";
import CommonModal from "~src/components/modal/common";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  closeCoverageModal,
  coverageModalState,
  fetchProductByIdAction,
  openCoverageModal,
  setTravelers,
  travelProductState,
} from "~src/features/travel/redux";
import Preloading from "~src/components/loading/Preloading";
import { useAuthenticate } from "~src/helpers/authen";

const InfoStep: React.FC = (): JSX.Element => {
  const params = useParams<{ order_id: string }>();
  const orderId = params.order_id;

  const history = useHistory();
  const { showPaidCommission } = useAuthenticate();
  const { search } = window.location;
  const [querires] = useState(QueryString.parse(search));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [orderInfo, setOrderInfo] = useRecoilState(orderInfoAtom);
  const quotesInfo = useRecoilValue(quotesInfoAtom);
  const quotationInfo = useRecoilValue(travelQuotationInfoAtom);
  const { paymentSummary, lineItemAs } = useRecoilValue(paymentsSummaryInfoAtom);

  const coverageModal = useAppSelector(coverageModalState);
  const product = useAppSelector(travelProductState);
  const dispatch = useAppDispatch();

  const criteriaSummaryInfo = () => {
    const {
      criteria: { from_date, to_date },
      short_desc_criteria,
      additional_information: { destination_name },
    } = quotationInfo;

    return {
      ประเทศปลายทาง: destination_name,
      ผู้เดินทาง: `${short_desc_criteria}`,
      วันที่: `${reformatDateEN(from_date)} - ${reformatDateEN(to_date)}`,
    };
  };

  const priceSummaryInfo = () => {
    const { premium_gross, premium_stamp, premium_vat, selling_price, discount } = lineItemAs[0];
    let info = {
      "เบี้ยประกันภัยสุทธิ": formatNumberWithCurrency(premium_gross),
      "อากรแสตมป์": formatNumberWithCurrency(premium_stamp),
      "ภาษีมูลค่าเพิ่ม": formatNumberWithCurrency(premium_vat),
      "เบี้ยประกันภัยรวมทั้งสิ้น": formatNumberWithCurrency(selling_price),
      "ส่วนลดลูกค้า": formatNumberWithCurrency(discount),
    };
    if (!showPaidCommission) delete info["ส่วนลดลูกค้า"];

    return info
  };

  const onSubmit = (data) => {
    const url = `/api/orders/${orderId}`;
    setLoading(true);

    axios
      .patch(url, data)
      .then(() => {
        setLoading(false);
        window.location.href = `/public/travels/checkout/${orderId}/summary?token=${querires.token}`;
      })
      .catch((error) => {
        console.error(`problem!!!! ${error}`);
      });
  };

  const { status, data } = useAxios<OrderInfoCheckout>(
    `/api/orders/${orderId}/info?token=${querires.token}`
  );

  const currentStepCheckout = () => {
    const temp = [...travelStep];
    temp[0].active = true;
    return temp;
  };

  useEffect(() => {
    if (product.status === "succeeded") {
      dispatch(openCoverageModal(product.data));
    }
  }, [product]);

  const handlefetchProduct = () => {
    const traveller = quotationInfo.criteria.traveller;

    dispatch(
      fetchProductByIdAction({ id: quotesInfo[0].product_id, token: querires.token.toString() })
    );
    dispatch(setTravelers(traveller));
  };

  useEffect(() => {
    if (status === "loaded") setOrderInfo(data);
  }, [status]);

  if (status !== "loaded" || !orderInfo) {
    return <Loading />;
  }

  const handleCloseCoverageModal = () => {
    dispatch(closeCoverageModal());
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {product.status === "loading" && <Preloading />}
      <CommonModal open={coverageModal.open} onClose={handleCloseCoverageModal}>
        <CoverageModal />
      </CommonModal>

      <div className="motor-info-page">
        <div className="row">
          <div className="col-12">
            <h1>
              <i className="material-icons">lock </i> ยืนยันคำสั่งซื้อ{" "}
            </h1>
            <Stepper steppers={currentStepCheckout()} />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-8">
            <BasicInsureInfo onSubmit={onSubmit} />
          </div>
          <div className="col-md-4">
            <InsurancePlanSummary3
              productId={quotesInfo[0].product_id}
              insurerImg={quotesInfo[0].insurer_icon}
              insurerName={quotesInfo[0].insurer_name}
              productName={quotesInfo[0].product_name}
              criteriaSummaryInfo={criteriaSummaryInfo()}
              priceSummaryInfo={priceSummaryInfo()}
              totalSummary={formatNumberWithCurrency(paymentSummary.payment_total_amount)}
              onCallback={handlefetchProduct}
            />
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </ErrorBoundary>
  );
};

export default InfoStep;
