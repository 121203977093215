import React, { useState, useContext } from "react";

import filterContext from "./store.js";

import Select from "../../../../components/filter/Select";
import yaml from "../../../../data.yaml";

const optionsLists = [
  {
    label: "ราคา ต่ำไปสูง",
    value: "selling_price_asc",
    isChecked: true,
  },
  {
    label: "ราคา สูงไปต่ำ",
    value: "selling_price_desc",
    isChecked: false,
  },
  {
    label: "ทุนประกัน ต่ำไปสูง",
    value: "sum_insured_asc",
    isChecked: false,
  },
  {
    label: "ทุนประกัน สูงไปต่ำ",
    value: "sum_insured_desc",
    isChecked: false,
  },
];

const SortFilter = ({ fnCallbackFilterValues }) => {
  const [options, setOptions] = useState(optionsLists);
  const [state, dispatch] = useContext(filterContext);
  const handleChange = (value) => {
    const opts = [...options];
    let filterSort = {};

    opts.filter((o) => {
      o.isChecked = o.value === value && true;
      let values = value.split("_");

      let oKey = `sortby_${values[0]}_${values[1]}`;
      let oValue = value;
      if (o.isChecked) filterSort[oKey] = oValue;
    });

    setOptions(opts);
    dispatch({
      type: "UPDATE",
      payload: {
        ...state,
        filterSort,
      },
    });

    fnCallbackFilterValues("sort", filterSort);
  };

  const renderLabel = (stateValues) => {
    return yaml.sorts[Object.keys(stateValues)[0]][
      Object.values(stateValues)[0]
    ];
  };

  return (
    <div>
      <Select
        type="option"
        label={renderLabel(state.filterSort)}
        options={options}
        onChangeValue={handleChange}
      />
    </div>
  );
};

export default SortFilter;
