module.exports = [
  {
    "sub_district": "ดุสิต",
    "district": "ดุสิต",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10300,
    "sub_district_code": 100201,
    "district_code": 1002,
    "province_code": 10
  },
  {
    "sub_district": "วชิรพยาบาล",
    "district": "ดุสิต",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10300,
    "sub_district_code": 100202,
    "district_code": 1002,
    "province_code": 10
  },
  {
    "sub_district": "สวนจิตรลดา",
    "district": "ดุสิต",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10300,
    "sub_district_code": 100203,
    "district_code": 1002,
    "province_code": 10
  },
  {
    "sub_district": "สี่แยกมหานาค",
    "district": "ดุสิต",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10300,
    "sub_district_code": 100204,
    "district_code": 1002,
    "province_code": 10
  },
  {
    "sub_district": "ถนนนครไชยศรี",
    "district": "ดุสิต",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10300,
    "sub_district_code": 100206,
    "district_code": 1002,
    "province_code": 10
  },
  {
    "sub_district": "สมเด็จเจ้าพระยา",
    "district": "คลองสาน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101801,
    "district_code": 1018,
    "province_code": 10
  },
  {
    "sub_district": "คลองสาน",
    "district": "คลองสาน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101802,
    "district_code": 1018,
    "province_code": 10
  },
  {
    "sub_district": "บางลำภูล่าง",
    "district": "คลองสาน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101803,
    "district_code": 1018,
    "province_code": 10
  },
  {
    "sub_district": "คลองต้นไทร",
    "district": "คลองสาน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101804,
    "district_code": 1018,
    "province_code": 10
  },
  {
    "sub_district": "วัดกัลยาณ์",
    "district": "ธนบุรี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101501,
    "district_code": 1015,
    "province_code": 10
  },
  {
    "sub_district": "หิรัญรูจี",
    "district": "ธนบุรี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101502,
    "district_code": 1015,
    "province_code": 10
  },
  {
    "sub_district": "บางยี่เรือ",
    "district": "ธนบุรี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101503,
    "district_code": 1015,
    "province_code": 10
  },
  {
    "sub_district": "บุคคโล",
    "district": "ธนบุรี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101504,
    "district_code": 1015,
    "province_code": 10
  },
  {
    "sub_district": "ตลาดพลู",
    "district": "ธนบุรี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101505,
    "district_code": 1015,
    "province_code": 10
  },
  {
    "sub_district": "สำเหร่",
    "district": "ธนบุรี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101507,
    "district_code": 1015,
    "province_code": 10
  },
  {
    "sub_district": "ดาวคะนอง",
    "district": "ธนบุรี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101506,
    "district_code": 1015,
    "province_code": 10
  },
  {
    "sub_district": "วัดอรุณ",
    "district": "บางกอกใหญ่",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101601,
    "district_code": 1016,
    "province_code": 10
  },
  {
    "sub_district": "วัดท่าพระ",
    "district": "บางกอกใหญ่",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10600,
    "sub_district_code": 101602,
    "district_code": 1016,
    "province_code": 10
  },
  {
    "sub_district": "มหาพฤฒาราม",
    "district": "บางรัก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10500,
    "sub_district_code": 100401,
    "district_code": 1004,
    "province_code": 10
  },
  {
    "sub_district": "สีลม",
    "district": "บางรัก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10500,
    "sub_district_code": 100402,
    "district_code": 1004,
    "province_code": 10
  },
  {
    "sub_district": "สุริยวงศ์",
    "district": "บางรัก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10500,
    "sub_district_code": 100403,
    "district_code": 1004,
    "province_code": 10
  },
  {
    "sub_district": "บางรัก",
    "district": "บางรัก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10500,
    "sub_district_code": 100404,
    "district_code": 1004,
    "province_code": 10
  },
  {
    "sub_district": "สี่พระยา",
    "district": "บางรัก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10500,
    "sub_district_code": 100405,
    "district_code": 1004,
    "province_code": 10
  },
  {
    "sub_district": "รองเมือง",
    "district": "ปทุมวัน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10330,
    "sub_district_code": 100701,
    "district_code": 1007,
    "province_code": 10
  },
  {
    "sub_district": "วังใหม่",
    "district": "ปทุมวัน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10330,
    "sub_district_code": 100702,
    "district_code": 1007,
    "province_code": 10
  },
  {
    "sub_district": "ปทุมวัน",
    "district": "ปทุมวัน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10330,
    "sub_district_code": 100703,
    "district_code": 1007,
    "province_code": 10
  },
  {
    "sub_district": "ลุมพินี",
    "district": "ปทุมวัน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10330,
    "sub_district_code": 100704,
    "district_code": 1007,
    "province_code": 10
  },
  {
    "sub_district": "ป้อมปราบ",
    "district": "ป้อมปราบศัตรูพ่าย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10100,
    "sub_district_code": 100801,
    "district_code": 1008,
    "province_code": 10
  },
  {
    "sub_district": "วัดเทพศิรินทร์",
    "district": "ป้อมปราบศัตรูพ่าย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10100,
    "sub_district_code": 100802,
    "district_code": 1008,
    "province_code": 10
  },
  {
    "sub_district": "คลองมหานาค",
    "district": "ป้อมปราบศัตรูพ่าย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10100,
    "sub_district_code": 100803,
    "district_code": 1008,
    "province_code": 10
  },
  {
    "sub_district": "บ้านบาตร",
    "district": "ป้อมปราบศัตรูพ่าย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10100,
    "sub_district_code": 100804,
    "district_code": 1008,
    "province_code": 10
  },
  {
    "sub_district": "วัดโสมนัส",
    "district": "ป้อมปราบศัตรูพ่าย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10100,
    "sub_district_code": 100805,
    "district_code": 1008,
    "province_code": 10
  },
  {
    "sub_district": "สามเสนใน",
    "district": "พญาไท",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10400,
    "sub_district_code": 101401,
    "district_code": 1014,
    "province_code": 10
  },
  {
    "sub_district": "พญาไท",
    "district": "พญาไท",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10400,
    "sub_district_code": 101406,
    "district_code": 1014,
    "province_code": 10
  },
  {
    "sub_district": "พระบรมมหาราชวัง",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100101,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "วังบูรพาภิรมย์",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100102,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "วัดราชบพิธ",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100103,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "สำราญราษฎร์",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100104,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "ศาลเจ้าพ่อเสือ",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100105,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "เสาชิงช้า",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100106,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "บวรนิเวศ",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100107,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "ตลาดยอด",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100108,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "ชนะสงคราม",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100109,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "บ้านพานถม",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100110,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "บางขุนพรหม",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100111,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "วัดสามพระยา",
    "district": "พระนคร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10200,
    "sub_district_code": 100112,
    "district_code": 1001,
    "province_code": 10
  },
  {
    "sub_district": "ช่องนนทรี",
    "district": "ยานนาวา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10120,
    "sub_district_code": 101203,
    "district_code": 1012,
    "province_code": 10
  },
  {
    "sub_district": "บางโพงพาง",
    "district": "ยานนาวา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10120,
    "sub_district_code": 101204,
    "district_code": 1012,
    "province_code": 10
  },
  {
    "sub_district": "จักรวรรดิ",
    "district": "สัมพันธวงศ์",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10100,
    "sub_district_code": 101301,
    "district_code": 1013,
    "province_code": 10
  },
  {
    "sub_district": "สัมพันธวงศ์",
    "district": "สัมพันธวงศ์",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10100,
    "sub_district_code": 101302,
    "district_code": 1013,
    "province_code": 10
  },
  {
    "sub_district": "ตลาดน้อย",
    "district": "สัมพันธวงศ์",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10100,
    "sub_district_code": 101303,
    "district_code": 1013,
    "province_code": 10
  },
  {
    "sub_district": "คลองชักพระ",
    "district": "ตลิ่งชัน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10170,
    "sub_district_code": 101901,
    "district_code": 1019,
    "province_code": 10
  },
  {
    "sub_district": "ตลิ่งชัน",
    "district": "ตลิ่งชัน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10170,
    "sub_district_code": 101902,
    "district_code": 1019,
    "province_code": 10
  },
  {
    "sub_district": "ฉิมพลี",
    "district": "ตลิ่งชัน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10170,
    "sub_district_code": 101903,
    "district_code": 1019,
    "province_code": 10
  },
  {
    "sub_district": "บางพรม",
    "district": "ตลิ่งชัน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10170,
    "sub_district_code": 101904,
    "district_code": 1019,
    "province_code": 10
  },
  {
    "sub_district": "บางระมาด",
    "district": "ตลิ่งชัน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10170,
    "sub_district_code": 101905,
    "district_code": 1019,
    "province_code": 10
  },
  {
    "sub_district": "บางเชือกหนัง",
    "district": "ตลิ่งชัน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10170,
    "sub_district_code": 101907,
    "district_code": 1019,
    "province_code": 10
  },
  {
    "sub_district": "ศิริราช",
    "district": "บางกอกน้อย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10700,
    "sub_district_code": 102004,
    "district_code": 1020,
    "province_code": 10
  },
  {
    "sub_district": "บ้านช่างหล่อ",
    "district": "บางกอกน้อย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10700,
    "sub_district_code": 102005,
    "district_code": 1020,
    "province_code": 10
  },
  {
    "sub_district": "บางขุนนนท์",
    "district": "บางกอกน้อย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10700,
    "sub_district_code": 102006,
    "district_code": 1020,
    "province_code": 10
  },
  {
    "sub_district": "บางขุนศรี",
    "district": "บางกอกน้อย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10700,
    "sub_district_code": 102007,
    "district_code": 1020,
    "province_code": 10
  },
  {
    "sub_district": "อรุณอมรินทร์",
    "district": "บางกอกน้อย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10700,
    "sub_district_code": 102009,
    "district_code": 1020,
    "province_code": 10
  },
  {
    "sub_district": "คลองจั่น",
    "district": "บางกะปิ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10240,
    "sub_district_code": 100698,
    "district_code": 1006,
    "province_code": 10
  },
  {
    "sub_district": "หัวหมาก",
    "district": "บางกะปิ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10240,
    "sub_district_code": 100608,
    "district_code": 1006,
    "province_code": 10
  },
  {
    "sub_district": "คลองกุ่ม",
    "district": "บางกะปิ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10240,
    "sub_district_code": 100601,
    "district_code": 1006,
    "province_code": 10
  },
  {
    "sub_district": "บางขุนเทียน",
    "district": "บางขุนเทียน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 102198,
    "district_code": 1021,
    "province_code": 10
  },
  {
    "sub_district": "แสมดำ",
    "district": "บางขุนเทียน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 102107,
    "district_code": 1021,
    "province_code": 10
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "บางขุนเทียน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 102105,
    "district_code": 1021,
    "province_code": 10
  },
  {
    "sub_district": "อนุสาวรีย์",
    "district": "บางเขน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10220,
    "sub_district_code": 100502,
    "district_code": 1005,
    "province_code": 10
  },
  {
    "sub_district": "ท่าแร้ง",
    "district": "บางเขน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10220,
    "sub_district_code": 100508,
    "district_code": 1005,
    "province_code": 10
  },
  {
    "sub_district": "บางจาก",
    "district": "พระโขนง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10260,
    "sub_district_code": 100905,
    "district_code": 1009,
    "province_code": 10
  },
  {
    "sub_district": "พระโขนงใต้",
    "district": "พระโขนง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10260,
    "sub_district_code": 100910,
    "district_code": 1009,
    "province_code": 10
  },
  {
    "sub_district": "บางหว้า",
    "district": "ภาษีเจริญ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 102201,
    "district_code": 1022,
    "province_code": 10
  },
  {
    "sub_district": "บางด้วน",
    "district": "ภาษีเจริญ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 102202,
    "district_code": 1022,
    "province_code": 10
  },
  {
    "sub_district": "บางจาก",
    "district": "ภาษีเจริญ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 102206,
    "district_code": 1022,
    "province_code": 10
  },
  {
    "sub_district": "บางแวก",
    "district": "ภาษีเจริญ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 102207,
    "district_code": 1022,
    "province_code": 10
  },
  {
    "sub_district": "คลองขวาง",
    "district": "ภาษีเจริญ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 102208,
    "district_code": 1022,
    "province_code": 10
  },
  {
    "sub_district": "ปากคลองภาษีเจริญ",
    "district": "ภาษีเจริญ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 102209,
    "district_code": 1022,
    "province_code": 10
  },
  {
    "sub_district": "คูหาสวรรค์",
    "district": "ภาษีเจริญ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 102210,
    "district_code": 1022,
    "province_code": 10
  },
  {
    "sub_district": "มีนบุรี",
    "district": "มีนบุรี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10510,
    "sub_district_code": 101001,
    "district_code": 1010,
    "province_code": 10
  },
  {
    "sub_district": "แสนแสบ",
    "district": "มีนบุรี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10510,
    "sub_district_code": 101002,
    "district_code": 1010,
    "province_code": 10
  },
  {
    "sub_district": "ราษฎร์บูรณะ",
    "district": "ราษฎร์บูรณะ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10140,
    "sub_district_code": 102401,
    "district_code": 1024,
    "province_code": 10
  },
  {
    "sub_district": "บางปะกอก",
    "district": "ราษฎร์บูรณะ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10140,
    "sub_district_code": 102402,
    "district_code": 1024,
    "province_code": 10
  },
  {
    "sub_district": "ลาดกระบัง",
    "district": "ลาดกระบัง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10520,
    "sub_district_code": 101101,
    "district_code": 1011,
    "province_code": 10
  },
  {
    "sub_district": "คลองสองต้นนุ่น",
    "district": "ลาดกระบัง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10520,
    "sub_district_code": 101102,
    "district_code": 1011,
    "province_code": 10
  },
  {
    "sub_district": "คลองสามประเวศ",
    "district": "ลาดกระบัง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10520,
    "sub_district_code": 101103,
    "district_code": 1011,
    "province_code": 10
  },
  {
    "sub_district": "ลำปลาทิว",
    "district": "ลาดกระบัง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10520,
    "sub_district_code": 101104,
    "district_code": 1011,
    "province_code": 10
  },
  {
    "sub_district": "ทับยาว",
    "district": "ลาดกระบัง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10520,
    "sub_district_code": 101105,
    "district_code": 1011,
    "province_code": 10
  },
  {
    "sub_district": "ขุมทอง",
    "district": "ลาดกระบัง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10520,
    "sub_district_code": 101106,
    "district_code": 1011,
    "province_code": 10
  },
  {
    "sub_district": "หนองแขม",
    "district": "หนองแขม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 102302,
    "district_code": 1023,
    "province_code": 10
  },
  {
    "sub_district": "หนองค้างพลู",
    "district": "หนองแขม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 102303,
    "district_code": 1023,
    "province_code": 10
  },
  {
    "sub_district": "กระทุ่มราย",
    "district": "หนองจอก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10530,
    "sub_district_code": 100301,
    "district_code": 1003,
    "province_code": 10
  },
  {
    "sub_district": "หนองจอก",
    "district": "หนองจอก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10530,
    "sub_district_code": 100302,
    "district_code": 1003,
    "province_code": 10
  },
  {
    "sub_district": "คลองสิบ",
    "district": "หนองจอก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10530,
    "sub_district_code": 100303,
    "district_code": 1003,
    "province_code": 10
  },
  {
    "sub_district": "คลองสิบสอง",
    "district": "หนองจอก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10530,
    "sub_district_code": 100304,
    "district_code": 1003,
    "province_code": 10
  },
  {
    "sub_district": "โคกแฝด",
    "district": "หนองจอก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10530,
    "sub_district_code": 100305,
    "district_code": 1003,
    "province_code": 10
  },
  {
    "sub_district": "คู้ฝั่งเหนือ",
    "district": "หนองจอก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10530,
    "sub_district_code": 100306,
    "district_code": 1003,
    "province_code": 10
  },
  {
    "sub_district": "ลำผักชี",
    "district": "หนองจอก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10530,
    "sub_district_code": 100307,
    "district_code": 1003,
    "province_code": 10
  },
  {
    "sub_district": "ลำต้อยติ่ง",
    "district": "หนองจอก",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10530,
    "sub_district_code": 100308,
    "district_code": 1003,
    "province_code": 10
  },
  {
    "sub_district": "ห้วยขวาง",
    "district": "ห้วยขวาง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10310,
    "sub_district_code": 101701,
    "district_code": 1017,
    "province_code": 10
  },
  {
    "sub_district": "บางกะปิ",
    "district": "ห้วยขวาง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10310,
    "sub_district_code": 101702,
    "district_code": 1017,
    "province_code": 10
  },
  {
    "sub_district": "สามเสนนอก",
    "district": "ห้วยขวาง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10310,
    "sub_district_code": 101704,
    "district_code": 1017,
    "province_code": 10
  },
  {
    "sub_district": "คลองเตย",
    "district": "คลองเตย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10110,
    "sub_district_code": 103301,
    "district_code": 1033,
    "province_code": 10
  },
  {
    "sub_district": "คลองตัน",
    "district": "คลองเตย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10110,
    "sub_district_code": 103302,
    "district_code": 1033,
    "province_code": 10
  },
  {
    "sub_district": "พระโขนง",
    "district": "คลองเตย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10260,
    "sub_district_code": 103303,
    "district_code": 1033,
    "province_code": 10
  },
  {
    "sub_district": "พระโขนง",
    "district": "คลองเตย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10110,
    "sub_district_code": 103303,
    "district_code": 1033,
    "province_code": 10
  },
  {
    "sub_district": "พระโขนง",
    "district": "คลองเตย",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10260,
    "sub_district_code": 103303,
    "district_code": 1033,
    "province_code": 10
  },
  {
    "sub_district": "ลาดยาว",
    "district": "จตุจักร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10900,
    "sub_district_code": 103001,
    "district_code": 1030,
    "province_code": 10
  },
  {
    "sub_district": "จตุจักร",
    "district": "จตุจักร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10900,
    "sub_district_code": 103005,
    "district_code": 1030,
    "province_code": 10
  },
  {
    "sub_district": "จอมพล",
    "district": "จตุจักร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10900,
    "sub_district_code": 103004,
    "district_code": 1030,
    "province_code": 10
  },
  {
    "sub_district": "จันทรเกษม",
    "district": "จตุจักร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10900,
    "sub_district_code": 103003,
    "district_code": 1030,
    "province_code": 10
  },
  {
    "sub_district": "เสนานิคม",
    "district": "จตุจักร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10900,
    "sub_district_code": 103002,
    "district_code": 1030,
    "province_code": 10
  },
  {
    "sub_district": "จอมทอง",
    "district": "จอมทอง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 103504,
    "district_code": 1035,
    "province_code": 10
  },
  {
    "sub_district": "บางขุนเทียน",
    "district": "จอมทอง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 103501,
    "district_code": 1035,
    "province_code": 10
  },
  {
    "sub_district": "บางค้อ",
    "district": "จอมทอง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 103502,
    "district_code": 1035,
    "province_code": 10
  },
  {
    "sub_district": "บางมด",
    "district": "จอมทอง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 103503,
    "district_code": 1035,
    "province_code": 10
  },
  {
    "sub_district": "ดอนเมือง",
    "district": "ดอนเมือง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10210,
    "sub_district_code": 103604,
    "district_code": 1036,
    "province_code": 10
  },
  {
    "sub_district": "สีกัน",
    "district": "ดอนเมือง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10210,
    "sub_district_code": 103602,
    "district_code": 1036,
    "province_code": 10
  },
  {
    "sub_district": "สนามบิน",
    "district": "ดอนเมือง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10210,
    "sub_district_code": 103605,
    "district_code": 1036,
    "province_code": 10
  },
  {
    "sub_district": "บางคอแหลม",
    "district": "บางคอแหลม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10120,
    "sub_district_code": 103101,
    "district_code": 1031,
    "province_code": 10
  },
  {
    "sub_district": "วัดพระยาไกร",
    "district": "บางคอแหลม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10120,
    "sub_district_code": 103102,
    "district_code": 1031,
    "province_code": 10
  },
  {
    "sub_district": "บางโคล่",
    "district": "บางคอแหลม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10120,
    "sub_district_code": 103103,
    "district_code": 1031,
    "province_code": 10
  },
  {
    "sub_district": "บางซื่อ",
    "district": "บางซื่อ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10800,
    "sub_district_code": 102901,
    "district_code": 1029,
    "province_code": 10
  },
  {
    "sub_district": "วงศ์สว่าง",
    "district": "บางซื่อ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10800,
    "sub_district_code": 102902,
    "district_code": 1029,
    "province_code": 10
  },
  {
    "sub_district": "บางพลัด",
    "district": "บางพลัด",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10700,
    "sub_district_code": 102501,
    "district_code": 1025,
    "province_code": 10
  },
  {
    "sub_district": "บางอ้อ",
    "district": "บางพลัด",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10700,
    "sub_district_code": 102502,
    "district_code": 1025,
    "province_code": 10
  },
  {
    "sub_district": "บางบำหรุ",
    "district": "บางพลัด",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10700,
    "sub_district_code": 102503,
    "district_code": 1025,
    "province_code": 10
  },
  {
    "sub_district": "บางยี่ขัน",
    "district": "บางพลัด",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10700,
    "sub_district_code": 102504,
    "district_code": 1025,
    "province_code": 10
  },
  {
    "sub_district": "คลองกุ่ม",
    "district": "บึงกุ่ม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10240,
    "sub_district_code": 102701,
    "district_code": 1027,
    "province_code": 10
  },
  {
    "sub_district": "สะพานสูง",
    "district": "บึงกุ่ม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10250,
    "sub_district_code": 102702,
    "district_code": 1027,
    "province_code": 10
  },
  {
    "sub_district": "นวลจันทร์",
    "district": "บึงกุ่ม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10230,
    "sub_district_code": 102705,
    "district_code": 1027,
    "province_code": 10
  },
  {
    "sub_district": "นวมินทร์",
    "district": "บึงกุ่ม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10240,
    "sub_district_code": 102704,
    "district_code": 1027,
    "province_code": 10
  },
  {
    "sub_district": "ประเวศ",
    "district": "ประเวศ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10250,
    "sub_district_code": 103201,
    "district_code": 1032,
    "province_code": 10
  },
  {
    "sub_district": "ดอกไม้",
    "district": "ประเวศ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10250,
    "sub_district_code": 103203,
    "district_code": 1032,
    "province_code": 10
  },
  {
    "sub_district": "หนองบอน",
    "district": "ประเวศ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10250,
    "sub_district_code": 103202,
    "district_code": 1032,
    "province_code": 10
  },
  {
    "sub_district": "มักกะสัน",
    "district": "ราชเทวี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10400,
    "sub_district_code": 103704,
    "district_code": 1037,
    "province_code": 10
  },
  {
    "sub_district": "ทุ่งพญาไท",
    "district": "ราชเทวี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10400,
    "sub_district_code": 103701,
    "district_code": 1037,
    "province_code": 10
  },
  {
    "sub_district": "ถนนพญาไท",
    "district": "ราชเทวี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10400,
    "sub_district_code": 103702,
    "district_code": 1037,
    "province_code": 10
  },
  {
    "sub_district": "ถนนเพชรบุรี",
    "district": "ราชเทวี",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10400,
    "sub_district_code": 103703,
    "district_code": 1037,
    "province_code": 10
  },
  {
    "sub_district": "ลาดพร้าว",
    "district": "ลาดพร้าว",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10230,
    "sub_district_code": 103801,
    "district_code": 1038,
    "province_code": 10
  },
  {
    "sub_district": "จรเข้บัว",
    "district": "ลาดพร้าว",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10230,
    "sub_district_code": 103802,
    "district_code": 1038,
    "province_code": 10
  },
  {
    "sub_district": "ทุ่งวัดดอน",
    "district": "สาทร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10120,
    "sub_district_code": 102801,
    "district_code": 1028,
    "province_code": 10
  },
  {
    "sub_district": "ยานนาวา",
    "district": "สาทร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10120,
    "sub_district_code": 102802,
    "district_code": 1028,
    "province_code": 10
  },
  {
    "sub_district": "ทุ่งมหาเมฆ",
    "district": "สาทร",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10120,
    "sub_district_code": 102803,
    "district_code": 1028,
    "province_code": 10
  },
  {
    "sub_district": "ดินแดง",
    "district": "ดินแดง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10400,
    "sub_district_code": 102601,
    "district_code": 1026,
    "province_code": 10
  },
  {
    "sub_district": "รัชดาภิเษก",
    "district": "ดินแดง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10400,
    "sub_district_code": 102602,
    "district_code": 1026,
    "province_code": 10
  },
  {
    "sub_district": "สวนหลวง",
    "district": "สวนหลวง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10250,
    "sub_district_code": 103401,
    "district_code": 1034,
    "province_code": 10
  },
  {
    "sub_district": "อ่อนนุช",
    "district": "สวนหลวง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10250,
    "sub_district_code": 103402,
    "district_code": 1034,
    "province_code": 10
  },
  {
    "sub_district": "พัฒนาการ",
    "district": "สวนหลวง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10250,
    "sub_district_code": 103403,
    "district_code": 1034,
    "province_code": 10
  },
  {
    "sub_district": "ทรายกองดินใต้",
    "district": "คลองสามวา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10510,
    "sub_district_code": 104605,
    "district_code": 1046,
    "province_code": 10
  },
  {
    "sub_district": "บางชัน",
    "district": "คลองสามวา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10510,
    "sub_district_code": 104603,
    "district_code": 1046,
    "province_code": 10
  },
  {
    "sub_district": "สามวาตะวันตก",
    "district": "คลองสามวา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10510,
    "sub_district_code": 104601,
    "district_code": 1046,
    "province_code": 10
  },
  {
    "sub_district": "ทรายกองดิน",
    "district": "คลองสามวา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10510,
    "sub_district_code": 104604,
    "district_code": 1046,
    "province_code": 10
  },
  {
    "sub_district": "สามวาตะวันออก",
    "district": "คลองสามวา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10510,
    "sub_district_code": 104602,
    "district_code": 1046,
    "province_code": 10
  },
  {
    "sub_district": "คันนายาว",
    "district": "คันนายาว",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10230,
    "sub_district_code": 104301,
    "district_code": 1043,
    "province_code": 10
  },
  {
    "sub_district": "รามอินทรา",
    "district": "คันนายาว",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10230,
    "sub_district_code": 104302,
    "district_code": 1043,
    "province_code": 10
  },
  {
    "sub_district": "ศาลาธรรมสพน์",
    "district": "ทวีวัฒนา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10170,
    "sub_district_code": 104802,
    "district_code": 1048,
    "province_code": 10
  },
  {
    "sub_district": "ทวีวัฒนา",
    "district": "ทวีวัฒนา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10170,
    "sub_district_code": 104801,
    "district_code": 1048,
    "province_code": 10
  },
  {
    "sub_district": "บางมด",
    "district": "ทุ่งครุ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10140,
    "sub_district_code": 104901,
    "district_code": 1049,
    "province_code": 10
  },
  {
    "sub_district": "ทุ่งครุ",
    "district": "ทุ่งครุ",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10140,
    "sub_district_code": 104902,
    "district_code": 1049,
    "province_code": 10
  },
  {
    "sub_district": "บางแคเหนือ",
    "district": "บางแค",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 104002,
    "district_code": 1040,
    "province_code": 10
  },
  {
    "sub_district": "บางแค",
    "district": "บางแค",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 104001,
    "district_code": 1040,
    "province_code": 10
  },
  {
    "sub_district": "บางไผ่",
    "district": "บางแค",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 104003,
    "district_code": 1040,
    "province_code": 10
  },
  {
    "sub_district": "หลักสอง",
    "district": "บางแค",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10160,
    "sub_district_code": 104004,
    "district_code": 1040,
    "province_code": 10
  },
  {
    "sub_district": "บางนาใต้",
    "district": "บางนา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10260,
    "sub_district_code": 104703,
    "district_code": 1047,
    "province_code": 10
  },
  {
    "sub_district": "บางนาเหนือ",
    "district": "บางนา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10260,
    "sub_district_code": 104702,
    "district_code": 1047,
    "province_code": 10
  },
  {
    "sub_district": "บางบอน",
    "district": "บางบอน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 105001,
    "district_code": 1050,
    "province_code": 10
  },
  {
    "sub_district": "คลองบางบอน",
    "district": "บางบอน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 105005,
    "district_code": 1050,
    "province_code": 10
  },
  {
    "sub_district": "บางบอนเหนือ",
    "district": "บางบอน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 105002,
    "district_code": 1050,
    "province_code": 10
  },
  {
    "sub_district": "บางบอนใต้",
    "district": "บางบอน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 105003,
    "district_code": 1050,
    "province_code": 10
  },
  {
    "sub_district": "คลองบางพราน",
    "district": "บางบอน",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10150,
    "sub_district_code": 105004,
    "district_code": 1050,
    "province_code": 10
  },
  {
    "sub_district": "วังทองหลาง",
    "district": "วังทองหลาง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10310,
    "sub_district_code": 104501,
    "district_code": 1045,
    "province_code": 10
  },
  {
    "sub_district": "สะพานสอง",
    "district": "วังทองหลาง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10310,
    "sub_district_code": 104502,
    "district_code": 1045,
    "province_code": 10
  },
  {
    "sub_district": "คลองเจ้าคุณสิงห์",
    "district": "วังทองหลาง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10310,
    "sub_district_code": 104503,
    "district_code": 1045,
    "province_code": 10
  },
  {
    "sub_district": "พลับพลา",
    "district": "วังทองหลาง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10310,
    "sub_district_code": 104504,
    "district_code": 1045,
    "province_code": 10
  },
  {
    "sub_district": "คลองตันเหนือ",
    "district": "วัฒนา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10110,
    "sub_district_code": 103902,
    "district_code": 1039,
    "province_code": 10
  },
  {
    "sub_district": "พระโขนงเหนือ",
    "district": "วัฒนา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10110,
    "sub_district_code": 103903,
    "district_code": 1039,
    "province_code": 10
  },
  {
    "sub_district": "พระโขนงเหนือ",
    "district": "วัฒนา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10260,
    "sub_district_code": 103903,
    "district_code": 1039,
    "province_code": 10
  },
  {
    "sub_district": "คลองเตยเหนือ",
    "district": "วัฒนา",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10110,
    "sub_district_code": 103901,
    "district_code": 1039,
    "province_code": 10
  },
  {
    "sub_district": "สะพานสูง",
    "district": "สะพานสูง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10240,
    "sub_district_code": 104401,
    "district_code": 1044,
    "province_code": 10
  },
  {
    "sub_district": "ราษฎร์พัฒนา",
    "district": "สะพานสูง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10240,
    "sub_district_code": 104402,
    "district_code": 1044,
    "province_code": 10
  },
  {
    "sub_district": "ทับช้าง",
    "district": "สะพานสูง",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10250,
    "sub_district_code": 104403,
    "district_code": 1044,
    "province_code": 10
  },
  {
    "sub_district": "สายไหม",
    "district": "สายไหม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10220,
    "sub_district_code": 104201,
    "district_code": 1042,
    "province_code": 10
  },
  {
    "sub_district": "คลองถนน",
    "district": "สายไหม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10220,
    "sub_district_code": 104203,
    "district_code": 1042,
    "province_code": 10
  },
  {
    "sub_district": "ออเงิน",
    "district": "สายไหม",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10220,
    "sub_district_code": 104202,
    "district_code": 1042,
    "province_code": 10
  },
  {
    "sub_district": "ทุ่งสองห้อง",
    "district": "หลักสี่",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10210,
    "sub_district_code": 104101,
    "district_code": 1041,
    "province_code": 10
  },
  {
    "sub_district": "ตลาดบางเขน",
    "district": "หลักสี่",
    "province": "กรุงเทพมหานคร",
    "zipcode": 10210,
    "sub_district_code": 104102,
    "district_code": 1041,
    "province_code": 10
  },
  {
    "sub_district": "ปากน้ำ",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81000,
    "sub_district_code": 810101,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "กระบี่ใหญ่",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81000,
    "sub_district_code": 810102,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "กระบี่น้อย",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81000,
    "sub_district_code": 810103,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "เกาะศรีบอยา",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810191,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "เขาคราม",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81000,
    "sub_district_code": 810105,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "เขาทอง",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81000,
    "sub_district_code": 810106,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "คลองขนาน",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810192,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "คลองเขม้า",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810193,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "โคกยาง",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810194,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "ตลิ่งชัน",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810195,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "ทับปริก",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81000,
    "sub_district_code": 810111,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "ปกาสัย",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810196,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "ห้วยยูง",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810197,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "เหนือคลอง",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810198,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "ไสไทย",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81000,
    "sub_district_code": 810115,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "อ่าวนาง",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81000,
    "sub_district_code": 810116,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "หนองทะเล",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81000,
    "sub_district_code": 810117,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "คลองประสงค์",
    "district": "เมืองกระบี่",
    "province": "กระบี่",
    "zipcode": 81000,
    "sub_district_code": 810118,
    "district_code": 8101,
    "province_code": 81
  },
  {
    "sub_district": "เกาะลันตาใหญ่",
    "district": "เกาะลันตา",
    "province": "กระบี่",
    "zipcode": 81150,
    "sub_district_code": 810301,
    "district_code": 8103,
    "province_code": 81
  },
  {
    "sub_district": "เกาะลันตาน้อย",
    "district": "เกาะลันตา",
    "province": "กระบี่",
    "zipcode": 81150,
    "sub_district_code": 810302,
    "district_code": 8103,
    "province_code": 81
  },
  {
    "sub_district": "เกาะกลาง",
    "district": "เกาะลันตา",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810303,
    "district_code": 8103,
    "province_code": 81
  },
  {
    "sub_district": "คลองยาง",
    "district": "เกาะลันตา",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810304,
    "district_code": 8103,
    "province_code": 81
  },
  {
    "sub_district": "ศาลาด่าน",
    "district": "เกาะลันตา",
    "province": "กระบี่",
    "zipcode": 81150,
    "sub_district_code": 810305,
    "district_code": 8103,
    "province_code": 81
  },
  {
    "sub_district": "เขาพนม",
    "district": "เขาพนม",
    "province": "กระบี่",
    "zipcode": 81140,
    "sub_district_code": 810201,
    "district_code": 8102,
    "province_code": 81
  },
  {
    "sub_district": "เขาดิน",
    "district": "เขาพนม",
    "province": "กระบี่",
    "zipcode": 81140,
    "sub_district_code": 810202,
    "district_code": 8102,
    "province_code": 81
  },
  {
    "sub_district": "สินปุน",
    "district": "เขาพนม",
    "province": "กระบี่",
    "zipcode": 80240,
    "sub_district_code": 810203,
    "district_code": 8102,
    "province_code": 81
  },
  {
    "sub_district": "พรุเตียว",
    "district": "เขาพนม",
    "province": "กระบี่",
    "zipcode": 81140,
    "sub_district_code": 810204,
    "district_code": 8102,
    "province_code": 81
  },
  {
    "sub_district": "หน้าเขา",
    "district": "เขาพนม",
    "province": "กระบี่",
    "zipcode": 81140,
    "sub_district_code": 810205,
    "district_code": 8102,
    "province_code": 81
  },
  {
    "sub_district": "โคกหาร",
    "district": "เขาพนม",
    "province": "กระบี่",
    "zipcode": 80240,
    "sub_district_code": 810206,
    "district_code": 8102,
    "province_code": 81
  },
  {
    "sub_district": "คลองท่อมใต้",
    "district": "คลองท่อม",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810401,
    "district_code": 8104,
    "province_code": 81
  },
  {
    "sub_district": "คลองท่อมเหนือ",
    "district": "คลองท่อม",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810402,
    "district_code": 8104,
    "province_code": 81
  },
  {
    "sub_district": "คลองพน",
    "district": "คลองท่อม",
    "province": "กระบี่",
    "zipcode": 81170,
    "sub_district_code": 810403,
    "district_code": 8104,
    "province_code": 81
  },
  {
    "sub_district": "ทรายขาว",
    "district": "คลองท่อม",
    "province": "กระบี่",
    "zipcode": 81170,
    "sub_district_code": 810404,
    "district_code": 8104,
    "province_code": 81
  },
  {
    "sub_district": "ห้วยน้ำขาว",
    "district": "คลองท่อม",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810405,
    "district_code": 8104,
    "province_code": 81
  },
  {
    "sub_district": "พรุดินนา",
    "district": "คลองท่อม",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810406,
    "district_code": 8104,
    "province_code": 81
  },
  {
    "sub_district": "เพหลา",
    "district": "คลองท่อม",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810407,
    "district_code": 8104,
    "province_code": 81
  },
  {
    "sub_district": "ดินอุดม",
    "district": "คลองท่อม",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810498,
    "district_code": 8104,
    "province_code": 81
  },
  {
    "sub_district": "อ่าวลึกใต้",
    "district": "อ่าวลึก",
    "province": "กระบี่",
    "zipcode": 81110,
    "sub_district_code": 810501,
    "district_code": 8105,
    "province_code": 81
  },
  {
    "sub_district": "แหลมสัก",
    "district": "อ่าวลึก",
    "province": "กระบี่",
    "zipcode": 81110,
    "sub_district_code": 810502,
    "district_code": 8105,
    "province_code": 81
  },
  {
    "sub_district": "นาเหนือ",
    "district": "อ่าวลึก",
    "province": "กระบี่",
    "zipcode": 81110,
    "sub_district_code": 810503,
    "district_code": 8105,
    "province_code": 81
  },
  {
    "sub_district": "คลองหิน",
    "district": "อ่าวลึก",
    "province": "กระบี่",
    "zipcode": 81110,
    "sub_district_code": 810504,
    "district_code": 8105,
    "province_code": 81
  },
  {
    "sub_district": "อ่าวลึกน้อย",
    "district": "อ่าวลึก",
    "province": "กระบี่",
    "zipcode": 81110,
    "sub_district_code": 810505,
    "district_code": 8105,
    "province_code": 81
  },
  {
    "sub_district": "อ่าวลึกเหนือ",
    "district": "อ่าวลึก",
    "province": "กระบี่",
    "zipcode": 81110,
    "sub_district_code": 810506,
    "district_code": 8105,
    "province_code": 81
  },
  {
    "sub_district": "เขาใหญ่",
    "district": "อ่าวลึก",
    "province": "กระบี่",
    "zipcode": 81110,
    "sub_district_code": 810507,
    "district_code": 8105,
    "province_code": 81
  },
  {
    "sub_district": "คลองยา",
    "district": "อ่าวลึก",
    "province": "กระบี่",
    "zipcode": 81110,
    "sub_district_code": 810508,
    "district_code": 8105,
    "province_code": 81
  },
  {
    "sub_district": "บ้านกลาง",
    "district": "อ่าวลึก",
    "province": "กระบี่",
    "zipcode": 81110,
    "sub_district_code": 810509,
    "district_code": 8105,
    "province_code": 81
  },
  {
    "sub_district": "ปลายพระยา",
    "district": "ปลายพระยา",
    "province": "กระบี่",
    "zipcode": 81160,
    "sub_district_code": 810601,
    "district_code": 8106,
    "province_code": 81
  },
  {
    "sub_district": "เขาเขน",
    "district": "ปลายพระยา",
    "province": "กระบี่",
    "zipcode": 81160,
    "sub_district_code": 810602,
    "district_code": 8106,
    "province_code": 81
  },
  {
    "sub_district": "เขาต่อ",
    "district": "ปลายพระยา",
    "province": "กระบี่",
    "zipcode": 81160,
    "sub_district_code": 810603,
    "district_code": 8106,
    "province_code": 81
  },
  {
    "sub_district": "คีรีวง",
    "district": "ปลายพระยา",
    "province": "กระบี่",
    "zipcode": 81160,
    "sub_district_code": 810604,
    "district_code": 8106,
    "province_code": 81
  },
  {
    "sub_district": "ลำทับ",
    "district": "ลำทับ",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810701,
    "district_code": 8107,
    "province_code": 81
  },
  {
    "sub_district": "ดินอุดม",
    "district": "ลำทับ",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810702,
    "district_code": 8107,
    "province_code": 81
  },
  {
    "sub_district": "ทุ่งไทรทอง",
    "district": "ลำทับ",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810703,
    "district_code": 8107,
    "province_code": 81
  },
  {
    "sub_district": "ดินแดง",
    "district": "ลำทับ",
    "province": "กระบี่",
    "zipcode": 81120,
    "sub_district_code": 810704,
    "district_code": 8107,
    "province_code": 81
  },
  {
    "sub_district": "เหนือคลอง",
    "district": "เหนือคลอง",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810801,
    "district_code": 8108,
    "province_code": 81
  },
  {
    "sub_district": "ปกาสัย",
    "district": "เหนือคลอง",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810807,
    "district_code": 8108,
    "province_code": 81
  },
  {
    "sub_district": "ห้วยยูง",
    "district": "เหนือคลอง",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810808,
    "district_code": 8108,
    "province_code": 81
  },
  {
    "sub_district": "คลองเขม้า",
    "district": "เหนือคลอง",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810804,
    "district_code": 8108,
    "province_code": 81
  },
  {
    "sub_district": "ตลิ่งชัน",
    "district": "เหนือคลอง",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810806,
    "district_code": 8108,
    "province_code": 81
  },
  {
    "sub_district": "โคกยาง",
    "district": "เหนือคลอง",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810805,
    "district_code": 8108,
    "province_code": 81
  },
  {
    "sub_district": "คลองขนาน",
    "district": "เหนือคลอง",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810803,
    "district_code": 8108,
    "province_code": 81
  },
  {
    "sub_district": "เกาะศรีบอยา",
    "district": "เหนือคลอง",
    "province": "กระบี่",
    "zipcode": 81130,
    "sub_district_code": 810802,
    "district_code": 8108,
    "province_code": 81
  },
  {
    "sub_district": "บ้านเหนือ",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710101,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "บ้านใต้",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710102,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "ปากแพรก",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710103,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "ท่ามะขาม",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710104,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "แก่งเสี้ยน",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710105,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "หนองบัว",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71190,
    "sub_district_code": 710106,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "ลาดหญ้า",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71190,
    "sub_district_code": 710107,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "วังด้ง",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71190,
    "sub_district_code": 710108,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "ช่องสะเดา",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71190,
    "sub_district_code": 710109,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "หนองหญ้า",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710110,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "เกาะสำโรง",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710111,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "ด่านมะขามเตี้ย",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71260,
    "sub_district_code": 710194,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "บ้านเก่า",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710113,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "จรเข้เผือก",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71260,
    "sub_district_code": 710195,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "กลอนโด",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71260,
    "sub_district_code": 710196,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "วังเย็น",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710116,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "ท่าล้อ",
    "district": "เมืองกาญจนบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710197,
    "district_code": 7101,
    "province_code": 71
  },
  {
    "sub_district": "ท่าขนุน",
    "district": "ทองผาภูมิ",
    "province": "กาญจนบุรี",
    "zipcode": 71180,
    "sub_district_code": 710701,
    "district_code": 7107,
    "province_code": 71
  },
  {
    "sub_district": "ปิล๊อก",
    "district": "ทองผาภูมิ",
    "province": "กาญจนบุรี",
    "zipcode": 71180,
    "sub_district_code": 710702,
    "district_code": 7107,
    "province_code": 71
  },
  {
    "sub_district": "หินดาด",
    "district": "ทองผาภูมิ",
    "province": "กาญจนบุรี",
    "zipcode": 71180,
    "sub_district_code": 710703,
    "district_code": 7107,
    "province_code": 71
  },
  {
    "sub_district": "ลิ่นถิ่น",
    "district": "ทองผาภูมิ",
    "province": "กาญจนบุรี",
    "zipcode": 71180,
    "sub_district_code": 710704,
    "district_code": 7107,
    "province_code": 71
  },
  {
    "sub_district": "ชะแล",
    "district": "ทองผาภูมิ",
    "province": "กาญจนบุรี",
    "zipcode": 71180,
    "sub_district_code": 710705,
    "district_code": 7107,
    "province_code": 71
  },
  {
    "sub_district": "ห้วยเขย่ง",
    "district": "ทองผาภูมิ",
    "province": "กาญจนบุรี",
    "zipcode": 71180,
    "sub_district_code": 710706,
    "district_code": 7107,
    "province_code": 71
  },
  {
    "sub_district": "สหกรณ์นิคม",
    "district": "ทองผาภูมิ",
    "province": "กาญจนบุรี",
    "zipcode": 71180,
    "sub_district_code": 710707,
    "district_code": 7107,
    "province_code": 71
  },
  {
    "sub_district": "ท่าม่วง",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710601,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "วังขนาย",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710602,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "วังศาลา",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710603,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "วังศาลา",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71130,
    "sub_district_code": 710603,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "ท่าล้อ",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71000,
    "sub_district_code": 710604,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "หนองขาว",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710605,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "ทุ่งทอง",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710606,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "เขาน้อย",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710607,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "ม่วงชุม",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710608,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710609,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "พังตรุ",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710610,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "ท่าตะคร้อ",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71130,
    "sub_district_code": 710611,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "รางสาลี่",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710612,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "หนองตากยา",
    "district": "ท่าม่วง",
    "province": "กาญจนบุรี",
    "zipcode": 71110,
    "sub_district_code": 710613,
    "district_code": 7106,
    "province_code": 71
  },
  {
    "sub_district": "พงตึก",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71120,
    "sub_district_code": 710501,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "ยางม่วง",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71120,
    "sub_district_code": 710502,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "ดอนชะเอม",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71130,
    "sub_district_code": 710503,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "ท่าไม้",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71120,
    "sub_district_code": 710504,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "ตะคร้ำเอน",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71130,
    "sub_district_code": 710505,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "ท่ามะกา",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71120,
    "sub_district_code": 710506,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "ท่าเรือ",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71130,
    "sub_district_code": 710507,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "โคกตะบอง",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71120,
    "sub_district_code": 710508,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "ดอนขมิ้น",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71120,
    "sub_district_code": 710509,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "อุโลกสี่หมื่น",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71130,
    "sub_district_code": 710510,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "เขาสามสิบหาบ",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71120,
    "sub_district_code": 710511,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "พระแท่น",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71130,
    "sub_district_code": 710512,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "หวายเหนียว",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71120,
    "sub_district_code": 710513,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "แสนตอ",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71130,
    "sub_district_code": 710514,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "สนามแย้",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 70190,
    "sub_district_code": 710515,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "ท่าเสา",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71120,
    "sub_district_code": 710516,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "หนองลาน",
    "district": "ท่ามะกา",
    "province": "กาญจนบุรี",
    "zipcode": 71130,
    "sub_district_code": 710517,
    "district_code": 7105,
    "province_code": 71
  },
  {
    "sub_district": "ลุ่มสุ่ม",
    "district": "ไทรโยค",
    "province": "กาญจนบุรี",
    "zipcode": 71150,
    "sub_district_code": 710201,
    "district_code": 7102,
    "province_code": 71
  },
  {
    "sub_district": "ท่าเสา",
    "district": "ไทรโยค",
    "province": "กาญจนบุรี",
    "zipcode": 71150,
    "sub_district_code": 710202,
    "district_code": 7102,
    "province_code": 71
  },
  {
    "sub_district": "สิงห์",
    "district": "ไทรโยค",
    "province": "กาญจนบุรี",
    "zipcode": 71150,
    "sub_district_code": 710203,
    "district_code": 7102,
    "province_code": 71
  },
  {
    "sub_district": "ไทรโยค",
    "district": "ไทรโยค",
    "province": "กาญจนบุรี",
    "zipcode": 71150,
    "sub_district_code": 710204,
    "district_code": 7102,
    "province_code": 71
  },
  {
    "sub_district": "วังกระแจะ",
    "district": "ไทรโยค",
    "province": "กาญจนบุรี",
    "zipcode": 71150,
    "sub_district_code": 710205,
    "district_code": 7102,
    "province_code": 71
  },
  {
    "sub_district": "ศรีมงคล",
    "district": "ไทรโยค",
    "province": "กาญจนบุรี",
    "zipcode": 71150,
    "sub_district_code": 710206,
    "district_code": 7102,
    "province_code": 71
  },
  {
    "sub_district": "บ้องตี้",
    "district": "ไทรโยค",
    "province": "กาญจนบุรี",
    "zipcode": 71150,
    "sub_district_code": 710207,
    "district_code": 7102,
    "province_code": 71
  },
  {
    "sub_district": "บ่อพลอย",
    "district": "บ่อพลอย",
    "province": "กาญจนบุรี",
    "zipcode": 71160,
    "sub_district_code": 710301,
    "district_code": 7103,
    "province_code": 71
  },
  {
    "sub_district": "หนองกุ่ม",
    "district": "บ่อพลอย",
    "province": "กาญจนบุรี",
    "zipcode": 71160,
    "sub_district_code": 710302,
    "district_code": 7103,
    "province_code": 71
  },
  {
    "sub_district": "หนองรี",
    "district": "บ่อพลอย",
    "province": "กาญจนบุรี",
    "zipcode": 71220,
    "sub_district_code": 710303,
    "district_code": 7103,
    "province_code": 71
  },
  {
    "sub_district": "หลุมรัง",
    "district": "บ่อพลอย",
    "province": "กาญจนบุรี",
    "zipcode": 71160,
    "sub_district_code": 710305,
    "district_code": 7103,
    "province_code": 71
  },
  {
    "sub_district": "ช่องด่าน",
    "district": "บ่อพลอย",
    "province": "กาญจนบุรี",
    "zipcode": 71160,
    "sub_district_code": 710308,
    "district_code": 7103,
    "province_code": 71
  },
  {
    "sub_district": "หนองกร่าง",
    "district": "บ่อพลอย",
    "province": "กาญจนบุรี",
    "zipcode": 71220,
    "sub_district_code": 710309,
    "district_code": 7103,
    "province_code": 71
  },
  {
    "sub_district": "พนมทวน",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71140,
    "sub_district_code": 710901,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "หนองโรง",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71140,
    "sub_district_code": 710902,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "ทุ่งสมอ",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71140,
    "sub_district_code": 710903,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "ดอนเจดีย์",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71140,
    "sub_district_code": 710904,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "พังตรุ",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71140,
    "sub_district_code": 710905,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "รางหวาย",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71170,
    "sub_district_code": 710906,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "ดอนแสลบ",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71170,
    "sub_district_code": 710996,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "ห้วยกระเจา",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71170,
    "sub_district_code": 710997,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "สระลงเรือ",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71170,
    "sub_district_code": 710998,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "หนองสาหร่าย",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71140,
    "sub_district_code": 710911,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "ดอนตาเพชร",
    "district": "พนมทวน",
    "province": "กาญจนบุรี",
    "zipcode": 71140,
    "sub_district_code": 710912,
    "district_code": 7109,
    "province_code": 71
  },
  {
    "sub_district": "เลาขวัญ",
    "district": "เลาขวัญ",
    "province": "กาญจนบุรี",
    "zipcode": 71210,
    "sub_district_code": 711001,
    "district_code": 7110,
    "province_code": 71
  },
  {
    "sub_district": "หนองโสน",
    "district": "เลาขวัญ",
    "province": "กาญจนบุรี",
    "zipcode": 71210,
    "sub_district_code": 711002,
    "district_code": 7110,
    "province_code": 71
  },
  {
    "sub_district": "หนองประดู่",
    "district": "เลาขวัญ",
    "province": "กาญจนบุรี",
    "zipcode": 71210,
    "sub_district_code": 711003,
    "district_code": 7110,
    "province_code": 71
  },
  {
    "sub_district": "หนองปลิง",
    "district": "เลาขวัญ",
    "province": "กาญจนบุรี",
    "zipcode": 71210,
    "sub_district_code": 711004,
    "district_code": 7110,
    "province_code": 71
  },
  {
    "sub_district": "หนองนกแก้ว",
    "district": "เลาขวัญ",
    "province": "กาญจนบุรี",
    "zipcode": 71210,
    "sub_district_code": 711005,
    "district_code": 7110,
    "province_code": 71
  },
  {
    "sub_district": "ทุ่งกระบ่ำ",
    "district": "เลาขวัญ",
    "province": "กาญจนบุรี",
    "zipcode": 71210,
    "sub_district_code": 711006,
    "district_code": 7110,
    "province_code": 71
  },
  {
    "sub_district": "หนองฝ้าย",
    "district": "เลาขวัญ",
    "province": "กาญจนบุรี",
    "zipcode": 71210,
    "sub_district_code": 711007,
    "district_code": 7110,
    "province_code": 71
  },
  {
    "sub_district": "นาสวน",
    "district": "ศรีสวัสดิ์",
    "province": "กาญจนบุรี",
    "zipcode": 71250,
    "sub_district_code": 710401,
    "district_code": 7104,
    "province_code": 71
  },
  {
    "sub_district": "ด่านแม่แฉลบ",
    "district": "ศรีสวัสดิ์",
    "province": "กาญจนบุรี",
    "zipcode": 71250,
    "sub_district_code": 710402,
    "district_code": 7104,
    "province_code": 71
  },
  {
    "sub_district": "หนองเป็ด",
    "district": "ศรีสวัสดิ์",
    "province": "กาญจนบุรี",
    "zipcode": 71250,
    "sub_district_code": 710403,
    "district_code": 7104,
    "province_code": 71
  },
  {
    "sub_district": "ท่ากระดาน",
    "district": "ศรีสวัสดิ์",
    "province": "กาญจนบุรี",
    "zipcode": 71250,
    "sub_district_code": 710404,
    "district_code": 7104,
    "province_code": 71
  },
  {
    "sub_district": "เขาโจด",
    "district": "ศรีสวัสดิ์",
    "province": "กาญจนบุรี",
    "zipcode": 71220,
    "sub_district_code": 710405,
    "district_code": 7104,
    "province_code": 71
  },
  {
    "sub_district": "แม่กระบุง",
    "district": "ศรีสวัสดิ์",
    "province": "กาญจนบุรี",
    "zipcode": 71250,
    "sub_district_code": 710406,
    "district_code": 7104,
    "province_code": 71
  },
  {
    "sub_district": "หนองลู",
    "district": "สังขละบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71240,
    "sub_district_code": 710801,
    "district_code": 7108,
    "province_code": 71
  },
  {
    "sub_district": "ปรังเผล",
    "district": "สังขละบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71240,
    "sub_district_code": 710802,
    "district_code": 7108,
    "province_code": 71
  },
  {
    "sub_district": "ไล่โว่",
    "district": "สังขละบุรี",
    "province": "กาญจนบุรี",
    "zipcode": 71240,
    "sub_district_code": 710803,
    "district_code": 7108,
    "province_code": 71
  },
  {
    "sub_district": "ด่านมะขามเตี้ย",
    "district": "ด่านมะขามเตี้ย",
    "province": "กาญจนบุรี",
    "zipcode": 71260,
    "sub_district_code": 711101,
    "district_code": 7111,
    "province_code": 71
  },
  {
    "sub_district": "กลอนโด",
    "district": "ด่านมะขามเตี้ย",
    "province": "กาญจนบุรี",
    "zipcode": 71260,
    "sub_district_code": 711102,
    "district_code": 7111,
    "province_code": 71
  },
  {
    "sub_district": "จรเข้เผือก",
    "district": "ด่านมะขามเตี้ย",
    "province": "กาญจนบุรี",
    "zipcode": 71260,
    "sub_district_code": 711103,
    "district_code": 7111,
    "province_code": 71
  },
  {
    "sub_district": "หนองไผ่",
    "district": "ด่านมะขามเตี้ย",
    "province": "กาญจนบุรี",
    "zipcode": 71260,
    "sub_district_code": 711104,
    "district_code": 7111,
    "province_code": 71
  },
  {
    "sub_district": "หนองปลาไหล",
    "district": "หนองปรือ",
    "province": "กาญจนบุรี",
    "zipcode": 71220,
    "sub_district_code": 711202,
    "district_code": 7112,
    "province_code": 71
  },
  {
    "sub_district": "หนองปรือ",
    "district": "หนองปรือ",
    "province": "กาญจนบุรี",
    "zipcode": 71220,
    "sub_district_code": 711201,
    "district_code": 7112,
    "province_code": 71
  },
  {
    "sub_district": "สมเด็จเจริญ",
    "district": "หนองปรือ",
    "province": "กาญจนบุรี",
    "zipcode": 71220,
    "sub_district_code": 711203,
    "district_code": 7112,
    "province_code": 71
  },
  {
    "sub_district": "ดอนแสลบ",
    "district": "ห้วยกระเจา",
    "province": "กาญจนบุรี",
    "zipcode": 71170,
    "sub_district_code": 711303,
    "district_code": 7113,
    "province_code": 71
  },
  {
    "sub_district": "ห้วยกระเจา",
    "district": "ห้วยกระเจา",
    "province": "กาญจนบุรี",
    "zipcode": 71170,
    "sub_district_code": 711301,
    "district_code": 7113,
    "province_code": 71
  },
  {
    "sub_district": "วังไผ่",
    "district": "ห้วยกระเจา",
    "province": "กาญจนบุรี",
    "zipcode": 71170,
    "sub_district_code": 711302,
    "district_code": 7113,
    "province_code": 71
  },
  {
    "sub_district": "สระลงเรือ",
    "district": "ห้วยกระเจา",
    "province": "กาญจนบุรี",
    "zipcode": 71170,
    "sub_district_code": 711304,
    "district_code": 7113,
    "province_code": 71
  },
  {
    "sub_district": "กาฬสินธุ์",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460101,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "เหนือ",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460102,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "หลุบ",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460103,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ไผ่",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460104,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ลำปาว",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460105,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ลำพาน",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460106,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "เชียงเครือ",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460107,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "บึงวิชัย",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460108,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ห้วยโพธิ์",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460109,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ม่วงนา",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460192,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ภูปอ",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460111,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ภูดิน",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460113,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ดงพยุง",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460193,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ดอนจาน",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460194,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "หนองกุง",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460115,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "กลางหมื่น",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460116,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "โพนทอง",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460119,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ยางตลาด",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460196,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "นาจำปา",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460197,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "นาจารย์",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460120,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ขมิ้น",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460117,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "ลำคลอง",
    "district": "เมืองกาฬสินธุ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460121,
    "district_code": 4601,
    "province_code": 46
  },
  {
    "sub_district": "กมลาไสย",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 460301,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "หลักเมือง",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 460302,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "โพนงาม",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 460303,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "ดงลิง",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 460304,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "ธัญญา",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 460305,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "ลำชี",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 460396,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "หนองแปน",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 460308,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 460397,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "เจ้าท่า",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 460310,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "โนนศิลา",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46140,
    "sub_district_code": 460398,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "โคกสมบูรณ์",
    "district": "กมลาไสย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 460311,
    "district_code": 4603,
    "province_code": 46
  },
  {
    "sub_district": "บัวขาว",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460501,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "แจนแลน",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460502,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "คำบง",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46240,
    "sub_district_code": 460597,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "ไค้นุ่น",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46240,
    "sub_district_code": 460598,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "เหล่าใหญ่",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460503,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "จุมจัง",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460504,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "เหล่าไฮงาม",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460505,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "กุดหว้า",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460506,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "สามขา",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460507,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "นาขาม",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460508,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "หนองห้าง",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460509,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "นาโก",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460510,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "สมสะอาด",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460511,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "กุดค้าว",
    "district": "กุฉินารายณ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46110,
    "sub_district_code": 460512,
    "district_code": 4605,
    "province_code": 46
  },
  {
    "sub_district": "คุ้มเก่า",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460601,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "สงเปลือย",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460602,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "หนองผือ",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460603,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "ภูแล่นช้าง",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460694,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "นาคู",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460695,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "กุดสิมคุ้มใหม่",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460606,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "บ่อแก้ว",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460696,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "สระพังทอง",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460608,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "สายนาวัง",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460697,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "โนนนาจาน",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460698,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "กุดปลาค้าว",
    "district": "เขาวง",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 460611,
    "district_code": 4606,
    "province_code": 46
  },
  {
    "sub_district": "ท่าคันโท",
    "district": "ท่าคันโท",
    "province": "กาฬสินธุ์",
    "zipcode": 46190,
    "sub_district_code": 461101,
    "district_code": 4611,
    "province_code": 46
  },
  {
    "sub_district": "กุงเก่า",
    "district": "ท่าคันโท",
    "province": "กาฬสินธุ์",
    "zipcode": 46190,
    "sub_district_code": 461102,
    "district_code": 4611,
    "province_code": 46
  },
  {
    "sub_district": "ยางอู้ม",
    "district": "ท่าคันโท",
    "province": "กาฬสินธุ์",
    "zipcode": 46190,
    "sub_district_code": 461103,
    "district_code": 4611,
    "province_code": 46
  },
  {
    "sub_district": "นาตาล",
    "district": "ท่าคันโท",
    "province": "กาฬสินธุ์",
    "zipcode": 46190,
    "sub_district_code": 461105,
    "district_code": 4611,
    "province_code": 46
  },
  {
    "sub_district": "กุดจิก",
    "district": "ท่าคันโท",
    "province": "กาฬสินธุ์",
    "zipcode": 46190,
    "sub_district_code": 461104,
    "district_code": 4611,
    "province_code": 46
  },
  {
    "sub_district": "ดงสมบูรณ์",
    "district": "ท่าคันโท",
    "province": "กาฬสินธุ์",
    "zipcode": 46190,
    "sub_district_code": 461106,
    "district_code": 4611,
    "province_code": 46
  },
  {
    "sub_district": "เขาพระนอน",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460712,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "ยางตลาด",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460701,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "หัวงัว",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460702,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "อุ่มเม่า",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460703,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "บัวบาน",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460704,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "เว่อ",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460705,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "อิตื้อ",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460706,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "หัวนาคำ",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460707,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "หนองอิเฒ่า",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460708,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "ดอนสมบูรณ์",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460709,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "นาเชือก",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460710,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "คลองขาม",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460711,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "ลำปาว",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 460798,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "นาดี",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460713,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "นาดี",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460713,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "โนนสูง",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460714,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "หนองตอกแป้น",
    "district": "ยางตลาด",
    "province": "กาฬสินธุ์",
    "zipcode": 46120,
    "sub_district_code": 460715,
    "district_code": 4607,
    "province_code": 46
  },
  {
    "sub_district": "สมเด็จ",
    "district": "สมเด็จ",
    "province": "กาฬสินธุ์",
    "zipcode": 46150,
    "sub_district_code": 461301,
    "district_code": 4613,
    "province_code": 46
  },
  {
    "sub_district": "หนองแวง",
    "district": "สมเด็จ",
    "province": "กาฬสินธุ์",
    "zipcode": 46150,
    "sub_district_code": 461302,
    "district_code": 4613,
    "province_code": 46
  },
  {
    "sub_district": "แซงบาดาล",
    "district": "สมเด็จ",
    "province": "กาฬสินธุ์",
    "zipcode": 46150,
    "sub_district_code": 461303,
    "district_code": 4613,
    "province_code": 46
  },
  {
    "sub_district": "มหาไชย",
    "district": "สมเด็จ",
    "province": "กาฬสินธุ์",
    "zipcode": 46150,
    "sub_district_code": 461304,
    "district_code": 4613,
    "province_code": 46
  },
  {
    "sub_district": "หมูม่น",
    "district": "สมเด็จ",
    "province": "กาฬสินธุ์",
    "zipcode": 46150,
    "sub_district_code": 461305,
    "district_code": 4613,
    "province_code": 46
  },
  {
    "sub_district": "ผาเสวย",
    "district": "สมเด็จ",
    "province": "กาฬสินธุ์",
    "zipcode": 46150,
    "sub_district_code": 461306,
    "district_code": 4613,
    "province_code": 46
  },
  {
    "sub_district": "ศรีสมเด็จ",
    "district": "สมเด็จ",
    "province": "กาฬสินธุ์",
    "zipcode": 46150,
    "sub_district_code": 461307,
    "district_code": 4613,
    "province_code": 46
  },
  {
    "sub_district": "ลำห้วยหลัว",
    "district": "สมเด็จ",
    "province": "กาฬสินธุ์",
    "zipcode": 46150,
    "sub_district_code": 461308,
    "district_code": 4613,
    "province_code": 46
  },
  {
    "sub_district": "ภูสิงห์",
    "district": "สหัสขันธ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46140,
    "sub_district_code": 460901,
    "district_code": 4609,
    "province_code": 46
  },
  {
    "sub_district": "สหัสขันธ์",
    "district": "สหัสขันธ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46140,
    "sub_district_code": 460902,
    "district_code": 4609,
    "province_code": 46
  },
  {
    "sub_district": "นามะเขือ",
    "district": "สหัสขันธ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46140,
    "sub_district_code": 460903,
    "district_code": 4609,
    "province_code": 46
  },
  {
    "sub_district": "โนนศิลา",
    "district": "สหัสขันธ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46140,
    "sub_district_code": 460904,
    "district_code": 4609,
    "province_code": 46
  },
  {
    "sub_district": "โนนน้ำเกลี้ยง",
    "district": "สหัสขันธ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46140,
    "sub_district_code": 460908,
    "district_code": 4609,
    "province_code": 46
  },
  {
    "sub_district": "โนนบุรี",
    "district": "สหัสขันธ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46140,
    "sub_district_code": 460907,
    "district_code": 4609,
    "province_code": 46
  },
  {
    "sub_district": "นิคม",
    "district": "สหัสขันธ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46140,
    "sub_district_code": 460905,
    "district_code": 4609,
    "province_code": 46
  },
  {
    "sub_district": "โนนแหลมทอง",
    "district": "สหัสขันธ์",
    "province": "กาฬสินธุ์",
    "zipcode": 46140,
    "sub_district_code": 460906,
    "district_code": 4609,
    "province_code": 46
  },
  {
    "sub_district": "ห้วยเม็ก",
    "district": "ห้วยเม็ก",
    "province": "กาฬสินธุ์",
    "zipcode": 46170,
    "sub_district_code": 460801,
    "district_code": 4608,
    "province_code": 46
  },
  {
    "sub_district": "คำใหญ่",
    "district": "ห้วยเม็ก",
    "province": "กาฬสินธุ์",
    "zipcode": 46170,
    "sub_district_code": 460802,
    "district_code": 4608,
    "province_code": 46
  },
  {
    "sub_district": "กุดโดน",
    "district": "ห้วยเม็ก",
    "province": "กาฬสินธุ์",
    "zipcode": 46170,
    "sub_district_code": 460803,
    "district_code": 4608,
    "province_code": 46
  },
  {
    "sub_district": "บึงนาเรียง",
    "district": "ห้วยเม็ก",
    "province": "กาฬสินธุ์",
    "zipcode": 46170,
    "sub_district_code": 460804,
    "district_code": 4608,
    "province_code": 46
  },
  {
    "sub_district": "หัวหิน",
    "district": "ห้วยเม็ก",
    "province": "กาฬสินธุ์",
    "zipcode": 46170,
    "sub_district_code": 460805,
    "district_code": 4608,
    "province_code": 46
  },
  {
    "sub_district": "พิมูล",
    "district": "ห้วยเม็ก",
    "province": "กาฬสินธุ์",
    "zipcode": 46170,
    "sub_district_code": 460806,
    "district_code": 4608,
    "province_code": 46
  },
  {
    "sub_district": "คำเหมือดแก้ว",
    "district": "ห้วยเม็ก",
    "province": "กาฬสินธุ์",
    "zipcode": 46170,
    "sub_district_code": 460807,
    "district_code": 4608,
    "province_code": 46
  },
  {
    "sub_district": "โนนสะอาด",
    "district": "ห้วยเม็ก",
    "province": "กาฬสินธุ์",
    "zipcode": 46170,
    "sub_district_code": 460808,
    "district_code": 4608,
    "province_code": 46
  },
  {
    "sub_district": "ทรายทอง",
    "district": "ห้วยเม็ก",
    "province": "กาฬสินธุ์",
    "zipcode": 46170,
    "sub_district_code": 460809,
    "district_code": 4608,
    "province_code": 46
  },
  {
    "sub_district": "ทุ่งคลอง",
    "district": "คำม่วง",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461001,
    "district_code": 4610,
    "province_code": 46
  },
  {
    "sub_district": "โพน",
    "district": "คำม่วง",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461002,
    "district_code": 4610,
    "province_code": 46
  },
  {
    "sub_district": "สำราญ",
    "district": "คำม่วง",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461095,
    "district_code": 4610,
    "province_code": 46
  },
  {
    "sub_district": "สำราญใต้",
    "district": "คำม่วง",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461096,
    "district_code": 4610,
    "province_code": 46
  },
  {
    "sub_district": "ดินจี่",
    "district": "คำม่วง",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461005,
    "district_code": 4610,
    "province_code": 46
  },
  {
    "sub_district": "นาบอน",
    "district": "คำม่วง",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461006,
    "district_code": 4610,
    "province_code": 46
  },
  {
    "sub_district": "นาทัน",
    "district": "คำม่วง",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461007,
    "district_code": 4610,
    "province_code": 46
  },
  {
    "sub_district": "คำสร้างเที่ยง",
    "district": "คำม่วง",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461097,
    "district_code": 4610,
    "province_code": 46
  },
  {
    "sub_district": "หนองช้าง",
    "district": "คำม่วง",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461098,
    "district_code": 4610,
    "province_code": 46
  },
  {
    "sub_district": "เนินยาง",
    "district": "คำม่วง",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461009,
    "district_code": 4610,
    "province_code": 46
  },
  {
    "sub_district": "ร่องคำ",
    "district": "ร่องคำ",
    "province": "กาฬสินธุ์",
    "zipcode": 46210,
    "sub_district_code": 460401,
    "district_code": 4604,
    "province_code": 46
  },
  {
    "sub_district": "สามัคคี",
    "district": "ร่องคำ",
    "province": "กาฬสินธุ์",
    "zipcode": 46210,
    "sub_district_code": 460402,
    "district_code": 4604,
    "province_code": 46
  },
  {
    "sub_district": "เหล่าอ้อย",
    "district": "ร่องคำ",
    "province": "กาฬสินธุ์",
    "zipcode": 46210,
    "sub_district_code": 460403,
    "district_code": 4604,
    "province_code": 46
  },
  {
    "sub_district": "หนองกุงศรี",
    "district": "หนองกุงศรี",
    "province": "กาฬสินธุ์",
    "zipcode": 46220,
    "sub_district_code": 461201,
    "district_code": 4612,
    "province_code": 46
  },
  {
    "sub_district": "หนองบัว",
    "district": "หนองกุงศรี",
    "province": "กาฬสินธุ์",
    "zipcode": 46220,
    "sub_district_code": 461202,
    "district_code": 4612,
    "province_code": 46
  },
  {
    "sub_district": "โคกเครือ",
    "district": "หนองกุงศรี",
    "province": "กาฬสินธุ์",
    "zipcode": 46220,
    "sub_district_code": 461203,
    "district_code": 4612,
    "province_code": 46
  },
  {
    "sub_district": "หนองสรวง",
    "district": "หนองกุงศรี",
    "province": "กาฬสินธุ์",
    "zipcode": 46220,
    "sub_district_code": 461204,
    "district_code": 4612,
    "province_code": 46
  },
  {
    "sub_district": "เสาเล้า",
    "district": "หนองกุงศรี",
    "province": "กาฬสินธุ์",
    "zipcode": 46220,
    "sub_district_code": 461205,
    "district_code": 4612,
    "province_code": 46
  },
  {
    "sub_district": "ลำหนองแสน",
    "district": "หนองกุงศรี",
    "province": "กาฬสินธุ์",
    "zipcode": 46220,
    "sub_district_code": 461208,
    "district_code": 4612,
    "province_code": 46
  },
  {
    "sub_district": "หนองใหญ่",
    "district": "หนองกุงศรี",
    "province": "กาฬสินธุ์",
    "zipcode": 46220,
    "sub_district_code": 461206,
    "district_code": 4612,
    "province_code": 46
  },
  {
    "sub_district": "ดงมูล",
    "district": "หนองกุงศรี",
    "province": "กาฬสินธุ์",
    "zipcode": 46220,
    "sub_district_code": 461207,
    "district_code": 4612,
    "province_code": 46
  },
  {
    "sub_district": "หนองหิน",
    "district": "หนองกุงศรี",
    "province": "กาฬสินธุ์",
    "zipcode": 46220,
    "sub_district_code": 461209,
    "district_code": 4612,
    "province_code": 46
  },
  {
    "sub_district": "นามน",
    "district": "นามน",
    "province": "กาฬสินธุ์",
    "zipcode": 46230,
    "sub_district_code": 460201,
    "district_code": 4602,
    "province_code": 46
  },
  {
    "sub_district": "ยอดแกง",
    "district": "นามน",
    "province": "กาฬสินธุ์",
    "zipcode": 46230,
    "sub_district_code": 460202,
    "district_code": 4602,
    "province_code": 46
  },
  {
    "sub_district": "สงเปลือย",
    "district": "นามน",
    "province": "กาฬสินธุ์",
    "zipcode": 46230,
    "sub_district_code": 460203,
    "district_code": 4602,
    "province_code": 46
  },
  {
    "sub_district": "หลักเหลี่ยม",
    "district": "นามน",
    "province": "กาฬสินธุ์",
    "zipcode": 46230,
    "sub_district_code": 460204,
    "district_code": 4602,
    "province_code": 46
  },
  {
    "sub_district": "หนองบัว",
    "district": "นามน",
    "province": "กาฬสินธุ์",
    "zipcode": 46230,
    "sub_district_code": 460205,
    "district_code": 4602,
    "province_code": 46
  },
  {
    "sub_district": "นิคมห้วยผึ้ง",
    "district": "ห้วยผึ้ง",
    "province": "กาฬสินธุ์",
    "zipcode": 46240,
    "sub_district_code": 461403,
    "district_code": 4614,
    "province_code": 46
  },
  {
    "sub_district": "ไค้นุ่น",
    "district": "ห้วยผึ้ง",
    "province": "กาฬสินธุ์",
    "zipcode": 46240,
    "sub_district_code": 461402,
    "district_code": 4614,
    "province_code": 46
  },
  {
    "sub_district": "คำบง",
    "district": "ห้วยผึ้ง",
    "province": "กาฬสินธุ์",
    "zipcode": 46240,
    "sub_district_code": 461401,
    "district_code": 4614,
    "province_code": 46
  },
  {
    "sub_district": "หนองอีบุตร",
    "district": "ห้วยผึ้ง",
    "province": "กาฬสินธุ์",
    "zipcode": 46240,
    "sub_district_code": 461404,
    "district_code": 4614,
    "province_code": 46
  },
  {
    "sub_district": "สำราญ",
    "district": "สามชัย",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461501,
    "district_code": 4615,
    "province_code": 46
  },
  {
    "sub_district": "สำราญใต้",
    "district": "สามชัย",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461502,
    "district_code": 4615,
    "province_code": 46
  },
  {
    "sub_district": "คำสร้างเที่ยง",
    "district": "สามชัย",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461503,
    "district_code": 4615,
    "province_code": 46
  },
  {
    "sub_district": "หนองช้าง",
    "district": "สามชัย",
    "province": "กาฬสินธุ์",
    "zipcode": 46180,
    "sub_district_code": 461504,
    "district_code": 4615,
    "province_code": 46
  },
  {
    "sub_district": "นาคู",
    "district": "นาคู",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 461601,
    "district_code": 4616,
    "province_code": 46
  },
  {
    "sub_district": "ภูแล่นช้าง",
    "district": "นาคู",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 461605,
    "district_code": 4616,
    "province_code": 46
  },
  {
    "sub_district": "บ่อแก้ว",
    "district": "นาคู",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 461604,
    "district_code": 4616,
    "province_code": 46
  },
  {
    "sub_district": "โนนนาจาน",
    "district": "นาคู",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 461603,
    "district_code": 4616,
    "province_code": 46
  },
  {
    "sub_district": "สายนาวัง",
    "district": "นาคู",
    "province": "กาฬสินธุ์",
    "zipcode": 46160,
    "sub_district_code": 461602,
    "district_code": 4616,
    "province_code": 46
  },
  {
    "sub_district": "ม่วงนา",
    "district": "ดอนจาน",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 461704,
    "district_code": 4617,
    "province_code": 46
  },
  {
    "sub_district": "นาจำปา",
    "district": "ดอนจาน",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 461705,
    "district_code": 4617,
    "province_code": 46
  },
  {
    "sub_district": "ดอนจาน",
    "district": "ดอนจาน",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 461701,
    "district_code": 4617,
    "province_code": 46
  },
  {
    "sub_district": "ดงพยุง",
    "district": "ดอนจาน",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 461703,
    "district_code": 4617,
    "province_code": 46
  },
  {
    "sub_district": "สะอาดไชยศรี",
    "district": "ดอนจาน",
    "province": "กาฬสินธุ์",
    "zipcode": 46000,
    "sub_district_code": 461702,
    "district_code": 4617,
    "province_code": 46
  },
  {
    "sub_district": "ลำชี",
    "district": "ฆ้องชัย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 461805,
    "district_code": 4618,
    "province_code": 46
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "ฆ้องชัย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 461803,
    "district_code": 4618,
    "province_code": 46
  },
  {
    "sub_district": "โนนศิลาเลิง",
    "district": "ฆ้องชัย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 461804,
    "district_code": 4618,
    "province_code": 46
  },
  {
    "sub_district": "ฆ้องชัยพัฒนา",
    "district": "ฆ้องชัย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 461801,
    "district_code": 4618,
    "province_code": 46
  },
  {
    "sub_district": "เหล่ากลาง",
    "district": "ฆ้องชัย",
    "province": "กาฬสินธุ์",
    "zipcode": 46130,
    "sub_district_code": 461802,
    "district_code": 4618,
    "province_code": 46
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620101,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "ไตรตรึงษ์",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62160,
    "sub_district_code": 620102,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "อ่างทอง",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620103,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "นาบ่อคำ",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620104,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "นครชุม",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620105,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "ทรงธรรม",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620106,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "ลานดอกไม้",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620107,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "ลานดอกไม้ตก",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620196,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "โกสัมพี",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620197,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "หนองปลิง",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620110,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "คณฑี",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620111,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "เทพนคร",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620113,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "นิคมทุ่งโพธิ์ทะเล",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620112,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "วังทอง",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620114,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "ท่าขุนราม",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620115,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "คลองแม่ลาย",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620117,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "สระแก้ว",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620119,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "พรานกระต่าย",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620198,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "ธำมรงค์",
    "district": "เมืองกำแพงเพชร",
    "province": "กำแพงเพชร",
    "zipcode": 62160,
    "sub_district_code": 620118,
    "district_code": 6201,
    "province_code": 62
  },
  {
    "sub_district": "วังชะโอน",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62210,
    "sub_district_code": 620495,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "ยางสูง",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62130,
    "sub_district_code": 620403,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "ป่าพุทรา",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62130,
    "sub_district_code": 620404,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "แสนตอ",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62130,
    "sub_district_code": 620405,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "สลกบาตร",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62140,
    "sub_district_code": 620406,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "บ่อถ้ำ",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62140,
    "sub_district_code": 620407,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "ดอนแตง",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62140,
    "sub_district_code": 620408,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "วังชะพลู",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62140,
    "sub_district_code": 620409,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "โค้งไผ่",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62140,
    "sub_district_code": 620410,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "ปางมะค่า",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62140,
    "sub_district_code": 620411,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "วังหามแห",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62140,
    "sub_district_code": 620412,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "เกาะตาล",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62130,
    "sub_district_code": 620413,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "บึงสามัคคี",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62210,
    "sub_district_code": 620497,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "โพธิ์ทอง",
    "district": "ขาณุวรลักษบุรี",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620498,
    "district_code": 6204,
    "province_code": 62
  },
  {
    "sub_district": "คลองขลุง",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620501,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "ท่ามะเขือ",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620502,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "ทุ่งทราย",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62190,
    "sub_district_code": 620593,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "ท่าพุทรา",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620504,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "แม่ลาด",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620505,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "วังยาง",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620506,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "วังแขม",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620507,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "หัวถนน",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620508,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "วังไทร",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620509,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "ถาวรวัฒนา",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62190,
    "sub_district_code": 620594,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "โพธิ์ทอง",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620595,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "หินดาต",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620596,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "ปางตาไว",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620597,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "วังบัว",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620513,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "ทุ่งทอง",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62190,
    "sub_district_code": 620598,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "คลองสมบูรณ์",
    "district": "คลองขลุง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620516,
    "district_code": 6205,
    "province_code": 62
  },
  {
    "sub_district": "พรานกระต่าย",
    "district": "พรานกระต่าย",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620601,
    "district_code": 6206,
    "province_code": 62
  },
  {
    "sub_district": "หนองหัววัว",
    "district": "พรานกระต่าย",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620602,
    "district_code": 6206,
    "province_code": 62
  },
  {
    "sub_district": "ท่าไม้",
    "district": "พรานกระต่าย",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620603,
    "district_code": 6206,
    "province_code": 62
  },
  {
    "sub_district": "วังควง",
    "district": "พรานกระต่าย",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620604,
    "district_code": 6206,
    "province_code": 62
  },
  {
    "sub_district": "วังตะแบก",
    "district": "พรานกระต่าย",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620605,
    "district_code": 6206,
    "province_code": 62
  },
  {
    "sub_district": "เขาคีริส",
    "district": "พรานกระต่าย",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620606,
    "district_code": 6206,
    "province_code": 62
  },
  {
    "sub_district": "คุยบ้านโอง",
    "district": "พรานกระต่าย",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620607,
    "district_code": 6206,
    "province_code": 62
  },
  {
    "sub_district": "คลองพิไกร",
    "district": "พรานกระต่าย",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620608,
    "district_code": 6206,
    "province_code": 62
  },
  {
    "sub_district": "ถ้ำกระต่ายทอง",
    "district": "พรานกระต่าย",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620609,
    "district_code": 6206,
    "province_code": 62
  },
  {
    "sub_district": "ห้วยยั้ง",
    "district": "พรานกระต่าย",
    "province": "กำแพงเพชร",
    "zipcode": 62110,
    "sub_district_code": 620610,
    "district_code": 6206,
    "province_code": 62
  },
  {
    "sub_district": "ไทรงาม",
    "district": "ไทรงาม",
    "province": "กำแพงเพชร",
    "zipcode": 62150,
    "sub_district_code": 620201,
    "district_code": 6202,
    "province_code": 62
  },
  {
    "sub_district": "หนองคล้า",
    "district": "ไทรงาม",
    "province": "กำแพงเพชร",
    "zipcode": 62150,
    "sub_district_code": 620202,
    "district_code": 6202,
    "province_code": 62
  },
  {
    "sub_district": "พานทอง",
    "district": "ไทรงาม",
    "province": "กำแพงเพชร",
    "zipcode": 62150,
    "sub_district_code": 620206,
    "district_code": 6202,
    "province_code": 62
  },
  {
    "sub_district": "มหาชัย",
    "district": "ไทรงาม",
    "province": "กำแพงเพชร",
    "zipcode": 62150,
    "sub_district_code": 620205,
    "district_code": 6202,
    "province_code": 62
  },
  {
    "sub_district": "หนองไม้กอง",
    "district": "ไทรงาม",
    "province": "กำแพงเพชร",
    "zipcode": 62150,
    "sub_district_code": 620204,
    "district_code": 6202,
    "province_code": 62
  },
  {
    "sub_district": "หนองทอง",
    "district": "ไทรงาม",
    "province": "กำแพงเพชร",
    "zipcode": 62150,
    "sub_district_code": 620203,
    "district_code": 6202,
    "province_code": 62
  },
  {
    "sub_district": "หนองไม้งาม",
    "district": "ไทรงาม",
    "province": "กำแพงเพชร",
    "zipcode": 62150,
    "sub_district_code": 620207,
    "district_code": 6202,
    "province_code": 62
  },
  {
    "sub_district": "หนองแม่แตง",
    "district": "ไทรงาม",
    "province": "กำแพงเพชร",
    "zipcode": 62150,
    "sub_district_code": 620207,
    "district_code": 6202,
    "province_code": 62
  },
  {
    "sub_district": "คลองลาน",
    "district": "คลองลาน",
    "province": "กำแพงเพชร",
    "zipcode": 62180,
    "sub_district_code": 620301,
    "district_code": 6203,
    "province_code": 62
  },
  {
    "sub_district": "โป่งน้ำร้อน",
    "district": "คลองลาน",
    "province": "กำแพงเพชร",
    "zipcode": 62180,
    "sub_district_code": 620302,
    "district_code": 6203,
    "province_code": 62
  },
  {
    "sub_district": "คลองน้ำไหล",
    "district": "คลองลาน",
    "province": "กำแพงเพชร",
    "zipcode": 62180,
    "sub_district_code": 620301,
    "district_code": 6203,
    "province_code": 62
  },
  {
    "sub_district": "คลองลานพัฒนา",
    "district": "คลองลาน",
    "province": "กำแพงเพชร",
    "zipcode": 62180,
    "sub_district_code": 620303,
    "district_code": 6203,
    "province_code": 62
  },
  {
    "sub_district": "คลองแม่ลาย",
    "district": "คลองลาน",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 620398,
    "district_code": 6203,
    "province_code": 62
  },
  {
    "sub_district": "สักงาม",
    "district": "คลองลาน",
    "province": "กำแพงเพชร",
    "zipcode": 62180,
    "sub_district_code": 620304,
    "district_code": 6203,
    "province_code": 62
  },
  {
    "sub_district": "ลานกระบือ",
    "district": "ลานกระบือ",
    "province": "กำแพงเพชร",
    "zipcode": 62170,
    "sub_district_code": 620701,
    "district_code": 6207,
    "province_code": 62
  },
  {
    "sub_district": "ช่องลม",
    "district": "ลานกระบือ",
    "province": "กำแพงเพชร",
    "zipcode": 62170,
    "sub_district_code": 620702,
    "district_code": 6207,
    "province_code": 62
  },
  {
    "sub_district": "หนองหลวง",
    "district": "ลานกระบือ",
    "province": "กำแพงเพชร",
    "zipcode": 62170,
    "sub_district_code": 620703,
    "district_code": 6207,
    "province_code": 62
  },
  {
    "sub_district": "โนนพลวง",
    "district": "ลานกระบือ",
    "province": "กำแพงเพชร",
    "zipcode": 62170,
    "sub_district_code": 620704,
    "district_code": 6207,
    "province_code": 62
  },
  {
    "sub_district": "ประชาสุขสันต์",
    "district": "ลานกระบือ",
    "province": "กำแพงเพชร",
    "zipcode": 62170,
    "sub_district_code": 620705,
    "district_code": 6207,
    "province_code": 62
  },
  {
    "sub_district": "บึงทับแรต",
    "district": "ลานกระบือ",
    "province": "กำแพงเพชร",
    "zipcode": 62170,
    "sub_district_code": 620706,
    "district_code": 6207,
    "province_code": 62
  },
  {
    "sub_district": "จันทิมา",
    "district": "ลานกระบือ",
    "province": "กำแพงเพชร",
    "zipcode": 62170,
    "sub_district_code": 620707,
    "district_code": 6207,
    "province_code": 62
  },
  {
    "sub_district": "หินดาต",
    "district": "ปางศิลาทอง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620902,
    "district_code": 6209,
    "province_code": 62
  },
  {
    "sub_district": "โพธิ์ทอง",
    "district": "ปางศิลาทอง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620901,
    "district_code": 6209,
    "province_code": 62
  },
  {
    "sub_district": "ปางตาไว",
    "district": "ปางศิลาทอง",
    "province": "กำแพงเพชร",
    "zipcode": 62120,
    "sub_district_code": 620903,
    "district_code": 6209,
    "province_code": 62
  },
  {
    "sub_district": "ระหาน",
    "district": "บึงสามัคคี",
    "province": "กำแพงเพชร",
    "zipcode": 62210,
    "sub_district_code": 621003,
    "district_code": 6210,
    "province_code": 62
  },
  {
    "sub_district": "บึงสามัคคี",
    "district": "บึงสามัคคี",
    "province": "กำแพงเพชร",
    "zipcode": 62210,
    "sub_district_code": 621001,
    "district_code": 6210,
    "province_code": 62
  },
  {
    "sub_district": "เทพนิมิต",
    "district": "บึงสามัคคี",
    "province": "กำแพงเพชร",
    "zipcode": 62210,
    "sub_district_code": 621004,
    "district_code": 6210,
    "province_code": 62
  },
  {
    "sub_district": "วังชะโอน",
    "district": "บึงสามัคคี",
    "province": "กำแพงเพชร",
    "zipcode": 62210,
    "sub_district_code": 621002,
    "district_code": 6210,
    "province_code": 62
  },
  {
    "sub_district": "ทุ่งทราย",
    "district": "ทรายทองวัฒนา",
    "province": "กำแพงเพชร",
    "zipcode": 62190,
    "sub_district_code": 620801,
    "district_code": 6208,
    "province_code": 62
  },
  {
    "sub_district": "ทุ่งทอง",
    "district": "ทรายทองวัฒนา",
    "province": "กำแพงเพชร",
    "zipcode": 62190,
    "sub_district_code": 620802,
    "district_code": 6208,
    "province_code": 62
  },
  {
    "sub_district": "ถาวรวัฒนา",
    "district": "ทรายทองวัฒนา",
    "province": "กำแพงเพชร",
    "zipcode": 62190,
    "sub_district_code": 620803,
    "district_code": 6208,
    "province_code": 62
  },
  {
    "sub_district": "โกสัมพี",
    "district": "โกสัมพีนคร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 621101,
    "district_code": 6211,
    "province_code": 62
  },
  {
    "sub_district": "เพชรชมภู",
    "district": "โกสัมพีนคร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 621102,
    "district_code": 6211,
    "province_code": 62
  },
  {
    "sub_district": "ลานดอกไม้ตก",
    "district": "โกสัมพีนคร",
    "province": "กำแพงเพชร",
    "zipcode": 62000,
    "sub_district_code": 621103,
    "district_code": 6211,
    "province_code": 62
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400101,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "สำราญ",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400102,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "โคกสี",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400103,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "ท่าพระ",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40260,
    "sub_district_code": 400104,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "บ้านทุ่ม",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400105,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "เมืองเก่า",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400106,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "พระลับ",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400107,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "สาวะถี",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400108,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "บ้านหว้า",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400109,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "บ้านค้อ",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400110,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "แดงใหญ่",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400111,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "ดอนช้าง",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400112,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "ดอนหัน",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40260,
    "sub_district_code": 400113,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "ศิลา",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400114,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "บ้านเป็ด",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400115,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "บึงเนียม",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400117,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "หนองตูม",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400116,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "โนนท่อน",
    "district": "เมืองขอนแก่น",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 400118,
    "district_code": 4001,
    "province_code": 40
  },
  {
    "sub_district": "หนองโก",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400901,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "หนองกุงใหญ่",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400902,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "กระนวน",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400996,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "บ้านโนน",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400997,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "ห้วยโจด",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400905,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "ห้วยยาง",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400906,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "บ้านฝาง",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400907,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "คำแมด",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400998,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "ดูนสาด",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400909,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "หัวนาคำ",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400912,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "น้ำอ้อม",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400911,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "หนองโน",
    "district": "กระนวน",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 400910,
    "district_code": 4009,
    "province_code": 40
  },
  {
    "sub_district": "ชนบท",
    "district": "ชนบท",
    "province": "ขอนแก่น",
    "zipcode": 40180,
    "sub_district_code": 401801,
    "district_code": 4018,
    "province_code": 40
  },
  {
    "sub_district": "กุดเพียขอม",
    "district": "ชนบท",
    "province": "ขอนแก่น",
    "zipcode": 40180,
    "sub_district_code": 401802,
    "district_code": 4018,
    "province_code": 40
  },
  {
    "sub_district": "วังแสง",
    "district": "ชนบท",
    "province": "ขอนแก่น",
    "zipcode": 40180,
    "sub_district_code": 401803,
    "district_code": 4018,
    "province_code": 40
  },
  {
    "sub_district": "ห้วยแก",
    "district": "ชนบท",
    "province": "ขอนแก่น",
    "zipcode": 40180,
    "sub_district_code": 401804,
    "district_code": 4018,
    "province_code": 40
  },
  {
    "sub_district": "บ้านแท่น",
    "district": "ชนบท",
    "province": "ขอนแก่น",
    "zipcode": 40180,
    "sub_district_code": 401805,
    "district_code": 4018,
    "province_code": 40
  },
  {
    "sub_district": "ศรีบุญเรือง",
    "district": "ชนบท",
    "province": "ขอนแก่น",
    "zipcode": 40180,
    "sub_district_code": 401806,
    "district_code": 4018,
    "province_code": 40
  },
  {
    "sub_district": "บ้านแฮด",
    "district": "ชนบท",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401898,
    "district_code": 4018,
    "province_code": 40
  },
  {
    "sub_district": "โนนพะยอม",
    "district": "ชนบท",
    "province": "ขอนแก่น",
    "zipcode": 40180,
    "sub_district_code": 401807,
    "district_code": 4018,
    "province_code": 40
  },
  {
    "sub_district": "ปอแดง",
    "district": "ชนบท",
    "province": "ขอนแก่น",
    "zipcode": 40180,
    "sub_district_code": 401808,
    "district_code": 4018,
    "province_code": 40
  },
  {
    "sub_district": "ชุมแพ",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40130,
    "sub_district_code": 400501,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "โนนหัน",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40290,
    "sub_district_code": 400502,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "นาหนองทุ่ม",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40290,
    "sub_district_code": 400503,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "วังหินลาด",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40130,
    "sub_district_code": 400508,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "โนนอุดม",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40130,
    "sub_district_code": 400504,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "ขัวเรียง",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40130,
    "sub_district_code": 400505,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "หนองไผ่",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40130,
    "sub_district_code": 400506,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "นาเพียง",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40130,
    "sub_district_code": 400509,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "ไชยสอ",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40130,
    "sub_district_code": 400507,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "ศรีสุข",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400598,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "หนองเขียด",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40290,
    "sub_district_code": 400510,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "โนนสะอาด",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40290,
    "sub_district_code": 400512,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "หนองเสาเล้า",
    "district": "ชุมแพ",
    "province": "ขอนแก่น",
    "zipcode": 40130,
    "sub_district_code": 400511,
    "district_code": 4005,
    "province_code": 40
  },
  {
    "sub_district": "น้ำพอง",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40140,
    "sub_district_code": 400701,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "วังชัย",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40140,
    "sub_district_code": 400702,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "หนองกุง",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40140,
    "sub_district_code": 400703,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "บัวใหญ่",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40140,
    "sub_district_code": 400704,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "สะอาด",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40310,
    "sub_district_code": 400705,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "ม่วงหวาน",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40310,
    "sub_district_code": 400706,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "บ้านขาม",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40140,
    "sub_district_code": 400707,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "บัวเงิน",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40140,
    "sub_district_code": 400708,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "ทรายมูล",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40140,
    "sub_district_code": 400709,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "ท่ากระเสริม",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40140,
    "sub_district_code": 400710,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "พังทุย",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40140,
    "sub_district_code": 400711,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "กุดน้ำใส",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40310,
    "sub_district_code": 400712,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "โนนอุดม",
    "district": "น้ำพอง",
    "province": "ขอนแก่น",
    "zipcode": 40130,
    "sub_district_code": 400797,
    "district_code": 4007,
    "province_code": 40
  },
  {
    "sub_district": "บ้านไผ่",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401001,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "ในเมือง",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401002,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "โคกสำราญ",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401091,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "เมืองเพีย",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401005,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "เปือยใหญ่",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401092,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "โนนศิลา",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401093,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "บ้านหัน",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401094,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "บ้านลาน",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401009,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "แคนเหนือ",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401010,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "ภูเหล็ก",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401011,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "หนองแซง",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401095,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "หินตั้ง",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401014,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "ป่าปอ",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401013,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "โนนสมบูรณ์",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401096,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "หนองน้ำใส",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401016,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "หัวหนอง",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401017,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "บ้านแฮด",
    "district": "บ้านไผ่",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 401097,
    "district_code": 4010,
    "province_code": 40
  },
  {
    "sub_district": "เมืองพล",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401201,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "โจดหนองแก",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401203,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "เก่างิ้ว",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401204,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "หนองมะเขือ",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401205,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "หนองแวงโสกพระ",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401206,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "เพ็กใหญ่",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401207,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "โคกสง่า",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401208,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "หนองแวงนางเบ้า",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401209,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "ลอมคอม",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401210,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "โนนข่า",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401211,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "หัวทุ่ง",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401213,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "โสกนกเต็น",
    "district": "พล",
    "province": "ขอนแก่น",
    "zipcode": 40120,
    "sub_district_code": 401212,
    "district_code": 4012,
    "province_code": 40
  },
  {
    "sub_district": "บ้านเรือ",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401601,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "ในเมือง",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40000,
    "sub_district_code": 401692,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "บ้านโคก",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401693,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "หว้าทอง",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401604,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "กุดขอนแก่น",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401605,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "นาชุมแสง",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401606,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "นาหว้า",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401607,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "เขาน้อย",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401698,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "กุดธาตุ",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401694,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "หนองกุงธนสาร",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401610,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "ขนวน",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401695,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "หนองกุงเซิน",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401612,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "หนองเรือ",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40210,
    "sub_district_code": 401696,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "สงเปือย",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401613,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "ทุ่งชมพู",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401614,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "ภูเวียง",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401617,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "เมืองเก่าพัฒนา",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401697,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "ดินดำ",
    "district": "ภูเวียง",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 401616,
    "district_code": 4016,
    "province_code": 40
  },
  {
    "sub_district": "กุดเค้า",
    "district": "มัญจาคีรี",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401701,
    "district_code": 4017,
    "province_code": 40
  },
  {
    "sub_district": "สวนหม่อน",
    "district": "มัญจาคีรี",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401702,
    "district_code": 4017,
    "province_code": 40
  },
  {
    "sub_district": "หนองแปน",
    "district": "มัญจาคีรี",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401703,
    "district_code": 4017,
    "province_code": 40
  },
  {
    "sub_district": "โพนเพ็ก",
    "district": "มัญจาคีรี",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401704,
    "district_code": 4017,
    "province_code": 40
  },
  {
    "sub_district": "คำแคน",
    "district": "มัญจาคีรี",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401705,
    "district_code": 4017,
    "province_code": 40
  },
  {
    "sub_district": "นาข่า",
    "district": "มัญจาคีรี",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401706,
    "district_code": 4017,
    "province_code": 40
  },
  {
    "sub_district": "นางาม",
    "district": "มัญจาคีรี",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401707,
    "district_code": 4017,
    "province_code": 40
  },
  {
    "sub_district": "บ้านโคก",
    "district": "มัญจาคีรี",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401797,
    "district_code": 4017,
    "province_code": 40
  },
  {
    "sub_district": "โพธิ์ไชย",
    "district": "มัญจาคีรี",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401798,
    "district_code": 4017,
    "province_code": 40
  },
  {
    "sub_district": "ท่าศาลา",
    "district": "มัญจาคีรี",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 401710,
    "district_code": 4017,
    "province_code": 40
  },
  {
    "sub_district": "แวงน้อย",
    "district": "แวงน้อย",
    "province": "ขอนแก่น",
    "zipcode": 40230,
    "sub_district_code": 401401,
    "district_code": 4014,
    "province_code": 40
  },
  {
    "sub_district": "ก้านเหลือง",
    "district": "แวงน้อย",
    "province": "ขอนแก่น",
    "zipcode": 40230,
    "sub_district_code": 401402,
    "district_code": 4014,
    "province_code": 40
  },
  {
    "sub_district": "ท่านางแนว",
    "district": "แวงน้อย",
    "province": "ขอนแก่น",
    "zipcode": 40230,
    "sub_district_code": 401403,
    "district_code": 4014,
    "province_code": 40
  },
  {
    "sub_district": "ละหานนา",
    "district": "แวงน้อย",
    "province": "ขอนแก่น",
    "zipcode": 40230,
    "sub_district_code": 401404,
    "district_code": 4014,
    "province_code": 40
  },
  {
    "sub_district": "ท่าวัด",
    "district": "แวงน้อย",
    "province": "ขอนแก่น",
    "zipcode": 40230,
    "sub_district_code": 401405,
    "district_code": 4014,
    "province_code": 40
  },
  {
    "sub_district": "ทางขวาง",
    "district": "แวงน้อย",
    "province": "ขอนแก่น",
    "zipcode": 40230,
    "sub_district_code": 401406,
    "district_code": 4014,
    "province_code": 40
  },
  {
    "sub_district": "สีชมพู",
    "district": "สีชมพู",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400601,
    "district_code": 4006,
    "province_code": 40
  },
  {
    "sub_district": "ศรีสุข",
    "district": "สีชมพู",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400602,
    "district_code": 4006,
    "province_code": 40
  },
  {
    "sub_district": "นาจาน",
    "district": "สีชมพู",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400603,
    "district_code": 4006,
    "province_code": 40
  },
  {
    "sub_district": "วังเพิ่ม",
    "district": "สีชมพู",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400604,
    "district_code": 4006,
    "province_code": 40
  },
  {
    "sub_district": "ซำยาง",
    "district": "สีชมพู",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400605,
    "district_code": 4006,
    "province_code": 40
  },
  {
    "sub_district": "หนองแดง",
    "district": "สีชมพู",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400606,
    "district_code": 4006,
    "province_code": 40
  },
  {
    "sub_district": "บริบูรณ์",
    "district": "สีชมพู",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400608,
    "district_code": 4006,
    "province_code": 40
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "สีชมพู",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400609,
    "district_code": 4006,
    "province_code": 40
  },
  {
    "sub_district": "ดงลาน",
    "district": "สีชมพู",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400607,
    "district_code": 4006,
    "province_code": 40
  },
  {
    "sub_district": "ภูห่าน",
    "district": "สีชมพู",
    "province": "ขอนแก่น",
    "zipcode": 40220,
    "sub_district_code": 400610,
    "district_code": 4006,
    "province_code": 40
  },
  {
    "sub_district": "หนองเรือ",
    "district": "หนองเรือ",
    "province": "ขอนแก่น",
    "zipcode": 40210,
    "sub_district_code": 400401,
    "district_code": 4004,
    "province_code": 40
  },
  {
    "sub_district": "บ้านเม็ง",
    "district": "หนองเรือ",
    "province": "ขอนแก่น",
    "zipcode": 40210,
    "sub_district_code": 400402,
    "district_code": 4004,
    "province_code": 40
  },
  {
    "sub_district": "บ้านกง",
    "district": "หนองเรือ",
    "province": "ขอนแก่น",
    "zipcode": 40240,
    "sub_district_code": 400403,
    "district_code": 4004,
    "province_code": 40
  },
  {
    "sub_district": "ยางคำ",
    "district": "หนองเรือ",
    "province": "ขอนแก่น",
    "zipcode": 40240,
    "sub_district_code": 400404,
    "district_code": 4004,
    "province_code": 40
  },
  {
    "sub_district": "จระเข้",
    "district": "หนองเรือ",
    "province": "ขอนแก่น",
    "zipcode": 40240,
    "sub_district_code": 400405,
    "district_code": 4004,
    "province_code": 40
  },
  {
    "sub_district": "โนนทอง",
    "district": "หนองเรือ",
    "province": "ขอนแก่น",
    "zipcode": 40210,
    "sub_district_code": 400406,
    "district_code": 4004,
    "province_code": 40
  },
  {
    "sub_district": "กุดกว้าง",
    "district": "หนองเรือ",
    "province": "ขอนแก่น",
    "zipcode": 40210,
    "sub_district_code": 400407,
    "district_code": 4004,
    "province_code": 40
  },
  {
    "sub_district": "โนนทัน",
    "district": "หนองเรือ",
    "province": "ขอนแก่น",
    "zipcode": 40210,
    "sub_district_code": 400408,
    "district_code": 4004,
    "province_code": 40
  },
  {
    "sub_district": "โนนสะอาด",
    "district": "หนองเรือ",
    "province": "ขอนแก่น",
    "zipcode": 40210,
    "sub_district_code": 400409,
    "district_code": 4004,
    "province_code": 40
  },
  {
    "sub_district": "บ้านผือ",
    "district": "หนองเรือ",
    "province": "ขอนแก่น",
    "zipcode": 40240,
    "sub_district_code": 400410,
    "district_code": 4004,
    "province_code": 40
  },
  {
    "sub_district": "หนองสองห้อง",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401501,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "คึมชาด",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401502,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "โนนธาตุ",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401503,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "ตะกั่วป่า",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401504,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "สำโรง",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401505,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "หนองเม็ก",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401506,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "ดอนดู่",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401507,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "ดงเค็ง",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401508,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "หนองไผ่ล้อม",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401512,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "ดอนดั่ง",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401510,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "วังหิน",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401511,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "หันโจด",
    "district": "หนองสองห้อง",
    "province": "ขอนแก่น",
    "zipcode": 40190,
    "sub_district_code": 401509,
    "district_code": 4015,
    "province_code": 40
  },
  {
    "sub_district": "โคกสูง",
    "district": "อุบลรัตน์",
    "province": "ขอนแก่น",
    "zipcode": 40250,
    "sub_district_code": 400801,
    "district_code": 4008,
    "province_code": 40
  },
  {
    "sub_district": "บ้านดง",
    "district": "อุบลรัตน์",
    "province": "ขอนแก่น",
    "zipcode": 40250,
    "sub_district_code": 400802,
    "district_code": 4008,
    "province_code": 40
  },
  {
    "sub_district": "เขื่อนอุบลรัตน์",
    "district": "อุบลรัตน์",
    "province": "ขอนแก่น",
    "zipcode": 40250,
    "sub_district_code": 400803,
    "district_code": 4008,
    "province_code": 40
  },
  {
    "sub_district": "นาคำ",
    "district": "อุบลรัตน์",
    "province": "ขอนแก่น",
    "zipcode": 40250,
    "sub_district_code": 400804,
    "district_code": 4008,
    "province_code": 40
  },
  {
    "sub_district": "ศรีสุขสำราญ",
    "district": "อุบลรัตน์",
    "province": "ขอนแก่น",
    "zipcode": 40250,
    "sub_district_code": 400805,
    "district_code": 4008,
    "province_code": 40
  },
  {
    "sub_district": "ทุ่งโป่ง",
    "district": "อุบลรัตน์",
    "province": "ขอนแก่น",
    "zipcode": 40250,
    "sub_district_code": 400806,
    "district_code": 4008,
    "province_code": 40
  },
  {
    "sub_district": "หนองบัว",
    "district": "บ้านฝาง",
    "province": "ขอนแก่น",
    "zipcode": 40270,
    "sub_district_code": 400201,
    "district_code": 4002,
    "province_code": 40
  },
  {
    "sub_district": "ป่าหวายนั่ง",
    "district": "บ้านฝาง",
    "province": "ขอนแก่น",
    "zipcode": 40270,
    "sub_district_code": 400202,
    "district_code": 4002,
    "province_code": 40
  },
  {
    "sub_district": "โนนฆ้อง",
    "district": "บ้านฝาง",
    "province": "ขอนแก่น",
    "zipcode": 40270,
    "sub_district_code": 400203,
    "district_code": 4002,
    "province_code": 40
  },
  {
    "sub_district": "บ้านเหล่า",
    "district": "บ้านฝาง",
    "province": "ขอนแก่น",
    "zipcode": 40270,
    "sub_district_code": 400204,
    "district_code": 4002,
    "province_code": 40
  },
  {
    "sub_district": "ป่ามะนาว",
    "district": "บ้านฝาง",
    "province": "ขอนแก่น",
    "zipcode": 40270,
    "sub_district_code": 400205,
    "district_code": 4002,
    "province_code": 40
  },
  {
    "sub_district": "บ้านฝาง",
    "district": "บ้านฝาง",
    "province": "ขอนแก่น",
    "zipcode": 40270,
    "sub_district_code": 400206,
    "district_code": 4002,
    "province_code": 40
  },
  {
    "sub_district": "โคกงาม",
    "district": "บ้านฝาง",
    "province": "ขอนแก่น",
    "zipcode": 40270,
    "sub_district_code": 400207,
    "district_code": 4002,
    "province_code": 40
  },
  {
    "sub_district": "พระยืน",
    "district": "พระยืน",
    "province": "ขอนแก่น",
    "zipcode": 40320,
    "sub_district_code": 400301,
    "district_code": 4003,
    "province_code": 40
  },
  {
    "sub_district": "พระบุ",
    "district": "พระยืน",
    "province": "ขอนแก่น",
    "zipcode": 40320,
    "sub_district_code": 400302,
    "district_code": 4003,
    "province_code": 40
  },
  {
    "sub_district": "บ้านโต้น",
    "district": "พระยืน",
    "province": "ขอนแก่น",
    "zipcode": 40320,
    "sub_district_code": 400303,
    "district_code": 4003,
    "province_code": 40
  },
  {
    "sub_district": "หนองแวง",
    "district": "พระยืน",
    "province": "ขอนแก่น",
    "zipcode": 40320,
    "sub_district_code": 400304,
    "district_code": 4003,
    "province_code": 40
  },
  {
    "sub_district": "ขามป้อม",
    "district": "พระยืน",
    "province": "ขอนแก่น",
    "zipcode": 40320,
    "sub_district_code": 400305,
    "district_code": 4003,
    "province_code": 40
  },
  {
    "sub_district": "คอนฉิม",
    "district": "แวงใหญ่",
    "province": "ขอนแก่น",
    "zipcode": 40330,
    "sub_district_code": 401301,
    "district_code": 4013,
    "province_code": 40
  },
  {
    "sub_district": "ใหม่นาเพียง",
    "district": "แวงใหญ่",
    "province": "ขอนแก่น",
    "zipcode": 40330,
    "sub_district_code": 401302,
    "district_code": 4013,
    "province_code": 40
  },
  {
    "sub_district": "โนนทอง",
    "district": "แวงใหญ่",
    "province": "ขอนแก่น",
    "zipcode": 40330,
    "sub_district_code": 401303,
    "district_code": 4013,
    "province_code": 40
  },
  {
    "sub_district": "แวงใหญ่",
    "district": "แวงใหญ่",
    "province": "ขอนแก่น",
    "zipcode": 40330,
    "sub_district_code": 401304,
    "district_code": 4013,
    "province_code": 40
  },
  {
    "sub_district": "โนนสะอาด",
    "district": "แวงใหญ่",
    "province": "ขอนแก่น",
    "zipcode": 40330,
    "sub_district_code": 401305,
    "district_code": 4013,
    "province_code": 40
  },
  {
    "sub_district": "เปือยน้อย",
    "district": "เปือยน้อย",
    "province": "ขอนแก่น",
    "zipcode": 40340,
    "sub_district_code": 401101,
    "district_code": 4011,
    "province_code": 40
  },
  {
    "sub_district": "วังม่วง",
    "district": "เปือยน้อย",
    "province": "ขอนแก่น",
    "zipcode": 40340,
    "sub_district_code": 401102,
    "district_code": 4011,
    "province_code": 40
  },
  {
    "sub_district": "ขามป้อม",
    "district": "เปือยน้อย",
    "province": "ขอนแก่น",
    "zipcode": 40340,
    "sub_district_code": 401103,
    "district_code": 4011,
    "province_code": 40
  },
  {
    "sub_district": "สระแก้ว",
    "district": "เปือยน้อย",
    "province": "ขอนแก่น",
    "zipcode": 40340,
    "sub_district_code": 401104,
    "district_code": 4011,
    "province_code": 40
  },
  {
    "sub_district": "เขาสวนกวาง",
    "district": "เขาสวนกวาง",
    "province": "ขอนแก่น",
    "zipcode": 40280,
    "sub_district_code": 401901,
    "district_code": 4019,
    "province_code": 40
  },
  {
    "sub_district": "ดงเมืองแอม",
    "district": "เขาสวนกวาง",
    "province": "ขอนแก่น",
    "zipcode": 40280,
    "sub_district_code": 401902,
    "district_code": 4019,
    "province_code": 40
  },
  {
    "sub_district": "นางิ้ว",
    "district": "เขาสวนกวาง",
    "province": "ขอนแก่น",
    "zipcode": 40280,
    "sub_district_code": 401903,
    "district_code": 4019,
    "province_code": 40
  },
  {
    "sub_district": "โนนสมบูรณ์",
    "district": "เขาสวนกวาง",
    "province": "ขอนแก่น",
    "zipcode": 40280,
    "sub_district_code": 401904,
    "district_code": 4019,
    "province_code": 40
  },
  {
    "sub_district": "คำม่วง",
    "district": "เขาสวนกวาง",
    "province": "ขอนแก่น",
    "zipcode": 40280,
    "sub_district_code": 401905,
    "district_code": 4019,
    "province_code": 40
  },
  {
    "sub_district": "โนนคอม",
    "district": "ภูผาม่าน",
    "province": "ขอนแก่น",
    "zipcode": 40350,
    "sub_district_code": 402001,
    "district_code": 4020,
    "province_code": 40
  },
  {
    "sub_district": "นาฝาย",
    "district": "ภูผาม่าน",
    "province": "ขอนแก่น",
    "zipcode": 40350,
    "sub_district_code": 402002,
    "district_code": 4020,
    "province_code": 40
  },
  {
    "sub_district": "ภูผาม่าน",
    "district": "ภูผาม่าน",
    "province": "ขอนแก่น",
    "zipcode": 40350,
    "sub_district_code": 402003,
    "district_code": 4020,
    "province_code": 40
  },
  {
    "sub_district": "วังสวาบ",
    "district": "ภูผาม่าน",
    "province": "ขอนแก่น",
    "zipcode": 40350,
    "sub_district_code": 402004,
    "district_code": 4020,
    "province_code": 40
  },
  {
    "sub_district": "ห้วยม่วง",
    "district": "ภูผาม่าน",
    "province": "ขอนแก่น",
    "zipcode": 40350,
    "sub_district_code": 402005,
    "district_code": 4020,
    "province_code": 40
  },
  {
    "sub_district": "บ้านโคก",
    "district": "หนองนาคำ",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 402302,
    "district_code": 4023,
    "province_code": 40
  },
  {
    "sub_district": "ขนวน",
    "district": "หนองนาคำ",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 402303,
    "district_code": 4023,
    "province_code": 40
  },
  {
    "sub_district": "กุดธาตุ",
    "district": "หนองนาคำ",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 402301,
    "district_code": 4023,
    "province_code": 40
  },
  {
    "sub_district": "นาแพง",
    "district": "โคกโพธิ์ชัย",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 409804,
    "district_code": 4098,
    "province_code": 40
  },
  {
    "sub_district": "นาแพง",
    "district": "โคกโพธิ์ไชย",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 402204,
    "district_code": 4022,
    "province_code": 40
  },
  {
    "sub_district": "บ้านโคก",
    "district": "โคกโพธิ์ไชย",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 402201,
    "district_code": 4022,
    "province_code": 40
  },
  {
    "sub_district": "บ้านโคก",
    "district": "โคกโพธิ์ชัย",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 409801,
    "district_code": 4098,
    "province_code": 40
  },
  {
    "sub_district": "โพธิ์ไชย",
    "district": "โคกโพธิ์ชัย",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 409802,
    "district_code": 4098,
    "province_code": 40
  },
  {
    "sub_district": "โพธิ์ไชย",
    "district": "โคกโพธิ์ไชย",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 402202,
    "district_code": 4022,
    "province_code": 40
  },
  {
    "sub_district": "ซับสมบูรณ์",
    "district": "โคกโพธิ์ไชย",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 402203,
    "district_code": 4022,
    "province_code": 40
  },
  {
    "sub_district": "ซับสมบูรณ์",
    "district": "โคกโพธิ์ชัย",
    "province": "ขอนแก่น",
    "zipcode": 40160,
    "sub_district_code": 409803,
    "district_code": 4098,
    "province_code": 40
  },
  {
    "sub_district": "บ้านแฮด",
    "district": "บ้านแฮด",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402401,
    "district_code": 4024,
    "province_code": 40
  },
  {
    "sub_district": "โนนสมบูรณ์",
    "district": "บ้านแฮด",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402403,
    "district_code": 4024,
    "province_code": 40
  },
  {
    "sub_district": "หนองแซง",
    "district": "บ้านแฮด",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402404,
    "district_code": 4024,
    "province_code": 40
  },
  {
    "sub_district": "โคกสำราญ",
    "district": "บ้านแฮด",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402402,
    "district_code": 4024,
    "province_code": 40
  },
  {
    "sub_district": "บ้านแฮด",
    "district": "บ้านแฮด",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402401,
    "district_code": 4024,
    "province_code": 40
  },
  {
    "sub_district": "โนนสมบูรณ์",
    "district": "บ้านแฮด",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402403,
    "district_code": 4024,
    "province_code": 40
  },
  {
    "sub_district": "โนนศิลา",
    "district": "โนนศิลา",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402501,
    "district_code": 4025,
    "province_code": 40
  },
  {
    "sub_district": "โนนแดง",
    "district": "โนนศิลา",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402505,
    "district_code": 4025,
    "province_code": 40
  },
  {
    "sub_district": "บ้านหัน",
    "district": "โนนศิลา",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402503,
    "district_code": 4025,
    "province_code": 40
  },
  {
    "sub_district": "เปือยใหญ่",
    "district": "โนนศิลา",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402504,
    "district_code": 4025,
    "province_code": 40
  },
  {
    "sub_district": "หนองปลาหมอ",
    "district": "โนนศิลา",
    "province": "ขอนแก่น",
    "zipcode": 40110,
    "sub_district_code": 402502,
    "district_code": 4025,
    "province_code": 40
  },
  {
    "sub_district": "คำแมด",
    "district": "ซำสูง",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 402102,
    "district_code": 4021,
    "province_code": 40
  },
  {
    "sub_district": "บ้านโนน",
    "district": "ซำสูง",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 402103,
    "district_code": 4021,
    "province_code": 40
  },
  {
    "sub_district": "กระนวน",
    "district": "ซำสูง",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 402101,
    "district_code": 4021,
    "province_code": 40
  },
  {
    "sub_district": "คูคำ",
    "district": "ซำสูง",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 402104,
    "district_code": 4021,
    "province_code": 40
  },
  {
    "sub_district": "ห้วยเตย",
    "district": "ซำสูง",
    "province": "ขอนแก่น",
    "zipcode": 40170,
    "sub_district_code": 402105,
    "district_code": 4021,
    "province_code": 40
  },
  {
    "sub_district": "ในเมือง",
    "district": "เวียงเก่า",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 402901,
    "district_code": 4029,
    "province_code": 40
  },
  {
    "sub_district": "เมืองเก่าพัฒนา",
    "district": "เวียงเก่า",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 402902,
    "district_code": 4029,
    "province_code": 40
  },
  {
    "sub_district": "เขาน้อย",
    "district": "เวียงเก่า",
    "province": "ขอนแก่น",
    "zipcode": 40150,
    "sub_district_code": 402903,
    "district_code": 4029,
    "province_code": 40
  },
  {
    "sub_district": "ตลาด",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220101,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "วัดใหม่",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220102,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "คลองนารายณ์",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220103,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "เกาะขวาง",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220104,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "คมบาง",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220105,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220106,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "จันทนิมิต",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220107,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "บางกะจะ",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220108,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "แสลง",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220109,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "หนองบัว",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220110,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "พลับพลา",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22000,
    "sub_district_code": 220111,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "คลองน้ำเค็ม",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22190,
    "sub_district_code": 220194,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "พลอยแหวน",
    "district": "เมืองจันทบุรี",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220198,
    "district_code": 2201,
    "province_code": 22
  },
  {
    "sub_district": "ขลุง",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220201,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "บ่อ",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220202,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "เกวียนหัก",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220203,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "ตะปอน",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220204,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "บางชัน",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220205,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "วันยาว",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220206,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "ซึ้ง",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220207,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "มาบไพ",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220208,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "วังสรรพรส",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220209,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "ตรอกนอง",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220210,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "ตกพรม",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22110,
    "sub_district_code": 220211,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "บ่อเวฬุ",
    "district": "ขลุง",
    "province": "จันทบุรี",
    "zipcode": 22150,
    "sub_district_code": 220212,
    "district_code": 2202,
    "province_code": 22
  },
  {
    "sub_district": "ท่าใหม่",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220301,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "ยายร้า",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220302,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "สีพยา",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220303,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "บ่อพุ",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220304,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "พลอยแหวน",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220305,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "เขาวัว",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220306,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "เขาบายศรี",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220307,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "สองพี่น้อง",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220308,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "ทุ่งเบญจา",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220309,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "วังโตนด",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220390,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "รำพัน",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220311,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "โขมง",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220312,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "ตะกาดเง้า",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220313,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "คลองขุด",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220314,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "กระแจะ",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220391,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "สนามไชย",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220392,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "ช้างข้าม",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220393,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "นายายอาม",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220394,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "แก่งหางแมว",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220395,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "สามพี่น้อง",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220396,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "เขาวงกต",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220397,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "บางกะไชย",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220398,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "เขาแก้ว",
    "district": "ท่าใหม่",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220324,
    "district_code": 2203,
    "province_code": 22
  },
  {
    "sub_district": "ทับไทร",
    "district": "โป่งน้ำร้อน",
    "province": "จันทบุรี",
    "zipcode": 22140,
    "sub_district_code": 220401,
    "district_code": 2204,
    "province_code": 22
  },
  {
    "sub_district": "โป่งน้ำร้อน",
    "district": "โป่งน้ำร้อน",
    "province": "จันทบุรี",
    "zipcode": 22140,
    "sub_district_code": 220402,
    "district_code": 2204,
    "province_code": 22
  },
  {
    "sub_district": "ทรายขาว",
    "district": "โป่งน้ำร้อน",
    "province": "จันทบุรี",
    "zipcode": 22180,
    "sub_district_code": 220495,
    "district_code": 2204,
    "province_code": 22
  },
  {
    "sub_district": "หนองตาคง",
    "district": "โป่งน้ำร้อน",
    "province": "จันทบุรี",
    "zipcode": 22140,
    "sub_district_code": 220404,
    "district_code": 2204,
    "province_code": 22
  },
  {
    "sub_district": "ปะตง",
    "district": "โป่งน้ำร้อน",
    "province": "จันทบุรี",
    "zipcode": 22180,
    "sub_district_code": 220496,
    "district_code": 2204,
    "province_code": 22
  },
  {
    "sub_district": "ทับช้าง",
    "district": "โป่งน้ำร้อน",
    "province": "จันทบุรี",
    "zipcode": 22180,
    "sub_district_code": 220497,
    "district_code": 2204,
    "province_code": 22
  },
  {
    "sub_district": "สะตอน",
    "district": "โป่งน้ำร้อน",
    "province": "จันทบุรี",
    "zipcode": 22180,
    "sub_district_code": 220498,
    "district_code": 2204,
    "province_code": 22
  },
  {
    "sub_district": "เทพนิมิต",
    "district": "โป่งน้ำร้อน",
    "province": "จันทบุรี",
    "zipcode": 22140,
    "sub_district_code": 220409,
    "district_code": 2204,
    "province_code": 22
  },
  {
    "sub_district": "คลองใหญ่",
    "district": "โป่งน้ำร้อน",
    "province": "จันทบุรี",
    "zipcode": 22140,
    "sub_district_code": 220410,
    "district_code": 2204,
    "province_code": 22
  },
  {
    "sub_district": "มะขาม",
    "district": "มะขาม",
    "province": "จันทบุรี",
    "zipcode": 22150,
    "sub_district_code": 220501,
    "district_code": 2205,
    "province_code": 22
  },
  {
    "sub_district": "ท่าหลวง",
    "district": "มะขาม",
    "province": "จันทบุรี",
    "zipcode": 22150,
    "sub_district_code": 220502,
    "district_code": 2205,
    "province_code": 22
  },
  {
    "sub_district": "ปัถวี",
    "district": "มะขาม",
    "province": "จันทบุรี",
    "zipcode": 22150,
    "sub_district_code": 220503,
    "district_code": 2205,
    "province_code": 22
  },
  {
    "sub_district": "วังแซ้ม",
    "district": "มะขาม",
    "province": "จันทบุรี",
    "zipcode": 22150,
    "sub_district_code": 220504,
    "district_code": 2205,
    "province_code": 22
  },
  {
    "sub_district": "พลวง",
    "district": "มะขาม",
    "province": "จันทบุรี",
    "zipcode": 22210,
    "sub_district_code": 220596,
    "district_code": 2205,
    "province_code": 22
  },
  {
    "sub_district": "ฉมัน",
    "district": "มะขาม",
    "province": "จันทบุรี",
    "zipcode": 22150,
    "sub_district_code": 220506,
    "district_code": 2205,
    "province_code": 22
  },
  {
    "sub_district": "ตะเคียนทอง",
    "district": "มะขาม",
    "province": "จันทบุรี",
    "zipcode": 22210,
    "sub_district_code": 220597,
    "district_code": 2205,
    "province_code": 22
  },
  {
    "sub_district": "อ่างคีรี",
    "district": "มะขาม",
    "province": "จันทบุรี",
    "zipcode": 22150,
    "sub_district_code": 220508,
    "district_code": 2205,
    "province_code": 22
  },
  {
    "sub_district": "คลองพลู",
    "district": "มะขาม",
    "province": "จันทบุรี",
    "zipcode": 22210,
    "sub_district_code": 220598,
    "district_code": 2205,
    "province_code": 22
  },
  {
    "sub_district": "ปากน้ำแหลมสิงห์",
    "district": "แหลมสิงห์",
    "province": "จันทบุรี",
    "zipcode": 22130,
    "sub_district_code": 220601,
    "district_code": 2206,
    "province_code": 22
  },
  {
    "sub_district": "เกาะเปริด",
    "district": "แหลมสิงห์",
    "province": "จันทบุรี",
    "zipcode": 22130,
    "sub_district_code": 220602,
    "district_code": 2206,
    "province_code": 22
  },
  {
    "sub_district": "หนองชิ่ม",
    "district": "แหลมสิงห์",
    "province": "จันทบุรี",
    "zipcode": 22130,
    "sub_district_code": 220603,
    "district_code": 2206,
    "province_code": 22
  },
  {
    "sub_district": "พลิ้ว",
    "district": "แหลมสิงห์",
    "province": "จันทบุรี",
    "zipcode": 22190,
    "sub_district_code": 220604,
    "district_code": 2206,
    "province_code": 22
  },
  {
    "sub_district": "คลองน้ำเค็ม",
    "district": "แหลมสิงห์",
    "province": "จันทบุรี",
    "zipcode": 22190,
    "sub_district_code": 220605,
    "district_code": 2206,
    "province_code": 22
  },
  {
    "sub_district": "บางสระเก้า",
    "district": "แหลมสิงห์",
    "province": "จันทบุรี",
    "zipcode": 22190,
    "sub_district_code": 220606,
    "district_code": 2206,
    "province_code": 22
  },
  {
    "sub_district": "บางกะไชย",
    "district": "แหลมสิงห์",
    "province": "จันทบุรี",
    "zipcode": 22120,
    "sub_district_code": 220607,
    "district_code": 2206,
    "province_code": 22
  },
  {
    "sub_district": "ปะตง",
    "district": "สอยดาว",
    "province": "จันทบุรี",
    "zipcode": 22180,
    "sub_district_code": 220701,
    "district_code": 2207,
    "province_code": 22
  },
  {
    "sub_district": "ทับช้าง",
    "district": "สอยดาว",
    "province": "จันทบุรี",
    "zipcode": 22180,
    "sub_district_code": 220703,
    "district_code": 2207,
    "province_code": 22
  },
  {
    "sub_district": "ทรายขาว",
    "district": "สอยดาว",
    "province": "จันทบุรี",
    "zipcode": 22180,
    "sub_district_code": 220704,
    "district_code": 2207,
    "province_code": 22
  },
  {
    "sub_district": "ทุ่งขนาน",
    "district": "สอยดาว",
    "province": "จันทบุรี",
    "zipcode": 22180,
    "sub_district_code": 220702,
    "district_code": 2207,
    "province_code": 22
  },
  {
    "sub_district": "สะตอน",
    "district": "สอยดาว",
    "province": "จันทบุรี",
    "zipcode": 22180,
    "sub_district_code": 220705,
    "district_code": 2207,
    "province_code": 22
  },
  {
    "sub_district": "ขุนซ่อง",
    "district": "แก่งหางแมว",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220802,
    "district_code": 2208,
    "province_code": 22
  },
  {
    "sub_district": "เขาวงกต",
    "district": "แก่งหางแมว",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220805,
    "district_code": 2208,
    "province_code": 22
  },
  {
    "sub_district": "สามพี่น้อง",
    "district": "แก่งหางแมว",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220803,
    "district_code": 2208,
    "province_code": 22
  },
  {
    "sub_district": "แก่งหางแมว",
    "district": "แก่งหางแมว",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220801,
    "district_code": 2208,
    "province_code": 22
  },
  {
    "sub_district": "พวา",
    "district": "แก่งหางแมว",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220804,
    "district_code": 2208,
    "province_code": 22
  },
  {
    "sub_district": "กระแจะ",
    "district": "เขาคิชฌกูฏ",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 221095,
    "district_code": 2210,
    "province_code": 22
  },
  {
    "sub_district": "นายายอาม",
    "district": "เขาคิชฌกูฏ",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 221096,
    "district_code": 2210,
    "province_code": 22
  },
  {
    "sub_district": "ช้างข้าม",
    "district": "เขาคิชฌกูฏ",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 221097,
    "district_code": 2210,
    "province_code": 22
  },
  {
    "sub_district": "สนามไชย",
    "district": "เขาคิชฌกูฏ",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 221098,
    "district_code": 2210,
    "province_code": 22
  },
  {
    "sub_district": "ชากไทย",
    "district": "เขาคิชฌกูฏ",
    "province": "จันทบุรี",
    "zipcode": 22210,
    "sub_district_code": 221001,
    "district_code": 2210,
    "province_code": 22
  },
  {
    "sub_district": "คลองพลู",
    "district": "เขาคิชฌกูฏ",
    "province": "จันทบุรี",
    "zipcode": 22210,
    "sub_district_code": 221004,
    "district_code": 2210,
    "province_code": 22
  },
  {
    "sub_district": "พลวง",
    "district": "เขาคิชฌกูฏ",
    "province": "จันทบุรี",
    "zipcode": 22210,
    "sub_district_code": 221002,
    "district_code": 2210,
    "province_code": 22
  },
  {
    "sub_district": "ตะเคียนทอง",
    "district": "เขาคิชฌกูฏ",
    "province": "จันทบุรี",
    "zipcode": 22210,
    "sub_district_code": 221003,
    "district_code": 2210,
    "province_code": 22
  },
  {
    "sub_district": "จันทเขลม",
    "district": "เขาคิชฌกูฏ",
    "province": "จันทบุรี",
    "zipcode": 22210,
    "sub_district_code": 221005,
    "district_code": 2210,
    "province_code": 22
  },
  {
    "sub_district": "วังโตนด",
    "district": "นายายอาม",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220902,
    "district_code": 2209,
    "province_code": 22
  },
  {
    "sub_district": "นายายอาม",
    "district": "นายายอาม",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220901,
    "district_code": 2209,
    "province_code": 22
  },
  {
    "sub_district": "กระแจะ",
    "district": "นายายอาม",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220903,
    "district_code": 2209,
    "province_code": 22
  },
  {
    "sub_district": "สนามไชย",
    "district": "นายายอาม",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220904,
    "district_code": 2209,
    "province_code": 22
  },
  {
    "sub_district": "ช้างข้าม",
    "district": "นายายอาม",
    "province": "จันทบุรี",
    "zipcode": 22160,
    "sub_district_code": 220905,
    "district_code": 2209,
    "province_code": 22
  },
  {
    "sub_district": "วังใหม่",
    "district": "นายายอาม",
    "province": "จันทบุรี",
    "zipcode": 22170,
    "sub_district_code": 220906,
    "district_code": 2209,
    "province_code": 22
  },
  {
    "sub_district": "หน้าเมือง",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240101,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "ท่าไข่",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240102,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240103,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "คลองนา",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240104,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "บางตีนเป็ด",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240105,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "บางไผ่",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240106,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "คลองจุกกระเฌอ",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240107,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "บางแก้ว",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240108,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "บางขวัญ",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240109,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "คลองนครเนื่องเขต",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240110,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "วังตะเคียน",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240111,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "โสธร",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240112,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "บางพระ",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240113,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "บางกะไห",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240114,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "หนามแดง",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240115,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "คลองเปรง",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240116,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "คลองอุดมชลจร",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240117,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "คลองหลวงแพ่ง",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240118,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "บางเตย",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240119,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "ท่าสะอ้าน",
    "district": "เมืองฉะเชิงเทรา",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240198,
    "district_code": 2401,
    "province_code": 24
  },
  {
    "sub_district": "บางคล้า",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 240201,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "ก้อนแก้ว",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240291,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "คลองเขื่อน",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240292,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "บางสวน",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 240204,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "บางเล่า",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240293,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "บางโรง",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240294,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "บางตลาด",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 240295,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "บางกระเจ็ด",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 240208,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "ปากน้ำ",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 240209,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "ท่าทองหลาง",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 240210,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "สาวชะโงก",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 240211,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "เสม็ดเหนือ",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 240212,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "เสม็ดใต้",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 240213,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "หัวไทร",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 240214,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "หัวสำโรง",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24190,
    "sub_district_code": 240296,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "แปลงยาว",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24190,
    "sub_district_code": 240297,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "แหลมประดู่",
    "district": "บางคล้า",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240298,
    "district_code": 2402,
    "province_code": 24
  },
  {
    "sub_district": "บางน้ำเปรี้ยว",
    "district": "บางน้ำเปรี้ยว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24150,
    "sub_district_code": 240301,
    "district_code": 2403,
    "province_code": 24
  },
  {
    "sub_district": "บางขนาก",
    "district": "บางน้ำเปรี้ยว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24150,
    "sub_district_code": 240302,
    "district_code": 2403,
    "province_code": 24
  },
  {
    "sub_district": "สิงโตทอง",
    "district": "บางน้ำเปรี้ยว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24150,
    "sub_district_code": 240303,
    "district_code": 2403,
    "province_code": 24
  },
  {
    "sub_district": "หมอนทอง",
    "district": "บางน้ำเปรี้ยว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24150,
    "sub_district_code": 240304,
    "district_code": 2403,
    "province_code": 24
  },
  {
    "sub_district": "บึงน้ำรักษ์",
    "district": "บางน้ำเปรี้ยว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24170,
    "sub_district_code": 240305,
    "district_code": 2403,
    "province_code": 24
  },
  {
    "sub_district": "ดอนเกาะกา",
    "district": "บางน้ำเปรี้ยว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24170,
    "sub_district_code": 240306,
    "district_code": 2403,
    "province_code": 24
  },
  {
    "sub_district": "โยธะกา",
    "district": "บางน้ำเปรี้ยว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24150,
    "sub_district_code": 240307,
    "district_code": 2403,
    "province_code": 24
  },
  {
    "sub_district": "ดอนฉิมพลี",
    "district": "บางน้ำเปรี้ยว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24170,
    "sub_district_code": 240308,
    "district_code": 2403,
    "province_code": 24
  },
  {
    "sub_district": "ศาลาแดง",
    "district": "บางน้ำเปรี้ยว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 240309,
    "district_code": 2403,
    "province_code": 24
  },
  {
    "sub_district": "โพรงอากาศ",
    "district": "บางน้ำเปรี้ยว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24150,
    "sub_district_code": 240310,
    "district_code": 2403,
    "province_code": 24
  },
  {
    "sub_district": "บางปะกง",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240401,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "ท่าสะอ้าน",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240402,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "บางวัว",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240403,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "บางสมัคร",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240404,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "บางผึ้ง",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240405,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "บางเกลือ",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240406,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "สองคลอง",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240407,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "หนองจอก",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240408,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "บางปะกงล่าง",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240413,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "พิมพา",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24180,
    "sub_district_code": 240409,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240410,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "หอมศีล",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240411,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "เขาดิน",
    "district": "บางปะกง",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24130,
    "sub_district_code": 240412,
    "district_code": 2404,
    "province_code": 24
  },
  {
    "sub_district": "บ้านโพธิ์",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240501,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "เกาะไร่",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240502,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "คลองขุด",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240503,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "คลองบ้านโพธิ์",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240504,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "คลองประเวศ",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240505,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "ดอนทราย",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240506,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "เทพราช",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240507,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "ท่าพลับ",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240508,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "หนองตีนนก",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240509,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "หนองบัว",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240510,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "บางซ่อน",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240511,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "บางกรูด",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240512,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "แหลมประดู่",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240513,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "ลาดขวาง",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240514,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "สนามจันทร์",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240515,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "แสนภูดาษ",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240516,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "สิบเอ็ดศอก",
    "district": "บ้านโพธิ์",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24140,
    "sub_district_code": 240517,
    "district_code": 2405,
    "province_code": 24
  },
  {
    "sub_district": "เกาะขนุน",
    "district": "พนมสารคาม",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240601,
    "district_code": 2406,
    "province_code": 24
  },
  {
    "sub_district": "บ้านซ่อง",
    "district": "พนมสารคาม",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240602,
    "district_code": 2406,
    "province_code": 24
  },
  {
    "sub_district": "พนมสารคาม",
    "district": "พนมสารคาม",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240603,
    "district_code": 2406,
    "province_code": 24
  },
  {
    "sub_district": "เมืองเก่า",
    "district": "พนมสารคาม",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240604,
    "district_code": 2406,
    "province_code": 24
  },
  {
    "sub_district": "หนองยาว",
    "district": "พนมสารคาม",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240605,
    "district_code": 2406,
    "province_code": 24
  },
  {
    "sub_district": "ท่าถ่าน",
    "district": "พนมสารคาม",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240606,
    "district_code": 2406,
    "province_code": 24
  },
  {
    "sub_district": "หนองแหน",
    "district": "พนมสารคาม",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240607,
    "district_code": 2406,
    "province_code": 24
  },
  {
    "sub_district": "เขาหินซ้อน",
    "district": "พนมสารคาม",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240608,
    "district_code": 2406,
    "province_code": 24
  },
  {
    "sub_district": "ดงน้อย",
    "district": "พนมสารคาม",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240698,
    "district_code": 2406,
    "province_code": 24
  },
  {
    "sub_district": "คู้ยายหมี",
    "district": "สนามชัยเขต",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24160,
    "sub_district_code": 240801,
    "district_code": 2408,
    "province_code": 24
  },
  {
    "sub_district": "ท่ากระดาน",
    "district": "สนามชัยเขต",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24160,
    "sub_district_code": 240802,
    "district_code": 2408,
    "province_code": 24
  },
  {
    "sub_district": "ทุ่งพระยา",
    "district": "สนามชัยเขต",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24160,
    "sub_district_code": 240803,
    "district_code": 2408,
    "province_code": 24
  },
  {
    "sub_district": "ท่าตะเกียบ",
    "district": "สนามชัยเขต",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24160,
    "sub_district_code": 240897,
    "district_code": 2408,
    "province_code": 24
  },
  {
    "sub_district": "ลาดกระทิง",
    "district": "สนามชัยเขต",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24160,
    "sub_district_code": 240805,
    "district_code": 2408,
    "province_code": 24
  },
  {
    "sub_district": "คลองตะเกรา",
    "district": "สนามชัยเขต",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24160,
    "sub_district_code": 240898,
    "district_code": 2408,
    "province_code": 24
  },
  {
    "sub_district": "บางคา",
    "district": "ราชสาส์น",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240701,
    "district_code": 2407,
    "province_code": 24
  },
  {
    "sub_district": "เมืองใหม่",
    "district": "ราชสาส์น",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240702,
    "district_code": 2407,
    "province_code": 24
  },
  {
    "sub_district": "ดงน้อย",
    "district": "ราชสาส์น",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24120,
    "sub_district_code": 240703,
    "district_code": 2407,
    "province_code": 24
  },
  {
    "sub_district": "แปลงยาว",
    "district": "แปลงยาว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24190,
    "sub_district_code": 240901,
    "district_code": 2409,
    "province_code": 24
  },
  {
    "sub_district": "วังเย็น",
    "district": "แปลงยาว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24190,
    "sub_district_code": 240902,
    "district_code": 2409,
    "province_code": 24
  },
  {
    "sub_district": "หัวสำโรง",
    "district": "แปลงยาว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24190,
    "sub_district_code": 240903,
    "district_code": 2409,
    "province_code": 24
  },
  {
    "sub_district": "หนองไม้แก่น",
    "district": "แปลงยาว",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24190,
    "sub_district_code": 240904,
    "district_code": 2409,
    "province_code": 24
  },
  {
    "sub_district": "คลองตะเกรา",
    "district": "ท่าตะเกียบ",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24160,
    "sub_district_code": 241002,
    "district_code": 2410,
    "province_code": 24
  },
  {
    "sub_district": "ท่าตะเกียบ",
    "district": "ท่าตะเกียบ",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24160,
    "sub_district_code": 241001,
    "district_code": 2410,
    "province_code": 24
  },
  {
    "sub_district": "คลองเขื่อน",
    "district": "คลองเขื่อน",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 241102,
    "district_code": 2411,
    "province_code": 24
  },
  {
    "sub_district": "บางเล่า",
    "district": "คลองเขื่อน",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 241103,
    "district_code": 2411,
    "province_code": 24
  },
  {
    "sub_district": "บางตลาด",
    "district": "คลองเขื่อน",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24110,
    "sub_district_code": 241105,
    "district_code": 2411,
    "province_code": 24
  },
  {
    "sub_district": "บางโรง",
    "district": "คลองเขื่อน",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 241104,
    "district_code": 2411,
    "province_code": 24
  },
  {
    "sub_district": "ก้อนแก้ว",
    "district": "คลองเขื่อน",
    "province": "ฉะเชิงเทรา",
    "zipcode": 24000,
    "sub_district_code": 241101,
    "district_code": 2411,
    "province_code": 24
  },
  {
    "sub_district": "บางปลาสร้อย",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200101,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "มะขามหย่ง",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200102,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "บ้านโขด",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200103,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "แสนสุข",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20130,
    "sub_district_code": 200104,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "บ้านสวน",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200105,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "หนองรี",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200106,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "นาป่า",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200107,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "หนองข้างคอก",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200108,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "ดอนหัวฬ่อ",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200109,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "หนองไม้แดง",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200110,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "บางทราย",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200111,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "คลองตำหรุ",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200112,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "เหมือง",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20130,
    "sub_district_code": 200113,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "บ้านปึก",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20130,
    "sub_district_code": 200114,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "ห้วยกะปิ",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200115,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "ห้วยกะปิ",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20130,
    "sub_district_code": 200115,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "เสม็ด",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200116,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "อ่างศิลา",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200117,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "สำนักบก",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20000,
    "sub_district_code": 200118,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "หนองซ้ำซาก",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20170,
    "sub_district_code": 200192,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "หนองอิรุณ",
    "district": "เมืองชลบุรี",
    "province": "ชลบุรี",
    "zipcode": 20220,
    "sub_district_code": 200197,
    "district_code": 2001,
    "province_code": 20
  },
  {
    "sub_district": "ท่าเทววงษ์",
    "district": "เกาะสีชัง",
    "province": "ชลบุรี",
    "zipcode": 20120,
    "sub_district_code": 200801,
    "district_code": 2008,
    "province_code": 20
  },
  {
    "sub_district": "นาเกลือ",
    "district": "บางละมุง",
    "province": "ชลบุรี",
    "zipcode": 20150,
    "sub_district_code": 200408,
    "district_code": 2004,
    "province_code": 20
  },
  {
    "sub_district": "บางละมุง",
    "district": "บางละมุง",
    "province": "ชลบุรี",
    "zipcode": 20150,
    "sub_district_code": 200401,
    "district_code": 2004,
    "province_code": 20
  },
  {
    "sub_district": "หนองปรือ",
    "district": "บางละมุง",
    "province": "ชลบุรี",
    "zipcode": 20150,
    "sub_district_code": 200402,
    "district_code": 2004,
    "province_code": 20
  },
  {
    "sub_district": "หนองปลาไหล",
    "district": "บางละมุง",
    "province": "ชลบุรี",
    "zipcode": 20150,
    "sub_district_code": 200403,
    "district_code": 2004,
    "province_code": 20
  },
  {
    "sub_district": "โป่ง",
    "district": "บางละมุง",
    "province": "ชลบุรี",
    "zipcode": 20150,
    "sub_district_code": 200404,
    "district_code": 2004,
    "province_code": 20
  },
  {
    "sub_district": "เขาไม้แก้ว",
    "district": "บางละมุง",
    "province": "ชลบุรี",
    "zipcode": 20150,
    "sub_district_code": 200405,
    "district_code": 2004,
    "province_code": 20
  },
  {
    "sub_district": "ห้วยใหญ่",
    "district": "บางละมุง",
    "province": "ชลบุรี",
    "zipcode": 20150,
    "sub_district_code": 200406,
    "district_code": 2004,
    "province_code": 20
  },
  {
    "sub_district": "ตะเคียนเตี้ย",
    "district": "บางละมุง",
    "province": "ชลบุรี",
    "zipcode": 20150,
    "sub_district_code": 200407,
    "district_code": 2004,
    "province_code": 20
  },
  {
    "sub_district": "หนองปรือ",
    "district": "บางละมุง",
    "province": "ชลบุรี",
    "zipcode": 20260,
    "sub_district_code": 200402,
    "district_code": 2004,
    "province_code": 20
  },
  {
    "sub_district": "บ้านบึง",
    "district": "บ้านบึง",
    "province": "ชลบุรี",
    "zipcode": 20170,
    "sub_district_code": 200201,
    "district_code": 2002,
    "province_code": 20
  },
  {
    "sub_district": "คลองกิ่ว",
    "district": "บ้านบึง",
    "province": "ชลบุรี",
    "zipcode": 20220,
    "sub_district_code": 200202,
    "district_code": 2002,
    "province_code": 20
  },
  {
    "sub_district": "มาบไผ่",
    "district": "บ้านบึง",
    "province": "ชลบุรี",
    "zipcode": 20170,
    "sub_district_code": 200203,
    "district_code": 2002,
    "province_code": 20
  },
  {
    "sub_district": "หนองซ้ำซาก",
    "district": "บ้านบึง",
    "province": "ชลบุรี",
    "zipcode": 20170,
    "sub_district_code": 200204,
    "district_code": 2002,
    "province_code": 20
  },
  {
    "sub_district": "หนองบอนแดง",
    "district": "บ้านบึง",
    "province": "ชลบุรี",
    "zipcode": 20170,
    "sub_district_code": 200205,
    "district_code": 2002,
    "province_code": 20
  },
  {
    "sub_district": "หนองชาก",
    "district": "บ้านบึง",
    "province": "ชลบุรี",
    "zipcode": 20170,
    "sub_district_code": 200206,
    "district_code": 2002,
    "province_code": 20
  },
  {
    "sub_district": "หนองอิรุณ",
    "district": "บ้านบึง",
    "province": "ชลบุรี",
    "zipcode": 20220,
    "sub_district_code": 200207,
    "district_code": 2002,
    "province_code": 20
  },
  {
    "sub_district": "หนองไผ่แก้ว",
    "district": "บ้านบึง",
    "province": "ชลบุรี",
    "zipcode": 20220,
    "sub_district_code": 200208,
    "district_code": 2002,
    "province_code": 20
  },
  {
    "sub_district": "บ้านเซิด",
    "district": "บ้านบึง",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200298,
    "district_code": 2002,
    "province_code": 20
  },
  {
    "sub_district": "พนัสนิคม",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200601,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "หน้าพระธาตุ",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200602,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "วัดหลวง",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200603,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "วัดโบสถ์",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200608,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "โคกเพลาะ",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200620,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "ไร่หลักทอง",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200621,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "นามะตูม",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200622,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "กุฎโง้ง",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200609,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "บ้านเซิด",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200604,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "นาเริก",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200605,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "หมอนนาง",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200606,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "สระสี่เหลี่ยม",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200607,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "หัวถนน",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200610,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200611,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "ท่าบุญมี",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20240,
    "sub_district_code": 200696,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "หนองปรือ",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200613,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "หนองขยาด",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200614,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "ทุ่งขวาง",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200615,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "หนองเหียง",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200616,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "นาวังหิน",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200617,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "บ้านช้าง",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20140,
    "sub_district_code": 200618,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "บ่อทอง",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20270,
    "sub_district_code": 200697,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "เกาะจันทร์",
    "district": "พนัสนิคม",
    "province": "ชลบุรี",
    "zipcode": 20240,
    "sub_district_code": 200698,
    "district_code": 2006,
    "province_code": 20
  },
  {
    "sub_district": "พานทอง",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200501,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "หนองตำลึง",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200502,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "มาบโป่ง",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200503,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "หนองกะขะ",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200504,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "หนองหงษ์",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200505,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "โคกขี้หนอน",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200506,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "บ้านเก่า",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200507,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "หน้าประดู่",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200508,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "บางนาง",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200509,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "เกาะลอย",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200510,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "บางหัก",
    "district": "พานทอง",
    "province": "ชลบุรี",
    "zipcode": 20160,
    "sub_district_code": 200511,
    "district_code": 2005,
    "province_code": 20
  },
  {
    "sub_district": "ศรีราชา",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20110,
    "sub_district_code": 200701,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "สุรศักดิ์",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20110,
    "sub_district_code": 200702,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "ทุ่งสุขลา",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20230,
    "sub_district_code": 200703,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "บึง",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20110,
    "sub_district_code": 200704,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "หนองขาม",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20110,
    "sub_district_code": 200705,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "เขาคันทรง",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20110,
    "sub_district_code": 200706,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "บางพระ",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20110,
    "sub_district_code": 200707,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "บ่อวิน",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20230,
    "sub_district_code": 200708,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "หาดผาแดง",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20110,
    "sub_district_code": 200795,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "อ่าวอุดม",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20110,
    "sub_district_code": 200796,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "บ้านนา",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20110,
    "sub_district_code": 200797,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "บ้านบึง",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20170,
    "sub_district_code": 200798,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "ทุ่งสุขลา",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20230,
    "sub_district_code": 200703,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "บึง",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20230,
    "sub_district_code": 200704,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "หนองขาม",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20230,
    "sub_district_code": 200705,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "หนองขาม",
    "district": "ศรีราชา",
    "province": "ชลบุรี",
    "zipcode": 20230,
    "sub_district_code": 200705,
    "district_code": 2007,
    "province_code": 20
  },
  {
    "sub_district": "สัตหีบ",
    "district": "สัตหีบ",
    "province": "ชลบุรี",
    "zipcode": 20180,
    "sub_district_code": 200901,
    "district_code": 2009,
    "province_code": 20
  },
  {
    "sub_district": "นาจอมเทียน",
    "district": "สัตหีบ",
    "province": "ชลบุรี",
    "zipcode": 20250,
    "sub_district_code": 200902,
    "district_code": 2009,
    "province_code": 20
  },
  {
    "sub_district": "บางสาลี",
    "district": "สัตหีบ",
    "province": "ชลบุรี",
    "zipcode": 20180,
    "sub_district_code": 200999,
    "district_code": 2009,
    "province_code": 20
  },
  {
    "sub_district": "พุทธหลวง",
    "district": "สัตหีบ",
    "province": "ชลบุรี",
    "zipcode": 20180,
    "sub_district_code": 200998,
    "district_code": 2009,
    "province_code": 20
  },
  {
    "sub_district": "บางเสร่",
    "district": "สัตหีบ",
    "province": "ชลบุรี",
    "zipcode": 20250,
    "sub_district_code": 200904,
    "district_code": 2009,
    "province_code": 20
  },
  {
    "sub_district": "พลูตาหลวง",
    "district": "สัตหีบ",
    "province": "ชลบุรี",
    "zipcode": 20180,
    "sub_district_code": 200903,
    "district_code": 2009,
    "province_code": 20
  },
  {
    "sub_district": "แสมสาร",
    "district": "สัตหีบ",
    "province": "ชลบุรี",
    "zipcode": 20180,
    "sub_district_code": 200905,
    "district_code": 2009,
    "province_code": 20
  },
  {
    "sub_district": "หนองใหญ่",
    "district": "หนองใหญ่",
    "province": "ชลบุรี",
    "zipcode": 20190,
    "sub_district_code": 200301,
    "district_code": 2003,
    "province_code": 20
  },
  {
    "sub_district": "คลองพลู",
    "district": "หนองใหญ่",
    "province": "ชลบุรี",
    "zipcode": 20190,
    "sub_district_code": 200302,
    "district_code": 2003,
    "province_code": 20
  },
  {
    "sub_district": "หนองเสือช้าง",
    "district": "หนองใหญ่",
    "province": "ชลบุรี",
    "zipcode": 20190,
    "sub_district_code": 200303,
    "district_code": 2003,
    "province_code": 20
  },
  {
    "sub_district": "ห้างสูง",
    "district": "หนองใหญ่",
    "province": "ชลบุรี",
    "zipcode": 20190,
    "sub_district_code": 200304,
    "district_code": 2003,
    "province_code": 20
  },
  {
    "sub_district": "เขาซก",
    "district": "หนองใหญ่",
    "province": "ชลบุรี",
    "zipcode": 20190,
    "sub_district_code": 200305,
    "district_code": 2003,
    "province_code": 20
  },
  {
    "sub_district": "บ่อทอง",
    "district": "บ่อทอง",
    "province": "ชลบุรี",
    "zipcode": 20270,
    "sub_district_code": 201001,
    "district_code": 2010,
    "province_code": 20
  },
  {
    "sub_district": "วัดสุวรรณ",
    "district": "บ่อทอง",
    "province": "ชลบุรี",
    "zipcode": 20270,
    "sub_district_code": 201002,
    "district_code": 2010,
    "province_code": 20
  },
  {
    "sub_district": "บ่อกวางทอง",
    "district": "บ่อทอง",
    "province": "ชลบุรี",
    "zipcode": 20270,
    "sub_district_code": 201003,
    "district_code": 2010,
    "province_code": 20
  },
  {
    "sub_district": "ธาตุทอง",
    "district": "บ่อทอง",
    "province": "ชลบุรี",
    "zipcode": 20270,
    "sub_district_code": 201004,
    "district_code": 2010,
    "province_code": 20
  },
  {
    "sub_district": "เกษตรสุวรรณ",
    "district": "บ่อทอง",
    "province": "ชลบุรี",
    "zipcode": 20270,
    "sub_district_code": 201005,
    "district_code": 2010,
    "province_code": 20
  },
  {
    "sub_district": "พลวงทอง",
    "district": "บ่อทอง",
    "province": "ชลบุรี",
    "zipcode": 20270,
    "sub_district_code": 201006,
    "district_code": 2010,
    "province_code": 20
  },
  {
    "sub_district": "ท่าบุญมี",
    "district": "เกาะจันทร์",
    "province": "ชลบุรี",
    "zipcode": 20240,
    "sub_district_code": 201102,
    "district_code": 2011,
    "province_code": 20
  },
  {
    "sub_district": "เกาะจันทร์",
    "district": "เกาะจันทร์",
    "province": "ชลบุรี",
    "zipcode": 20240,
    "sub_district_code": 201101,
    "district_code": 2011,
    "province_code": 20
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองชัยนาท",
    "province": "ชัยนาท",
    "zipcode": 17000,
    "sub_district_code": 180101,
    "district_code": 1801,
    "province_code": 18
  },
  {
    "sub_district": "บ้านกล้วย",
    "district": "เมืองชัยนาท",
    "province": "ชัยนาท",
    "zipcode": 17000,
    "sub_district_code": 180102,
    "district_code": 1801,
    "province_code": 18
  },
  {
    "sub_district": "ท่าชัย",
    "district": "เมืองชัยนาท",
    "province": "ชัยนาท",
    "zipcode": 17000,
    "sub_district_code": 180103,
    "district_code": 1801,
    "province_code": 18
  },
  {
    "sub_district": "ชัยนาท",
    "district": "เมืองชัยนาท",
    "province": "ชัยนาท",
    "zipcode": 17000,
    "sub_district_code": 180104,
    "district_code": 1801,
    "province_code": 18
  },
  {
    "sub_district": "เขาท่าพระ",
    "district": "เมืองชัยนาท",
    "province": "ชัยนาท",
    "zipcode": 17000,
    "sub_district_code": 180105,
    "district_code": 1801,
    "province_code": 18
  },
  {
    "sub_district": "หาดท่าเสา",
    "district": "เมืองชัยนาท",
    "province": "ชัยนาท",
    "zipcode": 17000,
    "sub_district_code": 180106,
    "district_code": 1801,
    "province_code": 18
  },
  {
    "sub_district": "ธรรมามูล",
    "district": "เมืองชัยนาท",
    "province": "ชัยนาท",
    "zipcode": 17000,
    "sub_district_code": 180107,
    "district_code": 1801,
    "province_code": 18
  },
  {
    "sub_district": "เสือโฮก",
    "district": "เมืองชัยนาท",
    "province": "ชัยนาท",
    "zipcode": 17000,
    "sub_district_code": 180108,
    "district_code": 1801,
    "province_code": 18
  },
  {
    "sub_district": "นางลือ",
    "district": "เมืองชัยนาท",
    "province": "ชัยนาท",
    "zipcode": 17000,
    "sub_district_code": 180109,
    "district_code": 1801,
    "province_code": 18
  },
  {
    "sub_district": "คุ้งสำเภา",
    "district": "มโนรมย์",
    "province": "ชัยนาท",
    "zipcode": 17110,
    "sub_district_code": 180201,
    "district_code": 1802,
    "province_code": 18
  },
  {
    "sub_district": "วัดโคก",
    "district": "มโนรมย์",
    "province": "ชัยนาท",
    "zipcode": 17110,
    "sub_district_code": 180202,
    "district_code": 1802,
    "province_code": 18
  },
  {
    "sub_district": "ศิลาดาน",
    "district": "มโนรมย์",
    "province": "ชัยนาท",
    "zipcode": 17110,
    "sub_district_code": 180203,
    "district_code": 1802,
    "province_code": 18
  },
  {
    "sub_district": "ท่าฉนวน",
    "district": "มโนรมย์",
    "province": "ชัยนาท",
    "zipcode": 17110,
    "sub_district_code": 180204,
    "district_code": 1802,
    "province_code": 18
  },
  {
    "sub_district": "หางน้ำสาคร",
    "district": "มโนรมย์",
    "province": "ชัยนาท",
    "zipcode": 17170,
    "sub_district_code": 180205,
    "district_code": 1802,
    "province_code": 18
  },
  {
    "sub_district": "ไร่พัฒนา",
    "district": "มโนรมย์",
    "province": "ชัยนาท",
    "zipcode": 17170,
    "sub_district_code": 180206,
    "district_code": 1802,
    "province_code": 18
  },
  {
    "sub_district": "อู่ตะเภา",
    "district": "มโนรมย์",
    "province": "ชัยนาท",
    "zipcode": 17170,
    "sub_district_code": 180207,
    "district_code": 1802,
    "province_code": 18
  },
  {
    "sub_district": "วัดสิงห์",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180301,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "มะขามเฒ่า",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180302,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "หนองน้อย",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180303,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "หนองบัว",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180304,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "หนองมะโมง",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180393,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "หนองขุ่น",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180306,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "บ่อแร่",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180307,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "กุดจอก",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180394,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "วังตะเคียน",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180395,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "สะพานหิน",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180396,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "วังหมัน",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180311,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "ท่าชัย",
    "district": "วัดสิงห์",
    "province": "ชัยนาท",
    "zipcode": 17000,
    "sub_district_code": 180398,
    "district_code": 1803,
    "province_code": 18
  },
  {
    "sub_district": "แพรกศรีราชา",
    "district": "สรรคบุรี",
    "province": "ชัยนาท",
    "zipcode": 17140,
    "sub_district_code": 180501,
    "district_code": 1805,
    "province_code": 18
  },
  {
    "sub_district": "เที่ยงแท้",
    "district": "สรรคบุรี",
    "province": "ชัยนาท",
    "zipcode": 17140,
    "sub_district_code": 180502,
    "district_code": 1805,
    "province_code": 18
  },
  {
    "sub_district": "ห้วยกรด",
    "district": "สรรคบุรี",
    "province": "ชัยนาท",
    "zipcode": 17140,
    "sub_district_code": 180503,
    "district_code": 1805,
    "province_code": 18
  },
  {
    "sub_district": "โพงาม",
    "district": "สรรคบุรี",
    "province": "ชัยนาท",
    "zipcode": 17140,
    "sub_district_code": 180504,
    "district_code": 1805,
    "province_code": 18
  },
  {
    "sub_district": "บางขุด",
    "district": "สรรคบุรี",
    "province": "ชัยนาท",
    "zipcode": 17140,
    "sub_district_code": 180505,
    "district_code": 1805,
    "province_code": 18
  },
  {
    "sub_district": "ดงคอน",
    "district": "สรรคบุรี",
    "province": "ชัยนาท",
    "zipcode": 17140,
    "sub_district_code": 180506,
    "district_code": 1805,
    "province_code": 18
  },
  {
    "sub_district": "ดอนกำ",
    "district": "สรรคบุรี",
    "province": "ชัยนาท",
    "zipcode": 17140,
    "sub_district_code": 180507,
    "district_code": 1805,
    "province_code": 18
  },
  {
    "sub_district": "ห้วยกรดพัฒนา",
    "district": "สรรคบุรี",
    "province": "ชัยนาท",
    "zipcode": 17140,
    "sub_district_code": 180508,
    "district_code": 1805,
    "province_code": 18
  },
  {
    "sub_district": "สรรพยา",
    "district": "สรรพยา",
    "province": "ชัยนาท",
    "zipcode": 17150,
    "sub_district_code": 180401,
    "district_code": 1804,
    "province_code": 18
  },
  {
    "sub_district": "ตลุก",
    "district": "สรรพยา",
    "province": "ชัยนาท",
    "zipcode": 17150,
    "sub_district_code": 180402,
    "district_code": 1804,
    "province_code": 18
  },
  {
    "sub_district": "เขาแก้ว",
    "district": "สรรพยา",
    "province": "ชัยนาท",
    "zipcode": 17150,
    "sub_district_code": 180403,
    "district_code": 1804,
    "province_code": 18
  },
  {
    "sub_district": "โพนางดำตก",
    "district": "สรรพยา",
    "province": "ชัยนาท",
    "zipcode": 17150,
    "sub_district_code": 180404,
    "district_code": 1804,
    "province_code": 18
  },
  {
    "sub_district": "โพนางดำออก",
    "district": "สรรพยา",
    "province": "ชัยนาท",
    "zipcode": 17150,
    "sub_district_code": 180405,
    "district_code": 1804,
    "province_code": 18
  },
  {
    "sub_district": "บางหลวง",
    "district": "สรรพยา",
    "province": "ชัยนาท",
    "zipcode": 17150,
    "sub_district_code": 180406,
    "district_code": 1804,
    "province_code": 18
  },
  {
    "sub_district": "หาดอาษา",
    "district": "สรรพยา",
    "province": "ชัยนาท",
    "zipcode": 17150,
    "sub_district_code": 180407,
    "district_code": 1804,
    "province_code": 18
  },
  {
    "sub_district": "หันคา",
    "district": "หันคา",
    "province": "ชัยนาท",
    "zipcode": 17130,
    "sub_district_code": 180601,
    "district_code": 1806,
    "province_code": 18
  },
  {
    "sub_district": "บ้านเชี่ยน",
    "district": "หันคา",
    "province": "ชัยนาท",
    "zipcode": 17130,
    "sub_district_code": 180602,
    "district_code": 1806,
    "province_code": 18
  },
  {
    "sub_district": "เนินขาม",
    "district": "หันคา",
    "province": "ชัยนาท",
    "zipcode": 17130,
    "sub_district_code": 180696,
    "district_code": 1806,
    "province_code": 18
  },
  {
    "sub_district": "สุขเดือนห้า",
    "district": "หันคา",
    "province": "ชัยนาท",
    "zipcode": 17130,
    "sub_district_code": 180697,
    "district_code": 1806,
    "province_code": 18
  },
  {
    "sub_district": "ไพรนกยูง",
    "district": "หันคา",
    "province": "ชัยนาท",
    "zipcode": 17130,
    "sub_district_code": 180605,
    "district_code": 1806,
    "province_code": 18
  },
  {
    "sub_district": "หนองแซง",
    "district": "หันคา",
    "province": "ชัยนาท",
    "zipcode": 17160,
    "sub_district_code": 180606,
    "district_code": 1806,
    "province_code": 18
  },
  {
    "sub_district": "ห้วยงู",
    "district": "หันคา",
    "province": "ชัยนาท",
    "zipcode": 17160,
    "sub_district_code": 180607,
    "district_code": 1806,
    "province_code": 18
  },
  {
    "sub_district": "วังไก่เถื่อน",
    "district": "หันคา",
    "province": "ชัยนาท",
    "zipcode": 17130,
    "sub_district_code": 180608,
    "district_code": 1806,
    "province_code": 18
  },
  {
    "sub_district": "เด่นใหญ่",
    "district": "หันคา",
    "province": "ชัยนาท",
    "zipcode": 17130,
    "sub_district_code": 180609,
    "district_code": 1806,
    "province_code": 18
  },
  {
    "sub_district": "สามง่ามท่าโบสถ์",
    "district": "หันคา",
    "province": "ชัยนาท",
    "zipcode": 17160,
    "sub_district_code": 180611,
    "district_code": 1806,
    "province_code": 18
  },
  {
    "sub_district": "หนองมะโมง",
    "district": "หนองมะโมง",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180701,
    "district_code": 1807,
    "province_code": 18
  },
  {
    "sub_district": "วังตะเคียน",
    "district": "หนองมะโมง",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180702,
    "district_code": 1807,
    "province_code": 18
  },
  {
    "sub_district": "กุดจอก",
    "district": "หนองมะโมง",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180704,
    "district_code": 1807,
    "province_code": 18
  },
  {
    "sub_district": "สะพานหิน",
    "district": "หนองมะโมง",
    "province": "ชัยนาท",
    "zipcode": 17120,
    "sub_district_code": 180703,
    "district_code": 1807,
    "province_code": 18
  },
  {
    "sub_district": "เนินขาม",
    "district": "เนินขาม",
    "province": "ชัยนาท",
    "zipcode": 17130,
    "sub_district_code": 180801,
    "district_code": 1808,
    "province_code": 18
  },
  {
    "sub_district": "กะบกเตี้ย",
    "district": "เนินขาม",
    "province": "ชัยนาท",
    "zipcode": 17130,
    "sub_district_code": 180802,
    "district_code": 1808,
    "province_code": 18
  },
  {
    "sub_district": "สุขเดือนห้า",
    "district": "เนินขาม",
    "province": "ชัยนาท",
    "zipcode": 17130,
    "sub_district_code": 180803,
    "district_code": 1808,
    "province_code": 18
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360101,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "รอบเมือง",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360102,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "โพนทอง",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360103,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "นาฝาย",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360104,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "บ้านค่าย",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36240,
    "sub_district_code": 360105,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "กุดตุ้ม",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360106,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "ชีลอง",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360107,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "บ้านเล่า",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360108,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "นาเสียว",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360109,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "หนองนาแซง",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360110,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "ลาดใหญ่",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360111,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "หนองไผ่",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36240,
    "sub_district_code": 360112,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "ท่าหินโงม",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360113,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "ห้วยต้อน",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360114,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "ห้วยบง",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360115,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "โนนสำราญ",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36240,
    "sub_district_code": 360116,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "บุ่งคล้า",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360118,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "โคกสูง",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360117,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "ซับสีทอง",
    "district": "เมืองชัยภูมิ",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360119,
    "district_code": 3601,
    "province_code": 36
  },
  {
    "sub_district": "บ้านยาง",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360401,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "บ้านหัน",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360402,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "บ้านเดื่อ",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360403,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "บ้านเป้า",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360404,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "กุดเลาะ",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360405,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "โนนกอก",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360406,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "สระโพนทอง",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360407,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "หนองข่า",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360408,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "หนองโพนงาม",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360409,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "บ้านบัว",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360410,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "ซับสีทอง",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36000,
    "sub_district_code": 360498,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "โนนทอง",
    "district": "เกษตรสมบูรณ์",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 360412,
    "district_code": 3604,
    "province_code": 36
  },
  {
    "sub_district": "ช่องสามหมอ",
    "district": "แก้งคร้อ",
    "province": "ชัยภูมิ",
    "zipcode": 36150,
    "sub_district_code": 361201,
    "district_code": 3612,
    "province_code": 36
  },
  {
    "sub_district": "หนองขาม",
    "district": "แก้งคร้อ",
    "province": "ชัยภูมิ",
    "zipcode": 36150,
    "sub_district_code": 361202,
    "district_code": 3612,
    "province_code": 36
  },
  {
    "sub_district": "นาหนองทุ่ม",
    "district": "แก้งคร้อ",
    "province": "ชัยภูมิ",
    "zipcode": 36150,
    "sub_district_code": 361203,
    "district_code": 3612,
    "province_code": 36
  },
  {
    "sub_district": "บ้านแก้ง",
    "district": "แก้งคร้อ",
    "province": "ชัยภูมิ",
    "zipcode": 36150,
    "sub_district_code": 361204,
    "district_code": 3612,
    "province_code": 36
  },
  {
    "sub_district": "หนองสังข์",
    "district": "แก้งคร้อ",
    "province": "ชัยภูมิ",
    "zipcode": 36150,
    "sub_district_code": 361205,
    "district_code": 3612,
    "province_code": 36
  },
  {
    "sub_district": "หลุบคา",
    "district": "แก้งคร้อ",
    "province": "ชัยภูมิ",
    "zipcode": 36150,
    "sub_district_code": 361206,
    "district_code": 3612,
    "province_code": 36
  },
  {
    "sub_district": "โคกกุง",
    "district": "แก้งคร้อ",
    "province": "ชัยภูมิ",
    "zipcode": 36150,
    "sub_district_code": 361207,
    "district_code": 3612,
    "province_code": 36
  },
  {
    "sub_district": "เก่าย่าดี",
    "district": "แก้งคร้อ",
    "province": "ชัยภูมิ",
    "zipcode": 36150,
    "sub_district_code": 361208,
    "district_code": 3612,
    "province_code": 36
  },
  {
    "sub_district": "หนองไผ่",
    "district": "แก้งคร้อ",
    "province": "ชัยภูมิ",
    "zipcode": 36150,
    "sub_district_code": 361210,
    "district_code": 3612,
    "province_code": 36
  },
  {
    "sub_district": "ท่ามะไฟหวาน",
    "district": "แก้งคร้อ",
    "province": "ชัยภูมิ",
    "zipcode": 36150,
    "sub_district_code": 361209,
    "district_code": 3612,
    "province_code": 36
  },
  {
    "sub_district": "คอนสวรรค์",
    "district": "คอนสวรรค์",
    "province": "ชัยภูมิ",
    "zipcode": 36140,
    "sub_district_code": 360301,
    "district_code": 3603,
    "province_code": 36
  },
  {
    "sub_district": "ยางหวาย",
    "district": "คอนสวรรค์",
    "province": "ชัยภูมิ",
    "zipcode": 36140,
    "sub_district_code": 360302,
    "district_code": 3603,
    "province_code": 36
  },
  {
    "sub_district": "ช่องสามหมอ",
    "district": "คอนสวรรค์",
    "province": "ชัยภูมิ",
    "zipcode": 36140,
    "sub_district_code": 360303,
    "district_code": 3603,
    "province_code": 36
  },
  {
    "sub_district": "โนนสะอาด",
    "district": "คอนสวรรค์",
    "province": "ชัยภูมิ",
    "zipcode": 36140,
    "sub_district_code": 360304,
    "district_code": 3603,
    "province_code": 36
  },
  {
    "sub_district": "ห้วยไร่",
    "district": "คอนสวรรค์",
    "province": "ชัยภูมิ",
    "zipcode": 36140,
    "sub_district_code": 360305,
    "district_code": 3603,
    "province_code": 36
  },
  {
    "sub_district": "โคกมั่งงอย",
    "district": "คอนสวรรค์",
    "province": "ชัยภูมิ",
    "zipcode": 36140,
    "sub_district_code": 360307,
    "district_code": 3603,
    "province_code": 36
  },
  {
    "sub_district": "บ้านโสก",
    "district": "คอนสวรรค์",
    "province": "ชัยภูมิ",
    "zipcode": 36140,
    "sub_district_code": 360306,
    "district_code": 3603,
    "province_code": 36
  },
  {
    "sub_district": "ศรีสำราญ",
    "district": "คอนสวรรค์",
    "province": "ชัยภูมิ",
    "zipcode": 36140,
    "sub_district_code": 360309,
    "district_code": 3603,
    "province_code": 36
  },
  {
    "sub_district": "หนองขาม",
    "district": "คอนสวรรค์",
    "province": "ชัยภูมิ",
    "zipcode": 36140,
    "sub_district_code": 360308,
    "district_code": 3603,
    "province_code": 36
  },
  {
    "sub_district": "คอนสาร",
    "district": "คอนสาร",
    "province": "ชัยภูมิ",
    "zipcode": 36180,
    "sub_district_code": 361301,
    "district_code": 3613,
    "province_code": 36
  },
  {
    "sub_district": "ทุ่งพระ",
    "district": "คอนสาร",
    "province": "ชัยภูมิ",
    "zipcode": 36180,
    "sub_district_code": 361302,
    "district_code": 3613,
    "province_code": 36
  },
  {
    "sub_district": "โนนคูณ",
    "district": "คอนสาร",
    "province": "ชัยภูมิ",
    "zipcode": 36180,
    "sub_district_code": 361303,
    "district_code": 3613,
    "province_code": 36
  },
  {
    "sub_district": "ห้วยยาง",
    "district": "คอนสาร",
    "province": "ชัยภูมิ",
    "zipcode": 36180,
    "sub_district_code": 361304,
    "district_code": 3613,
    "province_code": 36
  },
  {
    "sub_district": "ทุ่งลุยลาย",
    "district": "คอนสาร",
    "province": "ชัยภูมิ",
    "zipcode": 36180,
    "sub_district_code": 361305,
    "district_code": 3613,
    "province_code": 36
  },
  {
    "sub_district": "ดงบัง",
    "district": "คอนสาร",
    "province": "ชัยภูมิ",
    "zipcode": 36180,
    "sub_district_code": 361306,
    "district_code": 3613,
    "province_code": 36
  },
  {
    "sub_district": "ทุ่งนาเลา",
    "district": "คอนสาร",
    "province": "ชัยภูมิ",
    "zipcode": 36180,
    "sub_district_code": 361307,
    "district_code": 3613,
    "province_code": 36
  },
  {
    "sub_district": "ดงกลาง",
    "district": "คอนสาร",
    "province": "ชัยภูมิ",
    "zipcode": 36180,
    "sub_district_code": 361308,
    "district_code": 3613,
    "province_code": 36
  },
  {
    "sub_district": "บ้านกอก",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360601,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "หนองบัวบาน",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360602,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "บ้านขาม",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360603,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "หนองฉิม",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360693,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "กุดน้ำใส",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360605,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "หนองโดน",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360606,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "ละหาน",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360607,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "ตาเนิน",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360694,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "กะฮาด",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360695,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "หนองบัวใหญ่",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360610,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "หนองบัวโคก",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36220,
    "sub_district_code": 360611,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "ท่ากูบ",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360696,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "ส้มป่อย",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360613,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "ซับใหญ่",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360697,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "รังงาม",
    "district": "จัตุรัส",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 360698,
    "district_code": 3606,
    "province_code": 36
  },
  {
    "sub_district": "บ้านเขว้า",
    "district": "บ้านเขว้า",
    "province": "ชัยภูมิ",
    "zipcode": 36170,
    "sub_district_code": 360201,
    "district_code": 3602,
    "province_code": 36
  },
  {
    "sub_district": "ตลาดแร้ง",
    "district": "บ้านเขว้า",
    "province": "ชัยภูมิ",
    "zipcode": 36170,
    "sub_district_code": 360202,
    "district_code": 3602,
    "province_code": 36
  },
  {
    "sub_district": "ลุ่มลำชี",
    "district": "บ้านเขว้า",
    "province": "ชัยภูมิ",
    "zipcode": 36170,
    "sub_district_code": 360203,
    "district_code": 3602,
    "province_code": 36
  },
  {
    "sub_district": "ชีบน",
    "district": "บ้านเขว้า",
    "province": "ชัยภูมิ",
    "zipcode": 36170,
    "sub_district_code": 360204,
    "district_code": 3602,
    "province_code": 36
  },
  {
    "sub_district": "โนนแดง",
    "district": "บ้านเขว้า",
    "province": "ชัยภูมิ",
    "zipcode": 36170,
    "sub_district_code": 360206,
    "district_code": 3602,
    "province_code": 36
  },
  {
    "sub_district": "ภูแลนคา",
    "district": "บ้านเขว้า",
    "province": "ชัยภูมิ",
    "zipcode": 36170,
    "sub_district_code": 360205,
    "district_code": 3602,
    "province_code": 36
  },
  {
    "sub_district": "บ้านแท่น",
    "district": "บ้านแท่น",
    "province": "ชัยภูมิ",
    "zipcode": 36190,
    "sub_district_code": 361101,
    "district_code": 3611,
    "province_code": 36
  },
  {
    "sub_district": "สามสวน",
    "district": "บ้านแท่น",
    "province": "ชัยภูมิ",
    "zipcode": 36190,
    "sub_district_code": 361102,
    "district_code": 3611,
    "province_code": 36
  },
  {
    "sub_district": "สระพัง",
    "district": "บ้านแท่น",
    "province": "ชัยภูมิ",
    "zipcode": 36190,
    "sub_district_code": 361103,
    "district_code": 3611,
    "province_code": 36
  },
  {
    "sub_district": "บ้านเต่า",
    "district": "บ้านแท่น",
    "province": "ชัยภูมิ",
    "zipcode": 36190,
    "sub_district_code": 361104,
    "district_code": 3611,
    "province_code": 36
  },
  {
    "sub_district": "หนองคู",
    "district": "บ้านแท่น",
    "province": "ชัยภูมิ",
    "zipcode": 36190,
    "sub_district_code": 361105,
    "district_code": 3611,
    "province_code": 36
  },
  {
    "sub_district": "บ้านชวน",
    "district": "บำเหน็จณรงค์",
    "province": "ชัยภูมิ",
    "zipcode": 36160,
    "sub_district_code": 360701,
    "district_code": 3607,
    "province_code": 36
  },
  {
    "sub_district": "บ้านเพชร",
    "district": "บำเหน็จณรงค์",
    "province": "ชัยภูมิ",
    "zipcode": 36160,
    "sub_district_code": 360702,
    "district_code": 3607,
    "province_code": 36
  },
  {
    "sub_district": "บ้านตาล",
    "district": "บำเหน็จณรงค์",
    "province": "ชัยภูมิ",
    "zipcode": 36220,
    "sub_district_code": 360703,
    "district_code": 3607,
    "province_code": 36
  },
  {
    "sub_district": "หัวทะเล",
    "district": "บำเหน็จณรงค์",
    "province": "ชัยภูมิ",
    "zipcode": 36220,
    "sub_district_code": 360704,
    "district_code": 3607,
    "province_code": 36
  },
  {
    "sub_district": "โคกเริงรมย์",
    "district": "บำเหน็จณรงค์",
    "province": "ชัยภูมิ",
    "zipcode": 36160,
    "sub_district_code": 360705,
    "district_code": 3607,
    "province_code": 36
  },
  {
    "sub_district": "เกาะมะนาว",
    "district": "บำเหน็จณรงค์",
    "province": "ชัยภูมิ",
    "zipcode": 36160,
    "sub_district_code": 360706,
    "district_code": 3607,
    "province_code": 36
  },
  {
    "sub_district": "โคกเพชรพัฒนา",
    "district": "บำเหน็จณรงค์",
    "province": "ชัยภูมิ",
    "zipcode": 36160,
    "sub_district_code": 360707,
    "district_code": 3607,
    "province_code": 36
  },
  {
    "sub_district": "ผักปัง",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361001,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "กวางโจน",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361002,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "หนองคอนไทย",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361003,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "บ้านแก้ง",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361004,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "กุดยม",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361005,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "บ้านเพชร",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361006,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361007,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "หนองตูม",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361008,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "ธาตุทอง",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361010,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "โอโล",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361009,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "สระโพนทอง",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36120,
    "sub_district_code": 361095,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "บ้านดอน",
    "district": "ภูเขียว",
    "province": "ชัยภูมิ",
    "zipcode": 36110,
    "sub_district_code": 361011,
    "district_code": 3610,
    "province_code": 36
  },
  {
    "sub_district": "หนองบัวแดง",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36210,
    "sub_district_code": 360501,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "กุดชุมแสง",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36210,
    "sub_district_code": 360502,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "ถ้ำวัวแดง",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36210,
    "sub_district_code": 360503,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "นางแดด",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36210,
    "sub_district_code": 360504,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "บ้านเจียง",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36260,
    "sub_district_code": 360596,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "เจาทอง",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36260,
    "sub_district_code": 360597,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "หนองแวง",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36210,
    "sub_district_code": 360507,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "คูเมือง",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36210,
    "sub_district_code": 360508,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "ท่าใหญ่",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36210,
    "sub_district_code": 360509,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "วังทอง",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36260,
    "sub_district_code": 360598,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "วังชมภู",
    "district": "หนองบัวแดง",
    "province": "ชัยภูมิ",
    "zipcode": 36210,
    "sub_district_code": 360511,
    "district_code": 3605,
    "province_code": 36
  },
  {
    "sub_district": "วะตะแบก",
    "district": "เทพสถิต",
    "province": "ชัยภูมิ",
    "zipcode": 36230,
    "sub_district_code": 360901,
    "district_code": 3609,
    "province_code": 36
  },
  {
    "sub_district": "ห้วยยายจิ๋ว",
    "district": "เทพสถิต",
    "province": "ชัยภูมิ",
    "zipcode": 36230,
    "sub_district_code": 360902,
    "district_code": 3609,
    "province_code": 36
  },
  {
    "sub_district": "นายางกลัก",
    "district": "เทพสถิต",
    "province": "ชัยภูมิ",
    "zipcode": 36230,
    "sub_district_code": 360903,
    "district_code": 3609,
    "province_code": 36
  },
  {
    "sub_district": "บ้านไร่",
    "district": "เทพสถิต",
    "province": "ชัยภูมิ",
    "zipcode": 36230,
    "sub_district_code": 360904,
    "district_code": 3609,
    "province_code": 36
  },
  {
    "sub_district": "โป่งนก",
    "district": "เทพสถิต",
    "province": "ชัยภูมิ",
    "zipcode": 36230,
    "sub_district_code": 360905,
    "district_code": 3609,
    "province_code": 36
  },
  {
    "sub_district": "หนองบัวระเหว",
    "district": "หนองบัวระเหว",
    "province": "ชัยภูมิ",
    "zipcode": 36250,
    "sub_district_code": 360801,
    "district_code": 3608,
    "province_code": 36
  },
  {
    "sub_district": "วังตะเฆ่",
    "district": "หนองบัวระเหว",
    "province": "ชัยภูมิ",
    "zipcode": 36250,
    "sub_district_code": 360802,
    "district_code": 3608,
    "province_code": 36
  },
  {
    "sub_district": "ห้วยแย้",
    "district": "หนองบัวระเหว",
    "province": "ชัยภูมิ",
    "zipcode": 36250,
    "sub_district_code": 360803,
    "district_code": 3608,
    "province_code": 36
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "หนองบัวระเหว",
    "province": "ชัยภูมิ",
    "zipcode": 36250,
    "sub_district_code": 360804,
    "district_code": 3608,
    "province_code": 36
  },
  {
    "sub_district": "โสกปลาดุก",
    "district": "หนองบัวระเหว",
    "province": "ชัยภูมิ",
    "zipcode": 36250,
    "sub_district_code": 360805,
    "district_code": 3608,
    "province_code": 36
  },
  {
    "sub_district": "วังทอง",
    "district": "ภักดีชุมพล",
    "province": "ชัยภูมิ",
    "zipcode": 36260,
    "sub_district_code": 361403,
    "district_code": 3614,
    "province_code": 36
  },
  {
    "sub_district": "เจาทอง",
    "district": "ภักดีชุมพล",
    "province": "ชัยภูมิ",
    "zipcode": 36260,
    "sub_district_code": 361402,
    "district_code": 3614,
    "province_code": 36
  },
  {
    "sub_district": "บ้านเจียง",
    "district": "ภักดีชุมพล",
    "province": "ชัยภูมิ",
    "zipcode": 36260,
    "sub_district_code": 361401,
    "district_code": 3614,
    "province_code": 36
  },
  {
    "sub_district": "แหลมทอง",
    "district": "ภักดีชุมพล",
    "province": "ชัยภูมิ",
    "zipcode": 36260,
    "sub_district_code": 361404,
    "district_code": 3614,
    "province_code": 36
  },
  {
    "sub_district": "หนองฉิม",
    "district": "เนินสง่า",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 361501,
    "district_code": 3615,
    "province_code": 36
  },
  {
    "sub_district": "ตาเนิน",
    "district": "เนินสง่า",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 361502,
    "district_code": 3615,
    "province_code": 36
  },
  {
    "sub_district": "กะฮาด",
    "district": "เนินสง่า",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 361503,
    "district_code": 3615,
    "province_code": 36
  },
  {
    "sub_district": "รังงาม",
    "district": "เนินสง่า",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 361504,
    "district_code": 3615,
    "province_code": 36
  },
  {
    "sub_district": "ซับใหญ่",
    "district": "ซับใหญ่",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 361601,
    "district_code": 3616,
    "province_code": 36
  },
  {
    "sub_district": "ท่ากูบ",
    "district": "ซับใหญ่",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 361602,
    "district_code": 3616,
    "province_code": 36
  },
  {
    "sub_district": "ตะโกทอง",
    "district": "ซับใหญ่",
    "province": "ชัยภูมิ",
    "zipcode": 36130,
    "sub_district_code": 361603,
    "district_code": 3616,
    "province_code": 36
  },
  {
    "sub_district": "ท่าตะเภา",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86000,
    "sub_district_code": 860101,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "ปากน้ำ",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86120,
    "sub_district_code": 860102,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "ท่ายาง",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86000,
    "sub_district_code": 860103,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "บางหมาก",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86000,
    "sub_district_code": 860104,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "นาทุ่ง",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86000,
    "sub_district_code": 860105,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "นาชะอัง",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86000,
    "sub_district_code": 860106,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "ตากแดด",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86000,
    "sub_district_code": 860107,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "บางลึก",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86000,
    "sub_district_code": 860108,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "หาดพันไกร",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86000,
    "sub_district_code": 860109,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "วังไผ่",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86000,
    "sub_district_code": 860110,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "วังใหม่",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86190,
    "sub_district_code": 860111,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "บ้านนา",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86190,
    "sub_district_code": 860112,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "ขุนกระทิง",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86190,
    "sub_district_code": 860113,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "ทุ่งคา",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86100,
    "sub_district_code": 860114,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "วิสัยเหนือ",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86100,
    "sub_district_code": 860115,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "หาดทรายรี",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86120,
    "sub_district_code": 860116,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "ถ้ำสิงห์",
    "district": "เมืองชุมพร",
    "province": "ชุมพร",
    "zipcode": 86100,
    "sub_district_code": 860117,
    "district_code": 8601,
    "province_code": 86
  },
  {
    "sub_district": "ท่าแซะ",
    "district": "ท่าแซะ",
    "province": "ชุมพร",
    "zipcode": 86140,
    "sub_district_code": 860201,
    "district_code": 8602,
    "province_code": 86
  },
  {
    "sub_district": "คุริง",
    "district": "ท่าแซะ",
    "province": "ชุมพร",
    "zipcode": 86140,
    "sub_district_code": 860202,
    "district_code": 8602,
    "province_code": 86
  },
  {
    "sub_district": "สลุย",
    "district": "ท่าแซะ",
    "province": "ชุมพร",
    "zipcode": 86140,
    "sub_district_code": 860203,
    "district_code": 8602,
    "province_code": 86
  },
  {
    "sub_district": "นากระตาม",
    "district": "ท่าแซะ",
    "province": "ชุมพร",
    "zipcode": 86140,
    "sub_district_code": 860204,
    "district_code": 8602,
    "province_code": 86
  },
  {
    "sub_district": "รับร่อ",
    "district": "ท่าแซะ",
    "province": "ชุมพร",
    "zipcode": 86190,
    "sub_district_code": 860205,
    "district_code": 8602,
    "province_code": 86
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "ท่าแซะ",
    "province": "ชุมพร",
    "zipcode": 86140,
    "sub_district_code": 860206,
    "district_code": 8602,
    "province_code": 86
  },
  {
    "sub_district": "หงษ์เจริญ",
    "district": "ท่าแซะ",
    "province": "ชุมพร",
    "zipcode": 86140,
    "sub_district_code": 860207,
    "district_code": 8602,
    "province_code": 86
  },
  {
    "sub_district": "ทรัพย์อนันต์",
    "district": "ท่าแซะ",
    "province": "ชุมพร",
    "zipcode": 86140,
    "sub_district_code": 860209,
    "district_code": 8602,
    "province_code": 86
  },
  {
    "sub_district": "สองพี่น้อง",
    "district": "ท่าแซะ",
    "province": "ชุมพร",
    "zipcode": 86140,
    "sub_district_code": 860210,
    "district_code": 8602,
    "province_code": 86
  },
  {
    "sub_district": "หินแก้ว",
    "district": "ท่าแซะ",
    "province": "ชุมพร",
    "zipcode": 86190,
    "sub_district_code": 860208,
    "district_code": 8602,
    "province_code": 86
  },
  {
    "sub_district": "บางสน",
    "district": "ปะทิว",
    "province": "ชุมพร",
    "zipcode": 86160,
    "sub_district_code": 860301,
    "district_code": 8603,
    "province_code": 86
  },
  {
    "sub_district": "ทะเลทรัพย์",
    "district": "ปะทิว",
    "province": "ชุมพร",
    "zipcode": 86160,
    "sub_district_code": 860302,
    "district_code": 8603,
    "province_code": 86
  },
  {
    "sub_district": "สะพลี",
    "district": "ปะทิว",
    "province": "ชุมพร",
    "zipcode": 86230,
    "sub_district_code": 860303,
    "district_code": 8603,
    "province_code": 86
  },
  {
    "sub_district": "ชุมโค",
    "district": "ปะทิว",
    "province": "ชุมพร",
    "zipcode": 86160,
    "sub_district_code": 860304,
    "district_code": 8603,
    "province_code": 86
  },
  {
    "sub_district": "ดอนยาง",
    "district": "ปะทิว",
    "province": "ชุมพร",
    "zipcode": 86210,
    "sub_district_code": 860305,
    "district_code": 8603,
    "province_code": 86
  },
  {
    "sub_district": "ปากคลอง",
    "district": "ปะทิว",
    "province": "ชุมพร",
    "zipcode": 86210,
    "sub_district_code": 860306,
    "district_code": 8603,
    "province_code": 86
  },
  {
    "sub_district": "เขาไชยราช",
    "district": "ปะทิว",
    "province": "ชุมพร",
    "zipcode": 86210,
    "sub_district_code": 860307,
    "district_code": 8603,
    "province_code": 86
  },
  {
    "sub_district": "พะโต๊ะ",
    "district": "พะโต๊ะ",
    "province": "ชุมพร",
    "zipcode": 86180,
    "sub_district_code": 860601,
    "district_code": 8606,
    "province_code": 86
  },
  {
    "sub_district": "ปากทรง",
    "district": "พะโต๊ะ",
    "province": "ชุมพร",
    "zipcode": 86180,
    "sub_district_code": 860602,
    "district_code": 8606,
    "province_code": 86
  },
  {
    "sub_district": "ปังหวาน",
    "district": "พะโต๊ะ",
    "province": "ชุมพร",
    "zipcode": 86180,
    "sub_district_code": 860603,
    "district_code": 8606,
    "province_code": 86
  },
  {
    "sub_district": "พระรักษ์",
    "district": "พะโต๊ะ",
    "province": "ชุมพร",
    "zipcode": 86180,
    "sub_district_code": 860604,
    "district_code": 8606,
    "province_code": 86
  },
  {
    "sub_district": "ละแม",
    "district": "ละแม",
    "province": "ชุมพร",
    "zipcode": 86170,
    "sub_district_code": 860501,
    "district_code": 8605,
    "province_code": 86
  },
  {
    "sub_district": "ทุ่งหลวง",
    "district": "ละแม",
    "province": "ชุมพร",
    "zipcode": 86170,
    "sub_district_code": 860502,
    "district_code": 8605,
    "province_code": 86
  },
  {
    "sub_district": "สวนแตง",
    "district": "ละแม",
    "province": "ชุมพร",
    "zipcode": 86170,
    "sub_district_code": 860503,
    "district_code": 8605,
    "province_code": 86
  },
  {
    "sub_district": "ทุ่งคาวัด",
    "district": "ละแม",
    "province": "ชุมพร",
    "zipcode": 86170,
    "sub_district_code": 860504,
    "district_code": 8605,
    "province_code": 86
  },
  {
    "sub_district": "นาโพธิ์",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860701,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "สวี",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860702,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "ทุ่งระยะ",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860703,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "ท่าหิน",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860704,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "ปากแพรก",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860705,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "ด่านสวี",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860706,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "ครน",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860707,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "วิสัยใต้",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860708,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "นาสัก",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860709,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "เขาทะลุ",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860710,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "เขาค่าย",
    "district": "สวี",
    "province": "ชุมพร",
    "zipcode": 86130,
    "sub_district_code": 860711,
    "district_code": 8607,
    "province_code": 86
  },
  {
    "sub_district": "หลังสวน",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860401,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "ขันเงิน",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860402,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "ท่ามะพลา",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860403,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "นาขา",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860404,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "นาพญา",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860405,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "บ้านควน",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860406,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "บางมะพร้าว",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860407,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "บางน้ำจืด",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86150,
    "sub_district_code": 860408,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "ปากน้ำ",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86150,
    "sub_district_code": 860409,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "พ้อแดง",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860410,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "แหลมทราย",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860411,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "วังตะกอ",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860412,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "หาดยาย",
    "district": "หลังสวน",
    "province": "ชุมพร",
    "zipcode": 86110,
    "sub_district_code": 860413,
    "district_code": 8604,
    "province_code": 86
  },
  {
    "sub_district": "ปากตะโก",
    "district": "ทุ่งตะโก",
    "province": "ชุมพร",
    "zipcode": 86220,
    "sub_district_code": 860801,
    "district_code": 8608,
    "province_code": 86
  },
  {
    "sub_district": "ทุ่งตะไคร",
    "district": "ทุ่งตะโก",
    "province": "ชุมพร",
    "zipcode": 86220,
    "sub_district_code": 860802,
    "district_code": 8608,
    "province_code": 86
  },
  {
    "sub_district": "ตะโก",
    "district": "ทุ่งตะโก",
    "province": "ชุมพร",
    "zipcode": 86220,
    "sub_district_code": 860803,
    "district_code": 8608,
    "province_code": 86
  },
  {
    "sub_district": "ช่องไม้แก้ว",
    "district": "ทุ่งตะโก",
    "province": "ชุมพร",
    "zipcode": 86220,
    "sub_district_code": 860804,
    "district_code": 8608,
    "province_code": 86
  },
  {
    "sub_district": "เวียง",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570101,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "รอบเวียง",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570102,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "บ้านดู่",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57100,
    "sub_district_code": 570103,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "นางแล",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57100,
    "sub_district_code": 570104,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "แม่ข้าวต้ม",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57100,
    "sub_district_code": 570105,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "แม่ยาว",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57100,
    "sub_district_code": 570106,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "สันทราย",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570107,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ดงมะดะ",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57250,
    "sub_district_code": 570192,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ป่าก่อดำ",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57250,
    "sub_district_code": 570198,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "แม่กรณ์",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570111,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ห้วยชมภู",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570112,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ห้วยสัก",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570113,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ริมกก",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57100,
    "sub_district_code": 570114,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ท่าสาย",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570118,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ดอยลาน",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570115,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ป่าอ้อดอนชัย",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570116,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "โป่งแพร่",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570194,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ท่าข้าวเปลือก",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570196,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "เวียงชัย",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 570197,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ดอยฮาง",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 570120,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "ท่าสุด",
    "district": "เมืองเชียงราย",
    "province": "เชียงราย",
    "zipcode": 57100,
    "sub_district_code": 570121,
    "district_code": 5701,
    "province_code": 57
  },
  {
    "sub_district": "เวียง",
    "district": "เชียงของ",
    "province": "เชียงราย",
    "zipcode": 57140,
    "sub_district_code": 570301,
    "district_code": 5703,
    "province_code": 57
  },
  {
    "sub_district": "สถาน",
    "district": "เชียงของ",
    "province": "เชียงราย",
    "zipcode": 57140,
    "sub_district_code": 570302,
    "district_code": 5703,
    "province_code": 57
  },
  {
    "sub_district": "ครึ่ง",
    "district": "เชียงของ",
    "province": "เชียงราย",
    "zipcode": 57140,
    "sub_district_code": 570303,
    "district_code": 5703,
    "province_code": 57
  },
  {
    "sub_district": "บุญเรือง",
    "district": "เชียงของ",
    "province": "เชียงราย",
    "zipcode": 57140,
    "sub_district_code": 570304,
    "district_code": 5703,
    "province_code": 57
  },
  {
    "sub_district": "ห้วยซ้อ",
    "district": "เชียงของ",
    "province": "เชียงราย",
    "zipcode": 57140,
    "sub_district_code": 570305,
    "district_code": 5703,
    "province_code": 57
  },
  {
    "sub_district": "ศรีดอนชัย",
    "district": "เชียงของ",
    "province": "เชียงราย",
    "zipcode": 57140,
    "sub_district_code": 570308,
    "district_code": 5703,
    "province_code": 57
  },
  {
    "sub_district": "ริมโขง",
    "district": "เชียงของ",
    "province": "เชียงราย",
    "zipcode": 57140,
    "sub_district_code": 570310,
    "district_code": 5703,
    "province_code": 57
  },
  {
    "sub_district": "เวียง",
    "district": "เชียงแสน",
    "province": "เชียงราย",
    "zipcode": 57150,
    "sub_district_code": 570801,
    "district_code": 5708,
    "province_code": 57
  },
  {
    "sub_district": "ป่าสัก",
    "district": "เชียงแสน",
    "province": "เชียงราย",
    "zipcode": 57150,
    "sub_district_code": 570802,
    "district_code": 5708,
    "province_code": 57
  },
  {
    "sub_district": "บ้านแซว",
    "district": "เชียงแสน",
    "province": "เชียงราย",
    "zipcode": 57150,
    "sub_district_code": 570803,
    "district_code": 5708,
    "province_code": 57
  },
  {
    "sub_district": "ศรีดอนมูล",
    "district": "เชียงแสน",
    "province": "เชียงราย",
    "zipcode": 57150,
    "sub_district_code": 570804,
    "district_code": 5708,
    "province_code": 57
  },
  {
    "sub_district": "แม่เงิน",
    "district": "เชียงแสน",
    "province": "เชียงราย",
    "zipcode": 57150,
    "sub_district_code": 570805,
    "district_code": 5708,
    "province_code": 57
  },
  {
    "sub_district": "โยนก",
    "district": "เชียงแสน",
    "province": "เชียงราย",
    "zipcode": 57150,
    "sub_district_code": 570806,
    "district_code": 5708,
    "province_code": 57
  },
  {
    "sub_district": "ป่าตึง",
    "district": "เชียงแสน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570898,
    "district_code": 5708,
    "province_code": 57
  },
  {
    "sub_district": "เวียง",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57160,
    "sub_district_code": 570401,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "งิ้ว",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57160,
    "sub_district_code": 570402,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "ปล้อง",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57230,
    "sub_district_code": 570403,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "แม่ลอย",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57230,
    "sub_district_code": 570404,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "เชียงเคี่ยน",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57230,
    "sub_district_code": 570405,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "ต้า",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57340,
    "sub_district_code": 570492,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "ป่าตาล",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57340,
    "sub_district_code": 570493,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "ยางฮอม",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57340,
    "sub_district_code": 570494,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "แม่เปา",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57290,
    "sub_district_code": 570495,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "ตับเต่า",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57160,
    "sub_district_code": 570409,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "ไม้ยา",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57290,
    "sub_district_code": 570496,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "หงาว",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57160,
    "sub_district_code": 570410,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "แม่ต๋ำ",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57290,
    "sub_district_code": 570497,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "สันทรายงาม",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57160,
    "sub_district_code": 570411,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "ศรีดอนไชย",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57140,
    "sub_district_code": 570498,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "หนองแรด",
    "district": "เทิง",
    "province": "เชียงราย",
    "zipcode": 57230,
    "sub_district_code": 570413,
    "district_code": 5704,
    "province_code": 57
  },
  {
    "sub_district": "ป่าแดด",
    "district": "ป่าแดด",
    "province": "เชียงราย",
    "zipcode": 57190,
    "sub_district_code": 570601,
    "district_code": 5706,
    "province_code": 57
  },
  {
    "sub_district": "ป่าแงะ",
    "district": "ป่าแดด",
    "province": "เชียงราย",
    "zipcode": 57190,
    "sub_district_code": 570602,
    "district_code": 5706,
    "province_code": 57
  },
  {
    "sub_district": "สันมะค่า",
    "district": "ป่าแดด",
    "province": "เชียงราย",
    "zipcode": 57190,
    "sub_district_code": 570603,
    "district_code": 5706,
    "province_code": 57
  },
  {
    "sub_district": "เวียง",
    "district": "ป่าแดด",
    "province": "เชียงราย",
    "zipcode": 57160,
    "sub_district_code": 570698,
    "district_code": 5706,
    "province_code": 57
  },
  {
    "sub_district": "โรงช้าง",
    "district": "ป่าแดด",
    "province": "เชียงราย",
    "zipcode": 57190,
    "sub_district_code": 570605,
    "district_code": 5706,
    "province_code": 57
  },
  {
    "sub_district": "ศรีโพธิ์เงิน",
    "district": "ป่าแดด",
    "province": "เชียงราย",
    "zipcode": 57190,
    "sub_district_code": 570606,
    "district_code": 5706,
    "province_code": 57
  },
  {
    "sub_district": "เมืองพาน",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570513,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "แม่เย็น",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57280,
    "sub_district_code": 570512,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "สันกลาง",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570511,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "ทรายขาว",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570510,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "ม่วงคำ",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570509,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "ป่าหุ่ง",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570508,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "เจริญเมือง",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570507,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "หัวง้ม",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570506,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "ดอยงาม",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570505,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "สันมะเค็ด",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570501,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "แม่อ้อ",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570502,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "สันติสุข",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570504,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "ธารทอง",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57250,
    "sub_district_code": 570503,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "ทานตะวัน",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57280,
    "sub_district_code": 570514,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "เวียงห้าว",
    "district": "พาน",
    "province": "เชียงราย",
    "zipcode": 57120,
    "sub_district_code": 570515,
    "district_code": 5705,
    "province_code": 57
  },
  {
    "sub_district": "แม่จัน",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570701,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "จันจว้า",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57270,
    "sub_district_code": 570702,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "แม่คำ",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57240,
    "sub_district_code": 570703,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "ป่าซาง",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570704,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "สันทราย",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570705,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "ท่าข้าวเปลือก",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570706,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "ปงน้อย",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570793,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "ป่าตึง",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570708,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "แม่ต๋ำ",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57290,
    "sub_district_code": 570794,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "จันจว้าใต้",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57270,
    "sub_district_code": 570712,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "ศรีค้ำ",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570711,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "แม่ไร่",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57240,
    "sub_district_code": 570710,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "หนองป่าก่อ",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570796,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "จอมสวรรค์",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570713,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "แม่สลองใน",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 570797,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "ห้วยไคร้",
    "district": "แม่จัน",
    "province": "เชียงราย",
    "zipcode": 57220,
    "sub_district_code": 570798,
    "district_code": 5707,
    "province_code": 57
  },
  {
    "sub_district": "แม่สรวย",
    "district": "แม่สรวย",
    "province": "เชียงราย",
    "zipcode": 57180,
    "sub_district_code": 571001,
    "district_code": 5710,
    "province_code": 57
  },
  {
    "sub_district": "ป่าแดด",
    "district": "แม่สรวย",
    "province": "เชียงราย",
    "zipcode": 57180,
    "sub_district_code": 571002,
    "district_code": 5710,
    "province_code": 57
  },
  {
    "sub_district": "แม่พริก",
    "district": "แม่สรวย",
    "province": "เชียงราย",
    "zipcode": 57180,
    "sub_district_code": 571003,
    "district_code": 5710,
    "province_code": 57
  },
  {
    "sub_district": "ศรีถ้อย",
    "district": "แม่สรวย",
    "province": "เชียงราย",
    "zipcode": 57180,
    "sub_district_code": 571004,
    "district_code": 5710,
    "province_code": 57
  },
  {
    "sub_district": "ท่าก๊อ",
    "district": "แม่สรวย",
    "province": "เชียงราย",
    "zipcode": 57180,
    "sub_district_code": 571005,
    "district_code": 5710,
    "province_code": 57
  },
  {
    "sub_district": "วาวี",
    "district": "แม่สรวย",
    "province": "เชียงราย",
    "zipcode": 57180,
    "sub_district_code": 571006,
    "district_code": 5710,
    "province_code": 57
  },
  {
    "sub_district": "เจดีย์หลวง",
    "district": "แม่สรวย",
    "province": "เชียงราย",
    "zipcode": 57180,
    "sub_district_code": 571007,
    "district_code": 5710,
    "province_code": 57
  },
  {
    "sub_district": "แม่สาย",
    "district": "แม่สาย",
    "province": "เชียงราย",
    "zipcode": 57130,
    "sub_district_code": 570901,
    "district_code": 5709,
    "province_code": 57
  },
  {
    "sub_district": "ห้วยไคร้",
    "district": "แม่สาย",
    "province": "เชียงราย",
    "zipcode": 57220,
    "sub_district_code": 570902,
    "district_code": 5709,
    "province_code": 57
  },
  {
    "sub_district": "เกาะช้าง",
    "district": "แม่สาย",
    "province": "เชียงราย",
    "zipcode": 57130,
    "sub_district_code": 570903,
    "district_code": 5709,
    "province_code": 57
  },
  {
    "sub_district": "โป่งผา",
    "district": "แม่สาย",
    "province": "เชียงราย",
    "zipcode": 57130,
    "sub_district_code": 570904,
    "district_code": 5709,
    "province_code": 57
  },
  {
    "sub_district": "เวียงพางคำ",
    "district": "แม่สาย",
    "province": "เชียงราย",
    "zipcode": 57130,
    "sub_district_code": 570906,
    "district_code": 5709,
    "province_code": 57
  },
  {
    "sub_district": "ศรีเมืองชุม",
    "district": "แม่สาย",
    "province": "เชียงราย",
    "zipcode": 57130,
    "sub_district_code": 570905,
    "district_code": 5709,
    "province_code": 57
  },
  {
    "sub_district": "บ้านด้าย",
    "district": "แม่สาย",
    "province": "เชียงราย",
    "zipcode": 57220,
    "sub_district_code": 570908,
    "district_code": 5709,
    "province_code": 57
  },
  {
    "sub_district": "โป่งงาม",
    "district": "แม่สาย",
    "province": "เชียงราย",
    "zipcode": 57130,
    "sub_district_code": 570909,
    "district_code": 5709,
    "province_code": 57
  },
  {
    "sub_district": "ทุ่งก่อ",
    "district": "เวียงชัย",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 570298,
    "district_code": 5702,
    "province_code": 57
  },
  {
    "sub_district": "เวียงชัย",
    "district": "เวียงชัย",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 570202,
    "district_code": 5702,
    "province_code": 57
  },
  {
    "sub_district": "ผางาม",
    "district": "เวียงชัย",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 570203,
    "district_code": 5702,
    "province_code": 57
  },
  {
    "sub_district": "เวียงเหนือ",
    "district": "เวียงชัย",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 570204,
    "district_code": 5702,
    "province_code": 57
  },
  {
    "sub_district": "ป่าซาง",
    "district": "เวียงชัย",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 570296,
    "district_code": 5702,
    "province_code": 57
  },
  {
    "sub_district": "ดอนศิลา",
    "district": "เวียงชัย",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 570206,
    "district_code": 5702,
    "province_code": 57
  },
  {
    "sub_district": "ดงมหาวัน",
    "district": "เวียงชัย",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 570297,
    "district_code": 5702,
    "province_code": 57
  },
  {
    "sub_district": "เมืองชุม",
    "district": "เวียงชัย",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 570208,
    "district_code": 5702,
    "province_code": 57
  },
  {
    "sub_district": "สันสลี",
    "district": "เวียงป่าเป้า",
    "province": "เชียงราย",
    "zipcode": 57170,
    "sub_district_code": 571101,
    "district_code": 5711,
    "province_code": 57
  },
  {
    "sub_district": "เวียง",
    "district": "เวียงป่าเป้า",
    "province": "เชียงราย",
    "zipcode": 57170,
    "sub_district_code": 571102,
    "district_code": 5711,
    "province_code": 57
  },
  {
    "sub_district": "บ้านโป่ง",
    "district": "เวียงป่าเป้า",
    "province": "เชียงราย",
    "zipcode": 57170,
    "sub_district_code": 571103,
    "district_code": 5711,
    "province_code": 57
  },
  {
    "sub_district": "ป่างิ้ว",
    "district": "เวียงป่าเป้า",
    "province": "เชียงราย",
    "zipcode": 57170,
    "sub_district_code": 571104,
    "district_code": 5711,
    "province_code": 57
  },
  {
    "sub_district": "แม่เจดีย์",
    "district": "เวียงป่าเป้า",
    "province": "เชียงราย",
    "zipcode": 57260,
    "sub_district_code": 571106,
    "district_code": 5711,
    "province_code": 57
  },
  {
    "sub_district": "แม่เจดีย์ใหม่",
    "district": "เวียงป่าเป้า",
    "province": "เชียงราย",
    "zipcode": 57260,
    "sub_district_code": 571107,
    "district_code": 5711,
    "province_code": 57
  },
  {
    "sub_district": "เวียงกาหลง",
    "district": "เวียงป่าเป้า",
    "province": "เชียงราย",
    "zipcode": 57260,
    "sub_district_code": 571105,
    "district_code": 5711,
    "province_code": 57
  },
  {
    "sub_district": "แม่เปา",
    "district": "พญาเม็งราย",
    "province": "เชียงราย",
    "zipcode": 57290,
    "sub_district_code": 571201,
    "district_code": 5712,
    "province_code": 57
  },
  {
    "sub_district": "แม่ต๋ำ",
    "district": "พญาเม็งราย",
    "province": "เชียงราย",
    "zipcode": 57290,
    "sub_district_code": 571202,
    "district_code": 5712,
    "province_code": 57
  },
  {
    "sub_district": "ไม้ยา",
    "district": "พญาเม็งราย",
    "province": "เชียงราย",
    "zipcode": 57290,
    "sub_district_code": 571203,
    "district_code": 5712,
    "province_code": 57
  },
  {
    "sub_district": "เม็งราย",
    "district": "พญาเม็งราย",
    "province": "เชียงราย",
    "zipcode": 57290,
    "sub_district_code": 571204,
    "district_code": 5712,
    "province_code": 57
  },
  {
    "sub_district": "ตาดควัน",
    "district": "พญาเม็งราย",
    "province": "เชียงราย",
    "zipcode": 57290,
    "sub_district_code": 571205,
    "district_code": 5712,
    "province_code": 57
  },
  {
    "sub_district": "ม่วงยาย",
    "district": "เวียงแก่น",
    "province": "เชียงราย",
    "zipcode": 57310,
    "sub_district_code": 571301,
    "district_code": 5713,
    "province_code": 57
  },
  {
    "sub_district": "ปอ",
    "district": "เวียงแก่น",
    "province": "เชียงราย",
    "zipcode": 57310,
    "sub_district_code": 571302,
    "district_code": 5713,
    "province_code": 57
  },
  {
    "sub_district": "หล่ายงาว",
    "district": "เวียงแก่น",
    "province": "เชียงราย",
    "zipcode": 57310,
    "sub_district_code": 571303,
    "district_code": 5713,
    "province_code": 57
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "เวียงแก่น",
    "province": "เชียงราย",
    "zipcode": 57310,
    "sub_district_code": 571304,
    "district_code": 5713,
    "province_code": 57
  },
  {
    "sub_district": "ยางฮอม",
    "district": "ขุนตาล",
    "province": "เชียงราย",
    "zipcode": 57340,
    "sub_district_code": 571403,
    "district_code": 5714,
    "province_code": 57
  },
  {
    "sub_district": "ป่าตาล",
    "district": "ขุนตาล",
    "province": "เชียงราย",
    "zipcode": 57340,
    "sub_district_code": 571402,
    "district_code": 5714,
    "province_code": 57
  },
  {
    "sub_district": "ต้า",
    "district": "ขุนตาล",
    "province": "เชียงราย",
    "zipcode": 57340,
    "sub_district_code": 571401,
    "district_code": 5714,
    "province_code": 57
  },
  {
    "sub_district": "แม่สลองใน",
    "district": "แม่ฟ้าหลวง",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 571502,
    "district_code": 5715,
    "province_code": 57
  },
  {
    "sub_district": "เทอดไทย",
    "district": "แม่ฟ้าหลวง",
    "province": "เชียงราย",
    "zipcode": 57240,
    "sub_district_code": 571501,
    "district_code": 5715,
    "province_code": 57
  },
  {
    "sub_district": "แม่สลองนอก",
    "district": "แม่ฟ้าหลวง",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 571503,
    "district_code": 5715,
    "province_code": 57
  },
  {
    "sub_district": "แม่ฟ้าหลวง",
    "district": "แม่ฟ้าหลวง",
    "province": "เชียงราย",
    "zipcode": 57240,
    "sub_district_code": 571504,
    "district_code": 5715,
    "province_code": 57
  },
  {
    "sub_district": "ดงมะดะ",
    "district": "แม่ลาว",
    "province": "เชียงราย",
    "zipcode": 57250,
    "sub_district_code": 571601,
    "district_code": 5716,
    "province_code": 57
  },
  {
    "sub_district": "ป่าก่อดำ",
    "district": "แม่ลาว",
    "province": "เชียงราย",
    "zipcode": 57250,
    "sub_district_code": 571604,
    "district_code": 5716,
    "province_code": 57
  },
  {
    "sub_district": "จอมหมอกแก้ว",
    "district": "แม่ลาว",
    "province": "เชียงราย",
    "zipcode": 57250,
    "sub_district_code": 571602,
    "district_code": 5716,
    "province_code": 57
  },
  {
    "sub_district": "บัวสลี",
    "district": "แม่ลาว",
    "province": "เชียงราย",
    "zipcode": 57250,
    "sub_district_code": 571603,
    "district_code": 5716,
    "province_code": 57
  },
  {
    "sub_district": "โป่งแพร่",
    "district": "แม่ลาว",
    "province": "เชียงราย",
    "zipcode": 57000,
    "sub_district_code": 571605,
    "district_code": 5716,
    "province_code": 57
  },
  {
    "sub_district": "ทุ่งก่อ",
    "district": "เวียงเชียงรุ้ง",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 571701,
    "district_code": 5717,
    "province_code": 57
  },
  {
    "sub_district": "ป่าซาง",
    "district": "เวียงเชียงรุ้ง",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 571703,
    "district_code": 5717,
    "province_code": 57
  },
  {
    "sub_district": "ดงมหาวัน",
    "district": "เวียงเชียงรุ้ง",
    "province": "เชียงราย",
    "zipcode": 57210,
    "sub_district_code": 571702,
    "district_code": 5717,
    "province_code": 57
  },
  {
    "sub_district": "ปงน้อย",
    "district": "ดอยหลวง",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 571801,
    "district_code": 5718,
    "province_code": 57
  },
  {
    "sub_district": "โชคชัย",
    "district": "ดอยหลวง",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 571802,
    "district_code": 5718,
    "province_code": 57
  },
  {
    "sub_district": "หนองป่าก่อ",
    "district": "ดอยหลวง",
    "province": "เชียงราย",
    "zipcode": 57110,
    "sub_district_code": 571803,
    "district_code": 5718,
    "province_code": 57
  },
  {
    "sub_district": "ศรีภูมิ",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50200,
    "sub_district_code": 500101,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "พระสิงห์",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50200,
    "sub_district_code": 500102,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "หายยา",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50100,
    "sub_district_code": 500103,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "ช้างม่อย",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50300,
    "sub_district_code": 500104,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "ช้างคลาน",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50100,
    "sub_district_code": 500105,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "วัดเกต",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50000,
    "sub_district_code": 500106,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "ช้างเผือก",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50300,
    "sub_district_code": 500107,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "สุเทพ",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50200,
    "sub_district_code": 500108,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "แม่เหียะ",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50100,
    "sub_district_code": 500109,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "ป่าแดด",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50100,
    "sub_district_code": 500110,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "หนองหอย",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50000,
    "sub_district_code": 500111,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "ท่าศาลา",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50000,
    "sub_district_code": 500112,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "หนองป่าครั่ง",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50000,
    "sub_district_code": 500113,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "ฟ้าฮ่าม",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50000,
    "sub_district_code": 500114,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "ป่าตัน",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50300,
    "sub_district_code": 500115,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "สันผีเสื้อ",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50300,
    "sub_district_code": 500116,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "ท่าวังตาล",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 500193,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "ห้วยแก้ว",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 500194,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "สันผักหวาน",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 500195,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "สันทราย",
    "district": "เมืองเชียงใหม่",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 500196,
    "district_code": 5001,
    "province_code": 50
  },
  {
    "sub_district": "ยางคราม",
    "district": "จอมทอง",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 500295,
    "district_code": 5002,
    "province_code": 50
  },
  {
    "sub_district": "สองแคว",
    "district": "จอมทอง",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 500296,
    "district_code": 5002,
    "province_code": 50
  },
  {
    "sub_district": "บ้านหลวง",
    "district": "จอมทอง",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 500203,
    "district_code": 5002,
    "province_code": 50
  },
  {
    "sub_district": "ข่วงเปา",
    "district": "จอมทอง",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 500204,
    "district_code": 5002,
    "province_code": 50
  },
  {
    "sub_district": "สบเตี๊ยะ",
    "district": "จอมทอง",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 500205,
    "district_code": 5002,
    "province_code": 50
  },
  {
    "sub_district": "บ้านแปะ",
    "district": "จอมทอง",
    "province": "เชียงใหม่",
    "zipcode": 50240,
    "sub_district_code": 500206,
    "district_code": 5002,
    "province_code": 50
  },
  {
    "sub_district": "ดอยแก้ว",
    "district": "จอมทอง",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 500207,
    "district_code": 5002,
    "province_code": 50
  },
  {
    "sub_district": "ดอยหล่อ",
    "district": "จอมทอง",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 500297,
    "district_code": 5002,
    "province_code": 50
  },
  {
    "sub_district": "แม่สอย",
    "district": "จอมทอง",
    "province": "เชียงใหม่",
    "zipcode": 50240,
    "sub_district_code": 500209,
    "district_code": 5002,
    "province_code": 50
  },
  {
    "sub_district": "สันติสุข",
    "district": "จอมทอง",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 500298,
    "district_code": 5002,
    "province_code": 50
  },
  {
    "sub_district": "เชียงดาว",
    "district": "เชียงดาว",
    "province": "เชียงใหม่",
    "zipcode": 50170,
    "sub_district_code": 500401,
    "district_code": 5004,
    "province_code": 50
  },
  {
    "sub_district": "แม่นะ",
    "district": "เชียงดาว",
    "province": "เชียงใหม่",
    "zipcode": 50170,
    "sub_district_code": 500404,
    "district_code": 5004,
    "province_code": 50
  },
  {
    "sub_district": "เมืองงาย",
    "district": "เชียงดาว",
    "province": "เชียงใหม่",
    "zipcode": 50170,
    "sub_district_code": 500403,
    "district_code": 5004,
    "province_code": 50
  },
  {
    "sub_district": "เมืองนะ",
    "district": "เชียงดาว",
    "province": "เชียงใหม่",
    "zipcode": 50170,
    "sub_district_code": 500402,
    "district_code": 5004,
    "province_code": 50
  },
  {
    "sub_district": "เมืองคอง",
    "district": "เชียงดาว",
    "province": "เชียงใหม่",
    "zipcode": 50170,
    "sub_district_code": 500405,
    "district_code": 5004,
    "province_code": 50
  },
  {
    "sub_district": "เมืองแหง",
    "district": "เชียงดาว",
    "province": "เชียงใหม่",
    "zipcode": 50350,
    "sub_district_code": 500497,
    "district_code": 5004,
    "province_code": 50
  },
  {
    "sub_district": "เปียงหลวง",
    "district": "เชียงดาว",
    "province": "เชียงใหม่",
    "zipcode": 50350,
    "sub_district_code": 500498,
    "district_code": 5004,
    "province_code": 50
  },
  {
    "sub_district": "ปิงโค้ง",
    "district": "เชียงดาว",
    "province": "เชียงใหม่",
    "zipcode": 50170,
    "sub_district_code": 500406,
    "district_code": 5004,
    "province_code": 50
  },
  {
    "sub_district": "ทุ่งข้าวพวง",
    "district": "เชียงดาว",
    "province": "เชียงใหม่",
    "zipcode": 50170,
    "sub_district_code": 500407,
    "district_code": 5004,
    "province_code": 50
  },
  {
    "sub_district": "เชิงดอย",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500501,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "สันปูเลย",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500502,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "ลวงเหนือ",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500503,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "ป่าป้อง",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500504,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "สง่าบ้าน",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500505,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "ป่าลาน",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500506,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "ตลาดขวัญ",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500507,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "สำราญราษฎร์",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500508,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "แม่คือ",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500509,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "ตลาดใหญ่",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500510,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "แม่ฮ้อยเงิน",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500511,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "แม่โป่ง",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500512,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "ป่าเมี่ยง",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500513,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "เทพเสด็จ",
    "district": "ดอยสะเก็ด",
    "province": "เชียงใหม่",
    "zipcode": 50220,
    "sub_district_code": 500514,
    "district_code": 5005,
    "province_code": 50
  },
  {
    "sub_district": "เวียง",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50110,
    "sub_district_code": 500901,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "ปงตำ",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50320,
    "sub_district_code": 500994,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "แม่งอน",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50320,
    "sub_district_code": 500904,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "แม่สูน",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50110,
    "sub_district_code": 500905,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "สันทราย",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50110,
    "sub_district_code": 500906,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "ศรีดงเย็น",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50320,
    "sub_district_code": 500996,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "แม่ทะลบ",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50320,
    "sub_district_code": 500997,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "หนองบัว",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50320,
    "sub_district_code": 500998,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "แม่คะ",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50110,
    "sub_district_code": 500910,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "ม่อนปิ่น",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50110,
    "sub_district_code": 500903,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "แม่ข่า",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50320,
    "sub_district_code": 500911,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "โป่งน้ำร้อน",
    "district": "ฝาง",
    "province": "เชียงใหม่",
    "zipcode": 50110,
    "sub_district_code": 500912,
    "district_code": 5009,
    "province_code": 50
  },
  {
    "sub_district": "เวียง",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501101,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "ทุ่งหลวง",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501102,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "ป่าตุ้ม",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501103,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "ป่าไหน่",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501104,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "สันทราย",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501105,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "บ้านโป่ง",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501106,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "น้ำแพร่",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501107,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "เขื่อนผาก",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501108,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "แม่แวน",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501109,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "แม่ปั๋ง",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501110,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "โหล่งขอด",
    "district": "พร้าว",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501111,
    "district_code": 5011,
    "province_code": 50
  },
  {
    "sub_district": "ช่างเคิ่ง",
    "district": "แม่แจ่ม",
    "province": "เชียงใหม่",
    "zipcode": 50270,
    "sub_district_code": 500301,
    "district_code": 5003,
    "province_code": 50
  },
  {
    "sub_district": "ท่าผา",
    "district": "แม่แจ่ม",
    "province": "เชียงใหม่",
    "zipcode": 50270,
    "sub_district_code": 500302,
    "district_code": 5003,
    "province_code": 50
  },
  {
    "sub_district": "บ้านทับ",
    "district": "แม่แจ่ม",
    "province": "เชียงใหม่",
    "zipcode": 50270,
    "sub_district_code": 500303,
    "district_code": 5003,
    "province_code": 50
  },
  {
    "sub_district": "แม่ศึก",
    "district": "แม่แจ่ม",
    "province": "เชียงใหม่",
    "zipcode": 50270,
    "sub_district_code": 500304,
    "district_code": 5003,
    "province_code": 50
  },
  {
    "sub_district": "แม่นาจร",
    "district": "แม่แจ่ม",
    "province": "เชียงใหม่",
    "zipcode": 50270,
    "sub_district_code": 500305,
    "district_code": 5003,
    "province_code": 50
  },
  {
    "sub_district": "บ้านจันทร์",
    "district": "แม่แจ่ม",
    "province": "เชียงใหม่",
    "zipcode": 58130,
    "sub_district_code": 500396,
    "district_code": 5003,
    "province_code": 50
  },
  {
    "sub_district": "กองแขก",
    "district": "แม่แจ่ม",
    "province": "เชียงใหม่",
    "zipcode": 50270,
    "sub_district_code": 500308,
    "district_code": 5003,
    "province_code": 50
  },
  {
    "sub_district": "ปางหินฝน",
    "district": "แม่แจ่ม",
    "province": "เชียงใหม่",
    "zipcode": 50270,
    "sub_district_code": 500307,
    "district_code": 5003,
    "province_code": 50
  },
  {
    "sub_district": "แม่แดด",
    "district": "แม่แจ่ม",
    "province": "เชียงใหม่",
    "zipcode": 58130,
    "sub_district_code": 500397,
    "district_code": 5003,
    "province_code": 50
  },
  {
    "sub_district": "แจ่มหลวง",
    "district": "แม่แจ่ม",
    "province": "เชียงใหม่",
    "zipcode": 58130,
    "sub_district_code": 500398,
    "district_code": 5003,
    "province_code": 50
  },
  {
    "sub_district": "สันมหาพน",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500601,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "แม่แตง",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500602,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "ขี้เหล็ก",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500603,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "ช่อแล",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500604,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "แม่หอพระ",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500605,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "สบเปิง",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500606,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "บ้านเป้า",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500607,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "สันป่ายาง",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50330,
    "sub_district_code": 500608,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "ป่าแป๋",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500609,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "เมืองก๋าย",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500610,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "บ้านช้าง",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500611,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "กื้ดช้าง",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500612,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "อินทขิล",
    "district": "แม่แตง",
    "province": "เชียงใหม่",
    "zipcode": 50150,
    "sub_district_code": 500613,
    "district_code": 5006,
    "province_code": 50
  },
  {
    "sub_district": "ริมใต้",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50180,
    "sub_district_code": 500701,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "ริมเหนือ",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50180,
    "sub_district_code": 500702,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "สันโป่ง",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50180,
    "sub_district_code": 500703,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "ขี้เหล็ก",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50180,
    "sub_district_code": 500704,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "สะลวง",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50330,
    "sub_district_code": 500705,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "ห้วยทราย",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50180,
    "sub_district_code": 500706,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "แม่แรม",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50180,
    "sub_district_code": 500707,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "โป่งแยง",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50180,
    "sub_district_code": 500708,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "แม่สา",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50180,
    "sub_district_code": 500709,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "ดอนแก้ว",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50180,
    "sub_district_code": 500710,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "เหมืองแก้ว",
    "district": "แม่ริม",
    "province": "เชียงใหม่",
    "zipcode": 50180,
    "sub_district_code": 500711,
    "district_code": 5007,
    "province_code": 50
  },
  {
    "sub_district": "แม่อาย",
    "district": "แม่อาย",
    "province": "เชียงใหม่",
    "zipcode": 50280,
    "sub_district_code": 501001,
    "district_code": 5010,
    "province_code": 50
  },
  {
    "sub_district": "แม่สาว",
    "district": "แม่อาย",
    "province": "เชียงใหม่",
    "zipcode": 50280,
    "sub_district_code": 501002,
    "district_code": 5010,
    "province_code": 50
  },
  {
    "sub_district": "สันต้นหมื้อ",
    "district": "แม่อาย",
    "province": "เชียงใหม่",
    "zipcode": 50280,
    "sub_district_code": 501003,
    "district_code": 5010,
    "province_code": 50
  },
  {
    "sub_district": "แม่นาวาง",
    "district": "แม่อาย",
    "province": "เชียงใหม่",
    "zipcode": 50280,
    "sub_district_code": 501004,
    "district_code": 5010,
    "province_code": 50
  },
  {
    "sub_district": "ท่าตอน",
    "district": "แม่อาย",
    "province": "เชียงใหม่",
    "zipcode": 50280,
    "sub_district_code": 501005,
    "district_code": 5010,
    "province_code": 50
  },
  {
    "sub_district": "บ้านหลวง",
    "district": "แม่อาย",
    "province": "เชียงใหม่",
    "zipcode": 50280,
    "sub_district_code": 501006,
    "district_code": 5010,
    "province_code": 50
  },
  {
    "sub_district": "มะลิกา",
    "district": "แม่อาย",
    "province": "เชียงใหม่",
    "zipcode": 50280,
    "sub_district_code": 501007,
    "district_code": 5010,
    "province_code": 50
  },
  {
    "sub_district": "สะเมิงใต้",
    "district": "สะเมิง",
    "province": "เชียงใหม่",
    "zipcode": 50250,
    "sub_district_code": 500801,
    "district_code": 5008,
    "province_code": 50
  },
  {
    "sub_district": "สะเมิงเหนือ",
    "district": "สะเมิง",
    "province": "เชียงใหม่",
    "zipcode": 50250,
    "sub_district_code": 500802,
    "district_code": 5008,
    "province_code": 50
  },
  {
    "sub_district": "แม่สาบ",
    "district": "สะเมิง",
    "province": "เชียงใหม่",
    "zipcode": 50250,
    "sub_district_code": 500803,
    "district_code": 5008,
    "province_code": 50
  },
  {
    "sub_district": "บ่อแก้ว",
    "district": "สะเมิง",
    "province": "เชียงใหม่",
    "zipcode": 50250,
    "sub_district_code": 500804,
    "district_code": 5008,
    "province_code": 50
  },
  {
    "sub_district": "ยั้งเมิน",
    "district": "สะเมิง",
    "province": "เชียงใหม่",
    "zipcode": 50250,
    "sub_district_code": 500805,
    "district_code": 5008,
    "province_code": 50
  },
  {
    "sub_district": "สันกำแพง",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501301,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "ทรายมูล",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501302,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "ร้องวัวแดง",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501303,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "บวกค้าง",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501304,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "แช่ช้าง",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501305,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "ออนใต้",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501306,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "ออนเหนือ",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501391,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "บ้านสหกรณ์",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501392,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "ห้วยแก้ว",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501393,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "แม่ปูคา",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501310,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "ห้วยทราย",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501311,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "ต้นเปา",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501312,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "สันกลาง",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501313,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "แม่ทา",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501394,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "ทาเหนือ",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501395,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "ออนกลาง",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501396,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "สันนาเม็ง",
    "district": "สันกำแพง",
    "province": "เชียงใหม่",
    "zipcode": 50210,
    "sub_district_code": 501398,
    "district_code": 5013,
    "province_code": 50
  },
  {
    "sub_district": "สันทรายหลวง",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50210,
    "sub_district_code": 501401,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "สันทรายน้อย",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50210,
    "sub_district_code": 501402,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "สันพระเนตร",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50210,
    "sub_district_code": 501403,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "สันนาเม็ง",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50210,
    "sub_district_code": 501404,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "สันป่าเปา",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50210,
    "sub_district_code": 501405,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "หนองแหย่ง",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50210,
    "sub_district_code": 501406,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "หนองจ๊อม",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50210,
    "sub_district_code": 501407,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "หนองหาร",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50290,
    "sub_district_code": 501408,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "แม่แฝก",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50290,
    "sub_district_code": 501409,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "แม่แฝกใหม่",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50290,
    "sub_district_code": 501410,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "เมืองเล็น",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50210,
    "sub_district_code": 501411,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "ป่าไผ่",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50210,
    "sub_district_code": 501412,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "สันทราย",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50190,
    "sub_district_code": 501497,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "สันกำแพง",
    "district": "สันทราย",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 501498,
    "district_code": 5014,
    "province_code": 50
  },
  {
    "sub_district": "ยุหว่า",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501201,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "สันกลาง",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501202,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "ท่าวังพร้าว",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501203,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "มะขามหลวง",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501204,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "แม่ก๊า",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501205,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "บ้านแม",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501206,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "บ้านกลาง",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501207,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "ทุ่งสะโตก",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501208,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "ทุ่งต้อม",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501210,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "บ้านกาด",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50360,
    "sub_district_code": 501295,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "แม่วิน",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50360,
    "sub_district_code": 501296,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "น้ำบ่อหลวง",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501214,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "หนองหอย",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50000,
    "sub_district_code": 501298,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "มะขุนหวาน",
    "district": "สันป่าตอง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501215,
    "district_code": 5012,
    "province_code": 50
  },
  {
    "sub_district": "ยางเนิ้ง",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501901,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "สารภี",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501902,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "ชมภู",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501903,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "ไชยสถาน",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501904,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "ขัวมุง",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501905,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "หนองแฝก",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501906,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "หนองผึ้ง",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501907,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "ท่ากว้าง",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501908,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "ดอนแก้ว",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501909,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "ท่าวังตาล",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501910,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "สันทราย",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 10140,
    "sub_district_code": 501911,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "ป่าบง",
    "district": "สารภี",
    "province": "เชียงใหม่",
    "zipcode": 50140,
    "sub_district_code": 501912,
    "district_code": 5019,
    "province_code": 50
  },
  {
    "sub_district": "หางดง",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501501,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "หนองแก๋ว",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501502,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "หารแก้ว",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501503,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "หนองตอง",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50340,
    "sub_district_code": 501504,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "ขุนคง",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501505,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "สบแม่ข่า",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501506,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "บ้านแหวน",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501507,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "สันผักหวาน",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501508,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "หนองควาย",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501509,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "บ้านปง",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501510,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "น้ำแพร่",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501511,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "ทุ่งต้อม",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50120,
    "sub_district_code": 501598,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "หนองแก๋ว",
    "district": "หางดง",
    "province": "เชียงใหม่",
    "zipcode": 50230,
    "sub_district_code": 501502,
    "district_code": 5015,
    "province_code": 50
  },
  {
    "sub_district": "อมก๋อย",
    "district": "อมก๋อย",
    "province": "เชียงใหม่",
    "zipcode": 50310,
    "sub_district_code": 501801,
    "district_code": 5018,
    "province_code": 50
  },
  {
    "sub_district": "ยางเปียง",
    "district": "อมก๋อย",
    "province": "เชียงใหม่",
    "zipcode": 50310,
    "sub_district_code": 501802,
    "district_code": 5018,
    "province_code": 50
  },
  {
    "sub_district": "แม่ตื่น",
    "district": "อมก๋อย",
    "province": "เชียงใหม่",
    "zipcode": 50310,
    "sub_district_code": 501803,
    "district_code": 5018,
    "province_code": 50
  },
  {
    "sub_district": "ม่อนจอง",
    "district": "อมก๋อย",
    "province": "เชียงใหม่",
    "zipcode": 50310,
    "sub_district_code": 501804,
    "district_code": 5018,
    "province_code": 50
  },
  {
    "sub_district": "สบโขง",
    "district": "อมก๋อย",
    "province": "เชียงใหม่",
    "zipcode": 50310,
    "sub_district_code": 501805,
    "district_code": 5018,
    "province_code": 50
  },
  {
    "sub_district": "นาเกียน",
    "district": "อมก๋อย",
    "province": "เชียงใหม่",
    "zipcode": 50310,
    "sub_district_code": 501806,
    "district_code": 5018,
    "province_code": 50
  },
  {
    "sub_district": "หางดง",
    "district": "ฮอด",
    "province": "เชียงใหม่",
    "zipcode": 50240,
    "sub_district_code": 501601,
    "district_code": 5016,
    "province_code": 50
  },
  {
    "sub_district": "ฮอด",
    "district": "ฮอด",
    "province": "เชียงใหม่",
    "zipcode": 50240,
    "sub_district_code": 501602,
    "district_code": 5016,
    "province_code": 50
  },
  {
    "sub_district": "บ้านตาล",
    "district": "ฮอด",
    "province": "เชียงใหม่",
    "zipcode": 50240,
    "sub_district_code": 501603,
    "district_code": 5016,
    "province_code": 50
  },
  {
    "sub_district": "บ่อหลวง",
    "district": "ฮอด",
    "province": "เชียงใหม่",
    "zipcode": 50240,
    "sub_district_code": 501604,
    "district_code": 5016,
    "province_code": 50
  },
  {
    "sub_district": "บ่อสลี",
    "district": "ฮอด",
    "province": "เชียงใหม่",
    "zipcode": 50240,
    "sub_district_code": 501605,
    "district_code": 5016,
    "province_code": 50
  },
  {
    "sub_district": "นาคอเรือ",
    "district": "ฮอด",
    "province": "เชียงใหม่",
    "zipcode": 50240,
    "sub_district_code": 501606,
    "district_code": 5016,
    "province_code": 50
  },
  {
    "sub_district": "ดอยเต่า",
    "district": "ดอยเต่า",
    "province": "เชียงใหม่",
    "zipcode": 50260,
    "sub_district_code": 501701,
    "district_code": 5017,
    "province_code": 50
  },
  {
    "sub_district": "ท่าเดื่อ",
    "district": "ดอยเต่า",
    "province": "เชียงใหม่",
    "zipcode": 50260,
    "sub_district_code": 501702,
    "district_code": 5017,
    "province_code": 50
  },
  {
    "sub_district": "มืดกา",
    "district": "ดอยเต่า",
    "province": "เชียงใหม่",
    "zipcode": 50260,
    "sub_district_code": 501703,
    "district_code": 5017,
    "province_code": 50
  },
  {
    "sub_district": "บ้านแอ่น",
    "district": "ดอยเต่า",
    "province": "เชียงใหม่",
    "zipcode": 50260,
    "sub_district_code": 501704,
    "district_code": 5017,
    "province_code": 50
  },
  {
    "sub_district": "นาคอเรือ",
    "district": "ดอยเต่า",
    "province": "เชียงใหม่",
    "zipcode": 50240,
    "sub_district_code": 501798,
    "district_code": 5017,
    "province_code": 50
  },
  {
    "sub_district": "บงตัน",
    "district": "ดอยเต่า",
    "province": "เชียงใหม่",
    "zipcode": 50260,
    "sub_district_code": 501705,
    "district_code": 5017,
    "province_code": 50
  },
  {
    "sub_district": "โปงทุ่ง",
    "district": "ดอยเต่า",
    "province": "เชียงใหม่",
    "zipcode": 50260,
    "sub_district_code": 501706,
    "district_code": 5017,
    "province_code": 50
  },
  {
    "sub_district": "เมืองหลวง",
    "district": "เวียงแหง",
    "province": "เชียงใหม่",
    "zipcode": 50350,
    "sub_district_code": 502098,
    "district_code": 5020,
    "province_code": 50
  },
  {
    "sub_district": "เปียงหลวง",
    "district": "เวียงแหง",
    "province": "เชียงใหม่",
    "zipcode": 50350,
    "sub_district_code": 502002,
    "district_code": 5020,
    "province_code": 50
  },
  {
    "sub_district": "เมืองแหง",
    "district": "เวียงแหง",
    "province": "เชียงใหม่",
    "zipcode": 50350,
    "sub_district_code": 502001,
    "district_code": 5020,
    "province_code": 50
  },
  {
    "sub_district": "แสนไห",
    "district": "เวียงแหง",
    "province": "เชียงใหม่",
    "zipcode": 50350,
    "sub_district_code": 502003,
    "district_code": 5020,
    "province_code": 50
  },
  {
    "sub_district": "หนองบัว",
    "district": "ไชยปราการ",
    "province": "เชียงใหม่",
    "zipcode": 50320,
    "sub_district_code": 502104,
    "district_code": 5021,
    "province_code": 50
  },
  {
    "sub_district": "ปงตำ",
    "district": "ไชยปราการ",
    "province": "เชียงใหม่",
    "zipcode": 50320,
    "sub_district_code": 502101,
    "district_code": 5021,
    "province_code": 50
  },
  {
    "sub_district": "ศรีดงเย็น",
    "district": "ไชยปราการ",
    "province": "เชียงใหม่",
    "zipcode": 50320,
    "sub_district_code": 502102,
    "district_code": 5021,
    "province_code": 50
  },
  {
    "sub_district": "แม่ทะลบ",
    "district": "ไชยปราการ",
    "province": "เชียงใหม่",
    "zipcode": 50320,
    "sub_district_code": 502103,
    "district_code": 5021,
    "province_code": 50
  },
  {
    "sub_district": "แม่สูน",
    "district": "ไชยปราการ",
    "province": "เชียงใหม่",
    "zipcode": 50110,
    "sub_district_code": 502197,
    "district_code": 5021,
    "province_code": 50
  },
  {
    "sub_district": "ทุ่งปี๊",
    "district": "แม่วาง",
    "province": "เชียงใหม่",
    "zipcode": 50360,
    "sub_district_code": 502202,
    "district_code": 5022,
    "province_code": 50
  },
  {
    "sub_district": "บ้านกาด",
    "district": "แม่วาง",
    "province": "เชียงใหม่",
    "zipcode": 50360,
    "sub_district_code": 502201,
    "district_code": 5022,
    "province_code": 50
  },
  {
    "sub_district": "แม่วิน",
    "district": "แม่วาง",
    "province": "เชียงใหม่",
    "zipcode": 50360,
    "sub_district_code": 502204,
    "district_code": 5022,
    "province_code": 50
  },
  {
    "sub_district": "ทุ่งรวงทอง",
    "district": "แม่วาง",
    "province": "เชียงใหม่",
    "zipcode": 50360,
    "sub_district_code": 502203,
    "district_code": 5022,
    "province_code": 50
  },
  {
    "sub_district": "ดอนเปา",
    "district": "แม่วาง",
    "province": "เชียงใหม่",
    "zipcode": 50360,
    "sub_district_code": 502205,
    "district_code": 5022,
    "province_code": 50
  },
  {
    "sub_district": "ดอยหล่อ",
    "district": "ดอยหล่อ",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 502401,
    "district_code": 5024,
    "province_code": 50
  },
  {
    "sub_district": "สองแคว",
    "district": "ดอยหล่อ",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 502402,
    "district_code": 5024,
    "province_code": 50
  },
  {
    "sub_district": "ยางคราม",
    "district": "ดอยหล่อ",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 502403,
    "district_code": 5024,
    "province_code": 50
  },
  {
    "sub_district": "สันติสุข",
    "district": "ดอยหล่อ",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 502404,
    "district_code": 5024,
    "province_code": 50
  },
  {
    "sub_district": "ออนกลาง",
    "district": "แม่ออน",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 502302,
    "district_code": 5023,
    "province_code": 50
  },
  {
    "sub_district": "ออนเหนือ",
    "district": "แม่ออน",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 502301,
    "district_code": 5023,
    "province_code": 50
  },
  {
    "sub_district": "บ้านสหกรณ์",
    "district": "แม่ออน",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 502303,
    "district_code": 5023,
    "province_code": 50
  },
  {
    "sub_district": "สันติสุข",
    "district": "แม่ออน",
    "province": "เชียงใหม่",
    "zipcode": 50160,
    "sub_district_code": 502398,
    "district_code": 5023,
    "province_code": 50
  },
  {
    "sub_district": "ห้วยแก้ว",
    "district": "แม่ออน",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 502304,
    "district_code": 5023,
    "province_code": 50
  },
  {
    "sub_district": "แม่ทา",
    "district": "แม่ออน",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 502305,
    "district_code": 5023,
    "province_code": 50
  },
  {
    "sub_district": "ทาเหนือ",
    "district": "แม่ออน",
    "province": "เชียงใหม่",
    "zipcode": 50130,
    "sub_district_code": 502306,
    "district_code": 5023,
    "province_code": 50
  },
  {
    "sub_district": "แจ่มหลวง",
    "district": "กัลยาณิวัฒนา",
    "province": "เชียงใหม่",
    "zipcode": 58130,
    "sub_district_code": 502503,
    "district_code": 5025,
    "province_code": 50
  },
  {
    "sub_district": "บ้านจันทร์",
    "district": "กัลยาณิวัฒนา",
    "province": "เชียงใหม่",
    "zipcode": 58130,
    "sub_district_code": 502501,
    "district_code": 5025,
    "province_code": 50
  },
  {
    "sub_district": "แม่แดด",
    "district": "กัลยาณิวัฒนา",
    "province": "เชียงใหม่",
    "zipcode": 58130,
    "sub_district_code": 502502,
    "district_code": 5025,
    "province_code": 50
  },
  {
    "sub_district": "ทับเที่ยง",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920101,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "โคกสะบ้า",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920192,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "ละมอ",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920193,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "นาพละ",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920104,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "บ้านควน",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920105,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "นาบินหลา",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920106,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "ควนปริง",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920107,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "นาโยงใต้",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920108,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "บางรัก",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920109,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "โคกหล่อ",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920110,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "นาข้าวเสีย",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920194,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "นาหมื่นศรี",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920195,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "นาโต๊ะหมิง",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920113,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "หนองตรุด",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920114,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "น้ำผุด",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920115,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "นาโยงเหนือ",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920196,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "นาตาล่วง",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920117,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "บ้านโพธิ์",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920118,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "นาท่ามเหนือ",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92190,
    "sub_district_code": 920119,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "นาท่ามใต้",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92190,
    "sub_district_code": 920120,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "ช่อง",
    "district": "เมืองตรัง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920197,
    "district_code": 9201,
    "province_code": 92
  },
  {
    "sub_district": "กันตัง",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920201,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "ควนธานี",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920202,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "บางหมาก",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920203,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "บางเป้า",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920204,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "วังวน",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920205,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "กันตังใต้",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920206,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "โคกยาง",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920207,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "คลองลุ",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920208,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "ย่านซื่อ",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920209,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "บ่อน้ำร้อน",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920210,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "บางสัก",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920211,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "นาเกลือ",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920212,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "เกาะลิบง",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920213,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "คลองชีล้อม",
    "district": "กันตัง",
    "province": "ตรัง",
    "zipcode": 92110,
    "sub_district_code": 920214,
    "district_code": 9202,
    "province_code": 92
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92120,
    "sub_district_code": 920401,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "ทุ่งยาว",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92180,
    "sub_district_code": 920402,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "ปะเหลียน",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92180,
    "sub_district_code": 920403,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "บางด้วน",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920404,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "หาดสำราญ",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92120,
    "sub_district_code": 920496,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "ตะเสะ",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92120,
    "sub_district_code": 920497,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "บ้านนา",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920407,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "ท่าพญา",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920412,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "บ้าหวี",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92120,
    "sub_district_code": 920498,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "สุโสะ",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92120,
    "sub_district_code": 920409,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "ลิพัง",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92180,
    "sub_district_code": 920410,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "เกาะสุกร",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92120,
    "sub_district_code": 920411,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "แหลมสอม",
    "district": "ปะเหลียน",
    "province": "ตรัง",
    "zipcode": 92180,
    "sub_district_code": 920413,
    "district_code": 9204,
    "province_code": 92
  },
  {
    "sub_district": "ย่านตาขาว",
    "district": "ย่านตาขาว",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920301,
    "district_code": 9203,
    "province_code": 92
  },
  {
    "sub_district": "หนองบ่อ",
    "district": "ย่านตาขาว",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920302,
    "district_code": 9203,
    "province_code": 92
  },
  {
    "sub_district": "นาชุมเห็ด",
    "district": "ย่านตาขาว",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920303,
    "district_code": 9203,
    "province_code": 92
  },
  {
    "sub_district": "ในควน",
    "district": "ย่านตาขาว",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920304,
    "district_code": 9203,
    "province_code": 92
  },
  {
    "sub_district": "โพรงจระเข้",
    "district": "ย่านตาขาว",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920305,
    "district_code": 9203,
    "province_code": 92
  },
  {
    "sub_district": "ทุ่งกระบือ",
    "district": "ย่านตาขาว",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920306,
    "district_code": 9203,
    "province_code": 92
  },
  {
    "sub_district": "ทุ่งค่าย",
    "district": "ย่านตาขาว",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920307,
    "district_code": 9203,
    "province_code": 92
  },
  {
    "sub_district": "เกาะเปียะ",
    "district": "ย่านตาขาว",
    "province": "ตรัง",
    "zipcode": 92140,
    "sub_district_code": 920308,
    "district_code": 9203,
    "province_code": 92
  },
  {
    "sub_district": "บ่อหิน",
    "district": "สิเกา",
    "province": "ตรัง",
    "zipcode": 92150,
    "sub_district_code": 920501,
    "district_code": 9205,
    "province_code": 92
  },
  {
    "sub_district": "เขาไม้แก้ว",
    "district": "สิเกา",
    "province": "ตรัง",
    "zipcode": 92150,
    "sub_district_code": 920502,
    "district_code": 9205,
    "province_code": 92
  },
  {
    "sub_district": "กะลาเส",
    "district": "สิเกา",
    "province": "ตรัง",
    "zipcode": 92150,
    "sub_district_code": 920503,
    "district_code": 9205,
    "province_code": 92
  },
  {
    "sub_district": "ไม้ฝาด",
    "district": "สิเกา",
    "province": "ตรัง",
    "zipcode": 92150,
    "sub_district_code": 920504,
    "district_code": 9205,
    "province_code": 92
  },
  {
    "sub_district": "วังมะปราง",
    "district": "สิเกา",
    "province": "ตรัง",
    "zipcode": 92220,
    "sub_district_code": 920595,
    "district_code": 9205,
    "province_code": 92
  },
  {
    "sub_district": "อ่าวตง",
    "district": "สิเกา",
    "province": "ตรัง",
    "zipcode": 92220,
    "sub_district_code": 920596,
    "district_code": 9205,
    "province_code": 92
  },
  {
    "sub_district": "นาเมืองเพชร",
    "district": "สิเกา",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920505,
    "district_code": 9205,
    "province_code": 92
  },
  {
    "sub_district": "เขาวิเศษ",
    "district": "สิเกา",
    "province": "ตรัง",
    "zipcode": 92220,
    "sub_district_code": 920597,
    "district_code": 9205,
    "province_code": 92
  },
  {
    "sub_district": "ท่าสะบ้า",
    "district": "สิเกา",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920598,
    "district_code": 9205,
    "province_code": 92
  },
  {
    "sub_district": "ห้วยยอด",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920601,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "หนองช้างแล่น",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920602,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "หนองปรือ",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920693,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "หนองบัว",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92160,
    "sub_district_code": 920694,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "บางดี",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92210,
    "sub_district_code": 920605,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "บางกุ้ง",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92210,
    "sub_district_code": 920606,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "เขากอบ",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920607,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "เขาขาว",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920608,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "เขาปูน",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920609,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "ปากแจ่ม",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92190,
    "sub_district_code": 920610,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "ปากคม",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920611,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "คลองปาง",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92160,
    "sub_district_code": 920695,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "ควนเมา",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92160,
    "sub_district_code": 920696,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "ท่างิ้ว",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920614,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "ลำภูรา",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92190,
    "sub_district_code": 920615,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "นาวง",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92210,
    "sub_district_code": 920616,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "ห้วยนาง",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920617,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "เขาไพร",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92160,
    "sub_district_code": 920697,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "ในเตา",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920619,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "ทุ่งต่อ",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920620,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "วังคีรี",
    "district": "ห้วยยอด",
    "province": "ตรัง",
    "zipcode": 92210,
    "sub_district_code": 920621,
    "district_code": 9206,
    "province_code": 92
  },
  {
    "sub_district": "เขาวิเศษ",
    "district": "วังวิเศษ",
    "province": "ตรัง",
    "zipcode": 92220,
    "sub_district_code": 920701,
    "district_code": 9207,
    "province_code": 92
  },
  {
    "sub_district": "วังมะปราง",
    "district": "วังวิเศษ",
    "province": "ตรัง",
    "zipcode": 92220,
    "sub_district_code": 920702,
    "district_code": 9207,
    "province_code": 92
  },
  {
    "sub_district": "อ่าวตง",
    "district": "วังวิเศษ",
    "province": "ตรัง",
    "zipcode": 92220,
    "sub_district_code": 920703,
    "district_code": 9207,
    "province_code": 92
  },
  {
    "sub_district": "ท่าสะบ้า",
    "district": "วังวิเศษ",
    "province": "ตรัง",
    "zipcode": 92000,
    "sub_district_code": 920704,
    "district_code": 9207,
    "province_code": 92
  },
  {
    "sub_district": "วังมะปรางเหนือ",
    "district": "วังวิเศษ",
    "province": "ตรัง",
    "zipcode": 92220,
    "sub_district_code": 920705,
    "district_code": 9207,
    "province_code": 92
  },
  {
    "sub_district": "ละมอ",
    "district": "นาโยง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920803,
    "district_code": 9208,
    "province_code": 92
  },
  {
    "sub_district": "นาโยงเหนือ",
    "district": "นาโยง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920801,
    "district_code": 9208,
    "province_code": 92
  },
  {
    "sub_district": "นาหมื่นศรี",
    "district": "นาโยง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920805,
    "district_code": 9208,
    "province_code": 92
  },
  {
    "sub_district": "นาโยงใต้",
    "district": "นาโยง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920898,
    "district_code": 9208,
    "province_code": 92
  },
  {
    "sub_district": "โคกสะบ้า",
    "district": "นาโยง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920804,
    "district_code": 9208,
    "province_code": 92
  },
  {
    "sub_district": "นาข้าวเสีย",
    "district": "นาโยง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920806,
    "district_code": 9208,
    "province_code": 92
  },
  {
    "sub_district": "ช่อง",
    "district": "นาโยง",
    "province": "ตรัง",
    "zipcode": 92170,
    "sub_district_code": 920802,
    "district_code": 9208,
    "province_code": 92
  },
  {
    "sub_district": "คลองปาง",
    "district": "รัษฎา",
    "province": "ตรัง",
    "zipcode": 92160,
    "sub_district_code": 920902,
    "district_code": 9209,
    "province_code": 92
  },
  {
    "sub_district": "หนองบัว",
    "district": "รัษฎา",
    "province": "ตรัง",
    "zipcode": 92160,
    "sub_district_code": 920903,
    "district_code": 9209,
    "province_code": 92
  },
  {
    "sub_district": "ควนเมา",
    "district": "รัษฎา",
    "province": "ตรัง",
    "zipcode": 92160,
    "sub_district_code": 920901,
    "district_code": 9209,
    "province_code": 92
  },
  {
    "sub_district": "หนองปรือ",
    "district": "รัษฎา",
    "province": "ตรัง",
    "zipcode": 92130,
    "sub_district_code": 920904,
    "district_code": 9209,
    "province_code": 92
  },
  {
    "sub_district": "เขาไพร",
    "district": "รัษฎา",
    "province": "ตรัง",
    "zipcode": 92160,
    "sub_district_code": 920905,
    "district_code": 9209,
    "province_code": 92
  },
  {
    "sub_district": "หาดสำราญ",
    "district": "หาดสำราญ",
    "province": "ตรัง",
    "zipcode": 92120,
    "sub_district_code": 921001,
    "district_code": 9210,
    "province_code": 92
  },
  {
    "sub_district": "บ้าหวี",
    "district": "หาดสำราญ",
    "province": "ตรัง",
    "zipcode": 92120,
    "sub_district_code": 921002,
    "district_code": 9210,
    "province_code": 92
  },
  {
    "sub_district": "ตะเสะ",
    "district": "หาดสำราญ",
    "province": "ตรัง",
    "zipcode": 92120,
    "sub_district_code": 921003,
    "district_code": 9210,
    "province_code": 92
  },
  {
    "sub_district": "บางพระ",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230101,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "หนองเสม็ด",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230102,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "หนองโสน",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230103,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "หนองคันทรง",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230104,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "ห้วงน้ำขาว",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230105,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "อ่าวใหญ่",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230106,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "วังกระแจะ",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230107,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "ห้วยแร้ง",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230108,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "เนินทราย",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230109,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "ท่าพริก",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230110,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "ท่ากุ่ม",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230111,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "ตะกาง",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230112,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "ชำราก",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230113,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "แหลมกลัด",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230114,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "หาดเล็ก",
    "district": "เมืองตราด",
    "province": "ตราด",
    "zipcode": 23110,
    "sub_district_code": 230197,
    "district_code": 2301,
    "province_code": 23
  },
  {
    "sub_district": "เขาสมิง",
    "district": "เขาสมิง",
    "province": "ตราด",
    "zipcode": 23130,
    "sub_district_code": 230301,
    "district_code": 2303,
    "province_code": 23
  },
  {
    "sub_district": "แสนตุ้ง",
    "district": "เขาสมิง",
    "province": "ตราด",
    "zipcode": 23150,
    "sub_district_code": 230302,
    "district_code": 2303,
    "province_code": 23
  },
  {
    "sub_district": "วังตะเคียน",
    "district": "เขาสมิง",
    "province": "ตราด",
    "zipcode": 23130,
    "sub_district_code": 230303,
    "district_code": 2303,
    "province_code": 23
  },
  {
    "sub_district": "ท่าโสม",
    "district": "เขาสมิง",
    "province": "ตราด",
    "zipcode": 23150,
    "sub_district_code": 230304,
    "district_code": 2303,
    "province_code": 23
  },
  {
    "sub_district": "สะตอ",
    "district": "เขาสมิง",
    "province": "ตราด",
    "zipcode": 23150,
    "sub_district_code": 230305,
    "district_code": 2303,
    "province_code": 23
  },
  {
    "sub_district": "ประณีต",
    "district": "เขาสมิง",
    "province": "ตราด",
    "zipcode": 23150,
    "sub_district_code": 230306,
    "district_code": 2303,
    "province_code": 23
  },
  {
    "sub_district": "เทพนิมิต",
    "district": "เขาสมิง",
    "province": "ตราด",
    "zipcode": 23150,
    "sub_district_code": 230307,
    "district_code": 2303,
    "province_code": 23
  },
  {
    "sub_district": "ทุ่งนนทรี",
    "district": "เขาสมิง",
    "province": "ตราด",
    "zipcode": 23130,
    "sub_district_code": 230308,
    "district_code": 2303,
    "province_code": 23
  },
  {
    "sub_district": "คลองใหญ่",
    "district": "คลองใหญ่",
    "province": "ตราด",
    "zipcode": 23110,
    "sub_district_code": 230201,
    "district_code": 2302,
    "province_code": 23
  },
  {
    "sub_district": "ไม้รูด",
    "district": "คลองใหญ่",
    "province": "ตราด",
    "zipcode": 23110,
    "sub_district_code": 230202,
    "district_code": 2302,
    "province_code": 23
  },
  {
    "sub_district": "หาดเล็ก",
    "district": "คลองใหญ่",
    "province": "ตราด",
    "zipcode": 23110,
    "sub_district_code": 230203,
    "district_code": 2302,
    "province_code": 23
  },
  {
    "sub_district": "บ่อพลอย",
    "district": "บ่อไร่",
    "province": "ตราด",
    "zipcode": 23140,
    "sub_district_code": 230401,
    "district_code": 2304,
    "province_code": 23
  },
  {
    "sub_district": "ช้างทูน",
    "district": "บ่อไร่",
    "province": "ตราด",
    "zipcode": 23140,
    "sub_district_code": 230402,
    "district_code": 2304,
    "province_code": 23
  },
  {
    "sub_district": "ด่านชุมพล",
    "district": "บ่อไร่",
    "province": "ตราด",
    "zipcode": 23140,
    "sub_district_code": 230403,
    "district_code": 2304,
    "province_code": 23
  },
  {
    "sub_district": "หนองบอน",
    "district": "บ่อไร่",
    "province": "ตราด",
    "zipcode": 23140,
    "sub_district_code": 230404,
    "district_code": 2304,
    "province_code": 23
  },
  {
    "sub_district": "นนทรีย์",
    "district": "บ่อไร่",
    "province": "ตราด",
    "zipcode": 23140,
    "sub_district_code": 230405,
    "district_code": 2304,
    "province_code": 23
  },
  {
    "sub_district": "แหลมงอบ",
    "district": "แหลมงอบ",
    "province": "ตราด",
    "zipcode": 23120,
    "sub_district_code": 230501,
    "district_code": 2305,
    "province_code": 23
  },
  {
    "sub_district": "น้ำเชี่ยว",
    "district": "แหลมงอบ",
    "province": "ตราด",
    "zipcode": 23120,
    "sub_district_code": 230502,
    "district_code": 2305,
    "province_code": 23
  },
  {
    "sub_district": "บางปิด",
    "district": "แหลมงอบ",
    "province": "ตราด",
    "zipcode": 23120,
    "sub_district_code": 230503,
    "district_code": 2305,
    "province_code": 23
  },
  {
    "sub_district": "เกาะช้าง",
    "district": "แหลมงอบ",
    "province": "ตราด",
    "zipcode": 23170,
    "sub_district_code": 230594,
    "district_code": 2305,
    "province_code": 23
  },
  {
    "sub_district": "เกาะหมาก",
    "district": "แหลมงอบ",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230595,
    "district_code": 2305,
    "province_code": 23
  },
  {
    "sub_district": "เกาะกูด",
    "district": "แหลมงอบ",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230596,
    "district_code": 2305,
    "province_code": 23
  },
  {
    "sub_district": "คลองใหญ่",
    "district": "แหลมงอบ",
    "province": "ตราด",
    "zipcode": 23120,
    "sub_district_code": 230507,
    "district_code": 2305,
    "province_code": 23
  },
  {
    "sub_district": "บางปิด",
    "district": "แหลมงอบ",
    "province": "ตราด",
    "zipcode": 23120,
    "sub_district_code": 230597,
    "district_code": 2305,
    "province_code": 23
  },
  {
    "sub_district": "เกาะช้างใต้",
    "district": "แหลมงอบ",
    "province": "ตราด",
    "zipcode": 23170,
    "sub_district_code": 230598,
    "district_code": 2305,
    "province_code": 23
  },
  {
    "sub_district": "เกาะกูด",
    "district": "เกาะกูด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230602,
    "district_code": 2306,
    "province_code": 23
  },
  {
    "sub_district": "เกาะหมาก",
    "district": "เกาะกูด",
    "province": "ตราด",
    "zipcode": 23000,
    "sub_district_code": 230601,
    "district_code": 2306,
    "province_code": 23
  },
  {
    "sub_district": "เกาะช้าง",
    "district": "เกาะช้าง",
    "province": "ตราด",
    "zipcode": 23170,
    "sub_district_code": 230701,
    "district_code": 2307,
    "province_code": 23
  },
  {
    "sub_district": "เกาะช้างใต้",
    "district": "เกาะช้าง",
    "province": "ตราด",
    "zipcode": 23170,
    "sub_district_code": 230702,
    "district_code": 2307,
    "province_code": 23
  },
  {
    "sub_district": "ระแหง",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630101,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "หนองหลวง",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630102,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "เชียงเงิน",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630103,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "หัวเดียด",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630104,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "หนองบัวเหนือ",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630105,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "ไม้งาม",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630106,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "โป่งแดง",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630107,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "น้ำรึม",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630108,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "วังหิน",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630109,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "แม่ท้อ",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630111,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "ป่ามะม่วง",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630112,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "หนองบัวใต้",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630113,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "วังประจบ",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630114,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "ตลุกกลางทุ่ง",
    "district": "เมืองตาก",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630115,
    "district_code": 6301,
    "province_code": 63
  },
  {
    "sub_district": "ท่าสองยาง",
    "district": "ท่าสองยาง",
    "province": "ตาก",
    "zipcode": 63150,
    "sub_district_code": 630501,
    "district_code": 6305,
    "province_code": 63
  },
  {
    "sub_district": "แม่ต้าน",
    "district": "ท่าสองยาง",
    "province": "ตาก",
    "zipcode": 63150,
    "sub_district_code": 630502,
    "district_code": 6305,
    "province_code": 63
  },
  {
    "sub_district": "แม่สอง",
    "district": "ท่าสองยาง",
    "province": "ตาก",
    "zipcode": 63150,
    "sub_district_code": 630503,
    "district_code": 6305,
    "province_code": 63
  },
  {
    "sub_district": "แม่หละ",
    "district": "ท่าสองยาง",
    "province": "ตาก",
    "zipcode": 63150,
    "sub_district_code": 630504,
    "district_code": 6305,
    "province_code": 63
  },
  {
    "sub_district": "แม่วะหลวง",
    "district": "ท่าสองยาง",
    "province": "ตาก",
    "zipcode": 63150,
    "sub_district_code": 630505,
    "district_code": 6305,
    "province_code": 63
  },
  {
    "sub_district": "แม่อุสุ",
    "district": "ท่าสองยาง",
    "province": "ตาก",
    "zipcode": 63150,
    "sub_district_code": 630506,
    "district_code": 6305,
    "province_code": 63
  },
  {
    "sub_district": "ตากออก",
    "district": "บ้านตาก",
    "province": "ตาก",
    "zipcode": 63120,
    "sub_district_code": 630201,
    "district_code": 6302,
    "province_code": 63
  },
  {
    "sub_district": "สมอโคน",
    "district": "บ้านตาก",
    "province": "ตาก",
    "zipcode": 63120,
    "sub_district_code": 630202,
    "district_code": 6302,
    "province_code": 63
  },
  {
    "sub_district": "แม่สลิด",
    "district": "บ้านตาก",
    "province": "ตาก",
    "zipcode": 63120,
    "sub_district_code": 630203,
    "district_code": 6302,
    "province_code": 63
  },
  {
    "sub_district": "ตากตก",
    "district": "บ้านตาก",
    "province": "ตาก",
    "zipcode": 63120,
    "sub_district_code": 630204,
    "district_code": 6302,
    "province_code": 63
  },
  {
    "sub_district": "เกาะตะเภา",
    "district": "บ้านตาก",
    "province": "ตาก",
    "zipcode": 63120,
    "sub_district_code": 630205,
    "district_code": 6302,
    "province_code": 63
  },
  {
    "sub_district": "ทุ่งกระเชาะ",
    "district": "บ้านตาก",
    "province": "ตาก",
    "zipcode": 63120,
    "sub_district_code": 630206,
    "district_code": 6302,
    "province_code": 63
  },
  {
    "sub_district": "ท้องฟ้า",
    "district": "บ้านตาก",
    "province": "ตาก",
    "zipcode": 63120,
    "sub_district_code": 630207,
    "district_code": 6302,
    "province_code": 63
  },
  {
    "sub_district": "แม่ระมาด",
    "district": "แม่ระมาด",
    "province": "ตาก",
    "zipcode": 63140,
    "sub_district_code": 630401,
    "district_code": 6304,
    "province_code": 63
  },
  {
    "sub_district": "แม่จะเรา",
    "district": "แม่ระมาด",
    "province": "ตาก",
    "zipcode": 63140,
    "sub_district_code": 630402,
    "district_code": 6304,
    "province_code": 63
  },
  {
    "sub_district": "ขะเนจื้อ",
    "district": "แม่ระมาด",
    "province": "ตาก",
    "zipcode": 63140,
    "sub_district_code": 630403,
    "district_code": 6304,
    "province_code": 63
  },
  {
    "sub_district": "แม่ตื่น",
    "district": "แม่ระมาด",
    "province": "ตาก",
    "zipcode": 63140,
    "sub_district_code": 630404,
    "district_code": 6304,
    "province_code": 63
  },
  {
    "sub_district": "สามหมื่น",
    "district": "แม่ระมาด",
    "province": "ตาก",
    "zipcode": 63140,
    "sub_district_code": 630405,
    "district_code": 6304,
    "province_code": 63
  },
  {
    "sub_district": "พระธาตุ",
    "district": "แม่ระมาด",
    "province": "ตาก",
    "zipcode": 63140,
    "sub_district_code": 630406,
    "district_code": 6304,
    "province_code": 63
  },
  {
    "sub_district": "แม่สอด",
    "district": "แม่สอด",
    "province": "ตาก",
    "zipcode": 63110,
    "sub_district_code": 630601,
    "district_code": 6306,
    "province_code": 63
  },
  {
    "sub_district": "แม่กุ",
    "district": "แม่สอด",
    "province": "ตาก",
    "zipcode": 63110,
    "sub_district_code": 630602,
    "district_code": 6306,
    "province_code": 63
  },
  {
    "sub_district": "พะวอ",
    "district": "แม่สอด",
    "province": "ตาก",
    "zipcode": 63110,
    "sub_district_code": 630603,
    "district_code": 6306,
    "province_code": 63
  },
  {
    "sub_district": "แม่ตาว",
    "district": "แม่สอด",
    "province": "ตาก",
    "zipcode": 63110,
    "sub_district_code": 630604,
    "district_code": 6306,
    "province_code": 63
  },
  {
    "sub_district": "แม่กาษา",
    "district": "แม่สอด",
    "province": "ตาก",
    "zipcode": 63110,
    "sub_district_code": 630605,
    "district_code": 6306,
    "province_code": 63
  },
  {
    "sub_district": "ท่าสายลวด",
    "district": "แม่สอด",
    "province": "ตาก",
    "zipcode": 63110,
    "sub_district_code": 630606,
    "district_code": 6306,
    "province_code": 63
  },
  {
    "sub_district": "แม่ปะ",
    "district": "แม่สอด",
    "province": "ตาก",
    "zipcode": 63110,
    "sub_district_code": 630607,
    "district_code": 6306,
    "province_code": 63
  },
  {
    "sub_district": "มหาวัน",
    "district": "แม่สอด",
    "province": "ตาก",
    "zipcode": 63110,
    "sub_district_code": 630608,
    "district_code": 6306,
    "province_code": 63
  },
  {
    "sub_district": "ด่านแม่ละเมา",
    "district": "แม่สอด",
    "province": "ตาก",
    "zipcode": 63110,
    "sub_district_code": 630609,
    "district_code": 6306,
    "province_code": 63
  },
  {
    "sub_district": "พระธาตุผาแดง",
    "district": "แม่สอด",
    "province": "ตาก",
    "zipcode": 63110,
    "sub_district_code": 630610,
    "district_code": 6306,
    "province_code": 63
  },
  {
    "sub_district": "สามเงา",
    "district": "สามเงา",
    "province": "ตาก",
    "zipcode": 63130,
    "sub_district_code": 630301,
    "district_code": 6303,
    "province_code": 63
  },
  {
    "sub_district": "วังหมัน",
    "district": "สามเงา",
    "province": "ตาก",
    "zipcode": 63130,
    "sub_district_code": 630302,
    "district_code": 6303,
    "province_code": 63
  },
  {
    "sub_district": "ยกกระบัตร",
    "district": "สามเงา",
    "province": "ตาก",
    "zipcode": 63130,
    "sub_district_code": 630303,
    "district_code": 6303,
    "province_code": 63
  },
  {
    "sub_district": "ย่านรี",
    "district": "สามเงา",
    "province": "ตาก",
    "zipcode": 63130,
    "sub_district_code": 630304,
    "district_code": 6303,
    "province_code": 63
  },
  {
    "sub_district": "บ้านนา",
    "district": "สามเงา",
    "province": "ตาก",
    "zipcode": 63130,
    "sub_district_code": 630305,
    "district_code": 6303,
    "province_code": 63
  },
  {
    "sub_district": "วังจันทร์",
    "district": "สามเงา",
    "province": "ตาก",
    "zipcode": 63130,
    "sub_district_code": 630306,
    "district_code": 6303,
    "province_code": 63
  },
  {
    "sub_district": "อุ้มผาง",
    "district": "อุ้มผาง",
    "province": "ตาก",
    "zipcode": 63170,
    "sub_district_code": 630801,
    "district_code": 6308,
    "province_code": 63
  },
  {
    "sub_district": "หนองหลวง",
    "district": "อุ้มผาง",
    "province": "ตาก",
    "zipcode": 63170,
    "sub_district_code": 630802,
    "district_code": 6308,
    "province_code": 63
  },
  {
    "sub_district": "โมโกร",
    "district": "อุ้มผาง",
    "province": "ตาก",
    "zipcode": 63170,
    "sub_district_code": 630803,
    "district_code": 6308,
    "province_code": 63
  },
  {
    "sub_district": "แม่จัน",
    "district": "อุ้มผาง",
    "province": "ตาก",
    "zipcode": 63170,
    "sub_district_code": 630804,
    "district_code": 6308,
    "province_code": 63
  },
  {
    "sub_district": "แม่ละมุ้ง",
    "district": "อุ้มผาง",
    "province": "ตาก",
    "zipcode": 63170,
    "sub_district_code": 630805,
    "district_code": 6308,
    "province_code": 63
  },
  {
    "sub_district": "แม่กลอง",
    "district": "อุ้มผาง",
    "province": "ตาก",
    "zipcode": 63170,
    "sub_district_code": 630806,
    "district_code": 6308,
    "province_code": 63
  },
  {
    "sub_district": "พบพระ",
    "district": "พบพระ",
    "province": "ตาก",
    "zipcode": 63160,
    "sub_district_code": 630701,
    "district_code": 6307,
    "province_code": 63
  },
  {
    "sub_district": "ช่องแคบ",
    "district": "พบพระ",
    "province": "ตาก",
    "zipcode": 63160,
    "sub_district_code": 630702,
    "district_code": 6307,
    "province_code": 63
  },
  {
    "sub_district": "คีรีราษฎร์",
    "district": "พบพระ",
    "province": "ตาก",
    "zipcode": 63160,
    "sub_district_code": 630703,
    "district_code": 6307,
    "province_code": 63
  },
  {
    "sub_district": "วาเล่ย์",
    "district": "พบพระ",
    "province": "ตาก",
    "zipcode": 63160,
    "sub_district_code": 630704,
    "district_code": 6307,
    "province_code": 63
  },
  {
    "sub_district": "รวมไทยพัฒนา",
    "district": "พบพระ",
    "province": "ตาก",
    "zipcode": 63160,
    "sub_district_code": 630705,
    "district_code": 6307,
    "province_code": 63
  },
  {
    "sub_district": "ประดาง",
    "district": "วังเจ้า",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630903,
    "district_code": 6309,
    "province_code": 63
  },
  {
    "sub_district": "เชียงทอง",
    "district": "วังเจ้า",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630901,
    "district_code": 6309,
    "province_code": 63
  },
  {
    "sub_district": "นาโบสถ์",
    "district": "วังเจ้า",
    "province": "ตาก",
    "zipcode": 63000,
    "sub_district_code": 630902,
    "district_code": 6309,
    "province_code": 63
  },
  {
    "sub_district": "นครนายก",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260101,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260102,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "บ้านใหญ่",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260103,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "วังกระโจม",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260104,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "ท่าทราย",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260105,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "ดอนยอ",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260106,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "ศรีจุฬา",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260107,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "ดงละคร",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260108,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "ศรีนาวา",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260109,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "สาริกา",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260110,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "หินตั้ง",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260111,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "เขาพระ",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260112,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "พรหมณี",
    "district": "เมืองนครนายก",
    "province": "นครนายก",
    "zipcode": 26000,
    "sub_district_code": 260113,
    "district_code": 2601,
    "province_code": 26
  },
  {
    "sub_district": "บ้านนา",
    "district": "บ้านนา",
    "province": "นครนายก",
    "zipcode": 26110,
    "sub_district_code": 260301,
    "district_code": 2603,
    "province_code": 26
  },
  {
    "sub_district": "บ้านพร้าว",
    "district": "บ้านนา",
    "province": "นครนายก",
    "zipcode": 26110,
    "sub_district_code": 260302,
    "district_code": 2603,
    "province_code": 26
  },
  {
    "sub_district": "บ้านพริก",
    "district": "บ้านนา",
    "province": "นครนายก",
    "zipcode": 26110,
    "sub_district_code": 260303,
    "district_code": 2603,
    "province_code": 26
  },
  {
    "sub_district": "อาษา",
    "district": "บ้านนา",
    "province": "นครนายก",
    "zipcode": 26110,
    "sub_district_code": 260304,
    "district_code": 2603,
    "province_code": 26
  },
  {
    "sub_district": "ทองหลาง",
    "district": "บ้านนา",
    "province": "นครนายก",
    "zipcode": 26110,
    "sub_district_code": 260305,
    "district_code": 2603,
    "province_code": 26
  },
  {
    "sub_district": "บางอ้อ",
    "district": "บ้านนา",
    "province": "นครนายก",
    "zipcode": 26110,
    "sub_district_code": 260306,
    "district_code": 2603,
    "province_code": 26
  },
  {
    "sub_district": "พิกุลออก",
    "district": "บ้านนา",
    "province": "นครนายก",
    "zipcode": 26110,
    "sub_district_code": 260307,
    "district_code": 2603,
    "province_code": 26
  },
  {
    "sub_district": "ป่าขะ",
    "district": "บ้านนา",
    "province": "นครนายก",
    "zipcode": 26110,
    "sub_district_code": 260308,
    "district_code": 2603,
    "province_code": 26
  },
  {
    "sub_district": "เขาเพิ่ม",
    "district": "บ้านนา",
    "province": "นครนายก",
    "zipcode": 26110,
    "sub_district_code": 260309,
    "district_code": 2603,
    "province_code": 26
  },
  {
    "sub_district": "ศรีกะอาง",
    "district": "บ้านนา",
    "province": "นครนายก",
    "zipcode": 26110,
    "sub_district_code": 260310,
    "district_code": 2603,
    "province_code": 26
  },
  {
    "sub_district": "เกาะหวาย",
    "district": "ปากพลี",
    "province": "นครนายก",
    "zipcode": 26130,
    "sub_district_code": 260201,
    "district_code": 2602,
    "province_code": 26
  },
  {
    "sub_district": "เกาะโพธิ์",
    "district": "ปากพลี",
    "province": "นครนายก",
    "zipcode": 26130,
    "sub_district_code": 260202,
    "district_code": 2602,
    "province_code": 26
  },
  {
    "sub_district": "ปากพลี",
    "district": "ปากพลี",
    "province": "นครนายก",
    "zipcode": 26130,
    "sub_district_code": 260203,
    "district_code": 2602,
    "province_code": 26
  },
  {
    "sub_district": "โคกกรวด",
    "district": "ปากพลี",
    "province": "นครนายก",
    "zipcode": 26130,
    "sub_district_code": 260204,
    "district_code": 2602,
    "province_code": 26
  },
  {
    "sub_district": "ท่าเรือ",
    "district": "ปากพลี",
    "province": "นครนายก",
    "zipcode": 26130,
    "sub_district_code": 260205,
    "district_code": 2602,
    "province_code": 26
  },
  {
    "sub_district": "หนองแสง",
    "district": "ปากพลี",
    "province": "นครนายก",
    "zipcode": 26130,
    "sub_district_code": 260206,
    "district_code": 2602,
    "province_code": 26
  },
  {
    "sub_district": "นาหินลาด",
    "district": "ปากพลี",
    "province": "นครนายก",
    "zipcode": 26130,
    "sub_district_code": 260207,
    "district_code": 2602,
    "province_code": 26
  },
  {
    "sub_district": "พระอาจารย์",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260401,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "บึงศาล",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260402,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "ศีรษะกระบือ",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260403,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "โพธิ์แทน",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260404,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "บางสมบูรณ์",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260405,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "ทรายมูล",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260406,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "บางปลากด",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260407,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "บางลูกเสือ",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260408,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "องครักษ์",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260409,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "ชุมพล",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260410,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "คลองใหญ่",
    "district": "องครักษ์",
    "province": "นครนายก",
    "zipcode": 26120,
    "sub_district_code": 260411,
    "district_code": 2604,
    "province_code": 26
  },
  {
    "sub_district": "พระปฐมเจดีย์",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730101,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "บางแขม",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730102,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "พระประโทน",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730103,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "ธรรมศาลา",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730104,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "ตาก้อง",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730105,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "มาบแค",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730106,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "สนามจันทร์",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730107,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "ดอนยายหอม",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730108,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "ถนนขาด",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730109,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "บ่อพลับ",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730110,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "นครปฐม",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730111,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "วังตะกู",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730112,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "หนองปากโลง",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730113,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "สามควายเผือก",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730114,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "ทุ่งน้อย",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730115,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "หนองดินแดง",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730116,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "วังเย็น",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730117,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "โพรงมะเดื่อ",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730118,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "ลำพยา",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730119,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "สระกะเทียม",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730120,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "สวนป่าน",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730121,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "ห้วยจรเข้",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730122,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "ทัพหลวง",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730123,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "หนองงูเหลือม",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730124,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "บ้านยาง",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730125,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "พระประโทน",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73000,
    "sub_district_code": 730103,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "ดอนตูม",
    "district": "เมืองนครปฐม",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730197,
    "district_code": 7301,
    "province_code": 73
  },
  {
    "sub_district": "ทุ่งกระพังโหม",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730201,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "กระตีบ",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73180,
    "sub_district_code": 730202,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "ทุ่งลูกนก",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730203,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "ห้วยขวาง",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730204,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "ทุ่งขวาง",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730205,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "สระสี่มุม",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730206,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "ทุ่งบัว",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730207,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "ดอนข่อย",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730208,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "สระพัฒนา",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73180,
    "sub_district_code": 730209,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "ห้วยหมอนทอง",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730210,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "กำแพงแสน",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730212,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "วังน้ำเขียว",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730215,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "รางพิกุล",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730213,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "ห้วยม่วง",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73180,
    "sub_district_code": 730211,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "หนองกระทุ่ม",
    "district": "กำแพงแสน",
    "province": "นครปฐม",
    "zipcode": 73140,
    "sub_district_code": 730214,
    "district_code": 7302,
    "province_code": 73
  },
  {
    "sub_district": "สามง่าม",
    "district": "ดอนตูม",
    "province": "นครปฐม",
    "zipcode": 73150,
    "sub_district_code": 730401,
    "district_code": 7304,
    "province_code": 73
  },
  {
    "sub_district": "ห้วยพระ",
    "district": "ดอนตูม",
    "province": "นครปฐม",
    "zipcode": 73150,
    "sub_district_code": 730402,
    "district_code": 7304,
    "province_code": 73
  },
  {
    "sub_district": "ลำเหย",
    "district": "ดอนตูม",
    "province": "นครปฐม",
    "zipcode": 73150,
    "sub_district_code": 730403,
    "district_code": 7304,
    "province_code": 73
  },
  {
    "sub_district": "ดอนพุทรา",
    "district": "ดอนตูม",
    "province": "นครปฐม",
    "zipcode": 73150,
    "sub_district_code": 730404,
    "district_code": 7304,
    "province_code": 73
  },
  {
    "sub_district": "บ้านหลวง",
    "district": "ดอนตูม",
    "province": "นครปฐม",
    "zipcode": 73150,
    "sub_district_code": 730405,
    "district_code": 7304,
    "province_code": 73
  },
  {
    "sub_district": "ดอนรวก",
    "district": "ดอนตูม",
    "province": "นครปฐม",
    "zipcode": 73150,
    "sub_district_code": 730406,
    "district_code": 7304,
    "province_code": 73
  },
  {
    "sub_district": "ห้วยด้วน",
    "district": "ดอนตูม",
    "province": "นครปฐม",
    "zipcode": 73150,
    "sub_district_code": 730407,
    "district_code": 7304,
    "province_code": 73
  },
  {
    "sub_district": "ลำลูกบัว",
    "district": "ดอนตูม",
    "province": "นครปฐม",
    "zipcode": 73150,
    "sub_district_code": 730408,
    "district_code": 7304,
    "province_code": 73
  },
  {
    "sub_district": "นครชัยศรี",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730301,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "บางกระเบา",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730302,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "วัดแค",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730303,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ท่าตำหนัก",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730304,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "บางแก้ว",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730305,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ท่ากระชับ",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730306,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ขุนแก้ว",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730307,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ท่าพระยา",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730308,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "พะเนียด",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730309,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "บางระกำ",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730310,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "โคกพระเจดีย์",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730311,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ศรีษะทอง",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730312,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "แหลมบัว",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730313,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ศรีมหาโพธิ์",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730314,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "สัมปทวน",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730315,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "วัดสำโรง",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730316,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ดอนแฝก",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730317,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ห้วยพลู",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730318,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "วัดละมุด",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730319,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "บางพระ",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730320,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "บางแก้วฟ้า",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730321,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ลานตากฟ้า",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730322,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "งิ้วราย",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730323,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ไทยาวาส",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73120,
    "sub_district_code": 730324,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "ศาลายา",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73170,
    "sub_district_code": 730396,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "มหาสวัสดิ์",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73170,
    "sub_district_code": 730397,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "คลองโยง",
    "district": "นครชัยศรี",
    "province": "นครปฐม",
    "zipcode": 73170,
    "sub_district_code": 730398,
    "district_code": 7303,
    "province_code": 73
  },
  {
    "sub_district": "บางเลน",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730501,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "บางปลา",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730502,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "บางหลวง",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73190,
    "sub_district_code": 730503,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "บางภาษี",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730504,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "บางระกำ",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730505,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "บางไทรป่า",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730506,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "หินมูล",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73190,
    "sub_district_code": 730507,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "ไทรงาม",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730508,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "ดอนตูม",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730509,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "นิลเพชร",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730510,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "บัวปากท่า",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730511,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "คลองนกกระทุง",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730512,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "นราภิรมย์",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730513,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "ลำพญา",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730514,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "ไผ่หูช้าง",
    "district": "บางเลน",
    "province": "นครปฐม",
    "zipcode": 73130,
    "sub_district_code": 730515,
    "district_code": 7305,
    "province_code": 73
  },
  {
    "sub_district": "สามพราน",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73110,
    "sub_district_code": 730606,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "บางช้าง",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73110,
    "sub_district_code": 730607,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "ไร่ขิง",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73210,
    "sub_district_code": 730608,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "ท่าตลาด",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73110,
    "sub_district_code": 730609,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "กระทุ่มล้ม",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73220,
    "sub_district_code": 730610,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "คลองใหม่",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73110,
    "sub_district_code": 730611,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "ตลาดจินดา",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73110,
    "sub_district_code": 730612,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "คลองจินดา",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73110,
    "sub_district_code": 730613,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "ยายชา",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73110,
    "sub_district_code": 730614,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73110,
    "sub_district_code": 730615,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "อ้อมใหญ่",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73160,
    "sub_district_code": 730616,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73110,
    "sub_district_code": 730601,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "ทรงคนอง",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73210,
    "sub_district_code": 730602,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "หอมเกร็ด",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73110,
    "sub_district_code": 730603,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "บางกระทึก",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73210,
    "sub_district_code": 730604,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "บางเตย",
    "district": "สามพราน",
    "province": "นครปฐม",
    "zipcode": 73210,
    "sub_district_code": 730605,
    "district_code": 7306,
    "province_code": 73
  },
  {
    "sub_district": "ศาลายา",
    "district": "พุทธมณฑล",
    "province": "นครปฐม",
    "zipcode": 73170,
    "sub_district_code": 730701,
    "district_code": 7307,
    "province_code": 73
  },
  {
    "sub_district": "คลองโยง",
    "district": "พุทธมณฑล",
    "province": "นครปฐม",
    "zipcode": 73170,
    "sub_district_code": 730702,
    "district_code": 7307,
    "province_code": 73
  },
  {
    "sub_district": "มหาสวัสดิ์",
    "district": "พุทธมณฑล",
    "province": "นครปฐม",
    "zipcode": 73170,
    "sub_district_code": 730703,
    "district_code": 7307,
    "province_code": 73
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480101,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "หนองแสง",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480102,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "นาทราย",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480103,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "นาราชควาย",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480104,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "กุรุคุ",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480105,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "บ้านผึ้ง",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480106,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "อาจสามารถ",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480107,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "ขามเฒ่า",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480108,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "บ้านกลาง",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480109,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "ท่าค้อ",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480110,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "คำเตย",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480111,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "หนองญาติ",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480112,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "ดงขวาง",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480113,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "โพธิ์ตาก",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480115,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "บ้านแก้ง",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480198,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "วังตามัว",
    "district": "เมืองนครพนม",
    "province": "นครพนม",
    "zipcode": 48000,
    "sub_district_code": 480114,
    "district_code": 4801,
    "province_code": 48
  },
  {
    "sub_district": "ท่าอุเทน",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480301,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "โนนตาล",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480302,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "ท่าจำปา",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480303,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "ไชยบุรี",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480304,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "พนอม",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480305,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "พะทาย",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480306,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "นาขมิ้น",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480398,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "โพนบก",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480397,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "โพนสวรรค์",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480396,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "บ้านค้อ",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480395,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "เวินพระบาท",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480311,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "รามราช",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480312,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "หนองเทา",
    "district": "ท่าอุเทน",
    "province": "นครพนม",
    "zipcode": 48120,
    "sub_district_code": 480314,
    "district_code": 4803,
    "province_code": 48
  },
  {
    "sub_district": "ธาตุพนม",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480501,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "ฝั่งแดง",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480502,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "โพนแพง",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480503,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "พระกลางทุ่ง",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480504,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "นาถ่อน",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480505,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "แสนพัน",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480506,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "ดอนนางหงส์",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480507,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "น้ำก่ำ",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480508,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "อุ่มเหม้า",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480509,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "นาหนาด",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480510,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "กุดฉิม",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480511,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "ธาตุพนมเหนือ",
    "district": "ธาตุพนม",
    "province": "นครพนม",
    "zipcode": 48110,
    "sub_district_code": 480512,
    "district_code": 4805,
    "province_code": 48
  },
  {
    "sub_district": "นาแก",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480701,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "พระซอง",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480702,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "หนองสังข์",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480703,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "นาคู่",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480704,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "พิมาน",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480705,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "พุ่มแก",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480706,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "ก้านเหลือง",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480707,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "หนองบ่อ",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480708,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "นาเลียง",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480709,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "โคกสี",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480798,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "วังยาง",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480797,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "บ้านแก้ง",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480712,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "คำพี้",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480713,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "สีชมพู",
    "district": "นาแก",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 480715,
    "district_code": 4807,
    "province_code": 48
  },
  {
    "sub_district": "นาหว้า",
    "district": "นาหว้า",
    "province": "นครพนม",
    "zipcode": 48180,
    "sub_district_code": 480901,
    "district_code": 4809,
    "province_code": 48
  },
  {
    "sub_district": "นางัว",
    "district": "นาหว้า",
    "province": "นครพนม",
    "zipcode": 48180,
    "sub_district_code": 480902,
    "district_code": 4809,
    "province_code": 48
  },
  {
    "sub_district": "บ้านเสียว",
    "district": "นาหว้า",
    "province": "นครพนม",
    "zipcode": 48180,
    "sub_district_code": 480903,
    "district_code": 4809,
    "province_code": 48
  },
  {
    "sub_district": "นาคูณใหญ่",
    "district": "นาหว้า",
    "province": "นครพนม",
    "zipcode": 48180,
    "sub_district_code": 480904,
    "district_code": 4809,
    "province_code": 48
  },
  {
    "sub_district": "เหล่าพัฒนา",
    "district": "นาหว้า",
    "province": "นครพนม",
    "zipcode": 48180,
    "sub_district_code": 480905,
    "district_code": 4809,
    "province_code": 48
  },
  {
    "sub_district": "ท่าเรือ",
    "district": "นาหว้า",
    "province": "นครพนม",
    "zipcode": 48180,
    "sub_district_code": 480906,
    "district_code": 4809,
    "province_code": 48
  },
  {
    "sub_district": "บ้านแพง",
    "district": "บ้านแพง",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 480401,
    "district_code": 4804,
    "province_code": 48
  },
  {
    "sub_district": "ไผ่ล้อม",
    "district": "บ้านแพง",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 480402,
    "district_code": 4804,
    "province_code": 48
  },
  {
    "sub_district": "โพนทอง",
    "district": "บ้านแพง",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 480403,
    "district_code": 4804,
    "province_code": 48
  },
  {
    "sub_district": "หนองแวง",
    "district": "บ้านแพง",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 480404,
    "district_code": 4804,
    "province_code": 48
  },
  {
    "sub_district": "นาทม",
    "district": "บ้านแพง",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 480498,
    "district_code": 4804,
    "province_code": 48
  },
  {
    "sub_district": "หนองซน",
    "district": "บ้านแพง",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 480497,
    "district_code": 4804,
    "province_code": 48
  },
  {
    "sub_district": "ดอนเตย",
    "district": "บ้านแพง",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 480496,
    "district_code": 4804,
    "province_code": 48
  },
  {
    "sub_district": "นางัว",
    "district": "บ้านแพง",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 480408,
    "district_code": 4804,
    "province_code": 48
  },
  {
    "sub_district": "นาเข",
    "district": "บ้านแพง",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 480409,
    "district_code": 4804,
    "province_code": 48
  },
  {
    "sub_district": "ปลาปาก",
    "district": "ปลาปาก",
    "province": "นครพนม",
    "zipcode": 48160,
    "sub_district_code": 480201,
    "district_code": 4802,
    "province_code": 48
  },
  {
    "sub_district": "หนองฮี",
    "district": "ปลาปาก",
    "province": "นครพนม",
    "zipcode": 48160,
    "sub_district_code": 480202,
    "district_code": 4802,
    "province_code": 48
  },
  {
    "sub_district": "กุตาไก้",
    "district": "ปลาปาก",
    "province": "นครพนม",
    "zipcode": 48160,
    "sub_district_code": 480203,
    "district_code": 4802,
    "province_code": 48
  },
  {
    "sub_district": "โคกสว่าง",
    "district": "ปลาปาก",
    "province": "นครพนม",
    "zipcode": 48160,
    "sub_district_code": 480204,
    "district_code": 4802,
    "province_code": 48
  },
  {
    "sub_district": "โคกสูง",
    "district": "ปลาปาก",
    "province": "นครพนม",
    "zipcode": 48160,
    "sub_district_code": 480205,
    "district_code": 4802,
    "province_code": 48
  },
  {
    "sub_district": "มหาชัย",
    "district": "ปลาปาก",
    "province": "นครพนม",
    "zipcode": 48160,
    "sub_district_code": 480206,
    "district_code": 4802,
    "province_code": 48
  },
  {
    "sub_district": "นามะเขือ",
    "district": "ปลาปาก",
    "province": "นครพนม",
    "zipcode": 48160,
    "sub_district_code": 480207,
    "district_code": 4802,
    "province_code": 48
  },
  {
    "sub_district": "หนองเทาใหญ่",
    "district": "ปลาปาก",
    "province": "นครพนม",
    "zipcode": 48160,
    "sub_district_code": 480208,
    "district_code": 4802,
    "province_code": 48
  },
  {
    "sub_district": "เรณู",
    "district": "เรณูนคร",
    "province": "นครพนม",
    "zipcode": 48170,
    "sub_district_code": 480601,
    "district_code": 4806,
    "province_code": 48
  },
  {
    "sub_district": "โพนทอง",
    "district": "เรณูนคร",
    "province": "นครพนม",
    "zipcode": 48170,
    "sub_district_code": 480602,
    "district_code": 4806,
    "province_code": 48
  },
  {
    "sub_district": "ท่าลาด",
    "district": "เรณูนคร",
    "province": "นครพนม",
    "zipcode": 48170,
    "sub_district_code": 480603,
    "district_code": 4806,
    "province_code": 48
  },
  {
    "sub_district": "นางาม",
    "district": "เรณูนคร",
    "province": "นครพนม",
    "zipcode": 48170,
    "sub_district_code": 480604,
    "district_code": 4806,
    "province_code": 48
  },
  {
    "sub_district": "โคกหินแฮ่",
    "district": "เรณูนคร",
    "province": "นครพนม",
    "zipcode": 48170,
    "sub_district_code": 480605,
    "district_code": 4806,
    "province_code": 48
  },
  {
    "sub_district": "เรณูนคร",
    "district": "เรณูนคร",
    "province": "นครพนม",
    "zipcode": 48170,
    "sub_district_code": 480698,
    "district_code": 4806,
    "province_code": 48
  },
  {
    "sub_district": "หนองย่างชิ้น",
    "district": "เรณูนคร",
    "province": "นครพนม",
    "zipcode": 48170,
    "sub_district_code": 480607,
    "district_code": 4806,
    "province_code": 48
  },
  {
    "sub_district": "เรณูใต้",
    "district": "เรณูนคร",
    "province": "นครพนม",
    "zipcode": 48170,
    "sub_district_code": 480608,
    "district_code": 4806,
    "province_code": 48
  },
  {
    "sub_district": "นาขาม",
    "district": "เรณูนคร",
    "province": "นครพนม",
    "zipcode": 48170,
    "sub_district_code": 480609,
    "district_code": 4806,
    "province_code": 48
  },
  {
    "sub_district": "ศรีสงคราม",
    "district": "ศรีสงคราม",
    "province": "นครพนม",
    "zipcode": 48150,
    "sub_district_code": 480801,
    "district_code": 4808,
    "province_code": 48
  },
  {
    "sub_district": "นาเดื่อ",
    "district": "ศรีสงคราม",
    "province": "นครพนม",
    "zipcode": 48150,
    "sub_district_code": 480802,
    "district_code": 4808,
    "province_code": 48
  },
  {
    "sub_district": "บ้านเอื้อง",
    "district": "ศรีสงคราม",
    "province": "นครพนม",
    "zipcode": 48150,
    "sub_district_code": 480803,
    "district_code": 4808,
    "province_code": 48
  },
  {
    "sub_district": "สามผง",
    "district": "ศรีสงคราม",
    "province": "นครพนม",
    "zipcode": 48150,
    "sub_district_code": 480804,
    "district_code": 4808,
    "province_code": 48
  },
  {
    "sub_district": "ท่าบ่อสงคราม",
    "district": "ศรีสงคราม",
    "province": "นครพนม",
    "zipcode": 48150,
    "sub_district_code": 480805,
    "district_code": 4808,
    "province_code": 48
  },
  {
    "sub_district": "บ้านข่า",
    "district": "ศรีสงคราม",
    "province": "นครพนม",
    "zipcode": 48150,
    "sub_district_code": 480806,
    "district_code": 4808,
    "province_code": 48
  },
  {
    "sub_district": "นาคำ",
    "district": "ศรีสงคราม",
    "province": "นครพนม",
    "zipcode": 48150,
    "sub_district_code": 480807,
    "district_code": 4808,
    "province_code": 48
  },
  {
    "sub_district": "โพนสว่าง",
    "district": "ศรีสงคราม",
    "province": "นครพนม",
    "zipcode": 48150,
    "sub_district_code": 480808,
    "district_code": 4808,
    "province_code": 48
  },
  {
    "sub_district": "หาดแพง",
    "district": "ศรีสงคราม",
    "province": "นครพนม",
    "zipcode": 48150,
    "sub_district_code": 480809,
    "district_code": 4808,
    "province_code": 48
  },
  {
    "sub_district": "โพนสวรรค์",
    "district": "โพนสวรรค์",
    "province": "นครพนม",
    "zipcode": 48190,
    "sub_district_code": 481001,
    "district_code": 4810,
    "province_code": 48
  },
  {
    "sub_district": "นาหัวบ่อ",
    "district": "โพนสวรรค์",
    "province": "นครพนม",
    "zipcode": 48190,
    "sub_district_code": 481002,
    "district_code": 4810,
    "province_code": 48
  },
  {
    "sub_district": "นาขมิ้น",
    "district": "โพนสวรรค์",
    "province": "นครพนม",
    "zipcode": 48190,
    "sub_district_code": 481003,
    "district_code": 4810,
    "province_code": 48
  },
  {
    "sub_district": "โพนบก",
    "district": "โพนสวรรค์",
    "province": "นครพนม",
    "zipcode": 48190,
    "sub_district_code": 481004,
    "district_code": 4810,
    "province_code": 48
  },
  {
    "sub_district": "บ้านค้อ",
    "district": "โพนสวรรค์",
    "province": "นครพนม",
    "zipcode": 48190,
    "sub_district_code": 481005,
    "district_code": 4810,
    "province_code": 48
  },
  {
    "sub_district": "โพนจาน",
    "district": "โพนสวรรค์",
    "province": "นครพนม",
    "zipcode": 48190,
    "sub_district_code": 481006,
    "district_code": 4810,
    "province_code": 48
  },
  {
    "sub_district": "นาใน",
    "district": "โพนสวรรค์",
    "province": "นครพนม",
    "zipcode": 48190,
    "sub_district_code": 481007,
    "district_code": 4810,
    "province_code": 48
  },
  {
    "sub_district": "ดอนเตย",
    "district": "นาทม",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 481103,
    "district_code": 4811,
    "province_code": 48
  },
  {
    "sub_district": "นาทม",
    "district": "นาทม",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 481101,
    "district_code": 4811,
    "province_code": 48
  },
  {
    "sub_district": "หนองซน",
    "district": "นาทม",
    "province": "นครพนม",
    "zipcode": 48140,
    "sub_district_code": 481102,
    "district_code": 4811,
    "province_code": 48
  },
  {
    "sub_district": "วังยาง",
    "district": "วังยาง",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 481201,
    "district_code": 4812,
    "province_code": 48
  },
  {
    "sub_district": "โคกสี",
    "district": "วังยาง",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 481202,
    "district_code": 4812,
    "province_code": 48
  },
  {
    "sub_district": "ยอดชาด",
    "district": "วังยาง",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 481203,
    "district_code": 4812,
    "province_code": 48
  },
  {
    "sub_district": "หนองโพธิ์",
    "district": "วังยาง",
    "province": "นครพนม",
    "zipcode": 48130,
    "sub_district_code": 481204,
    "district_code": 4812,
    "province_code": 48
  },
  {
    "sub_district": "จอมสุรางค์",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30001,
    "sub_district_code": 300198,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300101,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "โพธิ์กลาง",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300102,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "หนองจะบก",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300103,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "โคกสูง",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30310,
    "sub_district_code": 300104,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "มะเริง",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300105,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "หนองระเวียง",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300106,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "ปรุใหญ่",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300107,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "หมื่นไวย",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300108,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "พลกรัง",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300109,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "หนองไผ่ล้อม",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300110,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "หัวทะเล",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300111,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "บ้านเกาะ",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300112,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300113,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "พุดซา",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300114,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "บ้านโพธิ์",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30310,
    "sub_district_code": 300115,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "จอหอ",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30310,
    "sub_district_code": 300116,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "โคกกรวด",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30280,
    "sub_district_code": 300117,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "ไชยมงคล",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300118,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "หนองบัวศาลา",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300119,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "สุรนารี",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300120,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "พะเนา",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300123,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "หนองกระทุ่ม",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300124,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "ตลาด",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30310,
    "sub_district_code": 300122,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "สีมุม",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300121,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "หนองไข่น้ำ",
    "district": "เมืองนครราชสีมา",
    "province": "นครราชสีมา",
    "zipcode": 30310,
    "sub_district_code": 300125,
    "district_code": 3001,
    "province_code": 30
  },
  {
    "sub_district": "ขามทะเลสอ",
    "district": "ขามทะเลสอ",
    "province": "นครราชสีมา",
    "zipcode": 30280,
    "sub_district_code": 301901,
    "district_code": 3019,
    "province_code": 30
  },
  {
    "sub_district": "โป่งแดง",
    "district": "ขามทะเลสอ",
    "province": "นครราชสีมา",
    "zipcode": 30280,
    "sub_district_code": 301902,
    "district_code": 3019,
    "province_code": 30
  },
  {
    "sub_district": "พันดุง",
    "district": "ขามทะเลสอ",
    "province": "นครราชสีมา",
    "zipcode": 30280,
    "sub_district_code": 301903,
    "district_code": 3019,
    "province_code": 30
  },
  {
    "sub_district": "หนองสรวง",
    "district": "ขามทะเลสอ",
    "province": "นครราชสีมา",
    "zipcode": 30280,
    "sub_district_code": 301904,
    "district_code": 3019,
    "province_code": 30
  },
  {
    "sub_district": "บึงอ้อ",
    "district": "ขามทะเลสอ",
    "province": "นครราชสีมา",
    "zipcode": 30280,
    "sub_district_code": 301905,
    "district_code": 3019,
    "province_code": 30
  },
  {
    "sub_district": "ขามสะแกแสง",
    "district": "ขามสะแกแสง",
    "province": "นครราชสีมา",
    "zipcode": 30290,
    "sub_district_code": 301101,
    "district_code": 3011,
    "province_code": 30
  },
  {
    "sub_district": "โนนเมือง",
    "district": "ขามสะแกแสง",
    "province": "นครราชสีมา",
    "zipcode": 30290,
    "sub_district_code": 301102,
    "district_code": 3011,
    "province_code": 30
  },
  {
    "sub_district": "เมืองนาท",
    "district": "ขามสะแกแสง",
    "province": "นครราชสีมา",
    "zipcode": 30290,
    "sub_district_code": 301103,
    "district_code": 3011,
    "province_code": 30
  },
  {
    "sub_district": "ชีวึก",
    "district": "ขามสะแกแสง",
    "province": "นครราชสีมา",
    "zipcode": 30290,
    "sub_district_code": 301104,
    "district_code": 3011,
    "province_code": 30
  },
  {
    "sub_district": "พะงาด",
    "district": "ขามสะแกแสง",
    "province": "นครราชสีมา",
    "zipcode": 30290,
    "sub_district_code": 301105,
    "district_code": 3011,
    "province_code": 30
  },
  {
    "sub_district": "หนองหัวฟาน",
    "district": "ขามสะแกแสง",
    "province": "นครราชสีมา",
    "zipcode": 30290,
    "sub_district_code": 301106,
    "district_code": 3011,
    "province_code": 30
  },
  {
    "sub_district": "เมืองเกษตร",
    "district": "ขามสะแกแสง",
    "province": "นครราชสีมา",
    "zipcode": 30290,
    "sub_district_code": 301107,
    "district_code": 3011,
    "province_code": 30
  },
  {
    "sub_district": "เมืองคง",
    "district": "คง",
    "province": "นครราชสีมา",
    "zipcode": 30260,
    "sub_district_code": 300401,
    "district_code": 3004,
    "province_code": 30
  },
  {
    "sub_district": "คูขาด",
    "district": "คง",
    "province": "นครราชสีมา",
    "zipcode": 30260,
    "sub_district_code": 300402,
    "district_code": 3004,
    "province_code": 30
  },
  {
    "sub_district": "เทพาลัย",
    "district": "คง",
    "province": "นครราชสีมา",
    "zipcode": 30260,
    "sub_district_code": 300403,
    "district_code": 3004,
    "province_code": 30
  },
  {
    "sub_district": "ตาจั่น",
    "district": "คง",
    "province": "นครราชสีมา",
    "zipcode": 30260,
    "sub_district_code": 300404,
    "district_code": 3004,
    "province_code": 30
  },
  {
    "sub_district": "บ้านปรางค์",
    "district": "คง",
    "province": "นครราชสีมา",
    "zipcode": 30260,
    "sub_district_code": 300405,
    "district_code": 3004,
    "province_code": 30
  },
  {
    "sub_district": "หนองมะนาว",
    "district": "คง",
    "province": "นครราชสีมา",
    "zipcode": 30260,
    "sub_district_code": 300406,
    "district_code": 3004,
    "province_code": 30
  },
  {
    "sub_district": "โนนเต็ง",
    "district": "คง",
    "province": "นครราชสีมา",
    "zipcode": 30260,
    "sub_district_code": 300408,
    "district_code": 3004,
    "province_code": 30
  },
  {
    "sub_district": "หนองบัว",
    "district": "คง",
    "province": "นครราชสีมา",
    "zipcode": 30260,
    "sub_district_code": 300407,
    "district_code": 3004,
    "province_code": 30
  },
  {
    "sub_district": "ขามสมบูรณ์",
    "district": "คง",
    "province": "นครราชสีมา",
    "zipcode": 30260,
    "sub_district_code": 300410,
    "district_code": 3004,
    "province_code": 30
  },
  {
    "sub_district": "ดอนใหญ่",
    "district": "คง",
    "province": "นครราชสีมา",
    "zipcode": 30260,
    "sub_district_code": 300409,
    "district_code": 3004,
    "province_code": 30
  },
  {
    "sub_district": "แชะ",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300201,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "เฉลียง",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300202,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "ครบุรี",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300203,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "โคกกระชาย",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300204,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "จระเข้หิน",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300205,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300208,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "มาบตะโกเอน",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300206,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "อรพิมพ์",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300207,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "ลำเพียก",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300209,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "ตะแบกบาน",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300211,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "ครบุรีใต้",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300210,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "สระว่านพระยา",
    "district": "ครบุรี",
    "province": "นครราชสีมา",
    "zipcode": 30250,
    "sub_district_code": 300212,
    "district_code": 3002,
    "province_code": 30
  },
  {
    "sub_district": "จักราช",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300601,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300694,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "ทองหลาง",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300603,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "สีสุก",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300604,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "หนองขาม",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300605,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "หนองงูเหลือม",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 300695,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "หนองพลวง",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300607,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "หนองยาง",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300696,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "พระพุทธ",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300697,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "ศรีละกอ",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300610,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "ช้างทอง",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300698,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "คลองเมือง",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300611,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "หินโคน",
    "district": "จักราช",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 300613,
    "district_code": 3006,
    "province_code": 30
  },
  {
    "sub_district": "ชุมพวง",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301701,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "ประสุข",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301702,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "ท่าลาด",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301703,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "สาหร่าย",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301704,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "ตลาดไทร",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301705,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "ช่องแมว",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301791,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "ขุย",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301792,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "กระเบื้องนอก",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301798,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "เมืองยาง",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301793,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "โนนรัง",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301710,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "บ้านยาง",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301794,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "ละหานปลาค้าว",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301795,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "โนนอุดม",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301796,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "หนองหลัก",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301714,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "ไพล",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301797,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "โนนตูม",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301716,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "โนนยอ",
    "district": "ชุมพวง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 301717,
    "district_code": 3017,
    "province_code": 30
  },
  {
    "sub_district": "กระโทก",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30190,
    "sub_district_code": 300701,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "พลับพลา",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30190,
    "sub_district_code": 300702,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "ท่าอ่าง",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30190,
    "sub_district_code": 300703,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "ทุ่งอรุณ",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30190,
    "sub_district_code": 300704,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "ท่าลาดขาว",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30190,
    "sub_district_code": 300705,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "ท่าจะหลุง",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30190,
    "sub_district_code": 300706,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "สารภี",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30410,
    "sub_district_code": 300798,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "ท่าเยี่ยม",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30190,
    "sub_district_code": 300707,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "โชคชัย",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30190,
    "sub_district_code": 300708,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "ละลมใหม่พัฒนา",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30190,
    "sub_district_code": 300709,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "ด่านเกวียน",
    "district": "โชคชัย",
    "province": "นครราชสีมา",
    "zipcode": 30190,
    "sub_district_code": 300710,
    "district_code": 3007,
    "province_code": 30
  },
  {
    "sub_district": "กุดพิมาน",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300801,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "ด่านขุนทด",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300802,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "ด่านนอก",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300803,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "ด่านใน",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300804,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "ตะเคียน",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300805,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "บ้านเก่า",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300806,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "บ้านแปรง",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 36220,
    "sub_district_code": 300807,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "พันชนะ",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300808,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "สระจรเข้",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300809,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "หนองกราด",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300810,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "หนองบัวตะเกียด",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300811,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "หนองบัวละคร",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300812,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "หินดาด",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300813,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "ห้วยบง",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300815,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "สำนักตะคร้อ",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300896,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "หนองแวง",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300897,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "โนนเมืองพัฒนา",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 300817,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "หนองไทร",
    "district": "ด่านขุนทด",
    "province": "นครราชสีมา",
    "zipcode": 36220,
    "sub_district_code": 300818,
    "district_code": 3008,
    "province_code": 30
  },
  {
    "sub_district": "โนนไทย",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300901,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "ด่านจาก",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300902,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "กำปัง",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300903,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "สำโรง",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300904,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "ค้างพลู",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300905,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "บ้านวัง",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300906,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "บัลลังก์",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300907,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "สายออ",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300908,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "ถนนโพธิ์",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300909,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "พังเทียม",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300995,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "สระพระ",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300996,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "ทัพรั้ง",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300997,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "หนองหอย",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300998,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "มะค่า",
    "district": "โนนไทย",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 300914,
    "district_code": 3009,
    "province_code": 30
  },
  {
    "sub_district": "โนนสูง",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301001,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "ใหม่",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301002,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "โตนด",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301003,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "บิง",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301004,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "ดอนชมพู",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301005,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "ธารปราสาท",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30420,
    "sub_district_code": 301006,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "หลุมข้าว",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301007,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "มะค่า",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301008,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "พลสงคราม",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301009,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "จันอัด",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301010,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "ขามเฒ่า",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301011,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "ด่านคล้า",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301012,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "ลำคอหงษ์",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301013,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "ดอนหวาย",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301015,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "ลำมูล",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301016,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "เมืองปราสาท",
    "district": "โนนสูง",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301014,
    "district_code": 3010,
    "province_code": 30
  },
  {
    "sub_district": "บัวใหญ่",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301201,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "ห้วยยาง",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301203,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "เสมาใหญ่",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301204,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "บึงพะไล",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30440,
    "sub_district_code": 301290,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "ดอนตะหนิน",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301206,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "หนองบัวสะอาด",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301207,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "โนนทองหลาง",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301208,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "หนองหว้า",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301291,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "บัวลาย",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301292,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "สีดา",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30430,
    "sub_district_code": 301293,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "โพนทอง",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30430,
    "sub_district_code": 301212,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "แก้งสนามนาง",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30440,
    "sub_district_code": 301295,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "กุดจอก",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301214,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "ด่านช้าง",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301215,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "โนนจาน",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301296,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "สามเมือง",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30430,
    "sub_district_code": 301298,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "หนองแจ้งใหญ่",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301224,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "ขุนทอง",
    "district": "บัวใหญ่",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 301220,
    "district_code": 3012,
    "province_code": 30
  },
  {
    "sub_district": "ประทาย",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301301,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "โนนแดง",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30360,
    "sub_district_code": 301395,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "กระทุ่มราย",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301303,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "วังไม้แดง",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301304,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "วังหิน",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30360,
    "sub_district_code": 301396,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "ตลาดไทร",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301306,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "หนองพลวง",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301307,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "หนองค่าย",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301308,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "หันห้วยทราย",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301309,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "ดอนมัน",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301310,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "โนนตาเถร",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30360,
    "sub_district_code": 301397,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "สำพะเนียง",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30360,
    "sub_district_code": 301398,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "นางรำ",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301313,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "โนนเพ็ด",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301314,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "ทุ่งสว่าง",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301315,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "โคกกลาง",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301317,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "เมืองโดน",
    "district": "ประทาย",
    "province": "นครราชสีมา",
    "zipcode": 30180,
    "sub_district_code": 301318,
    "district_code": 3013,
    "province_code": 30
  },
  {
    "sub_district": "เมืองปัก",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301401,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "ตะคุ",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301402,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "โคกไทย",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301403,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "สำโรง",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301404,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "ตะขบ",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301405,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "นกออก",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301406,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "ดอน",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301407,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "ตูม",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301409,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "งิ้ว",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301410,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "สะแกราช",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301411,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "ลำนางแก้ว",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301412,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "ภูหลวง",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301416,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "ธงชัยเหนือ",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301417,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "สุขเกษม",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301418,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "เกษมทรัพย์",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301419,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "บ่อปลาทอง",
    "district": "ปักธงชัย",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 301420,
    "district_code": 3014,
    "province_code": 30
  },
  {
    "sub_district": "ปากช่อง",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30130,
    "sub_district_code": 302101,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "กลางดง",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30320,
    "sub_district_code": 302102,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "จันทึก",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30130,
    "sub_district_code": 302103,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "วังกะทะ",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30130,
    "sub_district_code": 302104,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "หมูสี",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30450,
    "sub_district_code": 302105,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "หนองสาหร่าย",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30130,
    "sub_district_code": 302106,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "ขนงพระ",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30450,
    "sub_district_code": 302107,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "วังน้ำเขียว",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30370,
    "sub_district_code": 302198,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "โป่งตาลอง",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30450,
    "sub_district_code": 302108,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "คลองม่วง",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30130,
    "sub_district_code": 302109,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "หนองน้ำแดง",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30450,
    "sub_district_code": 302110,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "วังไทร",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30130,
    "sub_district_code": 302111,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "พญาเย็น",
    "district": "ปากช่อง",
    "province": "นครราชสีมา",
    "zipcode": 30320,
    "sub_district_code": 302112,
    "district_code": 3021,
    "province_code": 30
  },
  {
    "sub_district": "ในเมือง",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301501,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "สัมฤทธิ์",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301502,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "โบสถ์",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301503,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "กระเบื้องใหญ่",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301504,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "ท่าหลวง",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301505,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "รังกาใหญ่",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301506,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "ชีวาน",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301507,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "นิคมสร้างตนเอง",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301508,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "ดงใหญ่",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301510,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "กระชอน",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301509,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "ธารละหลอด",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301511,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "หนองระเวียง",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30110,
    "sub_district_code": 301512,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "ขามเฒ่า",
    "district": "พิมาย",
    "province": "นครราชสีมา",
    "zipcode": 30160,
    "sub_district_code": 301598,
    "district_code": 3015,
    "province_code": 30
  },
  {
    "sub_district": "สีคิ้ว",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30140,
    "sub_district_code": 302001,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "บ้านหัน",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30140,
    "sub_district_code": 302002,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "กฤษณา",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30140,
    "sub_district_code": 302003,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "ลาดบัวขาว",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30340,
    "sub_district_code": 302004,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "หนองหญ้าขาว",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30140,
    "sub_district_code": 302005,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "กุดน้อย",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30140,
    "sub_district_code": 302006,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "หนองน้ำใส",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30140,
    "sub_district_code": 302007,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "วังโรงใหญ่",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30140,
    "sub_district_code": 302008,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "มิตรภาพ",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30140,
    "sub_district_code": 302009,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "คลองไผ่",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30340,
    "sub_district_code": 302010,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "ดอนเมือง",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30140,
    "sub_district_code": 302011,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "หนองบัวน้อย",
    "district": "สีคิ้ว",
    "province": "นครราชสีมา",
    "zipcode": 30140,
    "sub_district_code": 302012,
    "district_code": 3020,
    "province_code": 30
  },
  {
    "sub_district": "สูงเนิน",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30170,
    "sub_district_code": 301801,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "เสมา",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30170,
    "sub_district_code": 301802,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "โคราช",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30170,
    "sub_district_code": 301803,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "บุ่งขี้เหล็ก",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30170,
    "sub_district_code": 301804,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "โนนค่า",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30170,
    "sub_district_code": 301805,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "โค้งยาง",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30170,
    "sub_district_code": 301806,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "มะเกลือใหม่",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30170,
    "sub_district_code": 301808,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "มะเกลือเก่า",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30170,
    "sub_district_code": 301807,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "นากลาง",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30380,
    "sub_district_code": 301809,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "หนองตะไก้",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30380,
    "sub_district_code": 301810,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "กุดจิก",
    "district": "สูงเนิน",
    "province": "นครราชสีมา",
    "zipcode": 30380,
    "sub_district_code": 301811,
    "district_code": 3018,
    "province_code": 30
  },
  {
    "sub_district": "ห้วยแถลง",
    "district": "ห้วยแถลง",
    "province": "นครราชสีมา",
    "zipcode": 30240,
    "sub_district_code": 301601,
    "district_code": 3016,
    "province_code": 30
  },
  {
    "sub_district": "ทับสวาย",
    "district": "ห้วยแถลง",
    "province": "นครราชสีมา",
    "zipcode": 30240,
    "sub_district_code": 301602,
    "district_code": 3016,
    "province_code": 30
  },
  {
    "sub_district": "เมืองพลับพลา",
    "district": "ห้วยแถลง",
    "province": "นครราชสีมา",
    "zipcode": 30240,
    "sub_district_code": 301603,
    "district_code": 3016,
    "province_code": 30
  },
  {
    "sub_district": "หลุ่งตะเคียน",
    "district": "ห้วยแถลง",
    "province": "นครราชสีมา",
    "zipcode": 30240,
    "sub_district_code": 301604,
    "district_code": 3016,
    "province_code": 30
  },
  {
    "sub_district": "หินดาด",
    "district": "ห้วยแถลง",
    "province": "นครราชสีมา",
    "zipcode": 30240,
    "sub_district_code": 301605,
    "district_code": 3016,
    "province_code": 30
  },
  {
    "sub_district": "งิ้ว",
    "district": "ห้วยแถลง",
    "province": "นครราชสีมา",
    "zipcode": 30240,
    "sub_district_code": 301606,
    "district_code": 3016,
    "province_code": 30
  },
  {
    "sub_district": "กงรถ",
    "district": "ห้วยแถลง",
    "province": "นครราชสีมา",
    "zipcode": 30240,
    "sub_district_code": 301607,
    "district_code": 3016,
    "province_code": 30
  },
  {
    "sub_district": "หลุ่งประดู่",
    "district": "ห้วยแถลง",
    "province": "นครราชสีมา",
    "zipcode": 30240,
    "sub_district_code": 301608,
    "district_code": 3016,
    "province_code": 30
  },
  {
    "sub_district": "ตะโก",
    "district": "ห้วยแถลง",
    "province": "นครราชสีมา",
    "zipcode": 30240,
    "sub_district_code": 301609,
    "district_code": 3016,
    "province_code": 30
  },
  {
    "sub_district": "ห้วยแคน",
    "district": "ห้วยแถลง",
    "province": "นครราชสีมา",
    "zipcode": 30240,
    "sub_district_code": 301610,
    "district_code": 3016,
    "province_code": 30
  },
  {
    "sub_district": "เสิงสาง",
    "district": "เสิงสาง",
    "province": "นครราชสีมา",
    "zipcode": 30330,
    "sub_district_code": 300301,
    "district_code": 3003,
    "province_code": 30
  },
  {
    "sub_district": "สระตะเคียน",
    "district": "เสิงสาง",
    "province": "นครราชสีมา",
    "zipcode": 30330,
    "sub_district_code": 300302,
    "district_code": 3003,
    "province_code": 30
  },
  {
    "sub_district": "โนนสมบูรณ์",
    "district": "เสิงสาง",
    "province": "นครราชสีมา",
    "zipcode": 30330,
    "sub_district_code": 300303,
    "district_code": 3003,
    "province_code": 30
  },
  {
    "sub_district": "กุดโบสถ์",
    "district": "เสิงสาง",
    "province": "นครราชสีมา",
    "zipcode": 30330,
    "sub_district_code": 300304,
    "district_code": 3003,
    "province_code": 30
  },
  {
    "sub_district": "สุขไพบูลย์",
    "district": "เสิงสาง",
    "province": "นครราชสีมา",
    "zipcode": 30330,
    "sub_district_code": 300305,
    "district_code": 3003,
    "province_code": 30
  },
  {
    "sub_district": "บ้านราษฎร์",
    "district": "เสิงสาง",
    "province": "นครราชสีมา",
    "zipcode": 30330,
    "sub_district_code": 300306,
    "district_code": 3003,
    "province_code": 30
  },
  {
    "sub_district": "บ้านเหลื่อม",
    "district": "บ้านเหลื่อม",
    "province": "นครราชสีมา",
    "zipcode": 30350,
    "sub_district_code": 300501,
    "district_code": 3005,
    "province_code": 30
  },
  {
    "sub_district": "วังโพธิ์",
    "district": "บ้านเหลื่อม",
    "province": "นครราชสีมา",
    "zipcode": 30350,
    "sub_district_code": 300502,
    "district_code": 3005,
    "province_code": 30
  },
  {
    "sub_district": "โคกกระเบื้อง",
    "district": "บ้านเหลื่อม",
    "province": "นครราชสีมา",
    "zipcode": 30350,
    "sub_district_code": 300503,
    "district_code": 3005,
    "province_code": 30
  },
  {
    "sub_district": "ช่อระกา",
    "district": "บ้านเหลื่อม",
    "province": "นครราชสีมา",
    "zipcode": 30350,
    "sub_district_code": 300504,
    "district_code": 3005,
    "province_code": 30
  },
  {
    "sub_district": "หนองบุนนาก",
    "district": "หนองบุญมาก",
    "province": "นครราชสีมา",
    "zipcode": 30410,
    "sub_district_code": 302201,
    "district_code": 3022,
    "province_code": 30
  },
  {
    "sub_district": "สารภี",
    "district": "หนองบุญมาก",
    "province": "นครราชสีมา",
    "zipcode": 30410,
    "sub_district_code": 302202,
    "district_code": 3022,
    "province_code": 30
  },
  {
    "sub_district": "ไทยเจริญ",
    "district": "หนองบุญมาก",
    "province": "นครราชสีมา",
    "zipcode": 30410,
    "sub_district_code": 302203,
    "district_code": 3022,
    "province_code": 30
  },
  {
    "sub_district": "หนองหัวแรต",
    "district": "หนองบุญมาก",
    "province": "นครราชสีมา",
    "zipcode": 30410,
    "sub_district_code": 302204,
    "district_code": 3022,
    "province_code": 30
  },
  {
    "sub_district": "แหลมทอง",
    "district": "หนองบุญมาก",
    "province": "นครราชสีมา",
    "zipcode": 30410,
    "sub_district_code": 302205,
    "district_code": 3022,
    "province_code": 30
  },
  {
    "sub_district": "หนองตะไก้",
    "district": "หนองบุญมาก",
    "province": "นครราชสีมา",
    "zipcode": 30410,
    "sub_district_code": 302206,
    "district_code": 3022,
    "province_code": 30
  },
  {
    "sub_district": "ลุงเขว้า",
    "district": "หนองบุญมาก",
    "province": "นครราชสีมา",
    "zipcode": 30410,
    "sub_district_code": 302207,
    "district_code": 3022,
    "province_code": 30
  },
  {
    "sub_district": "หนองไม้ไผ่",
    "district": "หนองบุญมาก",
    "province": "นครราชสีมา",
    "zipcode": 30410,
    "sub_district_code": 302208,
    "district_code": 3022,
    "province_code": 30
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "หนองบุญมาก",
    "province": "นครราชสีมา",
    "zipcode": 30410,
    "sub_district_code": 302209,
    "district_code": 3022,
    "province_code": 30
  },
  {
    "sub_district": "แก้งสนามนาง",
    "district": "แก้งสนามนาง",
    "province": "นครราชสีมา",
    "zipcode": 30440,
    "sub_district_code": 302301,
    "district_code": 3023,
    "province_code": 30
  },
  {
    "sub_district": "โนนสำราญ",
    "district": "แก้งสนามนาง",
    "province": "นครราชสีมา",
    "zipcode": 30440,
    "sub_district_code": 302302,
    "district_code": 3023,
    "province_code": 30
  },
  {
    "sub_district": "บึงพะไล",
    "district": "แก้งสนามนาง",
    "province": "นครราชสีมา",
    "zipcode": 30440,
    "sub_district_code": 302303,
    "district_code": 3023,
    "province_code": 30
  },
  {
    "sub_district": "สีสุก",
    "district": "แก้งสนามนาง",
    "province": "นครราชสีมา",
    "zipcode": 30440,
    "sub_district_code": 302304,
    "district_code": 3023,
    "province_code": 30
  },
  {
    "sub_district": "บึงสำโรง",
    "district": "แก้งสนามนาง",
    "province": "นครราชสีมา",
    "zipcode": 30440,
    "sub_district_code": 302305,
    "district_code": 3023,
    "province_code": 30
  },
  {
    "sub_district": "ดอนยาวใหญ่",
    "district": "โนนแดง",
    "province": "นครราชสีมา",
    "zipcode": 30360,
    "sub_district_code": 302405,
    "district_code": 3024,
    "province_code": 30
  },
  {
    "sub_district": "โนนแดง",
    "district": "โนนแดง",
    "province": "นครราชสีมา",
    "zipcode": 30360,
    "sub_district_code": 302401,
    "district_code": 3024,
    "province_code": 30
  },
  {
    "sub_district": "วังหิน",
    "district": "โนนแดง",
    "province": "นครราชสีมา",
    "zipcode": 30360,
    "sub_district_code": 302404,
    "district_code": 3024,
    "province_code": 30
  },
  {
    "sub_district": "สำพะเนียง",
    "district": "โนนแดง",
    "province": "นครราชสีมา",
    "zipcode": 30360,
    "sub_district_code": 302403,
    "district_code": 3024,
    "province_code": 30
  },
  {
    "sub_district": "โนนตาเถร",
    "district": "โนนแดง",
    "province": "นครราชสีมา",
    "zipcode": 30360,
    "sub_district_code": 302402,
    "district_code": 3024,
    "province_code": 30
  },
  {
    "sub_district": "วังน้ำเขียว",
    "district": "วังน้ำเขียว",
    "province": "นครราชสีมา",
    "zipcode": 30370,
    "sub_district_code": 302501,
    "district_code": 3025,
    "province_code": 30
  },
  {
    "sub_district": "อุดมทรัพย์",
    "district": "วังน้ำเขียว",
    "province": "นครราชสีมา",
    "zipcode": 30370,
    "sub_district_code": 302504,
    "district_code": 3025,
    "province_code": 30
  },
  {
    "sub_district": "ไทยสามัคคี",
    "district": "วังน้ำเขียว",
    "province": "นครราชสีมา",
    "zipcode": 30370,
    "sub_district_code": 302505,
    "district_code": 3025,
    "province_code": 30
  },
  {
    "sub_district": "วังหมี",
    "district": "วังน้ำเขียว",
    "province": "นครราชสีมา",
    "zipcode": 30370,
    "sub_district_code": 302502,
    "district_code": 3025,
    "province_code": 30
  },
  {
    "sub_district": "ระเริง",
    "district": "วังน้ำเขียว",
    "province": "นครราชสีมา",
    "zipcode": 30150,
    "sub_district_code": 302503,
    "district_code": 3025,
    "province_code": 30
  },
  {
    "sub_district": "เมืองยาง",
    "district": "เมืองยาง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 302701,
    "district_code": 3027,
    "province_code": 30
  },
  {
    "sub_district": "กระเบื้องนอก",
    "district": "เมืองยาง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 302702,
    "district_code": 3027,
    "province_code": 30
  },
  {
    "sub_district": "ละหานปลาค้าว",
    "district": "เมืองยาง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 302703,
    "district_code": 3027,
    "province_code": 30
  },
  {
    "sub_district": "โนนอุดม",
    "district": "เมืองยาง",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 302704,
    "district_code": 3027,
    "province_code": 30
  },
  {
    "sub_district": "สระพระ",
    "district": "พระทองคำ",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 302801,
    "district_code": 3028,
    "province_code": 30
  },
  {
    "sub_district": "พังเทียม",
    "district": "พระทองคำ",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 302803,
    "district_code": 3028,
    "province_code": 30
  },
  {
    "sub_district": "มาบกราด",
    "district": "พระทองคำ",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 302802,
    "district_code": 3028,
    "province_code": 30
  },
  {
    "sub_district": "ทัพรั้ง",
    "district": "พระทองคำ",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 302804,
    "district_code": 3028,
    "province_code": 30
  },
  {
    "sub_district": "หนองหอย",
    "district": "พระทองคำ",
    "province": "นครราชสีมา",
    "zipcode": 30220,
    "sub_district_code": 302805,
    "district_code": 3028,
    "province_code": 30
  },
  {
    "sub_district": "ขุย",
    "district": "ลำทะเมนชัย",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 302901,
    "district_code": 3029,
    "province_code": 30
  },
  {
    "sub_district": "บ้านยาง",
    "district": "ลำทะเมนชัย",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 302902,
    "district_code": 3029,
    "province_code": 30
  },
  {
    "sub_district": "ไพล",
    "district": "ลำทะเมนชัย",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 302904,
    "district_code": 3029,
    "province_code": 30
  },
  {
    "sub_district": "ช่องแมว",
    "district": "ลำทะเมนชัย",
    "province": "นครราชสีมา",
    "zipcode": 30270,
    "sub_district_code": 302903,
    "district_code": 3029,
    "province_code": 30
  },
  {
    "sub_district": "ช้างทอง",
    "district": "เฉลิมพระเกียรติ",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 303201,
    "district_code": 3032,
    "province_code": 30
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "เฉลิมพระเกียรติ",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 303202,
    "district_code": 3032,
    "province_code": 30
  },
  {
    "sub_district": "พระพุทธ",
    "district": "เฉลิมพระเกียรติ",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 303203,
    "district_code": 3032,
    "province_code": 30
  },
  {
    "sub_district": "หนองยาง",
    "district": "เฉลิมพระเกียรติ",
    "province": "นครราชสีมา",
    "zipcode": 30230,
    "sub_district_code": 303205,
    "district_code": 3032,
    "province_code": 30
  },
  {
    "sub_district": "หนองงูเหลือม",
    "district": "เฉลิมพระเกียรติ",
    "province": "นครราชสีมา",
    "zipcode": 30000,
    "sub_district_code": 303204,
    "district_code": 3032,
    "province_code": 30
  },
  {
    "sub_district": "เมืองพะไล",
    "district": "บัวลาย",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 303001,
    "district_code": 3030,
    "province_code": 30
  },
  {
    "sub_district": "โนนจาน",
    "district": "บัวลาย",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 303002,
    "district_code": 3030,
    "province_code": 30
  },
  {
    "sub_district": "บัวลาย",
    "district": "บัวลาย",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 303003,
    "district_code": 3030,
    "province_code": 30
  },
  {
    "sub_district": "หนองหว้า",
    "district": "บัวลาย",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 303004,
    "district_code": 3030,
    "province_code": 30
  },
  {
    "sub_district": "สีดา",
    "district": "สีดา",
    "province": "นครราชสีมา",
    "zipcode": 30430,
    "sub_district_code": 303101,
    "district_code": 3031,
    "province_code": 30
  },
  {
    "sub_district": "โพนทอง",
    "district": "สีดา",
    "province": "นครราชสีมา",
    "zipcode": 30120,
    "sub_district_code": 303102,
    "district_code": 3031,
    "province_code": 30
  },
  {
    "sub_district": "โนนประดู่",
    "district": "สีดา",
    "province": "นครราชสีมา",
    "zipcode": 30430,
    "sub_district_code": 303103,
    "district_code": 3031,
    "province_code": 30
  },
  {
    "sub_district": "สามเมือง",
    "district": "สีดา",
    "province": "นครราชสีมา",
    "zipcode": 30430,
    "sub_district_code": 303104,
    "district_code": 3031,
    "province_code": 30
  },
  {
    "sub_district": "หนองตาดใหญ่",
    "district": "สีดา",
    "province": "นครราชสีมา",
    "zipcode": 30430,
    "sub_district_code": 303105,
    "district_code": 3031,
    "province_code": 30
  },
  {
    "sub_district": "สำนักตะคร้อ",
    "district": "เทพารักษ์",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 302601,
    "district_code": 3026,
    "province_code": 30
  },
  {
    "sub_district": "หนองแวง",
    "district": "เทพารักษ์",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 302602,
    "district_code": 3026,
    "province_code": 30
  },
  {
    "sub_district": "บึงปรือ",
    "district": "เทพารักษ์",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 302603,
    "district_code": 3026,
    "province_code": 30
  },
  {
    "sub_district": "วังยายทอง",
    "district": "เทพารักษ์",
    "province": "นครราชสีมา",
    "zipcode": 30210,
    "sub_district_code": 302604,
    "district_code": 3026,
    "province_code": 30
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800101,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ท่าวัง",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800102,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "คลัง",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800103,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ท่าไร่",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800106,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ปากนคร",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800107,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "นาทราย",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80280,
    "sub_district_code": 800108,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "นาพรุ",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800195,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ช้างซ้าย",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800196,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "นาสาร",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800197,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "กำแพงเซา",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80280,
    "sub_district_code": 800112,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ไชยมนตรี",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800113,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "มะม่วงสองต้น",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800114,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "นาเคียน",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800115,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ท่างิ้ว",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80280,
    "sub_district_code": 800116,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ท้ายสำเภา",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800198,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "โพธิ์เสด็จ",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800118,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "บางจาก",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80330,
    "sub_district_code": 800119,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ปากพูน",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800120,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ท่าซัก",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800121,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ท่าเรือ",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800122,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ทุ่งปรัง",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80120,
    "sub_district_code": 800193,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ท้องลำเจียก",
    "district": "เมืองนครศรีธรรมราช",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800194,
    "district_code": 8001,
    "province_code": 80
  },
  {
    "sub_district": "ขนอม",
    "district": "ขนอม",
    "province": "นครศรีธรรมราช",
    "zipcode": 80210,
    "sub_district_code": 801501,
    "district_code": 8015,
    "province_code": 80
  },
  {
    "sub_district": "ควนทอง",
    "district": "ขนอม",
    "province": "นครศรีธรรมราช",
    "zipcode": 80210,
    "sub_district_code": 801502,
    "district_code": 8015,
    "province_code": 80
  },
  {
    "sub_district": "ท้องเนียน",
    "district": "ขนอม",
    "province": "นครศรีธรรมราช",
    "zipcode": 80210,
    "sub_district_code": 801503,
    "district_code": 8015,
    "province_code": 80
  },
  {
    "sub_district": "ฉวาง",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80150,
    "sub_district_code": 800401,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "ฉวาง เฉพาะหมู่ 8",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80250,
    "sub_district_code": 800496,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "ช้างกลาง",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80250,
    "sub_district_code": 800495,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "ละอาย",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80250,
    "sub_district_code": 800403,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "นาแว",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80260,
    "sub_district_code": 800404,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "ไม้เรียง",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80260,
    "sub_district_code": 800405,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "กะเปียด",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80260,
    "sub_district_code": 800406,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "นากะชะ",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80150,
    "sub_district_code": 800407,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "ห้วยปริก",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80260,
    "sub_district_code": 800409,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "ไสหร้า",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80250,
    "sub_district_code": 800410,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "หลักช้าง",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80250,
    "sub_district_code": 800497,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "จันดี",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80250,
    "sub_district_code": 800416,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "นาเขลียง",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80260,
    "sub_district_code": 800415,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "คลองเส",
    "district": "ฉวาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80260,
    "sub_district_code": 800498,
    "district_code": 8004,
    "province_code": 80
  },
  {
    "sub_district": "ชะอวด",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800701,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "ท่าเสม็ด",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800702,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "ท่าประจะ",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800703,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "เคร็ง",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800704,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "วังอ่าง",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800705,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "บ้านตูล",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800706,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "ขอนหาด",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800707,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "เกาะขันธ์",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800708,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "ควนหนองหงษ์",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800709,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "เขาพระทอง",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800710,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "นางหลง",
    "district": "ชะอวด",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 800711,
    "district_code": 8007,
    "province_code": 80
  },
  {
    "sub_district": "เชียรใหญ่",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800601,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "เชียรเขา",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800696,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "ท่าขนาน",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800603,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "บ้านกลาง",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800604,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "บ้านเนิน",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800605,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "ไสหมาก",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800606,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "ท้องลำเจียก",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800607,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "ดอนตรอ",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80290,
    "sub_district_code": 800697,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "สวนหลวง",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800698,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "เสือหึง",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800610,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "การะเกด",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800611,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "เขาพระบาท",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800612,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "แม่เจ้าอยู่หัว",
    "district": "เชียรใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 800613,
    "district_code": 8006,
    "province_code": 80
  },
  {
    "sub_district": "ท่าศาลา",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800801,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "กลาย",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800802,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "ท่าขึ้น",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800803,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "หัวตะพาน",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800804,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "กะหรอ",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800895,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "สระแก้ว",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800806,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "โมคลาน",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800807,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "นบพิตำ",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800896,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "ไทยบุรี",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800809,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "ดอนตะโก",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800810,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "ตลิ่งชัน",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800811,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "กรุงชิง",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800897,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "โพธิ์ทอง",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 800813,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "สิชล",
    "district": "ท่าศาลา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80120,
    "sub_district_code": 800898,
    "district_code": 8008,
    "province_code": 80
  },
  {
    "sub_district": "ปากแพรก",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800901,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "ชะมาย",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800902,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "หนองหงส์",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800903,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "ควนกรด",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800904,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "นาไม้ไผ่",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800905,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "นาหลวงเสน",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800906,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "วังหิน",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80360,
    "sub_district_code": 800995,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "เขาโร",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800907,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "กะปาง",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80310,
    "sub_district_code": 800908,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "ที่วัง",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800909,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "น้ำตก",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800910,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "ถ้ำใหญ่",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800911,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "นาโพธิ์",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800912,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "บางขัน",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80360,
    "sub_district_code": 800996,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "เขาขาว",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80110,
    "sub_district_code": 800913,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "ท่ายาง",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80240,
    "sub_district_code": 800997,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "คลัง",
    "district": "ทุ่งสง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 800998,
    "district_code": 8009,
    "province_code": 80
  },
  {
    "sub_district": "ท่ายาง",
    "district": "ทุ่งใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80240,
    "sub_district_code": 801101,
    "district_code": 8011,
    "province_code": 80
  },
  {
    "sub_district": "ทุ่งสัง",
    "district": "ทุ่งใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80240,
    "sub_district_code": 801102,
    "district_code": 8011,
    "province_code": 80
  },
  {
    "sub_district": "ทุ่งใหญ่",
    "district": "ทุ่งใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80240,
    "sub_district_code": 801103,
    "district_code": 8011,
    "province_code": 80
  },
  {
    "sub_district": "กุแหระ",
    "district": "ทุ่งใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80240,
    "sub_district_code": 801104,
    "district_code": 8011,
    "province_code": 80
  },
  {
    "sub_district": "ปริก",
    "district": "ทุ่งใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80240,
    "sub_district_code": 801105,
    "district_code": 8011,
    "province_code": 80
  },
  {
    "sub_district": "บางรูป",
    "district": "ทุ่งใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80240,
    "sub_district_code": 801106,
    "district_code": 8011,
    "province_code": 80
  },
  {
    "sub_district": "กรุงหยัน",
    "district": "ทุ่งใหญ่",
    "province": "นครศรีธรรมราช",
    "zipcode": 80240,
    "sub_district_code": 801107,
    "district_code": 8011,
    "province_code": 80
  },
  {
    "sub_district": "ปากพนัง",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801201,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "คลองน้อย",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80330,
    "sub_district_code": 801202,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "ป่าระกำ",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801203,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "ชะเมา",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80330,
    "sub_district_code": 801204,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "คลองกระบือ",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801205,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "เกาะทวด",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80330,
    "sub_district_code": 801206,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801207,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "หูล่อง",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801208,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "แหลมตะลุมพุก",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801209,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "ปากพนังฝั่งตะวันตก",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801210,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "บางศาลา",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801211,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "บางพระ",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801212,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "บางตะพง",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801213,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "ปากพนังฝั่งตะวันออก",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801214,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "บ้านเพิง",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801215,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "ท่าพยา",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801216,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "ปากแพรก",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801217,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "ขนาบนาก",
    "district": "ปากพนัง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80140,
    "sub_district_code": 801218,
    "district_code": 8012,
    "province_code": 80
  },
  {
    "sub_district": "ร่อนพิบูลย์",
    "district": "ร่อนพิบูลย์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80130,
    "sub_district_code": 801301,
    "district_code": 8013,
    "province_code": 80
  },
  {
    "sub_district": "หินตก",
    "district": "ร่อนพิบูลย์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80350,
    "sub_district_code": 801302,
    "district_code": 8013,
    "province_code": 80
  },
  {
    "sub_district": "เสาธง",
    "district": "ร่อนพิบูลย์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80350,
    "sub_district_code": 801303,
    "district_code": 8013,
    "province_code": 80
  },
  {
    "sub_district": "ควนเกย",
    "district": "ร่อนพิบูลย์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80130,
    "sub_district_code": 801304,
    "district_code": 8013,
    "province_code": 80
  },
  {
    "sub_district": "ควนพัง",
    "district": "ร่อนพิบูลย์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80130,
    "sub_district_code": 801305,
    "district_code": 8013,
    "province_code": 80
  },
  {
    "sub_district": "ควนชุม",
    "district": "ร่อนพิบูลย์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80130,
    "sub_district_code": 801306,
    "district_code": 8013,
    "province_code": 80
  },
  {
    "sub_district": "สามตำบล",
    "district": "ร่อนพิบูลย์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80130,
    "sub_district_code": 801397,
    "district_code": 8013,
    "province_code": 80
  },
  {
    "sub_district": "ทางพูน",
    "district": "ร่อนพิบูลย์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80290,
    "sub_district_code": 801398,
    "district_code": 8013,
    "province_code": 80
  },
  {
    "sub_district": "เขาแก้ว",
    "district": "ลานสกา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80230,
    "sub_district_code": 800301,
    "district_code": 8003,
    "province_code": 80
  },
  {
    "sub_district": "ลานสกา",
    "district": "ลานสกา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80230,
    "sub_district_code": 800302,
    "district_code": 8003,
    "province_code": 80
  },
  {
    "sub_district": "ท่าดี",
    "district": "ลานสกา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80230,
    "sub_district_code": 800303,
    "district_code": 8003,
    "province_code": 80
  },
  {
    "sub_district": "กำโลน",
    "district": "ลานสกา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80230,
    "sub_district_code": 800304,
    "district_code": 8003,
    "province_code": 80
  },
  {
    "sub_district": "ขุนทะเล",
    "district": "ลานสกา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80230,
    "sub_district_code": 800305,
    "district_code": 8003,
    "province_code": 80
  },
  {
    "sub_district": "สิชล",
    "district": "สิชล",
    "province": "นครศรีธรรมราช",
    "zipcode": 80120,
    "sub_district_code": 801401,
    "district_code": 8014,
    "province_code": 80
  },
  {
    "sub_district": "ทุ่งปรัง",
    "district": "สิชล",
    "province": "นครศรีธรรมราช",
    "zipcode": 80120,
    "sub_district_code": 801402,
    "district_code": 8014,
    "province_code": 80
  },
  {
    "sub_district": "ฉลอง",
    "district": "สิชล",
    "province": "นครศรีธรรมราช",
    "zipcode": 80120,
    "sub_district_code": 801403,
    "district_code": 8014,
    "province_code": 80
  },
  {
    "sub_district": "เสาเภา",
    "district": "สิชล",
    "province": "นครศรีธรรมราช",
    "zipcode": 80340,
    "sub_district_code": 801404,
    "district_code": 8014,
    "province_code": 80
  },
  {
    "sub_district": "เปลี่ยน",
    "district": "สิชล",
    "province": "นครศรีธรรมราช",
    "zipcode": 80120,
    "sub_district_code": 801405,
    "district_code": 8014,
    "province_code": 80
  },
  {
    "sub_district": "สี่ขีด",
    "district": "สิชล",
    "province": "นครศรีธรรมราช",
    "zipcode": 80120,
    "sub_district_code": 801406,
    "district_code": 8014,
    "province_code": 80
  },
  {
    "sub_district": "เทพราช",
    "district": "สิชล",
    "province": "นครศรีธรรมราช",
    "zipcode": 80340,
    "sub_district_code": 801407,
    "district_code": 8014,
    "province_code": 80
  },
  {
    "sub_district": "เขาน้อย",
    "district": "สิชล",
    "province": "นครศรีธรรมราช",
    "zipcode": 80120,
    "sub_district_code": 801408,
    "district_code": 8014,
    "province_code": 80
  },
  {
    "sub_district": "ทุ่งใส",
    "district": "สิชล",
    "province": "นครศรีธรรมราช",
    "zipcode": 80120,
    "sub_district_code": 801409,
    "district_code": 8014,
    "province_code": 80
  },
  {
    "sub_district": "หัวไทร",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801601,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "หน้าสตน",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801602,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "ทรายขาว",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801603,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "แหลม",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801604,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "เขาพังไกร",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801605,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "บ้านราม",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801606,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "บางนบ",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801607,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "ท่าซอม",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801608,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "ควนชะลิก",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801609,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "รามแก้ว",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801610,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "เกาะเพชร",
    "district": "หัวไทร",
    "province": "นครศรีธรรมราช",
    "zipcode": 80170,
    "sub_district_code": 801611,
    "district_code": 8016,
    "province_code": 80
  },
  {
    "sub_district": "พิปูน",
    "district": "พิปูน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80270,
    "sub_district_code": 800501,
    "district_code": 8005,
    "province_code": 80
  },
  {
    "sub_district": "กะทูน",
    "district": "พิปูน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80270,
    "sub_district_code": 800502,
    "district_code": 8005,
    "province_code": 80
  },
  {
    "sub_district": "เขาพระ",
    "district": "พิปูน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80270,
    "sub_district_code": 800503,
    "district_code": 8005,
    "province_code": 80
  },
  {
    "sub_district": "ยางค้อม",
    "district": "พิปูน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80270,
    "sub_district_code": 800504,
    "district_code": 8005,
    "province_code": 80
  },
  {
    "sub_district": "ควนกลาง",
    "district": "พิปูน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80270,
    "sub_district_code": 800505,
    "district_code": 8005,
    "province_code": 80
  },
  {
    "sub_district": "พรหมโลก",
    "district": "พรหมคีรี",
    "province": "นครศรีธรรมราช",
    "zipcode": 80320,
    "sub_district_code": 800201,
    "district_code": 8002,
    "province_code": 80
  },
  {
    "sub_district": "บ้านเกาะ",
    "district": "พรหมคีรี",
    "province": "นครศรีธรรมราช",
    "zipcode": 80320,
    "sub_district_code": 800202,
    "district_code": 8002,
    "province_code": 80
  },
  {
    "sub_district": "อินคีรี",
    "district": "พรหมคีรี",
    "province": "นครศรีธรรมราช",
    "zipcode": 80320,
    "sub_district_code": 800203,
    "district_code": 8002,
    "province_code": 80
  },
  {
    "sub_district": "ทอนหงส์",
    "district": "พรหมคีรี",
    "province": "นครศรีธรรมราช",
    "zipcode": 80320,
    "sub_district_code": 800204,
    "district_code": 8002,
    "province_code": 80
  },
  {
    "sub_district": "นาเรียง",
    "district": "พรหมคีรี",
    "province": "นครศรีธรรมราช",
    "zipcode": 80320,
    "sub_district_code": 800205,
    "district_code": 8002,
    "province_code": 80
  },
  {
    "sub_district": "นาบอน",
    "district": "นาบอน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80220,
    "sub_district_code": 801001,
    "district_code": 8010,
    "province_code": 80
  },
  {
    "sub_district": "ทุ่งสง",
    "district": "นาบอน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80220,
    "sub_district_code": 801002,
    "district_code": 8010,
    "province_code": 80
  },
  {
    "sub_district": "แก้วแสน",
    "district": "นาบอน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80220,
    "sub_district_code": 801003,
    "district_code": 8010,
    "province_code": 80
  },
  {
    "sub_district": "บางขัน",
    "district": "บางขัน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80360,
    "sub_district_code": 801701,
    "district_code": 8017,
    "province_code": 80
  },
  {
    "sub_district": "บ้านลำนาว",
    "district": "บางขัน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80360,
    "sub_district_code": 801702,
    "district_code": 8017,
    "province_code": 80
  },
  {
    "sub_district": "วังหิน",
    "district": "บางขัน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80360,
    "sub_district_code": 801703,
    "district_code": 8017,
    "province_code": 80
  },
  {
    "sub_district": "บ้านนิคม",
    "district": "บางขัน",
    "province": "นครศรีธรรมราช",
    "zipcode": 80360,
    "sub_district_code": 801704,
    "district_code": 8017,
    "province_code": 80
  },
  {
    "sub_district": "คลองเส",
    "district": "ถ้ำพรรณรา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80260,
    "sub_district_code": 801802,
    "district_code": 8018,
    "province_code": 80
  },
  {
    "sub_district": "ถ้ำพรรณรา",
    "district": "ถ้ำพรรณรา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80260,
    "sub_district_code": 801801,
    "district_code": 8018,
    "province_code": 80
  },
  {
    "sub_district": "ดุสิต",
    "district": "ถ้ำพรรณรา",
    "province": "นครศรีธรรมราช",
    "zipcode": 80260,
    "sub_district_code": 801803,
    "district_code": 8018,
    "province_code": 80
  },
  {
    "sub_district": "ท้ายสำเภา",
    "district": "พระพรหม",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 802003,
    "district_code": 8020,
    "province_code": 80
  },
  {
    "sub_district": "นาพรุ",
    "district": "พระพรหม",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 802001,
    "district_code": 8020,
    "province_code": 80
  },
  {
    "sub_district": "นาสาร",
    "district": "พระพรหม",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 802002,
    "district_code": 8020,
    "province_code": 80
  },
  {
    "sub_district": "ช้างซ้าย",
    "district": "พระพรหม",
    "province": "นครศรีธรรมราช",
    "zipcode": 80000,
    "sub_district_code": 802004,
    "district_code": 8020,
    "province_code": 80
  },
  {
    "sub_district": "สวนขัน",
    "district": "ช้างกลาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80250,
    "sub_district_code": 802203,
    "district_code": 8022,
    "province_code": 80
  },
  {
    "sub_district": "ช้างกลาง",
    "district": "ช้างกลาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80250,
    "sub_district_code": 802201,
    "district_code": 8022,
    "province_code": 80
  },
  {
    "sub_district": "หลักช้าง",
    "district": "ช้างกลาง",
    "province": "นครศรีธรรมราช",
    "zipcode": 80250,
    "sub_district_code": 802202,
    "district_code": 8022,
    "province_code": 80
  },
  {
    "sub_district": "นาเหรง",
    "district": "นบพิตำ",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 802104,
    "district_code": 8021,
    "province_code": 80
  },
  {
    "sub_district": "กะหรอ",
    "district": "นบพิตำ",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 802103,
    "district_code": 8021,
    "province_code": 80
  },
  {
    "sub_district": "นบพิตำ",
    "district": "นบพิตำ",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 802101,
    "district_code": 8021,
    "province_code": 80
  },
  {
    "sub_district": "กรุงชิง",
    "district": "นบพิตำ",
    "province": "นครศรีธรรมราช",
    "zipcode": 80160,
    "sub_district_code": 802102,
    "district_code": 8021,
    "province_code": 80
  },
  {
    "sub_district": "สวนหลวง",
    "district": "เฉลิมพระเกียรติ",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 802303,
    "district_code": 8023,
    "province_code": 80
  },
  {
    "sub_district": "ดอนตรอ",
    "district": "เฉลิมพระเกียรติ",
    "province": "นครศรีธรรมราช",
    "zipcode": 80290,
    "sub_district_code": 802302,
    "district_code": 8023,
    "province_code": 80
  },
  {
    "sub_district": "เชียรเขา",
    "district": "เฉลิมพระเกียรติ",
    "province": "นครศรีธรรมราช",
    "zipcode": 80190,
    "sub_district_code": 802301,
    "district_code": 8023,
    "province_code": 80
  },
  {
    "sub_district": "ทางพูน",
    "district": "เฉลิมพระเกียรติ",
    "province": "นครศรีธรรมราช",
    "zipcode": 80290,
    "sub_district_code": 802304,
    "district_code": 8023,
    "province_code": 80
  },
  {
    "sub_district": "บ้านชะอวด",
    "district": "จุฬาภรณ์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 801902,
    "district_code": 8019,
    "province_code": 80
  },
  {
    "sub_district": "บ้านควนมุด",
    "district": "จุฬาภรณ์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80180,
    "sub_district_code": 801901,
    "district_code": 8019,
    "province_code": 80
  },
  {
    "sub_district": "ควนหนองคว้า",
    "district": "จุฬาภรณ์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80130,
    "sub_district_code": 801903,
    "district_code": 8019,
    "province_code": 80
  },
  {
    "sub_district": "ทุ่งโพธิ์",
    "district": "จุฬาภรณ์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80130,
    "sub_district_code": 801904,
    "district_code": 8019,
    "province_code": 80
  },
  {
    "sub_district": "นาหมอบุญ",
    "district": "จุฬาภรณ์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80130,
    "sub_district_code": 801905,
    "district_code": 8019,
    "province_code": 80
  },
  {
    "sub_district": "สามตำบล",
    "district": "จุฬาภรณ์",
    "province": "นครศรีธรรมราช",
    "zipcode": 80130,
    "sub_district_code": 801906,
    "district_code": 8019,
    "province_code": 80
  },
  {
    "sub_district": "ปากน้ำโพ",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600101,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "กลางแดด",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600102,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "เกรียงไกร",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600103,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "แควใหญ่",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600104,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "ตะเคียนเลื่อน",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600105,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "นครสวรรค์ตก",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600106,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "นครสวรรค์ออก",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600107,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "บางพระหลวง",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600108,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "บางม่วง",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600109,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "บ้านแก่ง",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600111,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "พระนอน",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600112,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "วัดไทรย์",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600113,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "หนองกรด",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60240,
    "sub_district_code": 600114,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "หนองกระโดน",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60240,
    "sub_district_code": 600115,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "หนองปลิง",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600116,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "บ้านมะเกลือ",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600110,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "บึงเสนาท",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600117,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "วังเมือง",
    "district": "เมืองนครสวรรค์",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 600198,
    "district_code": 6001,
    "province_code": 60
  },
  {
    "sub_district": "มหาโพธิ",
    "district": "เก้าเลี้ยว",
    "province": "นครสวรรค์",
    "zipcode": 60230,
    "sub_district_code": 600601,
    "district_code": 6006,
    "province_code": 60
  },
  {
    "sub_district": "เก้าเลี้ยว",
    "district": "เก้าเลี้ยว",
    "province": "นครสวรรค์",
    "zipcode": 60230,
    "sub_district_code": 600602,
    "district_code": 6006,
    "province_code": 60
  },
  {
    "sub_district": "หนองเต่า",
    "district": "เก้าเลี้ยว",
    "province": "นครสวรรค์",
    "zipcode": 60230,
    "sub_district_code": 600603,
    "district_code": 6006,
    "province_code": 60
  },
  {
    "sub_district": "เขาดิน",
    "district": "เก้าเลี้ยว",
    "province": "นครสวรรค์",
    "zipcode": 60230,
    "sub_district_code": 600604,
    "district_code": 6006,
    "province_code": 60
  },
  {
    "sub_district": "หัวดง",
    "district": "เก้าเลี้ยว",
    "province": "นครสวรรค์",
    "zipcode": 60230,
    "sub_district_code": 600605,
    "district_code": 6006,
    "province_code": 60
  },
  {
    "sub_district": "โกรกพระ",
    "district": "โกรกพระ",
    "province": "นครสวรรค์",
    "zipcode": 60170,
    "sub_district_code": 600201,
    "district_code": 6002,
    "province_code": 60
  },
  {
    "sub_district": "ยางตาล",
    "district": "โกรกพระ",
    "province": "นครสวรรค์",
    "zipcode": 60170,
    "sub_district_code": 600202,
    "district_code": 6002,
    "province_code": 60
  },
  {
    "sub_district": "บางมะฝ่อ",
    "district": "โกรกพระ",
    "province": "นครสวรรค์",
    "zipcode": 60170,
    "sub_district_code": 600203,
    "district_code": 6002,
    "province_code": 60
  },
  {
    "sub_district": "บางประมุง",
    "district": "โกรกพระ",
    "province": "นครสวรรค์",
    "zipcode": 60170,
    "sub_district_code": 600204,
    "district_code": 6002,
    "province_code": 60
  },
  {
    "sub_district": "นากลาง",
    "district": "โกรกพระ",
    "province": "นครสวรรค์",
    "zipcode": 60170,
    "sub_district_code": 600205,
    "district_code": 6002,
    "province_code": 60
  },
  {
    "sub_district": "ศาลาแดง",
    "district": "โกรกพระ",
    "province": "นครสวรรค์",
    "zipcode": 60170,
    "sub_district_code": 600206,
    "district_code": 6002,
    "province_code": 60
  },
  {
    "sub_district": "เนินกว้าว",
    "district": "โกรกพระ",
    "province": "นครสวรรค์",
    "zipcode": 60170,
    "sub_district_code": 600207,
    "district_code": 6002,
    "province_code": 60
  },
  {
    "sub_district": "เนินศาลา",
    "district": "โกรกพระ",
    "province": "นครสวรรค์",
    "zipcode": 60170,
    "sub_district_code": 600208,
    "district_code": 6002,
    "province_code": 60
  },
  {
    "sub_district": "หาดสูง",
    "district": "โกรกพระ",
    "province": "นครสวรรค์",
    "zipcode": 60170,
    "sub_district_code": 600209,
    "district_code": 6002,
    "province_code": 60
  },
  {
    "sub_district": "ชุมแสง",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60120,
    "sub_district_code": 600301,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "ทับกฤช",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60250,
    "sub_district_code": 600302,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "พิกุล",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60120,
    "sub_district_code": 600303,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "เกยไชย",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60120,
    "sub_district_code": 600304,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "ท่าไม้",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60120,
    "sub_district_code": 600305,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "บางเคียน",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60120,
    "sub_district_code": 600306,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "หนองกระเจา",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60120,
    "sub_district_code": 600307,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "พันลาน",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60250,
    "sub_district_code": 600308,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "โคกหม้อ",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60120,
    "sub_district_code": 600309,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "ไผ่สิงห์",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60120,
    "sub_district_code": 600310,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "ฆะมัง",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60120,
    "sub_district_code": 600311,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "ทับกฤชใต้",
    "district": "ชุมแสง",
    "province": "นครสวรรค์",
    "zipcode": 60250,
    "sub_district_code": 600312,
    "district_code": 6003,
    "province_code": 60
  },
  {
    "sub_district": "ตากฟ้า",
    "district": "ตากฟ้า",
    "province": "นครสวรรค์",
    "zipcode": 60190,
    "sub_district_code": 601201,
    "district_code": 6012,
    "province_code": 60
  },
  {
    "sub_district": "ลำพยนต์",
    "district": "ตากฟ้า",
    "province": "นครสวรรค์",
    "zipcode": 60190,
    "sub_district_code": 601202,
    "district_code": 6012,
    "province_code": 60
  },
  {
    "sub_district": "สุขสำราญ",
    "district": "ตากฟ้า",
    "province": "นครสวรรค์",
    "zipcode": 60190,
    "sub_district_code": 601203,
    "district_code": 6012,
    "province_code": 60
  },
  {
    "sub_district": "หนองพิกุล",
    "district": "ตากฟ้า",
    "province": "นครสวรรค์",
    "zipcode": 60190,
    "sub_district_code": 601204,
    "district_code": 6012,
    "province_code": 60
  },
  {
    "sub_district": "พุนกยูง",
    "district": "ตากฟ้า",
    "province": "นครสวรรค์",
    "zipcode": 60190,
    "sub_district_code": 601205,
    "district_code": 6012,
    "province_code": 60
  },
  {
    "sub_district": "อุดมธัญญา",
    "district": "ตากฟ้า",
    "province": "นครสวรรค์",
    "zipcode": 60190,
    "sub_district_code": 601206,
    "district_code": 6012,
    "province_code": 60
  },
  {
    "sub_district": "เขาชายธง",
    "district": "ตากฟ้า",
    "province": "นครสวรรค์",
    "zipcode": 60190,
    "sub_district_code": 601207,
    "district_code": 6012,
    "province_code": 60
  },
  {
    "sub_district": "ตาคลี",
    "district": "ตาคลี",
    "province": "นครสวรรค์",
    "zipcode": 60140,
    "sub_district_code": 600701,
    "district_code": 6007,
    "province_code": 60
  },
  {
    "sub_district": "ช่องแค",
    "district": "ตาคลี",
    "province": "นครสวรรค์",
    "zipcode": 60210,
    "sub_district_code": 600702,
    "district_code": 6007,
    "province_code": 60
  },
  {
    "sub_district": "จันเสน",
    "district": "ตาคลี",
    "province": "นครสวรรค์",
    "zipcode": 60260,
    "sub_district_code": 600703,
    "district_code": 6007,
    "province_code": 60
  },
  {
    "sub_district": "ห้วยหอม",
    "district": "ตาคลี",
    "province": "นครสวรรค์",
    "zipcode": 60210,
    "sub_district_code": 600704,
    "district_code": 6007,
    "province_code": 60
  },
  {
    "sub_district": "หัวหวาย",
    "district": "ตาคลี",
    "province": "นครสวรรค์",
    "zipcode": 60140,
    "sub_district_code": 600705,
    "district_code": 6007,
    "province_code": 60
  },
  {
    "sub_district": "หนองโพ",
    "district": "ตาคลี",
    "province": "นครสวรรค์",
    "zipcode": 60140,
    "sub_district_code": 600706,
    "district_code": 6007,
    "province_code": 60
  },
  {
    "sub_district": "หนองหม้อ",
    "district": "ตาคลี",
    "province": "นครสวรรค์",
    "zipcode": 60140,
    "sub_district_code": 600707,
    "district_code": 6007,
    "province_code": 60
  },
  {
    "sub_district": "สร้อยทอง",
    "district": "ตาคลี",
    "province": "นครสวรรค์",
    "zipcode": 60210,
    "sub_district_code": 600708,
    "district_code": 6007,
    "province_code": 60
  },
  {
    "sub_district": "ลาดทิพรส",
    "district": "ตาคลี",
    "province": "นครสวรรค์",
    "zipcode": 60260,
    "sub_district_code": 600709,
    "district_code": 6007,
    "province_code": 60
  },
  {
    "sub_district": "พรหมนิมิต",
    "district": "ตาคลี",
    "province": "นครสวรรค์",
    "zipcode": 60210,
    "sub_district_code": 600710,
    "district_code": 6007,
    "province_code": 60
  },
  {
    "sub_district": "ท่าตะโก",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60160,
    "sub_district_code": 600801,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "พนมรอก",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60160,
    "sub_district_code": 600802,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "หัวถนน",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60160,
    "sub_district_code": 600803,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "สายลำโพง",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60160,
    "sub_district_code": 600804,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "วังมหากร",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60160,
    "sub_district_code": 600805,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "ดอนคา",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60160,
    "sub_district_code": 600806,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "ทำนบ",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60160,
    "sub_district_code": 600807,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "วังใหญ่",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60160,
    "sub_district_code": 600808,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "พนมเศษ",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60160,
    "sub_district_code": 600809,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "หนองหลวง",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60160,
    "sub_district_code": 600810,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "พระนอน",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60000,
    "sub_district_code": 600897,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "วังน้ำลัด",
    "district": "ท่าตะโก",
    "province": "นครสวรรค์",
    "zipcode": 60220,
    "sub_district_code": 600898,
    "district_code": 6008,
    "province_code": 60
  },
  {
    "sub_district": "ท่างิ้ว",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600501,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "บางตาหงาย",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600502,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "หูกวาง",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600503,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "อ่างทอง",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600504,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "บ้านแดน",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600505,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "บางแก้ว",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600506,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "ตาขีด",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600507,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "ตาสัง",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600508,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "ด่านช้าง",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600509,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "หนองกรด",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600510,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "หนองตางู",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600511,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "เจริญผล",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600513,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "บึงปลาทู",
    "district": "บรรพตพิสัย",
    "province": "นครสวรรค์",
    "zipcode": 60180,
    "sub_district_code": 600512,
    "district_code": 6005,
    "province_code": 60
  },
  {
    "sub_district": "พยุหะ",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601001,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "เนินมะกอก",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601002,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "นิคมเขาบ่อแก้ว",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601003,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "ม่วงหัก",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601004,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "ยางขาว",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601005,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "ย่านมัทรี",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601006,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "เขาทอง",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601007,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "ท่าน้ำอ้อย",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601008,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "น้ำทรง",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601009,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "เขากะลา",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601010,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "สระทะเล",
    "district": "พยุหะคีรี",
    "province": "นครสวรรค์",
    "zipcode": 60130,
    "sub_district_code": 601011,
    "district_code": 6010,
    "province_code": 60
  },
  {
    "sub_district": "โคกเดื่อ",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60220,
    "sub_district_code": 600901,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "สำโรงชัย",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60220,
    "sub_district_code": 600902,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "วังน้ำลัด",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60220,
    "sub_district_code": 600903,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "ตะคร้อ",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60220,
    "sub_district_code": 600904,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "โพธิ์ประสาท",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60220,
    "sub_district_code": 600905,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "วังข่อย",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60220,
    "sub_district_code": 600906,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "หนองยาว",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 600992,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "หนองนมวัว",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 600993,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "บ้านไร่",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 600994,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "เนินขี้เหล็ก",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 600995,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "แม่เล่ย์",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 600996,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "แม่วงก์",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 600997,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "ไพศาลี",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60220,
    "sub_district_code": 600908,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "วังซ่าน",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 600998,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "นาขอม",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60220,
    "sub_district_code": 600907,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "ไพศาลี",
    "district": "ไพศาลี",
    "province": "นครสวรรค์",
    "zipcode": 60220,
    "sub_district_code": 600908,
    "district_code": 6009,
    "province_code": 60
  },
  {
    "sub_district": "ลาดยาว",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601101,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "ห้วยน้ำหอม",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601102,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "วังม้า",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601103,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "วังเมือง",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601104,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "สร้อยละคร",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601105,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "มาบแก",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601106,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "หนองยาว",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601107,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "หนองนมวัว",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601108,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "บ้านไร่",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601109,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "เนินขี้เหล็ก",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601110,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "แม่เล่ย์",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601195,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "แม่วงก์",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601196,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "วังซ่าน",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601197,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "เขาชนกัน",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601198,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "ศาลเจ้าไก่ต่อ",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601116,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "สระแก้ว",
    "district": "ลาดยาว",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601117,
    "district_code": 6011,
    "province_code": 60
  },
  {
    "sub_district": "หนองบัว",
    "district": "หนองบัว",
    "province": "นครสวรรค์",
    "zipcode": 60110,
    "sub_district_code": 600401,
    "district_code": 6004,
    "province_code": 60
  },
  {
    "sub_district": "หนองกลับ",
    "district": "หนองบัว",
    "province": "นครสวรรค์",
    "zipcode": 60110,
    "sub_district_code": 600402,
    "district_code": 6004,
    "province_code": 60
  },
  {
    "sub_district": "ธารทหาร",
    "district": "หนองบัว",
    "province": "นครสวรรค์",
    "zipcode": 60110,
    "sub_district_code": 600403,
    "district_code": 6004,
    "province_code": 60
  },
  {
    "sub_district": "ห้วยร่วม",
    "district": "หนองบัว",
    "province": "นครสวรรค์",
    "zipcode": 60110,
    "sub_district_code": 600404,
    "district_code": 6004,
    "province_code": 60
  },
  {
    "sub_district": "ห้วยถั่วใต้",
    "district": "หนองบัว",
    "province": "นครสวรรค์",
    "zipcode": 60110,
    "sub_district_code": 600405,
    "district_code": 6004,
    "province_code": 60
  },
  {
    "sub_district": "ห้วยถั่วเหนือ",
    "district": "หนองบัว",
    "province": "นครสวรรค์",
    "zipcode": 60110,
    "sub_district_code": 600406,
    "district_code": 6004,
    "province_code": 60
  },
  {
    "sub_district": "ห้วยใหญ่",
    "district": "หนองบัว",
    "province": "นครสวรรค์",
    "zipcode": 60110,
    "sub_district_code": 600407,
    "district_code": 6004,
    "province_code": 60
  },
  {
    "sub_district": "ทุ่งทอง",
    "district": "หนองบัว",
    "province": "นครสวรรค์",
    "zipcode": 60110,
    "sub_district_code": 600408,
    "district_code": 6004,
    "province_code": 60
  },
  {
    "sub_district": "วังบ่อ",
    "district": "หนองบัว",
    "province": "นครสวรรค์",
    "zipcode": 60110,
    "sub_district_code": 600409,
    "district_code": 6004,
    "province_code": 60
  },
  {
    "sub_district": "วังซ่าน",
    "district": "แม่วงก์",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601304,
    "district_code": 6013,
    "province_code": 60
  },
  {
    "sub_district": "เขาชนกัน",
    "district": "แม่วงก์",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601305,
    "district_code": 6013,
    "province_code": 60
  },
  {
    "sub_district": "แม่เล่ย์",
    "district": "แม่วงก์",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601303,
    "district_code": 6013,
    "province_code": 60
  },
  {
    "sub_district": "ปางสวรรค์",
    "district": "แม่วงก์",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601397,
    "district_code": 6013,
    "province_code": 60
  },
  {
    "sub_district": "แม่วงก์",
    "district": "แม่วงก์",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601301,
    "district_code": 6013,
    "province_code": 60
  },
  {
    "sub_district": "ชุมตาบง",
    "district": "แม่วงก์",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601398,
    "district_code": 6013,
    "province_code": 60
  },
  {
    "sub_district": "แม่เปิน",
    "district": "แม่เปิน",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601401,
    "district_code": 6014,
    "province_code": 60
  },
  {
    "sub_district": "ชุมตาบง",
    "district": "ชุมตาบง",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601501,
    "district_code": 6015,
    "province_code": 60
  },
  {
    "sub_district": "ปางสวรรค์",
    "district": "ชุมตาบง",
    "province": "นครสวรรค์",
    "zipcode": 60150,
    "sub_district_code": 601502,
    "district_code": 6015,
    "province_code": 60
  },
  {
    "sub_district": "สวนใหญ่",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120101,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "ตลาดขวัญ",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120102,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางเขน",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120103,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางกระสอ",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120104,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "ท่าทราย",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120105,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางไผ่",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120106,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางศรีเมือง",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120107,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางกร่าง",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120108,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "ไทรม้า",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120109,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางรักน้อย",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120110,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางใหญ่",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11140,
    "sub_district_code": 120189,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางทราย",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11140,
    "sub_district_code": 120112,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางกรวย",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120190,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บ้านฉาง",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120192,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "ลาดโตนด",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120193,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางตะนาวศรี",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120194,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางซ่อน",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120195,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "วัดใหม่",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120196,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "ตลาดใหม่",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120197,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางประดู่",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120198,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "บางซื่อ",
    "district": "เมืองนนทบุรี",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120191,
    "district_code": 1201,
    "province_code": 12
  },
  {
    "sub_district": "ไทรน้อย",
    "district": "ไทรน้อย",
    "province": "นนทบุรี",
    "zipcode": 11150,
    "sub_district_code": 120501,
    "district_code": 1205,
    "province_code": 12
  },
  {
    "sub_district": "ราษฎร์นิยม",
    "district": "ไทรน้อย",
    "province": "นนทบุรี",
    "zipcode": 11150,
    "sub_district_code": 120502,
    "district_code": 1205,
    "province_code": 12
  },
  {
    "sub_district": "หนองเพรางาย",
    "district": "ไทรน้อย",
    "province": "นนทบุรี",
    "zipcode": 11150,
    "sub_district_code": 120503,
    "district_code": 1205,
    "province_code": 12
  },
  {
    "sub_district": "ไทรใหญ่",
    "district": "ไทรน้อย",
    "province": "นนทบุรี",
    "zipcode": 11150,
    "sub_district_code": 120504,
    "district_code": 1205,
    "province_code": 12
  },
  {
    "sub_district": "ขุนศรี",
    "district": "ไทรน้อย",
    "province": "นนทบุรี",
    "zipcode": 11150,
    "sub_district_code": 120505,
    "district_code": 1205,
    "province_code": 12
  },
  {
    "sub_district": "คลองขวาง",
    "district": "ไทรน้อย",
    "province": "นนทบุรี",
    "zipcode": 11150,
    "sub_district_code": 120506,
    "district_code": 1205,
    "province_code": 12
  },
  {
    "sub_district": "ทวีวัฒนา",
    "district": "ไทรน้อย",
    "province": "นนทบุรี",
    "zipcode": 11150,
    "sub_district_code": 120507,
    "district_code": 1205,
    "province_code": 12
  },
  {
    "sub_district": "วัดชลอ",
    "district": "บางกรวย",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120201,
    "district_code": 1202,
    "province_code": 12
  },
  {
    "sub_district": "บางกรวย",
    "district": "บางกรวย",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120202,
    "district_code": 1202,
    "province_code": 12
  },
  {
    "sub_district": "บางสีทอง",
    "district": "บางกรวย",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120203,
    "district_code": 1202,
    "province_code": 12
  },
  {
    "sub_district": "บางขนุน",
    "district": "บางกรวย",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120204,
    "district_code": 1202,
    "province_code": 12
  },
  {
    "sub_district": "บางขุนกอง",
    "district": "บางกรวย",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120205,
    "district_code": 1202,
    "province_code": 12
  },
  {
    "sub_district": "บางคูเวียง",
    "district": "บางกรวย",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120206,
    "district_code": 1202,
    "province_code": 12
  },
  {
    "sub_district": "มหาสวัสดิ์",
    "district": "บางกรวย",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120207,
    "district_code": 1202,
    "province_code": 12
  },
  {
    "sub_district": "ปลายบาง",
    "district": "บางกรวย",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120208,
    "district_code": 1202,
    "province_code": 12
  },
  {
    "sub_district": "ศาลากลาง",
    "district": "บางกรวย",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120209,
    "district_code": 1202,
    "province_code": 12
  },
  {
    "sub_district": "โสนลอย",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11110,
    "sub_district_code": 120401,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "บางบัวทอง",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11110,
    "sub_district_code": 120402,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "บางรักใหญ่",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11110,
    "sub_district_code": 120403,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "บางคูรัด",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11110,
    "sub_district_code": 120404,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "ละหาร",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11110,
    "sub_district_code": 120405,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "ลำโพ",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11110,
    "sub_district_code": 120406,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "บางไผ่",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120494,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "เสาธงหิน",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11140,
    "sub_district_code": 120496,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "พิมลราช",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11110,
    "sub_district_code": 120407,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "บางรักพัฒนา",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11110,
    "sub_district_code": 120408,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "ปลายบาง",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120498,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "บางพลับ",
    "district": "บางบัวทอง",
    "province": "นนทบุรี",
    "zipcode": 11130,
    "sub_district_code": 120499,
    "district_code": 1204,
    "province_code": 12
  },
  {
    "sub_district": "บางม่วง",
    "district": "บางใหญ่",
    "province": "นนทบุรี",
    "zipcode": 11140,
    "sub_district_code": 120301,
    "district_code": 1203,
    "province_code": 12
  },
  {
    "sub_district": "บางแม่นาง",
    "district": "บางใหญ่",
    "province": "นนทบุรี",
    "zipcode": 11140,
    "sub_district_code": 120302,
    "district_code": 1203,
    "province_code": 12
  },
  {
    "sub_district": "บางเลน",
    "district": "บางใหญ่",
    "province": "นนทบุรี",
    "zipcode": 11140,
    "sub_district_code": 120303,
    "district_code": 1203,
    "province_code": 12
  },
  {
    "sub_district": "เสาธงหิน",
    "district": "บางใหญ่",
    "province": "นนทบุรี",
    "zipcode": 11140,
    "sub_district_code": 120304,
    "district_code": 1203,
    "province_code": 12
  },
  {
    "sub_district": "บางใหญ่",
    "district": "บางใหญ่",
    "province": "นนทบุรี",
    "zipcode": 11140,
    "sub_district_code": 120305,
    "district_code": 1203,
    "province_code": 12
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "บางใหญ่",
    "province": "นนทบุรี",
    "zipcode": 11140,
    "sub_district_code": 120306,
    "district_code": 1203,
    "province_code": 12
  },
  {
    "sub_district": "บางกร่าง",
    "district": "บางใหญ่",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120397,
    "district_code": 1203,
    "province_code": 12
  },
  {
    "sub_district": "บางรักใหญ่",
    "district": "บางใหญ่",
    "province": "นนทบุรี",
    "zipcode": 11110,
    "sub_district_code": 120398,
    "district_code": 1203,
    "province_code": 12
  },
  {
    "sub_district": "ปากเกร็ด",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120601,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "บางตลาด",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120602,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120603,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "บางพูด",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120604,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "บางตะไนย์",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120605,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "คลองพระอุดม",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120606,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "ท่าอิฐ",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120607,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "เกาะเกร็ด",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120608,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "อ้อมเกร็ด",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120609,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "คลองข่อย",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120610,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "บางพลับ",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120611,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "ปากกู้",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120695,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "คลองเกลือ",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120612,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "สองห้อง",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120696,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "ปากพัง",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120697,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "บางบัว",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120698,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "บางพัง",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120699,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "ท่าทราย",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11000,
    "sub_district_code": 120692,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "ท่าทราย",
    "district": "ปากเกร็ด",
    "province": "นนทบุรี",
    "zipcode": 11120,
    "sub_district_code": 120694,
    "district_code": 1206,
    "province_code": 12
  },
  {
    "sub_district": "บางนาค",
    "district": "เมืองนราธิวาส",
    "province": "นราธิวาส",
    "zipcode": 96000,
    "sub_district_code": 960101,
    "district_code": 9601,
    "province_code": 96
  },
  {
    "sub_district": "ลำภู",
    "district": "เมืองนราธิวาส",
    "province": "นราธิวาส",
    "zipcode": 96000,
    "sub_district_code": 960102,
    "district_code": 9601,
    "province_code": 96
  },
  {
    "sub_district": "มะนังตายอ",
    "district": "เมืองนราธิวาส",
    "province": "นราธิวาส",
    "zipcode": 96000,
    "sub_district_code": 960103,
    "district_code": 9601,
    "province_code": 96
  },
  {
    "sub_district": "บางปอ",
    "district": "เมืองนราธิวาส",
    "province": "นราธิวาส",
    "zipcode": 96000,
    "sub_district_code": 960104,
    "district_code": 9601,
    "province_code": 96
  },
  {
    "sub_district": "กะลุวอ",
    "district": "เมืองนราธิวาส",
    "province": "นราธิวาส",
    "zipcode": 96000,
    "sub_district_code": 960105,
    "district_code": 9601,
    "province_code": 96
  },
  {
    "sub_district": "กะลุวอเหนือ",
    "district": "เมืองนราธิวาส",
    "province": "นราธิวาส",
    "zipcode": 96000,
    "sub_district_code": 960106,
    "district_code": 9601,
    "province_code": 96
  },
  {
    "sub_district": "โคกเคียน",
    "district": "เมืองนราธิวาส",
    "province": "นราธิวาส",
    "zipcode": 96000,
    "sub_district_code": 960107,
    "district_code": 9601,
    "province_code": 96
  },
  {
    "sub_district": "เจ๊ะเห",
    "district": "ตากใบ",
    "province": "นราธิวาส",
    "zipcode": 96110,
    "sub_district_code": 960201,
    "district_code": 9602,
    "province_code": 96
  },
  {
    "sub_district": "ไพรวัน",
    "district": "ตากใบ",
    "province": "นราธิวาส",
    "zipcode": 96110,
    "sub_district_code": 960202,
    "district_code": 9602,
    "province_code": 96
  },
  {
    "sub_district": "พร่อน",
    "district": "ตากใบ",
    "province": "นราธิวาส",
    "zipcode": 96110,
    "sub_district_code": 960203,
    "district_code": 9602,
    "province_code": 96
  },
  {
    "sub_district": "ศาลาใหม่",
    "district": "ตากใบ",
    "province": "นราธิวาส",
    "zipcode": 96110,
    "sub_district_code": 960204,
    "district_code": 9602,
    "province_code": 96
  },
  {
    "sub_district": "บางขุนทอง",
    "district": "ตากใบ",
    "province": "นราธิวาส",
    "zipcode": 96110,
    "sub_district_code": 960205,
    "district_code": 9602,
    "province_code": 96
  },
  {
    "sub_district": "เกาะสะท้อน",
    "district": "ตากใบ",
    "province": "นราธิวาส",
    "zipcode": 96110,
    "sub_district_code": 960206,
    "district_code": 9602,
    "province_code": 96
  },
  {
    "sub_district": "นานาค",
    "district": "ตากใบ",
    "province": "นราธิวาส",
    "zipcode": 96110,
    "sub_district_code": 960207,
    "district_code": 9602,
    "province_code": 96
  },
  {
    "sub_district": "โฆษิต",
    "district": "ตากใบ",
    "province": "นราธิวาส",
    "zipcode": 96110,
    "sub_district_code": 960208,
    "district_code": 9602,
    "province_code": 96
  },
  {
    "sub_district": "บาเจาะ",
    "district": "บาเจาะ",
    "province": "นราธิวาส",
    "zipcode": 96170,
    "sub_district_code": 960301,
    "district_code": 9603,
    "province_code": 96
  },
  {
    "sub_district": "ลุโบะสาวอ",
    "district": "บาเจาะ",
    "province": "นราธิวาส",
    "zipcode": 96170,
    "sub_district_code": 960302,
    "district_code": 9603,
    "province_code": 96
  },
  {
    "sub_district": "กาเยาะมาตี",
    "district": "บาเจาะ",
    "province": "นราธิวาส",
    "zipcode": 96170,
    "sub_district_code": 960303,
    "district_code": 9603,
    "province_code": 96
  },
  {
    "sub_district": "ปะลุกาสาเมาะ",
    "district": "บาเจาะ",
    "province": "นราธิวาส",
    "zipcode": 96170,
    "sub_district_code": 960304,
    "district_code": 9603,
    "province_code": 96
  },
  {
    "sub_district": "บาเระเหนือ",
    "district": "บาเจาะ",
    "province": "นราธิวาส",
    "zipcode": 96170,
    "sub_district_code": 960305,
    "district_code": 9603,
    "province_code": 96
  },
  {
    "sub_district": "บาเระใต้",
    "district": "บาเจาะ",
    "province": "นราธิวาส",
    "zipcode": 96170,
    "sub_district_code": 960306,
    "district_code": 9603,
    "province_code": 96
  },
  {
    "sub_district": "ยี่งอ",
    "district": "ยี่งอ",
    "province": "นราธิวาส",
    "zipcode": 96180,
    "sub_district_code": 960401,
    "district_code": 9604,
    "province_code": 96
  },
  {
    "sub_district": "ละหาร",
    "district": "ยี่งอ",
    "province": "นราธิวาส",
    "zipcode": 96180,
    "sub_district_code": 960402,
    "district_code": 9604,
    "province_code": 96
  },
  {
    "sub_district": "จอเบาะ",
    "district": "ยี่งอ",
    "province": "นราธิวาส",
    "zipcode": 96180,
    "sub_district_code": 960403,
    "district_code": 9604,
    "province_code": 96
  },
  {
    "sub_district": "ลุโบะบายะ",
    "district": "ยี่งอ",
    "province": "นราธิวาส",
    "zipcode": 96180,
    "sub_district_code": 960404,
    "district_code": 9604,
    "province_code": 96
  },
  {
    "sub_district": "ลุโบะบือซา",
    "district": "ยี่งอ",
    "province": "นราธิวาส",
    "zipcode": 96180,
    "sub_district_code": 960405,
    "district_code": 9604,
    "province_code": 96
  },
  {
    "sub_district": "ตะปอเยาะ",
    "district": "ยี่งอ",
    "province": "นราธิวาส",
    "zipcode": 96180,
    "sub_district_code": 960406,
    "district_code": 9604,
    "province_code": 96
  },
  {
    "sub_district": "ตันหยงมัส",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 960501,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "ตันหยงลิมอ",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 960502,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "จวบ",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 960596,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "มะรือโบออก",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 960597,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "บูกิต",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 960598,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "บองอ",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96220,
    "sub_district_code": 960506,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "ดุซงญอ",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96220,
    "sub_district_code": 960595,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "กาลิซา",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 960507,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "บาโงสะโต",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 960508,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "เฉลิม",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 960509,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "มะรือโบตก",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 960510,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "ตันหยงมัส",
    "district": "ระแงะ",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 960501,
    "district_code": 9605,
    "province_code": 96
  },
  {
    "sub_district": "รือเสาะ",
    "district": "รือเสาะ",
    "province": "นราธิวาส",
    "zipcode": 96150,
    "sub_district_code": 960601,
    "district_code": 9606,
    "province_code": 96
  },
  {
    "sub_district": "สาวอ",
    "district": "รือเสาะ",
    "province": "นราธิวาส",
    "zipcode": 96150,
    "sub_district_code": 960602,
    "district_code": 9606,
    "province_code": 96
  },
  {
    "sub_district": "เรียง",
    "district": "รือเสาะ",
    "province": "นราธิวาส",
    "zipcode": 96150,
    "sub_district_code": 960603,
    "district_code": 9606,
    "province_code": 96
  },
  {
    "sub_district": "สามัคคี",
    "district": "รือเสาะ",
    "province": "นราธิวาส",
    "zipcode": 96150,
    "sub_district_code": 960604,
    "district_code": 9606,
    "province_code": 96
  },
  {
    "sub_district": "บาตง",
    "district": "รือเสาะ",
    "province": "นราธิวาส",
    "zipcode": 96150,
    "sub_district_code": 960605,
    "district_code": 9606,
    "province_code": 96
  },
  {
    "sub_district": "ลาโละ",
    "district": "รือเสาะ",
    "province": "นราธิวาส",
    "zipcode": 96150,
    "sub_district_code": 960606,
    "district_code": 9606,
    "province_code": 96
  },
  {
    "sub_district": "รือเสาะออก",
    "district": "รือเสาะ",
    "province": "นราธิวาส",
    "zipcode": 96150,
    "sub_district_code": 960607,
    "district_code": 9606,
    "province_code": 96
  },
  {
    "sub_district": "โคกสะตอ",
    "district": "รือเสาะ",
    "province": "นราธิวาส",
    "zipcode": 96150,
    "sub_district_code": 960608,
    "district_code": 9606,
    "province_code": 96
  },
  {
    "sub_district": "สุวารี",
    "district": "รือเสาะ",
    "province": "นราธิวาส",
    "zipcode": 96150,
    "sub_district_code": 960609,
    "district_code": 9606,
    "province_code": 96
  },
  {
    "sub_district": "แว้ง",
    "district": "แว้ง",
    "province": "นราธิวาส",
    "zipcode": 96160,
    "sub_district_code": 960801,
    "district_code": 9608,
    "province_code": 96
  },
  {
    "sub_district": "กายูคละ",
    "district": "แว้ง",
    "province": "นราธิวาส",
    "zipcode": 96160,
    "sub_district_code": 960802,
    "district_code": 9608,
    "province_code": 96
  },
  {
    "sub_district": "ฆอเลาะ",
    "district": "แว้ง",
    "province": "นราธิวาส",
    "zipcode": 96160,
    "sub_district_code": 960803,
    "district_code": 9608,
    "province_code": 96
  },
  {
    "sub_district": "โละจูด",
    "district": "แว้ง",
    "province": "นราธิวาส",
    "zipcode": 96160,
    "sub_district_code": 960804,
    "district_code": 9608,
    "province_code": 96
  },
  {
    "sub_district": "แม่ดง",
    "district": "แว้ง",
    "province": "นราธิวาส",
    "zipcode": 96160,
    "sub_district_code": 960805,
    "district_code": 9608,
    "province_code": 96
  },
  {
    "sub_district": "เอราวัณ",
    "district": "แว้ง",
    "province": "นราธิวาส",
    "zipcode": 96160,
    "sub_district_code": 960806,
    "district_code": 9608,
    "province_code": 96
  },
  {
    "sub_district": "สุไหงโก-ลก",
    "district": "สุไหงโก-ลก",
    "province": "นราธิวาส",
    "zipcode": 96120,
    "sub_district_code": 961001,
    "district_code": 9610,
    "province_code": 96
  },
  {
    "sub_district": "ปาเสมัส",
    "district": "สุไหงโก-ลก",
    "province": "นราธิวาส",
    "zipcode": 96120,
    "sub_district_code": 961002,
    "district_code": 9610,
    "province_code": 96
  },
  {
    "sub_district": "มูโนะ",
    "district": "สุไหงโก-ลก",
    "province": "นราธิวาส",
    "zipcode": 96120,
    "sub_district_code": 961003,
    "district_code": 9610,
    "province_code": 96
  },
  {
    "sub_district": "ปูโยะ",
    "district": "สุไหงโก-ลก",
    "province": "นราธิวาส",
    "zipcode": 96120,
    "sub_district_code": 961004,
    "district_code": 9610,
    "province_code": 96
  },
  {
    "sub_district": "ตันหยงมัส",
    "district": "สุไหงโก-ลก",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 961098,
    "district_code": 9610,
    "province_code": 96
  },
  {
    "sub_district": "ปะลุรู",
    "district": "สุไหงปาดี",
    "province": "นราธิวาส",
    "zipcode": 96140,
    "sub_district_code": 961101,
    "district_code": 9611,
    "province_code": 96
  },
  {
    "sub_district": "สุไหงปาดี",
    "district": "สุไหงปาดี",
    "province": "นราธิวาส",
    "zipcode": 96140,
    "sub_district_code": 961102,
    "district_code": 9611,
    "province_code": 96
  },
  {
    "sub_district": "โต๊ะเด็ง",
    "district": "สุไหงปาดี",
    "province": "นราธิวาส",
    "zipcode": 96140,
    "sub_district_code": 961103,
    "district_code": 9611,
    "province_code": 96
  },
  {
    "sub_district": "สากอ",
    "district": "สุไหงปาดี",
    "province": "นราธิวาส",
    "zipcode": 96140,
    "sub_district_code": 961104,
    "district_code": 9611,
    "province_code": 96
  },
  {
    "sub_district": "ริโก๋",
    "district": "สุไหงปาดี",
    "province": "นราธิวาส",
    "zipcode": 96140,
    "sub_district_code": 961105,
    "district_code": 9611,
    "province_code": 96
  },
  {
    "sub_district": "กาวะ",
    "district": "สุไหงปาดี",
    "province": "นราธิวาส",
    "zipcode": 96140,
    "sub_district_code": 961106,
    "district_code": 9611,
    "province_code": 96
  },
  {
    "sub_district": "ซากอ",
    "district": "ศรีสาคร",
    "province": "นราธิวาส",
    "zipcode": 96210,
    "sub_district_code": 960701,
    "district_code": 9607,
    "province_code": 96
  },
  {
    "sub_district": "ตะมะยูง",
    "district": "ศรีสาคร",
    "province": "นราธิวาส",
    "zipcode": 96210,
    "sub_district_code": 960702,
    "district_code": 9607,
    "province_code": 96
  },
  {
    "sub_district": "ศรีสาคร",
    "district": "ศรีสาคร",
    "province": "นราธิวาส",
    "zipcode": 96210,
    "sub_district_code": 960703,
    "district_code": 9607,
    "province_code": 96
  },
  {
    "sub_district": "ศรีบรรพต",
    "district": "ศรีสาคร",
    "province": "นราธิวาส",
    "zipcode": 96210,
    "sub_district_code": 960706,
    "district_code": 9607,
    "province_code": 96
  },
  {
    "sub_district": "เชิงคีรี",
    "district": "ศรีสาคร",
    "province": "นราธิวาส",
    "zipcode": 96210,
    "sub_district_code": 960704,
    "district_code": 9607,
    "province_code": 96
  },
  {
    "sub_district": "กาหลง",
    "district": "ศรีสาคร",
    "province": "นราธิวาส",
    "zipcode": 96210,
    "sub_district_code": 960705,
    "district_code": 9607,
    "province_code": 96
  },
  {
    "sub_district": "มาโมง",
    "district": "สุคิริน",
    "province": "นราธิวาส",
    "zipcode": 96190,
    "sub_district_code": 960901,
    "district_code": 9609,
    "province_code": 96
  },
  {
    "sub_district": "สุคิริน",
    "district": "สุคิริน",
    "province": "นราธิวาส",
    "zipcode": 96190,
    "sub_district_code": 960902,
    "district_code": 9609,
    "province_code": 96
  },
  {
    "sub_district": "เกียร์",
    "district": "สุคิริน",
    "province": "นราธิวาส",
    "zipcode": 96190,
    "sub_district_code": 960903,
    "district_code": 9609,
    "province_code": 96
  },
  {
    "sub_district": "ภูเขาทอง",
    "district": "สุคิริน",
    "province": "นราธิวาส",
    "zipcode": 96190,
    "sub_district_code": 960904,
    "district_code": 9609,
    "province_code": 96
  },
  {
    "sub_district": "ร่มไทร",
    "district": "สุคิริน",
    "province": "นราธิวาส",
    "zipcode": 96190,
    "sub_district_code": 960905,
    "district_code": 9609,
    "province_code": 96
  },
  {
    "sub_district": "จะแนะ",
    "district": "จะแนะ",
    "province": "นราธิวาส",
    "zipcode": 96220,
    "sub_district_code": 961201,
    "district_code": 9612,
    "province_code": 96
  },
  {
    "sub_district": "ดุซงญอ",
    "district": "จะแนะ",
    "province": "นราธิวาส",
    "zipcode": 96220,
    "sub_district_code": 961202,
    "district_code": 9612,
    "province_code": 96
  },
  {
    "sub_district": "ผดุงมาตร",
    "district": "จะแนะ",
    "province": "นราธิวาส",
    "zipcode": 96220,
    "sub_district_code": 961203,
    "district_code": 9612,
    "province_code": 96
  },
  {
    "sub_district": "ช้างเผือก",
    "district": "จะแนะ",
    "province": "นราธิวาส",
    "zipcode": 96220,
    "sub_district_code": 961204,
    "district_code": 9612,
    "province_code": 96
  },
  {
    "sub_district": "บูกิต",
    "district": "เจาะไอร้อง",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 961302,
    "district_code": 9613,
    "province_code": 96
  },
  {
    "sub_district": "จวบ",
    "district": "เจาะไอร้อง",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 961301,
    "district_code": 9613,
    "province_code": 96
  },
  {
    "sub_district": "มะรือโบออก",
    "district": "เจาะไอร้อง",
    "province": "นราธิวาส",
    "zipcode": 96130,
    "sub_district_code": 961303,
    "district_code": 9613,
    "province_code": 96
  },
  {
    "sub_district": "ในเวียง",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550101,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "บ่อ",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550102,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "ผาสิงห์",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550103,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "ไชยสถาน",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550104,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "ถืมตอง",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550105,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "เรือง",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550106,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "นาซาว",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550107,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "ดู่ใต้",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550108,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "กองควาย",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550109,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "ท่าน้าว",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550193,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "นาปัง",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550194,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "เมืองจัง",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550195,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "ดู่พงษ์",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55210,
    "sub_district_code": 550196,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "ป่าแลวหลวง",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55210,
    "sub_district_code": 550197,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "บ่อสวก",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550116,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "สะเนียน",
    "district": "เมืองน่าน",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 550117,
    "district_code": 5501,
    "province_code": 55
  },
  {
    "sub_district": "เชียงกลาง",
    "district": "เชียงกลาง",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 550901,
    "district_code": 5509,
    "province_code": 55
  },
  {
    "sub_district": "เปือ",
    "district": "เชียงกลาง",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 550902,
    "district_code": 5509,
    "province_code": 55
  },
  {
    "sub_district": "เชียงคาน",
    "district": "เชียงกลาง",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 550903,
    "district_code": 5509,
    "province_code": 55
  },
  {
    "sub_district": "พระธาตุ",
    "district": "เชียงกลาง",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 550904,
    "district_code": 5509,
    "province_code": 55
  },
  {
    "sub_district": "นาไร่หลวง",
    "district": "เชียงกลาง",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 550996,
    "district_code": 5509,
    "province_code": 55
  },
  {
    "sub_district": "ชนแดน",
    "district": "เชียงกลาง",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 550997,
    "district_code": 5509,
    "province_code": 55
  },
  {
    "sub_district": "ยอด",
    "district": "เชียงกลาง",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 550998,
    "district_code": 5509,
    "province_code": 55
  },
  {
    "sub_district": "พญาแก้ว",
    "district": "เชียงกลาง",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 550908,
    "district_code": 5509,
    "province_code": 55
  },
  {
    "sub_district": "พระพุทธบาท",
    "district": "เชียงกลาง",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 550909,
    "district_code": 5509,
    "province_code": 55
  },
  {
    "sub_district": "ริม",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550601,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "ป่าคา",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550602,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "ผาตอ",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550603,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "ยม",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550604,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "ตาลชุม",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550605,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "ศรีภูมิ",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550606,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "จอมพระ",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550607,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "แสนทอง",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550608,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "ท่าวังผา",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550609,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "ศิลาเพชร",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550698,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "ผาทอง",
    "district": "ท่าวังผา",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550610,
    "district_code": 5506,
    "province_code": 55
  },
  {
    "sub_district": "ปอน",
    "district": "ทุ่งช้าง",
    "province": "น่าน",
    "zipcode": 55130,
    "sub_district_code": 550801,
    "district_code": 5508,
    "province_code": 55
  },
  {
    "sub_district": "งอบ",
    "district": "ทุ่งช้าง",
    "province": "น่าน",
    "zipcode": 55130,
    "sub_district_code": 550802,
    "district_code": 5508,
    "province_code": 55
  },
  {
    "sub_district": "และ",
    "district": "ทุ่งช้าง",
    "province": "น่าน",
    "zipcode": 55130,
    "sub_district_code": 550803,
    "district_code": 5508,
    "province_code": 55
  },
  {
    "sub_district": "ทุ่งช้าง",
    "district": "ทุ่งช้าง",
    "province": "น่าน",
    "zipcode": 55130,
    "sub_district_code": 550804,
    "district_code": 5508,
    "province_code": 55
  },
  {
    "sub_district": "นาน้อย",
    "district": "นาน้อย",
    "province": "น่าน",
    "zipcode": 55150,
    "sub_district_code": 550401,
    "district_code": 5504,
    "province_code": 55
  },
  {
    "sub_district": "เชียงของ",
    "district": "นาน้อย",
    "province": "น่าน",
    "zipcode": 55150,
    "sub_district_code": 550402,
    "district_code": 5504,
    "province_code": 55
  },
  {
    "sub_district": "ศรีษะเกษ",
    "district": "นาน้อย",
    "province": "น่าน",
    "zipcode": 55150,
    "sub_district_code": 550403,
    "district_code": 5504,
    "province_code": 55
  },
  {
    "sub_district": "สถาน",
    "district": "นาน้อย",
    "province": "น่าน",
    "zipcode": 55150,
    "sub_district_code": 550404,
    "district_code": 5504,
    "province_code": 55
  },
  {
    "sub_district": "สันทะ",
    "district": "นาน้อย",
    "province": "น่าน",
    "zipcode": 55150,
    "sub_district_code": 550405,
    "district_code": 5504,
    "province_code": 55
  },
  {
    "sub_district": "บัวใหญ่",
    "district": "นาน้อย",
    "province": "น่าน",
    "zipcode": 55150,
    "sub_district_code": 550406,
    "district_code": 5504,
    "province_code": 55
  },
  {
    "sub_district": "น้ำตก",
    "district": "นาน้อย",
    "province": "น่าน",
    "zipcode": 55150,
    "sub_district_code": 550407,
    "district_code": 5504,
    "province_code": 55
  },
  {
    "sub_district": "ปัว",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550501,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "แงง",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550502,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "สถาน",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550503,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "ศิลาแลง",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550504,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "ศิลาเพชร",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550505,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "อวน",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550506,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "บ่อเกลือเหนือ",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55220,
    "sub_district_code": 550595,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "บ่อเกลือใต้",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55220,
    "sub_district_code": 550596,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "ไชยวัฒนา",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550509,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "เจดีย์ชัย",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550510,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "ภูคา",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550511,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "สกาด",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550512,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "วรนคร",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550514,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "ป่ากลาง",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55120,
    "sub_district_code": 550513,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "ท่าวังผา",
    "district": "ปัว",
    "province": "น่าน",
    "zipcode": 55140,
    "sub_district_code": 550598,
    "district_code": 5505,
    "province_code": 55
  },
  {
    "sub_district": "พงษ์",
    "district": "แม่จริม",
    "province": "น่าน",
    "zipcode": 55210,
    "sub_district_code": 550298,
    "district_code": 5502,
    "province_code": 55
  },
  {
    "sub_district": "หนองแดง",
    "district": "แม่จริม",
    "province": "น่าน",
    "zipcode": 55170,
    "sub_district_code": 550202,
    "district_code": 5502,
    "province_code": 55
  },
  {
    "sub_district": "หมอเมือง",
    "district": "แม่จริม",
    "province": "น่าน",
    "zipcode": 55170,
    "sub_district_code": 550203,
    "district_code": 5502,
    "province_code": 55
  },
  {
    "sub_district": "น้ำพาง",
    "district": "แม่จริม",
    "province": "น่าน",
    "zipcode": 55170,
    "sub_district_code": 550204,
    "district_code": 5502,
    "province_code": 55
  },
  {
    "sub_district": "น้ำปาย",
    "district": "แม่จริม",
    "province": "น่าน",
    "zipcode": 55170,
    "sub_district_code": 550205,
    "district_code": 5502,
    "province_code": 55
  },
  {
    "sub_district": "แม่จริม",
    "district": "แม่จริม",
    "province": "น่าน",
    "zipcode": 55170,
    "sub_district_code": 550206,
    "district_code": 5502,
    "province_code": 55
  },
  {
    "sub_district": "กลางเวียง",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550701,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "ขึ่ง",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550702,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "ไหล่น่าน",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550703,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "ตาลชุม",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550704,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "นาเหลือง",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550705,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "ส้าน",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550706,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "น้ำมวบ",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550707,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "น้ำปั้ว",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550708,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "ยาบหัวนา",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550709,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "ปงสนุก",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550710,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "อ่ายนาไลย",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550711,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "ยาบหัวนา",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550709,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "ส้านนาหนองใหม่",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550712,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "แม่ขะนิง",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550713,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "แม่สา",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550716,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "จอมจันทร์",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550715,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "แม่สาคร",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550714,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "ทุ่งศรีทอง",
    "district": "เวียงสา",
    "province": "น่าน",
    "zipcode": 55110,
    "sub_district_code": 550717,
    "district_code": 5507,
    "province_code": 55
  },
  {
    "sub_district": "บ้านฟ้า",
    "district": "บ้านหลวง",
    "province": "น่าน",
    "zipcode": 55190,
    "sub_district_code": 550301,
    "district_code": 5503,
    "province_code": 55
  },
  {
    "sub_district": "ป่าคาหลวง",
    "district": "บ้านหลวง",
    "province": "น่าน",
    "zipcode": 55190,
    "sub_district_code": 550302,
    "district_code": 5503,
    "province_code": 55
  },
  {
    "sub_district": "สวด",
    "district": "บ้านหลวง",
    "province": "น่าน",
    "zipcode": 55190,
    "sub_district_code": 550303,
    "district_code": 5503,
    "province_code": 55
  },
  {
    "sub_district": "บ้านพี้",
    "district": "บ้านหลวง",
    "province": "น่าน",
    "zipcode": 55190,
    "sub_district_code": 550304,
    "district_code": 5503,
    "province_code": 55
  },
  {
    "sub_district": "นาทะนุง",
    "district": "นาหมื่น",
    "province": "น่าน",
    "zipcode": 55180,
    "sub_district_code": 551001,
    "district_code": 5510,
    "province_code": 55
  },
  {
    "sub_district": "บ่อแก้ว",
    "district": "นาหมื่น",
    "province": "น่าน",
    "zipcode": 55180,
    "sub_district_code": 551002,
    "district_code": 5510,
    "province_code": 55
  },
  {
    "sub_district": "เมืองลี",
    "district": "นาหมื่น",
    "province": "น่าน",
    "zipcode": 55180,
    "sub_district_code": 551003,
    "district_code": 5510,
    "province_code": 55
  },
  {
    "sub_district": "ปิงหลวง",
    "district": "นาหมื่น",
    "province": "น่าน",
    "zipcode": 55180,
    "sub_district_code": 551004,
    "district_code": 5510,
    "province_code": 55
  },
  {
    "sub_district": "ดู่พงษ์",
    "district": "สันติสุข",
    "province": "น่าน",
    "zipcode": 55210,
    "sub_district_code": 551101,
    "district_code": 5511,
    "province_code": 55
  },
  {
    "sub_district": "ป่าแลวหลวง",
    "district": "สันติสุข",
    "province": "น่าน",
    "zipcode": 55210,
    "sub_district_code": 551102,
    "district_code": 5511,
    "province_code": 55
  },
  {
    "sub_district": "พงษ์",
    "district": "สันติสุข",
    "province": "น่าน",
    "zipcode": 55210,
    "sub_district_code": 551103,
    "district_code": 5511,
    "province_code": 55
  },
  {
    "sub_district": "บ่อเกลือใต้",
    "district": "บ่อเกลือ",
    "province": "น่าน",
    "zipcode": 55220,
    "sub_district_code": 551202,
    "district_code": 5512,
    "province_code": 55
  },
  {
    "sub_district": "ภูฟ้า",
    "district": "บ่อเกลือ",
    "province": "น่าน",
    "zipcode": 55220,
    "sub_district_code": 551204,
    "district_code": 5512,
    "province_code": 55
  },
  {
    "sub_district": "บ่อเกลือเหนือ",
    "district": "บ่อเกลือ",
    "province": "น่าน",
    "zipcode": 55220,
    "sub_district_code": 551201,
    "district_code": 5512,
    "province_code": 55
  },
  {
    "sub_district": "ดงพญา",
    "district": "บ่อเกลือ",
    "province": "น่าน",
    "zipcode": 55220,
    "sub_district_code": 551205,
    "district_code": 5512,
    "province_code": 55
  },
  {
    "sub_district": "นาไร่หลวง",
    "district": "สองแคว",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 551301,
    "district_code": 5513,
    "province_code": 55
  },
  {
    "sub_district": "ชนแดน",
    "district": "สองแคว",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 551302,
    "district_code": 5513,
    "province_code": 55
  },
  {
    "sub_district": "ยอด",
    "district": "สองแคว",
    "province": "น่าน",
    "zipcode": 55160,
    "sub_district_code": 551303,
    "district_code": 5513,
    "province_code": 55
  },
  {
    "sub_district": "ห้วยโก๋น",
    "district": "เฉลิมพระเกียรติ",
    "province": "น่าน",
    "zipcode": 55130,
    "sub_district_code": 551501,
    "district_code": 5515,
    "province_code": 55
  },
  {
    "sub_district": "ขุนน่าน",
    "district": "เฉลิมพระเกียรติ",
    "province": "น่าน",
    "zipcode": 55220,
    "sub_district_code": 551502,
    "district_code": 5515,
    "province_code": 55
  },
  {
    "sub_district": "ฝายแก้ว",
    "district": "ภูเพียง",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 551407,
    "district_code": 5514,
    "province_code": 55
  },
  {
    "sub_district": "เมืองจัง",
    "district": "ภูเพียง",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 551405,
    "district_code": 5514,
    "province_code": 55
  },
  {
    "sub_district": "ม่วงตึ๊ด",
    "district": "ภูเพียง",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 551401,
    "district_code": 5514,
    "province_code": 55
  },
  {
    "sub_district": "นาปัง",
    "district": "ภูเพียง",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 551402,
    "district_code": 5514,
    "province_code": 55
  },
  {
    "sub_district": "น้ำเกี๋ยน",
    "district": "ภูเพียง",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 551404,
    "district_code": 5514,
    "province_code": 55
  },
  {
    "sub_district": "ท่าน้าว",
    "district": "ภูเพียง",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 551406,
    "district_code": 5514,
    "province_code": 55
  },
  {
    "sub_district": "น้ำแก่น",
    "district": "ภูเพียง",
    "province": "น่าน",
    "zipcode": 55000,
    "sub_district_code": 551403,
    "district_code": 5514,
    "province_code": 55
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310101,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "อิสาณ",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310102,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "เสม็ด",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310103,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "บ้านบัว",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310104,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "สะแกโพรง",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310105,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "สวายจีก",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310106,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "ห้วยราช",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310189,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "บ้านยาง",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310108,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "บ้านด่าน",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310190,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "สามแวง",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310191,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "ปราสาท",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310192,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "พระครู",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310112,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "ถลุงเหล็ก",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310113,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "หนองตาด",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310114,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "โนนขวาง",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310193,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "ชุมเห็ด",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310120,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "บัวทอง",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310119,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "ตาเสา",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310195,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "ลุมปุ๊ก",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310117,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "สองห้อง",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310118,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "สนวน",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310196,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "หลักเขต",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310122,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "คูเมือง",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31190,
    "sub_district_code": 310197,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "กระสัง",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310127,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "สะแกซำ",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310125,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "กลันทา",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310126,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "เมืองฝาง",
    "district": "เมืองบุรีรัมย์",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 310128,
    "district_code": 3101,
    "province_code": 31
  },
  {
    "sub_district": "กระสัง",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310301,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "ลำดวน",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310302,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "สองชั้น",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310303,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "สูงเนิน",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310304,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "หนองเต็ง",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310305,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "เมืองไผ่",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310306,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "ชุมแสง",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310307,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "บ้านปรือ",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310308,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "ห้วยสำราญ",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310309,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "กันทรารมย์",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310310,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "ศรีภูมิ",
    "district": "กระสัง",
    "province": "บุรีรัมย์",
    "zipcode": 31160,
    "sub_district_code": 310311,
    "district_code": 3103,
    "province_code": 31
  },
  {
    "sub_district": "คูเมือง",
    "district": "คูเมือง",
    "province": "บุรีรัมย์",
    "zipcode": 31190,
    "sub_district_code": 310201,
    "district_code": 3102,
    "province_code": 31
  },
  {
    "sub_district": "ปะเคียบ",
    "district": "คูเมือง",
    "province": "บุรีรัมย์",
    "zipcode": 31190,
    "sub_district_code": 310202,
    "district_code": 3102,
    "province_code": 31
  },
  {
    "sub_district": "บ้านแพ",
    "district": "คูเมือง",
    "province": "บุรีรัมย์",
    "zipcode": 31190,
    "sub_district_code": 310203,
    "district_code": 3102,
    "province_code": 31
  },
  {
    "sub_district": "พรสำราญ",
    "district": "คูเมือง",
    "province": "บุรีรัมย์",
    "zipcode": 31190,
    "sub_district_code": 310204,
    "district_code": 3102,
    "province_code": 31
  },
  {
    "sub_district": "หินเหล็กไฟ",
    "district": "คูเมือง",
    "province": "บุรีรัมย์",
    "zipcode": 31190,
    "sub_district_code": 310205,
    "district_code": 3102,
    "province_code": 31
  },
  {
    "sub_district": "ตูมใหญ่",
    "district": "คูเมือง",
    "province": "บุรีรัมย์",
    "zipcode": 31190,
    "sub_district_code": 310206,
    "district_code": 3102,
    "province_code": 31
  },
  {
    "sub_district": "หนองขมาร",
    "district": "คูเมือง",
    "province": "บุรีรัมย์",
    "zipcode": 31190,
    "sub_district_code": 310207,
    "district_code": 3102,
    "province_code": 31
  },
  {
    "sub_district": "นางรอง",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310401,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ตาเป๊ก",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310485,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "สะเดา",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310403,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ชำนิ",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310486,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ชุมแสง",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310405,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "หนองโบสถ์",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310406,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "หนองปล่อง",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310487,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "หนองกง",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310408,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ถนนหัก",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310413,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "เมืองยาง",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310488,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "อีสานเขต",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310489,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ทรัพย์พระยา",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310418,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ทุ่งจังหัน",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310490,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "เจริญสุข",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310491,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "โนนสุวรรณ",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310492,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "หนองไทร",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310414,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ก้านเหลือง",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310415,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "บ้านสิงห์",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310416,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ลำไทรโยง",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310417,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ละลวด",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310493,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "หัวถนน",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310425,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ช่อผกา",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310494,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "โกรกแก้ว",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310495,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ดงอีจาน",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310497,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ถาวร",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 310498,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "ทุ่งแสงทอง",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310426,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "หนองโสน",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310427,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "หนองยายพิมพ์",
    "district": "นางรอง",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310424,
    "district_code": 3104,
    "province_code": 31
  },
  {
    "sub_district": "บ้านกรวด",
    "district": "บ้านกรวด",
    "province": "บุรีรัมย์",
    "zipcode": 31180,
    "sub_district_code": 310801,
    "district_code": 3108,
    "province_code": 31
  },
  {
    "sub_district": "โนนเจริญ",
    "district": "บ้านกรวด",
    "province": "บุรีรัมย์",
    "zipcode": 31180,
    "sub_district_code": 310802,
    "district_code": 3108,
    "province_code": 31
  },
  {
    "sub_district": "หนองไม้งาม",
    "district": "บ้านกรวด",
    "province": "บุรีรัมย์",
    "zipcode": 31180,
    "sub_district_code": 310803,
    "district_code": 3108,
    "province_code": 31
  },
  {
    "sub_district": "ปราสาท",
    "district": "บ้านกรวด",
    "province": "บุรีรัมย์",
    "zipcode": 31180,
    "sub_district_code": 310804,
    "district_code": 3108,
    "province_code": 31
  },
  {
    "sub_district": "สายตะกู",
    "district": "บ้านกรวด",
    "province": "บุรีรัมย์",
    "zipcode": 31180,
    "sub_district_code": 310805,
    "district_code": 3108,
    "province_code": 31
  },
  {
    "sub_district": "หินลาด",
    "district": "บ้านกรวด",
    "province": "บุรีรัมย์",
    "zipcode": 31180,
    "sub_district_code": 310806,
    "district_code": 3108,
    "province_code": 31
  },
  {
    "sub_district": "บึงเจริญ",
    "district": "บ้านกรวด",
    "province": "บุรีรัมย์",
    "zipcode": 31180,
    "sub_district_code": 310807,
    "district_code": 3108,
    "province_code": 31
  },
  {
    "sub_district": "จันทบเพชร",
    "district": "บ้านกรวด",
    "province": "บุรีรัมย์",
    "zipcode": 31180,
    "sub_district_code": 310808,
    "district_code": 3108,
    "province_code": 31
  },
  {
    "sub_district": "เขาดินเหนือ",
    "district": "บ้านกรวด",
    "province": "บุรีรัมย์",
    "zipcode": 31180,
    "sub_district_code": 310809,
    "district_code": 3108,
    "province_code": 31
  },
  {
    "sub_district": "ประโคนชัย",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310701,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "แสลงโทน",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310702,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "บ้านไทร",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310703,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "จันดุม",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31250,
    "sub_district_code": 310794,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "ละเวี้ย",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310705,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "จรเข้มาก",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310706,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "ปังกู",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310707,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "โคกย่าง",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310708,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "โคกขมิ้น",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31250,
    "sub_district_code": 310795,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "โคกม้า",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310710,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "เขาคอก",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310715,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "ป่าชัน",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31250,
    "sub_district_code": 310796,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "สะเดา",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 310797,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "ไพศาล",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310713,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "ตะโกตาพิ",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310714,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "หนองบอน",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310716,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "สำโรง",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31250,
    "sub_district_code": 310798,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "โคกมะขาม",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310718,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "โคกตูม",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310719,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "ประทัดบุ",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310720,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "สี่เหลี่ยม",
    "district": "ประโคนชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31140,
    "sub_district_code": 310721,
    "district_code": 3107,
    "province_code": 31
  },
  {
    "sub_district": "พุทไธสง",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310901,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "มะเฟือง",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310902,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "บ้านจาน",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310903,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "หนองแวง",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310991,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "ทองหลาง",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310992,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "บ้านเป้า",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310906,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "นาโพธิ์",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31230,
    "sub_district_code": 310993,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "บ้านคู",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31230,
    "sub_district_code": 310994,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "บ้านดู่",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31230,
    "sub_district_code": 310995,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "บ้านแวง",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310907,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "แดงใหญ่",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310996,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "บ้านยาง",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310909,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "หายโศก",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310910,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "กู่สวนแตง",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310997,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "หนองเยือง",
    "district": "พุทไธสง",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 310998,
    "district_code": 3109,
    "province_code": 31
  },
  {
    "sub_district": "ละหานทราย",
    "district": "ละหานทราย",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 310601,
    "district_code": 3106,
    "province_code": 31
  },
  {
    "sub_district": "ถาวร",
    "district": "ละหานทราย",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 310694,
    "district_code": 3106,
    "province_code": 31
  },
  {
    "sub_district": "ตาจง",
    "district": "ละหานทราย",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 310603,
    "district_code": 3106,
    "province_code": 31
  },
  {
    "sub_district": "สำโรงใหม่",
    "district": "ละหานทราย",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 310604,
    "district_code": 3106,
    "province_code": 31
  },
  {
    "sub_district": "โนนดินแดง",
    "district": "ละหานทราย",
    "province": "บุรีรัมย์",
    "zipcode": 31260,
    "sub_district_code": 310695,
    "district_code": 3106,
    "province_code": 31
  },
  {
    "sub_district": "ยายแย้มวัฒนา",
    "district": "ละหานทราย",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 310697,
    "district_code": 3106,
    "province_code": 31
  },
  {
    "sub_district": "หนองแวง",
    "district": "ละหานทราย",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 310607,
    "district_code": 3106,
    "province_code": 31
  },
  {
    "sub_district": "หนองตะครอง",
    "district": "ละหานทราย",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 310610,
    "district_code": 3106,
    "province_code": 31
  },
  {
    "sub_district": "โคกว่าน",
    "district": "ละหานทราย",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 310611,
    "district_code": 3106,
    "province_code": 31
  },
  {
    "sub_district": "ลำปลายมาศ",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311001,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "หนองคู",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311002,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "แสลงพัน",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311003,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "ทะเมนชัย",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311004,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "ตลาดโพธิ์",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311005,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "หนองกะทิง",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311006,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "ไทยสามัคคี",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31240,
    "sub_district_code": 311096,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "โคกกลาง",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311007,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311008,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "เมืองแฝก",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311009,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "ห้วยหิน",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31240,
    "sub_district_code": 311097,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "สระแก้ว",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31240,
    "sub_district_code": 311098,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "บ้านยาง",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311010,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "ผไทรินทร์",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311011,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "โคกล่าม",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311012,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "หินโคน",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311013,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "หนองบัวโคก",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311014,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "บุโพธิ์",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311015,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "หนองโดน",
    "district": "ลำปลายมาศ",
    "province": "บุรีรัมย์",
    "zipcode": 31130,
    "sub_district_code": 311016,
    "district_code": 3110,
    "province_code": 31
  },
  {
    "sub_district": "สตึก",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311101,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "นิคม",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311102,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "ทุ่งวัง",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311103,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "เมืองแก",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311104,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "หนองใหญ่",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311105,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "ร่อนทอง",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311106,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "แคนดง",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311196,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "ดงพลอง",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311197,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "ดอนมนต์",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311109,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "ชุมแสง",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311110,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "ท่าม่วง",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311111,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "สะแก",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311112,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "สระบัว",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311198,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "สนามชัย",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311114,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "กระสัง",
    "district": "สตึก",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 311115,
    "district_code": 3111,
    "province_code": 31
  },
  {
    "sub_district": "หนองกี่",
    "district": "หนองกี่",
    "province": "บุรีรัมย์",
    "zipcode": 31210,
    "sub_district_code": 310501,
    "district_code": 3105,
    "province_code": 31
  },
  {
    "sub_district": "เย้ยปราสาท",
    "district": "หนองกี่",
    "province": "บุรีรัมย์",
    "zipcode": 31210,
    "sub_district_code": 310502,
    "district_code": 3105,
    "province_code": 31
  },
  {
    "sub_district": "เมืองไผ่",
    "district": "หนองกี่",
    "province": "บุรีรัมย์",
    "zipcode": 31210,
    "sub_district_code": 310503,
    "district_code": 3105,
    "province_code": 31
  },
  {
    "sub_district": "ดอนอะราง",
    "district": "หนองกี่",
    "province": "บุรีรัมย์",
    "zipcode": 31210,
    "sub_district_code": 310504,
    "district_code": 3105,
    "province_code": 31
  },
  {
    "sub_district": "โคกสว่าง",
    "district": "หนองกี่",
    "province": "บุรีรัมย์",
    "zipcode": 31210,
    "sub_district_code": 310505,
    "district_code": 3105,
    "province_code": 31
  },
  {
    "sub_district": "ทุ่งกระตาดพัฒนา",
    "district": "หนองกี่",
    "province": "บุรีรัมย์",
    "zipcode": 31210,
    "sub_district_code": 310506,
    "district_code": 3105,
    "province_code": 31
  },
  {
    "sub_district": "ทุ่งกระเต็น",
    "district": "หนองกี่",
    "province": "บุรีรัมย์",
    "zipcode": 31210,
    "sub_district_code": 310507,
    "district_code": 3105,
    "province_code": 31
  },
  {
    "sub_district": "โคกสูง",
    "district": "หนองกี่",
    "province": "บุรีรัมย์",
    "zipcode": 31210,
    "sub_district_code": 310509,
    "district_code": 3105,
    "province_code": 31
  },
  {
    "sub_district": "ท่าโพธิ์ชัย",
    "district": "หนองกี่",
    "province": "บุรีรัมย์",
    "zipcode": 31210,
    "sub_district_code": 310508,
    "district_code": 3105,
    "province_code": 31
  },
  {
    "sub_district": "บุกระสัง",
    "district": "หนองกี่",
    "province": "บุรีรัมย์",
    "zipcode": 31210,
    "sub_district_code": 310510,
    "district_code": 3105,
    "province_code": 31
  },
  {
    "sub_district": "ปะคำ",
    "district": "ปะคำ",
    "province": "บุรีรัมย์",
    "zipcode": 31220,
    "sub_district_code": 311201,
    "district_code": 3112,
    "province_code": 31
  },
  {
    "sub_district": "ไทยเจริญ",
    "district": "ปะคำ",
    "province": "บุรีรัมย์",
    "zipcode": 31220,
    "sub_district_code": 311202,
    "district_code": 3112,
    "province_code": 31
  },
  {
    "sub_district": "หนองบัว",
    "district": "ปะคำ",
    "province": "บุรีรัมย์",
    "zipcode": 31220,
    "sub_district_code": 311203,
    "district_code": 3112,
    "province_code": 31
  },
  {
    "sub_district": "โคกมะม่วง",
    "district": "ปะคำ",
    "province": "บุรีรัมย์",
    "zipcode": 31220,
    "sub_district_code": 311204,
    "district_code": 3112,
    "province_code": 31
  },
  {
    "sub_district": "หูทำนบ",
    "district": "ปะคำ",
    "province": "บุรีรัมย์",
    "zipcode": 31220,
    "sub_district_code": 311205,
    "district_code": 3112,
    "province_code": 31
  },
  {
    "sub_district": "สระแก้ว",
    "district": "หนองหงส์",
    "province": "บุรีรัมย์",
    "zipcode": 31240,
    "sub_district_code": 311401,
    "district_code": 3114,
    "province_code": 31
  },
  {
    "sub_district": "ห้วยหิน",
    "district": "หนองหงส์",
    "province": "บุรีรัมย์",
    "zipcode": 31240,
    "sub_district_code": 311402,
    "district_code": 3114,
    "province_code": 31
  },
  {
    "sub_district": "ไทยสามัคคี",
    "district": "หนองหงส์",
    "province": "บุรีรัมย์",
    "zipcode": 31240,
    "sub_district_code": 311403,
    "district_code": 3114,
    "province_code": 31
  },
  {
    "sub_district": "หนองชัยศรี",
    "district": "หนองหงส์",
    "province": "บุรีรัมย์",
    "zipcode": 31240,
    "sub_district_code": 311404,
    "district_code": 3114,
    "province_code": 31
  },
  {
    "sub_district": "เสาเดียว",
    "district": "หนองหงส์",
    "province": "บุรีรัมย์",
    "zipcode": 31240,
    "sub_district_code": 311405,
    "district_code": 3114,
    "province_code": 31
  },
  {
    "sub_district": "สระทอง",
    "district": "หนองหงส์",
    "province": "บุรีรัมย์",
    "zipcode": 31240,
    "sub_district_code": 311407,
    "district_code": 3114,
    "province_code": 31
  },
  {
    "sub_district": "เมืองฝ้าย",
    "district": "หนองหงส์",
    "province": "บุรีรัมย์",
    "zipcode": 31240,
    "sub_district_code": 311406,
    "district_code": 3114,
    "province_code": 31
  },
  {
    "sub_district": "นาโพธิ์",
    "district": "นาโพธิ์",
    "province": "บุรีรัมย์",
    "zipcode": 31230,
    "sub_district_code": 311301,
    "district_code": 3113,
    "province_code": 31
  },
  {
    "sub_district": "บ้านคู",
    "district": "นาโพธิ์",
    "province": "บุรีรัมย์",
    "zipcode": 31230,
    "sub_district_code": 311302,
    "district_code": 3113,
    "province_code": 31
  },
  {
    "sub_district": "บ้านดู่",
    "district": "นาโพธิ์",
    "province": "บุรีรัมย์",
    "zipcode": 31230,
    "sub_district_code": 311303,
    "district_code": 3113,
    "province_code": 31
  },
  {
    "sub_district": "ดอนกอก",
    "district": "นาโพธิ์",
    "province": "บุรีรัมย์",
    "zipcode": 31230,
    "sub_district_code": 311304,
    "district_code": 3113,
    "province_code": 31
  },
  {
    "sub_district": "ศรีสว่าง",
    "district": "นาโพธิ์",
    "province": "บุรีรัมย์",
    "zipcode": 31230,
    "sub_district_code": 311305,
    "district_code": 3113,
    "province_code": 31
  },
  {
    "sub_district": "สะเดา",
    "district": "พลับพลาชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31250,
    "sub_district_code": 311504,
    "district_code": 3115,
    "province_code": 31
  },
  {
    "sub_district": "จันดุม",
    "district": "พลับพลาชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31250,
    "sub_district_code": 311501,
    "district_code": 3115,
    "province_code": 31
  },
  {
    "sub_district": "สำโรง",
    "district": "พลับพลาชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31250,
    "sub_district_code": 311505,
    "district_code": 3115,
    "province_code": 31
  },
  {
    "sub_district": "โคกขมิ้น",
    "district": "พลับพลาชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31250,
    "sub_district_code": 311502,
    "district_code": 3115,
    "province_code": 31
  },
  {
    "sub_district": "ป่าชัน",
    "district": "พลับพลาชัย",
    "province": "บุรีรัมย์",
    "zipcode": 31250,
    "sub_district_code": 311503,
    "district_code": 3115,
    "province_code": 31
  },
  {
    "sub_district": "ห้วยราช",
    "district": "ห้วยราช",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 311601,
    "district_code": 3116,
    "province_code": 31
  },
  {
    "sub_district": "สามแวง",
    "district": "ห้วยราช",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 311602,
    "district_code": 3116,
    "province_code": 31
  },
  {
    "sub_district": "ตาเสา",
    "district": "ห้วยราช",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 311603,
    "district_code": 3116,
    "province_code": 31
  },
  {
    "sub_district": "สนวน",
    "district": "ห้วยราช",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 311605,
    "district_code": 3116,
    "province_code": 31
  },
  {
    "sub_district": "บ้านตะโก",
    "district": "ห้วยราช",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 311604,
    "district_code": 3116,
    "province_code": 31
  },
  {
    "sub_district": "โคกเหล็ก",
    "district": "ห้วยราช",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 311606,
    "district_code": 3116,
    "province_code": 31
  },
  {
    "sub_district": "เมืองโพธิ์",
    "district": "ห้วยราช",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 311607,
    "district_code": 3116,
    "province_code": 31
  },
  {
    "sub_district": "ห้วยราชา",
    "district": "ห้วยราช",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 311608,
    "district_code": 3116,
    "province_code": 31
  },
  {
    "sub_district": "หนองยายพิมพ์",
    "district": "โนนสุวรรณ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311798,
    "district_code": 3117,
    "province_code": 31
  },
  {
    "sub_district": "โนนสุวรรณ",
    "district": "โนนสุวรรณ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311701,
    "district_code": 3117,
    "province_code": 31
  },
  {
    "sub_district": "โกรกแก้ว",
    "district": "โนนสุวรรณ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311703,
    "district_code": 3117,
    "province_code": 31
  },
  {
    "sub_district": "ดงอีจาน",
    "district": "โนนสุวรรณ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311704,
    "district_code": 3117,
    "province_code": 31
  },
  {
    "sub_district": "ทุ่งจังหัน",
    "district": "โนนสุวรรณ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311702,
    "district_code": 3117,
    "province_code": 31
  },
  {
    "sub_district": "ทองหลาง",
    "district": "บ้านใหม่ไชยพจน์",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 311902,
    "district_code": 3119,
    "province_code": 31
  },
  {
    "sub_district": "กู่สวนแตง",
    "district": "บ้านใหม่ไชยพจน์",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 311904,
    "district_code": 3119,
    "province_code": 31
  },
  {
    "sub_district": "หนองแวง",
    "district": "บ้านใหม่ไชยพจน์",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 311901,
    "district_code": 3119,
    "province_code": 31
  },
  {
    "sub_district": "หนองเยือง",
    "district": "บ้านใหม่ไชยพจน์",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 311905,
    "district_code": 3119,
    "province_code": 31
  },
  {
    "sub_district": "แดงใหญ่",
    "district": "บ้านใหม่ไชยพจน์",
    "province": "บุรีรัมย์",
    "zipcode": 31120,
    "sub_district_code": 311903,
    "district_code": 3119,
    "province_code": 31
  },
  {
    "sub_district": "ช่อผกา",
    "district": "ชำนิ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311804,
    "district_code": 3118,
    "province_code": 31
  },
  {
    "sub_district": "ชำนิ",
    "district": "ชำนิ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311801,
    "district_code": 3118,
    "province_code": 31
  },
  {
    "sub_district": "หนองปล่อง",
    "district": "ชำนิ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311802,
    "district_code": 3118,
    "province_code": 31
  },
  {
    "sub_district": "เมืองยาง",
    "district": "ชำนิ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311803,
    "district_code": 3118,
    "province_code": 31
  },
  {
    "sub_district": "ละลวด",
    "district": "ชำนิ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311805,
    "district_code": 3118,
    "province_code": 31
  },
  {
    "sub_district": "โคกสนวน",
    "district": "ชำนิ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 311806,
    "district_code": 3118,
    "province_code": 31
  },
  {
    "sub_district": "โนนดินแดง",
    "district": "โนนดินแดง",
    "province": "บุรีรัมย์",
    "zipcode": 31260,
    "sub_district_code": 312001,
    "district_code": 3120,
    "province_code": 31
  },
  {
    "sub_district": "ลำนางรอง",
    "district": "โนนดินแดง",
    "province": "บุรีรัมย์",
    "zipcode": 31260,
    "sub_district_code": 312003,
    "district_code": 3120,
    "province_code": 31
  },
  {
    "sub_district": "ส้มป่อย",
    "district": "โนนดินแดง",
    "province": "บุรีรัมย์",
    "zipcode": 31260,
    "sub_district_code": 312002,
    "district_code": 3120,
    "province_code": 31
  },
  {
    "sub_district": "บ้านด่าน",
    "district": "บ้านด่าน",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 312101,
    "district_code": 3121,
    "province_code": 31
  },
  {
    "sub_district": "ปราสาท",
    "district": "บ้านด่าน",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 312102,
    "district_code": 3121,
    "province_code": 31
  },
  {
    "sub_district": "วังเหนือ",
    "district": "บ้านด่าน",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 312103,
    "district_code": 3121,
    "province_code": 31
  },
  {
    "sub_district": "โนนขวาง",
    "district": "บ้านด่าน",
    "province": "บุรีรัมย์",
    "zipcode": 31000,
    "sub_district_code": 312104,
    "district_code": 3121,
    "province_code": 31
  },
  {
    "sub_district": "เจริญสุข",
    "district": "เฉลิมพระเกียรติ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 312301,
    "district_code": 3123,
    "province_code": 31
  },
  {
    "sub_district": "ตาเป๊ก",
    "district": "เฉลิมพระเกียรติ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 312302,
    "district_code": 3123,
    "province_code": 31
  },
  {
    "sub_district": "อีสานเขต",
    "district": "เฉลิมพระเกียรติ",
    "province": "บุรีรัมย์",
    "zipcode": 31110,
    "sub_district_code": 312303,
    "district_code": 3123,
    "province_code": 31
  },
  {
    "sub_district": "ถาวร",
    "district": "เฉลิมพระเกียรติ",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 312304,
    "district_code": 3123,
    "province_code": 31
  },
  {
    "sub_district": "ยายแย้มวัฒนา",
    "district": "เฉลิมพระเกียรติ",
    "province": "บุรีรัมย์",
    "zipcode": 31170,
    "sub_district_code": 312305,
    "district_code": 3123,
    "province_code": 31
  },
  {
    "sub_district": "แคนดง",
    "district": "แคนดง",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 312201,
    "district_code": 3122,
    "province_code": 31
  },
  {
    "sub_district": "ดงพลอง",
    "district": "แคนดง",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 312202,
    "district_code": 3122,
    "province_code": 31
  },
  {
    "sub_district": "สระบัว",
    "district": "แคนดง",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 312203,
    "district_code": 3122,
    "province_code": 31
  },
  {
    "sub_district": "หัวฝาย",
    "district": "แคนดง",
    "province": "บุรีรัมย์",
    "zipcode": 31150,
    "sub_district_code": 312204,
    "district_code": 3122,
    "province_code": 31
  },
  {
    "sub_district": "บางปรอก",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130101,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130102,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บ้านกลาง",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130103,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บ้านฉาง",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130104,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บ้านกระแชง",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130105,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บางขะแยง",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130106,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บางคูวัด",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130107,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บางหลวง",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130108,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บางเดื่อ",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130109,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บางพูด",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130110,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บางพูน",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130111,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "บางกะดี",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130112,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "สวนพริกไทย",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130113,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "หลักหก",
    "district": "เมืองปทุมธานี",
    "province": "ปทุมธานี",
    "zipcode": 12000,
    "sub_district_code": 130114,
    "district_code": 1301,
    "province_code": 13
  },
  {
    "sub_district": "คลองหนึ่ง",
    "district": "คลองหลวง",
    "province": "ปทุมธานี",
    "zipcode": 12120,
    "sub_district_code": 130201,
    "district_code": 1302,
    "province_code": 13
  },
  {
    "sub_district": "คลองสอง",
    "district": "คลองหลวง",
    "province": "ปทุมธานี",
    "zipcode": 12120,
    "sub_district_code": 130202,
    "district_code": 1302,
    "province_code": 13
  },
  {
    "sub_district": "คลองสาม",
    "district": "คลองหลวง",
    "province": "ปทุมธานี",
    "zipcode": 12120,
    "sub_district_code": 130203,
    "district_code": 1302,
    "province_code": 13
  },
  {
    "sub_district": "คลองสี่",
    "district": "คลองหลวง",
    "province": "ปทุมธานี",
    "zipcode": 12120,
    "sub_district_code": 130204,
    "district_code": 1302,
    "province_code": 13
  },
  {
    "sub_district": "คลองห้า",
    "district": "คลองหลวง",
    "province": "ปทุมธานี",
    "zipcode": 12120,
    "sub_district_code": 130205,
    "district_code": 1302,
    "province_code": 13
  },
  {
    "sub_district": "คลองหก",
    "district": "คลองหลวง",
    "province": "ปทุมธานี",
    "zipcode": 12120,
    "sub_district_code": 130206,
    "district_code": 1302,
    "province_code": 13
  },
  {
    "sub_district": "คลองเจ็ด",
    "district": "คลองหลวง",
    "province": "ปทุมธานี",
    "zipcode": 12120,
    "sub_district_code": 130207,
    "district_code": 1302,
    "province_code": 13
  },
  {
    "sub_district": "ท่าโขลง",
    "district": "คลองหลวง",
    "province": "ปทุมธานี",
    "zipcode": 12120,
    "sub_district_code": 130208,
    "district_code": 1302,
    "province_code": 13
  },
  {
    "sub_district": "บางหวายใต้",
    "district": "คลองหลวง",
    "province": "ปทุมธานี",
    "zipcode": 12120,
    "sub_district_code": 130209,
    "district_code": 1302,
    "province_code": 13
  },
  {
    "sub_district": "คลองหลวง",
    "district": "คลองหลวง",
    "province": "ปทุมธานี",
    "zipcode": 12120,
    "sub_district_code": 130210,
    "district_code": 1302,
    "province_code": 13
  },
  {
    "sub_district": "ประชาธิปัตย์",
    "district": "ธัญบุรี",
    "province": "ปทุมธานี",
    "zipcode": 12130,
    "sub_district_code": 130301,
    "district_code": 1303,
    "province_code": 13
  },
  {
    "sub_district": "บึงยี่โถ",
    "district": "ธัญบุรี",
    "province": "ปทุมธานี",
    "zipcode": 12130,
    "sub_district_code": 130302,
    "district_code": 1303,
    "province_code": 13
  },
  {
    "sub_district": "รังสิต",
    "district": "ธัญบุรี",
    "province": "ปทุมธานี",
    "zipcode": 12110,
    "sub_district_code": 130303,
    "district_code": 1303,
    "province_code": 13
  },
  {
    "sub_district": "ลำผักกูด",
    "district": "ธัญบุรี",
    "province": "ปทุมธานี",
    "zipcode": 12110,
    "sub_district_code": 130304,
    "district_code": 1303,
    "province_code": 13
  },
  {
    "sub_district": "บึงสนั่น",
    "district": "ธัญบุรี",
    "province": "ปทุมธานี",
    "zipcode": 12110,
    "sub_district_code": 130305,
    "district_code": 1303,
    "province_code": 13
  },
  {
    "sub_district": "บึงน้ำรักษ์",
    "district": "ธัญบุรี",
    "province": "ปทุมธานี",
    "zipcode": 12110,
    "sub_district_code": 130306,
    "district_code": 1303,
    "province_code": 13
  },
  {
    "sub_district": "ลาดหลุมแก้ว",
    "district": "ลาดหลุมแก้ว",
    "province": "ปทุมธานี",
    "zipcode": 12140,
    "sub_district_code": 130502,
    "district_code": 1305,
    "province_code": 13
  },
  {
    "sub_district": "ระแหง",
    "district": "ลาดหลุมแก้ว",
    "province": "ปทุมธานี",
    "zipcode": 12140,
    "sub_district_code": 130501,
    "district_code": 1305,
    "province_code": 13
  },
  {
    "sub_district": "คูบางหลวง",
    "district": "ลาดหลุมแก้ว",
    "province": "ปทุมธานี",
    "zipcode": 12140,
    "sub_district_code": 130503,
    "district_code": 1305,
    "province_code": 13
  },
  {
    "sub_district": "คูขวาง",
    "district": "ลาดหลุมแก้ว",
    "province": "ปทุมธานี",
    "zipcode": 12140,
    "sub_district_code": 130504,
    "district_code": 1305,
    "province_code": 13
  },
  {
    "sub_district": "คลองพระอุดม",
    "district": "ลาดหลุมแก้ว",
    "province": "ปทุมธานี",
    "zipcode": 12140,
    "sub_district_code": 130505,
    "district_code": 1305,
    "province_code": 13
  },
  {
    "sub_district": "บ่อเงิน",
    "district": "ลาดหลุมแก้ว",
    "province": "ปทุมธานี",
    "zipcode": 12140,
    "sub_district_code": 130506,
    "district_code": 1305,
    "province_code": 13
  },
  {
    "sub_district": "หน้าไม้",
    "district": "ลาดหลุมแก้ว",
    "province": "ปทุมธานี",
    "zipcode": 12140,
    "sub_district_code": 130507,
    "district_code": 1305,
    "province_code": 13
  },
  {
    "sub_district": "คูคต",
    "district": "ลำลูกกา",
    "province": "ปทุมธานี",
    "zipcode": 12130,
    "sub_district_code": 130601,
    "district_code": 1306,
    "province_code": 13
  },
  {
    "sub_district": "ลาดสวาย",
    "district": "ลำลูกกา",
    "province": "ปทุมธานี",
    "zipcode": 12150,
    "sub_district_code": 130602,
    "district_code": 1306,
    "province_code": 13
  },
  {
    "sub_district": "บึงคำพร้อย",
    "district": "ลำลูกกา",
    "province": "ปทุมธานี",
    "zipcode": 12150,
    "sub_district_code": 130603,
    "district_code": 1306,
    "province_code": 13
  },
  {
    "sub_district": "ลำลูกกา",
    "district": "ลำลูกกา",
    "province": "ปทุมธานี",
    "zipcode": 12150,
    "sub_district_code": 130604,
    "district_code": 1306,
    "province_code": 13
  },
  {
    "sub_district": "บึงทองหลาง",
    "district": "ลำลูกกา",
    "province": "ปทุมธานี",
    "zipcode": 12150,
    "sub_district_code": 130605,
    "district_code": 1306,
    "province_code": 13
  },
  {
    "sub_district": "ลำไทร",
    "district": "ลำลูกกา",
    "province": "ปทุมธานี",
    "zipcode": 12150,
    "sub_district_code": 130606,
    "district_code": 1306,
    "province_code": 13
  },
  {
    "sub_district": "บึงคอไห",
    "district": "ลำลูกกา",
    "province": "ปทุมธานี",
    "zipcode": 12150,
    "sub_district_code": 130607,
    "district_code": 1306,
    "province_code": 13
  },
  {
    "sub_district": "บึงลาดสวาย",
    "district": "ลำลูกกา",
    "province": "ปทุมธานี",
    "zipcode": 12150,
    "sub_district_code": 130609,
    "district_code": 1306,
    "province_code": 13
  },
  {
    "sub_district": "พืชอุดม",
    "district": "ลำลูกกา",
    "province": "ปทุมธานี",
    "zipcode": 12150,
    "sub_district_code": 130608,
    "district_code": 1306,
    "province_code": 13
  },
  {
    "sub_district": "บางเตย",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130701,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "คลองควาย",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130702,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "สามโคก",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130703,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "กระแชง",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130704,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "บางโพธิ์เหนือ",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130705,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "เชียงรากใหญ่",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130706,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "บ้านปทุม",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130707,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "บ้านงิ้ว",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130708,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "เชียงรากน้อย",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130709,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "บางกระบือ",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130710,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "ท้ายเกาะ",
    "district": "สามโคก",
    "province": "ปทุมธานี",
    "zipcode": 12160,
    "sub_district_code": 130711,
    "district_code": 1307,
    "province_code": 13
  },
  {
    "sub_district": "บึงบา",
    "district": "หนองเสือ",
    "province": "ปทุมธานี",
    "zipcode": 12170,
    "sub_district_code": 130401,
    "district_code": 1304,
    "province_code": 13
  },
  {
    "sub_district": "บึงบอน",
    "district": "หนองเสือ",
    "province": "ปทุมธานี",
    "zipcode": 12170,
    "sub_district_code": 130402,
    "district_code": 1304,
    "province_code": 13
  },
  {
    "sub_district": "บึงกาสาม",
    "district": "หนองเสือ",
    "province": "ปทุมธานี",
    "zipcode": 12170,
    "sub_district_code": 130403,
    "district_code": 1304,
    "province_code": 13
  },
  {
    "sub_district": "บึงชำอ้อ",
    "district": "หนองเสือ",
    "province": "ปทุมธานี",
    "zipcode": 12170,
    "sub_district_code": 130404,
    "district_code": 1304,
    "province_code": 13
  },
  {
    "sub_district": "หนองสามวัง",
    "district": "หนองเสือ",
    "province": "ปทุมธานี",
    "zipcode": 12170,
    "sub_district_code": 130405,
    "district_code": 1304,
    "province_code": 13
  },
  {
    "sub_district": "ศาลาครุ",
    "district": "หนองเสือ",
    "province": "ปทุมธานี",
    "zipcode": 12170,
    "sub_district_code": 130406,
    "district_code": 1304,
    "province_code": 13
  },
  {
    "sub_district": "นพรัตน์",
    "district": "หนองเสือ",
    "province": "ปทุมธานี",
    "zipcode": 12170,
    "sub_district_code": 130407,
    "district_code": 1304,
    "province_code": 13
  },
  {
    "sub_district": "นพรัตน์",
    "district": "หนองเสือ",
    "province": "ปทุมธานี",
    "zipcode": 12170,
    "sub_district_code": 130407,
    "district_code": 1304,
    "province_code": 13
  },
  {
    "sub_district": "ประจวบคีรีขันธ์",
    "district": "เมืองประจวบคีรีขันธ์",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77000,
    "sub_district_code": 770101,
    "district_code": 7701,
    "province_code": 77
  },
  {
    "sub_district": "เกาะหลัก",
    "district": "เมืองประจวบคีรีขันธ์",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77000,
    "sub_district_code": 770102,
    "district_code": 7701,
    "province_code": 77
  },
  {
    "sub_district": "คลองวาฬ",
    "district": "เมืองประจวบคีรีขันธ์",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77000,
    "sub_district_code": 770103,
    "district_code": 7701,
    "province_code": 77
  },
  {
    "sub_district": "ห้วยทราย",
    "district": "เมืองประจวบคีรีขันธ์",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77000,
    "sub_district_code": 770104,
    "district_code": 7701,
    "province_code": 77
  },
  {
    "sub_district": "อ่าวน้อย",
    "district": "เมืองประจวบคีรีขันธ์",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77210,
    "sub_district_code": 770105,
    "district_code": 7701,
    "province_code": 77
  },
  {
    "sub_district": "บ่อนอก",
    "district": "เมืองประจวบคีรีขันธ์",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77210,
    "sub_district_code": 770106,
    "district_code": 7701,
    "province_code": 77
  },
  {
    "sub_district": "กุยบุรี",
    "district": "กุยบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77150,
    "sub_district_code": 770201,
    "district_code": 7702,
    "province_code": 77
  },
  {
    "sub_district": "กุยเหนือ",
    "district": "กุยบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77150,
    "sub_district_code": 770202,
    "district_code": 7702,
    "province_code": 77
  },
  {
    "sub_district": "เขาแดง",
    "district": "กุยบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77150,
    "sub_district_code": 770203,
    "district_code": 7702,
    "province_code": 77
  },
  {
    "sub_district": "ดอนยายหนู",
    "district": "กุยบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77150,
    "sub_district_code": 770204,
    "district_code": 7702,
    "province_code": 77
  },
  {
    "sub_district": "ไร่ใหม่",
    "district": "กุยบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77180,
    "sub_district_code": 770298,
    "district_code": 7702,
    "province_code": 77
  },
  {
    "sub_district": "สามกระทาย",
    "district": "กุยบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77150,
    "sub_district_code": 770206,
    "district_code": 7702,
    "province_code": 77
  },
  {
    "sub_district": "หาดขาม",
    "district": "กุยบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77150,
    "sub_district_code": 770207,
    "district_code": 7702,
    "province_code": 77
  },
  {
    "sub_district": "ทับสะแก",
    "district": "ทับสะแก",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77130,
    "sub_district_code": 770301,
    "district_code": 7703,
    "province_code": 77
  },
  {
    "sub_district": "อ่างทอง",
    "district": "ทับสะแก",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77130,
    "sub_district_code": 770302,
    "district_code": 7703,
    "province_code": 77
  },
  {
    "sub_district": "นาหูกวาง",
    "district": "ทับสะแก",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77130,
    "sub_district_code": 770303,
    "district_code": 7703,
    "province_code": 77
  },
  {
    "sub_district": "เขาล้าน",
    "district": "ทับสะแก",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77130,
    "sub_district_code": 770304,
    "district_code": 7703,
    "province_code": 77
  },
  {
    "sub_district": "ห้วยยาง",
    "district": "ทับสะแก",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77130,
    "sub_district_code": 770305,
    "district_code": 7703,
    "province_code": 77
  },
  {
    "sub_district": "แสงอรุณ",
    "district": "ทับสะแก",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77130,
    "sub_district_code": 770306,
    "district_code": 7703,
    "province_code": 77
  },
  {
    "sub_district": "กำเนิดนพคุณ",
    "district": "บางสะพาน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77140,
    "sub_district_code": 770401,
    "district_code": 7704,
    "province_code": 77
  },
  {
    "sub_district": "พงศ์ประศาสน์",
    "district": "บางสะพาน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77140,
    "sub_district_code": 770402,
    "district_code": 7704,
    "province_code": 77
  },
  {
    "sub_district": "ร่อนทอง",
    "district": "บางสะพาน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77230,
    "sub_district_code": 770403,
    "district_code": 7704,
    "province_code": 77
  },
  {
    "sub_district": "ร่อนทอง",
    "district": "บางสะพาน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77230,
    "sub_district_code": 770403,
    "district_code": 7704,
    "province_code": 77
  },
  {
    "sub_district": "ธงชัย",
    "district": "บางสะพาน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77190,
    "sub_district_code": 770404,
    "district_code": 7704,
    "province_code": 77
  },
  {
    "sub_district": "ชัยเกษม",
    "district": "บางสะพาน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77190,
    "sub_district_code": 770405,
    "district_code": 7704,
    "province_code": 77
  },
  {
    "sub_district": "แม่รำพึง",
    "district": "บางสะพาน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77140,
    "sub_district_code": 770407,
    "district_code": 7704,
    "province_code": 77
  },
  {
    "sub_district": "ทองมงคล",
    "district": "บางสะพาน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77230,
    "sub_district_code": 770406,
    "district_code": 7704,
    "province_code": 77
  },
  {
    "sub_district": "ทรายทอง",
    "district": "บางสะพาน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77170,
    "sub_district_code": 770498,
    "district_code": 7704,
    "province_code": 77
  },
  {
    "sub_district": "ปราณบุรี",
    "district": "ปราณบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77120,
    "sub_district_code": 770601,
    "district_code": 7706,
    "province_code": 77
  },
  {
    "sub_district": "เขาน้อย",
    "district": "ปราณบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77120,
    "sub_district_code": 770602,
    "district_code": 7706,
    "province_code": 77
  },
  {
    "sub_district": "ศิลาลอย",
    "district": "ปราณบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77180,
    "sub_district_code": 770695,
    "district_code": 7706,
    "province_code": 77
  },
  {
    "sub_district": "ปากน้ำปราณ",
    "district": "ปราณบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77220,
    "sub_district_code": 770604,
    "district_code": 7706,
    "province_code": 77
  },
  {
    "sub_district": "สามร้อยยอด",
    "district": "ปราณบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77120,
    "sub_district_code": 770696,
    "district_code": 7706,
    "province_code": 77
  },
  {
    "sub_district": "ไร่เก่า",
    "district": "ปราณบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77180,
    "sub_district_code": 770697,
    "district_code": 7706,
    "province_code": 77
  },
  {
    "sub_district": "หนองตาแต้ม",
    "district": "ปราณบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77120,
    "sub_district_code": 770607,
    "district_code": 7706,
    "province_code": 77
  },
  {
    "sub_district": "วังก์พง",
    "district": "ปราณบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77120,
    "sub_district_code": 770608,
    "district_code": 7706,
    "province_code": 77
  },
  {
    "sub_district": "เขาจ้าว",
    "district": "ปราณบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77120,
    "sub_district_code": 770609,
    "district_code": 7706,
    "province_code": 77
  },
  {
    "sub_district": "กำเนิดนพคุณ",
    "district": "ปราณบุรี",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77140,
    "sub_district_code": 770698,
    "district_code": 7706,
    "province_code": 77
  },
  {
    "sub_district": "หัวหิน",
    "district": "หัวหิน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77110,
    "sub_district_code": 770701,
    "district_code": 7707,
    "province_code": 77
  },
  {
    "sub_district": "หนองแก",
    "district": "หัวหิน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77110,
    "sub_district_code": 770702,
    "district_code": 7707,
    "province_code": 77
  },
  {
    "sub_district": "หินเหล็กไฟ",
    "district": "หัวหิน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77110,
    "sub_district_code": 770703,
    "district_code": 7707,
    "province_code": 77
  },
  {
    "sub_district": "หนองพลับ",
    "district": "หัวหิน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77110,
    "sub_district_code": 770704,
    "district_code": 7707,
    "province_code": 77
  },
  {
    "sub_district": "ทับใต้",
    "district": "หัวหิน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77110,
    "sub_district_code": 770705,
    "district_code": 7707,
    "province_code": 77
  },
  {
    "sub_district": "ห้วยสัตว์ใหญ่",
    "district": "หัวหิน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77110,
    "sub_district_code": 770706,
    "district_code": 7707,
    "province_code": 77
  },
  {
    "sub_district": "บึงนคร",
    "district": "หัวหิน",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77110,
    "sub_district_code": 770707,
    "district_code": 7707,
    "province_code": 77
  },
  {
    "sub_district": "ปากแพรก",
    "district": "บางสะพานน้อย",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77170,
    "sub_district_code": 770501,
    "district_code": 7705,
    "province_code": 77
  },
  {
    "sub_district": "บางสะพาน",
    "district": "บางสะพานน้อย",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77170,
    "sub_district_code": 770502,
    "district_code": 7705,
    "province_code": 77
  },
  {
    "sub_district": "ทรายทอง",
    "district": "บางสะพานน้อย",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77170,
    "sub_district_code": 770503,
    "district_code": 7705,
    "province_code": 77
  },
  {
    "sub_district": "ช้างแรก",
    "district": "บางสะพานน้อย",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77170,
    "sub_district_code": 770504,
    "district_code": 7705,
    "province_code": 77
  },
  {
    "sub_district": "ไชยราช",
    "district": "บางสะพานน้อย",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77170,
    "sub_district_code": 770505,
    "district_code": 7705,
    "province_code": 77
  },
  {
    "sub_district": "สามร้อยยอด",
    "district": "สามร้อยยอด",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77120,
    "sub_district_code": 770801,
    "district_code": 7708,
    "province_code": 77
  },
  {
    "sub_district": "ไร่เก่า",
    "district": "สามร้อยยอด",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77180,
    "sub_district_code": 770803,
    "district_code": 7708,
    "province_code": 77
  },
  {
    "sub_district": "ไร่ใหม่",
    "district": "สามร้อยยอด",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77180,
    "sub_district_code": 770805,
    "district_code": 7708,
    "province_code": 77
  },
  {
    "sub_district": "ศิลาลอย",
    "district": "สามร้อยยอด",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77180,
    "sub_district_code": 770802,
    "district_code": 7708,
    "province_code": 77
  },
  {
    "sub_district": "ศาลาลัย",
    "district": "สามร้อยยอด",
    "province": "ประจวบคีรีขันธ์",
    "zipcode": 77180,
    "sub_district_code": 770804,
    "district_code": 7708,
    "province_code": 77
  },
  {
    "sub_district": "หน้าเมือง",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25000,
    "sub_district_code": 250101,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "รอบเมือง",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25000,
    "sub_district_code": 250102,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "วัดโบสถ์",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25000,
    "sub_district_code": 250103,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "บางเดชะ",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25000,
    "sub_district_code": 250104,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "ท่างาม",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25000,
    "sub_district_code": 250105,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "บางบริบูรณ์",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25000,
    "sub_district_code": 250106,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "ดงพระราม",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25000,
    "sub_district_code": 250107,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "บ้านพระ",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25230,
    "sub_district_code": 250108,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "โคกไม้ลาย",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25230,
    "sub_district_code": 250109,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "ไม้เค็ด",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25230,
    "sub_district_code": 250110,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "ดงขี้เหล็ก",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25000,
    "sub_district_code": 250111,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "เนินหอม",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25230,
    "sub_district_code": 250112,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "โนนห้อม",
    "district": "เมืองปราจีนบุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25000,
    "sub_district_code": 250113,
    "district_code": 2501,
    "province_code": 25
  },
  {
    "sub_district": "กบินทร์",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250201,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "เมืองเก่า",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25240,
    "sub_district_code": 250202,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "วังดาล",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250203,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "นนทรี",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250204,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "ย่านรี",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250205,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "วังตะเคียน",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250206,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "หาดนางแก้ว",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250207,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "ลาดตะเคียน",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250208,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "บ้านนา",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250209,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "บ่อทอง",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250210,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "หนองกี่",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250211,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "นาแขม",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250212,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "เขาไม้แก้ว",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250213,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "วังท่าช้าง",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250214,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "สระแก้ว",
    "district": "กบินทร์บุรี",
    "province": "ปราจีนบุรี",
    "zipcode": 25110,
    "sub_district_code": 250299,
    "district_code": 2502,
    "province_code": 25
  },
  {
    "sub_district": "โคกปีบ",
    "district": "ศรีมโหสถ",
    "province": "ปราจีนบุรี",
    "zipcode": 25190,
    "sub_district_code": 250901,
    "district_code": 2509,
    "province_code": 25
  },
  {
    "sub_district": "โคกไทย",
    "district": "ศรีมโหสถ",
    "province": "ปราจีนบุรี",
    "zipcode": 25190,
    "sub_district_code": 250902,
    "district_code": 2509,
    "province_code": 25
  },
  {
    "sub_district": "คู้ลำพัน",
    "district": "ศรีมโหสถ",
    "province": "ปราจีนบุรี",
    "zipcode": 25190,
    "sub_district_code": 250903,
    "district_code": 2509,
    "province_code": 25
  },
  {
    "sub_district": "ไผ่ชะเลือด",
    "district": "ศรีมโหสถ",
    "province": "ปราจีนบุรี",
    "zipcode": 25190,
    "sub_district_code": 250904,
    "district_code": 2509,
    "province_code": 25
  },
  {
    "sub_district": "ท่าตูม",
    "district": "ศรีมโหสถ",
    "province": "ปราจีนบุรี",
    "zipcode": 25190,
    "sub_district_code": 250905,
    "district_code": 2509,
    "province_code": 25
  },
  {
    "sub_district": "บ้านสร้าง",
    "district": "บ้านสร้าง",
    "province": "ปราจีนบุรี",
    "zipcode": 25150,
    "sub_district_code": 250601,
    "district_code": 2506,
    "province_code": 25
  },
  {
    "sub_district": "บางกระเบา",
    "district": "บ้านสร้าง",
    "province": "ปราจีนบุรี",
    "zipcode": 25150,
    "sub_district_code": 250602,
    "district_code": 2506,
    "province_code": 25
  },
  {
    "sub_district": "บางเตย",
    "district": "บ้านสร้าง",
    "province": "ปราจีนบุรี",
    "zipcode": 25150,
    "sub_district_code": 250603,
    "district_code": 2506,
    "province_code": 25
  },
  {
    "sub_district": "บางยาง",
    "district": "บ้านสร้าง",
    "province": "ปราจีนบุรี",
    "zipcode": 25150,
    "sub_district_code": 250604,
    "district_code": 2506,
    "province_code": 25
  },
  {
    "sub_district": "บางแตน",
    "district": "บ้านสร้าง",
    "province": "ปราจีนบุรี",
    "zipcode": 25150,
    "sub_district_code": 250605,
    "district_code": 2506,
    "province_code": 25
  },
  {
    "sub_district": "บางพลวง",
    "district": "บ้านสร้าง",
    "province": "ปราจีนบุรี",
    "zipcode": 25150,
    "sub_district_code": 250606,
    "district_code": 2506,
    "province_code": 25
  },
  {
    "sub_district": "บางปลาร้า",
    "district": "บ้านสร้าง",
    "province": "ปราจีนบุรี",
    "zipcode": 25150,
    "sub_district_code": 250607,
    "district_code": 2506,
    "province_code": 25
  },
  {
    "sub_district": "บางขาม",
    "district": "บ้านสร้าง",
    "province": "ปราจีนบุรี",
    "zipcode": 25150,
    "sub_district_code": 250608,
    "district_code": 2506,
    "province_code": 25
  },
  {
    "sub_district": "กระทุ่มแพ้ว",
    "district": "บ้านสร้าง",
    "province": "ปราจีนบุรี",
    "zipcode": 25150,
    "sub_district_code": 250609,
    "district_code": 2506,
    "province_code": 25
  },
  {
    "sub_district": "ประจันตคาม",
    "district": "ประจันตคาม",
    "province": "ปราจีนบุรี",
    "zipcode": 25130,
    "sub_district_code": 250701,
    "district_code": 2507,
    "province_code": 25
  },
  {
    "sub_district": "เกาะลอย",
    "district": "ประจันตคาม",
    "province": "ปราจีนบุรี",
    "zipcode": 25130,
    "sub_district_code": 250702,
    "district_code": 2507,
    "province_code": 25
  },
  {
    "sub_district": "บ้านหอย",
    "district": "ประจันตคาม",
    "province": "ปราจีนบุรี",
    "zipcode": 25130,
    "sub_district_code": 250703,
    "district_code": 2507,
    "province_code": 25
  },
  {
    "sub_district": "หนองแสง",
    "district": "ประจันตคาม",
    "province": "ปราจีนบุรี",
    "zipcode": 25130,
    "sub_district_code": 250704,
    "district_code": 2507,
    "province_code": 25
  },
  {
    "sub_district": "ดงบัง",
    "district": "ประจันตคาม",
    "province": "ปราจีนบุรี",
    "zipcode": 25130,
    "sub_district_code": 250705,
    "district_code": 2507,
    "province_code": 25
  },
  {
    "sub_district": "คำโตนด",
    "district": "ประจันตคาม",
    "province": "ปราจีนบุรี",
    "zipcode": 25130,
    "sub_district_code": 250706,
    "district_code": 2507,
    "province_code": 25
  },
  {
    "sub_district": "บุฝ้าย",
    "district": "ประจันตคาม",
    "province": "ปราจีนบุรี",
    "zipcode": 25130,
    "sub_district_code": 250707,
    "district_code": 2507,
    "province_code": 25
  },
  {
    "sub_district": "หนองแก้ว",
    "district": "ประจันตคาม",
    "province": "ปราจีนบุรี",
    "zipcode": 25130,
    "sub_district_code": 250708,
    "district_code": 2507,
    "province_code": 25
  },
  {
    "sub_district": "โพธิ์งาม",
    "district": "ประจันตคาม",
    "province": "ปราจีนบุรี",
    "zipcode": 25130,
    "sub_district_code": 250709,
    "district_code": 2507,
    "province_code": 25
  },
  {
    "sub_district": "ศรีมหาโพธิ",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250801,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "สัมพันธ์",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250802,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "บ้านทาม",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250803,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "ท่าตูม",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250804,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "บางกุ้ง",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250805,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "ดงกระทงยาม",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250806,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "หนองโพรง",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250807,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "หัวหว้า",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250808,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "หาดยาง",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250809,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "โคกไทย",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250810,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "กรอกสมบูรณ์",
    "district": "ศรีมหาโพธิ",
    "province": "ปราจีนบุรี",
    "zipcode": 25140,
    "sub_district_code": 250811,
    "district_code": 2508,
    "province_code": 25
  },
  {
    "sub_district": "นาดี",
    "district": "นาดี",
    "province": "ปราจีนบุรี",
    "zipcode": 25220,
    "sub_district_code": 250301,
    "district_code": 2503,
    "province_code": 25
  },
  {
    "sub_district": "สำพันตา",
    "district": "นาดี",
    "province": "ปราจีนบุรี",
    "zipcode": 25220,
    "sub_district_code": 250302,
    "district_code": 2503,
    "province_code": 25
  },
  {
    "sub_district": "สะพานหิน",
    "district": "นาดี",
    "province": "ปราจีนบุรี",
    "zipcode": 25220,
    "sub_district_code": 250303,
    "district_code": 2503,
    "province_code": 25
  },
  {
    "sub_district": "ทุ่งโพธิ์",
    "district": "นาดี",
    "province": "ปราจีนบุรี",
    "zipcode": 25220,
    "sub_district_code": 250304,
    "district_code": 2503,
    "province_code": 25
  },
  {
    "sub_district": "แก่งดินสอ",
    "district": "นาดี",
    "province": "ปราจีนบุรี",
    "zipcode": 25220,
    "sub_district_code": 250305,
    "district_code": 2503,
    "province_code": 25
  },
  {
    "sub_district": "บุพราหมณ์",
    "district": "นาดี",
    "province": "ปราจีนบุรี",
    "zipcode": 25220,
    "sub_district_code": 250306,
    "district_code": 2503,
    "province_code": 25
  },
  {
    "sub_district": "สะบารัง",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940101,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "อาเนาะรู",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940102,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "จะบังติกอ",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940103,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "บานา",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940104,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "ตันหยงลุโละ",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940105,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "คลองมานิง",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940106,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "กะมิยอ",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940107,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "บาราโหม",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940108,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "ปะกาฮะรัง",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940109,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "รูสะมิแล",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940110,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "ตะลุโบะ",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940111,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "บาราเฮาะ",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940112,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "ปุยุด",
    "district": "เมืองปัตตานี",
    "province": "ปัตตานี",
    "zipcode": 94000,
    "sub_district_code": 940113,
    "district_code": 9401,
    "province_code": 94
  },
  {
    "sub_district": "โคกโพธิ์",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94120,
    "sub_district_code": 940201,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "มะกรูด",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94120,
    "sub_district_code": 940202,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "บางโกระ",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94120,
    "sub_district_code": 940203,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "ป่าบอน",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94120,
    "sub_district_code": 940204,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "ทรายขาว",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94120,
    "sub_district_code": 940205,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "นาประดู่",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94180,
    "sub_district_code": 940206,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "ปากล่อ",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94180,
    "sub_district_code": 940207,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "ทุ่งพลา",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94180,
    "sub_district_code": 940208,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "ท่าเรือ",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94120,
    "sub_district_code": 940211,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "นาเกตุ",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94120,
    "sub_district_code": 940213,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "ควนโนรี",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94180,
    "sub_district_code": 940214,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "ช้างให้ตก",
    "district": "โคกโพธิ์",
    "province": "ปัตตานี",
    "zipcode": 94120,
    "sub_district_code": 940215,
    "district_code": 9402,
    "province_code": 94
  },
  {
    "sub_district": "ปะนาเระ",
    "district": "ปะนาเระ",
    "province": "ปัตตานี",
    "zipcode": 94130,
    "sub_district_code": 940401,
    "district_code": 9404,
    "province_code": 94
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "ปะนาเระ",
    "province": "ปัตตานี",
    "zipcode": 94130,
    "sub_district_code": 940402,
    "district_code": 9404,
    "province_code": 94
  },
  {
    "sub_district": "บ้านนอก",
    "district": "ปะนาเระ",
    "province": "ปัตตานี",
    "zipcode": 94130,
    "sub_district_code": 940403,
    "district_code": 9404,
    "province_code": 94
  },
  {
    "sub_district": "ดอน",
    "district": "ปะนาเระ",
    "province": "ปัตตานี",
    "zipcode": 94190,
    "sub_district_code": 940404,
    "district_code": 9404,
    "province_code": 94
  },
  {
    "sub_district": "ควน",
    "district": "ปะนาเระ",
    "province": "ปัตตานี",
    "zipcode": 94190,
    "sub_district_code": 940405,
    "district_code": 9404,
    "province_code": 94
  },
  {
    "sub_district": "ท่าน้ำ",
    "district": "ปะนาเระ",
    "province": "ปัตตานี",
    "zipcode": 94130,
    "sub_district_code": 940406,
    "district_code": 9404,
    "province_code": 94
  },
  {
    "sub_district": "คอกกระบือ",
    "district": "ปะนาเระ",
    "province": "ปัตตานี",
    "zipcode": 94130,
    "sub_district_code": 940407,
    "district_code": 9404,
    "province_code": 94
  },
  {
    "sub_district": "พ่อมิ่ง",
    "district": "ปะนาเระ",
    "province": "ปัตตานี",
    "zipcode": 94130,
    "sub_district_code": 940408,
    "district_code": 9404,
    "province_code": 94
  },
  {
    "sub_district": "บ้านกลาง",
    "district": "ปะนาเระ",
    "province": "ปัตตานี",
    "zipcode": 94130,
    "sub_district_code": 940409,
    "district_code": 9404,
    "province_code": 94
  },
  {
    "sub_district": "บ้านน้ำบ่อ",
    "district": "ปะนาเระ",
    "province": "ปัตตานี",
    "zipcode": 94130,
    "sub_district_code": 940410,
    "district_code": 9404,
    "province_code": 94
  },
  {
    "sub_district": "มายอ",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940501,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "ถนน",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940502,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "ตรัง",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940503,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "กระหวะ",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94190,
    "sub_district_code": 940504,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "ลุโบะยิไร",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940505,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "ลางา",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94190,
    "sub_district_code": 940506,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "กระเสาะ",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940507,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "เกาะจัน",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940508,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "ปะโด",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940509,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "สาคอบน",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940510,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "สาคอใต้",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940511,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "สะกำ",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940512,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "ปานัน",
    "district": "มายอ",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940513,
    "district_code": 9405,
    "province_code": 94
  },
  {
    "sub_district": "ยะรัง",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941001,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "สะดาวา",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941002,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "ประจัน",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941003,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "สะนอ",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941004,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "ระแว้ง",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941005,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "ปิตูมุดี",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941006,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "วัด",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941007,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "กระโด",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941008,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "คลองใหม่",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941009,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "เมาะมาวี",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941010,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "กอลำ",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941011,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "เขาตูม",
    "district": "ยะรัง",
    "province": "ปัตตานี",
    "zipcode": 94160,
    "sub_district_code": 941012,
    "district_code": 9410,
    "province_code": 94
  },
  {
    "sub_district": "ตะโละ",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940901,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ตะโละกาโปร์",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940902,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ตันหยงดาลอ",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940903,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ตันหยงจึงงา",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94190,
    "sub_district_code": 940904,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ตอหลัง",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940905,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ตาแกะ",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940906,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ตาลีอายร์",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940907,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ยามู",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940908,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "บางปู",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940909,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "หนองแรต",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940910,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ปิยามุมัง",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940911,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ปุลากง",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940912,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "บาโลย",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94190,
    "sub_district_code": 940913,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "สาบัน",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940914,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "มะนังยง",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940915,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ราตาปันยัง",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940916,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "จะรัง",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940917,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "แหลมโพธิ์",
    "district": "ยะหริ่ง",
    "province": "ปัตตานี",
    "zipcode": 94150,
    "sub_district_code": 940918,
    "district_code": 9409,
    "province_code": 94
  },
  {
    "sub_district": "ตะลุบัน",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94110,
    "sub_district_code": 940701,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "ตะบิ้ง",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94110,
    "sub_district_code": 940702,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "ปะเสยะวอ",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94110,
    "sub_district_code": 940703,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "บางเก่า",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94110,
    "sub_district_code": 940704,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "บือเระ",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94110,
    "sub_district_code": 940705,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "เตราะบอน",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94110,
    "sub_district_code": 940706,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "กะดุนง",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94110,
    "sub_district_code": 940707,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "ละหาร",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94110,
    "sub_district_code": 940708,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "มะนังดาลำ",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94110,
    "sub_district_code": 940709,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "แป้น",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94110,
    "sub_district_code": 940710,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "ทุ่งคล้า",
    "district": "สายบุรี",
    "province": "ปัตตานี",
    "zipcode": 94190,
    "sub_district_code": 940711,
    "district_code": 9407,
    "province_code": 94
  },
  {
    "sub_district": "เกาะเปาะ",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940301,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "คอลอตันหยง",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940302,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "ดอนรัก",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940303,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "ดาโต๊ะ",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940304,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "ตุยง",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940305,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "ท่ากำชำ",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940306,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "บ่อทอง",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940307,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "บางเขา",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940308,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "บางตาวา",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940309,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "ปุโละปุโย",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940310,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "ยาบี",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940311,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "ลิปะสะโง",
    "district": "หนองจิก",
    "province": "ปัตตานี",
    "zipcode": 94170,
    "sub_district_code": 940312,
    "district_code": 9403,
    "province_code": 94
  },
  {
    "sub_district": "ไทรทอง",
    "district": "ไม้แก่น",
    "province": "ปัตตานี",
    "zipcode": 94220,
    "sub_district_code": 940801,
    "district_code": 9408,
    "province_code": 94
  },
  {
    "sub_district": "ไม้แก่น",
    "district": "ไม้แก่น",
    "province": "ปัตตานี",
    "zipcode": 94220,
    "sub_district_code": 940802,
    "district_code": 9408,
    "province_code": 94
  },
  {
    "sub_district": "ตะโละไกรทอง",
    "district": "ไม้แก่น",
    "province": "ปัตตานี",
    "zipcode": 94220,
    "sub_district_code": 940803,
    "district_code": 9408,
    "province_code": 94
  },
  {
    "sub_district": "ดอนทราย",
    "district": "ไม้แก่น",
    "province": "ปัตตานี",
    "zipcode": 94220,
    "sub_district_code": 940804,
    "district_code": 9408,
    "province_code": 94
  },
  {
    "sub_district": "ตะโละแมะนา",
    "district": "ทุ่งยางแดง",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940601,
    "district_code": 9406,
    "province_code": 94
  },
  {
    "sub_district": "พิเทน",
    "district": "ทุ่งยางแดง",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940602,
    "district_code": 9406,
    "province_code": 94
  },
  {
    "sub_district": "น้ำดำ",
    "district": "ทุ่งยางแดง",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940603,
    "district_code": 9406,
    "province_code": 94
  },
  {
    "sub_district": "ปากู",
    "district": "ทุ่งยางแดง",
    "province": "ปัตตานี",
    "zipcode": 94140,
    "sub_district_code": 940604,
    "district_code": 9406,
    "province_code": 94
  },
  {
    "sub_district": "กะรุบี",
    "district": "กะพ้อ",
    "province": "ปัตตานี",
    "zipcode": 94230,
    "sub_district_code": 941101,
    "district_code": 9411,
    "province_code": 94
  },
  {
    "sub_district": "ตะโละดือรามัน",
    "district": "กะพ้อ",
    "province": "ปัตตานี",
    "zipcode": 94230,
    "sub_district_code": 941102,
    "district_code": 9411,
    "province_code": 94
  },
  {
    "sub_district": "ปล่องหอย",
    "district": "กะพ้อ",
    "province": "ปัตตานี",
    "zipcode": 94230,
    "sub_district_code": 941103,
    "district_code": 9411,
    "province_code": 94
  },
  {
    "sub_district": "แม่ลาน",
    "district": "แม่ลาน",
    "province": "ปัตตานี",
    "zipcode": 94180,
    "sub_district_code": 941201,
    "district_code": 9412,
    "province_code": 94
  },
  {
    "sub_district": "ป่าไร่",
    "district": "แม่ลาน",
    "province": "ปัตตานี",
    "zipcode": 94180,
    "sub_district_code": 941203,
    "district_code": 9412,
    "province_code": 94
  },
  {
    "sub_district": "ม่วงเตี้ย",
    "district": "แม่ลาน",
    "province": "ปัตตานี",
    "zipcode": 94180,
    "sub_district_code": 941202,
    "district_code": 9412,
    "province_code": 94
  },
  {
    "sub_district": "ประตูชัย",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140101,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "กะมัง",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140102,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "หอรัตนไชย",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140103,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "หัวรอ",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140104,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "ท่าวาสุกรี",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140105,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "ไผ่ลิง",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140106,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "ปากกราน",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140107,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "ภูเขาทอง",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140108,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "สำเภาล่ม",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140109,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "สวนพริก",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140110,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "คลองตะเคียน",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140111,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "วัดตูม",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140112,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "หันตรา",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140113,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "ลุมพลี",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140114,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140115,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "บ้านเกาะ",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140116,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "คลองสวนพลู",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140117,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "คลองสระบัว",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140118,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "เกาะเรียน",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140119,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "บ้านป้อม",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140120,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "บ้านรุน",
    "district": "พระนครศรีอยุธยา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13000,
    "sub_district_code": 140121,
    "district_code": 1401,
    "province_code": 14
  },
  {
    "sub_district": "ท่าเรือ",
    "district": "ท่าเรือ",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13130,
    "sub_district_code": 140201,
    "district_code": 1402,
    "province_code": 14
  },
  {
    "sub_district": "จำปา",
    "district": "ท่าเรือ",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13130,
    "sub_district_code": 140202,
    "district_code": 1402,
    "province_code": 14
  },
  {
    "sub_district": "ท่าหลวง",
    "district": "ท่าเรือ",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 18270,
    "sub_district_code": 140203,
    "district_code": 1402,
    "province_code": 14
  },
  {
    "sub_district": "บ้านร่อม",
    "district": "ท่าเรือ",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13130,
    "sub_district_code": 140204,
    "district_code": 1402,
    "province_code": 14
  },
  {
    "sub_district": "ศาลาลอย",
    "district": "ท่าเรือ",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13130,
    "sub_district_code": 140205,
    "district_code": 1402,
    "province_code": 14
  },
  {
    "sub_district": "วังแดง",
    "district": "ท่าเรือ",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13130,
    "sub_district_code": 140206,
    "district_code": 1402,
    "province_code": 14
  },
  {
    "sub_district": "โพธิ์เอน",
    "district": "ท่าเรือ",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13130,
    "sub_district_code": 140207,
    "district_code": 1402,
    "province_code": 14
  },
  {
    "sub_district": "ปากท่า",
    "district": "ท่าเรือ",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13130,
    "sub_district_code": 140208,
    "district_code": 1402,
    "province_code": 14
  },
  {
    "sub_district": "หนองขนาก",
    "district": "ท่าเรือ",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13130,
    "sub_district_code": 140209,
    "district_code": 1402,
    "province_code": 14
  },
  {
    "sub_district": "ท่าเจ้าสนุก",
    "district": "ท่าเรือ",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13130,
    "sub_district_code": 140210,
    "district_code": 1402,
    "province_code": 14
  },
  {
    "sub_district": "นครหลวง",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140301,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140302,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "บ่อโพง",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140303,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "บ้านชุ้ง",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140304,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "ปากจั่น",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140305,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "บางระกำ",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140306,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "บางพระครู",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140307,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "แม่ลา",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140308,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "หนองปลิง",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140309,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "คลองสะแก",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140310,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "สามไถ",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140311,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "พระนอน",
    "district": "นครหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13260,
    "sub_district_code": 140312,
    "district_code": 1403,
    "province_code": 14
  },
  {
    "sub_district": "บางซ้าย",
    "district": "บางซ้าย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13270,
    "sub_district_code": 141301,
    "district_code": 1413,
    "province_code": 14
  },
  {
    "sub_district": "แก้วฟ้า",
    "district": "บางซ้าย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13270,
    "sub_district_code": 141302,
    "district_code": 1413,
    "province_code": 14
  },
  {
    "sub_district": "เต่าเล่า",
    "district": "บางซ้าย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13270,
    "sub_district_code": 141303,
    "district_code": 1413,
    "province_code": 14
  },
  {
    "sub_district": "ปลายกลัด",
    "district": "บางซ้าย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13270,
    "sub_district_code": 141304,
    "district_code": 1413,
    "province_code": 14
  },
  {
    "sub_district": "เทพมงคล",
    "district": "บางซ้าย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13270,
    "sub_district_code": 141305,
    "district_code": 1413,
    "province_code": 14
  },
  {
    "sub_district": "วังพัฒนา",
    "district": "บางซ้าย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13270,
    "sub_district_code": 141306,
    "district_code": 1413,
    "province_code": 14
  },
  {
    "sub_district": "บางไทร",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140401,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "บางพลี",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140402,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "สนามไชย",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140403,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "บ้านแป้ง",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140404,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "หน้าไม้",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140405,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "บางยี่โท",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140406,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "แคออก",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140407,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "แคตก",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140408,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "ช่างเหล็ก",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140409,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "กระแชง",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140410,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "บ้านกลึง",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140411,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "ช้างน้อย",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140412,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "ห่อหมก",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140413,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "ไผ่พระ",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140414,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "กกแก้วบูรพา",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140415,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "ไม้ตรา",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140416,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "บ้านม้า",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140417,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "บ้านเกาะ",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140418,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "ราชคราม",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13290,
    "sub_district_code": 140419,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "ช้างใหญ่",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13290,
    "sub_district_code": 140420,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "โพแตง",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13290,
    "sub_district_code": 140421,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "เชียงรากน้อย",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13290,
    "sub_district_code": 140422,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "โคกช้าง",
    "district": "บางไทร",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13190,
    "sub_district_code": 140423,
    "district_code": 1404,
    "province_code": 14
  },
  {
    "sub_district": "บางบาล",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140501,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "วัดยม",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140502,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "ไทรน้อย",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140503,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "สะพานไทย",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140504,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "มหาพราหมณ์",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140505,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "กบเจา",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140506,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "บ้านคลัง",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140507,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "พระขาว",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140508,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "น้ำเต้า",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140509,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "ทางช้าง",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140510,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "วัดตะกู",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140511,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "บางหลวง",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140512,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "บางหลวงโดด",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140513,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "บางหัก",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140514,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "บางชะนี",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140515,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "บ้านกุ่ม",
    "district": "บางบาล",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13250,
    "sub_district_code": 140516,
    "district_code": 1405,
    "province_code": 14
  },
  {
    "sub_district": "บางปะหัน",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140701,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "ขยาย",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140702,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "บางเดื่อ",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140703,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "เสาธง",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140704,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "ทางกลาง",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140705,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "บางเพลิง",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140706,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "หันสัง",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140707,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "บางนางร้า",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140708,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "ตานิม",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140709,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "ทับน้ำ",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140710,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "บ้านม้า",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140711,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "ขวัญเมือง",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140712,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "บ้านลี่",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140713,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "โพธิ์สามต้น",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140714,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "พุทเลา",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140715,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "ตาลเอน",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140716,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "บ้านขล้อ",
    "district": "บางปะหัน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13220,
    "sub_district_code": 140717,
    "district_code": 1407,
    "province_code": 14
  },
  {
    "sub_district": "บ้านเลน",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140601,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "เชียงรากน้อย",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13180,
    "sub_district_code": 140602,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "บ้านโพ",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140603,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "บ้านกรด",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140604,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "บางกระสั้น",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140605,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "คลองจิก",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140606,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "บ้านหว้า",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140607,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "วัดยม",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140608,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "บางประแดง",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140609,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "สามเรือน",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140610,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "เกาะเกิด",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140611,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "บ้านพลับ",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140612,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "บ้านแป้ง",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140613,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "คุ้งลาน",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140614,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "ตลิ่งชัน",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140615,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "บ้านสร้าง",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 140616,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "ตลาดเกรียบ",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140617,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "ขนอนหลวง",
    "district": "บางปะอิน",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13160,
    "sub_district_code": 140618,
    "district_code": 1406,
    "province_code": 14
  },
  {
    "sub_district": "บ้านแพรก",
    "district": "บ้านแพรก",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13240,
    "sub_district_code": 141601,
    "district_code": 1416,
    "province_code": 14
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "บ้านแพรก",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13240,
    "sub_district_code": 141602,
    "district_code": 1416,
    "province_code": 14
  },
  {
    "sub_district": "สำพะเนียง",
    "district": "บ้านแพรก",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13240,
    "sub_district_code": 141603,
    "district_code": 1416,
    "province_code": 14
  },
  {
    "sub_district": "คลองน้อย",
    "district": "บ้านแพรก",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13240,
    "sub_district_code": 141604,
    "district_code": 1416,
    "province_code": 14
  },
  {
    "sub_district": "สองห้อง",
    "district": "บ้านแพรก",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13240,
    "sub_district_code": 141605,
    "district_code": 1416,
    "province_code": 14
  },
  {
    "sub_district": "ผักไห่",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140801,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "อมฤต",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140802,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "บ้านแค",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140803,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "ลาดน้ำเค็ม",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140804,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "ตาลาน",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140805,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "ท่าดินแดง",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140806,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "ดอนลาน",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13280,
    "sub_district_code": 140807,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "นาคู",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13280,
    "sub_district_code": 140808,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "กุฎี",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140809,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "ลำตะเคียน",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13280,
    "sub_district_code": 140810,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "โคกช้าง",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140811,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "จักราช",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13280,
    "sub_district_code": 140812,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "หนองน้ำใหญ่",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13280,
    "sub_district_code": 140813,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "ลาดชิด",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140814,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "หน้าโคก",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140815,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "บ้านใหญ่",
    "district": "ผักไห่",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13120,
    "sub_district_code": 140816,
    "district_code": 1408,
    "province_code": 14
  },
  {
    "sub_district": "ภาชี",
    "district": "ภาชี",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13140,
    "sub_district_code": 140901,
    "district_code": 1409,
    "province_code": 14
  },
  {
    "sub_district": "โคกม่วง",
    "district": "ภาชี",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13140,
    "sub_district_code": 140902,
    "district_code": 1409,
    "province_code": 14
  },
  {
    "sub_district": "ระโสม",
    "district": "ภาชี",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13140,
    "sub_district_code": 140903,
    "district_code": 1409,
    "province_code": 14
  },
  {
    "sub_district": "หนองน้ำใส",
    "district": "ภาชี",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13140,
    "sub_district_code": 140904,
    "district_code": 1409,
    "province_code": 14
  },
  {
    "sub_district": "ดอนหญ้านาง",
    "district": "ภาชี",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13140,
    "sub_district_code": 140905,
    "district_code": 1409,
    "province_code": 14
  },
  {
    "sub_district": "ไผ่ล้อม",
    "district": "ภาชี",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13140,
    "sub_district_code": 140906,
    "district_code": 1409,
    "province_code": 14
  },
  {
    "sub_district": "กระจิว",
    "district": "ภาชี",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13140,
    "sub_district_code": 140907,
    "district_code": 1409,
    "province_code": 14
  },
  {
    "sub_district": "พระแก้ว",
    "district": "ภาชี",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13140,
    "sub_district_code": 140908,
    "district_code": 1409,
    "province_code": 14
  },
  {
    "sub_district": "หัวไผ่",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141501,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "กะทุ่ม",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141502,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "มหาราช",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141503,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "น้ำเต้า",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141504,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "บางนา",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141505,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "โรงช้าง",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141506,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "เจ้าปลุก",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141507,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "พิตเพียน",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141508,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "บ้านนา",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141509,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "บ้านขวาง",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141510,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "ท่าตอ",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141511,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "มหาราช",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13150,
    "sub_district_code": 141512,
    "district_code": 1415,
    "province_code": 14
  },
  {
    "sub_district": "ลาดบัวหลวง",
    "district": "ลาดบัวหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13230,
    "sub_district_code": 141001,
    "district_code": 1410,
    "province_code": 14
  },
  {
    "sub_district": "หลักชัย",
    "district": "ลาดบัวหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13230,
    "sub_district_code": 141002,
    "district_code": 1410,
    "province_code": 14
  },
  {
    "sub_district": "สามเมือง",
    "district": "ลาดบัวหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13230,
    "sub_district_code": 141003,
    "district_code": 1410,
    "province_code": 14
  },
  {
    "sub_district": "พระยาบันลือ",
    "district": "ลาดบัวหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13230,
    "sub_district_code": 141004,
    "district_code": 1410,
    "province_code": 14
  },
  {
    "sub_district": "สิงหนาท",
    "district": "ลาดบัวหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13230,
    "sub_district_code": 141005,
    "district_code": 1410,
    "province_code": 14
  },
  {
    "sub_district": "คู้สลอด",
    "district": "ลาดบัวหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13230,
    "sub_district_code": 141006,
    "district_code": 1410,
    "province_code": 14
  },
  {
    "sub_district": "คลองพระยาบันลือ",
    "district": "ลาดบัวหลวง",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13230,
    "sub_district_code": 141007,
    "district_code": 1410,
    "province_code": 14
  },
  {
    "sub_district": "ลำตาเสา",
    "district": "วังน้อย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 141101,
    "district_code": 1411,
    "province_code": 14
  },
  {
    "sub_district": "บ่อตาโล่",
    "district": "วังน้อย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 141102,
    "district_code": 1411,
    "province_code": 14
  },
  {
    "sub_district": "วังน้อย",
    "district": "วังน้อย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 141103,
    "district_code": 1411,
    "province_code": 14
  },
  {
    "sub_district": "ลำไทร",
    "district": "วังน้อย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 141104,
    "district_code": 1411,
    "province_code": 14
  },
  {
    "sub_district": "สนับทึบ",
    "district": "วังน้อย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 141105,
    "district_code": 1411,
    "province_code": 14
  },
  {
    "sub_district": "พยอม",
    "district": "วังน้อย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 141106,
    "district_code": 1411,
    "province_code": 14
  },
  {
    "sub_district": "หันตะเภา",
    "district": "วังน้อย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 141107,
    "district_code": 1411,
    "province_code": 14
  },
  {
    "sub_district": "วังจุฬา",
    "district": "วังน้อย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 141108,
    "district_code": 1411,
    "province_code": 14
  },
  {
    "sub_district": "ข้าวงาม",
    "district": "วังน้อย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 141109,
    "district_code": 1411,
    "province_code": 14
  },
  {
    "sub_district": "ชะแมบ",
    "district": "วังน้อย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13170,
    "sub_district_code": 141110,
    "district_code": 1411,
    "province_code": 14
  },
  {
    "sub_district": "เสนา",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141201,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "บ้านแพน",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141202,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "เจ้าเจ็ด",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141203,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "สามกอ",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141204,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "บางนมโค",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141205,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "หัวเวียง",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141206,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "มารวิชัย",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141207,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "บ้านโพธิ์",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141208,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "รางจรเข้",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141209,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "บ้านกระทุ่ม",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141210,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "บ้านแถว",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141211,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "ชายนา",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141212,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "สามตุ่ม",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141213,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "ลาดงา",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141214,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "ดอนทอง",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141215,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "บ้านหลวง",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141216,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "เจ้าเสด็จ",
    "district": "เสนา",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13110,
    "sub_district_code": 141217,
    "district_code": 1412,
    "province_code": 14
  },
  {
    "sub_district": "อุทัย",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141406,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "เสนา",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141407,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "หนองน้ำส้ม",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141408,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "โพสาวหาญ",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141409,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "ธนู",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141410,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "ข้าวเม่า",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141411,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "คานหาม",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141401,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "บ้านช้าง",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141402,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "สามบัณฑิต",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141403,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "บ้านหีบ",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141404,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "หนองไม้ซุง",
    "district": "อุทัย",
    "province": "พระนครศรีอยุธยา",
    "zipcode": 13210,
    "sub_district_code": 141405,
    "district_code": 1414,
    "province_code": 14
  },
  {
    "sub_district": "ท้ายช้าง",
    "district": "เมืองพังงา",
    "province": "พังงา",
    "zipcode": 82000,
    "sub_district_code": 820101,
    "district_code": 8201,
    "province_code": 82
  },
  {
    "sub_district": "นบปริง",
    "district": "เมืองพังงา",
    "province": "พังงา",
    "zipcode": 82000,
    "sub_district_code": 820102,
    "district_code": 8201,
    "province_code": 82
  },
  {
    "sub_district": "ถ้ำน้ำผุด",
    "district": "เมืองพังงา",
    "province": "พังงา",
    "zipcode": 82000,
    "sub_district_code": 820103,
    "district_code": 8201,
    "province_code": 82
  },
  {
    "sub_district": "บางเตย",
    "district": "เมืองพังงา",
    "province": "พังงา",
    "zipcode": 82000,
    "sub_district_code": 820104,
    "district_code": 8201,
    "province_code": 82
  },
  {
    "sub_district": "ตากแดด",
    "district": "เมืองพังงา",
    "province": "พังงา",
    "zipcode": 82000,
    "sub_district_code": 820105,
    "district_code": 8201,
    "province_code": 82
  },
  {
    "sub_district": "สองแพรก",
    "district": "เมืองพังงา",
    "province": "พังงา",
    "zipcode": 82000,
    "sub_district_code": 820106,
    "district_code": 8201,
    "province_code": 82
  },
  {
    "sub_district": "ทุ่งคาโงก",
    "district": "เมืองพังงา",
    "province": "พังงา",
    "zipcode": 82000,
    "sub_district_code": 820107,
    "district_code": 8201,
    "province_code": 82
  },
  {
    "sub_district": "เกาะปันหยี",
    "district": "เมืองพังงา",
    "province": "พังงา",
    "zipcode": 82000,
    "sub_district_code": 820108,
    "district_code": 8201,
    "province_code": 82
  },
  {
    "sub_district": "ป่ากอ",
    "district": "เมืองพังงา",
    "province": "พังงา",
    "zipcode": 82000,
    "sub_district_code": 820109,
    "district_code": 8201,
    "province_code": 82
  },
  {
    "sub_district": "ตะกั่วป่า",
    "district": "เมืองพังงา",
    "province": "พังงา",
    "zipcode": 82110,
    "sub_district_code": 820198,
    "district_code": 8201,
    "province_code": 82
  },
  {
    "sub_district": "กะปง",
    "district": "กะปง",
    "province": "พังงา",
    "zipcode": 82170,
    "sub_district_code": 820301,
    "district_code": 8203,
    "province_code": 82
  },
  {
    "sub_district": "ท่านา",
    "district": "กะปง",
    "province": "พังงา",
    "zipcode": 82170,
    "sub_district_code": 820302,
    "district_code": 8203,
    "province_code": 82
  },
  {
    "sub_district": "เหมาะ",
    "district": "กะปง",
    "province": "พังงา",
    "zipcode": 82170,
    "sub_district_code": 820303,
    "district_code": 8203,
    "province_code": 82
  },
  {
    "sub_district": "เหล",
    "district": "กะปง",
    "province": "พังงา",
    "zipcode": 82170,
    "sub_district_code": 820304,
    "district_code": 8203,
    "province_code": 82
  },
  {
    "sub_district": "รมณีย์",
    "district": "กะปง",
    "province": "พังงา",
    "zipcode": 82170,
    "sub_district_code": 820305,
    "district_code": 8203,
    "province_code": 82
  },
  {
    "sub_district": "เกาะยาวน้อย",
    "district": "เกาะยาว",
    "province": "พังงา",
    "zipcode": 82160,
    "sub_district_code": 820201,
    "district_code": 8202,
    "province_code": 82
  },
  {
    "sub_district": "เกาะยาวใหญ่",
    "district": "เกาะยาว",
    "province": "พังงา",
    "zipcode": 82160,
    "sub_district_code": 820202,
    "district_code": 8202,
    "province_code": 82
  },
  {
    "sub_district": "พรุใน",
    "district": "เกาะยาว",
    "province": "พังงา",
    "zipcode": 83000,
    "sub_district_code": 820203,
    "district_code": 8202,
    "province_code": 82
  },
  {
    "sub_district": "คุระ",
    "district": "คุระบุรี",
    "province": "พังงา",
    "zipcode": 82150,
    "sub_district_code": 820601,
    "district_code": 8206,
    "province_code": 82
  },
  {
    "sub_district": "บางวัน",
    "district": "คุระบุรี",
    "province": "พังงา",
    "zipcode": 82150,
    "sub_district_code": 820602,
    "district_code": 8206,
    "province_code": 82
  },
  {
    "sub_district": "เกาะพระทอง",
    "district": "คุระบุรี",
    "province": "พังงา",
    "zipcode": 82150,
    "sub_district_code": 820603,
    "district_code": 8206,
    "province_code": 82
  },
  {
    "sub_district": "เกาะคอเขา",
    "district": "คุระบุรี",
    "province": "พังงา",
    "zipcode": 82190,
    "sub_district_code": 820698,
    "district_code": 8206,
    "province_code": 82
  },
  {
    "sub_district": "แม่นางขาว",
    "district": "คุระบุรี",
    "province": "พังงา",
    "zipcode": 82150,
    "sub_district_code": 820605,
    "district_code": 8206,
    "province_code": 82
  },
  {
    "sub_district": "ถ้ำ",
    "district": "ตะกั่วทุ่ง",
    "province": "พังงา",
    "zipcode": 82130,
    "sub_district_code": 820401,
    "district_code": 8204,
    "province_code": 82
  },
  {
    "sub_district": "กระโสม",
    "district": "ตะกั่วทุ่ง",
    "province": "พังงา",
    "zipcode": 82130,
    "sub_district_code": 820402,
    "district_code": 8204,
    "province_code": 82
  },
  {
    "sub_district": "กะไหล",
    "district": "ตะกั่วทุ่ง",
    "province": "พังงา",
    "zipcode": 82130,
    "sub_district_code": 820403,
    "district_code": 8204,
    "province_code": 82
  },
  {
    "sub_district": "ท่าอยู่",
    "district": "ตะกั่วทุ่ง",
    "province": "พังงา",
    "zipcode": 82130,
    "sub_district_code": 820404,
    "district_code": 8204,
    "province_code": 82
  },
  {
    "sub_district": "หล่อยูง",
    "district": "ตะกั่วทุ่ง",
    "province": "พังงา",
    "zipcode": 82140,
    "sub_district_code": 820405,
    "district_code": 8204,
    "province_code": 82
  },
  {
    "sub_district": "โคกกลอย",
    "district": "ตะกั่วทุ่ง",
    "province": "พังงา",
    "zipcode": 82140,
    "sub_district_code": 820406,
    "district_code": 8204,
    "province_code": 82
  },
  {
    "sub_district": "คลองเคียน",
    "district": "ตะกั่วทุ่ง",
    "province": "พังงา",
    "zipcode": 82140,
    "sub_district_code": 820407,
    "district_code": 8204,
    "province_code": 82
  },
  {
    "sub_district": "โคกกลอย",
    "district": "ตะกั่วทุ่ง",
    "province": "พังงา",
    "zipcode": 82140,
    "sub_district_code": 820406,
    "district_code": 8204,
    "province_code": 82
  },
  {
    "sub_district": "ตะกั่วป่า",
    "district": "ตะกั่วป่า",
    "province": "พังงา",
    "zipcode": 82110,
    "sub_district_code": 820501,
    "district_code": 8205,
    "province_code": 82
  },
  {
    "sub_district": "บางนายสี",
    "district": "ตะกั่วป่า",
    "province": "พังงา",
    "zipcode": 82110,
    "sub_district_code": 820502,
    "district_code": 8205,
    "province_code": 82
  },
  {
    "sub_district": "บางไทร",
    "district": "ตะกั่วป่า",
    "province": "พังงา",
    "zipcode": 82110,
    "sub_district_code": 820503,
    "district_code": 8205,
    "province_code": 82
  },
  {
    "sub_district": "บางม่วง",
    "district": "ตะกั่วป่า",
    "province": "พังงา",
    "zipcode": 82190,
    "sub_district_code": 820504,
    "district_code": 8205,
    "province_code": 82
  },
  {
    "sub_district": "ตำตัว",
    "district": "ตะกั่วป่า",
    "province": "พังงา",
    "zipcode": 82110,
    "sub_district_code": 820505,
    "district_code": 8205,
    "province_code": 82
  },
  {
    "sub_district": "โคกเคียน",
    "district": "ตะกั่วป่า",
    "province": "พังงา",
    "zipcode": 82110,
    "sub_district_code": 820506,
    "district_code": 8205,
    "province_code": 82
  },
  {
    "sub_district": "คึกคัก",
    "district": "ตะกั่วป่า",
    "province": "พังงา",
    "zipcode": 82190,
    "sub_district_code": 820507,
    "district_code": 8205,
    "province_code": 82
  },
  {
    "sub_district": "เกาะคอเขา",
    "district": "ตะกั่วป่า",
    "province": "พังงา",
    "zipcode": 82190,
    "sub_district_code": 820508,
    "district_code": 8205,
    "province_code": 82
  },
  {
    "sub_district": "ทับปุด",
    "district": "ทับปุด",
    "province": "พังงา",
    "zipcode": 82180,
    "sub_district_code": 820701,
    "district_code": 8207,
    "province_code": 82
  },
  {
    "sub_district": "มะรุ่ย",
    "district": "ทับปุด",
    "province": "พังงา",
    "zipcode": 82180,
    "sub_district_code": 820702,
    "district_code": 8207,
    "province_code": 82
  },
  {
    "sub_district": "บ่อแสน",
    "district": "ทับปุด",
    "province": "พังงา",
    "zipcode": 82180,
    "sub_district_code": 820703,
    "district_code": 8207,
    "province_code": 82
  },
  {
    "sub_district": "ถ้ำทองหลาง",
    "district": "ทับปุด",
    "province": "พังงา",
    "zipcode": 82180,
    "sub_district_code": 820704,
    "district_code": 8207,
    "province_code": 82
  },
  {
    "sub_district": "โคกเจริญ",
    "district": "ทับปุด",
    "province": "พังงา",
    "zipcode": 82180,
    "sub_district_code": 820705,
    "district_code": 8207,
    "province_code": 82
  },
  {
    "sub_district": "บางเหรียง",
    "district": "ทับปุด",
    "province": "พังงา",
    "zipcode": 82180,
    "sub_district_code": 820706,
    "district_code": 8207,
    "province_code": 82
  },
  {
    "sub_district": "ท้ายเหมือง",
    "district": "ท้ายเหมือง",
    "province": "พังงา",
    "zipcode": 82120,
    "sub_district_code": 820801,
    "district_code": 8208,
    "province_code": 82
  },
  {
    "sub_district": "นาเตย",
    "district": "ท้ายเหมือง",
    "province": "พังงา",
    "zipcode": 82120,
    "sub_district_code": 820802,
    "district_code": 8208,
    "province_code": 82
  },
  {
    "sub_district": "บางทอง",
    "district": "ท้ายเหมือง",
    "province": "พังงา",
    "zipcode": 82120,
    "sub_district_code": 820803,
    "district_code": 8208,
    "province_code": 82
  },
  {
    "sub_district": "ทุ่งมะพร้าว",
    "district": "ท้ายเหมือง",
    "province": "พังงา",
    "zipcode": 82120,
    "sub_district_code": 820804,
    "district_code": 8208,
    "province_code": 82
  },
  {
    "sub_district": "ลำภี",
    "district": "ท้ายเหมือง",
    "province": "พังงา",
    "zipcode": 82120,
    "sub_district_code": 820805,
    "district_code": 8208,
    "province_code": 82
  },
  {
    "sub_district": "ลำแก่น",
    "district": "ท้ายเหมือง",
    "province": "พังงา",
    "zipcode": 82210,
    "sub_district_code": 820806,
    "district_code": 8208,
    "province_code": 82
  },
  {
    "sub_district": "คูหาสวรรค์",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930101,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "บ้านนา",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930194,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "เขาเจียก",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930103,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "ท่ามิหรำ",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930104,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "โคกชะงาย",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930105,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "นาท่อม",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930106,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "ปรางหมู่",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930107,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "ท่าแค",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930108,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "ลำปำ",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930109,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "ตำนาน",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930110,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "มะพร้าว",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930111,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "ร่มเมือง",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930112,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "ชัยบุรี",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930113,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "นาโหนด",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930114,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "พญาขัน",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930115,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "ลำสินธุ์",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930196,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "อ่างทอง",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930197,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "ควนมะพร้าว",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930120,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "ชุมพล",
    "district": "เมืองพัทลุง",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930198,
    "district_code": 9301,
    "province_code": 93
  },
  {
    "sub_district": "เขาชัยสน",
    "district": "เขาชัยสน",
    "province": "พัทลุง",
    "zipcode": 93130,
    "sub_district_code": 930301,
    "district_code": 9303,
    "province_code": 93
  },
  {
    "sub_district": "ควนขนุน",
    "district": "เขาชัยสน",
    "province": "พัทลุง",
    "zipcode": 93130,
    "sub_district_code": 930302,
    "district_code": 9303,
    "province_code": 93
  },
  {
    "sub_district": "ท่ามะเดื่อ",
    "district": "เขาชัยสน",
    "province": "พัทลุง",
    "zipcode": 93140,
    "sub_district_code": 930396,
    "district_code": 9303,
    "province_code": 93
  },
  {
    "sub_district": "นาปะขอ",
    "district": "เขาชัยสน",
    "province": "พัทลุง",
    "zipcode": 93140,
    "sub_district_code": 930397,
    "district_code": 9303,
    "province_code": 93
  },
  {
    "sub_district": "จองถนน",
    "district": "เขาชัยสน",
    "province": "พัทลุง",
    "zipcode": 93130,
    "sub_district_code": 930305,
    "district_code": 9303,
    "province_code": 93
  },
  {
    "sub_district": "หานโพธิ์",
    "district": "เขาชัยสน",
    "province": "พัทลุง",
    "zipcode": 93130,
    "sub_district_code": 930306,
    "district_code": 9303,
    "province_code": 93
  },
  {
    "sub_district": "โคกม่วง",
    "district": "เขาชัยสน",
    "province": "พัทลุง",
    "zipcode": 93130,
    "sub_district_code": 930307,
    "district_code": 9303,
    "province_code": 93
  },
  {
    "sub_district": "โคกสัก",
    "district": "เขาชัยสน",
    "province": "พัทลุง",
    "zipcode": 93140,
    "sub_district_code": 930398,
    "district_code": 9303,
    "province_code": 93
  },
  {
    "sub_district": "ควนขนุน",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930501,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "ทะเลน้อย",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93150,
    "sub_district_code": 930502,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "เกาะเต่า",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930595,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "นาขยาด",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930504,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "พนมวังก์",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930505,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "แหลมโตนด",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930506,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "ป่าพะยอม",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930596,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "ปันแต",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930508,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "โตนดด้วน",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930509,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "ดอนทราย",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930510,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "มะกอกเหนือ",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93150,
    "sub_district_code": 930511,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "พนางตุง",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93150,
    "sub_district_code": 930512,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "ชะมวง",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930513,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "บ้านพร้าว",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930597,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "ลานข่อย",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930598,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "แพรกหา",
    "district": "ควนขนุน",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 930516,
    "district_code": 9305,
    "province_code": 93
  },
  {
    "sub_district": "ปากพะยูน",
    "district": "ปากพะยูน",
    "province": "พัทลุง",
    "zipcode": 93120,
    "sub_district_code": 930601,
    "district_code": 9306,
    "province_code": 93
  },
  {
    "sub_district": "ดอนประดู่",
    "district": "ปากพะยูน",
    "province": "พัทลุง",
    "zipcode": 93120,
    "sub_district_code": 930602,
    "district_code": 9306,
    "province_code": 93
  },
  {
    "sub_district": "เกาะนางคำ",
    "district": "ปากพะยูน",
    "province": "พัทลุง",
    "zipcode": 93120,
    "sub_district_code": 930603,
    "district_code": 9306,
    "province_code": 93
  },
  {
    "sub_district": "เกาะหมาก",
    "district": "ปากพะยูน",
    "province": "พัทลุง",
    "zipcode": 93120,
    "sub_district_code": 930604,
    "district_code": 9306,
    "province_code": 93
  },
  {
    "sub_district": "ฝาละมี",
    "district": "ปากพะยูน",
    "province": "พัทลุง",
    "zipcode": 93120,
    "sub_district_code": 930605,
    "district_code": 9306,
    "province_code": 93
  },
  {
    "sub_district": "หารเทา",
    "district": "ปากพะยูน",
    "province": "พัทลุง",
    "zipcode": 93120,
    "sub_district_code": 930606,
    "district_code": 9306,
    "province_code": 93
  },
  {
    "sub_district": "ดอนทราย",
    "district": "ปากพะยูน",
    "province": "พัทลุง",
    "zipcode": 93120,
    "sub_district_code": 930607,
    "district_code": 9306,
    "province_code": 93
  },
  {
    "sub_district": "กงหรา",
    "district": "กงหรา",
    "province": "พัทลุง",
    "zipcode": 93180,
    "sub_district_code": 930201,
    "district_code": 9302,
    "province_code": 93
  },
  {
    "sub_district": "ชะรัด",
    "district": "กงหรา",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930202,
    "district_code": 9302,
    "province_code": 93
  },
  {
    "sub_district": "คลองเฉลิม",
    "district": "กงหรา",
    "province": "พัทลุง",
    "zipcode": 93180,
    "sub_district_code": 930203,
    "district_code": 9302,
    "province_code": 93
  },
  {
    "sub_district": "คลองทรายขาว",
    "district": "กงหรา",
    "province": "พัทลุง",
    "zipcode": 93180,
    "sub_district_code": 930204,
    "district_code": 9302,
    "province_code": 93
  },
  {
    "sub_district": "สมหวัง",
    "district": "กงหรา",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 930205,
    "district_code": 9302,
    "province_code": 93
  },
  {
    "sub_district": "แม่ขรี",
    "district": "ตะโหมด",
    "province": "พัทลุง",
    "zipcode": 93160,
    "sub_district_code": 930401,
    "district_code": 9304,
    "province_code": 93
  },
  {
    "sub_district": "ตะโหมด",
    "district": "ตะโหมด",
    "province": "พัทลุง",
    "zipcode": 93160,
    "sub_district_code": 930402,
    "district_code": 9304,
    "province_code": 93
  },
  {
    "sub_district": "คลองใหญ่",
    "district": "ตะโหมด",
    "province": "พัทลุง",
    "zipcode": 93160,
    "sub_district_code": 930403,
    "district_code": 9304,
    "province_code": 93
  },
  {
    "sub_district": "ป่าบอน",
    "district": "ป่าบอน",
    "province": "พัทลุง",
    "zipcode": 93170,
    "sub_district_code": 930801,
    "district_code": 9308,
    "province_code": 93
  },
  {
    "sub_district": "โคกทราย",
    "district": "ป่าบอน",
    "province": "พัทลุง",
    "zipcode": 93170,
    "sub_district_code": 930802,
    "district_code": 9308,
    "province_code": 93
  },
  {
    "sub_district": "หนองธง",
    "district": "ป่าบอน",
    "province": "พัทลุง",
    "zipcode": 93170,
    "sub_district_code": 930803,
    "district_code": 9308,
    "province_code": 93
  },
  {
    "sub_district": "ทุ่งนารี",
    "district": "ป่าบอน",
    "province": "พัทลุง",
    "zipcode": 93170,
    "sub_district_code": 930804,
    "district_code": 9308,
    "province_code": 93
  },
  {
    "sub_district": "วังใหม่",
    "district": "ป่าบอน",
    "province": "พัทลุง",
    "zipcode": 93170,
    "sub_district_code": 930806,
    "district_code": 9308,
    "province_code": 93
  },
  {
    "sub_district": "ท่ามะเดื่อ",
    "district": "บางแก้ว",
    "province": "พัทลุง",
    "zipcode": 93140,
    "sub_district_code": 930901,
    "district_code": 9309,
    "province_code": 93
  },
  {
    "sub_district": "โคกสัก",
    "district": "บางแก้ว",
    "province": "พัทลุง",
    "zipcode": 93140,
    "sub_district_code": 930903,
    "district_code": 9309,
    "province_code": 93
  },
  {
    "sub_district": "นาปะขอ",
    "district": "บางแก้ว",
    "province": "พัทลุง",
    "zipcode": 93140,
    "sub_district_code": 930902,
    "district_code": 9309,
    "province_code": 93
  },
  {
    "sub_district": "เกาะเต่า",
    "district": "ป่าพะยอม",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 931003,
    "district_code": 9310,
    "province_code": 93
  },
  {
    "sub_district": "บ้านพร้าว",
    "district": "ป่าพะยอม",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 931004,
    "district_code": 9310,
    "province_code": 93
  },
  {
    "sub_district": "ป่าพะยอม",
    "district": "ป่าพะยอม",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 931001,
    "district_code": 9310,
    "province_code": 93
  },
  {
    "sub_district": "ลานข่อย",
    "district": "ป่าพะยอม",
    "province": "พัทลุง",
    "zipcode": 93110,
    "sub_district_code": 931002,
    "district_code": 9310,
    "province_code": 93
  },
  {
    "sub_district": "เขาย่า",
    "district": "ศรีบรรพต",
    "province": "พัทลุง",
    "zipcode": 93190,
    "sub_district_code": 930701,
    "district_code": 9307,
    "province_code": 93
  },
  {
    "sub_district": "เขาปู่",
    "district": "ศรีบรรพต",
    "province": "พัทลุง",
    "zipcode": 93190,
    "sub_district_code": 930702,
    "district_code": 9307,
    "province_code": 93
  },
  {
    "sub_district": "ตะแพน",
    "district": "ศรีบรรพต",
    "province": "พัทลุง",
    "zipcode": 93190,
    "sub_district_code": 930703,
    "district_code": 9307,
    "province_code": 93
  },
  {
    "sub_district": "ชุมพล",
    "district": "ศรีนครินทร์",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 931101,
    "district_code": 9311,
    "province_code": 93
  },
  {
    "sub_district": "บ้านนา",
    "district": "ศรีนครินทร์",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 931102,
    "district_code": 9311,
    "province_code": 93
  },
  {
    "sub_district": "อ่างทอง",
    "district": "ศรีนครินทร์",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 931103,
    "district_code": 9311,
    "province_code": 93
  },
  {
    "sub_district": "ลำสินธุ์",
    "district": "ศรีนครินทร์",
    "province": "พัทลุง",
    "zipcode": 93000,
    "sub_district_code": 931104,
    "district_code": 9311,
    "province_code": 93
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660101,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ไผ่ขวาง",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660102,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ย่านยาว",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660103,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ท่าฬ่อ",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660104,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ปากทาง",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660105,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "คลองคะเชนทร์",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660106,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "โรงช้าง",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660107,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "เมืองเก่า",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660108,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ท่าหลวง",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660109,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "บ้านบุ่ง",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660110,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ฆะมัง",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660111,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ดงป่าคำ",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66170,
    "sub_district_code": 660112,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "หัวดง",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66170,
    "sub_district_code": 660113,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ป่ามะคาบ",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660115,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "สากเหล็ก",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66160,
    "sub_district_code": 660194,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ท่าเยี่ยม",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66160,
    "sub_district_code": 660195,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "บางมูลนาก",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66120,
    "sub_district_code": 660196,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "คลองทราย",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66160,
    "sub_district_code": 660197,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "สายคำโห้",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66000,
    "sub_district_code": 660119,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ดงกลาง",
    "district": "เมืองพิจิตร",
    "province": "พิจิตร",
    "zipcode": 66170,
    "sub_district_code": 660120,
    "district_code": 6601,
    "province_code": 66
  },
  {
    "sub_district": "ตะพานหิน",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660401,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "ทับคล้อ",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66150,
    "sub_district_code": 660494,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "งิ้วราย",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660402,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "ห้วยเกตุ",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660403,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "ไทรโรงโขน",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660404,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "หนองพยอม",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660405,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "ทุ่งโพธิ์",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66150,
    "sub_district_code": 660406,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "ดงตะขบ",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660407,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "ท้ายทุ่ง",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66150,
    "sub_district_code": 660495,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "คลองคูณ",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660408,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "วังสำโรง",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660409,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "วังหว้า",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660410,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "เขาทราย",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66230,
    "sub_district_code": 660496,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "เขาเจ็ดลูก",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66230,
    "sub_district_code": 660497,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "วังหลุม",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66150,
    "sub_district_code": 660411,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "ทับหมัน",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660412,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "ไผ่หลวง",
    "district": "ตะพานหิน",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660413,
    "district_code": 6604,
    "province_code": 66
  },
  {
    "sub_district": "บางมูลนาก",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66120,
    "sub_district_code": 660501,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "บางไผ่",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66120,
    "sub_district_code": 660502,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "หอไกร",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66120,
    "sub_district_code": 660503,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "เนินมะกอก",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66120,
    "sub_district_code": 660504,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "วังสำโรง",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66120,
    "sub_district_code": 660505,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "ภูมิ",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66120,
    "sub_district_code": 660506,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "วังกรด",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66120,
    "sub_district_code": 660507,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "ห้วยเขน",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66120,
    "sub_district_code": 660508,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "วังตะกู",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66210,
    "sub_district_code": 660509,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "สำนักขุนเณร",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66210,
    "sub_district_code": 660594,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "ห้วยพุก",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66210,
    "sub_district_code": 660595,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "ห้วยร่วม",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66210,
    "sub_district_code": 660596,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "วังงิ้ว",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66210,
    "sub_district_code": 660597,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "ลำประดา",
    "district": "บางมูลนาก",
    "province": "พิจิตร",
    "zipcode": 66120,
    "sub_district_code": 660514,
    "district_code": 6605,
    "province_code": 66
  },
  {
    "sub_district": "โพทะเล",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660601,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "ท้ายน้ำ",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660602,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "ทะนง",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660603,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "ท่าบัว",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660604,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "ทุ่งน้อย",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660605,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "ท่าขมิ้น",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660606,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "ท่าเสา",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660607,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "บางคลาน",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660608,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "บางลาย",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660693,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "บึงนาราง",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660694,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "ท่านั่ง",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660611,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "บ้านน้อย",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660612,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "วัดขวาง",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660613,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "โพธิ์ไทรงาม",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660695,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "วังสำโรง",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66110,
    "sub_district_code": 660696,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "แหลมรัง",
    "district": "โพทะเล",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 660697,
    "district_code": 6606,
    "province_code": 66
  },
  {
    "sub_district": "โพธิ์ประทับช้าง",
    "district": "โพธิ์ประทับช้าง",
    "province": "พิจิตร",
    "zipcode": 66190,
    "sub_district_code": 660301,
    "district_code": 6603,
    "province_code": 66
  },
  {
    "sub_district": "ไผ่ท่าโพ",
    "district": "โพธิ์ประทับช้าง",
    "province": "พิจิตร",
    "zipcode": 66190,
    "sub_district_code": 660302,
    "district_code": 6603,
    "province_code": 66
  },
  {
    "sub_district": "วังจิก",
    "district": "โพธิ์ประทับช้าง",
    "province": "พิจิตร",
    "zipcode": 66190,
    "sub_district_code": 660303,
    "district_code": 6603,
    "province_code": 66
  },
  {
    "sub_district": "ไผ่รอบ",
    "district": "โพธิ์ประทับช้าง",
    "province": "พิจิตร",
    "zipcode": 66190,
    "sub_district_code": 660304,
    "district_code": 6603,
    "province_code": 66
  },
  {
    "sub_district": "ดงเสือเหลือง",
    "district": "โพธิ์ประทับช้าง",
    "province": "พิจิตร",
    "zipcode": 66190,
    "sub_district_code": 660305,
    "district_code": 6603,
    "province_code": 66
  },
  {
    "sub_district": "เนินสว่าง",
    "district": "โพธิ์ประทับช้าง",
    "province": "พิจิตร",
    "zipcode": 66190,
    "sub_district_code": 660306,
    "district_code": 6603,
    "province_code": 66
  },
  {
    "sub_district": "ทุ่งใหญ่",
    "district": "โพธิ์ประทับช้าง",
    "province": "พิจิตร",
    "zipcode": 66190,
    "sub_district_code": 660307,
    "district_code": 6603,
    "province_code": 66
  },
  {
    "sub_district": "สามง่าม",
    "district": "สามง่าม",
    "province": "พิจิตร",
    "zipcode": 66140,
    "sub_district_code": 660701,
    "district_code": 6607,
    "province_code": 66
  },
  {
    "sub_district": "กำแพงดิน",
    "district": "สามง่าม",
    "province": "พิจิตร",
    "zipcode": 66220,
    "sub_district_code": 660702,
    "district_code": 6607,
    "province_code": 66
  },
  {
    "sub_district": "รังนก",
    "district": "สามง่าม",
    "province": "พิจิตร",
    "zipcode": 66140,
    "sub_district_code": 660703,
    "district_code": 6607,
    "province_code": 66
  },
  {
    "sub_district": "หนองหลุม",
    "district": "สามง่าม",
    "province": "พิจิตร",
    "zipcode": 66220,
    "sub_district_code": 660795,
    "district_code": 6607,
    "province_code": 66
  },
  {
    "sub_district": "บ้านนา",
    "district": "สามง่าม",
    "province": "พิจิตร",
    "zipcode": 66140,
    "sub_district_code": 660796,
    "district_code": 6607,
    "province_code": 66
  },
  {
    "sub_district": "เนินปอ",
    "district": "สามง่าม",
    "province": "พิจิตร",
    "zipcode": 66140,
    "sub_district_code": 660706,
    "district_code": 6607,
    "province_code": 66
  },
  {
    "sub_district": "หนองโสน",
    "district": "สามง่าม",
    "province": "พิจิตร",
    "zipcode": 66140,
    "sub_district_code": 660707,
    "district_code": 6607,
    "province_code": 66
  },
  {
    "sub_district": "วังโมกข์",
    "district": "สามง่าม",
    "province": "พิจิตร",
    "zipcode": 66140,
    "sub_district_code": 660797,
    "district_code": 6607,
    "province_code": 66
  },
  {
    "sub_district": "บึงบัว",
    "district": "สามง่าม",
    "province": "พิจิตร",
    "zipcode": 66140,
    "sub_district_code": 660798,
    "district_code": 6607,
    "province_code": 66
  },
  {
    "sub_district": "วังทรายพูน",
    "district": "วังทรายพูน",
    "province": "พิจิตร",
    "zipcode": 66180,
    "sub_district_code": 660201,
    "district_code": 6602,
    "province_code": 66
  },
  {
    "sub_district": "หนองพระ",
    "district": "วังทรายพูน",
    "province": "พิจิตร",
    "zipcode": 66180,
    "sub_district_code": 660203,
    "district_code": 6602,
    "province_code": 66
  },
  {
    "sub_district": "หนองปลาไหล",
    "district": "วังทรายพูน",
    "province": "พิจิตร",
    "zipcode": 66180,
    "sub_district_code": 660202,
    "district_code": 6602,
    "province_code": 66
  },
  {
    "sub_district": "หนองปล้อง",
    "district": "วังทรายพูน",
    "province": "พิจิตร",
    "zipcode": 66180,
    "sub_district_code": 660204,
    "district_code": 6602,
    "province_code": 66
  },
  {
    "sub_district": "ทับคล้อ",
    "district": "ทับคล้อ",
    "province": "พิจิตร",
    "zipcode": 66150,
    "sub_district_code": 660801,
    "district_code": 6608,
    "province_code": 66
  },
  {
    "sub_district": "เขาทราย",
    "district": "ทับคล้อ",
    "province": "พิจิตร",
    "zipcode": 66230,
    "sub_district_code": 660802,
    "district_code": 6608,
    "province_code": 66
  },
  {
    "sub_district": "เขาเจ็ดลูก",
    "district": "ทับคล้อ",
    "province": "พิจิตร",
    "zipcode": 66230,
    "sub_district_code": 660803,
    "district_code": 6608,
    "province_code": 66
  },
  {
    "sub_district": "ท้ายทุ่ง",
    "district": "ทับคล้อ",
    "province": "พิจิตร",
    "zipcode": 66150,
    "sub_district_code": 660804,
    "district_code": 6608,
    "province_code": 66
  },
  {
    "sub_district": "สากเหล็ก",
    "district": "สากเหล็ก",
    "province": "พิจิตร",
    "zipcode": 66160,
    "sub_district_code": 660901,
    "district_code": 6609,
    "province_code": 66
  },
  {
    "sub_district": "คลองทราย",
    "district": "สากเหล็ก",
    "province": "พิจิตร",
    "zipcode": 66160,
    "sub_district_code": 660903,
    "district_code": 6609,
    "province_code": 66
  },
  {
    "sub_district": "หนองหญ้าไทร",
    "district": "สากเหล็ก",
    "province": "พิจิตร",
    "zipcode": 66160,
    "sub_district_code": 660904,
    "district_code": 6609,
    "province_code": 66
  },
  {
    "sub_district": "ท่าเยี่ยม",
    "district": "สากเหล็ก",
    "province": "พิจิตร",
    "zipcode": 66160,
    "sub_district_code": 660902,
    "district_code": 6609,
    "province_code": 66
  },
  {
    "sub_district": "วังทับไทร",
    "district": "สากเหล็ก",
    "province": "พิจิตร",
    "zipcode": 66160,
    "sub_district_code": 660905,
    "district_code": 6609,
    "province_code": 66
  },
  {
    "sub_district": "วังงิ้วใต้",
    "district": "ดงเจริญ",
    "province": "พิจิตร",
    "zipcode": 66210,
    "sub_district_code": 661101,
    "district_code": 6611,
    "province_code": 66
  },
  {
    "sub_district": "สำนักขุนเณร",
    "district": "ดงเจริญ",
    "province": "พิจิตร",
    "zipcode": 66210,
    "sub_district_code": 661105,
    "district_code": 6611,
    "province_code": 66
  },
  {
    "sub_district": "ห้วยพุก",
    "district": "ดงเจริญ",
    "province": "พิจิตร",
    "zipcode": 66210,
    "sub_district_code": 661104,
    "district_code": 6611,
    "province_code": 66
  },
  {
    "sub_district": "วังงิ้ว",
    "district": "ดงเจริญ",
    "province": "พิจิตร",
    "zipcode": 66210,
    "sub_district_code": 661102,
    "district_code": 6611,
    "province_code": 66
  },
  {
    "sub_district": "ห้วยร่วม",
    "district": "ดงเจริญ",
    "province": "พิจิตร",
    "zipcode": 66210,
    "sub_district_code": 661103,
    "district_code": 6611,
    "province_code": 66
  },
  {
    "sub_district": "โพธิ์ไทรงาม",
    "district": "บึงนาราง",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 661002,
    "district_code": 6610,
    "province_code": 66
  },
  {
    "sub_district": "ห้วยแก้ว",
    "district": "บึงนาราง",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 661001,
    "district_code": 6610,
    "province_code": 66
  },
  {
    "sub_district": "แหลมรัง",
    "district": "บึงนาราง",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 661003,
    "district_code": 6610,
    "province_code": 66
  },
  {
    "sub_district": "บางลาย",
    "district": "บึงนาราง",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 661004,
    "district_code": 6610,
    "province_code": 66
  },
  {
    "sub_district": "บึงนาราง",
    "district": "บึงนาราง",
    "province": "พิจิตร",
    "zipcode": 66130,
    "sub_district_code": 661005,
    "district_code": 6610,
    "province_code": 66
  },
  {
    "sub_district": "บ้านนา",
    "district": "วชิรบารมี",
    "province": "พิจิตร",
    "zipcode": 66140,
    "sub_district_code": 661201,
    "district_code": 6612,
    "province_code": 66
  },
  {
    "sub_district": "บึงบัว",
    "district": "วชิรบารมี",
    "province": "พิจิตร",
    "zipcode": 66140,
    "sub_district_code": 661202,
    "district_code": 6612,
    "province_code": 66
  },
  {
    "sub_district": "วังโมกข์",
    "district": "วชิรบารมี",
    "province": "พิจิตร",
    "zipcode": 66140,
    "sub_district_code": 661203,
    "district_code": 6612,
    "province_code": 66
  },
  {
    "sub_district": "หนองหลุม",
    "district": "วชิรบารมี",
    "province": "พิจิตร",
    "zipcode": 66220,
    "sub_district_code": 661204,
    "district_code": 6612,
    "province_code": 66
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650101,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "วังน้ำคู้",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65230,
    "sub_district_code": 650102,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "วัดจันทร์",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650103,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "วัดพริก",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65230,
    "sub_district_code": 650104,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "ท่าทอง",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650105,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "ท่าโพธิ์",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650106,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "สมอแข",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650107,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "ดอนทอง",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650108,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "บ้านป่า",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650109,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "ปากโทก",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650110,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "หัวรอ",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650111,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "จอมทอง",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650112,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "บ้านกร่าง",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650113,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "บ้านคลอง",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650114,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "พลายชุมพล",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650115,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "มะขามสูง",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650116,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "อรัญญิก",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650117,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "บึงพระ",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650118,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "ไผ่ขอดอน",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65000,
    "sub_district_code": 650119,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "งิ้วงาม",
    "district": "เมืองพิษณุโลก",
    "province": "พิษณุโลก",
    "zipcode": 65230,
    "sub_district_code": 650120,
    "district_code": 6501,
    "province_code": 65
  },
  {
    "sub_district": "ป่าแดง",
    "district": "ชาติตระการ",
    "province": "พิษณุโลก",
    "zipcode": 65170,
    "sub_district_code": 650301,
    "district_code": 6503,
    "province_code": 65
  },
  {
    "sub_district": "ชาติตระการ",
    "district": "ชาติตระการ",
    "province": "พิษณุโลก",
    "zipcode": 65170,
    "sub_district_code": 650302,
    "district_code": 6503,
    "province_code": 65
  },
  {
    "sub_district": "สวนเมี่ยง",
    "district": "ชาติตระการ",
    "province": "พิษณุโลก",
    "zipcode": 65170,
    "sub_district_code": 650303,
    "district_code": 6503,
    "province_code": 65
  },
  {
    "sub_district": "บ้านดง",
    "district": "ชาติตระการ",
    "province": "พิษณุโลก",
    "zipcode": 65170,
    "sub_district_code": 650304,
    "district_code": 6503,
    "province_code": 65
  },
  {
    "sub_district": "บ่อภาค",
    "district": "ชาติตระการ",
    "province": "พิษณุโลก",
    "zipcode": 65170,
    "sub_district_code": 650305,
    "district_code": 6503,
    "province_code": 65
  },
  {
    "sub_district": "ท่าสะแก",
    "district": "ชาติตระการ",
    "province": "พิษณุโลก",
    "zipcode": 65170,
    "sub_district_code": 650306,
    "district_code": 6503,
    "province_code": 65
  },
  {
    "sub_district": "นครไทย",
    "district": "นครไทย",
    "province": "พิษณุโลก",
    "zipcode": 65120,
    "sub_district_code": 650201,
    "district_code": 6502,
    "province_code": 65
  },
  {
    "sub_district": "หนองกะท้าว",
    "district": "นครไทย",
    "province": "พิษณุโลก",
    "zipcode": 65120,
    "sub_district_code": 650202,
    "district_code": 6502,
    "province_code": 65
  },
  {
    "sub_district": "บ้านแยง",
    "district": "นครไทย",
    "province": "พิษณุโลก",
    "zipcode": 65120,
    "sub_district_code": 650203,
    "district_code": 6502,
    "province_code": 65
  },
  {
    "sub_district": "เนินเพิ่ม",
    "district": "นครไทย",
    "province": "พิษณุโลก",
    "zipcode": 65120,
    "sub_district_code": 650204,
    "district_code": 6502,
    "province_code": 65
  },
  {
    "sub_district": "นาบัว",
    "district": "นครไทย",
    "province": "พิษณุโลก",
    "zipcode": 65120,
    "sub_district_code": 650205,
    "district_code": 6502,
    "province_code": 65
  },
  {
    "sub_district": "บ่อโพธิ์",
    "district": "นครไทย",
    "province": "พิษณุโลก",
    "zipcode": 65120,
    "sub_district_code": 650209,
    "district_code": 6502,
    "province_code": 65
  },
  {
    "sub_district": "นครชุม",
    "district": "นครไทย",
    "province": "พิษณุโลก",
    "zipcode": 65120,
    "sub_district_code": 650206,
    "district_code": 6502,
    "province_code": 65
  },
  {
    "sub_district": "น้ำกุ่ม",
    "district": "นครไทย",
    "province": "พิษณุโลก",
    "zipcode": 65120,
    "sub_district_code": 650207,
    "district_code": 6502,
    "province_code": 65
  },
  {
    "sub_district": "บ้านพร้าว",
    "district": "นครไทย",
    "province": "พิษณุโลก",
    "zipcode": 65120,
    "sub_district_code": 650210,
    "district_code": 6502,
    "province_code": 65
  },
  {
    "sub_district": "ห้วยเฮี้ย",
    "district": "นครไทย",
    "province": "พิษณุโลก",
    "zipcode": 65120,
    "sub_district_code": 650211,
    "district_code": 6502,
    "province_code": 65
  },
  {
    "sub_district": "บางกระทุ่ม",
    "district": "บางกระทุ่ม",
    "province": "พิษณุโลก",
    "zipcode": 65110,
    "sub_district_code": 650501,
    "district_code": 6505,
    "province_code": 65
  },
  {
    "sub_district": "บ้านไร่",
    "district": "บางกระทุ่ม",
    "province": "พิษณุโลก",
    "zipcode": 65110,
    "sub_district_code": 650502,
    "district_code": 6505,
    "province_code": 65
  },
  {
    "sub_district": "โคกสลุด",
    "district": "บางกระทุ่ม",
    "province": "พิษณุโลก",
    "zipcode": 65110,
    "sub_district_code": 650503,
    "district_code": 6505,
    "province_code": 65
  },
  {
    "sub_district": "สนามคลี",
    "district": "บางกระทุ่ม",
    "province": "พิษณุโลก",
    "zipcode": 65110,
    "sub_district_code": 650504,
    "district_code": 6505,
    "province_code": 65
  },
  {
    "sub_district": "ท่าตาล",
    "district": "บางกระทุ่ม",
    "province": "พิษณุโลก",
    "zipcode": 65110,
    "sub_district_code": 650505,
    "district_code": 6505,
    "province_code": 65
  },
  {
    "sub_district": "ไผ่ล้อม",
    "district": "บางกระทุ่ม",
    "province": "พิษณุโลก",
    "zipcode": 65110,
    "sub_district_code": 650506,
    "district_code": 6505,
    "province_code": 65
  },
  {
    "sub_district": "นครป่าหมาก",
    "district": "บางกระทุ่ม",
    "province": "พิษณุโลก",
    "zipcode": 65110,
    "sub_district_code": 650507,
    "district_code": 6505,
    "province_code": 65
  },
  {
    "sub_district": "เนินกุ่ม",
    "district": "บางกระทุ่ม",
    "province": "พิษณุโลก",
    "zipcode": 65210,
    "sub_district_code": 650508,
    "district_code": 6505,
    "province_code": 65
  },
  {
    "sub_district": "วัดตายม",
    "district": "บางกระทุ่ม",
    "province": "พิษณุโลก",
    "zipcode": 65210,
    "sub_district_code": 650509,
    "district_code": 6505,
    "province_code": 65
  },
  {
    "sub_district": "บางระกำ",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65140,
    "sub_district_code": 650401,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "ปลักแรด",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65140,
    "sub_district_code": 650402,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "พันเสา",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65140,
    "sub_district_code": 650403,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "วังอิทก",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65140,
    "sub_district_code": 650404,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "บึงกอก",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65140,
    "sub_district_code": 650405,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "หนองกุลา",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65140,
    "sub_district_code": 650406,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "ชุมแสงสงคราม",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65240,
    "sub_district_code": 650407,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "นิคมพัฒนา",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65140,
    "sub_district_code": 650408,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "บ่อทอง",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65140,
    "sub_district_code": 650409,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "ท่านางงาม",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65140,
    "sub_district_code": 650410,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "คุยม่วง",
    "district": "บางระกำ",
    "province": "พิษณุโลก",
    "zipcode": 65240,
    "sub_district_code": 650411,
    "district_code": 6504,
    "province_code": 65
  },
  {
    "sub_district": "พรหมพิราม",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65150,
    "sub_district_code": 650601,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65150,
    "sub_district_code": 650602,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "วงฆ้อง",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65180,
    "sub_district_code": 650603,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "มะตูม",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65150,
    "sub_district_code": 650604,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "หอกลอง",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65150,
    "sub_district_code": 650605,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "ศรีภิรมย์",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65180,
    "sub_district_code": 650606,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "ตลุกเทียม",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65180,
    "sub_district_code": 650607,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "วังวน",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65150,
    "sub_district_code": 650608,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "หนองแขม",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65150,
    "sub_district_code": 650609,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "มะต้อง",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65180,
    "sub_district_code": 650610,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "ทับยายเชียง",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65150,
    "sub_district_code": 650611,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "ดงประคำ",
    "district": "พรหมพิราม",
    "province": "พิษณุโลก",
    "zipcode": 65180,
    "sub_district_code": 650612,
    "district_code": 6506,
    "province_code": 65
  },
  {
    "sub_district": "วังทอง",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65130,
    "sub_district_code": 650801,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "พันชาลี",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65130,
    "sub_district_code": 650802,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "แม่ระกา",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65130,
    "sub_district_code": 650803,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "บ้านกลาง",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65220,
    "sub_district_code": 650804,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "วังพิกุล",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65130,
    "sub_district_code": 650805,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "แก่งโสภา",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65220,
    "sub_district_code": 650806,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "ท่าหมื่นราม",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65130,
    "sub_district_code": 650807,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "วังนกแอ่น",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65130,
    "sub_district_code": 650808,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "หนองพระ",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65130,
    "sub_district_code": 650809,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "ชัยนาม",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65130,
    "sub_district_code": 650810,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "ดินทอง",
    "district": "วังทอง",
    "province": "พิษณุโลก",
    "zipcode": 65130,
    "sub_district_code": 650811,
    "district_code": 6508,
    "province_code": 65
  },
  {
    "sub_district": "วัดโบสถ์",
    "district": "วัดโบสถ์",
    "province": "พิษณุโลก",
    "zipcode": 65160,
    "sub_district_code": 650701,
    "district_code": 6507,
    "province_code": 65
  },
  {
    "sub_district": "ท่างาม",
    "district": "วัดโบสถ์",
    "province": "พิษณุโลก",
    "zipcode": 65160,
    "sub_district_code": 650702,
    "district_code": 6507,
    "province_code": 65
  },
  {
    "sub_district": "ท้อแท้",
    "district": "วัดโบสถ์",
    "province": "พิษณุโลก",
    "zipcode": 65160,
    "sub_district_code": 650703,
    "district_code": 6507,
    "province_code": 65
  },
  {
    "sub_district": "บ้านยาง",
    "district": "วัดโบสถ์",
    "province": "พิษณุโลก",
    "zipcode": 65160,
    "sub_district_code": 650704,
    "district_code": 6507,
    "province_code": 65
  },
  {
    "sub_district": "หินลาด",
    "district": "วัดโบสถ์",
    "province": "พิษณุโลก",
    "zipcode": 65160,
    "sub_district_code": 650705,
    "district_code": 6507,
    "province_code": 65
  },
  {
    "sub_district": "คันโช้ง",
    "district": "วัดโบสถ์",
    "province": "พิษณุโลก",
    "zipcode": 65160,
    "sub_district_code": 650706,
    "district_code": 6507,
    "province_code": 65
  },
  {
    "sub_district": "ชมพู",
    "district": "เนินมะปราง",
    "province": "พิษณุโลก",
    "zipcode": 65190,
    "sub_district_code": 650901,
    "district_code": 6509,
    "province_code": 65
  },
  {
    "sub_district": "บ้านมุง",
    "district": "เนินมะปราง",
    "province": "พิษณุโลก",
    "zipcode": 65190,
    "sub_district_code": 650902,
    "district_code": 6509,
    "province_code": 65
  },
  {
    "sub_district": "ไทรย้อย",
    "district": "เนินมะปราง",
    "province": "พิษณุโลก",
    "zipcode": 65190,
    "sub_district_code": 650903,
    "district_code": 6509,
    "province_code": 65
  },
  {
    "sub_district": "วังโพรง",
    "district": "เนินมะปราง",
    "province": "พิษณุโลก",
    "zipcode": 65190,
    "sub_district_code": 650904,
    "district_code": 6509,
    "province_code": 65
  },
  {
    "sub_district": "บ้านน้อยซุ้มขี้เหล็ก",
    "district": "เนินมะปราง",
    "province": "พิษณุโลก",
    "zipcode": 65190,
    "sub_district_code": 650905,
    "district_code": 6509,
    "province_code": 65
  },
  {
    "sub_district": "เนินมะปราง",
    "district": "เนินมะปราง",
    "province": "พิษณุโลก",
    "zipcode": 65190,
    "sub_district_code": 650906,
    "district_code": 6509,
    "province_code": 65
  },
  {
    "sub_district": "วังยาง",
    "district": "เนินมะปราง",
    "province": "พิษณุโลก",
    "zipcode": 65190,
    "sub_district_code": 650907,
    "district_code": 6509,
    "province_code": 65
  },
  {
    "sub_district": "ท่าราบ",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760101,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "คลองกระแชง",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760102,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "บางจาน",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760103,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "นาพันสาม",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760104,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "ธงชัย",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760105,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "บ้านกุ่ม",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760106,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "หนองโสน",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760107,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "ไร่ส้ม",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760108,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "เวียงคอย",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760109,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "บางจาก",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760110,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "บ้านหม้อ",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760111,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "ต้นมะม่วง",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760112,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "ช่องสะแก",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760113,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "นาวุ้ง",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760114,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "สำมะโรง",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760115,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "โพพระ",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760116,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "หัวสะพาน",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760118,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "วังตะโก",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760120,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "หาดเจ้าสำราญ",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76100,
    "sub_district_code": 760117,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "ต้นมะพร้าว",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760119,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "โพไร่หวาน",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760121,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "ดอนยาง",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760122,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "หนองขนาน",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760123,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "หนองพลับ",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76000,
    "sub_district_code": 760124,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "สมอพลือ",
    "district": "เมืองเพชรบุรี",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760198,
    "district_code": 7601,
    "province_code": 76
  },
  {
    "sub_district": "เขาย้อย",
    "district": "เขาย้อย",
    "province": "เพชรบุรี",
    "zipcode": 76140,
    "sub_district_code": 760201,
    "district_code": 7602,
    "province_code": 76
  },
  {
    "sub_district": "สระพัง",
    "district": "เขาย้อย",
    "province": "เพชรบุรี",
    "zipcode": 76140,
    "sub_district_code": 760202,
    "district_code": 7602,
    "province_code": 76
  },
  {
    "sub_district": "บางเค็ม",
    "district": "เขาย้อย",
    "province": "เพชรบุรี",
    "zipcode": 76140,
    "sub_district_code": 760203,
    "district_code": 7602,
    "province_code": 76
  },
  {
    "sub_district": "ทับคาง",
    "district": "เขาย้อย",
    "province": "เพชรบุรี",
    "zipcode": 76140,
    "sub_district_code": 760204,
    "district_code": 7602,
    "province_code": 76
  },
  {
    "sub_district": "หนองปลาไหล",
    "district": "เขาย้อย",
    "province": "เพชรบุรี",
    "zipcode": 76140,
    "sub_district_code": 760205,
    "district_code": 7602,
    "province_code": 76
  },
  {
    "sub_district": "หนองปรง",
    "district": "เขาย้อย",
    "province": "เพชรบุรี",
    "zipcode": 76140,
    "sub_district_code": 760206,
    "district_code": 7602,
    "province_code": 76
  },
  {
    "sub_district": "หนองชุมพล",
    "district": "เขาย้อย",
    "province": "เพชรบุรี",
    "zipcode": 76140,
    "sub_district_code": 760207,
    "district_code": 7602,
    "province_code": 76
  },
  {
    "sub_district": "ห้วยโรง",
    "district": "เขาย้อย",
    "province": "เพชรบุรี",
    "zipcode": 76140,
    "sub_district_code": 760208,
    "district_code": 7602,
    "province_code": 76
  },
  {
    "sub_district": "ห้วยท่าช้าง",
    "district": "เขาย้อย",
    "province": "เพชรบุรี",
    "zipcode": 76140,
    "sub_district_code": 760209,
    "district_code": 7602,
    "province_code": 76
  },
  {
    "sub_district": "หนองชุมพลเหนือ",
    "district": "เขาย้อย",
    "province": "เพชรบุรี",
    "zipcode": 76140,
    "sub_district_code": 760210,
    "district_code": 7602,
    "province_code": 76
  },
  {
    "sub_district": "ชะอำ",
    "district": "ชะอำ",
    "province": "เพชรบุรี",
    "zipcode": 76120,
    "sub_district_code": 760401,
    "district_code": 7604,
    "province_code": 76
  },
  {
    "sub_district": "บางเก่า",
    "district": "ชะอำ",
    "province": "เพชรบุรี",
    "zipcode": 76120,
    "sub_district_code": 760402,
    "district_code": 7604,
    "province_code": 76
  },
  {
    "sub_district": "นายาง",
    "district": "ชะอำ",
    "province": "เพชรบุรี",
    "zipcode": 76120,
    "sub_district_code": 760403,
    "district_code": 7604,
    "province_code": 76
  },
  {
    "sub_district": "เขาใหญ่",
    "district": "ชะอำ",
    "province": "เพชรบุรี",
    "zipcode": 76120,
    "sub_district_code": 760404,
    "district_code": 7604,
    "province_code": 76
  },
  {
    "sub_district": "หนองศาลา",
    "district": "ชะอำ",
    "province": "เพชรบุรี",
    "zipcode": 76120,
    "sub_district_code": 760405,
    "district_code": 7604,
    "province_code": 76
  },
  {
    "sub_district": "ห้วยทรายเหนือ",
    "district": "ชะอำ",
    "province": "เพชรบุรี",
    "zipcode": 76120,
    "sub_district_code": 760406,
    "district_code": 7604,
    "province_code": 76
  },
  {
    "sub_district": "ไร่ใหม่พัฒนา",
    "district": "ชะอำ",
    "province": "เพชรบุรี",
    "zipcode": 76120,
    "sub_district_code": 760407,
    "district_code": 7604,
    "province_code": 76
  },
  {
    "sub_district": "สามพระยา",
    "district": "ชะอำ",
    "province": "เพชรบุรี",
    "zipcode": 76120,
    "sub_district_code": 760408,
    "district_code": 7604,
    "province_code": 76
  },
  {
    "sub_district": "ดอนขุนห้วย",
    "district": "ชะอำ",
    "province": "เพชรบุรี",
    "zipcode": 76120,
    "sub_district_code": 760409,
    "district_code": 7604,
    "province_code": 76
  },
  {
    "sub_district": "ท่ายาง",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760501,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "ท่าคอย",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760502,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "ยางหย่อง",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760503,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "หนองจอก",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760504,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "มาบปลาเค้า",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760505,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "ท่าไม้รวก",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760506,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "วังไคร้",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760507,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "วังจันทร์",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76170,
    "sub_district_code": 760596,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "สองพี่น้อง",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76170,
    "sub_district_code": 760597,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "แก่งกระจาน",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76170,
    "sub_district_code": 760598,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "กลัดหลวง",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760511,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "ปึกเตียน",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760512,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "ท่าแลง",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760514,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "เขากระปุก",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760513,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "บ้านในดง",
    "district": "ท่ายาง",
    "province": "เพชรบุรี",
    "zipcode": 76130,
    "sub_district_code": 760515,
    "district_code": 7605,
    "province_code": 76
  },
  {
    "sub_district": "บ้านลาด",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760601,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "บ้านหาด",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760602,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "บ้านทาน",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760603,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "ตำหรุ",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760604,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "สมอพลือ",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760605,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "ไร่มะขาม",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760606,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "ท่าเสน",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760607,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "หนองกระเจ็ด",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760608,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "หนองกะปุ",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760609,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "ลาดโพธิ์",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760610,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "สะพานไกร",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760611,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "ไร่โคก",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760612,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "โรงเข้",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760613,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "ไร่สะท้อน",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760614,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "ห้วยข้อง",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760615,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760616,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "ถ้ำรงค์",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760617,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "ห้วยลึก",
    "district": "บ้านลาด",
    "province": "เพชรบุรี",
    "zipcode": 76150,
    "sub_district_code": 760618,
    "district_code": 7606,
    "province_code": 76
  },
  {
    "sub_district": "บ้านแหลม",
    "district": "บ้านแหลม",
    "province": "เพชรบุรี",
    "zipcode": 76110,
    "sub_district_code": 760701,
    "district_code": 7607,
    "province_code": 76
  },
  {
    "sub_district": "บางขุนไทร",
    "district": "บ้านแหลม",
    "province": "เพชรบุรี",
    "zipcode": 76110,
    "sub_district_code": 760702,
    "district_code": 7607,
    "province_code": 76
  },
  {
    "sub_district": "ปากทะเล",
    "district": "บ้านแหลม",
    "province": "เพชรบุรี",
    "zipcode": 76110,
    "sub_district_code": 760703,
    "district_code": 7607,
    "province_code": 76
  },
  {
    "sub_district": "บางแก้ว",
    "district": "บ้านแหลม",
    "province": "เพชรบุรี",
    "zipcode": 76110,
    "sub_district_code": 760704,
    "district_code": 7607,
    "province_code": 76
  },
  {
    "sub_district": "แหลมผักเบี้ย",
    "district": "บ้านแหลม",
    "province": "เพชรบุรี",
    "zipcode": 76100,
    "sub_district_code": 760705,
    "district_code": 7607,
    "province_code": 76
  },
  {
    "sub_district": "บางตะบูน",
    "district": "บ้านแหลม",
    "province": "เพชรบุรี",
    "zipcode": 76110,
    "sub_district_code": 760706,
    "district_code": 7607,
    "province_code": 76
  },
  {
    "sub_district": "บางตะบูนออก",
    "district": "บ้านแหลม",
    "province": "เพชรบุรี",
    "zipcode": 76110,
    "sub_district_code": 760707,
    "district_code": 7607,
    "province_code": 76
  },
  {
    "sub_district": "บางครก",
    "district": "บ้านแหลม",
    "province": "เพชรบุรี",
    "zipcode": 76110,
    "sub_district_code": 760708,
    "district_code": 7607,
    "province_code": 76
  },
  {
    "sub_district": "ท่าแร้ง",
    "district": "บ้านแหลม",
    "province": "เพชรบุรี",
    "zipcode": 76110,
    "sub_district_code": 760709,
    "district_code": 7607,
    "province_code": 76
  },
  {
    "sub_district": "ท่าแร้งออก",
    "district": "บ้านแหลม",
    "province": "เพชรบุรี",
    "zipcode": 76110,
    "sub_district_code": 760710,
    "district_code": 7607,
    "province_code": 76
  },
  {
    "sub_district": "หนองหญ้าปล้อง",
    "district": "หนองหญ้าปล้อง",
    "province": "เพชรบุรี",
    "zipcode": 76160,
    "sub_district_code": 760301,
    "district_code": 7603,
    "province_code": 76
  },
  {
    "sub_district": "ยางน้ำกลัดเหนือ",
    "district": "หนองหญ้าปล้อง",
    "province": "เพชรบุรี",
    "zipcode": 76160,
    "sub_district_code": 760302,
    "district_code": 7603,
    "province_code": 76
  },
  {
    "sub_district": "ยางน้ำกลัดใต้",
    "district": "หนองหญ้าปล้อง",
    "province": "เพชรบุรี",
    "zipcode": 76160,
    "sub_district_code": 760303,
    "district_code": 7603,
    "province_code": 76
  },
  {
    "sub_district": "ท่าตะคร้อ",
    "district": "หนองหญ้าปล้อง",
    "province": "เพชรบุรี",
    "zipcode": 76160,
    "sub_district_code": 760304,
    "district_code": 7603,
    "province_code": 76
  },
  {
    "sub_district": "วังจันทร์",
    "district": "แก่งกระจาน",
    "province": "เพชรบุรี",
    "zipcode": 76170,
    "sub_district_code": 760803,
    "district_code": 7608,
    "province_code": 76
  },
  {
    "sub_district": "แก่งกระจาน",
    "district": "แก่งกระจาน",
    "province": "เพชรบุรี",
    "zipcode": 76170,
    "sub_district_code": 760801,
    "district_code": 7608,
    "province_code": 76
  },
  {
    "sub_district": "พุสวรรค์",
    "district": "แก่งกระจาน",
    "province": "เพชรบุรี",
    "zipcode": 76170,
    "sub_district_code": 760805,
    "district_code": 7608,
    "province_code": 76
  },
  {
    "sub_district": "สองพี่น้อง",
    "district": "แก่งกระจาน",
    "province": "เพชรบุรี",
    "zipcode": 76170,
    "sub_district_code": 760802,
    "district_code": 7608,
    "province_code": 76
  },
  {
    "sub_district": "ป่าเด็ง",
    "district": "แก่งกระจาน",
    "province": "เพชรบุรี",
    "zipcode": 76170,
    "sub_district_code": 760804,
    "district_code": 7608,
    "province_code": 76
  },
  {
    "sub_district": "ห้วยแม่เพรียง",
    "district": "แก่งกระจาน",
    "province": "เพชรบุรี",
    "zipcode": 76170,
    "sub_district_code": 760806,
    "district_code": 7608,
    "province_code": 76
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670101,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "ตะเบาะ",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670102,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "บ้านโตก",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670103,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "สะเดียง",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670104,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "ป่าเลา",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670105,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "นางั่ว",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670106,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "ท่าพล",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67250,
    "sub_district_code": 670107,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "ดงมูลเหล็ก",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670108,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "บ้านโคก",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670109,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "ชอนไพร",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670110,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "นาป่า",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670111,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "นายม",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67210,
    "sub_district_code": 670112,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "วังชมภู",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67210,
    "sub_district_code": 670113,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "น้ำร้อน",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670114,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "ห้วยสะแก",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67210,
    "sub_district_code": 670115,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "ห้วยใหญ่",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67000,
    "sub_district_code": 670116,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "ระวิง",
    "district": "เมืองเพชรบูรณ์",
    "province": "เพชรบูรณ์",
    "zipcode": 67210,
    "sub_district_code": 670117,
    "district_code": 6701,
    "province_code": 67
  },
  {
    "sub_district": "ชนแดน",
    "district": "ชนแดน",
    "province": "เพชรบูรณ์",
    "zipcode": 67150,
    "sub_district_code": 670201,
    "district_code": 6702,
    "province_code": 67
  },
  {
    "sub_district": "ดงขุย",
    "district": "ชนแดน",
    "province": "เพชรบูรณ์",
    "zipcode": 67190,
    "sub_district_code": 670202,
    "district_code": 6702,
    "province_code": 67
  },
  {
    "sub_district": "บ้านกล้วย",
    "district": "ชนแดน",
    "province": "เพชรบูรณ์",
    "zipcode": 67190,
    "sub_district_code": 670206,
    "district_code": 6702,
    "province_code": 67
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "ชนแดน",
    "province": "เพชรบูรณ์",
    "zipcode": 67150,
    "sub_district_code": 670203,
    "district_code": 6702,
    "province_code": 67
  },
  {
    "sub_district": "พุทธบาท",
    "district": "ชนแดน",
    "province": "เพชรบูรณ์",
    "zipcode": 67150,
    "sub_district_code": 670204,
    "district_code": 6702,
    "province_code": 67
  },
  {
    "sub_district": "ลาดแค",
    "district": "ชนแดน",
    "province": "เพชรบูรณ์",
    "zipcode": 67150,
    "sub_district_code": 670205,
    "district_code": 6702,
    "province_code": 67
  },
  {
    "sub_district": "ตะกุดไร",
    "district": "ชนแดน",
    "province": "เพชรบูรณ์",
    "zipcode": 67190,
    "sub_district_code": 670209,
    "district_code": 6702,
    "province_code": 67
  },
  {
    "sub_district": "ศาลาลาย",
    "district": "ชนแดน",
    "province": "เพชรบูรณ์",
    "zipcode": 67150,
    "sub_district_code": 670210,
    "district_code": 6702,
    "province_code": 67
  },
  {
    "sub_district": "ซับพุทรา",
    "district": "ชนแดน",
    "province": "เพชรบูรณ์",
    "zipcode": 67150,
    "sub_district_code": 670208,
    "district_code": 6702,
    "province_code": 67
  },
  {
    "sub_district": "ท่าโรง",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67130,
    "sub_district_code": 670501,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "สระประดู่",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67130,
    "sub_district_code": 670502,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "สามแยก",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67130,
    "sub_district_code": 670503,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "โคกปรง",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67130,
    "sub_district_code": 670504,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "น้ำร้อน",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67130,
    "sub_district_code": 670505,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "บ่อรัง",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67130,
    "sub_district_code": 670506,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "พุเตย",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67180,
    "sub_district_code": 670507,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "พุขาม",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67180,
    "sub_district_code": 670508,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "ภูน้ำหยด",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67180,
    "sub_district_code": 670509,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "ซับสมบูรณ์",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67180,
    "sub_district_code": 670510,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "บึงกระจับ",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67130,
    "sub_district_code": 670511,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "วังใหญ่",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67180,
    "sub_district_code": 670512,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "ยางสาว",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67130,
    "sub_district_code": 670513,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "ซับน้อย",
    "district": "วิเชียรบุรี",
    "province": "เพชรบูรณ์",
    "zipcode": 67180,
    "sub_district_code": 670514,
    "district_code": 6705,
    "province_code": 67
  },
  {
    "sub_district": "ศรีเทพ",
    "district": "ศรีเทพ",
    "province": "เพชรบูรณ์",
    "zipcode": 67170,
    "sub_district_code": 670601,
    "district_code": 6706,
    "province_code": 67
  },
  {
    "sub_district": "สระกรวด",
    "district": "ศรีเทพ",
    "province": "เพชรบูรณ์",
    "zipcode": 67170,
    "sub_district_code": 670602,
    "district_code": 6706,
    "province_code": 67
  },
  {
    "sub_district": "คลองกระจัง",
    "district": "ศรีเทพ",
    "province": "เพชรบูรณ์",
    "zipcode": 67170,
    "sub_district_code": 670603,
    "district_code": 6706,
    "province_code": 67
  },
  {
    "sub_district": "นาสนุ่น",
    "district": "ศรีเทพ",
    "province": "เพชรบูรณ์",
    "zipcode": 67170,
    "sub_district_code": 670604,
    "district_code": 6706,
    "province_code": 67
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "ศรีเทพ",
    "province": "เพชรบูรณ์",
    "zipcode": 67170,
    "sub_district_code": 670605,
    "district_code": 6706,
    "province_code": 67
  },
  {
    "sub_district": "ประดู่งาม",
    "district": "ศรีเทพ",
    "province": "เพชรบูรณ์",
    "zipcode": 67170,
    "sub_district_code": 670607,
    "district_code": 6706,
    "province_code": 67
  },
  {
    "sub_district": "หนองย่างทอย",
    "district": "ศรีเทพ",
    "province": "เพชรบูรณ์",
    "zipcode": 67170,
    "sub_district_code": 670606,
    "district_code": 6706,
    "province_code": 67
  },
  {
    "sub_district": "กองทูล",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67140,
    "sub_district_code": 670701,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "นาเฉลียง",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67220,
    "sub_district_code": 670702,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "บ้านโภชน์",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67140,
    "sub_district_code": 670703,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "ท่าแดง",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67140,
    "sub_district_code": 670704,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "เพชรละคร",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67140,
    "sub_district_code": 670705,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "บ่อไทย",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67140,
    "sub_district_code": 670706,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "ห้วยโป่ง",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67220,
    "sub_district_code": 670707,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "วังท่าดี",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67140,
    "sub_district_code": 670708,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "บัววัฒนา",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67140,
    "sub_district_code": 670709,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "หนองไผ่",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67140,
    "sub_district_code": 670710,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "ยางงาม",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67220,
    "sub_district_code": 670712,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "วังโบสถ์",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67140,
    "sub_district_code": 670711,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "ท่าด้วง",
    "district": "หนองไผ่",
    "province": "เพชรบูรณ์",
    "zipcode": 67140,
    "sub_district_code": 670713,
    "district_code": 6707,
    "province_code": 67
  },
  {
    "sub_district": "หล่มเก่า",
    "district": "หล่มเก่า",
    "province": "เพชรบูรณ์",
    "zipcode": 67120,
    "sub_district_code": 670401,
    "district_code": 6704,
    "province_code": 67
  },
  {
    "sub_district": "นาซำ",
    "district": "หล่มเก่า",
    "province": "เพชรบูรณ์",
    "zipcode": 67120,
    "sub_district_code": 670402,
    "district_code": 6704,
    "province_code": 67
  },
  {
    "sub_district": "หินฮาว",
    "district": "หล่มเก่า",
    "province": "เพชรบูรณ์",
    "zipcode": 67120,
    "sub_district_code": 670403,
    "district_code": 6704,
    "province_code": 67
  },
  {
    "sub_district": "บ้านเนิน",
    "district": "หล่มเก่า",
    "province": "เพชรบูรณ์",
    "zipcode": 67120,
    "sub_district_code": 670404,
    "district_code": 6704,
    "province_code": 67
  },
  {
    "sub_district": "ศิลา",
    "district": "หล่มเก่า",
    "province": "เพชรบูรณ์",
    "zipcode": 67120,
    "sub_district_code": 670405,
    "district_code": 6704,
    "province_code": 67
  },
  {
    "sub_district": "นาแซง",
    "district": "หล่มเก่า",
    "province": "เพชรบูรณ์",
    "zipcode": 67120,
    "sub_district_code": 670406,
    "district_code": 6704,
    "province_code": 67
  },
  {
    "sub_district": "วังบาล",
    "district": "หล่มเก่า",
    "province": "เพชรบูรณ์",
    "zipcode": 67120,
    "sub_district_code": 670407,
    "district_code": 6704,
    "province_code": 67
  },
  {
    "sub_district": "นาเกาะ",
    "district": "หล่มเก่า",
    "province": "เพชรบูรณ์",
    "zipcode": 67120,
    "sub_district_code": 670408,
    "district_code": 6704,
    "province_code": 67
  },
  {
    "sub_district": "ตาดกลอย",
    "district": "หล่มเก่า",
    "province": "เพชรบูรณ์",
    "zipcode": 67120,
    "sub_district_code": 670409,
    "district_code": 6704,
    "province_code": 67
  },
  {
    "sub_district": "บ้านเนิน",
    "district": "หล่มเก่า",
    "province": "เพชรบูรณ์",
    "zipcode": 67120,
    "sub_district_code": 670404,
    "district_code": 6704,
    "province_code": 67
  },
  {
    "sub_district": "หล่มสัก",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670301,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "วัดป่า",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670302,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "ตาลเดี่ยว",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670303,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "ฝายนาแซง",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670304,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "หนองสว่าง",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670305,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "น้ำเฮี้ย",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670306,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "สักหลง",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670307,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "ท่าอิบุญ",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670308,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "บ้านโสก",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670309,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "บ้านติ้ว",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670310,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "ห้วยไร่",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670311,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "น้ำก้อ",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670312,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "ปากช่อง",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670313,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "น้ำชุน",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670314,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "หนองไขว่",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670315,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "ลานบ่า",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670316,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "บุ่งน้ำเต้า",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670318,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "บ้านกลาง",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670319,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "บุ่งคล้า",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670317,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "ช้างตะลูด",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670320,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "บ้านไร่",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670321,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "ปากดุก",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670322,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "บ้านหวาย",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670323,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "เทพนคร",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670399,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "ไร่",
    "district": "หล่มสัก",
    "province": "เพชรบูรณ์",
    "zipcode": 67110,
    "sub_district_code": 670399,
    "district_code": 6703,
    "province_code": 67
  },
  {
    "sub_district": "ซับสมอทอด",
    "district": "บึงสามพัน",
    "province": "เพชรบูรณ์",
    "zipcode": 67160,
    "sub_district_code": 670801,
    "district_code": 6708,
    "province_code": 67
  },
  {
    "sub_district": "ซับไม้แดง",
    "district": "บึงสามพัน",
    "province": "เพชรบูรณ์",
    "zipcode": 67160,
    "sub_district_code": 670802,
    "district_code": 6708,
    "province_code": 67
  },
  {
    "sub_district": "หนองแจง",
    "district": "บึงสามพัน",
    "province": "เพชรบูรณ์",
    "zipcode": 67160,
    "sub_district_code": 670803,
    "district_code": 6708,
    "province_code": 67
  },
  {
    "sub_district": "กันจุ",
    "district": "บึงสามพัน",
    "province": "เพชรบูรณ์",
    "zipcode": 67160,
    "sub_district_code": 670804,
    "district_code": 6708,
    "province_code": 67
  },
  {
    "sub_district": "วังพิกุล",
    "district": "บึงสามพัน",
    "province": "เพชรบูรณ์",
    "zipcode": 67230,
    "sub_district_code": 670805,
    "district_code": 6708,
    "province_code": 67
  },
  {
    "sub_district": "พญาวัง",
    "district": "บึงสามพัน",
    "province": "เพชรบูรณ์",
    "zipcode": 67160,
    "sub_district_code": 670806,
    "district_code": 6708,
    "province_code": 67
  },
  {
    "sub_district": "ศรีมงคล",
    "district": "บึงสามพัน",
    "province": "เพชรบูรณ์",
    "zipcode": 67160,
    "sub_district_code": 670807,
    "district_code": 6708,
    "province_code": 67
  },
  {
    "sub_district": "สระแก้ว",
    "district": "บึงสามพัน",
    "province": "เพชรบูรณ์",
    "zipcode": 67160,
    "sub_district_code": 670808,
    "district_code": 6708,
    "province_code": 67
  },
  {
    "sub_district": "บึงสามพัน",
    "district": "บึงสามพัน",
    "province": "เพชรบูรณ์",
    "zipcode": 67160,
    "sub_district_code": 670809,
    "district_code": 6708,
    "province_code": 67
  },
  {
    "sub_district": "วังโป่ง",
    "district": "วังโป่ง",
    "province": "เพชรบูรณ์",
    "zipcode": 67240,
    "sub_district_code": 671001,
    "district_code": 6710,
    "province_code": 67
  },
  {
    "sub_district": "ท้ายดง",
    "district": "วังโป่ง",
    "province": "เพชรบูรณ์",
    "zipcode": 67240,
    "sub_district_code": 671002,
    "district_code": 6710,
    "province_code": 67
  },
  {
    "sub_district": "ซับเปิบ",
    "district": "วังโป่ง",
    "province": "เพชรบูรณ์",
    "zipcode": 67240,
    "sub_district_code": 671003,
    "district_code": 6710,
    "province_code": 67
  },
  {
    "sub_district": "วังหิน",
    "district": "วังโป่ง",
    "province": "เพชรบูรณ์",
    "zipcode": 67240,
    "sub_district_code": 671004,
    "district_code": 6710,
    "province_code": 67
  },
  {
    "sub_district": "วังศาล",
    "district": "วังโป่ง",
    "province": "เพชรบูรณ์",
    "zipcode": 67240,
    "sub_district_code": 671005,
    "district_code": 6710,
    "province_code": 67
  },
  {
    "sub_district": "แคมป์สน",
    "district": "เขาค้อ",
    "province": "เพชรบูรณ์",
    "zipcode": 67280,
    "sub_district_code": 671102,
    "district_code": 6711,
    "province_code": 67
  },
  {
    "sub_district": "ทุ่งสมอ",
    "district": "เขาค้อ",
    "province": "เพชรบูรณ์",
    "zipcode": 67270,
    "sub_district_code": 671101,
    "district_code": 6711,
    "province_code": 67
  },
  {
    "sub_district": "เขาค้อ",
    "district": "เขาค้อ",
    "province": "เพชรบูรณ์",
    "zipcode": 67270,
    "sub_district_code": 671103,
    "district_code": 6711,
    "province_code": 67
  },
  {
    "sub_district": "ริมสีม่วง",
    "district": "เขาค้อ",
    "province": "เพชรบูรณ์",
    "zipcode": 67270,
    "sub_district_code": 671104,
    "district_code": 6711,
    "province_code": 67
  },
  {
    "sub_district": "สะเดาะพง",
    "district": "เขาค้อ",
    "province": "เพชรบูรณ์",
    "zipcode": 67270,
    "sub_district_code": 671105,
    "district_code": 6711,
    "province_code": 67
  },
  {
    "sub_district": "หนองแม่นา",
    "district": "เขาค้อ",
    "province": "เพชรบูรณ์",
    "zipcode": 67270,
    "sub_district_code": 671106,
    "district_code": 6711,
    "province_code": 67
  },
  {
    "sub_district": "เข็กน้อย",
    "district": "เขาค้อ",
    "province": "เพชรบูรณ์",
    "zipcode": 67280,
    "sub_district_code": 671107,
    "district_code": 6711,
    "province_code": 67
  },
  {
    "sub_district": "น้ำหนาว",
    "district": "น้ำหนาว",
    "province": "เพชรบูรณ์",
    "zipcode": 67260,
    "sub_district_code": 670901,
    "district_code": 6709,
    "province_code": 67
  },
  {
    "sub_district": "หลักด่าน",
    "district": "น้ำหนาว",
    "province": "เพชรบูรณ์",
    "zipcode": 67260,
    "sub_district_code": 670902,
    "district_code": 6709,
    "province_code": 67
  },
  {
    "sub_district": "วังกวาง",
    "district": "น้ำหนาว",
    "province": "เพชรบูรณ์",
    "zipcode": 67260,
    "sub_district_code": 670903,
    "district_code": 6709,
    "province_code": 67
  },
  {
    "sub_district": "โคกมน",
    "district": "น้ำหนาว",
    "province": "เพชรบูรณ์",
    "zipcode": 67260,
    "sub_district_code": 670904,
    "district_code": 6709,
    "province_code": 67
  },
  {
    "sub_district": "ในเวียง",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540101,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "นาจักร",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540102,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "น้ำชำ",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540103,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "ป่าแดง",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540104,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "ทุ่งโฮ้ง",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540105,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "เหมืองหม้อ",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540106,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "วังธง",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540107,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "แม่หล่าย",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540108,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "ห้วยม้า",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540109,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "ป่าแมต",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540110,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "บ้านถิ่น",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540111,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "สวนเขื่อน",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540112,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "วังหงส์",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540113,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "แม่คำมี",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540114,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "ทุ่งกวาว",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540115,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540116,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "ช่อแฮ",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540118,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "ร่องฟอง",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540119,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "กาญจนา",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540120,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "แม่ยม",
    "district": "เมืองแพร่",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540117,
    "district_code": 5401,
    "province_code": 54
  },
  {
    "sub_district": "เด่นชัย",
    "district": "เด่นชัย",
    "province": "แพร่",
    "zipcode": 54110,
    "sub_district_code": 540501,
    "district_code": 5405,
    "province_code": 54
  },
  {
    "sub_district": "แม่จั๊วะ",
    "district": "เด่นชัย",
    "province": "แพร่",
    "zipcode": 54110,
    "sub_district_code": 540502,
    "district_code": 5405,
    "province_code": 54
  },
  {
    "sub_district": "ไทรย้อย",
    "district": "เด่นชัย",
    "province": "แพร่",
    "zipcode": 54110,
    "sub_district_code": 540503,
    "district_code": 5405,
    "province_code": 54
  },
  {
    "sub_district": "ห้วยไร่",
    "district": "เด่นชัย",
    "province": "แพร่",
    "zipcode": 54110,
    "sub_district_code": 540504,
    "district_code": 5405,
    "province_code": 54
  },
  {
    "sub_district": "ปงป่าหวาย",
    "district": "เด่นชัย",
    "province": "แพร่",
    "zipcode": 54110,
    "sub_district_code": 540505,
    "district_code": 5405,
    "province_code": 54
  },
  {
    "sub_district": "ร้องกวาง",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540201,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "ร้องเข็ม",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540204,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "น้ำเลา",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540205,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "บ้านเวียง",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540206,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "ทุ่งศรี",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540207,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "แม่ยางตาล",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540208,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "แม่ยางฮ่อ",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540209,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "ไผ่โทน",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540210,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "ห้วยโรง",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540213,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "แม่ยางร้อง",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540215,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "แม่ทราย",
    "district": "ร้องกวาง",
    "province": "แพร่",
    "zipcode": 54140,
    "sub_district_code": 540214,
    "district_code": 5402,
    "province_code": 54
  },
  {
    "sub_district": "ห้วยอ้อ",
    "district": "ลอง",
    "province": "แพร่",
    "zipcode": 54150,
    "sub_district_code": 540301,
    "district_code": 5403,
    "province_code": 54
  },
  {
    "sub_district": "บ้านปิน",
    "district": "ลอง",
    "province": "แพร่",
    "zipcode": 54150,
    "sub_district_code": 540302,
    "district_code": 5403,
    "province_code": 54
  },
  {
    "sub_district": "ต้าผามอก",
    "district": "ลอง",
    "province": "แพร่",
    "zipcode": 54150,
    "sub_district_code": 540303,
    "district_code": 5403,
    "province_code": 54
  },
  {
    "sub_district": "เวียงต้า",
    "district": "ลอง",
    "province": "แพร่",
    "zipcode": 54150,
    "sub_district_code": 540304,
    "district_code": 5403,
    "province_code": 54
  },
  {
    "sub_district": "ปากกาง",
    "district": "ลอง",
    "province": "แพร่",
    "zipcode": 54150,
    "sub_district_code": 540305,
    "district_code": 5403,
    "province_code": 54
  },
  {
    "sub_district": "หัวทุ่ง",
    "district": "ลอง",
    "province": "แพร่",
    "zipcode": 54150,
    "sub_district_code": 540306,
    "district_code": 5403,
    "province_code": 54
  },
  {
    "sub_district": "ทุ่งแล้ง",
    "district": "ลอง",
    "province": "แพร่",
    "zipcode": 54150,
    "sub_district_code": 540307,
    "district_code": 5403,
    "province_code": 54
  },
  {
    "sub_district": "บ่อเหล็กลอง",
    "district": "ลอง",
    "province": "แพร่",
    "zipcode": 54150,
    "sub_district_code": 540308,
    "district_code": 5403,
    "province_code": 54
  },
  {
    "sub_district": "แม่ปาน",
    "district": "ลอง",
    "province": "แพร่",
    "zipcode": 54150,
    "sub_district_code": 540309,
    "district_code": 5403,
    "province_code": 54
  },
  {
    "sub_district": "วังชิ้น",
    "district": "วังชิ้น",
    "province": "แพร่",
    "zipcode": 54160,
    "sub_district_code": 540701,
    "district_code": 5407,
    "province_code": 54
  },
  {
    "sub_district": "สรอย",
    "district": "วังชิ้น",
    "province": "แพร่",
    "zipcode": 54160,
    "sub_district_code": 540702,
    "district_code": 5407,
    "province_code": 54
  },
  {
    "sub_district": "แม่ป้าก",
    "district": "วังชิ้น",
    "province": "แพร่",
    "zipcode": 54160,
    "sub_district_code": 540703,
    "district_code": 5407,
    "province_code": 54
  },
  {
    "sub_district": "นาพูน",
    "district": "วังชิ้น",
    "province": "แพร่",
    "zipcode": 54160,
    "sub_district_code": 540704,
    "district_code": 5407,
    "province_code": 54
  },
  {
    "sub_district": "แม่พุง",
    "district": "วังชิ้น",
    "province": "แพร่",
    "zipcode": 54160,
    "sub_district_code": 540705,
    "district_code": 5407,
    "province_code": 54
  },
  {
    "sub_district": "ป่าสัก",
    "district": "วังชิ้น",
    "province": "แพร่",
    "zipcode": 54160,
    "sub_district_code": 540706,
    "district_code": 5407,
    "province_code": 54
  },
  {
    "sub_district": "แม่เกิ๋ง",
    "district": "วังชิ้น",
    "province": "แพร่",
    "zipcode": 54160,
    "sub_district_code": 540707,
    "district_code": 5407,
    "province_code": 54
  },
  {
    "sub_district": "บ้านหนุน",
    "district": "สอง",
    "province": "แพร่",
    "zipcode": 54120,
    "sub_district_code": 540601,
    "district_code": 5406,
    "province_code": 54
  },
  {
    "sub_district": "บ้านกลาง",
    "district": "สอง",
    "province": "แพร่",
    "zipcode": 54120,
    "sub_district_code": 540602,
    "district_code": 5406,
    "province_code": 54
  },
  {
    "sub_district": "ห้วยหม้าย",
    "district": "สอง",
    "province": "แพร่",
    "zipcode": 54120,
    "sub_district_code": 540603,
    "district_code": 5406,
    "province_code": 54
  },
  {
    "sub_district": "เตาปูน",
    "district": "สอง",
    "province": "แพร่",
    "zipcode": 54120,
    "sub_district_code": 540604,
    "district_code": 5406,
    "province_code": 54
  },
  {
    "sub_district": "หัวเมือง",
    "district": "สอง",
    "province": "แพร่",
    "zipcode": 54120,
    "sub_district_code": 540605,
    "district_code": 5406,
    "province_code": 54
  },
  {
    "sub_district": "สะเอียบ",
    "district": "สอง",
    "province": "แพร่",
    "zipcode": 54120,
    "sub_district_code": 540606,
    "district_code": 5406,
    "province_code": 54
  },
  {
    "sub_district": "แดนชุมพล",
    "district": "สอง",
    "province": "แพร่",
    "zipcode": 54120,
    "sub_district_code": 540607,
    "district_code": 5406,
    "province_code": 54
  },
  {
    "sub_district": "ทุ่งน้าว",
    "district": "สอง",
    "province": "แพร่",
    "zipcode": 54120,
    "sub_district_code": 540608,
    "district_code": 5406,
    "province_code": 54
  },
  {
    "sub_district": "สูงเม่น",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540401,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "น้ำชำ",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540402,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "หัวฝาย",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540403,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "ดอนมูล",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540404,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "บ้านเหล่า",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540405,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "บ้านกวาง",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540406,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "บ้านปง",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540407,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "บ้านกาศ",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540408,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "ร่องกาศ",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540409,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "สบสาย",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540410,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "เวียงทอง",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54000,
    "sub_district_code": 540411,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "พระหลวง",
    "district": "สูงเม่น",
    "province": "แพร่",
    "zipcode": 54130,
    "sub_district_code": 540412,
    "district_code": 5404,
    "province_code": 54
  },
  {
    "sub_district": "หนองม่วงไข่",
    "district": "หนองม่วงไข่",
    "province": "แพร่",
    "zipcode": 54170,
    "sub_district_code": 540802,
    "district_code": 5408,
    "province_code": 54
  },
  {
    "sub_district": "แม่คำมี",
    "district": "หนองม่วงไข่",
    "province": "แพร่",
    "zipcode": 54170,
    "sub_district_code": 540801,
    "district_code": 5408,
    "province_code": 54
  },
  {
    "sub_district": "วังหลวง",
    "district": "หนองม่วงไข่",
    "province": "แพร่",
    "zipcode": 54170,
    "sub_district_code": 540804,
    "district_code": 5408,
    "province_code": 54
  },
  {
    "sub_district": "น้ำรัด",
    "district": "หนองม่วงไข่",
    "province": "แพร่",
    "zipcode": 54170,
    "sub_district_code": 540803,
    "district_code": 5408,
    "province_code": 54
  },
  {
    "sub_district": "ทุ่งแค้ว",
    "district": "หนองม่วงไข่",
    "province": "แพร่",
    "zipcode": 54170,
    "sub_district_code": 540806,
    "district_code": 5408,
    "province_code": 54
  },
  {
    "sub_district": "ตำหนักธรรม",
    "district": "หนองม่วงไข่",
    "province": "แพร่",
    "zipcode": 54170,
    "sub_district_code": 540805,
    "district_code": 5408,
    "province_code": 54
  },
  {
    "sub_district": "ตลาดใหญ่",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83000,
    "sub_district_code": 830101,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "ตลาดเหนือ",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83000,
    "sub_district_code": 830102,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "เกาะแก้ว",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83000,
    "sub_district_code": 830103,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "รัษฎา",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83000,
    "sub_district_code": 830104,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "วิชิต",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83000,
    "sub_district_code": 830105,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "ฉลอง",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83130,
    "sub_district_code": 830106,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "ราไวย์",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83130,
    "sub_district_code": 830107,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "กะรน",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83100,
    "sub_district_code": 830108,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "สามกอง",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83000,
    "sub_district_code": 830197,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "บางเหนียว",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83000,
    "sub_district_code": 830198,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "ป่าตอง",
    "district": "เมืองภูเก็ต",
    "province": "ภูเก็ต",
    "zipcode": 83150,
    "sub_district_code": 830199,
    "district_code": 8301,
    "province_code": 83
  },
  {
    "sub_district": "กะทู้",
    "district": "กะทู้",
    "province": "ภูเก็ต",
    "zipcode": 83120,
    "sub_district_code": 830201,
    "district_code": 8302,
    "province_code": 83
  },
  {
    "sub_district": "ป่าตอง",
    "district": "กะทู้",
    "province": "ภูเก็ต",
    "zipcode": 83150,
    "sub_district_code": 830202,
    "district_code": 8302,
    "province_code": 83
  },
  {
    "sub_district": "กมลา",
    "district": "กะทู้",
    "province": "ภูเก็ต",
    "zipcode": 83150,
    "sub_district_code": 830203,
    "district_code": 8302,
    "province_code": 83
  },
  {
    "sub_district": "เทพกระษัตรี",
    "district": "ถลาง",
    "province": "ภูเก็ต",
    "zipcode": 83110,
    "sub_district_code": 830301,
    "district_code": 8303,
    "province_code": 83
  },
  {
    "sub_district": "ศรีสุนทร",
    "district": "ถลาง",
    "province": "ภูเก็ต",
    "zipcode": 83110,
    "sub_district_code": 830302,
    "district_code": 8303,
    "province_code": 83
  },
  {
    "sub_district": "เชิงทะเล",
    "district": "ถลาง",
    "province": "ภูเก็ต",
    "zipcode": 83110,
    "sub_district_code": 830303,
    "district_code": 8303,
    "province_code": 83
  },
  {
    "sub_district": "ป่าคลอก",
    "district": "ถลาง",
    "province": "ภูเก็ต",
    "zipcode": 83110,
    "sub_district_code": 830304,
    "district_code": 8303,
    "province_code": 83
  },
  {
    "sub_district": "ไม้ขาว",
    "district": "ถลาง",
    "province": "ภูเก็ต",
    "zipcode": 83110,
    "sub_district_code": 830305,
    "district_code": 8303,
    "province_code": 83
  },
  {
    "sub_district": "สาคู",
    "district": "ถลาง",
    "province": "ภูเก็ต",
    "zipcode": 83110,
    "sub_district_code": 830306,
    "district_code": 8303,
    "province_code": 83
  },
  {
    "sub_district": "ตลาด",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440101,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "เขวา",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440102,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "ท่าตูม",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440103,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "แวงน่าง",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440104,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "โคกก่อ",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440105,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "ดอนหว่าน",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440106,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "เกิ้ง",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440107,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "แก่งเลิงจาน",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440108,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "ท่าสองคอน",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440109,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "ลาดพัฒนา",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440110,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "หนองโน",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440113,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "หนองปลิง",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440111,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "ห้วยแอ่ง",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440112,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "บัวค้อ",
    "district": "เมืองมหาสารคาม",
    "province": "มหาสารคาม",
    "zipcode": 44000,
    "sub_district_code": 440114,
    "district_code": 4401,
    "province_code": 44
  },
  {
    "sub_district": "โคกพระ",
    "district": "กันทรวิชัย",
    "province": "มหาสารคาม",
    "zipcode": 44150,
    "sub_district_code": 440401,
    "district_code": 4404,
    "province_code": 44
  },
  {
    "sub_district": "คันธารราษฎร์",
    "district": "กันทรวิชัย",
    "province": "มหาสารคาม",
    "zipcode": 44150,
    "sub_district_code": 440402,
    "district_code": 4404,
    "province_code": 44
  },
  {
    "sub_district": "มะค่า",
    "district": "กันทรวิชัย",
    "province": "มหาสารคาม",
    "zipcode": 44150,
    "sub_district_code": 440403,
    "district_code": 4404,
    "province_code": 44
  },
  {
    "sub_district": "ท่าขอนยาง",
    "district": "กันทรวิชัย",
    "province": "มหาสารคาม",
    "zipcode": 44150,
    "sub_district_code": 440404,
    "district_code": 4404,
    "province_code": 44
  },
  {
    "sub_district": "นาสีนวน",
    "district": "กันทรวิชัย",
    "province": "มหาสารคาม",
    "zipcode": 44150,
    "sub_district_code": 440405,
    "district_code": 4404,
    "province_code": 44
  },
  {
    "sub_district": "ขามเรียง",
    "district": "กันทรวิชัย",
    "province": "มหาสารคาม",
    "zipcode": 44150,
    "sub_district_code": 440406,
    "district_code": 4404,
    "province_code": 44
  },
  {
    "sub_district": "เขวาใหญ่",
    "district": "กันทรวิชัย",
    "province": "มหาสารคาม",
    "zipcode": 44150,
    "sub_district_code": 440407,
    "district_code": 4404,
    "province_code": 44
  },
  {
    "sub_district": "ศรีสุข",
    "district": "กันทรวิชัย",
    "province": "มหาสารคาม",
    "zipcode": 44150,
    "sub_district_code": 440408,
    "district_code": 4404,
    "province_code": 44
  },
  {
    "sub_district": "กุดใส้จ่อ",
    "district": "กันทรวิชัย",
    "province": "มหาสารคาม",
    "zipcode": 44150,
    "sub_district_code": 440409,
    "district_code": 4404,
    "province_code": 44
  },
  {
    "sub_district": "ขามเฒ่าพัฒนา",
    "district": "กันทรวิชัย",
    "province": "มหาสารคาม",
    "zipcode": 44150,
    "sub_district_code": 440410,
    "district_code": 4404,
    "province_code": 44
  },
  {
    "sub_district": "หัวขวาง",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440301,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "ยางน้อย",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440302,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "วังยาว",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440303,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "เขวาไร่",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440304,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "แพง",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440305,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "แก้งแก",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440306,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "หนองเหล็ก",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440307,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "หนองบัว",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440308,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "เหล่า",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440309,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "เขื่อน",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440310,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "ดอนกลาง",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440317,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "แห่ใต้",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440314,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "ยางท่าแจ้ง",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440313,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "โพนงาม",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440312,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "หนองบอน",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440311,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "หนองกุงสวรรค์",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440315,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "เลิงใต้",
    "district": "โกสุมพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44140,
    "sub_district_code": 440316,
    "district_code": 4403,
    "province_code": 44
  },
  {
    "sub_district": "เชียงยืน",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440501,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "ชื่นชม",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440596,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "หนองซอน",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440503,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "เหล่าดอกไม้",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440597,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "ดอนเงิน",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440505,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "กู่ทอง",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440506,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "นาทอง",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440507,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "เสือเฒ่า",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440508,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "กุดปลาดุก",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440598,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "โพนทอง",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440511,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "เหล่าบัวบาน",
    "district": "เชียงยืน",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 440512,
    "district_code": 4405,
    "province_code": 44
  },
  {
    "sub_district": "นาเชือก",
    "district": "นาเชือก",
    "province": "มหาสารคาม",
    "zipcode": 44170,
    "sub_district_code": 440701,
    "district_code": 4407,
    "province_code": 44
  },
  {
    "sub_district": "สำโรง",
    "district": "นาเชือก",
    "province": "มหาสารคาม",
    "zipcode": 44170,
    "sub_district_code": 440702,
    "district_code": 4407,
    "province_code": 44
  },
  {
    "sub_district": "หนองแดง",
    "district": "นาเชือก",
    "province": "มหาสารคาม",
    "zipcode": 44170,
    "sub_district_code": 440703,
    "district_code": 4407,
    "province_code": 44
  },
  {
    "sub_district": "เขวาไร่",
    "district": "นาเชือก",
    "province": "มหาสารคาม",
    "zipcode": 44170,
    "sub_district_code": 440704,
    "district_code": 4407,
    "province_code": 44
  },
  {
    "sub_district": "หนองโพธิ์",
    "district": "นาเชือก",
    "province": "มหาสารคาม",
    "zipcode": 44170,
    "sub_district_code": 440705,
    "district_code": 4407,
    "province_code": 44
  },
  {
    "sub_district": "ปอพาน",
    "district": "นาเชือก",
    "province": "มหาสารคาม",
    "zipcode": 44170,
    "sub_district_code": 440706,
    "district_code": 4407,
    "province_code": 44
  },
  {
    "sub_district": "หนองเม็ก",
    "district": "นาเชือก",
    "province": "มหาสารคาม",
    "zipcode": 44170,
    "sub_district_code": 440707,
    "district_code": 4407,
    "province_code": 44
  },
  {
    "sub_district": "หนองเรือ",
    "district": "นาเชือก",
    "province": "มหาสารคาม",
    "zipcode": 44170,
    "sub_district_code": 440708,
    "district_code": 4407,
    "province_code": 44
  },
  {
    "sub_district": "หนองกุง",
    "district": "นาเชือก",
    "province": "มหาสารคาม",
    "zipcode": 44170,
    "sub_district_code": 440709,
    "district_code": 4407,
    "province_code": 44
  },
  {
    "sub_district": "สันป่าตอง",
    "district": "นาเชือก",
    "province": "มหาสารคาม",
    "zipcode": 44170,
    "sub_district_code": 440710,
    "district_code": 4407,
    "province_code": 44
  },
  {
    "sub_district": "นาดูน",
    "district": "นาดูน",
    "province": "มหาสารคาม",
    "zipcode": 44180,
    "sub_district_code": 441001,
    "district_code": 4410,
    "province_code": 44
  },
  {
    "sub_district": "หนองไผ่",
    "district": "นาดูน",
    "province": "มหาสารคาม",
    "zipcode": 44180,
    "sub_district_code": 441002,
    "district_code": 4410,
    "province_code": 44
  },
  {
    "sub_district": "หนองคู",
    "district": "นาดูน",
    "province": "มหาสารคาม",
    "zipcode": 44180,
    "sub_district_code": 441003,
    "district_code": 4410,
    "province_code": 44
  },
  {
    "sub_district": "ดงบัง",
    "district": "นาดูน",
    "province": "มหาสารคาม",
    "zipcode": 44180,
    "sub_district_code": 441004,
    "district_code": 4410,
    "province_code": 44
  },
  {
    "sub_district": "ดงดวน",
    "district": "นาดูน",
    "province": "มหาสารคาม",
    "zipcode": 44180,
    "sub_district_code": 441005,
    "district_code": 4410,
    "province_code": 44
  },
  {
    "sub_district": "หัวดง",
    "district": "นาดูน",
    "province": "มหาสารคาม",
    "zipcode": 44180,
    "sub_district_code": 441006,
    "district_code": 4410,
    "province_code": 44
  },
  {
    "sub_district": "ดงยาง",
    "district": "นาดูน",
    "province": "มหาสารคาม",
    "zipcode": 44180,
    "sub_district_code": 441007,
    "district_code": 4410,
    "province_code": 44
  },
  {
    "sub_district": "พระธาตุ",
    "district": "นาดูน",
    "province": "มหาสารคาม",
    "zipcode": 44180,
    "sub_district_code": 441009,
    "district_code": 4410,
    "province_code": 44
  },
  {
    "sub_district": "กู่สันตรัตน์",
    "district": "นาดูน",
    "province": "มหาสารคาม",
    "zipcode": 44180,
    "sub_district_code": 441008,
    "district_code": 4410,
    "province_code": 44
  },
  {
    "sub_district": "บรบือ",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440601,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "บ่อใหญ่",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440602,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "กุดรัง",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440695,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "วังไชย",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440604,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "หนองม่วง",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440605,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "กำพี้",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440606,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "โนนราษี",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440607,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "โนนแดง",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440608,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "เลิงแฝก",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440696,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "หนองจิก",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440610,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "บัวมาศ",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440611,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "นาโพธิ์",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440697,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "หนองคูขาด",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440613,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "หนองแวง",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440698,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "หนองโก",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440619,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "หนองสิม",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440618,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "ยาง",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440616,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "วังใหม่",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440615,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "ดอนงัว",
    "district": "บรบือ",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 440620,
    "district_code": 4406,
    "province_code": 44
  },
  {
    "sub_district": "ปะหลาน",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440801,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "ก้ามปู",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440802,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "เวียงสะอาด",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440803,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "เม็กดำ",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440804,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "นาสีนวล",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440805,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "ดงเมือง",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 440893,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "แวงดง",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 440894,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "ราษฎร์เจริญ",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440809,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "หนองบัวแก้ว",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440810,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "นาภู",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 440895,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "เมืองเตา",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440812,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "บ้านกู่",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 440896,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "ยางสีสุราช",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 440897,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "ลานสะแก",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440815,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "เวียงชัย",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440816,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "หนองไผ่",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44180,
    "sub_district_code": 440898,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "เมืองเสือ",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440819,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "หนองบัว",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440817,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "ราษฎร์พัฒนา",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440818,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "ภารแอ่น",
    "district": "พยัคฆภูมิพิสัย",
    "province": "มหาสารคาม",
    "zipcode": 44110,
    "sub_district_code": 440820,
    "district_code": 4408,
    "province_code": 44
  },
  {
    "sub_district": "หนองแสง",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440901,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "ขามป้อม",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440902,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "เสือโก้ก",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440903,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "ดงใหญ่",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440904,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "โพธิ์ชัย",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440905,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "หัวเรือ",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440906,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "แคน",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440907,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "งัวบา",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440908,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "นาข่า",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440909,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "บ้านหวาย",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440910,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "หนองไฮ",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440911,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "หนองทุ่ม",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440913,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "หนองกุง",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44190,
    "sub_district_code": 440997,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "โคกสีทองหลาง",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440915,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "ประชาพัฒนา",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440912,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "หนองแสน",
    "district": "วาปีปทุม",
    "province": "มหาสารคาม",
    "zipcode": 44120,
    "sub_district_code": 440914,
    "district_code": 4409,
    "province_code": 44
  },
  {
    "sub_district": "แกดำ",
    "district": "แกดำ",
    "province": "มหาสารคาม",
    "zipcode": 44190,
    "sub_district_code": 440201,
    "district_code": 4402,
    "province_code": 44
  },
  {
    "sub_district": "วังแสง",
    "district": "แกดำ",
    "province": "มหาสารคาม",
    "zipcode": 44190,
    "sub_district_code": 440202,
    "district_code": 4402,
    "province_code": 44
  },
  {
    "sub_district": "มิตรภาพ",
    "district": "แกดำ",
    "province": "มหาสารคาม",
    "zipcode": 44190,
    "sub_district_code": 440203,
    "district_code": 4402,
    "province_code": 44
  },
  {
    "sub_district": "หนองกุง",
    "district": "แกดำ",
    "province": "มหาสารคาม",
    "zipcode": 44190,
    "sub_district_code": 440204,
    "district_code": 4402,
    "province_code": 44
  },
  {
    "sub_district": "โนนภิบาล",
    "district": "แกดำ",
    "province": "มหาสารคาม",
    "zipcode": 44190,
    "sub_district_code": 440205,
    "district_code": 4402,
    "province_code": 44
  },
  {
    "sub_district": "ดงเมือง",
    "district": "ยางสีสุราช",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 441105,
    "district_code": 4411,
    "province_code": 44
  },
  {
    "sub_district": "นาภู",
    "district": "ยางสีสุราช",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 441102,
    "district_code": 4411,
    "province_code": 44
  },
  {
    "sub_district": "บ้านกู่",
    "district": "ยางสีสุราช",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 441104,
    "district_code": 4411,
    "province_code": 44
  },
  {
    "sub_district": "ยางสีสุราช",
    "district": "ยางสีสุราช",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 441101,
    "district_code": 4411,
    "province_code": 44
  },
  {
    "sub_district": "แวงดง",
    "district": "ยางสีสุราช",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 441103,
    "district_code": 4411,
    "province_code": 44
  },
  {
    "sub_district": "หนองบัวสันตุ",
    "district": "ยางสีสุราช",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 441107,
    "district_code": 4411,
    "province_code": 44
  },
  {
    "sub_district": "สร้างแซ่ง",
    "district": "ยางสีสุราช",
    "province": "มหาสารคาม",
    "zipcode": 44210,
    "sub_district_code": 441108,
    "district_code": 4411,
    "province_code": 44
  },
  {
    "sub_district": "นาโพธิ์",
    "district": "กุดรัง",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 441202,
    "district_code": 4412,
    "province_code": 44
  },
  {
    "sub_district": "กุดรัง",
    "district": "กุดรัง",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 441201,
    "district_code": 4412,
    "province_code": 44
  },
  {
    "sub_district": "เลิงแฝก",
    "district": "กุดรัง",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 441203,
    "district_code": 4412,
    "province_code": 44
  },
  {
    "sub_district": "หนองแวง",
    "district": "กุดรัง",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 441204,
    "district_code": 4412,
    "province_code": 44
  },
  {
    "sub_district": "ห้วยเตย",
    "district": "กุดรัง",
    "province": "มหาสารคาม",
    "zipcode": 44130,
    "sub_district_code": 441205,
    "district_code": 4412,
    "province_code": 44
  },
  {
    "sub_district": "ชื่นชม",
    "district": "ชื่นชม",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 441301,
    "district_code": 4413,
    "province_code": 44
  },
  {
    "sub_district": "กุดปลาดุก",
    "district": "ชื่นชม",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 441302,
    "district_code": 4413,
    "province_code": 44
  },
  {
    "sub_district": "เหล่าดอกไม้",
    "district": "ชื่นชม",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 441303,
    "district_code": 4413,
    "province_code": 44
  },
  {
    "sub_district": "หนองกุง",
    "district": "ชื่นชม",
    "province": "มหาสารคาม",
    "zipcode": 44160,
    "sub_district_code": 441304,
    "district_code": 4413,
    "province_code": 44
  },
  {
    "sub_district": "จองคำ",
    "district": "เมืองแม่ฮ่องสอน",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58000,
    "sub_district_code": 580101,
    "district_code": 5801,
    "province_code": 58
  },
  {
    "sub_district": "ห้วยโป่ง",
    "district": "เมืองแม่ฮ่องสอน",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58000,
    "sub_district_code": 580102,
    "district_code": 5801,
    "province_code": 58
  },
  {
    "sub_district": "ผาบ่อง",
    "district": "เมืองแม่ฮ่องสอน",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58000,
    "sub_district_code": 580103,
    "district_code": 5801,
    "province_code": 58
  },
  {
    "sub_district": "ปางหมู",
    "district": "เมืองแม่ฮ่องสอน",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58000,
    "sub_district_code": 580104,
    "district_code": 5801,
    "province_code": 58
  },
  {
    "sub_district": "หมอกจำแป่",
    "district": "เมืองแม่ฮ่องสอน",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58000,
    "sub_district_code": 580105,
    "district_code": 5801,
    "province_code": 58
  },
  {
    "sub_district": "ห้วยผา",
    "district": "เมืองแม่ฮ่องสอน",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58000,
    "sub_district_code": 580106,
    "district_code": 5801,
    "province_code": 58
  },
  {
    "sub_district": "ห้วยปูลิง",
    "district": "เมืองแม่ฮ่องสอน",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58000,
    "sub_district_code": 580109,
    "district_code": 5801,
    "province_code": 58
  },
  {
    "sub_district": "ขุนยวม",
    "district": "ขุนยวม",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58140,
    "sub_district_code": 580201,
    "district_code": 5802,
    "province_code": 58
  },
  {
    "sub_district": "แม่เงา",
    "district": "ขุนยวม",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58140,
    "sub_district_code": 580202,
    "district_code": 5802,
    "province_code": 58
  },
  {
    "sub_district": "เมืองปอน",
    "district": "ขุนยวม",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58140,
    "sub_district_code": 580203,
    "district_code": 5802,
    "province_code": 58
  },
  {
    "sub_district": "แม่ยวมน้อย",
    "district": "ขุนยวม",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58140,
    "sub_district_code": 580204,
    "district_code": 5802,
    "province_code": 58
  },
  {
    "sub_district": "แม่กิ๊",
    "district": "ขุนยวม",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58140,
    "sub_district_code": 580205,
    "district_code": 5802,
    "province_code": 58
  },
  {
    "sub_district": "แม่อูคอ",
    "district": "ขุนยวม",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58140,
    "sub_district_code": 580206,
    "district_code": 5802,
    "province_code": 58
  },
  {
    "sub_district": "เวียงใต้",
    "district": "ปาย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58130,
    "sub_district_code": 580301,
    "district_code": 5803,
    "province_code": 58
  },
  {
    "sub_district": "เวียงเหนือ",
    "district": "ปาย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58130,
    "sub_district_code": 580302,
    "district_code": 5803,
    "province_code": 58
  },
  {
    "sub_district": "แม่นาเติง",
    "district": "ปาย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58130,
    "sub_district_code": 580303,
    "district_code": 5803,
    "province_code": 58
  },
  {
    "sub_district": "แม่ฮี้",
    "district": "ปาย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58130,
    "sub_district_code": 580304,
    "district_code": 5803,
    "province_code": 58
  },
  {
    "sub_district": "ทุ่งยาว",
    "district": "ปาย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58130,
    "sub_district_code": 580305,
    "district_code": 5803,
    "province_code": 58
  },
  {
    "sub_district": "เมืองแปง",
    "district": "ปาย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58130,
    "sub_district_code": 580306,
    "district_code": 5803,
    "province_code": 58
  },
  {
    "sub_district": "โป่งสา",
    "district": "ปาย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58130,
    "sub_district_code": 580307,
    "district_code": 5803,
    "province_code": 58
  },
  {
    "sub_district": "แม่ลาน้อย",
    "district": "แม่ลาน้อย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58120,
    "sub_district_code": 580501,
    "district_code": 5805,
    "province_code": 58
  },
  {
    "sub_district": "แม่ลาหลวง",
    "district": "แม่ลาน้อย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58120,
    "sub_district_code": 580502,
    "district_code": 5805,
    "province_code": 58
  },
  {
    "sub_district": "ท่าผาปุ้ม",
    "district": "แม่ลาน้อย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58120,
    "sub_district_code": 580503,
    "district_code": 5805,
    "province_code": 58
  },
  {
    "sub_district": "แม่โถ",
    "district": "แม่ลาน้อย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58120,
    "sub_district_code": 580504,
    "district_code": 5805,
    "province_code": 58
  },
  {
    "sub_district": "ห้วยห้อม",
    "district": "แม่ลาน้อย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58120,
    "sub_district_code": 580505,
    "district_code": 5805,
    "province_code": 58
  },
  {
    "sub_district": "แม่นาจาง",
    "district": "แม่ลาน้อย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58120,
    "sub_district_code": 580506,
    "district_code": 5805,
    "province_code": 58
  },
  {
    "sub_district": "ขุนแม่ลาน้อย",
    "district": "แม่ลาน้อย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58120,
    "sub_district_code": 580508,
    "district_code": 5805,
    "province_code": 58
  },
  {
    "sub_district": "สันติคีรี",
    "district": "แม่ลาน้อย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58120,
    "sub_district_code": 580507,
    "district_code": 5805,
    "province_code": 58
  },
  {
    "sub_district": "บ้านกาศ",
    "district": "แม่สะเรียง",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580401,
    "district_code": 5804,
    "province_code": 58
  },
  {
    "sub_district": "แม่สะเรียง",
    "district": "แม่สะเรียง",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580402,
    "district_code": 5804,
    "province_code": 58
  },
  {
    "sub_district": "แม่คง",
    "district": "แม่สะเรียง",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580403,
    "district_code": 5804,
    "province_code": 58
  },
  {
    "sub_district": "แม่เหาะ",
    "district": "แม่สะเรียง",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580404,
    "district_code": 5804,
    "province_code": 58
  },
  {
    "sub_district": "แม่ยวม",
    "district": "แม่สะเรียง",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580405,
    "district_code": 5804,
    "province_code": 58
  },
  {
    "sub_district": "เสาหิน",
    "district": "แม่สะเรียง",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580406,
    "district_code": 5804,
    "province_code": 58
  },
  {
    "sub_district": "ป่าแป๋",
    "district": "แม่สะเรียง",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580408,
    "district_code": 5804,
    "province_code": 58
  },
  {
    "sub_district": "สบเมย",
    "district": "สบเมย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580601,
    "district_code": 5806,
    "province_code": 58
  },
  {
    "sub_district": "แม่คะตวน",
    "district": "สบเมย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580602,
    "district_code": 5806,
    "province_code": 58
  },
  {
    "sub_district": "กองก๋อย",
    "district": "สบเมย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580603,
    "district_code": 5806,
    "province_code": 58
  },
  {
    "sub_district": "แม่สวด",
    "district": "สบเมย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580604,
    "district_code": 5806,
    "province_code": 58
  },
  {
    "sub_district": "ป่าโปง",
    "district": "สบเมย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580605,
    "district_code": 5806,
    "province_code": 58
  },
  {
    "sub_district": "แม่สามแลบ",
    "district": "สบเมย",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58110,
    "sub_district_code": 580606,
    "district_code": 5806,
    "province_code": 58
  },
  {
    "sub_district": "สบป่อง",
    "district": "ปางมะผ้า",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58150,
    "sub_district_code": 580701,
    "district_code": 5807,
    "province_code": 58
  },
  {
    "sub_district": "ปางมะผ้า",
    "district": "ปางมะผ้า",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58150,
    "sub_district_code": 580702,
    "district_code": 5807,
    "province_code": 58
  },
  {
    "sub_district": "ถ้ำลอด",
    "district": "ปางมะผ้า",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58150,
    "sub_district_code": 580703,
    "district_code": 5807,
    "province_code": 58
  },
  {
    "sub_district": "นาปู่ป้อม",
    "district": "ปางมะผ้า",
    "province": "แม่ฮ่องสอน",
    "zipcode": 58150,
    "sub_district_code": 580704,
    "district_code": 5807,
    "province_code": 58
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350101,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "น้ำคำใหญ่",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350102,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "ตาดทอง",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350103,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "สำราญ",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350104,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "ค้อเหนือ",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350105,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "ดู่ทุ่ง",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350106,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "เดิด",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350107,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "ขั้นไดใหญ่",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350108,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "ทุ่งแต้",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350109,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "สิงห์",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350110,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "นาสะไมย์",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350111,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "เขื่องคำ",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350112,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "หนองหิน",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350113,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "หนองคู",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350114,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "ขุมเงิน",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350115,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "ทุ่งนางโอก",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350116,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "หนองเรือ",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350117,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "หนองเป็ด",
    "district": "เมืองยโสธร",
    "province": "ยโสธร",
    "zipcode": 35000,
    "sub_district_code": 350118,
    "district_code": 3501,
    "province_code": 35
  },
  {
    "sub_district": "กุดชุม",
    "district": "กุดชุม",
    "province": "ยโสธร",
    "zipcode": 35140,
    "sub_district_code": 350301,
    "district_code": 3503,
    "province_code": 35
  },
  {
    "sub_district": "โนนเปือย",
    "district": "กุดชุม",
    "province": "ยโสธร",
    "zipcode": 35140,
    "sub_district_code": 350302,
    "district_code": 3503,
    "province_code": 35
  },
  {
    "sub_district": "กำแมด",
    "district": "กุดชุม",
    "province": "ยโสธร",
    "zipcode": 35140,
    "sub_district_code": 350303,
    "district_code": 3503,
    "province_code": 35
  },
  {
    "sub_district": "นาโส่",
    "district": "กุดชุม",
    "province": "ยโสธร",
    "zipcode": 35140,
    "sub_district_code": 350304,
    "district_code": 3503,
    "province_code": 35
  },
  {
    "sub_district": "ห้วยแก้ง",
    "district": "กุดชุม",
    "province": "ยโสธร",
    "zipcode": 35140,
    "sub_district_code": 350305,
    "district_code": 3503,
    "province_code": 35
  },
  {
    "sub_district": "หนองหมี",
    "district": "กุดชุม",
    "province": "ยโสธร",
    "zipcode": 35140,
    "sub_district_code": 350306,
    "district_code": 3503,
    "province_code": 35
  },
  {
    "sub_district": "โพนงาม",
    "district": "กุดชุม",
    "province": "ยโสธร",
    "zipcode": 35140,
    "sub_district_code": 350307,
    "district_code": 3503,
    "province_code": 35
  },
  {
    "sub_district": "คำน้ำสร้าง",
    "district": "กุดชุม",
    "province": "ยโสธร",
    "zipcode": 35140,
    "sub_district_code": 350308,
    "district_code": 3503,
    "province_code": 35
  },
  {
    "sub_district": "หนองแหน",
    "district": "กุดชุม",
    "province": "ยโสธร",
    "zipcode": 35140,
    "sub_district_code": 350309,
    "district_code": 3503,
    "province_code": 35
  },
  {
    "sub_district": "ลุมพุก",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35110,
    "sub_district_code": 350401,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "ย่อ",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35110,
    "sub_district_code": 350402,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "สงเปือย",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35110,
    "sub_district_code": 350403,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "โพนทัน",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35110,
    "sub_district_code": 350404,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "ทุ่งมน",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35110,
    "sub_district_code": 350405,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "นาคำ",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35180,
    "sub_district_code": 350406,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "ดงแคนใหญ่",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35180,
    "sub_district_code": 350407,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "กู่จาน",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35110,
    "sub_district_code": 350408,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "นาแก",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35180,
    "sub_district_code": 350409,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "กุดกุง",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35110,
    "sub_district_code": 350410,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "เหล่าไฮ",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35110,
    "sub_district_code": 350411,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "แคนน้อย",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35180,
    "sub_district_code": 350412,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "คำเขื่อนแก้ว",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35110,
    "sub_district_code": 350413,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "ดงเจริญ",
    "district": "คำเขื่อนแก้ว",
    "province": "ยโสธร",
    "zipcode": 35110,
    "sub_district_code": 350414,
    "district_code": 3504,
    "province_code": 35
  },
  {
    "sub_district": "โพธิ์ไทร",
    "district": "ป่าติ้ว",
    "province": "ยโสธร",
    "zipcode": 35150,
    "sub_district_code": 350501,
    "district_code": 3505,
    "province_code": 35
  },
  {
    "sub_district": "กระจาย",
    "district": "ป่าติ้ว",
    "province": "ยโสธร",
    "zipcode": 35150,
    "sub_district_code": 350502,
    "district_code": 3505,
    "province_code": 35
  },
  {
    "sub_district": "โคกนาโก",
    "district": "ป่าติ้ว",
    "province": "ยโสธร",
    "zipcode": 35150,
    "sub_district_code": 350503,
    "district_code": 3505,
    "province_code": 35
  },
  {
    "sub_district": "เชียงเพ็ง",
    "district": "ป่าติ้ว",
    "province": "ยโสธร",
    "zipcode": 35150,
    "sub_district_code": 350504,
    "district_code": 3505,
    "province_code": 35
  },
  {
    "sub_district": "ศรีฐาน",
    "district": "ป่าติ้ว",
    "province": "ยโสธร",
    "zipcode": 35150,
    "sub_district_code": 350505,
    "district_code": 3505,
    "province_code": 35
  },
  {
    "sub_district": "ฟ้าหยาด",
    "district": "มหาชนะชัย",
    "province": "ยโสธร",
    "zipcode": 35130,
    "sub_district_code": 350601,
    "district_code": 3506,
    "province_code": 35
  },
  {
    "sub_district": "หัวเมือง",
    "district": "มหาชนะชัย",
    "province": "ยโสธร",
    "zipcode": 35130,
    "sub_district_code": 350602,
    "district_code": 3506,
    "province_code": 35
  },
  {
    "sub_district": "คูเมือง",
    "district": "มหาชนะชัย",
    "province": "ยโสธร",
    "zipcode": 35130,
    "sub_district_code": 350603,
    "district_code": 3506,
    "province_code": 35
  },
  {
    "sub_district": "ผือฮี",
    "district": "มหาชนะชัย",
    "province": "ยโสธร",
    "zipcode": 35130,
    "sub_district_code": 350604,
    "district_code": 3506,
    "province_code": 35
  },
  {
    "sub_district": "บากเรือ",
    "district": "มหาชนะชัย",
    "province": "ยโสธร",
    "zipcode": 35130,
    "sub_district_code": 350605,
    "district_code": 3506,
    "province_code": 35
  },
  {
    "sub_district": "ม่วง",
    "district": "มหาชนะชัย",
    "province": "ยโสธร",
    "zipcode": 35130,
    "sub_district_code": 350606,
    "district_code": 3506,
    "province_code": 35
  },
  {
    "sub_district": "โนนทราย",
    "district": "มหาชนะชัย",
    "province": "ยโสธร",
    "zipcode": 35130,
    "sub_district_code": 350607,
    "district_code": 3506,
    "province_code": 35
  },
  {
    "sub_district": "บึงแก",
    "district": "มหาชนะชัย",
    "province": "ยโสธร",
    "zipcode": 35130,
    "sub_district_code": 350608,
    "district_code": 3506,
    "province_code": 35
  },
  {
    "sub_district": "พระเสาร์",
    "district": "มหาชนะชัย",
    "province": "ยโสธร",
    "zipcode": 35130,
    "sub_district_code": 350609,
    "district_code": 3506,
    "province_code": 35
  },
  {
    "sub_district": "สงยาง",
    "district": "มหาชนะชัย",
    "province": "ยโสธร",
    "zipcode": 35130,
    "sub_district_code": 350610,
    "district_code": 3506,
    "province_code": 35
  },
  {
    "sub_district": "น้ำคำ",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350895,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "บุ่งค้า",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350802,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "สวาท",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350803,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "ส้มผ่อ",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350896,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "ห้องแซง",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350805,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "สามัคคี",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350806,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "กุดเชียงหมี",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350807,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "คำเตย",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350897,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "คำไผ่",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350898,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "สามแยก",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350810,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "กุดแห่",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350811,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "โคกสำราญ",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350812,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "สร้างมิ่ง",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350813,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "ศรีแก้ว",
    "district": "เลิงนกทา",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350814,
    "district_code": 3508,
    "province_code": 35
  },
  {
    "sub_district": "ทรายมูล",
    "district": "ทรายมูล",
    "province": "ยโสธร",
    "zipcode": 35170,
    "sub_district_code": 350201,
    "district_code": 3502,
    "province_code": 35
  },
  {
    "sub_district": "ดู่ลาด",
    "district": "ทรายมูล",
    "province": "ยโสธร",
    "zipcode": 35170,
    "sub_district_code": 350202,
    "district_code": 3502,
    "province_code": 35
  },
  {
    "sub_district": "ดงมะไฟ",
    "district": "ทรายมูล",
    "province": "ยโสธร",
    "zipcode": 35170,
    "sub_district_code": 350203,
    "district_code": 3502,
    "province_code": 35
  },
  {
    "sub_district": "นาเวียง",
    "district": "ทรายมูล",
    "province": "ยโสธร",
    "zipcode": 35170,
    "sub_district_code": 350204,
    "district_code": 3502,
    "province_code": 35
  },
  {
    "sub_district": "ไผ่",
    "district": "ทรายมูล",
    "province": "ยโสธร",
    "zipcode": 35170,
    "sub_district_code": 350205,
    "district_code": 3502,
    "province_code": 35
  },
  {
    "sub_district": "ฟ้าห่วน",
    "district": "ค้อวัง",
    "province": "ยโสธร",
    "zipcode": 35160,
    "sub_district_code": 350701,
    "district_code": 3507,
    "province_code": 35
  },
  {
    "sub_district": "กุดน้ำใส",
    "district": "ค้อวัง",
    "province": "ยโสธร",
    "zipcode": 35160,
    "sub_district_code": 350702,
    "district_code": 3507,
    "province_code": 35
  },
  {
    "sub_district": "น้ำอ้อม",
    "district": "ค้อวัง",
    "province": "ยโสธร",
    "zipcode": 35160,
    "sub_district_code": 350703,
    "district_code": 3507,
    "province_code": 35
  },
  {
    "sub_district": "ค้อวัง",
    "district": "ค้อวัง",
    "province": "ยโสธร",
    "zipcode": 35160,
    "sub_district_code": 350704,
    "district_code": 3507,
    "province_code": 35
  },
  {
    "sub_district": "ไทยเจริญ",
    "district": "ไทยเจริญ",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350901,
    "district_code": 3509,
    "province_code": 35
  },
  {
    "sub_district": "น้ำคำ",
    "district": "ไทยเจริญ",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350902,
    "district_code": 3509,
    "province_code": 35
  },
  {
    "sub_district": "คำไผ่",
    "district": "ไทยเจริญ",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350905,
    "district_code": 3509,
    "province_code": 35
  },
  {
    "sub_district": "ส้มผ่อ",
    "district": "ไทยเจริญ",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350903,
    "district_code": 3509,
    "province_code": 35
  },
  {
    "sub_district": "คำเตย",
    "district": "ไทยเจริญ",
    "province": "ยโสธร",
    "zipcode": 35120,
    "sub_district_code": 350904,
    "district_code": 3509,
    "province_code": 35
  },
  {
    "sub_district": "สะเตง",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950101,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "บุดี",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950102,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "ยุโป",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950103,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "ลิดล",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95160,
    "sub_district_code": 950104,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "ยะลา",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950106,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "ท่าสาป",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950108,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "ลำใหม่",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95160,
    "sub_district_code": 950109,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "หน้าถ้ำ",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950110,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "ลำพะยา",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95160,
    "sub_district_code": 950111,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "เปาะเส้ง",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950112,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "พร่อน",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95160,
    "sub_district_code": 950114,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "บันนังสาเรง",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950115,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "สะเตงนอก",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950116,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "ตาเซะ",
    "district": "เมืองยะลา",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950118,
    "district_code": 9501,
    "province_code": 95
  },
  {
    "sub_district": "บันนังสตา",
    "district": "บันนังสตา",
    "province": "ยะลา",
    "zipcode": 95130,
    "sub_district_code": 950301,
    "district_code": 9503,
    "province_code": 95
  },
  {
    "sub_district": "บาเจาะ",
    "district": "บันนังสตา",
    "province": "ยะลา",
    "zipcode": 95130,
    "sub_district_code": 950302,
    "district_code": 9503,
    "province_code": 95
  },
  {
    "sub_district": "ตาเนาะปูเต๊ะ",
    "district": "บันนังสตา",
    "province": "ยะลา",
    "zipcode": 95130,
    "sub_district_code": 950303,
    "district_code": 9503,
    "province_code": 95
  },
  {
    "sub_district": "ถ้ำทะลุ",
    "district": "บันนังสตา",
    "province": "ยะลา",
    "zipcode": 95130,
    "sub_district_code": 950304,
    "district_code": 9503,
    "province_code": 95
  },
  {
    "sub_district": "ตลิ่งชัน",
    "district": "บันนังสตา",
    "province": "ยะลา",
    "zipcode": 95130,
    "sub_district_code": 950305,
    "district_code": 9503,
    "province_code": 95
  },
  {
    "sub_district": "เขื่อนบางลาง",
    "district": "บันนังสตา",
    "province": "ยะลา",
    "zipcode": 95130,
    "sub_district_code": 950306,
    "district_code": 9503,
    "province_code": 95
  },
  {
    "sub_district": "เบตง",
    "district": "เบตง",
    "province": "ยะลา",
    "zipcode": 95110,
    "sub_district_code": 950201,
    "district_code": 9502,
    "province_code": 95
  },
  {
    "sub_district": "ยะรม",
    "district": "เบตง",
    "province": "ยะลา",
    "zipcode": 95110,
    "sub_district_code": 950202,
    "district_code": 9502,
    "province_code": 95
  },
  {
    "sub_district": "ตาเนาะแมเราะ",
    "district": "เบตง",
    "province": "ยะลา",
    "zipcode": 95110,
    "sub_district_code": 950203,
    "district_code": 9502,
    "province_code": 95
  },
  {
    "sub_district": "อัยเยอร์เวง",
    "district": "เบตง",
    "province": "ยะลา",
    "zipcode": 95110,
    "sub_district_code": 950204,
    "district_code": 9502,
    "province_code": 95
  },
  {
    "sub_district": "ธารน้ำทิพย์",
    "district": "เบตง",
    "province": "ยะลา",
    "zipcode": 95110,
    "sub_district_code": 950205,
    "district_code": 9502,
    "province_code": 95
  },
  {
    "sub_district": "ยะหา",
    "district": "ยะหา",
    "province": "ยะลา",
    "zipcode": 95120,
    "sub_district_code": 950501,
    "district_code": 9505,
    "province_code": 95
  },
  {
    "sub_district": "ละแอ",
    "district": "ยะหา",
    "province": "ยะลา",
    "zipcode": 95120,
    "sub_district_code": 950502,
    "district_code": 9505,
    "province_code": 95
  },
  {
    "sub_district": "ปะแต",
    "district": "ยะหา",
    "province": "ยะลา",
    "zipcode": 95120,
    "sub_district_code": 950503,
    "district_code": 9505,
    "province_code": 95
  },
  {
    "sub_district": "บาโร๊ะ",
    "district": "ยะหา",
    "province": "ยะลา",
    "zipcode": 95120,
    "sub_district_code": 950504,
    "district_code": 9505,
    "province_code": 95
  },
  {
    "sub_district": "ตาชี",
    "district": "ยะหา",
    "province": "ยะลา",
    "zipcode": 95120,
    "sub_district_code": 950506,
    "district_code": 9505,
    "province_code": 95
  },
  {
    "sub_district": "บาโงยซิแน",
    "district": "ยะหา",
    "province": "ยะลา",
    "zipcode": 95120,
    "sub_district_code": 950507,
    "district_code": 9505,
    "province_code": 95
  },
  {
    "sub_district": "กาตอง",
    "district": "ยะหา",
    "province": "ยะลา",
    "zipcode": 95120,
    "sub_district_code": 950508,
    "district_code": 9505,
    "province_code": 95
  },
  {
    "sub_district": "กายูบอเกาะ",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950601,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "กาลูปัง",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950602,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "กาลอ",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950603,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "กอตอตือร๊ะ",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950604,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "โกตาบารู",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950605,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "เกะรอ",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950606,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "จะกว๊ะ",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950607,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "ท่าธง",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950608,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "เนินงาม",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950609,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "บาลอ",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950610,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "บาโงย",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950611,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "บือมัง",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950612,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "ยะต๊ะ",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950613,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "วังพญา",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950614,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "อาซ่อง",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950615,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "ตะโล๊ะหะลอ",
    "district": "รามัน",
    "province": "ยะลา",
    "zipcode": 95140,
    "sub_district_code": 950616,
    "district_code": 9506,
    "province_code": 95
  },
  {
    "sub_district": "แม่หวาด",
    "district": "ธารโต",
    "province": "ยะลา",
    "zipcode": 95170,
    "sub_district_code": 950403,
    "district_code": 9504,
    "province_code": 95
  },
  {
    "sub_district": "ธารโต",
    "district": "ธารโต",
    "province": "ยะลา",
    "zipcode": 95150,
    "sub_district_code": 950401,
    "district_code": 9504,
    "province_code": 95
  },
  {
    "sub_district": "บ้านแหร",
    "district": "ธารโต",
    "province": "ยะลา",
    "zipcode": 95150,
    "sub_district_code": 950402,
    "district_code": 9504,
    "province_code": 95
  },
  {
    "sub_district": "คีรีเขต",
    "district": "ธารโต",
    "province": "ยะลา",
    "zipcode": 95150,
    "sub_district_code": 950404,
    "district_code": 9504,
    "province_code": 95
  },
  {
    "sub_district": "กาบัง",
    "district": "กาบัง",
    "province": "ยะลา",
    "zipcode": 95120,
    "sub_district_code": 950701,
    "district_code": 9507,
    "province_code": 95
  },
  {
    "sub_district": "บาละ",
    "district": "กาบัง",
    "province": "ยะลา",
    "zipcode": 95120,
    "sub_district_code": 950702,
    "district_code": 9507,
    "province_code": 95
  },
  {
    "sub_district": "สะเอะ",
    "district": "กรงปินัง",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950802,
    "district_code": 9508,
    "province_code": 95
  },
  {
    "sub_district": "กรงปินัง",
    "district": "กรงปินัง",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950801,
    "district_code": 9508,
    "province_code": 95
  },
  {
    "sub_district": "ห้วยกระทิง",
    "district": "กรงปินัง",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950803,
    "district_code": 9508,
    "province_code": 95
  },
  {
    "sub_district": "ปุโรง",
    "district": "กรงปินัง",
    "province": "ยะลา",
    "zipcode": 95000,
    "sub_district_code": 950804,
    "district_code": 9508,
    "province_code": 95
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450101,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "รอบเมือง",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450102,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "เหนือเมือง",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450103,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "ขอนแก่น",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450104,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "นาโพธิ์",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450105,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "สะอาดสมบูรณ์",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450106,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "สีแก้ว",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450108,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "ปอภาร",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450109,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "โนนรัง",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450110,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "ดงสิงห์",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450197,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "ม่วงลาด",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450196,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "จังหาร",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450195,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "ดินดำ",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450194,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "หนองแก้ว",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450117,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "หนองแวง",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450118,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "หน้าเมือง",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450193,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "เมืองน้อย",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450192,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "ดงลาน",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450120,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "แคนใหญ่",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450123,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "โพธิ์ทอง",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450190,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "โนนตาล",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450124,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "เมืองทอง",
    "district": "เมืองร้อยเอ็ด",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 450125,
    "district_code": 4501,
    "province_code": 45
  },
  {
    "sub_district": "เกษตรวิสัย",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450201,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "เมืองบัว",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450202,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "เหล่าหลวง",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450203,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "สิงห์โคก",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450204,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "ดงครั่งใหญ่",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450205,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "บ้านฝาง",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450206,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "หนองแวง",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450207,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "กำแพง",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450208,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "กู่กาสิงห์",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450209,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "น้ำอ้อม",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450210,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "โนนสว่าง",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450211,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "ทุ่งทอง",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450212,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "ดงครั่งน้อย",
    "district": "เกษตรวิสัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45150,
    "sub_district_code": 450213,
    "district_code": 4502,
    "province_code": 45
  },
  {
    "sub_district": "หัวช้าง",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450401,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "หนองผือ",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450402,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "เมืองหงส์",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450403,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "โคกล่าม",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450404,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "น้ำใส",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450405,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "ดงแดง",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450406,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "ดงกลาง",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450407,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "ป่าสังข์",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450408,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "อีง่อง",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450409,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "ลิ้นฟ้า",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450410,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "ดู่น้อย",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450411,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "ศรีโคตร",
    "district": "จตุรพักตรพิมาน",
    "province": "ร้อยเอ็ด",
    "zipcode": 45180,
    "sub_district_code": 450412,
    "district_code": 4504,
    "province_code": 45
  },
  {
    "sub_district": "นิเวศน์",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450501,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "ธงธานี",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450502,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "หนองไผ่",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450503,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "ธวัชบุรี",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450504,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "หมูม้น",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450598,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "อุ่มเม้า",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450506,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "มะอึ",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450507,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "เหล่า",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450597,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "มะบ้า",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450596,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "เขวาทุ่ง",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450510,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "พระธาตุ",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450595,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "บึงงาม",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450594,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "บ้านเขือง",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450593,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "พระเจ้า",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450592,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "ไพศาล",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450515,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "เทอดไทย",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450591,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "เชียงขวัญ",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450590,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "ทุ่งเขาหลวง",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450589,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "บึงนคร",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450520,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "เมืองน้อย",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450517,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "ราชธานี",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450522,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "หนองพอก",
    "district": "ธวัชบุรี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 450524,
    "district_code": 4505,
    "province_code": 45
  },
  {
    "sub_district": "บัวแดง",
    "district": "ปทุมรัตต์",
    "province": "ร้อยเอ็ด",
    "zipcode": 45190,
    "sub_district_code": 450301,
    "district_code": 4503,
    "province_code": 45
  },
  {
    "sub_district": "ดอกล้ำ",
    "district": "ปทุมรัตต์",
    "province": "ร้อยเอ็ด",
    "zipcode": 45190,
    "sub_district_code": 450302,
    "district_code": 4503,
    "province_code": 45
  },
  {
    "sub_district": "หนองแคน",
    "district": "ปทุมรัตต์",
    "province": "ร้อยเอ็ด",
    "zipcode": 45190,
    "sub_district_code": 450303,
    "district_code": 4503,
    "province_code": 45
  },
  {
    "sub_district": "โพนสูง",
    "district": "ปทุมรัตต์",
    "province": "ร้อยเอ็ด",
    "zipcode": 45190,
    "sub_district_code": 450304,
    "district_code": 4503,
    "province_code": 45
  },
  {
    "sub_district": "โนนสวรรค์",
    "district": "ปทุมรัตต์",
    "province": "ร้อยเอ็ด",
    "zipcode": 45190,
    "sub_district_code": 450305,
    "district_code": 4503,
    "province_code": 45
  },
  {
    "sub_district": "สระบัว",
    "district": "ปทุมรัตต์",
    "province": "ร้อยเอ็ด",
    "zipcode": 45190,
    "sub_district_code": 450306,
    "district_code": 4503,
    "province_code": 45
  },
  {
    "sub_district": "โนนสง่า",
    "district": "ปทุมรัตต์",
    "province": "ร้อยเอ็ด",
    "zipcode": 45190,
    "sub_district_code": 450307,
    "district_code": 4503,
    "province_code": 45
  },
  {
    "sub_district": "ขี้เหล็ก",
    "district": "ปทุมรัตต์",
    "province": "ร้อยเอ็ด",
    "zipcode": 45190,
    "sub_district_code": 450308,
    "district_code": 4503,
    "province_code": 45
  },
  {
    "sub_district": "พนมไพร",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450601,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "แสนสุข",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450602,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "กุดน้ำใส",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450603,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "หนองทัพไทย",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450604,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "โพธิ์ใหญ่",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450605,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "วารีสวัสดิ์",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450606,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "โคกสว่าง",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450607,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "เด่นราษฎร์",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450697,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "ดูกอึ่ง",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450696,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "โพธิ์ชัย",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450611,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "นานวล",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450612,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "คำไฮ",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450613,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "สระแก้ว",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450614,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "ค้อใหญ่",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450615,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "ชานุวรรณ",
    "district": "พนมไพร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 450617,
    "district_code": 4506,
    "province_code": 45
  },
  {
    "sub_district": "แวง",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450701,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "โคกกกม่วง",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450702,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "นาอุดม",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450703,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "สว่าง",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450704,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "หนองใหญ่",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450705,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "โพธิ์ทอง",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450706,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "โนนชัยศรี",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450707,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "โพธิ์ศรีสว่าง",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450708,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "อุ่มเม่า",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450709,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "คำนาดี",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450710,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "พรมสวรรค์",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450711,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "สระนกแก้ว",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450712,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "วังสามัคคี",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450713,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "โคกสูง",
    "district": "โพนทอง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45110,
    "sub_district_code": 450714,
    "district_code": 4507,
    "province_code": 45
  },
  {
    "sub_district": "สระคู",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451101,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "ดอกไม้",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451102,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "นาใหญ่",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451103,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "หินกอง",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451104,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "เมืองทุ่ง",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451105,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "หัวโทน",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451106,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "บ่อพันขัน",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451107,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "ทุ่งหลวง",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451108,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "หัวช้าง",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451109,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "น้ำคำ",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451110,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "ห้วยหินลาด",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451111,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "ช้างเผือก",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451112,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "ทุ่งกุลา",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451113,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "สุวรรณภูมิ",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451198,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "ทุ่งศรีเมือง",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451114,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "จำปาขัน",
    "district": "สุวรรณภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45130,
    "sub_district_code": 451115,
    "district_code": 4511,
    "province_code": 45
  },
  {
    "sub_district": "กลาง",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451001,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "นางาม",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451002,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "เมืองไพร",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451003,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "นาแซง",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451004,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "นาเมือง",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451005,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "วังหลวง",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451006,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "ท่าม่วง",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451007,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "ขวาว",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451008,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "โพธิ์ทอง",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451009,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "ภูเงิน",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451010,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "เกาะแก้ว",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451011,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "นาเลิง",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451012,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "เหล่าน้อย",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451013,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "ศรีวิลัย",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451014,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "หนองหลวง",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451015,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "พรสวรรค์",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451016,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "ขวัญเมือง",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451017,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "บึงเกลือ",
    "district": "เสลภูมิ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45120,
    "sub_district_code": 451018,
    "district_code": 4510,
    "province_code": 45
  },
  {
    "sub_district": "หนองพอก",
    "district": "หนองพอก",
    "province": "ร้อยเอ็ด",
    "zipcode": 45210,
    "sub_district_code": 450901,
    "district_code": 4509,
    "province_code": 45
  },
  {
    "sub_district": "บึงงาม",
    "district": "หนองพอก",
    "province": "ร้อยเอ็ด",
    "zipcode": 45210,
    "sub_district_code": 450902,
    "district_code": 4509,
    "province_code": 45
  },
  {
    "sub_district": "ภูเขาทอง",
    "district": "หนองพอก",
    "province": "ร้อยเอ็ด",
    "zipcode": 45210,
    "sub_district_code": 450903,
    "district_code": 4509,
    "province_code": 45
  },
  {
    "sub_district": "กกโพธิ์",
    "district": "หนองพอก",
    "province": "ร้อยเอ็ด",
    "zipcode": 45210,
    "sub_district_code": 450904,
    "district_code": 4509,
    "province_code": 45
  },
  {
    "sub_district": "โคกสว่าง",
    "district": "หนองพอก",
    "province": "ร้อยเอ็ด",
    "zipcode": 45210,
    "sub_district_code": 450905,
    "district_code": 4509,
    "province_code": 45
  },
  {
    "sub_district": "หนองขุ่นใหญ่",
    "district": "หนองพอก",
    "province": "ร้อยเอ็ด",
    "zipcode": 45210,
    "sub_district_code": 450906,
    "district_code": 4509,
    "province_code": 45
  },
  {
    "sub_district": "รอบเมือง",
    "district": "หนองพอก",
    "province": "ร้อยเอ็ด",
    "zipcode": 45210,
    "sub_district_code": 450907,
    "district_code": 4509,
    "province_code": 45
  },
  {
    "sub_district": "ผาน้ำย้อย",
    "district": "หนองพอก",
    "province": "ร้อยเอ็ด",
    "zipcode": 45210,
    "sub_district_code": 450908,
    "district_code": 4509,
    "province_code": 45
  },
  {
    "sub_district": "ท่าสีดา",
    "district": "หนองพอก",
    "province": "ร้อยเอ็ด",
    "zipcode": 45210,
    "sub_district_code": 450909,
    "district_code": 4509,
    "province_code": 45
  },
  {
    "sub_district": "อาจสามารถ",
    "district": "อาจสามารถ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45160,
    "sub_district_code": 451401,
    "district_code": 4514,
    "province_code": 45
  },
  {
    "sub_district": "โพนเมือง",
    "district": "อาจสามารถ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45160,
    "sub_district_code": 451402,
    "district_code": 4514,
    "province_code": 45
  },
  {
    "sub_district": "บ้านแจ้ง",
    "district": "อาจสามารถ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45160,
    "sub_district_code": 451403,
    "district_code": 4514,
    "province_code": 45
  },
  {
    "sub_district": "หน่อม",
    "district": "อาจสามารถ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45160,
    "sub_district_code": 451404,
    "district_code": 4514,
    "province_code": 45
  },
  {
    "sub_district": "หนองหมื่นถ่าน",
    "district": "อาจสามารถ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45160,
    "sub_district_code": 451405,
    "district_code": 4514,
    "province_code": 45
  },
  {
    "sub_district": "หนองขาม",
    "district": "อาจสามารถ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45160,
    "sub_district_code": 451406,
    "district_code": 4514,
    "province_code": 45
  },
  {
    "sub_district": "โหรา",
    "district": "อาจสามารถ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45160,
    "sub_district_code": 451407,
    "district_code": 4514,
    "province_code": 45
  },
  {
    "sub_district": "หนองบัว",
    "district": "อาจสามารถ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45160,
    "sub_district_code": 451408,
    "district_code": 4514,
    "province_code": 45
  },
  {
    "sub_district": "ขี้เหล็ก",
    "district": "อาจสามารถ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45160,
    "sub_district_code": 451409,
    "district_code": 4514,
    "province_code": 45
  },
  {
    "sub_district": "บ้านดู่",
    "district": "อาจสามารถ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45160,
    "sub_district_code": 451410,
    "district_code": 4514,
    "province_code": 45
  },
  {
    "sub_district": "หนองผือ",
    "district": "เมืองสรวง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45220,
    "sub_district_code": 451201,
    "district_code": 4512,
    "province_code": 45
  },
  {
    "sub_district": "หนองหิน",
    "district": "เมืองสรวง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45220,
    "sub_district_code": 451202,
    "district_code": 4512,
    "province_code": 45
  },
  {
    "sub_district": "คูเมือง",
    "district": "เมืองสรวง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45220,
    "sub_district_code": 451203,
    "district_code": 4512,
    "province_code": 45
  },
  {
    "sub_district": "กกกุง",
    "district": "เมืองสรวง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45220,
    "sub_district_code": 451204,
    "district_code": 4512,
    "province_code": 45
  },
  {
    "sub_district": "เมืองสรวง",
    "district": "เมืองสรวง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45220,
    "sub_district_code": 451205,
    "district_code": 4512,
    "province_code": 45
  },
  {
    "sub_district": "ขามเปี้ย",
    "district": "โพธิ์ชัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45230,
    "sub_district_code": 450801,
    "district_code": 4508,
    "province_code": 45
  },
  {
    "sub_district": "เชียงใหม่",
    "district": "โพธิ์ชัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45230,
    "sub_district_code": 450802,
    "district_code": 4508,
    "province_code": 45
  },
  {
    "sub_district": "บัวคำ",
    "district": "โพธิ์ชัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45230,
    "sub_district_code": 450803,
    "district_code": 4508,
    "province_code": 45
  },
  {
    "sub_district": "อัคคะคำ",
    "district": "โพธิ์ชัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45230,
    "sub_district_code": 450804,
    "district_code": 4508,
    "province_code": 45
  },
  {
    "sub_district": "สะอาด",
    "district": "โพธิ์ชัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45230,
    "sub_district_code": 450805,
    "district_code": 4508,
    "province_code": 45
  },
  {
    "sub_district": "คำพอุง",
    "district": "โพธิ์ชัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45230,
    "sub_district_code": 450806,
    "district_code": 4508,
    "province_code": 45
  },
  {
    "sub_district": "หนองตาไก้",
    "district": "โพธิ์ชัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45230,
    "sub_district_code": 450807,
    "district_code": 4508,
    "province_code": 45
  },
  {
    "sub_district": "ดอนโอง",
    "district": "โพธิ์ชัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45230,
    "sub_district_code": 450808,
    "district_code": 4508,
    "province_code": 45
  },
  {
    "sub_district": "โพธิ์ศรี",
    "district": "โพธิ์ชัย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45230,
    "sub_district_code": 450809,
    "district_code": 4508,
    "province_code": 45
  },
  {
    "sub_district": "โพนทราย",
    "district": "โพนทราย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45240,
    "sub_district_code": 451301,
    "district_code": 4513,
    "province_code": 45
  },
  {
    "sub_district": "สามขา",
    "district": "โพนทราย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45240,
    "sub_district_code": 451302,
    "district_code": 4513,
    "province_code": 45
  },
  {
    "sub_district": "ศรีสว่าง",
    "district": "โพนทราย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45240,
    "sub_district_code": 451303,
    "district_code": 4513,
    "province_code": 45
  },
  {
    "sub_district": "ยางคำ",
    "district": "โพนทราย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45240,
    "sub_district_code": 451304,
    "district_code": 4513,
    "province_code": 45
  },
  {
    "sub_district": "ท่าหาดยาว",
    "district": "โพนทราย",
    "province": "ร้อยเอ็ด",
    "zipcode": 45240,
    "sub_district_code": 451305,
    "district_code": 4513,
    "province_code": 45
  },
  {
    "sub_district": "เมยวดี",
    "district": "เมยวดี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45250,
    "sub_district_code": 451501,
    "district_code": 4515,
    "province_code": 45
  },
  {
    "sub_district": "ชุมพร",
    "district": "เมยวดี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45250,
    "sub_district_code": 451502,
    "district_code": 4515,
    "province_code": 45
  },
  {
    "sub_district": "บุ่งเลิศ",
    "district": "เมยวดี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45250,
    "sub_district_code": 451503,
    "district_code": 4515,
    "province_code": 45
  },
  {
    "sub_district": "ชมสะอาด",
    "district": "เมยวดี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45250,
    "sub_district_code": 451504,
    "district_code": 4515,
    "province_code": 45
  },
  {
    "sub_district": "โพธิ์ทอง",
    "district": "ศรีสมเด็จ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451601,
    "district_code": 4516,
    "province_code": 45
  },
  {
    "sub_district": "ศรีสมเด็จ",
    "district": "ศรีสมเด็จ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451602,
    "district_code": 4516,
    "province_code": 45
  },
  {
    "sub_district": "เมืองเปลือย",
    "district": "ศรีสมเด็จ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451603,
    "district_code": 4516,
    "province_code": 45
  },
  {
    "sub_district": "หนองใหญ่",
    "district": "ศรีสมเด็จ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451604,
    "district_code": 4516,
    "province_code": 45
  },
  {
    "sub_district": "สวนจิก",
    "district": "ศรีสมเด็จ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45280,
    "sub_district_code": 451605,
    "district_code": 4516,
    "province_code": 45
  },
  {
    "sub_district": "โพธิ์สัย",
    "district": "ศรีสมเด็จ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45280,
    "sub_district_code": 451606,
    "district_code": 4516,
    "province_code": 45
  },
  {
    "sub_district": "บ้านบาก",
    "district": "ศรีสมเด็จ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451608,
    "district_code": 4516,
    "province_code": 45
  },
  {
    "sub_district": "หนองแวงควง",
    "district": "ศรีสมเด็จ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451607,
    "district_code": 4516,
    "province_code": 45
  },
  {
    "sub_district": "จังหาร",
    "district": "จังหาร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451704,
    "district_code": 4517,
    "province_code": 45
  },
  {
    "sub_district": "ม่วงลาด",
    "district": "จังหาร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451703,
    "district_code": 4517,
    "province_code": 45
  },
  {
    "sub_district": "ปาฝา",
    "district": "จังหาร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451702,
    "district_code": 4517,
    "province_code": 45
  },
  {
    "sub_district": "ดินดำ",
    "district": "จังหาร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451701,
    "district_code": 4517,
    "province_code": 45
  },
  {
    "sub_district": "ดงสิงห์",
    "district": "จังหาร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451705,
    "district_code": 4517,
    "province_code": 45
  },
  {
    "sub_district": "ผักแว่น",
    "district": "จังหาร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451707,
    "district_code": 4517,
    "province_code": 45
  },
  {
    "sub_district": "ยางใหญ่",
    "district": "จังหาร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451706,
    "district_code": 4517,
    "province_code": 45
  },
  {
    "sub_district": "แสนชาติ",
    "district": "จังหาร",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451708,
    "district_code": 4517,
    "province_code": 45
  },
  {
    "sub_district": "หมูม้น",
    "district": "เชียงขวัญ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 451805,
    "district_code": 4518,
    "province_code": 45
  },
  {
    "sub_district": "บ้านเขือง",
    "district": "เชียงขวัญ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451806,
    "district_code": 4518,
    "province_code": 45
  },
  {
    "sub_district": "เชียงขวัญ",
    "district": "เชียงขวัญ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451801,
    "district_code": 4518,
    "province_code": 45
  },
  {
    "sub_district": "พระธาตุ",
    "district": "เชียงขวัญ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451803,
    "district_code": 4518,
    "province_code": 45
  },
  {
    "sub_district": "พลับพลา",
    "district": "เชียงขวัญ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 451802,
    "district_code": 4518,
    "province_code": 45
  },
  {
    "sub_district": "พระเจ้า",
    "district": "เชียงขวัญ",
    "province": "ร้อยเอ็ด",
    "zipcode": 45000,
    "sub_district_code": 451804,
    "district_code": 4518,
    "province_code": 45
  },
  {
    "sub_district": "หนองฮี",
    "district": "หนองฮี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 451901,
    "district_code": 4519,
    "province_code": 45
  },
  {
    "sub_district": "สาวแห",
    "district": "หนองฮี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 451902,
    "district_code": 4519,
    "province_code": 45
  },
  {
    "sub_district": "ดูกอึ่ง",
    "district": "หนองฮี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 451903,
    "district_code": 4519,
    "province_code": 45
  },
  {
    "sub_district": "เด่นราษฎร์",
    "district": "หนองฮี",
    "province": "ร้อยเอ็ด",
    "zipcode": 45140,
    "sub_district_code": 451904,
    "district_code": 4519,
    "province_code": 45
  },
  {
    "sub_district": "ทุ่งเขาหลวง",
    "district": "ทุ่งเขาหลวง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 452001,
    "district_code": 4520,
    "province_code": 45
  },
  {
    "sub_district": "เทอดไทย",
    "district": "ทุ่งเขาหลวง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 452002,
    "district_code": 4520,
    "province_code": 45
  },
  {
    "sub_district": "บึงงาม",
    "district": "ทุ่งเขาหลวง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 452003,
    "district_code": 4520,
    "province_code": 45
  },
  {
    "sub_district": "มะบ้า",
    "district": "ทุ่งเขาหลวง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 452004,
    "district_code": 4520,
    "province_code": 45
  },
  {
    "sub_district": "เหล่า",
    "district": "ทุ่งเขาหลวง",
    "province": "ร้อยเอ็ด",
    "zipcode": 45170,
    "sub_district_code": 452005,
    "district_code": 4520,
    "province_code": 45
  },
  {
    "sub_district": "เขานิเวศน์",
    "district": "เมืองระนอง",
    "province": "ระนอง",
    "zipcode": 85000,
    "sub_district_code": 850101,
    "district_code": 8501,
    "province_code": 85
  },
  {
    "sub_district": "ราชกรูด",
    "district": "เมืองระนอง",
    "province": "ระนอง",
    "zipcode": 85000,
    "sub_district_code": 850102,
    "district_code": 8501,
    "province_code": 85
  },
  {
    "sub_district": "หงาว",
    "district": "เมืองระนอง",
    "province": "ระนอง",
    "zipcode": 85000,
    "sub_district_code": 850103,
    "district_code": 8501,
    "province_code": 85
  },
  {
    "sub_district": "บางริ้น",
    "district": "เมืองระนอง",
    "province": "ระนอง",
    "zipcode": 85000,
    "sub_district_code": 850104,
    "district_code": 8501,
    "province_code": 85
  },
  {
    "sub_district": "ปากน้ำ",
    "district": "เมืองระนอง",
    "province": "ระนอง",
    "zipcode": 85000,
    "sub_district_code": 850105,
    "district_code": 8501,
    "province_code": 85
  },
  {
    "sub_district": "บางนอน",
    "district": "เมืองระนอง",
    "province": "ระนอง",
    "zipcode": 85000,
    "sub_district_code": 850106,
    "district_code": 8501,
    "province_code": 85
  },
  {
    "sub_district": "หาดส้มแป้น",
    "district": "เมืองระนอง",
    "province": "ระนอง",
    "zipcode": 85000,
    "sub_district_code": 850107,
    "district_code": 8501,
    "province_code": 85
  },
  {
    "sub_district": "ทรายแดง",
    "district": "เมืองระนอง",
    "province": "ระนอง",
    "zipcode": 85130,
    "sub_district_code": 850108,
    "district_code": 8501,
    "province_code": 85
  },
  {
    "sub_district": "เกาะพยาม",
    "district": "เมืองระนอง",
    "province": "ระนอง",
    "zipcode": 85000,
    "sub_district_code": 850109,
    "district_code": 8501,
    "province_code": 85
  },
  {
    "sub_district": "น้ำจืด",
    "district": "กระบุรี",
    "province": "ระนอง",
    "zipcode": 85110,
    "sub_district_code": 850401,
    "district_code": 8504,
    "province_code": 85
  },
  {
    "sub_district": "น้ำจืดน้อย",
    "district": "กระบุรี",
    "province": "ระนอง",
    "zipcode": 85110,
    "sub_district_code": 850402,
    "district_code": 8504,
    "province_code": 85
  },
  {
    "sub_district": "มะมุ",
    "district": "กระบุรี",
    "province": "ระนอง",
    "zipcode": 85110,
    "sub_district_code": 850403,
    "district_code": 8504,
    "province_code": 85
  },
  {
    "sub_district": "ปากจั่น",
    "district": "กระบุรี",
    "province": "ระนอง",
    "zipcode": 85110,
    "sub_district_code": 850404,
    "district_code": 8504,
    "province_code": 85
  },
  {
    "sub_district": "ลำเลียง",
    "district": "กระบุรี",
    "province": "ระนอง",
    "zipcode": 85110,
    "sub_district_code": 850405,
    "district_code": 8504,
    "province_code": 85
  },
  {
    "sub_district": "บางใหญ่",
    "district": "กระบุรี",
    "province": "ระนอง",
    "zipcode": 85110,
    "sub_district_code": 850407,
    "district_code": 8504,
    "province_code": 85
  },
  {
    "sub_district": "จ.ป.ร.",
    "district": "กระบุรี",
    "province": "ระนอง",
    "zipcode": 85110,
    "sub_district_code": 850406,
    "district_code": 8504,
    "province_code": 85
  },
  {
    "sub_district": "ม่วงกลวง",
    "district": "กะเปอร์",
    "province": "ระนอง",
    "zipcode": 85120,
    "sub_district_code": 850301,
    "district_code": 8503,
    "province_code": 85
  },
  {
    "sub_district": "กะเปอร์",
    "district": "กะเปอร์",
    "province": "ระนอง",
    "zipcode": 85120,
    "sub_district_code": 850302,
    "district_code": 8503,
    "province_code": 85
  },
  {
    "sub_district": "เชี่ยวเหลียง",
    "district": "กะเปอร์",
    "province": "ระนอง",
    "zipcode": 85120,
    "sub_district_code": 850303,
    "district_code": 8503,
    "province_code": 85
  },
  {
    "sub_district": "บ้านนา",
    "district": "กะเปอร์",
    "province": "ระนอง",
    "zipcode": 85120,
    "sub_district_code": 850304,
    "district_code": 8503,
    "province_code": 85
  },
  {
    "sub_district": "บางหิน",
    "district": "กะเปอร์",
    "province": "ระนอง",
    "zipcode": 85120,
    "sub_district_code": 850305,
    "district_code": 8503,
    "province_code": 85
  },
  {
    "sub_district": "ม่วงกลวง",
    "district": "กะเปอร์",
    "province": "ระนอง",
    "zipcode": 85120,
    "sub_district_code": 850301,
    "district_code": 8503,
    "province_code": 85
  },
  {
    "sub_district": "ละอุ่นใต้",
    "district": "ละอุ่น",
    "province": "ระนอง",
    "zipcode": 85130,
    "sub_district_code": 850201,
    "district_code": 8502,
    "province_code": 85
  },
  {
    "sub_district": "ละอุ่นเหนือ",
    "district": "ละอุ่น",
    "province": "ระนอง",
    "zipcode": 85130,
    "sub_district_code": 850202,
    "district_code": 8502,
    "province_code": 85
  },
  {
    "sub_district": "บางพระใต้",
    "district": "ละอุ่น",
    "province": "ระนอง",
    "zipcode": 85130,
    "sub_district_code": 850203,
    "district_code": 8502,
    "province_code": 85
  },
  {
    "sub_district": "บางพระเหนือ",
    "district": "ละอุ่น",
    "province": "ระนอง",
    "zipcode": 85130,
    "sub_district_code": 850204,
    "district_code": 8502,
    "province_code": 85
  },
  {
    "sub_district": "บางแก้ว",
    "district": "ละอุ่น",
    "province": "ระนอง",
    "zipcode": 85130,
    "sub_district_code": 850205,
    "district_code": 8502,
    "province_code": 85
  },
  {
    "sub_district": "ในวงเหนือ",
    "district": "ละอุ่น",
    "province": "ระนอง",
    "zipcode": 85130,
    "sub_district_code": 850206,
    "district_code": 8502,
    "province_code": 85
  },
  {
    "sub_district": "ในวงใต้",
    "district": "ละอุ่น",
    "province": "ระนอง",
    "zipcode": 85130,
    "sub_district_code": 850207,
    "district_code": 8502,
    "province_code": 85
  },
  {
    "sub_district": "กำพวน",
    "district": "สุขสำราญ",
    "province": "ระนอง",
    "zipcode": 85120,
    "sub_district_code": 850502,
    "district_code": 8505,
    "province_code": 85
  },
  {
    "sub_district": "นาคา",
    "district": "สุขสำราญ",
    "province": "ระนอง",
    "zipcode": 85120,
    "sub_district_code": 850501,
    "district_code": 8505,
    "province_code": 85
  },
  {
    "sub_district": "ท่าประดู่",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21000,
    "sub_district_code": 210101,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "เชิงเนิน",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21000,
    "sub_district_code": 210102,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "ตะพง",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21000,
    "sub_district_code": 210103,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "ปากน้ำ",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21000,
    "sub_district_code": 210104,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "เพ",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21160,
    "sub_district_code": 210105,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "แกลง",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21160,
    "sub_district_code": 210106,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "บ้านแลง",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21000,
    "sub_district_code": 210107,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "นาตาขวัญ",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21000,
    "sub_district_code": 210108,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "เนินพระ",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21000,
    "sub_district_code": 210109,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "เนินพระ",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21150,
    "sub_district_code": 210109,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "กะเฉด",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21100,
    "sub_district_code": 210110,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "ทับมา",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21000,
    "sub_district_code": 210111,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "น้ำคอก",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21000,
    "sub_district_code": 210112,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "ห้วยโป่ง",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21150,
    "sub_district_code": 210113,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "มาบตาพุด",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21150,
    "sub_district_code": 210114,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "พลา",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21130,
    "sub_district_code": 210198,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "สำนักทอง",
    "district": "เมืองระยอง",
    "province": "ระยอง",
    "zipcode": 21100,
    "sub_district_code": 210115,
    "district_code": 2101,
    "province_code": 21
  },
  {
    "sub_district": "ทางเกวียน",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210301,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "วังหว้า",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210302,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "ชากโดน",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210303,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "เนินฆ้อ",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210304,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "กร่ำ",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21190,
    "sub_district_code": 210305,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "ชากพง",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21190,
    "sub_district_code": 210306,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "กระแสบน",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210307,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "บ้านนา",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210308,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "ทุ่งควายกิน",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210309,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "กองดิน",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 22160,
    "sub_district_code": 210310,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "คลองปูน",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21170,
    "sub_district_code": 210311,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "พังราด",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21170,
    "sub_district_code": 210312,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "ปากน้ำกระแส",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21170,
    "sub_district_code": 210313,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "น้ำเป็น",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210396,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "ชำฆ้อ",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210397,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "ห้วยทับมอญ",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210398,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "ห้วยยาง",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210317,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "สองสลึง",
    "district": "แกลง",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210318,
    "district_code": 2103,
    "province_code": 21
  },
  {
    "sub_district": "บ้านค่าย",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21120,
    "sub_district_code": 210501,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "หนองละลอก",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21120,
    "sub_district_code": 210502,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "หนองตะพาน",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21120,
    "sub_district_code": 210503,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "ตาขัน",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21120,
    "sub_district_code": 210504,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "บางบุตร",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21120,
    "sub_district_code": 210505,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "หนองบัว",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21120,
    "sub_district_code": 210506,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "ชากบก",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21120,
    "sub_district_code": 210507,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "มาบข่า",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21180,
    "sub_district_code": 210593,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "พนานิคม",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21180,
    "sub_district_code": 210594,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "มะขามคู่",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21180,
    "sub_district_code": 210595,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "นิคมพัฒนา",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21180,
    "sub_district_code": 210596,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "ละหาร",
    "district": "บ้านค่าย",
    "province": "ระยอง",
    "zipcode": 21140,
    "sub_district_code": 210598,
    "district_code": 2105,
    "province_code": 21
  },
  {
    "sub_district": "ปลวกแดง",
    "district": "ปลวกแดง",
    "province": "ระยอง",
    "zipcode": 21140,
    "sub_district_code": 210601,
    "district_code": 2106,
    "province_code": 21
  },
  {
    "sub_district": "ตาสิทธิ์",
    "district": "ปลวกแดง",
    "province": "ระยอง",
    "zipcode": 21140,
    "sub_district_code": 210602,
    "district_code": 2106,
    "province_code": 21
  },
  {
    "sub_district": "ละหาร",
    "district": "ปลวกแดง",
    "province": "ระยอง",
    "zipcode": 21140,
    "sub_district_code": 210603,
    "district_code": 2106,
    "province_code": 21
  },
  {
    "sub_district": "แม่น้ำคู้",
    "district": "ปลวกแดง",
    "province": "ระยอง",
    "zipcode": 21140,
    "sub_district_code": 210604,
    "district_code": 2106,
    "province_code": 21
  },
  {
    "sub_district": "มาบยางพร",
    "district": "ปลวกแดง",
    "province": "ระยอง",
    "zipcode": 21140,
    "sub_district_code": 210605,
    "district_code": 2106,
    "province_code": 21
  },
  {
    "sub_district": "หนองไร่",
    "district": "ปลวกแดง",
    "province": "ระยอง",
    "zipcode": 21140,
    "sub_district_code": 210606,
    "district_code": 2106,
    "province_code": 21
  },
  {
    "sub_district": "บ้านฉาง",
    "district": "บ้านฉาง",
    "province": "ระยอง",
    "zipcode": 21130,
    "sub_district_code": 210203,
    "district_code": 2102,
    "province_code": 21
  },
  {
    "sub_district": "พลา",
    "district": "บ้านฉาง",
    "province": "ระยอง",
    "zipcode": 21130,
    "sub_district_code": 210202,
    "district_code": 2102,
    "province_code": 21
  },
  {
    "sub_district": "สำนักท้อน",
    "district": "บ้านฉาง",
    "province": "ระยอง",
    "zipcode": 21130,
    "sub_district_code": 210201,
    "district_code": 2102,
    "province_code": 21
  },
  {
    "sub_district": "วังจันทร์",
    "district": "วังจันทร์",
    "province": "ระยอง",
    "zipcode": 21210,
    "sub_district_code": 210401,
    "district_code": 2104,
    "province_code": 21
  },
  {
    "sub_district": "ชุมแสง",
    "district": "วังจันทร์",
    "province": "ระยอง",
    "zipcode": 21210,
    "sub_district_code": 210402,
    "district_code": 2104,
    "province_code": 21
  },
  {
    "sub_district": "ป่ายุบใน",
    "district": "วังจันทร์",
    "province": "ระยอง",
    "zipcode": 21210,
    "sub_district_code": 210403,
    "district_code": 2104,
    "province_code": 21
  },
  {
    "sub_district": "พลงตาเอี่ยม",
    "district": "วังจันทร์",
    "province": "ระยอง",
    "zipcode": 21210,
    "sub_district_code": 210404,
    "district_code": 2104,
    "province_code": 21
  },
  {
    "sub_district": "นิคมพัฒนา",
    "district": "นิคมพัฒนา",
    "province": "ระยอง",
    "zipcode": 21180,
    "sub_district_code": 210801,
    "district_code": 2108,
    "province_code": 21
  },
  {
    "sub_district": "มาบข่า",
    "district": "นิคมพัฒนา",
    "province": "ระยอง",
    "zipcode": 21180,
    "sub_district_code": 210802,
    "district_code": 2108,
    "province_code": 21
  },
  {
    "sub_district": "มะขามคู่",
    "district": "นิคมพัฒนา",
    "province": "ระยอง",
    "zipcode": 21180,
    "sub_district_code": 210804,
    "district_code": 2108,
    "province_code": 21
  },
  {
    "sub_district": "พนานิคม",
    "district": "นิคมพัฒนา",
    "province": "ระยอง",
    "zipcode": 21180,
    "sub_district_code": 210803,
    "district_code": 2108,
    "province_code": 21
  },
  {
    "sub_district": "ห้วยทับมอญ",
    "district": "เขาชะเมา",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210702,
    "district_code": 2107,
    "province_code": 21
  },
  {
    "sub_district": "เขาน้อย",
    "district": "เขาชะเมา",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210704,
    "district_code": 2107,
    "province_code": 21
  },
  {
    "sub_district": "น้ำเป็น",
    "district": "เขาชะเมา",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210701,
    "district_code": 2107,
    "province_code": 21
  },
  {
    "sub_district": "ชำฆ้อ",
    "district": "เขาชะเมา",
    "province": "ระยอง",
    "zipcode": 21110,
    "sub_district_code": 210703,
    "district_code": 2107,
    "province_code": 21
  },
  {
    "sub_district": "หน้าเมือง",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700101,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "เจดีย์หัก",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700102,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "ดอนตะโก",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700103,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "หนองกลางนา",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700104,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "ห้วยไผ่",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700105,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "คุ้งน้ำวน",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700106,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "คุ้งกระถิน",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700107,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "อ่างทอง",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700108,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "โคกหม้อ",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700109,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "สามเรือน",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700110,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "พิกุลทอง",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700111,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "น้ำพุ",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700112,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "ดอนแร่",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700113,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "หินกอง",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700114,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "เขาแร้ง",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700115,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "เกาะพลับพลา",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700116,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "หลุมดิน",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700117,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "บางป่า",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700118,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "พงสวาย",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700119,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "คูบัว",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700120,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "ท่าราบ",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700121,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "บ้านไร่",
    "district": "เมืองราชบุรี",
    "province": "ราชบุรี",
    "zipcode": 70000,
    "sub_district_code": 700122,
    "district_code": 7001,
    "province_code": 70
  },
  {
    "sub_district": "จอมบึง",
    "district": "จอมบึง",
    "province": "ราชบุรี",
    "zipcode": 70150,
    "sub_district_code": 700201,
    "district_code": 7002,
    "province_code": 70
  },
  {
    "sub_district": "ปากช่อง",
    "district": "จอมบึง",
    "province": "ราชบุรี",
    "zipcode": 70150,
    "sub_district_code": 700202,
    "district_code": 7002,
    "province_code": 70
  },
  {
    "sub_district": "เบิกไพร",
    "district": "จอมบึง",
    "province": "ราชบุรี",
    "zipcode": 70150,
    "sub_district_code": 700203,
    "district_code": 7002,
    "province_code": 70
  },
  {
    "sub_district": "ด่านทับตะโก",
    "district": "จอมบึง",
    "province": "ราชบุรี",
    "zipcode": 70150,
    "sub_district_code": 700204,
    "district_code": 7002,
    "province_code": 70
  },
  {
    "sub_district": "แก้มอ้น",
    "district": "จอมบึง",
    "province": "ราชบุรี",
    "zipcode": 70150,
    "sub_district_code": 700205,
    "district_code": 7002,
    "province_code": 70
  },
  {
    "sub_district": "รางบัว",
    "district": "จอมบึง",
    "province": "ราชบุรี",
    "zipcode": 70150,
    "sub_district_code": 700206,
    "district_code": 7002,
    "province_code": 70
  },
  {
    "sub_district": "ดำเนินสะดวก",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700401,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "ประสาทสิทธิ์",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70210,
    "sub_district_code": 700402,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "ศรีสุราษฎร์",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700403,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "ตาหลวง",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700404,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "ดอนกรวย",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700405,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "ดอนคลัง",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700406,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "บัวงาม",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70210,
    "sub_district_code": 700407,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "บ้านไร่",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700408,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "แพงพวย",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700409,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "สี่หมื่น",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700410,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "ท่านัด",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700411,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "ขุนพิทักษ์",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700412,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "ดอนไผ่",
    "district": "ดำเนินสะดวก",
    "province": "ราชบุรี",
    "zipcode": 70130,
    "sub_district_code": 700413,
    "district_code": 7004,
    "province_code": 70
  },
  {
    "sub_district": "บางแพ",
    "district": "บางแพ",
    "province": "ราชบุรี",
    "zipcode": 70160,
    "sub_district_code": 700601,
    "district_code": 7006,
    "province_code": 70
  },
  {
    "sub_district": "วังเย็น",
    "district": "บางแพ",
    "province": "ราชบุรี",
    "zipcode": 70160,
    "sub_district_code": 700602,
    "district_code": 7006,
    "province_code": 70
  },
  {
    "sub_district": "หัวโพ",
    "district": "บางแพ",
    "province": "ราชบุรี",
    "zipcode": 70160,
    "sub_district_code": 700603,
    "district_code": 7006,
    "province_code": 70
  },
  {
    "sub_district": "วัดแก้ว",
    "district": "บางแพ",
    "province": "ราชบุรี",
    "zipcode": 70160,
    "sub_district_code": 700604,
    "district_code": 7006,
    "province_code": 70
  },
  {
    "sub_district": "ดอนใหญ่",
    "district": "บางแพ",
    "province": "ราชบุรี",
    "zipcode": 70160,
    "sub_district_code": 700605,
    "district_code": 7006,
    "province_code": 70
  },
  {
    "sub_district": "ดอนคา",
    "district": "บางแพ",
    "province": "ราชบุรี",
    "zipcode": 70160,
    "sub_district_code": 700606,
    "district_code": 7006,
    "province_code": 70
  },
  {
    "sub_district": "โพหัก",
    "district": "บางแพ",
    "province": "ราชบุรี",
    "zipcode": 70160,
    "sub_district_code": 700607,
    "district_code": 7006,
    "province_code": 70
  },
  {
    "sub_district": "บ้านโป่ง",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700501,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "ท่าผา",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700502,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "กรับใหญ่",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70190,
    "sub_district_code": 700503,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "ปากแรต",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700504,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "หนองกบ",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700505,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "หนองอ้อ",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700506,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "ดอนกระเบื้อง",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700507,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "ดอนกล้วย",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700508,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "นครชุมน์",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700509,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "บ้านม่วง",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700510,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "คุ้งพยอม",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700511,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "หนองปลาหมอ",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700512,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "เขาขลุง",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700513,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "เบิกไพร",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700514,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "ลาดบัวขาว",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700515,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "สวนกล้วย",
    "district": "บ้านโป่ง",
    "province": "ราชบุรี",
    "zipcode": 70110,
    "sub_district_code": 700517,
    "district_code": 7005,
    "province_code": 70
  },
  {
    "sub_district": "ทุ่งหลวง",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700801,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "วังมะนาว",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700802,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "ดอนทราย",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700803,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "หนองกระทุ่ม",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700804,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "ปากท่อ",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700805,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "ป่าไก่",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700806,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "วัดยางงาม",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700807,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "อ่างหิน",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700808,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "บ่อกระดาน",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700809,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "ยางหัก",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700810,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "วันดาว",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700811,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "ห้วยยางโทน",
    "district": "ปากท่อ",
    "province": "ราชบุรี",
    "zipcode": 70140,
    "sub_district_code": 700812,
    "district_code": 7008,
    "province_code": 70
  },
  {
    "sub_district": "โพธาราม",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700701,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "ดอนกระเบื้อง",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700702,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "หนองโพ",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700703,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "บ้านเลือก",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700704,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "คลองตาคต",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700705,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "บ้านฆ้อง",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700706,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "บ้านสิงห์",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700707,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "ดอนทราย",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700708,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "เจ็ดเสมียน",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700709,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "คลองข่อย",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700710,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "ชำแระ",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700711,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "สร้อยฟ้า",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700712,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "ท่าชุมพล",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700713,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "บางโตนด",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700714,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "เตาปูน",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700715,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "นางแก้ว",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700716,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "ธรรมเสน",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700717,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "เขาชะงุ้ม",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700718,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "หนองกวาง",
    "district": "โพธาราม",
    "province": "ราชบุรี",
    "zipcode": 70120,
    "sub_district_code": 700719,
    "district_code": 7007,
    "province_code": 70
  },
  {
    "sub_district": "เกาะศาลพระ",
    "district": "วัดเพลง",
    "province": "ราชบุรี",
    "zipcode": 70170,
    "sub_district_code": 700901,
    "district_code": 7009,
    "province_code": 70
  },
  {
    "sub_district": "จอมประทัด",
    "district": "วัดเพลง",
    "province": "ราชบุรี",
    "zipcode": 70170,
    "sub_district_code": 700902,
    "district_code": 7009,
    "province_code": 70
  },
  {
    "sub_district": "วัดเพลง",
    "district": "วัดเพลง",
    "province": "ราชบุรี",
    "zipcode": 70170,
    "sub_district_code": 700903,
    "district_code": 7009,
    "province_code": 70
  },
  {
    "sub_district": "สวนผึ้ง",
    "district": "สวนผึ้ง",
    "province": "ราชบุรี",
    "zipcode": 70180,
    "sub_district_code": 700301,
    "district_code": 7003,
    "province_code": 70
  },
  {
    "sub_district": "ป่าหวาย",
    "district": "สวนผึ้ง",
    "province": "ราชบุรี",
    "zipcode": 70180,
    "sub_district_code": 700302,
    "district_code": 7003,
    "province_code": 70
  },
  {
    "sub_district": "บ้านบึง",
    "district": "สวนผึ้ง",
    "province": "ราชบุรี",
    "zipcode": 70180,
    "sub_district_code": 700396,
    "district_code": 7003,
    "province_code": 70
  },
  {
    "sub_district": "ท่าเคย",
    "district": "สวนผึ้ง",
    "province": "ราชบุรี",
    "zipcode": 70180,
    "sub_district_code": 700304,
    "district_code": 7003,
    "province_code": 70
  },
  {
    "sub_district": "บ้านคา",
    "district": "สวนผึ้ง",
    "province": "ราชบุรี",
    "zipcode": 70180,
    "sub_district_code": 700397,
    "district_code": 7003,
    "province_code": 70
  },
  {
    "sub_district": "หนองพันจันทร์",
    "district": "สวนผึ้ง",
    "province": "ราชบุรี",
    "zipcode": 70180,
    "sub_district_code": 700398,
    "district_code": 7003,
    "province_code": 70
  },
  {
    "sub_district": "ตะนาวศรี",
    "district": "สวนผึ้ง",
    "province": "ราชบุรี",
    "zipcode": 70180,
    "sub_district_code": 700307,
    "district_code": 7003,
    "province_code": 70
  },
  {
    "sub_district": "บ้านคา",
    "district": "บ้านคา",
    "province": "ราชบุรี",
    "zipcode": 70180,
    "sub_district_code": 701001,
    "district_code": 7010,
    "province_code": 70
  },
  {
    "sub_district": "บ้านบึง",
    "district": "บ้านคา",
    "province": "ราชบุรี",
    "zipcode": 70180,
    "sub_district_code": 701002,
    "district_code": 7010,
    "province_code": 70
  },
  {
    "sub_district": "หนองพันจันทร์",
    "district": "บ้านคา",
    "province": "ราชบุรี",
    "zipcode": 70180,
    "sub_district_code": 701003,
    "district_code": 7010,
    "province_code": 70
  },
  {
    "sub_district": "ทะเลชุบศร",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160101,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "ท่าหิน",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160102,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "กกโก",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160103,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "โก่งธนู",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 13240,
    "sub_district_code": 160104,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "เขาพระงาม",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15160,
    "sub_district_code": 160105,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "เขาสามยอด",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160106,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "โคกกะเทียม",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15160,
    "sub_district_code": 160107,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "โคกลำพาน",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160108,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "โคกตูม",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15210,
    "sub_district_code": 160109,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "งิ้วราย",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160110,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "ดอนโพธิ์",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160111,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "ตะลุง",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160112,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "บางขันหมาก",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160117,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "ท่าแค",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160114,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "ท่าศาลา",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160115,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "นิคมสร้างตนเอง",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160116,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "บางขันหมาก",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160117,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "บ้านข่อย",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160118,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "ท้ายตลาด",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160119,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "ป่าตาล",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160120,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "พรหมมาสตร์",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160121,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "โพธิ์เก้าต้น",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160122,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "โพธิ์ตรุ",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160123,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "สี่คลอง",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160124,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "ถนนใหญ่",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15000,
    "sub_district_code": 160125,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "โพตลาดแก้ว",
    "district": "เมืองลพบุรี",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160198,
    "district_code": 1601,
    "province_code": 16
  },
  {
    "sub_district": "โคกสำโรง",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160301,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "เกาะแก้ว",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160302,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "ถลุงเหล็ก",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160303,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "หลุมข้าว",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160304,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "ห้วยโป่ง",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160305,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "คลองเกตุ",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160306,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "สะแกราบ",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160307,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "เพนียด",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160308,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "วังเพลิง",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160309,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "ดงมะรุม",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160310,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "ชอนสารเดช",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 160306,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "หนองม่วง",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 160394,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "บ่อทอง",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 160395,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "ยางโทน",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 160396,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "ชอนสมบูรณ์",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 160397,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "วังขอนขว้าง",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160318,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "ดงดินแดง",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 160398,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "วังจั่น",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160320,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "หนองแขม",
    "district": "โคกสำโรง",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160322,
    "district_code": 1603,
    "province_code": 16
  },
  {
    "sub_district": "ลำนารายณ์",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160401,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ชัยนารายณ์",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160402,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ศิลาทิพย์",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160403,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ห้วยหิน",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160404,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ม่วงค่อม",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15230,
    "sub_district_code": 160405,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "บัวชุม",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160406,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ท่าดินดำ",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160407,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "มะกอกหวาน",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15230,
    "sub_district_code": 160408,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ซับตะเคียน",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160409,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "นาโสม",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 160410,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "หนองยายโต๊ะ",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160411,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "เกาะรัง",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160412,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "หนองรี",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 160492,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ท่ามะนาว",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160414,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "กุดตาเพชร",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 160493,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ลำสนธิ",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 160494,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "นิคมลำนารายณ์",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160417,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ชัยบาดาล",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15230,
    "sub_district_code": 160418,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "บ้านใหม่สามัคคี",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160419,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ซับสมบูรณ์",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 160495,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "เขารวก",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 160496,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "หนองเต่า",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160497,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ท่าหลวง",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15230,
    "sub_district_code": 160498,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "เขาแหลม",
    "district": "ชัยบาดาล",
    "province": "ลพบุรี",
    "zipcode": 15130,
    "sub_district_code": 160422,
    "district_code": 1604,
    "province_code": 16
  },
  {
    "sub_district": "ท่าวุ้ง",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160501,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "บางคู้",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160502,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "โพตลาดแก้ว",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160503,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "บางลี่",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160504,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "บางงา",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160505,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "โคกสลุด",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160506,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "เขาสมอคอน",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15180,
    "sub_district_code": 160507,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "หัวสำโรง",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160508,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "ลาดสาลี่",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160509,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "บ้านเบิก",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160510,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "มุจลินท์",
    "district": "ท่าวุ้ง",
    "province": "ลพบุรี",
    "zipcode": 15150,
    "sub_district_code": 160511,
    "district_code": 1605,
    "province_code": 16
  },
  {
    "sub_district": "บ้านหมี่",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160619,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "เชียงงา",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160620,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "หนองเมือง",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160621,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "สนามแจง",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160622,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "ไผ่ใหญ่",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160601,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "บ้านทราย",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160602,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "บ้านกล้วย",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160603,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "ดงพลับ",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160604,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "บ้านชี",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160605,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "พุคา",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160606,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "หินปัก",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160607,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "บางพึ่ง",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160608,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "หนองทรายขาว",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160609,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "บางกะพี้",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160610,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "หนองเต่า",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160611,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "โพนทอง",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160612,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "บางขาม",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15180,
    "sub_district_code": 160613,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "ดอนดึง",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160614,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "ชอนม่วง",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160615,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "หนองกระเบียน",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160616,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "สายห้วยแก้ว",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160617,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "มหาสอน",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15110,
    "sub_district_code": 160618,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "ถลุงเหล็ก",
    "district": "บ้านหมี่",
    "province": "ลพบุรี",
    "zipcode": 15120,
    "sub_district_code": 160698,
    "district_code": 1606,
    "province_code": 16
  },
  {
    "sub_district": "พัฒนานิคม",
    "district": "พัฒนานิคม",
    "province": "ลพบุรี",
    "zipcode": 15140,
    "sub_district_code": 160201,
    "district_code": 1602,
    "province_code": 16
  },
  {
    "sub_district": "ช่องสาริกา",
    "district": "พัฒนานิคม",
    "province": "ลพบุรี",
    "zipcode": 15220,
    "sub_district_code": 160202,
    "district_code": 1602,
    "province_code": 16
  },
  {
    "sub_district": "มะนาวหวาน",
    "district": "พัฒนานิคม",
    "province": "ลพบุรี",
    "zipcode": 15140,
    "sub_district_code": 160203,
    "district_code": 1602,
    "province_code": 16
  },
  {
    "sub_district": "ดีลัง",
    "district": "พัฒนานิคม",
    "province": "ลพบุรี",
    "zipcode": 15220,
    "sub_district_code": 160204,
    "district_code": 1602,
    "province_code": 16
  },
  {
    "sub_district": "โคกสลุง",
    "district": "พัฒนานิคม",
    "province": "ลพบุรี",
    "zipcode": 15140,
    "sub_district_code": 160205,
    "district_code": 1602,
    "province_code": 16
  },
  {
    "sub_district": "ชอนน้อย",
    "district": "พัฒนานิคม",
    "province": "ลพบุรี",
    "zipcode": 15140,
    "sub_district_code": 160206,
    "district_code": 1602,
    "province_code": 16
  },
  {
    "sub_district": "หนองบัว",
    "district": "พัฒนานิคม",
    "province": "ลพบุรี",
    "zipcode": 15140,
    "sub_district_code": 160207,
    "district_code": 1602,
    "province_code": 16
  },
  {
    "sub_district": "ห้วยขุนราม",
    "district": "พัฒนานิคม",
    "province": "ลพบุรี",
    "zipcode": 18220,
    "sub_district_code": 160208,
    "district_code": 1602,
    "province_code": 16
  },
  {
    "sub_district": "น้ำสุด",
    "district": "พัฒนานิคม",
    "province": "ลพบุรี",
    "zipcode": 15140,
    "sub_district_code": 160209,
    "district_code": 1602,
    "province_code": 16
  },
  {
    "sub_district": "ท่าหลวง",
    "district": "ท่าหลวง",
    "province": "ลพบุรี",
    "zipcode": 15230,
    "sub_district_code": 160701,
    "district_code": 1607,
    "province_code": 16
  },
  {
    "sub_district": "แก่งผักกูด",
    "district": "ท่าหลวง",
    "province": "ลพบุรี",
    "zipcode": 15230,
    "sub_district_code": 160702,
    "district_code": 1607,
    "province_code": 16
  },
  {
    "sub_district": "ซับจำปา",
    "district": "ท่าหลวง",
    "province": "ลพบุรี",
    "zipcode": 15230,
    "sub_district_code": 160703,
    "district_code": 1607,
    "province_code": 16
  },
  {
    "sub_district": "หนองผักแว่น",
    "district": "ท่าหลวง",
    "province": "ลพบุรี",
    "zipcode": 15230,
    "sub_district_code": 160704,
    "district_code": 1607,
    "province_code": 16
  },
  {
    "sub_district": "ทะเลวังวัด",
    "district": "ท่าหลวง",
    "province": "ลพบุรี",
    "zipcode": 15230,
    "sub_district_code": 160705,
    "district_code": 1607,
    "province_code": 16
  },
  {
    "sub_district": "หัวลำ",
    "district": "ท่าหลวง",
    "province": "ลพบุรี",
    "zipcode": 15230,
    "sub_district_code": 160706,
    "district_code": 1607,
    "province_code": 16
  },
  {
    "sub_district": "สระโบสถ์",
    "district": "สระโบสถ์",
    "province": "ลพบุรี",
    "zipcode": 15240,
    "sub_district_code": 160801,
    "district_code": 1608,
    "province_code": 16
  },
  {
    "sub_district": "มหาโพธิ",
    "district": "สระโบสถ์",
    "province": "ลพบุรี",
    "zipcode": 15240,
    "sub_district_code": 160802,
    "district_code": 1608,
    "province_code": 16
  },
  {
    "sub_district": "ทุ่งท่าช้าง",
    "district": "สระโบสถ์",
    "province": "ลพบุรี",
    "zipcode": 15240,
    "sub_district_code": 160803,
    "district_code": 1608,
    "province_code": 16
  },
  {
    "sub_district": "ห้วยใหญ่",
    "district": "สระโบสถ์",
    "province": "ลพบุรี",
    "zipcode": 15240,
    "sub_district_code": 160804,
    "district_code": 1608,
    "province_code": 16
  },
  {
    "sub_district": "นิยมชัย",
    "district": "สระโบสถ์",
    "province": "ลพบุรี",
    "zipcode": 15240,
    "sub_district_code": 160805,
    "district_code": 1608,
    "province_code": 16
  },
  {
    "sub_district": "โคกเจริญ",
    "district": "โคกเจริญ",
    "province": "ลพบุรี",
    "zipcode": 15250,
    "sub_district_code": 160901,
    "district_code": 1609,
    "province_code": 16
  },
  {
    "sub_district": "ยางราก",
    "district": "โคกเจริญ",
    "province": "ลพบุรี",
    "zipcode": 15250,
    "sub_district_code": 160902,
    "district_code": 1609,
    "province_code": 16
  },
  {
    "sub_district": "หนองมะค่า",
    "district": "โคกเจริญ",
    "province": "ลพบุรี",
    "zipcode": 15250,
    "sub_district_code": 160903,
    "district_code": 1609,
    "province_code": 16
  },
  {
    "sub_district": "วังทอง",
    "district": "โคกเจริญ",
    "province": "ลพบุรี",
    "zipcode": 15250,
    "sub_district_code": 160904,
    "district_code": 1609,
    "province_code": 16
  },
  {
    "sub_district": "โคกแสมสาร",
    "district": "โคกเจริญ",
    "province": "ลพบุรี",
    "zipcode": 15250,
    "sub_district_code": 160905,
    "district_code": 1609,
    "province_code": 16
  },
  {
    "sub_district": "หนองรี",
    "district": "ลำสนธิ",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 161003,
    "district_code": 1610,
    "province_code": 16
  },
  {
    "sub_district": "ซับสมบูรณ์",
    "district": "ลำสนธิ",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 161002,
    "district_code": 1610,
    "province_code": 16
  },
  {
    "sub_district": "ลำสนธิ",
    "district": "ลำสนธิ",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 161001,
    "district_code": 1610,
    "province_code": 16
  },
  {
    "sub_district": "กุดตาเพชร",
    "district": "ลำสนธิ",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 161004,
    "district_code": 1610,
    "province_code": 16
  },
  {
    "sub_district": "เขารวก",
    "district": "ลำสนธิ",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 161005,
    "district_code": 1610,
    "province_code": 16
  },
  {
    "sub_district": "เขาน้อย",
    "district": "ลำสนธิ",
    "province": "ลพบุรี",
    "zipcode": 15190,
    "sub_district_code": 161006,
    "district_code": 1610,
    "province_code": 16
  },
  {
    "sub_district": "หนองม่วง",
    "district": "หนองม่วง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 161101,
    "district_code": 1611,
    "province_code": 16
  },
  {
    "sub_district": "ดงดินแดง",
    "district": "หนองม่วง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 161103,
    "district_code": 1611,
    "province_code": 16
  },
  {
    "sub_district": "ยางโทน",
    "district": "หนองม่วง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 161105,
    "district_code": 1611,
    "province_code": 16
  },
  {
    "sub_district": "ชอนสมบูรณ์",
    "district": "หนองม่วง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 161104,
    "district_code": 1611,
    "province_code": 16
  },
  {
    "sub_district": "บ่อทอง",
    "district": "หนองม่วง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 161102,
    "district_code": 1611,
    "province_code": 16
  },
  {
    "sub_district": "ชอนสารเดช",
    "district": "หนองม่วง",
    "province": "ลพบุรี",
    "zipcode": 15170,
    "sub_district_code": 161106,
    "district_code": 1611,
    "province_code": 16
  },
  {
    "sub_district": "เวียงเหนือ",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520101,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "หัวเวียง",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520102,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "สวนดอก",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52100,
    "sub_district_code": 520103,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "สบตุ๋ย",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52100,
    "sub_district_code": 520104,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "พระบาท",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520105,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "ชมพู",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52100,
    "sub_district_code": 520106,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "กล้วยแพะ",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520107,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "ปงแสนทอง",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52100,
    "sub_district_code": 520108,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "บ้านแลง",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520109,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "บ้านเสด็จ",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520110,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "พิชัย",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520111,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "ทุ่งฝาย",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520112,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "บ้านเอื้อม",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52100,
    "sub_district_code": 520113,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "บ้านเป้า",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52100,
    "sub_district_code": 520114,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "บ้านค่า",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52100,
    "sub_district_code": 520115,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "บ่อแฮ้ว",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52100,
    "sub_district_code": 520116,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "ต้นธงชัย",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520117,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "วังเงิน",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 520197,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "นิคมพัฒนา",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520118,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "ดอนไฟ",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 520198,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "บุญนาคพัฒนา",
    "district": "เมืองลำปาง",
    "province": "ลำปาง",
    "zipcode": 52000,
    "sub_district_code": 520119,
    "district_code": 5201,
    "province_code": 52
  },
  {
    "sub_district": "ลำปางหลวง",
    "district": "เกาะคา",
    "province": "ลำปาง",
    "zipcode": 52130,
    "sub_district_code": 520301,
    "district_code": 5203,
    "province_code": 52
  },
  {
    "sub_district": "นาแก้ว",
    "district": "เกาะคา",
    "province": "ลำปาง",
    "zipcode": 52130,
    "sub_district_code": 520302,
    "district_code": 5203,
    "province_code": 52
  },
  {
    "sub_district": "ไหล่หิน",
    "district": "เกาะคา",
    "province": "ลำปาง",
    "zipcode": 52130,
    "sub_district_code": 520303,
    "district_code": 5203,
    "province_code": 52
  },
  {
    "sub_district": "วังพร้าว",
    "district": "เกาะคา",
    "province": "ลำปาง",
    "zipcode": 52130,
    "sub_district_code": 520304,
    "district_code": 5203,
    "province_code": 52
  },
  {
    "sub_district": "ศาลา",
    "district": "เกาะคา",
    "province": "ลำปาง",
    "zipcode": 52130,
    "sub_district_code": 520305,
    "district_code": 5203,
    "province_code": 52
  },
  {
    "sub_district": "เกาะคา",
    "district": "เกาะคา",
    "province": "ลำปาง",
    "zipcode": 52130,
    "sub_district_code": 520306,
    "district_code": 5203,
    "province_code": 52
  },
  {
    "sub_district": "นาแส่ง",
    "district": "เกาะคา",
    "province": "ลำปาง",
    "zipcode": 52130,
    "sub_district_code": 520307,
    "district_code": 5203,
    "province_code": 52
  },
  {
    "sub_district": "ท่าผา",
    "district": "เกาะคา",
    "province": "ลำปาง",
    "zipcode": 52130,
    "sub_district_code": 520308,
    "district_code": 5203,
    "province_code": 52
  },
  {
    "sub_district": "ใหม่พัฒนา",
    "district": "เกาะคา",
    "province": "ลำปาง",
    "zipcode": 52130,
    "sub_district_code": 520309,
    "district_code": 5203,
    "province_code": 52
  },
  {
    "sub_district": "หลวงเหนือ",
    "district": "งาว",
    "province": "ลำปาง",
    "zipcode": 52110,
    "sub_district_code": 520501,
    "district_code": 5205,
    "province_code": 52
  },
  {
    "sub_district": "หลวงใต้",
    "district": "งาว",
    "province": "ลำปาง",
    "zipcode": 52110,
    "sub_district_code": 520502,
    "district_code": 5205,
    "province_code": 52
  },
  {
    "sub_district": "บ้านโป่ง",
    "district": "งาว",
    "province": "ลำปาง",
    "zipcode": 52110,
    "sub_district_code": 520503,
    "district_code": 5205,
    "province_code": 52
  },
  {
    "sub_district": "บ้านร้อง",
    "district": "งาว",
    "province": "ลำปาง",
    "zipcode": 52110,
    "sub_district_code": 520504,
    "district_code": 5205,
    "province_code": 52
  },
  {
    "sub_district": "ปงเตา",
    "district": "งาว",
    "province": "ลำปาง",
    "zipcode": 52110,
    "sub_district_code": 520505,
    "district_code": 5205,
    "province_code": 52
  },
  {
    "sub_district": "นาแก",
    "district": "งาว",
    "province": "ลำปาง",
    "zipcode": 52110,
    "sub_district_code": 520506,
    "district_code": 5205,
    "province_code": 52
  },
  {
    "sub_district": "บ้านอ้อน",
    "district": "งาว",
    "province": "ลำปาง",
    "zipcode": 52110,
    "sub_district_code": 520507,
    "district_code": 5205,
    "province_code": 52
  },
  {
    "sub_district": "บ้านแหง",
    "district": "งาว",
    "province": "ลำปาง",
    "zipcode": 52110,
    "sub_district_code": 520508,
    "district_code": 5205,
    "province_code": 52
  },
  {
    "sub_district": "บ้านหวด",
    "district": "งาว",
    "province": "ลำปาง",
    "zipcode": 52110,
    "sub_district_code": 520509,
    "district_code": 5205,
    "province_code": 52
  },
  {
    "sub_district": "แม่ตีบ",
    "district": "งาว",
    "province": "ลำปาง",
    "zipcode": 52110,
    "sub_district_code": 520510,
    "district_code": 5205,
    "province_code": 52
  },
  {
    "sub_district": "แจ้ห่ม",
    "district": "แจ้ห่ม",
    "province": "ลำปาง",
    "zipcode": 52120,
    "sub_district_code": 520601,
    "district_code": 5206,
    "province_code": 52
  },
  {
    "sub_district": "บ้านสา",
    "district": "แจ้ห่ม",
    "province": "ลำปาง",
    "zipcode": 52120,
    "sub_district_code": 520602,
    "district_code": 5206,
    "province_code": 52
  },
  {
    "sub_district": "ปงดอน",
    "district": "แจ้ห่ม",
    "province": "ลำปาง",
    "zipcode": 52120,
    "sub_district_code": 520603,
    "district_code": 5206,
    "province_code": 52
  },
  {
    "sub_district": "แม่สุก",
    "district": "แจ้ห่ม",
    "province": "ลำปาง",
    "zipcode": 52120,
    "sub_district_code": 520604,
    "district_code": 5206,
    "province_code": 52
  },
  {
    "sub_district": "เมืองมาย",
    "district": "แจ้ห่ม",
    "province": "ลำปาง",
    "zipcode": 52120,
    "sub_district_code": 520605,
    "district_code": 5206,
    "province_code": 52
  },
  {
    "sub_district": "ทุ่งผึ้ง",
    "district": "แจ้ห่ม",
    "province": "ลำปาง",
    "zipcode": 52120,
    "sub_district_code": 520606,
    "district_code": 5206,
    "province_code": 52
  },
  {
    "sub_district": "วิเชตนคร",
    "district": "แจ้ห่ม",
    "province": "ลำปาง",
    "zipcode": 52120,
    "sub_district_code": 520607,
    "district_code": 5206,
    "province_code": 52
  },
  {
    "sub_district": "ล้อมแรด",
    "district": "เถิน",
    "province": "ลำปาง",
    "zipcode": 52160,
    "sub_district_code": 520801,
    "district_code": 5208,
    "province_code": 52
  },
  {
    "sub_district": "แม่วะ",
    "district": "เถิน",
    "province": "ลำปาง",
    "zipcode": 52230,
    "sub_district_code": 520802,
    "district_code": 5208,
    "province_code": 52
  },
  {
    "sub_district": "แม่ปะ",
    "district": "เถิน",
    "province": "ลำปาง",
    "zipcode": 52160,
    "sub_district_code": 520803,
    "district_code": 5208,
    "province_code": 52
  },
  {
    "sub_district": "แม่มอก",
    "district": "เถิน",
    "province": "ลำปาง",
    "zipcode": 52160,
    "sub_district_code": 520804,
    "district_code": 5208,
    "province_code": 52
  },
  {
    "sub_district": "เวียงมอก",
    "district": "เถิน",
    "province": "ลำปาง",
    "zipcode": 52160,
    "sub_district_code": 520805,
    "district_code": 5208,
    "province_code": 52
  },
  {
    "sub_district": "นาโป่ง",
    "district": "เถิน",
    "province": "ลำปาง",
    "zipcode": 52160,
    "sub_district_code": 520806,
    "district_code": 5208,
    "province_code": 52
  },
  {
    "sub_district": "แม่ถอด",
    "district": "เถิน",
    "province": "ลำปาง",
    "zipcode": 52160,
    "sub_district_code": 520807,
    "district_code": 5208,
    "province_code": 52
  },
  {
    "sub_district": "เถินบุรี",
    "district": "เถิน",
    "province": "ลำปาง",
    "zipcode": 52160,
    "sub_district_code": 520808,
    "district_code": 5208,
    "province_code": 52
  },
  {
    "sub_district": "แม่ทะ",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 521001,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "นาครัว",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 521002,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "ป่าตัน",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 521003,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "บ้านกิ่ว",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 521004,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "บ้านบอม",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 521005,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "น้ำโจ้",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 521006,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "ดอนไฟ",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 521007,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "หัวเสือ",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 521008,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "สบป้าด",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52220,
    "sub_district_code": 521098,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "วังเงิน",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 521010,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "สันดอนแก้ว",
    "district": "แม่ทะ",
    "province": "ลำปาง",
    "zipcode": 52150,
    "sub_district_code": 521011,
    "district_code": 5210,
    "province_code": 52
  },
  {
    "sub_district": "แม่พริก",
    "district": "แม่พริก",
    "province": "ลำปาง",
    "zipcode": 52180,
    "sub_district_code": 520901,
    "district_code": 5209,
    "province_code": 52
  },
  {
    "sub_district": "ผาปัง",
    "district": "แม่พริก",
    "province": "ลำปาง",
    "zipcode": 52180,
    "sub_district_code": 520902,
    "district_code": 5209,
    "province_code": 52
  },
  {
    "sub_district": "แม่ปุ",
    "district": "แม่พริก",
    "province": "ลำปาง",
    "zipcode": 52180,
    "sub_district_code": 520903,
    "district_code": 5209,
    "province_code": 52
  },
  {
    "sub_district": "พระบาทวังตวง",
    "district": "แม่พริก",
    "province": "ลำปาง",
    "zipcode": 52180,
    "sub_district_code": 520904,
    "district_code": 5209,
    "province_code": 52
  },
  {
    "sub_district": "ทุ่งฮั้ว",
    "district": "วังเหนือ",
    "province": "ลำปาง",
    "zipcode": 52140,
    "sub_district_code": 520701,
    "district_code": 5207,
    "province_code": 52
  },
  {
    "sub_district": "วังเหนือ",
    "district": "วังเหนือ",
    "province": "ลำปาง",
    "zipcode": 52140,
    "sub_district_code": 520702,
    "district_code": 5207,
    "province_code": 52
  },
  {
    "sub_district": "วังใต้",
    "district": "วังเหนือ",
    "province": "ลำปาง",
    "zipcode": 52140,
    "sub_district_code": 520703,
    "district_code": 5207,
    "province_code": 52
  },
  {
    "sub_district": "ร่องเคาะ",
    "district": "วังเหนือ",
    "province": "ลำปาง",
    "zipcode": 52140,
    "sub_district_code": 520704,
    "district_code": 5207,
    "province_code": 52
  },
  {
    "sub_district": "วังแก้ว",
    "district": "วังเหนือ",
    "province": "ลำปาง",
    "zipcode": 52140,
    "sub_district_code": 520707,
    "district_code": 5207,
    "province_code": 52
  },
  {
    "sub_district": "วังซ้าย",
    "district": "วังเหนือ",
    "province": "ลำปาง",
    "zipcode": 52140,
    "sub_district_code": 520706,
    "district_code": 5207,
    "province_code": 52
  },
  {
    "sub_district": "วังทอง",
    "district": "วังเหนือ",
    "province": "ลำปาง",
    "zipcode": 52140,
    "sub_district_code": 520705,
    "district_code": 5207,
    "province_code": 52
  },
  {
    "sub_district": "วังทรายคำ",
    "district": "วังเหนือ",
    "province": "ลำปาง",
    "zipcode": 52140,
    "sub_district_code": 520708,
    "district_code": 5207,
    "province_code": 52
  },
  {
    "sub_district": "สบปราบ",
    "district": "สบปราบ",
    "province": "ลำปาง",
    "zipcode": 52170,
    "sub_district_code": 521101,
    "district_code": 5211,
    "province_code": 52
  },
  {
    "sub_district": "สมัย",
    "district": "สบปราบ",
    "province": "ลำปาง",
    "zipcode": 52170,
    "sub_district_code": 521102,
    "district_code": 5211,
    "province_code": 52
  },
  {
    "sub_district": "แม่กัวะ",
    "district": "สบปราบ",
    "province": "ลำปาง",
    "zipcode": 52170,
    "sub_district_code": 521103,
    "district_code": 5211,
    "province_code": 52
  },
  {
    "sub_district": "นายาง",
    "district": "สบปราบ",
    "province": "ลำปาง",
    "zipcode": 52170,
    "sub_district_code": 521104,
    "district_code": 5211,
    "province_code": 52
  },
  {
    "sub_district": "ทุ่งงาม",
    "district": "เสริมงาม",
    "province": "ลำปาง",
    "zipcode": 52210,
    "sub_district_code": 520401,
    "district_code": 5204,
    "province_code": 52
  },
  {
    "sub_district": "เสริมขวา",
    "district": "เสริมงาม",
    "province": "ลำปาง",
    "zipcode": 52210,
    "sub_district_code": 520402,
    "district_code": 5204,
    "province_code": 52
  },
  {
    "sub_district": "เสริมซ้าย",
    "district": "เสริมงาม",
    "province": "ลำปาง",
    "zipcode": 52210,
    "sub_district_code": 520403,
    "district_code": 5204,
    "province_code": 52
  },
  {
    "sub_district": "เสริมกลาง",
    "district": "เสริมงาม",
    "province": "ลำปาง",
    "zipcode": 52210,
    "sub_district_code": 520404,
    "district_code": 5204,
    "province_code": 52
  },
  {
    "sub_district": "ห้างฉัตร",
    "district": "ห้างฉัตร",
    "province": "ลำปาง",
    "zipcode": 52190,
    "sub_district_code": 521201,
    "district_code": 5212,
    "province_code": 52
  },
  {
    "sub_district": "หนองหล่ม",
    "district": "ห้างฉัตร",
    "province": "ลำปาง",
    "zipcode": 52190,
    "sub_district_code": 521202,
    "district_code": 5212,
    "province_code": 52
  },
  {
    "sub_district": "เมืองยาว",
    "district": "ห้างฉัตร",
    "province": "ลำปาง",
    "zipcode": 52190,
    "sub_district_code": 521203,
    "district_code": 5212,
    "province_code": 52
  },
  {
    "sub_district": "ปงยางคก",
    "district": "ห้างฉัตร",
    "province": "ลำปาง",
    "zipcode": 52190,
    "sub_district_code": 521204,
    "district_code": 5212,
    "province_code": 52
  },
  {
    "sub_district": "เวียงตาล",
    "district": "ห้างฉัตร",
    "province": "ลำปาง",
    "zipcode": 52190,
    "sub_district_code": 521205,
    "district_code": 5212,
    "province_code": 52
  },
  {
    "sub_district": "แม่สัน",
    "district": "ห้างฉัตร",
    "province": "ลำปาง",
    "zipcode": 52190,
    "sub_district_code": 521206,
    "district_code": 5212,
    "province_code": 52
  },
  {
    "sub_district": "วอแก้ว",
    "district": "ห้างฉัตร",
    "province": "ลำปาง",
    "zipcode": 52190,
    "sub_district_code": 521207,
    "district_code": 5212,
    "province_code": 52
  },
  {
    "sub_district": "บ้านดง",
    "district": "แม่เมาะ",
    "province": "ลำปาง",
    "zipcode": 52220,
    "sub_district_code": 520201,
    "district_code": 5202,
    "province_code": 52
  },
  {
    "sub_district": "นาสัก",
    "district": "แม่เมาะ",
    "province": "ลำปาง",
    "zipcode": 52220,
    "sub_district_code": 520202,
    "district_code": 5202,
    "province_code": 52
  },
  {
    "sub_district": "จางเหนือ",
    "district": "แม่เมาะ",
    "province": "ลำปาง",
    "zipcode": 52220,
    "sub_district_code": 520203,
    "district_code": 5202,
    "province_code": 52
  },
  {
    "sub_district": "แม่เมาะ",
    "district": "แม่เมาะ",
    "province": "ลำปาง",
    "zipcode": 52220,
    "sub_district_code": 520204,
    "district_code": 5202,
    "province_code": 52
  },
  {
    "sub_district": "สบป้าด",
    "district": "แม่เมาะ",
    "province": "ลำปาง",
    "zipcode": 52220,
    "sub_district_code": 520205,
    "district_code": 5202,
    "province_code": 52
  },
  {
    "sub_district": "เมืองปาน",
    "district": "เมืองปาน",
    "province": "ลำปาง",
    "zipcode": 52240,
    "sub_district_code": 521301,
    "district_code": 5213,
    "province_code": 52
  },
  {
    "sub_district": "บ้านขอ",
    "district": "เมืองปาน",
    "province": "ลำปาง",
    "zipcode": 52240,
    "sub_district_code": 521302,
    "district_code": 5213,
    "province_code": 52
  },
  {
    "sub_district": "ทุ่งกว๋าว",
    "district": "เมืองปาน",
    "province": "ลำปาง",
    "zipcode": 52240,
    "sub_district_code": 521303,
    "district_code": 5213,
    "province_code": 52
  },
  {
    "sub_district": "แจ้ซ้อน",
    "district": "เมืองปาน",
    "province": "ลำปาง",
    "zipcode": 52240,
    "sub_district_code": 521304,
    "district_code": 5213,
    "province_code": 52
  },
  {
    "sub_district": "หัวเมือง",
    "district": "เมืองปาน",
    "province": "ลำปาง",
    "zipcode": 52240,
    "sub_district_code": 521305,
    "district_code": 5213,
    "province_code": 52
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510101,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "เหมืองง่า",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510102,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "อุโมงค์",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51150,
    "sub_district_code": 510103,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "หนองช้างคืน",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51150,
    "sub_district_code": 510104,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "ประตูป่า",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510105,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "ริมปิง",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510106,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "ต้นธง",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510107,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "บ้านแป้น",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510108,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "เหมืองจี้",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510109,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "ป่าสัก",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510110,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "เวียงยอง",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510111,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "บ้านกลาง",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510112,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "มะเขือแจ้",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510113,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "บ้านธิ",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51180,
    "sub_district_code": 510196,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "ห้วยยาบ",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51180,
    "sub_district_code": 510197,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "ศรีบัวบาน",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510116,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "หนองหนาม",
    "district": "เมืองลำพูน",
    "province": "ลำพูน",
    "zipcode": 51000,
    "sub_district_code": 510117,
    "district_code": 5101,
    "province_code": 51
  },
  {
    "sub_district": "บ้านโฮ่ง",
    "district": "บ้านโฮ่ง",
    "province": "ลำพูน",
    "zipcode": 51130,
    "sub_district_code": 510301,
    "district_code": 5103,
    "province_code": 51
  },
  {
    "sub_district": "ป่าพลู",
    "district": "บ้านโฮ่ง",
    "province": "ลำพูน",
    "zipcode": 51130,
    "sub_district_code": 510302,
    "district_code": 5103,
    "province_code": 51
  },
  {
    "sub_district": "เหล่ายาว",
    "district": "บ้านโฮ่ง",
    "province": "ลำพูน",
    "zipcode": 51130,
    "sub_district_code": 510303,
    "district_code": 5103,
    "province_code": 51
  },
  {
    "sub_district": "ศรีเตี้ย",
    "district": "บ้านโฮ่ง",
    "province": "ลำพูน",
    "zipcode": 51130,
    "sub_district_code": 510304,
    "district_code": 5103,
    "province_code": 51
  },
  {
    "sub_district": "หนองปลาสะวาย",
    "district": "บ้านโฮ่ง",
    "province": "ลำพูน",
    "zipcode": 51130,
    "sub_district_code": 510305,
    "district_code": 5103,
    "province_code": 51
  },
  {
    "sub_district": "ปากบ่อง",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510601,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "ป่าซาง",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510602,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "แม่แรง",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510603,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "ม่วงน้อย",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510604,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "บ้านเรือน",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510605,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "มะกอก",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510606,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "ท่าตุ้ม",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510607,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "น้ำดิบ",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510608,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "วังผาง",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510695,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "หนองล่อง",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510696,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "นครเจดีย์",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510611,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "หนองยวง",
    "district": "ป่าซาง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510697,
    "district_code": 5106,
    "province_code": 51
  },
  {
    "sub_district": "ทาปลาดุก",
    "district": "แม่ทา",
    "province": "ลำพูน",
    "zipcode": 51140,
    "sub_district_code": 510201,
    "district_code": 5102,
    "province_code": 51
  },
  {
    "sub_district": "ทาสบเส้า",
    "district": "แม่ทา",
    "province": "ลำพูน",
    "zipcode": 51140,
    "sub_district_code": 510202,
    "district_code": 5102,
    "province_code": 51
  },
  {
    "sub_district": "ทากาศ",
    "district": "แม่ทา",
    "province": "ลำพูน",
    "zipcode": 51170,
    "sub_district_code": 510203,
    "district_code": 5102,
    "province_code": 51
  },
  {
    "sub_district": "ทาขุมเงิน",
    "district": "แม่ทา",
    "province": "ลำพูน",
    "zipcode": 51170,
    "sub_district_code": 510204,
    "district_code": 5102,
    "province_code": 51
  },
  {
    "sub_district": "ทาทุ่งหลวง",
    "district": "แม่ทา",
    "province": "ลำพูน",
    "zipcode": 51170,
    "sub_district_code": 510205,
    "district_code": 5102,
    "province_code": 51
  },
  {
    "sub_district": "ทาแม่ลอบ",
    "district": "แม่ทา",
    "province": "ลำพูน",
    "zipcode": 51170,
    "sub_district_code": 510206,
    "district_code": 5102,
    "province_code": 51
  },
  {
    "sub_district": "ลี้",
    "district": "ลี้",
    "province": "ลำพูน",
    "zipcode": 51110,
    "sub_district_code": 510401,
    "district_code": 5104,
    "province_code": 51
  },
  {
    "sub_district": "แม่ตืน",
    "district": "ลี้",
    "province": "ลำพูน",
    "zipcode": 51110,
    "sub_district_code": 510402,
    "district_code": 5104,
    "province_code": 51
  },
  {
    "sub_district": "นาทราย",
    "district": "ลี้",
    "province": "ลำพูน",
    "zipcode": 51110,
    "sub_district_code": 510403,
    "district_code": 5104,
    "province_code": 51
  },
  {
    "sub_district": "ดงดำ",
    "district": "ลี้",
    "province": "ลำพูน",
    "zipcode": 51110,
    "sub_district_code": 510404,
    "district_code": 5104,
    "province_code": 51
  },
  {
    "sub_district": "ก้อ",
    "district": "ลี้",
    "province": "ลำพูน",
    "zipcode": 51110,
    "sub_district_code": 510405,
    "district_code": 5104,
    "province_code": 51
  },
  {
    "sub_district": "แม่ลาน",
    "district": "ลี้",
    "province": "ลำพูน",
    "zipcode": 51110,
    "sub_district_code": 510406,
    "district_code": 5104,
    "province_code": 51
  },
  {
    "sub_district": "ป่าไผ่",
    "district": "ลี้",
    "province": "ลำพูน",
    "zipcode": 51110,
    "sub_district_code": 510408,
    "district_code": 5104,
    "province_code": 51
  },
  {
    "sub_district": "ศรีวิชัย",
    "district": "ลี้",
    "province": "ลำพูน",
    "zipcode": 51110,
    "sub_district_code": 510409,
    "district_code": 5104,
    "province_code": 51
  },
  {
    "sub_district": "ทุ่งหัวช้าง",
    "district": "ทุ่งหัวช้าง",
    "province": "ลำพูน",
    "zipcode": 51160,
    "sub_district_code": 510501,
    "district_code": 5105,
    "province_code": 51
  },
  {
    "sub_district": "บ้านปวง",
    "district": "ทุ่งหัวช้าง",
    "province": "ลำพูน",
    "zipcode": 51160,
    "sub_district_code": 510502,
    "district_code": 5105,
    "province_code": 51
  },
  {
    "sub_district": "ตะเคียนปม",
    "district": "ทุ่งหัวช้าง",
    "province": "ลำพูน",
    "zipcode": 51160,
    "sub_district_code": 510503,
    "district_code": 5105,
    "province_code": 51
  },
  {
    "sub_district": "บ้านธิ",
    "district": "บ้านธิ",
    "province": "ลำพูน",
    "zipcode": 51180,
    "sub_district_code": 510701,
    "district_code": 5107,
    "province_code": 51
  },
  {
    "sub_district": "ห้วยยาบ",
    "district": "บ้านธิ",
    "province": "ลำพูน",
    "zipcode": 51180,
    "sub_district_code": 510702,
    "district_code": 5107,
    "province_code": 51
  },
  {
    "sub_district": "วังผาง",
    "district": "เวียงหนองล่อง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510803,
    "district_code": 5108,
    "province_code": 51
  },
  {
    "sub_district": "หนองล่อง",
    "district": "เวียงหนองล่อง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510801,
    "district_code": 5108,
    "province_code": 51
  },
  {
    "sub_district": "หนองยวง",
    "district": "เวียงหนองล่อง",
    "province": "ลำพูน",
    "zipcode": 51120,
    "sub_district_code": 510802,
    "district_code": 5108,
    "province_code": 51
  },
  {
    "sub_district": "กุดป่อง",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420101,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "เมือง",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420102,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "นาอ้อ",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42100,
    "sub_district_code": 420103,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "กกดู่",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420104,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "น้ำหมาน",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420105,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "เสี้ยว",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420106,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "นาอาน",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420107,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "นาโป่ง",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420108,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "นาดินดำ",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420109,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "น้ำสวย",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420110,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "นาแขม",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42100,
    "sub_district_code": 420112,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "ชัยพฤกษ์",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420111,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "ศรีสองรัก",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42100,
    "sub_district_code": 420113,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "กกทอง",
    "district": "เมืองเลย",
    "province": "เลย",
    "zipcode": 42000,
    "sub_district_code": 420114,
    "district_code": 4201,
    "province_code": 42
  },
  {
    "sub_district": "เชียงคาน",
    "district": "เชียงคาน",
    "province": "เลย",
    "zipcode": 42110,
    "sub_district_code": 420301,
    "district_code": 4203,
    "province_code": 42
  },
  {
    "sub_district": "ธาตุ",
    "district": "เชียงคาน",
    "province": "เลย",
    "zipcode": 42110,
    "sub_district_code": 420302,
    "district_code": 4203,
    "province_code": 42
  },
  {
    "sub_district": "นาซ่าว",
    "district": "เชียงคาน",
    "province": "เลย",
    "zipcode": 42110,
    "sub_district_code": 420303,
    "district_code": 4203,
    "province_code": 42
  },
  {
    "sub_district": "เขาแก้ว",
    "district": "เชียงคาน",
    "province": "เลย",
    "zipcode": 42110,
    "sub_district_code": 420304,
    "district_code": 4203,
    "province_code": 42
  },
  {
    "sub_district": "ปากตม",
    "district": "เชียงคาน",
    "province": "เลย",
    "zipcode": 42110,
    "sub_district_code": 420305,
    "district_code": 4203,
    "province_code": 42
  },
  {
    "sub_district": "บุฮม",
    "district": "เชียงคาน",
    "province": "เลย",
    "zipcode": 42110,
    "sub_district_code": 420306,
    "district_code": 4203,
    "province_code": 42
  },
  {
    "sub_district": "จอมศรี",
    "district": "เชียงคาน",
    "province": "เลย",
    "zipcode": 42110,
    "sub_district_code": 420307,
    "district_code": 4203,
    "province_code": 42
  },
  {
    "sub_district": "หาดทรายขาว",
    "district": "เชียงคาน",
    "province": "เลย",
    "zipcode": 42110,
    "sub_district_code": 420308,
    "district_code": 4203,
    "province_code": 42
  },
  {
    "sub_district": "ด่านซ้าย",
    "district": "ด่านซ้าย",
    "province": "เลย",
    "zipcode": 42120,
    "sub_district_code": 420501,
    "district_code": 4205,
    "province_code": 42
  },
  {
    "sub_district": "ปากหมัน",
    "district": "ด่านซ้าย",
    "province": "เลย",
    "zipcode": 42120,
    "sub_district_code": 420502,
    "district_code": 4205,
    "province_code": 42
  },
  {
    "sub_district": "นาดี",
    "district": "ด่านซ้าย",
    "province": "เลย",
    "zipcode": 42120,
    "sub_district_code": 420503,
    "district_code": 4205,
    "province_code": 42
  },
  {
    "sub_district": "โคกงาม",
    "district": "ด่านซ้าย",
    "province": "เลย",
    "zipcode": 42120,
    "sub_district_code": 420504,
    "district_code": 4205,
    "province_code": 42
  },
  {
    "sub_district": "โพนสูง",
    "district": "ด่านซ้าย",
    "province": "เลย",
    "zipcode": 42120,
    "sub_district_code": 420505,
    "district_code": 4205,
    "province_code": 42
  },
  {
    "sub_district": "อิปุ่ม",
    "district": "ด่านซ้าย",
    "province": "เลย",
    "zipcode": 42120,
    "sub_district_code": 420506,
    "district_code": 4205,
    "province_code": 42
  },
  {
    "sub_district": "กกสะทอน",
    "district": "ด่านซ้าย",
    "province": "เลย",
    "zipcode": 42120,
    "sub_district_code": 420507,
    "district_code": 4205,
    "province_code": 42
  },
  {
    "sub_district": "โป่ง",
    "district": "ด่านซ้าย",
    "province": "เลย",
    "zipcode": 42120,
    "sub_district_code": 420508,
    "district_code": 4205,
    "province_code": 42
  },
  {
    "sub_district": "วังยาว",
    "district": "ด่านซ้าย",
    "province": "เลย",
    "zipcode": 42120,
    "sub_district_code": 420509,
    "district_code": 4205,
    "province_code": 42
  },
  {
    "sub_district": "นาหอ",
    "district": "ด่านซ้าย",
    "province": "เลย",
    "zipcode": 42120,
    "sub_district_code": 420510,
    "district_code": 4205,
    "province_code": 42
  },
  {
    "sub_district": "ท่าลี่",
    "district": "ท่าลี่",
    "province": "เลย",
    "zipcode": 42140,
    "sub_district_code": 420801,
    "district_code": 4208,
    "province_code": 42
  },
  {
    "sub_district": "หนองผือ",
    "district": "ท่าลี่",
    "province": "เลย",
    "zipcode": 42140,
    "sub_district_code": 420802,
    "district_code": 4208,
    "province_code": 42
  },
  {
    "sub_district": "อาฮี",
    "district": "ท่าลี่",
    "province": "เลย",
    "zipcode": 42140,
    "sub_district_code": 420803,
    "district_code": 4208,
    "province_code": 42
  },
  {
    "sub_district": "น้ำแคม",
    "district": "ท่าลี่",
    "province": "เลย",
    "zipcode": 42140,
    "sub_district_code": 420804,
    "district_code": 4208,
    "province_code": 42
  },
  {
    "sub_district": "โคกใหญ่",
    "district": "ท่าลี่",
    "province": "เลย",
    "zipcode": 42140,
    "sub_district_code": 420805,
    "district_code": 4208,
    "province_code": 42
  },
  {
    "sub_district": "น้ำทูน",
    "district": "ท่าลี่",
    "province": "เลย",
    "zipcode": 42140,
    "sub_district_code": 420806,
    "district_code": 4208,
    "province_code": 42
  },
  {
    "sub_district": "นาแห้ว",
    "district": "นาแห้ว",
    "province": "เลย",
    "zipcode": 42170,
    "sub_district_code": 420601,
    "district_code": 4206,
    "province_code": 42
  },
  {
    "sub_district": "แสงภา",
    "district": "นาแห้ว",
    "province": "เลย",
    "zipcode": 42170,
    "sub_district_code": 420602,
    "district_code": 4206,
    "province_code": 42
  },
  {
    "sub_district": "นาพึง",
    "district": "นาแห้ว",
    "province": "เลย",
    "zipcode": 42170,
    "sub_district_code": 420603,
    "district_code": 4206,
    "province_code": 42
  },
  {
    "sub_district": "นามาลา",
    "district": "นาแห้ว",
    "province": "เลย",
    "zipcode": 42170,
    "sub_district_code": 420604,
    "district_code": 4206,
    "province_code": 42
  },
  {
    "sub_district": "เหล่ากอหก",
    "district": "นาแห้ว",
    "province": "เลย",
    "zipcode": 42170,
    "sub_district_code": 420605,
    "district_code": 4206,
    "province_code": 42
  },
  {
    "sub_district": "ปากชม",
    "district": "ปากชม",
    "province": "เลย",
    "zipcode": 42150,
    "sub_district_code": 420401,
    "district_code": 4204,
    "province_code": 42
  },
  {
    "sub_district": "เชียงกลม",
    "district": "ปากชม",
    "province": "เลย",
    "zipcode": 42150,
    "sub_district_code": 420402,
    "district_code": 4204,
    "province_code": 42
  },
  {
    "sub_district": "หาดคัมภีร์",
    "district": "ปากชม",
    "province": "เลย",
    "zipcode": 42150,
    "sub_district_code": 420403,
    "district_code": 4204,
    "province_code": 42
  },
  {
    "sub_district": "ห้วยบ่อซืน",
    "district": "ปากชม",
    "province": "เลย",
    "zipcode": 42150,
    "sub_district_code": 420404,
    "district_code": 4204,
    "province_code": 42
  },
  {
    "sub_district": "ห้วยพิชัย",
    "district": "ปากชม",
    "province": "เลย",
    "zipcode": 42150,
    "sub_district_code": 420405,
    "district_code": 4204,
    "province_code": 42
  },
  {
    "sub_district": "ชมเจริญ",
    "district": "ปากชม",
    "province": "เลย",
    "zipcode": 42150,
    "sub_district_code": 420406,
    "district_code": 4204,
    "province_code": 42
  },
  {
    "sub_district": "ศรีฐาน",
    "district": "ภูกระดึง",
    "province": "เลย",
    "zipcode": 42180,
    "sub_district_code": 421001,
    "district_code": 4210,
    "province_code": 42
  },
  {
    "sub_district": "ปวนพุ",
    "district": "ภูกระดึง",
    "province": "เลย",
    "zipcode": 42190,
    "sub_district_code": 421093,
    "district_code": 4210,
    "province_code": 42
  },
  {
    "sub_district": "ท่าช้างคล้อง",
    "district": "ภูกระดึง",
    "province": "เลย",
    "zipcode": 42240,
    "sub_district_code": 421094,
    "district_code": 4210,
    "province_code": 42
  },
  {
    "sub_district": "ผาขาว",
    "district": "ภูกระดึง",
    "province": "เลย",
    "zipcode": 42240,
    "sub_district_code": 421095,
    "district_code": 4210,
    "province_code": 42
  },
  {
    "sub_district": "ผานกเค้า",
    "district": "ภูกระดึง",
    "province": "เลย",
    "zipcode": 42180,
    "sub_district_code": 421005,
    "district_code": 4210,
    "province_code": 42
  },
  {
    "sub_district": "โนนป่าซาง",
    "district": "ภูกระดึง",
    "province": "เลย",
    "zipcode": 42240,
    "sub_district_code": 421096,
    "district_code": 4210,
    "province_code": 42
  },
  {
    "sub_district": "ภูกระดึง",
    "district": "ภูกระดึง",
    "province": "เลย",
    "zipcode": 42180,
    "sub_district_code": 421007,
    "district_code": 4210,
    "province_code": 42
  },
  {
    "sub_district": "หนองหิน",
    "district": "ภูกระดึง",
    "province": "เลย",
    "zipcode": 42190,
    "sub_district_code": 421097,
    "district_code": 4210,
    "province_code": 42
  },
  {
    "sub_district": "โนนปอแดง",
    "district": "ภูกระดึง",
    "province": "เลย",
    "zipcode": 42240,
    "sub_district_code": 421098,
    "district_code": 4210,
    "province_code": 42
  },
  {
    "sub_district": "ห้วยส้ม",
    "district": "ภูกระดึง",
    "province": "เลย",
    "zipcode": 42180,
    "sub_district_code": 421010,
    "district_code": 4210,
    "province_code": 42
  },
  {
    "sub_district": "หนองบัว",
    "district": "ภูเรือ",
    "province": "เลย",
    "zipcode": 42160,
    "sub_district_code": 420701,
    "district_code": 4207,
    "province_code": 42
  },
  {
    "sub_district": "ท่าศาลา",
    "district": "ภูเรือ",
    "province": "เลย",
    "zipcode": 42160,
    "sub_district_code": 420702,
    "district_code": 4207,
    "province_code": 42
  },
  {
    "sub_district": "ร่องจิก",
    "district": "ภูเรือ",
    "province": "เลย",
    "zipcode": 42160,
    "sub_district_code": 420703,
    "district_code": 4207,
    "province_code": 42
  },
  {
    "sub_district": "ปลาบ่า",
    "district": "ภูเรือ",
    "province": "เลย",
    "zipcode": 42160,
    "sub_district_code": 420704,
    "district_code": 4207,
    "province_code": 42
  },
  {
    "sub_district": "ลาดค่าง",
    "district": "ภูเรือ",
    "province": "เลย",
    "zipcode": 42160,
    "sub_district_code": 420705,
    "district_code": 4207,
    "province_code": 42
  },
  {
    "sub_district": "สานตม",
    "district": "ภูเรือ",
    "province": "เลย",
    "zipcode": 42160,
    "sub_district_code": 420706,
    "district_code": 4207,
    "province_code": 42
  },
  {
    "sub_district": "วังสะพุง",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42130,
    "sub_district_code": 420901,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "ทรายขาว",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42130,
    "sub_district_code": 420902,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "หนองหญ้าปล้อง",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42130,
    "sub_district_code": 420903,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "หนองงิ้ว",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42130,
    "sub_district_code": 420904,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "ปากปวน",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42130,
    "sub_district_code": 420905,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "ผาน้อย",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42130,
    "sub_district_code": 420906,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "เอราวัณ",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42220,
    "sub_district_code": 420995,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "ผาอินทร์แปลง",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42220,
    "sub_district_code": 420996,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "ผาสามยอด",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42220,
    "sub_district_code": 420997,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "โคกขมิ้น",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42130,
    "sub_district_code": 420912,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "เขาหลวง",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42130,
    "sub_district_code": 420911,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "ศรีสงคราม",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42130,
    "sub_district_code": 420913,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "ผาบิ้ง",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42130,
    "sub_district_code": 420910,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "ภูหอ",
    "district": "วังสะพุง",
    "province": "เลย",
    "zipcode": 42230,
    "sub_district_code": 420998,
    "district_code": 4209,
    "province_code": 42
  },
  {
    "sub_district": "นาด้วง",
    "district": "นาด้วง",
    "province": "เลย",
    "zipcode": 42210,
    "sub_district_code": 420201,
    "district_code": 4202,
    "province_code": 42
  },
  {
    "sub_district": "นาดอกคำ",
    "district": "นาด้วง",
    "province": "เลย",
    "zipcode": 42210,
    "sub_district_code": 420202,
    "district_code": 4202,
    "province_code": 42
  },
  {
    "sub_district": "ท่าสะอาด",
    "district": "นาด้วง",
    "province": "เลย",
    "zipcode": 42210,
    "sub_district_code": 420203,
    "district_code": 4202,
    "province_code": 42
  },
  {
    "sub_district": "ท่าสวรรค์",
    "district": "นาด้วง",
    "province": "เลย",
    "zipcode": 42210,
    "sub_district_code": 420204,
    "district_code": 4202,
    "province_code": 42
  },
  {
    "sub_district": "ห้วยสีเสียด",
    "district": "ภูหลวง",
    "province": "เลย",
    "zipcode": 42230,
    "sub_district_code": 421104,
    "district_code": 4211,
    "province_code": 42
  },
  {
    "sub_district": "ภูหอ",
    "district": "ภูหลวง",
    "province": "เลย",
    "zipcode": 42230,
    "sub_district_code": 421101,
    "district_code": 4211,
    "province_code": 42
  },
  {
    "sub_district": "หนองคัน",
    "district": "ภูหลวง",
    "province": "เลย",
    "zipcode": 42230,
    "sub_district_code": 421102,
    "district_code": 4211,
    "province_code": 42
  },
  {
    "sub_district": "แก่งศรีภูมิ",
    "district": "ภูหลวง",
    "province": "เลย",
    "zipcode": 42230,
    "sub_district_code": 421106,
    "district_code": 4211,
    "province_code": 42
  },
  {
    "sub_district": "เลยวังไสย์",
    "district": "ภูหลวง",
    "province": "เลย",
    "zipcode": 42230,
    "sub_district_code": 421105,
    "district_code": 4211,
    "province_code": 42
  },
  {
    "sub_district": "ท่าช้างคล้อง",
    "district": "ผาขาว",
    "province": "เลย",
    "zipcode": 42240,
    "sub_district_code": 421202,
    "district_code": 4212,
    "province_code": 42
  },
  {
    "sub_district": "โนนปอแดง",
    "district": "ผาขาว",
    "province": "เลย",
    "zipcode": 42240,
    "sub_district_code": 421203,
    "district_code": 4212,
    "province_code": 42
  },
  {
    "sub_district": "โนนป่าซาง",
    "district": "ผาขาว",
    "province": "เลย",
    "zipcode": 42240,
    "sub_district_code": 421204,
    "district_code": 4212,
    "province_code": 42
  },
  {
    "sub_district": "ผาขาว",
    "district": "ผาขาว",
    "province": "เลย",
    "zipcode": 42240,
    "sub_district_code": 421201,
    "district_code": 4212,
    "province_code": 42
  },
  {
    "sub_district": "บ้านเพิ่ม",
    "district": "ผาขาว",
    "province": "เลย",
    "zipcode": 42240,
    "sub_district_code": 421205,
    "district_code": 4212,
    "province_code": 42
  },
  {
    "sub_district": "ผาอินทร์แปลง",
    "district": "เอราวัณ",
    "province": "เลย",
    "zipcode": 42220,
    "sub_district_code": 421302,
    "district_code": 4213,
    "province_code": 42
  },
  {
    "sub_district": "เอราวัณ",
    "district": "เอราวัณ",
    "province": "เลย",
    "zipcode": 42220,
    "sub_district_code": 421301,
    "district_code": 4213,
    "province_code": 42
  },
  {
    "sub_district": "ผาสามยอด",
    "district": "เอราวัณ",
    "province": "เลย",
    "zipcode": 42220,
    "sub_district_code": 421303,
    "district_code": 4213,
    "province_code": 42
  },
  {
    "sub_district": "ทรัพย์ไพวัลย์",
    "district": "เอราวัณ",
    "province": "เลย",
    "zipcode": 42220,
    "sub_district_code": 421304,
    "district_code": 4213,
    "province_code": 42
  },
  {
    "sub_district": "หนองหิน",
    "district": "หนองหิน",
    "province": "เลย",
    "zipcode": 42190,
    "sub_district_code": 421401,
    "district_code": 4214,
    "province_code": 42
  },
  {
    "sub_district": "ตาดข่า",
    "district": "หนองหิน",
    "province": "เลย",
    "zipcode": 42190,
    "sub_district_code": 421402,
    "district_code": 4214,
    "province_code": 42
  },
  {
    "sub_district": "ปวนพุ",
    "district": "หนองหิน",
    "province": "เลย",
    "zipcode": 42190,
    "sub_district_code": 421403,
    "district_code": 4214,
    "province_code": 42
  },
  {
    "sub_district": "เมืองเหนือ",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330101,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "เมืองใต้",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330102,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "คูซอด",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330103,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "ซำ",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330104,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "จาน",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330105,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "ตะดอบ",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330106,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "หนองครก",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330107,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "โนนเพ็ก",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33230,
    "sub_district_code": 330193,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "พรหมสวัสดิ์",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33230,
    "sub_district_code": 330194,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "พยุห์",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33230,
    "sub_district_code": 330195,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "โพนข่า",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330111,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "โพนค้อ",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330112,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "ตำแย",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33230,
    "sub_district_code": 330196,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "โพนเขวา",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330115,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "หญ้าปล้อง",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330116,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "ทุ่ม",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330118,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "หนองไฮ",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330119,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "หนองแก้ว",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330121,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "น้ำคำ",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330122,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "โพธิ์",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330123,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "หมากเขียบ",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330124,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "หนองไผ่",
    "district": "เมืองศรีสะเกษ",
    "province": "ศรีสะเกษ",
    "zipcode": 33000,
    "sub_district_code": 330127,
    "district_code": 3301,
    "province_code": 33
  },
  {
    "sub_district": "บึงมะลู",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330401,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "กุดเสลา",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330402,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "เมือง",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330403,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "หนองหว้า",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330493,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "สังเม็ก",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330405,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "น้ำอ้อม",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330406,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "น้ำอ้อม",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330406,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "เวียงเหนือ",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330423,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "ละลาย",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330407,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "รุง",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330408,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "ตระกาจ",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330409,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "เสียว",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330494,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "จานใหญ่",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330411,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "ภูเงิน",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330412,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "ชำ",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330413,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "กระแชง",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330414,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "หนองหญ้าลาด",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330416,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "หนองงูเหลือม",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330495,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "โนนสำราญ",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330415,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "ท่าคล้อ",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330496,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "เสาธงชัย",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330419,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "ขนุน",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330420,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "ทุ่งใหญ่",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330424,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "หนองฮาง",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330498,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "เวียงเหนือ",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330423,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "สวนกล้วย",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330421,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "ภูผาหมอก",
    "district": "กันทรลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 330425,
    "district_code": 3304,
    "province_code": 33
  },
  {
    "sub_district": "ดูน",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330301,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "โนนสัง",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330302,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "หนองหัวช้าง",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330303,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "ยาง",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330304,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "หนองแวง",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330305,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "หนองแก้ว",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330306,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "ทาม",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330307,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "ละทาย",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330308,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "เมืองน้อย",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330309,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "อีปาด",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330310,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "บัวน้อย",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330311,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "หนองบัว",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330312,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "ดู่",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330313,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "ผักแพว",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330314,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "จาน",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330315,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "ตองปิด",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330398,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "คำเนียม",
    "district": "กันทรารมย์",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 330320,
    "district_code": 3303,
    "province_code": 33
  },
  {
    "sub_district": "กันทรารมย์",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330501,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "จะกง",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330502,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ใจดี",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330503,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ดองกำเม็ด",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330504,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "โสน",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330505,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ปรือใหญ่",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330506,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "สะเดาใหญ่",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330507,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ตาอุด",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330508,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ห้วยเหนือ",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330509,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ห้วยใต้",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330510,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "หัวเสือ",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330511,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ละลม",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330597,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ตะเคียน",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330513,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "โคกตาล",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330598,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "นิคมพัฒนา",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330515,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ห้วยตามอญ",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330596,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "สำโรงตาเจ็น",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330521,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "หนองฉลอง",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330526,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "กฤษณา",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330524,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "โคกเพชร",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330517,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ลมศักดิ์",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330525,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ปราสาท",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330518,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ห้วยสำราญ",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330522,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ศรีตระกูล",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330527,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "ศรีสะอาด",
    "district": "ขุขันธ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 330528,
    "district_code": 3305,
    "province_code": 33
  },
  {
    "sub_district": "สิ",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330801,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "บักดอง",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330802,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "พราน",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330803,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "โพธิ์วงศ์",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330804,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "ไพร",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330805,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "กระหวัน",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330806,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "ขุนหาญ",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330807,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "โนนสูง",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330808,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "กันทรอม",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330809,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "ภูฝ้าย",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330810,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "โพธิ์กระสังข์",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330811,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "ห้วยจันทร์",
    "district": "ขุนหาญ",
    "province": "ศรีสะเกษ",
    "zipcode": 33150,
    "sub_district_code": 330812,
    "district_code": 3308,
    "province_code": 33
  },
  {
    "sub_district": "พิมาย",
    "district": "ปรางค์กู่",
    "province": "ศรีสะเกษ",
    "zipcode": 33170,
    "sub_district_code": 330701,
    "district_code": 3307,
    "province_code": 33
  },
  {
    "sub_district": "กู่",
    "district": "ปรางค์กู่",
    "province": "ศรีสะเกษ",
    "zipcode": 33170,
    "sub_district_code": 330702,
    "district_code": 3307,
    "province_code": 33
  },
  {
    "sub_district": "หนองเชียงทูน",
    "district": "ปรางค์กู่",
    "province": "ศรีสะเกษ",
    "zipcode": 33170,
    "sub_district_code": 330703,
    "district_code": 3307,
    "province_code": 33
  },
  {
    "sub_district": "ตูม",
    "district": "ปรางค์กู่",
    "province": "ศรีสะเกษ",
    "zipcode": 33170,
    "sub_district_code": 330704,
    "district_code": 3307,
    "province_code": 33
  },
  {
    "sub_district": "สมอ",
    "district": "ปรางค์กู่",
    "province": "ศรีสะเกษ",
    "zipcode": 33170,
    "sub_district_code": 330705,
    "district_code": 3307,
    "province_code": 33
  },
  {
    "sub_district": "โพธิ์ศรี",
    "district": "ปรางค์กู่",
    "province": "ศรีสะเกษ",
    "zipcode": 33170,
    "sub_district_code": 330706,
    "district_code": 3307,
    "province_code": 33
  },
  {
    "sub_district": "สำโรงปราสาท",
    "district": "ปรางค์กู่",
    "province": "ศรีสะเกษ",
    "zipcode": 33170,
    "sub_district_code": 330707,
    "district_code": 3307,
    "province_code": 33
  },
  {
    "sub_district": "ดู่",
    "district": "ปรางค์กู่",
    "province": "ศรีสะเกษ",
    "zipcode": 33170,
    "sub_district_code": 330708,
    "district_code": 3307,
    "province_code": 33
  },
  {
    "sub_district": "สวาย",
    "district": "ปรางค์กู่",
    "province": "ศรีสะเกษ",
    "zipcode": 33170,
    "sub_district_code": 330709,
    "district_code": 3307,
    "province_code": 33
  },
  {
    "sub_district": "พิมายเหนือ",
    "district": "ปรางค์กู่",
    "province": "ศรีสะเกษ",
    "zipcode": 33170,
    "sub_district_code": 330710,
    "district_code": 3307,
    "province_code": 33
  },
  {
    "sub_district": "ไพรบึง",
    "district": "ไพรบึง",
    "province": "ศรีสะเกษ",
    "zipcode": 33180,
    "sub_district_code": 330601,
    "district_code": 3306,
    "province_code": 33
  },
  {
    "sub_district": "ดินแดง",
    "district": "ไพรบึง",
    "province": "ศรีสะเกษ",
    "zipcode": 33180,
    "sub_district_code": 330602,
    "district_code": 3306,
    "province_code": 33
  },
  {
    "sub_district": "ปราสาทเยอ",
    "district": "ไพรบึง",
    "province": "ศรีสะเกษ",
    "zipcode": 33180,
    "sub_district_code": 330603,
    "district_code": 3306,
    "province_code": 33
  },
  {
    "sub_district": "สำโรงพลัน",
    "district": "ไพรบึง",
    "province": "ศรีสะเกษ",
    "zipcode": 33180,
    "sub_district_code": 330604,
    "district_code": 3306,
    "province_code": 33
  },
  {
    "sub_district": "สุขสวัสดิ์",
    "district": "ไพรบึง",
    "province": "ศรีสะเกษ",
    "zipcode": 33180,
    "sub_district_code": 330605,
    "district_code": 3306,
    "province_code": 33
  },
  {
    "sub_district": "โนนปูน",
    "district": "ไพรบึง",
    "province": "ศรีสะเกษ",
    "zipcode": 33180,
    "sub_district_code": 330606,
    "district_code": 3306,
    "province_code": 33
  },
  {
    "sub_district": "ยางชุมน้อย",
    "district": "ยางชุมน้อย",
    "province": "ศรีสะเกษ",
    "zipcode": 33190,
    "sub_district_code": 330201,
    "district_code": 3302,
    "province_code": 33
  },
  {
    "sub_district": "ลิ้นฟ้า",
    "district": "ยางชุมน้อย",
    "province": "ศรีสะเกษ",
    "zipcode": 33190,
    "sub_district_code": 330202,
    "district_code": 3302,
    "province_code": 33
  },
  {
    "sub_district": "คอนกาม",
    "district": "ยางชุมน้อย",
    "province": "ศรีสะเกษ",
    "zipcode": 33190,
    "sub_district_code": 330203,
    "district_code": 3302,
    "province_code": 33
  },
  {
    "sub_district": "โนนคูณ",
    "district": "ยางชุมน้อย",
    "province": "ศรีสะเกษ",
    "zipcode": 33190,
    "sub_district_code": 330204,
    "district_code": 3302,
    "province_code": 33
  },
  {
    "sub_district": "กุดเมืองฮาม",
    "district": "ยางชุมน้อย",
    "province": "ศรีสะเกษ",
    "zipcode": 33190,
    "sub_district_code": 330205,
    "district_code": 3302,
    "province_code": 33
  },
  {
    "sub_district": "บึงบอน",
    "district": "ยางชุมน้อย",
    "province": "ศรีสะเกษ",
    "zipcode": 33190,
    "sub_district_code": 330206,
    "district_code": 3302,
    "province_code": 33
  },
  {
    "sub_district": "ยางชุมใหญ่",
    "district": "ยางชุมน้อย",
    "province": "ศรีสะเกษ",
    "zipcode": 33190,
    "sub_district_code": 330207,
    "district_code": 3302,
    "province_code": 33
  },
  {
    "sub_district": "เมืองคง",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330901,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "เมืองแคน",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330902,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "หนองแค",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330903,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "กุง",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330997,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "คลีกลิ้ง",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330998,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "จิกสังข์ทอง",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330906,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "ด่าน",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330907,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "ดู่",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330908,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "หนองอึ่ง",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330909,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "บัวหุ่ง",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330910,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "ไผ่",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330911,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "ส้มป่อย",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330912,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "หนองหมี",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330913,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "หว้านคำ",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330914,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "สร้างปี่",
    "district": "ราษีไศล",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 330915,
    "district_code": 3309,
    "province_code": 33
  },
  {
    "sub_district": "กำแพง",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331001,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "อี่หล่ำ",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331002,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "ก้านเหลือง",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331003,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "ทุ่งไชย",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331004,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "สำโรง",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331005,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "แขม",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331006,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "หนองไฮ",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331007,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "ขะยูง",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331008,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "ตาโกน",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331091,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "ตาเกษ",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331010,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "หัวช้าง",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331011,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "รังแร้ง",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331012,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "เมืองจันทร์",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331092,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "แต้",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331014,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "แข้",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331015,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "โพธิ์ชัย",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331016,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "ปะอาว",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331017,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "หนองห้าง",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331018,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "โดด",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331093,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "เสียว",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331094,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "หนองม้า",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331095,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "สระกำแพงใหญ่",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331022,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "หนองใหญ่",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331096,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "โคกจาน",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331025,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "โคกหล่าม",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331024,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "เมืองคง",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 331097,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "ผือใหญ่",
    "district": "อุทุมพรพิสัย",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331098,
    "district_code": 3310,
    "province_code": 33
  },
  {
    "sub_district": "ห้วยทับทัน",
    "district": "ห้วยทับทัน",
    "province": "ศรีสะเกษ",
    "zipcode": 33210,
    "sub_district_code": 331201,
    "district_code": 3312,
    "province_code": 33
  },
  {
    "sub_district": "เมืองหลวง",
    "district": "ห้วยทับทัน",
    "province": "ศรีสะเกษ",
    "zipcode": 33210,
    "sub_district_code": 331202,
    "district_code": 3312,
    "province_code": 33
  },
  {
    "sub_district": "กล้วยกว้าง",
    "district": "ห้วยทับทัน",
    "province": "ศรีสะเกษ",
    "zipcode": 33210,
    "sub_district_code": 331203,
    "district_code": 3312,
    "province_code": 33
  },
  {
    "sub_district": "ผักไหม",
    "district": "ห้วยทับทัน",
    "province": "ศรีสะเกษ",
    "zipcode": 33210,
    "sub_district_code": 331204,
    "district_code": 3312,
    "province_code": 33
  },
  {
    "sub_district": "จานแสนไชย",
    "district": "ห้วยทับทัน",
    "province": "ศรีสะเกษ",
    "zipcode": 33210,
    "sub_district_code": 331205,
    "district_code": 3312,
    "province_code": 33
  },
  {
    "sub_district": "ปราสาท",
    "district": "ห้วยทับทัน",
    "province": "ศรีสะเกษ",
    "zipcode": 33210,
    "sub_district_code": 331206,
    "district_code": 3312,
    "province_code": 33
  },
  {
    "sub_district": "โนนค้อ",
    "district": "โนนคูณ",
    "province": "ศรีสะเกษ",
    "zipcode": 33250,
    "sub_district_code": 331301,
    "district_code": 3313,
    "province_code": 33
  },
  {
    "sub_district": "บก",
    "district": "โนนคูณ",
    "province": "ศรีสะเกษ",
    "zipcode": 33250,
    "sub_district_code": 331302,
    "district_code": 3313,
    "province_code": 33
  },
  {
    "sub_district": "โพธิ์",
    "district": "โนนคูณ",
    "province": "ศรีสะเกษ",
    "zipcode": 33250,
    "sub_district_code": 331303,
    "district_code": 3313,
    "province_code": 33
  },
  {
    "sub_district": "หนองกุง",
    "district": "โนนคูณ",
    "province": "ศรีสะเกษ",
    "zipcode": 33250,
    "sub_district_code": 331304,
    "district_code": 3313,
    "province_code": 33
  },
  {
    "sub_district": "เหล่ากวาง",
    "district": "โนนคูณ",
    "province": "ศรีสะเกษ",
    "zipcode": 33250,
    "sub_district_code": 331305,
    "district_code": 3313,
    "province_code": 33
  },
  {
    "sub_district": "พิงพวย",
    "district": "ศรีรัตนะ",
    "province": "ศรีสะเกษ",
    "zipcode": 33240,
    "sub_district_code": 331402,
    "district_code": 3314,
    "province_code": 33
  },
  {
    "sub_district": "ศรีแก้ว",
    "district": "ศรีรัตนะ",
    "province": "ศรีสะเกษ",
    "zipcode": 33240,
    "sub_district_code": 331401,
    "district_code": 3314,
    "province_code": 33
  },
  {
    "sub_district": "สระเยาว์",
    "district": "ศรีรัตนะ",
    "province": "ศรีสะเกษ",
    "zipcode": 33240,
    "sub_district_code": 331403,
    "district_code": 3314,
    "province_code": 33
  },
  {
    "sub_district": "ตูม",
    "district": "ศรีรัตนะ",
    "province": "ศรีสะเกษ",
    "zipcode": 33240,
    "sub_district_code": 331404,
    "district_code": 3314,
    "province_code": 33
  },
  {
    "sub_district": "เสื่องข้าว",
    "district": "ศรีรัตนะ",
    "province": "ศรีสะเกษ",
    "zipcode": 33240,
    "sub_district_code": 331405,
    "district_code": 3314,
    "province_code": 33
  },
  {
    "sub_district": "สะพุง",
    "district": "ศรีรัตนะ",
    "province": "ศรีสะเกษ",
    "zipcode": 33240,
    "sub_district_code": 331407,
    "district_code": 3314,
    "province_code": 33
  },
  {
    "sub_district": "ศรีโนนงาม",
    "district": "ศรีรัตนะ",
    "province": "ศรีสะเกษ",
    "zipcode": 33240,
    "sub_district_code": 331406,
    "district_code": 3314,
    "province_code": 33
  },
  {
    "sub_district": "เป๊าะ",
    "district": "บึงบูรพ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33220,
    "sub_district_code": 331101,
    "district_code": 3311,
    "province_code": 33
  },
  {
    "sub_district": "บึงบูรพ์",
    "district": "บึงบูรพ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33220,
    "sub_district_code": 331102,
    "district_code": 3311,
    "province_code": 33
  },
  {
    "sub_district": "หนองม้า",
    "district": "บึงบูรพ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331198,
    "district_code": 3311,
    "province_code": 33
  },
  {
    "sub_district": "น้ำเกลี้ยง",
    "district": "น้ำเกลี้ยง",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 331501,
    "district_code": 3315,
    "province_code": 33
  },
  {
    "sub_district": "ละเอาะ",
    "district": "น้ำเกลี้ยง",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 331502,
    "district_code": 3315,
    "province_code": 33
  },
  {
    "sub_district": "ตองปิด",
    "district": "น้ำเกลี้ยง",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 331503,
    "district_code": 3315,
    "province_code": 33
  },
  {
    "sub_district": "เขิน",
    "district": "น้ำเกลี้ยง",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 331504,
    "district_code": 3315,
    "province_code": 33
  },
  {
    "sub_district": "คูบ",
    "district": "น้ำเกลี้ยง",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 331506,
    "district_code": 3315,
    "province_code": 33
  },
  {
    "sub_district": "รุ่งระวี",
    "district": "น้ำเกลี้ยง",
    "province": "ศรีสะเกษ",
    "zipcode": 33130,
    "sub_district_code": 331505,
    "district_code": 3315,
    "province_code": 33
  },
  {
    "sub_district": "บุสูง",
    "district": "วังหิน",
    "province": "ศรีสะเกษ",
    "zipcode": 33270,
    "sub_district_code": 331601,
    "district_code": 3316,
    "province_code": 33
  },
  {
    "sub_district": "ธาตุ",
    "district": "วังหิน",
    "province": "ศรีสะเกษ",
    "zipcode": 33270,
    "sub_district_code": 331602,
    "district_code": 3316,
    "province_code": 33
  },
  {
    "sub_district": "ดวนใหญ่",
    "district": "วังหิน",
    "province": "ศรีสะเกษ",
    "zipcode": 33270,
    "sub_district_code": 331603,
    "district_code": 3316,
    "province_code": 33
  },
  {
    "sub_district": "บ่อแก้ว",
    "district": "วังหิน",
    "province": "ศรีสะเกษ",
    "zipcode": 33270,
    "sub_district_code": 331604,
    "district_code": 3316,
    "province_code": 33
  },
  {
    "sub_district": "ศรีสำราญ",
    "district": "วังหิน",
    "province": "ศรีสะเกษ",
    "zipcode": 33270,
    "sub_district_code": 331605,
    "district_code": 3316,
    "province_code": 33
  },
  {
    "sub_district": "ทุ่งสว่าง",
    "district": "วังหิน",
    "province": "ศรีสะเกษ",
    "zipcode": 33270,
    "sub_district_code": 331606,
    "district_code": 3316,
    "province_code": 33
  },
  {
    "sub_district": "วังหิน",
    "district": "วังหิน",
    "province": "ศรีสะเกษ",
    "zipcode": 33270,
    "sub_district_code": 331607,
    "district_code": 3316,
    "province_code": 33
  },
  {
    "sub_district": "โพนยาง",
    "district": "วังหิน",
    "province": "ศรีสะเกษ",
    "zipcode": 33270,
    "sub_district_code": 331608,
    "district_code": 3316,
    "province_code": 33
  },
  {
    "sub_district": "ห้วยตึ๊กชู",
    "district": "ภูสิงห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 331703,
    "district_code": 3317,
    "province_code": 33
  },
  {
    "sub_district": "โคกตาล",
    "district": "ภูสิงห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 331701,
    "district_code": 3317,
    "province_code": 33
  },
  {
    "sub_district": "ดงรัก",
    "district": "ภูสิงห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 331706,
    "district_code": 3317,
    "province_code": 33
  },
  {
    "sub_district": "ตะเคียนราม",
    "district": "ภูสิงห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 331705,
    "district_code": 3317,
    "province_code": 33
  },
  {
    "sub_district": "ละลม",
    "district": "ภูสิงห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 331704,
    "district_code": 3317,
    "province_code": 33
  },
  {
    "sub_district": "ห้วยตามอญ",
    "district": "ภูสิงห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 331702,
    "district_code": 3317,
    "province_code": 33
  },
  {
    "sub_district": "ไพรพัฒนา",
    "district": "ภูสิงห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33140,
    "sub_district_code": 331707,
    "district_code": 3317,
    "province_code": 33
  },
  {
    "sub_district": "เมืองจันทร์",
    "district": "เมืองจันทร์",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331801,
    "district_code": 3318,
    "province_code": 33
  },
  {
    "sub_district": "หนองใหญ่",
    "district": "เมืองจันทร์",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331803,
    "district_code": 3318,
    "province_code": 33
  },
  {
    "sub_district": "ตาโกน",
    "district": "เมืองจันทร์",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 331802,
    "district_code": 3318,
    "province_code": 33
  },
  {
    "sub_district": "พยุห์",
    "district": "พยุห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33230,
    "sub_district_code": 332001,
    "district_code": 3320,
    "province_code": 33
  },
  {
    "sub_district": "โนนเพ็ก",
    "district": "พยุห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33230,
    "sub_district_code": 332004,
    "district_code": 3320,
    "province_code": 33
  },
  {
    "sub_district": "พรหมสวัสดิ์",
    "district": "พยุห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33230,
    "sub_district_code": 332002,
    "district_code": 3320,
    "province_code": 33
  },
  {
    "sub_district": "ตำแย",
    "district": "พยุห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33230,
    "sub_district_code": 332003,
    "district_code": 3320,
    "province_code": 33
  },
  {
    "sub_district": "หนองค้า",
    "district": "พยุห์",
    "province": "ศรีสะเกษ",
    "zipcode": 33230,
    "sub_district_code": 332005,
    "district_code": 3320,
    "province_code": 33
  },
  {
    "sub_district": "โดด",
    "district": "โพธิ์ศรีสุวรรณ",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 332101,
    "district_code": 3321,
    "province_code": 33
  },
  {
    "sub_district": "อีเซ",
    "district": "โพธิ์ศรีสุวรรณ",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 332105,
    "district_code": 3321,
    "province_code": 33
  },
  {
    "sub_district": "เสียว",
    "district": "โพธิ์ศรีสุวรรณ",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 332102,
    "district_code": 3321,
    "province_code": 33
  },
  {
    "sub_district": "หนองม้า",
    "district": "โพธิ์ศรีสุวรรณ",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 332103,
    "district_code": 3321,
    "province_code": 33
  },
  {
    "sub_district": "ผือใหญ่",
    "district": "โพธิ์ศรีสุวรรณ",
    "province": "ศรีสะเกษ",
    "zipcode": 33120,
    "sub_district_code": 332104,
    "district_code": 3321,
    "province_code": 33
  },
  {
    "sub_district": "เสียว",
    "district": "เบญจลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 331901,
    "district_code": 3319,
    "province_code": 33
  },
  {
    "sub_district": "ท่าคล้อ",
    "district": "เบญจลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 331905,
    "district_code": 3319,
    "province_code": 33
  },
  {
    "sub_district": "หนองงูเหลือม",
    "district": "เบญจลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 331903,
    "district_code": 3319,
    "province_code": 33
  },
  {
    "sub_district": "หนองหว้า",
    "district": "เบญจลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 331902,
    "district_code": 3319,
    "province_code": 33
  },
  {
    "sub_district": "หนองฮาง",
    "district": "เบญจลักษ์",
    "province": "ศรีสะเกษ",
    "zipcode": 33110,
    "sub_district_code": 331904,
    "district_code": 3319,
    "province_code": 33
  },
  {
    "sub_district": "กุง",
    "district": "ศิลาลาด",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 332201,
    "district_code": 3322,
    "province_code": 33
  },
  {
    "sub_district": "คลีกกลิ้ง",
    "district": "ศิลาลาด",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 332202,
    "district_code": 3322,
    "province_code": 33
  },
  {
    "sub_district": "หนองบัวดง",
    "district": "ศิลาลาด",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 332203,
    "district_code": 3322,
    "province_code": 33
  },
  {
    "sub_district": "โจดม่วง",
    "district": "ศิลาลาด",
    "province": "ศรีสะเกษ",
    "zipcode": 33160,
    "sub_district_code": 332204,
    "district_code": 3322,
    "province_code": 33
  },
  {
    "sub_district": "ธาตุเชิงชุม",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470101,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "ขมิ้น",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47220,
    "sub_district_code": 470102,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "งิ้วด่อน",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470103,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "โนนหอม",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470104,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "ด่านม่วงคำ",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47280,
    "sub_district_code": 470193,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "นาตงวัฒนา",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 470194,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "เหล่าโพนค้อ",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47280,
    "sub_district_code": 470195,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "เชียงเครือ",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470106,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "ท่าแร่",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 470107,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "บ้านโพน",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 471796,
    "district_code": 4717,
    "province_code": 47
  },
  {
    "sub_district": "ม่วงลาย",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470109,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "กกปลาซิว",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 470197,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "ดงชน",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470111,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "ห้วยยาง",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470112,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "พังขว้าง",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470113,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "ดงมะไฟ",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470115,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "ธาตุนาเวง",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470116,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "เหล่าปอแดง",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470117,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "หนองลาด",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47220,
    "sub_district_code": 470118,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "บ้านแป้น",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 470198,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "ฮางโฮง",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470120,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "พังขว้าง",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470113,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "โคกก่อง",
    "district": "เมืองสกลนคร",
    "province": "สกลนคร",
    "zipcode": 47000,
    "sub_district_code": 470121,
    "district_code": 4701,
    "province_code": 47
  },
  {
    "sub_district": "กุดบาก",
    "district": "กุดบาก",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 470301,
    "district_code": 4703,
    "province_code": 47
  },
  {
    "sub_district": "โคกภู",
    "district": "กุดบาก",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 470396,
    "district_code": 4703,
    "province_code": 47
  },
  {
    "sub_district": "นาม่อง",
    "district": "กุดบาก",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 470303,
    "district_code": 4703,
    "province_code": 47
  },
  {
    "sub_district": "สร้างค้อ",
    "district": "กุดบาก",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 470397,
    "district_code": 4703,
    "province_code": 47
  },
  {
    "sub_district": "กุดไห",
    "district": "กุดบาก",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 470305,
    "district_code": 4703,
    "province_code": 47
  },
  {
    "sub_district": "หลุบเลา",
    "district": "กุดบาก",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 470398,
    "district_code": 4703,
    "province_code": 47
  },
  {
    "sub_district": "กุสุมาลย์",
    "district": "กุสุมาลย์",
    "province": "สกลนคร",
    "zipcode": 47210,
    "sub_district_code": 470201,
    "district_code": 4702,
    "province_code": 47
  },
  {
    "sub_district": "นาโพธิ์",
    "district": "กุสุมาลย์",
    "province": "สกลนคร",
    "zipcode": 47210,
    "sub_district_code": 470202,
    "district_code": 4702,
    "province_code": 47
  },
  {
    "sub_district": "นาเพียง",
    "district": "กุสุมาลย์",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 470203,
    "district_code": 4702,
    "province_code": 47
  },
  {
    "sub_district": "โพธิไพศาล",
    "district": "กุสุมาลย์",
    "province": "สกลนคร",
    "zipcode": 47210,
    "sub_district_code": 470204,
    "district_code": 4702,
    "province_code": 47
  },
  {
    "sub_district": "อุ่มจาน",
    "district": "กุสุมาลย์",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 470205,
    "district_code": 4702,
    "province_code": 47
  },
  {
    "sub_district": "ม่วง",
    "district": "บ้านม่วง",
    "province": "สกลนคร",
    "zipcode": 47140,
    "sub_district_code": 471001,
    "district_code": 4710,
    "province_code": 47
  },
  {
    "sub_district": "มาย",
    "district": "บ้านม่วง",
    "province": "สกลนคร",
    "zipcode": 47140,
    "sub_district_code": 471002,
    "district_code": 4710,
    "province_code": 47
  },
  {
    "sub_district": "ดงหม้อทอง",
    "district": "บ้านม่วง",
    "province": "สกลนคร",
    "zipcode": 47140,
    "sub_district_code": 471003,
    "district_code": 4710,
    "province_code": 47
  },
  {
    "sub_district": "ดงเหนือ",
    "district": "บ้านม่วง",
    "province": "สกลนคร",
    "zipcode": 47140,
    "sub_district_code": 471004,
    "district_code": 4710,
    "province_code": 47
  },
  {
    "sub_district": "ดงหม้อทองใต้",
    "district": "บ้านม่วง",
    "province": "สกลนคร",
    "zipcode": 47140,
    "sub_district_code": 471005,
    "district_code": 4710,
    "province_code": 47
  },
  {
    "sub_district": "ห้วยหลัว",
    "district": "บ้านม่วง",
    "province": "สกลนคร",
    "zipcode": 47140,
    "sub_district_code": 471006,
    "district_code": 4710,
    "province_code": 47
  },
  {
    "sub_district": "โนนสะอาด",
    "district": "บ้านม่วง",
    "province": "สกลนคร",
    "zipcode": 47140,
    "sub_district_code": 471007,
    "district_code": 4710,
    "province_code": 47
  },
  {
    "sub_district": "บ่อแก้ว",
    "district": "บ้านม่วง",
    "province": "สกลนคร",
    "zipcode": 47140,
    "sub_district_code": 471009,
    "district_code": 4710,
    "province_code": 47
  },
  {
    "sub_district": "หนองกวั่ง",
    "district": "บ้านม่วง",
    "province": "สกลนคร",
    "zipcode": 47140,
    "sub_district_code": 471008,
    "district_code": 4710,
    "province_code": 47
  },
  {
    "sub_district": "พรรณา",
    "district": "พรรณานิคม",
    "province": "สกลนคร",
    "zipcode": 47130,
    "sub_district_code": 470401,
    "district_code": 4704,
    "province_code": 47
  },
  {
    "sub_district": "วังยาง",
    "district": "พรรณานิคม",
    "province": "สกลนคร",
    "zipcode": 47130,
    "sub_district_code": 470402,
    "district_code": 4704,
    "province_code": 47
  },
  {
    "sub_district": "พอกน้อย",
    "district": "พรรณานิคม",
    "province": "สกลนคร",
    "zipcode": 47220,
    "sub_district_code": 470403,
    "district_code": 4704,
    "province_code": 47
  },
  {
    "sub_district": "นาหัวบ่อ",
    "district": "พรรณานิคม",
    "province": "สกลนคร",
    "zipcode": 47220,
    "sub_district_code": 470404,
    "district_code": 4704,
    "province_code": 47
  },
  {
    "sub_district": "ไร่",
    "district": "พรรณานิคม",
    "province": "สกลนคร",
    "zipcode": 47130,
    "sub_district_code": 470405,
    "district_code": 4704,
    "province_code": 47
  },
  {
    "sub_district": "ช้างมิ่ง",
    "district": "พรรณานิคม",
    "province": "สกลนคร",
    "zipcode": 47130,
    "sub_district_code": 470406,
    "district_code": 4704,
    "province_code": 47
  },
  {
    "sub_district": "นาใน",
    "district": "พรรณานิคม",
    "province": "สกลนคร",
    "zipcode": 47130,
    "sub_district_code": 470407,
    "district_code": 4704,
    "province_code": 47
  },
  {
    "sub_district": "สว่าง",
    "district": "พรรณานิคม",
    "province": "สกลนคร",
    "zipcode": 47130,
    "sub_district_code": 470408,
    "district_code": 4704,
    "province_code": 47
  },
  {
    "sub_district": "บะฮี",
    "district": "พรรณานิคม",
    "province": "สกลนคร",
    "zipcode": 47130,
    "sub_district_code": 470409,
    "district_code": 4704,
    "province_code": 47
  },
  {
    "sub_district": "เชิงชุม",
    "district": "พรรณานิคม",
    "province": "สกลนคร",
    "zipcode": 47130,
    "sub_district_code": 470410,
    "district_code": 4704,
    "province_code": 47
  },
  {
    "sub_district": "พังโคน",
    "district": "พังโคน",
    "province": "สกลนคร",
    "zipcode": 47160,
    "sub_district_code": 470501,
    "district_code": 4705,
    "province_code": 47
  },
  {
    "sub_district": "ม่วงไข่",
    "district": "พังโคน",
    "province": "สกลนคร",
    "zipcode": 47160,
    "sub_district_code": 470502,
    "district_code": 4705,
    "province_code": 47
  },
  {
    "sub_district": "แร่",
    "district": "พังโคน",
    "province": "สกลนคร",
    "zipcode": 47160,
    "sub_district_code": 470503,
    "district_code": 4705,
    "province_code": 47
  },
  {
    "sub_district": "ไฮหย่อง",
    "district": "พังโคน",
    "province": "สกลนคร",
    "zipcode": 47160,
    "sub_district_code": 470504,
    "district_code": 4705,
    "province_code": 47
  },
  {
    "sub_district": "ต้นผึ้ง",
    "district": "พังโคน",
    "province": "สกลนคร",
    "zipcode": 47160,
    "sub_district_code": 470505,
    "district_code": 4705,
    "province_code": 47
  },
  {
    "sub_district": "วานรนิวาส",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470801,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "เดื่อศรีคันไชย",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470802,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "ขัวก่าย",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470803,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "หนองสนม",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470804,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "คูสะคาม",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470805,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "ธาตุ",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470806,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "หนองแวง",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470807,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "ศรีวิชัย",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470808,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "นาซอ",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470809,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "อินทร์แปลง",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470810,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "นาคำ",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470811,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "กุดเรือคำ",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470813,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "คอนสวรรค์",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470812,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "หนองแวงใต้",
    "district": "วานรนิวาส",
    "province": "สกลนคร",
    "zipcode": 47120,
    "sub_district_code": 470814,
    "district_code": 4708,
    "province_code": 47
  },
  {
    "sub_district": "วาริชภูมิ",
    "district": "วาริชภูมิ",
    "province": "สกลนคร",
    "zipcode": 47150,
    "sub_district_code": 470601,
    "district_code": 4706,
    "province_code": 47
  },
  {
    "sub_district": "ปลาโหล",
    "district": "วาริชภูมิ",
    "province": "สกลนคร",
    "zipcode": 47150,
    "sub_district_code": 470602,
    "district_code": 4706,
    "province_code": 47
  },
  {
    "sub_district": "หนองลาด",
    "district": "วาริชภูมิ",
    "province": "สกลนคร",
    "zipcode": 47150,
    "sub_district_code": 470603,
    "district_code": 4706,
    "province_code": 47
  },
  {
    "sub_district": "คำบ่อ",
    "district": "วาริชภูมิ",
    "province": "สกลนคร",
    "zipcode": 47150,
    "sub_district_code": 470604,
    "district_code": 4706,
    "province_code": 47
  },
  {
    "sub_district": "ค้อเขียว",
    "district": "วาริชภูมิ",
    "province": "สกลนคร",
    "zipcode": 47150,
    "sub_district_code": 470605,
    "district_code": 4706,
    "province_code": 47
  },
  {
    "sub_district": "สว่างแดนดิน",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471201,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "บงเหนือ",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471206,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "โพนสูง",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471207,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "ทุ่งแก",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47290,
    "sub_district_code": 471294,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "หนองหลวง",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471210,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "บงใต้",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471211,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "ค้อใต้",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471212,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "พันนา",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47240,
    "sub_district_code": 471213,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "แวง",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47240,
    "sub_district_code": 471214,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "ทรายมูล",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471215,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "ตาลโกน",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47240,
    "sub_district_code": 471216,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "ตาลเนิ้ง",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47240,
    "sub_district_code": 471217,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "โคกศิลา",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47290,
    "sub_district_code": 471295,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "คำสะอาด",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471203,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "บ้านเหล่า",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47290,
    "sub_district_code": 471296,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "บ้านต้าย",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471204,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "เจริญศิลป์",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47290,
    "sub_district_code": 471297,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "หนองแปน",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47290,
    "sub_district_code": 471298,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "ธาตุทอง",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47240,
    "sub_district_code": 471220,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "โคกสี",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471208,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "บ้านถ่อน",
    "district": "สว่างแดนดิน",
    "province": "สกลนคร",
    "zipcode": 47110,
    "sub_district_code": 471221,
    "district_code": 4712,
    "province_code": 47
  },
  {
    "sub_district": "ส่องดาว",
    "district": "ส่องดาว",
    "province": "สกลนคร",
    "zipcode": 47190,
    "sub_district_code": 471301,
    "district_code": 4713,
    "province_code": 47
  },
  {
    "sub_district": "ท่าศิลา",
    "district": "ส่องดาว",
    "province": "สกลนคร",
    "zipcode": 47190,
    "sub_district_code": 471302,
    "district_code": 4713,
    "province_code": 47
  },
  {
    "sub_district": "วัฒนา",
    "district": "ส่องดาว",
    "province": "สกลนคร",
    "zipcode": 47190,
    "sub_district_code": 471303,
    "district_code": 4713,
    "province_code": 47
  },
  {
    "sub_district": "ปทุมวาปี",
    "district": "ส่องดาว",
    "province": "สกลนคร",
    "zipcode": 47190,
    "sub_district_code": 471304,
    "district_code": 4713,
    "province_code": 47
  },
  {
    "sub_district": "อากาศ",
    "district": "อากาศอำนวย",
    "province": "สกลนคร",
    "zipcode": 47170,
    "sub_district_code": 471101,
    "district_code": 4711,
    "province_code": 47
  },
  {
    "sub_district": "โพนแพง",
    "district": "อากาศอำนวย",
    "province": "สกลนคร",
    "zipcode": 47170,
    "sub_district_code": 471102,
    "district_code": 4711,
    "province_code": 47
  },
  {
    "sub_district": "วาใหญ่",
    "district": "อากาศอำนวย",
    "province": "สกลนคร",
    "zipcode": 47170,
    "sub_district_code": 471103,
    "district_code": 4711,
    "province_code": 47
  },
  {
    "sub_district": "โพนงาม",
    "district": "อากาศอำนวย",
    "province": "สกลนคร",
    "zipcode": 47170,
    "sub_district_code": 471104,
    "district_code": 4711,
    "province_code": 47
  },
  {
    "sub_district": "ท่าก้อน",
    "district": "อากาศอำนวย",
    "province": "สกลนคร",
    "zipcode": 47170,
    "sub_district_code": 471105,
    "district_code": 4711,
    "province_code": 47
  },
  {
    "sub_district": "สามัคคีพัฒนา",
    "district": "อากาศอำนวย",
    "province": "สกลนคร",
    "zipcode": 47170,
    "sub_district_code": 471108,
    "district_code": 4711,
    "province_code": 47
  },
  {
    "sub_district": "บะหว้า",
    "district": "อากาศอำนวย",
    "province": "สกลนคร",
    "zipcode": 47170,
    "sub_district_code": 471107,
    "district_code": 4711,
    "province_code": 47
  },
  {
    "sub_district": "นาฮี",
    "district": "อากาศอำนวย",
    "province": "สกลนคร",
    "zipcode": 47170,
    "sub_district_code": 471106,
    "district_code": 4711,
    "province_code": 47
  },
  {
    "sub_district": "นิคมน้ำอูน",
    "district": "นิคมน้ำอูน",
    "province": "สกลนคร",
    "zipcode": 47270,
    "sub_district_code": 470701,
    "district_code": 4707,
    "province_code": 47
  },
  {
    "sub_district": "หนองปลิง",
    "district": "นิคมน้ำอูน",
    "province": "สกลนคร",
    "zipcode": 47270,
    "sub_district_code": 470702,
    "district_code": 4707,
    "province_code": 47
  },
  {
    "sub_district": "หนองบัว",
    "district": "นิคมน้ำอูน",
    "province": "สกลนคร",
    "zipcode": 47270,
    "sub_district_code": 470703,
    "district_code": 4707,
    "province_code": 47
  },
  {
    "sub_district": "สุวรรณคาม",
    "district": "นิคมน้ำอูน",
    "province": "สกลนคร",
    "zipcode": 47270,
    "sub_district_code": 470704,
    "district_code": 4707,
    "province_code": 47
  },
  {
    "sub_district": "คำตากล้า",
    "district": "คำตากล้า",
    "province": "สกลนคร",
    "zipcode": 47250,
    "sub_district_code": 470901,
    "district_code": 4709,
    "province_code": 47
  },
  {
    "sub_district": "หนองบัวสิม",
    "district": "คำตากล้า",
    "province": "สกลนคร",
    "zipcode": 47250,
    "sub_district_code": 470902,
    "district_code": 4709,
    "province_code": 47
  },
  {
    "sub_district": "นาแต้",
    "district": "คำตากล้า",
    "province": "สกลนคร",
    "zipcode": 47250,
    "sub_district_code": 470903,
    "district_code": 4709,
    "province_code": 47
  },
  {
    "sub_district": "แพด",
    "district": "คำตากล้า",
    "province": "สกลนคร",
    "zipcode": 47250,
    "sub_district_code": 470904,
    "district_code": 4709,
    "province_code": 47
  },
  {
    "sub_district": "เต่างอย",
    "district": "เต่างอย",
    "province": "สกลนคร",
    "zipcode": 47260,
    "sub_district_code": 471401,
    "district_code": 4714,
    "province_code": 47
  },
  {
    "sub_district": "บึงทวาย",
    "district": "เต่างอย",
    "province": "สกลนคร",
    "zipcode": 47260,
    "sub_district_code": 471402,
    "district_code": 4714,
    "province_code": 47
  },
  {
    "sub_district": "นาตาล",
    "district": "เต่างอย",
    "province": "สกลนคร",
    "zipcode": 47260,
    "sub_district_code": 471403,
    "district_code": 4714,
    "province_code": 47
  },
  {
    "sub_district": "จันทร์เพ็ญ",
    "district": "เต่างอย",
    "province": "สกลนคร",
    "zipcode": 47260,
    "sub_district_code": 471404,
    "district_code": 4714,
    "province_code": 47
  },
  {
    "sub_district": "เหล่าโพนค้อ",
    "district": "โคกศรีสุพรรณ",
    "province": "สกลนคร",
    "zipcode": 47280,
    "sub_district_code": 471502,
    "district_code": 4715,
    "province_code": 47
  },
  {
    "sub_district": "ตองโขบ",
    "district": "โคกศรีสุพรรณ",
    "province": "สกลนคร",
    "zipcode": 47280,
    "sub_district_code": 471501,
    "district_code": 4715,
    "province_code": 47
  },
  {
    "sub_district": "ด่านม่วงคำ",
    "district": "โคกศรีสุพรรณ",
    "province": "สกลนคร",
    "zipcode": 47280,
    "sub_district_code": 471503,
    "district_code": 4715,
    "province_code": 47
  },
  {
    "sub_district": "แมดนาท่ม",
    "district": "โคกศรีสุพรรณ",
    "province": "สกลนคร",
    "zipcode": 47280,
    "sub_district_code": 471504,
    "district_code": 4715,
    "province_code": 47
  },
  {
    "sub_district": "เจริญศิลป์",
    "district": "เจริญศิลป์",
    "province": "สกลนคร",
    "zipcode": 47290,
    "sub_district_code": 471602,
    "district_code": 4716,
    "province_code": 47
  },
  {
    "sub_district": "ทุ่งแก",
    "district": "เจริญศิลป์",
    "province": "สกลนคร",
    "zipcode": 47290,
    "sub_district_code": 471603,
    "district_code": 4716,
    "province_code": 47
  },
  {
    "sub_district": "บ้านเหล่า",
    "district": "เจริญศิลป์",
    "province": "สกลนคร",
    "zipcode": 47290,
    "sub_district_code": 471601,
    "district_code": 4716,
    "province_code": 47
  },
  {
    "sub_district": "หนองแปน",
    "district": "เจริญศิลป์",
    "province": "สกลนคร",
    "zipcode": 47290,
    "sub_district_code": 471605,
    "district_code": 4716,
    "province_code": 47
  },
  {
    "sub_district": "โคกศิลา",
    "district": "เจริญศิลป์",
    "province": "สกลนคร",
    "zipcode": 47290,
    "sub_district_code": 471604,
    "district_code": 4716,
    "province_code": 47
  },
  {
    "sub_district": "บ้านโพน",
    "district": "โพนนาแก้ว",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 471701,
    "district_code": 4717,
    "province_code": 47
  },
  {
    "sub_district": "นาแก้ว",
    "district": "โพนนาแก้ว",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 471702,
    "district_code": 4717,
    "province_code": 47
  },
  {
    "sub_district": "นาตงวัฒนา",
    "district": "โพนนาแก้ว",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 471703,
    "district_code": 4717,
    "province_code": 47
  },
  {
    "sub_district": "บ้านแป้น",
    "district": "โพนนาแก้ว",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 471704,
    "district_code": 4717,
    "province_code": 47
  },
  {
    "sub_district": "เชียงสือ",
    "district": "โพนนาแก้ว",
    "province": "สกลนคร",
    "zipcode": 47230,
    "sub_district_code": 471705,
    "district_code": 4717,
    "province_code": 47
  },
  {
    "sub_district": "โคกภู",
    "district": "ภูพาน",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 471803,
    "district_code": 4718,
    "province_code": 47
  },
  {
    "sub_district": "สร้างค้อ",
    "district": "ภูพาน",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 471801,
    "district_code": 4718,
    "province_code": 47
  },
  {
    "sub_district": "หลุบเลา",
    "district": "ภูพาน",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 471802,
    "district_code": 4718,
    "province_code": 47
  },
  {
    "sub_district": "กกปลาซิว",
    "district": "ภูพาน",
    "province": "สกลนคร",
    "zipcode": 47180,
    "sub_district_code": 471804,
    "district_code": 4718,
    "province_code": 47
  },
  {
    "sub_district": "บ่อยาง",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90000,
    "sub_district_code": 900101,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "เขารูปช้าง",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90000,
    "sub_district_code": 900102,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "เกาะแต้ว",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90000,
    "sub_district_code": 900103,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "พะวง",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90100,
    "sub_district_code": 900104,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "ทุ่งหวัง",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90000,
    "sub_district_code": 900105,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "เกาะยอ",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90100,
    "sub_district_code": 900106,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "ชิงโค",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90280,
    "sub_district_code": 900186,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "สทิงหม้อ",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90280,
    "sub_district_code": 900187,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "ทำนบ",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90280,
    "sub_district_code": 900188,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "รำแดง",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 900189,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "วัดขนุน",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 900190,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "ชะแล้",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 900191,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "ปากรอ",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 900192,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "ป่าขาด",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 900193,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "หัวเขา",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90280,
    "sub_district_code": 900194,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "บางเขียด",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 900195,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "ม่วงงาม",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 900196,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "ตะเครียะ",
    "district": "เมืองสงขลา",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900197,
    "district_code": 9001,
    "province_code": 90
  },
  {
    "sub_district": "บ้านนา",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900301,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "ป่าชิง",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900302,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "สะพานไม้แก่น",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900303,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "สะกอม",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900304,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "นาหว้า",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900305,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "นาทับ",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900306,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "น้ำขาว",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900307,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "ขุนตัดหวาย",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900308,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "ท่าหมอไทร",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900309,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "จะโหนง",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900310,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "คู",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900311,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "แค",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900312,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "คลองเปียะ",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900313,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "ตลิ่งชัน",
    "district": "จะนะ",
    "province": "สงขลา",
    "zipcode": 90130,
    "sub_district_code": 900314,
    "district_code": 9003,
    "province_code": 90
  },
  {
    "sub_district": "เทพา",
    "district": "เทพา",
    "province": "สงขลา",
    "zipcode": 90150,
    "sub_district_code": 900501,
    "district_code": 9005,
    "province_code": 90
  },
  {
    "sub_district": "ปากบาง",
    "district": "เทพา",
    "province": "สงขลา",
    "zipcode": 90150,
    "sub_district_code": 900502,
    "district_code": 9005,
    "province_code": 90
  },
  {
    "sub_district": "เกาะสะบ้า",
    "district": "เทพา",
    "province": "สงขลา",
    "zipcode": 90150,
    "sub_district_code": 900503,
    "district_code": 9005,
    "province_code": 90
  },
  {
    "sub_district": "ลำไพล",
    "district": "เทพา",
    "province": "สงขลา",
    "zipcode": 90260,
    "sub_district_code": 900504,
    "district_code": 9005,
    "province_code": 90
  },
  {
    "sub_district": "ท่าม่วง",
    "district": "เทพา",
    "province": "สงขลา",
    "zipcode": 90150,
    "sub_district_code": 900505,
    "district_code": 9005,
    "province_code": 90
  },
  {
    "sub_district": "วังใหญ่",
    "district": "เทพา",
    "province": "สงขลา",
    "zipcode": 90260,
    "sub_district_code": 900506,
    "district_code": 9005,
    "province_code": 90
  },
  {
    "sub_district": "สะกอม",
    "district": "เทพา",
    "province": "สงขลา",
    "zipcode": 90150,
    "sub_district_code": 900507,
    "district_code": 9005,
    "province_code": 90
  },
  {
    "sub_district": "นาทวี",
    "district": "นาทวี",
    "province": "สงขลา",
    "zipcode": 90160,
    "sub_district_code": 900401,
    "district_code": 9004,
    "province_code": 90
  },
  {
    "sub_district": "ฉาง",
    "district": "นาทวี",
    "province": "สงขลา",
    "zipcode": 90160,
    "sub_district_code": 900402,
    "district_code": 9004,
    "province_code": 90
  },
  {
    "sub_district": "นาหมอศรี",
    "district": "นาทวี",
    "province": "สงขลา",
    "zipcode": 90160,
    "sub_district_code": 900403,
    "district_code": 9004,
    "province_code": 90
  },
  {
    "sub_district": "คลองทราย",
    "district": "นาทวี",
    "province": "สงขลา",
    "zipcode": 90160,
    "sub_district_code": 900404,
    "district_code": 9004,
    "province_code": 90
  },
  {
    "sub_district": "ปลักหนู",
    "district": "นาทวี",
    "province": "สงขลา",
    "zipcode": 90160,
    "sub_district_code": 900405,
    "district_code": 9004,
    "province_code": 90
  },
  {
    "sub_district": "ท่าประดู่",
    "district": "นาทวี",
    "province": "สงขลา",
    "zipcode": 90160,
    "sub_district_code": 900406,
    "district_code": 9004,
    "province_code": 90
  },
  {
    "sub_district": "สะท้อน",
    "district": "นาทวี",
    "province": "สงขลา",
    "zipcode": 90160,
    "sub_district_code": 900407,
    "district_code": 9004,
    "province_code": 90
  },
  {
    "sub_district": "ทับช้าง",
    "district": "นาทวี",
    "province": "สงขลา",
    "zipcode": 90160,
    "sub_district_code": 900408,
    "district_code": 9004,
    "province_code": 90
  },
  {
    "sub_district": "ประกอบ",
    "district": "นาทวี",
    "province": "สงขลา",
    "zipcode": 90160,
    "sub_district_code": 900409,
    "district_code": 9004,
    "province_code": 90
  },
  {
    "sub_district": "คลองกวาง",
    "district": "นาทวี",
    "province": "สงขลา",
    "zipcode": 90160,
    "sub_district_code": 900410,
    "district_code": 9004,
    "province_code": 90
  },
  {
    "sub_district": "ระโนด",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900701,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "คลองแดน",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900702,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "ตะเครียะ",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900703,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "ท่าบอน",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900704,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900705,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "บ่อตรุ",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900706,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "ปากแตระ",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900707,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "พังยาง",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900708,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "ระวะ",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900709,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "วัดสน",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900710,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "บ้านขาว",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900711,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "แดนสงวน",
    "district": "ระโนด",
    "province": "สงขลา",
    "zipcode": 90140,
    "sub_district_code": 900712,
    "district_code": 9007,
    "province_code": 90
  },
  {
    "sub_district": "กำแพงเพชร",
    "district": "รัตภูมิ",
    "province": "สงขลา",
    "zipcode": 90180,
    "sub_district_code": 900901,
    "district_code": 9009,
    "province_code": 90
  },
  {
    "sub_district": "ท่าชะมวง",
    "district": "รัตภูมิ",
    "province": "สงขลา",
    "zipcode": 90180,
    "sub_district_code": 900902,
    "district_code": 9009,
    "province_code": 90
  },
  {
    "sub_district": "คูหาใต้",
    "district": "รัตภูมิ",
    "province": "สงขลา",
    "zipcode": 90180,
    "sub_district_code": 900903,
    "district_code": 9009,
    "province_code": 90
  },
  {
    "sub_district": "ควนรู",
    "district": "รัตภูมิ",
    "province": "สงขลา",
    "zipcode": 90220,
    "sub_district_code": 900904,
    "district_code": 9009,
    "province_code": 90
  },
  {
    "sub_district": "ควนโส",
    "district": "รัตภูมิ",
    "province": "สงขลา",
    "zipcode": 90220,
    "sub_district_code": 900995,
    "district_code": 9009,
    "province_code": 90
  },
  {
    "sub_district": "รัตภูมิ",
    "district": "รัตภูมิ",
    "province": "สงขลา",
    "zipcode": 90220,
    "sub_district_code": 900996,
    "district_code": 9009,
    "province_code": 90
  },
  {
    "sub_district": "บางเหรียง",
    "district": "รัตภูมิ",
    "province": "สงขลา",
    "zipcode": 90220,
    "sub_district_code": 900997,
    "district_code": 9009,
    "province_code": 90
  },
  {
    "sub_district": "ห้วยลึก",
    "district": "รัตภูมิ",
    "province": "สงขลา",
    "zipcode": 90220,
    "sub_district_code": 900998,
    "district_code": 9009,
    "province_code": 90
  },
  {
    "sub_district": "เขาพระ",
    "district": "รัตภูมิ",
    "province": "สงขลา",
    "zipcode": 90180,
    "sub_district_code": 900909,
    "district_code": 9009,
    "province_code": 90
  },
  {
    "sub_district": "จะทิ้งพระ",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900201,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "กระดังงา",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900202,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "สนามชัย",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900203,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "ดีหลวง",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900204,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "ชุมพล",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900205,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "คลองรี",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900206,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "คูขุด",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900207,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "ท่าหิน",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900208,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "วัดจันทร์",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900209,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "บ่อแดง",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900210,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "บ่อดาน",
    "district": "สทิงพระ",
    "province": "สงขลา",
    "zipcode": 90190,
    "sub_district_code": 900211,
    "district_code": 9002,
    "province_code": 90
  },
  {
    "sub_district": "สะเดา",
    "district": "สะเดา",
    "province": "สงขลา",
    "zipcode": 90120,
    "sub_district_code": 901001,
    "district_code": 9010,
    "province_code": 90
  },
  {
    "sub_district": "ปริก",
    "district": "สะเดา",
    "province": "สงขลา",
    "zipcode": 90120,
    "sub_district_code": 901002,
    "district_code": 9010,
    "province_code": 90
  },
  {
    "sub_district": "พังลา",
    "district": "สะเดา",
    "province": "สงขลา",
    "zipcode": 90170,
    "sub_district_code": 901003,
    "district_code": 9010,
    "province_code": 90
  },
  {
    "sub_district": "สำนักแต้ว",
    "district": "สะเดา",
    "province": "สงขลา",
    "zipcode": 90120,
    "sub_district_code": 901004,
    "district_code": 9010,
    "province_code": 90
  },
  {
    "sub_district": "ทุ่งหมอ",
    "district": "สะเดา",
    "province": "สงขลา",
    "zipcode": 90240,
    "sub_district_code": 901005,
    "district_code": 9010,
    "province_code": 90
  },
  {
    "sub_district": "ท่าโพธิ์",
    "district": "สะเดา",
    "province": "สงขลา",
    "zipcode": 90170,
    "sub_district_code": 901006,
    "district_code": 9010,
    "province_code": 90
  },
  {
    "sub_district": "ปาดังเบซาร์",
    "district": "สะเดา",
    "province": "สงขลา",
    "zipcode": 90240,
    "sub_district_code": 901007,
    "district_code": 9010,
    "province_code": 90
  },
  {
    "sub_district": "สำนักขาม",
    "district": "สะเดา",
    "province": "สงขลา",
    "zipcode": 90320,
    "sub_district_code": 901008,
    "district_code": 9010,
    "province_code": 90
  },
  {
    "sub_district": "เขามีเกียรติ",
    "district": "สะเดา",
    "province": "สงขลา",
    "zipcode": 90170,
    "sub_district_code": 901009,
    "district_code": 9010,
    "province_code": 90
  },
  {
    "sub_district": "สะบ้าย้อย",
    "district": "สะบ้าย้อย",
    "province": "สงขลา",
    "zipcode": 90210,
    "sub_district_code": 900601,
    "district_code": 9006,
    "province_code": 90
  },
  {
    "sub_district": "ทุ่งพอ",
    "district": "สะบ้าย้อย",
    "province": "สงขลา",
    "zipcode": 90210,
    "sub_district_code": 900602,
    "district_code": 9006,
    "province_code": 90
  },
  {
    "sub_district": "เปียน",
    "district": "สะบ้าย้อย",
    "province": "สงขลา",
    "zipcode": 90210,
    "sub_district_code": 900603,
    "district_code": 9006,
    "province_code": 90
  },
  {
    "sub_district": "บ้านโหนด",
    "district": "สะบ้าย้อย",
    "province": "สงขลา",
    "zipcode": 90210,
    "sub_district_code": 900604,
    "district_code": 9006,
    "province_code": 90
  },
  {
    "sub_district": "จะแหน",
    "district": "สะบ้าย้อย",
    "province": "สงขลา",
    "zipcode": 90210,
    "sub_district_code": 900605,
    "district_code": 9006,
    "province_code": 90
  },
  {
    "sub_district": "คูหา",
    "district": "สะบ้าย้อย",
    "province": "สงขลา",
    "zipcode": 90210,
    "sub_district_code": 900606,
    "district_code": 9006,
    "province_code": 90
  },
  {
    "sub_district": "เขาแดง",
    "district": "สะบ้าย้อย",
    "province": "สงขลา",
    "zipcode": 90210,
    "sub_district_code": 900607,
    "district_code": 9006,
    "province_code": 90
  },
  {
    "sub_district": "บาโหย",
    "district": "สะบ้าย้อย",
    "province": "สงขลา",
    "zipcode": 90210,
    "sub_district_code": 900608,
    "district_code": 9006,
    "province_code": 90
  },
  {
    "sub_district": "ธารคีรี",
    "district": "สะบ้าย้อย",
    "province": "สงขลา",
    "zipcode": 90210,
    "sub_district_code": 900609,
    "district_code": 9006,
    "province_code": 90
  },
  {
    "sub_district": "หาดใหญ่",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901101,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "ควนลัง",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901102,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "คูเต่า",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901103,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "คอหงส์",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901104,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "คลองแห",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901105,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "ทุ่งลาน",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90230,
    "sub_district_code": 901192,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "คลองหอยโข่ง",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90230,
    "sub_district_code": 901193,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "คลองอู่ตะเภา",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901107,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "ฉลุง",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901108,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "โคกม่วง",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90230,
    "sub_district_code": 901195,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "ทุ่งใหญ่",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901111,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "ทุ่งตำเสา",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901112,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901113,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "น้ำน้อย",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901114,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "บ้านพรุ",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90250,
    "sub_district_code": 901116,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "พะตง",
    "district": "หาดใหญ่",
    "province": "สงขลา",
    "zipcode": 90230,
    "sub_district_code": 901118,
    "district_code": 9011,
    "province_code": 90
  },
  {
    "sub_district": "รัตภูมิ",
    "district": "ควนเนียง",
    "province": "สงขลา",
    "zipcode": 90220,
    "sub_district_code": 901301,
    "district_code": 9013,
    "province_code": 90
  },
  {
    "sub_district": "ควนโส",
    "district": "ควนเนียง",
    "province": "สงขลา",
    "zipcode": 90220,
    "sub_district_code": 901302,
    "district_code": 9013,
    "province_code": 90
  },
  {
    "sub_district": "ห้วยลึก",
    "district": "ควนเนียง",
    "province": "สงขลา",
    "zipcode": 90220,
    "sub_district_code": 901303,
    "district_code": 9013,
    "province_code": 90
  },
  {
    "sub_district": "บางเหรียง",
    "district": "ควนเนียง",
    "province": "สงขลา",
    "zipcode": 90220,
    "sub_district_code": 901304,
    "district_code": 9013,
    "province_code": 90
  },
  {
    "sub_district": "เกาะใหญ่",
    "district": "กระแสสินธุ์",
    "province": "สงขลา",
    "zipcode": 90270,
    "sub_district_code": 900801,
    "district_code": 9008,
    "province_code": 90
  },
  {
    "sub_district": "โรง",
    "district": "กระแสสินธุ์",
    "province": "สงขลา",
    "zipcode": 90270,
    "sub_district_code": 900802,
    "district_code": 9008,
    "province_code": 90
  },
  {
    "sub_district": "เชิงแส",
    "district": "กระแสสินธุ์",
    "province": "สงขลา",
    "zipcode": 90270,
    "sub_district_code": 900803,
    "district_code": 9008,
    "province_code": 90
  },
  {
    "sub_district": "กระแสสินธุ์",
    "district": "กระแสสินธุ์",
    "province": "สงขลา",
    "zipcode": 90270,
    "sub_district_code": 900804,
    "district_code": 9008,
    "province_code": 90
  },
  {
    "sub_district": "นาหม่อม",
    "district": "นาหม่อม",
    "province": "สงขลา",
    "zipcode": 90310,
    "sub_district_code": 901201,
    "district_code": 9012,
    "province_code": 90
  },
  {
    "sub_district": "ทุ่งขมิ้น",
    "district": "นาหม่อม",
    "province": "สงขลา",
    "zipcode": 90310,
    "sub_district_code": 901203,
    "district_code": 9012,
    "province_code": 90
  },
  {
    "sub_district": "พิจิตร",
    "district": "นาหม่อม",
    "province": "สงขลา",
    "zipcode": 90310,
    "sub_district_code": 901202,
    "district_code": 9012,
    "province_code": 90
  },
  {
    "sub_district": "คลองหรัง",
    "district": "นาหม่อม",
    "province": "สงขลา",
    "zipcode": 90310,
    "sub_district_code": 901204,
    "district_code": 9012,
    "province_code": 90
  },
  {
    "sub_district": "บางกล่ำ",
    "district": "บางกล่ำ",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901401,
    "district_code": 9014,
    "province_code": 90
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "บางกล่ำ",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901402,
    "district_code": 9014,
    "province_code": 90
  },
  {
    "sub_district": "แม่ทอม",
    "district": "บางกล่ำ",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901403,
    "district_code": 9014,
    "province_code": 90
  },
  {
    "sub_district": "บ้านหาร",
    "district": "บางกล่ำ",
    "province": "สงขลา",
    "zipcode": 90110,
    "sub_district_code": 901404,
    "district_code": 9014,
    "province_code": 90
  },
  {
    "sub_district": "ม่วงงาม",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 901511,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "ชิงโค",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90280,
    "sub_district_code": 901501,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "หัวเขา",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90280,
    "sub_district_code": 901509,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "สทิงหม้อ",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90280,
    "sub_district_code": 901502,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "ทำนบ",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90280,
    "sub_district_code": 901503,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "รำแดง",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 901504,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "บางเขียด",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 901510,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "ชะแล้",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 901506,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "ป่าขาด",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 901508,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "วัดขนุน",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 901505,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "ปากรอ",
    "district": "สิงหนคร",
    "province": "สงขลา",
    "zipcode": 90330,
    "sub_district_code": 901507,
    "district_code": 9015,
    "province_code": 90
  },
  {
    "sub_district": "โคกม่วง",
    "district": "คลองหอยโข่ง",
    "province": "สงขลา",
    "zipcode": 90230,
    "sub_district_code": 901603,
    "district_code": 9016,
    "province_code": 90
  },
  {
    "sub_district": "คลองหอยโข่ง",
    "district": "คลองหอยโข่ง",
    "province": "สงขลา",
    "zipcode": 90230,
    "sub_district_code": 901601,
    "district_code": 9016,
    "province_code": 90
  },
  {
    "sub_district": "ทุ่งลาน",
    "district": "คลองหอยโข่ง",
    "province": "สงขลา",
    "zipcode": 90230,
    "sub_district_code": 901602,
    "district_code": 9016,
    "province_code": 90
  },
  {
    "sub_district": "คลองหลา",
    "district": "คลองหอยโข่ง",
    "province": "สงขลา",
    "zipcode": 90115,
    "sub_district_code": 901604,
    "district_code": 9016,
    "province_code": 90
  },
  {
    "sub_district": "พิมาน",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91000,
    "sub_district_code": 910101,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "คลองขุด",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91000,
    "sub_district_code": 910102,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "ควนขัน",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91000,
    "sub_district_code": 910103,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "บ้านควน",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91140,
    "sub_district_code": 910104,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "ฉลุง",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91140,
    "sub_district_code": 910105,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "เกาะสาหร่าย",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91000,
    "sub_district_code": 910106,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "ตันหยงโป",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91000,
    "sub_district_code": 910107,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "เจ๊ะบิลัง",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91000,
    "sub_district_code": 910108,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "ตำมะลัง",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91000,
    "sub_district_code": 910109,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "ปูยู",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91000,
    "sub_district_code": 910110,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "ควนโพธิ์",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91140,
    "sub_district_code": 910111,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "เกตรี",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91140,
    "sub_district_code": 910112,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "กำแพง",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91110,
    "sub_district_code": 910197,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "ทุ่งนุ้ย",
    "district": "เมืองสตูล",
    "province": "สตูล",
    "zipcode": 91130,
    "sub_district_code": 910198,
    "district_code": 9101,
    "province_code": 91
  },
  {
    "sub_district": "ทุ่งนุ้ย",
    "district": "ควนกาหลง",
    "province": "สตูล",
    "zipcode": 91130,
    "sub_district_code": 910301,
    "district_code": 9103,
    "province_code": 91
  },
  {
    "sub_district": "ควนกาหลง",
    "district": "ควนกาหลง",
    "province": "สตูล",
    "zipcode": 91130,
    "sub_district_code": 910302,
    "district_code": 9103,
    "province_code": 91
  },
  {
    "sub_district": "อุใดเจริญ",
    "district": "ควนกาหลง",
    "province": "สตูล",
    "zipcode": 91130,
    "sub_district_code": 910303,
    "district_code": 9103,
    "province_code": 91
  },
  {
    "sub_district": "นิคมพัฒนา",
    "district": "ควนกาหลง",
    "province": "สตูล",
    "zipcode": 91130,
    "sub_district_code": 910397,
    "district_code": 9103,
    "province_code": 91
  },
  {
    "sub_district": "ปาล์มพัฒนา",
    "district": "ควนกาหลง",
    "province": "สตูล",
    "zipcode": 91130,
    "sub_district_code": 910398,
    "district_code": 9103,
    "province_code": 91
  },
  {
    "sub_district": "ทุ่งหว้า",
    "district": "ทุ่งหว้า",
    "province": "สตูล",
    "zipcode": 91120,
    "sub_district_code": 910601,
    "district_code": 9106,
    "province_code": 91
  },
  {
    "sub_district": "นาทอน",
    "district": "ทุ่งหว้า",
    "province": "สตูล",
    "zipcode": 91120,
    "sub_district_code": 910602,
    "district_code": 9106,
    "province_code": 91
  },
  {
    "sub_district": "ขอนคลาน",
    "district": "ทุ่งหว้า",
    "province": "สตูล",
    "zipcode": 91120,
    "sub_district_code": 910603,
    "district_code": 9106,
    "province_code": 91
  },
  {
    "sub_district": "ทุ่งบุหลัง",
    "district": "ทุ่งหว้า",
    "province": "สตูล",
    "zipcode": 91120,
    "sub_district_code": 910604,
    "district_code": 9106,
    "province_code": 91
  },
  {
    "sub_district": "ป่าแก่บ่อหิน",
    "district": "ทุ่งหว้า",
    "province": "สตูล",
    "zipcode": 91120,
    "sub_district_code": 910605,
    "district_code": 9106,
    "province_code": 91
  },
  {
    "sub_district": "กำแพง",
    "district": "ละงู",
    "province": "สตูล",
    "zipcode": 91110,
    "sub_district_code": 910501,
    "district_code": 9105,
    "province_code": 91
  },
  {
    "sub_district": "ละงู",
    "district": "ละงู",
    "province": "สตูล",
    "zipcode": 91110,
    "sub_district_code": 910502,
    "district_code": 9105,
    "province_code": 91
  },
  {
    "sub_district": "เขาขาว",
    "district": "ละงู",
    "province": "สตูล",
    "zipcode": 91110,
    "sub_district_code": 910503,
    "district_code": 9105,
    "province_code": 91
  },
  {
    "sub_district": "ปากน้ำ",
    "district": "ละงู",
    "province": "สตูล",
    "zipcode": 91110,
    "sub_district_code": 910504,
    "district_code": 9105,
    "province_code": 91
  },
  {
    "sub_district": "น้ำผุด",
    "district": "ละงู",
    "province": "สตูล",
    "zipcode": 91110,
    "sub_district_code": 910505,
    "district_code": 9105,
    "province_code": 91
  },
  {
    "sub_district": "แหลมสน",
    "district": "ละงู",
    "province": "สตูล",
    "zipcode": 91110,
    "sub_district_code": 910506,
    "district_code": 9105,
    "province_code": 91
  },
  {
    "sub_district": "ท่าแพ",
    "district": "ท่าแพ",
    "province": "สตูล",
    "zipcode": 91150,
    "sub_district_code": 910401,
    "district_code": 9104,
    "province_code": 91
  },
  {
    "sub_district": "แป-ระ",
    "district": "ท่าแพ",
    "province": "สตูล",
    "zipcode": 91150,
    "sub_district_code": 910402,
    "district_code": 9104,
    "province_code": 91
  },
  {
    "sub_district": "สาคร",
    "district": "ท่าแพ",
    "province": "สตูล",
    "zipcode": 91150,
    "sub_district_code": 910403,
    "district_code": 9104,
    "province_code": 91
  },
  {
    "sub_district": "ท่าเรือ",
    "district": "ท่าแพ",
    "province": "สตูล",
    "zipcode": 91150,
    "sub_district_code": 910404,
    "district_code": 9104,
    "province_code": 91
  },
  {
    "sub_district": "ควนโดน",
    "district": "ควนโดน",
    "province": "สตูล",
    "zipcode": 91160,
    "sub_district_code": 910201,
    "district_code": 9102,
    "province_code": 91
  },
  {
    "sub_district": "ควนสตอ",
    "district": "ควนโดน",
    "province": "สตูล",
    "zipcode": 91160,
    "sub_district_code": 910202,
    "district_code": 9102,
    "province_code": 91
  },
  {
    "sub_district": "ย่านซื่อ",
    "district": "ควนโดน",
    "province": "สตูล",
    "zipcode": 91160,
    "sub_district_code": 910203,
    "district_code": 9102,
    "province_code": 91
  },
  {
    "sub_district": "วังประจัน",
    "district": "ควนโดน",
    "province": "สตูล",
    "zipcode": 91160,
    "sub_district_code": 910204,
    "district_code": 9102,
    "province_code": 91
  },
  {
    "sub_district": "นิคมพัฒนา",
    "district": "มะนัง",
    "province": "สตูล",
    "zipcode": 91130,
    "sub_district_code": 910702,
    "district_code": 9107,
    "province_code": 91
  },
  {
    "sub_district": "ปาล์มพัฒนา",
    "district": "มะนัง",
    "province": "สตูล",
    "zipcode": 91130,
    "sub_district_code": 910701,
    "district_code": 9107,
    "province_code": 91
  },
  {
    "sub_district": "ปากน้ำ",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110101,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "สำโรงเหนือ",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110102,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "บางเมือง",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110103,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "ท้ายบ้าน",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10280,
    "sub_district_code": 110104,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "ท้ายบ้านใหม่",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10280,
    "sub_district_code": 110116,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "บางปูใหม่",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10280,
    "sub_district_code": 110108,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "ปากคลองบางปลากด",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10290,
    "sub_district_code": 110109,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "แพรกษา",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10280,
    "sub_district_code": 110110,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "บางโปรง",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110111,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "บางปู",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10280,
    "sub_district_code": 110112,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "บางด้วน",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110113,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "บางเมืองใหม่",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110114,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "เทพารักษ์",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110115,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "ท้ายบ้านใหม่",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10280,
    "sub_district_code": 110116,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "บางพึ่ง",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110117,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "คอกกระบือ",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110118,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "แหลมฟ้าผ่า",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10290,
    "sub_district_code": 110119,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "ท้ายเมืองใหม่",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110120,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "บางเมฆขาว",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10270,
    "sub_district_code": 110121,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "แพรกษาใหม่",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10280,
    "sub_district_code": 110122,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "แพรกษาใหม่",
    "district": "เมืองสมุทรปราการ",
    "province": "สมุทรปราการ",
    "zipcode": 10280,
    "sub_district_code": 110122,
    "district_code": 1101,
    "province_code": 11
  },
  {
    "sub_district": "บางบ่อ",
    "district": "บางบ่อ",
    "province": "สมุทรปราการ",
    "zipcode": 10560,
    "sub_district_code": 110201,
    "district_code": 1102,
    "province_code": 11
  },
  {
    "sub_district": "บ้านระกาศ",
    "district": "บางบ่อ",
    "province": "สมุทรปราการ",
    "zipcode": 10560,
    "sub_district_code": 110202,
    "district_code": 1102,
    "province_code": 11
  },
  {
    "sub_district": "บางพลีน้อย",
    "district": "บางบ่อ",
    "province": "สมุทรปราการ",
    "zipcode": 10560,
    "sub_district_code": 110203,
    "district_code": 1102,
    "province_code": 11
  },
  {
    "sub_district": "บางเพรียง",
    "district": "บางบ่อ",
    "province": "สมุทรปราการ",
    "zipcode": 10560,
    "sub_district_code": 110204,
    "district_code": 1102,
    "province_code": 11
  },
  {
    "sub_district": "คลองด่าน",
    "district": "บางบ่อ",
    "province": "สมุทรปราการ",
    "zipcode": 10550,
    "sub_district_code": 110205,
    "district_code": 1102,
    "province_code": 11
  },
  {
    "sub_district": "คลองสวน",
    "district": "บางบ่อ",
    "province": "สมุทรปราการ",
    "zipcode": 10560,
    "sub_district_code": 110206,
    "district_code": 1102,
    "province_code": 11
  },
  {
    "sub_district": "เปร็ง",
    "district": "บางบ่อ",
    "province": "สมุทรปราการ",
    "zipcode": 10560,
    "sub_district_code": 110207,
    "district_code": 1102,
    "province_code": 11
  },
  {
    "sub_district": "คลองนิยมยาตรา",
    "district": "บางบ่อ",
    "province": "สมุทรปราการ",
    "zipcode": 10560,
    "sub_district_code": 110208,
    "district_code": 1102,
    "province_code": 11
  },
  {
    "sub_district": "บางพลีใหญ่",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110301,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "บางแก้ว",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110302,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "บางปลา",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110303,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "บางโฉลง",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110304,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "บางเสาธง",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110393,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "ศีรษะจรเข้ใหญ่",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110394,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "ศีรษะจรเข้น้อย",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110395,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "ราชาเทวะ",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110308,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "หนองปรือ",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110309,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "วังแก้ว",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110397,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "บางลาว",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110396,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "บางแก้วใหญ่",
    "district": "บางพลี",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110398,
    "district_code": 1103,
    "province_code": 11
  },
  {
    "sub_district": "บางพึ่ง",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110402,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "บางจาก",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110403,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "บางครุ",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110404,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "บางหญ้าแพรก",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110405,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "บางหัวเสือ",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110406,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "สำโรงใต้",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110407,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "บางยอ",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110408,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "บางกะเจ้า",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110409,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "บางน้ำผึ้ง",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110410,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "บางกระสอบ",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110411,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "บางกอบัว",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110412,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "ทรงคนอง",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110413,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "สำโรง",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110414,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "ตลาด",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110401,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "บางบ่อ",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10560,
    "sub_district_code": 110497,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "ทุ่งครุ",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10560,
    "sub_district_code": 110499,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "สำโรงกลาง",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110415,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "สำโรงใต้",
    "district": "พระประแดง",
    "province": "สมุทรปราการ",
    "zipcode": 10130,
    "sub_district_code": 110407,
    "district_code": 1104,
    "province_code": 11
  },
  {
    "sub_district": "นาเกลือ",
    "district": "พระสมุทรเจดีย์",
    "province": "สมุทรปราการ",
    "zipcode": 10290,
    "sub_district_code": 110501,
    "district_code": 1105,
    "province_code": 11
  },
  {
    "sub_district": "บ้านคลองสวน",
    "district": "พระสมุทรเจดีย์",
    "province": "สมุทรปราการ",
    "zipcode": 10290,
    "sub_district_code": 110502,
    "district_code": 1105,
    "province_code": 11
  },
  {
    "sub_district": "แหลมฟ้าผ่า",
    "district": "พระสมุทรเจดีย์",
    "province": "สมุทรปราการ",
    "zipcode": 10290,
    "sub_district_code": 110503,
    "district_code": 1105,
    "province_code": 11
  },
  {
    "sub_district": "ปากคลองบางปลากด",
    "district": "พระสมุทรเจดีย์",
    "province": "สมุทรปราการ",
    "zipcode": 10290,
    "sub_district_code": 110504,
    "district_code": 1105,
    "province_code": 11
  },
  {
    "sub_district": "ในคลองบางปลากด",
    "district": "พระสมุทรเจดีย์",
    "province": "สมุทรปราการ",
    "zipcode": 10290,
    "sub_district_code": 110505,
    "district_code": 1105,
    "province_code": 11
  },
  {
    "sub_district": "บางเสาธง",
    "district": "บางเสาธง",
    "province": "สมุทรปราการ",
    "zipcode": 10570,
    "sub_district_code": 110601,
    "district_code": 1106,
    "province_code": 11
  },
  {
    "sub_district": "ศีรษะจรเข้ใหญ่",
    "district": "บางเสาธง",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110603,
    "district_code": 1106,
    "province_code": 11
  },
  {
    "sub_district": "บางโฉลง",
    "district": "บางเสาธง",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110604,
    "district_code": 1106,
    "province_code": 11
  },
  {
    "sub_district": "ศีรษะจรเข้น้อย",
    "district": "บางเสาธง",
    "province": "สมุทรปราการ",
    "zipcode": 10540,
    "sub_district_code": 110602,
    "district_code": 1106,
    "province_code": 11
  },
  {
    "sub_district": "แม่กลอง",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750101,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "บางขันแตก",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750102,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "ลาดใหญ่",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750103,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "บ้านปรก",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750104,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "บางแก้ว",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750105,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "ท้ายหาด",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750106,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "แหลมใหญ่",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750107,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "คลองเขิน",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750108,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "คลองโคน",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750109,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "นางตะเคียน",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750110,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "บางจะเกร็ง",
    "district": "เมืองสมุทรสงคราม",
    "province": "สมุทรสงคราม",
    "zipcode": 75000,
    "sub_district_code": 750111,
    "district_code": 7501,
    "province_code": 75
  },
  {
    "sub_district": "กระดังงา",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750201,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "บางสะแก",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750202,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "บางยี่รงค์",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750203,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "โรงหีบ",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750204,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "บางคนที",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750205,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "ดอนมะโนรา",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750206,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "บางพรม",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750207,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "บางกุ้ง",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750208,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "จอมปลวก",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750209,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "บางนกแขวก",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750210,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "ยายแพง",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750211,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "บางกระบือ",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750212,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "บ้านปราโมทย์",
    "district": "บางคนที",
    "province": "สมุทรสงคราม",
    "zipcode": 75120,
    "sub_district_code": 750213,
    "district_code": 7502,
    "province_code": 75
  },
  {
    "sub_district": "อัมพวา",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750301,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "สวนหลวง",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750302,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "ท่าคา",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750303,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "วัดประดู่",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750304,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "เหมืองใหม่",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750305,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "บางช้าง",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750306,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "แควอ้อม",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750307,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "ปลายโพงพาง",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750308,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "บางแค",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750309,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "แพรกหนามแดง",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750310,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "ยี่สาร",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750311,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "บางนางลี่",
    "district": "อัมพวา",
    "province": "สมุทรสงคราม",
    "zipcode": 75110,
    "sub_district_code": 750312,
    "district_code": 7503,
    "province_code": 75
  },
  {
    "sub_district": "มหาชัย",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740101,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "ท่าฉลอม",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740102,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "โกรกกราก",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740103,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "บ้านบ่อ",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740104,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "บางโทรัด",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740105,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "กาหลง",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740106,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "นาโคก",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740107,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "ท่าจีน",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740108,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "นาดี",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740109,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "ท่าทราย",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740110,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "คอกกระบือ",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740111,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "บางน้ำจืด",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740112,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "พันท้ายนรสิงห์",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740113,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "โคกขาม",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740114,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "บ้านเกาะ",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740115,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "บางกระเจ้า",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740116,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "บางหญ้าแพรก",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740117,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "ชัยมงคล",
    "district": "เมืองสมุทรสาคร",
    "province": "สมุทรสาคร",
    "zipcode": 74000,
    "sub_district_code": 740118,
    "district_code": 7401,
    "province_code": 74
  },
  {
    "sub_district": "กระทุ่มแบน",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74110,
    "sub_district_code": 740201,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "อ้อมน้อย",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74130,
    "sub_district_code": 740202,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "ท่าไม้",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74110,
    "sub_district_code": 740203,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "สวนหลวง",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74110,
    "sub_district_code": 740204,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "บางยาง",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74110,
    "sub_district_code": 740205,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "คลองมะเดื่อ",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74110,
    "sub_district_code": 740206,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "หนองนกไข่",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74110,
    "sub_district_code": 740207,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "ดอนไก่ดี",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74110,
    "sub_district_code": 740208,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "แคราย",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74110,
    "sub_district_code": 740209,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "ท่าเสา",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74110,
    "sub_district_code": 740210,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "ตลาดกระทุ่มแบน",
    "district": "กระทุ่มแบน",
    "province": "สมุทรสาคร",
    "zipcode": 74110,
    "sub_district_code": 740201,
    "district_code": 7402,
    "province_code": 74
  },
  {
    "sub_district": "บ้านแพ้ว",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740301,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "หลักสาม",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740302,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "ยกกระบัตร",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740303,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "โรงเข้",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740304,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "หนองสองห้อง",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740305,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "หนองบัว",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740306,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "หลักสอง",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740307,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "เจ็ดริ้ว",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740308,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "คลองตัน",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740309,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "อำแพง",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740310,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "สวนส้ม",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740311,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "เกษตรพัฒนา",
    "district": "บ้านแพ้ว",
    "province": "สมุทรสาคร",
    "zipcode": 74120,
    "sub_district_code": 740312,
    "district_code": 7403,
    "province_code": 74
  },
  {
    "sub_district": "ปากเพรียว",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190101,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "หน้าพระลาน",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18240,
    "sub_district_code": 190188,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "พุแค",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18240,
    "sub_district_code": 190189,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "ห้วยบง",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190190,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "ดาวเรือง",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190105,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "นาโฉง",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190106,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "โคกสว่าง",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190107,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "หนองโน",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190108,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "หนองยาว",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190109,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "ปากข้าวสาร",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190110,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "หนองปลาไหล",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190111,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "กุดนกเปล้า",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190112,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "ตลิ่งชัน",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190113,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "ตะกุด",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190114,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "บ้านแก้ง",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190191,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "ผึ้งรวง",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190192,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "เขาดินพัฒนา",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 190194,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "หนองบัว",
    "district": "เมืองสระบุรี",
    "province": "สระบุรี",
    "zipcode": 18130,
    "sub_district_code": 190197,
    "district_code": 1901,
    "province_code": 19
  },
  {
    "sub_district": "แก่งคอย",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190201,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "ทับกวาง",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18260,
    "sub_district_code": 190202,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "ตาลเดี่ยว",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190203,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "ห้วยแห้ง",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190204,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "ท่าคล้อ",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190205,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "หินซ้อน",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190206,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "บ้านธาตุ",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190207,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "บ้านป่า",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190208,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "ท่าตูม",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190209,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "ชะอม",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190210,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "สองคอน",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190211,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "เตาปูน",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190212,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "ชำผักแพว",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190213,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "ท่ามะปราง",
    "district": "แก่งคอย",
    "province": "สระบุรี",
    "zipcode": 18110,
    "sub_district_code": 190215,
    "district_code": 1902,
    "province_code": 19
  },
  {
    "sub_district": "ดอนพุด",
    "district": "ดอนพุด",
    "province": "สระบุรี",
    "zipcode": 18210,
    "sub_district_code": 190701,
    "district_code": 1907,
    "province_code": 19
  },
  {
    "sub_district": "ไผ่หลิ่ว",
    "district": "ดอนพุด",
    "province": "สระบุรี",
    "zipcode": 18210,
    "sub_district_code": 190702,
    "district_code": 1907,
    "province_code": 19
  },
  {
    "sub_district": "บ้านหลวง",
    "district": "ดอนพุด",
    "province": "สระบุรี",
    "zipcode": 18210,
    "sub_district_code": 190703,
    "district_code": 1907,
    "province_code": 19
  },
  {
    "sub_district": "ดงตะงาว",
    "district": "ดอนพุด",
    "province": "สระบุรี",
    "zipcode": 18210,
    "sub_district_code": 190704,
    "district_code": 1907,
    "province_code": 19
  },
  {
    "sub_district": "บ้านหมอ",
    "district": "บ้านหมอ",
    "province": "สระบุรี",
    "zipcode": 18130,
    "sub_district_code": 190601,
    "district_code": 1906,
    "province_code": 19
  },
  {
    "sub_district": "บางโขมด",
    "district": "บ้านหมอ",
    "province": "สระบุรี",
    "zipcode": 18130,
    "sub_district_code": 190602,
    "district_code": 1906,
    "province_code": 19
  },
  {
    "sub_district": "บ้านครัว",
    "district": "บ้านหมอ",
    "province": "สระบุรี",
    "zipcode": 18270,
    "sub_district_code": 190608,
    "district_code": 1906,
    "province_code": 19
  },
  {
    "sub_district": "หนองบัว",
    "district": "บ้านหมอ",
    "province": "สระบุรี",
    "zipcode": 18130,
    "sub_district_code": 190609,
    "district_code": 1906,
    "province_code": 19
  },
  {
    "sub_district": "สร่างโศก",
    "district": "บ้านหมอ",
    "province": "สระบุรี",
    "zipcode": 18130,
    "sub_district_code": 190603,
    "district_code": 1906,
    "province_code": 19
  },
  {
    "sub_district": "ตลาดน้อย",
    "district": "บ้านหมอ",
    "province": "สระบุรี",
    "zipcode": 18130,
    "sub_district_code": 190604,
    "district_code": 1906,
    "province_code": 19
  },
  {
    "sub_district": "หรเทพ",
    "district": "บ้านหมอ",
    "province": "สระบุรี",
    "zipcode": 18130,
    "sub_district_code": 190605,
    "district_code": 1906,
    "province_code": 19
  },
  {
    "sub_district": "โคกใหญ่",
    "district": "บ้านหมอ",
    "province": "สระบุรี",
    "zipcode": 18130,
    "sub_district_code": 190606,
    "district_code": 1906,
    "province_code": 19
  },
  {
    "sub_district": "ไผ่ขวาง",
    "district": "บ้านหมอ",
    "province": "สระบุรี",
    "zipcode": 18130,
    "sub_district_code": 190607,
    "district_code": 1906,
    "province_code": 19
  },
  {
    "sub_district": "พระพุทธบาท",
    "district": "พระพุทธบาท",
    "province": "สระบุรี",
    "zipcode": 18120,
    "sub_district_code": 190901,
    "district_code": 1909,
    "province_code": 19
  },
  {
    "sub_district": "ขุนโขลน",
    "district": "พระพุทธบาท",
    "province": "สระบุรี",
    "zipcode": 18120,
    "sub_district_code": 190902,
    "district_code": 1909,
    "province_code": 19
  },
  {
    "sub_district": "ธารเกษม",
    "district": "พระพุทธบาท",
    "province": "สระบุรี",
    "zipcode": 18120,
    "sub_district_code": 190903,
    "district_code": 1909,
    "province_code": 19
  },
  {
    "sub_district": "นายาว",
    "district": "พระพุทธบาท",
    "province": "สระบุรี",
    "zipcode": 18120,
    "sub_district_code": 190904,
    "district_code": 1909,
    "province_code": 19
  },
  {
    "sub_district": "พุคำจาน",
    "district": "พระพุทธบาท",
    "province": "สระบุรี",
    "zipcode": 18120,
    "sub_district_code": 190905,
    "district_code": 1909,
    "province_code": 19
  },
  {
    "sub_district": "เขาวง",
    "district": "พระพุทธบาท",
    "province": "สระบุรี",
    "zipcode": 18120,
    "sub_district_code": 190906,
    "district_code": 1909,
    "province_code": 19
  },
  {
    "sub_district": "ห้วยป่าหวาย",
    "district": "พระพุทธบาท",
    "province": "สระบุรี",
    "zipcode": 18120,
    "sub_district_code": 190907,
    "district_code": 1909,
    "province_code": 19
  },
  {
    "sub_district": "พุกร่าง",
    "district": "พระพุทธบาท",
    "province": "สระบุรี",
    "zipcode": 18120,
    "sub_district_code": 190908,
    "district_code": 1909,
    "province_code": 19
  },
  {
    "sub_district": "หนองแก",
    "district": "พระพุทธบาท",
    "province": "สระบุรี",
    "zipcode": 18120,
    "sub_district_code": 190909,
    "district_code": 1909,
    "province_code": 19
  },
  {
    "sub_district": "มวกเหล็ก",
    "district": "มวกเหล็ก",
    "province": "สระบุรี",
    "zipcode": 18180,
    "sub_district_code": 191101,
    "district_code": 1911,
    "province_code": 19
  },
  {
    "sub_district": "มิตรภาพ",
    "district": "มวกเหล็ก",
    "province": "สระบุรี",
    "zipcode": 18180,
    "sub_district_code": 191102,
    "district_code": 1911,
    "province_code": 19
  },
  {
    "sub_district": "แสลงพัน",
    "district": "มวกเหล็ก",
    "province": "สระบุรี",
    "zipcode": 18220,
    "sub_district_code": 191195,
    "district_code": 1911,
    "province_code": 19
  },
  {
    "sub_district": "หนองย่างเสือ",
    "district": "มวกเหล็ก",
    "province": "สระบุรี",
    "zipcode": 18180,
    "sub_district_code": 191104,
    "district_code": 1911,
    "province_code": 19
  },
  {
    "sub_district": "ลำสมพุง",
    "district": "มวกเหล็ก",
    "province": "สระบุรี",
    "zipcode": 30130,
    "sub_district_code": 191105,
    "district_code": 1911,
    "province_code": 19
  },
  {
    "sub_district": "คำพราน",
    "district": "มวกเหล็ก",
    "province": "สระบุรี",
    "zipcode": 18220,
    "sub_district_code": 191196,
    "district_code": 1911,
    "province_code": 19
  },
  {
    "sub_district": "ลำพญากลาง",
    "district": "มวกเหล็ก",
    "province": "สระบุรี",
    "zipcode": 30130,
    "sub_district_code": 191107,
    "district_code": 1911,
    "province_code": 19
  },
  {
    "sub_district": "วังม่วง",
    "district": "มวกเหล็ก",
    "province": "สระบุรี",
    "zipcode": 18220,
    "sub_district_code": 191198,
    "district_code": 1911,
    "province_code": 19
  },
  {
    "sub_district": "ซับสนุ่น",
    "district": "มวกเหล็ก",
    "province": "สระบุรี",
    "zipcode": 18220,
    "sub_district_code": 191109,
    "district_code": 1911,
    "province_code": 19
  },
  {
    "sub_district": "หนองหมู",
    "district": "วิหารแดง",
    "province": "สระบุรี",
    "zipcode": 18150,
    "sub_district_code": 190401,
    "district_code": 1904,
    "province_code": 19
  },
  {
    "sub_district": "บ้านลำ",
    "district": "วิหารแดง",
    "province": "สระบุรี",
    "zipcode": 18150,
    "sub_district_code": 190402,
    "district_code": 1904,
    "province_code": 19
  },
  {
    "sub_district": "คลองเรือ",
    "district": "วิหารแดง",
    "province": "สระบุรี",
    "zipcode": 18150,
    "sub_district_code": 190403,
    "district_code": 1904,
    "province_code": 19
  },
  {
    "sub_district": "วิหารแดง",
    "district": "วิหารแดง",
    "province": "สระบุรี",
    "zipcode": 18150,
    "sub_district_code": 190404,
    "district_code": 1904,
    "province_code": 19
  },
  {
    "sub_district": "หนองสรวง",
    "district": "วิหารแดง",
    "province": "สระบุรี",
    "zipcode": 18150,
    "sub_district_code": 190405,
    "district_code": 1904,
    "province_code": 19
  },
  {
    "sub_district": "เจริญธรรม",
    "district": "วิหารแดง",
    "province": "สระบุรี",
    "zipcode": 18150,
    "sub_district_code": 190406,
    "district_code": 1904,
    "province_code": 19
  },
  {
    "sub_district": "เสาไห้",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191001,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "บ้านยาง",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191002,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "หัวปลวก",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191003,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "งิ้วงาม",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191004,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "ศาลารีไทย",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191005,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "ต้นตาล",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191006,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191007,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "พระยาทด",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191008,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "ม่วงงาม",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191009,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "เริงราง",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191010,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "เมืองเก่า",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191011,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "สวนดอกไม้",
    "district": "เสาไห้",
    "province": "สระบุรี",
    "zipcode": 18160,
    "sub_district_code": 191012,
    "district_code": 1910,
    "province_code": 19
  },
  {
    "sub_district": "หนองแค",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18140,
    "sub_district_code": 190301,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "กุ่มหัก",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18140,
    "sub_district_code": 190302,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "คชสิทธิ์",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18250,
    "sub_district_code": 190303,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "โคกตูม",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18250,
    "sub_district_code": 190304,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "โคกแย้",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18230,
    "sub_district_code": 190305,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "บัวลอย",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18230,
    "sub_district_code": 190306,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "ไผ่ต่ำ",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18140,
    "sub_district_code": 190307,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "โพนทอง",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18250,
    "sub_district_code": 190308,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "ห้วยขมิ้น",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18230,
    "sub_district_code": 190309,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "ห้วยทราย",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18230,
    "sub_district_code": 190310,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "หนองไข่น้ำ",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18140,
    "sub_district_code": 190311,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "หนองแขม",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18140,
    "sub_district_code": 190312,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "หนองจิก",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18230,
    "sub_district_code": 190313,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "หนองจรเข้",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18140,
    "sub_district_code": 190314,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "หนองนาก",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18230,
    "sub_district_code": 190315,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "หนองปลาหมอ",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18140,
    "sub_district_code": 190316,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "หนองโรง",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18140,
    "sub_district_code": 190318,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "หนองหมู",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18150,
    "sub_district_code": 190398,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "หนองปลิง",
    "district": "หนองแค",
    "province": "สระบุรี",
    "zipcode": 18140,
    "sub_district_code": 190317,
    "district_code": 1903,
    "province_code": 19
  },
  {
    "sub_district": "หนองแซง",
    "district": "หนองแซง",
    "province": "สระบุรี",
    "zipcode": 18170,
    "sub_district_code": 190501,
    "district_code": 1905,
    "province_code": 19
  },
  {
    "sub_district": "หนองควายโซ",
    "district": "หนองแซง",
    "province": "สระบุรี",
    "zipcode": 18170,
    "sub_district_code": 190502,
    "district_code": 1905,
    "province_code": 19
  },
  {
    "sub_district": "หนองหัวโพ",
    "district": "หนองแซง",
    "province": "สระบุรี",
    "zipcode": 18170,
    "sub_district_code": 190503,
    "district_code": 1905,
    "province_code": 19
  },
  {
    "sub_district": "หนองสีดา",
    "district": "หนองแซง",
    "province": "สระบุรี",
    "zipcode": 18170,
    "sub_district_code": 190504,
    "district_code": 1905,
    "province_code": 19
  },
  {
    "sub_district": "หนองกบ",
    "district": "หนองแซง",
    "province": "สระบุรี",
    "zipcode": 18170,
    "sub_district_code": 190505,
    "district_code": 1905,
    "province_code": 19
  },
  {
    "sub_district": "ไก่เส่า",
    "district": "หนองแซง",
    "province": "สระบุรี",
    "zipcode": 18170,
    "sub_district_code": 190506,
    "district_code": 1905,
    "province_code": 19
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "หนองแซง",
    "province": "สระบุรี",
    "zipcode": 18170,
    "sub_district_code": 190507,
    "district_code": 1905,
    "province_code": 19
  },
  {
    "sub_district": "ม่วงหวาน",
    "district": "หนองแซง",
    "province": "สระบุรี",
    "zipcode": 18170,
    "sub_district_code": 190508,
    "district_code": 1905,
    "province_code": 19
  },
  {
    "sub_district": "เขาดิน",
    "district": "หนองแซง",
    "province": "สระบุรี",
    "zipcode": 18170,
    "sub_district_code": 190509,
    "district_code": 1905,
    "province_code": 19
  },
  {
    "sub_district": "หนองโดน",
    "district": "หนองโดน",
    "province": "สระบุรี",
    "zipcode": 18190,
    "sub_district_code": 190801,
    "district_code": 1908,
    "province_code": 19
  },
  {
    "sub_district": "บ้านกลับ",
    "district": "หนองโดน",
    "province": "สระบุรี",
    "zipcode": 18190,
    "sub_district_code": 190802,
    "district_code": 1908,
    "province_code": 19
  },
  {
    "sub_district": "ดอนทอง",
    "district": "หนองโดน",
    "province": "สระบุรี",
    "zipcode": 18190,
    "sub_district_code": 190803,
    "district_code": 1908,
    "province_code": 19
  },
  {
    "sub_district": "บ้านโปร่ง",
    "district": "หนองโดน",
    "province": "สระบุรี",
    "zipcode": 18190,
    "sub_district_code": 190804,
    "district_code": 1908,
    "province_code": 19
  },
  {
    "sub_district": "คำพราน",
    "district": "วังม่วง",
    "province": "สระบุรี",
    "zipcode": 18220,
    "sub_district_code": 191202,
    "district_code": 1912,
    "province_code": 19
  },
  {
    "sub_district": "วังม่วง",
    "district": "วังม่วง",
    "province": "สระบุรี",
    "zipcode": 18220,
    "sub_district_code": 191203,
    "district_code": 1912,
    "province_code": 19
  },
  {
    "sub_district": "แสลงพัน",
    "district": "วังม่วง",
    "province": "สระบุรี",
    "zipcode": 18220,
    "sub_district_code": 191201,
    "district_code": 1912,
    "province_code": 19
  },
  {
    "sub_district": "ผึ้งรวง",
    "district": "เฉลิมพระเกียรติ",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 191303,
    "district_code": 1913,
    "province_code": 19
  },
  {
    "sub_district": "หน้าพระลาน",
    "district": "เฉลิมพระเกียรติ",
    "province": "สระบุรี",
    "zipcode": 18240,
    "sub_district_code": 191306,
    "district_code": 1913,
    "province_code": 19
  },
  {
    "sub_district": "เขาดินพัฒนา",
    "district": "เฉลิมพระเกียรติ",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 191301,
    "district_code": 1913,
    "province_code": 19
  },
  {
    "sub_district": "บ้านแก้ง",
    "district": "เฉลิมพระเกียรติ",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 191302,
    "district_code": 1913,
    "province_code": 19
  },
  {
    "sub_district": "พุแค",
    "district": "เฉลิมพระเกียรติ",
    "province": "สระบุรี",
    "zipcode": 18240,
    "sub_district_code": 191304,
    "district_code": 1913,
    "province_code": 19
  },
  {
    "sub_district": "ห้วยบง",
    "district": "เฉลิมพระเกียรติ",
    "province": "สระบุรี",
    "zipcode": 18000,
    "sub_district_code": 191305,
    "district_code": 1913,
    "province_code": 19
  },
  {
    "sub_district": "บางพุทรา",
    "district": "เมืองสิงห์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16000,
    "sub_district_code": 170101,
    "district_code": 1701,
    "province_code": 17
  },
  {
    "sub_district": "บางมัญ",
    "district": "เมืองสิงห์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16000,
    "sub_district_code": 170102,
    "district_code": 1701,
    "province_code": 17
  },
  {
    "sub_district": "โพกรวม",
    "district": "เมืองสิงห์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16000,
    "sub_district_code": 170103,
    "district_code": 1701,
    "province_code": 17
  },
  {
    "sub_district": "ม่วงหมู่",
    "district": "เมืองสิงห์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16000,
    "sub_district_code": 170104,
    "district_code": 1701,
    "province_code": 17
  },
  {
    "sub_district": "หัวไผ่",
    "district": "เมืองสิงห์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16000,
    "sub_district_code": 170105,
    "district_code": 1701,
    "province_code": 17
  },
  {
    "sub_district": "ต้นโพธิ์",
    "district": "เมืองสิงห์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16000,
    "sub_district_code": 170106,
    "district_code": 1701,
    "province_code": 17
  },
  {
    "sub_district": "จักรสีห์",
    "district": "เมืองสิงห์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16000,
    "sub_district_code": 170107,
    "district_code": 1701,
    "province_code": 17
  },
  {
    "sub_district": "บางกระบือ",
    "district": "เมืองสิงห์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16000,
    "sub_district_code": 170108,
    "district_code": 1701,
    "province_code": 17
  },
  {
    "sub_district": "น้ำตาล",
    "district": "เมืองสิงห์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170197,
    "district_code": 1701,
    "province_code": 17
  },
  {
    "sub_district": "ทองเอน",
    "district": "เมืองสิงห์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170198,
    "district_code": 1701,
    "province_code": 17
  },
  {
    "sub_district": "โพทะเล",
    "district": "ค่ายบางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16150,
    "sub_district_code": 170301,
    "district_code": 1703,
    "province_code": 17
  },
  {
    "sub_district": "บางระจัน",
    "district": "ค่ายบางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16150,
    "sub_district_code": 170302,
    "district_code": 1703,
    "province_code": 17
  },
  {
    "sub_district": "โพสังโฆ",
    "district": "ค่ายบางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16150,
    "sub_district_code": 170303,
    "district_code": 1703,
    "province_code": 17
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "ค่ายบางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16150,
    "sub_district_code": 170304,
    "district_code": 1703,
    "province_code": 17
  },
  {
    "sub_district": "คอทราย",
    "district": "ค่ายบางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16150,
    "sub_district_code": 170305,
    "district_code": 1703,
    "province_code": 17
  },
  {
    "sub_district": "หนองกระทุ่ม",
    "district": "ค่ายบางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16150,
    "sub_district_code": 170306,
    "district_code": 1703,
    "province_code": 17
  },
  {
    "sub_district": "สิงห์",
    "district": "ค่ายบางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16130,
    "sub_district_code": 170398,
    "district_code": 1703,
    "province_code": 17
  },
  {
    "sub_district": "ถอนสมอ",
    "district": "ท่าช้าง",
    "province": "สิงห์บุรี",
    "zipcode": 16140,
    "sub_district_code": 170501,
    "district_code": 1705,
    "province_code": 17
  },
  {
    "sub_district": "โพประจักษ์",
    "district": "ท่าช้าง",
    "province": "สิงห์บุรี",
    "zipcode": 16140,
    "sub_district_code": 170502,
    "district_code": 1705,
    "province_code": 17
  },
  {
    "sub_district": "วิหารขาว",
    "district": "ท่าช้าง",
    "province": "สิงห์บุรี",
    "zipcode": 16140,
    "sub_district_code": 170503,
    "district_code": 1705,
    "province_code": 17
  },
  {
    "sub_district": "พิกุลทอง",
    "district": "ท่าช้าง",
    "province": "สิงห์บุรี",
    "zipcode": 16140,
    "sub_district_code": 170504,
    "district_code": 1705,
    "province_code": 17
  },
  {
    "sub_district": "สิงห์",
    "district": "บางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16130,
    "sub_district_code": 170201,
    "district_code": 1702,
    "province_code": 17
  },
  {
    "sub_district": "ไม้ดัด",
    "district": "บางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16130,
    "sub_district_code": 170202,
    "district_code": 1702,
    "province_code": 17
  },
  {
    "sub_district": "เชิงกลัด",
    "district": "บางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16130,
    "sub_district_code": 170203,
    "district_code": 1702,
    "province_code": 17
  },
  {
    "sub_district": "โพชนไก่",
    "district": "บางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16130,
    "sub_district_code": 170204,
    "district_code": 1702,
    "province_code": 17
  },
  {
    "sub_district": "แม่ลา",
    "district": "บางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16130,
    "sub_district_code": 170205,
    "district_code": 1702,
    "province_code": 17
  },
  {
    "sub_district": "บ้านจ่า",
    "district": "บางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16130,
    "sub_district_code": 170206,
    "district_code": 1702,
    "province_code": 17
  },
  {
    "sub_district": "พักทัน",
    "district": "บางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16130,
    "sub_district_code": 170207,
    "district_code": 1702,
    "province_code": 17
  },
  {
    "sub_district": "สระแจง",
    "district": "บางระจัน",
    "province": "สิงห์บุรี",
    "zipcode": 16130,
    "sub_district_code": 170208,
    "district_code": 1702,
    "province_code": 17
  },
  {
    "sub_district": "บ้านหม้อ",
    "district": "พรหมบุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16120,
    "sub_district_code": 170404,
    "district_code": 1704,
    "province_code": 17
  },
  {
    "sub_district": "บ้านแป้ง",
    "district": "พรหมบุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16120,
    "sub_district_code": 170405,
    "district_code": 1704,
    "province_code": 17
  },
  {
    "sub_district": "หัวป่า",
    "district": "พรหมบุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16120,
    "sub_district_code": 170406,
    "district_code": 1704,
    "province_code": 17
  },
  {
    "sub_district": "โรงช้าง",
    "district": "พรหมบุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16120,
    "sub_district_code": 170407,
    "district_code": 1704,
    "province_code": 17
  },
  {
    "sub_district": "พระงาม",
    "district": "พรหมบุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16120,
    "sub_district_code": 170401,
    "district_code": 1704,
    "province_code": 17
  },
  {
    "sub_district": "พรหมบุรี",
    "district": "พรหมบุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16160,
    "sub_district_code": 170402,
    "district_code": 1704,
    "province_code": 17
  },
  {
    "sub_district": "บางน้ำเชี่ยว",
    "district": "พรหมบุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16120,
    "sub_district_code": 170403,
    "district_code": 1704,
    "province_code": 17
  },
  {
    "sub_district": "อินทร์บุรี",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170601,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "ประศุก",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170602,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "ทับยา",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170603,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "งิ้วราย",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170604,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "ชีน้ำร้าย",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170605,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "ท่างาม",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170606,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "น้ำตาล",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170607,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "ทองเอน",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170608,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "ห้วยชัน",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170609,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "โพธิ์ชัย",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16110,
    "sub_district_code": 170610,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "โพกรวม",
    "district": "อินทร์บุรี",
    "province": "สิงห์บุรี",
    "zipcode": 16000,
    "sub_district_code": 170698,
    "district_code": 1706,
    "province_code": 17
  },
  {
    "sub_district": "ธานี",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64000,
    "sub_district_code": 640101,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "บ้านสวน",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64220,
    "sub_district_code": 640102,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "เมืองเก่า",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64210,
    "sub_district_code": 640103,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "ปากแคว",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64000,
    "sub_district_code": 640104,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "ยางซ้าย",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64000,
    "sub_district_code": 640105,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "บ้านกล้วย",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64000,
    "sub_district_code": 640106,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "บ้านหลุม",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64000,
    "sub_district_code": 640107,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "ตาลเตี้ย",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64220,
    "sub_district_code": 640108,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "ปากพระ",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64000,
    "sub_district_code": 640109,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "ปากแคว",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64000,
    "sub_district_code": 640104,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "ปากพระ",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64000,
    "sub_district_code": 640109,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "วังทองแดง",
    "district": "เมืองสุโขทัย",
    "province": "สุโขทัย",
    "zipcode": 64210,
    "sub_district_code": 640110,
    "district_code": 6401,
    "province_code": 64
  },
  {
    "sub_district": "กง",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640401,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "บ้านกร่าง",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640402,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "ไกรนอก",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640403,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "ไกรกลาง",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640404,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "ไกรใน",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640405,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "ดงเดือย",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640406,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "ป่าแฝก",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640407,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "กกแรต",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640408,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "ท่าฉนวน",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640409,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "หนองตูม",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640410,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "บ้านใหม่สุขเกษม",
    "district": "กงไกรลาศ",
    "province": "สุโขทัย",
    "zipcode": 64170,
    "sub_district_code": 640411,
    "district_code": 6404,
    "province_code": 64
  },
  {
    "sub_district": "โตนด",
    "district": "คีรีมาศ",
    "province": "สุโขทัย",
    "zipcode": 64160,
    "sub_district_code": 640301,
    "district_code": 6403,
    "province_code": 64
  },
  {
    "sub_district": "ทุ่งหลวง",
    "district": "คีรีมาศ",
    "province": "สุโขทัย",
    "zipcode": 64160,
    "sub_district_code": 640302,
    "district_code": 6403,
    "province_code": 64
  },
  {
    "sub_district": "บ้านป้อม",
    "district": "คีรีมาศ",
    "province": "สุโขทัย",
    "zipcode": 64160,
    "sub_district_code": 640303,
    "district_code": 6403,
    "province_code": 64
  },
  {
    "sub_district": "สามพวง",
    "district": "คีรีมาศ",
    "province": "สุโขทัย",
    "zipcode": 64160,
    "sub_district_code": 640304,
    "district_code": 6403,
    "province_code": 64
  },
  {
    "sub_district": "ศรีคีรีมาศ",
    "district": "คีรีมาศ",
    "province": "สุโขทัย",
    "zipcode": 64160,
    "sub_district_code": 640305,
    "district_code": 6403,
    "province_code": 64
  },
  {
    "sub_district": "หนองจิก",
    "district": "คีรีมาศ",
    "province": "สุโขทัย",
    "zipcode": 64160,
    "sub_district_code": 640306,
    "district_code": 6403,
    "province_code": 64
  },
  {
    "sub_district": "นาเชิงคีรี",
    "district": "คีรีมาศ",
    "province": "สุโขทัย",
    "zipcode": 64160,
    "sub_district_code": 640307,
    "district_code": 6403,
    "province_code": 64
  },
  {
    "sub_district": "หนองกระดิ่ง",
    "district": "คีรีมาศ",
    "province": "สุโขทัย",
    "zipcode": 64160,
    "sub_district_code": 640308,
    "district_code": 6403,
    "province_code": 64
  },
  {
    "sub_district": "บ้านน้ำพุ",
    "district": "คีรีมาศ",
    "province": "สุโขทัย",
    "zipcode": 64160,
    "sub_district_code": 640309,
    "district_code": 6403,
    "province_code": 64
  },
  {
    "sub_district": "ทุ่งยางเมือง",
    "district": "คีรีมาศ",
    "province": "สุโขทัย",
    "zipcode": 64160,
    "sub_district_code": 640310,
    "district_code": 6403,
    "province_code": 64
  },
  {
    "sub_district": "บ้านใหม่ไชยมงคล",
    "district": "ทุ่งเสลี่ยม",
    "province": "สุโขทัย",
    "zipcode": 64230,
    "sub_district_code": 640901,
    "district_code": 6409,
    "province_code": 64
  },
  {
    "sub_district": "ไทยชนะศึก",
    "district": "ทุ่งเสลี่ยม",
    "province": "สุโขทัย",
    "zipcode": 64150,
    "sub_district_code": 640902,
    "district_code": 6409,
    "province_code": 64
  },
  {
    "sub_district": "ทุ่งเสลี่ยม",
    "district": "ทุ่งเสลี่ยม",
    "province": "สุโขทัย",
    "zipcode": 64150,
    "sub_district_code": 640903,
    "district_code": 6409,
    "province_code": 64
  },
  {
    "sub_district": "กลางดง",
    "district": "ทุ่งเสลี่ยม",
    "province": "สุโขทัย",
    "zipcode": 64150,
    "sub_district_code": 640904,
    "district_code": 6409,
    "province_code": 64
  },
  {
    "sub_district": "เขาแก้วศรีสมบูรณ์",
    "district": "ทุ่งเสลี่ยม",
    "province": "สุโขทัย",
    "zipcode": 64230,
    "sub_district_code": 640905,
    "district_code": 6409,
    "province_code": 64
  },
  {
    "sub_district": "ลานหอย",
    "district": "บ้านด่านลานหอย",
    "province": "สุโขทัย",
    "zipcode": 64140,
    "sub_district_code": 640201,
    "district_code": 6402,
    "province_code": 64
  },
  {
    "sub_district": "บ้านด่าน",
    "district": "บ้านด่านลานหอย",
    "province": "สุโขทัย",
    "zipcode": 64140,
    "sub_district_code": 640202,
    "district_code": 6402,
    "province_code": 64
  },
  {
    "sub_district": "วังตะคร้อ",
    "district": "บ้านด่านลานหอย",
    "province": "สุโขทัย",
    "zipcode": 64140,
    "sub_district_code": 640203,
    "district_code": 6402,
    "province_code": 64
  },
  {
    "sub_district": "วังน้ำขาว",
    "district": "บ้านด่านลานหอย",
    "province": "สุโขทัย",
    "zipcode": 64140,
    "sub_district_code": 640204,
    "district_code": 6402,
    "province_code": 64
  },
  {
    "sub_district": "ตลิ่งชัน",
    "district": "บ้านด่านลานหอย",
    "province": "สุโขทัย",
    "zipcode": 64140,
    "sub_district_code": 640205,
    "district_code": 6402,
    "province_code": 64
  },
  {
    "sub_district": "หนองหญ้าปล้อง",
    "district": "บ้านด่านลานหอย",
    "province": "สุโขทัย",
    "zipcode": 64140,
    "sub_district_code": 640206,
    "district_code": 6402,
    "province_code": 64
  },
  {
    "sub_district": "วังลึก",
    "district": "บ้านด่านลานหอย",
    "province": "สุโขทัย",
    "zipcode": 64140,
    "sub_district_code": 640207,
    "district_code": 6402,
    "province_code": 64
  },
  {
    "sub_district": "หาดเสี้ยว",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64130,
    "sub_district_code": 640501,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "ป่างิ้ว",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64130,
    "sub_district_code": 640502,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "แม่สำ",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64130,
    "sub_district_code": 640503,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "แม่สิน",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64130,
    "sub_district_code": 640504,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "บ้านตึก",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64130,
    "sub_district_code": 640505,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "หนองอ้อ",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64130,
    "sub_district_code": 640506,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "ท่าชัย",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64190,
    "sub_district_code": 640507,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "ศรีสัชนาลัย",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64190,
    "sub_district_code": 640508,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "ดงคู่",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64130,
    "sub_district_code": 640509,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "บ้านแก่ง",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64130,
    "sub_district_code": 640510,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "สารจิตร",
    "district": "ศรีสัชนาลัย",
    "province": "สุโขทัย",
    "zipcode": 64130,
    "sub_district_code": 640511,
    "district_code": 6405,
    "province_code": 64
  },
  {
    "sub_district": "คลองตาล",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640601,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "วังลึก",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640602,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "สามเรือน",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640603,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "บ้านนา",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640604,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "วังทอง",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640605,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "นาขุนไกร",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640606,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "เกาะตาเลี้ยง",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640607,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "วัดเกาะ",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640608,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "บ้านไร่",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640609,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "ทับผึ้ง",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640610,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "บ้านซ่าน",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640611,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "วังใหญ่",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640612,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "ราวต้นจันทร์",
    "district": "ศรีสำโรง",
    "province": "สุโขทัย",
    "zipcode": 64120,
    "sub_district_code": 640613,
    "district_code": 6406,
    "province_code": 64
  },
  {
    "sub_district": "เมืองสวรรคโลก",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640701,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "ในเมือง",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640702,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "คลองกระจง",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640703,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "วังพิณพาทย์",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640704,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "วังไม้ขอน",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640705,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "ย่านยาว",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640706,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "นาทุ่ง",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640707,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "คลองยาง",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640708,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "เมืองบางยม",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640709,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "ท่าทอง",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640710,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "ปากน้ำ",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640711,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "ป่ากุมเกาะ",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640712,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "เมืองบางขลัง",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640713,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "หนองกลับ",
    "district": "สวรรคโลก",
    "province": "สุโขทัย",
    "zipcode": 64110,
    "sub_district_code": 640714,
    "district_code": 6407,
    "province_code": 64
  },
  {
    "sub_district": "ศรีนคร",
    "district": "ศรีนคร",
    "province": "สุโขทัย",
    "zipcode": 64180,
    "sub_district_code": 640801,
    "district_code": 6408,
    "province_code": 64
  },
  {
    "sub_district": "นครเดิฐ",
    "district": "ศรีนคร",
    "province": "สุโขทัย",
    "zipcode": 64180,
    "sub_district_code": 640802,
    "district_code": 6408,
    "province_code": 64
  },
  {
    "sub_district": "น้ำขุม",
    "district": "ศรีนคร",
    "province": "สุโขทัย",
    "zipcode": 64180,
    "sub_district_code": 640803,
    "district_code": 6408,
    "province_code": 64
  },
  {
    "sub_district": "คลองมะพลับ",
    "district": "ศรีนคร",
    "province": "สุโขทัย",
    "zipcode": 64180,
    "sub_district_code": 640804,
    "district_code": 6408,
    "province_code": 64
  },
  {
    "sub_district": "หนองบัว",
    "district": "ศรีนคร",
    "province": "สุโขทัย",
    "zipcode": 64180,
    "sub_district_code": 640805,
    "district_code": 6408,
    "province_code": 64
  },
  {
    "sub_district": "ท่าพี่เลี้ยง",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720101,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "รั้วใหญ่",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720102,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "ทับตีเหล็ก",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720103,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "ท่าระหัด",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720104,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "ไผ่ขวาง",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720105,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "โคกโคเฒ่า",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720106,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "ดอนตาล",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720107,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "ดอนมะสังข์",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720108,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "พิหารแดง",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720109,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "ดอนกำยาน",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720110,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "ดอนโพธิ์ทอง",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720111,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "บ้านโพธิ์",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720112,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "สระแก้ว",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72230,
    "sub_district_code": 720113,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "ตลิ่งชัน",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72230,
    "sub_district_code": 720114,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "บางกุ้ง",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72210,
    "sub_district_code": 720115,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "ศาลาขาว",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72210,
    "sub_district_code": 720116,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "สวนแตง",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72210,
    "sub_district_code": 720117,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "สนามชัย",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720118,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "โพธิ์พระยา",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72000,
    "sub_district_code": 720119,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "สนามคลี",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72230,
    "sub_district_code": 720120,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "บางปลาม้า",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720195,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "เขาพระ",
    "district": "เมืองสุพรรณบุรี",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720196,
    "district_code": 7201,
    "province_code": 72
  },
  {
    "sub_district": "ดอนเจดีย์",
    "district": "ดอนเจดีย์",
    "province": "สุพรรณบุรี",
    "zipcode": 72170,
    "sub_district_code": 720601,
    "district_code": 7206,
    "province_code": 72
  },
  {
    "sub_district": "หนองสาหร่าย",
    "district": "ดอนเจดีย์",
    "province": "สุพรรณบุรี",
    "zipcode": 72170,
    "sub_district_code": 720602,
    "district_code": 7206,
    "province_code": 72
  },
  {
    "sub_district": "ไร่รถ",
    "district": "ดอนเจดีย์",
    "province": "สุพรรณบุรี",
    "zipcode": 72170,
    "sub_district_code": 720603,
    "district_code": 7206,
    "province_code": 72
  },
  {
    "sub_district": "สระกระโจม",
    "district": "ดอนเจดีย์",
    "province": "สุพรรณบุรี",
    "zipcode": 72250,
    "sub_district_code": 720604,
    "district_code": 7206,
    "province_code": 72
  },
  {
    "sub_district": "ทะเลบก",
    "district": "ดอนเจดีย์",
    "province": "สุพรรณบุรี",
    "zipcode": 72250,
    "sub_district_code": 720605,
    "district_code": 7206,
    "province_code": 72
  },
  {
    "sub_district": "เขาพระ",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720201,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "เดิมบาง",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720202,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "นางบวช",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720203,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "เขาดิน",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720204,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "ปากน้ำ",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720205,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "ทุ่งคลี",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720206,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "โคกช้าง",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720207,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "หัวเขา",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720208,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "หัวนา",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720209,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "บ่อกรุ",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720210,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "วังศรีราช",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720211,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "ป่าสะแก",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720212,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "ยางนอน",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720213,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "หนองกระทุ่ม",
    "district": "เดิมบางนางบวช",
    "province": "สุพรรณบุรี",
    "zipcode": 72120,
    "sub_district_code": 720214,
    "district_code": 7202,
    "province_code": 72
  },
  {
    "sub_district": "โคกคราม",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720401,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "บางปลาม้า",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720402,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "ตะค่า",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720403,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "บางใหญ่",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720404,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "กฤษณา",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720405,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "สาลี",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720406,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "ไผ่กองดิน",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720407,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "องครักษ์",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720408,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "จรเข้ใหญ่",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720409,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "บ้านแหลม",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720410,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "มะขามล้ม",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720411,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "วังน้ำเย็น",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720412,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "วัดโบสถ์",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720413,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "วัดดาว",
    "district": "บางปลาม้า",
    "province": "สุพรรณบุรี",
    "zipcode": 72150,
    "sub_district_code": 720414,
    "district_code": 7204,
    "province_code": 72
  },
  {
    "sub_district": "ศรีประจันต์",
    "district": "ศรีประจันต์",
    "province": "สุพรรณบุรี",
    "zipcode": 72140,
    "sub_district_code": 720501,
    "district_code": 7205,
    "province_code": 72
  },
  {
    "sub_district": "บ้านกร่าง",
    "district": "ศรีประจันต์",
    "province": "สุพรรณบุรี",
    "zipcode": 72140,
    "sub_district_code": 720502,
    "district_code": 7205,
    "province_code": 72
  },
  {
    "sub_district": "มดแดง",
    "district": "ศรีประจันต์",
    "province": "สุพรรณบุรี",
    "zipcode": 72140,
    "sub_district_code": 720503,
    "district_code": 7205,
    "province_code": 72
  },
  {
    "sub_district": "บางงาม",
    "district": "ศรีประจันต์",
    "province": "สุพรรณบุรี",
    "zipcode": 72140,
    "sub_district_code": 720504,
    "district_code": 7205,
    "province_code": 72
  },
  {
    "sub_district": "ดอนปรู",
    "district": "ศรีประจันต์",
    "province": "สุพรรณบุรี",
    "zipcode": 72140,
    "sub_district_code": 720505,
    "district_code": 7205,
    "province_code": 72
  },
  {
    "sub_district": "ปลายนา",
    "district": "ศรีประจันต์",
    "province": "สุพรรณบุรี",
    "zipcode": 72140,
    "sub_district_code": 720506,
    "district_code": 7205,
    "province_code": 72
  },
  {
    "sub_district": "วังหว้า",
    "district": "ศรีประจันต์",
    "province": "สุพรรณบุรี",
    "zipcode": 72140,
    "sub_district_code": 720507,
    "district_code": 7205,
    "province_code": 72
  },
  {
    "sub_district": "วังน้ำซับ",
    "district": "ศรีประจันต์",
    "province": "สุพรรณบุรี",
    "zipcode": 72140,
    "sub_district_code": 720508,
    "district_code": 7205,
    "province_code": 72
  },
  {
    "sub_district": "วังยาง",
    "district": "ศรีประจันต์",
    "province": "สุพรรณบุรี",
    "zipcode": 72140,
    "sub_district_code": 720509,
    "district_code": 7205,
    "province_code": 72
  },
  {
    "sub_district": "สองพี่น้อง",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720701,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "บางเลน",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720702,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "บางตาเถร",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720703,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "บางตะเคียน",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720704,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "บ้านกุ่ม",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720705,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "หัวโพธิ์",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720706,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "บางพลับ",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720707,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "เนินพระปรางค์",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720708,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "บ้านช้าง",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720709,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "ต้นตาล",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720710,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "ศรีสำราญ",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720711,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "ทุ่งคอก",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720712,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "หนองบ่อ",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72190,
    "sub_district_code": 720713,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "บ่อสุพรรณ",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72190,
    "sub_district_code": 720714,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "ดอนมะนาว",
    "district": "สองพี่น้อง",
    "province": "สุพรรณบุรี",
    "zipcode": 72110,
    "sub_district_code": 720715,
    "district_code": 7207,
    "province_code": 72
  },
  {
    "sub_district": "ย่านยาว",
    "district": "สามชุก",
    "province": "สุพรรณบุรี",
    "zipcode": 72130,
    "sub_district_code": 720801,
    "district_code": 7208,
    "province_code": 72
  },
  {
    "sub_district": "วังลึก",
    "district": "สามชุก",
    "province": "สุพรรณบุรี",
    "zipcode": 72130,
    "sub_district_code": 720802,
    "district_code": 7208,
    "province_code": 72
  },
  {
    "sub_district": "สามชุก",
    "district": "สามชุก",
    "province": "สุพรรณบุรี",
    "zipcode": 72130,
    "sub_district_code": 720803,
    "district_code": 7208,
    "province_code": 72
  },
  {
    "sub_district": "หนองผักนาก",
    "district": "สามชุก",
    "province": "สุพรรณบุรี",
    "zipcode": 72130,
    "sub_district_code": 720804,
    "district_code": 7208,
    "province_code": 72
  },
  {
    "sub_district": "บ้านสระ",
    "district": "สามชุก",
    "province": "สุพรรณบุรี",
    "zipcode": 72130,
    "sub_district_code": 720805,
    "district_code": 7208,
    "province_code": 72
  },
  {
    "sub_district": "หนองสะเดา",
    "district": "สามชุก",
    "province": "สุพรรณบุรี",
    "zipcode": 72130,
    "sub_district_code": 720806,
    "district_code": 7208,
    "province_code": 72
  },
  {
    "sub_district": "หนองหญ้าไซ",
    "district": "สามชุก",
    "province": "สุพรรณบุรี",
    "zipcode": 72240,
    "sub_district_code": 720896,
    "district_code": 7208,
    "province_code": 72
  },
  {
    "sub_district": "หนองราชวัตร",
    "district": "สามชุก",
    "province": "สุพรรณบุรี",
    "zipcode": 72240,
    "sub_district_code": 720897,
    "district_code": 7208,
    "province_code": 72
  },
  {
    "sub_district": "กระเสียว",
    "district": "สามชุก",
    "province": "สุพรรณบุรี",
    "zipcode": 72130,
    "sub_district_code": 720807,
    "district_code": 7208,
    "province_code": 72
  },
  {
    "sub_district": "แจงงาม",
    "district": "สามชุก",
    "province": "สุพรรณบุรี",
    "zipcode": 72240,
    "sub_district_code": 720898,
    "district_code": 7208,
    "province_code": 72
  },
  {
    "sub_district": "อู่ทอง",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72160,
    "sub_district_code": 720901,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "สระยายโสม",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72220,
    "sub_district_code": 720902,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "จรเข้สามพัน",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 71170,
    "sub_district_code": 720903,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "บ้านดอน",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72160,
    "sub_district_code": 720904,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "ยุ้งทะลาย",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72160,
    "sub_district_code": 720905,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "ดอนมะเกลือ",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72220,
    "sub_district_code": 720906,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "หนองโอ่ง",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72160,
    "sub_district_code": 720907,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "ดอนคา",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72160,
    "sub_district_code": 720908,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "พลับพลาไชย",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72160,
    "sub_district_code": 720909,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "บ้านโข้ง",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72160,
    "sub_district_code": 720910,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "เจดีย์",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72160,
    "sub_district_code": 720911,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "สระพังลาน",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72220,
    "sub_district_code": 720912,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "กระจัน",
    "district": "อู่ทอง",
    "province": "สุพรรณบุรี",
    "zipcode": 72160,
    "sub_district_code": 720913,
    "district_code": 7209,
    "province_code": 72
  },
  {
    "sub_district": "หนองมะค่าโมง",
    "district": "ด่านช้าง",
    "province": "สุพรรณบุรี",
    "zipcode": 72180,
    "sub_district_code": 720301,
    "district_code": 7203,
    "province_code": 72
  },
  {
    "sub_district": "ด่านช้าง",
    "district": "ด่านช้าง",
    "province": "สุพรรณบุรี",
    "zipcode": 72180,
    "sub_district_code": 720302,
    "district_code": 7203,
    "province_code": 72
  },
  {
    "sub_district": "ห้วยขมิ้น",
    "district": "ด่านช้าง",
    "province": "สุพรรณบุรี",
    "zipcode": 72180,
    "sub_district_code": 720303,
    "district_code": 7203,
    "province_code": 72
  },
  {
    "sub_district": "องค์พระ",
    "district": "ด่านช้าง",
    "province": "สุพรรณบุรี",
    "zipcode": 72180,
    "sub_district_code": 720304,
    "district_code": 7203,
    "province_code": 72
  },
  {
    "sub_district": "วังคัน",
    "district": "ด่านช้าง",
    "province": "สุพรรณบุรี",
    "zipcode": 72180,
    "sub_district_code": 720305,
    "district_code": 7203,
    "province_code": 72
  },
  {
    "sub_district": "นิคมกระเสียว",
    "district": "ด่านช้าง",
    "province": "สุพรรณบุรี",
    "zipcode": 72180,
    "sub_district_code": 720306,
    "district_code": 7203,
    "province_code": 72
  },
  {
    "sub_district": "วังยาว",
    "district": "ด่านช้าง",
    "province": "สุพรรณบุรี",
    "zipcode": 72180,
    "sub_district_code": 720307,
    "district_code": 7203,
    "province_code": 72
  },
  {
    "sub_district": "หนองหญ้าไซ",
    "district": "หนองหญ้าไซ",
    "province": "สุพรรณบุรี",
    "zipcode": 72240,
    "sub_district_code": 721001,
    "district_code": 7210,
    "province_code": 72
  },
  {
    "sub_district": "หนองราชวัตร",
    "district": "หนองหญ้าไซ",
    "province": "สุพรรณบุรี",
    "zipcode": 72240,
    "sub_district_code": 721002,
    "district_code": 7210,
    "province_code": 72
  },
  {
    "sub_district": "หนองโพธิ์",
    "district": "หนองหญ้าไซ",
    "province": "สุพรรณบุรี",
    "zipcode": 72240,
    "sub_district_code": 721003,
    "district_code": 7210,
    "province_code": 72
  },
  {
    "sub_district": "แจงงาม",
    "district": "หนองหญ้าไซ",
    "province": "สุพรรณบุรี",
    "zipcode": 72240,
    "sub_district_code": 721004,
    "district_code": 7210,
    "province_code": 72
  },
  {
    "sub_district": "หนองขาม",
    "district": "หนองหญ้าไซ",
    "province": "สุพรรณบุรี",
    "zipcode": 72240,
    "sub_district_code": 721005,
    "district_code": 7210,
    "province_code": 72
  },
  {
    "sub_district": "ทัพหลวง",
    "district": "หนองหญ้าไซ",
    "province": "สุพรรณบุรี",
    "zipcode": 72240,
    "sub_district_code": 721006,
    "district_code": 7210,
    "province_code": 72
  },
  {
    "sub_district": "ตลาด",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840101,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "มะขามเตี้ย",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840102,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "วัดประดู่",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840103,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "ขุนทะเล",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84100,
    "sub_district_code": 840104,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "บางใบไม้",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840105,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "บางชนะ",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840106,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "คลองน้อย",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840107,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "บางไทร",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840108,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "บางโพธิ์",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840109,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "บางกุ้ง",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840110,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "คลองฉนาก",
    "district": "เมืองสุราษฎร์ธานี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840111,
    "district_code": 8401,
    "province_code": 84
  },
  {
    "sub_district": "ท่าทองใหม่",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84290,
    "sub_district_code": 840201,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "ท่าทอง",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84160,
    "sub_district_code": 840202,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "กะแดะ",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84160,
    "sub_district_code": 840203,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "ทุ่งกง",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84290,
    "sub_district_code": 840204,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "กรูด",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84160,
    "sub_district_code": 840205,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "ช้างซ้าย",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84160,
    "sub_district_code": 840206,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "พลายวาส",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84160,
    "sub_district_code": 840207,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "ป่าร่อน",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84160,
    "sub_district_code": 840208,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "ตะเคียนทอง",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84160,
    "sub_district_code": 840209,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "ช้างขวา",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84160,
    "sub_district_code": 840210,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "ท่าอุแท",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84340,
    "sub_district_code": 840211,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "ทุ่งรัง",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84290,
    "sub_district_code": 840212,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "บางกุ้ง",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84000,
    "sub_district_code": 840298,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "คลองสระ",
    "district": "กาญจนดิษฐ์",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84160,
    "sub_district_code": 840213,
    "district_code": 8402,
    "province_code": 84
  },
  {
    "sub_district": "เกาะพะงัน",
    "district": "เกาะพะงัน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84280,
    "sub_district_code": 840501,
    "district_code": 8405,
    "province_code": 84
  },
  {
    "sub_district": "บ้านใต้",
    "district": "เกาะพะงัน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84280,
    "sub_district_code": 840502,
    "district_code": 8405,
    "province_code": 84
  },
  {
    "sub_district": "บ้านใต้",
    "district": "เกาะพะงัน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84280,
    "sub_district_code": 840502,
    "district_code": 8405,
    "province_code": 84
  },
  {
    "sub_district": "เกาะเต่า",
    "district": "เกาะพะงัน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84360,
    "sub_district_code": 840503,
    "district_code": 8405,
    "province_code": 84
  },
  {
    "sub_district": "เกาะเต่า",
    "district": "เกาะพะงัน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84360,
    "sub_district_code": 840503,
    "district_code": 8405,
    "province_code": 84
  },
  {
    "sub_district": "อ่างทอง",
    "district": "เกาะสมุย",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84140,
    "sub_district_code": 840401,
    "district_code": 8404,
    "province_code": 84
  },
  {
    "sub_district": "ลิปะน้อย",
    "district": "เกาะสมุย",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84140,
    "sub_district_code": 840402,
    "district_code": 8404,
    "province_code": 84
  },
  {
    "sub_district": "ตลิ่งงาม",
    "district": "เกาะสมุย",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84140,
    "sub_district_code": 840403,
    "district_code": 8404,
    "province_code": 84
  },
  {
    "sub_district": "หน้าเมือง",
    "district": "เกาะสมุย",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84140,
    "sub_district_code": 840404,
    "district_code": 8404,
    "province_code": 84
  },
  {
    "sub_district": "มะเร็ต",
    "district": "เกาะสมุย",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84310,
    "sub_district_code": 840405,
    "district_code": 8404,
    "province_code": 84
  },
  {
    "sub_district": "บ่อผุด",
    "district": "เกาะสมุย",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84320,
    "sub_district_code": 840406,
    "district_code": 8404,
    "province_code": 84
  },
  {
    "sub_district": "แม่น้ำ",
    "district": "เกาะสมุย",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84330,
    "sub_district_code": 840407,
    "district_code": 8404,
    "province_code": 84
  },
  {
    "sub_district": "ท่าขนอน",
    "district": "คีรีรัฐนิคม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 840801,
    "district_code": 8408,
    "province_code": 84
  },
  {
    "sub_district": "บ้านยาง",
    "district": "คีรีรัฐนิคม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 840802,
    "district_code": 8408,
    "province_code": 84
  },
  {
    "sub_district": "น้ำหัก",
    "district": "คีรีรัฐนิคม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 840803,
    "district_code": 8408,
    "province_code": 84
  },
  {
    "sub_district": "ตะกุกใต้",
    "district": "คีรีรัฐนิคม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 840897,
    "district_code": 8408,
    "province_code": 84
  },
  {
    "sub_district": "ตะกุกเหนือ",
    "district": "คีรีรัฐนิคม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 840898,
    "district_code": 8408,
    "province_code": 84
  },
  {
    "sub_district": "กะเปา",
    "district": "คีรีรัฐนิคม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 840806,
    "district_code": 8408,
    "province_code": 84
  },
  {
    "sub_district": "ท่ากระดาน",
    "district": "คีรีรัฐนิคม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 840807,
    "district_code": 8408,
    "province_code": 84
  },
  {
    "sub_district": "ย่านยาว",
    "district": "คีรีรัฐนิคม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 840808,
    "district_code": 8408,
    "province_code": 84
  },
  {
    "sub_district": "ถ้ำสิงขร",
    "district": "คีรีรัฐนิคม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 840809,
    "district_code": 8408,
    "province_code": 84
  },
  {
    "sub_district": "บ้านทำเนียบ",
    "district": "คีรีรัฐนิคม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 840810,
    "district_code": 8408,
    "province_code": 84
  },
  {
    "sub_district": "เคียนซา",
    "district": "เคียนซา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84260,
    "sub_district_code": 841401,
    "district_code": 8414,
    "province_code": 84
  },
  {
    "sub_district": "พ่วงพรมคร",
    "district": "เคียนซา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84210,
    "sub_district_code": 841402,
    "district_code": 8414,
    "province_code": 84
  },
  {
    "sub_district": "เขาตอก",
    "district": "เคียนซา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84260,
    "sub_district_code": 841403,
    "district_code": 8414,
    "province_code": 84
  },
  {
    "sub_district": "อรัญคามวารี",
    "district": "เคียนซา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84260,
    "sub_district_code": 841404,
    "district_code": 8414,
    "province_code": 84
  },
  {
    "sub_district": "บ้านเสด็จ",
    "district": "เคียนซา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84260,
    "sub_district_code": 841405,
    "district_code": 8414,
    "province_code": 84
  },
  {
    "sub_district": "ตลาดไชยา",
    "district": "ไชยา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84110,
    "sub_district_code": 840601,
    "district_code": 8406,
    "province_code": 84
  },
  {
    "sub_district": "พุมเรียง",
    "district": "ไชยา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84110,
    "sub_district_code": 840602,
    "district_code": 8406,
    "province_code": 84
  },
  {
    "sub_district": "เลม็ด",
    "district": "ไชยา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84110,
    "sub_district_code": 840603,
    "district_code": 8406,
    "province_code": 84
  },
  {
    "sub_district": "เวียง",
    "district": "ไชยา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84110,
    "sub_district_code": 840604,
    "district_code": 8406,
    "province_code": 84
  },
  {
    "sub_district": "ทุ่ง",
    "district": "ไชยา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84110,
    "sub_district_code": 840605,
    "district_code": 8406,
    "province_code": 84
  },
  {
    "sub_district": "ป่าเว",
    "district": "ไชยา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84110,
    "sub_district_code": 840606,
    "district_code": 8406,
    "province_code": 84
  },
  {
    "sub_district": "ตะกรบ",
    "district": "ไชยา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84110,
    "sub_district_code": 840607,
    "district_code": 8406,
    "province_code": 84
  },
  {
    "sub_district": "โมถ่าย",
    "district": "ไชยา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84110,
    "sub_district_code": 840608,
    "district_code": 8406,
    "province_code": 84
  },
  {
    "sub_district": "ปากหมาก",
    "district": "ไชยา",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84110,
    "sub_district_code": 840609,
    "district_code": 8406,
    "province_code": 84
  },
  {
    "sub_district": "ดอนสัก",
    "district": "ดอนสัก",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84220,
    "sub_district_code": 840301,
    "district_code": 8403,
    "province_code": 84
  },
  {
    "sub_district": "ชลคราม",
    "district": "ดอนสัก",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84160,
    "sub_district_code": 840302,
    "district_code": 8403,
    "province_code": 84
  },
  {
    "sub_district": "ไชยคราม",
    "district": "ดอนสัก",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84220,
    "sub_district_code": 840303,
    "district_code": 8403,
    "province_code": 84
  },
  {
    "sub_district": "ปากแพรก",
    "district": "ดอนสัก",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84340,
    "sub_district_code": 840304,
    "district_code": 8403,
    "province_code": 84
  },
  {
    "sub_district": "ท่าฉาง",
    "district": "ท่าฉาง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84150,
    "sub_district_code": 841101,
    "district_code": 8411,
    "province_code": 84
  },
  {
    "sub_district": "ท่าเคย",
    "district": "ท่าฉาง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84150,
    "sub_district_code": 841102,
    "district_code": 8411,
    "province_code": 84
  },
  {
    "sub_district": "คลองไทร",
    "district": "ท่าฉาง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84150,
    "sub_district_code": 841103,
    "district_code": 8411,
    "province_code": 84
  },
  {
    "sub_district": "เขาถ่าน",
    "district": "ท่าฉาง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84150,
    "sub_district_code": 841104,
    "district_code": 8411,
    "province_code": 84
  },
  {
    "sub_district": "เสวียด",
    "district": "ท่าฉาง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84150,
    "sub_district_code": 841105,
    "district_code": 8411,
    "province_code": 84
  },
  {
    "sub_district": "ปากฉลุย",
    "district": "ท่าฉาง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84150,
    "sub_district_code": 841106,
    "district_code": 8411,
    "province_code": 84
  },
  {
    "sub_district": "ท่าชนะ",
    "district": "ท่าชนะ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84170,
    "sub_district_code": 840701,
    "district_code": 8407,
    "province_code": 84
  },
  {
    "sub_district": "สมอทอง",
    "district": "ท่าชนะ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84170,
    "sub_district_code": 840702,
    "district_code": 8407,
    "province_code": 84
  },
  {
    "sub_district": "ประสงค์",
    "district": "ท่าชนะ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84170,
    "sub_district_code": 840703,
    "district_code": 8407,
    "province_code": 84
  },
  {
    "sub_district": "คันธุลี",
    "district": "ท่าชนะ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84170,
    "sub_district_code": 840704,
    "district_code": 8407,
    "province_code": 84
  },
  {
    "sub_district": "วัง",
    "district": "ท่าชนะ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84170,
    "sub_district_code": 840705,
    "district_code": 8407,
    "province_code": 84
  },
  {
    "sub_district": "คลองพา",
    "district": "ท่าชนะ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84170,
    "sub_district_code": 840706,
    "district_code": 8407,
    "province_code": 84
  },
  {
    "sub_district": "นาสาร",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84120,
    "sub_district_code": 841201,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "พรุพี",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84270,
    "sub_district_code": 841202,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "ทุ่งเตา",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84120,
    "sub_district_code": 841203,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "ลำพูน",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84120,
    "sub_district_code": 841204,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "ท่าชี",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84120,
    "sub_district_code": 841205,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "ควนศรี",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84270,
    "sub_district_code": 841206,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "ควนสุบรรณ",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84120,
    "sub_district_code": 841207,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "คลองปราบ",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84120,
    "sub_district_code": 841208,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "น้ำพุ",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84120,
    "sub_district_code": 841209,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "ทุ่งเตาใหม่",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84120,
    "sub_district_code": 841210,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "เพิ่มพูนทรัพย์",
    "district": "บ้านนาสาร",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84120,
    "sub_district_code": 841211,
    "district_code": 8412,
    "province_code": 84
  },
  {
    "sub_district": "พนม",
    "district": "พนม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84250,
    "sub_district_code": 841001,
    "district_code": 8410,
    "province_code": 84
  },
  {
    "sub_district": "ต้นยวน",
    "district": "พนม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84250,
    "sub_district_code": 841002,
    "district_code": 8410,
    "province_code": 84
  },
  {
    "sub_district": "คลองศก",
    "district": "พนม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84250,
    "sub_district_code": 841003,
    "district_code": 8410,
    "province_code": 84
  },
  {
    "sub_district": "พลูเถื่อน",
    "district": "พนม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84250,
    "sub_district_code": 841004,
    "district_code": 8410,
    "province_code": 84
  },
  {
    "sub_district": "พังกาญจน์",
    "district": "พนม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84250,
    "sub_district_code": 841005,
    "district_code": 8410,
    "province_code": 84
  },
  {
    "sub_district": "คลองชะอุ่น",
    "district": "พนม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84250,
    "sub_district_code": 841006,
    "district_code": 8410,
    "province_code": 84
  },
  {
    "sub_district": "อิปัน",
    "district": "พระแสง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84210,
    "sub_district_code": 841601,
    "district_code": 8416,
    "province_code": 84
  },
  {
    "sub_district": "สินปุน",
    "district": "พระแสง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84210,
    "sub_district_code": 841602,
    "district_code": 8416,
    "province_code": 84
  },
  {
    "sub_district": "สองแพรก",
    "district": "พระแสง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84350,
    "sub_district_code": 841697,
    "district_code": 8416,
    "province_code": 84
  },
  {
    "sub_district": "บางสวรรค์",
    "district": "พระแสง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84210,
    "sub_district_code": 841603,
    "district_code": 8416,
    "province_code": 84
  },
  {
    "sub_district": "ไทรขึง",
    "district": "พระแสง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84210,
    "sub_district_code": 841604,
    "district_code": 8416,
    "province_code": 84
  },
  {
    "sub_district": "ชัยบุรี",
    "district": "พระแสง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84350,
    "sub_district_code": 841898,
    "district_code": 8418,
    "province_code": 84
  },
  {
    "sub_district": "สาคู",
    "district": "พระแสง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84210,
    "sub_district_code": 841607,
    "district_code": 8416,
    "province_code": 84
  },
  {
    "sub_district": "สินเจริญ",
    "district": "พระแสง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84210,
    "sub_district_code": 841605,
    "district_code": 8416,
    "province_code": 84
  },
  {
    "sub_district": "ไทรโสภา",
    "district": "พระแสง",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84210,
    "sub_district_code": 841606,
    "district_code": 8416,
    "province_code": 84
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841701,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "ท่าสะท้อน",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841702,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "ลีเล็ด",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841703,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "บางมะเดื่อ",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841704,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "บางเดือน",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841705,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "ท่าโรงช้าง",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841706,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "กรูด",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841707,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "พุนพิน",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841708,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "บางงอน",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841709,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "ศรีวิชัย",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841710,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "น้ำรอบ",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841711,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "มะลวน",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841712,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "หัวเตย",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841713,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "หนองไทร",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841714,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "เขาหัวควาย",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841715,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "ตะปาน",
    "district": "พุนพิน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84130,
    "sub_district_code": 841716,
    "district_code": 8417,
    "province_code": 84
  },
  {
    "sub_district": "เวียงสระ",
    "district": "เวียงสระ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84190,
    "sub_district_code": 841501,
    "district_code": 8415,
    "province_code": 84
  },
  {
    "sub_district": "บ้านส้อง",
    "district": "เวียงสระ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84190,
    "sub_district_code": 841502,
    "district_code": 8415,
    "province_code": 84
  },
  {
    "sub_district": "คลองฉนวน",
    "district": "เวียงสระ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84190,
    "sub_district_code": 841503,
    "district_code": 8415,
    "province_code": 84
  },
  {
    "sub_district": "ทุ่งหลวง",
    "district": "เวียงสระ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84190,
    "sub_district_code": 841504,
    "district_code": 8415,
    "province_code": 84
  },
  {
    "sub_district": "เขานิพันธ์",
    "district": "เวียงสระ",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84190,
    "sub_district_code": 841505,
    "district_code": 8415,
    "province_code": 84
  },
  {
    "sub_district": "เขาวง",
    "district": "บ้านตาขุน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84230,
    "sub_district_code": 840901,
    "district_code": 8409,
    "province_code": 84
  },
  {
    "sub_district": "พะแสง",
    "district": "บ้านตาขุน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84230,
    "sub_district_code": 840902,
    "district_code": 8409,
    "province_code": 84
  },
  {
    "sub_district": "พรุไทย",
    "district": "บ้านตาขุน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84230,
    "sub_district_code": 840903,
    "district_code": 8409,
    "province_code": 84
  },
  {
    "sub_district": "เขาพัง",
    "district": "บ้านตาขุน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84230,
    "sub_district_code": 840904,
    "district_code": 8409,
    "province_code": 84
  },
  {
    "sub_district": "ต้นยวน",
    "district": "บ้านตาขุน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84250,
    "sub_district_code": 840997,
    "district_code": 8409,
    "province_code": 84
  },
  {
    "sub_district": "ดอนสัก",
    "district": "บ้านตาขุน",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84220,
    "sub_district_code": 840998,
    "district_code": 8409,
    "province_code": 84
  },
  {
    "sub_district": "บ้านนา",
    "district": "บ้านนาเดิม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84240,
    "sub_district_code": 841301,
    "district_code": 8413,
    "province_code": 84
  },
  {
    "sub_district": "ท่าเรือ",
    "district": "บ้านนาเดิม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84240,
    "sub_district_code": 841302,
    "district_code": 8413,
    "province_code": 84
  },
  {
    "sub_district": "ทรัพย์ทวี",
    "district": "บ้านนาเดิม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84240,
    "sub_district_code": 841303,
    "district_code": 8413,
    "province_code": 84
  },
  {
    "sub_district": "นาใต้",
    "district": "บ้านนาเดิม",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84240,
    "sub_district_code": 841304,
    "district_code": 8413,
    "province_code": 84
  },
  {
    "sub_district": "ชัยบุรี",
    "district": "ชัยบุรี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84350,
    "sub_district_code": 841802,
    "district_code": 8418,
    "province_code": 84
  },
  {
    "sub_district": "สองแพรก",
    "district": "ชัยบุรี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84350,
    "sub_district_code": 841801,
    "district_code": 8418,
    "province_code": 84
  },
  {
    "sub_district": "คลองน้อย",
    "district": "ชัยบุรี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84350,
    "sub_district_code": 841803,
    "district_code": 8418,
    "province_code": 84
  },
  {
    "sub_district": "ไทรทอง",
    "district": "ชัยบุรี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84350,
    "sub_district_code": 841804,
    "district_code": 8418,
    "province_code": 84
  },
  {
    "sub_district": "ตะกุกเหนือ",
    "district": "วิภาวดี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 841902,
    "district_code": 8419,
    "province_code": 84
  },
  {
    "sub_district": "ตะกุกใต้",
    "district": "วิภาวดี",
    "province": "สุราษฎร์ธานี",
    "zipcode": 84180,
    "sub_district_code": 841901,
    "district_code": 8419,
    "province_code": 84
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320101,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "ตั้งใจ",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320102,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "เพี้ยราม",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320103,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "นาดี",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320104,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "ท่าสว่าง",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320105,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "สลักได",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320106,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "ตาอ็อง",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320107,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "ตากูก",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320198,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "สำโรง",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320109,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "แกใหญ่",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320110,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "นอกเมือง",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320111,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "คอโค",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320112,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "สวาย",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320113,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "เฉนียง",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320114,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "บึง",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320197,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "เทนมีย์",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320116,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "เขวาสินรินทร์",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320196,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "นาบัว",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320118,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "เมืองที",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320119,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "ราม",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320120,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "ตระแสง",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320122,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "บุฤาษี",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320121,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "บ้านโพธิ์",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320199,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "บ้านแร่",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320195,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "แสลงพันธ์",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320125,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "ปราสาททอง",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320193,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "กาเกาะ",
    "district": "เมืองสุรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320126,
    "district_code": 3201,
    "province_code": 32
  },
  {
    "sub_district": "จอมพระ",
    "district": "จอมพระ",
    "province": "สุรินทร์",
    "zipcode": 32180,
    "sub_district_code": 320401,
    "district_code": 3204,
    "province_code": 32
  },
  {
    "sub_district": "เมืองลีง",
    "district": "จอมพระ",
    "province": "สุรินทร์",
    "zipcode": 32180,
    "sub_district_code": 320402,
    "district_code": 3204,
    "province_code": 32
  },
  {
    "sub_district": "กระหาด",
    "district": "จอมพระ",
    "province": "สุรินทร์",
    "zipcode": 32180,
    "sub_district_code": 320403,
    "district_code": 3204,
    "province_code": 32
  },
  {
    "sub_district": "บุแกรง",
    "district": "จอมพระ",
    "province": "สุรินทร์",
    "zipcode": 32180,
    "sub_district_code": 320404,
    "district_code": 3204,
    "province_code": 32
  },
  {
    "sub_district": "หนองสนิท",
    "district": "จอมพระ",
    "province": "สุรินทร์",
    "zipcode": 32180,
    "sub_district_code": 320405,
    "district_code": 3204,
    "province_code": 32
  },
  {
    "sub_district": "บ้านผือ",
    "district": "จอมพระ",
    "province": "สุรินทร์",
    "zipcode": 32180,
    "sub_district_code": 320406,
    "district_code": 3204,
    "province_code": 32
  },
  {
    "sub_district": "ลุ่มระวี",
    "district": "จอมพระ",
    "province": "สุรินทร์",
    "zipcode": 32180,
    "sub_district_code": 320407,
    "district_code": 3204,
    "province_code": 32
  },
  {
    "sub_district": "ชุมแสง",
    "district": "จอมพระ",
    "province": "สุรินทร์",
    "zipcode": 32180,
    "sub_district_code": 320408,
    "district_code": 3204,
    "province_code": 32
  },
  {
    "sub_district": "เป็นสุข",
    "district": "จอมพระ",
    "province": "สุรินทร์",
    "zipcode": 32180,
    "sub_district_code": 320409,
    "district_code": 3204,
    "province_code": 32
  },
  {
    "sub_district": "ชุมพลบุรี",
    "district": "ชุมพลบุรี",
    "province": "สุรินทร์",
    "zipcode": 32190,
    "sub_district_code": 320201,
    "district_code": 3202,
    "province_code": 32
  },
  {
    "sub_district": "นาหนองไผ่",
    "district": "ชุมพลบุรี",
    "province": "สุรินทร์",
    "zipcode": 32190,
    "sub_district_code": 320202,
    "district_code": 3202,
    "province_code": 32
  },
  {
    "sub_district": "ไพรขลา",
    "district": "ชุมพลบุรี",
    "province": "สุรินทร์",
    "zipcode": 32190,
    "sub_district_code": 320203,
    "district_code": 3202,
    "province_code": 32
  },
  {
    "sub_district": "ศรีณรงค์",
    "district": "ชุมพลบุรี",
    "province": "สุรินทร์",
    "zipcode": 32190,
    "sub_district_code": 320204,
    "district_code": 3202,
    "province_code": 32
  },
  {
    "sub_district": "ยะวึก",
    "district": "ชุมพลบุรี",
    "province": "สุรินทร์",
    "zipcode": 32190,
    "sub_district_code": 320205,
    "district_code": 3202,
    "province_code": 32
  },
  {
    "sub_district": "เมืองบัว",
    "district": "ชุมพลบุรี",
    "province": "สุรินทร์",
    "zipcode": 32190,
    "sub_district_code": 320206,
    "district_code": 3202,
    "province_code": 32
  },
  {
    "sub_district": "สระขุด",
    "district": "ชุมพลบุรี",
    "province": "สุรินทร์",
    "zipcode": 32190,
    "sub_district_code": 320207,
    "district_code": 3202,
    "province_code": 32
  },
  {
    "sub_district": "กระเบื้อง",
    "district": "ชุมพลบุรี",
    "province": "สุรินทร์",
    "zipcode": 32190,
    "sub_district_code": 320208,
    "district_code": 3202,
    "province_code": 32
  },
  {
    "sub_district": "หนองเรือ",
    "district": "ชุมพลบุรี",
    "province": "สุรินทร์",
    "zipcode": 32190,
    "sub_district_code": 320209,
    "district_code": 3202,
    "province_code": 32
  },
  {
    "sub_district": "ท่าตูม",
    "district": "ท่าตูม",
    "province": "สุรินทร์",
    "zipcode": 32120,
    "sub_district_code": 320301,
    "district_code": 3203,
    "province_code": 32
  },
  {
    "sub_district": "กระโพ",
    "district": "ท่าตูม",
    "province": "สุรินทร์",
    "zipcode": 32120,
    "sub_district_code": 320302,
    "district_code": 3203,
    "province_code": 32
  },
  {
    "sub_district": "พรมเทพ",
    "district": "ท่าตูม",
    "province": "สุรินทร์",
    "zipcode": 32120,
    "sub_district_code": 320303,
    "district_code": 3203,
    "province_code": 32
  },
  {
    "sub_district": "โพนครก",
    "district": "ท่าตูม",
    "province": "สุรินทร์",
    "zipcode": 32120,
    "sub_district_code": 320304,
    "district_code": 3203,
    "province_code": 32
  },
  {
    "sub_district": "เมืองแก",
    "district": "ท่าตูม",
    "province": "สุรินทร์",
    "zipcode": 32120,
    "sub_district_code": 320305,
    "district_code": 3203,
    "province_code": 32
  },
  {
    "sub_district": "บะ",
    "district": "ท่าตูม",
    "province": "สุรินทร์",
    "zipcode": 32120,
    "sub_district_code": 320306,
    "district_code": 3203,
    "province_code": 32
  },
  {
    "sub_district": "หนองบัว",
    "district": "ท่าตูม",
    "province": "สุรินทร์",
    "zipcode": 32120,
    "sub_district_code": 320307,
    "district_code": 3203,
    "province_code": 32
  },
  {
    "sub_district": "บัวโคก",
    "district": "ท่าตูม",
    "province": "สุรินทร์",
    "zipcode": 32120,
    "sub_district_code": 320308,
    "district_code": 3203,
    "province_code": 32
  },
  {
    "sub_district": "หนองเมธี",
    "district": "ท่าตูม",
    "province": "สุรินทร์",
    "zipcode": 32120,
    "sub_district_code": 320309,
    "district_code": 3203,
    "province_code": 32
  },
  {
    "sub_district": "ทุ่งกุลา",
    "district": "ท่าตูม",
    "province": "สุรินทร์",
    "zipcode": 32120,
    "sub_district_code": 320310,
    "district_code": 3203,
    "province_code": 32
  },
  {
    "sub_district": "กังแอน",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320501,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "ทมอ",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320502,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "ไพล",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320503,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "ปรือ",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320504,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "ทุ่งมน",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320505,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "ตาเบา",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320506,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "หนองใหญ่",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320507,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "โคกยาง",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320508,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320509,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "บ้านไทร",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320510,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "โชคนาสาม",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320511,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "เชื้อเพลิง",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320512,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "ปราสาททนง",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320513,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "ตานี",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320514,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "บ้านพลวง",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320515,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "กันตวจระมวล",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320516,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "สมุด",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320517,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "ประทัดบุ",
    "district": "ปราสาท",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320518,
    "district_code": 3205,
    "province_code": 32
  },
  {
    "sub_district": "รัตนบุรี",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320701,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "ธาตุ",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320702,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "แก",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320703,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "ดอนแรด",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320704,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "หนองบัวทอง",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320705,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "หนองบัวบาน",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320706,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "หนองหลวง",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320798,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "หนองเทพ",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320797,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "ไผ่",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320709,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "โนน",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320796,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "เบิด",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320711,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "ระเวียง",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320795,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "น้ำเขียว",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320713,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "กุดขาคีม",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320714,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "ยางสว่าง",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320715,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "ทับใหญ่",
    "district": "รัตนบุรี",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 320716,
    "district_code": 3207,
    "province_code": 32
  },
  {
    "sub_district": "ระแงง",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320901,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "ตรึม",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320902,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "จารพัต",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320903,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "ยาง",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320904,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "แตล",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320905,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "หนองบัว",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320906,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "คาละแมะ",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320907,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "หนองเหล็ก",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320908,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "หนองขวาว",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320909,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "ช่างปี่",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320910,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "กุดหวาย",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320911,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "ขวาวใหญ่",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320912,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "เมืองที",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 320997,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "นารุ่ง",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320913,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "ตรมไพร",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320914,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "ผักไหม",
    "district": "ศีขรภูมิ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 320915,
    "district_code": 3209,
    "province_code": 32
  },
  {
    "sub_district": "สนม",
    "district": "สนม",
    "province": "สุรินทร์",
    "zipcode": 32160,
    "sub_district_code": 320801,
    "district_code": 3208,
    "province_code": 32
  },
  {
    "sub_district": "โพนโก",
    "district": "สนม",
    "province": "สุรินทร์",
    "zipcode": 32160,
    "sub_district_code": 320802,
    "district_code": 3208,
    "province_code": 32
  },
  {
    "sub_district": "หนองระฆัง",
    "district": "สนม",
    "province": "สุรินทร์",
    "zipcode": 32160,
    "sub_district_code": 320803,
    "district_code": 3208,
    "province_code": 32
  },
  {
    "sub_district": "นานวน",
    "district": "สนม",
    "province": "สุรินทร์",
    "zipcode": 32160,
    "sub_district_code": 320804,
    "district_code": 3208,
    "province_code": 32
  },
  {
    "sub_district": "แคน",
    "district": "สนม",
    "province": "สุรินทร์",
    "zipcode": 32160,
    "sub_district_code": 320805,
    "district_code": 3208,
    "province_code": 32
  },
  {
    "sub_district": "หัวงัว",
    "district": "สนม",
    "province": "สุรินทร์",
    "zipcode": 32160,
    "sub_district_code": 320806,
    "district_code": 3208,
    "province_code": 32
  },
  {
    "sub_district": "หนองอียอ",
    "district": "สนม",
    "province": "สุรินทร์",
    "zipcode": 32160,
    "sub_district_code": 320807,
    "district_code": 3208,
    "province_code": 32
  },
  {
    "sub_district": "สังขะ",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321001,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "ขอนแตก",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321002,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "ณรงค์",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321094,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "แจนแวน",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321095,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "ตรวจ",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321096,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "ดม",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321006,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "พระแก้ว",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321007,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "บ้านจารย์",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321008,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "กระเทียม",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321009,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "สะกาด",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321010,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "ตาตุม",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321011,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "ทับทัน",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321012,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "ตาคง",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321013,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "ศรีสุข",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321097,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "บ้านชบ",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321015,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "หนองแวง",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321098,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "เทพรักษา",
    "district": "สังขะ",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321017,
    "district_code": 3210,
    "province_code": 32
  },
  {
    "sub_district": "สำโรงทาบ",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32170,
    "sub_district_code": 321201,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "หนองไผ่ล้อม",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32170,
    "sub_district_code": 321202,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "กระออม",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32170,
    "sub_district_code": 321203,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "หนองฮะ",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32170,
    "sub_district_code": 321204,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "ศรีสุข",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32170,
    "sub_district_code": 321205,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "เกาะแก้ว",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32170,
    "sub_district_code": 321206,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "หมื่นศรี",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32170,
    "sub_district_code": 321207,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "เสม็จ",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32170,
    "sub_district_code": 321208,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "ประดู่",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32170,
    "sub_district_code": 321210,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "สะโน",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32170,
    "sub_district_code": 321209,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "แตล",
    "district": "สำโรงทาบ",
    "province": "สุรินทร์",
    "zipcode": 32110,
    "sub_district_code": 321298,
    "district_code": 3212,
    "province_code": 32
  },
  {
    "sub_district": "กาบเชิง",
    "district": "กาบเชิง",
    "province": "สุรินทร์",
    "zipcode": 32210,
    "sub_district_code": 320601,
    "district_code": 3206,
    "province_code": 32
  },
  {
    "sub_district": "บักได",
    "district": "กาบเชิง",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320698,
    "district_code": 3206,
    "province_code": 32
  },
  {
    "sub_district": "โคกกลาง",
    "district": "กาบเชิง",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 320697,
    "district_code": 3206,
    "province_code": 32
  },
  {
    "sub_district": "คูตัน",
    "district": "กาบเชิง",
    "province": "สุรินทร์",
    "zipcode": 32210,
    "sub_district_code": 320604,
    "district_code": 3206,
    "province_code": 32
  },
  {
    "sub_district": "ด่าน",
    "district": "กาบเชิง",
    "province": "สุรินทร์",
    "zipcode": 32210,
    "sub_district_code": 320605,
    "district_code": 3206,
    "province_code": 32
  },
  {
    "sub_district": "โคกตะเคียน",
    "district": "กาบเชิง",
    "province": "สุรินทร์",
    "zipcode": 32210,
    "sub_district_code": 320607,
    "district_code": 3206,
    "province_code": 32
  },
  {
    "sub_district": "ตะเคียน",
    "district": "กาบเชิง",
    "province": "สุรินทร์",
    "zipcode": 32210,
    "sub_district_code": 320610,
    "district_code": 3206,
    "province_code": 32
  },
  {
    "sub_district": "แนงมุด",
    "district": "กาบเชิง",
    "province": "สุรินทร์",
    "zipcode": 32210,
    "sub_district_code": 320606,
    "district_code": 3206,
    "province_code": 32
  },
  {
    "sub_district": "ลำดวน",
    "district": "ลำดวน",
    "province": "สุรินทร์",
    "zipcode": 32220,
    "sub_district_code": 321101,
    "district_code": 3211,
    "province_code": 32
  },
  {
    "sub_district": "โชคเหนือ",
    "district": "ลำดวน",
    "province": "สุรินทร์",
    "zipcode": 32220,
    "sub_district_code": 321102,
    "district_code": 3211,
    "province_code": 32
  },
  {
    "sub_district": "อู่โลก",
    "district": "ลำดวน",
    "province": "สุรินทร์",
    "zipcode": 32220,
    "sub_district_code": 321103,
    "district_code": 3211,
    "province_code": 32
  },
  {
    "sub_district": "ตระเปียงเตีย",
    "district": "ลำดวน",
    "province": "สุรินทร์",
    "zipcode": 32220,
    "sub_district_code": 321105,
    "district_code": 3211,
    "province_code": 32
  },
  {
    "sub_district": "ตรำดม",
    "district": "ลำดวน",
    "province": "สุรินทร์",
    "zipcode": 32220,
    "sub_district_code": 321104,
    "district_code": 3211,
    "province_code": 32
  },
  {
    "sub_district": "บัวเชด",
    "district": "บัวเชด",
    "province": "สุรินทร์",
    "zipcode": 32230,
    "sub_district_code": 321301,
    "district_code": 3213,
    "province_code": 32
  },
  {
    "sub_district": "สะเดา",
    "district": "บัวเชด",
    "province": "สุรินทร์",
    "zipcode": 32230,
    "sub_district_code": 321302,
    "district_code": 3213,
    "province_code": 32
  },
  {
    "sub_district": "จรัส",
    "district": "บัวเชด",
    "province": "สุรินทร์",
    "zipcode": 32230,
    "sub_district_code": 321303,
    "district_code": 3213,
    "province_code": 32
  },
  {
    "sub_district": "ตาวัง",
    "district": "บัวเชด",
    "province": "สุรินทร์",
    "zipcode": 32230,
    "sub_district_code": 321304,
    "district_code": 3213,
    "province_code": 32
  },
  {
    "sub_district": "อาโพน",
    "district": "บัวเชด",
    "province": "สุรินทร์",
    "zipcode": 32230,
    "sub_district_code": 321305,
    "district_code": 3213,
    "province_code": 32
  },
  {
    "sub_district": "สะกาด",
    "district": "บัวเชด",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321398,
    "district_code": 3213,
    "province_code": 32
  },
  {
    "sub_district": "สำเภาลูน",
    "district": "บัวเชด",
    "province": "สุรินทร์",
    "zipcode": 32230,
    "sub_district_code": 321306,
    "district_code": 3213,
    "province_code": 32
  },
  {
    "sub_district": "บักได",
    "district": "พนมดงรัก",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 321401,
    "district_code": 3214,
    "province_code": 32
  },
  {
    "sub_district": "โคกกลาง",
    "district": "พนมดงรัก",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 321402,
    "district_code": 3214,
    "province_code": 32
  },
  {
    "sub_district": "จีกแดก",
    "district": "พนมดงรัก",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 321403,
    "district_code": 3214,
    "province_code": 32
  },
  {
    "sub_district": "ตาเมียง",
    "district": "พนมดงรัก",
    "province": "สุรินทร์",
    "zipcode": 32140,
    "sub_district_code": 321404,
    "district_code": 3214,
    "province_code": 32
  },
  {
    "sub_district": "ศรีสุข",
    "district": "ศรีณรงค์",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321505,
    "district_code": 3215,
    "province_code": 32
  },
  {
    "sub_district": "ณรงค์",
    "district": "ศรีณรงค์",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321501,
    "district_code": 3215,
    "province_code": 32
  },
  {
    "sub_district": "แจนแวน",
    "district": "ศรีณรงค์",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321502,
    "district_code": 3215,
    "province_code": 32
  },
  {
    "sub_district": "ตรวจ",
    "district": "ศรีณรงค์",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321503,
    "district_code": 3215,
    "province_code": 32
  },
  {
    "sub_district": "หนองแวง",
    "district": "ศรีณรงค์",
    "province": "สุรินทร์",
    "zipcode": 32150,
    "sub_district_code": 321504,
    "district_code": 3215,
    "province_code": 32
  },
  {
    "sub_district": "เขวาสินรินทร์",
    "district": "เขวาสินรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 321601,
    "district_code": 3216,
    "province_code": 32
  },
  {
    "sub_district": "บึง",
    "district": "เขวาสินรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 321602,
    "district_code": 3216,
    "province_code": 32
  },
  {
    "sub_district": "ตากูก",
    "district": "เขวาสินรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 321603,
    "district_code": 3216,
    "province_code": 32
  },
  {
    "sub_district": "ปราสาททอง",
    "district": "เขวาสินรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 321604,
    "district_code": 3216,
    "province_code": 32
  },
  {
    "sub_district": "บ้านแร่",
    "district": "เขวาสินรินทร์",
    "province": "สุรินทร์",
    "zipcode": 32000,
    "sub_district_code": 321605,
    "district_code": 3216,
    "province_code": 32
  },
  {
    "sub_district": "หนองหลวง",
    "district": "โนนนารายณ์",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 321701,
    "district_code": 3217,
    "province_code": 32
  },
  {
    "sub_district": "คำผง",
    "district": "โนนนารายณ์",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 321702,
    "district_code": 3217,
    "province_code": 32
  },
  {
    "sub_district": "โนน",
    "district": "โนนนารายณ์",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 321703,
    "district_code": 3217,
    "province_code": 32
  },
  {
    "sub_district": "ระเวียง",
    "district": "โนนนารายณ์",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 321704,
    "district_code": 3217,
    "province_code": 32
  },
  {
    "sub_district": "หนองเทพ",
    "district": "โนนนารายณ์",
    "province": "สุรินทร์",
    "zipcode": 32130,
    "sub_district_code": 321705,
    "district_code": 3217,
    "province_code": 32
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430101,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "มีชัย",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430102,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "โพธิ์ชัย",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430103,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "กวนวัน",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430104,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "เวียงคุก",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430105,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "วัดธาตุ",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430106,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "หาดคำ",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430107,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "หินโงม",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430108,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "บ้านเดื่อ",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430109,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "ค่ายบกหวาน",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43100,
    "sub_district_code": 430110,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "สระใคร",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43100,
    "sub_district_code": 430192,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "พระธาตุบังพวน",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43100,
    "sub_district_code": 430113,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "บ้านฝาง",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43100,
    "sub_district_code": 430193,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "หนองกอมเกาะ",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430116,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "คอกช้าง",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43100,
    "sub_district_code": 430194,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "ปะโค",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430117,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "เมืองหมี",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430118,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "สีกาย",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43000,
    "sub_district_code": 430119,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "หมากแข้ง",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 41000,
    "sub_district_code": 430197,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "บ้านม่วง",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43160,
    "sub_district_code": 430198,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "โพนสว่าง",
    "district": "เมืองหนองคาย",
    "province": "หนองคาย",
    "zipcode": 43100,
    "sub_district_code": 430111,
    "district_code": 4301,
    "province_code": 43
  },
  {
    "sub_district": "เซกา",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38150,
    "sub_district_code": 430901,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "ซาง",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38150,
    "sub_district_code": 430902,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "ท่ากกแดง",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38150,
    "sub_district_code": 430903,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "โพธิ์หมากแข้ง",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38220,
    "sub_district_code": 430997,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "ดงบัง",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38220,
    "sub_district_code": 430998,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "บ้านต้อง",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38150,
    "sub_district_code": 430904,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "ป่งไฮ",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38150,
    "sub_district_code": 430905,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "ท่าสะอาด",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38150,
    "sub_district_code": 430907,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "น้ำจั้น",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38150,
    "sub_district_code": 430906,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "หนองทุ่ม",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38150,
    "sub_district_code": 430908,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "โสกก่าม",
    "district": "เซกา",
    "province": "หนองคาย",
    "zipcode": 38150,
    "sub_district_code": 430909,
    "district_code": 4309,
    "province_code": 43
  },
  {
    "sub_district": "โซ่",
    "district": "โซ่พิสัย",
    "province": "หนองคาย",
    "zipcode": 38170,
    "sub_district_code": 430601,
    "district_code": 4306,
    "province_code": 43
  },
  {
    "sub_district": "หนองพันทา",
    "district": "โซ่พิสัย",
    "province": "หนองคาย",
    "zipcode": 38170,
    "sub_district_code": 430602,
    "district_code": 4306,
    "province_code": 43
  },
  {
    "sub_district": "ศรีชมภู",
    "district": "โซ่พิสัย",
    "province": "หนองคาย",
    "zipcode": 38170,
    "sub_district_code": 430603,
    "district_code": 4306,
    "province_code": 43
  },
  {
    "sub_district": "คำแก้ว",
    "district": "โซ่พิสัย",
    "province": "หนองคาย",
    "zipcode": 38170,
    "sub_district_code": 430604,
    "district_code": 4306,
    "province_code": 43
  },
  {
    "sub_district": "บัวตูม",
    "district": "โซ่พิสัย",
    "province": "หนองคาย",
    "zipcode": 38170,
    "sub_district_code": 430605,
    "district_code": 4306,
    "province_code": 43
  },
  {
    "sub_district": "เหล่าทอง",
    "district": "โซ่พิสัย",
    "province": "หนองคาย",
    "zipcode": 38170,
    "sub_district_code": 430607,
    "district_code": 4306,
    "province_code": 43
  },
  {
    "sub_district": "ถ้ำเจริญ",
    "district": "โซ่พิสัย",
    "province": "หนองคาย",
    "zipcode": 38170,
    "sub_district_code": 430606,
    "district_code": 4306,
    "province_code": 43
  },
  {
    "sub_district": "ท่าบ่อ",
    "district": "ท่าบ่อ",
    "province": "หนองคาย",
    "zipcode": 43110,
    "sub_district_code": 430201,
    "district_code": 4302,
    "province_code": 43
  },
  {
    "sub_district": "น้ำโมง",
    "district": "ท่าบ่อ",
    "province": "หนองคาย",
    "zipcode": 43110,
    "sub_district_code": 430202,
    "district_code": 4302,
    "province_code": 43
  },
  {
    "sub_district": "กองนาง",
    "district": "ท่าบ่อ",
    "province": "หนองคาย",
    "zipcode": 43110,
    "sub_district_code": 430203,
    "district_code": 4302,
    "province_code": 43
  },
  {
    "sub_district": "โคกคอน",
    "district": "ท่าบ่อ",
    "province": "หนองคาย",
    "zipcode": 43110,
    "sub_district_code": 430204,
    "district_code": 4302,
    "province_code": 43
  },
  {
    "sub_district": "บ้านเดื่อ",
    "district": "ท่าบ่อ",
    "province": "หนองคาย",
    "zipcode": 43110,
    "sub_district_code": 430205,
    "district_code": 4302,
    "province_code": 43
  },
  {
    "sub_district": "บ้านถ่อน",
    "district": "ท่าบ่อ",
    "province": "หนองคาย",
    "zipcode": 43110,
    "sub_district_code": 430206,
    "district_code": 4302,
    "province_code": 43
  },
  {
    "sub_district": "บ้านว่าน",
    "district": "ท่าบ่อ",
    "province": "หนองคาย",
    "zipcode": 43110,
    "sub_district_code": 430207,
    "district_code": 4302,
    "province_code": 43
  },
  {
    "sub_district": "หนองนาง",
    "district": "ท่าบ่อ",
    "province": "หนองคาย",
    "zipcode": 43110,
    "sub_district_code": 430210,
    "district_code": 4302,
    "province_code": 43
  },
  {
    "sub_district": "โพนสา",
    "district": "ท่าบ่อ",
    "province": "หนองคาย",
    "zipcode": 43110,
    "sub_district_code": 430209,
    "district_code": 4302,
    "province_code": 43
  },
  {
    "sub_district": "นาข่า",
    "district": "ท่าบ่อ",
    "province": "หนองคาย",
    "zipcode": 43110,
    "sub_district_code": 430208,
    "district_code": 4302,
    "province_code": 43
  },
  {
    "sub_district": "บึงกาฬ",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430301,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "ชุมภูพร",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38210,
    "sub_district_code": 430392,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "โนนสมบูรณ์",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430303,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "หอคำ",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430305,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "หนองเลิง",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430306,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "โคกก่อง",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430307,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "หนองเดิ่น",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430393,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "นาสะแบง",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38210,
    "sub_district_code": 430394,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "นาสวรรค์",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430310,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "ศรีวิไล",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38210,
    "sub_district_code": 430395,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "ไคสี",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430311,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "โคกกว้าง",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430309,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "ชัยพร",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430314,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "นาแสง",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38210,
    "sub_district_code": 430396,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "วิศิษฐ์",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430316,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "บุ่งคล้า",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430397,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "คำนาดี",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430318,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "โป่งเปือย",
    "district": "บึงกาฬ",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 430319,
    "district_code": 4303,
    "province_code": 43
  },
  {
    "sub_district": "จุมพล",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430501,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "วัดหลวง",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430502,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "กุดบง",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430503,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "ชุมช้าง",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430504,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "รัตนวาปี",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430589,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "ทุ่งหลวง",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430506,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "เหล่าต่างคำ",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430507,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "นาหนัง",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430508,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "เซิม",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430509,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "หนองหลวง",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430590,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "โพนแพง",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430591,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "เฝ้าไร่",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430592,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "บ้านโพธิ์",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430513,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "นาทับไฮ",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430593,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "วังหลวง",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430594,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "พระบาทนาสิงห์",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430595,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "อุดมพร",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430596,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "นาดี",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430597,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "บ้านต้อน",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430598,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "บ้านผือ",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430521,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "สร้างนางขาว",
    "district": "โพนพิสัย",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 430522,
    "district_code": 4305,
    "province_code": 43
  },
  {
    "sub_district": "พานพร้าว",
    "district": "ศรีเชียงใหม่",
    "province": "หนองคาย",
    "zipcode": 43130,
    "sub_district_code": 430701,
    "district_code": 4307,
    "province_code": 43
  },
  {
    "sub_district": "โพธิ์ตาก",
    "district": "ศรีเชียงใหม่",
    "province": "หนองคาย",
    "zipcode": 43130,
    "sub_district_code": 430795,
    "district_code": 4307,
    "province_code": 43
  },
  {
    "sub_district": "บ้านหม้อ",
    "district": "ศรีเชียงใหม่",
    "province": "หนองคาย",
    "zipcode": 43130,
    "sub_district_code": 430703,
    "district_code": 4307,
    "province_code": 43
  },
  {
    "sub_district": "พระพุทธบาท",
    "district": "ศรีเชียงใหม่",
    "province": "หนองคาย",
    "zipcode": 43130,
    "sub_district_code": 430704,
    "district_code": 4307,
    "province_code": 43
  },
  {
    "sub_district": "หนองปลาปาก",
    "district": "ศรีเชียงใหม่",
    "province": "หนองคาย",
    "zipcode": 43130,
    "sub_district_code": 430705,
    "district_code": 4307,
    "province_code": 43
  },
  {
    "sub_district": "โพนทอง",
    "district": "ศรีเชียงใหม่",
    "province": "หนองคาย",
    "zipcode": 43130,
    "sub_district_code": 430796,
    "district_code": 4307,
    "province_code": 43
  },
  {
    "sub_district": "ด่านศรีสุข",
    "district": "ศรีเชียงใหม่",
    "province": "หนองคาย",
    "zipcode": 43130,
    "sub_district_code": 430798,
    "district_code": 4307,
    "province_code": 43
  },
  {
    "sub_district": "แก้งไก่",
    "district": "สังคม",
    "province": "หนองคาย",
    "zipcode": 43160,
    "sub_district_code": 430801,
    "district_code": 4308,
    "province_code": 43
  },
  {
    "sub_district": "ผาตั้ง",
    "district": "สังคม",
    "province": "หนองคาย",
    "zipcode": 43160,
    "sub_district_code": 430802,
    "district_code": 4308,
    "province_code": 43
  },
  {
    "sub_district": "บ้านม่วง",
    "district": "สังคม",
    "province": "หนองคาย",
    "zipcode": 43160,
    "sub_district_code": 430803,
    "district_code": 4308,
    "province_code": 43
  },
  {
    "sub_district": "นางิ้ว",
    "district": "สังคม",
    "province": "หนองคาย",
    "zipcode": 43160,
    "sub_district_code": 430804,
    "district_code": 4308,
    "province_code": 43
  },
  {
    "sub_district": "สังคม",
    "district": "สังคม",
    "province": "หนองคาย",
    "zipcode": 43160,
    "sub_district_code": 430805,
    "district_code": 4308,
    "province_code": 43
  },
  {
    "sub_district": "ศรีชมภู",
    "district": "พรเจริญ",
    "province": "หนองคาย",
    "zipcode": 38180,
    "sub_district_code": 430401,
    "district_code": 4304,
    "province_code": 43
  },
  {
    "sub_district": "ดอนหญ้านาง",
    "district": "พรเจริญ",
    "province": "หนองคาย",
    "zipcode": 38180,
    "sub_district_code": 430402,
    "district_code": 4304,
    "province_code": 43
  },
  {
    "sub_district": "พรเจริญ",
    "district": "พรเจริญ",
    "province": "หนองคาย",
    "zipcode": 38180,
    "sub_district_code": 430403,
    "district_code": 4304,
    "province_code": 43
  },
  {
    "sub_district": "หนองหัวช้าง",
    "district": "พรเจริญ",
    "province": "หนองคาย",
    "zipcode": 38180,
    "sub_district_code": 430404,
    "district_code": 4304,
    "province_code": 43
  },
  {
    "sub_district": "วังชมภู",
    "district": "พรเจริญ",
    "province": "หนองคาย",
    "zipcode": 38180,
    "sub_district_code": 430405,
    "district_code": 4304,
    "province_code": 43
  },
  {
    "sub_district": "ป่าแฝก",
    "district": "พรเจริญ",
    "province": "หนองคาย",
    "zipcode": 38180,
    "sub_district_code": 430406,
    "district_code": 4304,
    "province_code": 43
  },
  {
    "sub_district": "ศรีสำราญ",
    "district": "พรเจริญ",
    "province": "หนองคาย",
    "zipcode": 38180,
    "sub_district_code": 430407,
    "district_code": 4304,
    "province_code": 43
  },
  {
    "sub_district": "ปากคาด",
    "district": "ปากคาด",
    "province": "หนองคาย",
    "zipcode": 38190,
    "sub_district_code": 431001,
    "district_code": 4310,
    "province_code": 43
  },
  {
    "sub_district": "หนองยอง",
    "district": "ปากคาด",
    "province": "หนองคาย",
    "zipcode": 38190,
    "sub_district_code": 431002,
    "district_code": 4310,
    "province_code": 43
  },
  {
    "sub_district": "นากั้ง",
    "district": "ปากคาด",
    "province": "หนองคาย",
    "zipcode": 38190,
    "sub_district_code": 431003,
    "district_code": 4310,
    "province_code": 43
  },
  {
    "sub_district": "โนนศิลา",
    "district": "ปากคาด",
    "province": "หนองคาย",
    "zipcode": 38190,
    "sub_district_code": 431004,
    "district_code": 4310,
    "province_code": 43
  },
  {
    "sub_district": "สมสนุก",
    "district": "ปากคาด",
    "province": "หนองคาย",
    "zipcode": 38190,
    "sub_district_code": 431005,
    "district_code": 4310,
    "province_code": 43
  },
  {
    "sub_district": "นาดง",
    "district": "ปากคาด",
    "province": "หนองคาย",
    "zipcode": 38190,
    "sub_district_code": 431006,
    "district_code": 4310,
    "province_code": 43
  },
  {
    "sub_district": "บึงโขงหลง",
    "district": "บึงโขงหลง",
    "province": "หนองคาย",
    "zipcode": 38220,
    "sub_district_code": 431101,
    "district_code": 4311,
    "province_code": 43
  },
  {
    "sub_district": "โพธิ์หมากแข้ง",
    "district": "บึงโขงหลง",
    "province": "หนองคาย",
    "zipcode": 38220,
    "sub_district_code": 431102,
    "district_code": 4311,
    "province_code": 43
  },
  {
    "sub_district": "ดงบัง",
    "district": "บึงโขงหลง",
    "province": "หนองคาย",
    "zipcode": 38220,
    "sub_district_code": 431103,
    "district_code": 4311,
    "province_code": 43
  },
  {
    "sub_district": "ท่าดอกคำ",
    "district": "บึงโขงหลง",
    "province": "หนองคาย",
    "zipcode": 38220,
    "sub_district_code": 431104,
    "district_code": 4311,
    "province_code": 43
  },
  {
    "sub_district": "ศรีวิไล",
    "district": "ศรีวิไล",
    "province": "หนองคาย",
    "zipcode": 38210,
    "sub_district_code": 431201,
    "district_code": 4312,
    "province_code": 43
  },
  {
    "sub_district": "ชุมภูพร",
    "district": "ศรีวิไล",
    "province": "หนองคาย",
    "zipcode": 38210,
    "sub_district_code": 431202,
    "district_code": 4312,
    "province_code": 43
  },
  {
    "sub_district": "นาสะแบง",
    "district": "ศรีวิไล",
    "province": "หนองคาย",
    "zipcode": 38210,
    "sub_district_code": 431204,
    "district_code": 4312,
    "province_code": 43
  },
  {
    "sub_district": "นาแสง",
    "district": "ศรีวิไล",
    "province": "หนองคาย",
    "zipcode": 38210,
    "sub_district_code": 431203,
    "district_code": 4312,
    "province_code": 43
  },
  {
    "sub_district": "นาสิงห์",
    "district": "ศรีวิไล",
    "province": "หนองคาย",
    "zipcode": 38210,
    "sub_district_code": 431205,
    "district_code": 4312,
    "province_code": 43
  },
  {
    "sub_district": "โคกกว้าง",
    "district": "บุ่งคล้า",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 431303,
    "district_code": 4313,
    "province_code": 43
  },
  {
    "sub_district": "บุ่งคล้า",
    "district": "บุ่งคล้า",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 431301,
    "district_code": 4313,
    "province_code": 43
  },
  {
    "sub_district": "หนองเดิ่น",
    "district": "บุ่งคล้า",
    "province": "หนองคาย",
    "zipcode": 38000,
    "sub_district_code": 431302,
    "district_code": 4313,
    "province_code": 43
  },
  {
    "sub_district": "รัตนวาปี",
    "district": "รัตนวาปี",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 431601,
    "district_code": 4316,
    "province_code": 43
  },
  {
    "sub_district": "นาทับไฮ",
    "district": "รัตนวาปี",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 431602,
    "district_code": 4316,
    "province_code": 43
  },
  {
    "sub_district": "บ้านต้อน",
    "district": "รัตนวาปี",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 431603,
    "district_code": 4316,
    "province_code": 43
  },
  {
    "sub_district": "พระบาทนาสิงห์",
    "district": "รัตนวาปี",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 431604,
    "district_code": 4316,
    "province_code": 43
  },
  {
    "sub_district": "โพนแพง",
    "district": "รัตนวาปี",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 431605,
    "district_code": 4316,
    "province_code": 43
  },
  {
    "sub_district": "เฝ้าไร่",
    "district": "เฝ้าไร่",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 431501,
    "district_code": 4315,
    "province_code": 43
  },
  {
    "sub_district": "นาดี",
    "district": "เฝ้าไร่",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 431502,
    "district_code": 4315,
    "province_code": 43
  },
  {
    "sub_district": "หนองหลวง",
    "district": "เฝ้าไร่",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 431503,
    "district_code": 4315,
    "province_code": 43
  },
  {
    "sub_district": "วังหลวง",
    "district": "เฝ้าไร่",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 431504,
    "district_code": 4315,
    "province_code": 43
  },
  {
    "sub_district": "อุดมพร",
    "district": "เฝ้าไร่",
    "province": "หนองคาย",
    "zipcode": 43120,
    "sub_district_code": 431505,
    "district_code": 4315,
    "province_code": 43
  },
  {
    "sub_district": "บ้านฝาง",
    "district": "สระใคร",
    "province": "หนองคาย",
    "zipcode": 43100,
    "sub_district_code": 431403,
    "district_code": 4314,
    "province_code": 43
  },
  {
    "sub_district": "สระใคร",
    "district": "สระใคร",
    "province": "หนองคาย",
    "zipcode": 43100,
    "sub_district_code": 431401,
    "district_code": 4314,
    "province_code": 43
  },
  {
    "sub_district": "คอกช้าง",
    "district": "สระใคร",
    "province": "หนองคาย",
    "zipcode": 43100,
    "sub_district_code": 431402,
    "district_code": 4314,
    "province_code": 43
  },
  {
    "sub_district": "โพธิ์ตาก",
    "district": "โพธิ์ตาก",
    "province": "หนองคาย",
    "zipcode": 43130,
    "sub_district_code": 431701,
    "district_code": 4317,
    "province_code": 43
  },
  {
    "sub_district": "โพนทอง",
    "district": "โพธิ์ตาก",
    "province": "หนองคาย",
    "zipcode": 43130,
    "sub_district_code": 431702,
    "district_code": 4317,
    "province_code": 43
  },
  {
    "sub_district": "ด่านศรีสุข",
    "district": "โพธิ์ตาก",
    "province": "หนองคาย",
    "zipcode": 43130,
    "sub_district_code": 431703,
    "district_code": 4317,
    "province_code": 43
  },
  {
    "sub_district": "ตลาดหลวง",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150101,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "บางแก้ว",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150102,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "ศาลาแดง",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150103,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "ป่างิ้ว",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150104,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "บ้านแห",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150105,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "ตลาดกรวด",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150106,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "มหาดไทย",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150107,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "บ้านอิฐ",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150108,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "หัวไผ่",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150109,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "จำปาหล่อ",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150110,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "โพสะ",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150111,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "บ้านรี",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150112,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "คลองวัว",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150113,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "ย่านซื่อ",
    "district": "เมืองอ่างทอง",
    "province": "อ่างทอง",
    "zipcode": 14000,
    "sub_district_code": 150114,
    "district_code": 1501,
    "province_code": 15
  },
  {
    "sub_district": "จรเข้ร้อง",
    "district": "ไชโย",
    "province": "อ่างทอง",
    "zipcode": 14140,
    "sub_district_code": 150201,
    "district_code": 1502,
    "province_code": 15
  },
  {
    "sub_district": "ไชยภูมิ",
    "district": "ไชโย",
    "province": "อ่างทอง",
    "zipcode": 14140,
    "sub_district_code": 150202,
    "district_code": 1502,
    "province_code": 15
  },
  {
    "sub_district": "ชัยฤทธิ์",
    "district": "ไชโย",
    "province": "อ่างทอง",
    "zipcode": 14140,
    "sub_district_code": 150203,
    "district_code": 1502,
    "province_code": 15
  },
  {
    "sub_district": "เทวราช",
    "district": "ไชโย",
    "province": "อ่างทอง",
    "zipcode": 14140,
    "sub_district_code": 150204,
    "district_code": 1502,
    "province_code": 15
  },
  {
    "sub_district": "ราชสถิตย์",
    "district": "ไชโย",
    "province": "อ่างทอง",
    "zipcode": 14140,
    "sub_district_code": 150205,
    "district_code": 1502,
    "province_code": 15
  },
  {
    "sub_district": "ไชโย",
    "district": "ไชโย",
    "province": "อ่างทอง",
    "zipcode": 14140,
    "sub_district_code": 150206,
    "district_code": 1502,
    "province_code": 15
  },
  {
    "sub_district": "หลักฟ้า",
    "district": "ไชโย",
    "province": "อ่างทอง",
    "zipcode": 14140,
    "sub_district_code": 150207,
    "district_code": 1502,
    "province_code": 15
  },
  {
    "sub_district": "ชะไว",
    "district": "ไชโย",
    "province": "อ่างทอง",
    "zipcode": 14140,
    "sub_district_code": 150208,
    "district_code": 1502,
    "province_code": 15
  },
  {
    "sub_district": "ตรีณรงค์",
    "district": "ไชโย",
    "province": "อ่างทอง",
    "zipcode": 14140,
    "sub_district_code": 150209,
    "district_code": 1502,
    "province_code": 15
  },
  {
    "sub_district": "บางปลากด",
    "district": "ป่าโมก",
    "province": "อ่างทอง",
    "zipcode": 14130,
    "sub_district_code": 150301,
    "district_code": 1503,
    "province_code": 15
  },
  {
    "sub_district": "ป่าโมก",
    "district": "ป่าโมก",
    "province": "อ่างทอง",
    "zipcode": 14130,
    "sub_district_code": 150302,
    "district_code": 1503,
    "province_code": 15
  },
  {
    "sub_district": "สายทอง",
    "district": "ป่าโมก",
    "province": "อ่างทอง",
    "zipcode": 14130,
    "sub_district_code": 150303,
    "district_code": 1503,
    "province_code": 15
  },
  {
    "sub_district": "โรงช้าง",
    "district": "ป่าโมก",
    "province": "อ่างทอง",
    "zipcode": 14130,
    "sub_district_code": 150304,
    "district_code": 1503,
    "province_code": 15
  },
  {
    "sub_district": "บางเสด็จ",
    "district": "ป่าโมก",
    "province": "อ่างทอง",
    "zipcode": 14130,
    "sub_district_code": 150305,
    "district_code": 1503,
    "province_code": 15
  },
  {
    "sub_district": "นรสิงห์",
    "district": "ป่าโมก",
    "province": "อ่างทอง",
    "zipcode": 14130,
    "sub_district_code": 150306,
    "district_code": 1503,
    "province_code": 15
  },
  {
    "sub_district": "เอกราช",
    "district": "ป่าโมก",
    "province": "อ่างทอง",
    "zipcode": 14130,
    "sub_district_code": 150307,
    "district_code": 1503,
    "province_code": 15
  },
  {
    "sub_district": "โผงเผง",
    "district": "ป่าโมก",
    "province": "อ่างทอง",
    "zipcode": 14130,
    "sub_district_code": 150308,
    "district_code": 1503,
    "province_code": 15
  },
  {
    "sub_district": "อ่างแก้ว",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150401,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "อินทประมูล",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150402,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "บางพลับ",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150403,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "หนองแม่ไก่",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150404,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "รำมะสัก",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150405,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "ยางช้าย",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150410,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "บางระกำ",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150406,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "โพธิ์รังนก",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150407,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "องครักษ์",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150408,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "โคกพุทรา",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150409,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "บ่อแร่",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150411,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "ทางพระ",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150412,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "สามง่าม",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150413,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "บางเจ้าฉ่า",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150414,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "คำหยาด",
    "district": "โพธิ์ทอง",
    "province": "อ่างทอง",
    "zipcode": 14120,
    "sub_district_code": 150415,
    "district_code": 1504,
    "province_code": 15
  },
  {
    "sub_district": "ไผ่จำศีล",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150601,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "ศาลเจ้าโรงทอง",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150602,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "ไผ่ดำพัฒนา",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150603,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "สาวร้องไห้",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150604,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150605,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "ยี่ล้น",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150606,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "บางจัก",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150607,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "ห้วยคันแหลน",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150608,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "คลองขนาก",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150609,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "ไผ่วง",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150610,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "สี่ร้อย",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150611,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "ม่วงเตี้ย",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150612,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "หัวตะพาน",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150613,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "หลักแก้ว",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150614,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "ตลาดใหม่",
    "district": "วิเศษชัยชาญ",
    "province": "อ่างทอง",
    "zipcode": 14110,
    "sub_district_code": 150615,
    "district_code": 1506,
    "province_code": 15
  },
  {
    "sub_district": "สามโก้",
    "district": "สามโก้",
    "province": "อ่างทอง",
    "zipcode": 14160,
    "sub_district_code": 150701,
    "district_code": 1507,
    "province_code": 15
  },
  {
    "sub_district": "ราษฎรพัฒนา",
    "district": "สามโก้",
    "province": "อ่างทอง",
    "zipcode": 14160,
    "sub_district_code": 150702,
    "district_code": 1507,
    "province_code": 15
  },
  {
    "sub_district": "อบทม",
    "district": "สามโก้",
    "province": "อ่างทอง",
    "zipcode": 14160,
    "sub_district_code": 150703,
    "district_code": 1507,
    "province_code": 15
  },
  {
    "sub_district": "โพธิ์ม่วงพันธ์",
    "district": "สามโก้",
    "province": "อ่างทอง",
    "zipcode": 14160,
    "sub_district_code": 150704,
    "district_code": 1507,
    "province_code": 15
  },
  {
    "sub_district": "มงคลธรรมนิมิต",
    "district": "สามโก้",
    "province": "อ่างทอง",
    "zipcode": 14160,
    "sub_district_code": 150705,
    "district_code": 1507,
    "province_code": 15
  },
  {
    "sub_district": "แสวงหา",
    "district": "แสวงหา",
    "province": "อ่างทอง",
    "zipcode": 14150,
    "sub_district_code": 150501,
    "district_code": 1505,
    "province_code": 15
  },
  {
    "sub_district": "ศรีพราน",
    "district": "แสวงหา",
    "province": "อ่างทอง",
    "zipcode": 14150,
    "sub_district_code": 150502,
    "district_code": 1505,
    "province_code": 15
  },
  {
    "sub_district": "บ้านพราน",
    "district": "แสวงหา",
    "province": "อ่างทอง",
    "zipcode": 14150,
    "sub_district_code": 150503,
    "district_code": 1505,
    "province_code": 15
  },
  {
    "sub_district": "วังน้ำเย็น",
    "district": "แสวงหา",
    "province": "อ่างทอง",
    "zipcode": 14150,
    "sub_district_code": 150504,
    "district_code": 1505,
    "province_code": 15
  },
  {
    "sub_district": "สีบัวทอง",
    "district": "แสวงหา",
    "province": "อ่างทอง",
    "zipcode": 14150,
    "sub_district_code": 150505,
    "district_code": 1505,
    "province_code": 15
  },
  {
    "sub_district": "ห้วยไผ่",
    "district": "แสวงหา",
    "province": "อ่างทอง",
    "zipcode": 14150,
    "sub_district_code": 150506,
    "district_code": 1505,
    "province_code": 15
  },
  {
    "sub_district": "จำลอง",
    "district": "แสวงหา",
    "province": "อ่างทอง",
    "zipcode": 14150,
    "sub_district_code": 150507,
    "district_code": 1505,
    "province_code": 15
  },
  {
    "sub_district": "หมากแข้ง",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410101,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "นิคมสงเคราะห์",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410102,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "บ้านขาว",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410103,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "หนองบัว",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410104,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "บ้านตาด",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410105,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "โนนสูง",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41330,
    "sub_district_code": 410106,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "หมูม่น",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410107,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "เชียงยืน",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410108,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "หนองนาคำ",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410109,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "กุดสระ",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410110,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "นาดี",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410111,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "บ้านเลื่อม",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410112,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "เชียงพิณ",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410113,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "สามพร้าว",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410114,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "บ้านจั่น",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410117,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "หนองไฮ",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410115,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "นาข่า",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410116,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "หนองขอนกว้าง",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410118,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410119,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "บ้านเชียง",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41320,
    "sub_district_code": 410194,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "บ้านผือ",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 410195,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "บ้านเหล่า",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 410196,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "โคกกลาง",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 410197,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "นากว้าง",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 410120,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "หนองไผ่",
    "district": "เมืองอุดรธานี",
    "province": "อุดรธานี",
    "zipcode": 41330,
    "sub_district_code": 410121,
    "district_code": 4101,
    "province_code": 41
  },
  {
    "sub_district": "ตูมใต้",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410401,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "พันดอน",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41370,
    "sub_district_code": 410402,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "เวียงคำ",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410403,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "แชแล",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410404,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "อุ่มจาน",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410493,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "เชียงแหว",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410406,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "ห้วยเกิ้ง",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410407,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "ห้วยสามพาด",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410494,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "เสอเพลอ",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41370,
    "sub_district_code": 410409,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "หนองแสง",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41340,
    "sub_district_code": 410495,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "สีออ",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410410,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "แสงสว่าง",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41340,
    "sub_district_code": 410496,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "ปะโค",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41370,
    "sub_district_code": 410411,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "นาม่วง",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410497,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "ผาสุก",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41370,
    "sub_district_code": 410413,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "ท่าลี่",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410414,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "กุมภวาปี",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410415,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "หนองหว้า",
    "district": "กุมภวาปี",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 410416,
    "district_code": 4104,
    "province_code": 41
  },
  {
    "sub_district": "นางัว",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41210,
    "sub_district_code": 411801,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "น้ำโสม",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41210,
    "sub_district_code": 411802,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "นายูง",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41230,
    "sub_district_code": 411895,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "นาแค",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41380,
    "sub_district_code": 411896,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "หนองแวง",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41210,
    "sub_district_code": 411805,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "บ้านหยวก",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41210,
    "sub_district_code": 411806,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "โสมเยี่ยม",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41210,
    "sub_district_code": 411807,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "โนนทอง",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41380,
    "sub_district_code": 411897,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "บ้านก้อง",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41380,
    "sub_district_code": 411898,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "ศรีสำราญ",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41210,
    "sub_district_code": 411810,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "สามัคคี",
    "district": "น้ำโสม",
    "province": "อุดรธานี",
    "zipcode": 41210,
    "sub_district_code": 411812,
    "district_code": 4118,
    "province_code": 41
  },
  {
    "sub_district": "ศรีสุทโธ",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411101,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "บ้านดุง",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411102,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "ดงเย็น",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411103,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "บ้านจันทน์",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411106,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "อ้อมกอ",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411105,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "บ้านชัย",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411107,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "นาไหม",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411108,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "ถ่อนนาลับ",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411109,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "วังทอง",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411110,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "โพนสูง",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411104,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "บ้านม่วง",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411111,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "บ้านตาด",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411112,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "นาคำ",
    "district": "บ้านดุง",
    "province": "อุดรธานี",
    "zipcode": 41190,
    "sub_district_code": 411113,
    "district_code": 4111,
    "province_code": 41
  },
  {
    "sub_district": "บ้านผือ",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411701,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "หายโศก",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411702,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "เขือน้ำ",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411703,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "คำบง",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411704,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "หนองเม็ก",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 411798,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "ข้าวสาร",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411706,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "จำปาโมง",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411707,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "กลางใหญ่",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411708,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "โนนทอง",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411705,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "คำด้วง",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411710,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "เมืองพาน",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411709,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "หนองหัวคู",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411711,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "บ้านค้อ",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411712,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "หนองแวง",
    "district": "บ้านผือ",
    "province": "อุดรธานี",
    "zipcode": 41160,
    "sub_district_code": 411713,
    "district_code": 4117,
    "province_code": 41
  },
  {
    "sub_district": "เพ็ญ",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411901,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "บ้านธาตุ",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411902,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "นาพู่",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411903,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "เชียงหวาง",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411904,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "สุมเส้า",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411905,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "นาบัว",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411906,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "บ้านเหล่า",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411907,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "จอมศรี",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411908,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "เตาไห",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411909,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "บ้านขาว",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41000,
    "sub_district_code": 411998,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "สร้างแป้น",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411911,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "โคกกลาง",
    "district": "เพ็ญ",
    "province": "อุดรธานี",
    "zipcode": 41150,
    "sub_district_code": 411910,
    "district_code": 4119,
    "province_code": 41
  },
  {
    "sub_district": "ศรีธาตุ",
    "district": "ศรีธาตุ",
    "province": "อุดรธานี",
    "zipcode": 41230,
    "sub_district_code": 410901,
    "district_code": 4109,
    "province_code": 41
  },
  {
    "sub_district": "จำปี",
    "district": "ศรีธาตุ",
    "province": "อุดรธานี",
    "zipcode": 41230,
    "sub_district_code": 410902,
    "district_code": 4109,
    "province_code": 41
  },
  {
    "sub_district": "บ้านโปร่ง",
    "district": "ศรีธาตุ",
    "province": "อุดรธานี",
    "zipcode": 41230,
    "sub_district_code": 410903,
    "district_code": 4109,
    "province_code": 41
  },
  {
    "sub_district": "หัวนาคำ",
    "district": "ศรีธาตุ",
    "province": "อุดรธานี",
    "zipcode": 41230,
    "sub_district_code": 410904,
    "district_code": 4109,
    "province_code": 41
  },
  {
    "sub_district": "หนองนกเขียน",
    "district": "ศรีธาตุ",
    "province": "อุดรธานี",
    "zipcode": 41230,
    "sub_district_code": 410905,
    "district_code": 4109,
    "province_code": 41
  },
  {
    "sub_district": "นายูง",
    "district": "ศรีธาตุ",
    "province": "อุดรธานี",
    "zipcode": 41230,
    "sub_district_code": 410906,
    "district_code": 4109,
    "province_code": 41
  },
  {
    "sub_district": "ตาดทอง",
    "district": "ศรีธาตุ",
    "province": "อุดรธานี",
    "zipcode": 41230,
    "sub_district_code": 410907,
    "district_code": 4109,
    "province_code": 41
  },
  {
    "sub_district": "หมากหญ้า",
    "district": "หนองวัวซอ",
    "province": "อุดรธานี",
    "zipcode": 41360,
    "sub_district_code": 410301,
    "district_code": 4103,
    "province_code": 41
  },
  {
    "sub_district": "หนองอ้อ",
    "district": "หนองวัวซอ",
    "province": "อุดรธานี",
    "zipcode": 41220,
    "sub_district_code": 410302,
    "district_code": 4103,
    "province_code": 41
  },
  {
    "sub_district": "อูบมุง",
    "district": "หนองวัวซอ",
    "province": "อุดรธานี",
    "zipcode": 41220,
    "sub_district_code": 410303,
    "district_code": 4103,
    "province_code": 41
  },
  {
    "sub_district": "กุดหมากไฟ",
    "district": "หนองวัวซอ",
    "province": "อุดรธานี",
    "zipcode": 41220,
    "sub_district_code": 410304,
    "district_code": 4103,
    "province_code": 41
  },
  {
    "sub_district": "น้ำพ่น",
    "district": "หนองวัวซอ",
    "province": "อุดรธานี",
    "zipcode": 41360,
    "sub_district_code": 410305,
    "district_code": 4103,
    "province_code": 41
  },
  {
    "sub_district": "หนองบัวบาน",
    "district": "หนองวัวซอ",
    "province": "อุดรธานี",
    "zipcode": 41360,
    "sub_district_code": 410306,
    "district_code": 4103,
    "province_code": 41
  },
  {
    "sub_district": "โนนหวาย",
    "district": "หนองวัวซอ",
    "province": "อุดรธานี",
    "zipcode": 41220,
    "sub_district_code": 410307,
    "district_code": 4103,
    "province_code": 41
  },
  {
    "sub_district": "หนองวัวซอ",
    "district": "หนองวัวซอ",
    "province": "อุดรธานี",
    "zipcode": 41360,
    "sub_district_code": 410308,
    "district_code": 4103,
    "province_code": 41
  },
  {
    "sub_district": "หนองหาน",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410601,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "หนองเม็ก",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410602,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "คอนสาย",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410691,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "บ้านจีต",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410692,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "พังงู",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410605,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "สะแบง",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410606,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "สร้อยพร้าว",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410607,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "บ้านแดง",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410693,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "บ้านเชียง",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41320,
    "sub_district_code": 410609,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "บ้านยา",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41320,
    "sub_district_code": 410610,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "โพนงาม",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410611,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "ผักตบ",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410612,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "ดอนกลอย",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410695,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "หนองไผ่",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410614,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "นาทราย",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410696,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "ค้อใหญ่",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410697,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "หนองสระปลา",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41320,
    "sub_district_code": 410618,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "ท่าใหญ่",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 36210,
    "sub_district_code": 410698,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "ดอนหายโศก",
    "district": "หนองหาน",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 410617,
    "district_code": 4106,
    "province_code": 41
  },
  {
    "sub_district": "กุดจับ",
    "district": "กุดจับ",
    "province": "อุดรธานี",
    "zipcode": 41250,
    "sub_district_code": 410201,
    "district_code": 4102,
    "province_code": 41
  },
  {
    "sub_district": "ปะโค",
    "district": "กุดจับ",
    "province": "อุดรธานี",
    "zipcode": 41250,
    "sub_district_code": 410202,
    "district_code": 4102,
    "province_code": 41
  },
  {
    "sub_district": "ขอนยูง",
    "district": "กุดจับ",
    "province": "อุดรธานี",
    "zipcode": 41250,
    "sub_district_code": 410203,
    "district_code": 4102,
    "province_code": 41
  },
  {
    "sub_district": "เชียงเพ็ง",
    "district": "กุดจับ",
    "province": "อุดรธานี",
    "zipcode": 41250,
    "sub_district_code": 410204,
    "district_code": 4102,
    "province_code": 41
  },
  {
    "sub_district": "สร้างก่อ",
    "district": "กุดจับ",
    "province": "อุดรธานี",
    "zipcode": 41250,
    "sub_district_code": 410205,
    "district_code": 4102,
    "province_code": 41
  },
  {
    "sub_district": "เมืองเพีย",
    "district": "กุดจับ",
    "province": "อุดรธานี",
    "zipcode": 41250,
    "sub_district_code": 410206,
    "district_code": 4102,
    "province_code": 41
  },
  {
    "sub_district": "ตาลเลียน",
    "district": "กุดจับ",
    "province": "อุดรธานี",
    "zipcode": 41250,
    "sub_district_code": 410207,
    "district_code": 4102,
    "province_code": 41
  },
  {
    "sub_district": "โนนสะอาด",
    "district": "โนนสะอาด",
    "province": "อุดรธานี",
    "zipcode": 41240,
    "sub_district_code": 410501,
    "district_code": 4105,
    "province_code": 41
  },
  {
    "sub_district": "บุ่งแก้ว",
    "district": "โนนสะอาด",
    "province": "อุดรธานี",
    "zipcode": 41240,
    "sub_district_code": 410502,
    "district_code": 4105,
    "province_code": 41
  },
  {
    "sub_district": "โพธิ์ศรีสำราญ",
    "district": "โนนสะอาด",
    "province": "อุดรธานี",
    "zipcode": 41240,
    "sub_district_code": 410503,
    "district_code": 4105,
    "province_code": 41
  },
  {
    "sub_district": "ทมนางาม",
    "district": "โนนสะอาด",
    "province": "อุดรธานี",
    "zipcode": 41240,
    "sub_district_code": 410504,
    "district_code": 4105,
    "province_code": 41
  },
  {
    "sub_district": "หนองกุงศรี",
    "district": "โนนสะอาด",
    "province": "อุดรธานี",
    "zipcode": 41240,
    "sub_district_code": 410505,
    "district_code": 4105,
    "province_code": 41
  },
  {
    "sub_district": "โคกกลาง",
    "district": "โนนสะอาด",
    "province": "อุดรธานี",
    "zipcode": 41240,
    "sub_district_code": 410506,
    "district_code": 4105,
    "province_code": 41
  },
  {
    "sub_district": "สร้างคอม",
    "district": "สร้างคอม",
    "province": "อุดรธานี",
    "zipcode": 41260,
    "sub_district_code": 412001,
    "district_code": 4120,
    "province_code": 41
  },
  {
    "sub_district": "เชียงดา",
    "district": "สร้างคอม",
    "province": "อุดรธานี",
    "zipcode": 41260,
    "sub_district_code": 412002,
    "district_code": 4120,
    "province_code": 41
  },
  {
    "sub_district": "บ้านยวด",
    "district": "สร้างคอม",
    "province": "อุดรธานี",
    "zipcode": 41260,
    "sub_district_code": 412003,
    "district_code": 4120,
    "province_code": 41
  },
  {
    "sub_district": "บ้านโคก",
    "district": "สร้างคอม",
    "province": "อุดรธานี",
    "zipcode": 41260,
    "sub_district_code": 412004,
    "district_code": 4120,
    "province_code": 41
  },
  {
    "sub_district": "บ้านหินโงม",
    "district": "สร้างคอม",
    "province": "อุดรธานี",
    "zipcode": 41260,
    "sub_district_code": 412006,
    "district_code": 4120,
    "province_code": 41
  },
  {
    "sub_district": "นาสะอาด",
    "district": "สร้างคอม",
    "province": "อุดรธานี",
    "zipcode": 41260,
    "sub_district_code": 412005,
    "district_code": 4120,
    "province_code": 41
  },
  {
    "sub_district": "หนองกุงทับม้า",
    "district": "วังสามหมอ",
    "province": "อุดรธานี",
    "zipcode": 41280,
    "sub_district_code": 411001,
    "district_code": 4110,
    "province_code": 41
  },
  {
    "sub_district": "หนองหญ้าไซ",
    "district": "วังสามหมอ",
    "province": "อุดรธานี",
    "zipcode": 41280,
    "sub_district_code": 411002,
    "district_code": 4110,
    "province_code": 41
  },
  {
    "sub_district": "บะยาว",
    "district": "วังสามหมอ",
    "province": "อุดรธานี",
    "zipcode": 41280,
    "sub_district_code": 411003,
    "district_code": 4110,
    "province_code": 41
  },
  {
    "sub_district": "ผาสุก",
    "district": "วังสามหมอ",
    "province": "อุดรธานี",
    "zipcode": 41280,
    "sub_district_code": 411004,
    "district_code": 4110,
    "province_code": 41
  },
  {
    "sub_district": "คำโคกสูง",
    "district": "วังสามหมอ",
    "province": "อุดรธานี",
    "zipcode": 41280,
    "sub_district_code": 411005,
    "district_code": 4110,
    "province_code": 41
  },
  {
    "sub_district": "วังสามหมอ",
    "district": "วังสามหมอ",
    "province": "อุดรธานี",
    "zipcode": 41280,
    "sub_district_code": 411006,
    "district_code": 4110,
    "province_code": 41
  },
  {
    "sub_district": "ทุ่งฝน",
    "district": "ทุ่งฝน",
    "province": "อุดรธานี",
    "zipcode": 41310,
    "sub_district_code": 410701,
    "district_code": 4107,
    "province_code": 41
  },
  {
    "sub_district": "ทุ่งใหญ่",
    "district": "ทุ่งฝน",
    "province": "อุดรธานี",
    "zipcode": 41310,
    "sub_district_code": 410702,
    "district_code": 4107,
    "province_code": 41
  },
  {
    "sub_district": "นาชุมแสง",
    "district": "ทุ่งฝน",
    "province": "อุดรธานี",
    "zipcode": 41310,
    "sub_district_code": 410703,
    "district_code": 4107,
    "province_code": 41
  },
  {
    "sub_district": "นาทม",
    "district": "ทุ่งฝน",
    "province": "อุดรธานี",
    "zipcode": 41310,
    "sub_district_code": 410704,
    "district_code": 4107,
    "province_code": 41
  },
  {
    "sub_district": "ไชยวาน",
    "district": "ไชยวาน",
    "province": "อุดรธานี",
    "zipcode": 41290,
    "sub_district_code": 410801,
    "district_code": 4108,
    "province_code": 41
  },
  {
    "sub_district": "หนองหลัก",
    "district": "ไชยวาน",
    "province": "อุดรธานี",
    "zipcode": 41290,
    "sub_district_code": 410802,
    "district_code": 4108,
    "province_code": 41
  },
  {
    "sub_district": "คำเลาะ",
    "district": "ไชยวาน",
    "province": "อุดรธานี",
    "zipcode": 41290,
    "sub_district_code": 410803,
    "district_code": 4108,
    "province_code": 41
  },
  {
    "sub_district": "โพนสูง",
    "district": "ไชยวาน",
    "province": "อุดรธานี",
    "zipcode": 41290,
    "sub_district_code": 410804,
    "district_code": 4108,
    "province_code": 41
  },
  {
    "sub_district": "หนองแสง",
    "district": "หนองแสง",
    "province": "อุดรธานี",
    "zipcode": 41340,
    "sub_district_code": 412101,
    "district_code": 4121,
    "province_code": 41
  },
  {
    "sub_district": "แสงสว่าง",
    "district": "หนองแสง",
    "province": "อุดรธานี",
    "zipcode": 41340,
    "sub_district_code": 412102,
    "district_code": 4121,
    "province_code": 41
  },
  {
    "sub_district": "ทับกุง",
    "district": "หนองแสง",
    "province": "อุดรธานี",
    "zipcode": 41340,
    "sub_district_code": 412104,
    "district_code": 4121,
    "province_code": 41
  },
  {
    "sub_district": "หนองแสง",
    "district": "หนองแสง",
    "province": "อุดรธานี",
    "zipcode": 41340,
    "sub_district_code": 412101,
    "district_code": 4121,
    "province_code": 41
  },
  {
    "sub_district": "แสงสว่าง",
    "district": "หนองแสง",
    "province": "อุดรธานี",
    "zipcode": 41340,
    "sub_district_code": 412102,
    "district_code": 4121,
    "province_code": 41
  },
  {
    "sub_district": "ทับกุง",
    "district": "หนองแสง",
    "province": "อุดรธานี",
    "zipcode": 41340,
    "sub_district_code": 412104,
    "district_code": 4121,
    "province_code": 41
  },
  {
    "sub_district": "นาดี",
    "district": "หนองแสง",
    "province": "อุดรธานี",
    "zipcode": 41340,
    "sub_district_code": 412103,
    "district_code": 4121,
    "province_code": 41
  },
  {
    "sub_district": "นายูง",
    "district": "นายูง",
    "province": "อุดรธานี",
    "zipcode": 41380,
    "sub_district_code": 412201,
    "district_code": 4122,
    "province_code": 41
  },
  {
    "sub_district": "นาแค",
    "district": "นายูง",
    "province": "อุดรธานี",
    "zipcode": 41380,
    "sub_district_code": 412203,
    "district_code": 4122,
    "province_code": 41
  },
  {
    "sub_district": "บ้านก้อง",
    "district": "นายูง",
    "province": "อุดรธานี",
    "zipcode": 41380,
    "sub_district_code": 412202,
    "district_code": 4122,
    "province_code": 41
  },
  {
    "sub_district": "โนนทอง",
    "district": "นายูง",
    "province": "อุดรธานี",
    "zipcode": 41380,
    "sub_district_code": 412204,
    "district_code": 4122,
    "province_code": 41
  },
  {
    "sub_district": "นาทราย",
    "district": "พิบูลย์รักษ์",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 412302,
    "district_code": 4123,
    "province_code": 41
  },
  {
    "sub_district": "บ้านแดง",
    "district": "พิบูลย์รักษ์",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 412301,
    "district_code": 4123,
    "province_code": 41
  },
  {
    "sub_district": "ดอนกลอย",
    "district": "พิบูลย์รักษ์",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 412303,
    "district_code": 4123,
    "province_code": 41
  },
  {
    "sub_district": "นาม่วง",
    "district": "ประจักษ์ศิลปาคม",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 412501,
    "district_code": 4125,
    "province_code": 41
  },
  {
    "sub_district": "ห้วยสามพาด",
    "district": "ประจักษ์ศิลปาคม",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 412502,
    "district_code": 4125,
    "province_code": 41
  },
  {
    "sub_district": "อุ่มจาน",
    "district": "ประจักษ์ศิลปาคม",
    "province": "อุดรธานี",
    "zipcode": 41110,
    "sub_district_code": 412503,
    "district_code": 4125,
    "province_code": 41
  },
  {
    "sub_district": "บ้านจีต",
    "district": "กู่แก้ว",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 412401,
    "district_code": 4124,
    "province_code": 41
  },
  {
    "sub_district": "โนนทองอินทร์",
    "district": "กู่แก้ว",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 412402,
    "district_code": 4124,
    "province_code": 41
  },
  {
    "sub_district": "ค้อใหญ่",
    "district": "กู่แก้ว",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 412403,
    "district_code": 4124,
    "province_code": 41
  },
  {
    "sub_district": "คอนสาย",
    "district": "กู่แก้ว",
    "province": "อุดรธานี",
    "zipcode": 41130,
    "sub_district_code": 412404,
    "district_code": 4124,
    "province_code": 41
  },
  {
    "sub_district": "ท่าอิฐ",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530101,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "ท่าเสา",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530102,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "บ้านเกาะ",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530103,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "ป่าเซ่า",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530104,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "คุ้งตะเภา",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530105,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "วังกะพี้",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53170,
    "sub_district_code": 530106,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "หาดกรวด",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530107,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "น้ำริด",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530108,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "งิ้วงาม",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530109,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "บ้านด่านนาขาม",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530110,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "บ้านด่าน",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530111,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "ผาจุก",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530112,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "วังดิน",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530113,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "แสนตอ",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530114,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "หาดงิ้ว",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530115,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "ขุนฝาง",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530116,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "ถ้ำฉลอง",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53000,
    "sub_district_code": 530117,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "ท่าสัก",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53220,
    "sub_district_code": 530197,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "ป่าคาย",
    "district": "เมืองอุตรดิตถ์",
    "province": "อุตรดิตถ์",
    "zipcode": 53230,
    "sub_district_code": 530198,
    "district_code": 5301,
    "province_code": 53
  },
  {
    "sub_district": "วังแดง",
    "district": "ตรอน",
    "province": "อุตรดิตถ์",
    "zipcode": 53140,
    "sub_district_code": 530201,
    "district_code": 5302,
    "province_code": 53
  },
  {
    "sub_district": "บ้านแก่ง",
    "district": "ตรอน",
    "province": "อุตรดิตถ์",
    "zipcode": 53140,
    "sub_district_code": 530202,
    "district_code": 5302,
    "province_code": 53
  },
  {
    "sub_district": "หาดสองแคว",
    "district": "ตรอน",
    "province": "อุตรดิตถ์",
    "zipcode": 53140,
    "sub_district_code": 530203,
    "district_code": 5302,
    "province_code": 53
  },
  {
    "sub_district": "น้ำอ่าง",
    "district": "ตรอน",
    "province": "อุตรดิตถ์",
    "zipcode": 53140,
    "sub_district_code": 530204,
    "district_code": 5302,
    "province_code": 53
  },
  {
    "sub_district": "ป่าคาย",
    "district": "ตรอน",
    "province": "อุตรดิตถ์",
    "zipcode": 53230,
    "sub_district_code": 530296,
    "district_code": 5302,
    "province_code": 53
  },
  {
    "sub_district": "ผักขวง",
    "district": "ตรอน",
    "province": "อุตรดิตถ์",
    "zipcode": 53230,
    "sub_district_code": 530297,
    "district_code": 5302,
    "province_code": 53
  },
  {
    "sub_district": "น้ำพี้",
    "district": "ตรอน",
    "province": "อุตรดิตถ์",
    "zipcode": 53230,
    "sub_district_code": 530298,
    "district_code": 5302,
    "province_code": 53
  },
  {
    "sub_district": "ข่อยสูง",
    "district": "ตรอน",
    "province": "อุตรดิตถ์",
    "zipcode": 53140,
    "sub_district_code": 530205,
    "district_code": 5302,
    "province_code": 53
  },
  {
    "sub_district": "ท่าปลา",
    "district": "ท่าปลา",
    "province": "อุตรดิตถ์",
    "zipcode": 53150,
    "sub_district_code": 530301,
    "district_code": 5303,
    "province_code": 53
  },
  {
    "sub_district": "หาดล้า",
    "district": "ท่าปลา",
    "province": "อุตรดิตถ์",
    "zipcode": 53190,
    "sub_district_code": 530302,
    "district_code": 5303,
    "province_code": 53
  },
  {
    "sub_district": "ผาเลือด",
    "district": "ท่าปลา",
    "province": "อุตรดิตถ์",
    "zipcode": 53190,
    "sub_district_code": 530303,
    "district_code": 5303,
    "province_code": 53
  },
  {
    "sub_district": "จริม",
    "district": "ท่าปลา",
    "province": "อุตรดิตถ์",
    "zipcode": 53150,
    "sub_district_code": 530304,
    "district_code": 5303,
    "province_code": 53
  },
  {
    "sub_district": "น้ำหมัน",
    "district": "ท่าปลา",
    "province": "อุตรดิตถ์",
    "zipcode": 53150,
    "sub_district_code": 530305,
    "district_code": 5303,
    "province_code": 53
  },
  {
    "sub_district": "นางพญา",
    "district": "ท่าปลา",
    "province": "อุตรดิตถ์",
    "zipcode": 53150,
    "sub_district_code": 530307,
    "district_code": 5303,
    "province_code": 53
  },
  {
    "sub_district": "ร่วมจิต",
    "district": "ท่าปลา",
    "province": "อุตรดิตถ์",
    "zipcode": 53190,
    "sub_district_code": 530308,
    "district_code": 5303,
    "province_code": 53
  },
  {
    "sub_district": "แสนตอ",
    "district": "น้ำปาด",
    "province": "อุตรดิตถ์",
    "zipcode": 53110,
    "sub_district_code": 530401,
    "district_code": 5304,
    "province_code": 53
  },
  {
    "sub_district": "บ้านฝาย",
    "district": "น้ำปาด",
    "province": "อุตรดิตถ์",
    "zipcode": 53110,
    "sub_district_code": 530402,
    "district_code": 5304,
    "province_code": 53
  },
  {
    "sub_district": "เด่นเหล็ก",
    "district": "น้ำปาด",
    "province": "อุตรดิตถ์",
    "zipcode": 53110,
    "sub_district_code": 530403,
    "district_code": 5304,
    "province_code": 53
  },
  {
    "sub_district": "น้ำไคร้",
    "district": "น้ำปาด",
    "province": "อุตรดิตถ์",
    "zipcode": 53110,
    "sub_district_code": 530404,
    "district_code": 5304,
    "province_code": 53
  },
  {
    "sub_district": "น้ำไผ่",
    "district": "น้ำปาด",
    "province": "อุตรดิตถ์",
    "zipcode": 53110,
    "sub_district_code": 530405,
    "district_code": 5304,
    "province_code": 53
  },
  {
    "sub_district": "ห้วยมุ่น",
    "district": "น้ำปาด",
    "province": "อุตรดิตถ์",
    "zipcode": 53110,
    "sub_district_code": 530406,
    "district_code": 5304,
    "province_code": 53
  },
  {
    "sub_district": "ท่าแฝก",
    "district": "น้ำปาด",
    "province": "อุตรดิตถ์",
    "zipcode": 53110,
    "sub_district_code": 530407,
    "district_code": 5304,
    "province_code": 53
  },
  {
    "sub_district": "ในเมือง",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53120,
    "sub_district_code": 530701,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "บ้านดารา",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53220,
    "sub_district_code": 530702,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "ไร่อ้อย",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53120,
    "sub_district_code": 530703,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "ท่าสัก",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53220,
    "sub_district_code": 530704,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "คอรุม",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53120,
    "sub_district_code": 530705,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "บ้านหม้อ",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53120,
    "sub_district_code": 530706,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "ท่ามะเฟือง",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53120,
    "sub_district_code": 530707,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "บ้านโคน",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53120,
    "sub_district_code": 530708,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "พญาแมน",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53120,
    "sub_district_code": 530709,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "นาอิน",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53120,
    "sub_district_code": 530710,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "นายาง",
    "district": "พิชัย",
    "province": "อุตรดิตถ์",
    "zipcode": 53120,
    "sub_district_code": 530711,
    "district_code": 5307,
    "province_code": 53
  },
  {
    "sub_district": "ฟากท่า",
    "district": "ฟากท่า",
    "province": "อุตรดิตถ์",
    "zipcode": 53160,
    "sub_district_code": 530501,
    "district_code": 5305,
    "province_code": 53
  },
  {
    "sub_district": "สองคอน",
    "district": "ฟากท่า",
    "province": "อุตรดิตถ์",
    "zipcode": 53160,
    "sub_district_code": 530502,
    "district_code": 5305,
    "province_code": 53
  },
  {
    "sub_district": "บ้านเสี้ยว",
    "district": "ฟากท่า",
    "province": "อุตรดิตถ์",
    "zipcode": 53160,
    "sub_district_code": 530503,
    "district_code": 5305,
    "province_code": 53
  },
  {
    "sub_district": "สองห้อง",
    "district": "ฟากท่า",
    "province": "อุตรดิตถ์",
    "zipcode": 53160,
    "sub_district_code": 530504,
    "district_code": 5305,
    "province_code": 53
  },
  {
    "sub_district": "แม่พูล",
    "district": "ลับแล",
    "province": "อุตรดิตถ์",
    "zipcode": 53130,
    "sub_district_code": 530802,
    "district_code": 5308,
    "province_code": 53
  },
  {
    "sub_district": "นานกกก",
    "district": "ลับแล",
    "province": "อุตรดิตถ์",
    "zipcode": 53130,
    "sub_district_code": 530803,
    "district_code": 5308,
    "province_code": 53
  },
  {
    "sub_district": "ฝายหลวง",
    "district": "ลับแล",
    "province": "อุตรดิตถ์",
    "zipcode": 53130,
    "sub_district_code": 530804,
    "district_code": 5308,
    "province_code": 53
  },
  {
    "sub_district": "ชัยจุมพล",
    "district": "ลับแล",
    "province": "อุตรดิตถ์",
    "zipcode": 53130,
    "sub_district_code": 530805,
    "district_code": 5308,
    "province_code": 53
  },
  {
    "sub_district": "ไผ่ล้อม",
    "district": "ลับแล",
    "province": "อุตรดิตถ์",
    "zipcode": 53210,
    "sub_district_code": 530806,
    "district_code": 5308,
    "province_code": 53
  },
  {
    "sub_district": "ทุ่งยั้ง",
    "district": "ลับแล",
    "province": "อุตรดิตถ์",
    "zipcode": 53210,
    "sub_district_code": 530807,
    "district_code": 5308,
    "province_code": 53
  },
  {
    "sub_district": "ด่านแม่คำมัน",
    "district": "ลับแล",
    "province": "อุตรดิตถ์",
    "zipcode": 53210,
    "sub_district_code": 530808,
    "district_code": 5308,
    "province_code": 53
  },
  {
    "sub_district": "ศรีพนมมาศ",
    "district": "ลับแล",
    "province": "อุตรดิตถ์",
    "zipcode": 53130,
    "sub_district_code": 530801,
    "district_code": 5308,
    "province_code": 53
  },
  {
    "sub_district": "ม่วงเจ็ดต้น",
    "district": "บ้านโคก",
    "province": "อุตรดิตถ์",
    "zipcode": 53180,
    "sub_district_code": 530601,
    "district_code": 5306,
    "province_code": 53
  },
  {
    "sub_district": "บ้านโคก",
    "district": "บ้านโคก",
    "province": "อุตรดิตถ์",
    "zipcode": 53180,
    "sub_district_code": 530602,
    "district_code": 5306,
    "province_code": 53
  },
  {
    "sub_district": "นาขุม",
    "district": "บ้านโคก",
    "province": "อุตรดิตถ์",
    "zipcode": 53180,
    "sub_district_code": 530603,
    "district_code": 5306,
    "province_code": 53
  },
  {
    "sub_district": "บ่อเบี้ย",
    "district": "บ้านโคก",
    "province": "อุตรดิตถ์",
    "zipcode": 53180,
    "sub_district_code": 530604,
    "district_code": 5306,
    "province_code": 53
  },
  {
    "sub_district": "บ่อทอง",
    "district": "ทองแสนขัน",
    "province": "อุตรดิตถ์",
    "zipcode": 53230,
    "sub_district_code": 530902,
    "district_code": 5309,
    "province_code": 53
  },
  {
    "sub_district": "ป่าคาย",
    "district": "ทองแสนขัน",
    "province": "อุตรดิตถ์",
    "zipcode": 53230,
    "sub_district_code": 530903,
    "district_code": 5309,
    "province_code": 53
  },
  {
    "sub_district": "ผักขวง",
    "district": "ทองแสนขัน",
    "province": "อุตรดิตถ์",
    "zipcode": 53230,
    "sub_district_code": 530901,
    "district_code": 5309,
    "province_code": 53
  },
  {
    "sub_district": "น้ำพี้",
    "district": "ทองแสนขัน",
    "province": "อุตรดิตถ์",
    "zipcode": 53230,
    "sub_district_code": 530904,
    "district_code": 5309,
    "province_code": 53
  },
  {
    "sub_district": "อุทัยใหม่",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610101,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "น้ำซึม",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610102,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "สะแกกรัง",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610103,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "ดอนขวาง",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610104,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "หาดทนง",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610105,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "เกาะเทโพ",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610106,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "ท่าซุง",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610107,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "หนองแก",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610108,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "โนนเหล็ก",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610109,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "หนองเต่า",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610110,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "หนองไผ่แบน",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610111,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "หนองพังค่า",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610112,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "ทุ่งใหญ่",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610113,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "เนินแจง",
    "district": "เมืองอุทัยธานี",
    "province": "อุทัยธานี",
    "zipcode": 61000,
    "sub_district_code": 610114,
    "district_code": 6101,
    "province_code": 61
  },
  {
    "sub_district": "ทัพทัน",
    "district": "ทัพทัน",
    "province": "อุทัยธานี",
    "zipcode": 61120,
    "sub_district_code": 610201,
    "district_code": 6102,
    "province_code": 61
  },
  {
    "sub_district": "ทุ่งนาไทย",
    "district": "ทัพทัน",
    "province": "อุทัยธานี",
    "zipcode": 61120,
    "sub_district_code": 610202,
    "district_code": 6102,
    "province_code": 61
  },
  {
    "sub_district": "เขาขี้ฝอย",
    "district": "ทัพทัน",
    "province": "อุทัยธานี",
    "zipcode": 61120,
    "sub_district_code": 610203,
    "district_code": 6102,
    "province_code": 61
  },
  {
    "sub_district": "หนองหญ้าปล้อง",
    "district": "ทัพทัน",
    "province": "อุทัยธานี",
    "zipcode": 61120,
    "sub_district_code": 610204,
    "district_code": 6102,
    "province_code": 61
  },
  {
    "sub_district": "โคกหม้อ",
    "district": "ทัพทัน",
    "province": "อุทัยธานี",
    "zipcode": 61120,
    "sub_district_code": 610205,
    "district_code": 6102,
    "province_code": 61
  },
  {
    "sub_district": "หนองยายดา",
    "district": "ทัพทัน",
    "province": "อุทัยธานี",
    "zipcode": 61120,
    "sub_district_code": 610206,
    "district_code": 6102,
    "province_code": 61
  },
  {
    "sub_district": "หนองกลางดง",
    "district": "ทัพทัน",
    "province": "อุทัยธานี",
    "zipcode": 61120,
    "sub_district_code": 610207,
    "district_code": 6102,
    "province_code": 61
  },
  {
    "sub_district": "หนองกระทุ่ม",
    "district": "ทัพทัน",
    "province": "อุทัยธานี",
    "zipcode": 61120,
    "sub_district_code": 610208,
    "district_code": 6102,
    "province_code": 61
  },
  {
    "sub_district": "หนองสระ",
    "district": "ทัพทัน",
    "province": "อุทัยธานี",
    "zipcode": 61120,
    "sub_district_code": 610209,
    "district_code": 6102,
    "province_code": 61
  },
  {
    "sub_district": "ตลุกดู่",
    "district": "ทัพทัน",
    "province": "อุทัยธานี",
    "zipcode": 61120,
    "sub_district_code": 610210,
    "district_code": 6102,
    "province_code": 61
  },
  {
    "sub_district": "บ้านไร่",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61140,
    "sub_district_code": 610601,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "ทัพหลวง",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61140,
    "sub_district_code": 610602,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "ห้วยแห้ง",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61140,
    "sub_district_code": 610603,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "คอกควาย",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61140,
    "sub_district_code": 610604,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "วังหิน",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61180,
    "sub_district_code": 610605,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "แก่นมะกรูด",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61140,
    "sub_district_code": 610607,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "เมืองการุ้ง",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61180,
    "sub_district_code": 610606,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "หนองจอก",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61180,
    "sub_district_code": 610609,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "หูช้าง",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61180,
    "sub_district_code": 610610,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "บ้านใหม่คลองเคียน",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61180,
    "sub_district_code": 610612,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "บ้านบึง",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61140,
    "sub_district_code": 610611,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "หนองบ่มกล้วย",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61180,
    "sub_district_code": 610613,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "เจ้าวัด",
    "district": "บ้านไร่",
    "province": "อุทัยธานี",
    "zipcode": 61140,
    "sub_district_code": 610614,
    "district_code": 6106,
    "province_code": 61
  },
  {
    "sub_district": "สว่างอารมณ์",
    "district": "สว่างอารมณ์",
    "province": "อุทัยธานี",
    "zipcode": 61150,
    "sub_district_code": 610301,
    "district_code": 6103,
    "province_code": 61
  },
  {
    "sub_district": "หนองหลวง",
    "district": "สว่างอารมณ์",
    "province": "อุทัยธานี",
    "zipcode": 61150,
    "sub_district_code": 610302,
    "district_code": 6103,
    "province_code": 61
  },
  {
    "sub_district": "พลวงสองนาง",
    "district": "สว่างอารมณ์",
    "province": "อุทัยธานี",
    "zipcode": 61150,
    "sub_district_code": 610303,
    "district_code": 6103,
    "province_code": 61
  },
  {
    "sub_district": "ไผ่เขียว",
    "district": "สว่างอารมณ์",
    "province": "อุทัยธานี",
    "zipcode": 61150,
    "sub_district_code": 610304,
    "district_code": 6103,
    "province_code": 61
  },
  {
    "sub_district": "บ่อยาง",
    "district": "สว่างอารมณ์",
    "province": "อุทัยธานี",
    "zipcode": 61150,
    "sub_district_code": 610305,
    "district_code": 6103,
    "province_code": 61
  },
  {
    "sub_district": "หนองขาหย่าง",
    "district": "หนองขาหย่าง",
    "province": "อุทัยธานี",
    "zipcode": 61130,
    "sub_district_code": 610501,
    "district_code": 6105,
    "province_code": 61
  },
  {
    "sub_district": "หนองไผ่",
    "district": "หนองขาหย่าง",
    "province": "อุทัยธานี",
    "zipcode": 61130,
    "sub_district_code": 610502,
    "district_code": 6105,
    "province_code": 61
  },
  {
    "sub_district": "ดอนกลอย",
    "district": "หนองขาหย่าง",
    "province": "อุทัยธานี",
    "zipcode": 61130,
    "sub_district_code": 610503,
    "district_code": 6105,
    "province_code": 61
  },
  {
    "sub_district": "ห้วยรอบ",
    "district": "หนองขาหย่าง",
    "province": "อุทัยธานี",
    "zipcode": 61130,
    "sub_district_code": 610504,
    "district_code": 6105,
    "province_code": 61
  },
  {
    "sub_district": "ทุ่งพึ่ง",
    "district": "หนองขาหย่าง",
    "province": "อุทัยธานี",
    "zipcode": 61130,
    "sub_district_code": 610505,
    "district_code": 6105,
    "province_code": 61
  },
  {
    "sub_district": "ท่าโพ",
    "district": "หนองขาหย่าง",
    "province": "อุทัยธานี",
    "zipcode": 61130,
    "sub_district_code": 610506,
    "district_code": 6105,
    "province_code": 61
  },
  {
    "sub_district": "หมกแถว",
    "district": "หนองขาหย่าง",
    "province": "อุทัยธานี",
    "zipcode": 61130,
    "sub_district_code": 610507,
    "district_code": 6105,
    "province_code": 61
  },
  {
    "sub_district": "หลุมเข้า",
    "district": "หนองขาหย่าง",
    "province": "อุทัยธานี",
    "zipcode": 61130,
    "sub_district_code": 610508,
    "district_code": 6105,
    "province_code": 61
  },
  {
    "sub_district": "ดงขวาง",
    "district": "หนองขาหย่าง",
    "province": "อุทัยธานี",
    "zipcode": 61130,
    "sub_district_code": 610509,
    "district_code": 6105,
    "province_code": 61
  },
  {
    "sub_district": "หนองฉาง",
    "district": "หนองฉาง",
    "province": "อุทัยธานี",
    "zipcode": 61110,
    "sub_district_code": 610401,
    "district_code": 6104,
    "province_code": 61
  },
  {
    "sub_district": "หนองยาง",
    "district": "หนองฉาง",
    "province": "อุทัยธานี",
    "zipcode": 61110,
    "sub_district_code": 610402,
    "district_code": 6104,
    "province_code": 61
  },
  {
    "sub_district": "หนองนางนวล",
    "district": "หนองฉาง",
    "province": "อุทัยธานี",
    "zipcode": 61110,
    "sub_district_code": 610403,
    "district_code": 6104,
    "province_code": 61
  },
  {
    "sub_district": "หนองสรวง",
    "district": "หนองฉาง",
    "province": "อุทัยธานี",
    "zipcode": 61110,
    "sub_district_code": 610404,
    "district_code": 6104,
    "province_code": 61
  },
  {
    "sub_district": "บ้านเก่า",
    "district": "หนองฉาง",
    "province": "อุทัยธานี",
    "zipcode": 61110,
    "sub_district_code": 610405,
    "district_code": 6104,
    "province_code": 61
  },
  {
    "sub_district": "อุทัยเก่า",
    "district": "หนองฉาง",
    "province": "อุทัยธานี",
    "zipcode": 61110,
    "sub_district_code": 610406,
    "district_code": 6104,
    "province_code": 61
  },
  {
    "sub_district": "ทุ่งโพ",
    "district": "หนองฉาง",
    "province": "อุทัยธานี",
    "zipcode": 61110,
    "sub_district_code": 610407,
    "district_code": 6104,
    "province_code": 61
  },
  {
    "sub_district": "ทุ่งพง",
    "district": "หนองฉาง",
    "province": "อุทัยธานี",
    "zipcode": 61110,
    "sub_district_code": 610408,
    "district_code": 6104,
    "province_code": 61
  },
  {
    "sub_district": "เขาบางแกรก",
    "district": "หนองฉาง",
    "province": "อุทัยธานี",
    "zipcode": 61170,
    "sub_district_code": 610409,
    "district_code": 6104,
    "province_code": 61
  },
  {
    "sub_district": "เขากวางทอง",
    "district": "หนองฉาง",
    "province": "อุทัยธานี",
    "zipcode": 61110,
    "sub_district_code": 610410,
    "district_code": 6104,
    "province_code": 61
  },
  {
    "sub_district": "ลานสัก",
    "district": "ลานสัก",
    "province": "อุทัยธานี",
    "zipcode": 61160,
    "sub_district_code": 610701,
    "district_code": 6107,
    "province_code": 61
  },
  {
    "sub_district": "ประดู่ยืน",
    "district": "ลานสัก",
    "province": "อุทัยธานี",
    "zipcode": 61160,
    "sub_district_code": 610702,
    "district_code": 6107,
    "province_code": 61
  },
  {
    "sub_district": "ป่าอ้อ",
    "district": "ลานสัก",
    "province": "อุทัยธานี",
    "zipcode": 61160,
    "sub_district_code": 610703,
    "district_code": 6107,
    "province_code": 61
  },
  {
    "sub_district": "ระบำ",
    "district": "ลานสัก",
    "province": "อุทัยธานี",
    "zipcode": 61160,
    "sub_district_code": 610704,
    "district_code": 6107,
    "province_code": 61
  },
  {
    "sub_district": "น้ำรอบ",
    "district": "ลานสัก",
    "province": "อุทัยธานี",
    "zipcode": 61160,
    "sub_district_code": 610705,
    "district_code": 6107,
    "province_code": 61
  },
  {
    "sub_district": "ทุ่งนางาม",
    "district": "ลานสัก",
    "province": "อุทัยธานี",
    "zipcode": 61160,
    "sub_district_code": 610706,
    "district_code": 6107,
    "province_code": 61
  },
  {
    "sub_district": "สุขฤทัย",
    "district": "ห้วยคต",
    "province": "อุทัยธานี",
    "zipcode": 61170,
    "sub_district_code": 610801,
    "district_code": 6108,
    "province_code": 61
  },
  {
    "sub_district": "ทองหลาง",
    "district": "ห้วยคต",
    "province": "อุทัยธานี",
    "zipcode": 61170,
    "sub_district_code": 610802,
    "district_code": 6108,
    "province_code": 61
  },
  {
    "sub_district": "ห้วยคต",
    "district": "ห้วยคต",
    "province": "อุทัยธานี",
    "zipcode": 61170,
    "sub_district_code": 610803,
    "district_code": 6108,
    "province_code": 61
  },
  {
    "sub_district": "ในเมือง",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340101,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "โพนเมือง",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340190,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "ท่าเมือง",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340191,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "หัวเรือ",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340104,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "หนองขอน",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340105,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "ดอนมดแดง",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340192,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "ปทุม",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340107,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "ขามใหญ่",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340108,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "แจระแม",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340109,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "คำไฮใหญ่",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340193,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "หนองบ่อ",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340111,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "ไร่น้อย",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340112,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "กระโสบ",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340113,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "เหล่าแดง",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340194,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "เหล่าเสือโก้ก",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340195,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "กุดลาด",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340116,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "คำน้ำแซบ",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 340197,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "แพงใหญ่",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340198,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "ขี้เหล็ก",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340119,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "ปะอาว",
    "district": "เมืองอุบลราชธานี",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 340120,
    "district_code": 3401,
    "province_code": 34
  },
  {
    "sub_district": "ข้าวปุ้น",
    "district": "กุดข้าวปุ้น",
    "province": "อุบลราชธานี",
    "zipcode": 34270,
    "sub_district_code": 341201,
    "district_code": 3412,
    "province_code": 34
  },
  {
    "sub_district": "โนนสวาง",
    "district": "กุดข้าวปุ้น",
    "province": "อุบลราชธานี",
    "zipcode": 34270,
    "sub_district_code": 341202,
    "district_code": 3412,
    "province_code": 34
  },
  {
    "sub_district": "แก่งเค็ง",
    "district": "กุดข้าวปุ้น",
    "province": "อุบลราชธานี",
    "zipcode": 34270,
    "sub_district_code": 341203,
    "district_code": 3412,
    "province_code": 34
  },
  {
    "sub_district": "กาบิน",
    "district": "กุดข้าวปุ้น",
    "province": "อุบลราชธานี",
    "zipcode": 34270,
    "sub_district_code": 341204,
    "district_code": 3412,
    "province_code": 34
  },
  {
    "sub_district": "หนองทันน้ำ",
    "district": "กุดข้าวปุ้น",
    "province": "อุบลราชธานี",
    "zipcode": 34270,
    "sub_district_code": 341205,
    "district_code": 3412,
    "province_code": 34
  },
  {
    "sub_district": "เขมราฐ",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340501,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "กองโพน",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340594,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "ขามป้อม",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340503,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "เจียด",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340504,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "พังเคน",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340595,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "นาตาล",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340596,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "หนองผือ",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340507,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "นาแวง",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340508,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "พะลาน",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340598,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "หนองนกทา",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340511,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "แก้งเหนือ",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340510,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "หัวนา",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340513,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "หนองสิม",
    "district": "เขมราฐ",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 340512,
    "district_code": 3405,
    "province_code": 34
  },
  {
    "sub_district": "เขื่องใน",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340401,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "สร้างถ่อ",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340402,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "ค้อทอง",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340403,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "ก่อเอ้",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340404,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "หัวดอน",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340405,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "ชีทวน",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340406,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "ท่าไห",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340407,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "นาคำใหญ่",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340408,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "แดงหม้อ",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340409,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "ธาตุน้อย",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340410,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "บ้านไทย",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34320,
    "sub_district_code": 340411,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "บ้านกอก",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34320,
    "sub_district_code": 340412,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "กลางใหญ่",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34320,
    "sub_district_code": 340413,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "โนนรัง",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34320,
    "sub_district_code": 340414,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "ยางขี้นก",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340415,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "ศรีสุข",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340416,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "สหธาตุ",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340417,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "หนองเหล่า",
    "district": "เขื่องใน",
    "province": "อุบลราชธานี",
    "zipcode": 34150,
    "sub_district_code": 340418,
    "district_code": 3404,
    "province_code": 34
  },
  {
    "sub_district": "โขงเจียม",
    "district": "โขงเจียม",
    "province": "อุบลราชธานี",
    "zipcode": 34220,
    "sub_district_code": 340301,
    "district_code": 3403,
    "province_code": 34
  },
  {
    "sub_district": "ห้วยยาง",
    "district": "โขงเจียม",
    "province": "อุบลราชธานี",
    "zipcode": 34220,
    "sub_district_code": 340302,
    "district_code": 3403,
    "province_code": 34
  },
  {
    "sub_district": "นาโพธิ์กลาง",
    "district": "โขงเจียม",
    "province": "อุบลราชธานี",
    "zipcode": 34220,
    "sub_district_code": 340303,
    "district_code": 3403,
    "province_code": 34
  },
  {
    "sub_district": "หนองแสงใหญ่",
    "district": "โขงเจียม",
    "province": "อุบลราชธานี",
    "zipcode": 34220,
    "sub_district_code": 340304,
    "district_code": 3403,
    "province_code": 34
  },
  {
    "sub_district": "ห้วยไผ่",
    "district": "โขงเจียม",
    "province": "อุบลราชธานี",
    "zipcode": 34220,
    "sub_district_code": 340305,
    "district_code": 3403,
    "province_code": 34
  },
  {
    "sub_district": "คำเขื่อนแก้ว",
    "district": "โขงเจียม",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 340398,
    "district_code": 3403,
    "province_code": 34
  },
  {
    "sub_district": "เมืองเดช",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340701,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "นาส่วง",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340702,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "นาเยีย",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340793,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "นาเจริญ",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340704,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "นาเรือง",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340794,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "ทุ่งเทิง",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340706,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "สมสะอาด",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340708,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "กุดประทาย",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340709,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "ตบหู",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340710,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "กลาง",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340711,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "แก้ง",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340712,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "ท่าโพธิ์ศรี",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340713,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "นาดี",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340796,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "โพนงาม",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340720,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "นาเกษม",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340797,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "บัวงาม",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340715,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "คำครั่ง",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340716,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "นากระแซง",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340717,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "กุดเรือ",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340798,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "ป่าโมง",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340721,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "โนนสมบูรณ์",
    "district": "เดชอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 340723,
    "district_code": 3407,
    "province_code": 34
  },
  {
    "sub_district": "ขุหลุ",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341101,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "กระเดียน",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341102,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "เกษม",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341103,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "กุศกร",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341104,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "ขามเปี้ย",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341105,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "คอนสาย",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341106,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "โคกจาน",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341107,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "นาพิน",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341108,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "นาสะไม",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341109,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "โนนกุง",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341110,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "ตระการ",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341111,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "ตากแดด",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341112,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "ไหล่ทุ่ง",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341113,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "เป้า",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341114,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "เซเป็ด",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341115,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "สะพือ",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341116,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "หนองเต่า",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341117,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "ถ้ำแข้",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341118,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "ท่าหลวง",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341119,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "คำเจริญ",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341123,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "ห้วยฝ้ายพัฒนา",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341120,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "กุดยาลวน",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341121,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "บ้านแดง",
    "district": "ตระการพืชผล",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341122,
    "district_code": 3411,
    "province_code": 34
  },
  {
    "sub_district": "โซง",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340901,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "ตาเกา",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340995,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "ยาง",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340903,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "โดมประดิษฐ์",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340904,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "ขี้เหล็ก",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340996,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "สีวิเชียร",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340907,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "บุเปือย",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340906,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "ไพบูลย์",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340997,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340998,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "ยางใหญ่",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340909,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "เก่าขาม",
    "district": "น้ำยืน",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 340911,
    "district_code": 3409,
    "province_code": 34
  },
  {
    "sub_district": "โพนงาม",
    "district": "บุณฑริก",
    "province": "อุบลราชธานี",
    "zipcode": 34230,
    "sub_district_code": 341001,
    "district_code": 3410,
    "province_code": 34
  },
  {
    "sub_district": "ห้วยข่า",
    "district": "บุณฑริก",
    "province": "อุบลราชธานี",
    "zipcode": 34230,
    "sub_district_code": 341002,
    "district_code": 3410,
    "province_code": 34
  },
  {
    "sub_district": "คอแลน",
    "district": "บุณฑริก",
    "province": "อุบลราชธานี",
    "zipcode": 34230,
    "sub_district_code": 341003,
    "district_code": 3410,
    "province_code": 34
  },
  {
    "sub_district": "นาโพธิ์",
    "district": "บุณฑริก",
    "province": "อุบลราชธานี",
    "zipcode": 34230,
    "sub_district_code": 341004,
    "district_code": 3410,
    "province_code": 34
  },
  {
    "sub_district": "หนองสะโน",
    "district": "บุณฑริก",
    "province": "อุบลราชธานี",
    "zipcode": 34230,
    "sub_district_code": 341005,
    "district_code": 3410,
    "province_code": 34
  },
  {
    "sub_district": "โนนค้อ",
    "district": "บุณฑริก",
    "province": "อุบลราชธานี",
    "zipcode": 34230,
    "sub_district_code": 341006,
    "district_code": 3410,
    "province_code": 34
  },
  {
    "sub_district": "บัวงาม",
    "district": "บุณฑริก",
    "province": "อุบลราชธานี",
    "zipcode": 34230,
    "sub_district_code": 341007,
    "district_code": 3410,
    "province_code": 34
  },
  {
    "sub_district": "บ้านแมค",
    "district": "บุณฑริก",
    "province": "อุบลราชธานี",
    "zipcode": 34230,
    "sub_district_code": 341008,
    "district_code": 3410,
    "province_code": 34
  },
  {
    "sub_district": "บ้านแมด",
    "district": "บุณฑริก",
    "province": "อุบลราชธานี",
    "zipcode": 34230,
    "sub_district_code": 341008,
    "district_code": 3410,
    "province_code": 34
  },
  {
    "sub_district": "พิบูล",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341901,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "กุดชมภู",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341902,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "คันไร่",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 341992,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "ดอนจิก",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341904,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "ทรายมูล",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341905,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "นาโพธิ์",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341906,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "โนนกลาง",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341907,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "ฝางคำ",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 341993,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "โพธิ์ไทร",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341909,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "โพธิ์ศรี",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341910,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "ระเว",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341911,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "ไร่ใต้",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341912,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "หนองบัวฮี",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341913,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "อ่างศิลา",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341914,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "ช่องเม็ก",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 341996,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "โนนก่อ",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 341997,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "กุดยาลวน",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34130,
    "sub_district_code": 341991,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "โนนกาหลง",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341918,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "บ้านแขม",
    "district": "พิบูลมังสาหาร",
    "province": "อุบลราชธานี",
    "zipcode": 34110,
    "sub_district_code": 341919,
    "district_code": 3419,
    "province_code": 34
  },
  {
    "sub_district": "ม่วงสามสิบ",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341401,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "เหล่าบก",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341402,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "ดุมใหญ่",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341403,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "หนองช้างใหญ่",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341404,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "หนองเมือง",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341405,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "เตย",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341406,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "ยางสักกระโพหลุ่ม",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341407,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "หนองไข่นก",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341408,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "หนองเหล่า",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341409,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "หนองฮาง",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341410,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "ยางโยภาพ",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341411,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "ไผ่ใหญ่",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341412,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "นาเลิง",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341413,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "โพนแพง",
    "district": "ม่วงสามสิบ",
    "province": "อุบลราชธานี",
    "zipcode": 34140,
    "sub_district_code": 341414,
    "district_code": 3414,
    "province_code": 34
  },
  {
    "sub_district": "วารินชำราบ",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341501,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "ธาตุ",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341502,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341588,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "ท่าลาด",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34310,
    "sub_district_code": 341504,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "โนนโหนน",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341505,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "คูเมือง",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341507,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "สระสมิง",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341508,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "คำน้ำแซบ",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341510,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "บุ่งหวาย",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34310,
    "sub_district_code": 341511,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "สำโรง",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34330,
    "sub_district_code": 341589,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "สว่าง",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341590,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "คำขวาง",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341515,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "โพธิ์ใหญ่",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341516,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "แสนสุข",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341518,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "หนองกินเพล",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341520,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "โนนผึ้ง",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341521,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "โนนกาเล็น",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 341594,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "เมืองศรีไค",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341522,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "โคกสว่าง",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 341596,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "ห้วยขะยุง",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34310,
    "sub_district_code": 341524,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "แก่งโดม",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341597,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "ในเมือง",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 341598,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "บุ่งไหม",
    "district": "วารินชำราบ",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 341526,
    "district_code": 3415,
    "province_code": 34
  },
  {
    "sub_district": "นาคำ",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340201,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "แก้งกอก",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340202,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "เอือดใหญ่",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340203,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "วาริน",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340204,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "ลาดควาย",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340205,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "สงยาง",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340206,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "ตะบ่าย",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340207,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "คำไหล",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340208,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "หนามแท่ง",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340209,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "นาเลิน",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340210,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "ดอนใหญ่",
    "district": "ศรีเมืองใหม่",
    "province": "อุบลราชธานี",
    "zipcode": 34250,
    "sub_district_code": 340211,
    "district_code": 3402,
    "province_code": 34
  },
  {
    "sub_district": "นาจะหลวย",
    "district": "นาจะหลวย",
    "province": "อุบลราชธานี",
    "zipcode": 34280,
    "sub_district_code": 340801,
    "district_code": 3408,
    "province_code": 34
  },
  {
    "sub_district": "โนนสมบูรณ์",
    "district": "นาจะหลวย",
    "province": "อุบลราชธานี",
    "zipcode": 34280,
    "sub_district_code": 340802,
    "district_code": 3408,
    "province_code": 34
  },
  {
    "sub_district": "พรสวรรค์",
    "district": "นาจะหลวย",
    "province": "อุบลราชธานี",
    "zipcode": 34280,
    "sub_district_code": 340803,
    "district_code": 3408,
    "province_code": 34
  },
  {
    "sub_district": "บ้านตูม",
    "district": "นาจะหลวย",
    "province": "อุบลราชธานี",
    "zipcode": 34280,
    "sub_district_code": 340804,
    "district_code": 3408,
    "province_code": 34
  },
  {
    "sub_district": "โสกแสง",
    "district": "นาจะหลวย",
    "province": "อุบลราชธานี",
    "zipcode": 34280,
    "sub_district_code": 340805,
    "district_code": 3408,
    "province_code": 34
  },
  {
    "sub_district": "โนนสวรรค์",
    "district": "นาจะหลวย",
    "province": "อุบลราชธานี",
    "zipcode": 34280,
    "sub_district_code": 340806,
    "district_code": 3408,
    "province_code": 34
  },
  {
    "sub_district": "ตาลสุม",
    "district": "ตาลสุม",
    "province": "อุบลราชธานี",
    "zipcode": 34330,
    "sub_district_code": 342001,
    "district_code": 3420,
    "province_code": 34
  },
  {
    "sub_district": "สำโรง",
    "district": "ตาลสุม",
    "province": "อุบลราชธานี",
    "zipcode": 34330,
    "sub_district_code": 342002,
    "district_code": 3420,
    "province_code": 34
  },
  {
    "sub_district": "จิกเทิง",
    "district": "ตาลสุม",
    "province": "อุบลราชธานี",
    "zipcode": 34330,
    "sub_district_code": 342003,
    "district_code": 3420,
    "province_code": 34
  },
  {
    "sub_district": "หนองกุง",
    "district": "ตาลสุม",
    "province": "อุบลราชธานี",
    "zipcode": 34330,
    "sub_district_code": 342004,
    "district_code": 3420,
    "province_code": 34
  },
  {
    "sub_district": "นาคาย",
    "district": "ตาลสุม",
    "province": "อุบลราชธานี",
    "zipcode": 34330,
    "sub_district_code": 342005,
    "district_code": 3420,
    "province_code": 34
  },
  {
    "sub_district": "คำหว้า",
    "district": "ตาลสุม",
    "province": "อุบลราชธานี",
    "zipcode": 34330,
    "sub_district_code": 342006,
    "district_code": 3420,
    "province_code": 34
  },
  {
    "sub_district": "โพธิ์ไทร",
    "district": "โพธิ์ไทร",
    "province": "อุบลราชธานี",
    "zipcode": 34340,
    "sub_district_code": 342101,
    "district_code": 3421,
    "province_code": 34
  },
  {
    "sub_district": "ม่วงใหญ่",
    "district": "โพธิ์ไทร",
    "province": "อุบลราชธานี",
    "zipcode": 34340,
    "sub_district_code": 342102,
    "district_code": 3421,
    "province_code": 34
  },
  {
    "sub_district": "สำโรง",
    "district": "โพธิ์ไทร",
    "province": "อุบลราชธานี",
    "zipcode": 34340,
    "sub_district_code": 342103,
    "district_code": 3421,
    "province_code": 34
  },
  {
    "sub_district": "สองคอน",
    "district": "โพธิ์ไทร",
    "province": "อุบลราชธานี",
    "zipcode": 34340,
    "sub_district_code": 342104,
    "district_code": 3421,
    "province_code": 34
  },
  {
    "sub_district": "สารภี",
    "district": "โพธิ์ไทร",
    "province": "อุบลราชธานี",
    "zipcode": 34340,
    "sub_district_code": 342105,
    "district_code": 3421,
    "province_code": 34
  },
  {
    "sub_district": "เหล่างาม",
    "district": "โพธิ์ไทร",
    "province": "อุบลราชธานี",
    "zipcode": 34340,
    "sub_district_code": 342106,
    "district_code": 3421,
    "province_code": 34
  },
  {
    "sub_district": "สำโรง",
    "district": "สำโรง",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 342201,
    "district_code": 3422,
    "province_code": 34
  },
  {
    "sub_district": "โคกก่อง",
    "district": "สำโรง",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 342202,
    "district_code": 3422,
    "province_code": 34
  },
  {
    "sub_district": "หนองไฮ",
    "district": "สำโรง",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 342203,
    "district_code": 3422,
    "province_code": 34
  },
  {
    "sub_district": "ค้อน้อย",
    "district": "สำโรง",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 342204,
    "district_code": 3422,
    "province_code": 34
  },
  {
    "sub_district": "โนนกาเล็น",
    "district": "สำโรง",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 342205,
    "district_code": 3422,
    "province_code": 34
  },
  {
    "sub_district": "โคกสว่าง",
    "district": "สำโรง",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 342206,
    "district_code": 3422,
    "province_code": 34
  },
  {
    "sub_district": "โนนกลาง",
    "district": "สำโรง",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 342207,
    "district_code": 3422,
    "province_code": 34
  },
  {
    "sub_district": "ขามป้อม",
    "district": "สำโรง",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 342209,
    "district_code": 3422,
    "province_code": 34
  },
  {
    "sub_district": "บอน",
    "district": "สำโรง",
    "province": "อุบลราชธานี",
    "zipcode": 34360,
    "sub_district_code": 342208,
    "district_code": 3422,
    "province_code": 34
  },
  {
    "sub_district": "เหล่าแดง",
    "district": "ดอนมดแดง",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 342402,
    "district_code": 3424,
    "province_code": 34
  },
  {
    "sub_district": "ดอนมดแดง",
    "district": "ดอนมดแดง",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 342401,
    "district_code": 3424,
    "province_code": 34
  },
  {
    "sub_district": "ท่าเมือง",
    "district": "ดอนมดแดง",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 342403,
    "district_code": 3424,
    "province_code": 34
  },
  {
    "sub_district": "คำไฮใหญ่",
    "district": "ดอนมดแดง",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 342404,
    "district_code": 3424,
    "province_code": 34
  },
  {
    "sub_district": "นิคมสร้างตนเองลำโดมน้อย",
    "district": "สิรินธร",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 342504,
    "district_code": 3425,
    "province_code": 34
  },
  {
    "sub_district": "ฝางคำ",
    "district": "สิรินธร",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 342505,
    "district_code": 3425,
    "province_code": 34
  },
  {
    "sub_district": "ช่องเม็ก",
    "district": "สิรินธร",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 342502,
    "district_code": 3425,
    "province_code": 34
  },
  {
    "sub_district": "คันไร่",
    "district": "สิรินธร",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 342501,
    "district_code": 3425,
    "province_code": 34
  },
  {
    "sub_district": "คำเขื่อนแก้ว",
    "district": "สิรินธร",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 342506,
    "district_code": 3425,
    "province_code": 34
  },
  {
    "sub_district": "โนนก่อ",
    "district": "สิรินธร",
    "province": "อุบลราชธานี",
    "zipcode": 34350,
    "sub_district_code": 342503,
    "district_code": 3425,
    "province_code": 34
  },
  {
    "sub_district": "ทุ่งเทิง",
    "district": "ทุ่งศรีอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 342698,
    "district_code": 3426,
    "province_code": 34
  },
  {
    "sub_district": "โคกชำแระ",
    "district": "ทุ่งศรีอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 342605,
    "district_code": 3426,
    "province_code": 34
  },
  {
    "sub_district": "หนองอ้ม",
    "district": "ทุ่งศรีอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 342602,
    "district_code": 3426,
    "province_code": 34
  },
  {
    "sub_district": "กุดเรือ",
    "district": "ทุ่งศรีอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 342604,
    "district_code": 3426,
    "province_code": 34
  },
  {
    "sub_district": "นาเกษม",
    "district": "ทุ่งศรีอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 342603,
    "district_code": 3426,
    "province_code": 34
  },
  {
    "sub_district": "นาห่อม",
    "district": "ทุ่งศรีอุดม",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 342606,
    "district_code": 3426,
    "province_code": 34
  },
  {
    "sub_district": "หนองบก",
    "district": "เหล่าเสือโก้ก",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 343104,
    "district_code": 3431,
    "province_code": 34
  },
  {
    "sub_district": "แพงใหญ่",
    "district": "เหล่าเสือโก้ก",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 343103,
    "district_code": 3431,
    "province_code": 34
  },
  {
    "sub_district": "เหล่าเสือโก้ก",
    "district": "เหล่าเสือโก้ก",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 343101,
    "district_code": 3431,
    "province_code": 34
  },
  {
    "sub_district": "โพนเมือง",
    "district": "เหล่าเสือโก้ก",
    "province": "อุบลราชธานี",
    "zipcode": 34000,
    "sub_district_code": 343102,
    "district_code": 3431,
    "province_code": 34
  },
  {
    "sub_district": "แก่งโดม",
    "district": "สว่างวีระวงศ์",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 343201,
    "district_code": 3432,
    "province_code": 34
  },
  {
    "sub_district": "สว่าง",
    "district": "สว่างวีระวงศ์",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 343204,
    "district_code": 3432,
    "province_code": 34
  },
  {
    "sub_district": "บุ่งมะแลง",
    "district": "สว่างวีระวงศ์",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 343203,
    "district_code": 3432,
    "province_code": 34
  },
  {
    "sub_district": "ท่าช้าง",
    "district": "สว่างวีระวงศ์",
    "province": "อุบลราชธานี",
    "zipcode": 34190,
    "sub_district_code": 343202,
    "district_code": 3432,
    "province_code": 34
  },
  {
    "sub_district": "นาตาล",
    "district": "น้ำขุ่น",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 343398,
    "district_code": 3433,
    "province_code": 34
  },
  {
    "sub_district": "ตาเกา",
    "district": "น้ำขุ่น",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 343301,
    "district_code": 3433,
    "province_code": 34
  },
  {
    "sub_district": "ไพบูลย์",
    "district": "น้ำขุ่น",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 343302,
    "district_code": 3433,
    "province_code": 34
  },
  {
    "sub_district": "ขี้เหล็ก",
    "district": "น้ำขุ่น",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 343303,
    "district_code": 3433,
    "province_code": 34
  },
  {
    "sub_district": "โคกสะอาด",
    "district": "น้ำขุ่น",
    "province": "อุบลราชธานี",
    "zipcode": 34260,
    "sub_district_code": 343304,
    "district_code": 3433,
    "province_code": 34
  },
  {
    "sub_district": "นาเยีย",
    "district": "นาเยีย",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 342901,
    "district_code": 3429,
    "province_code": 34
  },
  {
    "sub_district": "นาดี",
    "district": "นาเยีย",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 342902,
    "district_code": 3429,
    "province_code": 34
  },
  {
    "sub_district": "นาเรือง",
    "district": "นาเยีย",
    "province": "อุบลราชธานี",
    "zipcode": 34160,
    "sub_district_code": 342903,
    "district_code": 3429,
    "province_code": 34
  },
  {
    "sub_district": "นาตาล",
    "district": "นาตาล",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 343001,
    "district_code": 3430,
    "province_code": 34
  },
  {
    "sub_district": "พะลาน",
    "district": "นาตาล",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 343002,
    "district_code": 3430,
    "province_code": 34
  },
  {
    "sub_district": "กองโพน",
    "district": "นาตาล",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 343003,
    "district_code": 3430,
    "province_code": 34
  },
  {
    "sub_district": "พังเคน",
    "district": "นาตาล",
    "province": "อุบลราชธานี",
    "zipcode": 34170,
    "sub_district_code": 343004,
    "district_code": 3430,
    "province_code": 34
  },
  {
    "sub_district": "เวียง",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560101,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "แม่ต๋ำ",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560102,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "ดงเจน",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560195,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "แม่นาเรือ",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560104,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "บ้านตุ่น",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560105,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "บ้านต๊ำ",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560106,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "บ้านต๋อม",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560107,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "ห้วยแก้ว",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560196,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "แม่กา",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560110,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "บ้านใหม่",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560111,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "จำป่าหวาย",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560112,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "แม่ปืม",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560108,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "ท่าวังทอง",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560113,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "แม่ใส",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560114,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "บ้านสาง",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560115,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "ท่าจำปี",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560116,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "สันป่าม่วง",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560118,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "แม่อิง",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560103,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "ป่าแฝก",
    "district": "เมืองพะเยา",
    "province": "พะเยา",
    "zipcode": 56130,
    "sub_district_code": 560198,
    "district_code": 5601,
    "province_code": 56
  },
  {
    "sub_district": "ห้วยข้าวก่ำ",
    "district": "จุน",
    "province": "พะเยา",
    "zipcode": 56150,
    "sub_district_code": 560201,
    "district_code": 5602,
    "province_code": 56
  },
  {
    "sub_district": "จุน",
    "district": "จุน",
    "province": "พะเยา",
    "zipcode": 56150,
    "sub_district_code": 560202,
    "district_code": 5602,
    "province_code": 56
  },
  {
    "sub_district": "ลอ",
    "district": "จุน",
    "province": "พะเยา",
    "zipcode": 56150,
    "sub_district_code": 560203,
    "district_code": 5602,
    "province_code": 56
  },
  {
    "sub_district": "หงส์หิน",
    "district": "จุน",
    "province": "พะเยา",
    "zipcode": 56150,
    "sub_district_code": 560204,
    "district_code": 5602,
    "province_code": 56
  },
  {
    "sub_district": "ทุ่งรวงทอง",
    "district": "จุน",
    "province": "พะเยา",
    "zipcode": 56150,
    "sub_district_code": 560205,
    "district_code": 5602,
    "province_code": 56
  },
  {
    "sub_district": "ห้วยยางขาม",
    "district": "จุน",
    "province": "พะเยา",
    "zipcode": 56150,
    "sub_district_code": 560206,
    "district_code": 5602,
    "province_code": 56
  },
  {
    "sub_district": "พระธาตุขิงแกง",
    "district": "จุน",
    "province": "พะเยา",
    "zipcode": 56150,
    "sub_district_code": 560207,
    "district_code": 5602,
    "province_code": 56
  },
  {
    "sub_district": "หย่วน",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560301,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "ทุ่งกล้วย",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560393,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "สบบง",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560394,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "เชียงแรง",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560395,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "ภูซาง",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560396,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "น้ำแวน",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560306,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "เวียง",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560307,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "ฝายกวาง",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560308,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "เจดีย์คำ",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560309,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "ร่มเย็น",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560310,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "เชียงบาน",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560311,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "แม่ลาว",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560312,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "อ่างทอง",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560313,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "ทุ่งรวงทอง",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56150,
    "sub_district_code": 560398,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "ทุ่งผาสุข",
    "district": "เชียงคำ",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560314,
    "district_code": 5603,
    "province_code": 56
  },
  {
    "sub_district": "เชียงม่วน",
    "district": "เชียงม่วน",
    "province": "พะเยา",
    "zipcode": 56160,
    "sub_district_code": 560401,
    "district_code": 5604,
    "province_code": 56
  },
  {
    "sub_district": "บ้านมาง",
    "district": "เชียงม่วน",
    "province": "พะเยา",
    "zipcode": 56160,
    "sub_district_code": 560402,
    "district_code": 5604,
    "province_code": 56
  },
  {
    "sub_district": "สระ",
    "district": "เชียงม่วน",
    "province": "พะเยา",
    "zipcode": 56160,
    "sub_district_code": 560403,
    "district_code": 5604,
    "province_code": 56
  },
  {
    "sub_district": "ดอกคำใต้",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560501,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "ดอนศรีชุม",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560502,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "บ้านถ้ำ",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560503,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "บ้านปิน",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560504,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "ห้วยลาน",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560505,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "สันโค้ง",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560506,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "ป่าซาง",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560507,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "หนองหล่ม",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560508,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "ดงสุวรรณ",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560509,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "บุญเกิด",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560510,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "สว่างอารมณ์",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560511,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "คือเวียง",
    "district": "ดอกคำใต้",
    "province": "พะเยา",
    "zipcode": 56120,
    "sub_district_code": 560512,
    "district_code": 5605,
    "province_code": 56
  },
  {
    "sub_district": "ปง",
    "district": "ปง",
    "province": "พะเยา",
    "zipcode": 56140,
    "sub_district_code": 560601,
    "district_code": 5606,
    "province_code": 56
  },
  {
    "sub_district": "ควร",
    "district": "ปง",
    "province": "พะเยา",
    "zipcode": 56140,
    "sub_district_code": 560602,
    "district_code": 5606,
    "province_code": 56
  },
  {
    "sub_district": "ออย",
    "district": "ปง",
    "province": "พะเยา",
    "zipcode": 56140,
    "sub_district_code": 560603,
    "district_code": 5606,
    "province_code": 56
  },
  {
    "sub_district": "งิม",
    "district": "ปง",
    "province": "พะเยา",
    "zipcode": 56140,
    "sub_district_code": 560604,
    "district_code": 5606,
    "province_code": 56
  },
  {
    "sub_district": "ผาช้างน้อย",
    "district": "ปง",
    "province": "พะเยา",
    "zipcode": 56140,
    "sub_district_code": 560605,
    "district_code": 5606,
    "province_code": 56
  },
  {
    "sub_district": "นาปรัง",
    "district": "ปง",
    "province": "พะเยา",
    "zipcode": 56140,
    "sub_district_code": 560606,
    "district_code": 5606,
    "province_code": 56
  },
  {
    "sub_district": "ขุนควร",
    "district": "ปง",
    "province": "พะเยา",
    "zipcode": 56140,
    "sub_district_code": 560607,
    "district_code": 5606,
    "province_code": 56
  },
  {
    "sub_district": "แม่ใจ",
    "district": "แม่ใจ",
    "province": "พะเยา",
    "zipcode": 56130,
    "sub_district_code": 560701,
    "district_code": 5607,
    "province_code": 56
  },
  {
    "sub_district": "ศรีถ้อย",
    "district": "แม่ใจ",
    "province": "พะเยา",
    "zipcode": 56130,
    "sub_district_code": 560702,
    "district_code": 5607,
    "province_code": 56
  },
  {
    "sub_district": "แม่สุก",
    "district": "แม่ใจ",
    "province": "พะเยา",
    "zipcode": 56130,
    "sub_district_code": 560703,
    "district_code": 5607,
    "province_code": 56
  },
  {
    "sub_district": "ป่าแฝก",
    "district": "แม่ใจ",
    "province": "พะเยา",
    "zipcode": 56130,
    "sub_district_code": 560704,
    "district_code": 5607,
    "province_code": 56
  },
  {
    "sub_district": "บ้านเหล่า",
    "district": "แม่ใจ",
    "province": "พะเยา",
    "zipcode": 56130,
    "sub_district_code": 560705,
    "district_code": 5607,
    "province_code": 56
  },
  {
    "sub_district": "เจริญราษฎร์",
    "district": "แม่ใจ",
    "province": "พะเยา",
    "zipcode": 56130,
    "sub_district_code": 560706,
    "district_code": 5607,
    "province_code": 56
  },
  {
    "sub_district": "ภูซาง",
    "district": "ภูซาง",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560801,
    "district_code": 5608,
    "province_code": 56
  },
  {
    "sub_district": "เชียงแรง",
    "district": "ภูซาง",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560804,
    "district_code": 5608,
    "province_code": 56
  },
  {
    "sub_district": "สบบง",
    "district": "ภูซาง",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560805,
    "district_code": 5608,
    "province_code": 56
  },
  {
    "sub_district": "ป่าสัก",
    "district": "ภูซาง",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560802,
    "district_code": 5608,
    "province_code": 56
  },
  {
    "sub_district": "ทุ่งกล้วย",
    "district": "ภูซาง",
    "province": "พะเยา",
    "zipcode": 56110,
    "sub_district_code": 560803,
    "district_code": 5608,
    "province_code": 56
  },
  {
    "sub_district": "ห้วยแก้ว",
    "district": "ภูกามยาว",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560901,
    "district_code": 5609,
    "province_code": 56
  },
  {
    "sub_district": "ดงเจน",
    "district": "ภูกามยาว",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560902,
    "district_code": 5609,
    "province_code": 56
  },
  {
    "sub_district": "แม่อิง",
    "district": "ภูกามยาว",
    "province": "พะเยา",
    "zipcode": 56000,
    "sub_district_code": 560903,
    "district_code": 5609,
    "province_code": 56
  },
  {
    "sub_district": "มุกดาหาร",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490101,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "ศรีบุญเรือง",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490102,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "บ้านโคก",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490103,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "บางทรายใหญ่",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490104,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "โพนทราย",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490105,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "ผึ่งแดด",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490106,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "นาโสก",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490107,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "นาสีนวน",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490108,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "คำป่าหลาย",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490109,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "คำอาฮวน",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490110,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "ดงเย็น",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490111,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "ดงมอน",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490112,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "กุดแข้",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490113,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "โพนงาม",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49110,
    "sub_district_code": 490197,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "บางทรายน้อย",
    "district": "เมืองมุกดาหาร",
    "province": "มุกดาหาร",
    "zipcode": 49150,
    "sub_district_code": 490198,
    "district_code": 4901,
    "province_code": 49
  },
  {
    "sub_district": "น้ำเที่ยง",
    "district": "คำชะอี",
    "province": "มุกดาหาร",
    "zipcode": 49110,
    "sub_district_code": 490514,
    "district_code": 4905,
    "province_code": 49
  },
  {
    "sub_district": "คำชะอี",
    "district": "คำชะอี",
    "province": "มุกดาหาร",
    "zipcode": 49110,
    "sub_district_code": 490504,
    "district_code": 4905,
    "province_code": 49
  },
  {
    "sub_district": "บ้านเหล่า",
    "district": "คำชะอี",
    "province": "มุกดาหาร",
    "zipcode": 49110,
    "sub_district_code": 490507,
    "district_code": 4905,
    "province_code": 49
  },
  {
    "sub_district": "หนองเอี่ยน",
    "district": "คำชะอี",
    "province": "มุกดาหาร",
    "zipcode": 49110,
    "sub_district_code": 490505,
    "district_code": 4905,
    "province_code": 49
  },
  {
    "sub_district": "บ้านค้อ",
    "district": "คำชะอี",
    "province": "มุกดาหาร",
    "zipcode": 49110,
    "sub_district_code": 490506,
    "district_code": 4905,
    "province_code": 49
  },
  {
    "sub_district": "โพนงาม",
    "district": "คำชะอี",
    "province": "มุกดาหาร",
    "zipcode": 49110,
    "sub_district_code": 490508,
    "district_code": 4905,
    "province_code": 49
  },
  {
    "sub_district": "เหล่าสร้างถ่อ",
    "district": "คำชะอี",
    "province": "มุกดาหาร",
    "zipcode": 49110,
    "sub_district_code": 490511,
    "district_code": 4905,
    "province_code": 49
  },
  {
    "sub_district": "คำบก",
    "district": "คำชะอี",
    "province": "มุกดาหาร",
    "zipcode": 49110,
    "sub_district_code": 490512,
    "district_code": 4905,
    "province_code": 49
  },
  {
    "sub_district": "บ้านซ่ง",
    "district": "คำชะอี",
    "province": "มุกดาหาร",
    "zipcode": 49110,
    "sub_district_code": 490503,
    "district_code": 4905,
    "province_code": 49
  },
  {
    "sub_district": "ผึ่งแดด",
    "district": "คำชะอี",
    "province": "มุกดาหาร",
    "zipcode": 49000,
    "sub_district_code": 490598,
    "district_code": 4905,
    "province_code": 49
  },
  {
    "sub_district": "ดงหลวง",
    "district": "ดงหลวง",
    "province": "มุกดาหาร",
    "zipcode": 49140,
    "sub_district_code": 490401,
    "district_code": 4904,
    "province_code": 49
  },
  {
    "sub_district": "หนองบัว",
    "district": "ดงหลวง",
    "province": "มุกดาหาร",
    "zipcode": 49140,
    "sub_district_code": 490402,
    "district_code": 4904,
    "province_code": 49
  },
  {
    "sub_district": "กกตูม",
    "district": "ดงหลวง",
    "province": "มุกดาหาร",
    "zipcode": 49140,
    "sub_district_code": 490403,
    "district_code": 4904,
    "province_code": 49
  },
  {
    "sub_district": "หนองแคน",
    "district": "ดงหลวง",
    "province": "มุกดาหาร",
    "zipcode": 49140,
    "sub_district_code": 490404,
    "district_code": 4904,
    "province_code": 49
  },
  {
    "sub_district": "ชะโนดน้อย",
    "district": "ดงหลวง",
    "province": "มุกดาหาร",
    "zipcode": 49140,
    "sub_district_code": 490405,
    "district_code": 4904,
    "province_code": 49
  },
  {
    "sub_district": "พังแดง",
    "district": "ดงหลวง",
    "province": "มุกดาหาร",
    "zipcode": 49140,
    "sub_district_code": 490406,
    "district_code": 4904,
    "province_code": 49
  },
  {
    "sub_district": "ดอนตาล",
    "district": "ดอนตาล",
    "province": "มุกดาหาร",
    "zipcode": 49120,
    "sub_district_code": 490301,
    "district_code": 4903,
    "province_code": 49
  },
  {
    "sub_district": "โพธิ์ไทร",
    "district": "ดอนตาล",
    "province": "มุกดาหาร",
    "zipcode": 49120,
    "sub_district_code": 490302,
    "district_code": 4903,
    "province_code": 49
  },
  {
    "sub_district": "ป่าไร่",
    "district": "ดอนตาล",
    "province": "มุกดาหาร",
    "zipcode": 49120,
    "sub_district_code": 490303,
    "district_code": 4903,
    "province_code": 49
  },
  {
    "sub_district": "เหล่าหมี",
    "district": "ดอนตาล",
    "province": "มุกดาหาร",
    "zipcode": 49120,
    "sub_district_code": 490304,
    "district_code": 4903,
    "province_code": 49
  },
  {
    "sub_district": "บ้านบาก",
    "district": "ดอนตาล",
    "province": "มุกดาหาร",
    "zipcode": 49120,
    "sub_district_code": 490305,
    "district_code": 4903,
    "province_code": 49
  },
  {
    "sub_district": "นาสะเม็ง",
    "district": "ดอนตาล",
    "province": "มุกดาหาร",
    "zipcode": 49120,
    "sub_district_code": 490306,
    "district_code": 4903,
    "province_code": 49
  },
  {
    "sub_district": "บ้านแก้ง",
    "district": "ดอนตาล",
    "province": "มุกดาหาร",
    "zipcode": 49120,
    "sub_district_code": 490307,
    "district_code": 4903,
    "province_code": 49
  },
  {
    "sub_district": "นิคมคำสร้อย",
    "district": "นิคมคำสร้อย",
    "province": "มุกดาหาร",
    "zipcode": 49130,
    "sub_district_code": 490201,
    "district_code": 4902,
    "province_code": 49
  },
  {
    "sub_district": "นากอก",
    "district": "นิคมคำสร้อย",
    "province": "มุกดาหาร",
    "zipcode": 49130,
    "sub_district_code": 490202,
    "district_code": 4902,
    "province_code": 49
  },
  {
    "sub_district": "หนองแวง",
    "district": "นิคมคำสร้อย",
    "province": "มุกดาหาร",
    "zipcode": 49130,
    "sub_district_code": 490203,
    "district_code": 4902,
    "province_code": 49
  },
  {
    "sub_district": "กกแดง",
    "district": "นิคมคำสร้อย",
    "province": "มุกดาหาร",
    "zipcode": 49130,
    "sub_district_code": 490204,
    "district_code": 4902,
    "province_code": 49
  },
  {
    "sub_district": "นาอุดม",
    "district": "นิคมคำสร้อย",
    "province": "มุกดาหาร",
    "zipcode": 49130,
    "sub_district_code": 490205,
    "district_code": 4902,
    "province_code": 49
  },
  {
    "sub_district": "โชคชัย",
    "district": "นิคมคำสร้อย",
    "province": "มุกดาหาร",
    "zipcode": 49130,
    "sub_district_code": 490206,
    "district_code": 4902,
    "province_code": 49
  },
  {
    "sub_district": "ร่มเกล้า",
    "district": "นิคมคำสร้อย",
    "province": "มุกดาหาร",
    "zipcode": 49130,
    "sub_district_code": 490207,
    "district_code": 4902,
    "province_code": 49
  },
  {
    "sub_district": "หนองสูง",
    "district": "หนองสูง",
    "province": "มุกดาหาร",
    "zipcode": 49160,
    "sub_district_code": 490701,
    "district_code": 4907,
    "province_code": 49
  },
  {
    "sub_district": "โนนยาง",
    "district": "หนองสูง",
    "province": "มุกดาหาร",
    "zipcode": 49160,
    "sub_district_code": 490702,
    "district_code": 4907,
    "province_code": 49
  },
  {
    "sub_district": "ภูวง",
    "district": "หนองสูง",
    "province": "มุกดาหาร",
    "zipcode": 49160,
    "sub_district_code": 490703,
    "district_code": 4907,
    "province_code": 49
  },
  {
    "sub_district": "บ้านเป้า",
    "district": "หนองสูง",
    "province": "มุกดาหาร",
    "zipcode": 49160,
    "sub_district_code": 490704,
    "district_code": 4907,
    "province_code": 49
  },
  {
    "sub_district": "หนองสูงใต้",
    "district": "หนองสูง",
    "province": "มุกดาหาร",
    "zipcode": 49160,
    "sub_district_code": 490705,
    "district_code": 4907,
    "province_code": 49
  },
  {
    "sub_district": "หนองสูงเหนือ",
    "district": "หนองสูง",
    "province": "มุกดาหาร",
    "zipcode": 49160,
    "sub_district_code": 490706,
    "district_code": 4907,
    "province_code": 49
  },
  {
    "sub_district": "หว้านใหญ่",
    "district": "หว้านใหญ่",
    "province": "มุกดาหาร",
    "zipcode": 49150,
    "sub_district_code": 490601,
    "district_code": 4906,
    "province_code": 49
  },
  {
    "sub_district": "ป่งขาม",
    "district": "หว้านใหญ่",
    "province": "มุกดาหาร",
    "zipcode": 49150,
    "sub_district_code": 490602,
    "district_code": 4906,
    "province_code": 49
  },
  {
    "sub_district": "บางทรายน้อย",
    "district": "หว้านใหญ่",
    "province": "มุกดาหาร",
    "zipcode": 49150,
    "sub_district_code": 490603,
    "district_code": 4906,
    "province_code": 49
  },
  {
    "sub_district": "ชะโนด",
    "district": "หว้านใหญ่",
    "province": "มุกดาหาร",
    "zipcode": 49150,
    "sub_district_code": 490604,
    "district_code": 4906,
    "province_code": 49
  },
  {
    "sub_district": "ดงหมู",
    "district": "หว้านใหญ่",
    "province": "มุกดาหาร",
    "zipcode": 49150,
    "sub_district_code": 490605,
    "district_code": 4906,
    "province_code": 49
  },
  {
    "sub_district": "สระแก้ว",
    "district": "เมืองสระแก้ว",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270101,
    "district_code": 2701,
    "province_code": 27
  },
  {
    "sub_district": "เขาฉกรรจ์",
    "district": "เมืองสระแก้ว",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270197,
    "district_code": 2701,
    "province_code": 27
  },
  {
    "sub_district": "ศาลาลำดวน",
    "district": "เมืองสระแก้ว",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270103,
    "district_code": 2701,
    "province_code": 27
  },
  {
    "sub_district": "ท่าเกษม",
    "district": "เมืองสระแก้ว",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270106,
    "district_code": 2701,
    "province_code": 27
  },
  {
    "sub_district": "บ้านแก้ง",
    "district": "เมืองสระแก้ว",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270102,
    "district_code": 2701,
    "province_code": 27
  },
  {
    "sub_district": "ท่าแยก",
    "district": "เมืองสระแก้ว",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270105,
    "district_code": 2701,
    "province_code": 27
  },
  {
    "sub_district": "สระขวัญ",
    "district": "เมืองสระแก้ว",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270108,
    "district_code": 2701,
    "province_code": 27
  },
  {
    "sub_district": "บ้านใหม่หนองไทร",
    "district": "เมืองสระแก้ว",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270198,
    "district_code": 2701,
    "province_code": 27
  },
  {
    "sub_district": "หนองบอน",
    "district": "เมืองสระแก้ว",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270111,
    "district_code": 2701,
    "province_code": 27
  },
  {
    "sub_district": "โคกปี่ฆ้อง",
    "district": "เมืองสระแก้ว",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270104,
    "district_code": 2701,
    "province_code": 27
  },
  {
    "sub_district": "คลองหาด",
    "district": "คลองหาด",
    "province": "สระแก้ว",
    "zipcode": 27260,
    "sub_district_code": 270201,
    "district_code": 2702,
    "province_code": 27
  },
  {
    "sub_district": "คลองไก่เถื่อน",
    "district": "คลองหาด",
    "province": "สระแก้ว",
    "zipcode": 27260,
    "sub_district_code": 270205,
    "district_code": 2702,
    "province_code": 27
  },
  {
    "sub_district": "เบญจขร",
    "district": "คลองหาด",
    "province": "สระแก้ว",
    "zipcode": 27260,
    "sub_district_code": 270206,
    "district_code": 2702,
    "province_code": 27
  },
  {
    "sub_district": "ไทรทอง",
    "district": "คลองหาด",
    "province": "สระแก้ว",
    "zipcode": 27260,
    "sub_district_code": 270207,
    "district_code": 2702,
    "province_code": 27
  },
  {
    "sub_district": "ซับมะกรูด",
    "district": "คลองหาด",
    "province": "สระแก้ว",
    "zipcode": 27260,
    "sub_district_code": 270203,
    "district_code": 2702,
    "province_code": 27
  },
  {
    "sub_district": "ไทรเดี่ยว",
    "district": "คลองหาด",
    "province": "สระแก้ว",
    "zipcode": 27260,
    "sub_district_code": 270204,
    "district_code": 2702,
    "province_code": 27
  },
  {
    "sub_district": "ไทยอุดม",
    "district": "คลองหาด",
    "province": "สระแก้ว",
    "zipcode": 27260,
    "sub_district_code": 270202,
    "district_code": 2702,
    "province_code": 27
  },
  {
    "sub_district": "วัฒนานคร",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270501,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "ผักขะ",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270503,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "หนองน้ำใส",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270505,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "ท่าเกวียน",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270502,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "หนองหมากฝ้าย",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270509,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "ช่องกุ่ม",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270506,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "หนองแวง",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270507,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "แซร์ออ",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270508,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "โนนหมากเค็ง",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270504,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "หนองตะเคียนบอน",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270510,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "ห้วยโจด",
    "district": "วัฒนานคร",
    "province": "สระแก้ว",
    "zipcode": 27160,
    "sub_district_code": 270511,
    "district_code": 2705,
    "province_code": 27
  },
  {
    "sub_district": "อรัญประเทศ",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270601,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "บ้านใหม่หนองไทร",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270608,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "บ้านด่าน",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270613,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "ป่าไร่",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270606,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "ฟากห้วย",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270612,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "หนองสังข์",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270610,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "คลองทับจันทร์",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270611,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "หันทราย",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270603,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "ผ่านศึก",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270609,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "ท่าข้าม",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270605,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "คลองน้ำใส",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270604,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "เมืองไผ่",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270602,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "ทับพริก",
    "district": "อรัญประเทศ",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270607,
    "district_code": 2706,
    "province_code": 27
  },
  {
    "sub_district": "วังน้ำเย็น",
    "district": "วังน้ำเย็น",
    "province": "สระแก้ว",
    "zipcode": 27210,
    "sub_district_code": 270401,
    "district_code": 2704,
    "province_code": 27
  },
  {
    "sub_district": "คลองหินปูน",
    "district": "วังน้ำเย็น",
    "province": "สระแก้ว",
    "zipcode": 27210,
    "sub_district_code": 270405,
    "district_code": 2704,
    "province_code": 27
  },
  {
    "sub_district": "วังสมบูรณ์",
    "district": "วังน้ำเย็น",
    "province": "สระแก้ว",
    "zipcode": 27250,
    "sub_district_code": 270497,
    "district_code": 2704,
    "province_code": 27
  },
  {
    "sub_district": "วังใหม่",
    "district": "วังน้ำเย็น",
    "province": "สระแก้ว",
    "zipcode": 27250,
    "sub_district_code": 270498,
    "district_code": 2704,
    "province_code": 27
  },
  {
    "sub_district": "ทุ่งมหาเจริญ",
    "district": "วังน้ำเย็น",
    "province": "สระแก้ว",
    "zipcode": 27210,
    "sub_district_code": 270406,
    "district_code": 2704,
    "province_code": 27
  },
  {
    "sub_district": "ตาหลังใน",
    "district": "วังน้ำเย็น",
    "province": "สระแก้ว",
    "zipcode": 27210,
    "sub_district_code": 270403,
    "district_code": 2704,
    "province_code": 27
  },
  {
    "sub_district": "เขาฉกรรจ์",
    "district": "เขาฉกรรจ์",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270701,
    "district_code": 2707,
    "province_code": 27
  },
  {
    "sub_district": "หนองหว้า",
    "district": "เขาฉกรรจ์",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270702,
    "district_code": 2707,
    "province_code": 27
  },
  {
    "sub_district": "เขาสามสิบ",
    "district": "เขาฉกรรจ์",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270704,
    "district_code": 2707,
    "province_code": 27
  },
  {
    "sub_district": "พระเพลิง",
    "district": "เขาฉกรรจ์",
    "province": "สระแก้ว",
    "zipcode": 27000,
    "sub_district_code": 270703,
    "district_code": 2707,
    "province_code": 27
  },
  {
    "sub_district": "โนนหมากมุ่น",
    "district": "ตาพระยา",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270398,
    "district_code": 2703,
    "province_code": 27
  },
  {
    "sub_district": "ตาพระยา",
    "district": "ตาพระยา",
    "province": "สระแก้ว",
    "zipcode": 27180,
    "sub_district_code": 270301,
    "district_code": 2703,
    "province_code": 27
  },
  {
    "sub_district": "ทัพราช",
    "district": "ตาพระยา",
    "province": "สระแก้ว",
    "zipcode": 27180,
    "sub_district_code": 270306,
    "district_code": 2703,
    "province_code": 27
  },
  {
    "sub_district": "ทัพไทย",
    "district": "ตาพระยา",
    "province": "สระแก้ว",
    "zipcode": 27180,
    "sub_district_code": 270307,
    "district_code": 2703,
    "province_code": 27
  },
  {
    "sub_district": "ทัพเสด็จ",
    "district": "ตาพระยา",
    "province": "สระแก้ว",
    "zipcode": 27180,
    "sub_district_code": 270302,
    "district_code": 2703,
    "province_code": 27
  },
  {
    "sub_district": "โคคลาน",
    "district": "ตาพระยา",
    "province": "สระแก้ว",
    "zipcode": 27180,
    "sub_district_code": 270309,
    "district_code": 2703,
    "province_code": 27
  },
  {
    "sub_district": "โคกสูง",
    "district": "โคกสูง",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270801,
    "district_code": 2708,
    "province_code": 27
  },
  {
    "sub_district": "หนองม่วง",
    "district": "โคกสูง",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270802,
    "district_code": 2708,
    "province_code": 27
  },
  {
    "sub_district": "หนองแวง",
    "district": "โคกสูง",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270803,
    "district_code": 2708,
    "province_code": 27
  },
  {
    "sub_district": "โนนหมากมุ่น",
    "district": "โคกสูง",
    "province": "สระแก้ว",
    "zipcode": 27120,
    "sub_district_code": 270804,
    "district_code": 2708,
    "province_code": 27
  },
  {
    "sub_district": "วังทอง",
    "district": "วังสมบูรณ์",
    "province": "สระแก้ว",
    "zipcode": 27250,
    "sub_district_code": 270903,
    "district_code": 2709,
    "province_code": 27
  },
  {
    "sub_district": "วังสมบูรณ์",
    "district": "วังสมบูรณ์",
    "province": "สระแก้ว",
    "zipcode": 27250,
    "sub_district_code": 270901,
    "district_code": 2709,
    "province_code": 27
  },
  {
    "sub_district": "วังใหม่",
    "district": "วังสมบูรณ์",
    "province": "สระแก้ว",
    "zipcode": 27250,
    "sub_district_code": 270902,
    "district_code": 2709,
    "province_code": 27
  },
  {
    "sub_district": "บุ่ง",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370101,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "นาผือ",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370108,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "สร้างนกทา",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370106,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "ไก่คำ",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370102,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "น้ำปลีก",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370109,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "โนนโพธิ์",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370112,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "โนนหนามแท่ง",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370113,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "ห้วยไร่",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370114,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "นาจิก",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370103,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "นาหมอม้า",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370111,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "คึมใหญ่",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370107,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "เหล่าพรวน",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370105,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "นาวัง",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370110,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "ปลาค้าว",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370104,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "หนองมะแซว",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370115,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "กุดปลาดุก",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370116,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "ดอนเมย",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370117,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "นาแต้",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370119,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "นายม",
    "district": "เมืองอำนาจเจริญ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370118,
    "district_code": 3701,
    "province_code": 37
  },
  {
    "sub_district": "ชานุมาน",
    "district": "ชานุมาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37210,
    "sub_district_code": 370201,
    "district_code": 3702,
    "province_code": 37
  },
  {
    "sub_district": "โคกสาร",
    "district": "ชานุมาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37210,
    "sub_district_code": 370202,
    "district_code": 3702,
    "province_code": 37
  },
  {
    "sub_district": "คำเขื่อนแก้ว",
    "district": "ชานุมาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37210,
    "sub_district_code": 370203,
    "district_code": 3702,
    "province_code": 37
  },
  {
    "sub_district": "โคกก่ง",
    "district": "ชานุมาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37210,
    "sub_district_code": 370204,
    "district_code": 3702,
    "province_code": 37
  },
  {
    "sub_district": "ป่าก่อ",
    "district": "ชานุมาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37210,
    "sub_district_code": 370205,
    "district_code": 3702,
    "province_code": 37
  },
  {
    "sub_district": "พนา",
    "district": "พนา",
    "province": "อำนาจเจริญ",
    "zipcode": 37180,
    "sub_district_code": 370401,
    "district_code": 3704,
    "province_code": 37
  },
  {
    "sub_district": "จานลาน",
    "district": "พนา",
    "province": "อำนาจเจริญ",
    "zipcode": 37180,
    "sub_district_code": 370402,
    "district_code": 3704,
    "province_code": 37
  },
  {
    "sub_district": "ไม้กลอน",
    "district": "พนา",
    "province": "อำนาจเจริญ",
    "zipcode": 37180,
    "sub_district_code": 370403,
    "district_code": 3704,
    "province_code": 37
  },
  {
    "sub_district": "พระเหลา",
    "district": "พนา",
    "province": "อำนาจเจริญ",
    "zipcode": 37180,
    "sub_district_code": 370404,
    "district_code": 3704,
    "province_code": 37
  },
  {
    "sub_district": "คำพระ",
    "district": "หัวตะพาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37240,
    "sub_district_code": 370602,
    "district_code": 3706,
    "province_code": 37
  },
  {
    "sub_district": "รัตนวารี",
    "district": "หัวตะพาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37240,
    "sub_district_code": 370608,
    "district_code": 3706,
    "province_code": 37
  },
  {
    "sub_district": "โพนเมืองน้อย",
    "district": "หัวตะพาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37240,
    "sub_district_code": 370605,
    "district_code": 3706,
    "province_code": 37
  },
  {
    "sub_district": "หัวตะพาน",
    "district": "หัวตะพาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37240,
    "sub_district_code": 370601,
    "district_code": 3706,
    "province_code": 37
  },
  {
    "sub_district": "เค็งใหญ่",
    "district": "หัวตะพาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37240,
    "sub_district_code": 370603,
    "district_code": 3706,
    "province_code": 37
  },
  {
    "sub_district": "หนองแก้ว",
    "district": "หัวตะพาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37240,
    "sub_district_code": 370604,
    "district_code": 3706,
    "province_code": 37
  },
  {
    "sub_district": "สร้างถ่อน้อย",
    "district": "หัวตะพาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37240,
    "sub_district_code": 370606,
    "district_code": 3706,
    "province_code": 37
  },
  {
    "sub_district": "จิกดู่",
    "district": "หัวตะพาน",
    "province": "อำนาจเจริญ",
    "zipcode": 37240,
    "sub_district_code": 370607,
    "district_code": 3706,
    "province_code": 37
  },
  {
    "sub_district": "เสนางคนิคม",
    "district": "เสนางคนิคม",
    "province": "อำนาจเจริญ",
    "zipcode": 37290,
    "sub_district_code": 370501,
    "district_code": 3705,
    "province_code": 37
  },
  {
    "sub_district": "โพนทอง",
    "district": "เสนางคนิคม",
    "province": "อำนาจเจริญ",
    "zipcode": 37290,
    "sub_district_code": 370502,
    "district_code": 3705,
    "province_code": 37
  },
  {
    "sub_district": "ไร่สีสุก",
    "district": "เสนางคนิคม",
    "province": "อำนาจเจริญ",
    "zipcode": 37290,
    "sub_district_code": 370503,
    "district_code": 3705,
    "province_code": 37
  },
  {
    "sub_district": "นาเวียง",
    "district": "เสนางคนิคม",
    "province": "อำนาจเจริญ",
    "zipcode": 37290,
    "sub_district_code": 370504,
    "district_code": 3705,
    "province_code": 37
  },
  {
    "sub_district": "หนองไฮ",
    "district": "เสนางคนิคม",
    "province": "อำนาจเจริญ",
    "zipcode": 37290,
    "sub_district_code": 370505,
    "district_code": 3705,
    "province_code": 37
  },
  {
    "sub_district": "หนองสามสี",
    "district": "เสนางคนิคม",
    "province": "อำนาจเจริญ",
    "zipcode": 37290,
    "sub_district_code": 370506,
    "district_code": 3705,
    "province_code": 37
  },
  {
    "sub_district": "ดงบัง",
    "district": "ลืออำนาจ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370704,
    "district_code": 3707,
    "province_code": 37
  },
  {
    "sub_district": "อำนาจ",
    "district": "ลืออำนาจ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370701,
    "district_code": 3707,
    "province_code": 37
  },
  {
    "sub_district": "เปือย",
    "district": "ลืออำนาจ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370703,
    "district_code": 3707,
    "province_code": 37
  },
  {
    "sub_district": "ดงมะยาง",
    "district": "ลืออำนาจ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370702,
    "district_code": 3707,
    "province_code": 37
  },
  {
    "sub_district": "แมด",
    "district": "ลืออำนาจ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370706,
    "district_code": 3707,
    "province_code": 37
  },
  {
    "sub_district": "ไร่ขี",
    "district": "ลืออำนาจ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370705,
    "district_code": 3707,
    "province_code": 37
  },
  {
    "sub_district": "โคกกลาง",
    "district": "ลืออำนาจ",
    "province": "อำนาจเจริญ",
    "zipcode": 37000,
    "sub_district_code": 370707,
    "district_code": 3707,
    "province_code": 37
  },
  {
    "sub_district": "นาหว้า",
    "district": "ปทุมราชวงศา",
    "province": "อำนาจเจริญ",
    "zipcode": 37110,
    "sub_district_code": 370303,
    "district_code": 3703,
    "province_code": 37
  },
  {
    "sub_district": "ห้วย",
    "district": "ปทุมราชวงศา",
    "province": "อำนาจเจริญ",
    "zipcode": 37110,
    "sub_district_code": 370305,
    "district_code": 3703,
    "province_code": 37
  },
  {
    "sub_district": "ลือ",
    "district": "ปทุมราชวงศา",
    "province": "อำนาจเจริญ",
    "zipcode": 37110,
    "sub_district_code": 370304,
    "district_code": 3703,
    "province_code": 37
  },
  {
    "sub_district": "คำโพน",
    "district": "ปทุมราชวงศา",
    "province": "อำนาจเจริญ",
    "zipcode": 37110,
    "sub_district_code": 370302,
    "district_code": 3703,
    "province_code": 37
  },
  {
    "sub_district": "หนองข่า",
    "district": "ปทุมราชวงศา",
    "province": "อำนาจเจริญ",
    "zipcode": 37110,
    "sub_district_code": 370301,
    "district_code": 3703,
    "province_code": 37
  },
  {
    "sub_district": "โนนงาม",
    "district": "ปทุมราชวงศา",
    "province": "อำนาจเจริญ",
    "zipcode": 37110,
    "sub_district_code": 370306,
    "district_code": 3703,
    "province_code": 37
  },
  {
    "sub_district": "นาป่าแซง",
    "district": "ปทุมราชวงศา",
    "province": "อำนาจเจริญ",
    "zipcode": 37110,
    "sub_district_code": 370307,
    "district_code": 3703,
    "province_code": 37
  },
  {
    "sub_district": "หนองบัว",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390101,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "ลำภู",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390110,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "หนองภัยศูนย์",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390102,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "นาคำไฮ",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390113,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "หนองสวรรค์",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390104,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "โพธิ์ชัย",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390103,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "บ้านพร้าว",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390108,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "หัวนา",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390105,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "บ้านขาม",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390106,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "นามะเฟือง",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390107,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "ป่าไม้งาม",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390114,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "โนนขมิ้น",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390109,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "กุดจิก",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390111,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "โนนทัน",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390112,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "หนองหว้า",
    "district": "เมืองหนองบัวลำภู",
    "province": "หนองบัวลำภู",
    "zipcode": 39000,
    "sub_district_code": 390115,
    "district_code": 3901,
    "province_code": 39
  },
  {
    "sub_district": "เมืองใหม่",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390401,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "โนนสะอาด",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390406,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "หันนางาม",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390412,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "นากอก",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390405,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "ศรีบุญเรือง",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390402,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "หนองกุงแก้ว",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390409,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "ยางหล่อ",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390407,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "หนองบัวใต้",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390403,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "ทรายทอง",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390411,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "โนนม่วง",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390408,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "กุดสะเทียน",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390404,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "หนองแก",
    "district": "ศรีบุญเรือง",
    "province": "หนองบัวลำภู",
    "zipcode": 39180,
    "sub_district_code": 390410,
    "district_code": 3904,
    "province_code": 39
  },
  {
    "sub_district": "นากลาง",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390201,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "นาเหล่า",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390298,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "กุดแห่",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390213,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "ดงสวรรค์",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39350,
    "sub_district_code": 390211,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "โนนเมือง",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390209,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "ด่านช้าง",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390202,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "อุทัยสวรรค์",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390210,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "กุดดินจี่",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39350,
    "sub_district_code": 390205,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "ป่าแดง",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390299,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "ฝั่งแดง",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390206,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "เก่ากลอย",
    "district": "นากลาง",
    "province": "หนองบัวลำภู",
    "zipcode": 39350,
    "sub_district_code": 390207,
    "district_code": 3902,
    "province_code": 39
  },
  {
    "sub_district": "นาดี",
    "district": "สุวรรณคูหา",
    "province": "หนองบัวลำภู",
    "zipcode": 39270,
    "sub_district_code": 390503,
    "district_code": 3905,
    "province_code": 39
  },
  {
    "sub_district": "สุวรรณคูหา",
    "district": "สุวรรณคูหา",
    "province": "หนองบัวลำภู",
    "zipcode": 39270,
    "sub_district_code": 390506,
    "district_code": 3905,
    "province_code": 39
  },
  {
    "sub_district": "นาด่าน",
    "district": "สุวรรณคูหา",
    "province": "หนองบัวลำภู",
    "zipcode": 39270,
    "sub_district_code": 390504,
    "district_code": 3905,
    "province_code": 39
  },
  {
    "sub_district": "นาสี",
    "district": "สุวรรณคูหา",
    "province": "หนองบัวลำภู",
    "zipcode": 39270,
    "sub_district_code": 390501,
    "district_code": 3905,
    "province_code": 39
  },
  {
    "sub_district": "ดงมะไฟ",
    "district": "สุวรรณคูหา",
    "province": "หนองบัวลำภู",
    "zipcode": 39270,
    "sub_district_code": 390505,
    "district_code": 3905,
    "province_code": 39
  },
  {
    "sub_district": "บ้านโคก",
    "district": "สุวรรณคูหา",
    "province": "หนองบัวลำภู",
    "zipcode": 39270,
    "sub_district_code": 390502,
    "district_code": 3905,
    "province_code": 39
  },
  {
    "sub_district": "บุญทัน",
    "district": "สุวรรณคูหา",
    "province": "หนองบัวลำภู",
    "zipcode": 39270,
    "sub_district_code": 390507,
    "district_code": 3905,
    "province_code": 39
  },
  {
    "sub_district": "กุดผึ้ง",
    "district": "สุวรรณคูหา",
    "province": "หนองบัวลำภู",
    "zipcode": 39270,
    "sub_district_code": 390508,
    "district_code": 3905,
    "province_code": 39
  },
  {
    "sub_district": "นาเหล่า",
    "district": "นาวัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390601,
    "district_code": 3906,
    "province_code": 39
  },
  {
    "sub_district": "นาแก",
    "district": "นาวัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390602,
    "district_code": 3906,
    "province_code": 39
  },
  {
    "sub_district": "วังทอง",
    "district": "นาวัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390603,
    "district_code": 3906,
    "province_code": 39
  },
  {
    "sub_district": "วังปลาป้อม",
    "district": "นาวัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390604,
    "district_code": 3906,
    "province_code": 39
  },
  {
    "sub_district": "เทพคีรี",
    "district": "นาวัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39170,
    "sub_district_code": 390605,
    "district_code": 3906,
    "province_code": 39
  },
  {
    "sub_district": "โนนสัง",
    "district": "โนนสัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39140,
    "sub_district_code": 390301,
    "district_code": 3903,
    "province_code": 39
  },
  {
    "sub_district": "บ้านถิ่น",
    "district": "โนนสัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39140,
    "sub_district_code": 390302,
    "district_code": 3903,
    "province_code": 39
  },
  {
    "sub_district": "กุดดู่",
    "district": "โนนสัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39140,
    "sub_district_code": 390304,
    "district_code": 3903,
    "province_code": 39
  },
  {
    "sub_district": "บ้านค้อ",
    "district": "โนนสัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39140,
    "sub_district_code": 390305,
    "district_code": 3903,
    "province_code": 39
  },
  {
    "sub_district": "หนองเรือ",
    "district": "โนนสัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39140,
    "sub_district_code": 390303,
    "district_code": 3903,
    "province_code": 39
  },
  {
    "sub_district": "โนนเมือง",
    "district": "โนนสัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39140,
    "sub_district_code": 390306,
    "district_code": 3903,
    "province_code": 39
  },
  {
    "sub_district": "โคกม่วง",
    "district": "โนนสัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39140,
    "sub_district_code": 390308,
    "district_code": 3903,
    "province_code": 39
  },
  {
    "sub_district": "โคกใหญ่",
    "district": "โนนสัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39140,
    "sub_district_code": 390307,
    "district_code": 3903,
    "province_code": 39
  },
  {
    "sub_district": "นิคมพัฒนา",
    "district": "โนนสัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39140,
    "sub_district_code": 390309,
    "district_code": 3903,
    "province_code": 39
  },
  {
    "sub_district": "ปางกู่",
    "district": "โนนสัง",
    "province": "หนองบัวลำภู",
    "zipcode": 39140,
    "sub_district_code": 390310,
    "district_code": 3903,
    "province_code": 39
  },
  {
    "sub_district": "บึงกาฬ",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380101,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "โนนสมบูรณ์",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380102,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "หอคำ",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380104,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "หนองเลิง",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380105,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "โคกก่อง",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380106,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "นาสวรรค์",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380107,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "ไคสี",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380108,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "ชัยพร",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380109,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "วิศิษฐ์",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380110,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "คำนาดี",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380111,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "โป่งเปือย",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380112,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "โนนสว่าง",
    "district": "เมืองบึงกาฬ",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380103,
    "district_code": 3801,
    "province_code": 38
  },
  {
    "sub_district": "ศรีชมภู",
    "district": "พรเจริญ",
    "province": "บึงกาฬ",
    "zipcode": 38180,
    "sub_district_code": 380201,
    "district_code": 3802,
    "province_code": 38
  },
  {
    "sub_district": "ดอนหญ้านาง",
    "district": "พรเจริญ",
    "province": "บึงกาฬ",
    "zipcode": 38180,
    "sub_district_code": 380202,
    "district_code": 3802,
    "province_code": 38
  },
  {
    "sub_district": "พรเจริญ",
    "district": "พรเจริญ",
    "province": "บึงกาฬ",
    "zipcode": 38180,
    "sub_district_code": 380203,
    "district_code": 3802,
    "province_code": 38
  },
  {
    "sub_district": "หนองหัวช้าง",
    "district": "พรเจริญ",
    "province": "บึงกาฬ",
    "zipcode": 38180,
    "sub_district_code": 380204,
    "district_code": 3802,
    "province_code": 38
  },
  {
    "sub_district": "วังชมภู",
    "district": "พรเจริญ",
    "province": "บึงกาฬ",
    "zipcode": 38180,
    "sub_district_code": 380205,
    "district_code": 3802,
    "province_code": 38
  },
  {
    "sub_district": "ป่าแฝก",
    "district": "พรเจริญ",
    "province": "บึงกาฬ",
    "zipcode": 38180,
    "sub_district_code": 380206,
    "district_code": 3802,
    "province_code": 38
  },
  {
    "sub_district": "ศรีสำราญ",
    "district": "พรเจริญ",
    "province": "บึงกาฬ",
    "zipcode": 38180,
    "sub_district_code": 380207,
    "district_code": 3802,
    "province_code": 38
  },
  {
    "sub_district": "โซ่",
    "district": "โซ่พิสัย",
    "province": "บึงกาฬ",
    "zipcode": 38170,
    "sub_district_code": 380301,
    "district_code": 3803,
    "province_code": 38
  },
  {
    "sub_district": "หนองพันทา",
    "district": "โซ่พิสัย",
    "province": "บึงกาฬ",
    "zipcode": 38170,
    "sub_district_code": 380302,
    "district_code": 3803,
    "province_code": 38
  },
  {
    "sub_district": "ศรีชมภู",
    "district": "โซ่พิสัย",
    "province": "บึงกาฬ",
    "zipcode": 38170,
    "sub_district_code": 380303,
    "district_code": 3803,
    "province_code": 38
  },
  {
    "sub_district": "คำแก้ว",
    "district": "โซ่พิสัย",
    "province": "บึงกาฬ",
    "zipcode": 38170,
    "sub_district_code": 380304,
    "district_code": 3803,
    "province_code": 38
  },
  {
    "sub_district": "บัวตูม",
    "district": "โซ่พิสัย",
    "province": "บึงกาฬ",
    "zipcode": 38170,
    "sub_district_code": 380305,
    "district_code": 3803,
    "province_code": 38
  },
  {
    "sub_district": "ถ้ำเจริญ",
    "district": "โซ่พิสัย",
    "province": "บึงกาฬ",
    "zipcode": 38170,
    "sub_district_code": 380306,
    "district_code": 3803,
    "province_code": 38
  },
  {
    "sub_district": "เหล่าทอง",
    "district": "โซ่พิสัย",
    "province": "บึงกาฬ",
    "zipcode": 38170,
    "sub_district_code": 380307,
    "district_code": 3803,
    "province_code": 38
  },
  {
    "sub_district": "เซกา",
    "district": "เซกา",
    "province": "บึงกาฬ",
    "zipcode": 38150,
    "sub_district_code": 380401,
    "district_code": 3804,
    "province_code": 38
  },
  {
    "sub_district": "ซาง",
    "district": "เซกา",
    "province": "บึงกาฬ",
    "zipcode": 38150,
    "sub_district_code": 380402,
    "district_code": 3804,
    "province_code": 38
  },
  {
    "sub_district": "ท่ากกแดง",
    "district": "เซกา",
    "province": "บึงกาฬ",
    "zipcode": 38150,
    "sub_district_code": 380403,
    "district_code": 3804,
    "province_code": 38
  },
  {
    "sub_district": "บ้านต้อง",
    "district": "เซกา",
    "province": "บึงกาฬ",
    "zipcode": 38150,
    "sub_district_code": 380404,
    "district_code": 3804,
    "province_code": 38
  },
  {
    "sub_district": "ป่งไฮ",
    "district": "เซกา",
    "province": "บึงกาฬ",
    "zipcode": 38150,
    "sub_district_code": 380405,
    "district_code": 3804,
    "province_code": 38
  },
  {
    "sub_district": "น้ำจั้น",
    "district": "เซกา",
    "province": "บึงกาฬ",
    "zipcode": 38150,
    "sub_district_code": 380406,
    "district_code": 3804,
    "province_code": 38
  },
  {
    "sub_district": "ท่าสะอาด",
    "district": "เซกา",
    "province": "บึงกาฬ",
    "zipcode": 38150,
    "sub_district_code": 380407,
    "district_code": 3804,
    "province_code": 38
  },
  {
    "sub_district": "หนองทุ่ม",
    "district": "เซกา",
    "province": "บึงกาฬ",
    "zipcode": 38150,
    "sub_district_code": 380408,
    "district_code": 3804,
    "province_code": 38
  },
  {
    "sub_district": "โสกก่าม",
    "district": "เซกา",
    "province": "บึงกาฬ",
    "zipcode": 38150,
    "sub_district_code": 380409,
    "district_code": 3804,
    "province_code": 38
  },
  {
    "sub_district": "ปากคาด",
    "district": "ปากคาด",
    "province": "บึงกาฬ",
    "zipcode": 38190,
    "sub_district_code": 380501,
    "district_code": 3805,
    "province_code": 38
  },
  {
    "sub_district": "หนองยอง",
    "district": "ปากคาด",
    "province": "บึงกาฬ",
    "zipcode": 38190,
    "sub_district_code": 380502,
    "district_code": 3805,
    "province_code": 38
  },
  {
    "sub_district": "นากั้ง",
    "district": "ปากคาด",
    "province": "บึงกาฬ",
    "zipcode": 38190,
    "sub_district_code": 380503,
    "district_code": 3805,
    "province_code": 38
  },
  {
    "sub_district": "โนนศิลา",
    "district": "ปากคาด",
    "province": "บึงกาฬ",
    "zipcode": 38190,
    "sub_district_code": 380504,
    "district_code": 3805,
    "province_code": 38
  },
  {
    "sub_district": "สมสนุก",
    "district": "ปากคาด",
    "province": "บึงกาฬ",
    "zipcode": 38190,
    "sub_district_code": 380505,
    "district_code": 3805,
    "province_code": 38
  },
  {
    "sub_district": "นาดง",
    "district": "ปากคาด",
    "province": "บึงกาฬ",
    "zipcode": 38190,
    "sub_district_code": 380506,
    "district_code": 3805,
    "province_code": 38
  },
  {
    "sub_district": "บึงโขงหลง",
    "district": "บึงโขงหลง",
    "province": "บึงกาฬ",
    "zipcode": 38220,
    "sub_district_code": 380601,
    "district_code": 3806,
    "province_code": 38
  },
  {
    "sub_district": "โพธิ์หมากแข้ง",
    "district": "บึงโขงหลง",
    "province": "บึงกาฬ",
    "zipcode": 38220,
    "sub_district_code": 380602,
    "district_code": 3806,
    "province_code": 38
  },
  {
    "sub_district": "ดงบัง",
    "district": "บึงโขงหลง",
    "province": "บึงกาฬ",
    "zipcode": 38220,
    "sub_district_code": 380603,
    "district_code": 3806,
    "province_code": 38
  },
  {
    "sub_district": "ท่าดอกคำ",
    "district": "บึงโขงหลง",
    "province": "บึงกาฬ",
    "zipcode": 38220,
    "sub_district_code": 380604,
    "district_code": 3806,
    "province_code": 38
  },
  {
    "sub_district": "ศรีวิไล",
    "district": "ศรีวิไล",
    "province": "บึงกาฬ",
    "zipcode": 38210,
    "sub_district_code": 380701,
    "district_code": 3807,
    "province_code": 38
  },
  {
    "sub_district": "ชุมภูพร",
    "district": "ศรีวิไล",
    "province": "บึงกาฬ",
    "zipcode": 38210,
    "sub_district_code": 380702,
    "district_code": 3807,
    "province_code": 38
  },
  {
    "sub_district": "นาแสง",
    "district": "ศรีวิไล",
    "province": "บึงกาฬ",
    "zipcode": 38210,
    "sub_district_code": 380703,
    "district_code": 3807,
    "province_code": 38
  },
  {
    "sub_district": "นาสะแบง",
    "district": "ศรีวิไล",
    "province": "บึงกาฬ",
    "zipcode": 38210,
    "sub_district_code": 380704,
    "district_code": 3807,
    "province_code": 38
  },
  {
    "sub_district": "นาสิงห์",
    "district": "ศรีวิไล",
    "province": "บึงกาฬ",
    "zipcode": 38210,
    "sub_district_code": 380705,
    "district_code": 3807,
    "province_code": 38
  },
  {
    "sub_district": "บุ่งคล้า",
    "district": "บุ่งคล้า",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380801,
    "district_code": 3808,
    "province_code": 38
  },
  {
    "sub_district": "หนองเดิ่น",
    "district": "บุ่งคล้า",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380802,
    "district_code": 3808,
    "province_code": 38
  },
  {
    "sub_district": "โคกกว้าง",
    "district": "บุ่งคล้า",
    "province": "บึงกาฬ",
    "zipcode": 38000,
    "sub_district_code": 380803,
    "district_code": 3808,
    "province_code": 38
  }
];