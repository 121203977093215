import React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { PDPAForm, padaFormSelector, fetchShowPDPA } from "~src/features/pdpa";
import { useAppDispatch, useAppSelector } from "~src/store";

const PDPAEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const pdpa = useAppSelector(padaFormSelector);

  React.useEffect(() => {
    dispatch(fetchShowPDPA({ id }));
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" component="h1">
          Edit PDPA
        </Typography>

        <Button href="/staffs/contracts" variant="contained" disableElevation color="inherit">
          ย้อนกลับ
        </Button>
      </Box>
      {pdpa.mode === "edit" && pdpa.status === "fulfilled" && <PDPAForm />}
    </Box>
  );
};

export default PDPAEditPage;
