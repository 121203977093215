import React from "react";

import "./table.scss";

const TABLE = ({ children, classes = "quotations-table" }) => {
  return (
    <div className="table-overflow">
      <table cellPadding="0" cellSpacing="0" className={classes}>
        {children}
      </table>
    </div>
  );
};

const THEAD = ({ children }) => {
  return <thead>{children}</thead>;
};

const TBODY = ({ children }) => {
  return <tbody>{children}</tbody>;
};

const TR = ({ children, classes }) => {
  return <tr className={classes}>{children}</tr>;
};

const TH = ({ label, ...rest }) => {
  return <th {...rest}>{label}</th>;
};

const TD = ({ children, label, ...rest }) => {
  return <td {...rest}>{label}</td>;
};

export { THEAD, TBODY, TR, TH, TD };
export default TABLE;
