import { createContext } from "react";
const initialState = {
  isFilter: false,
  carInfo: "",
  carModel: "",
  activeFilters: {
    policyType: false,
    repairFacility: false,
    excessDamageCoverage: false,
    insurers: false,
  },
  filterPolicyTypes: [],
  filterRepairFacility: [],
  filterExcessDamageCoverage: [],
  insurersOptions: [],
  filterInsurers: [],
  filterSort: {
    sortby_selling_price: "selling_price_asc",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      return {
        isFilter: action.payload.isFilter,
        carInfo: action.payload.carInfo,
        carModel: action.payload.carModel,
        activeFilters: action.payload.activeFilters,
        filterPolicyTypes: action.payload.filterPolicyTypes,
        filterRepairFacility: action.payload.filterRepairFacility,
        filterExcessDamageCoverage: action.payload.filterExcessDamageCoverage,
        insurersOptions: action.payload.insurersOptions,
        filterInsurers: action.payload.filterInsurers,
        filterSort: action.payload.filterSort,
      };
    case "RESET":
      return {
        ...state,
      };
  }
};

const filterContext = createContext();

export const FilterProvider = filterContext.Provider;
export { initialState, reducer };

export default filterContext;
