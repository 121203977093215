import React from "react";

import "./alert.scss";

const Alert = ({fnClose}) => {
  const handleClose = () => {
    fnClose(true)
  };

  return (
    <div className="alert-modal">
      <div className="alert-modal-box animated fadeInDownBig">
        <div className="alert-modal-box-icon">
          <i className="material-icons">priority_high</i>
        </div>
        <div className="alert-modal-box-content">
          <u>กรุณา</u> กดยืนยันและยอมรับเงื่อนไขและข้อตกลง
        </div>
        <div className="alert-modal-box-button">
          <button type="button" onClick={handleClose}>
            ปิด
          </button>
        </div>
      </div>
    </div>
  );
};

export default Alert;
