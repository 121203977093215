import { AxiosError, AxiosResponse } from "axios";
import APIResponse from "./APIResponse";

export interface APIErrorOptions<T> extends ErrorOptions {
  response?: AxiosResponse<APIResponse<T>>;
  statusCode?: number;
  details?: string | any[];
}

//TODO: Revise the interfaces later
export default class APIError<T> extends Error {
  response?: AxiosResponse<APIResponse<T>>;
  statusCode?: number;
  details: any[];

  public constructor(message: string, opts?: APIErrorOptions<T>) {
    super(message, opts);

    // restore prototype chain
    const actualProto = new.target.prototype;
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      (this as any).__proto__ = actualProto;
    }

    let { response, statusCode, details, cause } = opts || {};

    if (cause && "isAxiosError" in cause) {
      const axiosErr = cause as AxiosError<APIResponse<T>>;
      if (axiosErr.isAxiosError && "response" in cause) {
        response ||= axiosErr.response;
      }
    }
    if (response) {
      statusCode ||= response.status;
      details ||= response.data.messages || response.data.message || response.data.error;
    }

    this.response = response;
    this.statusCode = statusCode;
    this.details = !details ? [] : Array.isArray(details) ? details : [details];
  }
}
