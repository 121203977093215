import React from "react";
import cn from "classnames";
import "./select.scss";

const SelectGroupField = ({ optgroups, inputRef, error, className = "", ...rest }) => {
  return (
    <>
      <select
        {...rest}
        ref={inputRef}
        className={cn(!error ? "form-select" : "form-select has-error", className)}
      >
        <option value="">กรุณาเลือก</option>
        {optgroups.map((objs) => (
          <optgroup key={objs.label} label={objs.label}>
            {objs.options.map((option) => (
              <option key={`${option.value}_${option.label}`} value={option.value}>
                {option.label}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
      {error ? <p className="form-input-error">{error}</p> : ""}
    </>
  );
};

export default SelectGroupField;
