import React, { useState, useEffect } from "react";
import queryString from "query-string";

import SectionHeader from "/src/components/layout/staff/SectionHeader";
import SearchForm from "/src/components/staff/SearchForm";
import ErrorPage from "/src/components/errorPage";
import Table from "./table/Table";
import ReactPaginate from "react-paginate";

import useInput from "/src/hooks/useInput";
import useFetch from "/src/hooks/useFetch";
import { urlStringify } from "/src/helpers/helpers";

import "./list.scss";

const BASE_URL = "/staffs/renewals-list";
const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const List = (props) => {
  const { location } = props;
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });
  const [currentPage, setCurrentPage] = useState(queryParams.page ?? DEFAULT_PAGE);

  const { value: search_value, bind: handleChaneSearch } = useInput(queryParams?.keyword ?? "");
  const [queryStr] = useState(queryParams);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataLists, setDataLists] = useState([]);
  const [isError, setIsError] = useState(false);
  const url = `/api/orders?${urlStringify({
    page: currentPage,
    per_page: PER_PAGE,
    order_status: ["open"],
    status: "renew",
    ...queryParams,
  })}`;

  const { response, error, isLoading } = useFetch(url);

  useEffect(() => {
    if (!isLoading) {
      setTotalRecords(response.total_count);
      setPageCount(response.total_pages);
      setDataLists(response.content);
      setIsError(false);
    }
    if (error) {
      setIsError(true);
    }
  }, [response, error, isLoading, search_value, pageCount, queryStr, activePage]);

  const performSearch = () => {
    const queries = urlStringify({
      keyword: search_value,
      per_page: PER_PAGE,
      page: 1,
    });
    window.location.href = `${BASE_URL}?${queries}`;
  };

  const handleClickSearch = () => {
    
    performSearch();
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") performSearch();
  };

  const handlePageClick = (e) => {
    const selectedPage = Number(e.selected) + 1;
    const queries = urlStringify({
      keyword: search_value,
      per_page: PER_PAGE,
      page: selectedPage,
    });
    window.location.href = `${BASE_URL}?${queries}`;
  };

  return (
    <div className="renew-list">
      {isError ? (
        <ErrorPage />
      ) : (
        <>
          <SectionHeader
            title="ใบเตือนต่ออายุ"
            breadcrumbs={[{ url: "#", label: "รายการซื้อขาย", current: true }]}
            searchComponent={
              <SearchForm
                type="text"
                placeholder="ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่คำสั่งซื้อ"
                {...handleChaneSearch}
                handleClick={handleClickSearch}
                handleKeyPress={handleKeyPressSearch}
              />
            }
          />
          <div className="result-box">
            {totalRecords > 0 ? (
              <>
                {queryStr?.search ? (
                  <span>
                    คำค้นหา:
                    <strong style={{ padding: "0 5px" }}>"{queryStr.search}" พบ</strong>
                  </span>
                ) : null}
                <span>
                  {totalRecords > 20 ? (
                    <>รายการทั้งหมด 20+ รายการ</>
                  ) : (
                    <>
                      <span>ทั้งหมด</span>
                      <strong> {totalRecords}</strong> <span>รายการ</span>
                    </>
                  )}
                </span>
              </>
            ) : null}
          </div>
          <Table loading={isLoading} data={dataLists} />
          {pageCount > 1 && (
            <ReactPaginate
              initialPage={Number(currentPage) - 1}
              previousLabel="Prev"
              nextLabel="Next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="gm-paginate"
              subContainerClassName="pages pagination"
              activeClassName="active"
              disableInitialCallback={true}
            />
          )}
        </>
      )}
    </div>
  );
};

export default List;
