import { lazy } from "react";

const Dashboard = {
  index: lazy(() => import("~src/pages/staff/Dashboard")),
};

const Policies = {
  all: lazy(() => import("~src/pages/staff/policy/All")),
  details: lazy(() => import("~src/pages/staff/policy/Details")),
};

const OrderList = {
  index: lazy(() => import("~src/pages/staff/order/ordersList")),
  details: lazy(() => import("~src/pages/staff/order/Details")),
};

const InstallmentOrderList = {
  index: lazy(() => import("/src/pages/staff/installment/ordersList")),
};

const Renewals = {
  list: lazy(() => import("~src/pages/staff/renewals/List")),
  form: lazy(() => import("~src/pages/staff/renewals/Form")),
};

const Renewal = {
  index: lazy(() => import("~src/pages/staff/Renewal")),
  create: lazy(() => import("~src/pages/staff/Renewal/Create")),
  doc: lazy(() => import("~src/pages/staff/Renewal/RenewDoc")),
  close: lazy(() => import("~src/pages/staff/Renewal/RenewClose")),
};

const RenewalList = {
  index: lazy(() => import("~src/pages/staff/renewalList")),
};

const CMS = {
  promotion: lazy(() => import("../pages/staff/cms/Promotion")),
};

const Training = {
  index: lazy(() => import("../pages/staff/training")),
};

const Agents = {
  index: lazy(() => import("~src/pages/staff/agent")),
  details: lazy(() => import("~src/pages/staff/agent/Details")),
  edit: lazy(() => import("~src/pages/staff/agent/editor")),
};

const Partners = {
  index: lazy(() => import("~src/pages/staff/partner")),
};

const OrderCustom = {
  create: lazy(() => import("~src/pages/staff/OrderCustom/Create")),
};

const Banners = {
  index: lazy(() => import("~src/pages/staff/banner/BannerListPage")),
};

const Contract = {
  index: lazy(() => import("~src/pages/staff/contracts/ContractListPage")),
  create: lazy(() => import("~src/pages/staff/contracts/ContractCreatePage")),
  edit: lazy(() => import("~src/pages/staff/contracts/ContractEditPage")),
};

const PDPAs = {
  index: lazy(() => import("../pages/staff/pdpas/PDPAListPage")),
  create: lazy(() => import("../pages/staff/pdpas/PDPACreatePage")),
  edit: lazy(() => import("~src/pages/staff/pdpas/PDPAEditPage")),
};

const ImportHistories = {
  list: lazy(() => import("~src/pages/staff/import_history/List")),
  details: lazy(() => import("~src/pages/staff/import_history/Details"))
}

const prefix = "/staffs";

const staffRoutes = [
  {
    path: `${prefix}`,
    title: "Staff Dashboard",
    component: Dashboard.index,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/policies`,
    title: "Staff Policies",
    component: Policies.all,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/policies/inprogress`,
    title: "Staff Policies",
    component: Policies.inprogress,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/policies/:id/:policy_type/details`,
    title: "Staff Policies",
    component: Policies.details,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/orders`,
    title: "Orders List",
    component: OrderList.index,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/installments`,
    title: "Installment Orders List",
    component: InstallmentOrderList.index,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/orders/:id/details`,
    title: "Orders List",
    component: OrderList.details,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/renewal`,
    title: "Renewal",
    component: Renewal.index,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/renewal-doc`,
    title: "Renewal",
    component: Renewal.doc,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/renewal-closed`,
    title: "Renewal",
    component: Renewal.close,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/renewal/:id/:action`,
    title: "Renewal Create",
    component: Renewal.create,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/renewal/:id/:action/:type`,
    title: "Renewal Create",
    component: Renewal.create,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/renewals-list`,
    title: "Renewal List",
    component: RenewalList.index,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/renewals`,
    title: "Renewal",
    component: Renewals.list,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/renewals/:id`,
    title: "Renewal Form",
    component: Renewals.form,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/order-custom/create`,
    title: "Order Custom",
    component: OrderCustom.create,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/cms/promotion`,
    title: "Promotion",
    component: CMS.promotion,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/trainings`,
    title: "Trainings",
    component: Training.index,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/agent-list`,
    title: "Hero List",
    component: Agents.index,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/partner-list`,
    title: "Partner List",
    component: Partners.index,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/agent/add`,
    title: "Add new Hero",
    component: Agents.edit,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/agent/:id/details`,
    title: "Agent Detail",
    component: Agents.details,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/agent/:id/edit`,
    title: "Edit Hero",
    component: Agents.edit,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/banner`,
    title: "Staff Banner",
    component: Banners.index,
    sidebar: true,
    header: true,
    exact: true,
  },

  {
    path: `${prefix}/contracts`,
    title: "Staff Contract",
    component: Contract.index,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/contracts/create`,
    title: "Staff Contract",
    component: Contract.create,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/contracts/edit/:id`,
    title: "Staff Contract",
    component: Contract.edit,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/pdpas`,
    title: "Staff PDPA",
    component: PDPAs.index,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/pdpas/create`,
    title: "Staff PDPA",
    component: PDPAs.create,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/pdpas/edit/:id`,
    title: "Staff PDPA",
    component: PDPAs.edit,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/import-histories`,
    title: "Staff Import History",
    component: ImportHistories.list,
    sidebar: true,
    header: true,
    exact: true,
  },
  {
    path: `${prefix}/import-histories/:id`,
    title: "Staff Import History Detail",
    component: ImportHistories.details,
    sidebar: true,
    header: true,
    exact: true,
  },
];

export default staffRoutes;
