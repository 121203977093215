import React, { useState, BaseSyntheticEvent } from "react";
import { TextField } from "~/src/components/form";
import EditIcon from "@mui/icons-material/Edit";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { useForm } from "react-hook-form";
import { messages } from "~/src/helpers/messages";

interface Props {
  formId: string;
  onSubmit: (data: Object, event: BaseSyntheticEvent) => void;
}

const ResetPassword: React.FC<Props> = ({ formId, onSubmit }) => {
  const [editState, setEditState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const resetFormState = () => {
    setLoading(false);
    setEditState(false);
  };
  const onSubmitOverride = async (data, event) => {
    setLoading(true);

    await onSubmit(data, event);
    await resetFormState();
  };

  const PassWordField: React.FC = () => {
    if (loading) return null;

    return (
      <TextField
        id="reset-password-field"
        name="password"
        type="password"
        inputRef={register({
          ...messages.required,
          ...messages.password,
        })}
        error={errors.password?.message}
      />
    );
  };

  const BackButton: React.FC = () => {
    if (loading) return null;

    return (
      <button
        className="text-left text-gray-500 focus:outline-none hover:text-gray-600 w-max"
        type="button"
        onClick={() => setEditState(false)}
        disabled={loading}
      >
        <ChevronLeftIcon className="mr-1" />
        <span className="inline-block align-bottom">ย้อนกลับ</span>
      </button>
    );
  };

  const EditButton: React.FC = () => {
    if (loading) return null;

    return (
      <button
        className="px-5 py-3 mt-3 text-white transition duration-300 ease-in-out 
        bg-primary rounded-3xl hover:bg-gray-900"
        type="button"
        onClick={() => setEditState(true)}
      >
        <EditIcon fontSize="small" className="mr-1" />
        <span className="inline-block align-bottom">แก้ไขรหัสผ่าน</span>
      </button>
    );
  };

  return (
    <form
      id={formId}
      autoComplete="off"
      onSubmit={handleSubmit((data, event) => onSubmitOverride(data, event))}
    >
      <div className="flex items-center justify-between w-full col-span-2 text-xl bg-custom-gray-sm">
        <span className="p-2">แก้ไขรหัสผ่าน</span>
        <button
          className={`h-full px-3 text-base transition duration-200 
            bg-opacity-0 bg-custom-gray-md rounded-3xl text-primary-color focus:outline-none 
            ${
              editState && !loading
                ? "hover:bg-opacity-25"
                : "cursor-not-allowed text-custom-gray-md"
            }`}
          form={formId}
          type="submit"
          value="Submit"
          disabled={!editState || loading}
        >
          บันทึก
        </button>
      </div>

      {loading && <div className="flex flex-col text-center mt-3">กำลังโหลด...</div>}
      {editState ? (
        <>
          <div className="flex flex-col py-3 space-y-2">
            <BackButton />
          </div>
          <div className="flex flex-col py-1 space-y-2">
            <PassWordField />
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center h-full mt-3">
          <EditButton />
        </div>
      )}
    </form>
  );
};

export default ResetPassword;
