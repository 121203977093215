import React, { useState } from "react";
import { Button } from "/src/components/form";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import { Menu, MenuItem } from "@material-ui/core";
import { Person, Link, ExitToApp, Close, CheckCircleOutline } from "@mui/icons-material";

import { logout } from "../../../utils/logout";
import "./header.scss";
import { useAuthenticate } from "~src/helpers/authen";
import { isJsonString } from "~src/helpers/helpers";

import { AlertNotification, StyleType } from "~src/components/alert/alertNotification";

const Header = ({ logo, isPublic, auditAgent = null }) => {
  const history = useHistory();
  const id = Cookies.get("id");
  const username = Cookies.get("username");
  const title = Cookies.get("title");
  const firstname = Cookies.get("firstname");
  const lastname = Cookies.get("lastname");
  const role = isJsonString(Cookies.get("role")) ? JSON.parse(Cookies.get("role")) : null;
  const recommendCode =
    Cookies.get("recommend_code") == "null" ? null : Cookies.get("recommend_code");
  const { accessType } = useAuthenticate();

  const [listMenuElement, setListMenuElement] = React.useState(null);
  const [isAlertActive, setIsAlertActive] = useState(false);

  const getRecommendLink = (code) => {
    return `${window.location.origin}/register?code=${code}`;
  };

  const handleOpenHeaderMenu = (e) => {
    setListMenuElement(e.currentTarget);
  };

  const handleCloseListMenu = () => {
    setListMenuElement(null);
  };

  const handleOpenUserDetails = () => {
    if (accessType === "AGENT") window.location.href = `/agent/${id}`;
    else window.location.href = `/staffs/agent/${id}/details`;
    handleCloseListMenu();
  };

  const handleLogout = () => {
    logout();
    handleCloseListMenu();
  };

  const handleGetRecommendLink = () => {
    setIsAlertActive(true);
    handleCloseListMenu();
    navigator.clipboard.writeText(getRecommendLink(recommendCode));
  };

  const showAgentProfile = accessType == "AGENT" || accessType == "DIRECT";

  return (
    <header className="main-header">
      <div className="main-header-logo-main">
        {accessType == "STAFF" ? (
          <a href="/staffs">
            <img src={`${process.env.BUCKET_URL}/logo.svg`} alt="logo" />
          </a>
        ) : (
          <a href="/">
            <img src={`${process.env.BUCKET_URL}/logo.svg`} alt="logo" />
          </a>
        )}
      </div>
      <div className="text-sm main-header-actions text-gray mr-0">
        <div
          className="username h-full px-3 flex items-center cursor-pointer duration-300 transition-colors hover:bg-gray-100"
          onClick={handleOpenHeaderMenu}
        >
          {showAgentProfile ? (
            <div className="avatar">
              {firstname !== null ? firstname?.substring(1, 0).toUpperCase() : "A"}
            </div>
          ) : (
            <div className="avatar">
              {username !== undefined ? username?.substring(1, 0).toUpperCase() : "A"}
            </div>
          )}
          {showAgentProfile ? `${title} ${firstname} ${lastname} [${role.name}]` : username}
          {/* {auditAgent} */}
        </div>
        {!isPublic && (
          <Menu
            id="simple-menu"
            anchorEl={listMenuElement}
            keepMounted
            open={Boolean(listMenuElement)}
            onClose={handleCloseListMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {accessType == "AGENT" && (
              <MenuItem onClick={handleOpenUserDetails}>
                <Person className="mr-1" />
                ข้อมูลตัวแทน
              </MenuItem>
            )}
            {recommendCode && (
              <MenuItem onClick={handleGetRecommendLink}>
                <Link className="mr-1" />
                รับลิงค์แนะนำ
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>
              <ExitToApp className="mr-1" />
              ออกจากระบบ
            </MenuItem>
          </Menu>
        )}
      </div>
      <AlertNotification
        className="bottom-0 right-0 px-4 py-3 m-5 mr-3 flex"
        type={"success"}
        active={isAlertActive}
        setActive={setIsAlertActive}
        enterAnimation="slideInRight"
        exitAnimation="slideOutRight"
        closeDelay={5000}
      >
        <CheckCircleOutline className="mr-3 self-center" />
        <span className="inline-block align-bottom">คัดลอกลิงค์แนะนำไปยัง Clipboard เรียบร้อย</span>
        <button className="ml-3 self-center" onClick={() => setIsAlertActive(false)}>
          <Close />
        </button>
      </AlertNotification>
    </header>
  );
};

export default Header;
