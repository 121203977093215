import React from "react";
import { UseFormMethods } from "react-hook-form";
import { FormGroup, TextField } from "~/src/components/form";

interface Props extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  name: string;
  className?: string;
}

const NationalityInput: React.FC<Props> = ({ name, className, register, errors }): JSX.Element => {
  return (
    <FormGroup label="สัญชาติ" className={className}>
      <TextField
        name={`${name}.nationality`}
        inputRef={register}
        error={errors?.nationality?.message}
      />
    </FormGroup>
  );
};

export default NationalityInput;