import React from "react";
import { useTranslation } from 'react-i18next';
import "./contact.scss";
import "/assets/contact/hero-qr-line.jpg";
import "/assets/contact/mti-qr-line.jpg";

const index = () => {
  const { t } = useTranslation();
  const lineLink = t('lineLink', { returnObjects: true })
  
  return (
    <div className="contact-section">
      <div className="text-gray-500 "> บริการสมาชิก </div>
      <div className="inline text-lg mb-1">ติดต่อผู้ดูแลระบบ</div>
      <hr className="mt-2" />
      <div className="m-4 items-center flex flex-col">
        <p className="text mt-8">
          กรณีต้องการแจ้งงานประกันภัยอื่นๆ, งานรถยนต์ที่ไม่มีในระบบ,
          งานสลักหลังหรือยกเลิกรายการ
        </p>
        <p className="text pb-24">กรุณาติดต่อเจ้าหน้าที่เพื่อสอบถามข้อมูล</p>
        <div className="flex flex-row space-around w-full items-center justify-center h-full divide-x">
          <div className="text-center flex-1 flex justify-end self-start">
            <div className="mr-16">
              <img className="qrcode" src={require(t('qrLinePath'))} />
              <h4 className="text-line">ติดต่อผ่านไลน์ {' '}
                <a className="underline" href={lineLink['url']} target="_blank" >{lineLink['name']}</a>
              </h4>
            </div>
          </div>
          <div className="flex flex-1 justify-start flex-col pl-16">
            <div className="flex flex-row space-x-4">
              <div className="flex h-full text-icon-box bg-white justify-center items-center">
                <span className="hero-icon-arrowphone text-icon"></span>
              </div>
              <div className="text">
                {t('dataToContract', { returnObjects: true }).map ((v) => (
                  <React.Fragment key={`${v.info}`}>
                    <a href={`tel:${t('phoneNumberToContract')}`}>{v.contact}</a>
                    <p className=" text-base"> {v.info} </p>
                  </React.Fragment>
                ))}
                <p className="pt-5">เวลา: {`${t('timeToContact')} (${t('dayToContact')})`}</p>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="flex h-full text-icon-box bg-white justify-center items-center">
                <span className="hero-icon-mail text-icon"></span>
              </div>
              <div className="text flex items-center">
                <a href={`mailto:${t('contactMail')}`}>{t('contactMail')}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
