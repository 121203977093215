import React from "react";
import { PolicyTypeMapping } from "../../../../helpers/mappingData";
import { reformatDateTH } from "../../../../helpers/date";
import { formatNumberWithCurrency } from "../../../../helpers/formatNumber";
import { LoadStatus } from "../../../../types/load";
import { Policy } from "../../../../types/policy";
import Col from "../components/Col";
import { ProductTypeMapping } from "../../../../helpers/mappingData";

interface Props {
  loadStatus?: LoadStatus;
  data?: Policy[];
}

const btClasses: string =
  "px-4 py-2 mx-2 font-semibold text-center text-white transition duration-200 ease-in rounded-lg shadow-md bg-secondary hover:bg-secondary-dark focus:ring-indigo-500 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2";

const Table: React.FC<Props> = ({ loadStatus = "loaded", data }) => {
  const renderCols = (numRows: number, index: number): JSX.Element => {
    const cols = [];
    for (let i = 1; i <= numRows; i++) {
      cols.push(
        <td
          key={i + "_" + index}
          className="px-2 py-4 space-x-4 bg-white border-b-2 border-gray-300 animate-pulse"
        >
          <div className="w-full h-24 bg-gray-200 rounded-sm"></div>
        </td>
      );
    }
    return <>{cols}</>;
  };

  const renderLoadingRows = (numRows: number): JSX.Element => {
    const rows = [];
    for (let i = 1; i <= 5; i++) {
      rows.push(<tr key={i}>{renderCols(numRows, i)}</tr>);
    }
    return <>{rows}</>;
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <Col text="เลขที่กรมธรรม์เดิม" />
            <Col text="รหัสตัวแทน" />
            <Col text="ข้อมูลลูกค้า" />
            <Col text="บริษัท" classes="w-60" />
            <Col text="แผน/ประเภทประกัน" />
            <Col text="วันที่คุ้มครอง" />
            <Col text="ทุนประกัน" />
            <Col text="เบี้ยประกันที่ชำระ" />
            <Col classes="w-40" />
          </tr>
        </thead>
        <tbody>
          {loadStatus === "loaded" && (
            <>
              {data?.map((o, i) => (
                <tr key={i}>
                  <Col component="td" text={<>{o.policy_no}</>} />
                  <Col component="td" text={o.agent_number} />
                  <Col
                    component="td"
                    text={
                      <>
                        <p>{`${o.insured_title} ${o.insured_firstname} ${o.insured_lastname}`}</p>
                        {o.order_type !== "custom" && (
                          <div>
                            <p>{`${o.quotation.criteria?.car_brand} ${o.quotation.criteria?.car_model} ${o.quotation.criteria?.year}`}</p>
                            <p>เลขทะเบียน {`${o.plate_number}`}</p>
                          </div>
                        )}
                      </>
                    }
                  />
                  <Col
                    component="td"
                    text={<img src={o.insurer_icon} alt="" className="w-8 shadow rounded-5" />}
                  />
                  <Col
                    component="td"
                    text={
                      <>
                        <p>{PolicyTypeMapping[o.policy_type]}</p>
                        <small className="p-1 bg-gray-300 rounded-sm">
                          {ProductTypeMapping[o.product_type]}
                        </small>
                        {o.order_type && (
                          <small className="p-1 ml-4 bg-gray-300 rounded-sm">
                            {o.order_type.toUpperCase()}
                          </small>
                        )}
                      </>
                    }
                  />
                  <Col
                    component="td"
                    text={
                      <>
                        <p>เริ่ม: {reformatDateTH(o.coverage_from)}</p>
                        <p>ถึง: {reformatDateTH(o.coverage_to)}</p>
                      </>
                    }
                  />
                  <Col component="td" text={formatNumberWithCurrency(o.sum_insured)} />
                  <Col component="td" text={formatNumberWithCurrency(o.total_amount)} />
                  <Col
                    component="td"
                    text={
                      <a
                        href={`renewal/${o.order_id}/create/${o.policy_type}`}
                        className={btClasses}
                      >
                        แจ้งต่ออายุ
                      </a>
                    }
                  />
                </tr>
              ))}
              {data?.length == 0 && (
                <tr>
                  <td
                    colSpan={9}
                    className="px-4 py-4 text-center bg-white border-b-2 border-gray-200"
                  >
                    ไม่พบข้อมูล
                  </td>
                </tr>
              )}
            </>
          )}
          {loadStatus === "loading" && renderLoadingRows(9)}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
