import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { differenceInYears } from "date-fns";
import { useTranslation } from "react-i18next";

import { FormGroup, TextField, SelectField, Switcher, DatePickerField, Checkbox } from "../form";
import LocationForm from "../form/locationForm";

import { getAddressPositionByType } from "~src/helpers/addressPosition";
import { parseDate, dateFormat } from "~src/helpers/dateTime";
import { getInsuredDeliveryTypeAsArray } from "~src/helpers/insuredHelper";
import { messages } from "~src/helpers/messages";
import { getFinancialInstitution } from '~/src/api/FinancialInstitution';
import {
  titleTHOptions,
  titleRenewTHOptions,
  deliveryTypeOptions,
} from "~src/helpers/options";

interface EditInsuredCardProps {
  submitId: string;
  insured: any;
  insuredCategory: string;
  contract: string;
  // canSubmit: boolean;
  formRef: (el: HTMLFormElement) => void;
  onSubmit: (arg: any) => void;
  onInsuredChanged: (arg: any) => void;
  onInsuredCategoryChanged: (arg: string) => void;
}

interface IFormInputs {
  order: {
    insureds_attributes: {
      beneficiary_attributes: any;
      company_name: string;
      company_tax: string;
      title: string;
      firstname: string;
      lastname: string;
      birthday: string;
      idcard: string;
      phone_number: string;
      email: string;
    };
  };
}

const EditInsuredCard: React.FC<EditInsuredCardProps> = ({
  submitId,
  insured,
  insuredCategory,
  contract,
  // canSubmit,
  formRef,
  onSubmit,
  onInsuredChanged,
  onInsuredCategoryChanged,
}) => {
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm<IFormInputs>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const [copied, setCopied] = useState(true);
  const [financeNameOptions, setFinanceNameOptions] = useState([]);
  const attrPrefixInsured = "order[insureds_attributes]";
  const attrPrefixBenef = "order[insureds_attributes][][beneficiary_attributes]";
  const attrPrefixDeliveryAddress = "order[delivery_address_attributes]";
  const optionsInsuredCategory = t("insuredCategory", { returnObjects: true });

  const deliveryTypes = getInsuredDeliveryTypeAsArray(insured?.delivery_type);

  useEffect(() => {
    setCopied(insured.delivery_address_type !== "other_address");
    getFinancialInstitution().then(result => {
      setFinanceNameOptions(result.data.content.map((institution) => (
        { label: institution.name, 
          value: institution.code 
        }
      )));
    });
  }, []);

  const isDeliveryTypeChecked = (typeId: number) => {
    return deliveryTypes.includes(typeId);
  };

  const handleChangeInsuredCategory = (e) => {
    const { value } = e.target;
    let idCard = "";
    if (value === "person") {
      idCard = insured.idcard;
    }

    onInsuredChanged({
      ...insured,
      idcard: idCard,
      company_name: "",
      company_tax: "",
    });
    onInsuredCategoryChanged(value);
  };

  const handleChangeInsured = (e, name) => {
    const { value } = e.target;
    onInsuredChanged({
      ...insured,
      [name]: value,
    });
  };

  const handleChangeInsuredIdCard = (e, name) => {
    const value = e.target.value.replace(/[^0-9]/gi, "");
    const count = value.split("");
    if (count.length > 13) return;
    onInsuredChanged({
      ...insured,
      [name]: value,
    });
  };

  const handleChangeBirthDate = (date) => {
    onInsuredChanged({
      ...insured,
      birthday: date,
      age: differenceInYears(new Date(date), new Date()) * -1,
    });
  };

  const handleChangeAddressField = (e, name, type) => {
    const { value } = e.target;
    const pos = getAddressPositionByType(type);
    const { addresses } = insured;
    addresses[pos] = {
      ...addresses[pos],
      [name]: value,
    };
    if (copied) {
      addresses[1] = addresses[pos];
    }
    onInsuredChanged({
      ...insured,
      addresses,
    });
  };

  const handleChangeAddress = (obj, type) => {
    const pos = getAddressPositionByType(type);
    const { addresses } = insured;
    addresses[pos] = {
      ...addresses[pos],
      ...obj,
    };
    if (copied) {
      addresses[1] = addresses[pos];
    }
    onInsuredChanged({
      ...insured,
      addresses,
    });
  };

  const handleChangeDeliveryType = (evt) => {
    const { value, checked } = evt.target;
    const index = deliveryTypes.indexOf(parseInt(value));
    if (checked) {
      if (index === -1) deliveryTypes.push(parseInt(value));
    } else if (deliveryTypes.length > 1) deliveryTypes.splice(index, 1);

    onInsuredChanged({
      ...insured,
      delivery_type: deliveryTypes,
    });
  };

  const handleChangeDeliveryAddressType = (evt) => {
    const { value } = evt.target;
    const isCopied = !copied;
    setCopied(isCopied);

    onInsuredChanged({
      ...insured,
      delivery_address_type: value,
      addresses: [insured.addresses[0], isCopied ? insured.addresses[0] : insured.addresses[1]],
    });
  };

  const handleChangeBeneficiaryType = (evt) => {
    const { value } = evt.target;
    onInsuredChanged({
      ...insured,
      beneficiary: {
        ...insured.beneficiary,
        receiver: value,
        remark: "",
      },
    });
  };

  const handleChangeBeneficiary = (evt, name) => {
    const { value } = evt.target;
    onInsuredChanged({
      ...insured,
      beneficiary: {
        ...insured.beneficiary,
        [name]: value,
      },
    });
  };

  // TODO: useMemo ?
  const fields = [
    <div className="col-span-2" key="insuredCategory">
      <FormGroup label="ประเภทผู้เอาประกัน">
        <Switcher
          id="order[insured_category]"
          name="order[insured_category]"
          active={insuredCategory}
          onChange={handleChangeInsuredCategory}
          labels={optionsInsuredCategory}
        />
      </FormGroup>
    </div>,
    insuredCategory == "legal_entity" && (
      <div className="col-span-1" key="companyName">
        <FormGroup label="ชื่อบริษัทหรือองค์กร" htmlForLabel="company_name" require>
          <TextField
            id="company_name"
            name={`${attrPrefixInsured}[][company_name]`}
            value={insured.company_name}
            onChange={(evt) => handleChangeInsured(evt, "company_name")}
            inputRef={register(messages.required)}
            error={errors.order?.insureds_attributes?.company_name?.message}
          />
        </FormGroup>
      </div>
    ),
    insuredCategory == "legal_entity" && (
      <div className="col-span-1" key="companyTax">
        <FormGroup
          label="เลขประจำตัวผู้เสียภาษีของบริษัท/องค์กร"
          htmlForLabel="company_tax"
          require
        >
          <TextField
            id="company_tax"
            name={`${attrPrefixInsured}[][company_tax]`}
            value={insured.company_tax}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeInsuredIdCard(e, "company_tax");
            }}
            inputRef={register(messages.required)}
            error={errors.order?.insureds_attributes?.company_tax?.message}
            maxLength={13}
          />
        </FormGroup>
      </div>
    ),
    process.env.APP_NAME != "mti" && insuredCategory == "legal_entity" && (
      <h6 className="col-span-2" key="insuredHeader">
        ข้อมูลผู้เอาประกันเพิ่มเติม (บุคคล)
      </h6>
    ),
    <div className="col-span-1" key="insuredTitle">
      <FormGroup label="คำนำหน้าชื่อ" htmlForLabel="insured_title" require>
        <SelectField
          id="insured_title"
          name={`${attrPrefixInsured}[][title]`}
          value={insured.title}
          onChange={(e) => handleChangeInsured(e, "title")}
          inputRef={register(messages.requiredSelect)}
          error={errors.order?.insureds_attributes?.title?.message}
          options={contract === "renew" ? titleRenewTHOptions : titleTHOptions}
        />
      </FormGroup>
    </div>,
    <div className="col-span-1 col-start-1" key="insuredFirstname">
      <FormGroup label="ชื่อ" htmlForLabel="insured_firstname" require>
        <TextField
          id="insured_firstname"
          name={`${attrPrefixInsured}[][firstname]`}
          value={insured.firstname}
          onChange={(e) => handleChangeInsured(e, "firstname")}
          inputRef={register(messages.required)}
          error={errors.order?.insureds_attributes?.firstname?.message}
        />
      </FormGroup>
    </div>,
    <div className="col-span-1" key="insuredLastname">
      <FormGroup label="นามสกุล" htmlForLabel="insured_lastname" require>
        <TextField
          id="insured_lastname"
          name={`${attrPrefixInsured}[][lastname]`}
          value={insured.lastname}
          onChange={(evt) => handleChangeInsured(evt, "lastname")}
          inputRef={register(messages.required)}
          error={errors.order?.insureds_attributes?.lastname?.message}
        />
      </FormGroup>
    </div>,
    insuredCategory && (
      <div className="col-span-1" key="insuredBirthday">
        <FormGroup label="วันเดือนปีเกิด" htmlForLabel="insured_birthday" require>
          <DatePickerField
            type="text"
            id="insured_birthday"
            name={`${attrPrefixInsured}[][birthday]`}
            defaultView="decade"
            maxDate={parseDate(dateFormat(new Date()))}
            onChange={(evt) => handleChangeInsured(evt, "birthday")}
            callbackvalue={handleChangeBirthDate}
            inputRef={register({
              ...messages.required,
              ...messages.validateBirthday,
            })}
            value={insured.birthday}
            error={errors.order?.insureds_attributes?.birthday?.message}
          />
        </FormGroup>
      </div>
    ),
    insuredCategory === "person" && (
      <div className="col-span-1" key="insuredIdCard">
        <FormGroup
          label="เลขที่บัตรประชาชน / เลขประจําตัวผู้เสียภาษี"
          htmlForLabel="insured_idcard"
          require
        >
          <TextField
            id="insured_idcard"
            name={`${attrPrefixInsured}[][idcard]`}
            value={insured.idcard}
            onChange={(evt) => handleChangeInsuredIdCard(evt, "idcard")}
            inputRef={register({
              ...messages.required,
              ...messages.validateIdCard,
            })}
            error={errors.order?.insureds_attributes?.idcard?.message}
          />
        </FormGroup>
      </div>
    ),
    <div className="col-span-1" key="insuredPhoneNumber">
      <FormGroup label="เบอร์มือถือ" htmlForLabel="insured_phone_number" require>
        <TextField
          id="insured_phone_number"
          name={`${attrPrefixInsured}[][phone_number]`}
          value={insured.phone_number}
          onChange={(evt) => handleChangeInsured(evt, "phone_number")}
          inputRef={register({
            ...messages.required,
            ...messages.mobileNumber,
          })}
          error={errors.order?.insureds_attributes?.phone_number?.message}
        />
      </FormGroup>
    </div>,
    <div className="col-span-1" key="insuredEmail">
      <FormGroup label="อีเมล" htmlForLabel="insured_email">
        <TextField
          id="insured_email"
          name={`${attrPrefixInsured}[][email]`}
          value={insured.email}
          onChange={(evt) => handleChangeInsured(evt, "email")}
          inputRef={register(messages.email)}
          error={errors.order?.insureds_attributes?.email?.message}
        />
      </FormGroup>
    </div>,
    <h6 className="col-span-2" key="insuredAddressHeader">
      ที่อยู่ผู้เอาประกัน
    </h6>,
    <div className="col-span-2 mt-1" key="insuredAddress">
      <FormGroup label="ที่อยู่ / บริษัท" htmlForLabel="insured_address" require>
        <TextField
          id="insured_address"
          name={`${attrPrefixInsured}[][address]`}
          value={insured.addresses[0].address}
          onChange={(evt) => handleChangeAddressField(evt, "address", "insured")}
          inputRef={register(messages.required)}
        />
      </FormGroup>
    </div>,
    <div
      className="grid grid-cols-1 col-span-2 gap-x-4 gap-y-0 sm:grid-cols-2"
      key="insuredLocationForm"
    >
      <LocationForm
        address={insured.addresses[0]}
        name={attrPrefixInsured}
        onAddressChanged={(obj) => handleChangeAddress(obj, "insured")}
        register={register}
        errors={errors}
      />
    </div>,
    <h6 className="col-span-2" key="deliveryAddressHeader">
      ที่อยู่ในการจัดส่งกรมธรรม์ <span className="text-red-700 ml-1">*</span>
    </h6>,
    <div className="col-span-2 mb-3 grid gird-cols-1 sm:grid-cols-2" key="deliveryType">
      {deliveryTypeOptions.map((option, index) => (
        <div key={option.value} className="col-span-1 mt-1">
          <Checkbox
            name={`${attrPrefixInsured}[][delivery_type][]`}
            id={`insured_delivery_type_${option.value}`}
            key={option.value}
            label={option.label}
            emailLabel={index === 0 && insured.email}
            value={option.value}
            checked={isDeliveryTypeChecked(option.value)}
            checkedAria={isDeliveryTypeChecked(option.value)}
            inputRef={register}
            onChange={handleChangeDeliveryType}
          />
        </div>
      ))}
    </div>,
    insured?.delivery_type?.includes(2) && (
      <div className="col-span-2" key="deliveryAddressType">
        <FormGroup require>
          <Switcher
            id="delivery_address_type"
            name={`${attrPrefixInsured}[][delivery_address_type]`}
            active={insured.delivery_address_type}
            onChange={(evt) => handleChangeDeliveryAddressType(evt)}
            labels={[
              {
                label: "ที่อยู่ผู้เอาประกัน",
                value: "insured_address",
              },
              {
                label: "ที่อยู่อื่นๆ",
                value: "other_address",
              },
            ]}
          />
        </FormGroup>
      </div>
    ),
    isDeliveryTypeChecked(deliveryTypeOptions[1].value) && (
      <div className="col-span-2" key="deliveryAddress">
        <FormGroup label="ชื่อผู้รับ - ที่อยู่ / บริษัท" htmlForLabel="delivery_address" require>
          <TextField
            id="delivery_address"
            name={`${attrPrefixDeliveryAddress}[][address]`}
            value={insured.addresses[1].address}
            onChange={(evt) => handleChangeAddressField(evt, "address", "delivery")}
            inputRef={register(messages.required)}
            readOnly={copied}
          />
        </FormGroup>
      </div>
    ),
    isDeliveryTypeChecked(deliveryTypeOptions[1].value) && (
      <div
        className="grid grid-cols-1 col-span-2 gap-x-4 gap-y-0 sm:grid-cols-2"
        key="deliveryLocationForm"
      >
        <LocationForm
          address={insured.addresses[1]}
          name={attrPrefixDeliveryAddress}
          onAddressChanged={(obj) => handleChangeAddress(obj, "delivery")}
          readOnly={copied}
          register={register}
          errors={errors}
        />
      </div>
    ),
    <h6 className="col-span-2" key="beneficiaryHeader">
      ข้อมูลผู้รับผลประโยชน์
    </h6>,
    <div className="col-span-2" key="beneficiaryType">
      <FormGroup label="ผู้รับผลประโยชน์" require>
        <Switcher
          id="beneficiary"
          name={`${attrPrefixBenef}[receiver]`}
          active={insured.beneficiary.receiver}
          onChange={(evt) => handleChangeBeneficiaryType(evt)}
          labels={[
            {
              label: "ทายาทโดยธรรม",
              value: "legal_heir",
            },
            {
              label: "สถาบันการเงิน",
              value: "finance",
            },
          ]}
        />
      </FormGroup>
    </div>,
    insured.beneficiary.receiver == "finance" && (
      <div className="col-span-2" key="financeName">
        <FormGroup label="สถาบันการเงิน" htmlForLabel="finance_name" require>
          <SelectField
            id="finance_name"
            name={`${attrPrefixBenef}[financial_institution_code]`}
            value={insured.beneficiary.financial_institution_code}
            onChange={(evt) => handleChangeBeneficiary(evt, "financial_institution_code")}
            inputRef={register(messages.requiredSelect)}
            options={financeNameOptions}
            error={errors?.order?.insureds_attributes?.beneficiary_attributes?.financial_institution_code?.message}
          />
        </FormGroup>
      </div>
    ),
  ];

  return (
    <div
      className="block text-left align-bottom bg-white rounded-lg"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <form id={submitId} ref={formRef} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="container grid items-start grid-cols-1 mt-0 ml-0 text-left gap-x-4 gap-y-0 justify-items-stretch sm:grid-cols-2">
              <h6 className="col-span-2">ข้อมูลผู้ถือกรมธรรม์</h6>
              <p className="col-span-2 mt-2 mb-4 text-primary">
                ** ผลิตภัณฑ์นี้ใช้สำหรับรถยนต์ส่วนบุคคลเท่านั้น
                ไม่รองรับรถยนต์ที่นำไปใช้ในเชิงพาณิชย์
              </p>
              {[fields]}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditInsuredCard;
