import React, { useState, useEffect, useReducer, Reducer } from "react";
import { useForm } from "react-hook-form";
import { match as Match } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import Payslip from "~src/pages/agent/checkout/orders/upload/Payslip";
import IdCard from "~src/pages/agent/checkout/orders/upload/IdCard";
import { Button } from "~src/components/form";
import QueryString from 'query-string'

import dictionary from "~src/data.yaml";
import { useAgentOrdersAPI } from "~src/api/agent/Orders";
import { useAppContext } from "~src/context/App";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { toISODate } from "~src/helpers/date";
import {
  decimalToString,
  formatNumberWithCurrency,
  formatNumberWithCurrency2,
} from "~src/helpers/formatNumber";
import { messages } from "~src/helpers/messages";
import useOrder from "~src/hooks/useOrder";
import { CheckoutSummaryState } from "~src/models/CheckoutState";
import InstallmentInfo from "~src/models/InstallmentInfo";
import ThankyouPageReducer, {
  Action as ThankyouPageAction,
  createUpdateAction,
  createUpdateActionWithPayment,
  initState as initThankyouPageState,
  State as ThankyouPageState,
} from "~src/reducer/ThankyouPageReducer";
import { Order } from "~src/types/order";
import { Payment } from "~src/types/payment";

// import "./upload-payslip.scss";
import { ThankyouPageContextProvider, useThankyouPageContext } from "~src/context/ThankyouPage";

export interface UploadPayslipPageState {
  orderId: number;
  summary: CheckoutSummaryState;
  paymentMethod: string;
  totalAmount: string;
  payment?: Payment;
  installment?: InstallmentInfo;
  startDate?: string | Date;
}

export interface UploadPayslipPageProps {
  match: Match<any>;
}

export default function UploadPayslipPage(props) {
  return (
    <PageContextProvider>
      <UploadPayslipPageInner {...props} />
    </PageContextProvider>
  );
}

interface UploadPayslipPageInnerProps extends UploadPayslipPageProps {}

function UploadPayslipPageInner(props: UploadPayslipPageInnerProps) {
  const location = useLocation<UploadPayslipPageState>();

  const { summary, payment, paymentMethod, installment, totalAmount, startDate } = location.state;

  const orderId = location.state.orderId || props.match.params.order_id;
  const { search } = window.location;
  const [querires] = useState(QueryString.parse(search));
  const { appConfig } = useAppContext();
  const { onError, setAlert, setPreloading } = usePageContext();
  const { isLoading, order } = useOrder(orderId, {
    queryParams: { token: querires.token },
    onError,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPayment, setCurrentPayment] = useState<Payment | undefined>(payment);

  useDeepCompareEffectNoCheck(() => {
    setPreloading(isLoading);
    if (!isLoading && order) {
      const _payment =
        order.payments.find((payment) => payment.status !== "paid") ||
        order.payments[order.payments.length - 1];
      // TEST
      console.log("currentPayment:", currentPayment);
      setCurrentPayment(_payment);

  }, [isLoading, order, paymentMethod, summary]);

  return (
    <>
      {!isLoading && (
        <>
          {order ? (
            <ThankyouPageContextProvider
              token={querires.token}
              isPublic={true}
              summary={summary}
              order={order}
              paymentMethod={paymentMethod}
              setPreloading={setPreloading}
            >
              <UploadPayslipForm
                token={querires.token}
                summary={summary}
                order={order}
                paymentMethod={paymentMethod}
                totalAmount={totalAmount}
                payment={currentPayment}
                installment={installment}
                startDate={startDate}
              />
            </ThankyouPageContextProvider>
          ) : (
            <div>
              <h1 className="mt-4 text-center">Order is not available.</h1>
              {onError && onError(new Error("Order is not available"))}
            </div>
          )}
        </>
      )}
    </>
  );
}

export interface UploadPayslipFormProps {
  summary: CheckoutSummaryState;
  order: Order;
  paymentMethod: string;
  totalAmount: string;
  // ordersAPI: OrdersAPIClient;
  payment?: Payment;
  installment?: InstallmentInfo;
  startDate?: string | Date;
}

function UploadPayslipForm({
  token,
  summary,
  order,
  totalAmount,
  // ordersAPI,
  paymentMethod,
  payment,
  installment,
  startDate,
}) {
  const { appConfig } = useAppContext();
  const { onError, setAlert, setPreloading } = usePageContext();
  const { dispatch: dispatchThankyou, gotoPage: gotoThankyou } = useThankyouPageContext();

  const { ordersAPI } = useAgentOrdersAPI({
    onError: setAlert,
  });


  const { insurerName, insurerIcon, policyType, details } = summary;
  const { order_id: orderID } = order;
  const amount = payment?.required_amount ? decimalToString(payment.required_amount) : totalAmount;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    payment && dispatchThankyou(createUpdateActionWithPayment(payment));
  }, [payment]);

  function postPayslip(_, event) {
    let data = new FormData(event.target);
    // data.append("payment[payment_method]", paymentMethod);
    // DEPRECATED: Remove this after deploying Installment+Payment Link feature
    installment && data.append("payment[payment_count]", `${installment.payment_count}`);

    (async function () {
      let apiName, _respData;
      try {
        setAlert(undefined);
        setPreloading(true);
        setIsSubmitting(true);
        if (paymentMethod === "installment") {
          // DEPRECATED: Remove else block after deploying Installment+Payment Link feature
          if (appConfig.paymentLinksEnabled) {
            throw new Error("DEPRECATED: This page do not support Installment payment anymore");
          }
          // else {
          //   apiName = "create_installment";
          //   _respData = await ordersAPI.createInstallment(orderID, data);
          // }
        }

        apiName = "submit_payslip";
        _respData = await ordersAPI.addPayslip(orderID, data);

        dispatchThankyou(
          createUpdateAction({
            status: "completed",
            amount,
          })
        );

        gotoThankyou();
      } catch (err) {
        setAlert(err.message, err.details ? { [apiName]: err.details } : undefined);
        setIsSubmitting(false);
        setPreloading(false);
      }
    })();
  }

  return (
    <section className="upload-payslip">
      <div className="row justify-content-center">
        <div className="col-md-5">
          <div className="card">
            <h1>
              <span className="icon icon-document"></span>
              ส่งหลักฐานการชำระเงิน
            </h1>
            <div className="upload-payslip-content">
              <div className="row">
                <div className="col-12">
                  เลขที่ใบสั่งซื้อ <strong>{order.number}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="insurer-detail">
                    <div className="insurer-detail-img">
                      <img src={insurerIcon} />
                    </div>
                    <div className="insurer-detail-text">
                      <h3>{insurerName}</h3>
                      <p className="small">
                        {details}
                      </p>
                      <p className="price">{formatNumberWithCurrency(amount)}</p>
                      {paymentMethod === "installment" && (
                        <p className="small">ผ่อนชำระ {installment.payment_count} งวด</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="upload-box">
                    <form
                      autoComplete="off"
                      onSubmit={handleSubmit(postPayslip)}
                      encType="multipart/form-data"
                    >

                      <input type="hidden" name="token" value={token} />
                      <input type="hidden" name="payment[order_id]" value={orderID} />
                      <input type="hidden" name="payment[token]" value={payment?.token || ""} />
                      <input type="hidden" name="payment[amount]" value={amount} />
                      <input type="hidden" name="payment[payment_method]" value={paymentMethod} />
                      <input
                        type="hidden"
                        name="payment[start_date]"
                        value={startDate ? toISODate(startDate) : ""}
                      />
                      <input
                        type="hidden"
                        name="payment[description]"
                        value={payment?.description || ""}
                      />
                      <Payslip
                        id="payslip"
                        name="payment[payslip]"
                        errors={errors.payment?.payslip?.message}
                        register={register({
                          ...messages.uploadDocRequired,
                          ...messages.allowFileType,
                        })}
                      />
                      {paymentMethod === "installment" && (
                        <IdCard
                          id="id-card"
                          name="payment[id_card]"
                          errors={errors.payment?.id_card?.message}
                          register={register({
                            ...messages.uploadDocRequired,
                            ...messages.allowFileType,
                          })}
                        />
                      )}
                      <div className="flex mt-4 justify-content-center">
                        <Button type="submit" color="primary" disabled={!ordersAPI || isSubmitting}>
                          แนบเอกสารการชำระเงิน
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
