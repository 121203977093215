import React, { ChangeEvent } from "react";

import { useFormContext } from "react-hook-form";

import { checkEmpty } from "~/src/helpers/helpers";
import { parseDate, dateFormat } from "~/src/helpers/dateTime";

import {
  FormGroup,
  TextField,
  DatePickerField,
  SelectField,
  Switcher,
} from "~/src/components/form";

import {
  titleAgentTHOptions,
  activeStatusOptions,
  bankOptions,
  mtlSalesBranchOptions,
  educationLevelOptions,
} from "~/src/helpers/options";
import { messages } from "~/src/helpers/messages";
import { useAppDispatch, useAppSelector } from "~src/store";
import { agentInfoSelector, handleChangeAgentInfo } from "./registerSlice";

const RecommenderInfo = () => {
  const methods = useFormContext();
  const agenInfo = useAppSelector(agentInfoSelector);
  const dispatch = useAppDispatch();
  return (
    <div>
      <div className="flex items-center justify-between w-full col-span-1 col-start-1 text-xl bg-custom-gray-sm mb-3">
        <span className="p-2">ข้อมูลผู้แนะนำ(ถ้ามี)</span>
      </div>

      <div className="grid grid-cols-2">
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="รหัสสมาชิก (ผู้แนะนำ)" htmlForLabel="recommender_code">
              <TextField
                id="recommender_code"
                name="recommender_code"
                inputRef={methods.register(messages.recommend_id)}
                error={methods.errors.recommender_code?.message}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  dispatch(handleChangeAgentInfo("recommender_code", e.target.value))
                }
                value={agenInfo.recommender_code}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommenderInfo;
