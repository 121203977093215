import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { format, addYears } from "date-fns";
import classnames from "classnames";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AddIcon from "@mui/icons-material/Add";
import Label from "../../../../components/Forms/Label";
import Select from "../../../../components/Forms/Select";
import DatePicker from "../../../../components/Forms/DatePicker";
import TextInput from "../../../../components/Forms/TextInput";
import FileInput from "../../../../components/Forms/FileInput";
import { TextField } from "../../../../components/form";
import useAxios from "../../../../hooks/useAxios";
import { renewConText } from "../store";
import { formatNumberWithCurrency } from "../../../../helpers/formatNumber";
import { PolicyTypeMapping, RepairFacilityMapping } from "../../../../helpers/mappingData";
import { calculatePrice } from "~src/helpers/helpers";
import { messages } from "../../../../helpers/messages";
import { Insurer } from "../../../../types/insurer";
import { Quote } from "../../../../types/quote";
import { Details, OrderPaymentsSummaryLineItem } from "~src/types/order";
import { PolicyType } from "../../../../types/policy";
import { RepairFacility } from "../../../../types/repairFacility";

import CustomMotorProduct from "./CustomMotorProduct";
import CustomOtherProduct from "./CustomOtherProduct";

import useTotalPremium from "../hooks/useTotalPremium";

interface Props {
  onToggle: () => void;
  active: boolean;
  errors: any;
  register: any;
  requireAttachment?: boolean;
}

interface InsurerResponse {
  content: Insurer[];
}

type CoveredDate = {
  start: Date;
  end: Date;
};

const policyTypeOptions: PolicyType[] = ["1", "2+", "3", "3+"];
const cmiPolicyTypeOptions: PolicyType[] = ["cmi"];
const repairFactoryOptions: RepairFacility[] = ["dealer", "garage"];
const CURRENT_DATE = new Date();
const PolicyInfo: React.FC<Props> = ({ onToggle, active, errors, register, requireAttachment = true }) => {
  const { state, dispatch } = React.useContext(renewConText);
  const { type } = useParams<{ type: string }>();
  const isMotor = ["1", "2", "2+", "3", "3+"].includes(type);

  const insurersData = useAxios<InsurerResponse>(`/api/insurers`);

  const lineItemAsVMI: OrderPaymentsSummaryLineItem[] = React.useMemo(() => {
    return state.renew?.payments_summary?.line_itemas.filter((o) => o.policy_type === type);
  }, [state]);

  const total = useTotalPremium(state, type);

  const handleChangeQuote = (
    evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    name: keyof Quote
  ): void => {
    const quotes = [...state.renew.quotes] as Quote[];
    quotes[0] = {
      ...state.renew.quotes[0],
      [name]: evt.target.value,
    };

    let coverages = state.renew.coverages;
    if (name == "sum_insured") {
      coverages = {
        ...state.renew.coverages,
        fire_theft_damage_coverage: evt.target.value,
        natural_disaster_coverage: evt.target.value,
      };
    }

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          quotes,
          coverages,
        },
      },
    });
  };

  const handleChangeCoverDate = (date: Date): void => {
    const newYear: Date = addYears(date, 1);
    const details: Details = { ...state.renew.details };
    details.cover_start_date = format(date, "yyyy-MM-dd");
    details.cover_end_date = format(newYear, "yyyy-MM-dd");

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          details,
        },
      },
    });
  };

  const handleChangeAddonCoverDate = (date: Date): void => {
    const newYear: Date = addYears(date, 1);
    const details: Details = { ...state.renew.details };
    details.addon_start_date = format(date, "yyyy-MM-dd");
    details.addon_end_date = format(newYear, "yyyy-MM-dd");

    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          details,
        },
      },
    });
  };

  const handleChangePaymentLineItemAs = (
    evt: React.ChangeEvent<HTMLInputElement>,
    name: keyof OrderPaymentsSummaryLineItem
  ): void => {
    const { value } = evt.target;
    const prices = calculatePrice(
      parseFloat(value),
      name,
      state.renew?.checked_addon ? parseFloat(state.renew?.addon_selling_price) : 0,
      lineItemAsVMI[0]
    );

    const lineItemAs: OrderPaymentsSummaryLineItem[] = [];
    state.renew?.payments_summary?.line_itemas.forEach((lineItem: OrderPaymentsSummaryLineItem) => {
      if (lineItem.policy_type !== "cmi") {
        (lineItem as any) = Object.assign(lineItem, prices);
        (lineItem as any)[name] = value;
      }
      lineItemAs.push(lineItem);
    });

    updatePaymentLineItemAs(lineItemAs);
  };

  const updatePaymentLineItemAs = (line_itemas: OrderPaymentsSummaryLineItem[]): void => {
    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          payments_summary: {
            ...state.renew.payments_summary,
            line_itemas,
          },
        },
      },
    });
  };

  const handleChangeFile = (
    evt: React.ChangeEvent<HTMLInputElement>,
    key: "vmi_renewal_file" | "cmi_renewal_file" | "renewal_file"
  ): void => {
    const files: FileList = evt.target.files;
    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          [key]: files[0],
        },
      },
    });
  };

  const handleChangeWarningDate = (
    date: Date,
    key: "vmi_warning_date" | "cmi_warning_date" | "warning_date"
  ): void => {
    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          [key]: format(date, "yyyy-MM-dd"),
        },
      },
    });
  };

  const handleChangeCoverages = (
    evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    key: string
  ): void => {
    dispatch({
      type: "ADD",
      payload: {
        isEdit: true,
        renew: {
          ...state.renew,
          coverages: {
            ...state.renew.coverages,
            [key]: evt.target.value,
          },
        },
      },
    });
  };

  const vmiCoveredDate: CoveredDate = useMemo(() => {
    if (state.renew?.details?.cover_start_date) {
      const startDate = new Date(state.renew.details.cover_start_date);
      const endDate = new Date(state.renew?.details?.cover_end_date) || addYears(startDate, 1);

      return { start: startDate, end: endDate };
    } else {
      return { start: CURRENT_DATE, end: addYears(CURRENT_DATE, 1) };
    }
  }, [state.renew?.details?.cover_start_date]);

  const cmiCoveredDate: CoveredDate = useMemo(() => {
    if (state.renew?.details?.addon_start_date) {
      const startDate = new Date(state.renew.details.addon_start_date);
      const endDate = new Date(state.renew?.details?.addon_end_date) || addYears(startDate, 1);

      return { start: startDate, end: endDate };
    } else {
      return { start: CURRENT_DATE, end: addYears(CURRENT_DATE, 1) };
    }
  }, [state.renew?.details?.addon_start_date]);

  return (
    <div className="p-4 mb-4 bg-white shadow rounded-5">
      <h6 className="flex items-center justify-between p-1 px-2 text-sm font-bold bg-gray-100">
        ข้อมูลกรมธรรม์
        <button
          type="button"
          onClick={onToggle}
          className="flex items-center justify-center w-6 h-6 bg-white shadow outline-none rounded-5 focus:outline-none"
        >
          {active ? <RemoveIcon /> : <AddIcon />}
        </button>
      </h6>
      <div className={classnames(["px-4 mt-3", !active ? "hidden" : "block"])}>
        {state.renew?.quotation.product_type === "motor" && state.renew?.order_type !== "custom" ? (
          <>
            <div className="mt-6 mb-3 text-lg font-bold text-secondary">
              ข้อมูลประกันภัยรถยนต์ภาคสมัครใจ
            </div>
            <div className="flex flex-wrap items-center -mx-2">
              <div className="w-1/3 px-2 mb-4">
                <Label htmlFor="renew[product][insurer_code]" required>
                  บริษัทประกัน
                </Label>
                <Select
                  id="renew[product][insurer_code]"
                  name="renew[product][insurer_code]"
                  value={state.renew?.quotes[0].insurer_code}
                  onChange={(evt) => handleChangeQuote(evt, "insurer_code")}
                  inputRef={register({ ...messages.required })}
                  error={errors?.renew?.product?.insurer_code}
                >
                  {insurersData.status === "loaded" &&
                    insurersData.data?.content.map((insurer, index) => (
                      <option key={index} value={insurer.code}>
                        {insurer.name}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="w-1/3 px-2 mb-4">
                <Label htmlFor="renew[product][policy_type]" required>
                  ประเภทความคุ้มครอง
                </Label>
                <Select
                  id="renew[product][policy_type]"
                  name="renew[product][policy_type]"
                  value={state.renew?.quotes?.[0]?.policy_type}
                  onChange={(evt) => handleChangeQuote(evt, "policy_type")}
                  inputRef={register({ ...messages.required })}
                  error={errors?.renew?.product?.policy_type}
                >
                  {type !== "cmi" ? (
                    <>
                      {policyTypeOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {PolicyTypeMapping[option]}
                        </option>
                      ))}
                    </>
                  ) : (
                    <>
                      {cmiPolicyTypeOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {PolicyTypeMapping[option]}
                        </option>
                      ))}
                    </>
                  )}
                </Select>
              </div>
              <div className="w-1/3 px-2 mb-4">
                <Label htmlFor="renew[product][details][repair_facility]" required>
                  ประเภทความคุ้มครอง
                </Label>
                <Select
                  id="renew[product][details][repair_facility]"
                  name="renew[product][details][repair_facility]"
                  value={state.renew?.quotes[0].repair_facility}
                  onChange={(evt) => handleChangeQuote(evt, "repair_facility")}
                  inputRef={register({ ...messages.required })}
                  error={errors?.renew?.product?.details?.repair_facility}
                >
                  {repairFactoryOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {RepairFacilityMapping[option]}
                    </option>
                  ))}
                </Select>
              </div>
              {type !== "cmi" && (
                <>
                  <div className="w-1/2 px-2 mb-4">
                    <Label htmlFor="renew_cover_start_date" required>
                      วันที่เริ่มคุ้มครอง
                    </Label>
                    <DatePicker
                      id="renew_cover_start_date"
                      selected={vmiCoveredDate["start"]}
                      onChange={(date: Date): void => handleChangeCoverDate(date)}
                      dateFormat="dd/MM/yyyy"
                    />
                    <input
                      type="hidden"
                      name="renew[order_details][cover_start_date]"
                      ref={register}
                      value={state.renew?.details?.cover_start_date}
                    />
                  </div>
                  <div className="w-1/2 px-2 mb-4">
                    <Label htmlFor="renew_cover_end_date" required>
                      วันที่สิ้นสุดคุ้มครอง
                    </Label>
                    <DatePicker
                      id="renew_cover_end_date"
                      name="renew_cover_end_date"
                      selected={vmiCoveredDate["end"]}
                      onChange={() => false}
                      minDate={new Date()}
                      disabled
                      dateFormat="dd/MM/yyyy"
                    />
                    <input
                      type="hidden"
                      name="renew[order_details][cover_end_date]"
                      ref={register}
                      value={state.renew?.details?.cover_end_date}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-wrap mt-4 -mx-2">
              <div className="w-1/3 px-2 mb-4">
                <input type="hidden" name="renew[cmi]" ref={register} value={state.renew?.checked_addon ? "checked" : "unchecked" } />
                <button
                  type="button"
                  disabled={type === "cmi"}
                  onClick={() =>
                    dispatch({
                      type: "ADD",
                      payload: {
                        isEdit: true,
                        renew: {
                          ...state.renew,
                          checked_addon: !state.renew?.checked_addon,
                        },
                      },
                    })
                  }
                  className={classnames([
                    "flex items-center px-4 py-3 text-base font-semibold bg-opacity-25 border-2 rounded-md shadow-lg focus:outline-none",
                    state.renew?.checked_addon
                      ? "focus:ring-offset-secondary-dark border-secondary bg-secondary"
                      : "bg-white border-gray-200",
                    type === "cmi" && "cursor-not-allowed",
                  ])}
                >
                  {state.renew?.checked_addon ? (
                    <CheckBoxIcon className="mr-2 text-secondary" />
                  ) : (
                    <CheckBoxOutlineBlankIcon className="mr-2" />
                  )}
                  พ.ร.บ.
                  <span className="ml-2">{state.renew?.addon_selling_price} ฿</span>
                </button>
              </div>
            </div>
            {(state.renew?.checked_addon || type === "cmi") && (
              <>
                <div className="mt-6 mb-3 text-lg font-bold text-secondary">
                  ข้อมูลประกันภัยรถยนต์ภาคบังคับ (พ.ร.บ.)
                </div>
                <div className="flex flex-wrap mt-4 -mx-2">
                  <div className="w-1/2 px-2 mb-4">
                    <Label htmlFor="renew_addon_start_date" required>
                      วันที่เริ่มคุ้มครอง พ.ร.บ.
                    </Label>
                    <DatePicker
                      id="renew_addon_start_date"
                      name="renew_addon_start_date"
                      selected={cmiCoveredDate["start"]}
                      onChange={(date: Date): void => handleChangeAddonCoverDate(date)}
                      dateFormat="dd/MM/yyyy"
                    />
                    <input
                      type="hidden"
                      name="renew[order_details][addon_start_date]"
                      ref={register}
                      value={state.renew?.details?.addon_start_date}
                    />
                  </div>
                  <div className="w-1/2 px-2 mb-4">
                    <Label htmlFor="renew_addon_end_date" required>
                      วันที่สิ้นสุดคุ้มครอง พ.ร.บ.
                    </Label>
                    <DatePicker
                      id="renew_addon_end_date"
                      name="renew_addon_end_date"
                      selected={cmiCoveredDate["end"]}
                      onChange={() => false}
                      disabled
                      dateFormat="dd/MM/yyyy"
                    />
                    <input
                      type="hidden"
                      name="renew[order_details][addon_end_date]"
                      ref={register}
                      value={state.renew?.details?.addon_end_date}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {state.renew?.quotation.product_type === "motor" ? (
              <CustomMotorProduct
                load={insurersData.status}
                insurersData={insurersData.data?.content}
                register={register}
                errors={errors}
              />
            ) : (
              <CustomOtherProduct
                load={insurersData.status}
                insurersData={insurersData.data?.content}
                register={register}
                errors={errors}
              />
            )}
          </>
        )}

        <>
          <div className="mt-6 mb-3 text-base font-bold">รายละเอียดค่าเบี้ยประกันภัย</div>
          {type !== "cmi" && (
            <>
              <div className="flex items-center mb-4 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][sum_insured]" required>
                    ทุนประกัน
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][sum_insured]"
                    name="renew[product][sum_insured]"
                    align="right"
                    onChange={(evt) => handleChangeQuote(evt, "sum_insured")}
                    value={state.renew?.quotes[0].sum_insured}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <hr />
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][insurer_gross_amount]" required>
                    เบี้ยสุทธิ
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2 py-1">
                  <TextInput
                    id="renew[product][insurer_gross_amount]"
                    name="renew[product][insurer_gross_amount]"
                    align="right"
                    value={lineItemAsVMI?.[0].premium_gross}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                    onChange={(evt) => handleChangePaymentLineItemAs(evt, "premium_gross")}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][insurer_stamp_amount]" required>
                    อากรแสตมป์
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][insurer_stamp_amount]"
                    name="renew[product][insurer_stamp_amount]"
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                    align="right"
                    value={lineItemAsVMI?.[0].premium_stamp}
                    onChange={(evt) => handleChangePaymentLineItemAs(evt, "premium_stamp")}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][insurer_vat_amount]" required>
                    ภาษีมูลค่าเพิ่ม
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][insurer_vat_amount]"
                    name="renew[product][insurer_vat_amount]"
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                    align="right"
                    value={lineItemAsVMI?.[0].premium_vat}
                    onChange={(evt) => handleChangePaymentLineItemAs(evt, "premium_vat")}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][selling_price]" required>
                    เบี้ยภาคสมัครใจ
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2 py-1 justify-end">
                  <TextInput
                    id="renew[product][selling_price]"
                    name="renew[product][selling_price]"
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                    align="right"
                    value={lineItemAsVMI?.[0].selling_price}
                    onChange={(evt) => handleChangePaymentLineItemAs(evt, "selling_price")}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
            </>
          )}

          {state.renew?.checked_addon && (
            <>
              <div className="flex items-center mb-4 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][sum_insured]" required>
                    ค่าเบี้ยพ.ร.บ.
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2 py-3 justify-end">
                  <span>{state.renew?.addon_selling_price}</span>
                  <span className="ml-4">฿</span>
                </div>
              </div>
              <input
                name="addon_selling_price"
                ref={register()}
                type="hidden"
                value={state.renew?.addon_selling_price}
              />
              <input
                name="renew[product][details][remark]"
                ref={register}
                type="hidden"
                value={state.renew?.quotation.criteria?.vehicle_type}
              />
            </>
          )}

          <hr />

          <div className="flex items-center mb-4 -mx-2">
            <div className="w-3/4 px-2 text-lg font-semibold">เบี้ยประกันที่ต้องชำระทั้งสิ้น</div>
            <div className="w-1/4 px-2 text-xl font-semibold text-right text-primary">
              {type === "cmi"
                ? state.renew?.addon_selling_price
                : formatNumberWithCurrency(total.totalAmount)}
            </div>
            <input
              type="hidden"
              name="renew[product][standard_price]"
              ref={register()}
              value={total.totalAmount}
            />
          </div>

          <hr />

          {isMotor && (
            <>
              <div className="mt-6 mb-3 text-base font-bold">รายละเอียดค่าเบี้ยประกันภัยใหม่</div>
              {type !== "cmi" && (
                <div className="flex mb-4 -mx-2">
                  <div className="w-1/2 px-2">
                    <Label htmlFor="renew[vmi_renewal_file]" required={requireAttachment}>
                      ใบแจ้งเตือนกรมธรรม์
                    </Label>
                    <FileInput
                      id="renew[vmi_renewal_file]"
                      name="renew[vmi_renewal_file]"
                      align="right"
                      onChange={(evt) =>
                        handleChangeFile(evt, "vmi_renewal_file")
                      }
                      inputRef={
                        (requireAttachment && isMotor) ? register({ ...messages.required }) : register
                      }
                    />
                  </div>
                  <div className="w-1/2 px-2">
                    <Label htmlFor="renew[vmi_warning_date]" required>
                      วันที่ออกใบเตือน
                    </Label>
                    <DatePicker
                      id="vmi_warning_date"
                      name="renew[vmi_warning_date]"
                      selected={state.renew && new Date(state.renew?.vmi_warning_date)}
                      onChange={(date: Date): void =>
                        handleChangeWarningDate(date, "vmi_warning_date")
                      }
                      dateFormat="dd/MM/yyyy"
                    />
                    <input
                      type="hidden"
                      name="renew[vmi_warning_date]"
                      ref={register}
                      value={state.renew?.vmi_warning_date}
                    />
                  </div>
                </div>
              )}

              {(state.renew?.checked_addon || type === "cmi") && (
                <div className="flex mb-4 -mx-2">
                  <div className="w-1/2 px-2">
                    <Label htmlFor="renew[cmi_renewal_file]">ใบแจ้งเตือนพ.ร.บ. (ถ้ามี)</Label>
                    <FileInput
                      id="renew[cmi_renewal_file]"
                      name="renew[cmi_renewal_file]"
                      align="right"
                      onChange={(evt) => handleChangeFile(evt, "cmi_renewal_file")}
                      inputRef={register}
                    />
                  </div>
                  <div className="w-1/2 px-2">
                    <Label htmlFor="renew[cmi_warning_date]">วันที่ออกใบเตือน</Label>
                    <DatePicker
                      id="cmi_warning_date"
                      name="renew[cmi_warning_date]"
                      selected={(state.renew && state.renew?.cmi_warning_date) ? new Date(state.renew?.cmi_warning_date) : new Date()}
                      onChange={(date: Date): void =>
                        handleChangeWarningDate(date, "cmi_warning_date")
                      }
                      dateFormat="dd/MM/yyyy"
                    />
                    <input
                      type="hidden"
                      name="renew[cmi_warning_date]"
                      ref={register}
                      value={state.renew?.cmi_warning_date || ""}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {!isMotor && (
            <>
              <div className="mt-6 mb-3 text-base font-bold">รายละเอียดค่าเบี้ยประกันภัยใหม่</div>
              <div className="flex mb-4 -mx-2">
                <div className="w-1/2 px-2">
                  <Label htmlFor="renew[renewal_file]" required>
                    ใบแจ้งเตือนกรมธรรม์
                  </Label>
                  <FileInput
                    id="renew[renewal_file]"
                    name="renew[renewal_file]"
                    align="right"
                    onChange={(evt) => handleChangeFile(evt, "renewal_file")}
                    inputRef={
                      (requireAttachment && !isMotor) ? register({ ...messages.required }) : register
                    }
                  />
                </div>
                <div className="w-1/2 px-2">
                  <Label htmlFor="renew[warning_date]" required>
                    วันที่ออกใบเตือน
                  </Label>
                  <DatePicker
                    id="warning_date"
                    name="renew[warning_date]"
                    selected={state.renew && new Date(state.renew?.warning_date ?? new Date())}
                    onChange={(date: Date): void => handleChangeWarningDate(date, "warning_date")}
                    dateFormat="dd/MM/yyyy"
                  />
                  <input
                    type="hidden"
                    name="renew[warning_date]"
                    ref={register}
                    value={state.renew?.warning_date}
                  />
                </div>
              </div>
            </>
          )}
          {state.renew?.quotation?.product_type === "motor" && type !== "cmi" && (
            <>
              <hr />
              <div className="mt-6 mb-3 text-lg font-bold text-secondary">
                รายละเอียดค่าความคุ้มครอง
              </div>
              <div className="mt-3 mb-3 text-base font-bold">ความคุ้มครองรถยนต์ผู้เอาประกันภัย</div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][sum_insured]" required>
                    ทุนประกัน
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][sum_insured]"
                    name="renew[product][sum_insured]"
                    align="right"
                    onChange={(evt) => handleChangeQuote(evt, "sum_insured")}
                    value={state.renew?.quotes[0].sum_insured}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][coverages][excess_damage_coverage]">
                    ค่าเสียหายส่วนแรก
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][coverages][excess_damage_coverage]"
                    name="renew[product][coverages][excess_damage_coverage]"
                    align="right"
                    onChange={(evt) => handleChangeCoverages(evt, "excess_damage_coverage")}
                    value={state.renew?.coverages?.excess_damage_coverage}
                    inputRef={register({
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][coverages][fire_theft_damage_coverage]" required>
                    รถยนต์สูญหาย ไฟไหม้
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][coverages][fire_theft_damage_coverage]"
                    name="renew[product][coverages][fire_theft_damage_coverage]"
                    align="right"
                    onChange={(evt) => handleChangeCoverages(evt, "fire_theft_damage_coverage")}
                    value={state.renew?.coverages?.fire_theft_damage_coverage}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              {type === "1" && (
                <div className="flex items-center mb-1 -mx-2">
                  <div className="w-3/4 px-2">
                    <Label htmlFor="renew[product][coverages][natural_disaster_coverage]" required>
                      น้ำท่วม
                    </Label>
                  </div>
                  <div className="flex items-center w-1/4 px-2">
                    <TextInput
                      id="renew[product][coverages][natural_disaster_coverage]"
                      name="renew[product][coverages][natural_disaster_coverage]"
                      align="right"
                      onChange={(evt) => handleChangeCoverages(evt, "natural_disaster_coverage")}
                      value={state.renew?.coverages?.natural_disaster_coverage}
                      inputRef={register({
                        ...messages.required,
                        ...messages.floatingValid,
                      })}
                    />
                    <span className="ml-2">฿</span>
                  </div>
                </div>
              )}
              <div className="mt-6 mb-3 text-base font-bold">ความคุ้มครองบุคคลภายนอก</div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label
                    htmlFor="renew[product][coverages][third_party_bodily_injury_coverage_per_person]"
                    required
                  >
                    ความบาดเจ็บทางร่างกาย หรือเสียชีวิตต่อคน
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][coverages][third_party_bodily_injury_coverage_per_person]"
                    name="renew[product][coverages][third_party_bodily_injury_coverage_per_person]"
                    align="right"
                    onChange={(evt) =>
                      handleChangeCoverages(evt, "third_party_bodily_injury_coverage_per_person")
                    }
                    value={state.renew?.coverages?.third_party_bodily_injury_coverage_per_person}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label
                    htmlFor="renew[product][coverages][third_party_bodily_injury_coverage_per_incident]"
                    required
                  >
                    ความบาดเจ็บทางร่างกาย หรือเสียชีวิตต่อครั้ง
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][coverages][third_party_bodily_injury_coverage_per_incident]"
                    name="renew[product][coverages][third_party_bodily_injury_coverage_per_incident]"
                    align="right"
                    onChange={(evt) =>
                      handleChangeCoverages(evt, "third_party_bodily_injury_coverage_per_incident")
                    }
                    value={state.renew?.coverages?.third_party_bodily_injury_coverage_per_incident}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label
                    htmlFor="renew[product][coverages][third_party_property_damage_coverage_per_incident]"
                    required
                  >
                    ทรัพย์สินบุคคลภายนอก ต่อครั้ง
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][coverages][third_party_property_damage_coverage_per_incident]"
                    name="renew[product][coverages][third_party_property_damage_coverage_per_incident]"
                    align="right"
                    onChange={(evt) =>
                      handleChangeCoverages(
                        evt,
                        "third_party_property_damage_coverage_per_incident"
                      )
                    }
                    value={
                      state.renew?.coverages?.third_party_property_damage_coverage_per_incident
                    }
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <div className="mt-6 mb-3 text-base font-bold">ความคุ้มครองเพิ่มเติม</div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label
                    htmlFor="renew[product][coverages][driver_personal_accident_coverage]"
                    required
                  >
                    อุบัติเหตุส่วนบุคคล
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][coverages][driver_personal_accident_coverage]"
                    name="renew[product][coverages][driver_personal_accident_coverage]"
                    align="right"
                    onChange={(evt) =>
                      handleChangeCoverages(evt, "driver_personal_accident_coverage")
                    }
                    value={state.renew?.coverages?.driver_personal_accident_coverage}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label
                    htmlFor="renew[product][coverages][passengers_in_vehicle_covered]"
                    required
                  >
                    คุ้มครองคนโดยสาร
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][coverages][passengers_in_vehicle_covered]"
                    name="renew[product][coverages][passengers_in_vehicle_covered]"
                    align="right"
                    onChange={(evt) => handleChangeCoverages(evt, "passengers_in_vehicle_covered")}
                    value={state.renew?.coverages?.passengers_in_vehicle_covered}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">คน</span>
                </div>
              </div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][coverages][medical_coverage]" required>
                    ค่ารักษาพยาบาล (ต่อคน)
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][coverages][medical_coverage]"
                    name="renew[product][coverages][medical_coverage]"
                    align="right"
                    onChange={(evt) => handleChangeCoverages(evt, "medical_coverage")}
                    value={state.renew?.coverages?.medical_coverage}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
              <div className="flex items-center mb-1 -mx-2">
                <div className="w-3/4 px-2">
                  <Label htmlFor="renew[product][coverages][bail_bond_coverage]" required>
                    ประกันตัวผู้ขับขี่ (ต่อครั้ง)
                  </Label>
                </div>
                <div className="flex items-center w-1/4 px-2">
                  <TextInput
                    id="renew[product][coverages][bail_bond_coverage]"
                    name="renew[product][coverages][bail_bond_coverage]"
                    align="right"
                    onChange={(evt) => handleChangeCoverages(evt, "bail_bond_coverage")}
                    value={state.renew?.coverages?.bail_bond_coverage}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                    })}
                  />
                  <span className="ml-2">฿</span>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default PolicyInfo;
