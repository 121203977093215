import React from "react";
import { messages } from "/src/helpers/messages";
import { FormGroup, TextField, LocationSearch } from "/src/components/form";
import { InsuredsAttributes } from "/src/types/checkoutBasicForm";

interface AddressInputProps {
  copied: boolean;
  attrPrefixInsured: string;
  insuredsAttributes?: InsuredsAttributes;
  errors?: any;
  register: (msg) => void;
  handleChangeField: (event: React.FormEvent, attrName: string) => void;
}

const AddressInput: React.FC<AddressInputProps> = ({
  copied,
  attrPrefixInsured,
  insuredsAttributes,
  errors,
  register,
  handleChangeField,
}) => {
  return (
    <>
      <h2>ที่อยู่ผู้เอาประกัน</h2>
      <div className="row">
        <div className="col-md-12">
          <FormGroup label="ที่อยู่ / บริษัท" htmlForLabel="insured_address" require>
            <TextField
              id="insured_address"
              name={`${attrPrefixInsured}[address]`}
              value={insuredsAttributes?.address}
              onChange={(evt) => handleChangeField(evt, "address")}
              inputRef={register(messages.required)}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <LocationSearch
          register={register}
          errors={errors}
          data={insuredsAttributes}
          nameInput={attrPrefixInsured}
          keys="insureds_attributes"
          validateKey="insureds_attributes"
          isCopied={copied}
          copyKey="delivery_address_attributes"
        />
      </div>
    </>
  );
};

export default AddressInput;
