import React, { useCallback, useEffect } from "react";
import axios from "../../../../helpers/configAxios";

import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import { useThankyouPageContext } from "~src/context/ThankyouPage";

import mtiLogo from "/assets/payments/mti_logo.jpeg";

const UserCredit = (props) => {
  const { summary, orderId, isPublic, disabled, onError } = props;
  const [state, setState] = React.useState(null);
  const [disabled_btn, setDisabledBtn] = React.useState(true);
  const [credit_text, setCreditText] = React.useState("...กำลังโหลดข้อมูล...");
  const { gotoPage: gotoThankyou } = useThankyouPageContext();

  const getCredit = useCallback(async () => {
    if (disabled) {
      return;
    }

    try {
      const res = await axios.post("/api/v2/payments/get_user_credit");

      const { cResult, cErrorMsg, nCredit, nAccumulate, nNetCredit, cCheckCredit, cStatus } =
        res.data;

      setState({
        cResult,
        cErrorMsg,
        nCredit,
        nAccumulate,
        nNetCredit,
        cCheckCredit,
        cStatus,
      });

      setCreditText(nNetCredit);
      if (nNetCredit > summary.paymentTotalAmount) {
        setDisabledBtn(false);
      }
    } catch (err) {
      console.error(err);
      onError && onError(err);
    }
  }, [disabled, summary.paymentTotalAmount, onError]);

  const handlePay = useCallback(async () => {
    if (disabled) {
      return;
    }

    try {
      const res = await axios.post(`/orders/${orderId}/payments/user_credit`, {
        payable_type: "Order",
        amount: summary.paymentTotalAmount,
        currency: "THB",
        payment_description: `${summary.insurerCode} - ${summary.orderNumber}`,
        payable_id: orderId,
      });

      if (res.status === 200) {
        if (isPublic) {
          gotoThankyou();
        } else {
          window.location.href = `/thankyou?${res.data.query_string}`;
        }
      }
    } catch (err) {
      console.error(err);
      onError && onError(err);
    }
  }, [
    disabled,
    orderId,
    summary.paymentTotalAmount,
    summary.insurerCode,
    summary.orderNumber,
    onError,
  ]);

  useEffect(() => {
    getCredit();
  }, []);

  return (
    <div className="p-4 payment-methods-wrap">
      <div className="payment-methods-body">
        <div className="payment-total">
          <span>ยอดเงินที่ต้องชำระทั้งหมด</span>
          <span className="total-amount">
            {formatNumberWithCurrency(summary.paymentTotalAmount)}
          </span>
        </div>
      </div>
      <hr />
      <div className="flex justify-center">
        <img src={mtiLogo} alt="" />
      </div>

      <div className="flex justify-center" style={{ paddingTop: "20px" }}>
        คุณมีวงเงินเครดิตคงเหลือ : {credit_text}
      </div>

      <div className="flex justify-center" style={{ paddingTop: "20px" }}>
        <button
          type="button"
          className="px-6 py-2 text-white bg-green-700 shadow rounded-5 disabled:opacity-50 usercredit-btn"
          onClick={handlePay}
          disabled={disabled || disabled_btn}
        >
          ชำระเงิน
        </button>
      </div>
    </div>
  );
};

export default UserCredit;
