import React, { useState, useEffect } from "react";

import "./loading.scss";

const Index = () => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    // const timer = setTimeout(() => setShowLoading(true), 300);
    // return () => clearTimeout(timer);
    setShowLoading(true);
  }, []);

  return (
    <>
      {showLoading ? (
        <div className="load-overlay">
          <div className="loading-box">
            <div className="spinner">
              <div className="double-bounce1" />
              <div className="double-bounce2" />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Index;
