import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./dateTimePicker.scss";

const DateTimePickerField = ({ ...rest }) => {
  return (
    <div className="gm-dateTimepicker">
      <i className="material-icons">date_range</i>
      <DatePicker {...rest} />
    </div>
  );
};

export default DateTimePickerField;
