import React from "react";

import DropDownList from "./DropDownList";
import DropDownListCheckBox from "./DropDownListCheckBox";
import DropDownListRadio from "./DropDownListRadio";
import DropdownListIcon from "./DropdownListIcon";

import "./Select.scss";

const Select = ({ type, label, options, active, toggleAllOption, onChangeValue }) => {
  return (
    <>
      {type === "checkbox" && (
        <DropDownListCheckBox
          options={options}
          label={label}
          active={active}
          onChangeValue={onChangeValue}
        />
      )}
      {type === "radio" && (
        <DropDownListRadio
          options={options}
          label={label}
          active={active}
          onChangeValue={onChangeValue}
        />
      )}
      {type === "checkIcon" && (
        <DropdownListIcon
          options={options}
          label={label}
          active={active}
          toggleAllOption={toggleAllOption}
          onChangeValue={onChangeValue}
        />
      )}
      {type === "option" && (
        <DropDownList
          options={options}
          label={label}
          active={active}
          toggleAllOption={toggleAllOption}
          onChangeValue={onChangeValue}
        />
      )}
    </>
  );
};

export default Select;
