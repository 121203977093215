import React from "react";
import List from "./List";
import { checkEmpty } from "/src/helpers/helpers";
const AgentInfo = ({ agent }) => {
  return (
    <div>
      <h6 className="bg">ติดต่อตัวแทน</h6>
      <div className="list-content-2">
        <List label="รหัสตัวแทน" value={agent.number} type={2} align="right" />
        <List
          label="ชื่อตัวแทน"
          value={agent.full_name}
          type={2}
          align="right"
        />
        <List label="อีเมล" value={agent.email} type={2} align="right" />
        <List
          label="เบอร์โทรศัพท์"
          value={checkEmpty(agent.phone)}
          type={2}
          align="right"
        />
      </div>
    </div>
  );
};

export default AgentInfo;
