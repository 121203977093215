import React from "react";
import ReactCalendar from "react-calendar";
import "./DatePickerRange.scss";

const Calendar = ({ onDateChange, values, ...rest }) => {
  return (
    <div className="datepicker-range--modal shadow-sm">
      <ReactCalendar
        returnValue="range"
        onChange={(date) => onDateChange(date)}
        showDoubleView
        selectRange
        value={values}
        {...rest}
      />
    </div>
  );
};

export default Calendar;
