import queryString, { StringifiableRecord } from "query-string";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import useDeepCompareEffect, { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import { PaginationParams } from "~src/models/params/PaginationParams";

//TODO: Refactor this to dedup against PageContext
//Requires either [queryParams, setQueryParams] or location
export interface UsePaginatedURLProps {
  location?: Location;
  queryParams?: StringifiableRecord;
  setQueryParams?: (params: StringifiableRecord) => void;
  defaultPage?: number;
  defaultPageSize?: number;
  //   parseQuery?: (rawParams: queryString.StringifiableRecord) => Record<string, any>;
}

export interface UsePaginatedURLState {
  searchParams: StringifiableRecord;
  setSearchParams: (params: StringifiableRecord) => void;
  paginationParams: PaginationParams;
  setPaginationParams: (params: PaginationParams) => void;
}

export default function usePaginatedURL({
  location,
  queryParams,
  setQueryParams,
  defaultPage,
  defaultPageSize,
}: UsePaginatedURLProps): UsePaginatedURLState {
  const history = useHistory();

  const [_searchParams, _paginationParams] = extractParams(queryParams, location);

  const [searchParams, setSearchParams] = useState<StringifiableRecord>(_searchParams);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>(_paginationParams);

  useDeepCompareEffectNoCheck(() => {
    const [_searchParams, _paginationParams] = extractParams(queryParams, location);
    setSearchParams(_searchParams);
    setPaginationParams(_paginationParams);
  }, [queryParams, location]);

  useDeepCompareEffect(() => {
    const { page, per_page } = paginationParams;
    const _nextParams = { ...searchParams, page, per_page } as StringifiableRecord;

    if (setQueryParams) {
      setQueryParams(_nextParams);
      return;
    }

    const nextQS =
      "?" +
      queryString.stringify(_nextParams, {
        arrayFormat: "bracket",
      });
    if (nextQS !== location.search) {
      //TEST
      console.log("query string changed:", location.search, "=>", nextQS);
    }

    history.push({
      pathname: location.pathname,
      search: nextQS,
    });
  }, [searchParams, paginationParams]);

  function extractParams(
    queryParams?: StringifiableRecord,
    location?: Location
  ): [StringifiableRecord, PaginationParams] {
    const rawParams =
      queryParams ||
      (location?.search
        ? (queryString.parse(location.search, {
            arrayFormat: "bracket",
          }) as StringifiableRecord)
        : undefined) ||
      {};

    //TEST
    console.log("usePaginatedURL: extractParams: rawParams:", rawParams);

    const { page, per_page, ...otherParams } = rawParams;

    return [otherParams, buildPaginationParams(page, per_page)];
  }

  function buildPaginationParams(page: any, per_page: any): PaginationParams {
    return {
      page: (page ? Number(page) : undefined) || defaultPage || 1,
      per_page: (per_page ? Number(per_page) : undefined) || defaultPageSize || 0,
    };
  }

  return { searchParams, setSearchParams, paginationParams, setPaginationParams };
}
