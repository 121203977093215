import React, { useState } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import { useAuthenticate } from "~src/helpers/authen";
import List from "./List";

const CommissionInfo = ({ payment, productType }) => {
  const { t } = useTranslation();
  const { accessType } = useAuthenticate();
  const isStaff = accessType === "STAFF";

  const vmiItems = payment.line_itemas.filter((p) => p.policy_type !== "cmi");
  const cmiItems = payment.line_itemas.filter((p) => p.policy_type === "cmi");

  const [isCommissionRateOpened, setIsCommissionRateOpened] = useState(false);
  return (
    <div>
      <h6 className="bg">รายละเอียดค่าตอบแทน</h6>
      <div className="list-content-2">
        {vmiItems.length > 0 && (
          <>
            <List
              label="ค่าเบี้ยสุทธิ"
              value={formatNumberWithCurrency(vmiItems[0].premium_gross)}
              type={2}
              align="right"
            />

            <List
              label={productType === "travel" ? "ค่าตอบแทน" : "ค่าตอบแทนภาคสมัครใจ"}
              value={formatNumberWithCurrency(vmiItems[0].commission)}
              type={2}
              align="right"
            />
            <div className="bd-dotted" />
          </>
        )}
        {cmiItems.length > 0 && (
          <>
            <List
              label="ค่าเบี้ย พ.ร.บ. สุทธิ"
              value={formatNumberWithCurrency(cmiItems[0].premium_gross)}
              type={2}
              align="right"
            />

            <List
              label={`ค่าตอบแทน พ.ร.บ. ${cmiItems[0].paid_commission_percent}%`}
              value={formatNumberWithCurrency(cmiItems[0].commission)}
              type={2}
              align="right"
            />

            <div className="bd-dotted" />
          </>
        )}

        <List
          label="ค่าตอบแทนก่อนหักส่วนลด"
          value={formatNumberWithCurrency(payment.sum_commission)}
          type={2}
          align="right"
        />

        <List
          label="หักภาษี ณ ที่จ่าย 5%"
          value={`- ${formatNumberWithCurrency(payment.sum_vat_amount)}`}
          type={2}
          align="right"
        />
        <List
          label="หักส่วนลดให้ลูกค้า"
          value={`${
            formatNumberWithCurrency(payment.sum_discount) === "-"
              ? `-`
              : `- ${formatNumberWithCurrency(payment.sum_discount)}`
          }`}
          type={2}
          align="right"
        />
        <List
          label="ค่าตอบแทนสุทธิ"
          value={`${formatNumberWithCurrency(payment.commission_gain)}`}
          type={2}
          align="right"
          labelBold
          bold
          color="primary"
        />
        {isStaff && vmiItems.length > 0 && (
          <>
            <div className="bd-dotted" />
            <div
              className="mt-2 flex justify-between cursor-pointer"
              onClick={() => setIsCommissionRateOpened(!isCommissionRateOpened)}
            >
              <div>รายละเอียดอัตราค่าตอบแทน</div>
              {isCommissionRateOpened ? <ArrowDropUp /> : <ArrowDropDown />}
            </div>
            {isCommissionRateOpened && (
              <>
                <div className="mt-1 flex justify-between">
                  <div>• จ่าย</div>
                  <div>{vmiItems[0].paid_commission_percent} %</div>
                </div>
                <div className="mt-1 flex justify-between">
                  <div>• จ่าย (OV)</div>
                  <div>{vmiItems[0].paid_ov_percent} %</div>
                </div>
                <div className="mt-1 flex justify-between">
                  <div>• รับ</div>
                  <div>{vmiItems[0].received_commission} %</div>
                </div>
                <div className="mt-1 flex justify-between">
                  <div>• รับ (OV)</div>
                  <div>{vmiItems[0].received_ov} %</div>
                </div>
                <div className="mt-1 flex justify-between">
                  <div>• รับ (Printing)</div>
                  <div>{vmiItems[0].received_printing} %</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CommissionInfo;
