import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import Select, { Props as SelectProps } from "react-select";
// import { ValueType } from "react-select/src/types";
import useDeepCompareEffect, { useDeepCompareMemoize } from "use-deep-compare-effect";
import { PaymentMethod } from "~src/types/payment";
import yml from "~/src/status.yml";

export interface PaymentMethodOption {
  value: PaymentMethod;
  label: string;
}

export interface PaymentMethodFilterProps extends SelectProps<any, true> {
  values: PaymentMethod[];
  onMethodsChange: (methods: PaymentMethod[], evt: any) => void;
}

export const options: PaymentMethodOption[] = [
  { value: "bank_transfer", label: `${yml.paymentType.bank_transfer}` },
  { value: "installment", label: `${yml.paymentType.installment}` },
  { value: "direct_insurer", label: `${yml.paymentType.direct_insurer}` },
  { value: "credit_term", label: `${yml.paymentType.credit_term}` },
];
export const optionsMap = options.reduce(
  (m, opt) => ({
    ...m,
    [opt.value]: opt,
  }),
  {}
);

export default function PaymentMethodFilter({
  values,
  onMethodsChange,
  ...rest
}: PaymentMethodFilterProps) {
  const [selectedOptions, setSelectedOptions] = useState<PaymentMethodOption[]>([]);

  useDeepCompareEffect(() => {
    setSelectedOptions(values.map((val) => optionsMap[val]).filter((opt) => !!opt));
  }, [values]);

  function handleOptionsChange(selectedOpts, evt: any) {
    const _newValues = selectedOpts.map((opt) => opt.value);
    onMethodsChange && onMethodsChange(_newValues, evt);
    setSelectedOptions(selectedOpts);
  }

  return (
    <>
      <label>วิธีชำระเงิน</label>
      <Select
        isMulti
        options={options}
        onChange={handleOptionsChange}
        value={selectedOptions}
        {...rest}
      />
    </>
  );
}
