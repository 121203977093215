import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";

import locationJson from "/src/assets/database.json";

import { FormGroup } from "/src/components/form";
import AutoComplete from "/src/components/form/autoCompleteAddress";

import CheckoutContext from "/src/context/Checkout";

import "./location-search.scss";

import { messages } from "/src/helpers/messages";

const attrPrefixInsured = "order[insureds_attributes][]";
const attrPrefixBenef = "order[insureds_attributes][][beneficiary_attributes]";
const attrPrefixShipping = "order[insureds_attributes][][shipping_attributes]";

const LocationSearch = ({
  register,
  errors,
  data,
  nameInput,
  keys,
  readOnly,
  validateKey,
  copyKey = undefined,
  isCopied = false,
}) => {
  const { state, dispatch } = useContext(CheckoutContext);

  const [results, setResults] = useState([]);
  const [showDropDownSubDistrict, setShowDropdownSubDistrict] = useState(false);
  const [showDropDownDistrict, setShowDropdownDistrict] = useState(false);
  const [showDropDownProvince, setShowDropdownProvince] = useState(false);
  const [showDropDownZipcode, setShowDropdownZipcode] = useState(false);

  const handleChangeSubDistrict = (e) => {
    const { name, value } = e.target;
    let result = [];
    let show = false;
    if (value != "") {
      result = searchByText(locationJson, value, ["sub_district"]);
      show = true;
    } else {
      result = [];
      show = false;
    }
    setResults(result);
    setShowDropdownSubDistrict(show);

    dispatch({
      type: "ADD",
      payload: {
        ...state,
        [keys]: {
          ...state[keys],
          sub_district: value,
        },
        [copyKey]: {
          ...state[copyKey],
          sub_district: isCopied ? value : null,
        },
      },
    });
  };

  const handleChangeDistrict = (e) => {
    const { name, value } = e.target;
    let result = [];
    let show = false;
    if (value != "") {
      result = searchByText(locationJson, value, ["district"]);
      show = true;
    } else {
      result = [];
      show = false;
    }
    setResults(result);
    setShowDropdownDistrict(show);
    dispatch({
      type: "ADD",
      payload: {
        ...state,
        [keys]: {
          ...state[keys],
          district: value,
        },
        [copyKey]: {
          ...state[copyKey],
          district: isCopied ? value : null,
        },
      },
    });
  };

  const handleChangeProvince = (e) => {
    const { name, value } = e.target;
    let result = [];
    let show = false;
    if (value != "") {
      result = searchByText(locationJson, value, ["province"]);
      show = true;
    } else {
      result = [];
      show = false;
    }
    setResults(result);
    setShowDropdownProvince(show);
    dispatch({
      type: "ADD",
      payload: {
        ...state,
        [keys]: {
          ...state[keys],
          province: value,
        },
        [copyKey]: {
          ...state[copyKey],
          province: isCopied ? value : null,
        },
      },
    });
  };

  const handleChangeZipcode = (e) => {
    const { name, value } = e.target;

    if (!/^[0-9]*$/.test(value)) return;
    const zipcodeValue = value.replace(/[^0-9]/gi, "");
    const count = zipcodeValue.split("");
    if (count.length > 5) return;

    let result = [];
    let show = false;
    if (value != "") {
      result = searchByText(locationJson, value, ["zipcode"]);
      show = true;
    } else {
      result = [];
      show = false;
    }
    setResults(result);
    setShowDropdownZipcode(show);

    dispatch({
      type: "ADD",
      payload: {
        ...state,
        [keys]: {
          ...state[keys],
          postcode: value,
        },
        [copyKey]: {
          ...state[copyKey],
          postcode: isCopied ? value : null,
        },
      },
    });
  };

  const cbOutsideClick = (status) => {
    setShowDropdownSubDistrict(status);
    setShowDropdownDistrict(status);
    setShowDropdownProvince(status);
    setShowDropdownZipcode(status);
  };

  const cbData = (data) => {
    const objs = state[keys];
    (objs.sub_district = data.sub_district),
      (objs.district = data.district),
      (objs.province = data.province),
      (objs.postcode = data.zipcode);

    dispatch({
      type: "ADD",
      payload: {
        ...state,
        [keys]: {
          ...state[keys],
          ...objs,
        },
        [copyKey]: isCopied
          ? {
              ...state[copyKey],
              ...objs,
            }
          : {
              ...state[copyKey],
            },
      },
    });
    // setLocations(objs);
  };

  function searchByText(collection, text, include) {
    text = _.toLower(text);
    return _.filter(collection, function (object) {
      return _(object)
        .pick(include)
        .some(function (string) {
          return _(string).toLower().includes(text);
        });
    });
  }

  return (
    <>
      <div className="col-md-6">
        <FormGroup label="ตำบล" require>
          <AutoComplete
            data={results}
            onChange={handleChangeSubDistrict}
            name={`${nameInput}[sub_district]`}
            show={showDropDownSubDistrict}
            cbOutsideClick={cbOutsideClick}
            cbData={cbData}
            value={state[keys]?.sub_district}
            inputRef={register({
              ...messages.required,
              ...messages.validateSubDistrict,
            })}
            error={
              !!errors.order &&
              !!errors.order[validateKey] &&
              !!errors.order[validateKey].sub_district &&
              errors.order[validateKey].sub_district.message
            }
            readOnly={readOnly}
          />
        </FormGroup>
      </div>
      <div className="col-md-6">
        <FormGroup label="อำเภอ" require>
          <AutoComplete
            data={results}
            onChange={handleChangeDistrict}
            name={`${nameInput}[district]`}
            show={showDropDownDistrict}
            cbOutsideClick={cbOutsideClick}
            cbData={cbData}
            value={state[keys]?.district}
            inputRef={register({
              ...messages.required,
              ...messages.validateDistrict,
            })}
            error={
              !!errors.order &&
              !!errors.order[validateKey] &&
              !!errors.order[validateKey].district &&
              errors.order[validateKey].district.message
            }
            readOnly={readOnly}
          />
        </FormGroup>
      </div>
      <div className="col-md-6">
        <FormGroup label="จังหวัด" require>
          <AutoComplete
            name={`${nameInput}[province]`}
            data={results}
            onChange={handleChangeProvince}
            show={showDropDownProvince}
            cbOutsideClick={cbOutsideClick}
            cbData={cbData}
            value={state[keys]?.province}
            inputRef={register({
              ...messages.required,
              ...messages.validateProvince,
            })}
            error={
              !!errors.order &&
              !!errors.order[validateKey] &&
              !!errors.order[validateKey].province &&
              errors.order[validateKey].province.message
            }
            readOnly={readOnly}
          />
        </FormGroup>
      </div>
      <div className="col-md-6">
        <FormGroup label="รหัสไปรษณีย์" require>
          <AutoComplete
            name={`${nameInput}[postcode]`}
            data={results}
            onChange={handleChangeZipcode}
            show={showDropDownZipcode}
            cbOutsideClick={cbOutsideClick}
            cbData={cbData}
            value={state[keys]?.postcode}
            inputRef={register(messages.required)}
            maxLength={5}
            error={
              !!errors.order &&
              !!errors.order[validateKey] &&
              !!errors.order[validateKey].postcode &&
              errors.order[validateKey].postcode.message
            }
            readOnly={readOnly}
          />
        </FormGroup>
      </div>
    </>
  );
};

export default LocationSearch;
