import React, { useState, useEffect } from "react";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { CheckCircleOutline, Close } from "@mui/icons-material";
import useHttp from "~/src/hooks/useHttp";

import axios from "~/src/helpers/configAxios";
import { urlStringify } from "~/src/helpers/helpers";
import SectionHeader from "~/src/components/layout/staff/SectionHeader";
import ErrorPage from "~/src/components/errorPage";
import PartnerTable from "./table";

import useFetch from "~/src/hooks/useFetch";
import Modal from "~/src/pages/staff/cms/components/Modal";
import PartnerEditor from "./editor";
import PartnerTypeInfo from "~src/models/PartnerTypeInfo";
import { AlertNotification, StyleType } from "~/src/components/alert/alertNotification";

const BASE_URL = "/staffs/partner-list";
const PER_PAGE = 10;
const DEFAULT_PAGE = 1;
const FORM_SUBMIT_ID = "formEditor";

interface PartnerListProps {
  location: any;
}

const PartnerList: React.FC<PartnerListProps> = ({ location }) => {
  const { post } = useHttp();

  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });
  const queryUrl = `/api/partners?${urlStringify({
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    ...queryParams,
  })}`;

  const partnerTypeUrl = `/api/partner_types?${urlStringify({
    ...queryParams,
  })}`;

  const [isError, setIsError] = useState(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(queryParams.page ?? DEFAULT_PAGE);
  const [selectedPartner, setSelectedPartner] = useState({});
  const [partnerTypes, setPartnerTypes] = useState<Array<PartnerTypeInfo>>([]);

  const [alertText, setAlertText] = useState("ดำเนินการเสร็จสิ้น");
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertType, setAlertType] = useState<StyleType>("success");

  const [dataLists, setDataLists] = useState([]);
  const partnersFetch = useFetch(queryUrl);
  const partnerTypesFetch = useFetch(partnerTypeUrl);

  useEffect(() => {
    if (!partnersFetch.isLoading && !partnerTypesFetch.isLoading) {
      const partners = partnersFetch.response?.content;
      const totalRecords = partnersFetch.response?.total_count;
      const totalPages = partnersFetch.response?.total_pages;
      const partnerTypes = partnerTypesFetch.response?.content;

      setTotalRecords(totalRecords);
      setPageCount(totalPages);
      setDataLists(partners);
      setPartnerTypes(partnerTypes);
      setIsError(false);
    }
    if (partnersFetch.error) {
      setIsError(true);
    }
  }, [
    partnersFetch.error,
    partnersFetch.isLoading,
    partnersFetch.response,
    partnerTypesFetch.error,
    partnerTypesFetch.isLoading,
    partnerTypesFetch.response,
  ]);

  const handleAddPartner = () => {
    setSelectedPartner({});
    setIsEditModalOpened(true);
  };

  const handleEditPartner = (partner: any) => {
    setSelectedPartner(partner);
    setIsEditModalOpened(true);
  };

  const handlePageClick = (e: any): void => {
    const selectedPage = parseInt(e.selected) + 1;
    const params = { page: selectedPage };
    const queries = urlStringify(params);
    window.location.href = `${BASE_URL}?${queries}`;
  };

  const handleSubmitPartner = (data: object): void => {
    let submitPromise;
    if (data.id) {
      submitPromise = axios.patch(`/api/partners/${data.id}`, data);
    } else {
      submitPromise = axios.post(`/api/partners`, data);
    }
    submitPromise
      .then((result) => {
        setIsEditModalOpened(false);
        setAlertType("success");
        showAlert("ดำเนินการเสร็จสิ้น");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setAlertType("error");
        showAlert("พบความผิดพลาดในการดำเนินการ กรุณาลองใหม่ในภายหลัง");
      });
  };

  const showAlert = (text: string): void => {
    setAlertText(text);
    setIsAlertActive(true);
  };

  return (
    <div>
      {isError ? (
        <ErrorPage />
      ) : (
        <>
          <div className="flex flex-col p-4">
            <SectionHeader
              className="w-full"
              title="รายชื่อ Partner"
              breadcrumbs={[{ url: "#", label: "รายชื่อกลุ่มตัวแทน", current: true }]}
            />
            <div className="h-5">
              {totalRecords > 0 && (
                <div className="flex justify-end mt-1 mb-2 text-1xs text-custom-gray-md">
                  {totalRecords < 20 ? (
                    <span>
                      ทั้งหมด
                      <span className="mx-1 font-bold">{totalRecords}</span>
                      รายการ
                    </span>
                  ) : (
                    <span>รายการทั้งหมด 20+ รายการ</span>
                  )}
                </div>
              )}
            </div>
            <PartnerTable
              className="mt-4"
              isLoading={partnersFetch.isLoading}
              partners={dataLists}
              onAddPartner={handleAddPartner}
              onEditPartner={handleEditPartner}
            />
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel="Prev"
                nextLabel="Next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pageCount}
                initialPage={Number(currentPage) - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="gm-paginate"
                subContainerClassName="pages pagination"
                activeClassName="active"
                disableInitialCallback
              />
            )}
          </div>
          {isEditModalOpened && (
            <Modal>
              <PartnerEditor
                formId={FORM_SUBMIT_ID}
                partner={selectedPartner}
                partnerTypes={partnerTypes}
                onCancel={() => setIsEditModalOpened(false)}
                onSubmit={handleSubmitPartner}
              />
            </Modal>
          )}
        </>
      )}
      <AlertNotification
        className="bottom-0 right-0 px-4 py-3 m-5 mr-3"
        type={alertType}
        active={isAlertActive}
        setActive={setIsAlertActive}
        enterAnimation="slideInRight"
        exitAnimation="slideOutRight"
        closeDelay={5000}
      >
        <CheckCircleOutline className="mr-3" />
        <span className="inline-block align-bottom">{alertText}</span>
        <button className="ml-3" onClick={() => setIsAlertActive(false)}>
          <Close />
        </button>
      </AlertNotification>
    </div>
  );
};

export default PartnerList;
