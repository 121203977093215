import _ from "lodash";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import Select, { OptionProps } from "react-select";
import { ToastProvider, useToasts } from "react-toast-notifications";
import useDeepCompareEffect from "use-deep-compare-effect";

import StaffOrdersTableLayout, { StaffOrdersTableLayoutProps } from "./table/Table";
import DatePickerRange from "~src/components/datePickerRange/DatePickerRange";
import { ButtonRadius } from "~src/components/form";
import SectionHeader from "~src/components/layout/staff/SectionHeader";
import SearchForm from "~src/components/staff/SearchForm";
import PaymentMethodFilter, { options as methodOptions } from "./components/PaymentMethodFilter";
import OrdersListView from "~src/components/orders/OrdersListView";

import { FindOrdersResponse, OrdersAPIClientProps } from "~src/api/Orders";
import StaffOrdersAPIClient from "~src/api/staff/Orders";
import { useAppContext } from "~src/context/App";
import { OrdersContextProvider, useOrdersContext } from "~src/context/Orders";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { toISODate } from "~src/helpers/date";
import { dateFormat } from "~src/helpers/dateTime";
import useInput from "~src/hooks/useInput";
import usePaginatedURL from "~src/hooks/usePaginatedURL";
import { FindOrdersParams } from "~src/models/params/FindOrdersParams";
import { PaginationParams } from "~src/models/params/PaginationParams";
import { Order, Status as OrderStatus } from "~src/types/order";
import { Payment, PaymentMethod, PaymentStatus } from "~src/types/payment";

import "./ordersList.scss";

const BASE_URL = "/staffs/orders";
const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

function getSearchParamsWithDefaults(
  filters: Partial<FindOrdersParams> | undefined,
  paginationParams: PaginationParams
) {
  // TODO: Revise this later
  return {
    ...filters,
    keyword: filters?.keyword ?? "",
    payment_method: filters?.payment_method ?? [],
    order_status: filters?.order_status ?? [],
    created_from: filters?.created_from ?? null,
    created_to: filters?.created_to ?? null,
    // expiration_filter: true,
    page: paginationParams.page,
    per_page: paginationParams.per_page,
  };
}

interface OrderStatusOption {
  value: OrderStatus;
  label: string;
}

const statusOptions: OrderStatusOption[] = [
  { value: "open", label: "รอกรอกข้อมูล" },
  { value: "waiting_payment", label: "รอชำระเงิน" },
  { value: "waiting_verify", label: "รอการตรวจสอบ" },
  { value: "completed", label: "ชำระเงินเรียบร้อย" },
  // { value: "failed", label: "ชำระเงินไม่สำเร็จ" },
  { value: "closed", label: "คำสั่งซื้อหมดอายุ" },
];
const statusOptionsMap = statusOptions.reduce(
  (m, opt) => ({
    ...m,
    [opt.value]: opt,
  }),
  {}
);

const defaultValue = (params, options) => {
  if (Array.isArray(params)) {
    return _.filter(options, (o) => _.includes(params, o.value));
  }
  return _.filter(options, (o) => o.value === params);
};

export const ordersAPIFactory = (props: OrdersAPIClientProps) =>
  new StaffOrdersAPIClient({ ...props });

export interface StaffOrdersPageProps {
  location: Location;
}

export default function StaffOrdersPage(props: StaffOrdersPageProps) {
  return (
    <PageContextProvider withQuery location={props.location}>
      <StaffOrdersPageInner {...props} />
    </PageContextProvider>
  );
}

type StaffOrdersPageInnerProps = StaffOrdersPageProps;

var StaffOrdersPageInner = ({}: StaffOrdersPageInnerProps) => {
  const { queryParams, setQueryParams, onError: onPageError } = usePageContext();
  const { searchParams, setSearchParams, paginationParams, setPaginationParams } = usePaginatedURL({
    queryParams,
    setQueryParams,
    defaultPage: DEFAULT_PAGE,
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });
  const { addToast } = useToasts();

  const { value: keyword, bind: handleChangeKeyword } = useInput(searchParams.keyword ?? "");

  // TODO: Revise this later
  const [ordersContextParams, setOrdersContextParams] = useState(
    getSearchParamsWithDefaults(searchParams, paginationParams)
  );
  useDeepCompareEffect(() => {
    setOrdersContextParams(getSearchParamsWithDefaults(searchParams, paginationParams));
  }, [searchParams, paginationParams]);

  function handleSearch(params: Partial<FindOrdersParams>) {
    const { created_from, created_to, page, per_page, ...otherParams } = params;

    setSearchParams({
      ...searchParams,
      ...otherParams,
      created_from: created_from ? toISODate(created_from) : undefined,
      created_to: created_to ? toISODate(created_to) : undefined,
    });
    setPaginationParams({
      page: page || paginationParams.page,
      per_page: per_page || paginationParams.per_page,
    });
  }

  const handleSearchBtnClick = (_evt: React.MouseEvent<HTMLElement>) => {
    handleSearch({ ...searchParams, keyword });
  };

  const handleSearchBarKeyPress = (evt: React.KeyboardEvent<HTMLElement>) => {
    if (evt.key === "Enter") handleSearch({ ...searchParams, keyword });
  };

  function handleFiltersSubmit(newValue: Partial<FindOrdersParams>) {
    handleSearch({ ...newValue, page: 1 });
  }

  function handleError(err: Error) {
    addToast(
      <div>
        <h4>ERROR</h4>
        <p>{err.message}</p>
      </div>,
      {
        appearance: "error",
        autoDismiss: true,
      }
    );
    // console.error(err);
    // setIsError(true);
    // setErrorMsg(err.message || "Error");
    onPageError(err);
  }

  return (
    <OrdersContextProvider
      searchParams={ordersContextParams}
      apiFactory={ordersAPIFactory}
      onError={handleError}
      loadOnInit={true}
    >
      <div className="orders-page">
        <OrdersListView
          currentParams={ordersContextParams}
          onSearch={handleSearch}
          onError={handleError}
          renderSectionHeader={() => (
            <SectionHeader
              title="รายการคำสั่งซื้อ"
              breadcrumbs={[{ url: "#", label: "รายการซื้อขาย", current: true }]}
              searchComponent={
                <SearchForm
                  type="text"
                  placeholder="ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่คำสั่งซื้อ"
                  {...handleChangeKeyword}
                  handleClick={handleSearchBtnClick}
                  handleKeyPress={handleSearchBarKeyPress}
                />
              }
            />
          )}
          renderTableLayout={(props) => (
            <StaffOrderResultsArea
              {...props}
              currentParams={{ ...searchParams, ...paginationParams }}
              onFiltersSubmit={handleFiltersSubmit}
            />
          )}
        />
      </div>
    </OrdersContextProvider>
  );
};

export interface StaffOrderResultsAreaProps extends StaffOrdersTableLayoutProps {
  currentParams?: queryString.StringifiableRecord;
  onFiltersSubmit: (value: Partial<FindOrdersParams>) => void;
}

export const StaffOrderResultsArea = ({
  currentParams,
  onFiltersSubmit,
  ...otherProps
}: StaffOrderResultsAreaProps) => {
  const { isLoading, response: ordersResponse } = useOrdersContext();
  const keyword = currentParams.keyword ? `${currentParams.keyword}` : undefined;

  return (
    <>
      <StaffOrderResultsStatsBox
        isLoading={isLoading}
        keyword={keyword}
        response={ordersResponse}
      />
      <StaffOrdersFiltersForm value={currentParams} onSubmit={onFiltersSubmit} />
      <StaffOrdersTableLayout {...otherProps} />
    </>
  );
};

export interface StaffOrderResultsStatsBoxProps {
  isLoading: boolean;
  keyword?: string;
  response?: FindOrdersResponse;
}

export const StaffOrderResultsStatsBox = ({
  isLoading,
  keyword,
  response,
}: StaffOrderResultsStatsBoxProps) => {
  return (
    <div className="result-box">
      {!isLoading && response && response.total_count > 0 ? (
        <>
          {keyword ? (
            <span>
              คำค้นหา:
              <strong style={{ padding: "0 5px" }}>"{keyword}" พบ</strong>
            </span>
          ) : null}
          <span>
            {response.total_count > 20 ? (
              <span>รายการทั้งหมด 20+ รายการ</span>
            ) : (
              <>
                <span>ทั้งหมด</span>
                <strong> {response.total_count}</strong> <span>รายการ</span>
              </>
            )}
          </span>
        </>
      ) : null}
    </div>
  );
};

export interface StaffOrdersFiltersFormProps {
  value: Partial<FindOrdersParams>;
  onValueChange?: (value: Partial<FindOrdersParams>) => void;
  onSubmit?: (value: Partial<FindOrdersParams>) => void;
}

export const StaffOrdersFiltersForm = ({
  value,
  onValueChange,
  onSubmit,
}: StaffOrdersFiltersFormProps) => {
  const [filtersState, setFiltersState] = useState(value);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<OrderStatusOption[]>([]);

  useDeepCompareEffect(() => {
    setFiltersState(value);
    setSelectedStatusOptions(
      value.order_status?.map((status) => statusOptionsMap[status]).filter((opt) => !!opt) ?? []
    );
  }, [value]);

  function handleStatusOptionsChange(selectedOpts, evt: any) {
    const _newFiltersState = {
      ...filtersState,
      order_status: selectedOpts.map((opt) => opt.value) as OrderStatus[],
    };
    setFiltersState(_newFiltersState);
    onValueChange && onValueChange(_newFiltersState);
    setSelectedStatusOptions(selectedOpts);
  }

  function handlePaymentMethodsChange(newMethods: any) {
    const _newFiltersState = {
      ...filtersState,
      payment_method: newMethods as PaymentMethod[],
    };
    setFiltersState(_newFiltersState);
    onValueChange && onValueChange(_newFiltersState);
  }

  function handleDatesChange(dateForm: Date | undefined, dateTo: Date | undefined) {
    const _newFiltersState = {
      ...filtersState,
      created_from: dateFormat(dateForm),
      created_to: dateFormat(dateTo),
    };

    setFiltersState(_newFiltersState);
    onValueChange && onValueChange(_newFiltersState);
  }

  const resetValueCallback = () => {
    setFiltersState({
      ...filtersState,
      created_from: null,
      created_to: null,
    });
  };

  function handleSearchClick(evt: React.MouseEvent<HTMLElement>) {
    onSubmit && onSubmit(filtersState);
  }

  return (
    <section className="filter-area">
      <div className="filter-area-box">
        <div className="">
          <label>
            <span>สถานะ</span>
          </label>
          <Select
            isMulti
            options={statusOptions}
            onChange={handleStatusOptionsChange}
            value={selectedStatusOptions}
          />
        </div>
        <div>
          <PaymentMethodFilter
            className="z-50"
            values={filtersState.payment_method || []}
            onMethodsChange={handlePaymentMethodsChange}
          />
        </div>
        <div>
          <label htmlFor="">วันที่ทำรายการ</label>
          <DatePickerRange
            resetValueCallback={resetValueCallback}
            onDateChange={handleDatesChange}
            values={[filtersState.created_from, filtersState.created_to]}
          />
        </div>
      </div>
      <div className="text-center col-12">
        <ButtonRadius color="secondary" onClick={handleSearchClick}>
          ค้นหา
        </ButtonRadius>
      </div>
    </section>
  );
};

// DEPRECATED: Temporarily kept for reference
// const [queryStr] = useState(queryParams);
// const [totalRecords, setTotalRecords] = useState(0);
// const [pageCount, setPageCount] = useState(0);
// const [orderLists, setOrderList] = useState<Order[]>([]);
// const [isShowPayslipModal, setShowPayslipModal] = useState(false);
// const [currentPage, setCurrentPage] = useState(queryParams["page"] ?? DEFAULT_PAGE);
// const [slipUrl, setSlipUrl] = useState("");

// const handleChangeKeyWord = (e) => {
//   setFilterState({
//     ...filterState,
//     keyword: e.target.value,
//   });
// };

// const handleChangeStatus = (optionData) => {
//   const status = optionData ? [...optionData] : [];
//   setFilterState({ ...filterState, status });
// };

// const handleChangePaymentMethod = (optionData, event) => {
//   const paymentMethod = optionData ? [...optionData] : [];
//   setFilterState({ ...filterState, paymentMethod });
// }

// const onDateChange = (dateFrom, dateTo) => {
//   setFilterState({
//     ...filterState,
//     dateFrom: dateFormat(dateFrom),
//     dateTo: dateFormat(dateTo),
//   });
// };

// const handleClickFilter = () => {
//   const { status, paymentMethod, keyword, dateFrom, dateTo } = filterState;
//   const queries = urlStringify({
//     keyword,
//     payment_method: paymentMethod.map((m) => m.value),
//     order_status: status.map((s) => s.value),
//     created_from: dateFrom,
//     created_to: dateTo,
//     per_page: PER_PAGE,
//     page: 1
//   });

//   window.location.href = `${BASE_URL}?${queries}`;
// };
// return (
//   <div className="orders-page">
//     {isError ? (
//       <ErrorPage />
//     ) : (
//       <>
//         <SectionHeader
//           title="รายการคำสั่งซื้อ"
//           breadcrumbs={[{ url: "#", label: "รายการซื้อขาย", current: true }]}
//           searchComponent={
//             <SearchForm
//               type="text"
//               placeholder="ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่คำสั่งซื้อ"
//               onChange={handleChangeKeyWord}
//               handleClick={handleClickFilter}
//               handleKeyPress={handleKeyPressSearch}
//               value={filterState.keyword}
//             />
//           }
//         />

//         <div className="orders-page-title">
//           <div className="title-bottom">
//             <span className={"gettmore-icon-info mr-1"}></span>
//             รายการคำสั่งซื้อจะมีอายุ 5 วัน นับตั้งแต่วันที่สร้างรายการ
//           </div>
//         </div>

//         <div className="result-box">
//           {totalRecords > 0 ? (
//             <>
//               {queryStr.search ? (
//                 <span>
//                   คำค้นหา:
//                   <strong style={{ padding: "0 5px" }}>
//                     "{queryStr.search}" พบ
//                   </strong>
//                 </span>
//               ) : null}
//               <span>
//                 {totalRecords > 20 ? (
//                   <>
//                     <span>รายการทั้งหมด 20+ รายการ</span>
//                   </>
//                 ) : (
//                   <>
//                     <span>ทั้งหมด</span>
//                     <strong> {totalRecords}</strong> <span>รายการ</span>
//                   </>
//                 )}
//               </span>
//             </>
//           ) : null}
//         </div>

//         <section className="filter-area">
//           <div className="filter-area-box">
//             <div className="">
//               <label>
//                 <span>สถานะ</span>
//               </label>
//               <Select
//                 options={options}
//                 onChange={handleChangeStatus}
//                 value={filterState.status}
//                 isMulti
//               />
//             </div>
//             <div>
//               <PaymentMethodFilter
//                 className="z-50"
//                 value={filterState.paymentMethod}
//                 handleChangeMethod={handleChangePaymentMethod}
//               />
//             </div>
//             <div>
//               <label htmlFor="">วันที่ทำรายการ</label>
//               <DatePickerRange
//                 onDateChange={onDateChange}
//                 values={[filterState.dateFrom, filterState.dateTo]}
//               />
//             </div>
//           </div>
//           <div className="text-center col-12">
//             <ButtonRadius color="secondary" onClick={handleClickFilter}>
//               ค้นหา
//             </ButtonRadius>
//           </div>
//         </section>

//         {!error && (
//           <Table
//             loading={isLoading}
//             data={orderLists}
//             onDownloadExcel={handleOpenDownloadModal}
//             setIsDownloadModalOpened={setIsDownloadModalOpened}
//             confirmOrder={confirmOrder}
//             viewPaySlip={viewPaySlip}
//           />
//         )}
//         {pageCount > 1 && (
//           <ReactPaginate
//             previousLabel={"Prev"}
//             nextLabel={"Next"}
//             breakLabel={"..."}
//             breakClassName={"break-me"}
//             pageCount={pageCount}
//             initialPage={(Number(currentPage) - 1)}
//             marginPagesDisplayed={2}
//             pageRangeDisplayed={2}
//             onPageChange={handlePageClick}
//             containerClassName={"gm-paginate"}
//             // DEPRECATED: https://github.com/AdeleD/react-paginate/issues/66
//             // subContainerClassName={"pages pagination"}
//             pageClassName={"pages pagination"}
//             activeClassName={"active"}
//             disableInitialCallback={true}
//           />
//         )}

//         {preLoading && <Preloading label={preloadingLabel} />}

//         {isShowPayslipModal && (
//           <ShowPaySlipModal payslip_url={slipUrl} handleClose={handleClose} />
//         )}

//         <DownloadModal
//           id="downloadOrdersExcelFormId"
//           open={isDownloadModalOpened}
//           isDownloadDone={isDownloadModalDone}
//           downloadMessage={downloadModalMessage}
//           downloadEmail={downloadEmail}
//           onClose={() => setIsDownloadModalOpened(false)}
//           onDownload={handleDownloadFile}
//           onDownloadEmailChanged={(e) => setDownloadEmail(e.target.value)}
//        />
//       </>
//     )}
//   </div>
// );
// };
