const getLicenseByType = (licenses, type) => {
  if (!licenses) return null;
  
  const foundLicense = licenses.find(license => license.insurance_type === type);
  if (foundLicense) return foundLicense
  else return null;
}

export {
  getLicenseByType
};