import React from "react";
import { useFormContext } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { FormGroup } from "~/src/components/form";
import dataYml from "~/src/data.yaml";
import { Option } from "~/src/types/basic";
import { OrderType } from "~/src/types/order";
import AgentNumberInput from "~/src/components/Forms/AgentNumberInput";
import CustomDropdown from "~/src/components/Forms/CustomDropdown";
import ProductTypeInput from "~/src/components/Forms/ProductTypeInput";
import { insuranceInfo } from "~/src/states/orderCustom";

const orderTypeOptions: Option<OrderType>[] = [
  { label: "ใหม่", value: "new" },
  { label: "ต่ออายุ", value: "renew" },
];

const TypeDetailsPanel: React.FC = () => {
  const { control, errors } = useFormContext();
  const setInsuranceInfo = useSetRecoilState(insuranceInfo);

  return (
    <div className="w-full bg-white shadow rounded-5">
      <div className="flex flex-row p-4 gap-6 tablet:flex-col">
        <FormGroup label="ประเภทงาน" className="w-3/12 tablet:w-full" require>
          <CustomDropdown
            required
            name="order.contract"
            control={control}
            errors={errors}
            options={orderTypeOptions}
          />
        </FormGroup>

        <FormGroup className="w-6/12 tablet:w-full" label="รหัสตัวแทน" require>
          <AgentNumberInput name="order.agent_number" control={control} errors={errors} />
        </FormGroup>

        <FormGroup label="ประเภทประกัน" className="w-3/12 tablet:w-full" require>
          <ProductTypeInput
            name="product.type"
            control={control}
            errors={errors}
            callbackOnchange={(value: string) => {
              const subTypeOptions: Option<string>[] = dataYml.order_custom_type[value].map(
                (subType: string) => ({
                  label: subType,
                  value: subType,
                })
              );
              setInsuranceInfo((prevState) => ({
                ...prevState,
                subTypeOptions,
                mainType: value,
                subType: undefined,
              }));
            }}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default TypeDetailsPanel;