import React, { useState } from "react";
import { UseFormMethods, Controller, useWatch } from "react-hook-form";
import { format } from "date-fns";
import DatePicker from "~/src/components/Forms/DatePicker";
import { FormGroup, TextField } from "~/src/components/form";
import { messages } from "~/src/helpers/messages";
import NationalityInput from "../travel/NationalityInput";
import { useRecoilValue } from "recoil";
import { agentInfoAtom } from "~src/states/orderCustom";

interface Props extends Partial<Pick<UseFormMethods, "register" | "errors" | "control">> {
  name?: string;
}

const PersonalForm: React.FC<Props> = ({ name, errors, control, register }): JSX.Element => {
  const [idCard, serIdCard] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const agentInfo = useRecoilValue(agentInfoAtom);

  React.useEffect(() => {
    if (agentInfo) {
      setPhoneNumber(agentInfo.mobile_no);
      setEmail(agentInfo.email);
    }
  }, [agentInfo]);

  const mainType = useWatch({
    control,
    name: "product_type",
    defaultValue: undefined,
  });

  return (
    <>
      <FormGroup label="วัน เดือน ปี เกิด" className="col-span-2" require>
        <Controller
          name={`${name}.birthday`}
          rules={messages.required}
          control={control}
          render={({ onChange, value }) => {
            return (
              <>
                <DatePicker
                  value={value}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: Date): void => {
                    onChange(format(date, "dd/MM/yyyy"));
                  }}
                  yearRange={75}
                />
                {errors?.birthday?.message && (
                  <span className="text-xs text-red-600">{errors?.birthday?.message}</span>
                )}
              </>
            );
          }}
        />
      </FormGroup>

      <FormGroup label="เลขประจำตัวบัตรประชาชน" className="col-span-3" require>
        <TextField
          name={`${name}.idcard`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (/\d/.test(e.target.value)) serIdCard(e.target.value);
          }}
          type="text"
          maxLength={13}
          inputRef={register(messages.validateIdCard)}
          error={errors?.idcard?.message}
          value={idCard}
        />
      </FormGroup>

      <FormGroup label="เบอร์มือถือ" className="col-span-2" require>
        <TextField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (/\d/.test(e.target.value) || e.target.value == "") setPhoneNumber(e.target.value);
          }}
          name={`${name}.phone_number`}
          maxLength={10}
          inputRef={register({ ...messages.required, ...messages.mobileNumber })}
          error={errors?.phone_number?.message}
          value={phoneNumber}
        />
      </FormGroup>

      <FormGroup label="อีเมล" className="col-span-3">
        <TextField
          name={`${name}.email`}
          maxLength={64}
          inputRef={register(messages.email)}
          error={errors?.email?.message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          value={email}
        />
      </FormGroup>

      {mainType === "TA" && <NationalityInput className="col-span-2" name="national" />}
    </>
  );
};

export default PersonalForm;
