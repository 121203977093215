import Decimal from "decimal.js";
import React from "react";

import dictionary from "~src/data.yaml";
import GeneratePaymentLink from "~src/components/payments/GeneratePaymentLink";

// import { formatNumberWithCurrency } from "/src/helpers/formatNumber.js";

import "./paymentLinkCreateModal.scss";

const PayMentLinkCreateModal = (props) => {
  const { payment, orderID, order, onClose, onError, id = "payMentLinkCreateModal" } = props;
  const amount = payment?.required_amount;

  //TODO: Implement this

  return (
    <div id={id} className="paymentModal modal">
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modal-box">
        <div className="header">
          <div className="icon">
            <i className="material-icons">add_link</i>
          </div>
          <h2>{dictionary.payment.generate_link}</h2>
          <div className="buttons">
            <button className="material-icons close-icon" role="button" onClick={() => onClose()}>
              close
            </button>
          </div>
        </div>
        <br />
        <GeneratePaymentLink
          orderID={orderID}
          amount={amount}
          payment={payment}
          totalAmount={order.payments_summary?.payment_total_amount}
          isInstallments={order.payments_summary?.is_installments}
          installmentTerms={order.payments_summary?.installment_terms}
          generateOnInit={true}
          onError={onError}
        />
      </div>
    </div>
  );
};

export default PayMentLinkCreateModal;
