import React from "react";
import Header from "../../components/layout/public/Header";
import Hero from "../../components/agent/homepage/Hero";

const MtiHome = () => {
  return (
    <div>
      <Header />
      <Hero />
    </div>
  )
}

export default MtiHome