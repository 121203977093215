import React, { useState, useEffect, useMemo } from "react";
import { FormGroup, TextField, SelectField } from "/src/components/staff/form";
import Loading from "/src/components/loading";

import encodeURL from "/src/helpers/encodeURL";
import { messages } from "/src/helpers/messages";

import useInput from "/src/hooks/useInput";
import useFetch from "/src/hooks/useFetch";
import useHttp from "/src/hooks/useHttp";

const VehicleForm = (props) => {
  const { data } = props;
  const [loading, setLoading] = useState(false);
  const [carBrands, setCatBrands] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [codeNames, setCodeNames] = useState([]);
  const [carYears, setCarYears] = useState([]);

  const {
    response: resBrands,
    error: errBrands,
    isLoading: isLoadingBrands,
  } = useFetch("/api/vehicles");

  const {
    response: resProvinces,
    error: errProvinces,
    isLoading: isLoadingProvinces,
  } = useFetch("/api/locations");

  useEffect(() => {
    if (!isLoadingBrands) {
      setLoading(false);
      const { brands } = resBrands;
      const b = parseOptions(brands);
      setCatBrands(b);
    } else setLoading(true);
  }, [resBrands, errBrands, isLoadingBrands]);

  useEffect(() => {
    if (!isLoadingProvinces) {
      setLoading(false);
      const { provinces } = resProvinces;
      const p = parseOptions(provinces);
      setProvinces(p);
    } else setLoading(true);
  }, [resProvinces, errProvinces, isLoadingProvinces]);

  const { value: car_brand, bind: changeCarBrand } = useInput("");
  const { value: car_model, bind: changeCarModel } = useInput("");
  const { value: year, bind: changeYear } = useInput("");
  const { value: code_name, bind: changeCodeName } = useInput("");
  const { value: province, bind: changeProvince } = useInput("");
  const { value: plate_number, bind: changePlateNumber } = useInput(
    data.plate_number || "1กข-1234"
  );
  const [chassisNumber, setChassisNumber] = useState(data.chassis_number || "")

  const { get } = useHttp();

  useMemo(() => {
    setCarModels([]);
    setCarYears([]);
    setCodeNames([]);
    if (car_brand != "") {
      setLoading(true);
      get(`/api/vehicles/${encodeURL(car_brand)}`).then((res) => {
        const { models } = res.data;
        const m = parseOptions(models);
        setCarModels(m);
        setLoading(false);
      });
    }
  }, [car_brand]);

  useMemo(() => {
    setCarYears([]);
    setCodeNames([]);
    if (car_brand != "" && car_model != "") {
      setLoading(true);
      get(`/api/vehicles/${encodeURL(car_brand)}/${encodeURL(car_model)}`).then(
        (res) => {
          const { years } = res.data;
          let y = parseOptions(years);
          setCarYears(y);
          setLoading(false);
        }
      );
    }
  }, [car_model]);

  useMemo(() => {
    setCodeNames([]);
    if (car_brand != "" && car_model != "" && year) {
      setLoading(true);
      get(
        `/api/vehicles/${encodeURL(car_brand)}/${encodeURL(
          car_model
        )}/${encodeURL(year)}`
      ).then((res) => {
        const { sub_models } = res.data;
        const s = [];
        sub_models.map((value, i) => {
          s[i] = { label: value.sub_model, value: value.code_name };
        });
        setCodeNames(s);
        setLoading(false);
      });
    }
  }, [year]);

  function parseOptions(arr) {
    const a = [];
    arr.map((value, i) => {
      a[i] = { label: arr[i], value: arr[i] };
    });
    return a;
  }

  const handleChangeChassisNumber = (e) => {
    let { value } = e.target;
    value = value.replace(/[^A-Z0-9]/gi, "").toUpperCase();
    setChassisNumber(value);
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="row">
        <div className="col-md-4">
          <FormGroup
            label="ยี่ห้อรถยนต์"
            htmlForLabel="renew[criteria][car_brand]"
            require
          >
            <SelectField
              id="renew[criteria][car_brand]"
              name="renew[criteria][car_brand]"
              options={carBrands}
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.criteria?.car_brand?.message}
              {...changeCarBrand}
            />
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup
            label="รุ่น"
            htmlForLabel="renew[criteria][car_model]"
            require
          >
            <SelectField
              id="renew[criteria][car_model]"
              name="renew[criteria][car_model]"
              options={carModels}
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.criteria?.car_model?.message}
              {...changeCarModel}
            />
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup
            label="ปีที่จดทะเบียน"
            htmlForLabel="renew[criteria][year]"
            require
          >
            <SelectField
              id="renew[criteria][year]"
              name="renew[criteria][year]"
              options={carYears}
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.criteria?.year?.message}
              {...changeYear}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormGroup
            label="รุ่นย่อย"
            htmlForLabel="renew[criteria][car_submodel]"
            require
          >
            <SelectField
              id="renew[criteria][car_submodel]"
              name="renew[criteria][car_submodel]"
              options={codeNames}
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.criteria?.car_submodel?.message}
              {...changeCodeName}
            />
          </FormGroup>
          <input
            type="hidden"
            value={code_name}
            ref={props.register()}
            name="renew[criteria][code_name]"
          />
        </div>
        <div className="col-md-6">
          <FormGroup
            label="จังหวัดจดทะเบียน"
            htmlForLabel="renew[criteria][province]"
            require
          >
            <SelectField
              id="renew[criteria][province]"
              name="renew[criteria][province]"
              options={provinces}
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.criteria?.province?.message}
              {...changeProvince}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormGroup
            label="ทะเบียนรถยนต์"
            htmlForLabel="renew[order_details][plate_number]"
            require
          >
            <TextField
              id="renew[order_details][plate_number]"
              name="renew[order_details][plate_number]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.order_details?.plate_number?.message}
              {...changePlateNumber}
            />
          </FormGroup>
          <FormGroup
            label="หมายเลขตัวถังรถยนต์ (คัสซี)"
            htmlForLabel="renew[order_details][chassis_number]"
            require
          >
            <TextField
              id="renew[order_details][chassis_number]"
              name="renew[order_details][chassis_number]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.order_details?.chassis_number?.message}
              value={chassisNumber}
              onChange={handleChangeChassisNumber}
            />
          </FormGroup>
        </div>
        <div className="col-md-6">
          <div className="vehicle-registration-box">
            <div className="vehicle-registration-layout">
              <div className="top">{plate_number}</div>
              <div className="bottom">{province || "กรุงเทพมหานคร"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

VehicleForm.propTypes = {};

export default VehicleForm;
