import { atom, selector } from "recoil";
import { Option } from "~/src/types/basic";

const mainTypeMotorList = [
  "รถยนต์",
  "รถอื่นๆ",
  "Super Car",
  "รถบรรทุกเกิน 4 ตัน",
  "Big Bike/Motorcycle",
  "พ.ร.บ.",
];

const subTypeMotorList = ["พ.ร.บ. รถยนต์ส่วนบุคคล", "พ.ร.บ. รถยนต์รับจ้าง"];

const mainTypeMotorCustomList = [
  "รถอื่นๆ",
  "Super Car",
  "รถบรรทุกเกิน 4 ตัน",
  "Big Bike/Motorcycle",
];

const detailsLabel = {
  "Home Package": ["ที่อยู่"],
};

const loadingInfo = atom<boolean>({
  key: "customOrderLoading",
  default: false,
});

interface IInsuranceInfo {
  insurer: string;
  mainType: string;
  subType: string;
  subTypeOptions: Option<string>[];
}

const insuranceInfo = atom({
  key: "customOrderInsuranceInfo",
  default: {
    insurer: undefined,
    mainType: undefined,
    subType: undefined,
    subTypeOptions: [],
  } as IInsuranceInfo,
});

const mainTypeMotor = selector({
  key: "customOrderIsMainTypeMotor",
  get: ({ get }) => {
    const insurance = get(insuranceInfo);
    const motorPanelOpen =
      mainTypeMotorList.includes(insurance.mainType) ||
      subTypeMotorList.includes(insurance.subType);
    const isMotorCustom =
      mainTypeMotorCustomList.includes(insurance.mainType) ||
      subTypeMotorList.includes(insurance.subType);
    const remarkDetail = detailsLabel[insurance.mainType];

    return { motorPanelOpen, isMotorCustom, remarkDetail };
  },
});

interface ICoverageInfo {
  coverageFrom: Date;
  coverageTo: Date;
  addonStartDate: Date;
  addonEndDate: Date;
}

const coverageInfo = atom({
  key: "customOrderCoverageInfo",
  default: {
    coverageFrom: undefined,
    coverageTo: undefined,
    addonStartDate: undefined,
    addonEndDate: undefined,
  } as ICoverageInfo,
});

interface IaddonInfo {
  addonChecked: boolean;
  addonPrice: number;
}

const addonInfo = atom({
  key: "customOrderAddon",
  default: {
    addonChecked: undefined,
    addonPrice: 0,
  } as IaddonInfo,
});

interface IpaymentInfo {
  type: string;
  paid_at: Date;
  paid_at_time: Date;
  payslip: any;
  amount: number;
  description: string;
}

const paymentInfo = atom({
  key: "customOrderPayment",
  default: {
    type: "",
    paid_at: undefined,
    paid_at_time: undefined,
    payslip: undefined,
    amount: 0,
    description: "",
  } as IpaymentInfo,
});

interface IextendedPaymentInfo {
  agent_installment_contract: any;
  customer_installment_contract: any;
  installment_selfie_image: any;
  installment_citizen_id_card: any;
}

const extendedPaymentInfo = atom({
  key: "customOrderPaymentInfo",
  default: {
    agent_installment_contract: undefined,
    customer_installment_contract: undefined,
    installment_selfie_image: undefined,
    installment_citizen_id_card: undefined,
  } as IextendedPaymentInfo,
});

export interface AgentInfoResponse {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  is_active: boolean;
  mobile_no: string;
  status: string;
  username: string;
}

const agentInfoResponseAtom = atom({
  key: "agentInfoReponse",
  default: [] as AgentInfoResponse[],
});

const agentInfoAtom = atom({
  key: "agentInfo",
  default: {} as AgentInfoResponse,
});

export interface CommissionInfo {
  received_commission: number;
  received_commission_unit: string;
  received_ov: number;
  received_ov_unit: string;
  received_printing: number;
  received_printing_unit: string;
  paid_commission_percent: number;
  paid_ov_percent: number;
  paid_printing_percent: number;
}

const standardVmiCommission = atom({
  key: 'standardVmiCommission',
  default: null as CommissionInfo
})

const standardCmiCommission = atom({
  key: 'standardCmiCommission',
  default: null as CommissionInfo
})

export {
  insuranceInfo,
  coverageInfo,
  loadingInfo,
  mainTypeMotor,
  addonInfo,
  paymentInfo,
  extendedPaymentInfo,
  agentInfoResponseAtom,
  agentInfoAtom,
  standardVmiCommission,
  standardCmiCommission
};
