import React, { useState, useEffect } from "react";
import "babel-polyfill";
import axios from "/src/helpers/configAxios";
import "./upload-policy.scss";

import {
  formatNumberWithCurrency,
  formatNumberWithCurrency2,
} from "/src/helpers/formatNumber";
import { dateFormat, parseDate, parseDateTH } from "/src/helpers/dateTime";

import { useForm } from "react-hook-form";
import useHttp from "./hooks/useHttp";

import useInsurerIcons from "/src/helpers/useInsurerIcons";
import Badges from "/src/components/badges";
import Preloading from "/src/components/loading/Preloading";

import {
  Button,
  TextField,
  UploadField,
  FormGroup,
} from "/src/components/form";
import { messages } from "/src/helpers/messages";

import yml from "/src/status.yml";
import dataYml from "/src/data.yaml";

import useFetch from "/src/hooks/useFetch";

const UploadPolicy = ({ cbClose, cbFile, id, obj }) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [closeAnimation, setCloseAnimation] = useState("fadeInDownBig");
  const [disableButton, setDisableButton] = useState(false);
  const { register, clearError, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const [fileUpload, setFileUpload] = useState("");
  const [policyNumber, setPolicyNumber] = useState(obj.policy_no ? obj.policy_no : "");
  const { post } = useHttp();

  const { response, error, isLoading } = useFetch(
    `/api/insurers?code=${obj.insurer_code}`
  );

  const handleCloseModal = () => {
    setCloseAnimation("fadeInUpBig");
    setTimeout(() => cbClose(), 500);
  };

  const handleChangeUpload = (e) => {
    setFileUpload(e.target.files[0]);
  };

  const handleChangePolicyNumber = (evt) => {
    const { value } = evt.target;
    setPolicyNumber(value);
  };

  const onSubmit = () => {
    event.preventDefault();
    setFetchLoading(true);
    const data = new FormData();
    data.append("policy_file", fileUpload);
    data.append("policy_number", policyNumber);
    post(`/api/policies/${id}/upload_document`, data).then((res) => {
      const { url, success } = res.data;
      if (success) {
        cbFile(url, id);
        setFetchLoading(false);
        setCloseAnimation("fadeInUpBig");
        setTimeout(() => cbClose(), 500);
      }
    });

    // if (!confirm) {
    //   setErrConfirm(true);
    //   return;
    // } else {
    //   setErrConfirm(false);
    // }
    // const orderData = {
    //   order: {
    //     details: data,
    //   },
    // };
    // postUpdateOrder(orderData);
    // setIsCompleteStep(true);
  };

  return (
    <div className="modal-upload-policy">
      <div
        className={`modal-upload-policy-wrapper animated delay-01s ${closeAnimation}`}
      >
        <h5 className="flex mb-4 text-center align-items-center justify-content-center">
          <span className="mr-2 material-icons">cloud_upload</span>
          อัพโหลดกรมธรรม์
        </h5>
        <div className="modal-upload-policy-wrapper-grid-top">
          <div>
            เลขที่ออเดอร์: <strong>{obj.order_number}</strong>
          </div>
          <div className="flex" style={{ justifyContent: "flex-end" }}>
            <Badges status={obj.status}>
              {yml.status.policy.staff[obj.status]}
            </Badges>
          </div>
        </div>
        <div className="modal-upload-policy-wrapper-grid">
          <div className="img">
            {!isLoading && <img src={response.content[0].icon} />}
          </div>
          <div>
            <p>
              ประเภท:{" "}
              <strong>
                {dataYml.product_type[obj.product_type][obj.policy_type]},{" "}
                {dataYml.policy_type[obj.policy_type]}
              </strong>
            </p>
            <p>
              ทุนประกัน:{" "}
              <strong>{formatNumberWithCurrency2(obj.sum_insured)}</strong>
            </p>
            <p>
              วันที่คุ้มครอง:{" "}
              <strong>{`${parseDateTH(obj.coverage_from)} - ${parseDateTH(
                obj.coverage_to
              )}`}</strong>
            </p>
            <p>
              เบี้ยประกัน:{" "}
              <strong>{formatNumberWithCurrency(obj.total_amount)}</strong>
            </p>
          </div>
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <input type="hidden" name="policy_id" value="1" ref={register} />
          <div className="mt-4">
            <FormGroup label="หมายเลขกรมธรรม์" require>
              <TextField
                id="policy_number"
                name="policy_number"
                value={policyNumber}
                inputRef={register({ ...messages.required })}
                error={errors?.policy_number?.message}
                onChange={handleChangePolicyNumber}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                type="file"
                id={`policy_file`}
                name={`policy_file`}
                inputRef={register({
                  ...messages.uploadFileRequired,
                  ...messages.allowFileType,
                })}
                error={errors?.policy_file?.message}
                onChange={handleChangeUpload}
              />
            </FormGroup>
            <span className="block mb-1 error-txt">
              หมายเหตุ : ระบุชนิดของไฟล์เป็น .PNG, .JPG, .PDF เท่านั้น
            </span>
          </div>
          <div className="mt-4 modal-upload-policy-wrapper-action">
            <Button type="submit" color="primary" disabled={disableButton}>
              {!disableButton ? "อัพโหลด" : "โปรดรอ..."}
            </Button>
            {!disableButton ? (
              <Button
                type="button"
                onClick={handleCloseModal}
                disabled={disableButton}
              >
                ยกเลิก
              </Button>
            ) : null}
          </div>
        </form>
      </div>
      {fetchLoading ? <Preloading /> : null}
    </div>
  );
};

export default UploadPolicy;
