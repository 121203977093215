import _ from "lodash";
import { IconButton } from "@material-ui/core";
import { Edit, Close } from "@mui/icons-material";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
import List from "./List";
import { Button } from "~src/components/form";
import EditInsuredCard from "~src/components/card/editInsuredCard";
import EditVehicleCard from "~src/components/card/EditVehicleCard";
import Preloading from "~src/components/loading/Preloading";

import { checkEmpty, convertFileListToArray } from "~src/helpers/helpers";
import idCardFormat from "~src/helpers/idCardFormat";
import {
  getInsuredDeliveryTypeAsArray,
  getInsuredDeliveryTypeText,
} from "~src/helpers/insuredHelper";
import { Contract } from "~src/types/contract";
import { Beneficiary, Insured } from "~src/types/insured";
import { Quotation } from "~src/types/quotation";
import { Quote } from "~src/types/quote";
import { Attachment } from "~src/types/attachment";
import { OrdersAPIFactory, useOrdersAPI } from "~src/api/Orders";
import UpdateOrderParams from "~src/models/params/UpdateOrderParams";
import InsuresDetailInfo from "./InsuresDetailInfo";
import { OrderPolicy } from "~src/types/order";
import SecureLinkImg from "../secureLinkImg";
import SecureLink from "../secureLink";

export interface InsuredInfoProps {
  orderId: number;
  insuredCategory: string;
  insured: Insured;
  beneficiary: Beneficiary;
  quotation: Quotation;
  quote: Quote;
  details: any;
  checkedAddon: boolean;
  allowEdit: boolean;
  contract?: Contract;
  productType?: string;
  vehicleDocuments?: Attachment[];
  attachImagesStatus?: string;
  ordersAPIFactory?: OrdersAPIFactory;
  onError?: (err: Error) => void;
  insuresInfo?: Insured[];
  policies: OrderPolicy[];
}

export default function InsuredInfo({
  orderId,
  insuredCategory,
  insured,
  beneficiary,
  quotation,
  quote,
  details,
  checkedAddon,
  allowEdit,
  contract,
  productType,
  vehicleDocuments,
  attachImagesStatus,
  ordersAPIFactory,
  onError,
  insuresInfo,
  policies,
}: InsuredInfoProps) {
  const history = useHistory();
  const { ordersAPI } = useOrdersAPI({
    apiFactory: ordersAPIFactory,
    onError,
  });

  const [preLoading, setPreLoading] = useState(false);

  const [openEditInsured, setOpenEditInsured] = useState(false);
  const [editInsured, setEditInsured] = useState(_.cloneDeep(insured));
  const [editInsuredCategory, setEditInsuredCategory] = useState(insuredCategory);
  const [insuredFormElement, setInsuredFormElement] = useState<HTMLFormElement | undefined>();

  const [openEditVehicle, setOpenEditVehicle] = useState(false);
  const [editVehicle, setEditVehicle] = useState(_.cloneDeep(details));
  const [vehicleFormElement, setVehicleFormElement] = useState<HTMLFormElement | undefined>();

  const insuredSubmitId = "editInsured";
  const vehicleSubmitId = "editVehicle";

  const isPolicyType_1 = useMemo(() => quote?.policy_type === "1", [quote?.policy_type]);
  const deliveryTypes = useMemo(
    () => getInsuredDeliveryTypeAsArray(insured.delivery_type),
    [insured.delivery_type]
  );

  const handleSetInsuredFormRef = useCallback((el: HTMLFormElement) => {
    // TEST
    // console.log("handleSetInsuredFormRef:", el);
    if (!el) {
      return;
    }

    setInsuredFormElement(el);
  }, []);

  const handleSetVehicleFormRef = useCallback((el: HTMLFormElement) => {
    // TEST
    // console.log("handleSetVehicleFormRef:", el);
    if (!el) {
      return;
    }

    setVehicleFormElement(el);
  }, []);

  const onOpenEditInsuredCard = useCallback(
    (isOpen) => {
      if (!isOpen) {
        setEditInsured(insured);
        setEditInsuredCategory(insuredCategory);
      }
      setOpenEditInsured(isOpen);
    },
    [insured, insuredCategory]
  );

  const getUpdateInsuredParams = useCallback(
    () => ({
      order: {
        insured_category: editInsuredCategory,
        insureds_attributes: {
          ...editInsured,
          ...editInsured.addresses[0],
          id: editInsured.id,
          beneficiary_attributes: {
            ...editInsured.beneficiary,
          },
          addresses_attributes: editInsured.addresses,
          delivery_type:
            editInsured.delivery_type && typeof editInsured.delivery_type !== "number"
              ? editInsured.delivery_type.reduce((a, b) => a + b, 0)
              : editInsured.delivery_type,
        },
      },
      checked_addon: checkedAddon,
    }),
    [editInsuredCategory, editInsured, checkedAddon]
  );

  // TODO: Revise the request interface later
  const onSubmitInsured = useCallback(() => {
    if (!insuredFormElement) {
      console.warn("insuredFormElement not found");
      return;
    }

    (async function () {
      const params = getUpdateInsuredParams();
      setPreLoading(true);
      try {
        await ordersAPI.updateOrder(orderId, params as UpdateOrderParams);
        history.go(0);
      } catch (err) {
        setPreLoading(false);
        onError && onError(err);
      }
    })();
  }, [insuredFormElement, getUpdateInsuredParams, setPreLoading, ordersAPI, history, onError]);

  // const postUpdateOrder = (orderId, data) => {

  //   const url = `/api/orders/${orderId}`;
  //   return axios.patch(url, data);
  // };

  const onOpenEditVehicleCard = useCallback(
    (isOpen) => {
      if (!isOpen) {
        setEditVehicle(details);
        setEditInsuredCategory(insuredCategory);
      }
      setOpenEditVehicle(isOpen);
    },
    [details, insuredCategory]
  );

  const getUpdateVehicleParams = useCallback(() => {
    // const formElement = document.querySelector(`form[id="${vehicleSubmitId}"]`) as HTMLFormElement;
    const formData = new FormData(vehicleFormElement);

    if (checkedAddon) formData.append("checked_addon", `${checkedAddon}`);
    convertFileListToArray(formData, "order", "vehicle_document");
    convertFileListToArray(formData, "order", "vehicle_image");

    return formData;
  }, [vehicleFormElement]);

  const onSubmitVehicle = useCallback(() => {
    if (!vehicleFormElement) {
      console.warn("vehicleFormElement not found");
      return;
    }

    (async function () {
      const data = getUpdateVehicleParams();

      setPreLoading(true);
      try {
        await ordersAPI.updateOrder(orderId, data);
        history.go(0);
      } catch (err) {
        setPreLoading(false);
        onError && onError(err);
      }
    })();
  }, [vehicleFormElement, getUpdateVehicleParams, setPreLoading, ordersAPI, history, onError]);

  const VehicleDocuments = useCallback(() => {
    return (
      <>
        <div className="detail-header bg mt-4">
          <h6>เอกสารอื่น ๆ</h6>
        </div>
        {vehicleDocuments?.length > 0 && (
          <div className="list-content">
            <ul>
              {_.orderBy(vehicleDocuments, ["dataType", "name"], ["asc", "asc"]).map(
                (doc, index) => (
                  <li key={index}>
                    {doc.dataType === "application/pdf" ? (
                      <SecureLink src={doc.url} target="_blank" rel="noreferrer">
                        Click เพื่อดู PDF
                      </SecureLink>
                    ) : (
                      <SecureLinkImg src={doc.url} width="100%" alt="" />
                    )}
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </>
    );
  }, [vehicleDocuments]);

  const VehicleImages = useCallback(() => {
    return (
      <>
        <div className="detail-header bg mt-4">
          <h6>เอกสารตรวจสภาพรถ</h6>
        </div>
        {quote?.vehicle_images?.length > 0 && (
          <div className="list-content">
            <ul>
              {_.orderBy(quote?.vehicle_images, ["dataType", "name"], ["asc", "asc"]).map(
                (doc, index) => (
                  <li key={index}>
                    {doc.dataType === "application/pdf" ? (
                      <SecureLink src={doc.url} target="_blank" rel="noreferrer">
                        Click เพื่อดู PDF
                      </SecureLink>
                    ) : (
                      <SecureLinkImg src={doc.url} width="100%" />
                    )}
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </>
    );
  }, [quote?.vehicle_images]);

  return (
    <div>
      {insuresInfo &&
        insuresInfo.map((insure, index) => {
          return (
            <>
              <div
                className={classNames(
                  "flex detail-header bg justify-between",
                  index > 0 ? "mt-6" : null
                )}
              >
                <h6 className="bg">
                  {productType === "travel"
                    ? `ข้อมูลประกันเดินทาง ${insuresInfo.length > 1 ? `คนที่ ${index + 1}` : ""}`
                    : "ข้อมูลประกันภัยรถยนต์ภาคสมัครใจ"}
                </h6>
                <div className="flex items-center">
                  {openEditInsured && (
                    <Button
                      color="primary"
                      variant="none"
                      type="submit"
                      form={insuredSubmitId}
                      disabled={!insuredFormElement}
                    >
                      บันทึก
                    </Button>
                  )}
                  {allowEdit && (
                    <IconButton
                      disabled={!ordersAPI}
                      onClick={() => onOpenEditInsuredCard(!openEditInsured)}
                    >
                      {openEditInsured ? <Close /> : <Edit />}
                    </IconButton>
                  )}
                </div>
              </div>
              {openEditInsured ? (
                <EditInsuredCard
                  submitId={insuredSubmitId}
                  insured={editInsured}
                  insuredCategory={editInsuredCategory}
                  contract={contract}
                  // checkedAddon={checkedAddon}
                  formRef={handleSetInsuredFormRef}
                  onSubmit={onSubmitInsured}
                  onInsuredChanged={setEditInsured}
                  onInsuredCategoryChanged={setEditInsuredCategory}
                />
              ) : (
                <InsuresDetailInfo
                  insure={insure}
                  insuredCategory={insuredCategory}
                  productType={productType}
                  policies={policies}
                  index={index}
                />
              )}
            </>
          );
        })}

      {["motor", "cmi"].includes(productType) && (
        <div className="flex detail-header bg justify-between mt-4">
          <h6>ข้อมูลรถยนต์</h6>
          <div className="flex items-center">
            {openEditVehicle && (
              <Button
                color="primary"
                variant="none"
                type="submit"
                form={vehicleSubmitId}
                disabled={!vehicleFormElement}
              >
                บันทึก
              </Button>
            )}
            {allowEdit && (
              <IconButton onClick={() => onOpenEditVehicleCard(!openEditVehicle)}>
                {openEditVehicle ? <Close /> : <Edit />}
              </IconButton>
            )}
          </div>
        </div>
      )}

      {openEditVehicle ? (
        <EditVehicleCard
          submitId={vehicleSubmitId}
          allowEdit={allowEdit}
          vehicle={editVehicle}
          isPolicyType_1={isPolicyType_1}
          vehicleDocuments={vehicleDocuments}
          vehicleImages={quote?.vehicle_images || []}
          attachImagesStatus={attachImagesStatus}
          province={quotation?.criteria?.province}
          formRef={handleSetVehicleFormRef}
          onSubmit={onSubmitVehicle}
          onVehicleChanged={setEditVehicle}
        />
      ) : (
        <>
          {productType !== "travel" && (
            <div className="list-content">
              <List
                label="รุ่นรถยนต์"
                value={
                  quotation?.criteria
                    ? `${quotation?.criteria?.car_brand} ${quotation.criteria?.car_model}`
                    : "-"
                }
              />
              <List label="ปี ค.ศ.​รถยนต์" value={checkEmpty(quotation.criteria?.year)} />
              <List label="ทะเบียนรถยนต์" value={checkEmpty(details.plate_number)} />
              <List label="จังหวัดที่จดทะเบียน" value={checkEmpty(details.plate_province)} />
              <List
                label="หมายเลขตัวถังรถยนต์ (คัสซี)"
                value={checkEmpty(details.chassis_number)}
              />
              {quotation.criteria?.vehicle_type && (
                <List label="ประเภทรถยนต์" value={checkEmpty(quotation.criteria?.vehicle_type)} />
              )}
            </div>
          )}
          {isPolicyType_1 && <VehicleImages />}
          {productType !== "travel" && <VehicleDocuments />}
        </>
      )}
      {preLoading && <Preloading />}
    </div>
  );
}
