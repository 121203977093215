import React from "react";
import { parseDate, dateFormat } from "/src/helpers/dateTime";
import { Group, Header, List } from "../SummaryList";

const CMI = ({ car, price, quoteInfo }) => {
  const {
    addon_start_date,
    addon_end_date,
    cover_start_date,
    cover_end_date,
  } = car;

  return (
    <Group>
      <Header
        icon={<span className="icon icon-document" />}
        label={`ประกันภาคบังคับ(พ.ร.บ.) ${price} ฿`}
      />
      {quoteInfo[0].policy_type === "cmi" ? (
        <List
          label="วันที่คุ้มครอง (1ปี)"
          value={`${dateFormat(parseDate(cover_start_date), "dd/MM/yyyy")} -
      ${dateFormat(parseDate(cover_end_date), "dd/MM/yyyy")}`}
        />
      ) : (
        <List
          label="วันที่คุ้มครอง (1ปี)"
          value={`${dateFormat(parseDate(addon_start_date), "dd/MM/yyyy")} -
    ${dateFormat(parseDate(addon_end_date), "dd/MM/yyyy")}`}
        />
      )}
    </Group>
  );
};

export default CMI;
