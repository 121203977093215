import { differenceInYears } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { FormGroup, ButtonRadius, Switcher, ButtonCheckbox } from "~src/components/form";
import Preloading from "~src/components/loading/Preloading";

import CheckoutContext from "~src/context/Checkout";
import axios from "~src/helpers/configAxios";
import {
  titleTHOptions,
  titleRenewTHOptions,
  titleLegalEntityTHOptions,
} from "~src/helpers/options";
import {
  LegalEntityInput,
  PersonalAdditionInput,
  InformationInput,
  AddressInput,
  AddressDeliveryInsurance,
  BeneficiaryInformationInput,
} from "./form";
import { getInsuredDeliveryTypeAsArray } from "~src/helpers/insuredHelper";
import useBasicFormInfo from "~src/pages/agent/checkout/hook/useBasicFormInfo";
import { BasicInfoFormProps } from "~src/types/checkoutBasicForm";
import { CMI } from "~src/types/cmi";

export interface CheckoutProductDetails {
  type: string;
  vehicleType: string;
  checked_addon: boolean;
  addon_selling_price: string;
  addOrRemoveCMI: CMI;
  policyType: string;
}

export interface CheckoutBasicInfoFormProps extends BasicInfoFormProps {
  productDetail: CheckoutProductDetails;
}

const BasicInfoForm: React.FC<CheckoutBasicInfoFormProps> = ({
  orderId,
  insured,
  insuredCategory,
  contract,
  productDetail,
  includeWhTax,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state: checkoutState, dispatch } = useContext(CheckoutContext);
  const [preloading, setPreloading] = useState(false);
  const { vehicleType, type: productType, addOrRemoveCMI, policyType } = productDetail;
  const { addon_selling_price, checked_addon } = addOrRemoveCMI;
  const [copied, setCopied] = useState<boolean>(true);
  const optionsInsuredCategory = t("insuredCategory", { returnObjects: true });

  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    attrPrefixInsured,
    attrPrefixBenef,
    attrPrefixDeliveryAddress,
    formatAddressesInformation,
    formatInsuredDeliveryType,
    getTextDetailForm,
    getDeliveryAddress,
  } = useBasicFormInfo(checkoutState);

  const { title, subTitle }: { title: string; subTitle: string } = getTextDetailForm(productType);
  const onSubmit = (data) => {
    formatAddressesInformation(data);
    formatInsuredDeliveryType(data);
    setPreloading(true);
    postUpdateOrder(data);
  };

  const postUpdateOrder = (data) => {
    const url = `/api/orders/${orderId}`;
    axios
      .patch(url, data)
      .then((response) => {
        setPreloading(false);
        history.push(`/checkout/${orderId}/step-2`);
      })
      .catch((error) => {
        console.error(`problem!!!! ${error}`);
      });
  };

  const handleToggleWHTax = () => {
    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        order: {
          ...checkoutState.order,
          include_wh_tax: !checkoutState.order.include_wh_tax,
        },
      },
    });
  };

  const handleChangeInsuredCategory = (evt) => {
    const { value } = evt.target;
    let idCard = "";
    if (value === "person") {
      idCard = checkoutState.insureds_attributes?.idcard;
    }

    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        insured_category: value,
        insureds_attributes: {
          ...checkoutState.insureds_attributes,
          idcard: idCard,
        },
      },
    });
  };

  const handleChangePolicyDeliveryField = (evt, name) => {
    const { value } = evt.target;
    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        delivery_address_attributes: {
          ...checkoutState.delivery_address_attributes,
          [name]: value,
        },
      },
    });
  };

  const handleChangeDeliveryAddressType = (evt) => {
    const { value } = evt.target;
    setCopied(!copied);
    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        delivery_address_attributes:
          value == "insured_address"
            ? checkoutState.insureds_attributes
            : checkoutState.delivery_address_attributes,
        insureds_attributes: {
          ...checkoutState.insureds_attributes,
          delivery_address_type: value,
        },
      },
    });
  };

  const handleChangeDeliveryType = (evt) => {
    const { value, checked } = evt.target;
    const deliveryTypes = getInsuredDeliveryTypeAsArray(
      checkoutState.insureds_attributes.delivery_type
    );
    const index = deliveryTypes.indexOf(parseInt(value));
    if (checked) {
      if (index === -1) deliveryTypes.push(parseInt(value));
    } else if (deliveryTypes.length > 1) deliveryTypes.splice(index, 1);

    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        insureds_attributes: {
          ...checkoutState.insureds_attributes,
          delivery_type: deliveryTypes,
        },
      },
    });
  };

  const handleChangeField = (evt, name) => {
    const { value } = evt.target;
    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        insureds_attributes: {
          ...checkoutState.insureds_attributes,
          [name]: value,
        },
        delivery_address_attributes: {
          ...checkoutState.delivery_address_attributes,
          [name]: copied ? value : null,
        },
      },
    });
  };

  const handleChangeFieldIdCard = (evt, name) => {
    const value = evt.target.value.replace(/[^0-9]/gi, "");
    const count = value.split("");
    if (count.length > 13) return;
    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        insureds_attributes: {
          ...checkoutState.insureds_attributes,
          [name]: value,
        },
      },
    });
  };

  const handleChangeBeneficially = (evt, name) => {
    const { value } = evt.target;
    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        insureds_attributes: {
          ...checkoutState.insureds_attributes,
          beneficiary: {
            ...checkoutState.insureds_attributes?.beneficiary,
            [name]: value,
          },
        },
      },
    });
  };

  const callbackvalueBirthDate = (date) => {
    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        insureds_attributes: {
          ...checkoutState.insureds_attributes,
          age: differenceInYears(new Date(date), new Date()) * -1,
        },
      },
    });
  };

  useEffect(() => {
    setCopied(insured.delivery_address_type !== "other_address");
    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        insured_category: insuredCategory,
        insureds_attributes: {
          ...checkoutState.insureds_attributes,
          ...insured,
          age: differenceInYears(new Date(insured.birthday), new Date()) * -1,
        },
        delivery_address_attributes: {
          ...checkoutState.delivery_address_attributes,
          ...getDeliveryAddress(insured),
        },
        order: {
          ...checkoutState.order,
          include_wh_tax: includeWhTax,
        },
      },
    });
  }, []);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {checked_addon && (
        <input type="hidden" name="checked_addon" ref={register} value={`${checked_addon}`} />
      )}

      <div className="relative motor-info-page-card">
        <h2>{title}</h2>
        {productType === "motor" && vehicleType !== "120" && (
          <p className="mb-4 -mt-2 text-primary">{subTitle}</p>
        )}

        <FormGroup label="ประเภทผู้เอาประกัน">
          <Switcher
            id="order[insured_category]"
            name="order[insured_category]"
            active={checkoutState.insured_category}
            onChange={handleChangeInsuredCategory}
            labels={optionsInsuredCategory}
          />
          <input
            type="hidden"
            name="[order][insured_category]"
            value={checkoutState.insured_category}
            ref={register}
          />
        </FormGroup>

        {checkoutState.insured_category === "legal_entity" && t("whTax") && (
          <>
            <h2 className="mt-1">ข้อมูลภาษี</h2>
            <div className="row">
              <ButtonCheckbox
                label="หักภาษี ณ ที่จ่าย 1%"
                className={{ label: "mb-4 ml-2" }}
                id="order[include_wh_tax]"
                name="order[include_wh_tax]"
                inputRef={register()}
                onChange={handleToggleWHTax}
                active={checkoutState.order.include_wh_tax}
                checked={checkoutState.order.include_wh_tax}
                value={checkoutState.order.include_wh_tax}
              />
            </div>
          </>
        )}

        <h2>ข้อมูลผู้เอาประกัน</h2>
        {checkoutState.insured_category == "legal_entity" ? (
          <>
            <LegalEntityInput
              insuredId={insured.id}
              attrPrefixInsured={attrPrefixInsured}
              insuredsAttributes={checkoutState.insureds_attributes}
              errorsInsuredsAttr={errors.order?.insureds_attributes}
              handleChangeField={handleChangeField}
              handleChangeFieldIdCard={handleChangeFieldIdCard}
              additionalFieldsEnabled={policyType === "cmi"}
              register={register}
              options={{
                selectFields: titleLegalEntityTHOptions,
              }}
            />

            {(process.env.APP_NAME != "mti" || addon_selling_price !== undefined) && (
              <>
                <h2>ข้อมูลผู้เอาประกันเพิ่มเติม (บุคคล)</h2>
                <InformationInput
                  insuredId={insured.id}
                  insuredsAttributes={checkoutState.insureds_attributes}
                  attrPrefixInsured={attrPrefixInsured}
                  errorsInsuredsAttr={errors.order?.insureds_attributes}
                  register={register}
                  handleChangeField={handleChangeField}
                  options={{
                    selectFields: contract === "renew" ? titleRenewTHOptions : titleTHOptions,
                  }}
                />
              </>
            )}
          </>
        ) : (
          <InformationInput
            insuredId={insured.id}
            insuredsAttributes={checkoutState.insureds_attributes}
            attrPrefixInsured={attrPrefixInsured}
            errorsInsuredsAttr={errors.order?.insureds_attributes}
            register={register}
            handleChangeField={handleChangeField}
            options={{
              selectFields: contract === "renew" ? titleRenewTHOptions : titleTHOptions,
            }}
          />
        )}

        {checkoutState.insured_category === "person" && (
          <PersonalAdditionInput
            attrPrefixInsured={attrPrefixInsured}
            insuredsAttributes={checkoutState.insureds_attributes}
            errorsInsuredsAttr={errors.order?.insureds_attributes}
            register={register}
            handleChangeField={handleChangeField}
            handleChangeFieldIdCard={handleChangeFieldIdCard}
            callbackvalueBirthDate={callbackvalueBirthDate}
          />
        )}

        <AddressInput
          copied={copied}
          attrPrefixInsured={attrPrefixInsured}
          insuredsAttributes={checkoutState.insureds_attributes}
          errors={errors}
          register={register}
          handleChangeField={handleChangeField}
        />

        <AddressDeliveryInsurance
          copied={copied}
          attrPrefixDeliveryAddress={attrPrefixDeliveryAddress}
          attrPrefixInsured={attrPrefixInsured}
          insuredsAttributes={checkoutState.insureds_attributes}
          deliveryAddressAttributes={checkoutState.delivery_address_attributes}
          policyType={policyType}
          errors={errors}
          register={register}
          handleChangePolicyDeliveryField={handleChangePolicyDeliveryField}
          handleChangeDeliveryAddressType={handleChangeDeliveryAddressType}
          handleChangeDeliveryType={handleChangeDeliveryType}
        />

        <BeneficiaryInformationInput
          beneficiary={insured.id}
          attrPrefixBenef={attrPrefixBenef}
          insuredsAttributes={checkoutState.insureds_attributes}
          register={register}
          handleChangeBeneficially={handleChangeBeneficially}
          errors={errors}
        />

        <div className="row">
          <div className="col-12">
            <div className="motor-info-page-actions">
              <ButtonRadius type="submit" color="primary">
                บันทึกและดำเนินการต่อ
              </ButtonRadius>
            </div>
          </div>
        </div>
      </div>

      {preloading && <Preloading />}
    </form>
  );
};

export default BasicInfoForm;
