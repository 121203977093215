module.exports = {
    "openMenu": "/motors",
    "quickMenuTravel": false,
    "quickMenuAccident": "https://drive.google.com/drive/folders/1HCgMGSohJDJ2nBdLy21hT2VK2NXHT5HU?usp=sharing",
    "quickMenuFire": "https://drive.google.com/drive/folders/1mmH9Z0JHFmeqLDaFFwF2mcux166-cK58",
    "quickMenuOther": "https://drive.google.com/drive/folders/1Ihrjb-NBLtMI_qfoWnDliLhSsKCa98KA",
    "registerButton": true,
    "menuCommission": true,
    "Referral": false,
    "tabSearchCommercial": true,
    "policyFromOldSystem": false,
    "insurersFilter": true,
    "agentCheckboxConfirm": false,
    "staffCheckboxConfirm": false,
    "receiptAgentDashBoard": false,
    "modalEndOfYearWarning": false,
    "buttonLine": true,
    "adjustSumInsured": false,
    "importRenewalFile": true,
    "armoryTab": true,
    "commissionShow": true,
    "paySlipAttach": true,
    "vehicleType": false,
    "partner": true,
    "customQuote": true,
    "trackingPolicy": true,
    "whTax": true
};