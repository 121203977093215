import React from "react";

interface BadgeCommonProps {
  color: "primary" | "secondary" | "secondary-light";
  children: JSX.Element | React.ReactNode;
}

const BadgeCommon: React.FC<BadgeCommonProps> = ({ children, color }): JSX.Element => {
  return (
    <div className={`bg-${color} w-fit px-1 border-${color}-1 rounded-20 border-1 cursor-pointer`}>
      {children}
    </div>
  );
};

export default BadgeCommon;
