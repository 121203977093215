import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { isDesktop, isMobileOnly, isTablet, withOrientationChange } from "react-device-detect";
import QueryString from "query-string";
import useFetch from "~src/hooks/useFetch";
import Header from "../header";
import Sidebar from "../sidebar";
import { useAuthContext } from "../../../context/Auth";
import { isJsonString } from "~src/helpers/helpers";

export interface PublicPageProps {
  logo: any;
  sidebar: boolean;
  header: boolean;
  isPortrait?: boolean;
}

const PublicPage = ({
  logo,
  sidebar,
  header,
  isPortrait,
  children,
}: React.PropsWithChildren<PublicPageProps>) => {
  const { search } = window.location;
  const [querires] = useState(QueryString.parse(search));
  const { session, dispatch: dispatchAuth } = useAuthContext();
  const history = useHistory();
  const { response, error, isLoading } = useFetch(
    `/api/v1/secure_links/decode?token=${querires.token}`
  );

  // useEffect(() => {
  //   //if (error) history.push({ pathname: "/login" });
  // }, [error]);

  useEffect(() => {
    dispatchAuth({
      type: "MODIFY",
      payload: {
        ...session,
        username: Cookies.get("username") || null,
        role: isJsonString(Cookies.get("role")) ? JSON.parse(Cookies.get("role")) : null,
        title: Cookies.get("title") || null,
        firstname: Cookies.get("firstname") || null,
        lastname: Cookies.get("lastname") || null,
        permissions: isJsonString(Cookies.get("permissions"))
          ? JSON.parse(Cookies.get("permissions"))
          : null,
      },
    });
  }, []);

  useEffect(() => {
    if (isDesktop) localStorage.removeItem("showOnMobile");
  }, [isDesktop]);

  return (
    <div>
      {localStorage.getItem("showOnMobile") === null &&
      (isMobileOnly || (isTablet && isPortrait)) ? (
        <>
          {header && !isLoading && <Header logo={logo} isPublic={true} auditAgent={null} />}
          <div className="overflow-x-scroll">
            <div className="w-screen">
              {/* <div style={{ width: 1024 }}> */}
              <section className={header ? "page-wrapper" : ""}>
                {sidebar && <Sidebar />}
                {/* showsidebar={true} */}
                <div className={sidebar ? "content-wrapper" : ""}>{children}</div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <>
          {header && !isLoading && <Header logo={logo} isPublic={true} auditAgent={null} />}
          <section className={header ? "page-wrapper" : ""}>
            {sidebar && <Sidebar />}
            {/* showsidebar={true} */}
            <div className={sidebar ? "content-wrapper" : ""}>{children}</div>
          </section>
        </>
      )}
    </div>
  );
};

export default withOrientationChange<React.PropsWithChildren<PublicPageProps>>(PublicPage);
