import React from "react";
import "./home.scss";

const CookieConsent: React.FC = (): JSX.Element => {
  return (
    <div className="pt-20 page">
      <p className="text-lg font-bold">นโยบายความเป็นส่วนตัว</p>
      <p className="text-justify text-indent-home">
        นโยบายคุกกี้นี้ ("นโยบายคุกกี้") อธิบายถึงคุกกี้และเทคโนโลยีอื่นๆ ที่ บริษัท เมืองไทย
        โบรกเกอร์จำกัด ("MTB", "เรา", "พวกเรา" or "ของพวกเรา") ใช้บนเว็บไซต์ ("ไซต์")
        และตัวเลือกต่างๆ ที่ผู้ใช้งานมี
        นโยบายคุกกี้นี้เป็นส่วนหนึ่งของนโยบายความเป็นส่วนตัวของบริษัท เมืองไทย โบรกเกอร์ จำกัด
      </p>
      <p className="text-justify text-indent-home mt-4">
        เมื่อคุณเข้าชมไซต์ครั้งแรก คุณจะถูกถามให้ยินยอมในการใช้คุกกี้ตามนโยบายคุกกี้นี้
        กรุณารับทราบว่าหากคุณยอมรับ เราจะเก็บมันไว้บนคอมพิวเตอร์ของคุณ
      </p>

      <p className="text-justify text-indent-home mt-4">
        'คุกกี้' คือ ชิ้นส่วนของข้อมูลที่ถูกส่งไปยังบราวเซอร์ของคุณตามเว็บไซต์ที่คุณเข้าชม
        ไซต์จะใช้คุกกี้แบบ Necessary
        (อันที่ถูกกำหนดโดยเว็บไซต์ที่กำลังถูกเข้าชมโดยผู้ใช้งานในเวลานั้น ยกตัวอย่างเช่น คุกกี้ของ
        gettgo.com) และคุกกี้แบบ third-party (ที่ถูกกำหนดโดยโดเมนอื่น) ตามที่ถูกอธิบายไว้ข้างล่าง
      </p>

      <p className="text-justify text-indent-home mt-4">
        คุกกี้สามารถถูกเก็บไว้บนคอมพิวเตอร์ของคุณในหลายๆ ช่วงเวลา
        พวกมันสามารถอยู่ในรูปแบบของไม่ว่าจะเป็น "คุกกี้แบบ session" หรือ "คุกกี้แบบ persistent"
        คุกกี้แบบ session จะมีอายุอยู่นานเท่ากับเซสชั่นของบราวเซอร์
        และจะถูกลบออกไปโดยอัตโนมัติเมื่อคุณปิดบราวเซอร์ของคุณ คุกกี้แบบ persistent
        จะยังอยู่หลังจากบราวเซอร์ของคุณถูกปิดไป และจะยังอยู่จนกว่ามันจะหมดอายุ
        (ตามที่ถูกกำหนดไว้โดยบุคคลที่สามที่เป็นคนวางมันไว้) หรือจนกว่าคุณได้ลบคุกกี้ออกไปเอง
        คุกกี้แบบ persistent จะถูกใช้ในการช่วยไซต์จดจำ และระบุคอมพิวเตอร์ของคุณ
        เมื่อคุณเปิดบราวเซอร์ของคุณและเล่นอินเตอร์เน็ตอีกครั้ง
      </p>

      <p className="text-justify text-indent-home mt-4">
        เมื่อคุณเข้าชมไซต์ครั้งแรก คุณจะถูกถามให้ยินยอมในการใช้คุกกี้ตามนโยบายคุกกี้นี้
        กรุณารับทราบว่าหากคุณยอมรับ เราจะเก็บมันไว้บนคอมพิวเตอร์ของคุณ
      </p>

      <p className="text-justify text-indent-home mt-4">
        ข้อมูลที่ถูกเก็บผ่านคุกกี้อาจประกอบไปด้วยข้อมูลเกี่ยวกับ IP (Internet Protocol) address
        ของอุปกรณ์ของคุณ ประเภทของบราวเซอร์ ภาษา ระบบปฏิบัติการ รัฐหรือประเทศที่คุณได้เข้าถึงไซต์
        วันที่และเวลาที่คุณเยี่ยมชม จำนวนลิงค์ที่คุณคลิกบนไซต์นี้ ฟังก์ชั่นที่คุณใช้งาน
        การค้นหาที่คุณได้ร้องขอไป และข้อมูลที่คุณได้บันทึกขณะอยู่บน gettgo.com
        อาจใช้ข้อมูลที่เก็บมานี้ในจุดประสงค์ที่ถูกต้องและมีความจำเป็นในหลายรูปแบบ
        ซึ่งประกอบไปด้วยการยืนยันตัวตนของผู้ใช้งาน การปรับเปลี่ยนหน้าจอผู้ใช้งาน ความปลอดภัย
        การค้นคว้าวิจัย และการวิเคราะห์เพื่อปรับปรุงการทำงานของไซต์ของเรา และการโฆษณา
        (ข้อมูลเพิ่มเติมเกี่ยวกับวิธีการที่เราใช้ข้อมูลของคุณ กรุณาอ่าน นโยบายความเป็นส่วนตัว
        ของเรา)
      </p>
    </div>
  );
};

export default CookieConsent;
