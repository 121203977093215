import { AxiosResponse } from "axios";
import { BasicResponse } from "~src/types/basic";
import { Order } from "~src/types/order";
import axios from "~src/helpers/configAxios";
import {
  CreateQuotationPayload,
  Quotation,
  QuotationPlaceOrderPayload,
} from "~src/types/quotation";

export const createQuotation = (
  payload: CreateQuotationPayload
): Promise<AxiosResponse<BasicResponse<Quotation>>> => {
  const url = `api/quotations`;
  return axios.post(url, payload);
};

export const quotationPlaceOrder = (
  payload: QuotationPlaceOrderPayload
): Promise<AxiosResponse<BasicResponse<Order>>> => {
  const url = `api/quotations/place_order`;
  return axios.post(url, payload);
};
