import React, { useState } from "react";
import { format, addMonths } from "date-fns";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import { Dialog, IconButton } from "@material-ui/core";
import { Close, Publish, Refresh } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Tab from "./components/Tab";
import Table from "./table/RenewTable";
import ErrorPage from "../../../components/errorPage";
import SectionHeader from "../../../components/layout/staff/SectionHeader";
import Search from "./components/Search";
import useAxios from "../../../hooks/useAxios";
import { urlStringify } from "../../../helpers/helpers";
import { Policy } from "../../../types/policy";
import { FormGroup, TextField } from "~src/components/form";
import { messages } from "~src/helpers/messages";
import useHttp from "~src/hooks/useHttp";
import Preloading from "~src/components/loading/Preloading";

interface Data {
  current_page: number;
  total_count: number;
  total_pages: number;
  content: Policy[];
}

interface UploadFile {
  renewalsFile: string;
}

const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const Renewal = () => {
  const { t } = useTranslation();
  const { post } = useHttp();
  const location = useLocation();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const expireFrom = format(new Date(), "yyyy-MM-dd");
  const expireTo = format(addMonths(new Date(), 4), "yyyy-MM-dd");
  const keyword = queryParams?.keyword ?? "";
  const [searchValue, setSearchValue] = useState<string>(keyword.toString());
  const [openImportRenewalModal, setOpenImportRenewalModal] = useState<boolean>(false);
  const [openResultModal, setOpenResultModal] = useState<boolean>(false);
  const [preLoading, setPreLoading] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<boolean>(false);
  const [renewalFile, setRenewalFile] = useState(null);

  const SUPPORTED_FORMATS = [
    ".csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const { handleSubmit, errors, register } = useForm<UploadFile>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleUploadFile = (): void => {
    const formData = new FormData();
    if (renewalFile !== null) formData.append("file", renewalFile);
    setOpenImportRenewalModal(false);
    setPreLoading(true);
    post("/api/renewals/import-data-file", formData)
      .then((result) => {
        setUploadStatus(result?.status);
        setPreLoading(false);
        setOpenResultModal(true);
      })
      .catch((error) => {
        setUploadStatus(error?.status);
        setPreLoading(false);
        setOpenResultModal(true);
      });
  };

  const handleSelectedFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setRenewalFile(e.target.files[0]);
  };

  const response = useAxios<Data>(
    `/api/policies?status=success&${urlStringify({
      page: DEFAULT_PAGE,
      per_page: PER_PAGE,
      expired_from: expireFrom,
      expired_to: expireTo,
      warning_renew: true,
      main_policy: true,
      ...queryParams,
    })}`
  );

  const handlePageClick = (page: { selected: number }): void => {
    const selectedPage = page.selected + 1;
    const queries = urlStringify({
      page: selectedPage,
      per_page: PER_PAGE,
      expired_from: expireFrom,
      expired_to: expireTo,
    });
    window.location.href = `${location.pathname}?${queries}`;
  };

  const handleReloadPage = (): void => {
    const queries = urlStringify({
      keyword: searchValue,
      per_page: PER_PAGE,
      expired_from: expireFrom,
      expired_to: expireTo,
      page: 1,
    });

    window.location.href = `${location.pathname}?${queries}`;
  };

  const handleSearch = (e: React.FormEvent): void => {
    e.preventDefault();
    const queries = urlStringify({
      keyword: searchValue,
      per_page: PER_PAGE,
      expired_from: expireFrom,
      expired_to: expireTo,
      page: 1,
    });
    window.location.href = `${location.pathname}?${queries}`;
  };

  return (
    <div className="p-4">
      {response.status === "error" ? (
        <ErrorPage />
      ) : (
        <div>
          <SectionHeader
            title="กรมธรรม์รอต่ออายุ"
            breadcrumbs={[{ url: "#", label: "กรมธรรม์", current: true }]}
            searchComponent={
              <Search
                value={searchValue}
                onInputChange={(e) => setSearchValue(e.target.value)}
                onSubmit={handleSearch}
              />
            }
          />
          <hr />
          <Tab />

          {location.pathname === "/staffs/renewal" && t("importRenewalFile") && (
            <div className="text-right -mt-11 mb-2">
              <button
                type="button"
                onClick={() => setOpenImportRenewalModal(true)}
                className="bg-pink-600 hover:bg-pink-800 text-white rounded-md px-3 py-2 mx-1 transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
              >
                Import ใบเตือน
              </button>
            </div>
          )}

          <div className="relative p-4 bg-white shadow z-1">
            <Table loadStatus={response.status} data={response.data?.content} />
            {response.status === "loaded" && response.data?.total_pages > 1 && (
              <ReactPaginate
                previousLabel="Prev"
                nextLabel="Next"
                breakLabel="..."
                breakClassName="break-me"
                initialPage={response.data?.current_page - 1}
                pageCount={response.data?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="gm-paginate"
                // subContainerClassName={"pages pagination"}
                activeClassName="active"
                disableInitialCallback={true}
              />
            )}
          </div>

          <Dialog open={openImportRenewalModal}>
            <div className="rounded-3xl">
              <div className="flex flex-row items-center justify-between w-full py-1 pl-4 text-lg text-white border-b bg-primary-color border-custom-gray-200">
                อัพโหลดข้อมูลใบเตือน
                <IconButton
                  component="span"
                  onClick={() => {
                    setRenewalFile(null);
                    setOpenImportRenewalModal(false);
                  }}
                >
                  <Close />
                </IconButton>
              </div>
              <div className="px-4 py-6">
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit(handleUploadFile)}
                  encType="multipart/form-data"
                >
                  <div>หมายเหตุ: รองรับชนิดของไฟล์เป็น .xls และ .xlsx เท่านั้น</div>
                  <div className="flex justify-center w-full mt-4">
                    <FormGroup>
                      <TextField
                        type="file"
                        className="truncate bg-white hover:bg-custom-gray-sm"
                        id="renewalsFile"
                        name="renewalsFile"
                        accept={SUPPORTED_FORMATS.join(",")}
                        inputRef={register({
                          ...messages.uploadFileRequired,
                        })}
                        error={errors?.renewalsFile?.message}
                        onChange={handleSelectedFile}
                      />
                    </FormGroup>
                  </div>
                  <div className="flex justify-center w-full mt-2">
                    {renewalFile === null ? (
                      <button
                        type="button"
                        className="px-4 py-2 font-bold text-white rounded-full shadow opacity-50 cursor-not-allowed bg-primary-color"
                        disabled
                      >
                        <Publish /> เพิ่มข้อมูลใบเตือน
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="px-4 py-2 font-bold text-white transition duration-300 ease-in-out rounded-full shadow bg-primary-color focus:outline-none hover:bg-primary-color-dark disabled:opacity-50"
                      >
                        <Publish /> เพิ่มข้อมูลใบเตือน
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Dialog>

          <Dialog open={openResultModal}>
            <div className="rounded-3xl">
              <div className="flex flex-row items-center justify-between w-full py-1 pl-4 text-lg text-white border-b bg-primary-color border-custom-gray-200">
                อัพโหลดข้อมูลใบเตือน
                <IconButton
                  component="span"
                  onClick={() => {
                    setRenewalFile(null);
                    setOpenResultModal(false);
                  }}
                >
                  <Close />
                </IconButton>
              </div>
              <div className="px-4 py-6">
                <div className="text-base">
                  {uploadStatus
                    ? "อัพโหลดข้อมูลสำเร็จ"
                    : "ทำรายการไม่สำเร็จ ส่ง email ถึงผู้เกี่ยวข้องเรียบร้อยแล้ว"}
                </div>
                <div className="flex justify-center mt-6">
                  <button
                    type="button"
                    className="px-4 py-2 font-bold text-white transition duration-300 ease-in-out rounded-full shadow bg-primary-color focus:outline-none hover:bg-primary-color-dark disabled:opacity-50"
                    onClick={handleReloadPage}
                  >
                    <Refresh /> โหลดข้อมูลใหม่
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      )}
      {preLoading && <Preloading />}
    </div>
  );
};

export default Renewal;
