import React from "react";
// import PropTypes from "prop-types";

import "./search-form.scss";

export interface SearchFormProps extends React.ComponentPropsWithoutRef<"input"> {
  handleClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleKeyPress: (e: React.KeyboardEvent<HTMLElement>) => void;
}

export default function SearchForm({ handleClick, handleKeyPress, ...htmlProps }) {
  return (
    <div className="staff-search-form">
      <input className="search-input" {...htmlProps} onKeyPress={(e) => handleKeyPress(e)} />
      <button type="button" className="search-button" onClick={(e) => handleClick(e)}>
        <span className="material-icons">search</span>
      </button>
    </div>
  );
}

// SearchForm.propTypes = {};

// export default SearchForm;
