import Decimal from "decimal.js";
import _isEmpty from "lodash/isEmpty";
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import QueryString from "query-string";
import { formatNumberWithCurrency } from "~src/helpers/formatNumber";
import Tab from "~src/pages/agent/payment/tab";
import BankTransferMethod from "~src/pages/agent/payment/method/bankTransfer";
import CreditMethod from "~src/pages/agent/payment/method/credit";
import InstallmentMethod from "~src/pages/agent/payment/method/installment";
import TwoCTwoP from "~src/pages/agent/payment/method/2c2p";
import UserCreditMethod from "~src/pages/agent/payment/method/userCredit";
import InsurancePlanSummary3 from "~/src/components/insurancePlanSummary/insurancePlanSummary3";
import QRPayment from "~src/components/payments/QRPayment";
import useKbankPaymentButton from "~src/components/payments/useKbankPaymentButton";
import Stepper from "~/src/components/stepper/Stepper";
import dictionary from "~src/data.yaml";
import {
  CreateCCPaymentContentResponse,
  CreateCCPaymentResponse,
  PaymentEventResponse,
  usePaymentsAPI,
} from "~src/api/Payments";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { ThankyouPageContextProvider, useThankyouPageContext } from "~src/context/ThankyouPage";
import useOrder from "~src/hooks/useOrder";
import APIError from "~src/models/APIError";
import { CheckoutSummaryState, PublicPaymentSummaryState } from "~src/models/CheckoutState";
import {
  createPaymentEventAction,
  createResetAction,
  createUpdateAction,
  createUpdateActionWithPayment,
  initState as initThankyouPageState,
  restoreStateFromStorage as restoreThankyouPageState,
  clearStateFromStorage as clearThankyouPageStateFromStorage,
} from "~src/reducer/ThankyouPageReducer";
import { Order, Status as OrderStatus } from "~src/types/order";
import { Payment } from "~src/types/payment";
import TravelProduct from "~src/models/TravelProduct";

import "./payments.scss";
import PaymentsAlertModal, {
  PaymentsAlertModalProps,
} from "~src/components/modal/payments/PaymentsAlertModal";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  closeCoverageModal,
  coverageModalState,
  fetchProductById,
  fetchProductByIdAction,
  openCoverageModal,
  resetProducts,
  setTravelers,
  travelProductState,
} from "~src/features/travel/redux";
import CoverageModal from "~src/features/travel/CoverageModal";
import Preloading from "~src/components/loading/Preloading";
import CommonModal from "~src/components/modal/common";

interface PaymentsPageTabsProps {
  publicPath?: boolean;
  summary: CheckoutSummaryState;
  order: Order;
  payment?: Payment;
  defaultTab?: string;
  setIsKbankCCButtonLoading?: (state: boolean) => void;
  gotoCheckoutSummary?: () => void;
  onTabChanged?: (selectedTab: string) => void;
  gotoUploadPayslip: (paymentMethod: string) => void;
  onActiveInstallmentCardChanged?: (idx: number) => void;
}

const PaymentsPageTabs: React.FC<PaymentsPageTabsProps> = ({
  publicPath,
  summary,
  order,
  payment,
  defaultTab,
  setIsKbankCCButtonLoading,
  gotoCheckoutSummary,
  onTabChanged,
  onActiveInstallmentCardChanged,
  gotoUploadPayslip,
}) => {
  const { onError, setAlert, setPreloading, reloadPage } = usePageContext();
  const {
    dispatch: dispatchThankyou,
    gotoPage: gotoThankyou,
    state: thankyouPageState,
  } = useThankyouPageContext();

  // TEST
  // useWhatChanged([setAlert], "setAlert", "PaymentsPageTabs#1", "PaymentsPageTabs#1");
  // TODO: Refactor/Consolidate flow here
  const paymentsAPIOpts = useMemo(
    () => ({
      redirect: (url) => {
        window.location.href = url;
        // history.push(url);
      },
      onError: (err) => setAlert(err.message),
    }),
    [setAlert]
  );

  const { paymentsAPI } = usePaymentsAPI(paymentsAPIOpts);

  const { orderNumber, paymentTotalAmount } = summary;
  const { order_id: orderID } = order;

  const [isShowAlertModal, setIsShowAlertModal] = React.useState(false);
  const [alertMessages, setAlertMessages] = React.useState<string[]>([]);
  const [alertModalProps, setAlertModalProps] = React.useState<Partial<PaymentsAlertModalProps>>();
  // TODO: Revise this later
  const [kbankBackyardElement, setKbankBackyardElement] = useState<HTMLElement>();
  const [kbankErrors, setKbankErrors] = useState<Error[]>([]);
  const [kbankCCLastResponse, setKBankCCLastResponse] = useState<
    CreateCCPaymentResponse | undefined
  >();

  const kbankPaymentButtonOpts = useMemo(
    () => ({
      amount: summary.paymentTotalAmountIncludedCCFee,
      orderID,
      orderNumber,
      reloadPage,
      onLoaded: (_el, tempFormElement) => {
        tempFormElement && tempFormElement.classList.add("hidden");
      },
      onError: (err) => setAlert(err, { useKbankPaymentButton: (err as APIError<any>).details }),
    }),
    [summary.paymentTotalAmountIncludedCCFee, orderID, orderNumber, reloadPage, setAlert]
  );

  const {
    isLoading: isKbankCCButtonLoading,
    loadingCounter: kbankCCButtonLoadingCounter,
    formElement: kbankCCFormElement,
    formRefCallback: kbankCCFormRefCallback,
  } = useKbankPaymentButton(kbankPaymentButtonOpts);

  const [currentTab, setCurrentTab] = useState(defaultTab);
  const [isValidChannel, setIsValidChannel] = useState(true);

  const subscriptionRef = useRef();
  const unsubscribeFnRef = useRef();

  const tabAlerters = useMemo(() => {
    const _map: Record<string, (Error) => void> = {};
    for (const tab of ["2c2p", "qr", "usercredit"]) {
      _map[tab] = (err: Error) => setAlert(err.message, { [tab]: (err as APIError<any>).details });
    }

    return _map;
  }, []);

  const handleShowAlertModal = useCallback(
    (messages: string[], props?: Partial<PaymentsAlertModalProps>) => {
      setAlertMessages(messages);
      setAlertModalProps(props || {});
      setIsShowAlertModal(true);
    },
    []
  );

  const handleTabChange = (selectedTab) => {
    setCurrentTab(selectedTab);
    document.querySelectorAll(".payment-methods-tab").forEach((t) => {
      t.classList.remove("tab-on");
    });
    const headTab = document.getElementById(`tab-${selectedTab}`);
    headTab.classList.add("tab-on");

    onTabChanged && onTabChanged(selectedTab);
  };

  function handlePaymentEventResponse(evt: PaymentEventResponse) {
    // TEST
    console.log(`Event from PaymentChannel [${order.order_id}]:`, evt);

    dispatchThankyou(createPaymentEventAction(evt));

    // if (evt.status === "completed") {
    if (evt.payment_status === "paid") {
      gotoThankyou();
    }
  }

  function handleSetKbankBackyardRef(element) {
    if (!element) {
      return;
    }

    setKbankBackyardElement(element);
  }

  function handleKbankCCFormSubmit(evt: React.FormEvent) {
    evt.preventDefault();

    if (order.payments_summary.is_installments) {
      handleShowAlertModal(dictionary.payment.cash_installment_already_set_up);
      return;
    }

    const _formEl = evt.target as HTMLElement;
    const formInputs = _formEl.querySelectorAll("input");
    const formData: Record<string, any> = [...formInputs].reduce(
      (m, el) => ({ ...m, [el.name]: el.value }),
      {}
    );
    const formBtns = _formEl.querySelectorAll("button");

    (async function () {
      try {
        unstable_batchedUpdates(() => {
          setKbankErrors([]);
          setAlert(undefined);
          setPreloading(true);
        });
        const respData = await paymentsAPI.createCCPayment({
          ...formData,
          orderID,
          token: formData.token || "",
        });
        setKBankCCLastResponse(respData);

        // TODO: Revise this later
        if (respData.content) {
          dispatchThankyou(createUpdateActionWithPayment(respData.content.payment));
          dispatchThankyou(
            createUpdateAction({ isRedirectingToKbankCC: respData.redirecting }, true)
          );
          if (!respData.redirecting && respData.content.payment.status === "paid") {
            gotoThankyou();
          }
          // setPreloading(false);
          // setTimeout(() => history.go(0), 1);
        }
      } catch (err) {
        console.error(err);
        // TODO: Revise this later
        let userErrMsgs: string[] = [];
        let statusCode = 0;
        if (err instanceof APIError && err.response) {
          statusCode = err.response.status;
          const respData = err.response.data?.content;
          setKBankCCLastResponse(respData);
          if (respData && "provider_result" in respData) {
            const providerResult = (respData as CreateCCPaymentContentResponse).provider_result;
            if (providerResult) {
              userErrMsgs = [
                `[${providerResult.code}] ${providerResult.message} - ${providerResult.transaction_state}`,
              ];
            }
          }
        } else {
          userErrMsgs = Array.isArray(err.message) ? err.message : [err.message];
        }
        const _kbankErrors = userErrMsgs.map(
          (errMsg) => new APIError(errMsg, { statusCode, cause: err })
        );

        unstable_batchedUpdates(() => {
          setKbankErrors(_kbankErrors);
          setPreloading(false);
        });

        for (let i = 0; i < formBtns.length; i++) {
          const btn = formBtns.item(i);
          btn.classList.remove("processing");
        }
      }
    })();
  }

  useEffect(() => {
    const restoredThankyouState = restoreThankyouPageState();
    if (restoredThankyouState) {
      dispatchThankyou(createResetAction(restoredThankyouState));
      clearThankyouPageStateFromStorage();
    } else {
      dispatchThankyou(
        createResetAction(initThankyouPageState(summary, order, order.payments?.[0]?.pay_method))
      );
    }

    // TODO: Revise the flow here later
    let subs;
    let unsubscribe;
    let firstPaidPayment = order.payments.find((payment) => payment.status === "paid");

    if (firstPaidPayment || order.status === "waiting_verify") {
      firstPaidPayment = firstPaidPayment || order.payments.find((payment) => !!payment.payslip);

      dispatchThankyou(createUpdateActionWithPayment(firstPaidPayment));

      gotoThankyou();
    } else {
      payment && dispatchThankyou(createUpdateActionWithPayment(payment));
    }

    [subs, unsubscribe] = paymentsAPI.subscribePaymentChannel(
      orderID,
      undefined,
      handlePaymentEventResponse
    );
    subscriptionRef.current = subs;
    unsubscribeFnRef.current = unsubscribe;
    // cleanup
    return () => {
      if (subs && unsubscribe) {
        unsubscribe("page unmounted");
        if (subscriptionRef.current === subs) {
          subscriptionRef.current = undefined;
        }
        if (unsubscribeFnRef.current === unsubscribe) {
          unsubscribeFnRef.current = undefined;
        }
      }
    };
  }, []);

  useEffect(() => {
    setIsKbankCCButtonLoading && setIsKbankCCButtonLoading(isKbankCCButtonLoading);
  }, [isKbankCCButtonLoading]);

  useEffect(() => {
    if (order.payments_summary.is_installments) {
      handleShowAlertModal([
        dictionary.payment.invalid_payment_channel,
        dictionary.payment.cash_installment_already_set_up,
      ]);
      setIsValidChannel(false);
      return;
    }

    setIsValidChannel(true);
  }, [currentTab, order.payments_summary.is_installments]);

  useDeepCompareEffectNoCheck(() => {
    if (thankyouPageState.isRedirectingToKbankCC) {
      dispatchThankyou(createUpdateAction({ isRedirectingToKbankCC: false }));
      clearThankyouPageStateFromStorage();

      unstable_batchedUpdates(() => {
        setKbankErrors([new APIError("Transaction Incomplete", { statusCode: 400 })]);
        setKBankCCLastResponse({
          success: false,
          content: {
            payment,
            provider_result: {
              is_pending: true,
              is_card_info_error: false,
              is_card_auth_error: false,
            },
          },
        });
      });
    }
  }, [thankyouPageState.isRedirectingToKbankCC, thankyouPageState.payment]);

  return (
    <div className="payment-methods">
      <div className="payment-methods-tabs">
        <div className="flex justify-between">
          <h6>เลือกวิธีชำระเงิน</h6>
          <p className="pt-2 text-xs order-number ">
            Order: <span>{orderNumber}</span>
          </p>
        </div>
        <div className="payment-methods-tab-head">
          {process.env.APP_NAME === "mti" ? (
            <>
              <Tab
                role="button"
                id="tab-2c2p"
                className="payment-methods-tab tab-on"
                onClick={() => handleTabChange("2c2p")}
                label="2C2P"
              />
              <Tab
                role="button"
                id="tab-usercredit"
                className="payment-methods-tab"
                onClick={() => handleTabChange("usercredit")}
                label="User Credits"
              />
            </>
          ) : (
            <ShowingPaymentTabs
              handleTabChange={handleTabChange}
              summary={summary}
              isKbankCCButtonLoading={isKbankCCButtonLoading}
              publicPath={publicPath}
            />
          )}
        </div>
      </div>
      {currentTab === "bankTransfer" ? (
        <BankTransferMethod
          summary={summary}
          orderId={orderID}
          order={order}
          disabled={!isValidChannel}
          onGoBack={gotoCheckoutSummary}
          gotoUploadPayslip={() => gotoUploadPayslip("bank_transfer")}
        />
      ) : null}

      {currentTab === "qr" ? (
        <QRPayment
          orderID={orderID}
          amount={new Decimal(paymentTotalAmount)}
          totalAmount={new Decimal(paymentTotalAmount)}
          order={order}
          isInstallments={false}
          isShowOrderInfoLines={false}
          paymentsAPI={paymentsAPI}
          disabled={!isValidChannel}
          onGoBack={gotoCheckoutSummary}
          onSuccess={(_p, _o, data) => handlePaymentEventResponse(data)}
          onError={tabAlerters.qr}
        />
      ) : null}

      {currentTab === "cc" ? (
        <CreditMethod
          order={order}
          summary={summary}
          orderId={orderID}
          isLoading={isKbankCCButtonLoading}
          kbankBackyardElement={kbankBackyardElement}
          kbankCCFormElement={kbankCCFormElement}
          kbankCCLastResponse={kbankCCLastResponse}
          disabled={!isValidChannel}
          lastErrors={kbankErrors}
        />
      ) : null}

      {currentTab === "2c2p" && (
        <TwoCTwoP
          orderId={orderID}
          order={order}
          summary={summary}
          disabled={!isValidChannel}
          onError={tabAlerters["2c2p"]}
        />
      )}

      {currentTab === "usercredit" && (
        <UserCreditMethod
          isPublic={true}
          orderId={orderID}
          order={order}
          summary={summary}
          disabled={!isValidChannel}
          onError={tabAlerters.usercredit}
        />
      )}

      {currentTab === "installment" && (
        <InstallmentMethod
          orderId={orderID}
          order={order}
          summary={summary}
          disabled={!isValidChannel}
          onActiveInstallmentCardChanged={onActiveInstallmentCardChanged}
        />
      )}

      {isShowAlertModal && (
        <PaymentsAlertModal
          onClose={() => setIsShowAlertModal(false)}
          order={order}
          payment={payment}
          title={alertModalProps.title}
          icon={alertModalProps.icon}
          messages={alertMessages}
          renderActions={alertModalProps.renderActions || null}
        />
      )}

      <div id="kbankBackyard" ref={handleSetKbankBackyardRef}>
        <form
          id="kbankCCForm"
          method="POST"
          action=""
          ref={kbankCCFormRefCallback}
          className="hidden"
          onSubmit={handleKbankCCFormSubmit}
        />
      </div>
    </div>
  );
};

const ShowingPaymentTabs = ({ isKbankCCButtonLoading, handleTabChange, summary, publicPath }) => {
  return (
    <>
      <Tab
        role="button"
        id="tab-qr"
        className="payment-methods-tab tab-on"
        onClick={() => handleTabChange("qr")}
        label="QR Code"
      />
      <Tab
        role="button"
        id="tab-cc"
        className={`payment-methods-tab ${isKbankCCButtonLoading ? "disabled" : ""}`}
        onClick={() => handleTabChange("cc")}
        label="บัตรเครดิต"
      />
      {!publicPath && (
        <Tab
          role="button"
          id="tab-bankTransfer"
          className="payment-methods-tab"
          onClick={() => handleTabChange("bankTransfer")}
          label="โอนเงิน"
        />
      )}
      {!_isEmpty(summary.installmentInfo) && (
        <Tab
          role="button"
          id="tab-installment"
          className="payment-methods-tab"
          onClick={() => handleTabChange("installment")}
          label="ผ่อนชำระ"
        />
      )}
    </>
  );
};

const PaymentsInner = () => {
  const location = useLocation<PublicPaymentSummaryState>();
  const history = useHistory();
  const { onError, setAlert, setPreloading } = usePageContext();

  const coverageModal = useAppSelector(coverageModalState);
  const product = useAppSelector(travelProductState);
  const dispatch = useAppDispatch();

  const {
    orderID,
    summary,
    quotesInfo,
    quotationInfo,
    paymentSummary,
    criteriaSummary,
    priceSummary,
  } = location.state;

  const { search, pathname } = window.location;
  const [querires] = useState(QueryString.parse(search));
  const [productObj, setProductObj] = useState<TravelProduct>();
  const countdownIntervalRef = useRef();
  const publicPath = pathname.includes("public");

  useEffect(() => {
    if (product.status === "succeeded") {
      dispatch(openCoverageModal(product.data));
    }
  }, [product]);

  const fetchProduct = () => {
    const traveller = quotationInfo.criteria.traveller;

    dispatch(
      fetchProductByIdAction({ id: quotesInfo[0].product_id, token: querires.token.toString() })
    );
    dispatch(setTravelers(traveller));
  };

  const { isLoading: isOrderLoading, order } = useOrder(Number(orderID), {
    queryParams: { token: querires.token },
    suffix: "info",
    onError,
  });

  const defaultTab = useMemo(
    () => (process.env.APP_NAME === "mti" ? "2c2p" : "qr") /* "bankTransfer" */,
    []
  );

  const handleCloseCoverageModal = () => {
    dispatch(closeCoverageModal());
    dispatch(resetProducts());
  };

  const [state, setState] = useState(() => ({
    currentTab: defaultTab,
    activeInstallmentCardIndex: -1,
    isKBankCCButtonLoading: false,
    startCountDown: false,
    qrId: "",
    qrDisplay: false,
    qrImage: "",
    countdownTime: new Date().getTime(),
    intervalId: null,
    minutes: 5,
    seconds: "00",
  }));

  const firstPayment = useMemo<Payment | undefined>(() => {
    if (isOrderLoading || !order) {
      return undefined;
    }

    return order.payments[0];
  }, [isOrderLoading, order]);

  const handleSetIsKbankCCButtonLoading = useCallback((loading: boolean) => {
    setState((st) => ({
      ...st,
      isKBankCCButtonLoading: loading,
    }));
  }, []);

  const handleTabChanged = useCallback((selectedTab: string) => {
    setState((st) => ({
      ...st,
      currentTab: selectedTab,
    }));
  }, []);

  const handleActiveInstallmentCardChanged = useCallback((index: number) => {
    setState((st) => ({
      ...st,
      activeInstallmentCardIndex: index,
    }));
  }, []);

  useEffect(() => {
    setPreloading(isOrderLoading || state.isKBankCCButtonLoading); // || other required states
  }, [isOrderLoading, state.isKBankCCButtonLoading]);

  useDeepCompareEffectNoCheck(() => {
    return () => {
      countdownIntervalRef.current && clearInterval(countdownIntervalRef.current);
    };
  }, [state]);

  const gotoCheckoutSummary = useCallback(() => {
    history.push({
      pathname: `/public/travels/checkout/${orderID}/summary`,
      search: `?token=${querires.token}`,
      state: {
        orderId: orderID,
      },
    });
  }, [history, orderID]);

  const gotoUploadPayslip = (paymentMethod) => {
    const stateUpload = {
      orderID,
      summary,
      paymentMethod,
      totalAmount: summary.paymentTotalAmount,
    };

    history.push({
      pathname: `/public/checkout/orders/${orderID}/upload-payslip`,
      search: `?token=${querires.token}`,
      state: stateUpload,
    });
  };

  function getLabelSteppers(step) {
    const result = [
      { label: "ข้อมูลผู้ถือกรมธรรม์", active: false },
      { label: "ยืนยันข้อมูล", active: false },
      { label: "ชำระเงิน", active: false },
    ];
    result[step].active = true;

    return result;
  }

  return (
    <div className="payment-wrapper">
      {product.status === "loading" && <Preloading />}
      <CommonModal open={coverageModal.open} onClose={handleCloseCoverageModal}>
        <CoverageModal />
      </CommonModal>

      <div className="col-12">
        <h1 className="text-center">
          <i className="material-icons">lock</i>ยืนยันคำสั่งซื้อ
        </h1>
        <Stepper steppers={getLabelSteppers(2)} />
      </div>
      <div className="flex flex-row gap-3 tablet:flex-col laptop:flex-col">
        <div className="col-8">
          {!isOrderLoading && order && (
            <ThankyouPageContextProvider
              token={querires.token}
              isPublic={true}
              summary={summary}
              order={order}
              setPreloading={setPreloading}
            >
              <PaymentsPageTabs
                publicPath={publicPath}
                summary={summary}
                order={order}
                payment={firstPayment}
                defaultTab={defaultTab}
                setIsKbankCCButtonLoading={handleSetIsKbankCCButtonLoading}
                onTabChanged={handleTabChanged}
                onActiveInstallmentCardChanged={handleActiveInstallmentCardChanged}
                gotoCheckoutSummary={gotoCheckoutSummary}
                gotoUploadPayslip={gotoUploadPayslip}
              />
            </ThankyouPageContextProvider>
          )}
        </div>
        <div className="col-4">
          <InsurancePlanSummary3
            productId={quotesInfo[0].product_id}
            insurerImg={quotesInfo[0].insurer_icon}
            insurerName={quotesInfo[0].insurer_name}
            productName={quotesInfo[0].product_name}
            criteriaSummaryInfo={criteriaSummary}
            priceSummaryInfo={priceSummary}
            totalSummary={formatNumberWithCurrency(paymentSummary.payment_total_amount)}
            onCallback={fetchProduct}
          />
        </div>
      </div>
    </div>
  );
};

const Payments: React.FC = (props) => {
  return (
    <PageContextProvider>
      <PaymentsInner {...props} />
    </PageContextProvider>
  );
};

export default Payments;
