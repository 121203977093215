import React from "react";

interface State {
  dateFrom: string;
  dateTo: string;
}

interface ActionType {
  type: "ADD" | "RESET";
  payload: State;
}

export const initialState: State = { dateFrom: "", dateTo: "" };

export const reducer = (state: State, action: ActionType) => {
  switch (action.type) {
    case "ADD":
      return {
        dateFrom: action.payload.dateFrom,
        dateTo: action.payload.dateTo,
      };
  }
};

export const ChartContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null,
});
