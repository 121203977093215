import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthenticate } from "~src/helpers/authen";
import Cookies from "js-cookie";
import { MenulistIcon, MenulistIconExternal } from "./Menu";

import { isJsonString } from "~src/helpers/helpers";

const AgentMenu = () => {
  const { t } = useTranslation();
  const { showPaidCommission, accessType } = useAuthenticate();
  const role_json = (isJsonString(Cookies.get("role")) ? JSON.parse(Cookies.get("role")) : null)
    ?.code;

  const enableSearchMenu =
    process.env.TRAVEL_ENABLED === "true" && process.env.APP_NAME === "hero" ? "/search" : "/motors";

  const isSearchMenuHidden = accessType === "DIRECT";
  console.log(isSearchMenuHidden);
  
  return (
    <ul className="w-full overflow-y-scroll">
      <MenulistIcon
        icon="PieChart"
        label="ผลงาน"
        link="/dashboard"
        active={!!window.location.href.match(/dashboard/g)}
        hidden={isSearchMenuHidden}
      />
      <MenulistIcon
        icon="Motor1"
        label="เช็คเบี้ย"
        link={enableSearchMenu}
        active={
          !!window.location.href.match(/motors/g) ||
          !!window.location.href.match(/travel/g) ||
          !!window.location.href.match(/search/g)
        }
        hidden={isSearchMenuHidden}
      />
      <MenulistIcon
        icon="Quotation"
        label="ใบเสนอราคา"
        link="/quotations"
        active={!!window.location.href.match(/quotations/g)}
        hidden={isSearchMenuHidden}
      />
      <MenulistIcon
        icon="Order"
        label="คำสั่งซื้อ"
        link="/orders"
        active={!!window.location.href.match(/orders/g)}
        hidden={isSearchMenuHidden}
      />
      <MenulistIcon
        icon="Policytracking"
        label="กรมธรรม์"
        link="/all-policies"
        active={!!window.location.href.match(/all-policies/g)}
      />
      {t("policyFromOldSystem") && (
        <MenulistIcon
          icon="Endrosement"
          label="กรมธรรม์ระบบเก่า"
          link="/ensuremore-motor"
          active={!!window.location.href.match(/ensuremore-motor/g)}
        />
      )}
      <MenulistIcon
        icon="Renew"
        label="ใบเตือน"
        link="/renewals"
        active={!!window.location.href.match(/renewals/g)}
        hidden={isSearchMenuHidden}
      />
      {role_json !== "sales" && role_json !== "supervisor" && (
        <>
          {(t("menuCommission") && showPaidCommission) && (
            <MenulistIcon
              icon="Policytracking"
              label="อัตราค่าตอบแทน"
              link="/commission"
              active={!!window.location.href.match(/\/commission/g)}
              hidden={isSearchMenuHidden}
            />
          )}
          {t("armoryTab") && (
            <MenulistIconExternal
              icon="ArmoryIcon"
              label="คลังอาวุธ"
              link="https://gettgo.com/hero-armory"
              hidden={isSearchMenuHidden}
            />
          )}
          <MenulistIcon
            icon="Faq"
            label="สอบถาม"
            link="/contact-us"
            active={!!window.location.href.match(/contact-us/g)}
            hidden={isSearchMenuHidden}
          />
        </>
      )}

      {process.env.APP_NAME !== "mti" && (role_json === "sales" || role_json === "supervisor") && (
        <MenulistIcon
          icon="Book"
          label="สรุปLead"
          link="/leads_summary"
          active={!!window.location.pathname.match(/^\/leads_summary$/g)}
        />
      )}
      {process.env.APP_NAME !== "mti" && (role_json === "sales" || role_json === "supervisor") && (
        <MenulistIcon
          icon="Book"
          label="จัดการ Lead"
          link="/lead"
          active={!!window.location.pathname.match(/^\/lead$/g)}
        />
      )}
      {process.env.APP_NAME !== "mti" && (role_json === "sales" || role_json === "supervisor") && (
        <MenulistIcon
          icon="Book"
          label="จัดการ Renew Lead"
          link="/renew_lead"
          active={!!window.location.pathname.match(/^\/renew_lead$/g)}
        />
      )}
    </ul>
  );
};

export default AgentMenu;
