import React from "react";
import classNames from "classnames";
import "./select.scss";

const SelectField = ({ options, inputRef, error, className = "", placeholder = 'กรุณาเลือก', require = false, ...rest }) => {
  return (
    <>
      <select
        {...rest}
        ref={inputRef}
        className={classNames(!error ? "form-select" : "form-select has-error", className)}
      >
        {!require && <option value="">{placeholder}</option>}
        {options && (
          <>
            {options.map((v, i) => (
              <option key={i} value={v.value}>
                {v.label}
              </option>
            ))}
          </>
        )}
      </select>
      {error ? <p className="form-input-error">{error}</p> : ""}
    </>
  );
};

export default SelectField;
