import React from "react";

const ButtonToggleDropdown = ({ buttonRef, label, active, ...restProps }) => {
  return (
    <button
      ref={buttonRef}
      className={`select-component-button ${active ? "active" : ""}`}
      {...restProps}
    >
      {label}
      <span className="icon material-icons">arrow_drop_down</span>
    </button>
  );
};

export default ButtonToggleDropdown;
