import React, { useRef } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  MoreVert,
  GetApp,
  CloudUpload,
  Refresh,
  Done,
  AssignmentTurnedIn,
  Publish,
} from "@mui/icons-material";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import PolicyTrackingModal from "~src/components/modal/PolicyTrackingModal";

import dataYml from "~/src/data.yaml";
import { getTotalAmountDetails } from "~src/helpers/quotationHelper";

import Tracking from "~src/components/Badge/Tracking";
import IconStatus from "~src/components/iconStatus";
import PolicyStatus from "~src/components/badges/policy/PolicyStatus";
import { attachmentStatusOptions } from "~src/helpers/options";

import useSecureLink from "../../../../../hooks/useSecureLink";

import { checkEmpty, getInsuredByCategory } from "~src/helpers/helpers";
import { parseDateEN } from "~src/helpers/dateTime";
import { renderYaml } from "~src/helpers/renderYaml";
import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "~src/helpers/formatNumber";
import TotalAmount from "../TotalAmount";
import { Policy } from "~src/types/policy";
import EarningCommission from "../EarningCommission";

const ProductTypeIcon = ({ type }) => {
  switch (type) {
    case "motor":
      return <i className="icon icon-3-Motor" />;
    case "motorbike":
      return <i className="icon icon-3-motorbike" />;
    case "travel":
      return <i className="icon icon-3-travel" />;
    case "fire":
      return <i className="icon icon-3-fire" />;
    case "marine":
      return <i className="icon icon-3-marine" />;
    case "home":
      return <i className="icon icon-3-House" />;
    case "sme":
      return <i className="icon icon-3-SME" />;
    case "iar":
      return <i className="icon icon-3-IAR" />;
    case "golfer":
      return <i className="icon icon-3-golf" />;
    case "engineer":
      return <i className="icon icon-3-engineer" />;
    case "personalaccident":
      return <i className="icon icon-3-PA" />;
    case "health":
      return <i className="icon icon-3-health" />;
    case "cancer":
      return <i className="icon icon-3-cancer" />;
    case "miscellaneous":
      return <i className="icon icon-3-misc" />;
    default:
      return <i className="icon gettmore-icon-policylist" />;
  }
};

interface PolicyTableProps {
  isLoading: boolean;
  policies: Policy[];
  className?: string;
  onDownloadExcel?: any;
  onUploadExcel?: any;
  onUploadPolicy?: any;
  onConfirmPolicy?: any;
  onApproveSendPolicy?: any;
  onResendPolicy?: any;
  onOpenPolicy?: any;
  onCheckStatusPolicy?: any;
}

const PolicyTable: React.FC<PolicyTableProps> = ({
  isLoading,
  policies,
  className = "",
  onDownloadExcel,
  onUploadExcel,
  onUploadPolicy,
  onConfirmPolicy,
  onApproveSendPolicy,
  onResendPolicy,
  onOpenPolicy,
  onCheckStatusPolicy,
}) => {
  const currentClickPolicyId = useRef<string | undefined>(undefined);
  const currentClickOrderNumber = useRef<string | undefined>(undefined);
  const trackingCount = useRef<number>(0);
  const [isOpenTracking, setIsOpenTracking] = React.useState<boolean>(false);
  const [listMenuElement, setListMenuElement] = React.useState(null);
  const [policyMenuElement, setPolicyMenuElement] = React.useState({});
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { secureDownloads } = useSecureLink();

  const handleOpenListMenu = (e) => {
    setListMenuElement(e.currentTarget);
  };

  const handleCloseListMenu = () => {
    setListMenuElement(null);
  };

  const handleSelectListMenu = (callback) => {
    setListMenuElement(null);
    callback();
  };

  const handleOpenPolicyMenu = (e) => {
    const menuId = e.currentTarget.id;
    setPolicyMenuElement({
      ...policyMenuElement,
      [menuId]: e.currentTarget,
    });
  };

  const handleClosePolicyMenu = () => {
    setPolicyMenuElement({});
  };

  const handleSelectPolicyMenu = (callback, policy) => {
    setPolicyMenuElement({});
    callback(policy);
  };

  const getPolicyRow = (policy: Policy, index) => {
    return (
      <tr className="mb-1 align-top transition duration-300 bg-white hover:bg-pink-100">
        <td className="px-1 py-2 font-normal">
          <ProductTypeIcon type={policy.product_type} /> {` `}
          <a
            href={`/staffs/policies/${policy.order_id}/${policy.policy_type}/details`}
            className="order-detail-link"
          >
            {`${policy.order_number}`}
          </a>
        </td>
        <td className="px-1 py-2 font-normal">{policy.agent_number}</td>
        <td className="px-1 py-2 font-normal">
          <ProductTypeIcon type={policy.product_type} /> {` `}
          {checkEmpty(policy.policy_no) !== "-" ? (
            <>
              {/* {policy.policy_type === "cmi" ? (
                <>
                  <span className="inline-block mr-1 cmi-label">พ.ร.บ.</span>
                  {policy.policy_no}
                </>
              ) : (
                <b style={{ textDecoration: "underline" }}>{policy.policy_no}</b>
              )} */}
              {policy.policy_no}
            </>
          ) : (
            "-"
          )}
        </td>
        <td className="px-1 py-2 font-normal">
          {getInsuredByCategory(
            {
              title: policy.insured_title,
              firstname: policy.insured_firstname,
              lastname: policy.insured_lastname,
              company_name: policy.company_name,
            },
            policy.insured_category
          )}
          <br />
          {policy.order_type !== "custom" ? (
            <>
              {policy.quotation.criteria?.car_brand !== undefined ? (
                <>
                  {`${policy.quotation.criteria?.car_brand} ${policy.quotation.criteria?.car_model}`}
                </>
              ) : (
                "-"
              )}
              <br />
              {policy.product_type === "motor" || policy.product_type === "motorbike"
                ? `ทะเบียน ${policy.plate_number}`
                : ""}
            </>
          ) : null}
        </td>
        <td className="px-1 py-2 font-normal">{dataYml.order.contract[policy.contract]}</td>
        <td className="px-1 py-2 font-normal">
          <img className="rounded w-7.5r" src={policy.insurer_icon} />
        </td>
        <td className="px-1 py-2 font-normal text-center">
          {renderYaml(dataYml.product_type[policy.product_type][policy.policy_type])}
        </td>
        <td className="px-1 py-2 font-normal">
          เริ่ม: {parseDateEN(policy.coverage_from)}
          <br />
          ถึง: {parseDateEN(policy.coverage_to)}
        </td>
        <td className="px-1 py-2 font-normal">{formatNumberWithCurrency2(policy.sum_insured)}</td>
        <td className="px-1 py-2 font-normal font-semibold">
          <TotalAmount
            totalAmount={policy.total_amount}
            insureds={policy.quotation.criteria?.traveller || 1}
          />
        </td>
        <td className="px-1 py-2 font-normal">
          <EarningCommission
            totalAmount={policy.earning_commission}
            insureds={policy.quotation.criteria?.traveller || 1}
          />
        </td>
        <td className="px-1 py-2 font-normal text-center">
          {parseDateEN(policy.order_created_at)}
        </td>
        <td className="px-1 py-2 font-normal text-center">
          {checkEmpty(
            attachmentStatusOptions.find((o) => o.value == policy.attachment_status)?.label
          )}
        </td>
        {process.env.APP_NAME === "mti" && (
          <td className="px-1 py-2 font-normal text-center">{policy.nos_number ?? "-"}</td>
        )}
        <td className="px-1 py-2 font-normal text-center">
          <PolicyStatus status={policy.status} modalBodyText={policy.message} />
          {t("trackingPolicy") && policy.status === "success" && (
            <Tracking
              policyId={policy.id}
              onClickCallback={() => {
                trackingCount.current += 1;
                currentClickOrderNumber.current = policy.order_number;
                currentClickPolicyId.current = policy.id;
                setIsOpenTracking(true);
              }}
            />
          )}
        </td>
        <td className="px-1 py-2 font-normal align-middle">
          <IconStatus status={policy.status !== "failed"} />
        </td>
        <td className="px-1 py-2 font-normal">
          <IconButton
            id={index}
            component="span"
            ria-haspopup="true"
            onClick={handleOpenPolicyMenu}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id={index}
            anchorEl={policyMenuElement[index]}
            keepMounted
            open={Boolean(policyMenuElement[index])}
            onClose={handleClosePolicyMenu}
          >
            <MenuItem
              onClick={() => {
                handleSelectPolicyMenu(onUploadPolicy, policy);
              }}
            >
              <CloudUpload className="mr-1" />
              อัพโหลดกรมธรรม์
            </MenuItem>
            <MenuItem
              className="hover:bg-none"
              onClick={() => handleSelectPolicyMenu(onCheckStatusPolicy, policy)}
            >
              <AssignmentTurnedIn className="mr-1" />
              ตรวจสอบสถานะ
            </MenuItem>
            {setButton(policy.status, policy.id, policy)}
          </Menu>
        </td>
      </tr>
    );
  };

  const setButton = (status, id, policy) => {
    switch (status) {
      case "failed":
      case "issuing":
        return [
          <MenuItem
            className="hover:bg-none"
            onClick={() => handleSelectPolicyMenu(onResendPolicy, policy)}
          >
            <Refresh className="mr-1" />
            ส่งใหม่
          </MenuItem>,
        ];
      case "waiting_approval":
        return (
          <MenuItem
            className="hover:bg-none"
            onClick={() => handleSelectPolicyMenu(onApproveSendPolicy, policy)}
          >
            <Done className="mr-1" />
            ยืนยันออกงาน
          </MenuItem>
        );
      case "waiting_inspection":
        return (
          <MenuItem
            className="hover:bg-none"
            onClick={() => handleSelectPolicyMenu(onConfirmPolicy, policy)}
          >
            <Done className="mr-1" />
            ตรวจผ่าน
          </MenuItem>
        );
      case "success":
        return (
          <MenuItem
            className="hover:bg-none"
            // onClick={() => handleSelectPolicyMenu(onOpenPolicy, policy)}
            onClick={() => secureDownloads([policy.policy_url])}
          >
            <i className="mr-1 material-icons slip-icon">assignment</i>
            เอกสารกรมธรรม์
          </MenuItem>
        );
      default:
        return null;
    }
  };

  return (
    <table className={`table-auto text-left ${className} min-w-367.5r`}>
      <PolicyTrackingModal
        queryKey={`tracking-${trackingCount.current.toString()}`}
        open={isOpenTracking}
        onClose={() => setIsOpenTracking(false)}
        addToast={addToast}
        orderNumber={currentClickOrderNumber.current}
        policyId={currentClickPolicyId.current}
      />
      {/* {loadingScreen && <>} */}
      <thead className="text-1xs text-custom-gray-md">
        <tr>
          <th className="px-1 py-3 font-normal min-w-37.5r">เลขที่คำสั่งซื้อ</th>
          <th className="px-1 py-3 font-normal min-w-20r">รหัสตัวแทน</th>
          <th className="px-1 py-3 font-normal min-w-45r">เลขที่กรมธรรม์</th>
          <th className="px-1 py-3 font-normal min-w-45r">ข้อมูลลูกค้า</th>
          <th className="px-1 py-3 font-normal min-w-17.5r">ประเภทงาน</th>
          <th className="px-1 py-3 font-normal min-w-10r">บริษัท</th>
          <th className="px-1 py-3 font-normal text-center min-w-25r">แผน</th>
          <th className="px-1 py-3 font-normal min-w-25r">วันที่คุ้มครอง</th>
          <th className="px-1 py-3 font-normal text-center min-w-25r">ทุนประกัน</th>
          <th className="px-1 py-3 font-normal text-center min-w-20r">เบี้ยที่ต้องชำระ</th>
          <th className="px-1 py-3 font-normal text-center min-w-20r">ค่าตอบแทนสุทธิ</th>
          <th className="px-1 py-3 font-normal text-center min-w-20r">วันที่ทำรายการ</th>
          <th className="px-1 py-3 font-normal text-center min-w-20r">รายการเอกสาร</th>
          {process.env.APP_NAME === "mti" && (
            <th className="px-1 py-3 font-normal text-center min-w-20r">เลขรับแจ้ง (NOS)</th>
          )}
          <th className="px-1 py-3 font-normal min-w-37.5r text-center">สถานะรายการ</th>
          <th className="px-1 py-3 font-normal min-w-7.5r text-center" />
          <th className="font-normal min-w-7.5r text-center">
            <IconButton component="span" aria-haspopup="true" onClick={handleOpenListMenu}>
              <MoreVert />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={listMenuElement}
              keepMounted
              open={Boolean(listMenuElement)}
              onClose={handleCloseListMenu}
            >
              <MenuItem onClick={() => handleSelectListMenu(onDownloadExcel)}>
                <GetApp className="mr-1" />
                ดาวน์โหลด Excel
              </MenuItem>
              {onUploadExcel && (
                <MenuItem onClick={() => handleSelectListMenu(onUploadExcel)}>
                  <Publish className="mr-1" />
                  อัพโหลด Excel
                </MenuItem>
              )}
            </Menu>
          </th>
        </tr>
      </thead>
      <tbody className="text-xs divide-y-4 divide-custom-gray-sm">
        {isLoading ? (
          <tr className="mb-1 bg-white">
            <td colSpan={15} className="px-1 py-2 text-center">
              กำลังโหลด...
            </td>
          </tr>
        ) : (
          <>
            {policies.length == 0 ? (
              <tr className="mb-1 bg-white">
                <td colSpan={15} className="px-1 py-2 text-center">
                  ไม่พบข้อมูล
                </td>
              </tr>
            ) : (
              <>
                {policies.map((policy, index) => (
                  <>{getPolicyRow(policy, index)}</>
                ))}
              </>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};

export default PolicyTable;
