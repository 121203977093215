import React from "react";

interface Props {
  htmlFor: string;
  required?: boolean;
  children: JSX.Element | React.ReactNode;
}

const Label: React.FC<Props> = ({ htmlFor, children, required }) => {
  return (
    <label htmlFor={htmlFor} className="inline-block w-full mb-1 text-gray-600">
      {children} {required && <span className="text-red-500">*</span>}
    </label>
  );
};

export default Label;
