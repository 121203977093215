import cn from "classnames";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDeepCompareMemoize } from "use-deep-compare-effect";

import Preloading from "../../../loading/Preloading";

import yaml from "~/src/data.yaml";
import AgentOrdersAPIClient from "~src/api/agent/Orders";
import { reformatDateEN } from "~src/helpers/date";
import { formatNumberWithCurrency, formatPhone } from "~src/helpers/formatNumber";
import { useAuthenticate } from "~src/helpers/authen";

interface QuotationTableProps {
  data?: any[];
  isLoading?: boolean;
  ordersAPI?: AgentOrdersAPIClient;
}

export const QuotationTable: React.FC<QuotationTableProps> = ({
  data = [],
  isLoading = false,
  ordersAPI,
}) => {
  const history = useHistory();
  const { showPaidCommission } = useAuthenticate();

  const [focusIndex, setFocusIndex] = React.useState<number>();
  const [preloading, setPreloading] = React.useState<boolean>(false);
  const toggleExpand = (index: number) => setFocusIndex(index === focusIndex ? null : index);
  const totalPrice = (quote) =>
    parseFloat(quote.selling_price) -
    parseFloat(quote.discount) +
    parseFloat(quote.addon?.selling_price ?? 0);

  const handlePlaceOrder = async (quotationID: number, quoteID: number): Promise<void> => {
    if (!ordersAPI) {
      return;
    }

    setPreloading(true);
    // const data = {
    //   quotation_id: quotationId,
    //   quote_ids: quoteId,
    // };
    try {
      const respData = await ordersAPI.placeOrder(quotationID, quoteID);
      // const resp = await axios.post("/api/quotations/place_order", JSON.stringify(data));
      setPreloading(false);
      const orderID = respData.content?.order_id;
      history.push(ordersAPI.getCheckoutURL(orderID));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex flex-col h-full overflow-hidden text-xs">
      {preloading && <Preloading />}
      <div className="flex text-xs text-center text-gray-600 bg-white border divide-x">
        <div className="w-1/12 m-auto" />
        <div className="w-3/12 m-auto">เลขที่ใบเสนอราคา</div>
        <div className="w-2/12 m-auto">จำนวนแผนที่เสนอ</div>
        <div className="w-3/12 m-auto">ข้อมูลลูกค้า</div>
        <div className="w-3/12 m-auto">รายละเอียดเพิ่มเติม</div>
      </div>
      <div className="flex-1 overflow-y-scroll divide-y">
        {isLoading
          ? Array.from({ length: 2 }).map((_, idx) => (
              <div className="flex py-2" key={idx}>
                <div className="w-1/12 p-1 pl-2" />
                <div className="w-3/12 p-1">
                  <div className="w-8/12 h-4 mt-2 bg-gray-400 rounded animate-pulse" />
                  <div className="w-8/12 h-2 mt-2 bg-gray-400 rounded animate-pulse" />
                </div>
                <div className="w-2/12 p-1">
                  <div className="w-3/12 h-2 mx-auto mt-2 bg-gray-400 rounded animate-pulse" />
                </div>
                <div className="w-3/12 p-1">
                  <div className="w-7/12 h-2 mt-2 bg-gray-400 rounded animate-pulse" />
                  <div className="w-7/12 h-2 mt-2 bg-gray-400 rounded animate-pulse" />
                </div>
                <div className="w-3/12 p-1 pr-2">
                  <div className="w-7/12 h-2 mt-2 bg-gray-400 rounded animate-pulse" />
                </div>
              </div>
            ))
          : (data ?? []).map((quotation, idx) => (
              <div key={idx} className="bg-white border border-white">
                <div className="flex my-2">
                  <div className="w-1/12 p-1 pl-2">
                    <button
                      className={cn(
                        "w-16 h-16 rounded-full focus:outline-none flex items-center justify-center",
                        "transition-transform transform duration-200",
                        focusIndex === idx
                          ? "bg-secondary-color text-white rotate-180"
                          : "bg-white text-secondary-color border border-secondary-color"
                      )}
                      onClick={() => toggleExpand(idx)}
                    >
                      <i className="font-bold material-icons">keyboard_arrow_down</i>
                    </button>
                  </div>
                  <div className="w-3/12 p-1">
                    <div className="font-bold align-middle">
                      <i className={`mr-2 icon-${quotation.product_type}`} />
                      {quotation.number}
                    </div>
                    <div className="text-xs font-light text-gray-600">
                      วันหมดอายุ:
                      {reformatDateEN(quotation.created_date, "dd/MM/yyyy", "yyyy/MM/dd")}
                    </div>
                  </div>
                  <div className="w-2/12 p-1 text-center">{quotation.quotes?.length ?? 1} แผน</div>
                  <div className="w-3/12 p-1">
                    <div>{quotation.customer_name}</div>
                    <div>
                      <a href={`tel:${quotation.customer_phone}`} className="link-tel">
                        <span className="phone">
                          <i className="mr-2 hero-icon-phone" />
                          {formatPhone(quotation.customer_phone ?? "")}
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="w-3/12 p-1 pr-2">
                    {`${quotation.criteria?.car_brand ?? "-"} ${
                      quotation.criteria?.car_model ?? ""
                    }`}
                  </div>
                </div>
                <div className={cn("overflow-hidden", focusIndex === idx ? "h-auto" : "h-0")}>
                  <div className="flex text-center text-gray-600 bg-white border divide-x">
                    <div className="w-1/12">บริษัท</div>
                    {showPaidCommission ? (
                      <>
                        <div className="w-2/12">แผน</div>
                        <div className="w-2/12">พ.ร.บ.</div>
                        <div className="w-2/12">เบี้ยทั้งหมด</div>
                        <div className="w-2/12">
                          {showPaidCommission && "ส่วนลด"}
                        </div>
                        <div className="w-2/12">เบี้ยประกันที่ต้องชำระ</div>
                        <div className="w-2/12">
                          {showPaidCommission && "ค่าตอบแทน"}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-3/12">แผน</div>
                        <div className="w-3/12">พ.ร.บ.</div>
                        <div className="w-3/12">เบี้ยทั้งหมด</div>
                        <div className="w-3/12">เบี้ยประกันที่ต้องชำระ</div>
                      </>
                    )}
                    <div className="w-1/12" />
                  </div>
                  <div className="space-y-1">
                    {quotation.quotes?.map((quote, idx) => (
                      <div key={idx} className="flex py-1 text-center bg-gray-100">
                        <div className="w-1/12 m-auto">
                          <img
                            style={{ width: 30, height: 30, margin: "auto" }}
                            src={quote.insurer_icon}
                          />
                        </div>
                        {showPaidCommission ? (
                          <>
                            <div className="w-2/12 m-auto">
                              {`${yaml.policy_type[quote.policy_type]}`}
                              <br />
                              {`${yaml.repair_facility[quote.repair_facility]}`}
                            </div>
                            <div className="w-2/12 m-auto">
                              {formatNumberWithCurrency(quote.addon?.selling_price)}
                            </div>
                            <div className="w-2/12 m-auto">
                              {formatNumberWithCurrency(quote.selling_price)}
                            </div>
                            <div className="w-2/12 m-auto">
                              {showPaidCommission && formatNumberWithCurrency(quote.discount)}
                            </div>
                            <div className="w-2/12 m-auto text-primary-color">
                              {formatNumberWithCurrency(totalPrice(quote))}
                            </div>
                            <div className="w-2/12 m-auto font-bold">
                              {showPaidCommission && formatNumberWithCurrency(quote.commission_total)}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="w-3/12 m-auto">
                              {`${yaml.policy_type[quote.policy_type]}`}
                              <br />
                              {`${yaml.repair_facility[quote.repair_facility]}`}
                            </div>
                            <div className="w-3/12 m-auto">
                              {formatNumberWithCurrency(quote.addon?.selling_price)}
                            </div>
                            <div className="w-3/12 m-auto">
                              {formatNumberWithCurrency(quote.selling_price)}
                            </div>
                            <div className="w-3/12 m-auto text-primary-color">
                              {formatNumberWithCurrency(totalPrice(quote))}
                            </div>
                          </>
                        )}
                        <div className="w-1/12 m-auto">
                          <a
                            className="px-2 py-1 text-xs text-white rounded-full shadow-lg bg-primary-color focus:outline-none"
                            role="button"
                            onClick={() => handlePlaceOrder(quotation.id, quote.id)}
                            aria-disabled={!ordersAPI}
                          >
                            ซื้อ
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};
