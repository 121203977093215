import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import InsurancePlanSummary2 from "/src/components/insurancePlanSummary/insurancePlanSummary2";

const DummyPayments = (props) => {
  const {
    orderId,
    summary,
    insured_category,
  } = props.location.state;

  useEffect(() => {
    // To reload the page once only at the first render
    // It is due to the KBank cc payment button script tag loading.
    if (window.localStorage) {
      if (!localStorage.getItem("payment-firstLoad")) {
        localStorage["payment-firstLoad"] = true;
        window.location.reload();
      } else localStorage.removeItem("payment-firstLoad");
    }
  }, []);

  useEffect(() => {
    loadKBankBtn()
  }, [])

  const loadKBankBtn = () => {
    console.log(' + + + ')

  //const ccPromise = new Promise((resolve, reject) => {
    const kbankCcForm = document.getElementById("kbankCCForm");
    const ccScriptTag = document.createElement("script");
    ccScriptTag.type = 'text/javascript'
    ccScriptTag.src = process.env.KBANK_API_JAVASCRIPT_URL;
    ccScriptTag.dataset.apikey = process.env.KBANK_API_PKEY;
    ccScriptTag.dataset.amount = 5;
    ccScriptTag.dataset.currency = "THB";
    ccScriptTag.dataset.paymentMethods = "card";
    ccScriptTag.dataset.orderId = summary.orderNumber;
    ccScriptTag.dataset.name = "Gettgo HERO";
    ccScriptTag.dataset.mid = process.env.KBANK_MID;
    ccScriptTag.async = true;
    kbankCcForm.action = `${process.env.API_URL}/orders/${orderId}/payments/kbank/cc`;

    kbankCcForm.appendChild(ccScriptTag);
    //event.target.appendChild(ccScriptTag)
    console.log('append script tag')

    //ccPromise.then(() => {
    //  console.log('resolve promise!!')
    //})
  }

  const testDomAppend = (event) => {
    console.log(event.target)
    event.target.insertAdjacentHTML('beforebegin', '<p>Hello</p>')
  }
   
  console.log('render the DOM~')
  return (
    <div>
      <p>{ orderId }</p>
      <p>{ summary.paymentTotalAmountIncludedCCFee }</p>
      <div
        id="kbankCCWrapper"
        className="pt-6 text-center"
      ></div>

      <div>
        <button onClick={loadKBankBtn}>Load KBank button</button>
      </div>

      <div>
        <button onClick={testDomAppend}>Test DOM append</button>
      </div>

      <div id="kbankBackyard" className="mt-12">
        <form
          id="kbankCCForm"
          method="POST"
          action=""
          className=""
        ></form>
      </div>
    </div>
  ) 
}

export default DummyPayments
