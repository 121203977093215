import axios from "/src/helpers/configAxios";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { ToastProvider, useToasts } from "react-toast-notifications";
import { ButtonRadius } from "/src/components/form";

import "./quotationModal.scss";
import useSecureLink from "~src/hooks/useSecureLink";
import Preloading from "~src/components/loading/Preloading";

const QuotationModal = (props) => {
  const { multipleProduct, handleClose, quotation_no, quotationId, quoteIds } = props;
  const { secureDownloads, secureLinkLoading } = useSecureLink();

  const [successPlaceOrder, setSuccessPlaceOrder] = useState(false);
  const [orderId, setOrderId] = useState("");
  const quotation = { id: quotationId, number: quotation_no };

  const { addToast } = useToasts();

  function placeOrder() {
    const url = `${process.env.API_URL}/api/quotations/place_order`;
    const data = {
      quotation_id: quotationId,
      quote_ids: quoteIds,
    };
    axios.post(url, JSON.stringify(data)).then((response) => {
      setOrderId(response.data.content.order_id);
      setSuccessPlaceOrder(true);
    });
  }

  function setAlert(text) {
    addToast(
      <div>
        <h4>{text}</h4>
      </div>,
      {
        appearance: "success",
        autoDismiss: true,
      }
    );
  }

  if (successPlaceOrder) {
    return <Redirect to={`/checkout/${orderId}/step-1`} />;
  }
  return (
    <>
      {secureLinkLoading && <Preloading />}
      <div className="quotationModal">
        <div
          className="quotationModal-overlay"
          onClick={() => (multipleProduct ? (window.location.href = "/quotations") : handleClose)}
        />
        <div className="quotationModal-box">
          <i className="material-icons checkSign">check_circle_outline</i>
          <div className="title">สร้างใบเสนอราคา {quotation_no} สำเร็จ!</div>
          <div className="subTitle">
            สามารถเข้าดูได้ที่เมนู <a href="/quotations">ใบเสนอราคา</a> ของคุณ
          </div>
          <hr />
          <div className="subTitle">แชร์ หรือดาวน์โหลดใบเสนอราคาเพื่อส่งให้ลูกค้า</div>

          <div className="quotationModal-download">
            <button
              type="button"
              className="btn-download"
              onClick={(e) =>
                secureDownloads([`/api/quotations/${quotation.id}/attachment?type=document`])
              }
            >
              <i className="material-icons">get_app</i> DOWNLOAD
            </button>

            <div className="flex justify-end">
              <input
                type="text"
                name=""
                defaultValue={`${process.env.API_URL}/api/quotations/${quotation.id}/download`}
                disabled
              />
              <CopyToClipboard
                text={`${process.env.API_URL}/api/quotations/${quotation.id}/download`}
              >
                <button
                  className="button-copy-url"
                  type="button"
                  onClick={() => setAlert("คัดลอกลิ้งค์แล้ว!")}
                >
                  คัดลอกลิ้งค์
                </button>
              </CopyToClipboard>
            </div>
          </div>
          {!multipleProduct ? (
            <div>
              <div className="quotationModal-separator">
                <hr />
                <div>หรือ</div>
                <hr />
              </div>
              <div className="subTitle">คุณสามารถเปิดใบงานสำหรับซื้อแผนประกันนี้ได้เลย</div>
              <div className="bt-space">
                <ButtonRadius type="button" color="primary" onClick={placeOrder}>
                  <i className="material-icons shopping-cart">shopping_cart</i>
                  ยืนยันสั่งซื้อ และชำระเงิน
                </ButtonRadius>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default QuotationModal;
