import React from "react";
import { formatNumberWithCurrency } from "~src/helpers/formatNumber";

interface InstallmentCardProps {
  paymentCount: number;
  isActive: boolean;
  cardIndex: number;
  paymentAmount: string;
  minPaymentAmount: string;
  activeCardIndex: number;
  setActiveCardIndex: (cardIndex: number) => void;
}

const InstallmentCard: React.FC<InstallmentCardProps> = ({
  paymentCount,
  isActive,
  paymentAmount,
  cardIndex,
  minPaymentAmount,
  activeCardIndex,
  setActiveCardIndex,
}) => {
  return (
    <div className="flex flex-col">
      <div
        className={`flex-col mx-2 pt-2 rounded-lg overflow-hidden
          shadow-lg border-2 cursor-pointer
          ${isActive ? "bg-white hover:bg-opacity-25" : "bg-gray-300"}
          ${activeCardIndex === cardIndex ? "border-secondary-color" : ""}`}
        onClick={() => isActive && setActiveCardIndex(cardIndex)}
      >
        <div className="text-center my-auto text-lg font-bold">{`${paymentCount} เดือน`}</div>

        <div className="text-white px-1 pb-1 w-full text-center bg-secondary-color">
          {`เดือนละ ${formatNumberWithCurrency(paymentAmount, "")} บาท`}
        </div>
      </div>

      {!isActive && (
        <div className="flex-col text-xs text-red-600 text-right mx-2">
          {`ขั้นต่ำ ${formatNumberWithCurrency(minPaymentAmount, "")} บาท`}
        </div>
      )}
    </div>
  );
};

export default InstallmentCard;
