import React, { useState } from "react";
import _ from "lodash";
import useInput from "/src/hooks/useInput";
import {
  FormGroup,
  TextField,
  Switcher,
  SelectField,
  DatePickerField,
} from "/src/components/staff/form";
import LocationSearch from "/src/components/form/locationSearch";
import AutoComplete from "/src/components/form/autoCompleteAddress";
import { titleRenewTHOptions, beneficiaryOptions } from "/src/helpers/options";
import { dateFormat } from "/src/helpers/dateTime";
import { messages } from "/src/helpers/messages";
import locationJson from "/src/assets/database.json";

const InsuredForm = (props) => {
  const { data } = props;
  const [insuredCategory, setInsuredCategory] = useState("person");
  const [results, setResults] = useState([]);
  const [isShowAutoComplele, setIsShowAutoComplele] = useState({
    sub_district: false,
    district: false,
    province: false,
    zipcode: false,
  });
  const [insuredLocation, setInsuredLocation] = useState({
    addresss: "",
    sub_district: "",
    district: "",
    province: "",
    zipcode: "",
  });

  const { bind: changeInsuredCategory } = useInput("");
  const { bind: changeCompanyName } = useInput(data.company_name || "");
  const { bind: changeCompanyTax } = useInput(data.company_tax || "");

  const { bind: changeInsuredTitle } = useInput(data.title || "");
  const { bind: changeInsuredFistname } = useInput(data.firstname || "");
  const { bind: changeInsuredLastname } = useInput(data.lastname || "");
  const { bind: changeInsuredBirthday } = useInput(dateFormat(new Date()));
  const { bind: changeInsuredIDcard } = useInput(data.id_card || "");
  const { bind: changeInsuredPhone } = useInput(data.phone_no || "");
  const { bind: changeAInsuredAddress } = useInput(data.address || "");

  const handleChangeInsuredCategory = (evt) => {
    const { value, name } = evt.target;
    setInsuredCategory(value);
  };

  const handleChangeAutoComplete = (e, key) => {
    const { value } = e.target;
    const { result, show } = getAutoCompleteResult(value, key);
    setResults(result);
    setInsuredLocation({
      ...insuredLocation,
      [key]: value,
    });
    setIsShowAutoComplele({
      ...isShowAutoComplele,
      [key]: show,
    });
  };

  const cbOutsideClick = (status) => {
    setIsShowAutoComplele({
      sub_district: false,
      district: false,
      province: false,
      zipcode: false,
    });
  };

  const cbData = (data) => {
    const { sub_district, district, province, zipcode } = data;
    setInsuredLocation({
      sub_district,
      district,
      province,
      zipcode,
    });

    setIsShowAutoComplele({
      sub_district: false,
      district: false,
      province: false,
      zipcode: false,
    });
  };

  function getAutoCompleteResult(value, include) {
    let result = [];
    let show = false;
    if (value != "") {
      result = searchByText(locationJson, value, [include]);
      show = true;
    } else {
      result = [];
      show = false;
    }

    return { result, show };
  }

  function searchByText(collection, text, include) {
    text = _.toLower(text);
    return _.filter(collection, function (object) {
      return _(object)
        .pick(include)
        .some(function (string) {
          return _(string).toLower().includes(text);
        });
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <FormGroup label="ประเภทผู้เอาประกัน" require>
            <Switcher
              active={insuredCategory}
              onChange={handleChangeInsuredCategory}
              labels={[
                { label: "บุคคลธรรมดา", value: "person" },
                { label: "นิติบุคคล", value: "legal_entity" },
              ]}
            />
            <input
              type="hidden"
              name="renew[insured_category]"
              value={insuredCategory}
              ref={props.register(messages.required)}
            />
          </FormGroup>
        </div>
      </div>
      {insuredCategory == "legal_entity" ? (
        <div className="row">
          <div className="col-md-6">
            <FormGroup
              label="ชื่อบริษัทหรือองค์กร"
              htmlForLabel="renew[insured][company_name]"
              require
            >
              <TextField
                id="renew[insured][company_name]"
                name="renew[insured][company_name]"
                inputRef={props.register(messages.required)}
                error={props.errors?.renew?.insured?.company_name?.message}
                {...changeCompanyName}
              />
            </FormGroup>
          </div>
          <div className="col-md-6">
            <FormGroup
              label="เลขประจำตัวผู้เสียภาษีของบริษัท/องค์กร"
              htmlForLabel="renew[insured][company_tax]"
              require
            >
              <TextField
                id="renew[insured][company_tax]"
                name="renew[insured][company_tax]"
                inputRef={props.register(messages.required)}
                error={props.errors?.renew?.insured?.company_tax?.message}
                {...changeCompanyTax}
              />
            </FormGroup>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-md-2">
          <FormGroup label="คำนำหน้าชื่อ" htmlForLabel="renew[insured][idcard]" require>
            <SelectField
              id="renew[insured][title]"
              name="renew[insured][title]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.insured?.title?.message}
              options={titleRenewTHOptions}
              {...changeInsuredTitle}
            />
          </FormGroup>
        </div>
        <div className="col-md-5">
          <FormGroup label="ชื่อจริง" htmlForLabel="renew[insured][idcard]" require>
            <TextField
              id="renew[insured][firstname]"
              name="renew[insured][firstname]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.insured?.firstname?.message}
              {...changeInsuredFistname}
            />
          </FormGroup>
        </div>
        <div className="col-md-5">
          <FormGroup label="นามสกุล" htmlForLabel="renew[insured][idcard]" require>
            <TextField
              id="renew[insured][lastname]"
              name="renew[insured][lastname]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.insured?.lastname?.message}
              {...changeInsuredLastname}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormGroup label="วัน เดือน ปี เกิด" htmlForLabel="renew[insured][idcard]">
            <DatePickerField
              id="renew[insured][birthday]"
              name="renew[insured][birthday]"
              inputRef={props.register()}
              error={props.errors.renew?.insured?.birthday?.message}
              readOnly
              {...changeInsuredBirthday}
            />
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup label="เลขประจำตัวบัตรประชาชน" htmlForLabel="renew[insured][idcard]">
            <TextField
              id="renew[insured][idcard]"
              name="renew[insured][idcard]"
              inputRef={props.register()}
              error={props.errors.renew?.insured?.idcard?.message}
              {...changeInsuredIDcard}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormGroup label="เบอร์มือถือ" htmlForLabel="renew[insured][phone_number]" require>
            <TextField
              id="renew[insured][phone_number]"
              name="renew[insured][phone_number]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.insured?.phone_number?.message}
              {...changeInsuredPhone}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormGroup label="ที่อยู่ / บริษัท" htmlForLabel="renew[insured][address]" require>
            <TextField
              id="renew[insured][address]"
              name="renew[insured][address]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.insured?.address?.message}
              {...changeAInsuredAddress}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <FormGroup label="ตำบล" htmlForLabel="renew[insured][sub_district]" require>
            <AutoComplete
              data={results}
              name="renew[insured][sub_district]"
              inputRef={props.register(messages.required)}
              show={isShowAutoComplele.sub_district}
              cbOutsideClick={cbOutsideClick}
              cbData={cbData}
              value={insuredLocation.sub_district}
              onChange={(e) => handleChangeAutoComplete(e, "sub_district")}
              error={props.errors.renew?.insured?.sub_district?.message}
            />
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup label="อำเภอ" htmlForLabel="renew[insured][district]" require>
            <AutoComplete
              data={results}
              name="renew[insured][district]"
              inputRef={props.register(messages.required)}
              show={isShowAutoComplele.district}
              cbOutsideClick={cbOutsideClick}
              cbData={cbData}
              value={insuredLocation.district}
              onChange={(e) => handleChangeAutoComplete(e, "district")}
              error={props.errors.renew?.insured?.district?.message}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormGroup label="จังหวัด" htmlForLabel="renew[insured][province]" require>
            <AutoComplete
              data={results}
              name="renew[insured][province]"
              inputRef={props.register(messages.required)}
              show={isShowAutoComplele.province}
              cbOutsideClick={cbOutsideClick}
              cbData={cbData}
              value={insuredLocation.province}
              onChange={(e) => handleChangeAutoComplete(e, "province")}
              error={props.errors.renew?.insured?.province?.message}
            />
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup label="รหัสไปรษณีย์" htmlForLabel="renew[insured][postcode]" require>
            <AutoComplete
              data={results}
              maxLength={5}
              name="renew[insured][postcode]"
              inputRef={props.register(messages.required)}
              show={isShowAutoComplele.zipcode}
              cbOutsideClick={cbOutsideClick}
              cbData={cbData}
              value={insuredLocation.zipcode}
              onChange={(e) => handleChangeAutoComplete(e, "zipcode")}
              error={props.errors.renew?.insured?.postcode?.message}
            />
          </FormGroup>
        </div>
      </div>
    </>
  );
};

InsuredForm.propTypes = {};

export default InsuredForm;
