import React from "react";
import { useTranslation } from "react-i18next";
import CommonModal from "~src/components/modal/common";
import HeroImage from "~assets/travel/hero-illus-01.png";
import lineLogo from "~/assets/contact/logo-line.png";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const ExceedLimitBuy: React.FC<Props> = ({ isOpen, onClose }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CommonModal open={isOpen} onClose={onClose} persistent={false}>
      <div className="animate-fade-in duration-700 flex flex-col text-center">
        <div className="flex p-10 w-fit">
          <div className="flex flex-col ml-5">
            <p className="text-lg">
              หากต้องการซื้อ <span className="text-primary-color">ประกันเดินทางกลุ่มใหญ่</span>
            </p>
            <p className="text-custom-gray-md mt-4 text-base">
              เดินทาง 10 ท่านขึ้นไป สามารถแบ่งการทำรายการได้สูงสุด 9 ท่านต่อรายการ <br />{" "}
              หรือติดต่อผ่านช่องทาง Line @gettgohero เพื่ออำนวยความสะดวก
            </p>
            {/* <p className="text-custom-gray-md mb-4">เพื่อให้ได้รับส่วนลด/โปรโมชั่น ตามเงื่อนไข</p> */}

            <div className="grid grid-cols-2 text-custom-gray-md justify-items-center">
              <img src={HeroImage} className="w-fit" width={200} height={200} alt="" />
              <div className="flex flex-col justify-center gap-3">
                {/* <div className="flex flex-row text-lg gap-3 items-center">
                  <span className="hero-icon-phone text-icon text-primary" />
                  <p className="break-all text-base"> {t("phoneNumberFormat")}</p>
                </div> */}
                <div className="flex flex-row text-lg gap-3 items-center">
                  <img src={lineLogo} alt="lineLogo" width={30} />
                  <p className="break-all text-base"> {t("lineAccount")}</p>
                </div>
                <div className="flex flex-row text-lg gap-3 items-center">
                  <span className="hero-icon-mail text-icon text-primary " />
                  <p className="break-all text-base"> {t("contactMail")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default ExceedLimitBuy;
