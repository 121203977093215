import { atom, selector, selectorFamily } from "recoil";
import { OrderInfoCheckout } from "~src/type/order";
import { Quote } from "~src/types/quote";
import { Insured } from "~src/types/insured";
import { Quotation, TravelQuotation } from "~src/types/quotation";
import { PaymentSummaryInfo } from "~src/types/payment";

const orderInfoAtom = atom<OrderInfoCheckout>({
  key: "OrderInfoCheckout",
  default: undefined,
});

const quotesInfoAtom = selector<Quote[]>({
  key: "QuoteInfoCheckout",
  get: ({ get }) => {
    return get(orderInfoAtom)?.content?.quotes;
  },
});

const insuredsInfoAtom = selector<Insured[]>({
  key: "InsuredsInfoCheckout",
  get: ({ get }) => {
    return get(orderInfoAtom)?.content.insureds;
  },
});

const insuredsInfoState = atom<Insured[]>({
  key: "insuredsInfoState",
  default: insuredsInfoAtom,
});

const paymentsSummaryInfoAtom = selector({
  key: "PaymentsSummaryCheckout",
  get: ({ get }) => {
    const paymentSummary: PaymentSummaryInfo = get(orderInfoAtom)?.content.payments_summary;
    const lineItemAs = paymentSummary?.line_itemas;

    return { paymentSummary, lineItemAs };
  },
});

const quotationInfoAtom = selector<TravelQuotation>({
  key: "QuotationInfoCheckout",
  get: ({ get }) => {
    return get(orderInfoAtom)?.content.quotation;
  },
});

const travelQuotationInfoAtom = selector<TravelQuotation>({
  key: "TravelQuotationInfoCheckout",
  get: ({ get }) => {
    return get(orderInfoAtom)?.content.quotation;
  },
});

const taxConsentAtom = selector({
  key: "TaxConsentCheckout",
  get: ({ get }) => {
    return get(orderInfoAtom)?.content?.tax_consent;
  },
});

export {
  orderInfoAtom,
  quotesInfoAtom,
  insuredsInfoAtom,
  quotationInfoAtom,
  paymentsSummaryInfoAtom,
  insuredsInfoState,
  taxConsentAtom,
  travelQuotationInfoAtom,
};
