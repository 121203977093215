import { useState, useEffect } from "react";
import "babel-polyfill";
import axios from "/src/helpers/configAxios";

const useFetch = (url, payload = {}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      await axios
        .get(`${process.env.API_URL}${url}`, payload)
        .then((res) => {
          setResponse(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(true);
          setError(error);
        });
    }
    fetchData();
  }, []);

  return { response, error, isLoading };
};

export default useFetch;
