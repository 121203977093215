import React, { useState } from "react";
import Badges from "/src/components/badges";
import InfoModal from "/src/components/modal/info/Info";
import yml from "/src/status.yml";

interface Props {
  status: string;
  modalHeaderText?: string;
  modalBodyText: string;
}

const PolicyStatus: React.FC<Props> = ({ status, modalHeaderText, modalBodyText }) => {
  const [showPolicyInfoModal, setShowPolicyInfoModal] = useState(false);
  const isSuccess = status == "success";

  return (
    <>
      <div
        id="div-policy-status"
        onClick={() => setShowPolicyInfoModal(true)}
        className={`
          w-fit h-fit
          ${!isSuccess ? "cursor-pointer" : ""} 
        `}
      >
        <Badges status={`policy-${status}`}>{yml.status.policy.agent[status]}</Badges>
      </div>

      {showPolicyInfoModal && (
        <InfoModal
          headerText={modalHeaderText}
          bodyText={!!modalBodyText ? modalBodyText : "- ไม่มีข้อมูลการดำเนินการ -"}
          handleClose={() => setShowPolicyInfoModal(false)}
        />
      )}
    </>
  );
};

export default PolicyStatus;
