import React, { useEffect, useRef } from "react";
import Button from "/src/components/form/button/Button";
import ClearIcon from "@mui/icons-material/Clear";
import "./upload.scss";
import SecureLinkImg from "~src/components/secureLinkImg";

export interface UploadImagesProps {
  id: string;
  name: string;
  multiple?: boolean;
  buttonLabel: string;
  buttonSize?: string;
  previewImages: Array<any>;
  errors: any;
  handleChangeUpload: (evt: any) => void;
  handleDeleteFile: (ref, index) => void;
  handleDeleteAttachedFile: (blobId) => void;
  //TODO: Check this with react-hook-form interfaces
  registerValidate: (target: HTMLElement, opts?: any) => any;
}

const UploadImages: React.FC<UploadImagesProps> = ({
  id,
  name,
  errors,
  multiple,
  buttonLabel,
  buttonSize,
  previewImages,
  registerValidate,
  handleChangeUpload,
  handleDeleteFile,
  handleDeleteAttachedFile,
}) => {
  const inputRef = useRef<HTMLInputElement | null>();
  const errorRef = useRef<HTMLInputElement | null>();
  const typeCanOpenImage = ["image/png", "image/jpg", "image/jpeg"];
  const isImagesType = (_type) => typeCanOpenImage.includes(_type.toLowerCase());

  useEffect(() => {
    const keyErrorFirst = Object.keys(errors || {})[0];

    if (keyErrorFirst === id) errorRef.current?.focus();
  }, [errors]);

  return (
    <>
      <div className="block">
        <input
          type="file"
          id={id}
          name={name}
          className="hidden"
          ref={(e) => {
            registerValidate(e);
            inputRef.current = e;
          }}
          multiple={!!multiple}
          onChange={handleChangeUpload}
        />

        <Button
          size={buttonSize}
          type="button"
          inputRef={errorRef}
          onClick={() => inputRef?.current?.click()}
        >
          <i className="material-icons">add_photo_alternate</i> {buttonLabel}
        </Button>
      </div>

      <div className="grid md:grid-flow-row md:grid-cols-4 gap-4 mt-10">
        {previewImages.map((obj, index) => (
          <div key={`div-${obj?.url}`} className="relative">
            {isImagesType(obj?.type || "") ? (
              <>
                {obj?.imageFrom !== "local" ? (
                  <SecureLinkImg
                    key={`img-${obj?.url}`}
                    className="h-32 opacity-75"
                    src={obj?.url}
                  />
                ) : (
                  <img key={`img-${obj?.url}`} className="h-32 opacity-75" src={obj?.url} />
                )}
              </>
            ) : (
              <div key={`img-${obj?.url}`} className="h-32 upload-preview help-txt text-xs" />
            )}
            <ClearIcon
              key={`delete-icon-${obj?.url}`}
              color="error"
              className="absolute top-0 right-0 cursor-pointer"
              onClick={
                obj?.imageFrom === "local"
                  ? () => handleDeleteFile(inputRef, index)
                  : () => handleDeleteAttachedFile(obj.blobId)
              }
            />
            <p key={`text-${obj?.url}`} className="break-words help-txt text-xs">{`${index + 1}. ${
              obj?.name
            }`}</p>
          </div>
        ))}
      </div>

      {!!errors && !!errors[id] && (
        <div className="block form-input-error mt-10">{errors[id].message}</div>
      )}
    </>
  );
};

export default UploadImages;
