import React, { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Form, { TextField } from "./form";
import axios from "../../../../helpers/configAxios";

interface Props {
  editId: number;
  onClose: () => void;
}

interface Data {
  id: number;
  title: string;
  url: string;
  image_url: string;
}

const Edit: React.FC<Props> = ({ editId, onClose }) => {
  const { errors, register, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Data | null>(null);

  useEffect(() => {
    setLoading(true);
    async function fetchData(): Promise<void> {
      try {
        const res = await axios.get(`/api/promotions/${editId}`);
        setData(res.data.content);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);

  const hangeChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (data: Data): Promise<void> => {
    try {
      const res = await axios.patch(`/api/promotions/${editId}`, data);
      if (res.data.success) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {!loading && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h5 className="mb-3">Edit Promotion</h5>
          <TextField
            inputRef={register()}
            label="Title"
            name="title"
            value={data?.title}
            onChange={hangeChange}
          />
          <TextField
            inputRef={register({ required: true })}
            label="Image URL"
            name="image_url"
            value={data?.image_url}
            onChange={hangeChange}
          />
          <TextField
            inputRef={register({ required: true })}
            label="URL"
            name="url"
            value={data?.url}
            onChange={hangeChange}
          />
          <div className="flex justify-between">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 transition duration-500 bg-gray-200 border border-gray-200 rounded-md select-none ease hover:bg-gray-300 focus:outline-none focus:shadow-outline"
            >
              Close
            </button>
            <button className="px-4 py-3 text-white bg-pink-600 rounded shadow">
              Save
            </button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Edit;
