import React from "react";
import "./preloading.scss";
const Preloading = ({ label = "กำลังโหลดข้อมูล..." }) => {
  return (
    <div className="preloading animated fadeIn">
      <div className="preloading-loading-content">{label}</div>
    </div>
  );
};

export default Preloading;
