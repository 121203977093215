import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TravelForm from "../../../features/travel/TravelForm";

const TabButton = ({ label, active, icon, onClick }) => {
  return (
    <button type="button" className={`tab-btn ${active && "active"}`} onClick={onClick}>
      {icon}
      {label}
    </button>
  );
};

const Travels: React.FC = (): JSX.Element => {
  const [state, setState] = useState({
    travel_type: "one_time",
  });

  const { t } = useTranslation();

  const handleChangeType = (type) => {
    setState({ travel_type: type });
  };

  return (
    <div className="travel-main-page" style={{ backgroundImage: `url(${t("backgroundHome")})` }}>
      <div className="row justify-content-center">
        <div className="col-md-11 col-lg-11 col-xl-9">
          <div className="flex items-center justify-between">
            <h3 className="white-color">ค้นหาแผนประกันเดินทาง</h3>
          </div>
          <div className="travel-tab">
            <TabButton
              active={state.travel_type === "one_time"}
              icon={<i className="icon-travel" />}
              label="รายครั้ง"
              onClick={() => handleChangeType("one_time")}
            />
            <TabButton
              active={state.travel_type === "yearly"}
              icon={<i className="icon-travel" />}
              label="รายปี"
              onClick={() => handleChangeType("yearly")}
            />
          </div>
          <div className="travel-main-page-searchbox">
            <TravelForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Travels;
