import React, { useEffect, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import _get from "lodash/get";
import { messages } from "~/src/helpers/messages";
import { Option } from "~/src/types/basic";
import { FormGroup, SelectField, TextField } from "~/src/components/form";
import { getCarModels } from "~/src/pages/staff/Renewal/hooks/useVehicles";

interface Props extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  name: string;
  carBrand: string | undefined;
  type?: "text" | "select";
  className?: string;
  setLoading?: (status: boolean) => void;
  callbackOnChange?: (e: React.ChangeEvent) => void;
}

const CarModelInput: React.FC<Props> = ({
  name,
  carBrand,
  className,
  type = "select",
  errors,
  register,
  setLoading,
  callbackOnChange,
}): JSX.Element => {
  const [optionCarModels, setOptionCarModels] = useState<Option<string>[]>([]);
  const errorMsg = _get(errors, name)?.message;

  const convertDataToOption = (carModels: string[]): Option<string>[] => {
    return carModels.map((carModel) => {
      return {
        label: carModel,
        value: carModel,
      };
    });
  };

  const fetchCarModels = async (): Promise<void> => {
    try {
      setLoading?.(true);
      const response = await getCarModels(carBrand);
      const resultsOption = convertDataToOption(response.data.models);
      setOptionCarModels(resultsOption);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading?.(false);
    }
  };

  useEffect(() => {
    if (carBrand && type === "select") fetchCarModels();
  }, [carBrand]);

  return (
    <FormGroup label="รุ่นรถยนต์" className={className}>
      {type === "select" ? (
        <SelectField
          name={name}
          style={{ minHeight: "38px" }}
          inputRef={register(messages.required)}
          error={errorMsg}
          onChange={callbackOnChange}
          options={optionCarModels}
        />
      ) : (
        <TextField name={name} inputRef={register(messages.required)} error={errorMsg} />
      )}
    </FormGroup>
  );
};

export default CarModelInput;