import { createConsumer } from "@rails/actioncable";
import { AxiosResponse } from "axios";
import { parseJSON } from "date-fns";
import Decimal from "decimal.js";
import { useCallback, useEffect, useMemo, useState } from "react";
// TEST
// import { useWhatChanged } from "@simbathesailor/use-what-changed";

import ProtectedAPIClient, {
  ProtectedAPIClientProps,
  RedirectAction,
  useProtectedAPI,
  UseProtectedAPIOptions,
  UseProtectedAPIState,
} from "./ProtectedAPIClient";
import { useAppContext } from "~src/context/App";
import { useAuthContext } from "~src/context/Auth";
import { parseDate } from "~src/helpers/date";
import APIResponse from "~src/models/APIResponse";
import CreateCCPaymentParams from "~src/models/params/CreateCCPaymentParams";
import CancelQRCodeParams from "~src/models/params/CancelQRCodeParams";
import CreateQRPaymentParams from "~src/models/params/CreateQRPaymentParams";
import GeneratePaymentLinkParams from "~src/models/params/GeneratePaymentLinkParams";
import GenerateSecureLinkResult from "~src/models/GenerateSecureLinkResult";
import { Order } from "~src/types/order";
import { Payment } from "~src/types/payment";
import { QRInfo } from "~src/types/qrInfo";

export interface GetLinkInfoResponse {
  short_url?: string;
  metadata: Record<string, any>;
  expires_at?: string | Date;
  payment: Payment;
  order: Partial<Order>;
  qr?: QRInfo;
}

export interface CreateCCPaymentResponse extends APIResponse<CreateCCPaymentContentResponse> {
  redirecting?: boolean;
}

export interface CreateCCPaymentContentResponse {
  payment: Payment;
  provider_result?: CreateCCPaymentProviderResultResponse;
}

export interface CreateCCPaymentProviderResultResponse {
  is_pending: boolean;
  is_card_info_error: boolean;
  is_card_auth_error: boolean;
  code?: string;
  message?: string;
  reference_order?: string;
  transaction_state?: string;
  status?: string;
  redirect_url?: string;
}

export interface CreateQRPaymentResponse extends APIResponse<CreateQRPaymentContentResponse> {
  //For backward compatibility
  order_id: number | string;
}

export interface CreateQRPaymentContentResponse {
  payment: Partial<Payment>;
}

export interface GenerateQRCodeResponse extends APIResponse<any>, QRInfo {
  // Put QRInfoResponse fields in here For backward compatibility
}

export interface PaymentEventResponse {
  status: string;
  [key: string]: any;
}

export interface PaymentsAPIClientProps extends ProtectedAPIClientProps {
  redirect?: RedirectAction;
}

export default class PaymentsAPIClient extends ProtectedAPIClient<PaymentsAPIClientProps> {
  constructor(props: PaymentsAPIClientProps) {
    super(props);
  }

  public async generateLink(
    params: GeneratePaymentLinkParams
  ): Promise<APIResponse<GenerateSecureLinkResult>> {
    try {
      const { orderID, ...reqData } = params;

      const url = this.getEndpointURL(`/orders/${orderID}/payments/links`);

      const resp = await this.withAbort(({ signal }) =>
        this._axios.post<any, AxiosResponse<APIResponse<GenerateSecureLinkResult>>>(url, reqData, {
          signal,
        })
      );
      this.checkAPIResponse(resp);

      const genResult = resp.data?.content ? { ...resp.data.content } : undefined;
      if (genResult) {
        for (const fld of ["expires_at"]) {
          genResult[fld] = genResult[fld] ? parseJSON(genResult[fld]) : undefined;
        }
      }

      return { ...resp.data, content: genResult };
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public getLinkURL(token: string): string {
    const url = new URL("/links/payment", window.location.href);
    url.searchParams.append("token", token);

    return url.toString();
  }

  public async getLinkInfo(token: string): Promise<APIResponse<GetLinkInfoResponse>> {
    try {
      const url = this.getEndpointURL("payments/links");

      const resp = await this.withAbort((abc) =>
        this._axios.get<APIResponse<GetLinkInfoResponse>>(url, {
          params: { token },
          signal: abc.signal,
        })
      );
      this.checkAPIResponse(resp);

      const linkInfo = resp.data?.content ? { ...resp.data.content } : undefined;
      if (linkInfo) {
        for (const fld of ["expires_at"]) {
          linkInfo[fld] = linkInfo[fld] ? parseJSON(linkInfo[fld]) : undefined;
        }

        linkInfo.payment = linkInfo.payment ? convertPaymentResponse(linkInfo.payment) : undefined;
        linkInfo.order = linkInfo.order ? convertLinkOrderResponse(linkInfo.order) : undefined;
      }

      return {
        ...resp.data,
        content: linkInfo,
      };
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public async createCCPayment(params: CreateCCPaymentParams): Promise<CreateCCPaymentResponse> {
    try {
      const { orderID, ...reqData } = params;
      const url = `${this.props.appConfig.backendURL}/orders/${orderID}/payments/kbank/cc`;

      const resp = await this.withAbort(({ signal }) =>
        this._axios.post<any, AxiosResponse<CreateCCPaymentResponse>>(url, reqData, {
          signal,
        })
      );
      let nextURL = this.checkRedirectResponse(resp);

      const respData = { ...resp.data };
      // WORKAROUND: Redirections get complicated with Axios/XHR and CORS
      if (!nextURL) {
        this.checkAPIResponse(resp);
        if (respData.content?.provider_result?.redirect_url) {
          nextURL = respData.content.provider_result.redirect_url;
        }
      }
      if (nextURL) {
        console.log(`createCCPayment: Redirecting to URL: ${nextURL} to complete payment`);
        respData.redirecting = true;
        this.props.redirect && this.props.redirect(nextURL);
      }

      return respData;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public async createQRPayment(params: CreateQRPaymentParams): Promise<CreateQRPaymentResponse> {
    try {
      const { orderID, ...reqData } = params;
      const url = `${this.props.appConfig.backendURL}/orders/${orderID}/payments/kbank/qr`;

      const resp = await this.withAbort(({ signal }) =>
        this._axios.post<any, AxiosResponse<CreateQRPaymentResponse>>(url, reqData, {
          signal,
        })
      );
      this.checkAPIResponse(resp);

      return resp.data;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public async generateQRCode(
    orderID: number,
    paymentToken: string
  ): Promise<GenerateQRCodeResponse> {
    try {
      const url = this.getQRCodeURL(orderID);

      const resp = await this.withAbort(({ signal }) =>
        this._axios.post<any, AxiosResponse<GenerateQRCodeResponse>>(
          url,
          {
            payment: {
              token: paymentToken,
            },
          },
          { signal }
        )
      );
      this.checkAPIResponse(resp);

      const qrResult = { ...resp.data };
      if (qrResult) {
        for (const fld of ["expires_at"]) {
          qrResult[fld] = qrResult[fld] ? new Date(qrResult[fld]) : undefined;
        }
      }

      return qrResult;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public async cancelQRCode(params: CancelQRCodeParams): Promise<APIResponse<any>> {
    try {
      const { orderID, paymentToken, qrID } = params;
      const url = this.getQRCodeURL(orderID);

      const resp = await this.withAbort(({ signal }) =>
        this._axios.delete<any, AxiosResponse<APIResponse<any>>>(url, {
          params: {
            "payment[token]": paymentToken,
            qr_id: qrID,
          },
          signal,
        })
      );
      this.checkAPIResponse(resp);

      return resp.data;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public subscribePaymentChannel<T extends PaymentEventResponse>(
    orderID: number,
    paymentToken: string,
    onReceived: (data: T) => void
  ): [ReturnType<typeof createConsumer>, (reason: any) => void] {
    // const accessToken = Cookies.get("access_token");
    // const cableUrl = this.props.appConfig.actionCableURL.replace(/\/$/, "");

    const consumer = createConsumer(this.props.appConfig.actionCableURL);
    return this.withAbort((abc) => {
      const subs = consumer.subscriptions.create(
        {
          channel: "PaymentChannel",
          order_id: orderID,
          payment: {
            token: paymentToken,
          },
        },
        {
          received: onReceived,
        }
      );
      //TODO: Revise this later
      abc.signal.addEventListener("abort", (evt) => {
        console.log("subscribePaymentChannel: aborted:", evt);
        return subs.unsubscribe();
      });
      console.log(`Subscribed to PaymentChannel [${orderID}/${paymentToken}]:`, subs);

      return [subs, abc.abort.bind(abc)];
    });
  }

  private getQRCodeURL(orderID: number) {
    return `${this.props.appConfig.backendURL}/orders/${orderID}/payments/kbank/qr/image`;
  }
}

export function convertPaymentResponse(paymentResp: Payment): Payment {
  const payment = { ...paymentResp };

  for (const fld of ["created_at", "updated_at"]) {
    payment[fld] = payment[fld] ? new Date(payment[fld]) : undefined;
  }
  for (const fld of ["paid_at"]) {
    payment[fld] = payment[fld] ? parseDate(payment[fld]) : undefined;
  }
  for (const fld of ["required_amount"]) {
    payment[fld] = payment[fld] ? new Decimal(payment[fld]) : undefined;
  }

  return payment;
}

// TODO: Revise/Refactor this with OrdersAPI (currently used this to prevent cyclic deps.)
function convertLinkOrderResponse(orderResp: Partial<Order>): Partial<Order> {
  const order = { ...orderResp };

  for (const fld of ["created_at", "updated_at", "expired_at"]) {
    order[fld] = order[fld] ? parseDate(order[fld]) : undefined;
  }

  return order;
}

export const defaultPaymentsAPIFactory = (props: PaymentsAPIClientProps) =>
  new PaymentsAPIClient(props);

export interface UsePaymentsAPIOptions {
  apiFactory?: (props: PaymentsAPIClientProps) => PaymentsAPIClient;
  redirect?: RedirectAction;
  onError?: (err: Error) => void;
}

export interface UsePaymentsAPIState {
  paymentsAPI?: PaymentsAPIClient;
  error?: Error;
}

export function usePaymentsAPI(opts: UsePaymentsAPIOptions = {}): UsePaymentsAPIState {
  const { redirect, onError } = opts;

  // TEST
  // const deps1 = [opts.apiFactory, onError];
  // useWhatChanged(deps1, "opts.apiFactory, onError", "", "usePaymentsAPI");
  const apiFactory = useCallback(
    (baseProps: ProtectedAPIClientProps) => {
      const apiProps = { ...baseProps, redirect };

      if (opts.apiFactory) {
        return opts.apiFactory(apiProps);
      }

      return defaultPaymentsAPIFactory(apiProps);
    },
    [opts.apiFactory]
  );

  const {
    api: paymentsAPI,
    error,
    ...otherState
  } = useProtectedAPI<PaymentsAPIClientProps, PaymentsAPIClient>({
    apiFactory,
    onError,
  });

  return { ...otherState, paymentsAPI, error };
}
