import { Insured } from "~src/types/insured";
import { reformatDateTH } from "./date";
import { deliveryTypeOptions } from "./options";

interface InsuredInfo {
  fullName?: string;
  birthday?: string;
  phone?: string;
}

export const insuredInfoSummary = (insured: Insured): InsuredInfo => {
  return {
    fullName: getInsuredFullName(insured),
    birthday: reformatDateTH(insured?.birthday),
    phone: insured?.phone_number,
  };
};

export function getInsuredFullName(insured: Insured) {
  return insured ? `${insured.title || ""} ${insured.firstname} ${insured.lastname}` : undefined;
}

// TODO: Workaround for Insured.deliveryType conflict
export const getInsuredDeliveryTypeAsArray = (deliveryType?: number | number[]) => {
  if (typeof deliveryType === "undefined" || deliveryType === null) {
    return [];
  }

  if (typeof deliveryType === "number") {
    return deliveryType === 3 ? [1, 2] : [deliveryType];
  }

  return deliveryType;
};

export const getInsuredDeliveryTypeText = (deliveryTypes: number[]): string => {
  return deliveryTypes
    .map((typeId) => {
      return deliveryTypeOptions.find((option) => option.value === typeId).label;
    })
    .join(", ");
};

export const getDeliveryTypeAsNumber = (deliveryType): number => {
  if (deliveryType.length > 1) return 3;
  if (deliveryType.includes("1")) return 1;
  if (deliveryType.includes("2")) return 2;

  return deliveryType;
};
