import React from "react";

import "./text-field.scss";

const TextField = ({ inputRef, currency = null, error, ...rest }) => {
  return (
    <div className="input-text">
      {!currency ? (
        <input
          {...rest}
          ref={inputRef}
          className={!error ? "form-input" : "form-input has-error"}
        />
      ) : (
        <>
          <input
            {...rest}
            ref={inputRef}
            className={!error ? "form-input text-currency" : "form-input text-currency has-error"}
          />
          {currency ? <span className="currency">฿</span> : null}
        </>
      )}
      {error ? <p className="form-input-error">{error}</p> : ""}
    </div>
  );
};

export default TextField;
