import React from "react";
import MaskedInput from "react-text-mask";

import "./mesk-field.scss"

const MeskField = (props) => {
    const {inputRef, ...other} = props;  
    return (
        <MaskedInput
            {...other}
            className="form-mesk"
            // showMask
        />
    );
};

export default MeskField;
