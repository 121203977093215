import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./index.scss";

const SectionHeader = ({ 
  title = "", 
  breadcrumbs = null, 
  searchComponent = null, 
  filterComponent = null, 
  className = "",
  noBorder = false,
}) => {

  return (
    <>
      <div className={`section-header ${noBorder ? "border-none" : ""} ${className}`}>
        <div className="section-header-breadcrumb">
          <ul>
            {breadcrumbs.map((obj, index) => (
              <li key={index}>
                <Link to={obj.url}>{obj.label}</Link>
              </li>
            ))}
          </ul>
          <h1>{title}</h1>
        </div>
        {searchComponent}
      </div>
      {filterComponent}
    </>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  searchComponent: PropTypes.element,
  classname: PropTypes.string
};

export default SectionHeader;
