import { lazy } from "react";

export const publicRoutes = [
  {
    path: "/links/payment",
    exact: true,
    sidebar: false,
    header: false,
    title: "Payment",
    component: lazy(() => import("~src/pages/public/payments/paymentLink")),
  },
  // {
  //   path: "/register",
  //   exact: true,
  //   sidebar: false,
  //   header: false,
  //   title: "Register",
  //   component: lazy(() => import("~src/pages/staff/agent/editor")),
  // },
  {
    path: "/register",
    exact: true,
    sidebar: false,
    header: false,
    title: "Register-2",
    component: lazy(() => import("~src/pages/public/register/Register")),
  },
  {
    path: "/register-2",
    exact: true,
    sidebar: false,
    header: false,
    title: "Register-2",
    component: lazy(() => import("~src/pages/public/register/Register")),
  },
  {
    path: "/register/thankyou",
    exact: true,
    sidebar: false,
    header: false,
    title: "Thank you!",
    component: lazy(() => import("~src/pages/public/register/complete")),
  },
  {
    path: "/public/travels",
    exact: true,
    sidebar: false,
    header: true,
    title: "SearchTravel",
    component: lazy(() => import("~src/pages/public/travels")),
  },
  {
    path: "/public/travels/search",
    exact: true,
    sidebar: false,
    header: true,
    title: "SearchTravel",
    component: lazy(() => import("~src/pages/public/travels/Search")),
  },
  {
    path: "/public/travels/checkout/:order_id/step-1",
    exact: true,
    sidebar: false,
    header: true,
    title: "CheckoutInfo",
    component: lazy(() => import("~src/pages/public/checkout/InfoStep")),
  },
  {
    path: "/public/payments",
    exact: true,
    sidebar: false,
    header: true,
    title: "Payment | Credit Card | QR Code | Bank Transfer",
    component: lazy(() => import("~src/pages/public/payments")),
  },
  {
    path: `/public/checkout/orders/:order_id/upload-payslip`,
    exact: true,
    sidebar: false,
    title: "Commission",
    component: lazy(() => import("~src/pages/public/checkout/UploadPayslip")),
  },
  {
    path: `/public/travels/checkout/:order_id/summary`,
    exact: true,
    sidebar: false,
    header: true,
    title: "CheckoutInfo",
    component: lazy(() => import("~src/pages/public/checkout/Summary")),
  },
  {
    path: `/public/thankyou`,
    exact: true,
    title: "Thankyou for your payment",
    component: lazy(() => import("~src/pages/public/payments/thankyou")),
  },
  {
    path: `/public/privacy-policy`,
    exact: true,
    sidebar: false,
    header: false,
    title: "Privacy Policy",
    component: lazy(() => import("~src/pages/public/PrivacyPolicy")),
  },
];
