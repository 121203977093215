import axios from "/src/helpers/configAxios";
import Cookies from "js-cookie";
import React, { useRef, useState, useContext, useEffect } from "react";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import { Button } from "/src/components/form";
import { CoverageModal } from "~src/components/modal";
import CommissionInfoModal from "~src/components/modal/commissionInfo/commissionInfoModal";
import formingCoveragesFor from "/src/helpers/formingCoverage.js";
import useInsurerIcons from "/src/helpers/useInsurerIcons";
import Preloading from "/src/components/loading/Preloading";
import { useAuthContext } from "~src/context/Auth";

import _ from "lodash";

import compareContext from "/src/context/Compare";
import InsurerDetail from "./InsurerDetail";
import InsurerImg from "./InsurerImg";
import Price from "./Price";
import Commission from "./Commission";
import CompareButton from "./CompareButton";
import CoverageList from "./CoverageList";

import Coverage from "./Coverage";

import useHttp from "/src/hooks/useHttp";

import "./card.scss";
import RecommendIcon from "./RecommendIcon";

const Card = ({
  index,
  packageObj,
  showComission,
  linkCreate,
  details,
  productType,
  modalCarInfo,
}) => {
  const { coverages } = packageObj;
  const compareRef = useRef(null);
  const [stateProductType, setStateProducType] = useState(productType || "motor");
  const [fetchLoading, setFetchLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [insurerImg, setInsurerImg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openCommissionModal, setOpenCommissionModal] = useState(false);
  const [state, dispatch] = useContext(compareContext);
  const [fullCoverages, setFullCoverages] = useState([]);
  const [productInfo, setProductInfo] = useState("");
  const [commissionInfo, setCommissionInfo] = useState({});

  const { get, post } = useHttp();

  const { session } = useAuthContext();
  const { username, permissions } = session;
  const isCommissionAccessible =
    permissions.findIndex((p) => p.slug === "COMMISSION" && p.action === "SEARCH") > -1;

  useEffect(() => {
    const activeCompare = _.find(state.compareLists, function (o) {
      return o.id == packageObj.id;
    });
    setActive(!!activeCompare);
  }, [state]);

  const renderRecommendIcon = () => {
    const insurers = ["MTI", "TNI", "SAFE", "MSIG"];
    const { insurer_code, insurer_class } = packageObj;
    if (insurers.indexOf(insurer_code) !== -1 && insurer_class === "1") return <RecommendIcon />;
  };

  const renderRecommendMessage = () => {
    const insurers = ["MSIG"];
    const { insurer_code, insurer_class } = packageObj;

    let box = [];
    if (insurers.indexOf(insurer_code) !== -1 && insurer_class === "1") {
      box.push(
        <div class="text-white text-left rounded-sm" style={{ height: 20 }}>
          <span className="bg-secondary text-xs rounded px-1 mr-2">โปรโมชั่น</span>{" "}
          <span className="text-secondary">รับสูงสุด 400 บาทต่อกรมธรรม์</span>
        </div>
      );
    } else {
      box.push(<div class="text-white text-center" style={{ height: 20 }}></div>);
    }

    return box;
  };

  const handleCompare = () => {
    compareRef.current = !active;
    if (compareRef.current && state.compareLists.length <= 2) {
      const { id, insurer_name, insurer_img, insurer_class, repair_facility, standard_price } =
        packageObj;
      dispatch({
        type: "ADD",
        payload: {
          ...state,
          compareIds: [...state.compareIds, packageObj.id],
          compareLists: [
            ...state.compareLists,
            {
              id,
              insurer_name,
              insurer_img,
              insurer_class,
              repair_facility,
              standard_price,
            },
          ],
        },
      });
    } else if (state.compareLists.length > 2 && !active) {
      alert("ตัวเลือกเปรียบเทียบเต็มแล้ว กรุณาลบของเก่าออกก่อนค่ะ");
    } else {
      const ids = _.remove(state.compareIds, (o) => {
        return o != packageObj.id;
      });
      const objs = _.remove(state.compareLists, (o) => {
        return o.id != packageObj.id;
      });
      dispatch({
        type: "REMOVE",
        payload: {
          ...state,
          compareIds: [...ids],
          compareLists: [...objs],
        },
      });
    }
  };

  function handleCloseModal() {
    setOpenModal(false);
    setOpenCommissionModal(false);
    setInsurerImg("");
  }

  function fetchProductData(productId) {
    setFetchLoading(true);
    get(`/api/products/${productId}`)
      .then((response) => {
        setProductInfo(response.data.content);
        setFullCoverages(formingCoveragesFor("motor", response.data.content));
        setOpenModal(true);
        setFetchLoading(false);
      })
      .catch((error) => {
        handleFetchError(error);
        setFetchLoading(false);
      });
  }

  const fetchProductCommission = (product) => {
    const payload = {
      insurer_code: product.insurer_code,
      product_type: "MotorProduct",
      policy_type: product.insurer_class,
      agent_code: username,
      package_name: product.product_name,
      order_type: "new",
    };

    setFetchLoading(true);
    post(`/api/commissions/search`, payload)
      .then((response) => {
        const packageCommission = response.data[0];
        setCommissionInfo(packageCommission);
        setOpenCommissionModal(true);
        setFetchLoading(false);
      })
      .catch((error) => {
        handleFetchError(error);
        setFetchLoading(false);
      });
  };

  const handleOpenModalCovered = () => {
    fetchProductData(packageObj.id);
  };

  const handleOpenModalCommission = () => {
    fetchProductCommission(packageObj);
  };

  return (
    <>
      <div className={active ? "card zoom-in" : "card"}>
        <div className="card-header">
          <InsurerImg src={packageObj.insurer_img} alt={packageObj.insurer_name} />
          <InsurerDetail
            name={packageObj.insurer_name}
            type={packageObj.insurer_class}
            productName={packageObj.product_name}
            repairFacility={packageObj.repair_facility}
          />
          {renderRecommendIcon()}
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            {(() => {
              switch (productType) {
                case "motor":
                  return (
                    <Price
                      className="font-extrabold text-lg"
                      value={packageObj.standard_price}
                      suffix="ต่อปี"
                    />
                  );
                case "travel":
                  return (
                    <Price className="font-extrabold text-lg" value={packageObj.standard_price} />
                  );
              }
            })()}
            <CompareButton
              id={`compareBtn_${index}`}
              inputRef={compareRef}
              classes={active ? "active" : ""}
              onClick={handleCompare}
            />
          </div>
          {showComission && <Commission value={packageObj.commission} />}
        </div>
        <hr className="mt-2" />
        <div className="card-coverage">
          {(() => {
            const data = [];
            switch (stateProductType) {
              case "motor":
                data[0] = [
                  { ทุนประกัน: coverages.sum_insured },
                  { ค่าเสียหายส่วนแรก: coverages.excess_damage_coverage },
                  { ประเภทการซ่อม: packageObj.repair_facility },
                  { น้ำท่วม: coverages.natural_disaster_coverage },
                  {
                    ทรัพย์สินบุคคลภายนอก:
                      coverages.third_party_property_damage_coverage_per_incident,
                  },
                ];
                break;
              case "travel":
                data[0] = [
                  { ชดเชยกรณีเสียชีวิต: coverages.sum_insured },
                  { ค่ารักษาพยาบาล: coverages.excess_damage_coverage },
                  { เคลื่อนย้ายฉุกเฉิน: coverages.repair_facility },
                  {
                    "สัมภาระสูญหาย/เสียหาย": coverages.natural_disaster_coverage,
                  },
                  {
                    การบอกเลิกการเดินทาง:
                      coverages.third_party_property_damage_coverage_per_incident,
                  },
                ];
                break;
            }
            return <Coverage data={data[0]} />;
          })()}
        </div>
        <div className="card-actions">
          {isCommissionAccessible && (
            <button
              className="bg-pink-200 h-24 w-full hover:bg-pink-300 transition"
              id={`commissionInfo_${index}`}
              onClick={handleOpenModalCommission}
            >
              ดูค่าตอบแทน
            </button>
          )}
          <button
            className="more-detail"
            id={`moreConveres_${index}`}
            onClick={handleOpenModalCovered}
          >
            ดูรายละเอียด
          </button>
          <a href={linkCreate} id={`linkCreateQuotation_${index}`} className="buy-button">
            <i className="material-icons">add_shopping_cart</i>
            สร้างใบเสนอราคา
          </a>
        </div>
      </div>
      {openModal && (
        <CoverageModal
          handleClose={handleCloseModal}
          insurerImg={insurerImg}
          productInfo={productInfo}
          coverages={fullCoverages}
          details={details}
        />
      )}
      {openCommissionModal && (
        <CommissionInfoModal
          handleClose={handleCloseModal}
          commissionInfo={commissionInfo}
          productInfo={packageObj}
        />
      )}

      {fetchLoading && <Preloading />}
    </>
  );
};

function handleFetchError(error) {
  // TODO: handling fetch error here
  console.log("error", error);
}

export default Card;
