import React from "react";
import cn from "classnames";
import t from "../../../../status.yml";

interface Props {
  status: string;
}

const Badge: React.FC<Props> = ({ status }) => {
  const statusClasses = (status: string): string[] => {
    switch (status) {
      case "open":
        return ["bg-green-200", "text-green-600"];
      case "closed":
        return ["bg-red-200", "text-red-600"];
      case "cancel":
        return ["bg-yellow-200", "text-yellow-600"];
      default:
        return ["bg-gray-200", "text-gray-600"];
    }
  };
  return (
    <div
      className={cn([
        "text-2xs rounded px-3 py-1",
        statusClasses(status).join(" "),
      ])}
    >
      {t.training[status]}
    </div>
  );
};

export default Badge;
