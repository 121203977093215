import { ProductDto, CoverageDetailsProp } from "~src/types/product";
import { formatNumberWithCurrency2 } from "~src/helpers";

export default abstract class Product {
  id: number;

  insurerName: string;

  insurerCode: string;

  insurerImg: string;

  insurerClass: string;

  productName: string;

  standardPrice: number;

  commission: number;

  constructor(productDto: ProductDto) {
    this.id = productDto.id;
    this.insurerName = productDto.insurer_name;
    this.insurerCode = productDto.insurer_code;
    this.insurerImg = productDto.insurer_img || productDto.insurer_icon;
    this.insurerClass = productDto.insurer_class;
    this.productName = productDto.product_name;
    this.standardPrice = productDto.standard_price;
    this.commission = productDto.commission ?? 0;
  }

  displayValue = (value: number): number | "-" => {
    if (value === 0 || !value) {
      return "-";
    }

    return formatNumberWithCurrency2(value);
  };

  abstract cardDetails();
  abstract coverageDetails(): CoverageDetailsProp[];
  abstract priceSuffix(): string;
}
