export default class AppConfig {
  backendURL: string = "";
  apiURL: string = "";
  actionCableURL: string = "";
  // TODO: Permanently enable Payment Links feature
  paymentLinksEnabled = false;

  constructor(props?: Partial<AppConfig>) {
    props && Object.assign(this, props);
    this.updateFromEnv();
    this.checkUpdateURLs();
  }

  //TODO: Revise ENV names
  public updateFromEnv() {
    this.backendURL = process.env.BACKEND_URL || process.env.API_URL || this.backendURL;
    this.apiURL = process.env.BACKEND_API_URL || this.apiURL;
    this.actionCableURL = process.env.ACTION_CABLE_URL || this.actionCableURL;
    this.paymentLinksEnabled = process.env.PAYMENT_LINKS_ENABLED
      ? !/false|0/i.test(process.env.PAYMENT_LINKS_ENABLED)
      : this.paymentLinksEnabled;
  }

  public checkUpdateURLs() {
    if (!this.apiURL) {
      this.apiURL = `${this.backendURL ?? ""}/api`;
    }
    if (!this.actionCableURL) {
      const tempURL = new URL(this.backendURL);
      const wsProto = tempURL.protocol === "https" ? "wss" : "ws";
      this.actionCableURL = `${wsProto}://${tempURL.hostname}:${tempURL.port}/cable`;
    }
  }
}
