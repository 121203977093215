import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Form, { TextField } from "./form";
import axios from "../../../../helpers/configAxios";

interface Props {
  onClose: () => void;
}

interface Data {
  id: number;
  title: string;
  url: string;
  image_url: string;
}

const Create: React.FC<Props> = ({ onClose }) => {
  const [data, setData] = useState<Data | null>(null);

  const hangeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const { errors, register, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  
  const onSubmit = async (data: Data): Promise<void> => {
    try {
      const res = await axios.post("/api/promotions", data);
      if (res.data.success) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h5 className="mb-3">Create new promotion</h5>
        <TextField
          inputRef={register()}
          label="Title"
          name="title"
          onChange={hangeChange}
        />
        <TextField
          inputRef={register({ required: true })}
          label="Image URL"
          name="image_url"
          onChange={hangeChange}
        />
        <TextField
          inputRef={register({ required: true })}
          label="URL"
          name="url"
          onChange={hangeChange}
        />
        <div className="flex justify-between">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-700 transition duration-500 bg-gray-200 border border-gray-200 rounded-md select-none ease hover:bg-gray-300 focus:outline-none focus:shadow-outline"
          >
            Close
          </button>
          <button className="px-4 py-3 text-white bg-pink-600 rounded shadow">
            Save
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Create;
