import React, { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "~src/store";
import { getPdpaNewAgent, handleChangeStep, handleSubmit } from "./registerSlice";
import { pdpaStatusSelector, pdpaViewSelector } from "../pdpa";
import { unEscapeHtml } from "~src/helpers/helpers";
import { Radio } from "~src/components/form";

const Pdpa = () => {
  const [answers, setAnswers] = React.useState([]);
  const dispatch = useAppDispatch();
  const pdpa = useAppSelector(pdpaViewSelector);
  const pdpaResponsStatus = useAppSelector(pdpaStatusSelector);

  React.useEffect(() => {
    dispatch(getPdpaNewAgent());
  }, []);

  const handleChangeAnswer = (index: number, id: number, checked: boolean) => {
    const updateAnswers = [...answers];
    updateAnswers[index] = { ...updateAnswers[index], id, answer: checked };
    setAnswers(updateAnswers);
  };

  const handleOk = () => {
    if (answers.length < pdpa.data.questions.length) {
      alert("กรุณาตอบคำถามให้ครบ");
      return;
    }

    dispatch(
      handleSubmit({
        id: Number(pdpa.data.id),
        questions: [...answers],
      })
    );
  };

  return (
    <div className="w-full px-2">
      <div className="flex flex-wrap items-center justify-between w-full col-span-1 col-start-1 bg-white shadow-sm mb-3 rounded-5 p-24">
        {pdpa.open && (
          <div className="w-full">
            <div
              className="detail-wrapper [word-break:break-word]"
              style={{ wordBreak: "break-word"}}
              dangerouslySetInnerHTML={{ __html: unEscapeHtml(pdpa.data.detail) }}
            />

            <div className="w-full my-30">
              {pdpa.data.questions.map((q, index) => (
                <React.Fragment key={index}>
                  <div className="text-base mb-1">{q.question}</div>
                  <div className="mb-2 flex gap-2">
                    <Radio
                      id={`answer[${q.id}]_true`}
                      name={`answer[${q.id}]`}
                      label="ยินยอม"
                      value="1"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChangeAnswer(index, q.id, true)
                      }
                    />
                    <Radio
                      id={`answer[${q.id}]_false`}
                      name={`answer[${q.id}]`}
                      label="ไม่ยินยอม"
                      value="0"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChangeAnswer(index, q.id, false)
                      }
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="w-full grid grid-cols-2 gap-4 my-30">
        <button
          type="button"
          onClick={() => dispatch(handleChangeStep(1))}
          className="px-3 py-2 w-full md:max-w-xs justify-self-start text-base transition duration-200 text-white bg-gray-300 rounded-lg hover:bg-gray-600"
        >
          ย้อนกลับ
        </button>
        <button
          type="button"
          onClick={handleOk}
          className="px-3 py-2 w-full md:max-w-xs justify-self-end text-base transition duration-200 text-white bg-primary-color rounded-lg hover:bg-primary-color-dark"
          disabled={pdpaResponsStatus === "pending"}
        >
          ดำเนินการสมัครสมาชิก
        </button>
      </div>
    </div>
  );
};

export default Pdpa;
