import React from "react";
import Search from "~src/features/travel/Search";

import "./search.scss";

const TravelSearchPage = () => {
  return (
    <div className="travel-search-page">
      <h1>
        <i className="material-icons">card_travel</i>ประกันเดินทาง
      </h1>
      <div className="row">
        <div className="col-12">
          <div className="row"></div>
        </div>
      </div>
      <Search />
    </div>
  );
};

export default TravelSearchPage;
