import axios from "/src/helpers/configAxios";
import React, { useReducer, useState, useEffect, useContext, useMemo } from "react";

import { ToastProvider, useToasts } from "react-toast-notifications";

import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import { QuotationModal } from "/src/components/modal";
import QueryString from "query-string";
import InsurerDetails from "/src/pages/agent/quotation/insurerDetails";
import { MultipleQuotationProvider } from "/src/context/MultipleQuotation";
import Preloading from "/src/components/loading/Preloading";

import {
  initMultipleQuotationState,
  multipleQuotationReducer,
} from "/src/reducer/multipleQuotationReducer";
import { Button, TextField, FormGroup } from "/src/components/form";
import { messages } from "/src/helpers/messages";
import { format } from "date-fns";

import useFetch from "/src/hooks/useFetch";
import useHttp from "/src/hooks/useHttp";

import { useAuthContext } from "/src/context/Auth";

import "./multipleQuotation.scss";

const MultipleQuotation = (props) => {
  const query = new URLSearchParams(useLocation().search);
  const params = {};
  query.forEach((value, key) => {
    params[key] = value;
  });

  const { session } = useAuthContext();

  const carTitle = `${params.car_brand} ${params.year} ${params.car_model}`;

  const productType = "motor";

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const history = useHistory();
  const { search } = props.location;
  const [fetchLoading, setFetchLoading] = useState(false);
  const [querires, setQuerires] = useState(QueryString.parse(search));
  const [locationsSearch, setLocationSearch] = useState(location.search);
  const [productInfo, setProductInfo] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [quotationInfo, setQuotationInfo] = useState({});
  const [quotesInfo, setQuotesInfo] = useState([]);
  const [productsIds, setProductsId] = useState(QueryString.parse(locationsSearch).compares || "");
  const useMultipleQuotationState = useReducer(
    multipleQuotationReducer,
    initMultipleQuotationState
  );

  const { response, error, isLoading } = useFetch(`/api/products/`, {
    params: {
      ids: productsIds,
      code_name: querires.code_name,
      province: querires.province,
    },
  });
  const { post } = useHttp();

  useMemo(() => {
    setFetchLoading(true);
    if (!isLoading && !error) {
      if (response.error) {
        setFetchLoading(false);
      } else {
        setFetchLoading(false);
        const { content } = response;
        setProductInfo(response.content);
      }
    }
    if (error) {
      handleFetchError(error);
    }
  }, [response, error, isLoading]);

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const setupQuotesData = (data, index) => {
    quotesInfo[index] = data;
    setQuotesInfo(quotesInfo);
  };

  const quotationParams = {
    product_ids: productsIds,
    customer_name: customerName,
    customer_phone: customerPhone,
    criteria: {
      insurance_class: params.policy_type,
      car_brand: params.car_brand,
      car_model: params.car_model,
      year: params.year,
      code_name: params.code_name,
      province: params.province,
    },
    quotes: quotesInfo,
  };

  function postCreateQuotation() {
    setFetchLoading(true);
    const url = `${process.env.API_URL}/api/quotations`;
    axios
      .post(url, JSON.stringify(quotationParams))
      .then((response) => {
        setFetchLoading(false);
        validateFetchReponse(response);
        setQuotationInfo(response.data.content);
        setIsOpenModal(true);
      })
      .catch((error) => {
        handleFetchError(error);
      });
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    postCreateQuotation();
  };

  return (
    <MultipleQuotationProvider value={useMultipleQuotationState}>
      <div className="multiple-quotation">
        <div className="p-8 row">
          <div className="col-md-10">
            <div className="d-flex">
              <h6 className="inline">
                <i className="inline-block mr-1 icon-Motor" />
                สร้างใบเสนอประกันภัยรถยนต์แบบเปรียบเทียบ
              </h6>
            </div>
          </div>
          <div className="text-blue-700 col-md-2">
            <button onClick={() => history.goBack()}>ย้อนกลับไปเลือกแผนประกันอื่น</button>
          </div>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="multiple-quotation-component">
            <div className="row">
              <div className="col-8">
                <div className="row">
                  <div className="mb-2 col-3 text-2">สำหรับรถยนต์</div>
                  <div className="mb-2 col-9">{carTitle}</div>
                </div>
                <div className="row">
                  <div className="mb-2 col-3 text-2">ออกให้ ณ. วันที่</div>
                  <div className="mb-2 col-9">{format(new Date(), "dd/MM/yyyy")}</div>
                </div>
                <div className="row">
                  <div className="mb-2 col-3 text-2">ตัวแทน</div>
                  <div className="mb-2 col-9">
                    {`${session.title} ${session.firstname} ${session.lastname}`}{" "}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="mb-2 text-base text-gray-600">
                  <FormGroup htmlForLabel="name" label="ชื่อลูกค้า" require>
                    <TextField
                      type="text"
                      name="name"
                      id="name"
                      onChange={(e) => setCustomerName(e.target.value)}
                      inputRef={register(messages.required)}
                      error={!!errors.name && errors.name.message}
                    />
                  </FormGroup>
                </div>
                <div className="text-base text-gray-600">
                  <FormGroup htmlForLabel="phone" label="เบอร์โทรลูกค้า" require>
                    <TextField
                      type="text"
                      name="phone"
                      id="phone"
                      onChange={(e) => setCustomerPhone(e.target.value)}
                      inputRef={register({
                        ...messages.required,
                        ...messages.mobileNumber,
                      })}
                      error={!!errors.phone && errors.phone.message}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button type="submit" color="primary" fullwidth>
                      บันทึกใบเสนอราคา
                    </Button>
                  </FormGroup>
                </div>
              </div>
            </div>
            <hr />
            <div className="multiple-quotation-body">
              {!isLoading ? (
                <>
                  {(() => {
                    switch (productType) {
                      case "motor":
                        return (
                          <InsurerDetails
                            packageObjs={productInfo}
                            setupQuotesData={setupQuotesData}
                            errors={errors}
                            register={register}
                          />
                        );
                    }
                  })()}
                </>
              ) : null}
            </div>
            {isOpenModal ? (
              <ToastProvider>
                <QuotationModal
                  multipleProduct={true}
                  handleClose={handleCloseModal}
                  quotation_no={quotationInfo.number}
                  quotationId={quotationInfo.id}
                  quoteIds={quotationInfo.quotes.map((q) => q.id)}
                />
              </ToastProvider>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
      {fetchLoading ? <Preloading /> : null}
    </MultipleQuotationProvider>
  );
};

function validateFetchReponse(response) {
  if (response.ok) {
    return response.json();
  }
  return Promise.reject(`error: ${response.status}`);
}

function handleFetchError(error) {
  console.log("error", error);
}

export default MultipleQuotation;
