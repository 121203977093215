import React, { useState } from "react";
import qs from "qs";

import { useTranslation } from "react-i18next";
import FormVmi from "./form/FormVmi";
import FormCmi from "./form/FormCmi";

import "./index.scss";

type Tab = "vmi" | "cmi" | "commercial";

interface TabButton {
  label: JSX.Element | string;
  active: boolean;
  icon: JSX.Element | string;
  onClick: () => void;
}

const TabButton: React.FC<TabButton> = ({ label, active, icon, onClick }) => {
  return (
    <button className={`tab-btn ${active && "active"}`} onClick={onClick}>
      {icon}
      {label}
    </button>
  );
};

const Motor = () => {
  const [animationVmiClass, setAnimationVmiClass] = useState("");
  const [animationCmiClass, setAnimationCmiClass] = useState("");
  const [activeTab, setActiveTab] = useState<Tab>(() => {
    const defaultTab: Tab = "vmi";

    try {
      const searchParams = window.location.search;
      const query = qs.parse(searchParams, { ignoreQueryPrefix: true });
      const { tab } = query;

      if (!["vmi", "cmi", "commercial"].includes(tab)) return defaultTab;

      return tab;
    } catch {
      return defaultTab;
    }
  });
  const { t } = useTranslation();

  const handleChangeTab = (tab: Tab) => {
    switch (tab) {
      case "cmi":
        setAnimationCmiClass("bounceInRight");
        break;
      case "commercial":
        setAnimationVmiClass("bounceInLeft");
        break;
      default:
        setAnimationVmiClass("bounceInLeft");
        break;
    }

    setActiveTab(tab);
  };
  return (
    <div className="motor-mainpage" style={{ backgroundImage: `url(${t("backgroundHome")})` }}>
      <div className="row justify-content-center">
        <div className="col-md-11 col-lg-11 col-xl-9">
          <h3 className="white-color">ค้นหาแผนประกันภัย</h3>
          <div className="motor-tab">
            <TabButton
              active={activeTab === "vmi"}
              icon={<i className="icon-Motor" />}
              label="ซื้อประกันภัยรถยนต์"
              onClick={() => handleChangeTab("vmi")}
            />
            <TabButton
              active={activeTab === "cmi"}
              icon={<i className="icon-Motor" />}
              label="ซื้อ พ.ร.บ."
              onClick={() => handleChangeTab("cmi")}
            />
            {t("tabSearchCommercial") && (
              <TabButton
                active={activeTab === "commercial"}
                icon={<i className="icon-Motor" />}
                label="ซื้อประกันภัยรถยนต์เชิงพาณิชย์"
                onClick={() => handleChangeTab("commercial")}
              />
            )}
            <div className="tab-content">
              {activeTab === "vmi" && (
                <div className={`motor-mainpage-searchbox animated ${animationVmiClass}`}>
                  <FormVmi />
                </div>
              )}
              {activeTab === "cmi" && (
                <div className={`motor-mainpage-searchbox animated ${animationCmiClass}`}>
                  <FormCmi />
                </div>
              )}
              {activeTab === "commercial" && (
                <div className={`motor-mainpage-searchbox animated ${animationCmiClass}`}>
                  <FormVmi productType="commercial" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Motor;
