import React, { useReducer, useState, useEffect, useContext } from "react";
import { unstable_batchedUpdates } from "react-dom";
// import { matchPath, match as Match } from "react-router";
import { useHistory } from "react-router-dom";

import MotorInsured from "./summary/motor/Insured";
import Car from "./summary/motor/Car";
import Cmi from "./summary/motor/Cmi";
import Alert from "~src/components/alert";
import Miscellaneous from "./summary/motor/Miscellaneous";
import { Button, ButtonRadius } from "~src/components/form";
import InsurancePlanSummary from "~src/components/insurancePlanSummary/insurancePlanSummary";
import Preloading from "~src/components/loading/Preloading";
import Stepper from "~src/components/stepper/Stepper";

import CheckoutContext from "~/src/context/Checkout";
import useProductOrder from "./product_order";
import { useAgentOrdersAPI } from "~src/api/agent/Orders";
import { CheckoutProvider } from "~src/context/Checkout";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { CheckoutSummaryState } from "~src/models/CheckoutState";
import CheckoutReducer, { initCheckoutState } from "~src/reducer/CheckoutReducer";
import { CMI } from "~src/types/cmi";

import "./summary.scss";
import { PaymentsPageState } from "../payment/payments";

// export interface SummaryProps { location: Location, match: Match<any> }

export default function Summary(props) {
  return (
    <PageContextProvider>
      <SummaryInner {...props} />
    </PageContextProvider>
  );
}

// interface SummaryInnerProps extends DashboardProps {}

var SummaryInner = (props) => {
  // TEST
  const orderId = props.match.params.order_id;
  // const orderId = matchPath(props.location.pathname, {
  //   path: "/checkout/:order_id/summary",
  //   exact: true,
  // }).params.order_id;

  const history = useHistory();

  const { onError, setAlert, setPreloading } = usePageContext();
  const { ordersAPI } = useAgentOrdersAPI({
    onError: setAlert,
  });

  const [addOrRemoveCMI, setAddOrRemoveCmi] = useState<Partial<CMI>>({});
  // const [preLoading, setPreloading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(null);

  const {
    isOrderAvailable,
    orderNumber,
    insureds,
    quotationInfo,
    quoteInfo,
    details,
    deliveryAddress,
    getLabelSteppers,
    getSummaryInfo,
    insuredCategory,
    cmiObjs,
    includeWhTax,
  } = useProductOrder(orderId, { onError });

  // TODO: Revise this later
  const [insured, car, delivery, summary] = isOrderAvailable
    ? [insureds[0], getCar(), deliveryAddress, getSummaryInfo()]
    : [{}, {}, deliveryAddress, {}];

  useEffect(() => {
    setPreloading(!isOrderAvailable);
  }, [isOrderAvailable]);

  useEffect(() => {
    setAddOrRemoveCmi(cmiObjs);
  }, [cmiObjs]);

  function getCar() {
    const model_desc = `${quotationInfo?.criteria?.car_brand} ${quotationInfo.criteria?.car_model} ปี ${quotationInfo.criteria?.year}`;
    const { plate_number, plate_province, chassis_number, cover_start_date, cover_end_date } =
      details;

    return {
      model_desc,
      plate_number,
      plate_province,
      chassis_number,
      cover_start_date,
      cover_end_date,
    };
  }

  function gotoPayment(summary: CheckoutSummaryState) {
    const state: PaymentsPageState = {
      orderID: orderId,
      orderNumber,
      summary,
      insuredCategory,
      insureds,
      quoteInfo,
      addOrRemoveCMI,
      includeWhTax,
    };

    history.push({
      pathname: `/payments`,
      state,
    });
  }

  const updateOrderRefId = async (summary) => {
    setPreloading(true);
    try {
      const respData = await ordersAPI.updateRefId(orderId);
      setPreloading(false);
      return gotoPayment(summary);
    } catch (error) {
      console.error(error);
      unstable_batchedUpdates(() => {
        setPreloading(false);
        setAlert(error.message, { update_order_ref_id: error.details || [] });
      });
    }
  };

  return (
    <CheckoutProvider>
      {/* {errorMessage && <Alert message={errorMessage} />} */}
      {isOrderAvailable ? (
        <div className="checkout-summary">
          <div className="row">
            <div className="col-md-12">
              <h1>
                <i className="material-icons">lock</i>ยืนยันคำสั่งซื้อ
              </h1>
              <Stepper steppers={getLabelSteppers(2)} />
            </div>

            <div className="col-md-8">
              <div className="checkout-summary-card">
                <div>
                  <h6>ยืนยันข้อมูล</h6>
                  <p className="text-primary">
                    ** กรุณาตรวจสอบข้อมูลของคุณ ให้ถูกต้อง ก่อนทำธุรกรรมทุกครั้ง
                    เพราะข้อมูลเหล่านี้มีผลต่อ การคุ้มครอง และปรากฎอยู่บนกรมธรรม์
                  </p>
                </div>

                <MotorInsured
                  category={insuredCategory}
                  insured={insured}
                  includeWhTax={includeWhTax}
                />
                <Car car={car} quoteInfo={quoteInfo} />
                {addOrRemoveCMI.checked_addon &&
                addOrRemoveCMI.addon_selling_price !== undefined ? (
                  <Cmi
                    car={details}
                    price={addOrRemoveCMI.addon_selling_price}
                    quoteInfo={quoteInfo}
                  />
                ) : (
                  <>
                    {quoteInfo[0].policy_type === "cmi" ? (
                      <Cmi car={details} price={quoteInfo[0].selling_price} quoteInfo={quoteInfo} />
                    ) : null}
                  </>
                )}

                {/* <DeliveryAddress delivery={delivery} /> */}

                {process.env.APP_NAME !== "mti" && insuredCategory === "legal_entity" && (
                  <Miscellaneous includeWhTax={includeWhTax} />
                )}

                <div className="motor-summary-page-actions space-between w-full mt-16">
                  <ButtonRadius component="link" to="./step-2" type="submit">
                    ย้อนกลับ
                  </ButtonRadius>
                  <ButtonRadius onClick={() => updateOrderRefId(summary)} color="primary">
                    บันทึกและดำเนินการต่อ
                  </ButtonRadius>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <InsurancePlanSummary
                addOrRemoveCMI={addOrRemoveCMI}
                quoteInfo={quoteInfo}
                summary={summary}
                insuredCategory={insuredCategory}
                includeWhTax={includeWhTax}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="motor-info-page">{/* <p className="text-gray-500">Loading ...</p> */}</div>
      )}
    </CheckoutProvider>
  );
};
