import React from "react";
import PropTypes from "prop-types";

import "./section.scss";

const Section = ({ title, component }) => {
  return (
    <section className="box-item">
      <div className="section-form-header">
        <span className="material-icons">expand_more</span>
        {title}
      </div>
      <div className="section-form-content">{component}</div>
    </section>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  component: PropTypes.element,
};

export default Section;
