import React from "react";

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Modal: React.FC<Props> = ({ children, onClick }) => {
  return (
    <div
      className="fixed top-0 left-0 flex items-center justify-center w-full h-full "
      style={{ zIndex: 1000 }}
    >
      <div className="absolute w-full h-full bg-gray-900 opacity-75"></div>
      <div
        style={{ height: "calc(100vh - 20px)" }}
        className="relative z-10 w-full p-5 bg-white rounded-lg shadow-sm overflow-y-auto"
      >
        <div className="flex justify-end cursor-pointer">
          <button onClick={onClick}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div
          style={{ height: "calc(100vh - 30px)" }}
          className="w-full overflow-x-auto "
        >
          {children}
        </div>
      </div>
    </div>
  );
};
