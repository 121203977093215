import React from "react";

import { formatNumberWithCurrency } from "~src/helpers";

interface Props {
  totalAmount: string;
  insureds: number;
}

const EarningCommission: React.FC<Props> = ({ totalAmount, insureds = 1 }) => {
  return <div>{formatNumberWithCurrency(Number(totalAmount) / insureds)}</div>;
};

export default EarningCommission;
