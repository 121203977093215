import React from "react";
import { LocalShippingOutlined } from "@mui/icons-material";
import BadgeCommon from "./Common";

type TrackingProp = {
  policyId: string;
  onClickCallback?: (policyId: string) => void;
};

const Tracking: React.FC<TrackingProp> = ({ policyId, onClickCallback }): JSX.Element => {
  return (
    <button type="button" onClick={() => onClickCallback(policyId)}>
      <BadgeCommon color="secondary-light">
        <LocalShippingOutlined className="mr-1 text-secondary-1" fontSize="small" />
        <span className="text-secondary-1" style={{ fontSize: "10px" }}>
          สถานะการจัดส่งกรมธรรม์
        </span>
      </BadgeCommon>
    </button>
  );
};

export default Tracking;
