import React from "react";
import { Button } from "~src/components/form";

const HeroLicence = ({ onClose }) => {
  return (
    <div className="w-full h-full fixed top-0 left-0 flex justify-center items-center">
      <div className="absolute w-full h-full bg-black top-0 left-0 opacity-90"></div>
      <div className="bg-white w-10/12 h-screen relative z-10 overflow-y-auto">
        <div className="m-60">
          {/* <h1>
            บริษัท เมืองไทย โบรกเกอร์ จำกัด (สำนักงานใหญ่) <br />
            MUANG THAI BROKER CO., LTD (Head Office)
          </h1>
          <div className="text-xs font-light">
            252/280 อาคาร เมืองไทย-ภัทร คอมเพล็กซ์ อาคารคอนโด The Garage ชั้น 11 ถนนรัชดาภิเษก
            แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร 10310 โทร.(662) 274-9400 #5578 <br />
            252/280 MUANGTHAI PHATRA COMPLEX BUILDING, RESIDENCE TOWER, 11th FLOOR THE GARAGE,
            RACHADAPHISEK ROAD, HUAYKWANG, HUAYKWANG, BANGKOK 10310 Tel. (662) 274-9400 #5578
          </div> */}

          <h3 className="text-center my-16 text-secondary text-lg font-bold">
            สัญญาแต่งตั้งนายหน้าประกันวินาศภัย
          </h3>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 1. โดยสัญญานี้
            บริษัทตกลงแต่งตั้งให้นายหน้า
            เป็นนายหน้าประกันวินาศภัยของบริษัทมีกำหนดเวลาตั้งแต่วันที่ทำสัญญานี้เป็นต้นไป
            จนกว่าสัญญานี้จะสิ้นสุดลงด้วยเหตุหนึ่งเหตุใดตามที่ระบุไว้ในสัญญานี้
          </p>
          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สัญญาฉบับนี้มีวัตถุประสงค์ให้นายหน้ามีหน้าที่ชี้ช่องหรือจัดการให้บุคคลหรือนิติบุคคล
            ซึ่งต่อไปในสัญญานี้เรียกว่า “ผู้เอาประกันภัย” เข้าทำสัญญาประกันภัย
            กับบริษัทประกันวินาศภัยตามที่บริษัทจะเป็นผู้กำหนดและแจ้งให้ทราบ
            โดยนายหน้าเป็นผู้ดำเนินการเบื้องต้น เพื่อให้ผู้เอาประกันภัย
            ยื่นคำขอเอาประกันภัยกับบริษัทประกันวินาศภัย
            และต้องจัดให้ผู้เอาประกันภัยแสดงตนและตรวจสอบความถูกต้องแท้จริงของข้อมูลนั้น ตามหลักเกณฑ์
            วิธีการ
            ที่กำหนดไว้ในกฎหมายว่าด้วยการป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้ายและการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง
            และกฎหมายว่าด้วยการป้องกันและปราบปรามการฟอกเงิน ทั้งนี้
            ให้นายหน้ามีอำนาจรับเบี้ยประกันภัยในนามของบริษัทได้ก็ต่อเมื่อได้รับมอบอำนาจจากบริษัทเป็นลายลักษณ์อักษรจากบริษัทเท่านั้น
          </p>
          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 2.
            นายหน้าตกลงรับการแต่งตั้งเป็นนายหน้าประกันวินาศภัยของบริษัท
            โดยตกลงที่จะปฏิบัติตามหน้าที่ที่กล่าวในข้อ 1. และตามที่กล่าวต่อไปในสัญญานี้
            รวมทั้งจะปฏิบัติตามเงื่อนไข ระเบียบ ข้อบังคับของบริษัททั้งที่ใช้อยู่ในวันที่ทำสัญญา
            และที่จะออกมาใช้บังคับต่อไปภายหลังวันที่ทำสัญญานี้รวมถึงกฎหมายประกันวินาศภัย ประกาศ
            ระเบียบ ข้อบังคับของคำสั่งของสำนักงาน คณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย
            (คปภ.) และหรือกฎระเบียบ กฎหมายอื่นๆ ที่เกี่ยวข้องกับนายหน้าประกันวินาศภัย ที่ใช้อยู่ ณ
            วันทำสัญญา และที่จะประกาศใช้ในอนาคตและกฎระเบียบที่เกี่ยวข้องอย่างเคร่งครัดทุกประการ
          </p>
          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 3.
            นายหน้าไม่มีอำนาจออกกรมธรรม์ประกันภัยไม่มีอำนาจแก้ไขเปลี่ยนแปลง เพิ่มเติม
            หรือตัดทอนข้อกำหนดหรือเงื่อนไขของกรมธรรม์ประกันภัยที่บริษัทประกันวินาศภัยได้ออกให้แก่ผู้เอาประกันภัยหรือเอกสารอื่นใดที่เป็นของบริษัทประกันวินาศภัยและของบริษัท
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 4.
            ทั้งสองฝ่ายตกลงกันว่าความเกี่ยวพันระหว่างนายหน้ากับบริษัทตามสัญญานี้
            ไม่ใช่เป็นไปในฐานะตัวการกับตัวแทน และไม่ใช่ในฐานะนายจ้างกับลูกจ้าง
            นายหน้าเป็นแต่เพียงผู้ชี้ช่องหรือจัดการให้มีการทำสัญญาประกันภัยกับบริษัทประกันวินาศภัย
            โดยทำเพื่อมุ่งหวังที่จะได้รับค่าตอบแทนหรือค่าบำเหน็จเท่านั้น การชี้ช่อง การกระทำ
            หรือการละเว้นการกระทำใดๆ ของนายหน้าจะไม่มีผลผูกพันใดกับบริษัททั้งสิ้น
            เว้นแต่การกระทำดังกล่าวอยู่ภายใต้ขอบเขตของสัญญานี้
            และถูกต้องตามพระราชบัญญัติประกันวินาศภัย
            ประกาศคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย ที่ประกาศใช้อยู่ ณ
            วันทำสัญญาและที่จะประกาศใช้ในอนาคต และกฎระเบียบที่เกี่ยวข้องทุกประการ
            และบริษัทประกันวินาศภัยได้พิจารณาและอนุมัติทำสัญญาประกันภัยกับบุคคลที่นายหน้าได้ชี้ช่องนั้นครบถ้วนแล้ว
          </p>
          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 5.
            นายหน้าตกลงชี้ช่องหรือจัดการให้ผู้เอาประกันภัยซื้อแบบประกันวินาศภัยของ
            บริษัทประกันวินาศภัยตามที่บริษัทกำหนด จะต้องไม่แสดงข้อความอันเป็นเท็จ
            หรือปกปิดข้อความจริงเกี่ยวกับทรัพย์สินของผู้เอาประกันภัยที่จะเอาประกันภัย
            และจะต้องใช้ข้อความหรือภาพที่โฆษณา หรือหนังสือ ชี้ชวนให้ผู้เอาประกันภัยทำสัญญาประกันภัย
            ที่เป็นแบบฟอร์มของบริษัทหรือบริษัทประกันวินาศภัยหรือที่ได้รับความเห็นชอบเป็นลายลักษณ์อักษรจากบริษัทหรือบริษัทประกันวินาศภัยและตกลงจัดส่งหลักฐานการแสดงเจตนาขอเอาประกันภัยและเอกสารอื่นใดที่ใช้ประกอบกับการขอเอาประกันภัย
            (ถ้ามี) ให้กับบริษัทในโอกาสแรกที่กระทำได้ แต่ไม่เกินวันทำการถัดไป
            ส่วนเงินค่าเบี้ยประกันภัยตกลงรับชำระโดยการโอนเข้าบัญชีของบริษัทและใช้เอกสารแสดงการรับเงินที่บริษัทจัดเตรียมให้เท่านั้น
            หากนายหน้าผิดสัญญาข้อนี้ และทำให้บริษัทต้องรับผิดชดใช้ค่าเสียหายให้แก่ผู้เอาประกันภัย
            หรือผู้รับประโยชน์ตามกรมธรรม์ หรือบริษัทประกันวินาศภัย หรือบุคคลใดๆ
            จากการผิดสัญญาของนายหน้าดังกล่าวเป็นเงินจำนวนเท่าใดก็ตาม
            นายหน้าตกลงชดใช้ค่าเสียหายให้แก่บริษัททั้งสิ้นทันทีที่ได้รับแจ้งจากบริษัทเป็นลายลักษณ์อักษร
          </p>
          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 6.
            ในกรณีที่บริษัทจัดให้นายหน้าส่งข้อมูลผู้เอาประกันภัย
            ข้อมูลทรัพย์สินที่เอาประกันภัยผ่านระบบคอมพิวเตอร์ ซึ่งบริษัทจะมอบรหัส (Password และ User
            Name) ให้กับนายหน้าเพื่อเข้าถึงระบบคอมพิวเตอร์ของบริษัท นายหน้าตกลงว่าจะรักษารหัส
            (Password และ User Name) ไว้เป็นความลับ จะไม่เปิดเผยรหัส (Password และ User Name)
            ให้กับบุคคลอื่นได้ล่วงรู้เป็นอันขาดและตกลงว่าข้อมูลต่างๆ
            ที่ส่งให้กับบริษัทผ่านระบบคอมพิวเตอร์ โดยใช้รหัส (Password และ User Name) ดังกล่าว
            เป็นการจัดส่งโดยนายหน้าทั้งสิ้น และนายหน้าตกลงให้นำความในข้อ 5.
            มาใช้กับการจัดส่งข้อมูลผ่านระบบคอมพิวเตอร์นี้ด้วยโดยนายหน้าตกลงรับผิดต่อบริษัท
            จากความเสียหายใดๆ ที่เกิดขึ้นจากการจัดส่งข้อมูลที่ไม่ถูกต้องตามความเป็นจริงทั้งสิ้น
            ทันทีที่บริษัทเรียกร้อง
          </p>
          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 7.
            นายหน้าตกลงที่จะรักษาและไม่เปิดเผยข้อความหรือข้อมูลใดๆ ในสัญญาฉบับนี้
            ตลอดจนเอกสารและข้อมูลใดๆ ที่ได้รับจากบริษัทอันเนื่องมาจากการปฏิบัติงานภายใต้สัญญานี้
            รวมทั้งข้อมูลของผู้เอาประกันภัยไว้เป็นความลับที่สุด และจะไม่ใช้
            หรือหาประโยชน์จากข้อมูลดังกล่าว หรือเปิดเผยข้อมูลดังกล่าวต่อบุคคลอื่น
            เว้นแต่จะได้รับความยินยอมจากบริษัทล่วงหน้าเป็นลายลักษณ์อักษร
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การสิ้นสุดสัญญาไม่ว่ากรณีใดๆ
            ไม่ส่งผลกระทบต่อหน้าที่ของนายหน้าที่ระบุไว้ในวรรคก่อนหน้า
            และนายหน้าตกลงให้เป็นสิทธิของบริษัทที่จะใช้หรือหาประโยชน์จากข้อมูลดังกล่าวแต่เพียงผู้เดียว
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 8.
            เมื่อบริษัทได้รับเบี้ยประกันภัยอย่างครบถ้วน
            และบริษัทประกันวินาศภัยได้ตกลงทำสัญญาประกันภัย
            และได้ออกกรมธรรม์ประกันภัยแก่ผู้เอาประกันภัยตามที่นายหน้าเป็นผู้ชี้ช่องหรือจัดการ
            นายหน้ามีสิทธิได้รับค่าบำเหน็จและ/หรือผลประโยชน์จากผลงานประกันภัยตามข้อกำหนดการจ่ายค่าบำเหน็จและ/หรือผลประโยชน์อื่นๆ
            ตามอัตราและภายในเวลาที่บริษัทกำหนด
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัทมีสิทธิที่จะหักภาษีต่างๆ
            ออกจากค่าบำเหน็จ และ/หรือผลประโยชน์ใดๆ
            ที่บริษัทตกลงจ่ายให้แก่นายหน้าตามจำนวนเต็มตามที่กฎหมายหรือกฎระเบียบที่เกี่ยวข้องกับการหักภาษี
            ณ ที่จ่ายกำหนดไว้ หรือภาษีอื่นๆ ที่เกี่ยวข้อง (ถ้ามี)
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่ผู้เอาประกันภัยใช้สิทธิในการขอยกเลิกกรมธรรม์ภายในกำหนดเวลาตามที่ระบุไว้ในกรมธรรม์
            หรือบริษัทประกันวินาศภัยต้องยกเลิก
            หรือบอกล้างการประกันภัยรายใดภายหลังจากที่ยอมรับประกันภัยแล้ว ไม่ว่าด้วยเหตุผลใดก็ตาม
            และบริษัทต้องคืนเงินค่าบำเหน็จและ/หรือผลประโยชน์ตามกรมธรรม์ที่ยกเลิกหรือบอกล้างให้กับบริษัทประกันวินาศภัย
            นายหน้าตกลงคืนค่าบำเหน็จ
            และ/หรือผลประโยชน์ที่ตนได้รับจากบริษัททั้งหมดอันเนื่องจากการชี้ช่องรายนั้นให้แก่บริษัทตามกำหนดเวลาที่บริษัทกำหนด
            และ/หรือบริษัทจะหักเงินดังกล่าวออกจากเงินค่าบำเหน็จ
            และ/หรือผลประโยชน์อื่นใดที่จะต้องจ่ายให้แก่นายหน้าจากผลงานประกันภัยรายอื่นที่นายหน้าเป็นผู้ชี้ช่องหรือจัดการก็ได้
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่กรมธรรม์ของผู้เอาประกันภัยครบกำหนด/สิ้นสุดระยะเวลาประกันภัยแล้ว
            ผู้เอาประกันภัยแจ้งความประสงค์กับบริษัทโดยตรงเพื่อต่ออายุกรมธรรม์กับบริษัทประกันวินาศภัย
            และบริษัทไม่สามารถติดต่อนายหน้าตามภูมิลำเนาและช่องทางการติดต่อที่ได้ระบุไว้ในสัญญานี้ได้หรือผู้เอาประกันภัยไม่ได้ติดต่อแจ้งความประสงค์กับบริษัทเพื่อต่ออายุกรมธรรม์หรือนายหน้าไม่ดำเนินการให้ผู้เอาประกันภัยต่ออายุกรมธรรม์กับบริษัทประกันวินาศภัย
            ภายในกำหนด 7(เจ็ด)วัน
            นับถัดจากวันที่กรมธรรม์ครบกำหนด/สิ้นสุดความคุ้มครองนายหน้าตกลงให้บริษัทเป็นผู้ดำเนินการให้ผู้เอาประกันภัยต่ออายุกรมธรรม์ได้โดยตรง
            และตกลงให้บริษัทเป็นเจ้าของ ผลงานประกันภัยและเป็นผู้ให้บริการแก่ผู้เอาประกันภัยรายนั้นๆ
            ทั้งนี้ จากกรณีดังกล่าวข้างต้นนายหน้าตกลงไม่รับค่าบำเหน็จ
            และ/หรือผลประโยชน์จากผลงานประกันภัยนั้นจากบริษัทอีกต่อไป
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 9.
            เมื่อนายหน้าได้ก่อให้เกิดหนี้สินขึ้นกับบริษัทไม่ว่าจะโดยสาเหตุใด
            นายหน้าตกลงยินยอมให้บริษัทหักชำระหนี้สินนั้นจากค่าบำเหน็จและ/หรือผลประโยชน์อื่นใดที่นายหน้าจะพึงได้รับจากบริษัท
            โดยหักเอาไว้ก่อนได้ทั้งสิ้นทันที หากในช่วงระยะเวลาใดค่าบำเหน็จ
            และ/หรือผลประโยชน์มีไม่เพียงพอที่จะหักชำระหนี้
            นายหน้าตกลงชำระหนี้สินให้แก่บริษัทตามกำหนดเวลาที่ได้รับแจ้งจากบริษัทเป็นลายลักษณ์อักษร
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 10.
            บริษัททรงไว้ซึ่งสิทธิโดยเด็ดขาดที่จะงดจ่ายค่าบำเหน็จ
            และ/หรือผลประโยชน์อื่นใดที่จะคำนวณจ่าย ทั้งในปัจจุบันและ/หรือในอนาคต (ถ้ามี)
            ให้แก่นายหน้า เมื่อปรากฏเหตุหนึ่งเหตุใด ดังต่อไปนี้
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.1 เมื่อนายหน้า
            กระทำผิดสัญญานี้ข้อหนึ่งข้อใด
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.2เมื่อสัญญานี้สิ้นสุดลงไม่ว่ากรณีใดๆ
            ทั้งสิ้นการงดจ่ายค่าบำเหน็จ
            และ/หรือผลประโยชน์อื่นใดที่จะคำนวณจ่ายทั้งในปัจจุบันและ/หรือในอนาคต(ถ้ามี)
            ให้แก่นายหน้าตามวรรคก่อนไม่เป็นการตัดสิทธิบริษัทที่จะบอกเลิกสัญญานี้กับนายหน้า
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 11. เมื่อสัญญาสิ้นสุดไม่ว่ากรณีใดๆ
            ทั้งสองฝ่ายตกลงว่าค่าบำเหน็จและ/
            หรือผลประโยชน์ที่จะจ่ายให้แก่นายหน้านั้นสิ้นสุดในวันที่สัญญาสิ้นสุดลงด้วย
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 12.
            บริษัทหรือนายหน้าฝ่ายหนึ่งฝ่ายใดมีสิทธิบอกเลิกสัญญานี้ได้ เมื่อได้
            บอกกล่าวล่วงหน้าเป็นลายลักษณ์อักษรให้อีกฝ่ายหนึ่งทราบล่วงหน้าไม่น้อยกว่า 15 (สิบห้า) วัน
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 13.
            บริษัทมีสิทธิบอกเลิกสัญญานี้ได้โดยไม่ต้องบอกกล่าวล่วงหน้าเมื่อปรากฏ
            เหตุอย่างหนึ่งอย่างใดดังต่อไปนี
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13.1 เมื่อนายหน้ากระทำผิดสัญญาข้อหนึ่งข้อใด
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13.2 เมื่อนายหน้ากระทำการใดๆ
            ที่เป็นการอันไม่สุจริตหรือประพฤติผิดแบบธรรมเนียมหรือฝ่าฝืนระเบียบแบบ แผนปฏิบัติ
            หน้าที่ของนายหน้า
            หรือบริษัทเห็นว่าหากจะยังคงให้นายหน้าปฏิบัติหน้าที่ต่อไปจะเป็นการเสียหายแก่บริษัท
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13.3
            เมื่อนายหน้าได้กระทำการที่เกิดหรืออาจจะเกิดความเสียหายแก่ผู้เอาประกันภัย
            หรือผู้รับประโยชน์ หรือบริษัทประกันวินาศภัย หรือบริษัท
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13.4 นายหน้าถูกพิทักษ์ทรัพย์
            หรือตกเป็นบุคคลล้มละลาย หรือถูกฟ้องเป็นคดีล้มละลาย
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13.5นายหน้าขาดคุณสมบัติตามที่กำหนดหรือปฏิบัติหรือละเว้นการปฏิบัติอันเป็นการฝ่าฝืนบทบัญญัติ
            แห่งพระราชบัญญัติประกันวินาศภัย ตามกฎ ประกาศ หรือคำสั่งของนายทะเบียนที่เกี่ยวข้อง
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13.6 นายทะเบียนประกันวินาศภัย
            ไม่ต่อใบอนุญาตเป็นนายหน้าประกันวินาศภัย
            หรือนายหน้าไม่ต่อใบอนุญาตเป็นนายหน้าประกันวินาศภัย
            หรือใบอนุญาตเป็นนายหน้าประกันวินาศภัยถูกเพิกถอน
            ในกรณีเช่นว่านี้ให้ถือว่าสัญญานี้สิ้นสุดลงในวันที่ไม่มีการต่อใบอนุญาต
            หรือวันที่ใบอนุญาตเป็นนายหน้าประกันวินาศภัยถูกเพิกถอน
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ 14.
            นายหน้ารับทราบข้อกำหนดและเงื่อนไขวิธีการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล
            นโยบายความเป็นส่วนตัวของบริษัทและกฎหมายคุ้มครองข้อมูลส่วนบุคคลแล้วทุกประการ
            หากนายหน้ามิได้ปฏิบัติให้เป็นไปตามข้อกำหนดและเงื่อนไข นโยบายและกฎหมายดังกล่าว
            รวมถึงที่จะมีการแก้ไข และ/หรือที่จะประกาศใช้บังคับต่อไปในภายหน้า
            นายหน้ายินยอมรับผิดและชดใช้ค่าเสียหายให้กับบริษัทและเจ้าของข้อมูลในฐานะเป็นผู้ควบคุมข้อมูลส่วนบุคคลตามความในพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
            พ.ศ. 2562 (และตามที่จะได้มีการแก้ไขในภายหน้า) แต่เพียงผู้เดียวโดยจะไม่ใช้สิทธิโต้แย้งใดๆ
            ทั้งสิ้น
            และนายหน้าตกลงยินยอมชดใช้ค่าเสียหายทุกประเภทที่กฎหมายกำหนดให้แก่บริษัททั้งสิ้นทันทีที่ได้รับแจ้งจากบริษัทเป็นลายลักษณ์อักษร
            รวมทั้งให้บริษัทหักชำระหนี้จากค่าบำเหน็จและ/หรือผลประโยชน์อื่นใดที่นายหน้าจะพึงได้รับจากบริษัท
            โดยหักเอาไว้ก่อนได้ทั้งสิ้นทันที
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;โดยในส่วนของข้อกำหนดและเงื่อนไขวิธีการเก็บรวบรวม
            ใช้ และเปิดเผยข้อมูลส่วนบุคคลระหว่างนายหน้าและบริษัท
            ให้เป็นไปตามข้อกำหนดและเงื่อนไขในภาคผนวก ก
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สัญญาแต่งตั้งนายหน้าประกันวินาศภัยฉบับนี้
            ทำขึ้นสองฉบับ มีข้อความถูกต้องตรงกัน
            คู่สัญญาทั้งสองฝ่ายได้อ่านและเข้าใจข้อความตามสัญญานี้โดยตลอด
            และเห็นว่าตรงตามเจตนาของตนเองทุกประการแล้ว จึงได้ลงลายมือชื่อและประทับตรา (ถ้ามี)
            ไว้เป็นสำคัญต่อหน้าพยาน และต่างยึดถือไว้ฝ่ายละหนึ่งฉบับ
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สัญญาแต่งตั้งนายหน้าประกันวินาศภัยฉบับนี้
            ทำขึ้นสองฉบับ มีข้อความถูกต้องตรงกัน
            คู่สัญญาทั้งสองฝ่ายได้อ่านและเข้าใจข้อความตามสัญญานี้โดยตลอด
            และเห็นว่าตรงตามเจตนาของตนเองทุกประการแล้ว จึงได้ลงลายมือชื่อและประทับตรา (ถ้ามี)
            ไว้เป็นสำคัญต่อหน้าพยาน และต่างยึดถือไว้ฝ่ายละหนึ่งฉบับ
          </p>

          <h3 className="text-center my-16 text-secondary text-lg font-bold">
            นโยบายความเป็นส่วนตัวว่าด้วยข้อมูลส่วนบุคคลของนายหน้าประกันวินาศภัย
          </h3>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อท่านตกลงด้วยกับนโยบายความเป็นส่วนตัวว่าด้วยข้อมูลส่วนบุคคลของนายหน้าประกันวินาศภัยนี้
            ท่านได้อนุญาตให้ บริษัท เมืองไทย โบกเกอร์ จำกัด (“บริษัท”) เก็บรวบรวม ใช้
            ประมวลผลและเปิดเผยข้อมูลส่วนบุคคลของท่าน ซึ่งอาจรวมถึงข้อมูลส่วนบุคคลที่มีความอ่อนไหว
            (Sensitive Data) (“ข้อมูลส่วนบุคคล”) โดยข้อมูลส่วนบุคคล
            จะถูกเก็บรักษาในที่ปลอดภัยและเป็นความลับตามที่ได้กำหนดไว้ในนโยบายความเป็นส่วนตัวว่าด้วยข้อมูลส่วนบุคคลของนายหน้าประกันวินาศภัยฉบับนี้
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. หลักการเก็บข้อมูลส่วนบุคคล
            บริษัทจะเก็บรวบรวม ข้อมูลการปฏิบัติงานของท่าน
            ข้อมูลส่วนบุคคลของท่านตามข้อมูลที่ท่านได้ให้ไว้หรือได้มาในขณะที่ท่าน
            สมัครเป็นนายหน้าประกันวินาศภัย ในขั้นตอนการเข้ารับการอบรมหรือตามกรณีอื่น
            และระหว่างระยะเวลาสัญญาที่ท่านเป็นนายหน้าประกันวินาศภัยกับบริษัทและบริษัทอาจตรวจสอบและเก็บข้อมูลประวัติส่วนตัวของนายหน้าประกันวินาศภัยเพิ่มเติมในกรณีที่มีข้อสงสัยข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวมนั้นเป็นข้อมูลที่จำเป็นต่อบริษัทในการเข้าทำสัญญานายหน้าประกันวินาศภัยกับท่าน
            หรือเพื่อเป็นการปฏิบัติตามสัญญาและกฎหมายต่าง ๆ ที่ใช้บังคับ
            หากท่านไม่ให้ข้อมูลส่วนบุคคลตามที่ร้องขอดังกล่าว
            บริษัทอาจไม่สามารถบริหารหรือจัดการสัญญานายหน้าประกันวินาศภัยระหว่างท่านกับบริษัทได้
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. ประเภทของข้อมูลส่วนบุคคล
            ตัวอย่างของข้อมูลส่วนบุคคลที่บริษัทอาจเก็บรวบรวมประกอบไปด้วย:
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ก. ข้อมูลที่ใช้ระบุตัวบุคคล อาทิ ชื่อ สกุล
            ที่อยู่ รายละเอียดการติดต่ออื่น ๆ เช่น อีเมล เบอร์โทรศัพท์ เพศ สัญชาติ สถานภาพการสมรส
            วันเกิด เลขที่หนังสือเดินทาง/บัตรประจำตัวประชาชน/ใบอนุญาตนายหน้า ข้อมูลสุขภาพ
            ข้อมูลเกี่ยวกับผู้ที่อยู่ในการดูแลของท่าน และภาพถ่าย
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข.
            ข้อมูลเกี่ยวกับคุณวุฒิและประวัติการทำงานของท่าน อาทิ คุณวุฒิจากโรงเรีย/มหาวิทยาลัย
            หนังสือรับรองและหนังสืออ้างอิง อาชีพ
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ค. ข้อมูลเกี่ยวกับการเงินของท่าน อาทิ
            ข้อมูลบัญชีธนาคาร ลายมือชื่อ
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ง.ข้อมูลด้านกฎหมายและประวัติอาชญากรรมของท่าน
            เพื่อใช้ตรวจสอบว่าเป็นท่านเป็นบุคคลที่ต้องห้ามเป็นนายหน้าประกันวินาศภัยตามกฎหมายหรือไม่
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่ท่านได้ให้ข้อมูลส่วนบุคคลเกี่ยวกับบุคคลอื่นแก่บริษัท
            อาทิ สมาชิกในครอบครัว ญาติใกล้ชิด หรือเพื่อนของท่านเพื่อวัตถุประสงค์สำหรับเหตุฉุกเฉิน
            เพื่อจัดการผลประโยชน์ หรือการเข้าค้ำประกันท่าน
            ท่านรับทราบและยืนยันว่าได้รับความยินยอมของบุคคลนั้นในการเปิดเผยข้อมูลแก่บริษัทแล้ว
            และบุคคลดังกล่าวยินยอมให้บริษัทเก็บรวบรวมใช้
            และเปิดเผยข้อมูลส่วนบุคคลนั้นได้ตามวัตถุประสงค์ที่ได้กล่าวมา
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. การใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน
            ข้อมูลส่วนบุคคลของท่านจะถูกใช้และเปิดเผยเพื่อวัตถุประสงค์ในการพิจารณารับท่านเป็นนายหน้าประกันวินาศภัย
            การบริหารและจัดการนายหน้าประกันวินาศภัยและสัญญานายหน้าประกันวินาศภัย
            การบริหารจัดการบริการของบริษัทสำหรับงานการดำเนินงานภายในบริษัทและการดูแลลูกค้า
            การบริหารความสัมพันธ์ระหว่างบริษัทกับท่าน การตรวจสอบหรือยืนยันตัวตนของท่าน
            การดำเนินธุรกิจของบริษัท การปฏิบัติตามข้อบังคับที่กำกับดูแลบริษัท
            การปฏิบัติตามกฎหมายและกฎเกณฑ์ที่ใช้บังคับ การจ่ายผลประโยชน์ ค่าตอบแทน การติดต่อท่าน
            รวมถึงการตรวจสอบข้อมูลของท่าน
            ตรวจสอบข้อร้องเรียนและประเด็นของการประพฤติตัวไม่เหมาะสมและบันทึกข้อมูลนายหน้าประกันวินาศภัย
            ซึ่งเราจะใช้และเปิดเผยข้อมูลส่วนบุคคลของท่านเมื่อ (ก)
            ท่านได้ให้ความยินยอมไว้กับบริษัทตามกฎหมาย(โดยเฉพาะสำหรับข้อมูลส่วนบุคคลที่มีความอ่อนไหว)
            (ข) เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญา(ค)เป็นการปฏิบัติตามกฎหมาย (ง)
            เป็นประโยชน์โดยชอบด้วยกฎหมายของบริษัทหรือของบุคคลหรือนิติบุคคลอื่น
            (จ)บริษัทใช้สิทธิเรียกร้องตากฎหมาย หรือยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย (ฉ)
            เป็นการใช้/เปิดเผยเพื่อวัตถุประสงค์อื่นใดที่กฎหมายอนุญาต หรือ (ช)
            เป็นการใช้เพื่อทำการตลาดตามที่กฎหมายอนุญาต
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. การเปิดเผยข้อมูลส่วนบุคคลของท่าน
            บริษัทมีนโยบายและกระบวนการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
            และบริษัทจำกัดสิทธิการเข้าถึงข้อมูลส่วนบุคคลของท่านให้กับเฉพาะบุคคลที่จำเป็นต้องใช้ข้อมูลของท่าน
            อย่างไรก็ตามข้อมูลส่วนบุคคลของท่านอาจมีการเปิดเผยไปยังกลุ่มบุคคล ได้แก่ ผู้บริหารบริษัท
            หัวหน้าสังกัดนายหน้าประกันวินาศภัย พนักงานบริษัท
            สำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย (คปภ.) สมาคมประกันวินาศภัยไทย
            โรงพยาบาล หน่วยงานที่มีหน้าที่บังคับใช้กฎหมาย คณะกรรมการต่าง ๆ ที่จัดตั้งขึ้นตามกฎหมาย
            หน่วยงานรัฐหรือหน่วยงานกำกับดูแล พันธมิตรธุรกิจของบริษัท นายหน้า บริษัทประกันภัยต่อ
            มูลนิธิ
            และผู้รับจ้างให้บริการแก่บริษัท(ทั้งนี้แม้ว่าท่านอาจจะยุติความสัมพันธ์กับบริษัทแล้ว)
            ในกรณีที่บริษัทมีการเข้าทำสัญญากับบุคคลที่สามหรือผู้รับจ้างให้บริการแก่บริษัท
            บริษัทจะกำหนดมาตรการรักษาความปลอดภัยข้อมูลส่วนบุคคล
            การรักษาข้อมูลที่เป็นความลับที่เหมาะสม ทั้งนี้
            เพื่อให้มั่นใจว่าข้อมูลส่วนบุคคลของท่านจะได้รับความปลอดภัย
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. การเก็บรักษาข้อมูลส่วนบุคคล
            บริษัทมีนโยบายที่จะเก็บรักษาข้อมูลส่วนบุคคลตราบเท่าที่จำเป็นเพื่อบรรลุวัตถุประสงค์ในการเก็บรวบรวมข้อมูลโดยบริษัท
            หรือโดยข้อมูลที่ท่านได้ให้ไว้เท่านั้น โดยทั่วไปแล้ว
            ระยะเวลาการเก็บรวบรวมข้อมูลของท่านคือ 12 ปี นับแต่ได้มีการเลิก
            หรือสิ้นสุดสัญญานายหน้าประกันวินาศภัย
            หรืออาจเก็บรวบรวมเป็นระยะเวลาตราบเท่าที่กฎหมายที่ใช้บังคับอาจกำหนดหรืออนุญาต
            (ทั้งนี้แม้ว่าท่านอาจจะยุติความสัมพันธ์กับบริษัทแล้ว)
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. สิทธิในข้อมูลส่วนบุคคลของท่าน
            ท่านมีสิทธิตรวจสอบว่าบริษัทเก็บรวบรวมข้อมูลส่วนบุคคลของท่านหรือไม่
            ท่านมีสิทธิที่จะเข้าถึง
            หรือขอรับสำเนาข้อมูลส่วนบุคคลของท่านที่อยู่ในความครอบครองของบริษัท
            ท่านมีสิทธิขอทราบรายละเอียดว่าบริษัทใช้และเปิดเผยข้อมูลส่วนบุคคลของท่านอย่างไร
            ท่านมีสิทธิขอให้บริษัทแก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง หรือทำให้เป็นปัจจุบัน
            ท่านมีสิทธิขอให้บริษัทส่งออก หรือโอนข้อมูลส่วนบุคคลของท่านให้แก่ท่าน
            หรือผู้ควบคุมข้อมูลส่วนบุคคลอื่นในรูปแบบที่สามารถอ่านได้โดยง่ายด้วยเครื่องมืออุปกรณ์
            ท่านมีสิทธิขอให้บริษัทระงับการใช้ข้อมูลส่วนบุคคลของท่านชั่วคราวในบางสถานการณ์
            ท่านมีสิทธิขอให้บริษัทลบหรือทำลายข้อมูลส่วนบุคคลของท่านในบางสถานการณ์
            ท่านมีสิทธิขอให้บริษัทดำเนินการทำให้ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ในบางสถานการณ์
            ท่านมีสิทธิคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านในบางสถานการณ์
            สิทธิในการขอให้บริษัทเปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลที่เกี่ยวกับท่านในกรณีที่เป็นข้อมูลซึ่งท่านไม่ได้ให้ความยินยอมในการรวบรวมหรือจัดเก็บ
            ท่านมีสิทธิขอทราบความมีอยู่ ลักษณะของข้อมูลส่วนบุคคล
            และวัตถุประสงค์ของการนำข้อมูลส่วนบุคคลไปใช้ของบริษัท
            และท่านมีสิทธิในการขอเพิกถอนความยินยอมข้อมูลส่วนบุคคลของท่านโดยเฉพาะอย่างยิ่งในกรณีที่ข้อมูลส่วนบุคคลของท่านได้ถูกเก็บรวบรวม
            ใช้ หรือเปิดเผยเพื่อวัตถุประสงค์เกี่ยวกับการตลาดแบบตรง
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การเปลี่ยนแปลงขอบเขตการให้ความยินยอมของท่าน
            การเพิกถอนความยินยอม หรือเปลี่ยนแปลงขอบเขตการให้ความยินยอมของท่าน
            อาจมีผลทำให้บริษัทไม่สามารถบริหารจัดการหรือดำเนินต่อซึ่งสัญญานายหน้าประกันวินาศภัยระหว่างท่านกับบริษัทได้
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ท่านสามารถส่งคำร้องขอเข้าถึงข้อมูล ขอแก้ไข
            และข้อร้องเรียน หรือคำร้องขออื่น ๆ เกี่ยวกับข้อมูลส่วนบุคคลของท่านที่
          </p>

          <p className="font-light mb-4">
            เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล ตามข้อมูลการติดต่อด้านล่างนี้
          </p>

          <p className="font-bold mb-4">
            252/73 อาคาร เมืองไทย-ภัทร คอมเพล็กซ์ Tower B ชั้น 6 ถนนรัชดาภิเษก
            แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร 10310 Email: hero@gettgo.com
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัทสงวนสิทธิ์ในการแก้ไขประกาศนโยบายความเป็นส่วนตัวว่าด้วยข้อมูลส่วนบุคคลของนายหน้าประกันวินาศภัยนี้ได้ตลอดเวลาและบริษัทจะนำข้อมูลใหม่มาปรับปรุงบนหน้าเว็บไซต์
            หรือตามช่องทางนายหน้าประกันวินาศภัยตามที่บริษัทเห็นว่าเหมาะสม
            นโยบายความเป็นส่วนตัวว่าด้วยข้อมูลส่วนบุคคลของนายหน้าประกันวินาศภัยนี้ปรับปรุงครั้งล่าสุดเมื่อวันที่
            31 มีนาคม 2563
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัทสงวนสิทธิ์ในการแก้ไขประกาศนโยบายความเป็นส่วนตัวว่าด้วยข้อมูลส่วนบุคคลของนายหน้าประกันวินาศภัยนี้ได้ตลอดเวลาและบริษัทจะนำข้อมูลใหม่มาปรับปรุงบนหน้าเว็บไซต์
            หรือตามช่องทางนายหน้าประกันวินาศภัยตามที่บริษัทเห็นว่าเหมาะสม
            นโยบายความเป็นส่วนตัวว่าด้วยข้อมูลส่วนบุคคลของนายหน้าประกันวินาศภัยนี้ปรับปรุงครั้งล่าสุดเมื่อวันที่
            31 มีนาคม 2563
          </p>

          <h3 className="text-center my-16 text-secondary text-lg font-bold">
            ภาคผนวก ก แนบท้ายสัญญา <br />
            ข้อกำหนดและเงื่อนไขวิธีการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล
          </h3>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เพื่อเป็นการปฏิบัติตามกฎหมายว่าด้วยข้อมูลส่วนบุคคล
            คู่สัญญาทั้งสองฝ่ายตกลงที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขใน ภาคผนวก ก
            แนบท้ายสัญญาฉบับนี้
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;คู่สัญญาตกลงให้ ภาคผนวก ก
            แนบท้ายสัญญาฉบับนี้เป็นส่วนหนึ่งของสัญญา
            หากมีข้อสัญญาหรือคำนิยามใดที่ขัดหรือแย้งกันระหว่างสัญญาและภาคผนวก ก แนบท้ายสัญญา
            ให้ข้อสัญญาและคำนิยามในภาคผนวก ก
            แนบท้ายสัญญานี้มีผลใช้บังคับเหนือข้อสัญญาหรือคำนิยามในสัญญา
          </p>

          <p className="font-light mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;นอกจากที่จะได้นิยามไว้ในภาคผนวก ก
            แนบท้ายสัญญานี้
            ให้คำนิยามมีความหมายตามที่ได้ระบุไว้ในสัญญาคู่สัญญาทั้งสองฝ่ายตกลงกันดังนี้
          </p>

          <p className="font-bold">1. คำนิยาม</p>

          <ol className="ml-10 font-light">
            <li className="mb-5">
              1.1 “กฎหมายคุ้มครองข้อมูลส่วนบุคคล” หมายถึง (ก) กฎหมาย กฎระเบียบ กฎเกณฑ์ ประกาศ
              รวมถึงแต่ไม่จำกัดเพียงพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              (และตามที่จะได้มีการแก้ไขในภายหน้า) (ข) กฎ ระเบียบ
              ที่จะออกภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              โดยคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล หรือหน่วยงานอื่นใดที่มีอำนาจ (ค)
              พระราชบัญญัติความมั่นคงปลอดภัยไซเบอร์ พ.ศ. 2562
              รวมถึงกฎหมายอื่นใดที่จำต้องนำมาปรับใช้ร่วมกับกฎหมาย กฎ ระเบียบข้างต้น
              หรือใช้สำหรับการประมวลผลข้อมูลส่วนบุคคล
            </li>
            <li className="mb-5">
              1.2 “ข้อมูลส่วนบุคคลที่มีความอ่อนไหวเป็นพิเศษ” หมายถึง
              ข้อมูลส่วนบุคคลเกี่ยวกับเชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ
              ศาสนา หรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ
              ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ หรือข้อมูลอื่นใด
              ซึ่งกระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนด
            </li>
            <li className="mb-5">
              1.3 “เจ้าของข้อมูลส่วนบุคคล” หมายถึง ลูกค้าของคู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคล
              และบุคคลอื่นๆ ซึ่งเป็นเจ้าของข้อมูลส่วนบุคคล
            </li>
            <li className="mb-5">
              1.4 คำว่า “ข้อมูลส่วนบุคคล” “ผู้ควบคุมข้อมูลส่วนบุคคล” “ผู้ประมวลผลข้อมูลส่วนบุคคล”
              ให้มีความหมายตามที่กำหนดไว้ในกฎหมายคุ้มครองข้อมูลส่วนบุคคล
            </li>
            <li className="mb-5">
              1.5 “เหตุละเมิดข้อมูลส่วนบุคคล” หมายถึง (ก) การที่ข้อมูลส่วนบุคคลรั่วไหล หรือสูญหาย
              หรือถูกทำลาย หรือการเข้าถึงโดยไม่มีอำนาจหรือโดยมิชอบด้วยกฎหมาย
              ทั้งที่เจตนาหรือไม่เจตนา รวมถึง (ข) การเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลการแก้ไขหรือการประมวลผลข้อมูลส่วนบุคคล โดยไม่มีสิทธิ
              หรือผิดวัตถุประสงค์ (ค)
              การปฏิเสธสิทธิของเจ้าของข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคลพึงมีตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลโดยไม่มีเหตุอันพึงกล่าวอ้างได้ตามกฎหมาย
              หรือ (ง) การกระทำอื่นใดที่ขัดต่อกฎหมาย
            </li>
            <li className="mb-5">
              1.6 “คู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคล” หมายถึง คู่สัญญาที่โอน ส่งต่อ
              หรือกระทำการด้วยประการใดๆ อันเป็นการเปิดเผยข้อมูลส่วนบุคคล
            </li>
            <li className="mb-5">
              1.7 “คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคล” หมายถึง
              คู่สัญญาที่รับข้อมูลส่วนบุคคลจากคู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคล
            </li>
            <li className="mb-5">
              18 “สัญญา” หมายถึง สัญญาแต่งตั้งนายหน้าประกันวินาศภัย รวมทั้ง ภาคผนวก ก เอกสารแนบท้าย
              เอกสารแนบท้ายภาคผนวก และเอกสารหรือข้อตกลงอื่นใดที่คู่สัญญาตกลงให้เป็นส่วนหนึ่งของสัญญา
            </li>
          </ol>

          <p className="font-bold">2. การโอนหรือเปิดเผยข้อมูลส่วนบุคคล</p>

          <p className="font-light mb-5">
            รายละเอียดและประเภทของข้อมูลส่วนบุคคลที่จะโอนหรือเปิดเผย
            รวมถึงวัตถุประสงค์ในการโอนหรือเปิดเผยข้อมูลส่วนบุคคลดังกล่าวเป็นไปตามที่ระบุไว้ในเอกสารแนบท้ายภาคผนวก
            <strong>(“รายละเอียดการโอนหรือส่งข้อมูลส่วนบุคคล”)</strong>
          </p>

          <p className="font-bold">
            3. หน้าที่ของคู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคล
            <span className="font-light ml-1">
              คู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลตกลงและรับรองว่า
            </span>
          </p>

          <p className="font-light mb-5">
            3.1
            ข้อมูลส่วนบุคคลที่คู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลจะทำการโอนหรือส่งข้อมูลส่วนบุคคลนั้น
            เป็นข้อมูลส่วนบุคคลที่คู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลเก็บรวบรวม ใช้
            และเปิดเผยได้ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
          </p>

          <p className="font-light mb-5">
            3.2
            เจ้าของข้อมูลส่วนบุคคลได้ให้ความยินยอมหรือได้รับทราบการโอนหรือส่งข้อมูลส่วนบุคคลตามสัญญานี้
            และได้ทราบถึงนโยบายความเป็นส่วนตัวของคู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลแล้ว
          </p>

          <p className="font-light mb-5">
            3.3 ก่อนการโอนหรือส่งข้อมูลส่วนบุคคลตามสัญญานี้
            มีการดำเนินการตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลแล้ว
            และในกรณีที่เป็นการส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศที่มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลต่ำกว่ามาตรฐานที่กำหนดไว้ในกฎหมายคุ้มครองข้อมูลส่วนบุคคลมีการแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบถึงมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลของประเทศปลายทางที่รับข้อมูลส่วนบุคคลนั้นและเจ้าของข้อมูลส่วนบุคคลได้ให้ความยินยอมไว้แล้ว
          </p>

          <p className="font-bold">
            4. หน้าที่ของคู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคล
            <span className="font-light ml-1">
              คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลตกลงและรับรองว่า
            </span>
          </p>

          <p className="font-light mb-5">
            4.1 สามารถปฏิบัติตามสัญญานี้และกฎหมายคุ้มครองข้อมูลส่วนบุคคลอย่างเคร่งครัด
            ในกรณีที่มีการเปลี่ยนแปลงกฎหมายคุ้มครองข้อมูลส่วนบุคคลซึ่งอาจส่งผลให้คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลไม่สามารถปฏิบัติตามสัญญานี้หรือกฎหมายคุ้มครองข้อมูลส่วนบุคคลได้
            คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลต้องแจ้งให้คู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลทราบโดยทันที
            ในกรณีดังกล่าว
            คู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลมีสิทธิระงับการโอนหรือส่งข้อมูลส่วนบุคคลตามสัญญานี้
            และคู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลต้องระงับการใช้ข้อมูลส่วนบุคคลที่ได้รับนั้นโดยทันที
          </p>

          <p className="font-light mb-5">
            4.2
            ในกรณีที่คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลอยู่ในประเทศที่ไม่มีกฎหมายคุ้มครองข้อมูลส่วนบุคคลหรือเป็นบุคคลที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลไม่ใช้บังคับ
            คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลจะต้องปฏิบัติตามกฎหมายที่เกี่ยวข้องกับการคุ้มครองข้อมูลส่วนบุคคลที่ใช้บังคับในประเทศหรืออาณาเขตที่คู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลนั้นตั้งอยู่
          </p>

          <p className="font-light mb-5">
            4.3 จะเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลที่ได้รับตามวัตถุประสงค์ที่ได้ระบุในสัญญาและในเอกสารแนบท้ายภาคผนวก
            และตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
            และจะต้องเก็บรวบรวมข้อมูลส่วนบุคคลนั้นเท่าที่จำเป็นเพื่อวัตถุประสงค์ดังกล่าวเท่านั้น
          </p>

          <p className="font-light mb-5">
            4.4 จะเคารพสิทธิของเจ้าของข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
          </p>

          <p className="font-light mb-5">
            4.5
            ในกรณีที่คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลต้องโอนหรือส่งข้อมูลส่วนบุคคลที่ได้รับจากคู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลต่อไปยังประเทศอื่นที่มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคล
            และ/หรือ
            กฎหมายคุ้มครองข้อมูลส่วนบุคคลที่มีมาตรฐานน้อยกว่าที่กำหนดไว้ในกฎหมายคุ้มครองข้อมูลส่วนบุคคล
            คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลจะต้องเข้าทำสัญญากับบุคคล/องค์กร/หน่วยงานที่จะรับโอนข้อมูลส่วนบุคคลดังกล่าว
            โดยกำหนดหน้าที่และมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลไม่ต่ำกว่าที่กำหนดในสัญญานี้
          </p>

          <p className="font-light mb-5">
            4.6
            จะไม่เปิดเผยข้อมูลส่วนบุคคลที่ได้รับจากคู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลภายใต้สัญญานี้
            แก่ลูกจ้าง กรรมการ ตัวแทน คู่สัญญา บริษัทในเครือ หรือบุคคลอื่นใด ยกเว้นการเปิดเผยแก่
          </p>

          <p className="font-light mb-5 ml-8">
            (ก) บุคลากรของคู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคล องค์กร
            หรือหน่วยงานที่เกี่ยวข้องโดยตรงและมีความจำเป็นอย่างยิ่งที่จะต้องได้รับการเปิดเผยข้อมูลส่วนบุคคลเพื่อให้สามารถปฏิบัติตามสัญญาได้
            โดยจะเป็นการเปิดเผยเท่าที่แต่ละบุคคล องค์กร หรือหน่วยงานดังกล่าวจำเป็นต้องทราบเท่านั้น
            (need-to-know basis) หรือ
          </p>

          <p className="font-light mb-5 ml-8">
            (ข) เมื่อได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล
            หากเป็นข้อมูลส่วนบุคคลที่มีความอ่อนไหวเป็นพิเศษ
          </p>

          <p className="font-light mb-5">
            4.7
            จะต้องจัดให้มีระบบการตรวจสอบเพื่อดำเนินการทำลายหรือลบข้อมูลส่วนบุคคลเมื่อพ้นกำหนดระยะเวลาอันจำเป็นในการเก็บข้อมูลส่วนบุคคลตามวัตถุประสงค์ของสัญญา
            หรือเมื่อได้รับคำร้องขอจากเจ้าของข้อมูลส่วนบุคคล
            หรือเมื่อเจ้าของข้อมูลส่วนบุคคลได้ถอนความยินยอม
          </p>

          <p className="font-light mb-5">
            4.8
            จะต้องจัดให้มีระบบรักษาความมั่นคงปลอดภัยที่รัดกุมเพื่อป้องกันการละเมิดข้อมูลส่วนบุคคลที่ได้รับหรือส่งต่อภายใต้สัญญานี้
            โดยระบบรักษาความมั่นคงปลอดภัยขั้นต่ำ ได้แก่ การมี
          </p>

          <p className="font-light mb-5 ml-8">
            (ก) กระบวนการปกปิดหรือปิดบังข้อมูลซึ่งไม่ใช่การเข้ารหัส
            แต่เป็นกระบวนการพิเศษเพื่อทำให้ข้อมูลนั้นแสดงเป็นข้อมูลหลอกหรือนามแฝง
            เพื่อปกปิดข้อมูลจริง
            โดยจะสามารถอ่านข้อมูลนั้นได้เมื่อมีการใช้ข้อมูลเพิ่มเติมประกอบเท่านั้น
            (pseudonymization)
          </p>

          <p className="font-light mb-5 ml-8">
            (ข) ระบบและมาตรการที่มีประสิทธิภาพ เพื่อป้องกันการเข้าถึงข้อมูลส่วนบุคคลโดยไม่มีสิทธิ
          </p>

          <p className="font-light mb-5 ml-8">
            (ค) ระบบและมาตรการที่มีประสิทธิภาพที่สามารถดึงข้อมูลส่วนบุคคลที่รับ และ/หรือ ส่งต่อ
            ภายใต้สัญญานี้ได้ภายในเวลาอันสมควร (แต่ไม่เกิน 24 ชั่วโมง) และ
          </p>

          <p className="font-light mb-5 ml-8">
            (ง) ระบบที่มีการตรวจสอบ และประเมินผลสัมฤทธิ์ของระบบรักษาความมั่นคงปลอดภัย
            โดยคู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคล
            มีสิทธิเรียกตรวจสอบระบบรักษาความมั่นคงปลอดภัยของคู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลได้ตลอดเวลา
          </p>

          <p className="font-bold">5. หน้าที่ของคู่สัญญา</p>

          <p className="font-light mb-5">
            5.1 การส่งหรือโอนข้อมูลส่วนบุคคลตามสัญญานี้
            ให้ส่งผ่านช่องทางตามที่ได้ระบุในสัญญาและในเอกสารแนบท้ายภาคผนวก เท่านั้น
            หากมีการเปลี่ยนแปลงแก้ไขช่องทางการส่งหรือโอนข้อมูลส่วนบุคคลในภายหลัง
            ให้คู่สัญญาทั้งสองฝ่ายทำการแก้ไขสัญญา/เอกสารแนบท้ายภาคผนวก เป็นลายลักษณ์อักษร
          </p>

          <p className="font-light mb-5">
            5.2 หากเจ้าของข้อมูลส่วนบุคคลต้องการยื่นคำขอใช้สิทธิใดๆ
            ที่เป็นสิทธิของเจ้าของข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
            รวมถึงการขอยกเลิกความยินยอมในการเก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล
            ให้คู่สัญญาฝ่ายที่ได้รับแจ้งจากเจ้าของข้อมูลส่วนบุคคล
            แจ้งคู่สัญญาอีกฝ่ายหนึ่งโดยไม่ชักช้าแต่ไม่เกิน 7 วัน นับแต่ได้รับคำขอ
            และให้คู่สัญญาทั้งสองฝ่ายระงับการใช้และเปิดเผยข้อมูลส่วนบุคคลนั้นไว้ชั่วคราวจนกว่าคู่สัญญาแต่ละฝ่ายจะพิจารณาคำขอเสร็จสิ้น
            เมื่อพิจารณาคำขอเสร็จสิ้นแล้ว (แต่ไม่เกิน 15 วัน นับแต่ได้รับแจ้งจากคู่สัญญาอีกฝ่าย)
            ให้คู่สัญญาฝ่ายที่ไม่ได้รับคำขอใช้สิทธิแจ้งผลการพิจารณาไปยังคู่สัญญาฝ่ายที่ได้รับคำขอใช้สิทธิ
            เพื่อดำเนินการแจ้งผลการพิจารณาต่อเจ้าของข้อมูลส่วนบุคคลต่อไป
          </p>

          <p className="font-light mb-5">
            5.3 หากมีข้อร้องเรียนจากเจ้าของข้อมูลส่วนบุคคลเกี่ยวกับเหตุละเมิดข้อมูลส่วนบุคคล
            หรือหากมีเหตุละเมิดข้อมูลส่วนบุคคล
            (รวมถึงกรณีมีข้อสงสัยว่าจะเกิดเหตุละเมิดข้อมูลส่วนบุคคล)
            เกี่ยวกับข้อมูลส่วนบุคคลที่มีการโอนและส่ง และ/หรือรับ ภายใต้สัญญานี้
            คู่สัญญาตกลงดำเนินการตามนี้
          </p>

          <p className="font-light ml-6">
            1. คู่สัญญาฝ่ายที่ได้รับข้อร้องเรียน
            หรือพบเหตุละเมิดข้อมูลส่วนบุคคลจะต้องแจ้งให้คู่สัญญาอีกฝ่ายทราบเป็นลายลักษณ์อักษรภายใน
            24 ชั่วโมง นับแต่ได้รับข้อร้องเรียน หรือนับแต่ทราบเหตุละเมิดข้อมูลส่วนบุคคล
            แล้วแต่เหตุใดจะเกิดก่อนกัน
          </p>

          <ul className="list-disc ml-12 font-light">
            <li>การแจ้งแก่ บริษัท ให้แจ้งที่ Email: hero@gettgo.com</li>
            <li>การแจ้งแก่นายหน้าประกันวินาศภัยให้แจ้งที่ Email: hero@gettgo.com</li>
          </ul>
          <p className="font-light ml-6 mb-4">
            ทั้งนี้ ให้แจ้งรายละเอียดของข้อร้องเรียน/เหตุละเมิดข้อมูลส่วนบุคคล ผลกระทบที่อาจเกิดขึ้น
            จำนวนเจ้าของข้อมูลส่วนบุคคล จำนวนข้อมูลส่วนบุคคล
            และประเภทข้อมูลส่วนบุคคลที่ได้รับผลกระทบ เจ้าของข้อมูลส่วนบุคคลที่เกี่ยวข้อง
            (ชื่อและนามสกุล)
            มาตรการที่ได้กระทำไปเพื่อบรรเทาผลร้ายจากการร้องเรียน/เหตุละเมิดข้อมูลส่วนบุคคล ด้วย
          </p>

          <p className="font-light ml-6 mb-4">
            2. หากข้อร้องเรียน หรือเกิดเหตุละเมิดข้อมูลส่วนบุคคลนั้น
            เกิดจากการไม่ปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
            หรือการไม่ปฏิบัติหน้าที่ของคู่สัญญาฝ่ายใด
            ให้คู่สัญญาฝ่ายนั้นเป็นฝ่ายรับผิดชอบต่อข้อร้องเรียน/เหตุละเมิดข้อมูลส่วนบุคคลนั้นๆ
            (“คู่สัญญาฝ่ายที่ต้องรับผิด”) อนึ่ง หลังจากทราบเหตุแล้ว
            ให้คู่สัญญาฝ่ายที่ได้รับข้อร้องเรียน/พบเหตุละเมิดข้อมูลส่วนบุคคล
            (หากไม่ใช่คู่สัญญาฝ่ายที่ต้องรับผิด)
            ส่งต่อข้อร้องเรียน/รายละเอียดเหตุละเมิดข้อมูลส่วนบุคคลนั้นแก่คู่สัญญาฝ่ายที่ต้องรับผิดภายในกำหนดเวลาที่ระบุในข้อ
            5.3 (ก) ข้างต้นนี้
          </p>

          <p className="font-light ml-6 mb-4">
            3. สำหรับกรณีเหตุละเมิดข้อมูลส่วนบุคคล
            คู่สัญญาฝ่ายที่ต้องรับผิดมีหน้าที่ต้องแจ้งเจ้าหน้าที่รัฐที่เกี่ยวข้องภายใน 72 ชั่วโมง
            นับแต่ทราบเหตุละเมิดข้อมูลส่วนบุคคล
            เว้นแต่เหตุนั้นไม่มีความเสี่ยงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของบุคคล
            อย่างไรก็ตามหากเป็นเหตุที่มีความเสี่ยงสูงที่จะกระทบต่อสิทธิและเสรีภาพของบุคคล
            ให้คู่สัญญาฝ่ายที่ต้องรับผิด4.
          </p>

          <p className="font-light ml-6 mb-4">
            4.
            แจ้งเหตุดังกล่าวแก่เจ้าของข้อมูลส่วนบุคคลพร้อมแนวทางการเยียวยาตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลด้วยให้คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลนำเสนอมาตรการการจัดการและการเยียวยาข้อร้องเรียน/เหตุละเมิดข้อมูลส่วนบุคคลนั้น
            และเมื่อคู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลเห็นสมควรด้วย
            ให้คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลปฏิบัติตามมาตรการดังกล่าวในทันที
            เพื่อที่จะหลีกเลี่ยงการเกิดคดีความหรือเพื่อยุติข้อร้องเรียน/เหตุละเมิดข้อมูลส่วนบุคคล
          </p>
          <p className="font-light ml-6 mb-4">
            5. คู่สัญญาทั้งสองฝ่ายจะให้ความร่วมมือ และช่วยเหลือกันในการจัดการกับข้อร้องเรียน
            และเหตุละเมิดข้อมูลส่วนบุคคล เพื่อป้องกันไม่ให้เกิดผลร้ายมากขึ้น
          </p>

          <p className="font-light ml-6 mb-4">
            6.
            คู่สัญญาฝ่ายที่ต้องรับผิดจะต้องชดใช้ค่าเสียหายให้แก่คู่สัญญาอีกฝ่ายหนึ่งสำหรับความเสียหายทุกประเภทที่เกิดแก่คู่สัญญาอีกฝ่ายหนึ่งและเจ้าของข้อมูลส่วนบุคคล
            อันเนื่องมาจากเหตุที่คู่สัญญาฝ่ายที่ต้องรับผิดไม่ปฏิบัติตามสัญญา
            หรือกฎหมายคุ้มครองข้อมูลส่วนบุคคล
          </p>

          <p className="font-light mb-4">
            5.4
            คู่สัญญาทั้งสองฝ่ายจะให้ความร่วมมือตามจำเป็นที่จะช่วยคู่สัญญาอีกฝ่ายหนึ่งให้สามารถปฏิบัติหน้าที่ของตนในฐานะผู้ควบคุมข้อมูลส่วนบุคคลตามความในกฎหมายคุ้มครองข้อมูลส่วนบุคคลได้
            และคู่สัญญาทั้งสองฝ่ายตกลงว่า หากมีความจำเป็นที่ต้องแก้ไขสัญญานี้
            เพื่อที่จะทำให้คู่สัญญาสามารถปฏิบัติตามวัตถุประสงค์ของสัญญาและตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลต่อไปได้
            คู่สัญญาทั้งสองฝ่ายจะเข้าเจรจากันโดยสุจริตใจเพื่อแก้ไขสัญญาตามที่จำเป็น
          </p>

          <p className="font-light mb-4">
            5.5
            คู่สัญญาแต่ละฝ่ายมีสิทธิที่จะว่าจ้างผู้ประมวลผลข้อมูลส่วนบุคคลเพื่อประมวลผลข้อมูลส่วนบุคคลที่ตนได้รับไว้ได้
            แต่จะต้องจัดให้มีสัญญาสำหรับการประมวลผลข้อมูลส่วนบุคคลนั้นเป็นลายลักษณ์อักษรโดยกำหนดหน้าที่ในการดูแลรักษาข้อมูลส่วนบุคคลที่มีมาตรฐานไม่ต่ำไปกว่าหน้าที่ตามสัญญานี้
            และตามที่ได้กำหนดในกฎหมายคุ้มครองข้อมูลส่วนบุคคล แต่อย่างไรก็ตาม
            คู่สัญญาแต่ละฝ่ายยังคงต้องรับผิดสำหรับการกระทำของผู้ประมวลผลข้อมูลส่วนบุคคลที่ตนว่าจ้างโดยไม่มีข้อโต้แย้งใดๆ
            ทั้งสิ้น
          </p>

          <p className="font-light mb-4">
            5.6 นอกเหนือจากหน้าที่ในการรักษาข้อมูลความลับแล้ว
            คู่สัญญาทั้งสองฝ่ายยังมีหน้าที่รักษาความลับของข้อมูลส่วนบุคคลอย่างเคร่งครัด
            และจะต้องจัดให้ผู้ที่เข้าถึงข้อมูลส่วนบุคคลรวมถึงบุคคลในข้อ 4.6
            และผู้ประมวลผลข้อมูลส่วนบุคคลตามข้อ 5.5 มีหน้าที่รักษาความลับของข้อมูลส่วนบุคคล
            และไม่กระทำการใดที่จะเป็นการขัดหรือแย้งกับกฎหมายคุ้มครองข้อมูลส่วนบุคคลอย่างเคร่งครัดด้วย
          </p>

          <p className="font-light">
            5.7
            คู่สัญญาทั้งสองฝ่ายจะต้องเก็บบันทึกการประมวลผลข้อมูลส่วนบุคคลในส่วนของข้อมูลส่วนบุคคลที่ได้รับมาตามสัญญานี้
            ตามหลักเกณฑ์ของกฎหมายคุ้มครองข้อมูลส่วนบุคคล อย่างไรก็ตาม
            ให้ระบุรายการดังต่อไปนี้ไว้ด้วย
          </p>

          <ol className="list-decimal font-light ml-6 mb-4">
            <li>ข้อมูลส่วนบุคคลที่ได้รับมา</li>
            <li>วัตถุประสงค์การรับข้อมูลของข้อมูลส่วนบุคคลแต่ละประเภท</li>
            <li>ระยะเวลาจัดเก็บข้อมูลส่วนบุคคลของคู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคล</li>
            <li>
              สิทธิและวิธีการเข้าถึงข้อมูลส่วนบุคคล
              และระบบความมั่นคงปลอดภัยที่ใช้กับข้อมูลส่วนบุคคลนั้น
            </li>
            <li>การเปิดเผยข้อมูลส่วนบุคคลนั้นต่อบุคคลอื่น และเหตุแห่งการเปิดเผย</li>
            <li>การปฏิเสธสิทธิที่เจ้าของข้อมูลส่วนบุคคลขอใช้ (หากมี)</li>
          </ol>

          <p className="font-light mb-4">
            5.8 เมื่อได้รับการร้องขอ
            คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลจะต้องจัดทำรายงานการจัดเก็บบันทึกข้อมูลส่วนบุคคลดังกล่าวให้แก่คู่สัญญาฝ่ายที่ส่งต่อข้อมูลส่วนบุคคล
            และคู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลตกลงให้คู่สัญญาฝ่ายที่ส่งต่อข้อมูลส่วนบุคคลสามารถนำรายงานนั้นไปส่งมอบให้แก่ผู้ควบคุมข้อมูลส่วนบุคคลภายนอก
            (หากมี) และหน่วยงานราชการที่เกี่ยวข้องได้ตามกฎหมาย
          </p>

          <p className="font-bold">6. ผลของการสิ้นสุดหรือการเลิกสัญญา</p>

          <p className="font-light mb-4">
            6.1 เมื่อครบอายุสัญญาและ/หรือเมื่อมีการเลิกสัญญา
            ให้คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลระงับการใช้ข้อมูลส่วนบุคคลทันที
            และให้คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลลบหรือคืนข้อมูลส่วนบุคคลที่ได้รับไว้
            และทำลายสำเนาทั้งหมดที่มี ไม่ว่าจะถูกเก็บอยู่ในรูปแบบใด โดยเร็วที่สุดที่สามารถกระทำได้
            แต่ไม่เกิน 15 วันนับแต่ครบอายุสัญญาหรือนับแต่วันเลิกสัญญา (แล้วแต่กรณี)
            รวมถึงแจ้งให้บุคคล/องค์กร/หน่วยงาน
            ที่รับโอนข้อมูลส่วนบุคคลจากคู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลกระทำการดังกล่าวด้วย
            เว้นแต่กฎหมายคุ้มครองข้อมูลส่วนบุคคล
            หรือกฎหมายอื่นใดจะให้สิทธิคู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลในการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลนั้นต่อได้ ทั้งนี้ เมื่อมีการลบ/คืน/ทำลายสำเนาเรียบร้อยแล้ว
            ให้คู่สัญญาฝ่ายที่รับข้อมูลส่วนบุคคลแจ้งเป็นลายลักษณ์อักษรแก่คู่สัญญาฝ่ายที่เปิดเผยข้อมูลส่วนบุคคลภายใน
            7 วัน นับแต่วันที่ได้คืน/ลบ/ทำลายข้อมูลส่วนบุคคลนั้นทั้งหมดเป็นที่เรียบร้อยแล้ว
          </p>

          <p className="font-light mb-4">
            6.2 การสิ้นสุดของสัญญา หรือการเลิกสัญญาไม่ว่าด้วยเหตุใดก็ตาม
            ไม่ถือเป็นการยกเลิกหน้าที่ของคู่สัญญาเกี่ยวกับการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคลตามสัญญานี้ และให้ข้อกำหนดอันเกี่ยวกับการเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลตามสัญญานี้มีผลใช้บังคับต่อไปแม้สัญญานี้สิ้นสุดลงหรือได้มีการบอกเลิกสัญญา
          </p>

          <h3 className="text-center my-16 text-secondary text-lg font-bold">
            เอกสารแนบท้ายภาคผนวก
            <br />
            รายละเอียดการโอนหรือส่งข้อมูลส่วนบุคคล
          </h3>
          <ol className="list-decimal font-light">
            <li>
              ประเภทของข้อมูลส่วนบุคคล และข้อมูลส่วนบุคคลที่มีความอ่อนไหวเป็นพิเศษ (ถ้ามี)
              ที่จะมีการเข้าถึง เก็บ รวบรวม ใช้ ส่งต่อ และเปิดเผย ได้แก่
              <ul className="list-disc ml-6">
                <li>
                  ข้อมูลผู้มุ่งหวัง ข้อมูลผู้ขอเอาประกันชีวิต ข้อมูลผู้เอาประกันชีวิต
                  ข้อมูลผู้รับประโยชน์ ข้อมูลนายหน้าประกันชีวิต รวมถึง ชื่อ ที่อยู่ เบอร์โทรศัพท์
                  อายุ วันเกิด เพศ
                </li>
                <li>ใบคำขอเอาประกันชีวิต และเอกสารประกอบการขอเอาประกันชีวิต</li>
                <li>วันที่ผู้ป่วยใน และผู้ป่วยนอกของโรงพยาบาลเข้ารับการรักษา</li>
                <li>ใบรับรองแพทย์ระบุสาเหตุของการเข้ารับการรักษา </li>
                <li>เอกสารใบเรียกร้องค่าสินไหมทดแทน ค่าชดเชย</li>
                <li>ใบแจ้งหนี้การรักษา/ ใบสรุปค่ารักษาพยาบาล</li>
                <li>ผลการอ่านฟิล์มเอ็กซเรย์/EKG/การตรวจอื่นๆ</li>
                <li>ข้อมูลกรมธรรม์ของลูกค้าเมืองไทยประกันชีวิต</li>
                <li>รายงานการรับประกันภัย</li>
                <li>หนังสือปฏิเสธการรับประกันภัย </li>
                <li>เอกสารค่าใช้จ่ายส่วนเกินสิทธิ</li>
              </ul>
            </li>
            <li>
              วัตถุประสงค์และระยะเวลาในการเข้าถึง เก็บ รวบรวม ใช้ ส่งต่อ โอน
              หรือเปิดเผยข้อมูลส่วนบุคคล ให้เป็นไปตาม
              <ul className="list-disc ml-6">
                <li>สัญญา</li>
                <li>การรับประกันภัยและการรับประกันภัยต่อ </li>
                <li>สัญญาประกันชีวิต</li>
                <li>
                  การจ่ายเงินค่าสินไหมทดแทน เงินค่าชดเชย และเงินผลประโยชน์ต่างๆ
                  ตามเงื่อนไขของสัญญาประกันชีวิต
                </li>
                <li>การให้บริการต่างๆ ตามสัญญาประกันชีวิตและสัญญาอื่นๆ ที่เกี่ยวข้อง</li>
                <li>กฎหมายที่เกี่ยวข้อง </li>
                <li>
                  นโยบายความเป็นส่วนตัวปัจจุบันของคู่สัญญาและตามที่จะได้มีการปรับปรุงในอนาคต และ
                </li>
                <li>ความยินยอมที่ได้รับ กรณีข้อมูลส่วนบุคคลที่มีความอ่อนไหวเป็นพิเศษ</li>
              </ul>
            </li>
            <li>
              เจ้าของข้อมูลส่วนบุคคล ได้แก่ ผู้มุ่งหวัง ข้อมูลผู้ขอเอาประกันภัย
              ข้อมูลผู้เอาประกันภัย ข้อมูลผู้รับประโยชน์ ข้อมูลนายหน้าประกันภัย และบุคคลอื่น ๆ
              ซึ่งเป็นเจ้าของข้อมูลส่วนบุคคล
            </li>
            <li>
              บุคคล/องค์กร/หน่วยงาน ที่ข้อมูลส่วนบุคคลอาจมีการโอนหรือส่งต่อ
              ข้อมูลส่วนบุคคลอาจจะมีการโอนหรือส่งต่อไปยังบุคคล/องค์กร/หน่วยงาน
              หรือประเภทของบุคคล/องค์กร/หน่วยงาน ดังต่อไปนี้
              <ul className="list-disc ml-6">
                <li>บริษัทรับประกันภัยต่อ</li>
                <li>
                  โรงพยาบาล/สถานพยาบาล/คลินิกที่เป็นคู่สัญญาของบริษัท
                  หรือโรงพยาบาล/สถานพยาบาล/คลินิกที่เป็นผู้ให้การรักษาพยาบาลแก่เจ้าของข้อมูลส่วนบุคคล
                </li>
                <li>
                  บุคคลหรือนิติบุคคลต่างๆ ที่เป็นคู่สัญญาของบริษัทซึ่งเป็นผู้ให้บริการในด้านต่างๆ
                  แก่เจ้าของข้อมูลส่วนบุคคล
                </li>
                <li>สำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย</li>
                <li>กรมสรรพากร</li>
                <li>หน่วยงานราชการ/หน่วยงานอื่นๆ ที่มีอำนาจกำกับดูแลตามกฎหมาย</li>
              </ul>
            </li>
            <li>
              ช่องทางการส่งหรือโอนข้อมูลส่วนบุคคล ได้แก่
              <ul className="list-disc ml-6">
                <li>สัญญา</li>
                <li>โทรศัพท์</li>
                <li>โทรสาร</li>
                <li>ไปรษณีย์</li>
                <li>Electronic mail</li>
                <li>MTL Click</li>
                <li>Group Web Access</li>
                <li>ระบบอื่นๆ ที่รองรับข้อมูลในภายภาคหน้า</li>
              </ul>
            </li>
          </ol>

          <div className="text-center mt-12">
            <Button onClick={onClose} color="secondary">
              ตกลง
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroLicence;
