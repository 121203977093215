import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isBrowser, isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { messages } from "~/src/helpers/messages";
import { urlStringify } from "~/src/helpers/helpers";
import Loading from "~/src/components/loading";
import EndOfYearModal from "~src/components/modal/warning/EndOfYearModal";
import lineLogo from "~/assets/contact/logo-line.png";
import driverIcon from "~/assets/driver-hero.svg";
import heroQrIcon from "~/assets/contact/hero-qr-line.jpg";
import mtiQrIcon from "~/assets/contact/mti-qr-line.jpg";
import { isLastMonth } from "~/src/helpers/date";

import {
  FormGroup,
  ButtonRadius,
  SelectField,
  SelectGroupField,
  Checkbox,
} from "/src/components/form";

import { getCarBrand, getCarModel, getCarYear, getCarSubModel, getProvinces } from "../Services";
import CommonModal from "~src/components/modal/common";

const POPULARS = [
  {
    label: "Toyota",
    value: "Toyota",
  },
  { label: "Honda", value: "Honda" },
  { label: "Isuzu", value: "Isuzu" },
  { label: "Nissan", value: "Nissan" },
  { label: "Mazda", value: "Mazda" },
  { label: "BMW", value: "BMW" },
  { label: "Mercedes-Benz", value: "Mercedes-Benz" },
];

const isDecemberMonth = new Date().getMonth() === 11;

const FormVmi = ({ productType = "motor" }) => {
  const { t } = useTranslation();
  const lineLink = t("lineLink", { returnObjects: true });
  const [loading, setLoading] = useState(false);
  const [sumInsured, setSumInsured] = useState("");
  const [carBrands, setCarBrands] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [carYears, setCarYears] = useState([]);
  const [carSubModels, setCarSubModels] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [showEndOfYearModal, setShowEndOfYearModal] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const isAdjustSumInsured = t("adjustSumInsured");
  const username = Cookies.get("username");
  const [state, setState] = useState({
    unSureSubModel: 0,
    insurance_type: "motor",
    vehicleType: ""
  });
  const { register, errors, handleSubmit, getValues } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    const c = [];
    getCarBrand().then((res) => {
      const { brands } = res.data;
      brands.map((value, i) => {
        c[i] = { label: brands[i], value: brands[i] };
      });
      setCarBrands(c);
    });

    const p = [];
    getProvinces().then((res) => {
      const { provinces } = res.data;
      provinces.map((value, i) => {
        p[i] = { label: provinces[i], value: provinces[i] };
      });
      setProvinces(p);
    });
  }, []);

  useEffect(() => {
    if (searchData && !showEndOfYearModal) {
      redirectToSearch(searchData);
    }
  }, [searchData, showEndOfYearModal]);

  const handleChangeCarBrand = (evt) => {
    const { name, value } = evt.target;
    setLoading(true);
    setCarModels([]);
    setCarYears([]);
    setCarSubModels([]);
    if (value === "") {
      setLoading(false);
      return false;
    }
    const m = [];
    getCarModel(value).then((res) => {
      const { models } = res.data;
      models.map((value, i) => {
        m[i] = { label: models[i], value: models[i] };
      });
      setCarModels(m);
      setLoading(false);
    });
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeCarModel = (evt) => {
    const { name, value } = evt.target;
    setLoading(true);
    setCarYears([]);
    setCarSubModels([]);
    if (value === "") {
      setLoading(false);
      return false;
    }
    const y = [];
    getCarYear(state.car_brand, value).then((res) => {
      const { years } = res.data;
      years.map((value, i) => {
        y[i] = { label: `${years[i]} / ${years[i] + 543}`, value: years[i] };
      });

      setCarYears(y);
      setLoading(false);
    });
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeCarYear = (evt) => {
    const { name, value } = evt.target;
    setLoading(true);
    setCarSubModels([]);
    if (value === "") {
      setLoading(false);
      return false;
    }
    getCarSubModel(state.car_brand, state.car_model, value).then((res) => {
      const { sub_models } = res.data;
      const s = [];

      sub_models.map((value, i) => {
        s[i] = { label: value.sub_model, value: value.code_name, vehicleType: value.vehicle_type };
      });

      setCarSubModels(s);
      setLoading(false);
    });
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeSubModel = (e) => {
    const indexSeleted = e.nativeEvent.target.selectedIndex;
    const label = e.nativeEvent.target[indexSeleted].text;
    const { name, value } = e.target;
    const selectedModel = carSubModels.find((model) => model.value == value)
    const vehicleType = selectedModel.vehicleType

    setState({
      ...state,
      unSureSubModel: label === "ไม่แน่ใจ" ? 1 : 0,
      [name]: value,
      vehicleType: vehicleType,
    });
  };

  const handleClickContactLine = () => {
    const { policy_type } = getValues();
    let mobileContactLineUrl = lineLink.mobileUrl;

    const lineParams = encodeURIComponent(
      `${username} หารุ่นรถในระบบไม่เจอ ขอราคาเบี้ยประกันชั้น ${policy_type.join(",")}`
    );
    if (process.env.APP_NAME === "hero") mobileContactLineUrl += `?${lineParams}`;
    const browserContactLineUrl = lineLink.url;

    if (isBrowser) window.open(browserContactLineUrl, "_blank");
    if (isMobile) window.open(mobileContactLineUrl, "_blank");
  };

  const redirectToSearch = (data) => {
    if (productType === "commercial") data.is_commercial = true;

    window.location.href = `/motors/search?${urlStringify(data)}`;
  };

  const onSubmit = (data) => {
    if (process.env.APP_NAME !== "mti" && isDecemberMonth) {
      setShowEndOfYearModal(true);
      setSearchData(data);
      return;
    }

    redirectToSearch(data);
  };

  return (
    <>
      <EndOfYearModal
        nextStep={false}
        iconOne={driverIcon}
        iconTwo={lineLogo}
        iconQR={t("appName") == "hero" ? heroQrIcon : mtiQrIcon}
        open={showEndOfYearModal}
        onClose={() => setShowEndOfYearModal(false)}
      />
      {loading && <Loading />}
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" ref={register} name="unsure_sub_model" value={state.unSureSubModel} />
        <input type="hidden" ref={register} name="vehicle_type" value={state.vehicleType} />
        <div className="motor-mainpage-searchbox-checkbox">
          <div className="">เลือกชั้นประกัน </div>
          <Checkbox
            name="policy_type"
            id="policy_type_1"
            label="ชั้น 1"
            value="1"
            inputRef={register(messages.required)}
            onChange={handleChange}
            defaultChecked
          />
          <Checkbox
            name="policy_type"
            id="policy_type_2plus"
            label="ชั้น 2+"
            inputRef={register(messages.required)}
            value="2+"
            onChange={handleChange}
          />
          <Checkbox
            name="policy_type"
            id="policy_type_3plus"
            label="ชั้น 3+"
            inputRef={register(messages.required)}
            value="3+"
            onChange={handleChange}
          />
          <Checkbox
            name="policy_type"
            id="policy_type_3"
            label="ชั้น 3"
            inputRef={register(messages.required)}
            value="3"
            onChange={handleChange}
          />

          {errors.policy_type?.message && (
            <span className="text-xs text-red-600">{errors.policy_type?.message}</span>
          )}
        </div>
        <div className="motor-mainpage-searchbox-fields vmi gap-4  mr-1">
          <FormGroup label="ยี่ห้อรถยนต์">
            <SelectGroupField
              name="car_brand"
              id="car_brand"
              inputRef={register(messages.required)}
              error={!!errors.car_brand && errors.car_brand.message}
              onChange={handleChangeCarBrand}
              optgroups={[
                { label: "ยี่ห้อรถยนต์ยอดนิยม", options: POPULARS },
                { label: "ยี่ห้อรถยนต์ทั้งหมด", options: carBrands },
              ]}
            />
          </FormGroup>
          <FormGroup label="รุ่นรถยนต์">
            <SelectField
              name="car_model"
              id="car_model"
              inputRef={register(messages.required)}
              error={!!errors.car_model && errors.car_model.message}
              onChange={handleChangeCarModel}
              options={carModels}
            />
          </FormGroup>
          <FormGroup label="ปีรถยนต์ (คศ.)">
            <SelectField
              name="year"
              id="year"
              inputRef={register(messages.required)}
              error={!!errors.year && errors.year.message}
              onChange={handleChangeCarYear}
              options={carYears}
            />
          </FormGroup>
          <FormGroup label="รุ่นย่อย">
            <SelectField
              name="code_name"
              id="code_name"
              inputRef={register(messages.required)}
              error={!!errors.code_name && errors.code_name.message}
              onChange={handleChangeSubModel}
              options={carSubModels}
            />
          </FormGroup>
          <FormGroup label="ทะเบียนจังหวัด">
            <SelectField
              name="province"
              id="province"
              inputRef={register(messages.required)}
              error={!!errors.province && errors.province.message}
              onChange={handleChange}
              options={provinces}
            />
          </FormGroup>
        </div>

        <p>
          หมายเหตุ : หากไม่พบยี่ห้อ หรือรุ่นรถยนต์ ที่ต้องการ กรุณาติดต่อ Line:
          {t("lineAccount")}
        </p>
        <div className="flex flex-row justify-center mt-4 text-center">
          <div className="mx-1">
            <FormGroup>
              <ButtonRadius type="submit" color="secondary">
                ค้นหา
              </ButtonRadius>
            </FormGroup>
          </div>
          {t("buttonLine") && (
            <div className="flex mx-1">
              <ButtonRadius onClick={handleClickContactLine} type="button">
                <img src={lineLogo} alt="lineLogo" className="mr-1 h-30" />
                หารุ่นไม่เจอ
              </ButtonRadius>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default FormVmi;
