import React from "react";
import classnames from "classnames";
import { isBrowser, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Link from "../../link";
import Logo from "../../../../assets/logo/gettgo-hero-logo.svg";
import iconContact from "../../../../assets/navbar/contact.svg";
import iconLogin from "../../../../assets/navbar/login.svg";
import { Icon } from "../../icons";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import useWindowScroll from "../../../hooks/useWindowScroll";

import "./css/header.scss";

type MenuContactItemProps = {
  children: string | JSX.Element;
};

interface ButtonElements {
  classNames: string;
  styles?: React.CSSProperties;
}

const MenuContactItem: React.FC<MenuContactItemProps> = ({ children }) => {
  return <li className="flex mb-10 text-base font-normal text-primary">{children}</li>;
};

const RegisterAnimateButton = () => {
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [isShow, setIsShow] = React.useState<boolean>(false);

  useWindowScroll(() => {
    if (window.scrollY > 78) setIsShow(true);
    else setIsShow(false);
    setScrollY(window.scrollY);
  });

  const renderButton = (): ButtonElements => {
    if (isBrowser) {
      return {
        classNames: classnames(
          "absolute right-0 flex items-center justify-center h-48 px-24 text-lg font-bold text-white top-10 bg-secondary hover:bg-secondary-dark rounded-5 shadow-button hover:shadow-button-hover",
          isShow ? "button-animated" : "0"
        ),
        styles: {
          top: isShow ? scrollY - 30 : "-200px",
        },
      };
    }

    if (isMobile) {
      return {
        classNames: classnames(
          "fixed flex items-center justify-center h-48 px-24 text-lg font-bold text-white top-10 bg-secondary hover:bg-secondary-dark rounded-5 shadow-button hover:shadow-button-hover right-10"
        ),
        styles: {
          top: isShow ? 10 : "-200px",
        },
      };
    }
  };

  return (
    <a
      href="/register"
      className={renderButton()?.classNames}
      style={renderButton()?.styles}
    >
      สมัครเลย
    </a>
  );
};

const Header: React.FC = () => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const lineLink = t("lineLink", { returnObjects: true });

  useOnClickOutside(dropdownRef, (e) => {
    if (!buttonRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  });

  return (
    <div className="absolute top-0 z-20 w-full h-78">
      <header className="container py-20 px-14 lg:px-30">
        <div className="flex items-center justify-between">
          <img src={`${process.env.BUCKET_URL}/logo.svg`} alt="" className="w-150" />
          <nav className="w-auto">
            <ul className="flex">
              <li className="relative hidden ml-10 lg:block">
                <button
                  ref={buttonRef}
                  className="flex items-center text-base nav-link focus:outline-none"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <img src={iconContact} alt="" className="w-24 mr-10" />
                  <span className="font-medium">ติดต่อ {t("support")}</span>
                </button>
                <div
                  ref={dropdownRef}
                  className={classnames(
                    "absolute bg-white shadow-md top-30 rounded-20 swipe-300-enter-done transform transition-all duration-500",
                    isOpen ? "z-1 opacity-100 translate-y-0" : "-z-1 opacity-0 -translate-y-4"
                  )}
                >
                  <div className="p-20">
                    <ul className="">
                      <MenuContactItem>
                        <div className="w-full mb-4 text-center">จ. - ศ. 9.00 - 18.00 น.</div>
                      </MenuContactItem>
                      <MenuContactItem>
                        <Link href="tel:+020266387" className="flex nav-link">
                          <>
                            <span className="inline-block ml-2 mr-4">
                              <Icon name="Call" />
                            </span>
                            <span className="label">{t("phoneNumberFormat")}</span>
                          </>
                        </Link>
                      </MenuContactItem>
                      <MenuContactItem>
                        <Link
                          href={lineLink.url}
                          className="flex items-center nav-link"
                          target="_blank"
                        >
                          <>
                            <span className="inline-block mr-2">
                              <Icon name="Line" />
                            </span>
                            <span className="label">{t("lineAccount")}</span>
                          </>
                        </Link>
                      </MenuContactItem>
                      <MenuContactItem>
                        <Link
                          href={`mailto:${t("contactMail")}`}
                          target="_blank"
                          className="flex items-center nav-link"
                        >
                          <>
                            <span className="inline-block ml-2 mr-4">
                              <Icon name="Email" />
                            </span>
                            <span className="label">{t("contactMail")}</span>
                          </>
                        </Link>
                      </MenuContactItem>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="ml-20">
                <Link href="/login" className="flex items-center text-base nav-link">
                  <>
                    <img src={iconLogin} alt="" className="w-24 mr-10" />
                    <span className="font-medium">เข้าสู่ระบบ</span>
                  </>
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        {t("registerButton") && (
          <div className="relative">
            <RegisterAnimateButton />
          </div>
        )}
      </header>
    </div>
  );
};

export default Header;
