import React, {useState, useEffect} from "react";
import SectionHeader from "/src/components/layout/staff/SectionHeader";

import "./lead.scss";
import useFetch from "~src/hooks/useFetch";
import Cookies from "js-cookie";
import ErrorPage from "~src/components/errorPage";

export default function Details(props) {
  const {match} = props;
  const [loaded, setLoaded] = useState(true);
  const [isError, setIsError] = useState(false);
  const [leadActivity, setLeadActivity] = useState([]);
  const role = Cookies.get('role')
  const role_json = JSON.parse(role).code

  const url = `/api/leads/${match.params.id}`
  const {response, error, isLoading} = useFetch(url);
  useEffect(() => {
    if (!isLoading && !error) {
      const results = response?.content;
      setLeadActivity(results);
      setLoaded(false);
      setIsError(false);
    }

    if (error) {
      setIsError(true);
    }
  }, [isLoading]);

  return (
    <div className="lead-page">
      {(isError || role_json != 'supervisor') ? (
        <ErrorPage/>
      ) : (
        <>
          <SectionHeader
            title="Log Lead Activities"
            breadcrumbs={[{url: "#", label: "lead", current: true}]}
            // searchComponent={
            // <SearchForm
            //   type="text"
            //   placeholder="ค้นหาชื่อลูกค้า, นามสกุล, เบอร์โทร, อีเมลล์"
            //   {...handleChangeSearch}
            //   handleClick={handleClickSearch}
            //   handleKeyPress={handleKeyPressSearch}
            // />
            // }
          />
          <div className="lead-table">
            <div className="table-grid">
              <div className="grid-th">
                <div>ผู้กระทำ</div>
                <div>event</div>
                <div>ผู้รับผิดชอบ</div>
                <div>วันที่ได้รับมอบหมาย</div>
                <div>สถานะ</div>
                <div style={{textAlign: "center"}}/>
              </div>
              {leadActivity.length == 0 && <div className="renew-grid-body-full">ไม่พบข้อมูล</div>}
              {leadActivity.map((lead_activity, index) => (
                <div className="grid-tbody" key={index}>
                  <div>{lead_activity.actioner}</div>
                  <div>{lead_activity.action}</div>
                  <div>{lead_activity.assigned_user_email}</div>
                  <div>{lead_activity.assigned_at}</div>
                  <div>
                    {lead_activity.status_text}<br/>
                    {lead_activity.sub_status_text}<br/>
                    {lead_activity.status_key == 'follow_up' &&
                    <>{lead_activity.followup_date} {lead_activity.followup_time}<br/><br/></>
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}