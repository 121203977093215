import React from "react";
import {
  Card,
  CardContent,
  Box,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "~src/store";
import { unEscapeHtml } from "~src/helpers/helpers";
import { pdpaViewSelector, closeAgentViewModal } from "./pdpaSlice";
import { fetchAcceptPdpa, fetchNewAgentPdpa } from "./apis";

const PdpaCard = ({ onOk }) => {
  const [loading, setLoading] = React.useState(false);
  const [answers, setAnswers] = React.useState([]);
  const { open, data, status } = useAppSelector(pdpaViewSelector);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchNewAgentPdpa());
  }, []);

  React.useEffect(() => {
    if (status === "fulfilled") {
      const answersList = data?.answers
        ? data.answers.map((answer) => ({ id: answer.pdpa_question_id, answer: answer.answer }))
        : data.questions.map((question) => ({ id: question.id, answer: "" }));

      setAnswers(answersList);
    }
  }, [status, data]);

  const handleChangeAnswer = (index: number, event) => {
    const updateAnswers = [...answers];
    const value = event.target.value;
    updateAnswers[index] = { ...updateAnswers[index], answer: value === "1" };
    setAnswers(updateAnswers);
  };

  const handleOk = () => {
    const filteredAnswers = answers.filter((q) => q.answer !== "");
    if (filteredAnswers.length < answers.length) {
      alert("กรุณาตอบคำถามให้ครบ");
      return;
    }

    const params = {
      id: data.id,
      questions: answers,
    };

    onOk(params);
  };

  return (
    <Box sx={{ px: 4 }}>
      <Card variant="outlined">
        <CardContent sx={{ height: `calc(100vh - 200px)`, overflowY: "auto" }}>
          {status === "fulfilled" && (
            <>
              <Box
                className="detail-wrapper"
                dangerouslySetInnerHTML={{ __html: unEscapeHtml(data.detail) }}
              />

              <Box>
                <Box>
                  {data.questions.map((o, index) => (
                    <Box key={index} sx={{ mb: 1 }}>
                      <Typography>{o.question}</Typography>
                      <RadioGroup
                        name={`answer[${o.id}]`}
                        value={answers[index]?.answer}
                        onChange={(event) => handleChangeAnswer(index, event)}
                        row
                      >
                        <FormControlLabel control={<Radio />} value="1" label="ยินยอม" />
                        <FormControlLabel control={<Radio />} value="0" label="ไม่ยินยอม" />
                      </RadioGroup>
                    </Box>
                  ))}
                </Box>

                <Stack justifyContent="center" direction="row" spacing={3} sx={{ mt: 4 }}>
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={handleOk}
                  >
                    ตกลง
                  </Button>
                </Stack>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default PdpaCard;
