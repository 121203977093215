import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import classNames from "classnames";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import CarBrandInput from "~src/components/Forms/motor/CarBrandInput";
import CarModelInput from "~/src/components/Forms/motor/CarModelInput";
import CarYearInput from "~/src/components/Forms/motor/CarYearInput";
import CarSubModelInput from "~/src/components/Forms/motor/CarSubModelInput";
import ProvinceInput from "~/src/components/Forms/ProvinceInput";
import VehicleTypeInput from "~/src/components/Forms/VehicleTypeInput";
import { loadingInfo, mainTypeMotor } from "~/src/states/orderCustom";
import { FormGroup, TextField, Switcher } from "~/src/components/form";
import { messages } from "~/src/helpers/messages";
import AddonForm from "../form/AddonForm";

const typeCarInputLabel = [
  { label: "รถนอกระบบ", value: "text" },
  { label: "รถในระบบ", value: "select" },
];

const VehiclePanel: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(true);
  const { isMotorCustom } = useRecoilValue(mainTypeMotor);
  const [typeCarInput, setTypeCarInput] = useState<"select" | "text">(
    isMotorCustom ? "text" : "select"
  );

  const { register, setValue, control, errors } = useFormContext();
  const setLoading = useSetRecoilState(loadingInfo);

  const carBrand = useWatch({
    control,
    name: `quotation.criteria.car_brand`,
    defaultValue: undefined,
  });
  const carModel = useWatch({
    control,
    name: `quotation.criteria.car_model`,
    defaultValue: undefined,
  });
  const carYear = useWatch({ control, name: `quotation.criteria.year`, defaultValue: undefined });

  useEffect(() => {
    setValue(`quotation.criteria.car_model`, "");
    setValue(`quotation.criteria.year`, "");
    setValue(`quotation.criteria.car_sub_model`, "");
  }, [carBrand]);

  useEffect(() => {
    setValue(`quotation.criteria.year`, "");
    setValue(`quotation.criteria.car_submodel`, "");
  }, [carModel]);

  useEffect(() => {
    setValue(`quotation.criteria.car_submodel`, "");
  }, [carYear]);

  useEffect(() => {
    setTypeCarInput(isMotorCustom ? "text" : "select");
  }, [isMotorCustom]);

  return (
    <div className="p-4 w-full bg-white shadow rounded-5">
      <h6 className="flex items-center justify-between p-1 px-2 text-sm font-bold bg-gray-100">
        ข้อมูลเพิ่มเติม
        <button
          type="button"
          onClick={() => setExpanded(!expanded)}
          className="flex items-center justify-center w-6 h-6 bg-white shadow outline-none rounded-5 focus:outline-none"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </button>
      </h6>

      {isMotorCustom && (
        <div className="mt-4">
          <Switcher
            id="type_car_input"
            name="type_car_input"
            active={typeCarInput}
            onChange={(e) => setTypeCarInput(e.target.value)}
            labels={typeCarInputLabel}
          />
        </div>
      )}

      <div
        className={classNames(
          "grid grid-cols-3 gap-6 mt-4 tablet:grid-cols-2",
          expanded ? "block" : "hidden"
        )}
      >
        <CarBrandInput
          name="quotation.criteria.car_brand"
          errors={errors}
          type={typeCarInput}
          register={register}
          setLoading={setLoading}
        />
        <CarModelInput
          name="quotation.criteria.car_model"
          type={typeCarInput}
          carBrand={carBrand}
          errors={errors}
          register={register}
          setLoading={setLoading}
        />
        <CarYearInput
          name="quotation.criteria.year"
          type={typeCarInput}
          carBrand={carBrand}
          carModel={carModel}
          register={register}
          errors={errors}
          setLoading={setLoading}
        />
        <CarSubModelInput
          name="quotation.criteria.car_submodel"
          type={typeCarInput}
          carBrand={carBrand}
          carModel={carModel}
          carYear={carYear}
          errors={errors}
          register={register}
          setLoading={setLoading}
        />
        <ProvinceInput
          name="quotation.criteria.province"
          errors={errors}
          register={register}
          setLoading={setLoading}
        />

        {isMotorCustom && (
          <VehicleTypeInput
            name="quotation.criteria.vehicle_type"
            errors={errors}
            register={register}
          />
        )}

        <div className="col-span-3 grid grid-cols-2 gap-4">
          <FormGroup label="ทะเบียนรถยนต์" require>
            <TextField
              name="order.details.plate_number"
              inputRef={register(messages.required)}
              error={errors?.order?.details?.plate_number?.message}
            />
          </FormGroup>

          <FormGroup label="หมายเลขตัวถัง" require>
            <TextField
              maxLength={t("maxChassisNumber")}
              name="order.details.chassis_number"
              inputRef={register(messages.required)}
              onChange={(e) =>
                setValue("order.details.chassis_number", e.target.value.toUpperCase())
              }
              error={errors?.order?.details?.chassis_number?.message}
            />
          </FormGroup>
        </div>

        <AddonForm
          name="addon"
          isMotorCustom={isMotorCustom}
          register={register}
          setValue={setValue}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default VehiclePanel;
