import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Close, CheckCircleOutline, GetApp, Replay } from "@mui/icons-material";

import { useAuthenticate } from "~src/helpers/authen";
import useFetch from "~src/hooks/useFetch";

import axios from "~src/helpers/configAxios";
import ErrorPage from "~src/components/errorPage";
import ImportHistoryInfo from "~src/models/ImportHistoryInfo";
import { reformatDateTimeEN } from "~src/helpers/date";
import { AlertNotification } from "~src/components/alert/alertNotification";

interface ImportHistoryProps {
  match: any;
}

const ImportHistory: React.FC<ImportHistoryProps> = ({ match }) => {
  const historyId = match.params.id;
  const historyFetch = useFetch(`/api/import_histories/${historyId}`);

  const [isError, setIsError] = useState(false);
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [importHistory, setImportHistory] = useState<ImportHistoryInfo>(null);
  const [isDownloadable, setIsDownloadable] = useState(false);

  const { accessType } = useAuthenticate();
  const isStaff = accessType === "STAFF";

  useEffect(() => {
    if(!historyFetch.isLoading) {
      const historyData = historyFetch.response.content;
      setImportHistory(historyData);
      setIsDownloadable(true);
    } else if (historyFetch.error) {
      setIsError(true);
    }
  }, [
    historyFetch.response,
    historyFetch.error,
    historyFetch.isLoading,
  ]);

  const handleCopyJson = (jsonString: string) => {
    navigator.clipboard.writeText(jsonString);
    setIsAlertActive(true);
  };

  const sortByColumnName = (details) => {
    return details.sort((a, b) => {
      return a.column_name - b.column_name
    })
  }

  const handleDownloadImportFile = () => {
    setIsDownloadable(false);
    axios
      .get(`/api/import_histories/${importHistory.id}/file`, {
        responseType: "arraybuffer",
      })
      .then((result) => {
        const filenameRegex = /filename\*?=([^']*'')?([^;]*)/;
        const filename = decodeURI(filenameRegex.exec(result.headers["content-disposition"])[2]);
        const uriString =
          "data:" +
          result.headers["content-type"] +
          ";base64," +
          encodeURI(Buffer.from(result.data, "binary").toString("base64"));
        let a = document.createElement("a");
        a.href = uriString;
        a.download = decodeURI(filename);
        a.click();
        setIsDownloadable(true);
      })
      .catch((error) => {
        alert("File not found");
        setIsDownloadable(true);
      });
  }

  return (
    <div>
      {isError ? (
        <ErrorPage />
      ) : (
        <div className="flex flex-col">
          {historyFetch.isLoading ? (
            <div className="mt-4 flex justify-center text-center text-gray-600">
              กำลังโหลด...
            </div>
          ) : (
            !!importHistory && (
              <>
                <div className="shadow-lg">
                  <div className="flex w-full justify-between bg-white">
                    <div className="flex flex-col px-4 pt-4">
                      <span className="text-xs">รายการ Import</span>
                      <span className="text-lg">รายละเอียด</span>
                    </div>
                    <div className="px-8 py-4">
                      <button 
                        onClick={() => { handleDownloadImportFile() }}
                        className={`p-2 w-32 text-center align-middle rounded-lg shadow-lg text-white ${isDownloadable ? "bg-secondary" : "bg-gray-400"}`}
                        >
                        {isDownloadable ? (
                          <>
                            <GetApp /> ดาวน์โหลดไฟล์
                          </>
                        ) : (
                          <>
                            กำลังโหลด...
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="grid grid-cols-5 px-4 pt-2 pb-4 bg-white">
                    <div className="flex flex-col">
                      <span className="text-gray-500 text-xs mb-1">ชื่อไฟล์</span>
                      <span className="leading-tight">{importHistory.filename}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-gray-500 text-xs mb-1">ประเภท:</span>
                      <span className="leading-tight">{importHistory.import_type}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-gray-500 text-xs mb-1">วันที่นำเข้า:</span>
                      <span className="leading-tight">{reformatDateTimeEN(new Date(importHistory.created_at))}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-gray-500 text-xs mb-1">ผลการนำเข้า</span>
                      <div className="grid grid-cols-3">
                        <span className="leading-tight">Success:</span><span className="text-green-600 leading-tight">{importHistory.order_count}</span><span />
                        <span className="leading-tight">Error:</span><span className="text-red-600 leading-tight">{importHistory.error_count}</span><span />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-gray-500 text-xs mb-1">นำเข้าโดย:</span>
                      <span className="leading-tight">{`${importHistory.user.username} - ${importHistory.user.title}${importHistory.user.firstname} ${importHistory.user.lastname} (${importHistory.user.role})`}</span>
                    </div>
                  </div>
                </div>
                
                <div className="m-4 bg-white rounded">
                  {importHistory.error_logs.length <= 0 ? (
                    <div className="col-span-12 flex justify-center">
                      <span className="py-2">ไม่มีข้อมูลความผิดพลาด</span>
                    </div>
                  ) : (
                    <>
                      <div className="text-lg px-4 pt-4">
                        รายละเอียดความผิดพลาด
                      </div>
                      <div className="flex flex-col p-4 divide-y">
                        <div className="grid grid-cols-12 pb-2">
                          <div>
                            Row
                          </div>
                          <div className="col-span-11">
                            Raw Data
                          </div>
                        </div>
                        {importHistory.error_logs.map((log) => (
                          <div className="grid grid-cols-12">
                            <div className="p-2 col-span-1">
                              {log.row_number}
                            </div>
                            <div className="pt-1 pb-2 col-span-11 flex flex-col divide-y">
                              <div 
                                className="my-1 p-1 overflow-hidden truncate cursor-pointer bg-gray-200 rounded duration-300"
                                onClick={() => handleCopyJson(JSON.stringify(log.raw_data))}
                              >
                                {JSON.stringify(log.raw_data)}
                              </div>
                              <div className="p-1 grid grid-cols-6 gap-y-1">
                                <div className="text-gray-500 text-xs">Column Name</div>
                                <div className="col-span-2 text-gray-500 text-xs">Error Type</div>
                                <div className="col-span-3 text-gray-500 text-xs">Description</div>
                                {sortByColumnName(log.details).map((detail, index) => (
                                  <>
                                    <div>{(index == 0 || log.details[index - 1].column_name != detail.column_name) && (detail.column_name)}</div>
                                    <div className="col-span-2">{detail.error_type}</div>
                                    <div className="col-span-3">{detail.description}</div>
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </>
            )
          )}
        </div>
      )}
      <AlertNotification
        className="bottom-0 right-0 px-4 py-3 m-5 mr-3 flex"
        type={"success"}
        active={isAlertActive}
        setActive={setIsAlertActive}
        enterAnimation="slideInRight"
        exitAnimation="slideOutRight"
        closeDelay={5000}
      >
        <CheckCircleOutline className="mr-3 self-center" />
        <span className="inline-block align-bottom">คัดลอกข้อมูลไปยัง Clipboard เรียบร้อย</span>
        <button className="ml-3 self-center" onClick={() => setIsAlertActive(false)}>
          <Close />
        </button>
      </AlertNotification>
    </div>
  );
}

export default ImportHistory