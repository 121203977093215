import React from "react";
import Fade from "react-reveal/Fade";
import classnames from "classnames";
import Slider, { Settings } from "react-slick";
import { BrowserView, MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";
import SystemIcon from "../../../../assets/homepage/system-icon.svg";
import ArmoryIcon from "../../../../assets/homepage/armory-icon.svg";
import SupportIcon from "../../../../assets/homepage/support-icon.svg";
import SystemImg from "../../../../assets/homepage/systems-img-min.png";
import ArmoryImg from "../../../../assets/homepage/armory-img-min.png";
import SupportImg from "../../../../assets/homepage/supports_img.png";

import "slick-carousel/slick/slick.css";

import "./css/services.scss";

interface LabelBoxProps {
  icon: string;
  en: string;
  th: string;
}
interface SectionProps {
  title: string | JSX.Element;
  detail: string | JSX.Element;
  image: string;
  icon?: "system" | "armory" | "support";
  iconAlign?: "left" | "right" | "none";
}

interface MobileSectionProps extends SectionProps {}

const serviceText = [
  {
    icon: SystemIcon,
    textEN: "Hero System",
    textTH: "เครื่องมือขายคู่กายฮีโร่",
  },
  {
    icon: ArmoryIcon,
    textEN: "Hero Armory",
    textTH: "คลังอาวุธฮีโร่",
  },
  {
    icon: SupportIcon,
    textEN: "Hero Supports",
    textTH: "กำลังเสริมฮีโร่",
  },
];

const serviceTextMobile = [
  {
    icon: SystemIcon,
    textEN: "Hero System",
    textTH: "เครื่องมือขายคู่กายฮีโร่",
    size: {
      normal: 60,
      active: 110,
    },
  },
  {
    icon: ArmoryIcon,
    textEN: "Hero Armory",
    textTH: "คลังอาวุธฮีโร่",
    size: {
      normal: 60,
      active: 110,
    },
  },
  {
    icon: SupportIcon,
    textEN: "Hero Supports",
    textTH: "กำลังเสริมฮีโร่",
    size: {
      normal: 80,
      active: 200,
    },
  },
  {
    icon: SystemIcon,
    textEN: "Hero System",
    textTH: "เครื่องมือขายคู่กายฮีโร่",
    size: {
      normal: 60,
      active: 110,
    },
  },
  {
    icon: ArmoryIcon,
    textEN: "Hero Armory",
    textTH: "คลังอาวุธฮีโร่",
    size: {
      normal: 60,
      active: 110,
    },
  },
  {
    icon: SupportIcon,
    textEN: "Hero Supports",
    textTH: "กำลังเสริมฮีโร่",
    size: {
      normal: 80,
      active: 200,
    },
  },
];

const LabelBox: React.FC<LabelBoxProps> = ({ icon, en, th }) => {
  return (
    <>
      <div className="flex items-center justify-center mb-24 text-center h-70">
        <img src={icon} alt="" className="inline-block" />
      </div>
      <div className="text-lg text-center text-white">
        {en} <br /> {th}
      </div>
    </>
  );
};

const Section: React.FC<SectionProps> = ({
  title,
  detail,
  image,
  icon,
  iconAlign = "left",
}) => {
  return (
    <div className="relative">
      <div
        className={classnames([
          "flex flex-wrap -px-20 mb-120 section-tool",
          iconAlign === "right" && "flex-row-reverse",
        ])}
      >
        <div className="relative w-full lg:w-8/12">
          <div
            className={classnames(
              `bg-tool bg-${icon}`,
              iconAlign === "left" ? "bg-right" : "bg-left"
            )}
          ></div>
          <div
            className={classnames(
              "flex",
              iconAlign === "right" ? "justify-end" : ""
            )}
          >
            <Fade bottom>
              <div className="px-0 xl:px-120 2xl:px-0">
                <img src={image} alt="" className="relative z-10" />
              </div>
            </Fade>
          </div>
        </div>
        <div className="w-full lg:w-4/12">
          <div className={classnames("flex items-center h-full")}>
            <div
              className={
                iconAlign === "right"
                  ? "pl-30 xl:pl-60"
                  : "pl-30 xl:pr-30 xl:pl-0"
              }
            >
              <h2 className="text-2xl font-medium text-white mb-30">{title}</h2>
              <p className="text-lg text-white">{detail}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileSection: React.FC<MobileSectionProps> = ({
  title,
  detail,
  image,
  icon,
  iconAlign = "right",
}) => {
  const renderTranslate = (align: "left" | "right" | "none"): string => {
    switch (align) {
      case "left":
        return "translate-x-1/4";
      case "right":
        return "-translate-x-1/4";
      default:
        return "";
    }
  };

  return (
    <div className="section-tool mt-60 mb-120">
      <div className="relative flex overflow-hidden">
        <div
          className={classnames(
            "relative z-10 transform detail-left-img",
            renderTranslate(iconAlign)
          )}
        >
          <img src={image} alt="" />
        </div>
        <div
          className={classnames(["bg-mobile-tools", iconAlign, `bg-${icon}`])}
        ></div>
      </div>
      <div className="mx-20 text-white mt-30">
        <h2 className="text-xl">{title}</h2>
        <p className="mt-20 text-base">{detail}</p>
      </div>
    </div>
  );
};

const Services = () => {
  const [itemIndex, setItemIndex] = React.useState(0);
  const { t } = useTranslation();

  const settings: Settings = {
    className: "flex flex-col items-center w-full search-new-way",
    centerMode: true,
    arrows: false,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 2,
    focusOnSelect: true,
    responsive: null,
    speed: 200,
    afterChange: (index: number) => {
      setItemIndex(index);
    },
  };

  return (
    <>
      <div className="relative">
        <div className="absolute d3phone"></div>
      </div>
      <BrowserView>
        <div className="pt-120 pb-60 bg-dark-blue">
          <div className="w-8/12 mx-auto">
            <div className="flex flex-wrap justify-center">
              {serviceText.map((o, i) => (
                <div className="w-1/3" key={i}>
                  <LabelBox icon={o.icon} en={o.textEN} th={o.textTH} />
                </div>
              ))}
            </div>
          </div>
          <div className="container mt-60">
            <div className="px-20">
              <Section
                title={
                  <>
                    Hero System
                    <br /> เครื่องมือขายคู่กายฮีโร่
                  </>
                }
                detail={
                  <>
                    สุดทันสมัยในยุคออนไลน์ สะดวก ง่าย
                    <br />
                    ช่วยให้เหล่าฮีโร่ปิดการขายได้ทุกเวลา
                  </>
                }
                image={SystemImg}
                icon="system"
              />
              <Section
                title={
                  <>
                    Hero Armory
                    <br /> คลังอาวุธฮีโร่
                  </>
                }
                detail={
                  <>
                    คัมภีร์ฝึกวิชางานขายประกันวินาศภัย
                    <br />
                    ตั้งแต่เบสิคไปจนถึงระดับมืออาชีพ
                  </>
                }
                image={ArmoryImg}
                icon="armory"
                iconAlign="right"
              />
              <Section
                title={
                  <>
                    {t('support')}
                    <br /> กำลังเสริมฮีโร่
                  </>
                }
                detail={
                  <>
                    ทีมงานระดับแนวหน้า
                    <br />
                    พร้อมไขปัญหาให้คุณทุกสมรภูมิงานขาย
                  </>
                }
                image={SupportImg}
                // icon="support"
              />
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="relative px-5 pt-60 xl:pt-120 pb-60 bg-dark-blue">
          <Slider {...settings}>
            {serviceTextMobile.map((o, i) => (
              <div
                className="flex flex-col items-center justify-center flex-1 space-y-20 text-center"
                key={i}
              >
                <img
                  src={o.icon}
                  alt=""
                  className="inline-block mx-auto transition-all duration-75"
                />
              </div>
            ))}
          </Slider>
          <div className="mt-16 text-lg font-normal text-center text-white">
            {serviceTextMobile[itemIndex].textEN} <br />
            {serviceTextMobile[itemIndex].textTH}
          </div>
          <MobileSection
            title={
              <>
                Hero System <br /> เครื่องมือขายคู่กายฮีโร่
              </>
            }
            detail={
              <>
                สุดทันสมัยในยุคออนไลน์ สะดวก ง่าย <br />
                ช่วยให้เหล่าฮีโร่ปิดการขายได้ทุกเวลา
              </>
            }
            image={SystemImg}
            icon="system"
          />
          <MobileSection
            title={
              <>
                Hero Armory
                <br /> คลังอาวุธฮีโร่
              </>
            }
            detail={
              <>
                คัมภีร์ฝึกวิชางานขายประกันวินาศภัย
                <br />
                ตั้งแต่เบสิคไปจนถึงระดับมืออาชีพ
              </>
            }
            image={ArmoryImg}
            icon="armory"
            iconAlign="left"
          />
          <MobileSection
            title={
              <>
                {t('support')}
                <br /> กำลังเสริมฮีโร่
              </>
            }
            detail={
              <>
                ทีมงานระดับแนวหน้า
                <br />
                พร้อมไขปัญหาให้คุณทุกสมรภูมิงานขาย
              </>
            }
            image={SupportImg}
            iconAlign="none"
          />
        </div>
      </MobileView>
    </>
  );
};

export default Services;
