import React from "react";
import classnames from "classnames";
import "./switch.scss";
const Switch = ({ active, size, ...rest }) => {
  return (
    <div
      className={classnames("switch-toggle", active ? "on" : "off", size ? size : "default")}
      {...rest}
    >
      <div className="bullet"></div>
    </div>
  );
};

export default Switch;
