import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import queryString from "query-string";

import SectionHeader from "/src/components/layout/staff/SectionHeader";
import SearchForm from "/src/components/staff/SearchForm";
import ErrorPage from "/src/components/errorPage";
import ReactPaginate from "react-paginate";
import Table from "./table/Table";

import useInput from "/src/hooks/useInput";
import useFetch from "/src/hooks/useFetch";
import { urlStringify } from "/src/helpers/helpers";


import "./list.scss";

const BASE_URL = "/staffs/renewals";
const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const List = (props) => {
  const { location } = props;
  const history = useHistory();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const { value: search_value, bind: handleChaneSearch, reset } = useInput(
    queryParams?.keyword ?? ""
  );

  const [queryStr] = useState(queryParams);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataLists, setDataLists] = useState([]);
  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(queryParams["page"] ?? DEFAULT_PAGE);


  const url = `/api/renewals?${urlStringify(Object.assign({
    page: DEFAULT_PAGE,
    per_page: PER_PAGE
  }, queryParams))}`;
  const { response, error, isLoading } = useFetch(url);

  useEffect(() => {
    if (!isLoading) {
      const results = response?.content;
      let totalPages = response?.total_pages;
      let totalRecords = response?.total_count;
      setTotalRecords(totalRecords);
      setPageCount(totalPages);
      setDataLists(results);
      setIsError(false);
    }
    if (error) {
      setIsError(true);
    }
  }, [
    response,
    error,
    isLoading,
    search_value,
    pageCount,
    queryStr,
    activePage,
  ]);

  const handleClickSearch = () => {
    performSearch()
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") performSearch()
  };

  const performSearch = () => {
    const queries = urlStringify({
      keyword: search_value,
      per_page: PER_PAGE,
      page: 1
    });
    window.location.href = `${BASE_URL}?${queries}`;
  }

  const handlePageClick = (e) => {
    const selectedPage = parseInt(e.selected) + 1;
    const queries = urlStringify({
      keyword: search_value,
      per_page: PER_PAGE,
      page: selectedPage
    });
    window.location.href = `${BASE_URL}?${queries}`;

  };

  return (
    <div className="renew-list">
      {isError ? (
        <ErrorPage />
      ) : (
        <>
          <SectionHeader
            title="กรมธรรม์ระบบเก่า"
            breadcrumbs={[{ url: "#", label: "กรมธรรม์", current: true }]}
            searchComponent={
              <SearchForm
                type="text"
                placeholder="ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่คำสั่งซื้อ"
                {...handleChaneSearch}
                handleClick={handleClickSearch}
                handleKeyPress={handleKeyPressSearch}
              />
            }
          />
          <hr />
          <div className="result-box">
            {totalRecords > 0 ? (
              <>
                {queryStr?.search ? (
                  <span>
                    คำค้นหา:
                    <strong style={{ padding: "0 5px" }}>
                      "{queryStr.search}" พบ
                    </strong>
                  </span>
                ) : null}
                <span>
                  {totalRecords > 20 ? (
                    <>
                      <span>รายการทั้งหมด 20+ รายการ</span>
                    </>
                  ) : (
                    <>
                      <span>ทั้งหมด</span>
                      <strong> {totalRecords}</strong> <span>รายการ</span>
                    </>
                  )}
                  {/* {PER_PAGE * activePage - PER_PAGE + 1} */}
                  {/* {totalRecords > PER_PAGE ? `-${PER_PAGE * activePage}` : null} */}
                </span>
              </>
            ) : null}
          </div>
          {!error ? <Table loading={isLoading} data={dataLists} /> : null}
          {pageCount > 1 && (
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              initialPage={(Number(currentPage) - 1)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"gm-paginate"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              disableInitialCallback={true}
            />
          )}
        </>
      )}
    </div>
  );
};

export default List;
