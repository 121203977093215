import _filter from "lodash/filter";
import _map from "lodash/map";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Radio } from "~src/components/form";
import UploadImages from "~src/components/form/uploadField/UploadImages";

import { removeFileFromFileList } from "~src/helpers/helpers";
import { messages } from "~src/helpers/messages";

interface UploadVehicleImageProps {
  id: string;
  name: string;
  radioName: string;
  vehicleImages: Array<Object>;
  attachImagesStatus: string;
  appName?: string;
  errors: any;
  //TODO: Check this with react-hook-form interfaces
  register: (target: HTMLElement, opts?: any) => any;
}

const UploadVehicleImage: React.FC<UploadVehicleImageProps> = ({
  id,
  name,
  errors,
  appName,
  radioName,
  vehicleImages,
  attachImagesStatus,
  register,
}) => {
  const { t } = useTranslation();
  const defaultAttachImagesStatus =
    process.env.APP_NAME == "mti" && attachImagesStatus == "not_attach"
      ? "attached"
      : attachImagesStatus;

  const [vehicleImageFiles, setVehicleImageFiles] = useState<File[] | Object[]>([]);
  const [deleteFileIds, setDeleteFileIds] = useState<Array<string>>([]);
  const [vehicleAttachOptions, setVehicleAttachOptions] =
    useState<string>(defaultAttachImagesStatus);
  const [previewImages, setPreviewImages] = useState<Array<string>>([]);

  useEffect(() => {
    const tempPreviewImages = [];

    if (vehicleImageFiles?.length > 0) {
      const extractFileField = (file) => ({
        url: URL.createObjectURL(file),
        name: file.name,
        type: file.type,
        imageFrom: "local",
      });

      tempPreviewImages.push(..._map(vehicleImageFiles || [], extractFileField));
    }

    if (vehicleImages?.length > 0) {
      const isFilePresent = (doc) => !deleteFileIds.includes(doc["blob_id"]);
      const extractFileField = (doc) => ({
        url: doc["url"],
        blobId: doc["blob_id"],
        name: doc["filename"],
        type: doc["dataType"],
        imageFrom: "external",
      });

      let vehicleImagePresent = _filter(vehicleImages || [], isFilePresent);
      tempPreviewImages.push(..._map(vehicleImagePresent || [], extractFileField));
    }

    setPreviewImages(tempPreviewImages);
  }, [vehicleImageFiles, deleteFileIds]);

  const handleChangeUpload = (e) => {
    const { files } = e.target;

    setVehicleImageFiles([...files]);
  };

  const handleChangeUploadOption = (e) => {
    const valueOption = e.target.value;

    if (valueOption !== "attached") setVehicleImageFiles([]);
    setVehicleAttachOptions(valueOption);
  };

  const handleDeleteFile = (inputRef, index) => {
    const tempVehicleImage = [...vehicleImageFiles];
    tempVehicleImage.splice(index, 1);

    setVehicleImageFiles(tempVehicleImage);
    removeFileFromFileList(inputRef, index);
  };

  const handleDeleteAttachedFile = (blobId) => {
    const tempDeleteFileIds = [...deleteFileIds];
    tempDeleteFileIds.push(blobId);

    setDeleteFileIds(tempDeleteFileIds);
  };

  return (
    <div className="block mt-10">
      <p className="my-10 text-lg">
        เอกสารตรวจสภาพรถ{" "}
        <small className="block" style={{ fontSize: "0.8rem" }}>
          หมายเหตุ : รองรับชนิดของไฟล์เป็น .PNG, .JPG, .JPEG, .PDF เท่านั้น
        </small>
      </p>

      <div className={`flex flex-wrap text-sm mb-10 ${appName == "hero" ? "hidden" : ""}`}>
        <Radio
          id="attach_image_vehicle"
          name={radioName}
          value="attached"
          onChange={handleChangeUploadOption}
          checked={vehicleAttachOptions === "attached"}
          label="แนบรูปสำหรับตรวจสภาพรถ"
        />
        <Radio
          id="check_by_officier"
          name={radioName}
          value="check_by_officier"
          onChange={handleChangeUploadOption}
          checked={vehicleAttachOptions === "check_by_officier"}
          label={
            <div className="flex-col">
              <p> ตรวจโดยเจ้าหน้าที่ (ไม่ต้องแนบรูป) </p>
              <p className="help-txt text-1xs">* ใช้เวลาในการตรวจสอบ 7 วันทำการ </p>
            </div>
          }
        />
      </div>
      {(t("appName") == "hero" || vehicleAttachOptions === "attached") && (
        <div id="div-upload-image" className="animated fadeIn">
          <input type="hidden" name={`${id}_deleted_ids`} ref={register} value={deleteFileIds} />

          <UploadImages
            id={id}
            name={name}
            buttonLabel="เพิ่มรูป"
            // files={vehicleImageFiles || []}
            previewImages={previewImages}
            errors={errors}
            multiple
            handleChangeUpload={handleChangeUpload}
            handleDeleteAttachedFile={handleDeleteAttachedFile}
            handleDeleteFile={handleDeleteFile}
            //TODO: Check this with react-hook-form interfaces
            registerValidate={(target, opts) =>
              register(target, {
                ...opts,
                ...messages.validateImagesFileType,
              })
            }
          />
        </div>
      )}
    </div>
  );
};

export default UploadVehicleImage;
