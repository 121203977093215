import React from "react";
interface Props {
  children: JSX.Element;
}
const Modal: React.FC<Props> = ({ children }) => {
  return (
    <div
      className="fixed top-0 left-0 flex items-center justify-center w-full h-full modal"
      style={{ zIndex: 1000 }}
    >
      <div className="absolute w-full h-full bg-gray-900 opacity-75 modal-overlay"></div>
      <div style={{height: "calc(100vh - 30px)"}} className="relative z-10 w-7/12 p-5 bg-white rounded-lg shadow-sm">
      <div style={{height: "calc(100vh - 60px)"}} className="w-full overflow-y-scroll">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
