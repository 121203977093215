import React, { useState } from "react";
import { UseFormMethods } from "react-hook-form";
import { FormGroup, ButtonCheckbox } from "~/src/components/form";
import { messages } from "~/src/helpers/messages";

interface Props extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  name?: string;
}

const WhTaxForm: React.FC<Props> = ({ name, register, errors }): JSX.Element => {
  const [includeWhTax, setIncludeWhTax] = useState<boolean>(true);
  return (
    <>
      <FormGroup label="หักภาษี ณ ที่จ่าย 1%" className="col-span-4">
        <ButtonCheckbox
          id={"include_wh_tax"}
          label={"หักภาษี ณ ที่จ่าย 1%"}
          name={`${name}.include_wh_tax`}
          className={{ label: "" }}
          inputRef={register}
          error={errors?.include_wh_tax?.message}
          active={includeWhTax}
          checked={includeWhTax}
          value={includeWhTax}
          onChange={() => setIncludeWhTax(!includeWhTax)}
        />
      </FormGroup>
    </>
  );
};

export default WhTaxForm;