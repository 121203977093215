import React from "react";
import PropTypes from "prop-types";

const SaleSummaryTable = (props) => {
  const {loading, data} = props;

  return (
    <table className="text-center bg-white-color mt-20">
      <thead>
      <tr className={"border-t border-b"}>
        <th className={"py-2 pl-3"}>ชื่อ</th>
        <th className="text-center pl-3">ได้รับมอบหมาย</th>
        <th className="text-center pl-3">ติดตาม</th>
        <th className="text-center pl-3">ไม่สามารถปิดการขาย</th>
        <th className="text-center pl-3">ปิดการขาย</th>
        <th className="text-center pl-3">ไม่รับสาย</th>
        <th className="text-center pl-3">รอชำระค่าเบี้ย</th>
        <th className="text-center pl-3">ลูกค้าไม่ต้องการให้ติดต่อ</th>
        <th className="text-center pl-3">ข้อมูลไม่อัพเดท</th>
        <th className="text-center pl-3">รายชื่อซ้ำ</th>
      </tr>
      </thead>
      <tbody>
      {loading && <tr className="text-center"><td rowspan="10">กำลังโหลด...</td></tr>}
      {!loading && (
        data.map((summary, index) => (
            <tr key={index} className={"border-t"}>
              <td className={"py-2"}>{summary.email}</td>
              <td className="text-center">{summary.summary_lead_status.assigned}</td>
              <td className="text-center">{summary.summary_lead_status.follow_up}</td>
              <td className="text-center">{summary.summary_lead_status.no_sale}</td>
              <td className="text-center">{summary.summary_lead_status.yes_sale}</td>
              <td className="text-center">{summary.summary_lead_status.callback}</td>
              <td className="text-center">{summary.summary_lead_status.waiting_for_payment}</td>
              <td className="text-center">{summary.summary_lead_status.do_not_contact}</td>
              <td className="text-center">{summary.summary_lead_status.not_update}</td>
              <td className="text-center">{summary.summary_lead_status.duplicate}</td>
            </tr>
          )
        ))}
      </tbody>
    </table>
  )
}

SaleSummaryTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default SaleSummaryTable;
