import React, { useEffect, useState, ChangeEvent, useMemo } from "react";
import { UseFormMethods } from "react-hook-form";
import _debounce from "lodash/debounce";
import Fuse from "fuse.js";
import { FormGroup, TextField } from "~/src/components/form";
import { messages } from "~/src/helpers/messages";
import AutoComplete from "~/src/components/form/autoCompleteAddress";
import locationJson from "~/src/assets/database.json";
import { CustomerAddress } from "~src/types/customerInfo";

type SelectableAddressKey = "province" | "postcode" | "district" | "sub_district"

interface Props
  extends Partial<
    Pick<UseFormMethods, "register" | "errors" | "control" | "setValue" | "clearErrors">
  > {
  name?: string;
  readOnly?: boolean;
  includeRoad?: boolean;
  value?: any;
  onChange?: (address: CustomerAddress) => void;
  require?: boolean;
}

const AddressForm: React.FC<Props> = ({
  name,
  readOnly = false,
  errors,
  setValue,
  register,
  clearErrors,
  includeRoad = false,
  value,
  onChange,
  require = true,
}): JSX.Element => {
  const [results, setResults] = useState([]);
  const [showDropDownZipcode, setShowDropdownZipcode] = useState<boolean>(false);
  const [showDropDownProvince, setShowDropdownProvince] = useState<boolean>(false);
  const [showDropDownDistrict, setShowDropdownDistrict] = useState<boolean>(false);
  const [showDropDownSubDistrict, setShowDropdownSubDistrict] = useState<boolean>(false);

  const clickOutside = (status: boolean) => {
    setShowDropdownZipcode(status);
    setShowDropdownProvince(status);
    setShowDropdownDistrict(status);
    setShowDropdownSubDistrict(status);
  };

  const isSelectableAddressType = (name: string) => {
    return ["province", "postcode", "district", "sub_district"].includes(name);
  }

  const searchByText = (text: string, addressType: string) => {
    if (addressType === "postcode") addressType = "zipcode";
    const options = { keys: [addressType], isCaseSensitive: true, useExtendedSearch: true };
    const fuse = new Fuse(locationJson, options);
    const regexPrefix = addressType === "zipcode" ? "^" : "'";
    const searchResult = fuse.search(`${regexPrefix}${text}`);

    return searchResult.map((data) => data.item);
  };

  const handleChangeText = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    name: keyof Omit<CustomerAddress, "id">,
    callback?: (i: boolean) => void
  ) => {
    fillData({
      ...value,
      [name]: event.target.value
    })
    
    if (!!callback && isSelectableAddressType(name)) debounceHandleChangeAddress(event, name, callback)
  };

  const changeAddressCallback = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    addressType: SelectableAddressKey,
    callback: (i: boolean) => void
  ) => {
    const { value } = event.target;
    
    let searchResult = [];
    let show = false;

    if (value !== "") {
      searchResult = searchByText(value, addressType);
      show = true;
    } else {
      searchResult = [];
    }

    setResults(searchResult);
    callback(show);
  };

  const debounceHandleChangeAddress = useMemo(
    () => _debounce(changeAddressCallback, 500),
    [locationJson]
  );

  const fillData = (data) => {
    let newData = JSON.parse(JSON.stringify(data))
    
    if (newData?.zipcode) {
      newData.postcode = newData?.zipcode;
      delete newData.zipcode
    }
    
    if (!!onChange && !!value) {
      let address: CustomerAddress = {
        ...value,
        ...newData
      }
      
      onChange(address);
    }

    if (!!setValue) {
      setValue(`${name}${name && "."}postcode`, newData?.postcode);
      setValue(`${name}${name && "."}province`, newData?.province);
      setValue(`${name}${name && "."}district`, newData?.district);
      setValue(`${name}${name && "."}sub_district`, newData?.sub_district);
    }

    if (!!clearErrors) {
      clearErrors([
        `${name}${name && "."}sub_district`,
        `${name}${name && "."}district`,
        `${name}${name && "."}province`,
        `${name}${name && "."}postcode`,
      ]);
    }
  };

  useEffect(() => {
    return () => {
      debounceHandleChangeAddress.cancel();
    };
  }, []);

  return (
    <>
      <FormGroup label="ที่อยู่ / บริษัท" className="col-span-4" require={require}>
        <TextField
          name={`${name}${name && "."}address`}
          inputRef={require ? register(messages.required) : register}
          error={errors?.address?.message}
          value={value?.address}
          onChange={(evt) => handleChangeText(evt, "address")}
        />
      </FormGroup>
      {includeRoad && (
        <FormGroup label="ถนน" className="col-span-4">
          <TextField
            name={`${name}${name && "."}road`}
            inputRef={register}
            error={errors?.road?.message}
            value={value?.road}
            onChange={(evt) => handleChangeText(evt, "road")}
          />
        </FormGroup>
      )}
      <FormGroup label="ตำบล" className="col-span-2" require={require}>
        <AutoComplete
          show={showDropDownSubDistrict}
          data={results}
          name={`${name}${name && "."}sub_district`}
          inputRef={require ? register(messages.required) : register}
          error={errors?.sub_district?.message}
          readOnly={readOnly}
          cbOutsideClick={clickOutside}
          cbData={fillData}
          onChange={(e) => handleChangeText(e, "sub_district", setShowDropdownSubDistrict)}
          value={value?.sub_district}
        />
      </FormGroup>
      <FormGroup label="อำเภอ" className="col-span-2" require={require}>
        <AutoComplete
          show={showDropDownDistrict}
          data={results}
          name={`${name}${name && "."}district`}
          inputRef={require ? register(messages.required) : register}
          error={errors?.district?.message}
          readOnly={readOnly}
          cbOutsideClick={clickOutside}
          cbData={fillData}
          onChange={(e) => handleChangeText(e, "district", setShowDropdownDistrict)}
          value={value?.district}
        />
      </FormGroup>
      <FormGroup label="จังหวัด" className="col-span-2" require={require}>
        <AutoComplete
          show={showDropDownProvince}
          data={results}
          name={`${name}${name && "."}province`}
          inputRef={require ? register(messages.required) : register}
          error={errors?.province?.message}
          readOnly={readOnly}
          cbOutsideClick={clickOutside}
          cbData={fillData}
          onChange={(e) => handleChangeText(e, "province", setShowDropdownProvince)}
          value={value?.province}
        />
      </FormGroup>
      <FormGroup label="รหัสไปรษณีย์" className="col-span-2" require={require}>
        <AutoComplete
          show={showDropDownZipcode}
          maxLength={5}
          data={results}
          name={`${name}${name && "."}postcode`}
          inputRef={require ? register(messages.required) : register}
          error={errors?.postcode?.message}
          readOnly={readOnly}
          cbOutsideClick={clickOutside}
          cbData={fillData}
          onChange={(e) => {
            if (e.target.value.match(/^[0-9]*$/g) || e.target.value === "") {
              handleChangeText(e, "postcode", setShowDropdownZipcode);
            }
          }}
          value={value?.postcode}
        />
      </FormGroup>
    </>
  );
};

export default AddressForm;
