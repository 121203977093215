import React from 'react'
import classnames from 'classnames'
import './highlightText'

export type headerText = {
  text: string
  highlight: boolean
  newline?: boolean
}

interface HighlightableProps {
  headerText: headerText[]
  size?: '2xl' | '3xl'
}

const HighlightText: React.FC<HighlightableProps> = ({ headerText = [], size = '2xl' }) => {
  const highlightClass = {
    '2xl': 'highlight-2xl',
    '3xl': 'highlight-3xl',
  }[size]

  return (
    <>
      {headerText.map((i, index) => {
        const TextTagElement = (i.newline ? 'p' : 'span') as keyof JSX.IntrinsicElements

        return (
          <TextTagElement key={index} className={classnames(i.highlight ? highlightClass : 'relative z-10')}>
            {i.text}
          </TextTagElement>
        )
      })}
    </>
  )
}

export default HighlightText
