import React, { useEffect, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import dataYml from "~/src/data.yaml";
import { Option } from "~/src/types/basic";
import CustomDropdown from "~/src/components/Forms/CustomDropdown";

interface Props extends Partial<Pick<UseFormMethods, "control" | "errors">> {
  name: string;
  className?: string;
  callbackOnchange?: (value: string) => void;
}

const ProductTypeInput: React.FC<Props> = ({
  name,
  className,
  control,
  errors,
  callbackOnchange,
}): JSX.Element => {
  const [options, setOptions] = useState<Option<string>[]>([]);

  const convertDataToOption = (mainTypesData: string[]): Option<string>[] => {
    return mainTypesData.map((mainTypeData) => {
      return {
        label: mainTypeData,
        value: mainTypeData,
      };
    });
  };

  useEffect(() => {
    const results = convertDataToOption(Object.keys(dataYml.order_custom_type));
    setOptions(results);
  }, []);

  return (
    <CustomDropdown
      required
      isSearchable
      className={className}
      name={name}
      control={control}
      errors={errors}
      options={options}
      callbackOnChange={(value: string) => callbackOnchange?.(value)}
    />
  );
};

export default ProductTypeInput;