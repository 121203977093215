import React, { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  acceptedContractSelector,
  agenRoleSelector,
  contractViewSelector,
  getContractNewAgent,
  handleChangeAcceptContract,
  handleChangeContractId,
  handleChangeStep,
} from "./registerSlice";
import { unEscapeHtml } from "~src/helpers/helpers";
import { Checkbox } from "~src/components/form";
import { contractStatusSelector } from "../contracts";

const Contract = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(agenRoleSelector);
  const contract = useAppSelector(contractViewSelector);
  const isAcceptedContract = useAppSelector(acceptedContractSelector);
  const contactResponseStatus = useAppSelector(contractStatusSelector);

  React.useEffect(() => {
    dispatch(getContractNewAgent(role));
  }, [role]);

  const handleOk = () => {
    if (!isAcceptedContract) {
      alert("กรุณากดยอมรับ สัญญาสมาชิก");
      return;
    }

    dispatch(handleChangeContractId(Number(contract.data.id)));
  };

  return (
    <div className="w-full px-2">
      <div className="flex flex-wrap items-center justify-between w-full col-span-1 col-start-1 bg-white shadow-sm mb-3 rounded-5 p-24">
        {contract.open && (
          <div
            className="detail-wrapper [word-break:break-word]"
            style={{ wordBreak: "break-word"}}
            dangerouslySetInnerHTML={{ __html: screen.width >= 640 ? unEscapeHtml(contract.data.detail) : contract.data.detail }}
          />
        )}

        <div className="flex w-full justify-center my-30">
          <Checkbox
            id="accept_contract"
            name="accept_contract"
            label={"ยอมรับ สัญญาสมาชิก"}
            checked={isAcceptedContract}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              dispatch(handleChangeAcceptContract(e.target.checked))
            }
          />
        </div>
      </div>

      <div className="w-full grid grid-cols-2 gap-4 my-30">
        <button
          type="button"
          onClick={() => dispatch(handleChangeStep(0))}
          className="px-3 py-2 w-full md:max-w-xs justify-self-start text-base transition duration-200 text-white bg-gray-300 rounded-lg hover:bg-gray-600"
        >
          ย้อนกลับ
        </button>
        <button
          type="button"
          onClick={handleOk}
          className="px-3 py-2 w-full md:max-w-xs justify-self-end text-base transition duration-200 text-white bg-primary-color rounded-lg hover:bg-primary-color-dark"
          disabled={contactResponseStatus === "pending"}
        >
          ถัดไป
        </button>
      </div>
    </div>
  );
};

export default Contract;
