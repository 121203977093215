const Renewals = {
    notice: [
        { label: "ต่อประกันกับที่อื่นไปแล้ว", value: "ต่อประกันกับที่อื่นไปแล้ว" },
        { label: "ขายรถ", value: "ขายรถ" },
        {
          label: "มีเคลมหลังใบเตือน / เคลมสูง",
          value: "มีเคลมหลังใบเตือน / เคลมสูง",
        },
        { label: "เบี้ยประกันภัยแพง", value: "เบี้ยประกันภัยแพง" },
        { label: "ทุนประกันภัยลดลง", value: "ทุนประกันภัยลดลง" },
        { label: "ต้องการเปลี่ยนแบบประกัน", value: "ต้องการเปลี่ยนแบบประกัน" },
        { label: "ต้องการเปลี่ยนบริษัทประกัน", value: "ต้องการเปลี่ยนบริษัทประกัน" },
        { label: "ต้องการเปลี่ยนตัวแทน", value: "ต้องการเปลี่ยนตัวแทน" },
        { label: "ไม่ต้องการทำประกัน", value: "ไม่ต้องการทำประกัน" },
        { label: "บริษัทประกันปฏิเสธรับประกัน", value: "บริษัทประกันปฏิเสธรับประกัน" },
        { label: "เหตุผลส่วนตัว", value: "เหตุผลส่วนตัว" },
        { label: "อื่นๆ", value: "อื่นๆ" },
    ]
}

export default Renewals
