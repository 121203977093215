import TravelProduct from "~src/models/TravelProduct";
import { CoverageDetailsProp } from "~src/types/product";

export const msigCoverageTravel = (product: TravelProduct): CoverageDetailsProp[] => {
  return [
    {
      coverageType: "ความคุ้มครองค่ารักษาพยาบาล",
      details: [
        {
          name: "ค่ารักษาพยาบาล",
          sumInsured: "",
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 1-14 ปี",
          sumInsured: product.displayValue(
            product.coverages.optional?.overseasMedicalExpensesCoverage114
          ),
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 15-75 ปี",
          sumInsured: product.displayValue(
            product.coverages.optional?.overseasMedicalExpensesCoverage1575
          ),
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 76-80 ปี",
          sumInsured: product.displayValue(
            product.coverages.optional?.overseasMedicalExpensesCoverage7680
          ),
        },
        {
          name: "ค่ารักษาพยาบาลต่อเนื่องหลังจากกลับไทย",
          sumInsured: "",
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 1-14 ปี",
          sumInsured: product.displayValue(
            product.coverages.optional?.followUpMedicalExpensesCoverage114
          ),
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 15-75 ปี",
          sumInsured: product.displayValue(
            product.coverages.optional?.followUpMedicalExpensesCoverage1575
          ),
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 76-80 ปี",
          sumInsured: product.displayValue(
            product.coverages.optional?.followUpMedicalExpensesCoverage7680
          ),
        },
        {
          name: "เคลื่อนย้ายฉุกเฉิน",
          sumInsured: product.displayValue(
            product.coverages.emergencyEvacuationAndRepatriationCoverage
          ),
        },
        {
          name: "ค่าชดเชยรายวัน",
          sumInsured: product.displayValue(
            product.coverages.overseasDailyHospitalConfinementBenefitCoverage
          ),
        },
      ],
    },
    {
      coverageType: "ความคุ้มครองกรณีเสียชีวิต",
      details: [
        {
          name: "ชดเชยกรณีเสียชีวิต",
          sumInsured: "",
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 1-14 ปี",
          sumInsured: product.displayValue(product.coverages.optional?.dismembermentCoverage114),
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 15-75 ปี",
          sumInsured: product.displayValue(product.coverages.optional?.dismembermentCoverage1575),
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 76-80 ปี",
          sumInsured: product.displayValue(product.coverages.optional?.dismembermentCoverage7680),
        },
        {
          name: "ค่าใช้จ่ายในการส่งศพกลับประเทศ",
          sumInsured: product.displayValue(product.coverages.mortalRemainRepatriationCoverage),
        },
      ],
    },
  ];
};

export const mtiCoverageTravel = (product: TravelProduct): CoverageDetailsProp[] => {
  return [
    {
      coverageType: "ความคุ้มครองค่ารักษาพยาบาล",
      details: [
        {
          name: "ค่ารักษาพยาบาล",
          sumInsured: "",
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 1-75 ปี",
          sumInsured: product.displayValue(
            product.coverages.optional?.overseasMedicalExpensesCoverage175
          ),
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 76-80 ปี",
          sumInsured: product.displayValue(
            product.coverages.optional?.overseasMedicalExpensesCoverage7680
          ),
        },
        {
          name: "ค่ารักษาพยาบาลต่อเนื่องหลังจากกลับไทย",
          sumInsured: "",
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 1-75 ปี",
          sumInsured: product.displayValue(
            product.coverages.optional?.followUpMedicalExpensesCoverage175
          ),
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 76-80 ปี",
          sumInsured: product.displayValue(
            product.coverages.optional?.followUpMedicalExpensesCoverage7680
          ),
        },
        {
          name: "เคลื่อนย้ายฉุกเฉิน",
          sumInsured: product.displayValue(
            product.coverages.emergencyEvacuationAndRepatriationCoverage
          ),
        },
        {
          name: "ค่าชดเชยรายวัน",
          sumInsured: product.displayValue(
            product.coverages.overseasDailyHospitalConfinementBenefitCoverage
          ),
        },
      ],
    },
    {
      coverageType: "ความคุ้มครองกรณีเสียชีวิต",
      details: [
        {
          name: "ชดเชยกรณีเสียชีวิต",
          sumInsured: "",
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 1-75 ปี",
          sumInsured: product.displayValue(product.coverages.optional?.dismembermentCoverage175),
        },
        {
          name: "- ผู้เอาประกันที่มีอายุ 76-80 ปี",
          sumInsured: product.displayValue(product.coverages.optional?.dismembermentCoverage7680),
        },
        {
          name: "ค่าใช้จ่ายในการส่งศพกลับประเทศ",
          sumInsured: product.displayValue(product.coverages.mortalRemainRepatriationCoverage),
        },
      ],
    },
  ];
};
