import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "~src/store";
import { hideNotification, notificationSelector } from "./notificationSlice";
import { isArray } from "lodash";

const Notification = () => {
  const notification = useAppSelector(notificationSelector);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(hideNotification());
  };

  const renderMessage = () => {
    if (isArray(notification.message)) {
      return (
        <ul>
          {notification.message.map((msg, index) => (
            <li>{msg}</li>
          ))}
        </ul>
      );
    } else {
      return <>{notification.message}</>;
    }
  };

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity={notification.type} sx={{ width: "100%" }} onClose={handleClose}>
        {renderMessage()}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
