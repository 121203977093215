import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  Chip,
  Typography,
  IconButton,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "~src/store";
import { contractDataSelector, contractStatusSelector, openViewModal } from "./contractSlice";
import { fetchDeleteContract, fetchListContract } from "./apis";
import PreviewRoundedIcon from "@mui/icons-material/PreviewRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Preloading from "~src/components/loading/Preloading";

const ContractTable = () => {
  const status = useAppSelector(contractStatusSelector);
  const data = useAppSelector(contractDataSelector);
  const dispatch = useAppDispatch();

  const [preloading, setPreloading] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchListContract());
  }, []);

  const handleDelete = (id: number): void => {
    if (window.confirm("Are you sure you want to delete?")) {
      setPreloading(true);
      dispatch(fetchDeleteContract({ id }));
    }
  };

  return (
    <Paper sx={{ mt: 2 }} elevation={0}>
      {preloading && <Preloading />}
      {status === "fulfilled" && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>เวอร์ชั่น</TableCell>
              <TableCell sx={{ width: 200 }}>วันที่ประกาศใช้</TableCell>
              <TableCell sx={{ width: 200 }}>สร้างเมื่อ</TableCell>
              <TableCell sx={{ width: 100 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.content.map((o, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>{o.version}</Typography>
                    <Chip label={o.role} size="small" />
                  </Stack>
                </TableCell>
                <TableCell sx={{ width: 100 }}>{o.effective_date}</TableCell>
                <TableCell sx={{ width: 200 }}>{o.created_at}</TableCell>
                <TableCell sx={{ width: 100 }}>
                  <Stack direction="row">
                    <IconButton onClick={() => dispatch(openViewModal(o))} size="small">
                      <PreviewRoundedIcon />
                    </IconButton>
                    <IconButton href={`/staffs/contracts/edit/${o.id}`} size="small">
                      <EditRoundedIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(o.id)} color="error" size="small">
                      <DeleteRoundedIcon />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
};

export default ContractTable;
