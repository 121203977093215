import React, { useState, useContext } from "react";

import filterContext from "./store.js";

import Select from "../../../../components/filter/Select";
import yaml from "../../../../data.yaml";

const optionsLists = [
  {
    label: "ทั้งหมด",
    value: "all",
    isChecked: false,
  },
  {
    label: "มี",
    value: "has_excess",
    isChecked: false,
  },
  {
    label: "ไม่มี",
    value: "no_excess",
    isChecked: false,
  },
];

const ExcessDamageCoverageFilter = ({ fnCallbackFilterValues, active }) => {
  const [options, setOptions] = useState(optionsLists);
  const [state, dispatch] = useContext(filterContext);
  const [activeButton, setActiveButton] = useState(active);

  const handleChange = (value) => {
    const opts = [...options];
    let filterExcessDamageCoverage = [];

    opts.filter((o) => {
      o.isChecked = o.value === value && true;
      if (o.isChecked) filterExcessDamageCoverage = [o.value];
    });

    setOptions(opts);
    setActiveButton(true);
    dispatch({
      type: "UPDATE",
      payload: {
        ...state,
        filterExcessDamageCoverage,
      },
    });

    fnCallbackFilterValues("excessDamageCoverage", filterExcessDamageCoverage);
  };

  const renderLabel = (stateValues) => {
    if (stateValues.length == 0) {
      return "ค่าเสียหายส่วนแรก";
    } else {
      let label = null;
      if (stateValues[0] === "all") {
        label = (
          <>
            <span className="text-primary inline-block mr-1">
              {yaml.excess_damage_coverage.has_excess},
              {yaml.excess_damage_coverage.no_excess}
            </span>
            ค่าเสียหายส่วนแรก
          </>
        );
      } else {
        label = (
          <>
            <span className="text-primary inline-block mr-1">
              {yaml.excess_damage_coverage[stateValues[0]]}
            </span>
            ค่าเสียหายส่วนแรก
          </>
        );
      }
      return label;
    }
  };

  return (
    <div>
      <Select
        type="radio"
        label={renderLabel(state.filterExcessDamageCoverage)}
        options={options}
        active={activeButton}
        onChangeValue={handleChange}
      />
    </div>
  );
};

export default ExcessDamageCoverageFilter;
