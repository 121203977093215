import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  formatNumber,
  formatNumberWithCurrency,
} from "../../../../helpers/formatNumber";
import { ChartData, MonthMapping } from "./buildData";

const ValueNames = Object.freeze({
  motorValue: "ประกันภัยรถยนต์",
  otherValue: "ประกันภัยอื่นๆ",
});

interface Props {
  data: ChartData[];
}

export const DashboardChart: React.FC<Props> = ({ data }) => {
  return (
    <div>
      <div className="text-lg">รายงานสรุปยอดขายของท่าน</div>
      <div style={{ height: 300 }}>
        <ResponsiveContainer>
          <BarChart data={data} margin={{ left: 50 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="monthShort" />
            <YAxis
              type="number"
              tickFormatter={(amount) => formatNumber(amount)}
              unit="฿"
            />
            <Tooltip
              formatter={(value, name) => [
                formatNumberWithCurrency(value),
                name === "motorValue" ? "ประกันภัยรถยนต์" : "ประกันภัยอื่นๆ",
              ]}
              labelFormatter={(value) => {
                return null;
              }}
            />
            <Legend
              verticalAlign="top"
              height={50}
              iconType="circle"
              formatter={(name) =>
                name === "motorValue" ? "ประกันภัยรถยนต์" : "ประกันภัยอื่นๆ"
              }
            />
            <Bar
              isAnimationActive={true}
              dataKey="motorValue"
              stackId="a"
              fill="#112e42"
            />
            <Bar
              isAnimationActive={true}
              dataKey="otherValue"
              stackId="a"
              fill="#eb008b"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
