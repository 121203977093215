import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "~src/store";
import {
  fetchAcceptContract,
  fetchAgentContract,
  fetchCreateContract,
  fetchListContract,
  fetchNewAgentContract,
  fetchShowContract,
} from "./apis";
import { unEscapeHtml } from "~src/helpers/helpers";
import { format } from "date-fns";
import { isEmpty } from "lodash";

const initialState = {
  status: "idle",
  accepted: false,
  data: {
    count: 0,
    content: [],
  },
  error: "" as string | string[],
  form: {
    mode: "create",
    status: "idle",
    data: {
      id: "",
      version: "",
      detail: "",
      effective_date: format(new Date(), "dd/MM/yyyy"),
      role: "hero",
    },
    error: "" as string | string[],
  },
  view: {
    open: false,
    status: "idle",
    data: {
      id: "",
      version: "",
      detail: "",
      effective_date: "",
      role: "hero",
    },
  },
};

const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    changeRole: (state, { payload }: PayloadAction<"hero" | "knight">) => {
      state.form.data.role = payload;
    },
    setDetail: (state, { payload }: PayloadAction<string>) => {
      state.form.data.detail = payload;
    },
    setFormData: (state, { payload }: PayloadAction<{ name: string; value: string }>) => {
      state.form.data = {
        ...state.form.data,
        [payload.name]: payload.value,
      };
    },
    setEffectiveDate: (state, { payload }) => {
      state.form.data.effective_date = format(new Date(payload), "dd/MM/yyyy");
    },
    clearError: (state) => {
      state.form.status = "idle";
      state.form.error = "";
    },
    openViewModal: (state, { payload }: PayloadAction<any>) => {
      const detail = unEscapeHtml(payload.detail);
      state.view.open = true;
      state.view.data = { ...payload, detail };
    },
    closeViewModal: (state) => {
      state.view.open = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchListContract.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchListContract.fulfilled, (state, { payload }) => {
        state.status = "fulfilled";
        state.data = payload;
      });

    builder
      .addCase(fetchCreateContract.pending, (state) => {
        state.form.status = "pending";
      })
      .addCase(fetchCreateContract.fulfilled, (state) => {
        state.form.status = "fulfilled";
      })
      .addCase(fetchCreateContract.rejected, (state, { payload }) => {
        state.form.status = "rejected";
        state.form.error = payload.message;
      });

    builder
      .addCase(fetchShowContract.pending, (state) => {
        state.form.status = "pending";
      })
      .addCase(fetchShowContract.fulfilled, (state, { payload }) => {
        const data = { ...payload.content };
        data.detail = unEscapeHtml(data.detail);
        const [day, month, year] = data.effective_date.split("-");
        const formattedDate = `${day}/${month}/${year}`;
        data.effective_date = formattedDate;

        state.form = {
          ...state.form,
          mode: "edit",
          status: "fulfilled",
          data,
        };
      });

    builder
      .addCase(fetchAgentContract.pending, (state) => {
        state.view.status = "pending";
      })
      .addCase(fetchAgentContract.fulfilled, (state, { payload }) => {
        let accepted = true;
        let data = {
          id: "",
          version: "",
          detail: "",
          effective_date: "",
          role: "hero",
        };
        let open = false;
        if (!isEmpty(payload.content)) {
          accepted = false;
          data = { ...payload.content };
          data.detail = unEscapeHtml(data.detail);
          open = true;
        }
        state.accepted = accepted;
        state.view = {
          ...state.view,
          open,
          status: "fulfilled",
          data,
        };
      });

    builder.addCase(fetchAcceptContract.fulfilled, (state) => {
      let data = {
        id: "",
        version: "",
        detail: "",
        effective_date: "",
        role: "hero",
      };

      state.accepted = true;
      state.view = {
        ...state.view,
        open: false,
        status: "idle",
        data,
      };
    });

    builder
      .addCase(fetchNewAgentContract.pending, (state) => {
        state.view.status = "pending";
      })
      .addCase(fetchNewAgentContract.fulfilled, (state, { payload }) => {
        let accepted = true;
        let data = {
          id: "",
          version: "",
          detail: "",
          effective_date: "",
          role: "hero",
        };
        let open = false;
        if (!isEmpty(payload.content)) {
          accepted = false;
          data = { ...payload.content };
          data.detail = unEscapeHtml(data.detail);
          open = true;
        }
        state.accepted = accepted;
        state.view = {
          ...state.view,
          open,
          status: "fulfilled",
          data,
        };
      });
  },
});

export const {
  changeRole,
  setDetail,
  setFormData,
  setEffectiveDate,
  clearError,
  openViewModal,
  closeViewModal,
} = contractSlice.actions;

export const contractSelector = (state: RootState) => state.contract;
export const contractStatusSelector = (state: RootState) => state.contract.status;
export const contractDataSelector = (state: RootState) => state.contract.data;
export const contractFormSelector = (state: RootState) => state.contract.form;
export const contractViewSelector = (state: RootState) => state.contract.view;
export const contractShowAgenModalSelector = (state: RootState) => {
  return state.contract.status === "fulfilled" && state.contract.data.count > 0;
};
export const contractAcceptedSelector = (state: RootState) => state.contract.accepted;

export default contractSlice.reducer;
