import React from "react";
import helpers from "../../../helpers/renewal";
import { useForm } from "react-hook-form";

import { useAuthContext } from "~src/context/Auth";
import { useAuthenticate } from "~src/helpers/authen";

interface Props {
  open: boolean;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

const CancelOrder: React.FC<Props> = ({
  open,
  onSubmit,
  onClose,
}) => {
  const { register, errors, handleSubmit } = useForm();

  const { accessType } = useAuthenticate();

  return (
    <>
      {open && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-70"
            style={{ zIndex: 1000 }}
          >
            <div className="w-1/3 p-4 bg-white shadow rounded-5">
              <h6 className="mb-4">ยืนยันการยกเลิกรายการต่ออายุ</h6>
              <hr />
              <div>
                <label htmlFor="" className="block mb-2">
                  เหตุผลที่ไม่ต่ออายุ
                </label>
                <select
                  className="w-full px-3 py-2 border border-gray-200 rounded"
                  name="notice"
                  placeholder="เหตุผลที่ไม่ต่ออายุ"
                  ref={register({ required: "กรุณาเลือกเหตุผลที่ไม่ต่ออายุ" })}
                >
                  {accessType === "STAFF" && (
                    <option value="ยกเลิกเพื่อแก้ไขข้อมูล">
                      ยกเลิกเพื่อแก้ไขข้อมูล
                    </option>
                  )}
                  {helpers.notice.map((o, i) => (
                    <option value={o.value}>{o.label}</option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end mt-10">
                <button
                  type="button"
                  onClick={onClose}
                  className="inline-block px-3 pt-2 pb-2 mr-4 bg-gray-200 shadow rounded-5"
                >
                  ยกเลิก
                </button>
                <button
                  type="submit"
                  className="inline-block px-3 pt-2 pb-2 text-white bg-red-500 shadow rounded-5"
                >
                  ตกลง
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default CancelOrder;
