import { format, parse, parseJSON, differenceInDays } from "date-fns";
import _ from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { ToastProvider, useToasts } from "react-toast-notifications";
import useDeepCompareEffect from "use-deep-compare-effect";

import ShowPaySlipButton from "./ShowPaySlipButton";
import Badges from "../badges";
import Preloading from "../loading/Preloading";

import dataYml from "~src/data.yaml";
import statusYml from "~src/status.yml";
import OrdersAPIClient, { OrdersAPIClientProps } from "~src/api/Orders";
import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "~src/helpers/formatNumber.js";
import { urlStringify, getInsuredByCategory } from "~src/helpers/helpers";
import { ISO_DATETIME_FORMAT, parseDate, reformatDateEN } from "~src/helpers/date";
import { renderYaml } from "~src/helpers/renderYaml";
import useInput from "~src/hooks/useInput";
import { Order } from "~src/types/order";
import { Payment } from "~src/types/payment";

import "./index.scss";

export type PaymentAction = (payment: Payment) => void;
export type PaymentActionsRenderer = (payment: Payment) => React.ReactNode;
export type PaySlipAction = (url: string | URL, orderID: number, payment?: Payment) => void;

export interface PaymentsListViewProps extends React.ComponentPropsWithoutRef<"div"> {
  payments: Payment[];
  order: Order;
  // onConfirmPaySlip?: PaymentAction;
  renderSectionHeader?: () => React.ReactNode;
  renderTableLayout?: (props: PaymentsTableLayoutProps) => React.ReactNode;
  renderActions?: PaymentActionsRenderer;
  onShowPaySlipModal?: PaySlipAction;
  layoutProps?: Partial<PaymentsTableLayoutProps>;
}

export default function PaymentsListView({
  payments,
  order,
  // onConfirmPaySlip,
  renderSectionHeader,
  renderTableLayout,
  renderActions,
  onShowPaySlipModal,
  className,
  layoutProps,
  ...htmlProps
}: PaymentsListViewProps) {
  const tableLayoutProps = {
    ...layoutProps,
    payments,
    order,
    onShowPaySlipModal,
    // onConfirmPaySlip,
    renderActions,
  };

  return (
    <div {...htmlProps} className={`payments-wrapper ${className || ""}`}>
      {renderSectionHeader && renderSectionHeader()}
      {renderTableLayout ? (
        <>{renderTableLayout(tableLayoutProps)}</>
      ) : (
        <PaymentsListViewDefaultTableLayout {...tableLayoutProps} />
      )}
      {/* {isError && <Alert message={errorMsg} />} */}
    </div>
  );
}

export interface PaymentsTableLayoutProps {
  payments: Payment[];
  order: Order;
  renderActions?: PaymentActionsRenderer;
  onShowPaySlipModal?: PaySlipAction;
  rowProps?: Partial<PaymentsTableHeaderRowProps>;
}

export function PaymentsListViewDefaultTableLayout({
  payments,
  order,
  onShowPaySlipModal,
  renderActions,
  rowProps,
}: PaymentsTableLayoutProps) {
  return (
    <div className="view-payments-component w-full justify-center">
      <PaymentsListViewDefaultTableHeaderRow {...rowProps} />
      {payments.length == 0 && (
        <div className="grid-col-span-5 text-secondary-color">ไม่พบข้อมูล</div>
      )}
      {payments.map((payment, index) => (
        <PaymentsListViewDefaultTableRow
          {...rowProps}
          key={index}
          payment={payment}
          order={order}
          onShowPaySlipModal={onShowPaySlipModal}
          renderActions={renderActions}
        />
      ))}
    </div>
  );
}

export interface PaymentsTableHeaderRowProps extends React.ComponentPropsWithoutRef<"div"> {
  cellClassName?: string;
}

export function PaymentsListViewDefaultTableHeaderRow({
  className,
  cellClassName,
  ...htmlProps
}: PaymentsTableHeaderRowProps) {
  return (
    <div {...htmlProps} className={`grid grid-cols-5 gap-2 ${className || ""}`}>
      <div className={`${cellClassName || ""}`}>รายการจ่ายเงิน/สถานะ</div>
      <div className={`text-right ${cellClassName || ""}`}>จำนวน</div>
      <div className={`text-center ${cellClassName || ""}`}>วันที่ชำระ</div>
      <div className={`text-center ${cellClassName || ""}`}>ช่องทาง</div>
      <div className={`${cellClassName || ""}`}></div>
    </div>
  );
}

export interface PaymentDerivedFields {
  displayStatus?: string;
  paidAmount?: number;
  displayPaidDate?: Date;
}

export function getPaymentDerivedFields(payment: Payment): PaymentDerivedFields {
  const paidAmount = payment.paid_amount ? Number(payment.paid_amount) : undefined;
  const displayStatus =
    payment.status === "pending" && paidAmount ? "waiting_verify" : payment.status;
  const displayPaidDate =
    (payment.paid_at as Date) ||
    (displayStatus === "waiting_verify" ? (payment.created_at as Date) : undefined);

  return {
    displayStatus,
    paidAmount,
    displayPaidDate,
  };
}

export interface PaymentsTableRowProps extends PaymentsTableHeaderRowProps, PaymentDerivedFields {
  payment: Payment;
  paymentDerivedFields?: PaymentDerivedFields;
  order?: Order;
  onShowPaySlipModal?: PaySlipAction;
  renderActions?: PaymentActionsRenderer;
}

export function PaymentsListViewDefaultTableRow({
  payment,
  paymentDerivedFields,
  order,
  onShowPaySlipModal,
  renderActions,
  className,
  cellClassName,
  ...htmlProps
}: PaymentsTableRowProps) {
  const cellProps = {
    payment,
    paymentDerivedFields,
    ...getPaymentDerivedFields(payment),
    className: (cellClassName = ""),
  };

  return (
    <div {...htmlProps} className={`grid grid-cols-5 gap-2 mb-1 ${className || ""}`}>
      <PaymentStatusCell {...cellProps} />
      <PaymentAmountCell {...cellProps} />
      <PaymentPaidDateCell {...cellProps} />
      <PaymentMethodCell
        {...cellProps}
        order={order}
        onShowPaySlipModal={onShowPaySlipModal}
        className={`flex-wrap ${cellClassName}`}
      />
      <PaymentActionsCell {...cellProps} renderActions={renderActions} />
    </div>
  );
}

//TODO: Revise this later

export function PaymentStatusCell({ displayStatus, className }: PaymentsTableRowProps) {
  return (
    <div className={`flex flex-wrap content-start justify-center text-center ${className || ""}`}>
      <Badges module="payment" status={`payment-status payment-status-${displayStatus}`}>
        {statusYml.paymentStatus[displayStatus]}
      </Badges>
    </div>
  );
}

export function PaymentAmountCell({ paidAmount, className }: PaymentsTableRowProps) {
  return (
    <div className={`flex content-start justify-end text-end ${className || ""}`}>
      {formatNumberWithCurrency(paidAmount)}
    </div>
  );
}

export function PaymentPaidDateCell({ displayPaidDate, className }: PaymentsTableRowProps) {
  return (
    <div className={`flex content-start justify-center text-center ${className || ""}`}>
      {displayPaidDate ? reformatDateEN(displayPaidDate) : null}
    </div>
  );
}

export function PaymentMethodCell({
  payment,
  order,
  onShowPaySlipModal,
  renderActions,
  className = "",
}: PaymentsTableRowProps) {
  return (
    <div className={`flex flex-col w-full ${className}`}>
      <p className="flex flex-row content-start justify-start text-center gap-1 ">
        {/* TODO: Revise the UI later (this is from master/Production) */}
        {payment.pay_method && (
          <span className="flex">
            {/* <Badges module="payment" status={`payment-method payment-method-${payment.pay_method}`}> */}
            {statusYml.paymentType[payment.pay_method]}
            {/* </Badges> */}
          </span>
        )}
        {payment.payslip_url && (
          <span className="flex flex-shrink">
            {/* <Badges module="payment" status="payment-method"> */}
            <ShowPaySlipButton
              title={payment.pay_method === "bank_transfer" ? "ใบสลิปการโอน" : "ใบสลิป"}
              disabled={!onShowPaySlipModal}
              onClick={() =>
                onShowPaySlipModal &&
                onShowPaySlipModal(payment.payslip_url, order.order_id, payment)
              }
            />
            {/* </Badges> */}
          </span>
        )}
        {order.payer_id_card_url && payment.installment_seq_no <= 1 && (
          <span className="flex flex-shrink">
            {/* <Badges module="payment" status="payment-method"> */}
            <ShowPaySlipButton
              title="บัตรประชาชน"
              disabled={!onShowPaySlipModal}
              onClick={() =>
                onShowPaySlipModal &&
                onShowPaySlipModal(order.payer_id_card_url, order.order_id, payment)
              }
            />
            {/* </Badges> */}
          </span>
        )}
        {renderActions && renderActions(payment)}
      </p>
      {order.payments_summary.is_installments && (
        <>
          <p className="flex flex-row content-start justify-start text-center gap-1 ml-1">
            งวดที่ {payment.installment_seq_no} ยอดเงิน{" "}
            {formatNumberWithCurrency(payment.installment_amount)}
          </p>
          <p className="flex flex-row content-start justify-start text-center gap-1 ml-1">
            ครบกำหนด {payment.installment_due_at ? reformatDateEN(payment.installment_due_at) : "-"}
          </p>
        </>
      )}
    </div>
  );
}

export function PaymentActionsCell({ payment, renderActions, className }: PaymentsTableRowProps) {
  return (
    <div className={`payment-actions ${className || ""}`}>
      {renderActions ? renderActions(payment) : null}
    </div>
  );
}

function Image({ icon }) {
  return <img className="insurer-img" src={icon} />;
}

function diffDate(order) {
  const now = new Date();
  return differenceInDays(order.expired_at || now, now);
}
//DEPRECATED: Augmenting types complicate things with TS
// const diffDate = (contents) => {
//   const orders = contents.map((content) => {
//     content.diffDate = function () {
//       //TODO: Revise type later
//       let result: any[] | number = [];
//       var today = new Date();
//       let expiredDate = content.expired_at;
//       var expired = parse(expiredDate, "yyyy-MM-dd", today);

//       result = differenceInDays(expired, today);

//       return result;
//     };
//     return content;
//   });

//   return orders;
// };
