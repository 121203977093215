import React, { useState, useRef, useEffect } from "react";

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const AutoComplete = ({ data, show, cbOutsideClick, cbData, inputRef, error, ...rest }) => {
  const [showDropdown, setShowDropdown] = useState(show);
  const ref = useRef();
  useOutsideClick(ref, () => {
    if (showDropdown) {
      setShowDropdown(false);
      cbOutsideClick(false);
    }
  });

  useEffect(() => {
    setShowDropdown(show);
  }, [show]);

  const handleClick = (data) => {
    cbData(data);
    cbOutsideClick(false);
  };

  return (
    <div className="location-search" ref={ref}>
      <input
        type="text"
        ref={inputRef}
        className={!error ? "location-search-input" : "location-search-input has-error"}
        {...rest}
      />
      {showDropdown ? (
        <ul className="location-search-input-items">
          {data.map((o, i) => (
            <li key={i}>
              <button type="button" onClick={() => handleClick(o)}>
                {o.sub_district}
                <i className="material-icons">arrow_right</i>
                {o.district}
                <i className="material-icons">arrow_right</i>
                {o.province}
                <i className="material-icons">arrow_right</i>
                {o.zipcode}
              </button>
            </li>
          ))}
        </ul>
      ) : null}
      {error ? <p className="form-input-error">กรุณากรอกข้อมูลให้ถูกต้อง</p> : ""}
    </div>
  );
};

export default AutoComplete;
