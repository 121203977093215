import React, { useState, useEffect } from "react";
import axios from "~src/helpers/configAxios";

export interface SecureImgProps {
  img: string;
  onError?: any;
}

const SecureLinkImg = ({ src, onError, ...rest }) => {
  const [imageSource, setImageSource] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (src) {
      setLoading(true);
      axios
        .get(src, {
          responseType: "arraybuffer",
        })
        .then((result) => {
          const base64String =
            "data:" +
            result.headers["content-type"] +
            ";base64," +
            Buffer.from(result.data, "binary").toString("base64");
          setImageSource(base64String);
          setLoading(false);
        });
    }
  }, [src]);

  return <>{!loading && <img src={imageSource} key={imageSource} onError={onError} {...rest} />}</>;
};

export default SecureLinkImg;
