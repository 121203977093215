import React, { useState, useRef, useCallback } from "react";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import ButtonToggleDropdown from "./ButtonToggleDropdown";
import Checkbox from "./Checkbox";

const DropdownListIcon = ({
  label,
  options,
  active,
  toggleAllOption,
  onChangeValue,
}) => {
  const buttonRef = useRef();
  const dropDownRef = useRef();
  const [isToggleDropdown, setToggleDropdown] = useState(false);

  useOnClickOutside(dropDownRef, () => {
    if (!buttonRef.current.contains(event.target)) {
      setToggleDropdown(false);
    }
  });

  const handleToggleDropdown = useCallback(() => {
    setToggleDropdown(!isToggleDropdown);
  }, [isToggleDropdown]);

  return (
    <div className="select-component">
      <ButtonToggleDropdown
        buttonRef={buttonRef}
        active={active}
        label={label}
        onClick={handleToggleDropdown}
      />
      {isToggleDropdown && (
        <div
          className={`select-component-list fix-width overflow`}
          ref={dropDownRef}
        >
          {toggleAllOption && (
            <Checkbox
              label={toggleAllOption.label}
              value={toggleAllOption.value}
              checked={toggleAllOption.isChecked}
              onChangeValue={onChangeValue}
            />
          )}
          {options.map((option, index) => (
            <Checkbox
              key={index}
              label={option.label}
              value={option.value}
              icon={option.icon}
              checked={option.isChecked}
              onChangeValue={onChangeValue}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownListIcon;
