import React from "react";
import { getDate, getDay, getMonth, format } from "date-fns";

interface Props {
  data: Data[];
}

interface Data {
  title: string | null;
  link: string | null;
  from_date: string;
  place: string;
  to_date: string;
  start_time: string;
  end_time: string;
  people: string;
}

interface DayMonth {
  days: string[];
  months: string[];
}

const dayMonths: DayMonth = {
  days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],
  months: [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
  ],
};

const DayBox: React.FC<{ date: string | null }> = ({ date }) => {
  return (
    <>
      {date && (
        <div className="overflow-hidden border border-primary-color rounded-md">
          <span className="block text-center text-white text-3xs bg-primary-color">
            {dayMonths.days[getDay(new Date(date))]}
          </span>
          <span className="block text-sm font-bold text-center">
            {getDate(new Date(date))}
          </span>
          <span className="block -mt-2 text-center text-2xs">
            {dayMonths.months[getMonth(new Date(date))]}
          </span>
        </div>
      )}
    </>
  );
};

const TrainingList: React.FC<Props> = ({ data }) => {
  return (
    <div className="p-2">
      {!data ? (
        <div className="flex animate-pulse">
          <div className="w-full h-10 bg-gray-200"></div>
        </div>
      ) : (
        <>
          <h2 className="px-1 mb-2 text-sm font-bold">
            รายการคอร์สอบรมเร็วๆนี้
          </h2>
          <div className="overflow-y-scroll" style={{ height: "200px" }}>
            {data.length === 0 && (
              <div className="text-center">ไม่พบข้อมูล</div>
            )}
            {data.map((o, index) => (
              <div
                className="flex pb-2 mb-2 border-b border-gray-300"
                key={index}
              >
                <div className="w-2/12">
                  <DayBox date={o.from_date} />
                </div>
                <div className="w-8/12">
                  <div className="px-2">
                    <a
                      href={o.link}
                      target="_blank"
                      className="block text-xs font-bold"
                    >
                      {o.title}
                    </a>
                    <span className="block text-gray-600 text-2xs">
                      เวลา {format(new Date(o.from_date), "HH:mm")}
                      <span className="inline-block p-1">-</span>
                      {format(new Date(o.to_date), "HH:mm")} น.
                    </span>
                    <span className="flex block text-gray-600 text-2xs">
                      <span className="text-sm material-icons">
                        location_on
                      </span>
                      {o.place}
                    </span>
                  </div>
                </div>
                <div className="w-3/12 text-right">
                  <span className="text-xs font-bold">{o.people} ท่าน</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TrainingList;
