import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Agent0084Img from "../../../../assets/agent/MTL_0084_retouched-min.png";
import Agent0248Img from "../../../../assets/agent/MTL_0248_retouched-min.png";
import Agent0358Img from "../../../../assets/agent/MTL_0358_retouched-min.png";
import "./css/top-agent.scss";

interface AgentsProfile {
  nickname: string;
  name: string;
  image: string;
}

const agentProfiles: AgentsProfile[] = [
  {
    nickname: "ฮีโร่แป้ง",
    name: "คุณสุปิยา พงษ์โนรี",
    image: Agent0358Img,
  },
  {
    nickname: "ฮีโร่ลักษณ์",
    name: "คุณนงลักษณ์ ศิลปชัย",
    image: Agent0084Img,
  },
  {
    nickname: "ฮีโร่เดียร์",
    name: "คุณอุลัย สมเพชร",
    image: Agent0248Img,
  },
];

const HeaderText: React.FC = () => {
  return (
    <h2 className="text-2xl font-normal xl:text-3xl px-30 xl:px-120">
      <span className="relative z-10">ถ้า</span>
      <span className="hero-highlight">พวกเขาทำได้</span> <br />
      <span className="relative z-10">ทำไม</span>
      <span className="relative z-10">คุณ</span>
      <span className="relative z-10">จะทำไม่ได้</span>
    </h2>
  );
};

const TopAgent = () => {
  return (
    <div className="agent-section">
      <BrowserView>
        <div className="container">
          <HeaderText />
          <div className="relative flex flex-wrap">
            <div className="w-5/12">
              <div className="flex px-30 xl:pl-120 mt-60">
                <a
                  href="/register"
                  className="flex items-center justify-center px-48 text-xl font-bold text-white transition duration-500 ease-in-out h-78 bg-secondary-1 hover:bg-secondary-dark rounded-5 shadow-button hover:shadow-button-hover"
                >
                  สมัครเลย
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="agent-section-box">
          <div className="w-full overflow-hidden">
            <div className="gird-list">
              {agentProfiles.map((o, i) => (
                <div key={i}>
                  <img src={o.image} alt={o.name} />
                  <div className="text-lg font-medium text-center text-secondary">
                    {o.nickname}
                  </div>
                  <div className="text-lg text-center">{o.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="container">
          <HeaderText />
          <div className="p-30 xl:px-120">
            <a
              href="/register"
              className="flex items-center justify-center w-full px-48 text-xl font-bold text-white transition duration-500 ease-in-out md:w-auto h-78 bg-secondary-1 hover:bg-secondary-dark rounded-5 shadow-button hover:shadow-button-hover"
            >
              สมัครเลย
            </a>
          </div>
        </div>
        <div className="agent-section-box">
          <div className="w-full pl-16 overflow-x-scroll lg:pl-0">
            <div className="gird-list">
              {agentProfiles.map((o, i) => (
                <div key={i}>
                  <img src={o.image} alt={o.name} />
                  <div className="text-lg font-medium text-center text-secondary">
                    {o.nickname}
                  </div>
                  <div className="text-lg text-center">{o.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </MobileView>
    </div>
  );
};

export default TopAgent;
