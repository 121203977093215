import React, { useState } from "react";
import QueryString from "query-string";
import { useHistory } from "react-router-dom";

import { ButtonRadius } from "/src/components/form";
import Preloading from "/src/components/loading/Preloading";
import ErrorPage from "/src/components/errorPage";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import useFetch from "/src/hooks/useFetch";
import useHttp from "/src/hooks/useHttp";

import "./compulsory.scss";

const Compulsory = ({ location }) => {
  const { search } = location;
  const [querires, setQuerires] = useState(QueryString.parse(search));
  const [fetchLoading, setFetchLoading] = useState(false);

  const carInfo = `${querires?.car_brand} ${querires.car_model} ${querires.year}`;

  const { response, error, isLoading } = useFetch(`/api/products/search2/motor${search}`);

  const { post } = useHttp();

  const history = useHistory();

  const vehicleType = (code) => {
    if (code === "110") return "รถเก๋ง / รถกระบะ 4 ประตู";
    if (code === "320") return "รถกระบะ 2 ประตู";
    return "รถตู้";
  };

  const handlePost = () => {
    setFetchLoading(true);
    const payload = JSON.stringify({
      product_ids: response[0].id,
      customer_name: "-",
      customer_phone: "-",
      doc: false,
      product_type: 'motor',
      criteria: {
        insurance_class: "cmi",
        car_brand: querires.car_brand,
        car_model: querires.car_model,
        year: querires.year,
        code_name: querires.code_name,
        province: querires.province,
      },
      quotes: [
        {
          product_id: response[0].id,
          discount: 0,
          addon: "",
        },
      ],
    });

    post(`/api/quotations/completed_quotation`, payload)
      .then((response) => {
        setFetchLoading(false);
        // setFetchLoading(false);
        // setQuotationInfo(response.data.content);
        // // setIsOpenModal(true);
        const { content } = response.data;
        const { order_id } = content;
        history.push(`/checkout/${order_id}/step-1`);
      })
      .catch((error) => {
        // handleFetchError(error);
      });
  };
  return (
    <>
      {fetchLoading && <Preloading />}
      {isLoading && <Preloading />}
      {!isLoading && response?.error && <ErrorPage />}
      {!isLoading && !response?.error && (
        <div className="compulsory-page">
          <div className="my-4">
            <ButtonRadius component="link" to="/motors">
              <span className="material-icons mr-2">keyboard_backspace</span>
              กลับไปเลือกรถยนต์รุ่นอื่นๆ
            </ButtonRadius>
          </div>
          <div className="card mt-6">
            <section className="compulsory-page-header mb-6">
              <h6>
                <i className="icon-Motor mr-2" />
                สรุปข้อมูลรถยนต์ของคุณ
              </h6>
              <div className="row mt-4">
                <div className="col-lg-7 col-xl-9">
                  <div className="compulsory-page-header-grid">
                    <div>บริษัทประกัน</div>
                    <div>
                      <strong>{response[0].insurer_name}</strong>
                    </div>
                    <div>ประเภท</div>
                    <div>
                      <strong>
                        ประกันภัยรถยนต์ ภาคบังคับ (พ.ร.บ.){" "}
                        {querires.used_vehicle_type &&
                          `- ${vehicleType(querires.used_vehicle_type)}`}
                      </strong>
                    </div>
                    <div>รถยนต์</div>
                    <div>
                      <strong>{carInfo}</strong>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-xl-3">
                  <div className="row">
                    <div className="col-7">
                      <h6>เบี้ยประกันที่ต้องชำระ</h6>
                      <small className="-mt-2 block">รวมภาษีและอากรแสตมป์</small>
                    </div>
                    <div className="col-5">
                      <h6 className="text-primary text-right">{formatNumberWithCurrency(response[0].standard_price)}</h6>
                    </div>
                    <div className="col-12 mt-3">
                      <ButtonRadius onClick={handlePost} color="primary" fullwidth>
                        ซื้อเลย
                      </ButtonRadius>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <hr />
            <section>
              <h6>รายละเอียดความคุ้มครอง</h6>
              <small className="error-txt">
                ** ผลิตภัณฑ์นี้ใช้สำหรับรถยนต์ส่วนบุคคลเท่านั้น
                ไม่รองรับรถยนต์ที่นำไปใช้ในเชิงพาณิชย์
              </small>
              <div className="compulsory-page-detail my-4">
                <h4>1) จำนวนเงินค่าเสียหายเบื้องต้น โดยไม่ต้องรอพิสูจน์</h4>
                <ul>
                  <li>
                    <span>1.1 ค่ารักษาพยาบาล จากการบาดเจ็บ (ตามจริง)</span>
                    <span className="text-right">30,000 ฿ / คน</span>
                  </li>
                  <li>
                    <span>1.2 การเสียชีวิต สูญเสียอวัยวะ หรือทุพพลภาพอย่างถาวร</span>
                    <span className="text-right">35,000 ฿ / คน</span>
                  </li>
                  <li>
                    <span />
                    <span className="text-right">
                      * (1.1) และ (1.2) รวมกันไม่เกิน 65,000 ฿ / คน
                    </span>
                  </li>
                </ul>
                <h4>
                  2) ค่าเสียหายส่วนที่เกินกว่าค่าเสียหายเบื้องต้น สำหรับผู้ประสบภัย
                  <small className="block">
                    (จะได้รับภายหลังจากการพิสูจน์แล้วว่าไม่ได้เป็นฝ่ายละเมิด)
                  </small>
                </h4>
                <ul>
                  <li>
                    <span>2.1 ค่ารักษาพยาบาล จากการบาดเจ็บ</span>
                    <span className="text-right">ไม่เกิน 80,000 ฿ / คน</span>
                  </li>
                  <li>
                    <span>2.2 การเสียชีวิต หรือทุพพลภาพอย่างถาวร</span>
                    <span className="text-right">500,000 ฿ / คน</span>
                  </li>
                  <li>
                    <span>2.3 สูญเสียอวัยวะ</span>
                    <span className="text-right">200,000 - 500,000 ฿ / คน</span>
                  </li>
                  <li>
                    <span>
                      2.4 ชดเชยรายวัน 200 บาท รวมกันไม่เกิน 20 วัน
                      กรณีเข้าพักรักษาพยาบาลในสถานพยาบาลในฐานะคนไข้ใน
                    </span>
                    <span className="text-right">สูงสุดไม่เกิน 4,000 ฿ / คน</span>
                  </li>
                  <li>
                    <span />
                    <span className="text-right">
                      * ข้อ (1) และ (2) รวมกันไม่เกิน 504,000 ฿ / คน
                    </span>
                  </li>
                </ul>
                <p>
                  <strong className="mt-2 mb-1 block">
                    <u>หมายเหตุ:</u>
                  </strong>{" "}
                  <br />
                  - ผู้ที่ขับขี่ที่กระทำละเมิด (ฝ่ายผิด) จะได้รับแค่ ความคุ้มครอง ค่าเสียหาย
                  เบื้องต้นเท่านั้น <br />
                  - ผู้ประสบภัย หมายรวมถึง ผู้ที่ขับขี่ถูกละเมิด ผู้โดยสาร และบุคคลภายนอก <br />-
                  จำนวนเงินค่าเสียหายเบื้องต้นนี้เป็นส่วนหนึ่งของจำนวนเงินคุ้มครองผู้ประสบภัย
                  (ดูในกรมธรรม์)
                </p>
              </div>
              {/* <h6>อ่านข้อมูลเพิ่มเติม:</h6>
              <div className="row my-4">
                <div className="col-md-6">
                  <ButtonRadius
                    variant="outlined"
                    color="primary"
                    component="a"
                    target="_blank"
                    href="http://www.oic.or.th/th/consumer/%E0%B8%AA%E0%B8%B2%E0%B8%A3%E0%B8%B0%E0%B8%AA%E0%B8%B3%E0%B8%84%E0%B8%B1%E0%B8%8D%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B1%E0%B8%99%E0%B8%A0%E0%B8%B1%E0%B8%A2%E0%B8%A3%E0%B8%96%E0%B8%A0%E0%B8%B2%E0%B8%84%E0%B8%9A%E0%B8%B1%E0%B8%87%E0%B8%84%E0%B8%B1%E0%B8%9A"
                    fullwidth
                  >
                    สาระสำคัญการประกันภัยรถยนต์ภาคบังคับ จาก คปภ.
                  </ButtonRadius>
                </div>
                <div className="col-md-6">
                  <ButtonRadius
                    variant="outlined"
                    color="primary"
                    component="a"
                    target="_blank"
                    href="http://www.oic.or.th/th/consumer/insurance/car/insurance"
                    fullwidth
                  >
                    ประกันภัยรถยนต์ภาคบังคับ จาก คปภ.
                  </ButtonRadius>
                </div>
              </div> */}
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default Compulsory;
