import React from "react";
import { formatNumberWithCurrency2 } from "/src/helpers/formatNumber.js";

import "./CoverageCard.scss";

const CoverageCard = (props) => {
  let { title, coverages, classname } = props;

  function renderIcon(title) {
    switch (title) {
      case "ความคุ้มครองรถยนต์ผู้เอาประกันภัย":
        return <span className="icon-motor mr-2" />;
      case "ความคุ้มครองบุคคลภายนอก":
        return <span className="icon-person mr-2 text-xl" />;
      case "ความคุ้มครองเพิ่มเติม":
        return <span className="icon-star mr-2 text-xl" />;
      default:
        return <span className="icon-motor mr-2" />;
    }
  }

  if (coverages.length === 0) {
    return <h6 className="text-center p-4">ความคุ้มครองตามใบเตือนที่แนบ</h6>;
  } else {
    return (
      <div className={`coverage ${classname}`}>
        {coverages.map((cov, m) => {
          return Object.keys(cov).map((key, n) => {
            if (key == "title") {
              let titleKey = `title-${m}-${n}`;
              return (
                <h4 className="" key={titleKey}>
                  {renderIcon(cov[key])}
                  {cov[key]}
                </h4>
              );
            } else {
              let componentKey = `cov-${m}-${n}`;
              return (
                <CoverageItem key={componentKey} name={key} value={cov[key]} />
              );
            }
          });
        })}
      </div>
    );
  }
};

const CoverageItem = (props) => {
  return (
    <div className="item">
      <div className="row">
        <div className="col-8 item-left">
          <div>{props.name}</div>
        </div>
        <div className="col-4 item-right">
          <div className="text-right">
            {props.name === "คุ้มครองคนโดยสาร"
              ? props.value + " คน"
              : props.value === "garage"
              ? "อู่"
              : props.value === "dealer"
              ? "ศูนย์"
              : formatNumberWithCurrency2(props.value)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverageCard;
