import QueryString from "query-string";
import React, { useState, useContext, useMemo, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import { useTranslation } from "react-i18next";
import { useAuthenticate } from "~src/helpers/authen";
import QuotationHeader from "./single/QuotationHeader";
import QuotationDetail from "./single/QuotationDetail";
import CustomerForm from "./single/CustomerForm";
import CoverageCard from "/src/components/coverage/CoverageCard";
import { TextField, Checkbox, FormGroup, ButtonRadius } from "/src/components/form";
import Preloading from "/src/components/loading/Preloading";
import { QuotationModal } from "/src/components/modal";

import { useAuthContext } from "/src/context/Auth";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import formingCoveragesFor from "/src/helpers/formingCoverage";
import { messages } from "/src/helpers/messages";
import useFetch from "/src/hooks/useFetch";
import useHttp from "/src/hooks/useHttp";

import "./createQuotation.scss";

const CreateSingle = (props) => {
  const query = new URLSearchParams(useLocation().search);
  const params = {};
  query.forEach((value, key) => {
    params[key] = value;
  });
  const { search } = props.location;
  const { showPaidCommission } = useAuthenticate();
  const { session, dispatch } = useAuthContext();

  const [querires, setQuerires] = useState(QueryString.parse(search));
  const [fetchLoading, setFetchLoading] = useState(false);
  const [productInfo, setProductInfo] = useState("");
  const [quotationInfo, setQuotationInfo] = useState({});
  const [coverages, setCoverages] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [totals, setTotals] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [cmiValue, setCmiValue] = useState(0);
  const [cmiLabelValue, setCmiLabelValue] = useState(0);
  const [isCmiRequired, setIsCmiRequired] = useState(true);
  const [repairFacility, setRepairFacility] = useState(true);
  const [showCmiCheckbox, setShowCmiCheckbox] = useState(true);

  const carTitle = `${querires.car_brand} ${querires.year} ${querires.car_model}`;

  const { response, error, isLoading } = useFetch(
    `/api/products/${params.product_id}?code_name=${querires.code_name}&province=${querires.province}&vehicle_type=${querires.vehicle_type}`
  );
  const { post } = useHttp();

  const INSURERS_NOT_HAS_CMI = [];

  useEffect(() => {
    if (productInfo && INSURERS_NOT_HAS_CMI.includes(productInfo.insurer_code)) {
      setIsCmiRequired(false);
      setShowCmiCheckbox(false);
    }
  }, [productInfo]);

  // TODO: Revise/Refactor this later
  useDeepCompareEffectNoCheck(() => {
    setFetchLoading(isLoading);

    if (isLoading) {
      return;
    }
    if (error) {
      handleFetchError(error);
    }
    if (response?.error) {
      handleFetchError(response.error);
    }

    unstable_batchedUpdates(() => {
      const { content } = response || {};
      setProductInfo(content);
      setCoverages(formingCoveragesFor("motor", content));
      setRepairFacility(content?.details?.repair_facility);
      setCmiValue(content?.cmi?.selling_price);
      setCmiLabelValue(content?.cmi?.selling_price);
      setTotals(parseFloat(content?.selling_price) + parseFloat(content?.cmi?.selling_price));
    });
  }, [response, error, isLoading]);

  useMemo(() => {
    let total = parseFloat(productInfo.selling_price) + parseFloat(cmiValue);
    total -= discount;

    if (!isCmiRequired) {
      total -= cmiValue;
    }
    setTotals(total);
  }, [discount, isCmiRequired]);

  function toggleCmiRequired() {
    if (isCmiRequired === true) {
      setCmiValue(0);
    } else {
      setCmiValue(cmiLabelValue);
    }
    setIsCmiRequired(!isCmiRequired);
  }

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  function quotationParams(cName, cPhone, discount) {
    const addon = isCmiRequired ? "checked" : "";
    return JSON.stringify({
      product_ids: params.product_id,
      customer_name: cName,
      customer_phone: cPhone,
      product_type: params.product_type,
      criteria: {
        insurance_class: params.policy_type,
        is_commercial: !!querires.is_commercial,
        car_brand: params.car_brand,
        car_model: params.car_model,
        vehicle_type: productInfo.details?.remark,
        year: params.year,
        code_name: params.code_name,
        province: params.province,
      },
      quotes: [
        {
          product_id: params.product_id,
          discount,
          addon,
        },
      ],
    });
  }

  function postCreateQuotation(payload) {
    setFetchLoading(true);
    post(`/api/quotations`, payload)
      .then((response) => {
        unstable_batchedUpdates(() => {
          setFetchLoading(false);
          setQuotationInfo(response.data.content);
          setIsOpenModal(true);
        });
      })
      .catch((error) => {
        handleFetchError(error);
      });
  }

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const onSubmit = (data) => {
    const { name, phone, discount } = data;

    const payload = quotationParams(name, phone, discount);
    postCreateQuotation(payload);
  };

  function handleFetchError(error) {
    // TODO: handling fetch error here
    console.error(error);
  }

  return (
    <div className="create-quotation">
      {!isLoading && (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <QuotationHeader />
          <div className="relative create-quotation-component">
            <div className="row">
              <div className="col-md-9">
                <QuotationDetail
                  title={carTitle}
                  agentName={`${session.title} ${session.firstname} ${session.lastname}`}
                  productInfo={productInfo}
                  repairFacility={repairFacility}
                />
              </div>
              <div className="col-md-3">
                <div className="mb-2 text-base text-gray-600">
                  <CustomerForm register={register} errors={errors} />
                </div>
              </div>
            </div>
            <hr />
            <div className="details">
              <div>
                <h6 className="ml-1 text-xl">รายละเอียดความคุ้มครอง</h6>
                <div className="">
                  <CoverageCard coverages={coverages} classname="coverage-details" />
                </div>
              </div>
              <div className="ml-20">
                {showCmiCheckbox && (
                  <>
                    <div className="mb-4 ">
                      <Checkbox
                        name="remove_cmi"
                        label={`ซื้อพร้อม พ.ร.บ. (${formatNumberWithCurrency(cmiLabelValue)})`}
                        onInput={() => toggleCmiRequired()}
                        defaultChecked
                      />
                    </div>
                    <hr />
                  </>
                )}
                <div className="mb-6">
                  <div className="flex justify-between px-2 py-1">
                    <div>ค่าเบี้ยประกันภัย</div>
                    <div className="text-lg">
                      {formatNumberWithCurrency(productInfo.selling_price)}
                    </div>
                  </div>
                  {showPaidCommission && (
                    <>
                      <div className="flex items-center justify-between px-2 py-1">
                        <div>
                          ส่วนลด
                          <span className="block -mt-1 text-xs text-gray-500">
                            (หักจากคอมมิชชั่นที่จะได้รับ)
                          </span>
                        </div>
                        <div style={{ textAlign: "right", width: "60%" }}>
                          <TextField
                            style={{ textAlign: "right" }}
                            type="number"
                            name="discount"
                            id="discount"
                            value={discount}
                            min="0"
                            step="0.01"
                            onChange={(e) => setDiscount(e.target.value)}
                            inputRef={register({
                              ...messages.required,
                              ...messages.floatingValid,
                              ...messages.maximumNumber(productInfo.max_discount),
                            })}
                            error={errors.discount && errors.discount.message}
                          />
                        </div>
                      </div>
                      <div className="flex justify-between px-2 py-1">
                        <div>
                          ส่วนลดสูงสุด
                          <br />
                          <span className="block -mt-1 text-xs text-gray-500">
                            ค่าตอบแทนภาคสมัครใจ หักภาษี 5%
                          </span>
                        </div>
                        <div className="font-thin">
                          {formatNumberWithCurrency(productInfo.max_discount)}
                        </div>
                      </div>
                    </>
                  )}
                  {showCmiCheckbox && (
                    <div className="flex justify-between px-2 py-1">
                      <div>พ.ร.บ.</div>
                      <div className="font-thin">{formatNumberWithCurrency(cmiValue)}</div>
                    </div>
                  )}
                  <div className="flex justify-between px-2 py-1">
                    <h6>
                      เบี้ยประกันภัย <br />
                      <span className="block -mt-1 text-xs text-gray-500">(รวมภาษี+อากร)</span>
                    </h6>
                    <div className="text-xl font-bold text-primary">
                      {formatNumberWithCurrency(totals)}
                      <div className="-mt-1 text-xs text-right text-gray-500">ต่อปี</div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <FormGroup>
                      <ButtonRadius type="submit" color="primary" fullwidth>
                        บันทึกใบเสนอราคา
                      </ButtonRadius>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      <div>
        {isOpenModal ? (
          <ToastProvider>
            <QuotationModal
              multipleProduct={false}
              handleClose={handleCloseModal}
              quotationId={quotationInfo.id}
              quotation_no={quotationInfo.number}
              quoteIds={quotationInfo.quotes.map((q) => q.id)}
            />
          </ToastProvider>
        ) : (
          ""
        )}
      </div>

      {fetchLoading && <Preloading />}
    </div>
  );
};

export default CreateSingle;
