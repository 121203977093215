import React from "react";
import { Link } from "react-router-dom";

import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert, Cancel, Description } from "@mui/icons-material";

import { PolicyTypeMapping } from "../../../../helpers/mappingData";
import { reformatDateTH } from "../../../../helpers/date";
import { formatNumberWithCurrency } from "../../../../helpers/formatNumber";
import { LoadStatus } from "../../../../types/load";
import Col from "../components/Col";
import { Order } from "../../../../types/order";
import { ProductTypeMapping } from "../../../../helpers/mappingData";
import NoticeButton from "../components/NoticeButton";
import SecureLink from "~src/components/secureLink";

interface Props {
  loadStatus?: LoadStatus;
  data?: Order[];
  onCancelOrder: (id: number) => void;
  onEditOrder: (order: Order) => void;
}

const Table: React.FC<Props> = ({ loadStatus = "loaded", data, onCancelOrder, onEditOrder }) => {
  const [noticeMenuElement, setNoticeMenuElement] = React.useState({});

  const renderCols = (numRows: number, index: number): JSX.Element => {
    const cols = [];
    for (let i = 1; i <= numRows; i++) {
      cols.push(
        <td
          key={i + "_" + index}
          className="px-2 py-4 space-x-4 bg-white border-b-2 border-gray-300 animate-pulse"
        >
          <div className="w-full h-24 bg-gray-200 rounded-sm"></div>
        </td>
      );
    }
    return <>{cols}</>;
  };

  const renderLoadingRows = (numRows: number): JSX.Element => {
    const rows = [];
    for (let i = 1; i <= 5; i++) {
      rows.push(<tr key={i}>{renderCols(numRows, i)}</tr>);
    }
    return <>{rows}</>;
  };

  const handleOpenNoticeMenu = (e) => {
    const menuId = e.currentTarget.id;
    setNoticeMenuElement({
      ...noticeMenuElement,
      [menuId]: e.currentTarget,
    });
  };

  const handleCloseNoticeMenu = (e) => {
    setNoticeMenuElement({});
  };

  const handleCancelRenewal = (o) => {
    setNoticeMenuElement({});
    onCancelOrder(o.order_id);
  };

  const handleEditRenewal = (o) => {
    setNoticeMenuElement({});
    onEditOrder(o);
  }

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <Col text="รหัสตัวแทน" />
            <Col text="ข้อมูลลูกค้า" />
            <Col text="บริษัท" classes="w-60" />
            <Col text="แผน/ประเภทประกัน" />
            <Col text="วันที่คุ้มครอง" />
            <Col text="ทุนประกัน" />
            <Col text="เบี้ยประกันที่ชำระ" />
            <Col text="เอกสารแจ้งเตือน" />
            <Col />
          </tr>
        </thead>
        <tbody>
          {loadStatus === "loaded" && (
            <>
              {data?.map((o, i) => (
                <tr key={i}>
                  <Col component="td" text={<>{o.agent_number}</>} />
                  <Col
                    component="td"
                    text={
                      <>
                        <p>{`${o.insureds?.[0].title} ${o.insureds?.[0].firstname} ${o.insureds?.[0].lastname}`}</p>
                        {o.quotation.product_type === "motor" && o.order_type !== "custom" && (
                          <>
                            <p>{`${o.quotation.criteria?.car_brand} ${o.quotation.criteria?.car_model} ${o.quotation.criteria?.year}`}</p>
                            <p>เลขทะเบียน {`${o.plate_number}`}</p>
                          </>
                        )}
                      </>
                    }
                  />
                  <Col
                    component="td"
                    text={
                      <img
                        src={o.quotes?.[0].insurer_icon}
                        alt={o.quotes?.[0].insurer_name}
                        className="w-8 shadow rounded-5"
                      />
                    }
                  />
                  <Col
                    component="td"
                    text={
                      <>
                        {o.order_type !== "custom" ? (
                          <>
                            <p>{PolicyTypeMapping[o.quotes?.[0].policy_type]}</p>
                            {o.quotes?.[0]?.addon && <p>{PolicyTypeMapping["cmi"]}</p>}
                          </>
                        ) : (
                          <>
                            {ProductTypeMapping[o.quotation.product_type]}

                            {o.order_type && (
                              <small className="p-1 ml-4 bg-gray-300 rounded-sm">
                                {o.order_type.toUpperCase()}
                              </small>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                  <Col
                    component="td"
                    text={
                      <>
                        <p>
                          เริ่ม:{" "}
                          {o.quotes?.[0].policy_type === "cmi"
                            ? reformatDateTH(
                                o.quotes?.[0].coverage_from ?? o.addon_start_date ?? ""
                              )
                            : reformatDateTH(
                                o.quotes?.[0].coverage_from ?? o.cover_start_date ?? ""
                              )}
                        </p>
                        <p>
                          ถึง:{" "}
                          {o.quotes?.[0].policy_type === "cmi"
                            ? reformatDateTH(o.quotes?.[0].coverage_to ?? o.addon_end_date ?? "")
                            : reformatDateTH(o.quotes?.[0].coverage_to ?? o.cover_end_date ?? "")}
                        </p>
                      </>
                    }
                  />
                  <Col component="td" text={formatNumberWithCurrency(o.quotes?.[0].sum_insured)} />
                  <Col
                    component="td"
                    text={formatNumberWithCurrency(o.payments_summary.payment_total_amount)}
                  />
                  <Col
                    component="td"
                    text={
                      <>
                        {o?.renewal_file && (
                          <>
                            <p>
                              <SecureLink src={o.renewal_file}>ใบแจ้งเตือนประกัน</SecureLink>
                            </p>
                            {o?.vmi_renewal_file && (
                              <p>
                                <SecureLink src={o.vmi_renewal_file}>
                                  ต้นฉบับใบเสนอราคาภาคสมัครใจ
                                </SecureLink>
                              </p>
                            )}
                            {o?.cmi_renewal_file && (
                              <p>
                                <SecureLink src={o.cmi_renewal_file}>
                                  ต้นฉบับใบเสนอราคา (พรบ.)
                                </SecureLink>
                              </p>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                  <Col
                    component="td"
                    text={
                      <>
                        <p>
                          <button
                            type="button"
                            onClick={() => handleEditRenewal(o)}
                            className="w-full px-4 py-2 mx-2 font-semibold text-center text-white transition duration-200 ease-in rounded-lg shadow-md bg-blue-600 hover:bg-blue-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            แก้ไข
                          </button>
                        </p>
                        <p>
                          <button
                            type="button"
                            onClick={() => handleCancelRenewal(o)}
                            className="w-full mt-2 px-4 py-2 mx-2 font-semibold text-center text-white transition duration-200 ease-in rounded-lg shadow-md bg-red-600 hover:bg-red-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            ยกเลิก
                          </button>
                        </p>
                      </>
                    }
                  />
                </tr>
              ))}
            </>
          )}

          {loadStatus === "loading" && renderLoadingRows(8)}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
