import React, { useState } from "react";
import { Attachment, PictureAsPdf } from "@mui/icons-material";
import SecureLinkImg from "~src/components/secureLinkImg";

interface UploadImageProps {
  id: string;
  name: string;
  title: string;
  inputRef?: any;
  error?: boolean;
  exampleLink?: string;
  onFileSelected?: (evt: any) => void;
  className?: string;
  value?: string;
  required?: boolean;
}

const UploadImage: React.FC<UploadImageProps> = ({
  id,
  name,
  title,
  exampleLink,
  inputRef,
  error,
  onFileSelected,
  className,
  value,
  required = false,
  ...rest
}) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileType, setFileType] = useState<"image" | "pdf">("image");

  const handleOpenFileInput = (e) => {
    document.getElementById(id).click();
  };

  const handleUploadFile = (e) => {
    setIsInvalid(false);
    let newIsUploaded = true;
    let newFileType: any = "image";
    const file = e.target.files[0];
    let fileUrl = file ? URL.createObjectURL(file) : null;

    if (!file) {
      newIsUploaded = false;
    } else if (file.type === "application/pdf") {
      newFileType = "pdf";
    }

    setFileType(newFileType);
    setIsUploaded(newIsUploaded);
    if (onFileSelected) onFileSelected(fileUrl);
  };

  const handleError = () => {
    setIsInvalid(true);
  };

  const borderClassName =
    !(error && required) || value
      ? "border-blue-400 text-blue-400 hover:border-blue-600 hover:text-blue-600"
      : "border-red-400 text-red-400 hover:border-red-600 hover:text-red-600";

  return (
    <div className={` ${className}`} {...rest}>
      <input
        id={id}
        name={name}
        ref={inputRef}
        type="file"
        onChange={handleUploadFile}
        accept="image/png, image/jpeg, .pdf"
        hidden
      />
      <div className="flex justify-center">
        <button
          className={`w-20r h-20r rounded-lg border-2 flex justify-center duration-200 bg-white hover:shadow-md ${borderClassName}`}
          onClick={handleOpenFileInput}
          type="button"
        >
          {isUploaded ? (
            <>
              {fileType === "image" ? (
                <img
                  src={value}
                  className="rounded-lg max-h-full self-center"
                  onError={handleError}
                />
              ) : (
                <PictureAsPdf className="self-center" />
              )}
            </>
          ) : value ? (
            isInvalid ? (
              <PictureAsPdf className="self-center" />
            ) : (
              <SecureLinkImg
                src={value}
                className="rounded-lg max-h-full self-center"
                onError={handleError}
              />
            )
          ) : (
            <Attachment className="self-center" />
          )}
        </button>
      </div>
      <div className="text-center mt-2">
        {title}
        {required && <span className="text-red-400 ml-1">*</span>}
      </div>
      {exampleLink && (
        <div className="flex justify-center">
          <a
            href={exampleLink}
            target="_blank"
            className="text-center text-blue-400 underline hover:text-blue-600 duration-200"
          >
            ตัวอย่าง
          </a>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
