const getAddressPositionByType = (type) => {
  switch (type) {
    case "insured":
      return 0;
    case "delivery":
      return 1;
    default:
      return null;
  }
};

export { getAddressPositionByType };
