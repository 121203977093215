import queryString, { StringifiableRecord } from "query-string";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { Placement as ToastPlacement, ToastProvider } from "react-toast-notifications";

import Alert from "~src/components/alert";
import ErrorPage from "~src/components/errorPage";
import Preloading from "~src/components/loading/Preloading";

import { useAuthContext } from "./Auth";
import APIError from "~src/models/APIError";
import { logout } from "~src/utils/logout";

const DEFAULT_PRELOADING_LABEL = "กำลังโหลดข้อมูล...";
const DEFAULT_TOAST_PLACEMENT = "top-right";

export interface PageContextProps {
  setAlert: (msgObj: string | Error, details?: Record<string, any[]>) => void;
  setPreloading: (state: boolean, label?: string) => void;
  queryParams: StringifiableRecord;
  setQueryParams: (params: StringifiableRecord) => void;
  reloadPage: () => void;
  onError: (err: Error) => void;
}

const PageContext = createContext<PageContextProps | undefined>(undefined);
export default PageContext;

export interface PageContextProviderProps {
  preloading?: boolean;
  preloadingLabel?: string;
  withQuery?: boolean;
  location?: Location;
  loginPath?: string;
  mainPagePath?: string;
  toastPlacement?: ToastPlacement;
  toastDismiss?: boolean | number;
}

export var PageContextProvider = ({
  preloading: initialPreloading,
  preloadingLabel: initialPreloadingLabel,
  withQuery,
  location,
  loginPath = "/login",
  mainPagePath,
  toastPlacement,
  toastDismiss,
  children,
}: React.PropsWithChildren<PageContextProviderProps>) => {
  const history = useHistory();
  const { session, dispatch: dispatchAuth } = useAuthContext();

  const queryParams = useMemo(
    () =>
      withQuery && location
        ? (queryString.parse(location.search, {
            arrayFormat: "bracket",
          }) as StringifiableRecord)
        : {},
    [withQuery, location?.search]
  );

  const [alertMessage, setAlertMessage] = useState<string | undefined>();
  const [alertDetails, setAlertDetails] = useState<Record<string, any[]>>({});
  const [hasAlert, setHasAlert] = useState<boolean>(false);
  const [preloading, setPreloading] = useState(initialPreloading ?? false);
  const [preloadingLabel, setPreloadingLabel] = useState(
    initialPreloadingLabel || DEFAULT_PRELOADING_LABEL
  );
  const [error, setError] = useState<Error | undefined>();

  const handleSetAlert = useCallback(
    (msgObj: string | Error | undefined, details?: Record<string, any[]>) => {
      const _hasAlert =
        !!msgObj || (details && !!Object.values(details).find((detail) => !!detail.length));
      // TEST
      console.log("setHasAlert:", _hasAlert, msgObj);
      unstable_batchedUpdates(() => {
        setHasAlert(_hasAlert);
        setAlertMessage(typeof msgObj === "string" ? msgObj : msgObj?.message);
        // TODO: Auto derive details from msgObj as Error, with prefix options
        setAlertDetails(details || {});
      });
    },
    []
  );

  const handleSetPreloading = useCallback((state: boolean, label?: string) => {
    unstable_batchedUpdates(() => {
      setPreloading(state);
      setPreloadingLabel(`${label || initialPreloadingLabel || DEFAULT_PRELOADING_LABEL}`);
    });
  }, []);

  const handleSetQueryParams = useCallback(
    (params: StringifiableRecord) => {
      if (!location) {
        return;
      }

      const nextQS = `?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`;
      if (nextQS !== location.search) {
        // TEST
        console.log("query string changed:", location.search, "=>", nextQS);
      }

      history.push({
        pathname: location.pathname,
        search: nextQS,
      });
    },
    [location]
  );

  const handleReloadPage = useCallback(() => {
    history.go(0);
  }, [history]);

  const handleError = useCallback((err: Error) => {
    console.error(err);
    setError(err);

    if (err instanceof APIError) {
      const { statusCode } = err as APIError<any>;
      if ([401, 403].includes(statusCode)) {
        if (statusCode === 401) {
          logout();
        }

        handleSetAlert("Session Invalidated");
        // TODO: Fix transient states
        // dispatchAuth({ type: "LOGOUT" });

        // if (loginPath) {
        //   history.push({
        //     pathname: loginPath,
        //     state: { goBack: true },
        //   });
        // }
      }
    }
  }, []);

  return (
    <ErrorBoundary
      fallbackRender={({ error: err }) => (
        <ErrorPage message={err.message} mainPagePath={mainPagePath} />
      )}
    >
      <PageContext.Provider
        value={{
          setPreloading: handleSetPreloading,
          setAlert: handleSetAlert,
          queryParams,
          setQueryParams: handleSetQueryParams,
          reloadPage: handleReloadPage,
          onError: handleError,
        }}
      >
        <ToastProvider
          placement={toastPlacement || DEFAULT_TOAST_PLACEMENT}
          autoDismiss={toastDismiss}
        >
          {hasAlert && <Alert message={alertMessage} details={alertDetails} />}
          {preloading && <Preloading label={preloadingLabel} />}
          {error ? <ErrorPage message={error.message} mainPagePath={mainPagePath} /> : children}
        </ToastProvider>
      </PageContext.Provider>
    </ErrorBoundary>
  );

  // <ErrorBoundary
  //   fallbackRender={({ error: err }) => {
  //     handleError(err);
  //     return null;
  //   }}
  // >
  // { children }
  // </ErrorBoundary>
};

export function usePageContext(): PageContextProps {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error("PageContext provider not found");
  }

  return context;
}
