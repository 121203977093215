import React from "react";
import "./placeholder-loading.scss";

const CardLoading = ({ items, ...rest }) => {
  const renderLoadint = [];
  for (let i = 0; i < items; i++) {
    renderLoadint.push(
      <div key={i} {...rest}>
        <div className="ph-item">
          <div className="ph-card-header" />
          <div className="ph-card-price" />
          <div className="ph-card-content">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="ph-card-button" />
          <div className="ph-card-button no-space" />
        </div>
      </div>
    );
  }
  return <>{renderLoadint}</>;
};

export default CardLoading;
