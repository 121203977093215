import React, { useEffect, useState, ChangeEvent } from "react";
import { Controller, UseFormMethods, useFormContext, useWatch } from "react-hook-form";
import { useRecoilState } from "recoil";
import _ from "lodash";
import AutoComplete from "~src/components/form/autoComplete";
import { Option } from "~/src/types/basic";
import { messages } from "~/src/helpers/messages";
import { standardVmiCommission } from "~/src/states/orderCustom";
import Select from "./Select";
import CustomDropdown from "./CustomDropdown";
import ReactSelect from "react-select/creatable";

interface Props extends Partial<Pick<UseFormMethods, "control" | "errors">> {
  name: string;
  className?: string;
  control?: any;
  errors?: any;
  setValue?: any;
  register?: any;
  clearErrors?: any;
  packages?: any[];
}

const PackageNameInput: React.FC<Props> = ({
  name,
  className,
  control,
  errors,
  setValue,
  register,
  clearErrors,
  packages = [],
}): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState<boolean>(true);
  const [options, setOptions] = useState<Option<string>[]>([]);
  const [customVmiCommission, setCustomVmiCommission] = useRecoilState(standardVmiCommission);

  const packageName = useWatch({ control, name: name, defaultValue: "" });

  const convertDataToOption = (packagesInfo: any[]): Option<string>[] => {
    return packagesInfo.map((packageInfo) => {
      return {
        label: packageInfo.package_name,
        value: `${packageInfo.package_name}`,
      };
    });
  };

  const filterPackageOptions = (keyword: string): void => {
    let results = [];
    // let filteredPackages = packages.filter((o) => o.package_name !== "default");
    // if (!keyword) {
    //   results = convertDataToOption(filteredPackages);
    // } else {
    //   results = convertDataToOption(
    //     filteredPackages.filter(
    //       (o) =>
    //         o.package_name.toLowerCase().includes(keyword.toLowerCase()) ||
    //         o.package_name.toLowerCase().includes(keyword.toLowerCase())
    //     )
    //   );
    // }

    results = convertDataToOption(
      packages.filter(
        (o) =>
          o.package_name.toLowerCase().includes(keyword.toLowerCase()) ||
          o.package_name.toLowerCase().includes(keyword.toLowerCase())
      )
    );

    setOptions(results);
    setShowDropdown(results.length > 0 && keyword != "");
  };

  const updateCommissionInfo = (packageInfo) => {
    setCustomVmiCommission({
      received_commission: packageInfo?.received_commission || 0,
      received_commission_unit: packageInfo?.received_commission_unit || "PERCENT",
      received_ov: packageInfo?.received_ov || 0,
      received_ov_unit: packageInfo?.received_ov_unit || "PERCENT",
      received_printing: packageInfo?.received_printing || 0,
      received_printing_unit: packageInfo?.received_printing_unit || "PERCENT",
      paid_commission_percent: packageInfo?.paid_commission || 0,
      paid_ov_percent: packageInfo?.paid_ov || 0,
      paid_printing_percent: packageInfo?.paid_printing || 0,
    });
  };

  useEffect(() => {
    filterPackageOptions("");
    updateCommissionInfo(packages[0]);
  }, [JSON.stringify(packages)]);

  const handleChangeInputPackage = (name: string, value: string) => {
    setValue(name, value);
    console.log(name, value);
  };

  const onPackageNameChanged = (name: string, value: string) => {
    //filterPackageOptions(value);
    setValue(name, value);

    let selectedPackage = packages.find((p) => p.package_name === value);
    if (!selectedPackage) selectedPackage = packages.find((p) => p.package_name === "default");

    updateCommissionInfo(selectedPackage);
  };

  const handleChangePackage = (newValue: Option<string>) => {
    const { value } = newValue;
    const defaultPackage = packages.find((p) => p.package_name === "default") || null;
    const selectedPackage = packages.find((o) => o.package_name === value) || defaultPackage;

    updateCommissionInfo(selectedPackage);

    setValue(name, value);
  };

  return (
    // <AutoComplete
    //   data={options}
    //   name={name}
    //   className={className}
    //   show={showDropdown}
    //   error={errors && _.get(errors, name)?.message}
    //   value={packageName}
    //   onChange={onPackageNameChanged}
    //   cbOutsideClick={() => setShowDropdown(false)}
    //   cbData={onPackageNameSelected}
    //   inputRef={register && register(messages.required)}
    // />
    <Controller
      name={name}
      rules={messages.required}
      control={control}
      render={({ onChange, value }) => (
        <ReactSelect
          onChange={(newValue) => handleChangePackage(newValue)}
          options={convertDataToOption(packages)}
        />
      )}
    />
  );
};

export default PackageNameInput;
