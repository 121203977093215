import React from "react";
import { Parallax } from "react-parallax";
import { BrowserView, MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";

import "./css/hero.scss";

const Hero: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <BrowserView>
        <section className="relative overflow-hidden" style={{ height: 600 }}>
          <Parallax
            strength={1000}
            renderLayer={(percentage) => (
              <div
                data-a={percentage}
                className="clip-circle"
                style={{
                  backgroundPositionY: percentage * 150,
                }}
              />
            )}
          >
            <div className="flex items-center justify-center bg-cover" style={{ height: 600 }}>
              <div>
                <h1 className="relative mb-20 text-xl font-medium text-center lg:text-3xl text-primary">
                  <span className="relative z-10">พลิกหน้ากระดาษสู่นายหน้า</span>
                  <span className="hero-highlight">ประกันออนไลน์</span>
                </h1>
                <div className="mb-48 text-lg font-normal text-center lg:text-xl">
                  เครื่องมือขายคู่กายฮีโร่ ที่ให้คุณได้มากกว่าตัวเงิน
                </div>
                {t("registerButton") && (
                  <div className="flex justify-center">
                    <a
                      href="/register"
                      className="flex items-center justify-center px-48 text-xl font-bold text-white transition duration-500 ease-in-out h-78 bg-secondary-1 hover:bg-secondary-dark rounded-5 shadow-button hover:shadow-button-hover"
                    >
                      สมัครเลย วันนี้
                    </a>
                  </div>
                )}
              </div>
            </div>
          </Parallax>
        </section>
      </BrowserView>
      <MobileView>
        <section className="relative overflow-hidden" style={{ height: 430 }}>
          <div className="clip-circle-mobile">
            <div
              className="relative flex items-center justify-center bg-cover"
              style={{ height: 430 }}
            >
              <div>
                <h1 className="relative mb-20 text-xl font-medium text-center lg:text-3xl text-primary">
                  <span className="relative z-10">พลิกหน้ากระดาษสู่นายหน้า</span>
                  <span className="hero-highlight">ประกันออนไลน์</span>
                </h1>
                <div className="mb-48 text-lg font-normal text-center lg:text-xl">
                  เครื่องมือขายคู่กายฮีโร่ <br /> ที่ให้คุณได้มากกว่าตัวเงิน
                </div>
                {t("registerButton") && (
                  <div className="flex justify-center">
                    <a
                      href="/register"
                      className="flex items-center justify-center px-48 text-xl font-bold text-white transition duration-500 ease-in-out h-78 bg-secondary-1 hover:bg-secondary-dark rounded-5 shadow-button hover:shadow-button-hover"
                    >
                      สมัครเลย วันนี้
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </MobileView>
    </>
  );
};

export default Hero;
