import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "./useOnClickOutside";
import { parseDate, dateFormat } from "/src/helpers/dateTime";
import "./datepicker.scss";

const DatePickerField = ({
  inputRef,
  error,
  value,
  minDate = null,
  maxDate = null,
  callbackvalue,
  defaultView,
  disableNextYearSelect = false,
  readOnly = false,
  ...rest
}) => {
  const [opened, setOpened] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const [pickDate, setPickDate] = useState(parseDate(value) || new Date());
  const { t } = useTranslation();

  useEffect(() => {
    if (value !== "") {
      setDateValue(value);
      setPickDate(parseDate(value) || new Date());
    }
  }, [value, disableNextYearSelect]);

  const dialogRef = useRef();
  useOnClickOutside(dialogRef, () => setOpened(false));

  const focusInput = () => {
    if (readOnly) setOpened(false);
    else setOpened(true);
  };

  const handleChange = (selectDate) => {
    setPickDate(selectDate);
    setDateValue(dateFormat(selectDate));
    if (callbackvalue !== undefined) {
      callbackvalue(dateFormat(selectDate));
    }
    setOpened(false);
  };

  return (
    <>
      <div className="form-datepicker">
        <button type="button" className="datepicker-toggle" onClick={focusInput}>
          <i className="material-icons">date_range</i>
        </button>
        <input
          {...rest}
          ref={inputRef}
          value={dateValue}
          className={!error ? "form-input" : "form-input has-error"}
          readOnly
        />
        {opened ? (
          <div className="form-datepicker-dialog" ref={dialogRef}>
            <Calendar
              locale="th-Th"
              calendarType="US"
              minDate={minDate}
              maxDate={maxDate}
              defaultView={defaultView}
              onChange={handleChange}
              value={pickDate}
            />
          </div>
        ) : null}
      </div>
      {error !== undefined && <p className="form-input-error">{error}</p>}
    </>
  );
};

export default DatePickerField;
