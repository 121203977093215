import React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { ContractForm, contractFormSelector, fetchShowContract } from "~src/features/contracts";
import { useAppDispatch, useAppSelector } from "~src/store";

const ContractEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const contract = useAppSelector(contractFormSelector);

  React.useEffect(() => {
    dispatch(fetchShowContract({ id }));
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" component="h1">
          แก้ไขสัญญา / ข้อกำหนดและเงื่อนไข
        </Typography>

        <Button href="/staffs/contracts" variant="contained" disableElevation color="inherit">
          ย้อนกลับ
        </Button>
      </Box>
      {contract.mode === "edit" && contract.status === "fulfilled" && <ContractForm />}
    </Box>
  );
};

export default ContractEditPage;
