import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "babel-polyfill";
import axios from "/src/helpers/configAxios";
import useFetch from "/src/hooks/useFetch";
import {
  formatNumberWithCurrency,
  formatNumberWithCurrency2,
} from "/src/helpers/formatNumber";
import { dateFormat, parseDate, parseDateEN } from "/src/helpers/dateTime";

import TABLE, { THEAD, TBODY, TR, TH, TD } from "/src/components/table";
import ErrorPage from "/src/components/errorPage";
import { useTranslation } from "react-i18next";

import useInsurerIcons from "/src/helpers/useInsurerIcons";

import Alert from "/src/components/alert";

import "./oldlist.scss";
import dataYml from "/src/data.yaml";

const Index = () => {
  const [isError, setIsError] = useState(false);
  const { response, error, isLoading } = useFetch(`/api/ensure_more_policies`);
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      setIsError(true);
    }
  });

  return (
    <div className="policy-page">
      {isError ? (
        <ErrorPage />
      ) : (
        <>
          <div className="mt-6 ml-6">
            <h4 className="inline text-lg mb-1">กรมธรรม์จากระบบเก่า</h4>
            <hr className="mt-2" />
            <div className="title-bottom">
              <span className="gettmore-icon-info" />
              {` กรมธรรม์ของท่านที่มาจากระบบ EnsureMore หากไม่พบงานของท่าน
          กรุณาติดต่อเจ้าหน้าที่ได้ที่ Line: ${t("lineAccount")} หรือ ${t("contactMail")}`}
            </div>
          </div>
          <div className="old-table">
            <TABLE>
              <THEAD>
                <TR>
                  <TH className="width-2 text-left pl-6" label="เลขที่กรมธรรม์" />
                  <TH className="width-2 text-left" label="ข้อมูลลูกค้า" />
                  <TH style={{ width: 150 }} label="รายละเอียด" />
                  <TH className="width-1" label="ประกัน" />
                  <TH className="width-3" label="แผน" />
                  <TH className="width-3" label="วันที่คุ้มครอง" />
                  <TH className="width-1" label="ทุนประกัน" />
                  <TH className="width-1" label="เบี้ยประกันที่ชำระ" />
                </TR>
              </THEAD>
              <TBODY>
                {isLoading ? (
                  <TR>
                    <TD style={{ textAlign: "center" }} colSpan="10" label="กำลังโหลด..." />
                  </TR>
                ) : (
                  <>
                    {response.content.length === 0 ? (
                      <TR>
                        <TD style={{ textAlign: "center" }} colSpan="10" label="ไม่พบข้อมูล" />
                      </TR>
                    ) : null}
                    {response.content.map((obj, index) => {
                      return (
                        <TR key={index}>
                          <TD
                            className="text-left-policy"
                            label={
                              <>
                                {obj.policy_type === "cmi" ? (
                                  <span className="cmi-label">พ.ร.บ.</span>
                                ) : (
                                  <span className="gettmore-icon-Motor"> </span>
                                )}{" "}
                                {obj.policy_number}
                              </>
                            }
                          />
                          <TD
                            className="width-1 text-left"
                            label={
                              <>
                                {`${obj.insured_title}${obj.insured_firstname} ${obj.insured_lastname} `}
                              </>
                            }
                          />
                          <TD
                            className="text-left"
                            label={
                              <>
                                {`${obj.criteria?.car_brand} ${obj.criteria?.car_model}`} <br />
                                {`เลขทะเบียน: ${obj.plate_number}`}
                              </>
                            }
                          />
                          <TD
                            className="width-1"
                            label={<img className="insurer-img" src={obj.insurer_icon} />}
                          />
                          <TD
                            className="width-1"
                            label={
                              obj.policy_type === "cmi"
                                ? "พ.ร.บ."
                                : dataYml.policy_type[obj.policy_type]
                            }
                          />
                          <TD
                            className="width-2"
                            label={
                              <>
                                เริ่ม: {parseDateEN(obj.coverage_from)}
                                <br />
                                ถึง: {parseDateEN(obj.coverage_to)}
                              </>
                            }
                          />
                          <TD
                            className="width-1"
                            label={formatNumberWithCurrency2(obj.sum_insured)}
                          />
                          <TD
                            className="width-1"
                            label={formatNumberWithCurrency(obj.total_amount)}
                          />
                        </TR>
                      );
                    })}
                  </>
                )}
              </TBODY>
            </TABLE>
          </div>
        </>
      )}
    </div>
  );
};

export default Index;
