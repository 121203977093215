import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { ProductTypeMapping } from "../../../../helpers/mappingData";
import Preloading from "/src/components/loading/Preloading";
import { parseDateEN } from "/src/helpers/dateTime";
import { messages } from "/src/helpers/messages";
import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "/src/helpers/formatNumber";
import Renewals from "/src/helpers/renewal";
import NotRenewModal from "../modal";
import useHttp from "/src/hooks/useHttp";
import { useTranslation } from "react-i18next";
import { useAuthenticate } from "~src/helpers/authen";

import { FormGroup, TextField, SelectField, Button, ButtonRadius } from "/src/components/form";
import SecureLink from "~src/components/secureLink";

import useInput from "/src/hooks/useInput";

import "./table.scss";

const ProductTypeIcon = ({ type }) => {
  switch (type) {
    case "motor":
      return <i className="icon icon-3-Motor" />;
    case "travel":
      return <i className="icon icon-3-travel" />;
    default:
      return <i className="icon gettmore-icon-policylist" />;
  }
};

const Table = (props) => {
  const { t } = useTranslation();
  const { showPaidCommission } = useAuthenticate();
  const { loading, data } = props;
  const history = useHistory();

  const [dataLists, setDataLists] = useState([]);
  const [isNotRenewModal, setIsNotRenewModal] = useState(false);
  const [vmiWarningNumber, setVmiWarningNumber] = useState("");
  const [preloading, setPreloading] = useState(false);
  const [orderId, setOderId] = useState("");
  const lineLink = t("lineLink", { returnObjects: true });

  useMemo(() => {
    setDataLists(data);
  }, [data]);

  const { value: notice, bind: changeRenewalNotice, reset: resetRenewalNotice } = useInput("");
  const { value: remark, bind: changeRenewalRemark, reset: resetRenewalRemark } = useInput("");
  const { register, errors, handleSubmit, reset } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const { post } = useHttp();

  const { addToast } = useToasts();

  const BuyButtonRenew = ({ orderInfo }) => {
    if (orderInfo.order_type !== "custom") {
      return (
        <>
          {orderInfo.status === "open" ? (
            <>
              <ButtonRadius
                type="button"
                color="primary"
                size="xs"
                onClick={() => handlePlaceOrder(orderInfo.quotation.id, orderInfo.quotes[0].id)}
              >
                ซื้อเลย
              </ButtonRadius>
              <ButtonRadius
                type="button"
                size="xs"
                onClick={() =>
                  handleOpenModalNotRenew(orderInfo.order_id, orderInfo.vmi_warning_number)
                }
              >
                ไม่ต่ออายุ
              </ButtonRadius>
            </>
          ) : (
            <ButtonRadius
              type="button"
              color="primary"
              size="xs"
              onClick={() => handleSubmitPayment(orderInfo.order_id)}
            >
              ชำระเงิน
            </ButtonRadius>
          )}
        </>
      );
    }
    return (
      <ButtonRadius
        classNameOption="max-h-30 px-10 py-1 justify-center items-center flex"
        component="a"
        target="_blank"
        href={lineLink.url}
        color="primary"
      >
        <div style={{ fontSize: "11px" }}> ซื้อผ่านไลน์ </div>
      </ButtonRadius>
    );
  };

  const handleOpenModalNotRenew = (id, vmi_warning_number) => {
    setIsNotRenewModal(true);
    setVmiWarningNumber(vmi_warning_number);
    setOderId(id);
  };

  const cbCloseModal = () => {
    setIsNotRenewModal(!isNotRenewModal);
  };

  const onSubmit = (data) => {
    setPreloading(true);
    post(`/api/orders/cancel`, data)
      .then((response) => {
        setPreloading(false);
        const { success } = response.data;
        if (success) {
          setIsNotRenewModal(false);
          resetRenewalNotice();
          resetRenewalRemark();
          addToast("อัพเดตข้อมูลเรียบร้อย", {
            appearance: "success",
            autoDismiss: true,
          });
          updateLists(dataLists, data.order_id);
        }
      })
      .catch((error) => {
        setPreloading(false);
        addToast("Something wrong!", {
          appearance: "error",
          autoDismiss: false,
        });
      });
  };

  const handlePlaceOrder = (quotation_id, quotesId) => {
    const data = {
      quotation_id,
      quote_ids: quotesId,
    };

    post(`/api/quotations/place_order`, data)
      .then((res) => {
        const { content } = res.data;
        if (content) history.push(`/checkout/${content.order_id}/step-1`);
        else
          addToast("Something wrong!", {
            appearance: "error",
            autoDismiss: false,
          });
      })
      .catch((error) => {
        addToast("Something wrong!", {
          appearance: "error",
          autoDismiss: false,
        });
      });
  };

  const handleSubmitPayment = (orderId) => {
    history.push(`/checkout/${orderId}/summary`);
  };

  function updateLists(lists, order_id) {
    const result = lists.filter((obj) => obj.order_id != order_id);
    setDataLists(result);
  }

  return (
    <div className="renewal-table">
      <div className="table-grid">
        <div className="grid-th">
          <div>ข้อมูลลูกค้า</div>
          <div>ประกัน</div>
          <div style={{ textAlign: "center" }}>แผน</div>
          <div>วันที่คุ้มครอง</div>
          <div>ทุนประกัน</div>
          <div>เบี้ยประกันที่ชำระ</div>
          <div>{showPaidCommission ? "ค่าตอบแทน" : ""}</div>
          <div>ใบเตือนต่ออายุ</div>
          <div />
        </div>
        
        {loading && <div className="grid-tbody-full">กำลังโหลด...</div>}
        {!loading && (
          <>
            {dataLists.length == 0 && <div className="grid-tbody-full">ไม่พบข้อมูล</div>}
            {dataLists.length > 0 &&
              dataLists.map((o, i) => (
                <div className="grid-tbody" key={i}>
                  <div>
                    {`${o.insureds[0].title} ${o.insureds[0].firstname}
                  ${o.insureds[0].lastname}`}
                    {o.order_type !== "custom" && (
                      <>
                        <br />
                        {o.quotation.criteria?.car_brand} {o.quotation.criteria?.car_model} <br />
                        ทะเบียน: {o.plate_number ?? "-"}
                      </>
                    )}
                  </div>
                  <div>
                    <img src={o.quotes[0].insurer_icon} />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {o.order_type !== "custom" ? (
                      <>
                        ชั้น {o.quotes[0].policy_type} <br />
                        {o.quotes[0].addon ? "พ.ร.บ." : null}
                      </>
                    ) : (
                      ProductTypeMapping[o.quotation.product_type]
                    )}
                  </div>
                  <div>
                    <span>เริ่ม:</span>{" "}
                    {o.quotes[0]?.policy_type === "cmi"
                      ? parseDateEN(o.addon_start_date)
                      : parseDateEN(o.cover_start_date)}{" "}
                    <br />
                    <span>ถึง:</span>{" "}
                    {o.quotes[0]?.policy_type === "cmi"
                      ? parseDateEN(o.addon_end_date)
                      : parseDateEN(o.cover_end_date)}
                  </div>
                  <div>{formatNumberWithCurrency2(o.quotes[0].sum_insured)}</div>
                  <div>
                    <div className="selling-price">
                      <ProductTypeIcon type={o.quotation.product_type} />{" "}
                      {formatNumberWithCurrency(o.quotes[0].selling_price)}{" "}
                    </div>
                    {o.quotes[0].addon !== undefined && (
                      <>
                        <span className="cmi-label">พ.ร.บ.</span>{" "}
                        <span className="num-value">
                          {formatNumberWithCurrency(o.quotes[0].addon.selling_price)}
                        </span>
                      </>
                    )}
                  </div>

                  <div className="selling-price">
                    {showPaidCommission
                      ? formatNumberWithCurrency2(o.payments_summary.payment_earning_commission)
                      : ""}
                  </div>

                  <div>
                    {o.renewal_file ? (
                      <SecureLink src={o.renewal_file} className="file" rel="noreferrer">
                        <span className="material-icons">description</span>
                        ใบแจ้งเตือนประกัน
                      </SecureLink>
                    ) : null}{" "}
                  </div>
                  <div className="flex justify-between">
                    <BuyButtonRenew orderInfo={o} />
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      {isNotRenewModal ? (
        <NotRenewModal cbCloseModal={cbCloseModal}>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <h5 className="text-center">
              ไม่ต่ออายุกรมธรรม์ <br />{" "}
              <small className="block mb-2">เลขที่ใบเตือนต่ออายุ #{vmiWarningNumber}</small>
            </h5>
            <hr />
            <input type="hidden" name="order_id" ref={register()} value={orderId} />
            <div className="row">
              <div className="col-12">
                <FormGroup label="เหตุผลที่ไม่ต่ออายุ" htmlForLabel="notice" require>
                  <SelectField
                    id="notice"
                    name="notice"
                    options={Renewals.notice}
                    inputRef={register(messages.required)}
                    {...changeRenewalNotice}
                    error={errors.notice?.message}
                  />
                </FormGroup>
              </div>
              <div className="col-12">
                <FormGroup label="หมายเหตุ" htmlForLabel="remark">
                  <TextField
                    id="remark"
                    name="remark"
                    inputRef={register()}
                    {...changeRenewalRemark}
                  />
                </FormGroup>
              </div>
              <div className="col-12">
                <FormGroup>
                  <Button type="submit" color="primary" fullwidth>
                    ยืนยัน
                  </Button>
                </FormGroup>
              </div>
            </div>
          </form>
        </NotRenewModal>
      ) : null}

      {preloading ? <Preloading /> : null}
    </div>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default Table;
