import { createAsyncThunk } from "@reduxjs/toolkit";
import services from "../services";
import { CreateQuotationPayload, PlaceOrderParam } from "./types";

export const fetchGenerateSecureTokenAction = createAsyncThunk(
  "travel/fetchGenerateSecureToken",
  async () => {
    const response = await services.postGenerateSecureToken();
    return response.data;
  }
);

export const fetchCountriesAction = createAsyncThunk("travel/fetchCountries", async () => {
  const response = await services.getCountries();
  return response.data;
});

export const fetchTravelProductsAction = createAsyncThunk(
  "travel/fetchTravelProduct",
  async (params: string) => {
    const response = await services.getTravelProducts(params);

    return response.data;
  }
);

export const fetchPlaceOrderAction = createAsyncThunk(
  "travel/fetchPlaceOrder",
  async (param: PlaceOrderParam) => {
    const response = await services.placeOrder(param);
    return response.data;
  }
);

export const fetchCreateQuotationAction = createAsyncThunk(
  "travel/fetchCreateQuotation",
  async (params: CreateQuotationPayload) => {
    const response = await services.creatQuotation(params);
    return response.data;
  }
);

export const fetchProductByIdAction = createAsyncThunk(
  "travel/fetchProductById",
  async (param: { id: number; token: string }) => {
    const response = await services.getTravelProductById(param.id, param.token);
    return response.data.content;
  }
);
