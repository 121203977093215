import React, { ChangeEvent } from "react";

import { FormGroup, TextField } from "~/src/components/form";
import { messages } from "~/src/helpers/messages";
import AutoComplete from "~/src/components/form/autoCompleteAddress";
import { useFormContext } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "~src/store";
import { agentInfoSelector, handleChangeAgentInfo } from "./registerSlice";
import {
  handleCallbackLocation,
  handleFilterLocation,
  locationResultSelector,
  locationSelectedSelector,
} from "../locations";
import AgentInfo from "~src/models/AgentInfo";

const AddressForm = () => {
  const [showDropDownZipcode, setShowDropdownZipcode] = React.useState<boolean>(false);
  const [showDropDownProvince, setShowDropdownProvince] = React.useState<boolean>(false);
  const [showDropDownDistrict, setShowDropdownDistrict] = React.useState<boolean>(false);
  const [showDropDownSubDistrict, setShowDropdownSubDistrict] = React.useState<boolean>(false);
  const methods = useFormContext();
  const agentInfo = useAppSelector(agentInfoSelector);
  const locationResults = useAppSelector(locationResultSelector);
  const selectedLocation = useAppSelector(locationSelectedSelector);

  const dispatch = useAppDispatch();

  const clickOutside = (status: boolean) => {
    setShowDropdownZipcode(status);
    setShowDropdownProvince(status);
    setShowDropdownDistrict(status);
    setShowDropdownSubDistrict(status);
  };

  React.useEffect(() => {
    const fieldsToUpdate: (keyof AgentInfo)[] = [
      "sub_district",
      "district",
      "province",
      "postcode",
    ];

    for (const field of fieldsToUpdate) {
      let value = selectedLocation[field];
      if (field === "postcode") {
        value = selectedLocation.zipcode !== 0 ? selectedLocation.zipcode.toString() : "";
      }
      if (value !== "") {
        dispatch(handleChangeAgentInfo(field, field === "postcode" ? value : value));
      }
    }
  }, [selectedLocation]);

  const debounceHandleChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const showDropdown = (dropdownName: string) => {
      switch (dropdownName) {
        case "sub_district":
          setShowDropdownSubDistrict(true);
          break;
        case "district":
          setShowDropdownDistrict(true);
          break;
        case "province":
          setShowDropdownProvince(true);
          break;
        default:
          // Handle other cases or errors if needed
          break;
      }
    };

    if (["sub_district", "district", "province"].includes(name)) {
      showDropdown(name);
      dispatch(handleFilterLocation(value, name));
    }
  };

  const handleChangePostcode = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match(/^[0-9]*$/g) || event.target.value === "") {
      setShowDropdownZipcode(true);
      dispatch(handleFilterLocation(event.target.value, "zipcode"));
    }
  };

  return (
    <>
      <FormGroup label="ที่อยู่ / บริษัท" className="col-span-4" require>
        <TextField
          name={`address`}
          inputRef={methods.register(messages.required)}
          error={methods.errors?.address?.message}
          value={agentInfo.address}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            dispatch(handleChangeAgentInfo("address", e.target.value))
          }
        />
      </FormGroup>

      <FormGroup label="ถนน" className="col-span-4">
        <TextField
          name={`road`}
          inputRef={methods.register}
          error={methods.errors?.road?.message}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            dispatch(handleChangeAgentInfo("road", e.target.value))
          }
        />
      </FormGroup>

      <FormGroup label="ตำบล" className="col-span-4 md:col-span-2" require>
        <AutoComplete
          show={showDropDownSubDistrict}
          data={locationResults}
          name={`sub_district`}
          inputRef={methods.register(messages.required)}
          error={methods.errors?.sub_district?.message}
          cbOutsideClick={clickOutside}
          cbData={(data) => dispatch(handleCallbackLocation(data))}
          onChange={debounceHandleChangeAddress}
          value={selectedLocation.sub_district}
        />
      </FormGroup>
      <FormGroup label="อำเภอ" className="col-span-4 md:col-span-2" require>
        <AutoComplete
          show={showDropDownDistrict}
          data={locationResults}
          name={`district`}
          inputRef={methods.register(messages.required)}
          error={methods.errors?.district?.message}
          cbOutsideClick={clickOutside}
          cbData={(data) => dispatch(handleCallbackLocation(data))}
          onChange={debounceHandleChangeAddress}
          value={selectedLocation.district}
        />
      </FormGroup>
      <FormGroup label="จังหวัด" className="col-span-4 md:col-span-2" require>
        <AutoComplete
          show={showDropDownProvince}
          data={locationResults}
          name={`province`}
          inputRef={methods.register(messages.required)}
          error={methods.errors?.province?.message}
          cbOutsideClick={clickOutside}
          cbData={(data) => dispatch(handleCallbackLocation(data))}
          onChange={debounceHandleChangeAddress}
          value={selectedLocation.province}
        />
      </FormGroup>
      <FormGroup label="รหัสไปรษณีย์" className="col-span-4 md:col-span-2" require>
        <AutoComplete
          show={showDropDownZipcode}
          maxLength={5}
          data={locationResults}
          name={`postcode`}
          inputRef={methods.register(messages.required)}
          error={methods.errors?.postcode?.message}
          cbOutsideClick={clickOutside}
          cbData={(data) => dispatch(handleCallbackLocation(data))}
          value={selectedLocation.zipcode === 0 ? "" : selectedLocation.zipcode}
          onChange={handleChangePostcode}
        />
      </FormGroup>
    </>
  );
};

export default AddressForm;
