import React from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";

const Tab = () => {
  const location = useLocation();
  return (
    <div className="mt-4">
      <a
        href="/staffs/renewal"
        className={classnames(
          "inline-block px-3 pt-2 pb-2 mr-2 shadow  rounded-t-5",
          location.pathname === "/staffs/renewal" ? "bg-primary text-white" : "bg-gray-300"
        )}
      >
        รอทำใบแจ้งเตือน
      </a>
      <a
        href="/staffs/renewal-doc"
        className={classnames(
          "inline-block px-3 pt-2 pb-2 shadow  rounded-t-5",
          location.pathname === "/staffs/renewal-doc" ? "bg-primary text-white" : "bg-gray-300"
        )}
      >
        อัพโหลดใบแจ้งเตือนแล้ว
      </a>
      <a
        href="/staffs/renewal-closed"
        className={classnames(
          "inline-block px-3 pt-2 pb-2 shadow ml-2 rounded-t-5",
          location.pathname === "/staffs/renewal-closed" ? "bg-primary text-white" : "bg-gray-300"
        )}
      >
        ไม่ต่ออายุ
      </a>
    </div>
  );
};

export default Tab;
