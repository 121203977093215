import { createAsyncThunk } from "@reduxjs/toolkit";
import { isAxiosError, AxiosError } from "axios";
import axios from "~src/helpers/configAxios";
import { escapeHtml } from "~src/helpers/helpers";

export interface ErrorResponse {
  message: string | string[];
  status: number;
}

export const fetchListPDPA = createAsyncThunk<any, void, { rejectValue: ErrorResponse }>(
  "contract/fetchListPDPA",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("api/pdpas");
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchCreatePDPA = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchCreatePDPA",
  async (params, { rejectWithValue }) => {
    try {
      const payload = { pdpa: { ...params, detail: escapeHtml(params.detail) } };

      await axios.post("api/pdpas", payload);
      window.location.href = "/staffs/pdpas";
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchShowPDPA = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchShowPDPA",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`api/pdpas/${params.id}`);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchUpdatePDPA = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchUpdatePDPA",
  async (params, { rejectWithValue }) => {
    try {
      const payload = { pdpa: { ...params.payload, detail: escapeHtml(params.payload.detail) } };

      await axios.patch(`api/pdpas/${params.id}`, payload);
      window.location.href = "/staffs/pdpas";
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchDeletePDPA = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchDeletePDPA",
  async (params, { rejectWithValue }) => {
    try {
      await axios.delete(`api/pdpas/${params.id}`);
      window.location.reload();
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchAgentPdpa = createAsyncThunk<any, void, { rejectValue: ErrorResponse }>(
  "contract/fetchAgentPdpa",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("api/pdpas/agent_pdpa");
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchAcceptPdpa = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchAcceptPdpa",
  async (params, { rejectWithValue }) => {
    try {
      await axios.post(`api/pdpas/agent_accept`, params);
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchAcceptPdpaWithAnswers = createAsyncThunk<
  any,
  void,
  { rejectValue: ErrorResponse }
>("contract/fetchAcceptPdpaWithAnswers", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`api/pdpas/accepted_pdpa_with_answers`);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const { status, data } = axiosError.response as {
          status: number;
          data: {
            message: string | string[];
          };
        };
        if (status === 401) {
        }

        return rejectWithValue({ message: data.message, status });
      }
    }
    return rejectWithValue({ message: "An error occurred", status: 500 });
  }
});

export const fetchNewAgentPdpa = createAsyncThunk<any, void, { rejectValue: ErrorResponse }>(
  "contract/fetchNewAgentPdpa",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("api/pdpas/new_agent_pdpa");
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);
