import iconBki from "/assets/insurer_icons/bki.png";
import iconTvi from "/assets/insurer_icons/tvi.png";
import iconVir from "/assets/insurer_icons/vir.png";
import iconSmk from "/assets/insurer_icons/smk.jpeg"
import iconSafety from "/assets/insurer_icons/safety.png"
import iconMti from "/assets/insurer_icons/mti.png"
import iconMsig from "/assets/insurer_icons/msig.png"
import iconChubb from "/assets/insurer_icons/chubb.png"
import iconAzcp from "/assets/insurer_icons/azcp.png"
import iconAig from "/assets/insurer_icons/aig.png"
import iconAxa from "/assets/insurer_icons/axa.png"
import iconDefault from "/assets/insurer_icons/default.png";

export default function useIcons(insurerCode) {
  let result
  switch(insurerCode.toLowerCase()) {
    case 'bki': result = iconBki; break;
    case 'tvi': result = iconTvi; break;
    case 'vir': result = iconVir; break;
    case 'smk': result = iconSmk; break;
    case 'safe': result = iconSafety; break;
    case 'mti': result = iconMti; break;
    case 'msig': result = iconMsig; break;
    case 'chubb': result = iconChubb; break;
    case 'azcp', 'aagi': result = iconAzcp; break;
    case 'aig': result = iconAig; break;
    case 'axa': result = iconAxa; break;
    default: iconDefault
  }

  return result
}
