import React from "react";
import "./index.scss";

const IconStatus = ({ status }) => {
  return (
    <>
      {!status && (
        <span className="icon-status false">
          <span className="material-icons">clear</span>
        </span>
      )}
    </>
  );
};

export default IconStatus;
{
  status;
}
