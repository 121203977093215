import React from "react";
import { OrderPaymentsSummaryLineItem } from "~src/types/order";
import { State } from "../store";

interface Total {
  totalVMISellingPrice: number;
  totalAmount: number;
}

const useTotalPremium = (state: State, type: string): Total => {
  const lineItemAs: OrderPaymentsSummaryLineItem[] =
    state.renew?.payments_summary?.line_itemas.filter((o) => o.policy_type === type);

  const total: Total = React.useMemo(() => {
    const premiumGross: number = parseFloat(lineItemAs?.[0]?.premium_gross) || 0;
    const addonSelling: number =
      state.renew?.checked_addon && type !== "cmi"
        ? parseFloat(state.renew?.addon_selling_price)
        : 0;
    const premiumStamp: number = parseFloat(lineItemAs?.[0]?.premium_stamp) || 0;
    const premiumVat: number = parseFloat(lineItemAs?.[0]?.premium_vat) || 0;

    return {
      totalVMISellingPrice: premiumGross + premiumStamp + premiumVat,
      totalAmount: premiumGross + addonSelling + premiumStamp + premiumVat,
    };
  }, [state]);

  return total;
};

export default useTotalPremium;
