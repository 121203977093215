import _ from "lodash";
import { differenceInDays } from "date-fns";
import queryString from "query-string";
import React, { useState, useEffect, Fragment } from "react";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";

import Badges from "/src/components/badges";
import QuotationList from "/src/pages/agent/quotation/quotationList";
import Alert from "/src/components/alert";
import { ToastProvider, useToasts } from "react-toast-notifications";

import SectionHeader from "/src/components/layout/staff/SectionHeader";
import SearchForm from "/src/components/staff/SearchForm";
import ErrorPage from "/src/components/errorPage";

import yml from "/src/status.yml";
import { parseDateTH, parseDate, dateFormat } from "/src/helpers/dateTime";
import { urlStringify } from "/src/helpers/helpers";
import useFetch from "/src/hooks/useFetch";
import useInput from "/src/hooks/useInput";

import "./viewQuotation.scss";
import useSecureLink from "~src/hooks/useSecureLink";
import Preloading from "~src/components/loading/Preloading";

const BASE_URL = "/api/quotations";
const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const Quotations = ({ location }) => {
  const { t } = useTranslation();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const url = `${BASE_URL}?${urlStringify({
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    status: "open",
    ...queryParams,
  })}`;

  const [isOpenQuotationList, setIsOpenQuotationList] = useState({
    0: true,
  });
  const [quotations, setQuotations] = useState([]);
  const [currentDate] = useState(new Date());
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(queryParams.page ?? DEFAULT_PAGE);
  const [isError, setIsError] = useState(false);
  const { addToast } = useToasts();

  const { response, error, isLoading } = useFetch(url);
  const { value: search_value, bind: handleChaneSearch } = useInput(queryParams?.keyword ?? "");

  const { secureDownloads, secureLinkLoading } = useSecureLink();

  useEffect(() => {
    if (!isLoading && !error) {
      const results = response?.content;
      const totalPages = response.total_pages;

      setPageCount(totalPages);
      setQuotations(results);
      setIsError(false);
    }

    if (error) {
      setIsError(true);
    }
  }, [response, error, isLoading]);

  const handleToggle = (i) => {
    setIsOpenQuotationList({
      [i]: !isOpenQuotationList[i],
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = parseInt(e.selected) + 1;
    performSearch(selectedPage);
  };

  const handleClickSearch = () => {
    performSearch(1);
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") performSearch(1);
  };

  const handleDownloadPdf = (url) => {
    secureDownloads([url]);
  };

  const performSearch = (page) => {
    const queries = urlStringify({
      keyword: search_value,
      per_page: PER_PAGE,
      page: page ?? 1,
    });
    window.location.href = `/quotations?${queries}`;
  };

  const printCarDescription = (info) => {
    return `${info.car_brand} ${info.car_model}`;
  };
  const Phone = ({ phoneNumber }) => {
    return (
      <a href={`tel:${phoneNumber}`} className="link-tel">
        <span className="phone">
          <i className="hero-icon-phone" />
          {phoneNumber}
        </span>
      </a>
    );
  };
  const DownloadPdf = ({ pdfUrl }) => {
    if (pdfUrl) {
      return (
        <div>
          <button
            role="button"
            className="download-btn"
            onClick={() => handleDownloadPdf(pdfUrl)}
            rel="noreferrer"
          >
            <i className="icon-hero-download mr-1" />
            <span>ดาวน์โหลด</span>
          </button>
        </div>
      );
    }
    return (
      <div>
        <a role="button" className="download-btn disable">
          <i className="icon-hero-download mr-1" />
          <span>ดาวน์โหลด</span>
        </a>

        <button className="copyURL-btn opacity-50">
          <i className="icon-hero-link mr-1" />
          คัดลอกลิ้งค์
        </button>
      </div>
    );
  };
  function setAlert(text) {
    addToast(
      <div>
        <h4>{text}</h4>
      </div>,
      {
        appearance: "success",
        autoDismiss: true,
      }
    );
  }

  return (
    <div className="view-quotation">
      {isError ? (
        <ErrorPage />
      ) : (
        <>
          {secureLinkLoading && <Preloading />}
          <div className="view-quotation-title">
            <SectionHeader
              title="ใบเสนอราคา"
              breadcrumbs={[{ url: "#", label: "รายการซื้อขาย", current: true }]}
              searchComponent={
                <SearchForm
                  type="text"
                  placeholder="ค้นหาเลขที่ใบเสนอราคา, ชื่อลูกค้า"
                  {...handleChaneSearch}
                  handleClick={handleClickSearch}
                  handleKeyPress={handleKeyPressSearch}
                />
              }
            />
            <div className="title-bottom">
              <span className="gettmore-icon-info" />
              {` ใบเสนอราคาในระบบ ${t("fullAppName")} มีอายุ 30 วัน นับตั้งแต่วันที่สร้างรายการ`}
            </div>
          </div>

          <div className="view-quotation-component">
            <div className="table-grid">
              <div className="grid-th">
                <div />
                <div>เลขที่ใบเสนอราคา</div>
                <div>จำนวนแผนที่เสนอ</div>
                <div>ข้อมูลลูกค้า</div>
                <div>รายละเอียดเพิ่มเติม</div>
                <div>วันที่สร้างรายการ</div>
                <div>วันที่หมดอายุใบเสนอราคา</div>
                <div />
              </div>
              {isLoading && <div className="grid-tbody-full">กำลังโหลด...</div>}
              {!isLoading && (
                <>
                  {quotations.length == 0 && <div className="grid-tbody-full">ไม่พบข้อมูล</div>}
                  {quotations.map((obj, i) => {
                    const { created_date, expired_date, status } = obj;
                    const parseExpireDate = parseDate(expired_date, "yyyy/MM/dd");
                    const differenceDays = differenceInDays(parseExpireDate, currentDate);
                    const expiredDateTH = parseDateTH(expired_date, "yyyy/MM/dd");
                    const createdDateTH = parseDateTH(created_date, "yyyy/MM/dd");

                    const disabledAction = parseExpireDate > currentDate;
                    return (
                      <Fragment key={i}>
                        <div className="grid-tbody">
                          <div>
                            <button
                              className={isOpenQuotationList[i] ? "expand-on" : "expand"}
                              onClick={() => handleToggle(i)}
                            >
                              <i className="material-icons">
                                {isOpenQuotationList[i]
                                  ? "keyboard_arrow_up"
                                  : "keyboard_arrow_down"}
                              </i>
                            </button>
                          </div>
                          <div>
                            <span
                              className={obj.product_type == "motor" ? "icon-motor" : "icon-travel"}
                            />
                            {` ${obj.number}`}
                            <Badges status={`quotation-${obj.status}`}>
                              {yml.status.quotation[obj.status]}
                            </Badges>
                          </div>
                          <div>{`${obj.quotes.length} แผน`}</div>
                          <div>
                            {obj.customer_name}
                            <Phone phoneNumber={obj.customer_phone} />
                          </div>
                          <div>{printCarDescription(obj.criteria)}</div>
                          <div>
                            {dateFormat(parseDate(obj.created_date, "yyyy/MM/dd"), "dd/MM/yyyy")}
                          </div>
                          <div>
                            {dateFormat(parseDate(obj.expired_date, "yyyy/MM/dd"), "dd/MM/yyyy")}
                          </div>
                          <div>{disabledAction && <DownloadPdf pdfUrl={obj.pdf_url} />}</div>
                        </div>
                        {isOpenQuotationList[i] && (
                          <div className="grid-expand-table">
                            <QuotationList
                              idx={i}
                              quotation_id={obj.id}
                              criteria={obj.criteria}
                              quotes={obj.quotes}
                              currentDate={currentDate}
                              expiredDate={parseExpireDate}
                            />
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </>
              )}
            </div>
            {error ? <Alert message="error" /> : ""}
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel="Prev"
                nextLabel="Next"
                breakLabel="..."
                breakClassName="break-me"
                initialPage={Number(currentPage) - 1}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="gm-paginate"
                subContainerClassName="pages pagination"
                activeClassName="active"
                disableInitialCallback={true}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
const ViewQuotation = ({ location }) => {
  return (
    <ToastProvider placement="top-right">
      <Quotations location={location} />
    </ToastProvider>
  );
};
export default ViewQuotation;
