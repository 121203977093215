import { configureStore, Middleware } from "@reduxjs/toolkit";
import logger from "redux-logger";

import { reducers as travelProductReducer } from "../features/travel/redux";
import bannerReducer from "~src/features/banner/bannerSlice";
import { reducer as locationReducer } from "../features/locations";
import { reducer as contractReducer } from "../features/contracts";
import { reducer as notificationReducer } from "../features/notification";
import { reducer as pdpaReducer } from "../features/pdpa";
import { reducer as registerReducer } from "../features/register";

const middleware: Middleware[] = [];

if (process.env.NODE_ENV !== "production") {
  middleware.push(logger);
}

export const store = configureStore({
  reducer: {
    travel: travelProductReducer,
    banner: bannerReducer,
    contract: contractReducer,
    notification: notificationReducer,
    pdpa: pdpaReducer,
    register: registerReducer,
    locations: locationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
