import React, {useState, useEffect} from "react";
import SectionHeader from "/src/components/layout/staff/SectionHeader";
import ErrorPage from "/src/components/errorPage";
import useFetch from "/src/hooks/useFetch";
import {format} from "date-fns";

import "./lead.scss";
import "babel-polyfill";
import Label from "~src/components/Forms/Label";
import DatePicker from "~src/components/Forms/DatePicker";
import {ButtonRadius} from "~src/components/form";
import {urlStringify} from "~src/helpers/helpers";
import queryString from "query-string";
import SaleSummaryTable from "~src/pages/agent/lead/table/SaleSummaryTable";

const DEFAULT_START_DATE = format(new Date(new Date().getFullYear(), 0, 1), "dd/MM/yyyy")
const DEFAULT_END_DATE = format(new Date(new Date().getFullYear(), 11, 31), "dd/MM/yyyy")

const SaleSummary = ({location}) => {
  // comment for redeploy can be delete later
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const [loaded, setLoaded] = useState(true);
  const [summaries, setSummaries] = useState([]);
  const [isError, setIsError] = useState(false);
  const [startDate, setStartDate] = useState(queryParams["created_at_gteq"] ?? DEFAULT_START_DATE);
  const [endDate, setEndDate] = useState(queryParams["created_at_lteq"] ?? DEFAULT_END_DATE);

  const getSearchParams = () => {
    return {
      created_at_gteq: startDate,
      created_at_lteq: endDate
    };
  };

  const url = `/api/leads/summary?${urlStringify(Object.assign({
    ...queryParams
  }))}`;

  const {response, error, isLoading} = useFetch(url);

  useEffect(() => {
    if (!isLoading && !error) {
      const results = response?.content;
      setSummaries(results);
      setLoaded(false);
      setIsError(false);
    }

    if (error) {
      setIsError(true);
    }
  }, [isLoading]);

  const handleChangeStartDate = (date) => {
    setStartDate(format(date, "dd/MM/yyyy"));
  }

  const handleChangeEndDate = (date) => {
    setEndDate(format(date, "dd/MM/yyyy"));
  }

  const handleClickFilter = () => {
    performSearch();
  };

  const performSearch = () => {
    const queries = urlStringify(getSearchParams());
    window.location.href = `/leads_summary?${queries}`;
  }

  return (
    <div className="lead-page">
      {(isError) ? (
        <ErrorPage/>
      ) : (
        <>
          <SectionHeader
            title="Summary Lead"
            breadcrumbs={[{url: "#", label: "lead", current: true}]}
          />

          <section className="filter-area">
            <div className="filter-area-box">
              <div className="w-full px-2 mb-4">
                <Label htmlFor="renew_cover_start_date">
                  วันที่เริ่ม
                </Label>
                <DatePicker
                  id="created_at_gteq"
                  value={startDate}
                  onChange={handleChangeStartDate}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="w-full px-2 mb-4">
                <Label htmlFor="renew_cover_end_date">
                  วันที่สิ้นสุด
                </Label>
                <DatePicker
                  id="created_at_lteq"
                  value={endDate}
                  onChange={handleChangeEndDate}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="w-1/2 mt-20">
                <ButtonRadius color="secondary" onClick={handleClickFilter}>
                  ค้นหา
                </ButtonRadius>
              </div>
            </div>
          </section>

          <SaleSummaryTable loading={isLoading} data={summaries} />
        </>
      )}
    </div>
  );
};

export default SaleSummary;
