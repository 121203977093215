import React, { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

import { checkEmpty } from "~/src/helpers/helpers";
import { parseDate, dateFormat } from "~/src/helpers/dateTime";

import {
  FormGroup,
  TextField,
  DatePickerField,
  SelectField,
  Switcher,
} from "~/src/components/form";

import { titleAgentTHOptions, educationLevelOptions } from "~/src/helpers/options";
import { messages } from "~/src/helpers/messages";
import { useAppDispatch, useAppSelector } from "~src/store";
import { agentInfoSelector, handleChangeAgentInfo } from "./registerSlice";

const AgentInfo = () => {
  const methods = useFormContext();

  const agentInfo = useAppSelector(agentInfoSelector);
  const dispatch = useAppDispatch();

  const handleChangeCitizenId = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/gi, "");
    const count = value.split("");
    if (count.length > 13) return;

    dispatch(handleChangeAgentInfo("citizen_id", value));
  };

  return (
    <div>
      <div className="flex items-center justify-between w-full col-span-1 col-start-1 text-xl bg-custom-gray-sm mb-3">
        <span className="p-2">ข้อมูลตัวแทน</span>
      </div>

      <div className="grid grid-cols-2">
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="คำนำหน้าชื่อ" htmlForLabel="title" require>
              <SelectField
                id="title"
                name="title"
                inputRef={methods.register(messages.requiredSelect)}
                error={methods.errors.title?.message}
                options={titleAgentTHOptions}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  dispatch(handleChangeAgentInfo("title", e.target.value));
                }}
                value={agentInfo.title}
              />
            </FormGroup>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2">
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="ชื่อ" htmlForLabel="firstname" require>
              <TextField
                id="firstname"
                name="firstname"
                inputRef={methods.register(messages.required)}
                error={methods.errors.firstname?.message}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  dispatch(handleChangeAgentInfo("firstname", e.target.value));
                }}
                value={agentInfo.firstname}
              />
            </FormGroup>
          </div>
        </div>

        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="นามสกุล" htmlForLabel="lastname" require>
              <TextField
                id="lastname"
                name="lastname"
                inputRef={methods.register(messages.required)}
                error={methods.errors.lastname?.message}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  dispatch(handleChangeAgentInfo("lastname", e.target.value));
                }}
                value={agentInfo.lastname}
              />
            </FormGroup>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2">
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="วันเดือนปีเกิด" htmlForLabel="dob" require>
              <DatePickerField
                type="text"
                id="dob"
                name="dob"
                defaultView="decade"
                maxDate={parseDate(dateFormat(new Date()))}
                callbackvalue={(date: string) => {
                  dispatch(handleChangeAgentInfo("dob", date));
                }}
                inputRef={methods.register({
                  ...messages.required,
                  ...messages.validateBirthday,
                })}
                value={agentInfo.dob}
                error={methods.errors.dob?.message}
              />
            </FormGroup>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup
              label="เลขที่บัตรประชาชน / เลขประจําตัวผู้เสียภาษี"
              htmlForLabel="citizen_id"
              require
            >
              <TextField
                id="citizen_id"
                name="citizen_id"
                value={agentInfo.citizen_id}
                inputRef={methods.register({
                  ...messages.required,
                  ...messages.validateIdCard,
                })}
                error={methods.errors.citizen_id?.message}
                onChange={handleChangeCitizenId}
              />
            </FormGroup>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2">
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="ระดับการศึกษา" htmlForLabel="education_level">
              <SelectField
                id="education_level"
                name="education_level"
                inputRef={methods.register(messages.requiredSelect)}
                error={methods.errors.education_level?.message}
                options={educationLevelOptions}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  dispatch(handleChangeAgentInfo("education_level", e.target.value));
                }}
                value={agentInfo.education_level}
              />
            </FormGroup>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="px-2">
            <FormGroup label="เบอร์ติดต่อ" htmlForLabel="mobile_no" require>
              <TextField
                id="mobile_no"
                name="mobile_no"
                inputRef={methods.register({
                  ...messages.required,
                  ...messages.mobileNumber,
                })}
                error={methods.errors.mobile_no?.message}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  dispatch(handleChangeAgentInfo("mobile_no", e.target.value));
                }}
                value={agentInfo.mobile_no}
              />
            </FormGroup>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2">
        <div className="col-span-2 md:col-span-1 px-2">
          <FormGroup label="อีเมล" htmlForLabel="email" require>
            <TextField
              id="email"
              name="email"
              inputRef={methods.register({
                ...messages.required,
                ...messages.email,
              })}
              error={methods.errors.email?.message}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                dispatch(handleChangeAgentInfo("email", e.target.value));
              }}
              value={agentInfo.email}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default AgentInfo;
