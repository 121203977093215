import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { messages } from "~src/helpers/messages";
import { beneficiaryOptions, titleENOptions } from "~src/helpers/options";
import { Insured } from "~src/types/insured";
import { FormGroup, Radio, SelectField, TextField } from "../form";

interface BeneficiaryProps {
  insuredsInfo: Insured;
  fieldName: string;
  index: number;
  defaultValue: any;
}

const BeneficiaryForm: React.FC<BeneficiaryProps> = ({
  insuredsInfo,
  fieldName,
  index,
  defaultValue,
}): JSX.Element => {
  const { register, control, errors } = useFormContext();
  const watchReciever = useWatch({
    control,
    name: `${fieldName}.beneficiary_attributes[receiver]`,
    defaultValue: defaultValue?.receiver,
  });

  return (
    <div className="space-y-16">
      <Radio
        id={`legal_heir-${fieldName}`}
        name={`${fieldName}.beneficiary_attributes[receiver]`}
        value="legal_heir"
        inputRef={register(messages.required)}
        defaultChecked={defaultValue?.receiver === "legal_heir"}
        label="ทายาทโดยชอบธรรม"
      />
      <Radio
        id={`define_beneficiary-${fieldName}`}
        name={`${fieldName}.beneficiary_attributes[receiver]`}
        value="define_beneficiary"
        inputRef={register(messages.required)}
        defaultChecked={defaultValue?.receiver === "define_beneficiary"}
        label="หรือ ระบุผู้รับผลประโยชน์"
      />
      {watchReciever === "define_beneficiary" && (
        <>
          <div className="row">
            <div className="col-md-6">
              <FormGroup label="คำนำหน้าชื่อ" htmlForLabel="payer_title" require>
                <input
                  type="hidden"
                  name={`${fieldName}.beneficiary_attributes[id]`}
                  value={insuredsInfo?.beneficiary?.id}
                  ref={register}
                />
                <SelectField
                  id="payer_title"
                  name={`${fieldName}.beneficiary_attributes[title]`}
                  defaultValue={insuredsInfo?.beneficiary?.title}
                  inputRef={register(messages.requiredSelect)}
                  error={
                    errors.order?.insureds_attributes?.[index]?.beneficiary_attributes?.title
                      ?.message
                  }
                  options={titleENOptions}
                />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormGroup
                label="ชื่อ"
                htmlForLabel="payer_firstname"
                errorRequire="(ระบุชื่อเป็นภาษาอังกฤษ)"
                require
              >
                <Controller
                  id="payer_firstname"
                  name={`${fieldName}.beneficiary_attributes[firstname]`}
                  rules={messages.required}
                  defaultValue={insuredsInfo?.beneficiary?.firstname}
                  control={control}
                  render={({ onChange, value }) => (
                    <TextField
                      value={value}
                      placeholder="ภาษาอังกฤษ"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (/^[A-Za-z]*$/.test(e.target.value))
                          onChange(e.target.value.toUpperCase());
                      }}
                      error={
                        errors.order?.insureds_attributes?.[index]?.beneficiary_attributes
                          ?.firstname?.message
                      }
                    />
                  )}
                />
              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup
                label="นามสกุล"
                htmlForLabel="payer_lastname"
                errorRequire="(ระบุนามสกุลเป็นภาษาอังกฤษ)"
                require
              >
                <Controller
                  id="payer_lastname"
                  name={`${fieldName}.beneficiary_attributes[lastname]`}
                  rules={messages.required}
                  defaultValue={insuredsInfo?.beneficiary?.lastname}
                  control={control}
                  render={({ onChange, value }) => (
                    <TextField
                      value={value}
                      placeholder="ภาษาอังกฤษ"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (/^[A-Za-z]*$/.test(e.target.value))
                          onChange(e.target.value.toUpperCase());
                      }}
                      error={
                        errors.order?.insureds_attributes?.[index]?.beneficiary_attributes?.lastname
                          ?.message
                      }
                    />
                  )}
                />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <FormGroup label="ความสัมพันธ์" htmlForLabel="payer_relationship" require>
                <SelectField
                  id="payer_relationship"
                  name={`${fieldName}.beneficiary_attributes[relationship]`}
                  defaultValue={insuredsInfo?.beneficiary?.relationship}
                  inputRef={register(messages.requiredSelect)}
                  error={
                    errors.order?.insureds_attributes?.[index]?.beneficiary_attributes?.relationship
                      ?.message
                  }
                  options={beneficiaryOptions}
                />
              </FormGroup>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BeneficiaryForm;
