import React from "react";

import Label from "/src/components/staff/Label";

import {
  formatNumberWithCurrency,
  formatNumberWithCurrency2,
} from "/src/helpers/formatNumber";

const Policy = (props) => {
  const { data } = props;
  return (
    <div className="row">
      <div className="col-12">
        <Label title="บริษัทประกัน" value={data?.insurer_code ?? "-"} inline />
      </div>
      <div className="col-12">
        <Label
          title="แผนประกัน"
          value={`ชั้น ${data.policy_details[0]?.product_type}`}
          inline
        />
      </div>
      <div className="col-12">
        <Label
          title="ทุนประกัน"
          value={formatNumberWithCurrency2(data.policy_details[0]?.sum_insured)}
          inline
        />
      </div>
      {/* <div className="col-12">
        <Label
          title="ค่าเบี้ยพ.ร.บ."
          value={formatNumberWithCurrency(data.policy_details[0]?.sum_insured)}
          inline
        />
      </div> */}
      <div className="col-12">
        <Label
          title="ค่าเบี้ยประกันภัย"
          value={formatNumberWithCurrency(
            data.policy_details[0]?.selling_price
          )}
          inline
        />
      </div>
      <div className="col-12">
        <Label
          title="เบี้ยประกันที่ชำระ"
          value={formatNumberWithCurrency(
            data.policy_details[0]?.selling_price
          )}
          inline
        />
      </div>
    </div>
  );
};

export default Policy;
