import React, { useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useFormContext, useWatch } from "react-hook-form";
import classNames from "classnames";
import AddressForm from "../form/AddressForm";
import { deliveryAddressTypeOptions, deliveryTypeOptions } from "~/src/helpers/options";
import { Checkbox, Switcher } from "~/src/components/form";
import { messages } from "~src/helpers/messages";

const DeliveryPanel: React.FC = (): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [deliveryType, setDeliveryType] = useState<string>(deliveryAddressTypeOptions[0].value);
  const { errors, control, register, setValue, clearErrors } = useFormContext();

  const watchInsuredEmail = useWatch({
    control,
    name: "order[insureds_attributes][].email",
    defaultValue: undefined,
  });

  const watchDeliveryType = useWatch({
    control,
    name: "order[insureds_attributes][].delivery_type[]",
    defaultValue: [],
  });

  useEffect(() => {
    if (!watchInsuredEmail) {
      const ePolicyCheckBox = document.getElementById("delivery_type_1") as HTMLInputElement;
      ePolicyCheckBox.checked = false;
    }
  }, [watchInsuredEmail]);

  return (
    <div className="p-4 w-full bg-white shadow rounded-5">
      <h6 className="flex items-center justify-between p-1 px-2 text-sm font-bold bg-gray-100">
        ข้อมูลการจัดส่งกรมธรรม์
        <button
          type="button"
          onClick={() => setExpanded(!expanded)}
          className="flex items-center justify-center w-6 h-6 bg-white shadow outline-none rounded-5 focus:outline-none"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </button>
      </h6>

      <div className={classNames(expanded ? "block" : "hidden")}>
        <div className="grid grid-cols-4 col-span-5 gap-4 mt-4">
          {deliveryTypeOptions.map((option, index) => (
            <div className="col-span-2" key={option.value}>
              <Checkbox
                id={`delivery_type_${option.value}`}
                name="order[insureds_attributes][].delivery_type[]"
                label={option.label}
                value={option.value}
                inputRef={register(messages.required)}
                disabled={option.value === 1 && !watchInsuredEmail}
                checkedAria
              />

              {index === 0 && watchInsuredEmail && (
                <a href={`mailto:${watchInsuredEmail}`} className="ml-8 text-gray-400 underline">
                  {`(${watchInsuredEmail})`}
                </a>
              )}
            </div>
          ))}
          {errors?.order?.insureds_attributes?.delivery_type?.message && (
            <span className="text-xs text-red-600">
              {errors?.order?.insureds_attributes?.delivery_type?.message}
            </span>
          )}

          {watchDeliveryType.includes("2") && (
            <div className="col-span-4">
              <Switcher
                id="delivery_type_switcher"
                name="delivery_type_switcher"
                active={deliveryType}
                onChange={(e) => setDeliveryType(e.target.value)}
                labels={deliveryAddressTypeOptions}
              />
              <input
                type="hidden"
                name="order[insureds_attributes][].delivery_address_type"
                value={deliveryType}
                ref={register}
              />
            </div>
          )}

          {deliveryType === "other_address" && (
            <>
              <AddressForm
                name="order[insureds_attributes][].addresses_attributes[1]"
                setValue={setValue}
                register={register}
                clearErrors={clearErrors}
                errors={errors?.order?.insureds_attributes?.addresses_attributes?.[1]}
                control={control}
              />
              <input
                type="hidden"
                name="order[insureds_attributes][].addresses_attributes[1][address_type]"
                value="delivery"
                ref={register}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryPanel;
