import React from "react";
import yaml from "/src/data";
const QuotationDetail = ({ title, agentName, productInfo, repairFacility }) => {
  return (
    <>
      <div className="d-flex items-center">
        <h6>
          <span className="icon-motor icon-size" />{" "}
          สร้างใบเสนอราคาประกันภัยรถยนต์
        </h6>
      </div>
      <div className="row p-3">
        <div className="mr-4">
          <div>บริษัทประกัน</div>
          <div>ประเภท</div>
          <div>แพ็คเกจ</div>
          <div>รถยนต์</div>
        </div>
        <div className="mr-20">
          <div className="text-gray-600">{productInfo.insurer_name}</div>
          <div className="text-gray-600">{`${
            yaml.policy_type[productInfo.policy_type]
          } ${yaml.repair_facility[repairFacility]}`}</div>
          <div className="text-gray-600">{productInfo.name}</div>
          <div className="text-gray-600">{title}</div>
        </div>
        <div className="mr-4">
          <div>ออกให้ ณ. วันที่</div>
          <div>ตัวแทน</div>
        </div>
        <div>
          <div className="text-gray-600">{new Date().toLocaleDateString()}</div>
          <div className="text-gray-600"> {agentName}</div>
        </div>
      </div>
    </>
  );
};

export default QuotationDetail;
