import React from "react";
import "./input.scss";

const TextField = ({ inputRef = null, error = null, className = "", helpText = null, ...rest }) => {
  const fldErrorClassNames = !error
    ? rest.field == "chassis-number"
      ? "form-input chassis-number"
      : "form-input"
    : "form-input has-error";

  return (
    <>
      <input
        {...rest}
        ref={inputRef}
        // className={!error ? "form-input" : "form-input has-error"}
        className={`${fldErrorClassNames} ${className}`}
      />
      {helpText && <p className="form-input-help-text">{helpText}</p>}
      {error ? <p className="form-input-error">{error}</p> : ""}
    </>
  );
};

export default TextField;
