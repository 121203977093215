import { addYears } from "date-fns";

// import { CheckoutState } from "~src/models/CheckoutState";

import { DeliveryAddressAttributes, InsuredsAttributes } from "~src/types/checkoutBasicForm";
import { Order } from "~src/types/order";

// TODO: Refactor/Consolidate Checkout States

export interface State {
  product_type: string;
  delivery_address_attributes: DeliveryAddressAttributes;
  insured_category: string;
  insureds_attributes: InsuredsAttributes;
  order: Partial<Order>;
}

export interface AddAction {
  type: "ADD";
  payload: Partial<State>;
}

export interface ResetAction {
  type: "RESET";
  payload: any;
}

export type Action = AddAction | ResetAction;

const motorState: State = {
  product_type: "motor",
  insured_category: "",
  insureds_attributes: {
    company_name: "",
    company_tax: "",
    title: "",
    firstname: "",
    lastname: "",
    birthday: "",
    age: "",
    email: "",
    phone_number: "",
    office_phone_number: "",
    nationality: "",
    idcard: "",
    passport: "",
    address: "",
    province: "",
    district: "",
    sub_district: "",
    postcode: "",
    beneficiary: {
      receiver: "legal_heir",
      title: "",
      firstname: "",
      lastname: "",
      relationship: "",
      remark: "",
    },
    delivery_type: [],
    delivery_address_type: "insured_address",
  },
  order: {
    include_wh_tax: true,
    details: {
      chassis_number: "",
      cover_end_date: "",
      cover_start_date: "",
      addon_start_date: new Date(),
      addon_end_date: addYears(new Date(), 1),
      plate_number: "",
      plate_province: "",
    },
  },
  delivery_address_attributes: {
    address_type: "insured",
    address: "",
    province: "",
    district: "",
    sub_district: "",
    postcode: "",
  },
};

const vaccineState: State = {
  product_type: "vaccine",
  insured_category: "",
  insureds_attributes: {
    company_name: "",
    company_tax: "",
    title: "",
    firstname: "",
    lastname: "",
    birthday: "",
    age: "",
    email: "",
    phone_number: "",
    office_phone_number: "",
    nationality: "",
    idcard: "",
    passport: "",
    address: "",
    province: "",
    district: "",
    sub_district: "",
    postcode: "",
    beneficiary: {
      receiver: "legal_heir",
      title: "",
      firstname: "",
      lastname: "",
      relationship: "",
      remark: "",
    },
    delivery_type: [],
    delivery_address_type: "insured_address",
  },
  order: {
    details: {
      chassis_number: "",
      cover_end_date: "",
      cover_start_date: "",
      addon_start_date: new Date(),
      addon_end_date: addYears(new Date(), 1),
      plate_number: "",
      plate_province: "",
    },
  },
  delivery_address_attributes: {
    address_type: "insured",
    address: "",
    province: "",
    district: "",
    sub_district: "",
    postcode: "",
  },
};

export const initCheckoutState = (productType: string) => {
  switch (productType) {
    case motorState.product_type:
      return {
        ...motorState,
        product_type: productType,
      };
    case vaccineState.product_type:
      return {
        ...vaccineState,
        product_type: productType,
      };
    default:
      return {
        ...motorState,
        product_type: productType,
      };
    // return []
  }
};

export default function CheckoutReducer(state: State, action: Action): State {
  const { type, payload } = action;

  switch (type) {
    case "ADD":
      return {
        ...state,
        ...payload,
      };
    case "RESET":
      return initCheckoutState(state.product_type);
    default:
      return state;
  }
}
