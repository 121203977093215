import React from "react";
import List from "./List";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import { getTotalAmountDetails } from "~src/helpers/quotationHelper";

const AmountInfo = ({ payment, includeWhTax, quotation = undefined }) => {
  const mainItem = payment.line_itemas.filter((p) => p.policy_type !== "cmi");
  const cmiItems = payment.line_itemas.filter((p) => p.policy_type === "cmi");
  const totalAmountDetail = getTotalAmountDetails(
    quotation?.criteria,
    quotation?.product_type,
    payment?.payment_total_amount
  );

  return (
    <div>
      <h6 className="bg">รายละเอียดยอดที่ต้องชำระ</h6>
      <div className="list-content-2">
        {mainItem.length > 0 && (
          <List
            label="เบี้ยประกันภัยรวม"
            value={formatNumberWithCurrency(mainItem[0].selling_price)}
            type={2}
            align="right"
          />
        )}
        {cmiItems.length > 0 && (
          <List
            label="เบี้ยพรบ."
            value={formatNumberWithCurrency(cmiItems[0].selling_price)}
            type={2}
            align="right"
          />
        )}

        <List
          label="เบี้ยประกันภัยทั้งสิ้น"
          value={
            <>
              {formatNumberWithCurrency(payment.sum_selling_price)}
              <br />
              {totalAmountDetail && (
                <p className="text-gray-500" style={{ fontSize: "10px" }}>
                  {totalAmountDetail}
                </p>
              )}
            </>
          }
          smallText="(รวมภาษี & อากร)"
          bold
          type={2}
          align="right"
        />
        {process.env.APP_NAME !== "mti" && includeWhTax && (
          <List
            label="ภาษีหัก ณ ที่จ่าย 1 %"
            value={formatNumberWithCurrency(payment.withhold_tax_amount)}
            type={2}
            align="right"
          />
        )}
        <List
          label="ส่วนลดให้ลูกค้า"
          value={formatNumberWithCurrency(payment.sum_discount)}
          align="right"
          type={2}
        />
        <List
          label="ยอดที่ลูกค้าต้องชำระ"
          value={formatNumberWithCurrency(payment.payment_total_amount)}
          align="right"
          labelBold
          bold
          type={2}
          color="primary"
        />
      </div>
    </div>
  );
};

export default AmountInfo;
