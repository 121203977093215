import React, { useEffect, useState } from "react";
import _ from "lodash";

import {
  Edit,
  FileCopy,
  CheckCircleOutline,
  Close,
  HighlightOff,
  Check,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import AgentInfo from "../../../models/AgentInfo";
import LicenseInfo from "../../../models/LicenseInfo";
import { useAuthenticate } from "~src/helpers/authen";
import renderLocation from "~src/helpers/renderLocation";
import EditIcon from "@mui/icons-material/Edit";

import { checkEmpty, dateFormat } from "../../../helpers/helpers";
import { educationLevelOptions } from "~src/helpers/options";
import { getLicenseByType } from "../../../helpers/licenseHelper";
import idCardFormat from "../../../helpers/idCardFormat";

import SectionHeader from "../../../components/layout/staff/SectionHeader";
import ErrorPage from "../../../components/errorPage";

import useFetch from "../../../hooks/useFetch";
import axios from "~src/helpers/configAxios";

import { AlertNotification, StyleType } from "~src/components/alert/alertNotification";
import { IconButton } from "@mui/material";
import {
  AgentViewPdpaModal,
  fetchAcceptPdpaWithAnswers,
  pdpaAcceptedSelector,
} from "~src/features/pdpa";
import { useAppDispatch, useAppSelector } from "~src/store";

const BASE_URL = "/staffs/agent";
const agentFormId = "AgentFormId";

interface AgentProps {
  match: any;
}

const Agent: React.FC<AgentProps> = ({ match }) => {
  const agentId = match.params.id;
  const agentFetch = useFetch(`/api/agents/${agentId}`);
  const licenseFetch = useFetch(`/api/agents/${agentId}/licenses`);

  const [isError, setIsError] = useState(false);
  const [agentInfo, setAgentInfo] = useState<AgentInfo>(null);
  const [lifeLicenseInfo, setLifeLicenseInfo] = useState<LicenseInfo>(null);
  const [nonLifeLicenseInfo, setNonLifeLicenseInfo] = useState<LicenseInfo>(null);
  const isAcceptedPdpa = useAppSelector(pdpaAcceptedSelector);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [alertText, setAlertText] = useState("ดำเนินการเสร็จสิ้น");
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertType, setAlertType] = useState<StyleType>("success");

  const { accessType } = useAuthenticate();
  const isStaff = accessType === "STAFF";

  useEffect(() => {
    if (!agentFetch.isLoading && !licenseFetch.isLoading) {
      const agentData = agentFetch.response.content;
      const licenseData = licenseFetch.response.content.licenses;

      setAgentInfo(agentData);
      setLifeLicenseInfo(getLicenseByType(licenseData, "life"));
      setNonLifeLicenseInfo(getLicenseByType(licenseData, "non-life"));
      setIsError(false);
    }
    if (agentFetch.error || licenseFetch.error) {
      setIsError(true);
    }
  }, [
    agentFetch.response,
    agentFetch.error,
    agentFetch.isLoading,
    licenseFetch.response,
    licenseFetch.error,
    licenseFetch.isLoading,
  ]);

  const showAlert = (text: string): void => {
    setAlertText(text);
    setIsAlertActive(true);
  };

  const hideAlert = (): void => {
    setIsAlertActive(false);
  };

  const getRecommendLink = (code) => {
    return `${window.location.origin}/register?code=${code}`;
  };

  const handleGetRecommendLink = () => {
    setAlertType("success");
    showAlert("คัดลอกลิงค์แนะนำไปยัง Clipboard เรียบร้อย");
    navigator.clipboard.writeText(getRecommendLink(agentInfo?.recommend_code));
  };

  const handleClickEditAgentInfo = () => {
    window.location.href = `${BASE_URL}/${agentId}/edit`;
  };

  const handleApproveAgent = () => {
    const url = `/api/agents/${agentId}/approve`;
    axios
      .post(url)
      .then((result) => {
        setAlertType("success");
        showAlert("ดำเนินการเสร็จสิ้น");

        // setAgentInfo(result.data.content);
        window.location.reload();
      })
      .catch(() => {
        setAlertType("error");
        showAlert("พบความผิดพลาดในการดำเนินการ กรุณาลองใหม่ในภายหลัง");
      });
  };

  const getStatusCell = (status) => {
    switch (status) {
      case "active":
        return <div className="flex-1 px-4 text-green-800">Active</div>;
      case "inactive":
        return <div className="flex-1 px-4 text-red-800">Inactive</div>;
      case "waiting_approval":
        return <span className="flex-1 px-4 text-gray-700">Waiting Approval</span>;
      default:
        return <span className="flex-1 px-4 text-gray-400">Unknown</span>;
    }
  };

  return (
    <div>
      {isError ? (
        <ErrorPage />
      ) : (
        <>
          {!isAcceptedPdpa && <AgentViewPdpaModal canClose />}
          <div className="flex flex-col p-4">
            <SectionHeader
              className="w-full"
              title="Profile"
              breadcrumbs={[{ url: "#", label: "ข้อมูลตัวแทน", current: true }]}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3">
              <div className="mt-2">
                <div className="px-3 py-4 bg-white rounded-md shadow-lg">
                  <div className="flex flex-col">
                    <div className="w-full col-span-2 px-2 py-2 text-xl bg-custom-gray-sm">
                      ข้อมูล Hero
                    </div>
                    <div className="flex flex-row py-2 mt-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">รหัส Hero</div>
                      <div className="flex-1 px-4">{checkEmpty(agentInfo?.username)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">สถานะ</div>
                      {getStatusCell(agentInfo?.status)}
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">ชื่อ</div>
                      <div className="flex-1 px-4">{checkEmpty(agentInfo?.firstname)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">นามสกุล</div>
                      <div className="flex-1 px-4">{checkEmpty(agentInfo?.lastname)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">ประเภท</div>
                      <div className="flex-1 px-4">{checkEmpty(agentInfo?.role?.name)}</div>
                    </div>
                    {t("partner") && (
                      <div className="flex flex-row py-2 hover:bg-pink-100">
                        <div className="flex-1 px-4 text-custom-gray-md">กลุ่มตัวแทน</div>
                        <div className="flex-1 px-4">{checkEmpty(agentInfo?.partner?.name)}</div>
                      </div>
                    )}
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">รหัสบัตรประชาชาน</div>
                      <div className="flex-1 px-4">{idCardFormat(agentInfo?.citizen_id)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">วันเกิด</div>
                      <div className="flex-1 px-4">{dateFormat(agentInfo?.dob)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">ที่อยู่จัดส่ง</div>
                      <div className="flex-1 px-4">
                        {agentInfo &&
                        agentInfo?.address?.concat(
                          agentInfo?.road,
                          agentInfo?.district,
                          agentInfo?.sub_district,
                          agentInfo?.province,
                          agentInfo?.postcode
                        ) !== ""
                          ? renderLocation(agentInfo)
                          : "-"}
                      </div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">Email</div>
                      <div className="flex-1 px-4">{checkEmpty(agentInfo?.email)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">Email Group</div>
                      <div className="flex-1 px-4">{checkEmpty(agentInfo?.branch_email)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">Channel</div>
                      <div className="flex-1 px-4">{checkEmpty(agentInfo?.channel)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">โทรศัพท์</div>
                      <div className="flex-1 px-4">{checkEmpty(agentInfo?.mobile_no)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">ระดับการศึกษา</div>
                      <div className="flex-1 px-4">
                        {educationLevelOptions.find((o) => o.value == agentInfo?.education_level)
                          ? educationLevelOptions.find((o) => o.value == agentInfo?.education_level)
                              ?.label
                          : checkEmpty(agentInfo?.education_level)}
                      </div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">วันที่สมัครสมาชิก</div>
                      <div className="flex-1 px-4">{dateFormat(agentInfo?.created_at)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">วันเริ่มเป็นสมาชิก</div>
                      <div className="flex-1 px-4">{dateFormat(agentInfo?.approved_at)}</div>
                    </div>
                    {agentInfo?.current_pdpa && (
                      <>
                        <div className="flex flex-row py-2 hover:bg-pink-100">
                          <div className="flex-1 px-4 text-custom-gray-md">Consent</div>
                          <div className="flex-1 px-4">
                            Consent version {agentInfo?.current_pdpa?.version}
                            {!isStaff && (
                              <IconButton
                                size="small"
                                onClick={() => dispatch(fetchAcceptPdpaWithAnswers())}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-row py-2 hover:bg-pink-100">
                          <div className="flex-1 px-4 text-custom-gray-md">
                            วันที่ยอมรับ Consent
                          </div>
                          <div className="flex-1 px-4">
                            {dateFormat(agentInfo?.current_pdpa.updated_at)}
                          </div>
                        </div>
                      </>
                    )}

                    {agentInfo?.current_contract && (
                      <>
                        <div className="flex flex-row py-2 hover:bg-pink-100">
                          <div className="flex-1 px-4 text-custom-gray-md">วันที่ยอมรับสัญญา</div>
                          <div className="flex-1 px-4">
                            version {agentInfo?.current_contract?.version}
                          </div>
                        </div>
                        <div className="flex flex-row py-2 hover:bg-pink-100">
                          <div className="flex-1 px-4 text-custom-gray-md">วันที่ยอมรับสัญญา</div>
                          <div className="flex-1 px-4">
                            {dateFormat(agentInfo?.current_contract.updated_at)}
                          </div>
                        </div>
                      </>
                    )}

                    {agentInfo?.recommend_code && (
                      <div className="flex flex-row py-2 hover:bg-pink-100">
                        <div className="flex-1 px-4 text-custom-gray-md">Link แนะนำตัวแทน</div>
                        <div
                          className="flex-1 px-4 truncate flex flex-row cursor-pointer"
                          onClick={handleGetRecommendLink}
                        >
                          <div className="truncate">
                            {getRecommendLink(agentInfo?.recommend_code)}
                          </div>
                          {agentInfo?.recommend_code && <FileCopy className="ml-1" />}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-2 h-full">
                <div className="px-3 py-4 bg-white rounded-md shadow-lg">
                  <div className="flex flex-col">
                    <div className="w-full px-2 py-2 text-xl bg-custom-gray-sm">ข้อมูลใบอนุญาต</div>
                    <div className="flex flex-row py-2 mt-2 bg-gray-100">
                      <div className="flex-1 px-4 text-custom-gray-md">License Non-life</div>
                      <div className="flex-1 px-4">ใบอนุญาตประกันวินาศภัย</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">
                        เลขที่ใบอนุญาตประกันวินาศภัย
                      </div>
                      <div className="flex-1 px-4">
                        {checkEmpty(nonLifeLicenseInfo?.license_no)}
                      </div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">
                        วันที่ออกใบอนุญาตประกันวินาศภัย
                      </div>
                      <div className="flex-1 px-4">
                        {dateFormat(nonLifeLicenseInfo?.issued_date)}
                      </div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">
                        วันหมดอายุใบอนุญาตประกันวินาศภัย
                      </div>
                      <div className="flex-1 px-4">
                        {dateFormat(nonLifeLicenseInfo?.expired_date)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 px-3 py-4 bg-white rounded-md shadow-lg">
                  <div className="flex flex-col">
                    <div className="w-full px-2 py-2 text-xl bg-custom-gray-sm">
                      ข้อมูลบัญชีธนาคาร
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">เลขที่บัญชีธนาคาร</div>
                      <div className="flex-1 px-4">{checkEmpty(agentInfo?.bank_account_id)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">ชื่อบัญชีธนาคาร</div>
                      <div className="flex-1 px-4">{checkEmpty(agentInfo?.bank_account)}</div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">ธนาคาร</div>
                      <div className="flex-1 px-4">
                        {checkEmpty(agentInfo?.bank_account_provider)}
                      </div>
                    </div>
                    <div className="flex flex-row py-2 hover:bg-pink-100">
                      <div className="flex-1 px-4 text-custom-gray-md">สาขาธนาคาร</div>
                      <div className="flex-1 px-4">
                        {checkEmpty(agentInfo?.bank_account_branch)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isStaff && (
              <div className="flex w-full mt-4 gap-2 justify-center">
                <button
                  onClick={handleClickEditAgentInfo}
                  className={`h-full px-3 py-2 text-base transition duration-200 text-white bg-primary-color rounded-lg hover:bg-primary-color-dark`}
                >
                  <Edit className="mr-1" />
                  แก้ไขข้อมูล Hero
                </button>
                {agentInfo?.status === "waiting_approval" && (
                  <button
                    className={`h-full px-3 py-2 text-base transition duration-200 text-white bg-primary-color rounded-lg hover:bg-primary-color-dark`}
                    type="button"
                    onClick={handleApproveAgent}
                  >
                    <Check className="mr-1" />
                    ยืนยันสมาชิก
                  </button>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Agent;
