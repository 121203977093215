import React, { useEffect, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import _get from "lodash/get";
import { messages } from "~/src/helpers/messages";
import { Option } from "~/src/types/basic";
import { FormGroup, SelectField, TextField } from "~/src/components/form";
import { getYears } from "~/src/pages/staff/Renewal/hooks/useVehicles";

interface Props extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  name: string;
  carBrand: string | undefined;
  carModel: string | undefined;
  type?: "text" | "select";
  className?: string;
  setLoading?: (status: boolean) => void;
  callbackOnChange?: (e: React.ChangeEvent) => void;
}

const CarYearInput: React.FC<Props> = ({
  name,
  carBrand,
  carModel,
  className,
  type = "select",
  errors,
  register,
  setLoading,
  callbackOnChange,
}): JSX.Element => {
  const [optionCarYears, setOptionCarYears] = useState<Option<number>[]>([]);
  const errorMsg = _get(errors, name)?.message;

  const convertDataToOption = (carYears: number[]): Option<string>[] => {
    return carYears.map((carYear) => {
      return {
        label: carYear,
        value: carYear,
      };
    });
  };

  const fetchCarYears = async (): Promise<void> => {
    try {
      setLoading?.(true);
      const response = await getYears(carBrand, carModel);
      const resultsOption = convertDataToOption(response.data.years);
      setOptionCarYears(resultsOption);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading?.(false);
    }
  };

  useEffect(() => {
    if (carBrand && carModel && type === "select") fetchCarYears();
  }, [carBrand, carModel]);

  return (
    <FormGroup label="ปีรถยนต์ (คศ.)" className={className}>
      {type === "select" ? (
        <SelectField
          name={name}
          style={{ minHeight: "38px" }}
          inputRef={register(messages.required)}
          error={errorMsg}
          onChange={callbackOnChange}
          options={optionCarYears}
        />
      ) : (
        <TextField name={name} inputRef={register(messages.required)} error={errorMsg} />
      )}
    </FormGroup>
  );
};

export default CarYearInput;