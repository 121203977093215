import React, { ChangeEvent } from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
  onSubmit: () => void;
}

interface Input {
  type?: string;
  required?: true | false;
  label: string;
  name: string;
  inputRef: any;
  value?: string | number;
  onChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
}

interface SelectInput extends Input {
  options: Option[];
}

interface Option {
  label: string;
  value: string | number;
}

interface TimeDropdownProps {
  label: string;
  required: boolean;
  onChangeHour: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeMinute: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: [string, string];
}

const range = (start: number, end: number): number[] => {
  const length = end - start;
  return Array.from({ length }, (_, i) => start + i);
};

const Form: React.FC<Props> = ({ children, onSubmit }) => {
  return (
    <form className="relative" onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export const TextField: React.FC<Input> = ({
  type = "text",
  label,
  required = false,
  name,
  inputRef,
  value,
  onChange,
}) => {
  return (
    <div className="flex flex-wrap mb-3">
      <label htmlFor="" className="w-full mb-1">
        {label}
        {required && <span className="text-red-400">*</span>}
      </label>
      <input
        type={type}
        className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
        ref={inputRef}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export const SelectField: React.FC<SelectInput> = ({
  options,
  name,
  value,
  onChange,
  inputRef,
  required = false,
  label,
}) => {
  return (
    <div className="flex flex-wrap mb-3">
      <label htmlFor="" className="w-full mb-1">
        {label}
        {required && <span className="text-red-400">*</span>}
      </label>
      <select
        name={name}
        className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
        ref={inputRef}
        value={value}
        onChange={onChange}
      >
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const TextareaField: React.FC<Input> = ({
  label,
  required = false,
  name,
  inputRef,
  onChange,
  value,
}) => {
  return (
    <div className="flex flex-wrap mb-3">
      <label htmlFor="" className="w-full mb-1">
        {label}
        {required && <span className="text-red-400">*</span>}
      </label>
      <textarea
        name={name}
        ref={inputRef}
        className="w-full px-2 py-2 border border-gray-400 rounded"
        onChange={onChange}
      >
        {value}
      </textarea>
    </div>
  );
};

export const TimeDropdown: React.FC<TimeDropdownProps> = ({
  label,
  required = false,
  value,
  onChangeHour,
  onChangeMinute,
}) => {
  return (
    <div>
      <div className="flex flex-wrap mb-3">
        <label htmlFor="" className="w-full mb-1">
          {label}
          {required && <span className="text-red-400">*</span>}
        </label>
        <div className="w-full flex">
          <select
            name="hour"
            className="px-2 py-2 border border-gray-400 rounded"
            onChange={onChangeHour}
            value={value[0]}
          >
            {range(0, 24).map((n, i) => (
              <option key={i} value={n}>
                {("0" + n).slice(-2)}
              </option>
            ))}
          </select>
          <strong className="p-2 text-center">:</strong>
          <select
            name="minute"
            className="px-2 py-2 border border-gray-400 rounded"
            onChange={onChangeMinute}
            value={value[1]}
          >
            {range(0, 60).map((n, i) => (
              <option key={i} value={n}>
                {("0" + n).slice(-2)}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Form;
