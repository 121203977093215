import React from "react";
import axios from "~src/helpers/configAxios";

export interface SecureLinkProps {
  src: string;
}

const SecureLink = ({ src, children, ...rest }) => {
  const downloadFile = (e) => {
    e.preventDefault();

    axios
      .get(src, {
        responseType: "arraybuffer",
      })
      .then((result) => {
        const filenameRegex = /filename\*?=([^']*'')?([^;]*)/;
        const filename = decodeURI(filenameRegex.exec(result.headers["content-disposition"])[2]);
        const uriString =
          "data:" +
          result.headers["content-type"] +
          ";base64," +
          encodeURI(Buffer.from(result.data, "binary").toString("base64"));
        let a = document.createElement("a");
        a.href = uriString;
        a.download = decodeURI(filename);
        a.click();
      })
      .catch((error) => {
        alert("File not found");
      });
  };

  return (
    <button onClick={downloadFile} {...rest}>
      {children}
    </button>
  );
};

export default SecureLink;
