import React from "react";
import {
  AddressInfo,
  AgentInfo,
  AgentInfoPayload,
  BankInfo,
  Contract,
  HasMotorLicense,
  MTLAgentInfo,
  Pdpa,
  RecommenderInfo,
  Stepper,
  UploadInfo,
  agentInfoSelector,
  buildAgentInfoFormData,
  errorMessgaeSelector,
  handleMockAgentData,
  responseStatusSelector,
  stepSelector,
} from "~src/features/register";

import { FormProvider, useForm } from "react-hook-form";
import AgentInfoState from "~src/models/AgentInfo";
import { useAppDispatch, useAppSelector } from "~src/store";
import Preloading from "~src/components/loading/Preloading";

const Register = () => {
  const step = useAppSelector(stepSelector);
  const responseStatus = useAppSelector(responseStatusSelector);
  const errorMessage = useAppSelector(errorMessgaeSelector);
  const dispatch = useAppDispatch();

  const methods = useForm<AgentInfoState>({
    reValidateMode: "onSubmit",
  });

  const onSubmit = (data: AgentInfoPayload) => {
    dispatch(buildAgentInfoFormData(data));
  };

  const cardClassNames = "col-span-2 px-3 py-4 mt-3 bg-white rounded-md shadow-lg md:col-span-1";

  return (
    <div className="container max-w-none px-16">
      <Stepper active={step} />

      {responseStatus === "loading" && <Preloading />}

      {errorMessage !== "" && (
        <div className="flex flex-wrap -mx-1">
          <div className="w-full px-2">
            <div className="w-full p-16 bg-red-300 border border-red-500 rounded-10 mb-16">
              {errorMessage}
            </div>
          </div>
        </div>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          {step === 0 && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="w-full">
                <div className={cardClassNames}>
                  <AgentInfo />
                </div>
                <div className={cardClassNames}>
                  <AddressInfo />
                </div>
              </div>

              <div className="w-full">
                <div className={cardClassNames}>
                  <BankInfo />
                </div>

                <div className={cardClassNames}>
                  <RecommenderInfo />
                </div>

                <div className={cardClassNames}>
                  <HasMotorLicense />
                </div>

                <div className={cardClassNames}>
                  <MTLAgentInfo />
                </div>

                <div className={cardClassNames}>
                  <UploadInfo />
                </div>
              </div>

              <div className="xl:col-start-2 w-full px-2 mb-12 mt-30">
                <div className="flex justify-end">
                  <button
                    className={`px-3 py-2 w-full md:max-w-xs text-base transition duration-200 text-white bg-primary-color rounded-lg hover:bg-primary-color-dark`}
                    type="submit"
                    value="Submit"
                  >
                    ถัดไป
                  </button>
                </div>
              </div>
            </div>
          )}

          {step === 1 && <Contract />}
          {step === 2 && <Pdpa />}
        </form>
      </FormProvider>
    </div>
  );
};

export default Register;
