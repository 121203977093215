import React, { useState } from "react";
import CoverageCard from "/src/components/coverage/CoverageCard";
import useInsurerIcons from "/src/helpers/useInsurerIcons";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import yaml from "/src/data";

import "./coverageModal.scss";

const CoverageModal = (props) => {
  const [closeAnimation, setCloseAnimation] = useState("fadeInDownBig");
  const { coverages, productInfo, details, handleClose } = props;

  return (
    <div className="coverageModal">
      <div className="coverageModal-overlay" onClick={handleClose}></div>
      <div className={`coverageModal-box animated delay-01s ${closeAnimation}`}>
        <button type="button" className="coverageModal-box-close">
          <i role="button" className="material-icons" onClick={handleClose}>
            close
          </i>
        </button>
        <div className="header">
          <div className="d-grid">
            <div className="img">
              <img src={productInfo.insurer_icon} />
            </div>
            <div className="desc">
              <div className="title">
                {yaml.insurers[productInfo.insurer_code]}
              </div>
              <div className="subTitle">{`${
                yaml.policy_type[productInfo.policy_type]
              } ${
                yaml.repair_facility[productInfo.details.repair_facility]
              }`}</div>
              <div className="motorInfo">{details}</div>
            </div>
            <div className="price">
              {formatNumberWithCurrency(productInfo.standard_price)}
            </div>
          </div>
        </div>
        <div className="coverageModal-box-detailBox">
          <CoverageCard coverages={coverages} classname={"coverage-details"} />
        </div>
      </div>
    </div>
  );
};

const CoverageModalCmi = (props) => {
  const [closeAnimation, setCloseAnimation] = useState("fadeInDownBig");
  const { coverages, productInfo, details, handleClose } = props;
  return (
    <div className="coverageModal">
      <div className="coverageModal-overlay" onClick={handleClose}></div>
      <div className={`coverageModal-box animated delay-01s ${closeAnimation}`}>
        <button type="button" className="coverageModal-box-close">
          <i role="button" className="material-icons" onClick={handleClose}>
            close
          </i>
        </button>
        <div className="header">
          <div className="d-grid">
            <div className="img">
              <img src={productInfo.insurer_icon} />
            </div>
            <div className="desc">
              <div className="title">
                {yaml.insurers[productInfo.insurer_code]}
              </div>
              <div className="subTitle">{`พ.ร.บ.`}</div>
              <div className="motorInfo">{details}</div>
            </div>
            <div className="price">
              {formatNumberWithCurrency(productInfo.standard_price)}
            </div>
          </div>
        </div>
        <div className="coverageModal-box-detailBox">
          <div className="compulsory-page-detail my-4">
            <div className="coverageModal-cmi-detail">
              <h4>1) จำนวนเงินค่าเสียหายเบื้องต้น โดยไม่ต้องรอพิสูจน์</h4>
              <ul>
                <li>
                  <span>1.1 ค่ารักษาพยาบาล จากการบาดเจ็บ (ตามจริง)</span>
                  <span className="text-right">30,000 ฿ / คน</span>
                </li>
                <li>
                  <span>
                    1.2 การเสียชีวิต สูญเสียอวัยวะ หรือทุพพลภาพอย่างถาวร
                  </span>
                  <span className="text-right">35,000 ฿ / คน</span>
                </li>
                <li>
                  <span></span>
                  <span className="text-right">
                    * (1.1) และ (1.2) รวมกันไม่เกิน 65,000 ฿ / คน
                  </span>
                </li>
              </ul>
              <h4>
                2) ค่าเสียหายส่วนที่เกินกว่าค่าเสียหายเบื้องต้น
                สำหรับผู้ประสบภัย
                <small className="block">
                  (จะได้รับภายหลังจากการพิสูจน์แล้วว่าไม่ได้เป็นฝ่ายละเมิด)
                </small>
              </h4>
              <ul>
                <li>
                  <span>2.1 ค่ารักษาพยาบาล จากการบาดเจ็บ</span>
                  <span className="text-right">ไม่เกิน 80,000 ฿ / คน</span>
                </li>
                <li>
                  <span>2.2 การเสียชีวิต หรือทุพพลภาพอย่างถาวร</span>
                  <span className="text-right">500,000 ฿ / คน</span>
                </li>
                <li>
                  <span>2.3 สูญเสียอวัยวะ</span>
                  <span className="text-right">200,000 - 500,000 ฿ / คน</span>
                </li>
                <li>
                  <span>
                    2.4 ชดเชยรายวัน 200 บาท รวมกันไม่เกิน 20 วัน
                    กรณีเข้าพักรักษาพยาบาลในสถานพยาบาลในฐานะคนไข้ใน
                  </span>
                  <span className="text-right">สูงสุดไม่เกิน 4,000 ฿ / คน</span>
                </li>
                <li>
                  <span></span>
                  <span className="text-right">
                    * ข้อ (1) และ (2) รวมกันไม่เกิน 504,000 ฿ / คน
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverageModal;
export { CoverageModalCmi };
