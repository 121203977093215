import React from "react";
import { useAppDispatch, useAppSelector } from "~src/store";
import { closeModal, selectModalOpen, selectModalType } from "../bannerSlice";
import { Dialog, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Form from "./Form";

const CreateModal = () => {
  const open = useAppSelector(selectModalOpen);
  const type = useAppSelector(selectModalType);

  const dispach = useAppDispatch();

  const handleClose = () => {
    dispach(closeModal());
  };

  return (
    <>
      {type === "create" && (
        <Dialog open={open} fullWidth maxWidth="sm">
          <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
            Create new banner
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Form />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CreateModal;
