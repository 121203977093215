import React, { useEffect, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import _get from "lodash/get";
import { getCarBrands } from "~/src/pages/staff/Renewal/hooks/useVehicles";
import { FormGroup, SelectGroupField, TextField } from "~/src/components/form";
import { messages } from "~/src/helpers/messages";
import { Option } from "~/src/types/basic";
import { popularCarBrands } from "~/src/helpers/options";

interface Props extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  name: string;
  type?: "text" | "select";
  className?: string;
  setLoading?: (status: boolean) => void;
  callbackOnChange?: (e: React.ChangeEvent) => void;
}

const CarBrandInput: React.FC<Props> = ({
  name,
  className,
  type = "select",
  errors,
  register,
  setLoading,
  callbackOnChange,
}): JSX.Element => {
  const [optionCarBrands, setOptionCarBrands] = useState<Option<string>[]>([]);
  const errorMsg = _get(errors, name)?.message;

  const convertDataToOption = (carBrands: string[]): Option<string>[] => {
    return carBrands.map((carBrand) => {
      return {
        label: carBrand,
        value: carBrand,
      };
    });
  };

  const fetchCarBrands = async (): Promise<void> => {
    try {
      setLoading?.(true);
      const response = await getCarBrands();
      const resultsOption = convertDataToOption(response.data.brands);
      setOptionCarBrands(resultsOption);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading?.(false);
    }
  };

  useEffect(() => {
    if (type === "select") fetchCarBrands();
  }, []);

  return (
    <FormGroup label="ยี่ห้อรถยนต์" className={className}>
      {type === "select" ? (
        <SelectGroupField
          style={{ minHeight: "38px" }}
          name={name}
          inputRef={register(messages.required)}
          error={errorMsg}
          onChange={callbackOnChange}
          optgroups={[
            { label: "ยี่ห้อรถยนต์ยอดนิยม", options: popularCarBrands },
            { label: "ยี่ห้อรถยนต์ทั้งหมด", options: optionCarBrands },
          ]}
        />
      ) : (
        <TextField name={name} inputRef={register(messages.required)} error={errorMsg} />
      )}
    </FormGroup>
  );
};

export default CarBrandInput;