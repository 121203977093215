import React from "react";
import Cookies from "js-cookie";
import {
  isDesktop,
  isMobileOnly,
  isTablet,
  MobileView,
  withOrientationChange,
} from "react-device-detect";
import { Redirect } from "react-router-dom";

import Header from "../header";
import Sidebar from "../sidebar";
import MobileContentWall from "../../../pages/error/MobileContentWall";
import { useAuthContext } from "../../../context/Auth";
import { isJsonString } from "~src/helpers/helpers";

export interface AgentProps {
  // isAuthenticated: () => boolean;
  logo: any;
  sidebar: boolean;
  header: boolean;
  isPortrait?: boolean;
}

const Agent = ({
  // isAuthenticated,
  logo,
  sidebar,
  header,
  isPortrait,
  children,
}: React.PropsWithChildren<AgentProps>) => {
  const { session, dispatch: dispatchAuth, validateAuth } = useAuthContext();

  React.useEffect(() => {
    dispatchAuth({
      type: "MODIFY",
      payload: {
        ...session,
        username: Cookies.get("username") || null,
        role: isJsonString(Cookies.get("role")) ? JSON.parse(Cookies.get("role")) : null,
        title: Cookies.get("title") || null,
        firstname: Cookies.get("firstname") || null,
        lastname: Cookies.get("lastname") || null,
        permissions: isJsonString(Cookies.get("permissions")) ? JSON.parse(Cookies.get("permissions")) : null,
      },
    });
  }, []);

  React.useEffect(() => {
    if (isDesktop) localStorage.removeItem("showOnMobile");
  }, [isDesktop]);

  return (
    <div>
      {localStorage.getItem("showOnMobile") === null &&
      (isMobileOnly || (isTablet && isPortrait)) ? (
        <React.Fragment>
          {header && <Header logo={logo} />}
          <div className="overflow-x-scroll">
            <div style={{ width: 1024 }}>
              <section className={header && "page-wrapper"}>
                {sidebar && <Sidebar />}
                {/* showsidebar={true} */}
                <div className={sidebar ? "content-wrapper" : ""}>
                  {validateAuth() ? children : <Redirect to="/login" />}
                </div>
              </section>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <>
          {header && <Header logo={logo} />}
          <section className={header && "page-wrapper"}>
            {sidebar && <Sidebar />}
            {/* showsidebar={true} */}
            <div className={sidebar ? "content-wrapper" : ""}>
              {validateAuth() ? children : <Redirect to="/login" />}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default withOrientationChange<React.PropsWithChildren<AgentProps>>(Agent);
