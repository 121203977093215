import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";

export interface UseLoadingOptions {
  loadOnInit?: boolean;
  isReady?: boolean;
  onError?: (err: Error) => void;
}

export interface UseLoadingState<T> {
  isLoading: boolean;
  data?: T;
  error?: Error;
  abort: (reason: any) => void;
  reload: () => void;
}

export default function useLoading<T>(
  asyncSupplier: (abc?: AbortController) => Promise<T>,
  opts: UseLoadingOptions = {}
): UseLoadingState<T> {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [abortFn, setAbortFn] = useState<(reason: any) => void | undefined>();

  useEffect(() => {
    if (!(opts.isReady ?? true)) {
      return;
    }
    if (!opts.loadOnInit) {
      return;
    }

    reload();
  }, [opts.loadOnInit, opts.isReady]);

  useEffect(() => {
    if (!loading) {
      return;
    }

    //cleanup
    return () => {
      abortFn && abortFn("useLoading: cleanup");
    };
  }, [loading, abortFn]);

  function reload() {
    (async function () {
      setLoading(true);
      try {
        const abc = new AbortController();
        setAbortFn((reason) => abc.abort(reason));

        const _data = await asyncSupplier(abc);
        unstable_batchedUpdates(() => {
          setLoading(false);
          setData(_data);
          setError(undefined);
          setAbortFn(undefined);
        });
      } catch (err) {
        unstable_batchedUpdates(() => {
          setLoading(false);
          setError(err);
          setAbortFn(undefined);
        });
        opts.onError && opts.onError(err);
      }
    })();
  }

  return { isLoading: loading, data, error, abort: abortFn, reload };
}
