import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import {
  FormGroup,
  TextField,
  SelectField,
  DatePickerField,
  CheckboxField,
} from "/src/components/staff/form";

import { addYears } from "date-fns";
import { dateFormat, parseDate } from "/src/helpers/dateTime";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import { messages } from "/src/helpers/messages";

import useFetch from "/src/hooks/useFetch";
import useInput from "/src/hooks/useInput";
import useFile from "/src/hooks/useFile";

const InlineForm = ({ label, require, htmlForLabel, children }) => {
  return (
    <div className="row justify-content-between align-items-center space-bottom">
      <div className="col-md-8">
        <label className="policy-form-label" htmlFor={htmlForLabel}>
          {label} {require ? <span>*</span> : null}
        </label>
      </div>
      <div className="col-md-4">{children}</div>
    </div>
  );
};

const PolicyForm = (props) => {
  const { data } = props;
  const [coverStartDate] = useState(dateFormat(new Date()));
  const [coverEndDate, setCoverEndDate] = useState(dateFormat(addYears(new Date(), 1)));
  const [addonCoverStartDate] = useState(dateFormat(new Date()));
  const [addonCoverEndDate, setAddonCoverEndDate] = useState(dateFormat(addYears(new Date(), 1)));
  const [vmiWarningDate] = useState(dateFormat(new Date()));
  const [cmiWarningDate] = useState(dateFormat(new Date()));
  const [addonCover, setAddonCover] = useState(true);
  const [insurers, setInsurers] = useState([]);

  const { response, errors, isLoaded } = useFetch(`/api/insurers`);

  useMemo(() => {
    if (!isLoaded) {
      const options = [];
      response?.content.map((o, i) => {
        options[i] = { label: o.code, value: o.code };
      });
      options.push({ label: "LMG", value: "LMG" });
      setInsurers(options);
    }
  }, [response, errors, isLoaded]);

  const { bind: changeInsurerName } = useInput(data?.insurer_code.toUpperCase() || "");
  const { bind: changePolicyType } = useInput(data.policy_details?.[0]?.product_type || "");
  const { bind: changeSumInsured } = useInput(data.policy_details?.[0]?.sum_insured || "");
  const { value: cmi_price, bind: changeCmiPrice } = useInput("");
  const { value: selling_price, bind: changeSellingPrice } = useInput(
    // data.policy_details?.[0]?.selling_price || ""
    ""
  );
  const { value: vat_premium, bind: changeVatPremium } = useInput("");
  const { value: revenue_stamp, bind: changeRevenueStamp } = useInput("");
  const { bind: changeRepairFacility } = useInput("");

  const { value: vmi_file, bind: changeVmiFile } = useFile("");
  const { value: cmi_file, bind: changeCmiFile } = useFile("");

  const handleChangeCmi = (e) => {
    setAddonCover(!addonCover);
  };

  const callbackValueStartDate = (value) => {
    const cover_end_date = dateFormat(addYears(parseDate(value), 1));
    setCoverEndDate(cover_end_date);
  };

  const callbackValueAddonStartDate = (value) => {
    const addon_cover_end_date = dateFormat(addYears(parseDate(value), 1));
    setAddonCoverEndDate(addon_cover_end_date);
  };

  const totalAmount = useMemo(() => {
    const cmiType = parseFloat(cmi_price) || 0;
    const sellingPrice = parseFloat(selling_price) || 0;
    const vatPremium = parseFloat(vat_premium) || 0;
    const revenueStamp = parseFloat(revenue_stamp) || 0;

    let cmiPrice = 0;
    if (addonCover) {
      if (cmiType == 110) cmiPrice = 645.21;
      else if (cmiType == 320) cmiPrice = 967.28;
    }

    return sellingPrice + vatPremium + revenueStamp + cmiPrice;
  }, [selling_price, vat_premium, revenue_stamp, cmi_price, addonCover]);

  const totalSellingPrice = useMemo(() => {
    const sellingPrice = parseFloat(selling_price) || 0;
    const vatPremium = parseFloat(vat_premium) || 0;
    const revenueStamp = parseFloat(revenue_stamp) || 0;

    return sellingPrice + vatPremium + revenueStamp;
  }, [selling_price, vat_premium, revenue_stamp, cmi_price, addonCover]);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <FormGroup label="บริษัทประกัน" htmlForLabel="renew[product][insurer_code]" require>
            <SelectField
              id="renew[product][insurer_code]"
              name="renew[product][insurer_code]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.product?.insurer_code?.message}
              options={insurers}
              {...changeInsurerName}
            />
          </FormGroup>
          <input type="hidden" name="renew[product_type]" ref={props.register()} value="motor" />
          <input type="hidden" name="renew[product][name]" ref={props.register()} value="-" />
        </div>
        <div className="col-md-4">
          <FormGroup label="ประเภทความคุ้มครอง" htmlForLabel="renew[product][policy_type]" require>
            <SelectField
              id="renew[product][policy_type]"
              name="renew[product][policy_type]"
              options={[
                { label: "ประกันชั้น 1", value: "1" },
                { label: "ประกันชั้น 2+", value: "2+" },
                { label: "ประกันชั้น 3+", value: "3+" },
                { label: "ประกันชั้น 3", value: "3" },
              ]}
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.product?.policy_type?.message}
              {...changePolicyType}
            />
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup label="พ.ร.บ." htmlForLabel="cmi">
            <CheckboxField
              name="renew[cmi]"
              id="renew[cmi]"
              label=""
              value="checked"
              color="primary"
              onChange={handleChangeCmi}
              inputRef={props.register()}
              defaultChecked
            />
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup
            label="ประเภทการซ่อม"
            htmlForLabel="renew[product][details][repair_facility]"
            require
          >
            <SelectField
              id="renew[product][details][repair_facility]"
              name="renew[product][details][repair_facility]"
              options={[
                { label: "อู่", value: "garage" },
                { label: "ศูนย์", value: "dealer" },
              ]}
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.product?.details?.repair_facility?.message}
              {...changeRepairFacility}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <FormGroup
            label="ระยะเวลาประกันภัยใหม่"
            htmlForLabel="renew[order_details][cover_start_date]"
            require
          >
            <div className="d-flex align-items-center row-inline">
              <div className="col-6">
                <DatePickerField
                  id="renew[order_details][cover_start_date]"
                  name="renew[order_details][cover_start_date]"
                  inputRef={props.register()}
                  value={coverStartDate}
                  minDate={parseDate(dateFormat(new Date()))}
                  callbackvalue={callbackValueStartDate}
                  error={props.errors.renew?.order_details?.cover_start_date?.message}
                  readOnly
                />
              </div>
              <div className="space-input">ถึง</div>
              <div className="col-6">
                <DatePickerField
                  id="renew[order_details][cover_end_date]"
                  name="renew[order_details][cover_end_date]"
                  inputRef={props.register()}
                  value={coverEndDate}
                  error={props.errors.renew?.order_details?.cover_end_date?.message}
                  disableCarlendar
                  readOnly
                />
              </div>
            </div>
          </FormGroup>
        </div>
        {addonCover ? (
          <div className="col-md-8">
            <FormGroup
              label="ระยะเวลาคุ้มครองพ.ร.บ."
              htmlForLabel="renew[order_details][addon_start_date]"
              require
            >
              <div className="d-flex align-items-center row-inline">
                <div className="col-6">
                  <DatePickerField
                    id="renew[order_details][addon_start_date]"
                    name="renew[order_details][addon_start_date]"
                    value={addonCoverStartDate}
                    minDate={parseDate(dateFormat(new Date()))}
                    callbackvalue={callbackValueAddonStartDate}
                    inputRef={props.register()}
                    error={props.errors.renew?.order_details?.addon_start_date?.message}
                    readOnly
                  />
                </div>
                <div className="space-input">ถึง</div>
                <div className="col-6">
                  <DatePickerField
                    id="renew[order_details][addon_end_date]"
                    name="renew[order_details][addon_end_date]"
                    value={addonCoverEndDate}
                    inputRef={props.register()}
                    error={props.errors.renew?.order_details?.addon_end_date?.message}
                    disableCarlendar
                    readOnly
                  />
                </div>
              </div>
            </FormGroup>
          </div>
        ) : null}
      </div>
      <div className="row">
        <div className="col-12">
          <h4>รายละเอียดค่าเบี้ยประกันภัยใหม่</h4>
        </div>
        <div className="col-12">
          <InlineForm label="ทุนประกัน" htmlForLabel="renew[product][sum_insured]" require>
            <TextField
              id="renew[product][sum_insured]"
              name="renew[product][sum_insured]"
              currency
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.product?.sum_insured?.message}
              {...changeSumInsured}
            />
          </InlineForm>
          {addonCover ? (
            <InlineForm label="ค่าเบี้ยพ.ร.บ." htmlForLabel="product[selling_price]" require>
              <SelectField
                id="renew[product][details][remark]"
                name="renew[product][details][remark]"
                inputRef={props.register(messages.required)}
                error={props.errors.renew?.product?.details?.remark?.message}
                options={[
                  { label: "฿ 645.21", value: "110" },
                  { label: "฿ 967.28", value: "320" },
                ]}
                {...changeCmiPrice}
                alignRight
              />
            </InlineForm>
          ) : null}
          <InlineForm
            label="ค่าเบี้ยประกันภัยสุทธิ"
            htmlForLabel="renew[product][insurer_gross_amount]"
            require
          >
            <TextField
              id="renew[product][insurer_gross_amount]"
              name="renew[product][insurer_gross_amount]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.product?.insurer_gross_amount?.message}
              currency
              {...changeSellingPrice}
            />
            <input
              type="hidden"
              name="renew[product][standard_price]"
              ref={props.register()}
              value={totalAmount}
            />
          </InlineForm>
          <InlineForm
            label="อากรแสตมป์"
            htmlForLabel="renew[product][insurer_stamp_amount]"
            require
          >
            <TextField
              id="renew[product][insurer_stamp_amount]"
              name="renew[product][insurer_stamp_amount]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.product?.insurer_stamp_amount?.message}
              currency
              {...changeRevenueStamp}
            />
          </InlineForm>
          <InlineForm
            label="ภาษีมูลค่าเพิ่ม"
            htmlForLabel="renew[product][insurer_vat_amount]"
            require
          >
            <TextField
              id="renew[product][insurer_vat_amount]"
              name="renew[product][insurer_vat_amount]"
              inputRef={props.register(messages.required)}
              error={props.errors.renew?.product?.insurer_vat_amount?.message}
              {...changeVatPremium}
              currency
            />
          </InlineForm>
          <hr className="end-section" />
          <InlineForm label="เบี้ยประกันที่ต้องชำระทั้งสิ้น">
            <div className="text-right">
              {formatNumberWithCurrency(totalAmount)}
              <input
                type="hidden"
                name="renew[product][selling_price]"
                ref={props.register()}
                value={totalSellingPrice}
              />
            </div>
          </InlineForm>
        </div>
      </div>
      <div className="mt-2 row">
        <div className="col-12">
          <h4>รายละเอียดค่าเบี้ยประกันภัยใหม่</h4>
        </div>
        {/* <div className="col-md-4">
          <FormGroup
            label="เลขที่ใบแจ้งเตือนกรมธรรม์"
            htmlForLabel="renew[vmi_warning_number]"
            require
          >
            <TextField
              type="text"
              id="renew[vmi_warning_number]"
              name="renew[vmi_warning_number]"
              inputRef={props.register()}
            />
          </FormGroup>
        </div> */}
        <div className="col-md-6">
          <FormGroup label="ใบแจ้งเตือนกรมธรรม์" htmlForLabel="renew[vmi_renewal_file]" require>
            <TextField
              type="file"
              id="renew[vmi_renewal_file]"
              name="renew[vmi_renewal_file]"
              inputRef={props.register(messages.required)}
              {...changeVmiFile}
              error={props.errors.renew?.vmi_renewal_file?.message}
            />
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup label="วันที่ออกใบเตือน" htmlForLabel="renew[vmi_warning_date]" require>
            <DatePickerField
              id="renew[vmi_warning_date]"
              name="renew[vmi_warning_date]"
              value={vmiWarningDate}
              readOnly
              inputRef={props.register()}
            />
          </FormGroup>
        </div>
        {addonCover ? (
          <>
            <div className="col-12">
              <h4>ใบแจ้งเตือนพ.ร.บ. (ถ้ามี)</h4>
            </div>
            {/* <div className="col-md-4">
              <FormGroup
                label="เลขที่ใบแจ้งเตือนพ.ร.บ."
                htmlForLabel="renew[cmi_warning_number]"
                require
              >
                <TextField
                  type="text"
                  id="renew[cmi_warning_number]"
                  name="renew[cmi_warning_number]"
                  inputRef={props.register()}
                />
              </FormGroup>
            </div> */}
            <div className="col-md-6">
              <FormGroup label="ใบแจ้งเตือนพ.ร.บ." htmlForLabel="renew[cmi_renewal_file]">
                <TextField
                  type="file"
                  id="renew[cmi_renewal_file]"
                  name="renew[cmi_renewal_file]"
                  inputRef={props.register()}
                  {...changeCmiFile}
                  error={props.errors.renew?.cmi_renewal_file?.message}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup label="วันที่ออกใบเตือน" htmlForLabel="renew[cmi_warning_date]">
                <DatePickerField
                  id="renew[cmi_warning_date]"
                  name="renew[cmi_warning_date]"
                  value={cmiWarningDate}
                  readOnly
                  // inputRef={props.register()}
                />
              </FormGroup>
            </div>
          </>
        ) : null}

        {/* <input type="hidden" name="product[details][remark]" value={cmi_price} ref={props.register()} /> */}
      </div>
    </>
  );
};

PolicyForm.propTypes = {};

export default PolicyForm;
