import React from "react";
import TravelForm from "~src/features/travel/TravelForm";

import { useTranslation } from "react-i18next";

import "./main.scss";

const TravelFormPage = () => {
  const { t } = useTranslation();

  return (
    <div className="travel-main-page" style={{ backgroundImage: `url(${t("backgroundHome")})` }}>
      <TravelForm />
    </div>
  );
};

export default TravelFormPage;
