import React from "react";
import useLogo from "../../../../assets/logo/gettgo-hero-logo.svg";
import { ButtonRadius } from "../../../components/form";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full h-screen flex ">
        <div className=" w-3/5 flex flex-col bg-white m-auto p-5 rounded-2xl shadow-2xl">
          <div className="p-6 flex justify-between">
            <img src={useLogo} alt={t('fullAppName')} className=" h-12" />
            <div className="flex-col">
              <p className=" text-right">Call center</p>
              <p className="text-2xl font-medium inline">
                <i className="icon-phone inline-block pr-1 text-xl" />
                02 026 6387
              </p>
            </div>
          </div>
          <hr className=" mx-6" />

          <div className="text-center">
            <p className="text-4xl font-medium p-3">
              ยินดีต้อนรับสู่ครอบครัว {t('appName')}
            </p>
            <p className="text-lg">แนะนำการสมัครผ่านสมาชิก: คุณวรรณา สวัสดี</p>
            <div className="text-left ml-24 p-5">
              <p className="text-2xl font-medium pb-4">
                โปรดเตรียมเอกสารดังต่อไปนี้ เพื่อใช้ประกอบการสมัครสมาชิก
              </p>
              <p className=" text-xl">1. สำเนาบัตรประจำตัวประชาชน </p>
              <p className=" text-xl">2. สำเนาใบอนุญาตประกันวินาศภัย</p>
              <p className=" text-xl">3. สำเนาทะเบียนบ้าน</p>
              <p className=" text-xl">4. สำเนาหน้าบัญชีธนาคารกสิกรไทย</p>
              <p className=" text-xl">5. สำเนาใบเปลี่ยนชื่อ – สกุล (ถ้ามี)</p>
            </div>
          </div>
          <div className="self-center p-10">
            <ButtonRadius color="primary">
              กรอกใบสมัคร
              <i className="material-icons" style={{ marginLeft: 4 }}>
                double_arrow
              </i>
            </ButtonRadius>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
