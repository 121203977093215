import React from "react";
import { useParams } from "react-router-dom";
import { format, addYears } from "date-fns";
import { serialize } from "object-to-formdata";
import { useForm, UseFormOptions } from "react-hook-form";
import useAxios from "../../../hooks/useAxios";
import useHttp from "../../../hooks/useHttp";
import ErrorPage from "../../../components/errorPage";
import Preloading from "../../../components/loading/Preloading";
import { initialState, reducer, RenewContextProvider } from "./store";
import Header from "./components/Header";
import PolicyInfo from "./components/PolicyInfo";
import InsuredInfo from "./components/InsuredInfo";
import VehicleInfo from "./components/VehicleInfo";
import { Details, OrderInfo } from "../../../types/order";
import { Insured, InsuredCategory } from "../../../types/insured";
import { MotorCriteria } from "../../../types/criteria";
import { PolicyType } from "../../../types/policy";
import { RepairFacility } from "../../../types/repairFacility";
import { Quote } from "../../../types/quote";

import usePolicy from "./hooks/usePolicy";

interface Tab {
  policy: boolean;
  insured: boolean;
  vehicle: boolean;
}

interface ResponseMessage {
  data: { message: string; success: boolean };
}

interface InputData {
  renew: {
    cmi?: string;
    from_order_id: string;
    policy_id: string;
    from_quote_id: string;
    agent_code: string;
    vmi_renewal_file?: Blob | null;
    vmi_warning_date?: string;
    cmi_renewal_file?: Blob | null;
    cmi_warning_date?: string;
    renewal_file?: Blob | null;
    warning_date?: string;
    insured_category: InsuredCategory;
    insured: Insured;
    criteria: MotorCriteria;
    details: Details;
    remark: "";
    product: {
      name: string;
      sum_insured: string;
      insurer_code: string;
      policy_type: PolicyType;
      details: {
        repair_facility: RepairFacility;
      };
    };
    order_details: Details;
  };
}

const Create = () => {
  const { id, type, action } = useParams<{ id: string; type: string; action: string }>();
  const [tab, setTab] = React.useState<Tab>({
    policy: true,
    vehicle: true,
    insured: true,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const response = useAxios<OrderInfo>(`api/orders/${id}/info`);
  const { post, patch } = useHttp();

  const policy = usePolicy(state.renew?.policies, type);

  const { register, errors, handleSubmit, control, setValue, clearErrors } = useForm<
    InputData,
    UseFormOptions
  >({
    reValidateMode: "onSubmit",
  });

  React.useEffect(() => {
    if (response.status === "loaded") {
      let renew = response.data?.content;
      const startDate = renew?.details?.cover_start_date
        ? new Date(renew?.details?.cover_start_date)
        : new Date();
      const endDate = renew?.details?.cover_end_date
        ? new Date(renew?.details?.cover_end_date)
        : addYears(startDate, 1);
      const addonStartDate = renew?.details?.addon_start_date
        ? new Date(renew?.details?.addon_start_date)
        : new Date();
      const addonEndDate = renew?.details?.addon_end_date
        ? new Date(renew?.details?.addon_end_date)
        : addYears(addonStartDate, 1);
      const details: Details = { ...renew.details };
      details.cover_start_date = format(startDate, "yyyy-MM-dd");
      details.cover_end_date = format(endDate, "yyyy-MM-dd");
      details.addon_start_date = format(addonStartDate, "yyyy-MM-dd");
      details.addon_end_date = format(addonEndDate, "yyyy-MM-dd");

      if (action === "create") {
        const quotes = [...renew.quotes] as Quote[];
        quotes[0] = {
          ...renew.quotes[0],
          sum_insured: null,
          repair_facility: "garage",
        };
        renew = {
          ...renew,
          vmi_renewal_file: null,
          vmi_warning_date: format(new Date(), "yyyy-MM-dd"),
          cmi_renewal_file: null,
          cmi_warning_date: format(new Date(), "yyyy-MM-dd"),
          renewal_file: null,
          warning_date: format(new Date(), "yyyy-MM-dd"),
          quotes,
          coverages: {
            ...renew.coverages,
            excess_damage_coverage: null,
            fire_theft_damage_coverage: null,
            natural_disaster_coverage: null,
          },
        }
      } else if (action === "edit") {
        renew = {
          ...renew,
          vmi_renewal_file: null,
          vmi_warning_date: renew.details.vmi_warning_date,
          cmi_renewal_file: null,
          cmi_warning_date: renew.details.cmi_warning_date,
          renewal_file: null,
          warning_date: format(new Date(), "yyyy-MM-dd"),
        }
      }

      dispatch({
        type: "ADD",
        payload: {
          isEdit: false,
          renew: {
            ...renew,
            addon_selling_price: response.data?.addon_selling_price,
            checked_addon: response.data?.checked_addon,
            remark: renew.remark,
            details,
          },
        },
      });
    }
  }, [response]);

  const onSubmit = (data): void => {
    const payloads: InputData = data;

    if (data.renew.vmi_renewal_file && data.renew.vmi_renewal_file.length > 0) {
      payloads.renew.vmi_renewal_file = data.renew.vmi_renewal_file[0];
    }

    if (data.renew.cmi_renewal_file && data.renew.cmi_renewal_file.length > 0) {
      payloads.renew.cmi_renewal_file = data.renew.cmi_renewal_file[0];
    }

    if (data.renew.renewal_file && data.renew.renewal_file.length > 0) {
      payloads.renew.renewal_file = data.renew.renewal_file[0];
    }

    if (action === "create") {
      setIsLoading(true);
      post(`/api/renewals`, serialize(payloads))
        .then((response: ResponseMessage) => {
          setIsLoading(false);
          if (response.data.success) {
            window.location.href = "/staffs/renewal";
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }

    if (action === "edit") {
      setIsLoading(true);
      patch(`/api/renewals/${id}/update`, serialize(payloads))
        .then((response: ResponseMessage) => {
          setIsLoading(false);
          if (response.data.success) {
            window.location.href = "/staffs/renewal-doc";
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <RenewContextProvider value={{ state, dispatch }}>
      {isLoading && <Preloading />}
      {response.status === "loading" && (
        <div className="p-4 mt-4 text-center bg-white shadow-lg">Loading</div>
      )}
      {response.status === "error" && <ErrorPage />}
      {response.status === "loaded" && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="hidden"
            name="renew[agent_code]"
            ref={register}
            value={state.renew?.agent.number}
          />
          <input type="hidden" name="renew[policy_id]" ref={register} value={policy?.id} />
          <input
            type="hidden"
            name="renew[from_order_id]"
            ref={register}
            value={state.renew?.order_id}
          />
          <input
            type="hidden"
            name="renew[from_quote_id]"
            ref={register}
            value={state.renew?.quotes?.[0].id}
          />
          <input
            type="hidden"
            ref={register}
            name="renew[product_type]"
            value={state.renew?.quotation.product_type}
          />
          <input type="hidden" ref={register} name="renew[product][name]" value="-" />
          <input type="hidden" name="renew[remark]" ref={register} value={state.renew?.remark} />

          <Header />
          <div className="px-2">
            <div className="flex flex-wrap my-4 -mx-2">
              <div className="w-full px-2 lg:w-7/12">
                <PolicyInfo
                  onToggle={() => setTab({ ...tab, policy: !tab.policy })}
                  active={tab.policy}
                  register={register}
                  errors={errors}
                  requireAttachment={action === "create"}
                />
              </div>
              <div className="w-full px-2 lg:w-5/12">
                {state.renew?.quotation.product_type === "motor" && (
                  <VehicleInfo
                    onToggle={() => setTab({ ...tab, vehicle: !tab.vehicle })}
                    active={tab.vehicle}
                    register={register}
                    errors={errors}
                  />
                )}
                <InsuredInfo
                  onToggle={() => setTab({ ...tab, insured: !tab.insured })}
                  active={tab.insured}
                  register={register}
                  errors={errors}
                  clearErrors={clearErrors}
                  control={control}
                  setValue={setValue}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </RenewContextProvider>
  );
};

export default Create;
