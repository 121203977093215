import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { AddToast } from "react-toast-notifications";
import { useQuery } from "react-query";
import { AxiosResponse, AxiosError } from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import CommonModal from "./common";
import { TrackingData } from "~src/types/policy";
import { fetchTrackingPolicyData } from "~/src/api/PolicyServices";
import Preloading from "~src/components/loading/Preloading";

type PolicyTrackingModalProps = {
  queryKey: string;
  policyId: string;
  orderNumber: string;
  open: boolean;
  onClose: () => void;
  addToast: AddToast;
};

const PolicyTrackingModal: React.FC<PolicyTrackingModalProps> = ({
  policyId,
  orderNumber,
  queryKey,
  open,
  onClose,
  addToast,
}): JSX.Element | undefined => {
  const { data, isLoading, isFetching, isSuccess } = useQuery<
    AxiosResponse<TrackingData>,
    AxiosError<{ message: string }>
  >(queryKey, () => fetchTrackingPolicyData(policyId), {
    onError: (error) => {
      onClose();
      addToast(`[${orderNumber}] ${error.response.data.message}`, {
        appearance: "error",
        autoDismiss: true,
      });
      return undefined;
    },
    enabled: !!policyId,
    retry: false,
  });

  if (isLoading || isFetching) {
    return <Preloading />;
  }

  if (!isSuccess) {
    onClose();
    return undefined;
  }

  const { trackingData, deliverSuccess, trackingService, trackingNo } = data.data;

  const transportName = (transportService: string): string => {
    switch (transportService.toLowerCase()) {
      case "thailandpost":
        return "ไปรษณีย์ไทย";
      case "thaipost":
        return "ไปรษณีย์ไทย";
      default:
        return transportService;
    }
  };

  const deliverStatus: string = deliverSuccess ? "สำเร็จ" : "อยู่ระหว่างการดำเนินการ";
  const stepperActive: number = deliverSuccess ? trackingData.length : trackingData.length - 1;

  return (
    <CommonModal open={open} onClose={onClose} persistent={false}>
      <div className="text-xl font-bold text-center">
        <p className="text-primary">{transportName(trackingService)}</p>
        <p className="text-secondary">{trackingNo}</p>
      </div>
      <p>
        Order#<span className="text-secondary">{orderNumber}</span>
      </p>
      <p className="mb-3">
        สถานะ <span className="ml-1 text-secondary">{deliverStatus}</span>
        {!deliverSuccess && <CircularProgress className="ml-2" color="info" size={15} />}
      </p>
      <Stepper activeStep={stepperActive} alternativeLabel>
        {trackingData.map((datum) => (
          <Step key={datum.statusDate}>
            <StepLabel>
              <div className="flex flex-col">
                <p>{datum.statusDescription}</p>
                <p>{datum.location}</p>
                <p>{datum.statusDate}</p>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </CommonModal>
  );
};

export default PolicyTrackingModal;
