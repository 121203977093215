import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { ToastProvider } from "react-toast-notifications";
import { urlStringify } from "/src/helpers/helpers";
import SectionHeader from "/src/components/layout/staff/SectionHeader";
import SearchForm from "/src/components/staff/SearchForm";
import ErrorPage from "/src/components/errorPage";
import Table from "./table/Table";
import useInput from "/src/hooks/useInput";
import useFetch from "/src/hooks/useFetch";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import "./list.scss";

const BASE_URL = "/renewals";
const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const List = (props) => {
  const { location } = props;
  const { t } = useTranslation();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const { value: search_value, bind: handleChaneSearch } = useInput(
    queryParams?.keyword ?? ""
  );

  const [queryStr] = useState(queryParams);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataLists, setDataLists] = useState([]);
  const [currentPage, setCurrentPage] = useState(queryParams["page"] ?? DEFAULT_PAGE);

  const [isError, setIsError] = useState(false);
  const url = `/api/orders?${urlStringify(Object.assign({
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    order_status: ["open", "waiting_payment"],
    status: "renew",
  }, queryParams))}`;
  const { response, error, isLoading } = useFetch(url);

  useEffect(() => {
    if (!isLoading) {
      const results = response?.content;
      let totalPages = response?.total_pages;
      let totalRecords = response?.total_count;

      setTotalRecords(totalRecords);
      setPageCount(totalPages);
      setDataLists(results);
      setIsError(false);
    }

    if (error) {
      setIsError(true);
    }
  }, [
    response,
    error,
    isLoading,
    search_value,
    pageCount,
    queryStr,
    activePage,
  ]);

  const handleClickSearch = () => {
    performSearch();
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") performSearch();
  };

  const performSearch = () => {
    const queries = urlStringify({
      keyword: search_value,
      per_page: PER_PAGE,
      page: 1,
      order_status: ["open"],
      status: "renew",
    });
    window.location.href = `${BASE_URL}?${queries}`;
  }

  const handlePageClick = (e) => {
    const selectedPage = parseInt(e.selected) + 1;
    const queries = urlStringify({
      keyword: search_value,
      per_page: PER_PAGE,
      page: selectedPage,
      order_status: ["open"],
      status: "renew",
    });
    window.location.href = `${BASE_URL}?${queries}`;
  };

  return (
    <ToastProvider>
      <div className="renew-list">
        {isError ? (
          <ErrorPage />
        ) : (
          <>
            <SectionHeader
              title="ใบเตือนต่ออายุ"
              breadcrumbs={[
                { url: "#", label: "รายการซื้อขาย", current: true },
              ]}
              searchComponent={
                <SearchForm
                  type="text"
                  placeholder="ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่คำสั่งซื้อ"
                  {...handleChaneSearch}
                  handleClick={handleClickSearch}
                  handleKeyPress={handleKeyPressSearch}
                />
              }
            />

            <div className="renew-list-title">
              <div className="title-bottom">
                <span className={"gettmore-icon-info"}></span>
                {` หากไม่พบใบเตือนต่ออายุที่ท่านต้องการ กรุณาติดต่อเจ้าหน้าที่ได้ที่ Line: ${t('lineAccount')} หรือ ${t('contactMail')}`}
              </div>
            </div>

            <Table loading={isLoading} data={dataLists} />
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                initialPage={(Number(currentPage) - 1)}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"gm-paginate"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                disableInitialCallback={true}
              />
            )}
          </>
        )}
      </div>
    </ToastProvider>
  );
};

export default List;
