import React from "react";

const useWindowScroll = (handler: any): void => {
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handler]);

  const handleScroll = (): void => {
    handler();
  };
};

export default useWindowScroll;
