import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  openCreateModal,
  selectActionStatus,
  selectBannerTotal,
} from "~src/features/banner/bannerSlice";
import Table from "~src/features/banner/staff/Table";
import Preloading from "~src/components/loading/Preloading";
import EditModal from "~src/features/banner/staff/EditModal";
import CreateModal from "~src/features/banner/staff/CreateModal";

const BannerListPage = () => {
  const actionStatus = useAppSelector(selectActionStatus);
  const total = useAppSelector(selectBannerTotal);
  const dispatch = useAppDispatch();

  return (
    <>
      {actionStatus === "pending" && <Preloading />}
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" component="h1">
            Banner
          </Typography>
          {total < 5 && (
            <Button
              variant="contained"
              disableElevation
              onClick={() => dispatch(openCreateModal())}
            >
              Add new banner
            </Button>
          )}
        </Box>
        <Table />
        <CreateModal />
        <EditModal />
      </Box>
    </>
  );
};

export default BannerListPage;
