import React from "react";
import { Box, Button, IconButton, Modal, Paper } from "@mui/material";
import Slider, { Settings } from "react-slick";
import { useAppDispatch, useAppSelector } from "~src/store";
import { fetchBanners } from "../apis/banners";
import { selectBannerData, selectBannerTotal } from "../bannerSlice";
import CloseIcon from "@mui/icons-material/Close";

import "slick-carousel/slick/slick.css";

const BannerModal = () => {
  const dispatch = useAppDispatch();
  const banners = useAppSelector(selectBannerData);
  const total = useAppSelector(selectBannerTotal);

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchBanners());
  }, []);

  React.useEffect(() => {
    setOpen(total > 0);
  }, [total]);

  const settings: Settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} hideBackdrop>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "fixed",
            background: "rgba(0,0,0,0.9)",
          }}
        >
          <Paper
            id="slider-banner"
            elevation={0}
            sx={{
              position: "relative",
              //   top: "10%",
              //   left: "50%",
              //   transform: "translateX(-50%)",
              width: { sm: 700, lg: 1000, xl: 1200 },
              background: "none",
              height: 600,
              outline: "none",
              overflow: "hidden",
              pb: 2,
              "& .slick-slide": {
                padding: 0,
              },
            }}
          >
            <Slider {...settings}>
              {banners.map((banner, index) => (
                <Box
                  sx={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={banner.image}
                    alt=""
                    sx={{ transform: "scale(1) !important", maxHeight: "500px" }}
                    key={index}
                  />
                </Box>
              ))}
            </Slider>
          </Paper>
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: "absolute", top: "10px", right: "10px", color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export default BannerModal;
