import classnames from "classnames";
import React from "react";

interface Props {
  text: string | Element;
  value: string;
  onChange?: (value: string) => void;
  selected: string;
  image?: string;
  height?: string;
  width?: string;
}

const RadioButtonBox: React.FC<Props> = ({
  text,
  value,
  onChange,
  selected,
  image,
  height,
  width ,
}): JSX.Element => {
  return (
    <>
      <button
        type="button"
        className={classnames(
          value === selected ? "bg-white border-primary-color border-2" : "bg-white",
          "rounded-10 h-32 w-40 shadow-md p-0"
        )}
        style={{ height: height, width: width }}
        onClick={() => onChange(value)}
      >
        <div className="flex flex-row items-center justify-center after:border-r-2">
          <div className="z-0 flex flex-col items-center flex-1">
            { image ? <img src={image} alt="logo" width={50} height={50} /> : '' }
            <p
              className={classnames(
                value === selected ? "text-primary" : " text-gray-500",
                "text-sm"
              )}
            >
              {text}
            </p>
          </div>
        </div>
      </button>
    </>
  );
};

export default RadioButtonBox;
