import React from "react";

import "./Option.scss";

const Option = ({ label, value, checked, onChangeValue }) => {
  return (
    <div className="option-component">
      <button
        className={`option-component-button ${checked ? "checked" : ""}`}
        value={value}
        onClick={() => onChangeValue(value)}
      >
        {label}
      </button>
    </div>
  );
};

export default Option;
