import React from "react";
import { FormGroup, TextField, SelectField } from "~src/components/form";

import { messages } from "~src/helpers/messages";
import { InsuredsAttributes } from "~src/types/checkoutBasicForm";

interface InformationInputProps {
  insuredId: number | string;
  attrPrefixInsured: string;
  insuredsAttributes?: InsuredsAttributes;
  errorsInsuredsAttr?: any;
  options?: any;
  register: (msg) => void;
  handleChangeField: (event: React.FormEvent, attrName: string) => void;
}

const InformationInput: React.FC<InformationInputProps> = ({
  insuredId,
  attrPrefixInsured,
  insuredsAttributes,
  errorsInsuredsAttr,
  options,
  register,
  handleChangeField,
}) => {
  const validateOnChange = (evt, name) => {
    if (/^[ก-๙A-Za-z]*$/.test(evt.target.value)) handleChangeField(evt, name);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <FormGroup label="คำนำหน้าชื่อ" htmlForLabel="insured_title" require>
            <input
              type="hidden"
              name={`${attrPrefixInsured}[id]`}
              value={`${insuredId}`}
              ref={register}
            />
            <SelectField
              id="insured_title"
              name={`${attrPrefixInsured}[title]`}
              value={insuredsAttributes?.title}
              onChange={(evt) => handleChangeField(evt, "title")}
              inputRef={register(messages.requiredSelect)}
              error={errorsInsuredsAttr?.title?.message}
              options={options?.selectFields}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <FormGroup label="ชื่อ" htmlForLabel="insured_firstname" require>
            <TextField
              id="insured_firstname"
              name={`${attrPrefixInsured}[firstname]`}
              value={insuredsAttributes?.firstname}
              onChange={(evt) => validateOnChange(evt, "firstname")}
              inputRef={register(messages.required)}
              error={errorsInsuredsAttr?.firstname?.message}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup label="นามสกุล" htmlForLabel="insured_lastname" require>
            <TextField
              id="insured_lastname"
              name={`${attrPrefixInsured}[lastname]`}
              value={insuredsAttributes?.lastname}
              onChange={(evt) => validateOnChange(evt, "lastname")}
              inputRef={register(messages.required)}
              error={errorsInsuredsAttr?.lastname?.message}
            />
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default InformationInput;
