import { createAsyncThunk } from "@reduxjs/toolkit";
import { isAxiosError, AxiosError } from "axios";
import axios from "~src/helpers/configAxios";
import { escapeHtml } from "~src/helpers/helpers";

export interface ErrorResponse {
  message: string | string[];
  status: number;
}

export const fetchListContract = createAsyncThunk<any, void, { rejectValue: ErrorResponse }>(
  "contract/fetchListContract",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("api/contracts");
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchCreateContract = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchCreateContract",
  async (params, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      Object.keys(params).map((k) => {
        if (k === "detail") {
          formData.append(k, escapeHtml(params[k]));
        } else {
          formData.append(k, params[k]);
        }
      });

      await axios.post("api/contracts", formData);
      window.location.href = "/staffs/contracts";
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchShowContract = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchShowContract",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`api/contracts/${params.id}`);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchUpdateContract = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchUpdateContract",
  async (params, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      Object.keys(params.payload).map((k) => {
        if (k === "detail") {
          formData.append(k, escapeHtml(params.payload[k]));
        } else {
          formData.append(k, params.payload[k]);
        }
      });

      await axios.patch(`api/contracts/${params.id}`, formData);
      window.location.href = "/staffs/contracts";
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchDeleteContract = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchDeleteContract",
  async (params, { rejectWithValue }) => {
    try {
      await axios.delete(`api/contracts/${params.id}`);
      window.location.reload();
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchAgentContract = createAsyncThunk<any, void, { rejectValue: ErrorResponse }>(
  "contract/fetchAgentContract",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("api/contracts/agent_contract");
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchAcceptContract = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchAcceptContract",
  async (params, { rejectWithValue }) => {
    try {
      await axios.post(`api/contracts/agent_accept`, { contract_id: params.contract_id });
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);

export const fetchNewAgentContract = createAsyncThunk<any, any, { rejectValue: ErrorResponse }>(
  "contract/fetchNewAgentContract",
  async ({ role }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`api/contracts/new_agent_contract?role=${role}`);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const { status, data } = axiosError.response as {
            status: number;
            data: {
              message: string | string[];
            };
          };
          if (status === 401) {
          }

          return rejectWithValue({ message: data.message, status });
        }
      }
      return rejectWithValue({ message: "An error occurred", status: 500 });
    }
  }
);
