import React, { useCallback } from "react";

import { Button } from "~src/components/form/button";
import CreditCardAlert, {
  CreditCardAlertProps,
  ErrorInfoLineData,
} from "~src/components/payments/CreditCardAlert";

import dictionary from "~src/data.yaml";
import { Order } from "~src/types/order";
import { Payment } from "~src/types/payment";
// import { formatNumberWithCurrency } from "/src/helpers/formatNumber.js";

import "./creditCardErrorModal.scss";

export interface CreditCardErrorModalProps extends CreditCardAlertProps {
  onClose: () => void;
  order: Order;
  payment?: Payment;
}

export default function CreditCardErrorModal({
  onClose,
  order,
  payment,
  id = "creditCardErrorModal",
  ...alertProps
}: CreditCardErrorModalProps) {
  const renderDefaultButtons = useCallback(
    () => (
      <div className="result-button-block flex flex-row w-full justify-center">
        <Button color="primary" role="button" classNameOption="btn-close" onClick={() => onClose()}>
          ปิด
        </Button>
        {/* <input type="submit" value="Send Error Report" /> */}
      </div>
    ),
    [onClose]
  );

  return (
    <div id={id} className="card-error-modal modal">
      <div className="modal-overlay"></div>
      <div className="modal-box">
        <div className="header">
          <div className="icon">
            <i className="material-icons">credit_card</i>
          </div>
          <h2>ERROR</h2>
          <div className="buttons">
            <button className="material-icons close-icon" role="button" onClick={() => onClose()}>
              close
            </button>
          </div>
        </div>
        <br />
        <form className="error-report-form">
          <input type="hidden" name="order_id" value={order.order_id} />
          <input type="hidden" name="payment[token]" value={payment?.token} />
          <CreditCardAlert
            {...alertProps}
            className="body flex flex-col w-full"
            renderActions={alertProps.renderActions || renderDefaultButtons}
          ></CreditCardAlert>
        </form>
      </div>
    </div>
  );
}
