import React from "react";
import { useAuthContext } from "~src/context/Auth";
import Product from "~src/models/Product";

interface HeaderDetailProp {
  type?: string;
  packageObj?: Product;
}

const QuotationHeaderDetail: React.FC<HeaderDetailProp> = ({ type, packageObj }): JSX.Element => {
  const { session } = useAuthContext();
  const agentName = `${session.title} ${session.firstname} ${session.lastname}`;
  const renderHeader = () => {
    switch (type) {
      case "motor":
        return (
          <>
            <i className="icon-motor icon-size" /> สร้างใบเสนอราคาประกันภัยรถยนต์
          </>
        );
      case "travel":
        return (
          <>
            <i className="icon-travel icon-size" /> สร้างใบเสนอราคาประกันเดินทาง
          </>
        );
      default:
        return (
          <>
            <i className="icon-motor icon-size" /> สร้างใบเสนอราคาประกันภัยรถยนต์
          </>
        );
    }
  };

  return (
    <div className="">
      <h6>{renderHeader()}</h6>
      <div className="row p-3">
        <div className="mr-4">
          <div>บริษัทประกัน</div>
          <div>ประเภท</div>
          <div>แพ็คเกจ</div>
          {type === "motor" && <div>รถยนต์</div>}
        </div>
        <div className="mr-20">
          <div className="text-gray-600">{packageObj && packageObj.insurerName}</div>
          {/* <div className="text-gray-600">{`${yaml.policy_type[productInfo.policy_type]} ${
            yaml.repair_facility[repairFacility]
          }`}</div> */}
          {/* <div className="text-gray-600">{productInfo.name}</div> */}
          {/* {type === "motor" && <div className="text-gray-600">{title}</div>} */}
        </div>
        <div className="mr-4">
          <div>ออกให้ ณ. วันที่</div>
          <div>ตัวแทน</div>
        </div>
        <div>
          <div className="text-gray-600">{new Date().toLocaleDateString()}</div>
          <div className="text-gray-600">{agentName}</div>
        </div>
      </div>
    </div>
  );
};

export default QuotationHeaderDetail;
