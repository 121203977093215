import { useState, useEffect } from "react";

const useFile = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      onChange: (event) => {
        setValue(event.target.files[0]);
      },
    },
  };
};

export default useFile;
