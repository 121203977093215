import React from "react";

import dataYml from "~/src/data.yaml";
import { reformatDateEN } from "~src/helpers/date";
import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "~src/helpers/formatNumber.js";
import { renderYaml } from "~src/helpers/renderYaml";
import ContinueOrderButton from "~src/components/form/button/ContinueOrderButton";
import { useAuthenticate } from "~src/helpers/authen";

interface Props {
  data?: any[];
  isLoading?: boolean;
}

export const OrderTable: React.FC<Props> = ({ data = [], isLoading = false }) => {
  const [orderList, setOrderList] = React.useState<any[] | null>();
  const { showPaidCommission } = useAuthenticate();

  React.useEffect(() => {
    let mounted = true;
    if (data.length > 0) {
      if (mounted) {
        const dataWithCoverDate = data.filter((d) => d.cover_end_date);
        const dataNoneCoverDate = data.filter(
          (d) => d.cover_end_date == "" || d.cover_end_date == undefined
        );

        const sortByCoverDate = dataWithCoverDate.sort(
          (a, b) => a.cover_end_date - b.cover_end_date
        );

        setOrderList([...sortByCoverDate, ...dataNoneCoverDate]);
      }

      return () => (mounted = false);
    }
  }, [data]);

  return (
    <section className="flex flex-col h-full overflow-hidden bg-white">
      <div className="flex text-xs font-light text-center text-gray-600 bg-white border-t border-b divide-x">
        <div className="w-3/12">เลขที่คำสั่งซื้อ</div>
        <div className="w-1/12">บริษัท</div>
        <div className="w-1/12">แผน</div>
        <div className="w-2/12">วันที่คุ้มครอง</div>
        <div className="w-2/12">ทุนประกัน</div>
        <div className="w-2/12">เบี้ยประกันที่ชำระ</div>
        {showPaidCommission && <div className="w-2/12">ค่าตอบแทนสุทธิ</div>}
        <div className="w-1/12" />
      </div>
      <div className="flex-1 overflow-scroll divide-y">
        {isLoading
          ? Array.from({ length: 2 }).map(() => (
              <div className="flex py-3">
                <div className="w-3/12 p-1 space-y-2">
                  <div className="w-9/12 h-2 bg-gray-400 animate-pulse" />
                  <div className="w-8/12 h-2 bg-gray-400 animate-pulse" />
                  <div className="w-9/12 h-2 bg-gray-400 animate-pulse" />
                  <div className="w-8/12 h-2 bg-gray-400 animate-pulse" />
                </div>
                <div className="w-1/12 p-1">
                  <div className="w-8 h-8 mx-auto bg-gray-400 animate-pulse" />
                </div>
                <div className="w-1/12 p-1 text-center space-y-2">
                  <div className="w-8 h-2 mx-auto bg-gray-400 animate-pulse" />
                  <div className="w-8 h-2 mx-auto bg-gray-400 animate-pulse" />
                </div>
                <div className="w-2/12 p-1 space-y-2">
                  <div className="w-2/12 h-2 bg-gray-400 animate-pulse" />
                  <div className="w-8/12 h-2 bg-gray-400 animate-pulse" />
                  <div className="w-2/12 h-2 bg-gray-400 animate-pulse" />
                  <div className="w-8/12 h-2 bg-gray-400 animate-pulse" />
                </div>
                <div className="w-2/12 p-1 text-center">
                  <div className="w-8/12 h-2 mx-auto bg-gray-400 animate-pulse" />
                </div>
                <div className="w-2/12 p-1 font-bold text-center">
                  <div className="w-8/12 h-2 mx-auto bg-gray-400 animate-pulse" />
                </div>
                <div className="w-2/12 p-1 text-center">
                  <div className="w-8/12 h-2 mx-auto bg-gray-400 animate-pulse" />
                </div>
                <div className="w-1/12 p-1" />
              </div>
            ))
          : orderList &&
            orderList.map((order, idx) => (
              <div key={idx} className="flex py-2 text-xs">
                <div className="w-3/12 p-1">
                  <div className="align-middle">
                    <i className={`mr-2 icon-${order.quotation.product_type}`} />
                    {order.number}
                  </div>
                  <div>{order.quotation.customer_name}</div>
                  {order.quotation.product_type !== "travel" && (
                    <>
                      <div>{`${order.quotation.criteria?.car_brand} ${order.quotation.criteria?.car_model}`}</div>
                      <div>
                        <span className="mr-1 text-gray-500">ทะเบียน:</span>
                        <span>{order.plate_number}</span>
                      </div>
                    </>
                  )}
                </div>
                <div className="w-1/12 p-1">
                  <img
                    style={{ width: 30, height: 30, margin: "auto" }}
                    src={order.quotes?.[0]?.insurer_icon}
                    alt=""
                  />
                </div>
                <div className="w-1/12 p-1 text-center">
                  {renderYaml(
                    dataYml.product_type[order.quotation?.product_type][
                      order.quotes?.[0]?.policy_type
                    ]
                  )}
                  {order.quotes?.[0].addon != undefined && (
                    <>
                      <br />
                      พ.ร.บ.
                    </>
                  )}
                </div>
                <div className="w-2/12 p-1">
                  <div>
                    <div className="mr-1 text-gray-500">เริ่ม:</div>
                    {order.quotes?.[0].addon
                      ? reformatDateEN(order?.addon_start_date)
                      : reformatDateEN(order?.cover_start_date)}
                  </div>
                  <div>
                    <div className="mr-1 text-gray-500">ถึง:</div>
                    {order.quotes?.[0].addon
                      ? reformatDateEN(order?.addon_end_date)
                      : reformatDateEN(order?.cover_end_date)}
                  </div>
                </div>
                <div className="w-2/12 p-1 text-center">
                  {formatNumberWithCurrency2(order.quotes?.[0]?.sum_insured)}
                </div>
                <div className="w-2/12 p-1 font-bold text-center">
                  {formatNumberWithCurrency(order.payments_summary.payment_total_amount)}
                </div>
                {showPaidCommission && (
                  <div className="w-2/12 p-1 text-center">
                    {formatNumberWithCurrency(order.payments_summary.payment_earning_commission)}
                  </div>
                )}
                <div className="w-1/12 p-1">
                  <ContinueOrderButton order={order} size="xs" />
                  <div className="mt-2 overflow-hidden text-gray-600 break-all text-2xs">
                    <div>คำสั่งซื้อหมดอายุ:</div>
                    <div>{reformatDateEN(order?.expired_at)}</div>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </section>
  );
};
