import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import QueryString from "query-string";

import CardLoading from "/src/components/loading/CardLoading";
import SearchCard from "./SearchCard";
import { Switch } from "/src/components/form";
import { getSearchMotors } from "/src/pages/agent/motor/Services";

import useFetch from "/src/hooks/useFetch";

import "./search-modal.scss";

const SearchModal = ({ fnClose, compareIds, carTitle }) => {
  const { search } = location;

  const [isShowCommission, setIsShowCommission] = useState(false);
  const [querires] = useState(QueryString.parse(search));

  const { response, error, isLoading } = useFetch(
    `/api/products/search2/motor${search}`
  );

  const handleCloseModal = () => {
    fnClose();
  };

  return (
    <div className="search-modal">
      <div className="search-modal-wrapper animated fadeInDownBig delay-01s">
        <div className="search-modal-wrapper-header">
          <div className="search-modal-wrapper-header-left">
            <h1>เลือกแผนประกันใหม่</h1>
            <p> {`สำหรับรถยนต์ ${carTitle}`}</p>
          </div>
          <div className="search-modal-wrapper-header-right">
            <div className="search-modal-wrapper-header-action">
              <Switch
                size="small"
                active={isShowCommission}
                onClick={() => setIsShowCommission(!isShowCommission)}
              />
              <div className="label">แสดงค่า Commission</div>
            </div>
          </div>
          <button type="button" className="search-modal-wrapper-header-close">
            <i
              role="button"
              className="material-icons"
              onClick={handleCloseModal}
            >
              close
            </i>
          </button>
        </div>
        <div className="search-modal-wrapper-content">
          <div className="row">
            {isLoading ? (
              <CardLoading
                items={8}
                className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
              />
            ) : (
              <>
                {response.map((packageObj, index) => (
                  <div
                    key={index}
                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                  >
                    <SearchCard
                      compareIds={compareIds}
                      packageObj={packageObj}
                      isShowCommission={isShowCommission}
                      querires={querires}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
