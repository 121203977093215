import React from "react";
import { FormGroup } from "~/src/components/form";
import UploadImage from "~src/components/form/uploadImage/UploadImage";
import { useAppDispatch, useAppSelector } from "~src/store";
import { agentInfoSelector, handleChangeImageUrl } from "./registerSlice";
import { useFormContext } from "react-hook-form";

const UploadInfo = () => {
  const methods = useFormContext();
  const agentInfo = useAppSelector(agentInfoSelector);
  const dispatch = useAppDispatch();

  const handleChangeUploadedImage = (
    url: string,
    name: "citizen_card_file" | "motor_license_file" | "account_book_file"
  ) => {
    dispatch(handleChangeImageUrl(url, name));
  };
  return (
    <div>
      <div className="flex items-center justify-between w-full col-span-1 col-start-1 text-xl bg-custom-gray-sm mb-3">
        <span className="p-2">เอกสารเพิ่มเติม</span>
      </div>

      <div id="uploadImage" className="col-span-2 mt-2 grid grid-cols-3 lg:grid-cols-4 gap-4">
        <FormGroup>
          <UploadImage
            id="citizen_card_file"
            name="citizen_card_file"
            title="บัตรประชาชน"
            inputRef={methods.register}
            className="text-xs"
            onFileSelected={(url) => handleChangeUploadedImage(url, "citizen_card_file")}
            value={agentInfo.citizen_card_file_url}
            required
          />
        </FormGroup>
        <FormGroup>
          <UploadImage
            id="motor_license_file"
            name="motor_license_file"
            title="รูปบัตรนายหน้า (วินาศภัย)"
            inputRef={methods.register}
            className="text-xs"
            onFileSelected={(url) => handleChangeUploadedImage(url, "motor_license_file")}
            value={agentInfo.motor_license_file_url}
            required={agentInfo.has_motor_license}
          />
        </FormGroup>
        <FormGroup>
          <UploadImage
            id="account_book_file"
            name="account_book_file"
            title="สมุดบัญชีเงินฝากหน้าแรก"
            inputRef={methods.register}
            className="text-xs"
            value={agentInfo.account_book_file_url}
            onFileSelected={(url) => handleChangeUploadedImage(url, "account_book_file")}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default UploadInfo;
