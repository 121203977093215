import QueryString from "query-string";
import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import CheckSignIcon from "~src/components/icons/svg/CheckSignIcon";

import dictionary from "~src/data.yaml";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { formatNumberWithCurrency } from "~src/helpers";
import useFetch from "~src/hooks/useFetch";
import {
  State as ThankyouPageState,
  clearStateFromStorage,
} from "~src/reducer/ThankyouPageReducer";

import "./thankyou.scss";

export interface ThankyouPageProps {
  location: any;
}

export default function ThankyouPage(props: ThankyouPageProps) {
  return (
    // <PageContextProvider withQuery location={props.location}>
    <PageContextProvider withQuery location={props.location}>
      <ThankyouPageInner {...props} />
    </PageContextProvider>
  );
}

export type ThankyouPageInnerProps = ThankyouPageProps;

const ThankyouPageInner = () => {
  const location = useLocation<ThankyouPageState>();
  const { onError, queryParams, setAlert } = usePageContext();
  const { t } = useTranslation();

  const locStateKey = "thankyou_location_state";
  if (location.state) {
    localStorage.setItem(locStateKey, JSON.stringify(location.state));
  } else if (queryParams?.orderNumber) {
    location.state = { ...queryParams };
    localStorage.setItem(locStateKey, JSON.stringify(location.state));
  } else if (window.localStorage) {
    const locStateStr = localStorage.getItem(locStateKey);
    if (locStateStr) {
      location.state = JSON.parse(locStateStr) as ThankyouPageState;
    }
  }

  const [data, setData] = useState<ThankyouPageState>(location.state);
  // const [nextUrl, setNextUrl] = useState("");
  // const [nextUrlLabel, setNextUrlLabel] = useState("");
  const [isBankTransfer, setIsBankTransfer] = useState(false);

  const {
    response: insurer,
    error,
    isLoading,
  } = useFetch(`/api/insurers?code=${data.insurerCode}`);

  useEffect(() => {
    clearStateFromStorage();
  }, []);

  useEffect(() => {
    switch (data.paymentMethod) {
      case "bank_transfer":
        // setNextUrl("/orders");
        setIsBankTransfer(true);
        break;
      // case "qr":
      //   setNextUrl("/policies/follow-up");
      //   break;
      // case "cc":
      //   setNextUrl("/policies/follow-up");
      //   break;
      default:
        // setNextUrl("/orders");
        break;
    }
  }, [data.paymentMethod]);

  return (
    <div className="card card-thankyou m-16 p-8 mx-auto text-center">
      <div className="card-thankyou-title">
        <CheckSignIcon fillColor="green" width="88px" height="88px" />
        <h1>ได้รับการแจ้งงานเรียบร้อยแล้ว</h1>
        {data.paymentMethod === "bank_transfer" ? (
          <>
            <p>เมื่อเจ้าหน้าที่ยืนยันการชำระเงินเรียบร้อยแล้ว</p>
            <p>
              ท่านสามารถตรวจสอบรายการได้ที่เมนู <span />
              <span className="gettmore-icon-policytracking"> </span>
              <span className="font-medium">ติดตามกรมธรรม์</span>
            </p>
          </>
        ) : (
          <>
            <p>เจ้าหน้าที่จะทำการตรวจสอบรายการของท่าน เพื่อประสานงานกับบริษัทประกันภัยต่อไป</p>
            <p>
              ท่านสามารถตรวจสอบรายการได้ที่เมนู <span />
              <span className="gettmore-icon-policytracking"> </span>
              <span className="font-medium">ติดตามกรมธรรม์</span>
            </p>
          </>
        )}
      </div>
      <div className="mb-4">
        <p>
          เลขที่ใบสั่งซื้อ: <span className="font-semibold text-lg">{data?.orderNumber}</span>
        </p>
        {isBankTransfer ? (
          <div className="batch batch-verify">
            <span />
            <span>รอตรวจสอบรายการ</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="card-thankyou-focus">
        {!isLoading && (
          <img src={insurer?.content[0].icon} width="100" alt={insurer?.content[0].name} />
        )}
        <div className="text-left">
          {/* <p className="text-title">{data?.insureds}</p> */}
          <p className="text-sm text-body">{data?.shortDescPolicy}</p>
          <p className="text-sm text-body">{data?.shortDescCriteria}</p>
          <p className="card-thankyou-amount">
            {formatNumberWithCurrency(data?.amount)} <span className="text-sm">ต่อปี</span>
          </p>
        </div>
      </div>
      <div className="card-thankyou-footer">
        <p className="text-gray-400">
          หากต้องการความช่วยเหลือ สามารถติดต่อได้ที่ Line: {t("lineAccount")} หรือ
          {t("contactMail")}
        </p>
        {isBankTransfer ? (
          <a href="/orders" role="button" className="btn-follow-policy">
            {dictionary.payment.to_orders}
          </a>
        ) : (
          <a href="/policies/follow-up" role="button" className="btn-follow-policy">
            {dictionary.payment.to_followup}
          </a>
        )}
      </div>
    </div>
  );
};
