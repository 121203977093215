import React, { useEffect, useState } from "react";
import { UseFormMethods, Controller, useWatch } from "react-hook-form";
import classNames from "classnames";
import { format } from "date-fns";
import _isUndefined from "lodash/isUndefined";
import _get from "lodash/get";
import { useRecoilState, useResetRecoilState } from "recoil";
import { optionsAddonCmi } from "~src/helpers/options";
import { formatNumberWithCurrency } from "~/src/helpers/formatNumber";
import { FormGroup } from "~/src/components/form";
import DatePicker from "~/src/components/Forms/DatePicker";
import { messages } from "~src/helpers/messages";
import PremiumPanel from "../panel/PremiumPanel";
import { coverageInfo, addonInfo } from "~/src/states/orderCustom";

interface Props
  extends Partial<Pick<UseFormMethods, "register" | "errors" | "control" | "setValue">> {
  name: string;
  isMotorCustom: boolean;
}

const AddonSelect = ({
  cmiSelected,
  setCmiSelected,
  setCheckAddon,
  optionAddon,
  isCustom = false,
}): JSX.Element => (
  <>
    {optionAddon.map((cmiOption) => (
      <div
        key={cmiOption.value}
        className={classNames("border-2 border-gray-300 rounded-5 cursor-pointer", {
          "border-2 rounded-5 border-pink-600 bg-pink-200": cmiSelected === cmiOption.value,
        })}
      >
        <button
          type="button"
          className="text-base px-4 py-2"
          onClick={() => {
            setCheckAddon(cmiOption.value !== cmiSelected, cmiOption.label);
            setCmiSelected(
              cmiOption.value === cmiSelected ? "" : cmiOption.value,
              cmiOption.label,
              isCustom
            );
          }}
        >
          {isCustom ? `พ.ร.บ. custom` : `พ.ร.บ. ${formatNumberWithCurrency(cmiOption.label)}`}
        </button>
      </div>
    ))}
  </>
);

const AddonForm: React.FC<Props> = ({
  name,
  control,
  errors,
  isMotorCustom,
  setValue,
  register,
}): JSX.Element => {
  const errorFields = _get(errors, name);
  const [cmiSelected, setCmiSelected] = useState<string>("");
  const [checkAddon, setCheckAddon] = useState<boolean>(false);
  const [{ addonStartDate }, setCoverageInfo] = useRecoilState(coverageInfo);
  const [{ addonChecked }, setAddonInfo] = useRecoilState(addonInfo);
  const resetAddonInfo = useResetRecoilState(addonInfo);

  const mainType = useWatch({
    control,
    name: "product_type",
    defaultValue: undefined,
  });

  const handleCheckedAddon = (status: boolean) => {
    setCheckAddon(status);
    setAddonInfo((prevState) => ({
      ...prevState,
      addonChecked: status,
    }));
  };

  const handleCmiValue = (value: string, label: string, isCustom: boolean) => {
    setCmiSelected(value);
    setAddonInfo((prevState) => ({
      ...prevState,
      addonPrice: value !== "" && !isCustom ? parseFloat(label) : 0,
    }));
  };

  useEffect(() => {
    resetAddonInfo();
    setCmiSelected("");
    setCheckAddon(false);
  }, [mainType]);

  useEffect(() => {
    if (addonStartDate) {
      const newDate = new Date(
        addonStartDate.getFullYear() + 1,
        addonStartDate.getMonth(),
        addonStartDate.getDate()
      );
      setValue(`${name}.details.addon_end_date`, format(newDate, "dd/MM/yyyy"));
    }
  }, [addonStartDate]);

  return (
    <>
      <div className="flex flex-row gap-2 col-span-3 justify-between tablet:flex-col">
        {isMotorCustom ? (
          <AddonSelect
            isCustom={true}
            cmiSelected={cmiSelected}
            optionAddon={[{ label: "พ.ร.บ.", value: "custom" }]}
            setCmiSelected={handleCmiValue}
            setCheckAddon={handleCheckedAddon}
          />
        ) : (
          <AddonSelect
            cmiSelected={cmiSelected}
            optionAddon={optionsAddonCmi}
            setCmiSelected={handleCmiValue}
            setCheckAddon={handleCheckedAddon}
          />
        )}

        <input type="hidden" name={`${name}.addon_remark`} ref={register} value={cmiSelected} />
        <input
          type="hidden"
          name={`${name}.checked_addon`}
          ref={register}
          value={checkAddon.toString()}
        />
      </div>
      {checkAddon && (
        <div className="col-span-3 grid grid-cols-2 gap-4 animate-fade-in duration-700">
          <FormGroup label="วันที่เริ่มคุ้มครอง (พ.ร.บ.)" require>
            <Controller
              name={`${name}.details.addon_start_date`}
              rules={cmiSelected === "" ? null : messages.required}
              control={control}
              render={({ onChange, value }) => {
                return (
                  <>
                    <DatePicker
                      value={value}
                      dateFormat="dd/MM/yyyy"
                      maxYears={3}
                      onChange={(date: Date): void => {
                        onChange(format(date, "dd/MM/yyyy"));
                        setCoverageInfo((prevState) => ({
                          ...prevState,
                          addonStartDate: date,
                        }));
                      }}
                    />
                    {errorFields?.addon_start_date?.message && (
                      <span className="text-xs text-red-600">
                        {errorFields?.addon_start_date?.message}
                      </span>
                    )}
                  </>
                );
              }}
            />
          </FormGroup>

          <FormGroup label="วันที่สิ้นสุดวันคุ้มครอง (พ.ร.บ.)" require>
            <Controller
              name={`${name}.details.addon_end_date`}
              rules={cmiSelected === "" ? null : messages.required}
              control={control}
              render={({ onChange, value }) => {
                return (
                  <>
                    <DatePicker
                      readOnly={_isUndefined(addonStartDate)}
                      value={value}
                      minDate={addonStartDate}
                      maxYears={60}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        onChange(format(date, "dd/MM/yyyy"));
                        setCoverageInfo((prevState) => ({
                          ...prevState,
                          addonEndDate: date,
                        }));
                      }}
                    />
                    {errorFields?.addon_end_date?.message && (
                      <span className="text-xs text-red-600">
                        {errorFields?.addon_end_date?.message}
                      </span>
                    )}
                  </>
                );
              }}
            />
          </FormGroup>
        </div>
      )}

      {isMotorCustom && addonChecked && (
        <div className="col-span-3">
          <PremiumPanel
            name={name}
            label="รายละเอียดเบี้ยประกันภัย พ.ร.บ."
            expandedHeader={false}
            isAddon={true}
            callbackAddon={(addonValue) => {
              setAddonInfo((prevState) => ({
                ...prevState,
                addonPrice: addonValue,
              }));
            }}
          />
        </div>
      )}
    </>
  );
};

export default AddonForm;
