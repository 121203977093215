import React, { useState } from "react";
import PropTypes from "prop-types";

import "./table.scss";
import Cookies from "js-cookie";
import { formatNumberWithCurrency } from "~src/helpers";
import { ButtonRadius } from "~src/components/form";
import UpdateStatusModal from "~src/pages/agent/lead/modal/UpdateStatusModal";
import AssignSaleModal from "~src/pages/agent/lead/modal/AssignSaleModal";
import UpdateCustomerModal from "~src/pages/agent/customer/modal/UpdateCustomerModal";
import { isJsonString } from "~src/helpers/helpers";

const LeadTable = (props) => {
  const { loading, data, setSortBy, sortBy, handleClick } = props;
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false);
  const [isUpdateSaleModalOpen, setIsUpdateSaleModalOpen] = useState(false);
  const [isUpdateCustomerModalOpen, setIsUpdateCustomerModalOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [leadId, setLeadId] = useState("");
  const [saleId, setSaleId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const role_json = (isJsonString(Cookies.get("role")) ? JSON.parse(Cookies.get("role")) : null)
    ?.code;

  const handleUpdateSale = (lead_id, current_sale) => {
    setLeadId(lead_id);
    setSaleId(current_sale);
    setIsUpdateSaleModalOpen(true);
  };

  const handleUpdateStatusClick = (lead_id, current_status) => {
    setStatus(current_status);
    setLeadId(lead_id);
    setIsUpdateStatusModalOpen(true);
  };

  const handleShowActivity = (lead_id) => {
    window.location.href = `/lead/${lead_id}`;
  };

  const handleUpdateCustomerClick = (customer_id, customer_first_name, customer_last_name) => {
    setCustomerId(customer_id);
    setFirstName(customer_first_name);
    setLastName(customer_last_name);
    setIsUpdateCustomerModalOpen(true);
  };

  const updateStatusCloseModal = () => {
    setIsUpdateStatusModalOpen(!isUpdateStatusModalOpen);
  };

  const updateCustomerCloseModal = () => {
    setIsUpdateCustomerModalOpen(!isUpdateCustomerModalOpen);
  };

  const assignSaleCloseModal = () => {
    setIsUpdateSaleModalOpen(!isUpdateSaleModalOpen);
  };

  return (
    <div>
      <div className="lead-table">
        <div className="table-grid">
          <div className="grid-th">
            <div>ข้อมูลลูกค้า</div>
            <div>เบอร์โทรศัพท์</div>
            <div>แผน</div>
            <div>สถานะ</div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setSortBy(!sortBy);
                handleClick();
              }}
              aria-hidden="true"
            >
              วันที่ได้lead <i className="material-icons text-xs">unfold_more</i>
            </div>
            <div>วันที่ได้รับมอบหมาย</div>
            {role_json === "supervisor" && <div>ผู้รับผิดชอบ</div>}
            <div style={{ textAlign: "center" }} />
          </div>

          {loading && <div className="renew-grid-body-full">กำลังโหลด...</div>}
          {!loading && (
            <>
              {data.length === 0 && <div className="renew-grid-body-full">ไม่พบข้อมูล</div>}
              {data.map((lead, index) => (
                <div className="grid-tbody" key={index}>
                  <div>
                    <div className="tooltip">
                      {lead.drop_full_name}
                      <span className="tooltiptext">{lead.db_full_name}</span>
                    </div>
                    <div>{lead.email}</div>
                  </div>
                  <div>{lead.phone}</div>
                  <div>
                    รุ่น: {lead.car_brand} {lead.car_model} <br />
                    รุ่นย่อย/ปี: {lead.sub_model}/{lead.year} <br />
                    บริษัท: {lead.insurer} <br />
                    ทุนประกัน: {formatNumberWithCurrency(parseFloat(lead.sum_insured))} <br />
                    ราคา: {formatNumberWithCurrency(parseFloat(lead.price))}
                  </div>
                  <div>
                    {lead.status}
                    <br />
                    <br />
                    {lead.status_key === "follow_up" && (
                      <>
                        วันที่: {lead.followup_date}
                        <br />
                        <br />
                      </>
                    )}
                    {lead.sub_status && (
                      <>
                        {lead.sub_status}
                        <br />
                        <br />
                      </>
                    )}
                    {lead.activity_remark && <>{lead.activity_remark}</>}
                  </div>
                  <div>
                    {lead.received_date} <br />
                    จาก {lead.source_type} <br/>
                    {(lead.utm_source || lead.utm_medium || lead.utm_term || lead.utm_content || lead.utm_campaign) && <>UTM<br/></>}
                    {lead.utm_source   && <>source: {lead.utm_source} <br/></>}
                    {lead.utm_medium   && <>medium: {lead.utm_medium} <br/></>}
                    {lead.utm_term     && <>term: {lead.utm_term} <br/></>}
                    {lead.utm_content  && <>content: {lead.utm_content} <br/></>}
                    {lead.utm_campaign && <>campaign: {lead.utm_campaign}</>}
                  </div>
                  <div>{lead.assigned_date}</div>
                  {role_json !== "sales" && <div>{lead.assigned}</div>}
                  <div>
                    <ButtonRadius
                      type="button"
                      color="primary"
                      size="xs"
                      onClick={() => handleUpdateStatusClick(lead.id, lead.status_key)}
                    >
                      อัพเดทสถานะ
                    </ButtonRadius>

                    {role_json === "supervisor" && (
                      <div>
                        <ButtonRadius
                          type="button"
                          color="primary"
                          size="xs"
                          onClick={() => handleUpdateSale(lead.id, lead.sale_id)}
                        >
                          เปลี่ยน sale
                        </ButtonRadius>
                      </div>
                    )}

                    {role_json === "supervisor" && (
                      <div>
                        <ButtonRadius
                          type="button"
                          color="primary"
                          size="xs"
                          onClick={() => handleShowActivity(lead.id)}
                        >
                          log
                        </ButtonRadius>
                      </div>
                    )}

                    {role_json === "sales" && (
                      <div>
                        <ButtonRadius
                          type="button"
                          color="primary"
                          size="xs"
                          onClick={() =>
                            handleUpdateCustomerClick(
                              lead.customer_id,
                              lead.customer_first_name,
                              lead.customer_last_name
                            )
                          }
                        >
                          อัพเดทข้อมูลลูกค้า
                        </ButtonRadius>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {isUpdateStatusModalOpen && (
        <UpdateStatusModal leadId={leadId} status={status} cbCloseModal={updateStatusCloseModal} />
      )}

      {isUpdateSaleModalOpen && (
        <AssignSaleModal leadId={leadId} saleId={saleId} cbCloseModal={assignSaleCloseModal} />
      )}

      {isUpdateCustomerModalOpen && (
        <UpdateCustomerModal
          customerId={customerId}
          firstName={firstName}
          lastName={lastName}
          cbCloseModal={updateCustomerCloseModal}
        />
      )}
    </div>
  );
};

LeadTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default LeadTable;
