import React, { useState, useRef, useMemo } from "react";
import Calendar from "react-calendar";
import useOnClickOutside from "./useOnClickOutside";
import { parseDate, dateFormat } from "/src/helpers/dateTime";
import "./datepicker.scss";
const DatePickerField = ({
  inputRef,
  error,
  value,
  minDate,
  callbackvalue,
  disableCarlendar,
  ...rest
}) => {
  const [opened, setOpened] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const [pickDate, setPickDate] = useState(parseDate(value) || new Date());

  useMemo(() => {
    if(value !== "") {
      setDateValue(value)
      setPickDate(parseDate(value) || new Date())
    }
  }, [value])

  const dialogRef = useRef();
  useOnClickOutside(dialogRef, () => setOpened(false));

  const focusInput = () => {
    setOpened(true);
  };

  const handleChange = (date) => {
    setPickDate(date);
    setDateValue(dateFormat(date));
    if (callbackvalue !== undefined) {
      callbackvalue(dateFormat(date));
    }
    setOpened(false);
  };

  return (
    <div className="form-datepicker">
      <i className="material-icons">date_range</i>
      <input
        type="date"
        {...rest}
        ref={inputRef}
        onFocus={focusInput}
        value={dateValue}
        className={!error ? "form-input" : "form-input has-error"}
      />
      {opened && !disableCarlendar ? (
        <div className="form-datepicker-dialog" ref={dialogRef}>
          <Calendar
            locale="th-Th"
            calendarType="US"
            minDate={minDate}
            onChange={handleChange}
            value={pickDate}
          />
        </div>
      ) : null}
    </div>
  );
};

export default DatePickerField;
