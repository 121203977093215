import React from "react";
import { Group, Header, List } from "../SummaryList";
import { getWhTaxStatusText } from "~/src/helpers/helpers";

interface Props {
  includeWhTax: boolean;
}

const Miscellaneous: React.FC<Props> = ({
  includeWhTax
}) => {

  return (
    <Group>
      <Header
        icon={<span className="icon icon-motor" />}
        label="ข้อมูลอื่น ๆ"
        color="secondary"
      />
      <List label="การหักภาษี ณ ที่จ่าย" value={getWhTaxStatusText(includeWhTax)} />
    </Group>
  );
};

export default Miscellaneous;