import React from "react";
import Fade from "react-reveal/Fade";
import { BrowserView, MobileView } from "react-device-detect";
import Countup from "react-countup";
import IMG from "../../../../assets/homepage/3-min.png";
import youtubePlay from "../../../../assets/homepage/play-btn.png";
import CloseImg from "../../../../assets/homepage/close-button.svg";
import "./css/experience.scss";

const Experience = () => {
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);

  return (
    <div className="relative py-120 vdo-section">
      <div className="container">
        <h2 className="text-xl text-center xl:text-3xl">
          เปิดประสบการณ์ <br /> พลิกหน้ากระดาษสู่ดิจิทัล
        </h2>
        <p className="text-lg text-center xl:text-xl mt-30">
          จาก 2 เรื่องจริงของชีวิตคนขายประกัน
        </p>
        <div className="py-60 lg:px-120 px-30">
          <div className="relative text-center vdo-thumb">
            <div className="bg"></div>
            <Fade bottom>
              <img
                src={IMG}
                alt=""
                className="inline-block rounded-5 vdo-thumb-image"
              />
            </Fade>

            <div className="play-button">
              <button
                onClick={() => setIsOpenModal(true)}
                className="heartbeat"
              >
                <img src={youtubePlay} alt="" className="opacity-75" />
              </button>
            </div>
          </div>
        </div>
        <div className="text-xl text-center">
          <BrowserView>
            <span className="text-3xl font-medium text-secondary-1">
              <Countup end={72} duration={5} />%
            </span>
            <span className="ml-2">ของนายหน้า</span>
          </BrowserView>
          <MobileView>
            <div className="text-2xl font-medium text-secondary-1">
              <Countup end={72} duration={1} />%
            </div>
          </MobileView>
        </div>
        <BrowserView>
          <p className="text-xl font-normal text-center">
            มีรายได้ที่เพิ่มขึ้นจาก <br /> โอกาสขายประกันที่มากกว่า
          </p>
        </BrowserView>
        <MobileView>
          <p className="mt-16 text-lg font-normal text-center">
            ของนายหน้ามีรายได้ที่เพิ่มขึ้น
            <br /> จากโอกาสขายประกันที่มากกว่า
          </p>
        </MobileView>
      </div>
      {isOpenModal && (
        <div className="fixed top-0 left-0 z-20 w-full h-screen bg-white animated fadeIn">
          <button
            className="absolute top-0 right-0 p-10 focus:outline-none"
            onClick={() => setIsOpenModal(false)}
          >
            <img src={CloseImg} alt="" />
          </button>
          <div className="flex items-center justify-center h-full">
            <div className="video-modal">
              <div className="video-container">
                <iframe
                  className="absolute w-full h-full"
                  src="https://www.youtube.com/embed/55MN8NkyfQo?autoplay=1&mute=0&loop=1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Experience;
