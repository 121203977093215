import { RepairFacility } from "../types/repairFacility";
import { InsuredCategory } from "../types/insured";
import { PolicyType } from "../types/policy";
import { ProductType } from "../types/product";

export const PolicyTypeMapping: Record<PolicyType, string> = {
  "1": "ชั้น 1",
  "2+": "ชั้น 2+",
  "3+": "ชั้น 3+",
  "3": "ชั้น 3",
  "cmi": "พ.ร.บ.",
  "truck_over_4ton": "รถบรรทุก",
  "truck_over_4ton_1": "รถบรรทุก ชั้น 1",
  "truck_over_4ton_2": "รถบรรทุก ชั้น 2",
  "truck_over_4ton_2+": "รถบรรทุก ชั้น 2+",
  "truck_over_4ton_3+": "รถบรรทุก ชั้น 3+",
  "truck_over_4ton_3": "รถบรรทุก ชั้น 3",
  "super_car": "รถซุปเปอร์คาร์",
  "super_car_1": "ชั้น 1",
  "super_car_2": "ชั้น 2",
  "super_car_2+": "ชั้น 2+",
  "super_car_3+": "ชั้น 3+",
  "super_car_3": "ชั้น 3",
  "big_bike": "รถบิ๊กไบค์",
  "big_bike_1": "รถบิ๊กไบค์ ชั้น 1",
  "big_bike_2": "รถบิ๊กไบค์ ชั้น 2",
  "big_bike_2+": "รถบิ๊กไบค์ ชั้น 2+",
  "big_bike_3+": "รถบิ๊กไบค์ ชั้น 3+",
  "big_bike_3": "รถบิ๊กไบค์ ชั้น 3",
  "motorbike": "รถจักรยานยนต์",
  "motorbike_1": "รถจักรยานยนต์ ชั้น 1",
  "motorbike_2": "รถจักรยานยนต์ ชั้น 2",
  "motorbike_2+": "รถจักรยานยนต์ ชั้น 2+",
  "motorbike_3+": "รถจักรยานยนต์ ชั้น 3+",
  "motorbike_3": "รถจักรยานยนต์ ชั้น 3",
  "commercial_cmi": "พ.ร.บ. รถยนต์รับจ้าง",
  "motorcycle_cmi": "พ.ร.บ. มอเตอร์ไซต์",
  "residential": "อัคคีภัยที่อยู่อาศัย",
  "not_residential": "อัคคีภัยธุรกิจ",
  "not_residential_high_risk": "อัคคีภัยธุรกิจ(ความเสี่ยงสูง)",
  "hull": "ตัวเรือ",
  "marine_cargo": "ขนส่งทางทะเล",
  "inland_transit": "ขนส่งภายในประเทศ",
  "carrier_liability": "ความรับผิดของผู้ขนส่ง",
  "home": "ที่อยู่อาศัยแบบแพคเกจ",
  "sme": "ธุรกิจ SME แบบแพคเกจ",
  "iar": "ความเสี่ยงภัยทรัพย์สิน",
  "iar_high_risk": "ความเสี่ยงภัยทรัพย์สิน (ความเสี่ยงสูง)",
  "golfers": "ผู้เล่นกอล์ฟ",
  "engineer": "วิศวกรรม",
  "pa": "อุบัติเหตุส่วนบุคคล",
  "pa_group": "อุบัติเหตุกลุ่ม",
  "pa_student": "อุบัติเหตุกลุ่ม นักเรียน นักศึกษา",
  "single": "สุขภาพรายเดี่ยว",
  "group": "สุขภาพกลุ่ม",
  "domestic": "เดินทางในประเทศ",
  "international": "เดินทางต่างประเทศ",
  "cancer": "โรคมะเร็งและโรคร้ายแรง",
  "miscellaneous": "เบ็ดเตล็ดอื่นๆ",
  "burgaly": "ประกันภัยโจรกรรม",
  "billboard": "ประกันภัยป้ายโฆษณา",
  "pure_glass": "ประกันภัยกระจก",
  "bicycle": "ประกันภัยจักรยาน",
  "money": "ประกันภัยสำหรับเงิน",
  "fidelity_guarantee": "ประกันความซื่อสัตย์",
  "business_interruption": "ประกันภัยธุรกิจหยุดชะงัก",
  "gold": "ประกันภัยร้านทอง",
  "trade_credit": "ประกันภัยสินเชื่อทางการค้า",
  "bail_bond": "ประกันภัยอิสรภาพ",
  "car": "ประกันภัยงานก่อสร้าง",
  "ear": "ประกันภัยการติดตั้งเครื่องจักร",
  "mb": "ประกันภัยเครื่องจักร",
  "bo": "ประกันภัยหม้อกำเนิดไอน้ำและถังอัดความดัน",
  "eei": "ประกันภัยเครื่องอุปกรณ์อิเล็กทรอนิกส์",
  "cpm": "ประกันภัยเครื่องมือและเครื่องจักรของผู้รับเหมา",
  "cw": "ประกันภัยการปฏิบัติงานตามสัญญา",
  "condo": "ประกันภัยทรัพย์สินภายในคอนโด",
  "do": "ประกันภัยความรับผิดของผู้บริหาร",
  "pet": "ประกันภัยสัตว์เลี้ยง",
  "cyber": "ประกันภัยความเสี่ยงภัยไซเบอร์",
  "public": "ประกันภัยความรับผิดต่อบุคคลภายนอก",
  "product": "ประกันภัยความรับผิดอันเกิดจากผลิตภัณฑ์",
  "professional": "ประกันภัยความรับผิดอันเกิดจากวิชาชีพ",
  "building_inspector": "ประกันภัยความรับผิดสำหรับผู้ตรวจสอบอาคาร",
  "cmi_oil": "ประกันภัย พ.ร.บ. น้ำมัน",
};

export const InsuredCategoryMapping: Record<InsuredCategory, string> = {
  legal_entity: "นิติบุคคล",
  person: "บุคคลธรรมดา",
};

export const RepairFacilityMapping: Record<RepairFacility, string> = {
  garage: "ซ่อมอู่",
  dealer: "ซ่อมศูนย์",
};

export const ProductTypeMapping: Record<ProductType, string> = {
  motor: "ประกันภัยรถยนต์",
  travel: "ประกันเดินทาง",
  motorbike: "ประกันรถจักรยานยนต์",
  fire: "ประกันอัคคีภัย",
  marine: "ประกันขนส่งทางทะเล",
  home: "ประกันที่อยู่อาศัยแบบแพคเกจ",
  sme: "ประกันธุรกิจ SME แบบแพคเกจ",
  iar: "ประกันความเสี่ยงภัยทรัพย์สิน",
  golfer: "ประกันผู้เล่นกอล์ฟ",
  engineer: "ประกันวิศวกรรม",
  personalaccident: "ประกันอุบัติเหตุส่วนบุคคล",
  health: "ประกันสุขภาพ",
  cancer: "ประกันโรคมะเร็งและโรคร้ายแรง",
  miscellaneous: "ประกันเบ็ดเตล็ดอื่นๆ",
};
