import React from "react";
import "./index.scss";

const Modal = ({ children, cbCloseModal }) => {
  return (
    <div className="renew-modal">
      <div className="renew-modal-content">
        <button className="close" type="button" onClick={() => cbCloseModal()}>
          <span className="material-icons">close</span>
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
