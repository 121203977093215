module.exports = {
  "appName": "MTi-Agent-Click",
  "fullAppName": "MTi-Agent-Click",
  "lineAccount": "@mtiagentclick",
  "contactMail": "AgentSupport@muangthaiinsurance.com",
  "support": "MTi Supports",
  "insuererCmiSearch": "mti",
  "backgroundLogin": "https://storage.googleapis.com/asia.artifacts.gm-gettmore-project.mtb.co.th/hero-mti-bg.jpg",
  "backgroundHome": "https://storage.googleapis.com/asia.artifacts.gm-gettmore-project.mtb.co.th/hero-mti-bg-home.jpg",
  "commisionCMIRate": "",
  "maxDateCoverage": "30",
  "maxChassisNumber": 20,
  "qrLinePath": "/assets/contact/mti-qr-line.jpg",
  "phoneNumberToContract": "026654000",
  "phoneNumberFormat": "02-665-4000",
  "timeToContact": "8.30 - 17.00 น.",
  "dayToContact": "จันทร์ - ศุกร์",
  "defaultAttachedStatus": "attached",
  "lineLink": {
    "name": "https://line.me/R/ti/g/MY1Hm7IGTZ",
    "url": "https://line.me/R/ti/g/MY1Hm7IGTZ",
    "mobileUrl": "https://line.me/R/ti/g/MY1Hm7IGTZ"
  },
  "dataToContract": [
    {
      "contact": "02-665-4000 ต่อ 4272 4207 4285 4208 4275 4209",
      "info": "ฝ่ายขายธุรกิจตัวแทนกรุงเทพฯ และปริมณฑล"
    },
    {
      "contact": "02-665-4000 ต่อ 4039",
      "info": "ฝ่ายขายธุรกิจตัวแทนภาคตะวันออกเฉียงเหนือ"
    },
    {
      "contact": "02-665-4000 ต่อ 4281",
      "info": "ฝ่ายขายธุรกิจตัวแทนภาคใต้"
    },
    {
      "contact": "02-665-4000 ต่อ 4948",
      "info": "ฝ่ายขายธุรกิจตัวแทนภาคกลาง และตะวันตก"
    },
    {
      "contact": "02-665-4000 ต่อ 3150",
      "info": "ฝ่ายขายธุรกิจตัวแทนภาคตะวันออก"
    },
    {
      "contact": "02-665-4000 ต่อ 4278",
      "info": "ฝ่ายขายธุรกิจตัวแทนภาคเหนือ"
    }
  ],
  "insuredCategory": [
    { "label": "บุคคลธรรมดา", "value": "person" },
    { "label": "นิติบุคคล", "value": "legal_entity" }
  ]
};