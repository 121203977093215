import React, { useEffect, useState, useMemo } from "react";
import { UseFormMethods, useFormContext } from "react-hook-form";
import _debounce from "lodash/debounce";
import CustomDropdown from "~src/components/Forms/CustomDropdown";
import { urlStringify } from "~/src/helpers/helpers";
import axios, { AxiosResponse } from "~/src/helpers/configAxios";
import { Option, PerPageResponse } from "~/src/types/basic";
import { useSetRecoilState } from "recoil";
import { agentInfoResponseAtom, AgentInfoResponse } from "~src/states/orderCustom";

interface Props extends Partial<Pick<UseFormMethods, "control" | "errors">> {
  name: string;
  className?: string;
}

export interface AgentNumberField {
  firstname: string;
  lastname: string;
  username: string;
  role: any;
}

const AgentNumberInput: React.FC<Props> = ({ name, className }): JSX.Element => {
  const { control, errors } = useFormContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<Option<string>[]>([]);
  const setAgentInfo = useSetRecoilState(agentInfoResponseAtom);

  const convertDataToOption = (agentsInfo: AgentNumberField[]): Option<string>[] => {
    return agentsInfo.map((agent) => {
      return {
        label: `${agent.username} - ${agent.firstname} ${agent.lastname} (${agent.role?.role_type})`,
        value: agent.username,
      };
    });
  };

  const fetchAgents = async (searchWord: string) => {
    if (!searchWord) return;

    const url = `/api/agents?${urlStringify({
      page: 0,
      per_page: 50,
      keyword: searchWord,
      is_active: ["active"],
    })}`;

    try {
      setLoading(true);
      const response: AxiosResponse<PerPageResponse<AgentNumberField>> = await axios.get(url);
      const arrAgentsInfo = response.data.content;

      const data: AgentInfoResponse[] = response.data.content;
      setAgentInfo(data);

      const results = convertDataToOption(arrAgentsInfo);
      setOptions(results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const debouceFetchAgents = useMemo(() => _debounce((word) => fetchAgents(word), 750), []);

  useEffect(() => {
    return () => {
      debouceFetchAgents.cancel();
    };
  }, []);

  return (
    <CustomDropdown
      required
      isLoading={loading}
      isSearchable
      className={className}
      name={name}
      control={control}
      errors={errors}
      options={options}
      callbackOnInputChange={(value: string) => debouceFetchAgents(value)}
    />
  );
};

export default AgentNumberInput;
