import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastProvider } from "react-toast-notifications";
import QuickMenuItem from "~src/components/agent/dashboard/QuickMenuItem";
import { Icon } from "~src/components/icons";
import "./index.scss";
import Loading from "~src/components/loading";
import { useAppDispatch, useAppSelector } from "~src/store";
import { fetchGenerateSecureTokenAction, secureTokenState } from "~src/features/travel/redux";

const SearchProduct: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const secureToken = useAppSelector(secureTokenState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (secureToken.status === "idle") {
      dispatch(fetchGenerateSecureTokenAction());
    }
  }, [secureToken]);

  return (
    <ToastProvider>
      <div
        className="main-search-product"
        style={{ backgroundImage: `url(${t("backgroundHome")})` }}
      >
        {loading && <Loading />}
        <div className="main-search-product-box">
          <p className="main-search-product-title">เช็คเบี้ยแผนประกัน</p>
          <div className="mt-4 flex gap-4 justify-center">
            {secureToken.status === "succeeded" && (
              <>
                <QuickMenuItem
                  icon={<Icon name="DashMotor" />}
                  title="รถยนต์"
                  href="/motors"
                  width="w-44"
                  heightStyle={120}
                />
                <QuickMenuItem
                  icon={<Icon name="DashCompulsory" />}
                  title="พ.ร.บ. เดี่ยว"
                  href="/motors?tab=cmi"
                  width="w-44"
                  heightStyle={120}
                />
                <QuickMenuItem
                  icon={<Icon name="DashTA" />}
                  title="เดินทาง"
                  href={secureToken.status === "succeeded" && `/travels?token=${secureToken.token}`}
                  width="w-44"
                  heightStyle={120}
                />
              </>
            )}
          </div>
          <div className="mt-4 flex gap-4 justify-center">
            <QuickMenuItem
              icon={<Icon name="DashPA" />}
              title="อุบัติเหตุ"
              href={t("quickMenuAccident")}
              width="w-44"
              heightStyle={120}
              subtitle="เร็วๆนี้"
            />
            <QuickMenuItem
              icon={<Icon name="DashFire" />}
              title="อัคคีภัย"
              // href={t("quickMenuFire")}
              width="w-44"
              heightStyle={120}
              subtitle="เร็วๆนี้"
            />
            <QuickMenuItem
              icon={<Icon name="DashMisc" />}
              title="ประกันภัยอื่นๆ"
              href={t("quickMenuOther")}
              width="w-44"
              heightStyle={120}
            />
          </div>
        </div>
      </div>
    </ToastProvider>
  );
};

export default SearchProduct;
