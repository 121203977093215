// TODO: Revise/refactor this later

import cookie from "js-cookie";

import Session from "~src/types/session";

export interface State extends Session {
  isInitializing: boolean;
  error?: Error;
}

export interface LoginAction {
  type: "LOGIN";
  payload: Session;
}

export interface LoginErrorAction {
  type: "LOGIN_ERROR";
  payload: {
    error: Error;
  };
}

export interface ModifyAction {
  type: "MODIFY";
  payload: Partial<Session>;
}

export interface LogoutAction {
  type: "LOGOUT";
  payload: any;
}

export type Action = LoginAction | LoginErrorAction | ModifyAction | LogoutAction;

export const initialAuthState: State = {
  isInitializing: true,
  isLoggedIn: false,
  username: "",
  title: "",
  firstname: "",
  lastname: "",
  role: {},
  permissions: [],
  show_paid_commission: true,
  error: undefined,
};

const COOKIE_TOKEN_KEY = "access_token";

export function getAccessToken() {
  return cookie.get(COOKIE_TOKEN_KEY);
}

export default function AuthReducer(state: State, action: Action): State {
  const { type: actionType, payload } = action;
  //TEST
  // console.log(`AuthReducer: ${action.type}:`, action.payload);

  let apiToken;
  switch (actionType) {
    case "LOGIN":
      apiToken = getAccessToken();
      if (!apiToken) {
        console.warn("LOGIN: No apiToken!");
      }

      return {
        isInitializing: false,
        isLoggedIn: true,
        username: payload.username,
        role: payload.role,
        title: payload.title,
        firstname: payload.firstname,
        lastname: payload.lastname,
        permissions: payload.permissions,
        show_paid_commission: payload.show_paid_commission,
        apiToken,
      };
    case "MODIFY":
      apiToken = getAccessToken();
      if (!apiToken) {
        console.warn("MODIFY: No apiToken!");
      }

      return {
        ...state,
        isInitializing: false,
        isLoggedIn: true,
        username: payload.username,
        role: payload.role,
        title: payload.title,
        firstname: payload.firstname,
        lastname: payload.lastname,
        permissions: payload.permissions,
        show_paid_commission: payload.show_paid_commission,
        apiToken,
      };
    case "LOGIN_ERROR":
      return {
        isInitializing: false,
        isLoggedIn: false,
        username: "",
        role: {},
        title: "",
        firstname: "",
        lastname: "",
        permissions: [],
        show_paid_commission: true,
        apiToken: undefined,
        error: payload.error,
      };
    case "LOGOUT":
      return {
        isInitializing: false,
        isLoggedIn: false,
        username: "",
        role: {},
        title: "",
        firstname: "",
        lastname: "",
        permissions: [],
        show_paid_commission: true,
        apiToken: undefined,
      };
    default:
      return state;
  }
}
