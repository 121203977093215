import React, { ChangeEvent } from "react";
interface Props {
  children: JSX.Element | JSX.Element[];
  onSubmit: () => void;
}

type Input = {
  label: string;
  name: string;
  inputRef: any;
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Form: React.FC<Props> = ({ onSubmit, children }) => {
  return <form onSubmit={onSubmit}>{children}</form>;
};

export const TextField: React.FC<Input> = ({
  label,
  name,
  inputRef,
  value,
  onChange,
}) => {
  return (
    <div className="flex flex-wrap mb-3">
      <label className="w-full mb-1">{label}</label>
      <input
        type="text"
        ref={inputRef}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full p-2 border rounded border-gray-400"
      />
    </div>
  );
};

export default Form;
