import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { messages } from "/src/helpers/messages";
import Loading from "/src/components/loading";
import { useTranslation } from "react-i18next";
import { FormGroup, ButtonRadius, SelectField, SelectGroupField } from "/src/components/form";
import { getCarBrand, getCarModel, getCarYear, getCarSubModel, getProvinces } from "../Services";
import RadioButtonBox from "~src/components/form/radioBox/RadioBox";
import city from "/assets/city.svg";
import pickup from "/assets/pickup.svg";
import van from "/assets/van.svg";

const POPULARS = [
  {
    label: "Toyota",
    value: "Toyota",
  },
  { label: "Honda", value: "Honda" },
  { label: "Isuzu", value: "Isuzu" },
  { label: "Nissan", value: "Nissan" },
  { label: "Mazda", value: "Mazda" },
  { label: "BMW", value: "BMW" },
  { label: "Mercedes-Benz", value: "Mercedes-Benz" },
];

const usedVehicleTypes = [
  { name: "รถเก๋ง/รถกระบะ 4 ประตู", value: "110", img: city },
  { name: "รถกระบะ 2 ประตู", value: "320", img: pickup },
  { name: "รถตู้", value: "210", img: van },
];

const commercialTypes = [
  { name: "ส่วนบุคคล", code: "1.10", value: false },
  { name: "เพื่อการพาณิชย์", code: "3.10", value: true },
];

const vanCmiTypes = [
  { name: "ไม่เกิน 15 ที่นั่ง", code: "1.20A", value: "seat_lt_15" },
  { name: "ไม่เกิน 20 ที่นั่ง", code: "1.20B", value: "seat_lt_20" },
  { name: "ไม่เกิน 40 ที่นั่ง", code: "1.20C", value: "seat_lt_40" },
  { name: "เกิน 40 ที่นั่ง", code: "1.20D", value: "seat_gt_40" },
];

const pickupCmiTypes = [
  { name: "ไม่เกิน 3 ตัน", code: "1.40A", value: "weight_lt_3" },
  { name: "ไม่เกิน 6 ตัน", code: "1.40B", value: "weight_lt_6" },
  { name: "ไม่เกิน 12 ตัน", code: "1.40C", value: "weight_lt_12" },
  { name: "เกิน 12 ตัน", code: "1.40D", value: "weight_gt_12" },
];

const FormCmi = () => {
  const [loading, setLoading] = useState(false);
  const [carBrands, setCarBrands] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [carYears, setCarYears] = useState([]);

  const [carSubModels, setCarSubModels] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const { t } = useTranslation();
  const [state, setState] = useState({
    insurance_type: "motor",
  });
  const [vehicleType, setVehicleType] = useState("");
  const [commercialType, setCommercialType] = useState("");
  const [cmiVehicleType, setCmiVehicleType] = useState("");
  const [animationCmiOption, setAnimationCmiOption] = useState("");

  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const selectCmiType = (vehicleType) => {
    if (vehicleType == "210") {
      return vanCmiTypes;
    } else if (vehicleType == "320") {
      return pickupCmiTypes;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const c = [];
    getCarBrand().then((res) => {
      const { brands } = res.data;
      brands.map((value, i) => {
        c[i] = { label: brands[i], value: brands[i] };
      });
      setCarBrands(c);
    });

    const p = [];
    getProvinces().then((res) => {
      const { provinces } = res.data;
      provinces.map((value, i) => {
        p[i] = { label: provinces[i], value: provinces[i] };
      });
      setProvinces(p);
    });
  }, []);

  const handleChangeSwitcher = (evt) => {
    const { name, value } = evt.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeCarBrand = (evt) => {
    const { name, value } = evt.target;
    setLoading(true);
    setCarModels([]);
    setCarYears([]);
    setCarSubModels([]);
    if (value == "") {
      setLoading(false);
      return false;
    }
    const m = [];
    getCarModel(value).then((res) => {
      const { models } = res.data;
      models.map((value, i) => {
        m[i] = { label: models[i], value: models[i] };
      });
      setCarModels(m);
      setLoading(false);
    });
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeCarModel = (evt) => {
    const { name, value } = evt.target;
    setLoading(true);
    setCarYears([]);
    setCarSubModels([]);
    if (value == "") {
      setLoading(false);
      return false;
    }
    const y = [];
    getCarYear(state.car_brand, value).then((res) => {
      const { years } = res.data;
      years.map((value, i) => {
        y[i] = { label: `${years[i]} / ${years[i] + 543}`, value: years[i] };
      });
      setCarYears(y);
      setLoading(false);
    });
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeCarYear = (evt) => {
    const { name, value } = evt.target;
    setLoading(true);
    setCarSubModels([]);
    if (value == "") {
      setLoading(false);
      return false;
    }
    getCarSubModel(state.car_brand, state.car_model, value).then((res) => {
      const { sub_models } = res.data;
      const s = [];
      sub_models.map((value, i) => {
        s[i] = { label: value.sub_model, value: value.code_name };
      });

      setCarSubModels(s);
      setLoading(false);
    });
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeProvince = (evt) => {
    const { name, value } = evt.target;
    setLoading(true);
    if (value == "") {
      setLoading(false);
      return false;
    }

    setLoading(false);
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeVehicleType = (evt) => {
    setVehicleType(evt);
    setCmiVehicleType("");
    setAnimationCmiOption("bounceInRight");
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    const q1 = Object.keys(data)
      .map((k) => `${k}=${data[k]}`)
      .join("&");
    const queries = `?${q1}&insurers[]=${t("insuererCmiSearch")}&policy_type[]=cmi`;

    history.push({
      pathname: "/motors/compulsory",
      search: queries,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {t("vehicleType") && (
          <FormGroup className="radio-button">
            <p className="form-input-error">
              {!!errors.used_vehicle_type && errors.used_vehicle_type.message}
            </p>
            <div className="mb-8 flex space-x-20">
              {usedVehicleTypes &&
                usedVehicleTypes.map((item) => {
                  return (
                    <RadioButtonBox
                      key={item.value}
                      text={item.name}
                      value={item.value}
                      image={item.img}
                      onChange={handleChangeVehicleType}
                      selected={vehicleType}
                    />
                  );
                })}
              <input
                type="hidden"
                name="used_vehicle_type"
                ref={register(messages.required)}
                value={vehicleType}
              />
            </div>
          </FormGroup>
        )}
        {vehicleType && (
          <div className={`flex inline-flex animated ${animationCmiOption}`}>
            <FormGroup className="radio-button" label="จุดประสงค์ในการใช้งานรถ">
              <p className="form-input-error">
                {!!errors.is_commercial && errors.is_commercial.message}
              </p>
              <div className="flex space-x-20">
                {commercialTypes &&
                  commercialTypes.map((item) => {
                    let used_type_string = vehicleType == "110" ? <>{item.name}</> : item.name;

                    return (
                      <RadioButtonBox
                        key={item.value}
                        text={used_type_string}
                        value={item.value}
                        onChange={(value) => setCommercialType(value)}
                        height="80px"
                        width="112px"
                        selected={commercialType}
                      />
                    );
                  })}
                <input
                  type="hidden"
                  name="is_commercial"
                  ref={register(messages.required)}
                  value={commercialType}
                />
              </div>
            </FormGroup>

            {selectCmiType(vehicleType) && (
              <>
                <div className="border-l-2 mt-8 ml-8" style={{ height: 70 + "px" }}></div>
                <FormGroup className="radio-button ml-8" label="ขนาด">
                  <p className="form-input-error">
                    {!!errors.cmi_vehicle_type && errors.cmi_vehicle_type.message}
                  </p>
                  <div className="flex space-x-20">
                    {selectCmiType(vehicleType).map((item) => {
                      return (
                        <RadioButtonBox
                          key={item.value}
                          text={<>{item.name}</>}
                          value={item.value}
                          onChange={(value) => setCmiVehicleType(value)}
                          height="80px"
                          width="112px"
                          selected={cmiVehicleType}
                        />
                      );
                    })}
                    <input
                      type="hidden"
                      name="cmi_vehicle_type"
                      ref={register(messages.required)}
                      value={cmiVehicleType}
                      $
                    />
                  </div>
                </FormGroup>
              </>
            )}
          </div>
        )}
        <div className="motor-mainpage-searchbox-fields cmi">
          <FormGroup label="ยี่ห้อรถยนต์">
            <SelectGroupField
              name="car_brand"
              id="car_brand"
              inputRef={register(messages.required)}
              error={!!errors.car_brand && errors.car_brand.message}
              onChange={handleChangeCarBrand}
              optgroups={[
                { label: "ยี่ห้อรถยนต์ยอดนิยม", options: POPULARS },
                { label: "ยี่ห้อรถยนต์ทั้งหมด", options: carBrands },
              ]}
            />
          </FormGroup>
          <FormGroup label="รุ่นรถยนต์">
            <SelectField
              name="car_model"
              id="car_model"
              inputRef={register(messages.required)}
              error={!!errors.car_model && errors.car_model.message}
              onChange={handleChangeCarModel}
              options={carModels}
            />
          </FormGroup>
          <FormGroup label="ปีรถยนต์ (คศ.)">
            <SelectField
              name="year"
              id="year"
              inputRef={register(messages.required)}
              error={!!errors.year && errors.year.message}
              onChange={handleChangeCarYear}
              options={carYears}
            />
            <input
              type="hidden"
              name="code_name"
              ref={register}
              value={!!carSubModels && !!carSubModels[0] && carSubModels[0].value}
            />
          </FormGroup>
          <FormGroup label="ทะเบียนจังหวัด">
            <SelectField
              name="province"
              id="province"
              inputRef={register(messages.required)}
              error={!!errors.province && errors.province.message}
              onChange={handleChangeProvince}
              options={provinces}
            />
          </FormGroup>
        </div>
        <p>
          หมายเหตุ : หากไม่พบยี่ห้อ หรือรุ่นรถยนต์ ที่ต้องการ กรุณาติดต่อ Line:
          {t("lineAccount")}
        </p>
        <div className="mt-4 text-center">
          <FormGroup>
            <ButtonRadius type="submit" color="secondary">
              ค้นหา
            </ButtonRadius>
          </FormGroup>
        </div>
      </form>
    </>
  );
};

export default FormCmi;
