const idCardFormat = (str) => {
  if (!str) return "-"
  const n = str.length;
  let idCard = str
  if (n == 13) {
    const parts = [
      str.substring(0,1),
      str.substring(1, 5),
      str.substring(5, 10),
      str.substring(10, 12),
      str.substring(12, 13),
    ]
    idCard = parts.join("-")
  }

  return idCard;
};

export default idCardFormat;
