import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Button from "@mui/material/Button";
import { Publish, PictureAsPdf } from "@mui/icons-material";

import axios from "~src/helpers/configAxios";
import { messages } from "~src/helpers/messages";

import Modal from "~src/pages/staff/cms/components/Modal";
import { UploadField } from "~src/components/form";
import FormGroup from "~src/components/form/fromGroup/FormGroup";
import SecureLinkImg from "~src/components/secureLinkImg";

export interface AttachmentModalProps {
  title: string;
  name?: string;
  imageUrl?: string;
  allowUpload?: boolean;
  acceptedFileType?: string;
  onBackdropClicked?: () => void;
  onUpload?: (data: FormData) => Promise<any>;
  onError?: (err: Error) => void;
}

const AttachmentModal: React.FC<AttachmentModalProps> = ({
  title,
  name,
  imageUrl,
  allowUpload = false,
  acceptedFileType = "image/*",
  onBackdropClicked,
  onUpload,
  onError,
}) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [uploadedType, setUploadedType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { register, errors, handleSubmit } = useForm<any>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleImageLoadError = () => {
    setIsInvalid(true);
  };

  const handleChangeUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      const type = e.target.result.toString().split(";")[0].split("/")[1];
      setUploadedType(type);
      setSelectedImage(e.target.result);
    };
    if (files.length > 0) {
      fileReader.readAsDataURL(files[0]);
    } else {
      setSelectedImage(null);
    }
  };

  const handleBackdropclicked = () => {
    setSelectedImage(null);
    onBackdropClicked();
  };

  const handleDownloadFile = () => {
    axios
      .get(imageUrl, {
        responseType: "arraybuffer",
      })
      .then((result) => {
        const filenameRegex = /filename\*?=([^']*'')?([^;]*)/;
        const filename = decodeURI(filenameRegex.exec(result.headers["content-disposition"])[2]);
        const uriString =
          "data:" +
          result.headers["content-type"] +
          ";base64," +
          encodeURI(Buffer.from(result.data, "binary").toString("base64"));
        let a = document.createElement("a");
        a.href = uriString;
        a.download = decodeURI(filename);
        a.click();
      });
  };

  const handleUploadFile = async (_, evt: React.BaseSyntheticEvent) => {
    const data = new FormData(evt.target);

    try {
      setIsSubmitting(true);
      const _respData = onUpload ? await onUpload(data) : undefined;
      setIsSubmitting(false);
    } catch (err) {
      console.error(err);
      onError && onError(err);
      setIsSubmitting(false);
    }
  };

  return (
    <Modal onBackdropClicked={handleBackdropclicked}>
      <div className="flex flex-col p-4">
        <div className="text-2xl">{title}</div>
        <div className="my-2 width-full flex flex-col justify-center">
          {selectedImage ? (
            uploadedType == "pdf" ? (
              <PictureAsPdf
                className="self-center mx-auto border border-green-500"
                fontSize="large"
              />
            ) : (
              <img
                src={selectedImage}
                className="p-1 mx-auto max-h-80 border border-green-500"
                key={selectedImage}
              />
            )
          ) : (
            <>
              {imageUrl ? (
                <>
                  <div onClick={handleDownloadFile} className="flex cursor-pointer justify-center">
                    {isInvalid ? (
                      <PictureAsPdf className="self-center mx-auto" fontSize="large" />
                    ) : (
                      <SecureLinkImg
                        src={imageUrl}
                        className="p-1 mx-auto max-h-80 border border-blue-500"
                        onError={handleImageLoadError}
                      />
                    )}
                  </div>
                  <div className="pt-1 text-xs text-center text-gray-500">
                    กดที่ไฟล์เพื่อดาวน์โหลด
                  </div>
                </>
              ) : (
                <div className="p-1 justify-self-center text-center text-blue-500 border border-blue-500 cursor-not-allowed">
                  ยังไม่มีข้อมูลไฟล์แนบ
                </div>
              )}
            </>
          )}
        </div>
        {allowUpload && (
          <form
            id={`${title}_form`}
            autoComplete="off"
            onSubmit={handleSubmit(handleUploadFile)}
            encType="multipart/form-data"
          >
            <FormGroup htmlForLabel={`${title}_file`}>
              <UploadField
                id={`${title}_file`}
                name={name || title}
                accept={acceptedFileType}
                inputRef={register(messages.required)}
                onChange={handleChangeUpload}
                errors={errors}
              />
            </FormGroup>
            <div className="flex flex-row-reverse">
              <Button
                variant="contained"
                className="w-28"
                disabled={!selectedImage || isSubmitting}
                type="submit"
              >
                {isSubmitting ? (
                  <>
                    <svg className="animate-spin h-5 w-5 mr-3 mr-1" viewBox="0 0 24 24" />
                    Uploading
                  </>
                ) : (
                  <>
                    <Publish className="mr-1" />
                    Upload
                  </>
                )}
              </Button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default AttachmentModal;
