import React, { useState } from "react";
import "./form-group.scss";

const FormGroup = ({
  label = "",
  labelType = "",
  htmlForLabel = "",
  align = "",
  children = null,
  require = false,
}) => {
  const [stateLabel] = useState(label || false);
  const [stateLabelType] = useState(labelType || "block");
  const [stateHtmlFor] = useState(htmlForLabel || false);
  return (
    <div className="from-group">
      {stateLabel ? (
        <label
          htmlFor={stateHtmlFor ? stateHtmlFor : ""}
          className={stateLabelType == "block" ? "label-block" : "label-inline"}
        >
          {stateLabel} {require ? <span>*</span> : ""}
        </label>
      ) : (
        ""
      )}
      {children}
    </div>
  );
};

export default FormGroup;
