import React from "react";
import { CommisssionData } from "src/pages/agent/dashboard/charts";
import { formatNumberWithCurrency, renderYaml, dateFormat } from "../../../../helpers";
import dataYml from "~/src/data.yaml";
import yml from "~/src/status.yml";
import Badges from "../../../badges";
import ProductTypeIcon from "../../../ProductTypeIcon";

interface Props {
  data?: CommisssionData[];
}

export const CommisstionTable: React.FC<Props> = ({ data }) => {
  const policyList = data;
  return (
    <section className="flex flex-col h-full p-16 overflow-hidden bg-white">
      <p className="py-10 text-lg">รายละเอียดค่าตอบแทน</p>
      <div className="flex text-xs font-medium text-center text-gray-600 bg-white ">
        <div className="w-2/12">ข้อมูลคำสั่งซื้อ</div>
        <div className="w-2/12">ข้อมูลลูกค้า</div>
        <div className="w-1/12">บริษัท</div>
        <div className="w-1/12">แผน</div>
        <div className="w-1/12">รอบจ่าย</div>
        <div className="w-2/12 text-right">ทุนประกัน</div>
        <div className="w-2/12 text-right">ส่วนลด</div>
        <div className="w-2/12 text-right">เบี้ยประกันที่ชำระ</div>
        <div className="w-2/12 text-right">ค่าตอบแทนสุทธิ</div>
      </div>
      <div className="flex-1 overflow-scroll divide-y">
        {policyList !== undefined &&
          policyList.map((order, idx) => (
            <div key={idx} className="flex py-2 text-xs">
              <div className="w-2/12 text-left">
                {order.product_type === "motor" || order.product_type === "cmi" ? (
                  <>
                    <ProductTypeIcon type={order.product_type} />
                    {order?.order_number}
                    <Badges payslip="" status={`policy-${order.status}`}>
                      {yml.status.policy.agent[order.status]}
                    </Badges>
                  </>
                ) : (
                  <>
                    <ProductTypeIcon type={order.product_type} />
                    {` ${order.order_number}`}
                  </>
                )}
              </div>
              <div className="w-2/12 text-left">
                <div>
                  {`${order?.insured_title} ${order?.insured_firstname}
                  ${order?.insured_lastname}`}
                  <br />
                  {order.product_type === "motor" || order.product_type === "motorbike" ? (
                    <>
                      {`${order?.criteria?.car_brand} ${order?.criteria?.car_model}`}
                      <br />
                      {`ทะเบียน ${order?.plate_number}`}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="w-1/12 text-center">
                <img style={{ width: 30, height: 30, margin: "auto" }} src={order?.insurer_icon} />
              </div>
              <div className="w-1/12 text-center">
                {renderYaml(dataYml.product_type[order?.product_type][order?.policy_type])}
              </div>
              <div className="w-1/12 text-center">{dateFormat(new Date(order?.payout_date))}</div>
              <div className="w-2/12 text-right">
                {formatNumberWithCurrency(order?.sum_insured)}
              </div>
              <div className="w-2/12 text-right">{formatNumberWithCurrency(order?.discount)}</div>
              <div className="w-2/12 text-right">
                {formatNumberWithCurrency(order?.total_amount)}
              </div>

              <div className="w-2/12 text-right">
                {formatNumberWithCurrency(order?.earning_commission)}
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};
