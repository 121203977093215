import React, {
  createContext,
  Dispatch,
  Reducer,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { CheckoutSummaryState } from "~src/models/CheckoutState";

import ThankyouPageReducer, { initState, State, Action } from "~src/reducer/ThankyouPageReducer";
import { Order } from "~src/types/order";

// TODO: Refactor/Reconsolidate Checkout states

export interface ThankyouPageContextProps {
  state: State;
  dispatch: Dispatch<Action>;
  gotoPage: () => void;
}

const ThankyouPageContext = createContext<ThankyouPageContextProps | undefined>(undefined);
export default ThankyouPageContext;

export interface ThankyouPageContextProviderProps {
  token?: string | string[];
  isPublic?: boolean;
  summary?: Partial<CheckoutSummaryState>;
  order?: Order;
  paymentMethod?: string;
  setPreloading?: (state: boolean, label?: string) => void;
}

export const ThankyouPageContextProvider = ({
  token = undefined,
  isPublic = false,
  summary,
  order,
  paymentMethod,
  setPreloading,
  children,
}: React.PropsWithChildren<ThankyouPageContextProviderProps>) => {
  const history = useHistory();

  const initialState = useMemo(
    () => initState(summary, order, paymentMethod),
    [summary, order, paymentMethod]
  );

  const [state, dispatch] = useReducer<Reducer<State, Action>>(ThankyouPageReducer, initialState);

  const [isGoingToPage, setIsGoingToPage] = useState(false);

  useEffect(() => {
    if (!isGoingToPage) {
      return;
    }

    setPreloading && setPreloading(true, "กำลังเปลี่ยนหน้า");

    if (isPublic && !!token) {
      history.push({
        pathname: "/public/thankyou",
        search: `?token=${token}`,
        state,
      });
    } else {
      history.push({
        pathname: "/thankyou",
        state,
      });
    }
  }, [isGoingToPage]);

  function gotoPage() {
    setIsGoingToPage(true);
  }

  return (
    <ThankyouPageContext.Provider value={{ state, dispatch, gotoPage }}>
      {children}
    </ThankyouPageContext.Provider>
  );
};

export function useThankyouPageContext(): ThankyouPageContextProps {
  const context = useContext(ThankyouPageContext);
  if (!context) {
    throw new Error("ThankyouPageContext Provider not found");
  }

  return context;
}
