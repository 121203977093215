import React from "react";
import PropTypes from "prop-types";

import { addYears } from "date-fns";
// import { dateFormat, parseDate, parseDateEN } from "/src/helpers/dateTime";
import { reformatDateEN } from "~src/helpers/date";
import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "/src/helpers/formatNumber";

import { Button } from "/src/components/form";
import dataYml from "/src/data.yaml";
import { renderYaml } from "/src/helpers/renderYaml";
import SecureLink from "~src/components/secureLink";

import "./table.scss";

const PATH = "/staffs/renewals";

const Table = (props) => {
  const { loading, data } = props;
  return (
    <div>
      <div className="renew-grid">
        <div className="renew-grid-head">
          {/* <div>เลขที่กรมธรรม์เดิม</div> */}
          <div>รห้สตัวแทน</div>
          <div>ข้อมูลลูกค้า</div>
          <div>ประเภทงาน</div>
          <div>บริษัท</div>
          <div>แผน</div>
          <div>วันที่คุ้มครอง</div>
          <div>ทุนประกัน</div>
          <div>เบี้ยประกันที่ชำระ</div>
          <div>ค่าตอบแทนสุทธิ</div>
          <div>วันที่แจ้งเตือน</div>
          <div>เอกสารแจ้งเตือน</div>
        </div>

        {loading && <div className="renew-grid-body-full">กำลังโหลด...</div>}
        {!loading && (
          <>
            {data.length == 0 && <div className="renew-grid-body-full">ไม่พบข้อมูล</div>}
            {data.map((order, index) => (
              <div className="renew-grid-body" key={index}>
                {/* <div>{`เลขที่กรมธรรม์เดิม`}</div> */}
                <div>{order.agent_number}</div>
                <div>
                  {`${order.insureds[0].title} ${order.insureds[0].firstname}
                  ${order.insureds[0].lastname}`}{" "}
                  {order.order_type !== "custom" && (
                    <>
                      <br />
                      {order.quotation?.criteria?.car_brand} {order.quotation?.criteria?.car_model}{" "}
                      <br />
                      {`ทะเบียน: ${order.plate_number}`}
                    </>
                  )}
                </div>
                <div>{dataYml.order.contract[order.contract]}</div>
                <div>
                  <img src={order.quotes[0].insurer_icon} alt="" />
                </div>
                <div>
                  {order.quotes[0].addon != undefined ? (
                    <>
                      {`ชั้น ${order.quotes[0].policy_type}`} <br />
                      {`(พ.ร.บ.)`}
                    </>
                  ) : (
                    renderYaml(
                      dataYml.product_type[order.quotation.product_type][
                        order.quotes[0].policy_type
                      ]
                    )
                  )}
                </div>
                <div>
                  <span>เริ่ม:</span>{" "}
                  {order.quotes[0]?.policy_type === "cmi"
                    ? reformatDateEN(order.addon_start_date)
                    : reformatDateEN(order.cover_start_date)}
                  <br />
                  <span>ถึง:</span>{" "}
                  {order.quotes[0]?.policy_type === "cmi"
                    ? reformatDateEN(order.addon_end_date)
                    : reformatDateEN(order.cover_end_date)}
                </div>
                <div>{formatNumberWithCurrency2(order.quotes[0].sum_insured)}</div>
                <div>
                  <div className="selling-price">
                    <i className="icon icon-motor selling-price"></i>{" "}
                    {formatNumberWithCurrency(order.quotes[0].selling_price)}{" "}
                  </div>
                  {order.quotes[0].addon !== undefined && (
                    <>
                      <span className="cmi-label">พ.ร.บ.</span>{" "}
                      <span className="num-value">
                        {formatNumberWithCurrency(order.quotes[0].addon.selling_price)}
                      </span>
                    </>
                  )}
                </div>
                <div>
                  {" "}
                  {formatNumberWithCurrency(order.payments_summary.payment_earning_commission)}
                </div>

                <div>
                  <i className="icon icon-motor selling-price"></i>{" "}
                  {order.order_type !== "custom"
                    ? reformatDateEN(order.vmi_warning_date)
                    : reformatDateEN(order.warning_date)}
                  <br />
                  {order.quotes[0].addon !== undefined && (
                    <>
                      <span className="cmi-label">พ.ร.บ.</span>{" "}
                      {reformatDateEN(order.cmi_warning_date)}
                    </>
                  )}
                </div>
                <div>
                  {order.renewal_file ? (
                    <SecureLink src={order.renewal_file} className="file">
                      <span className="material-icons">description</span>
                      ใบแจ้งเตือนประกัน
                    </SecureLink>
                  ) : null}{" "}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default Table;
