import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FormGroup, ButtonRadius, SelectField } from "/src/components/form";
import { AlertNotification } from "../../../../components/alert/alertNotification";
import { TextField } from "~src/components/form";
import axios from "~src/helpers/configAxios";
import { CheckCircleOutline, Close } from "@mui/icons-material";
import { messages } from "~src/helpers/messages";

const SOURCE_TYPE = [
  { label: "line", value: "line" },
  { label: "facebook", value: "facebook" },
  { label: "google", value: "google" },
  { label: "priceza", value: "priceza" },
  { label: "แนะนำ", value: "suggest" },
  { label: "อื่นๆ", value: "other" },
];

const CreateLeadModal = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [email, setEmail] = useState("");
  const [year, setYear] = useState("");
  const [selectedSale, setSelectedSale] = useState("");
  const [carBrand, setCarBrand] = useState("");
  const [carModel, setCarModel] = useState("");
  const [subModel, setSubModel] = useState("");
  const [sumInsured, setSumInsured] = useState("");
  const [price, setPrice] = useState("");
  const [saleList, setSaleList] = useState(props.saleList);
  const [submitBtn, setSubmitBtn] = useState(false);

  const [alertText, setAlertText] = useState("ดำเนินการเสร็จสิ้น");
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertType, setAlertType] = useState("success");

  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const popUpAlert = (type, message) => {
    hideAlert();
    setIsAlertActive(true);
    setAlertType(type);
    setAlertText(message);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    setSubmitBtn(true);
    const url = `/api/leads/create_custom_lead`;

    axios.post(url, JSON.stringify(data)).then((response) => {
      if (response.data.content.success) {
        popUpAlert("success", "สร้าง lead สำเร็จ");
        setTimeout(() => {
          setSubmitBtn(false);
          window.location.href = "/lead";
        }, 1500);
      } else {
        setSubmitBtn(false);
        popUpAlert("error", "ไม่สามารถสร้าง lead ได้");
      }
    });
  };

  const handleChange = (evt, callback) => {
    const { value } = evt.target;
    callback(value);
  };

  const hideAlert = () => {
    setIsAlertActive(false);
  };

  const handleClick = () => {
    const url = "/api/customers/check_duplicate";

    axios.get(`${url}?phone=${mobileNumber}`).then((response) => {
      if (response && response.data.content.status && response.data.content.status == "found") {
        popUpAlert("success", "พบข้อมูลลูกค้า");
        if (response.data.content.first_name) {
          setFirstName(response.data.content.first_name);
        }

        if (response.data.content.last_name) {
          setLastName(response.data.content.last_name);
        }
      } else {
        popUpAlert("error", "ไม่พบข้อมูลลูกค้า");
      }
    });
    setTimeout(() => {
      hideAlert();
    }, 4000);
  };

  return (
    <div className="renew-modal">
      <div className="renew-modal-content">
        <button className="close" type="button" onClick={() => props.cbCloseModal()}>
          <span className="material-icons">close</span>
        </button>
        <div>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  เบอร์โทร
                  <span className="text-red-400">*</span>
                </label>
                <TextField
                  type="text"
                  className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                  inputRef={register(messages.required)}
                  error={!!errors.mobile_number && errors.mobile_number.message}
                  name="lead[mobile_number]"
                  value={mobileNumber}
                  onChange={(evt) => handleChange(evt, setMobileNumber)}
                />
              </div>

              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-20"></label>
                <ButtonRadius type="button" onClick={handleClick} color="primary">
                  ค้นหา
                </ButtonRadius>
              </div>
            </div>

            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  ชื่อ<span className="text-red-400">*</span>
                </label>
                <TextField
                  type="text"
                  className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                  inputRef={register(messages.required)}
                  error={!!errors.first_name && errors.first_name.message}
                  name="lead[first_name]"
                  value={firstName}
                  onChange={(evt) => handleChange(evt, setFirstName)}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2">
                  นามสกุล<span className="text-red-400">*</span>
                </label>
                <TextField
                  type="text"
                  className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                  inputRef={register(messages.required)}
                  error={!!errors.last_name && errors.last_name.message}
                  name="lead[last_name]"
                  value={lastName}
                  onChange={(evt) => handleChange(evt, setLastName)}
                />
              </div>
            </div>

            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2">
                  จากแหล่ง
                </label>
                <SelectField
                  name="lead[source_type]"
                  id="lead[source_type]"
                  className="border border-gray-400 rounded"
                  onChange={(evt) => handleChange(evt, setSourceType)}
                  options={SOURCE_TYPE}
                  value={sourceType}
                  inputRef={register}
                  style={{ height: "39px" }}
                />
              </div>

              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2">
                  ผู้รับผิดชอบ
                </label>
                <SelectField
                  name="lead[user_id]"
                  id="lead[user_id]"
                  className="border border-gray-400 rounded"
                  onChange={(evt) => handleChange(evt, setSelectedSale)}
                  options={saleList}
                  value={selectedSale}
                  inputRef={register}
                  style={{ height: "39px" }}
                />
              </div>
            </div>

            <FormGroup label="รายละเอียด">
              <div className="flex flex-wrap mb-3">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    อีเมลล์
                  </label>
                  <TextField
                    type="email"
                    className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                    name="lead[detail][email]"
                    value={email}
                    inputRef={register}
                    onChange={(evt) => handleChange(evt, setEmail)}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2">
                    ปี
                  </label>
                  <TextField
                    type="text"
                    className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                    name="lead[detail][year]"
                    value={year}
                    inputRef={register}
                    onChange={(evt) => handleChange(evt, setYear)}
                  />
                </div>
              </div>

              <div className="flex flex-wrap mb-3">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    ยี่ห้อรถ
                  </label>
                  <TextField
                    type="text"
                    className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                    name="lead[detail][car_brand]"
                    value={carBrand}
                    inputRef={register}
                    onChange={(evt) => handleChange(evt, setCarBrand)}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2">
                    รุ่น
                  </label>
                  <TextField
                    type="text"
                    className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                    name="lead[detail][car_model]"
                    value={carModel}
                    inputRef={register}
                    onChange={(evt) => handleChange(evt, setCarModel)}
                  />
                </div>
              </div>

              <div className="flex flex-wrap mb-3">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    รุ่นย่อย
                  </label>
                  <TextField
                    type="text"
                    className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                    name="lead[detail][sub_model]"
                    value={subModel}
                    inputRef={register}
                    onChange={(evt) => handleChange(evt, setSubModel)}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2">
                    ทุนประกัน
                  </label>
                  <TextField
                    type="text"
                    className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                    name="lead[detail][sum_insured]"
                    value={sumInsured}
                    inputRef={register}
                    onChange={(evt) => handleChange(evt, setSumInsured)}
                  />
                </div>
              </div>

              <div className="flex flex-wrap mb-3">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    ราคา
                  </label>
                  <TextField
                    type="text"
                    className="w-full px-2 py-2 border border-gray-400 rounded focus:border-pink-700"
                    name="lead[detail][price]"
                    value={price}
                    inputRef={register}
                    onChange={(evt) => handleChange(evt, setPrice)}
                  />
                </div>
              </div>
            </FormGroup>

            <div className="mt-4 text-center">
              <FormGroup>
                <ButtonRadius type="submit" color="primary" disabled={submitBtn}>
                  สร้างLead
                </ButtonRadius>
              </FormGroup>
            </div>
          </form>
        </div>
      </div>

      <AlertNotification
        className="bottom-0 right-0 px-4 py-3 m-5 mr-3"
        type={alertType}
        active={isAlertActive}
        setActive={setIsAlertActive}
        enterAnimation="slideInRight"
        exitAnimation="slideOutRight"
        closeDelay={3000}
      >
        <CheckCircleOutline className="mr-3" />
        <span className="inline-block align-bottom">{alertText}</span>
        <button className="ml-3" onClick={hideAlert}>
          <Close />
        </button>
      </AlertNotification>
    </div>
  );
};

export default CreateLeadModal;
