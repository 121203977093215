import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";

import locationJson from "../../../assets/database.json";

import { FormGroup } from "..";
import AutoComplete from "../autoCompleteAddress";

import { messages } from "../../../helpers/messages";
import { searchByText } from "../../../helpers/helpers";

interface LocationFormProps {
  address: AddressFormInputs;
  name: string;
  readOnly?: boolean;
  register?: any;
  errors?: any;
  onAddressChanged: (arg: any) => void;
}

interface AddressFormInputs {
  sub_district: string;
  district: string;
  province: string;
  postcode: string;
}

const LocationForm: React.FC<LocationFormProps> = ({
  address,
  name,
  readOnly = false,
  register,
  errors,
  onAddressChanged,
}) => {
  const [results, setResults] = useState([]);
  const [showDropDownSubDistrict, setShowDropdownSubDistrict] = useState(false);
  const [showDropDownDistrict, setShowDropdownDistrict] = useState(false);
  const [showDropDownProvince, setShowDropdownProvince] = useState(false);
  const [showDropDownZipcode, setShowDropdownZipcode] = useState(false);

  const handleAddressChanged = (evt, name) => {
    const { value } = evt.target;
    let result = [];
    let show = false;
    if (value != "") {
      result = searchByText(locationJson, value, [getAddressKey(name)]);
      show = true;
    } else {
      result = [];
      show = false;
    }
    switch (name) {
      case "sub_district":
        setShowDropdownSubDistrict(show);
        break;
      case "district":
        setShowDropdownDistrict(show);
        break;
      case "province":
        setShowDropdownProvince(show);
        break;
      case "postcode":
        setShowDropdownZipcode(show);
        break;
    }
    setResults(result);
    onAddressChanged({
      ...address,
      [name]: value,
    });
  };

  const cbData = (data) => {
    const objs = address;
    (objs.sub_district = data.sub_district),
      (objs.district = data.district),
      (objs.province = data.province),
      (objs.postcode = data.zipcode);

    onAddressChanged({
      ...address,
      ...objs,
    });
  };

  const cbOutsideClick = (status) => {
    setShowDropdownSubDistrict(status);
    setShowDropdownDistrict(status);
    setShowDropdownProvince(status);
    setShowDropdownZipcode(status);
  };

  const getAddressKey = (key) => {
    if (key === "postcode") return "zipcode";
    return key;
  };

  return (
    <>
      <div className="col-span-1">
        <FormGroup label="ตำบล" require>
          <AutoComplete
            data={results}
            onChange={(evt) => {
              handleAddressChanged(evt, "sub_district");
            }}
            name={`${name}[sub_district]`}
            show={showDropDownSubDistrict}
            cbOutsideClick={cbOutsideClick}
            cbData={cbData}
            value={address.sub_district}
            inputRef={register({
              ...messages.required,
              ...messages.validateSubDistrict,
            })}
            error={errors && _.get(errors, name)?.sub_district?.message}
            readOnly={readOnly}
          />
        </FormGroup>
      </div>
      <div className="col-span-1">
        <FormGroup label="อำเภอ" require>
          <AutoComplete
            data={results}
            onChange={(evt) => {
              handleAddressChanged(evt, "district");
            }}
            name={`${name}[district]`}
            show={showDropDownDistrict}
            cbOutsideClick={cbOutsideClick}
            cbData={cbData}
            value={address.district}
            inputRef={register({
              ...messages.required,
              ...messages.validateDistrict,
            })}
            error={errors && _.get(errors, name)?.district?.message}
            readOnly={readOnly}
          />
        </FormGroup>
      </div>
      <div className="col-span-1">
        <FormGroup label="จังหวัด" require>
          <AutoComplete
            data={results}
            onChange={(evt) => {
              handleAddressChanged(evt, "province");
            }}
            name={`${name}[province]`}
            show={showDropDownProvince}
            cbOutsideClick={cbOutsideClick}
            cbData={cbData}
            value={address.province}
            inputRef={register({
              ...messages.required,
              ...messages.validateProvince,
            })}
            error={errors && _.get(errors, name)?.province?.message}
            readOnly={readOnly}
          />
        </FormGroup>
      </div>
      <div className="col-span-1">
        <FormGroup label="รหัสไปรษณีย์" require>
          <AutoComplete
            data={results}
            maxLength={5}
            onChange={(evt) => {
              if (/^[0-9]*$/.test(evt.target.value)) handleAddressChanged(evt, "postcode");
            }}
            name={`${name}[postcode]`}
            show={showDropDownZipcode}
            cbOutsideClick={cbOutsideClick}
            cbData={cbData}
            value={address.postcode}
            maxLength={5}
            inputRef={register && register(messages.required)}
            error={errors && _.get(errors, name)?.postcode?.message}
            readOnly={readOnly}
          />
        </FormGroup>
      </div>
    </>
  );
};

export default LocationForm;
