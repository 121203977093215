import Decimal from "decimal.js";
import { useCallback, useEffect, useMemo, useState } from "react";

import { convertOrderResponse } from "./Orders";
import ProtectedAPIClient, {
  ProtectedAPIClientProps,
  useProtectedAPI,
  UseProtectedAPIOptions,
} from "./ProtectedAPIClient";
import { useAppContext } from "~src/context/App";
import { useAuthContext } from "~src/context/Auth";
import { PagedResults } from "~src/models/PagedResults";
import { Order } from "~src/types/order";
import { Quotation } from "~src/types/quotation";
import UserProfile from "~src/types/userProfile";

//TODO: Revise API interfaces later
export interface GetDashboardInfoResponse {
  profile: UserProfile;
  summary: DashboardInfoSummaryResponse;
  expiring_quotations: PagedResults<Quotation>;
  expiring_orders: PagedResults<Order>;
  expiring_renewals: PagedResults<Order>;
  promotions: any[];
  trainings: any[];
}

export interface DashboardInfoSummaryResponse {
  next_payout_date: string | Date;
  premium_by_yearly: number | Decimal;
  commission_by_yearly: number | Decimal;
  commission_to_pay: number | Decimal;
}

export interface DashboardAPIClientProps extends ProtectedAPIClientProps { }

export default class DashboardAPIClient extends ProtectedAPIClient<DashboardAPIClientProps> {
  constructor(props: DashboardAPIClientProps) {
    super(props);
  }

  public async getDashboardInfo(): Promise<GetDashboardInfoResponse> {
    try {
      const url = this.getEndpointURL("dashboard");

      const resp = await this.withAbort((abc) =>
        this._axios.get<GetDashboardInfoResponse>(url, {
          signal: abc.signal,
        })
      );

      const result = resp.data;

      //TODO: Convert Quotes?
      result.expiring_orders = {
        ...resp.data.expiring_orders,
        content: resp.data.expiring_orders.content.map((o) => ({
          ...o,
          ...convertOrderResponse(o),
        })),
      };
      result.expiring_renewals = {
        ...resp.data.expiring_renewals,
        content: resp.data.expiring_renewals.content.map((o) => ({
          ...o,
          ...convertOrderResponse(o),
        })),
      };

      return result;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }
}

export const defaultDashboardAPIFactory = (props: DashboardAPIClientProps) =>
  new DashboardAPIClient(props);

export interface UseDashboardAPIOptions {
  apiFactory?: (props: DashboardAPIClientProps) => DashboardAPIClient;
  onError?: (err: Error) => void;
}

export interface UseDashboardAPIState {
  dashboardAPI?: DashboardAPIClient;
  error?: Error;
}

export function useDashboardAPI(opts: UseDashboardAPIOptions = {}): UseDashboardAPIState {
  const { onError } = opts;

  const apiFactory = useCallback(
    (baseProps: ProtectedAPIClientProps) => {
      const apiProps = { ...baseProps };

      if (opts.apiFactory) {
        return opts.apiFactory(apiProps);
      }

      return defaultDashboardAPIFactory(apiProps);
    },
    [opts.apiFactory]
  );

  const {
    api: dashboardAPI,
    error,
    ...otherState
  } = useProtectedAPI<DashboardAPIClientProps, DashboardAPIClient>({
    apiFactory,
    onError,
  });

  return { ...otherState, dashboardAPI, error };
}
