import React from "react";

import { useHistory } from "react-router-dom";
import { ButtonRadius } from "/src/components/form";
import { useTranslation } from "react-i18next";
import "./error.scss";

const ErrorPage = ({ message = "", mainPagePath = "/motors" }) => {
  let history = useHistory();
  const { t } = useTranslation();

  function gotoMainPage() {
    history.push({
      pathname: mainPagePath,
    });
  }

  return (
    <div className="handle-error">
      <div className="error-img col-md-5">
        <img src="https://storage.googleapis.com/asia.artifacts.gm-gettmore-project.mtb.co.th/images/Error-img.png" />
      </div>
      <p className="header-text">
        ขออภัยครับ ดูเหมือนอาจเกิดปัญหาบางอย่างเกี่ยวกับระบบ {t("fullAppName")}
      </p>
      <p style={{ display: "none" }}>{message}</p>
      <p className="support-text">
        ทีม {t("support")} กำลังเร่งแก้ปัญหานี้อยู่ และจะสามารถใช้งานปกติในเร็วๆ นี้ครับ
      </p>

      {mainPagePath && (
        <div className="back-button">
          <ButtonRadius onClick={(e) => gotoMainPage()}>กดที่นี่เพื่อกลับหน้าหลัก</ButtonRadius>
        </div>
      )}

      <p className="contact-text ">
        ถ้าต้องการความช่วยเหลือจากเรา สามารถติดต่อ {t("support")} <br /> โทร.
        {t("phoneNumberFormat")} เวลา {t("timeToContact")} ({t("dayToContact")}) หรือ LINE:{" "}
        {t("lineAccount")}
      </p>
    </div>
  );
};

export default ErrorPage;
