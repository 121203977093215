import React from "react";
import Cookies from "js-cookie";
import { isBrowser, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ButtonRadius } from "../form";
import lineLogo from "../../../assets/contact/logo-line.png";

interface Props {
  productType: string;
  selectedType?: string;
  make?: string;
  model?: string;
  year?: string;
  province?: string;
  info?: string;
}
const ContactCard: React.FC<Props> = ({
  productType,
  selectedType,
  make,
  model,
  year,
  province,
  info,
}) => {
  const { t } = useTranslation();
  const username = Cookies.get("username");
  const lineLink = t("lineLink", { returnObjects: true });

  const messageInLine = (type: string): string => {
    switch (type) {
      case "travel":
        return `ขอราคาประกันเดินทาง ${info}`;
      default:
        return `${username} ขอราคารถชั้น ${selectedType} รถรุ่น${make} ${model} ปี${year} จังหวัด${province}`;
    }
  };

  const handleClickContactLine = () => {
    let mobileContactLineUrl = lineLink.mobileUrl;
    const lineParams = encodeURIComponent(messageInLine(productType));
    if (process.env.APP_NAME === "hero") mobileContactLineUrl += `?${lineParams}`;
    const browserContactLineUrl = lineLink.url;

    if (isBrowser) window.open(browserContactLineUrl, "_blank");
    if (isMobile) window.open(mobileContactLineUrl, "_blank");
  };

  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex">
      <div className="bg-white mt-16 p-16 rounded flex-1 items-center shadow-lg">
        <div className="text-center my-60 flex-col">
          <p>
            ไม่เจอแผนประกัน <br />
            ที่คุณต้องการรึเปล่าครับ ?
          </p>
          <p className="my-10">ติดต่อเรา เพื่อขอราคาได้</p>
          {t("buttonLine") && (
            <div className="flex justify-center mt-30">
              <ButtonRadius onClick={handleClickContactLine}>
                <img src={lineLogo} className="h-30 mr-1" alt="line" />
                หาแผนไม่เจอ
              </ButtonRadius>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ContactCard;
