import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthenticate } from "~src/helpers/authen";
import { Button, ButtonRadius } from "/src/components/form";
import TABLE, { THEAD, TBODY, TR, TH, TD } from "/src/components/table";

import { useAppContext } from "~src/context/App";
import axios from "/src/helpers/configAxios";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import yaml from "/src/data";

import "./quotationList.scss";

const QuotationList = (props) => {
  const { t } = useTranslation();
  const { showPaidCommission } = useAuthenticate();
  const { quotation_id, quotes, idx, currentDate, expiredDate } = props;
  const [successPlaceOrder, setSuccessPlaceOrder] = useState(false);
  const [orderId, setOrderId] = useState("");

  // TODO: Refactor/Consolidate each part to use its own API/Context
  const { appConfig } = useAppContext();

  const products = quotes.map((q) => {
    q.total_price = function () {
      const result = this.selling_price - this.discount;
      if (this.addon == undefined) {
        return result;
      }
      return parseFloat(result) + parseFloat(this.addon.selling_price);
    };
    return q;
  });

  function placeOrder(quotesId) {
    const url = `${appConfig.apiURL}/quotations/place_order`;
    const data = {
      quotation_id,
      quote_ids: quotesId,
    };
    axios.post(url, JSON.stringify(data)).then((response) => {
      setOrderId(response.data.content.order_id);
      setSuccessPlaceOrder(true);
    });
  }

  if (successPlaceOrder) {
    return <Redirect to={`/checkout/${orderId}/step-1`} />;
  }
  return (
    <>
      <div className="grid-expand-table-grid-th">
        <div />
        <div>บริษัทประกัน</div>
        <div>แผนประกัน</div>
        <div style={{ textAlign: "right" }}>พ.ร.บ.</div>
        <div style={{ textAlign: "right" }}>เบี้ยประกันทั้งหมด</div>
        <div style={{ textAlign: "right" }}>
          {showPaidCommission ? "ส่วนลดลูกค้า" : ""}
        </div>
        <div style={{ textAlign: "right" }}>เบี้ยประกันที่ต้องชำระ</div>
        <div style={{ textAlign: "right" }}>
          {showPaidCommission ? "ค่าตอบแทนสุทธิ" : ""}
        </div>
        <div />
      </div>
      {products.map((q, index) => (
        <div className="grid-expand-table-grid-tbody" key={`${idx}-${index}`}>
          <div>
            <img src={q.insurer_icon} />
          </div>
          <div>{q.insurer_name}</div>
          <div>{`${yaml.policy_type[q.policy_type]} ${
            yaml.repair_facility[q.repair_facility]
          }`}</div>
          <div style={{ textAlign: "right" }}>
            {q.addon === undefined ? (
              <span>-</span>
            ) : (
              <span>{formatNumberWithCurrency(q.addon.selling_price)}</span>
            )}
          </div>
          <div style={{ textAlign: "right" }}>
            {parseInt(q.discount) === 0 ? (
              <div>{formatNumberWithCurrency(q.selling_price)}</div>
            ) : (
              <s className="text-gray-500">{formatNumberWithCurrency(q.selling_price)}</s>
            )}
          </div>
          <div style={{ textAlign: "right" }}>
            {showPaidCommission ? formatNumberWithCurrency(q.discount) : ""}
          </div>
          <div style={{ textAlign: "right" }}>
            <b className="text-secondary">{formatNumberWithCurrency(q.total_price())}</b>
          </div>
          
          <div style={{ textAlign: "right" }}>
            {showPaidCommission ? (<b>{formatNumberWithCurrency(q.commission_total)}</b>) : ""}
          </div>
          <div>
            <ButtonRadius
              className="mr-1 close-deal-and-buy-btn"
              color="primary"
              size="xs"
              onClick={() => placeOrder(q.id)}
              disabled={currentDate > expiredDate}
              fullwidth
            >
              <i className="mr-1 icon-shop" />
              ซื้อเลย
            </ButtonRadius>
          </div>
        </div>
      ))}
    </>
  );
};

export default QuotationList;
