import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { ContractTable, ContractViewModal } from "~src/features/contracts";

const ContractListPage = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" component="h1">
          สัญญา / ข้อกำหนดและเงื่อนไข
        </Typography>

        <Button href="/staffs/contracts/create" variant="contained" disableElevation>
          สร้างสัญญา
        </Button>
      </Box>

      <ContractTable />
      <ContractViewModal />
    </Box>
  );
};

export default ContractListPage;
