import React from "react";
import InsurerDetail from "~src/components/card/InsurerDetail";
import InsurerImg from "~src/components/card/InsurerImg";

type InsurancePlanSummaryProps = {
  productId: number;
  insurerImg: string;
  insurerName: string;
  productName: string;
  totalSummary: string | number;
  criteriaSummaryInfo: { [key: string]: string | number };
  priceSummaryInfo: { [key: string]: string | number };
  onCallback?: () => void;
};

const InsurancePlanSummary3: React.FC<InsurancePlanSummaryProps> = ({
  insurerImg,
  insurerName,
  productName,
  criteriaSummaryInfo,
  priceSummaryInfo,
  totalSummary,
  onCallback,
}): JSX.Element => {
  return (
    <div className="w-full flex flex-col items-center shadow-md bg-white gap-3">
      <div className="w-full bg-primary text-base text-white p-4 text-center">
        สรุปแผนประกันที่คุณเลือก
      </div>

      <div className="w-full flex flex-col gap-3 p-3">
        <div className="flex flex-row gap-7 items-center">
          <InsurerImg
            style={{ width: "60px", height: "60px" }}
            src={insurerImg}
            alt={insurerName}
          />
          <InsurerDetail name={insurerName} productName={productName} />
        </div>

        <div className="w-full px-4">
          <button
            type="button"
            className="text-xs flex text-gray-600 w-full px-7 py-1 items-center justify-between bg-gray-100 rounded-48 border border-gray-300 border-solid"
            onClick={onCallback}
          >
            <i className="material-icons text-sm">notes</i>
            อ่านรายละเอียดความคุ้มครอง
          </button>
        </div>

        <div className="w-full">
          {Object.keys(criteriaSummaryInfo).map((key) => (
            <div
              key={`criteria-summary-info-${key}`}
              className="grid grid-cols-2 px-4 pb-1 font-normal"
            >
              <div>{key}</div>
              <div className="text-right">{criteriaSummaryInfo[key]}</div>
            </div>
          ))}
        </div>

        <hr className="w-full mb-0" />

        <div className="w-full">
          {Object.keys(priceSummaryInfo).map((key) => (
            <div
              key={`price-summary-info-${key}`}
              className="grid grid-cols-2 px-4 pb-1 font-light"
            >
              <div>{key}</div>
              <div className="text-right">{priceSummaryInfo[key]}</div>
            </div>
          ))}
        </div>

        <hr className="w-full mb-0" />

        <div className="w-full grid grid-cols-2 px-4 pb-1 text-lg font-medium">
          <div className="">เบี้ยประกันที่ต้องชำระ</div>
          <div className="text-right">{totalSummary}</div>
        </div>
      </div>
    </div>
  );
};

export default InsurancePlanSummary3;
