import React from "react";

export interface ProductTypeIconProps {
  type: string;
}

export default function ProductTypeIcon({ type }: ProductTypeIconProps) {
  switch (type) {
    case "motor":
      return <i className="icon icon-3-Motor"></i>;
    case "motorbike":
      return <i className="icon icon-3-motorbike"></i>;
    case "travel":
      return <i className="icon icon-3-travel"></i>;
    case "fire":
      return <i className="icon icon-3-fire"></i>;
    case "marine":
      return <i className="icon icon-3-marine"></i>;
    case "home":
      return <i className="icon icon-3-House"></i>;
    case "sme":
      return <i className="icon icon-3-SME"></i>;
    case "iar":
      return <i className="icon icon-3-IAR"></i>;
    case "golfer":
      return <i className="icon icon-3-golf"></i>;
    case "engineer":
      return <i className="icon icon-3-engineer"></i>;
    case "personalaccident":
      return <i className="icon icon-3-PA"></i>;
    case "health":
      return <i className="icon icon-3-health"></i>;
    case "cancer":
      return <i className="icon icon-3-cancer"></i>;
    case "miscellaneous":
      return <i className="icon icon-3-misc"></i>;
    default:
      break;
  }

  return <i className="icon gettmore-icon-policylist"></i>;
}
