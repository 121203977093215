import React from "react";
import {
  Card,
  CardContent,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow as MuiTableRow,
  Box,
  Switch,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  openEditModal,
  selectBannerData,
  selectBannerFetchStatus,
  updateData,
} from "../bannerSlice";
import {
  fetchBanners,
  fetchDeleteBanner,
  fetchPublishBanner,
  fetchSorterBanner,
} from "../apis/banners";

const Table = () => {
  const data = useAppSelector(selectBannerData);
  const status = useAppSelector(selectBannerFetchStatus);
  const dispach = useAppDispatch();

  React.useEffect(() => {
    dispach(fetchBanners());
  }, []);

  const handleOpenEditModal = (id: number) => {
    dispach(openEditModal({ id }));
  };

  const handlePublish = (id: number) => {
    dispach(fetchPublishBanner({ id }));
  };

  const handleDelete = (id: number) => {
    if (window.confirm("Are you sure you want to delete?")) {
      dispach(fetchDeleteBanner({ id }));
    }
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRow = data[dragIndex];
    const newRows = [...data];
    newRows.splice(dragIndex, 1);
    newRows.splice(hoverIndex, 0, dragRow);
    buildSortedData(newRows);
  };

  const buildSortedData = (newRows: any[]) => {
    let params = [] as { id: number; sort: number }[];
    let total = newRows.length - 1;
    newRows.forEach((o, index) => {
      params.push({ id: o.id, sort: total - index });
    });

    dispach(updateData(newRows));
    dispach(fetchSorterBanner(params));
  };

  const TableRow: React.FC<any> = ({ row, index, moveRow }: any) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "ROW",
      item: { index },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: "ROW",
      drop: (item: { index: number }) => {
        moveRow(item.index, index);
      },
      canDrop: () => true,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }));

    const opacity = isDragging ? 0.5 : 1;
    const backgroundColor = isOver ? "#eee" : "transparent";

    return (
      <MuiTableRow ref={(node) => drag(drop(node))} style={{ opacity, backgroundColor }}>
        <TableCell>
          <Box component="img" src={row.image} alt="" sx={{ maxWidth: "100%" }} />
        </TableCell>
        <TableCell>{row.title}</TableCell>
        <TableCell>{row.created_at}</TableCell>
        <TableCell>{row.updated_at}</TableCell>
        <TableCell>
          <Switch checked={row.status} onClick={() => handlePublish(row.id)} />
        </TableCell>
        <TableCell>
          <Tooltip title="Edit" arrow>
            <IconButton size="small" onClick={() => handleOpenEditModal(row.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <IconButton color="error" size="small" onClick={() => handleDelete(row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </MuiTableRow>
    );
  };

  return (
    <Card sx={{ mt: 3 }} variant="outlined">
      <CardContent>
        <DndProvider backend={HTML5Backend}>
          <MuiTable>
            <TableHead>
              <MuiTableRow>
                <TableCell sx={{ width: 200 }}>Image</TableCell>
                <TableCell>Title</TableCell>
                <TableCell sx={{ width: 200 }}>Created Date</TableCell>
                <TableCell sx={{ width: 200 }}>Updated Date</TableCell>
                <TableCell sx={{ width: 200 }}>Publish</TableCell>
                <TableCell sx={{ width: 100 }}>Actions</TableCell>
              </MuiTableRow>
            </TableHead>
            <TableBody>
              {status === "fulfilled" &&
                data.map((row, index) => (
                  <TableRow key={row.id} row={row} index={index} moveRow={moveRow} />
                ))}
            </TableBody>
          </MuiTable>
        </DndProvider>
      </CardContent>
    </Card>
  );
};

export default Table;
