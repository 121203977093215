module.exports = {
  "appName": "hero",
  "fullAppName": "gettgo hero",
  "lineAccount": "@gettgohero",
  "contactMail": "hero@gettgo.com",
  "support": "Hero Supports",
  "insuererCmiSearch": "safe",
  "backgroundLogin": "https://storage.googleapis.com/asia.artifacts.gm-gettmore-project.mtb.co.th/hero-bg.jpg",
  "backgroundHome": "https://storage.googleapis.com/asia.artifacts.gm-gettmore-project.mtb.co.th/hero-bg-home.jpg",
  "commisionCMIRate": "(10%)",
  "maxDateCoverage": "60",
  "maxChassisNumber": 17,
  "qrLinePath": "/assets/contact/hero-qr-line.jpg",
  "phoneNumberToContract": "020266387",
  "phoneNumberFormat": "02-026-6387",
  "timeToContact": "9.00 - 18.00 น.",
  "dayToContact": "จันทร์ - ศุกร์",
  "defaultAttachedStatus": "attached",
  "lineLink": {
    "name": "@gettgohero",
    "url": "https://line.me/R/ti/p/%40pho5745a#~",
    "mobileUrl": "https://line.me/R/oaMessage/@gettgohero/"
  },
  "dataToContract": [
    {
      "contact": "02-026-6387 กด 1",
      "info": null
    }
  ],
  "insuredCategory": [
    {
      "label": "บุคคลธรรมดา",
      "value": "person"
    },
    {
      "label": "นิติบุคคล",
      "value": "legal_entity"
    }
  ]
};