import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { Icon, IconName } from "../../icons";

interface MenulistIconProps {
  icon: IconName;
  label?: string;
  link?: string;
  active?: boolean;
  hidden?: boolean;
}

const MenulistIcon: React.FC<MenulistIconProps> = ({ icon, label, link, active, hidden = false }) => {
  return (
    <>
      {!hidden && (
        <li>
          <Link to={link}>
            <div
              className={cn(
                "flex flex-col items-center py-3 px-1",
                "hover:bg-white hover:bg-opacity-25",
                active ? "bg-secondary" : "bg-primary"
              )}
            >
              <Icon name={icon} color="white" width={28} height={28} />
              <span className="mt-2 text-xs font-hairline text-center text-white">{label}</span>
            </div>
          </Link>
        </li>
      )}
    </>
  );
};

const MenulistIconExternal: React.FC<MenulistIconProps> = ({ icon, label, link, active, hidden = false }) => {
  return (
    <>
      {!hidden && (
        <li>
          <a href={link} target="_blank" rel="noreferrer">
            <div
              className={cn(
                "flex flex-col items-center py-3 px-1",
                "hover:bg-white hover:bg-opacity-25",
                active ? "bg-primary-color" : "bg-secondary-color"
              )}
            >
              <Icon name={icon} color="white" width={28} height={28} />
              <span className="mt-2 text-xs font-hairline text-center text-white">{label}</span>
            </div>
          </a>
        </li>
      )}
    </>
  );
};

export { MenulistIcon, MenulistIconExternal };
