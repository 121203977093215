import React from "react";
import { UseFormMethods } from "react-hook-form";
import { FormGroup, TextField } from "~/src/components/form";
import useAddress, { AddressResult } from "../hooks/useAddress";
import { CustomerAddress } from "~src/types/customerInfo";
import { messages } from "~src/helpers/messages";
import { get } from "lodash";

interface DropDownProps {
  data: any[];
  onSelect?: (v: AddressResult) => void;
}

const DropDown: React.FC<DropDownProps> = ({ data, onSelect }) => {
  return (
    <ul className="location-search-input-items">
      {data.map((o, i) => (
        <li key={i}>
          <button type="button" onClick={() => onSelect(o)}>
            {o.sub_district}
            <i className="material-icons">arrow_right</i>
            {o.district}
            <i className="material-icons">arrow_right</i>
            {o.province}
            <i className="material-icons">arrow_right</i>
            {o.zipcode}
          </button>
        </li>
      ))}
    </ul>
  );
};

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

interface Props
  extends Partial<
    Pick<
      UseFormMethods,
      "register" | "errors" | "control" | "setValue" | "clearErrors" | "getValues"
    >
  > {
  name: string;
  initialValue: CustomerAddress;
  readOnly?: boolean;
}

const Address: React.FC<Props> = ({
  name,
  initialValue,
  errors,
  register,
  setValue,
  clearErrors,
  getValues,
  readOnly = false,
}) => {
  const ref = React.useRef();

  const {
    result,
    showDropdown,
    setShowDropdown,
    handleSearchSubDistric,
    handleSearchDistric,
    handleSearchProvince,
    handleSearchZipCode,
  } = useAddress();

  useOutsideClick(ref, () => {
    if (showDropdown !== "") {
      setShowDropdown("");
    }
  });

  const namePrefix = `${name}${name && "."}`;

  React.useEffect(() => {
    if (result.length === 0) setShowDropdown("");
  }, [result]);

  React.useEffect(() => {
    setValue(`${namePrefix}address`, initialValue.address);
    setFieldValues(initialValue);
  }, [initialValue]);

  const handleSetField = (data: any) => {
    setFieldValues(data);
    setShowDropdown("");
  };

  const handleChangeAddress = (e) => {
    setValue(`${namePrefix}address`, e.target.value);
  };

  const handleChangeSubDistrict = (e) => {
    handleSearchSubDistric(e);
    setValue(`${namePrefix}sub_district`, e.target.value);
  };

  const handleChangeDistrict = (e) => {
    handleSearchDistric(e);
    setValue(`${namePrefix}district`, e.target.value);
  };

  const handleChangeProvince = (e) => {
    handleSearchProvince(e);
    setValue(`${namePrefix}province`, e.target.value);
  };

  const handleChangeZipCode = (e) => {
    if (e.target.value.match(/^[0-9]*$/g) || e.target.value === "") {
      handleSearchZipCode(e);
      setValue(`${namePrefix}postcode`, e.target.value);
    }
  };

  const setFieldValues = (data: any) => {
    const postcode = get(data, "postcode");
    setValue(`${namePrefix}sub_district`, data.sub_district);
    setValue(`${namePrefix}district`, data.district);
    setValue(`${namePrefix}province`, data.province);
    setValue(`${namePrefix}postcode`, postcode || data.zipcode);

    clearErrors(`${namePrefix}sub_district`);
    clearErrors(`${namePrefix}district`);
    clearErrors(`${namePrefix}province`);
    clearErrors(`${namePrefix}postcode`);
  };

  return (
    <>
      <FormGroup label="ที่อยู่ / บริษัท" className="col-span-4" require>
        <TextField
          inputRef={register(messages.required)}
          name={`${namePrefix}address`}
          onChange={handleChangeAddress}
          error={errors?.address?.message}
          readOnly={readOnly}
        />
      </FormGroup>
      <FormGroup label="ตำบล" className="col-span-2" require>
        <div ref={ref} className="relative">
          <TextField
            inputRef={register({ ...messages.required, ...messages.validateSubDistrict })}
            name={`${namePrefix}sub_district`}
            onChange={handleChangeSubDistrict}
            error={errors?.sub_district?.message}
            readOnly={readOnly}
          />
          {showDropdown === "sub_district" && <DropDown data={result} onSelect={handleSetField} />}
        </div>
      </FormGroup>
      <FormGroup label="อำเภอ" className="col-span-2" require>
        <div ref={ref} className="relative">
          <TextField
            inputRef={register({ ...messages.required, ...messages.validateDistrict })}
            name={`${namePrefix}district`}
            onChange={handleChangeDistrict}
            error={errors?.district?.message}
            readOnly={readOnly}
          />
          {showDropdown === "district" && <DropDown data={result} onSelect={handleSetField} />}
        </div>
      </FormGroup>
      <FormGroup label="จังหวัด" className="col-span-2" require>
        <div ref={ref} className="relative">
          <TextField
            inputRef={register({ ...messages.required, ...messages.validateProvince })}
            name={`${namePrefix}province`}
            onChange={handleChangeProvince}
            error={errors?.province?.message}
            readOnly={readOnly}
          />
          {showDropdown === "province" && <DropDown data={result} onSelect={handleSetField} />}
        </div>
      </FormGroup>
      <FormGroup label="รหัสไปรษณีย์" className="col-span-2" require>
        <div ref={ref} className="relative">
          <div className="location-search">
            <TextField
              inputRef={register({ ...messages.required, ...messages.validate })}
              name={`${namePrefix}postcode`}
              onChange={handleChangeZipCode}
              error={errors?.postcode?.message}
              maxLength="5"
              pattern="[0-9]*"
              readOnly={readOnly}
            />
            {showDropdown === "zipcode" && <DropDown data={result} onSelect={handleSetField} />}
          </div>
        </div>
      </FormGroup>
    </>
  );
};

export default Address;
