import { isArray } from "lodash";

const useBasicFormInfo = (props) => {
  const { insureds_attributes } = props;
  const attrPrefixInsured = "order[insureds_attributes][]";
  const attrPrefixBenef = "order[insureds_attributes][][beneficiary_attributes]";
  const attrPrefixDeliveryAddress = "order[delivery_address_attributes][]";

  const formatAddressesInformation = (data) => {
    data.order.insureds_attributes.addresses_attributes = [
      {
        ...(({ address, sub_district, district, province, postcode }) => ({
          address,
          sub_district,
          district,
          province,
          postcode,
        }))(data.order.insureds_attributes),
        id: insureds_attributes?.addresses[0].id,
        address_type: "insured",
      },
      data.order.delivery_address_attributes
        ? {
            ...(({ address, sub_district, district, province, postcode }) => ({
              address,
              sub_district,
              district,
              province,
              postcode,
            }))(data.order.delivery_address_attributes),
            id: insureds_attributes?.addresses[1].id,
            address_type: "delivery",
          }
        : {
            ...(({ address, sub_district, district, province, postcode }) => ({
              address,
              sub_district,
              district,
              province,
              postcode,
            }))(data.order.insureds_attributes),
            id: insureds_attributes?.addresses[1].id,
            address_type: "delivery",
          },
    ];
  };

  const formatInsuredDeliveryType = (data) => {
    if (isArray(data.order.insureds_attributes.delivery_type)) {
      data.order.insureds_attributes.delivery_type =
        data.order.insureds_attributes.delivery_type.reduce(
          (a: string, b: string) => parseInt(a) + parseInt(b),
          0
        );
    }
  };

  const getTextDetailForm = (productType: string) => {
    switch (productType) {
      case "motor":
        return {
          title: "ข้อมูลผู้ถือกรมธรรม์",
          subTitle:
            "** ผลิตภัณฑ์นี้ใช้สำหรับรถยนต์ส่วนบุคคลเท่านั้น ไม่รองรับรถยนต์ที่นำไปใช้ในเชิงพาณิชย์",
        };
      case "vaccine":
        return {
          title: "วัคซีน",
          subTitle:
            "** ผลิตภัณฑ์นี้ใช้สำหรับรถยนต์ส่วนบุคคลเท่านั้น ไม่รองรับรถยนต์ที่นำไปใช้ในเชิงพาณิชย์",
        };
      case "travel":
        return {
          title: "กรอกข้อมูลผู้เดินทาง",
          subTitle:
            "** กรุณากรอกข้อมูลชื่อและนามสกุลเป็นภาษาอังกฤษเท่านั้น ผู้ซื้อต้องมีถื่นฐานอยู่ในปนะเทศ ต้องเริ่มเดอนทางจากประเทศไทย และสิ้นสุดที่ประเทศไทยเท่านั้น",
        };
      default:
        return {
          title: "ข้อมูลผู้ถือกรมธรรม์",
          subTitle: "",
        };
    }
  };

  const getDeliveryAddress = (insured) => {
    return insured.addresses && insured.addresses[1] ? insured.addresses[1] : null;
  };

  return {
    attrPrefixInsured,
    attrPrefixBenef,
    attrPrefixDeliveryAddress,
    formatAddressesInformation,
    formatInsuredDeliveryType,
    getTextDetailForm,
    getDeliveryAddress,
  };
};

export default useBasicFormInfo;
