import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormGroup, ButtonRadius, SelectField, DatePickerField } from "/src/components/form";
import { messages } from "~src/helpers/messages";
import { toJsDate, dateFormat } from "~src/helpers/dateTime";
import axios from "~/src/helpers/configAxios";
import { TextareaField } from "~src/pages/staff/training/components/form";
import { AlertNotification } from "../../../../components/alert/alertNotification";
import { CheckCircleOutline, Close } from "@mui/icons-material";

const ALLOW_LEADS_STATUS = [
  { label: "ติดตาม", value: "follow_up" },
  { label: "สำเร็จ", value: "yes_sale" },
  { label: "ไม่สำเร็จ", value: "no_sale" },
  { label: "ไม่รับสาย", value: "callback" },
  { label: "รอชำระค่าเบี้ย", value: "waiting_for_payment" },
  { label: "ลูกค้าไม่ต้องการให้ติดต่อ", value: "do_not_contact" },
  { label: "ข้อมูลไม่อัพเดท", value: "not_update" },
  { label: "ข้อมูลซ้ำ", value: "duplicate" },
];

const TIME_HOURS = [...Array(24)].map((elementInArray, index) => ({
  label: ("00" + (index + 1)).slice(-2),
  value: ("00" + (index + 1)).slice(-2),
}));
const TIME_MINS = [...Array(60)].map((elementInArray, index) => ({
  label: ("00" + (index + 1)).slice(-2),
  value: ("00" + (index + 1)).slice(-2),
}));
const FOLLOWUP_TYPE = [
  { label: "ลูกค้าดูแบบผ่อนเงินสด", value: "looking_for_installment" },
  { label: "รอตัดสินใจ", value: "waiting_for_decision" },
  { label: "ส่งใบเสนอราคาใบแล้ว", value: "sent_quotation" },
  { label: "ยังไม่หมดประกัน", value: "insurance_covering" },
  { label: "อื่นๆ", value: "other" },
];
const CALLBACK_TYPE = [
  { label: "ไม่รับสาย1", value: "callback1" },
  { label: "ไม่รับสาย2", value: "callback2" },
  { label: "ไม่รับสาย3", value: "callback3" },
  { label: "ไม่รับสาย4", value: "callback4" },
  { label: "ไม่รับสาย5", value: "callback5" },
  { label: "รอโทรนัดใหม่", value: "waiting_for_call" },
];
const NOSALE_TYPE = [
  { label: "โปรโมชั่นที่อื่นดีกว่า", value: "looking_for_better_promotion" },
  { label: "ค่าเบี้ยสูงเกินไป", value: "cost_expensive" },
  { label: "ต่อรายเดือนแบบเดิม", value: "continue_on_monthly" },
  { label: "ไปต่อที่อื่นแล้ว", value: "buy_other_broker" },
];
const NOTUPDATE_TYPE = [
  { label: "ขายรถแล้ว", value: "sold_vehicle" },
  { label: "เบอร์ติดต่อไม่ได้", value: "cannot_contact" },
  { label: "เบอร์ผิด", value: "invalid_phone" },
];

const UpdateStatusModal = (props) => {
  const [status, setStatus] = useState(props.status);
  const [leadId, setLeadId] = useState(props.leadId);
  const [isShowFollowUpDate, setIsShowFollowUpDate] = useState(false);
  const [isShowCallback, setIsShowCallback] = useState(false);
  const [isShowNoSale, setIsShowNoSale] = useState(false);
  const [isShowNotUpdate, setIsShowNotUpdate] = useState(false);

  const [followupDate, setFollowupDate] = useState("");
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [remark, setRemark] = useState("");
  const [subStatus, setSubStatus] = useState("");
  const { register, watch, errors, control, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const [submitBtn, setSubmitBtn] = useState(false);
  const [alertText, setAlertText] = useState("ดำเนินการเสร็จสิ้น");
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertType, setAlertType] = useState("success");

  const handleChangeStatus = (value) => {
    setStatus(value);

    if (value == "follow_up") {
      setIsShowFollowUpDate(true);
      setIsShowCallback(false);
      setIsShowNoSale(false);
      setIsShowNotUpdate(false);
    } else if (value == "callback") {
      setIsShowCallback(true);
      setIsShowFollowUpDate(false);
      setIsShowNoSale(false);
      setIsShowNotUpdate(false);
    } else if (value == "no_sale") {
      setIsShowNoSale(true);
      setIsShowCallback(false);
      setIsShowFollowUpDate(false);
      setIsShowNotUpdate(false);
    } else if (value == "not_update") {
      setIsShowNotUpdate(true);
      setIsShowNoSale(false);
      setIsShowCallback(false);
      setIsShowFollowUpDate(false);
    } else {
      setIsShowNotUpdate(false);
      setIsShowNoSale(false);
      setIsShowCallback(false);
      setIsShowFollowUpDate(false);
    }
  };

  useEffect(() => {
    handleChangeStatus(status);
  }, [status]);

  const handleChangeRemark = (evt) => {
    const { value } = evt.target;
    setRemark(value);
  };

  const handleChange = (evt, callback) => {
    const { value } = evt.target;
    callback(value);
  };

  const handleChangeField = (evt) => {
    const { value } = evt.target;
    setFollowupDate(toJsDate(value));
  };

  const hideAlert = () => {
    setIsAlertActive(false);
  };

  const popUpAlert = (type, message) => {
    hideAlert();
    setIsAlertActive(true);
    setAlertType(type);
    setAlertText(message);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    setSubmitBtn(true);
    const url = `/api/leads/${leadId}/update_status`;

    axios.post(url, JSON.stringify(data)).then((response) => {
      if (response.data.content.success) {
        popUpAlert("success", "อัพเดทข้อมูลสำเร็จ");
        setTimeout(() => {
          setSubmitBtn(false);
          window.location.reload();
        }, 1500);
      } else {
        setSubmitBtn(false);
        popUpAlert("error", "อัพเดทข้อมูลไม่สำเร็จ");
      }
    });
  };

  return (
    <div className="renew-modal">
      <div className="renew-modal-content">
        <button className="close" type="button" onClick={() => props.cbCloseModal()}>
          <span className="material-icons">close</span>
        </button>
        <div>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup label="สถานะ">
              <SelectField
                name="lead[status]"
                id="lead[status]"
                inputRef={register(messages.required)}
                error={!!errors.lead && !!errors.lead.status && errors.lead.status.message}
                onChange={(e) => handleChangeStatus(e.target.value)}
                options={ALLOW_LEADS_STATUS}
                value={status}
              />
            </FormGroup>

            {isShowFollowUpDate && (
              <>
                <FormGroup label="วันที่ติดตาม">
                  <DatePickerField
                    type="text"
                    id="lead[followup_date]"
                    name="lead[followup_date]"
                    defaultView="decade"
                    onChange={handleChangeField}
                    inputRef={register(messages.required)}
                    error={
                      !!errors.lead &&
                      !!errors.lead.followup_date &&
                      errors.lead.followup_date.message
                    }
                    value={dateFormat(new Date())}
                  />
                </FormGroup>

                <FormGroup label="เวลาที่ติดตาม">
                  <div>
                    <SelectField
                      name="lead[hour]"
                      id="lead[hour]"
                      inputRef={register(messages.required)}
                      error={!!errors.lead && !!errors.lead.hour && errors.lead.hour.message}
                      onChange={(evt) => handleChange(evt, setHour)}
                      options={TIME_HOURS}
                      value={hour}
                      style={{ width: "30%", display: "inline-block", marginRight: "10px" }}
                    />
                    :
                    <SelectField
                      name="lead[minute]"
                      id="lead[minute]"
                      inputRef={register(messages.required)}
                      error={!!errors.lead && !!errors.lead.minute && errors.lead.minute.message}
                      onChange={(evt) => handleChange(evt, setMinute)}
                      options={TIME_MINS}
                      value={minute}
                      style={{ width: "30%", display: "inline-block", marginLeft: "10px" }}
                    />
                  </div>
                </FormGroup>

                <FormGroup label="ประเภทการติดตาม">
                  <SelectField
                    name="lead[sub_status]"
                    id="lead[sub_status]"
                    inputRef={register(messages.required)}
                    error={
                      !!errors.lead && !!errors.lead.subStatus && errors.lead.subStatus.message
                    }
                    onChange={(evt) => handleChange(evt, setSubStatus)}
                    options={FOLLOWUP_TYPE}
                    value={subStatus}
                  />
                </FormGroup>
              </>
            )}

            {isShowCallback && (
              <FormGroup label="ประเภทการโทรกลับ">
                <SelectField
                  name="lead[sub_status]"
                  id="lead[sub_status]"
                  inputRef={register(messages.required)}
                  error={!!errors.lead && !!errors.lead.subStatus && errors.lead.subStatus.message}
                  onChange={(evt) => handleChange(evt, setSubStatus)}
                  options={CALLBACK_TYPE}
                  value={subStatus}
                />
              </FormGroup>
            )}

            {isShowNoSale && (
              <FormGroup label="ประเภทการปฏิเสธ">
                <SelectField
                  name="lead[sub_status]"
                  id="lead[sub_status]"
                  inputRef={register(messages.required)}
                  error={!!errors.lead && !!errors.lead.subStatus && errors.lead.subStatus.message}
                  onChange={(evt) => handleChange(evt, setSubStatus)}
                  options={NOSALE_TYPE}
                  value={subStatus}
                />
              </FormGroup>
            )}

            {isShowNotUpdate && (
              <FormGroup label="ประเภทการไม่อัพเดท">
                <SelectField
                  name="lead[sub_status]"
                  id="lead[sub_status]"
                  inputRef={register(messages.required)}
                  error={!!errors.lead && !!errors.lead.subStatus && errors.lead.subStatus.message}
                  onChange={(evt) => handleChange(evt, setSubStatus)}
                  options={NOTUPDATE_TYPE}
                  value={subStatus}
                />
              </FormGroup>
            )}

            {[
              "follow_up",
              "yes_sale",
              "no_sale",
              "callback",
              "waiting_for_payment",
              "do_not_contact",
              "not_update",
              "duplicate",
            ].includes(status) && (
              <FormGroup label="หมายเหตุ">
                <TextareaField
                  name="lead[remark]"
                  inputRef={register}
                  onChange={handleChangeRemark}
                />
              </FormGroup>
            )}

            <div className="mt-4 text-center">
              <FormGroup>
                <ButtonRadius type="submit" color="primary" disabled={submitBtn}>
                  อัพเดท
                </ButtonRadius>
              </FormGroup>
            </div>
          </form>
        </div>
      </div>

      <AlertNotification
        className="bottom-0 right-0 px-4 py-3 m-5 mr-3"
        type={alertType}
        active={isAlertActive}
        enterAnimation="slideInRight"
        exitAnimation="slideOutRight"
        closeDelay={3000}
      >
        <CheckCircleOutline className="mr-3" />
        <span className="inline-block align-bottom">{alertText}</span>
        <button className="ml-3" onClick={hideAlert}>
          <Close />
        </button>
      </AlertNotification>
    </div>
  );
};

export default UpdateStatusModal;
