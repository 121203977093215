import Decimal from "decimal.js";

import { GetOrderResponse } from "~src/api/Orders";
import { CheckoutSummaryState } from "~src/models/CheckoutState";
import { Order } from "~src/types/order";
import { Quote } from "~src/types/quote";
import { formatNumberWithCurrency } from "./formatNumber";

export function getCheckoutSummaryStateFromOrderResponse(
  orderResponse: GetOrderResponse
): CheckoutSummaryState {
  const order = orderResponse.content;
  const { addon_selling_price, checked_addon, installment_info: installmentInfo } = orderResponse;

  const { number: orderNumber, quotation, quotes, insureds, payments_summary } = order;
  const { product_type: productType } = quotation;
  const {
    sum_selling_price,
    sum_discount,
    commission_gain,
    credit_card_fee,
    payment_total_amount,
    payment_total_amount_included_cc_fee,
    estimated_main_withholding_tax,
    estimated_addon_withholding_tax,
  } = payments_summary;

  let mainQuote: Partial<Quote> | undefined;
  let addonQuote: Partial<Quote> | undefined;

  mainQuote = quotes[0];
  if (mainQuote.addon) {
    addonQuote = mainQuote.addon;
  } else {
    addonQuote = { selling_price: "0" };
  }

  const result: CheckoutSummaryState = {
    orderNumber,
    productType,
    installmentInfo,
    subTitle: quotation.criteria?.insurance_class,
    sumSellingPrice: new Decimal(sum_selling_price).toFixed(2), // formatNumberWithCurrency(sum_selling_price, ""),
    discount: new Decimal(sum_discount).toFixed(2), // formatNumberWithCurrency(sum_discount, ""),
    commission: new Decimal(commission_gain).toFixed(2), // formatNumberWithCurrency(commission_gain, ""),
    creditCardFee: new Decimal(credit_card_fee).toFixed(2), // formatNumberWithCurrency(credit_card_fee, ""),
    paymentTotalAmount: new Decimal(payment_total_amount).toFixed(2), // formatNumberWithCurrency(payment_total_amount, ""),
    paymentTotalAmountIncludedCCFee: new Decimal(payment_total_amount_included_cc_fee).toFixed(2),
    // formatNumberWithCurrency(
    //   payment_total_amount_included_cc_fee,
    //   ""
    // ),
    sellingPrice: mainQuote.selling_price,
    sellingPriceAddon: addonQuote.selling_price,
    policyType: mainQuote.policy_type,
    estimateWhTax: estimated_main_withholding_tax,
    estimateAddonWhTax: estimated_addon_withholding_tax,
  };

  let majorityQuote: Quote | undefined;
  let carDefinition: string | undefined;
  switch (productType) {
    case "motor":
      majorityQuote = quotes[0];
      Object.assign(result, {
        productId: majorityQuote.product_id,
        insurerCode: majorityQuote.insurer_code,
        insurerName: majorityQuote.insurer_name,
        insurerIcon: majorityQuote.insurer_icon,
        title: majorityQuote.product_name,
        repairFacility: majorityQuote.repair_facility,
      });
      const { criteria } = quotation;
      carDefinition = `${criteria?.car_brand} ${criteria?.car_model} ปี ${criteria?.year}`;
      result.details = carDefinition;
      break;
    case "travel":
      majorityQuote = quotes[0];
      Object.assign(result, {
        productId: majorityQuote.product_id,
        insurerCode: majorityQuote.insurer_code,
        insurerName: majorityQuote.insurer_name,
        insurerIcon: majorityQuote.insurer_icon,
        title: majorityQuote.product_name,
      });

      result.details = quotation.short_desc_criteria;
      break;
  }

  return result;
}
