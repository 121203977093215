import React from "react";
import { useHistory } from "react-router-dom";

const QuotationHeader = () => {
  const history = useHistory();
  return (
    <div className="create-quotation-header items-center">
      <div className="row">
        <div className="col-md-3">
          <button type="button" className="back-to-search" onClick={() => history.goBack()}>
            <span className="material-icons">arrow_right_alt</span>
            กลับไปเลือกแผนอื่น
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuotationHeader;
