import React from "react";
import locationJson from "~/src/assets/database.json";
import { searchByText } from "~src/helpers/helpers";

export type AddressResult = {
  district: string;
  district_code?: number;
  province: string;
  province_code?: number;
  sub_district_code?: number;
  sub_district: string;
  zipcode: number | string;
};
type AddressType = "province" | "zipcode" | "district" | "sub_district";

const useAddress = () => {
  const [result, setResult] = React.useState<any[]>([]);
  const [showDropdown, setShowDropdown] = React.useState<AddressType | "">("");

  const handleSearchSubDistric = React.useCallback((e: any) => {
    setResult(searchAddressType("sub_district", e.target.value));
    setShowDropdown("sub_district");
  }, []);

  const handleSearchDistric = React.useCallback((e: any) => {
    setResult(searchAddressType("district", e.target.value));
    setShowDropdown("district");
  }, []);

  const handleSearchProvince = React.useCallback((e: any) => {
    setResult(searchAddressType("province", e.target.value));
    setShowDropdown("province");
  }, []);

  const handleSearchZipCode = React.useCallback((e: any) => {
    setResult(searchAddressType("zipcode", e.target.value));
    setShowDropdown("zipcode");
  }, []);

  const searchAddressType = (addressType: AddressType, value: string) => {
    let searchResult = [];
    if (value !== "") searchResult = searchByText(locationJson, value, [addressType]);

    return searchResult;
  };

  return {
    result,
    showDropdown,
    setShowDropdown,
    handleSearchSubDistric,
    handleSearchProvince,
    handleSearchZipCode,
    handleSearchDistric,
  };
};

export default useAddress;
