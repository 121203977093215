import React from "react";
import { Home } from "@mui/icons-material";

const Complete: React.FC = () => {
  return (
    <div className="flex min-h-screen bg-city">
      <div className="m-auto grid text-center">
        <span className="text-lg px-4 md:text-xl font-thin md:px-0">
          การสมัครเสร็จสมบูรณ์แล้ว ขอบคุณที่ร่วมเป็นส่วนหนึ่งกับเรานะครับ
          <br />
          โดยเราจะส่ง Username & Password สำหรับเข้าใช้งานระบบ Gettgo Hero
          <br />
          ผ่านทางอีเมล์ที่ให้ไว้ ภายใน 2 วันทำการครับ
          <br />
        </span>
        <span className="mt-8 px-4 text-md md:text-lg md:px-0 font-thin">
          สามารถติดต่อสอบถามและติดตามข้อมูลข่าวสารต่างๆ จากเราได้ที่นี่
          <br />
          <a href="https://bit.ly/Linegettgohero" target="_blank" className="underline">
            https://bit.ly/Linegettgohero
          </a>
        </span>
        <div>
          <button
            className={`mx-2 mt-8 px-3 py-2 transition duration-200 text-white text-lg bg-primary-color rounded-lg hover:bg-primary-color-dark w-64`}
            type="button"
            onClick={() => (window.location.href = window.location.origin)}
          >
            <Home className="mr-2" />
            กลับสู่หน้าแรก
          </button>
        </div>
      </div>
    </div>
  );
};

export default Complete;
