import React from "react";
import SearchIcon from "@mui/icons-material/Search";

interface Props {
  value: string;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
}

const Search: React.FC<Props> = ({ onSubmit, onInputChange, value }) => {
  return (
    <form onSubmit={onSubmit} className="relative">
      <input
        type="text"
        name="keyword"
        placeholder="ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่กรมธรรม์"
        className="px-3 py-2 border rounded-10 focus:border-secondary focus:outline-none"
        style={{ width: 350 }}
        onChange={onInputChange}
        value={value}
      />
      <button type="submit" className="absolute text-gray-500 top-2 right-2 focus:outline-none">
        <SearchIcon />
      </button>
    </form>
  );
};

export default Search;
