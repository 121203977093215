import React, { useState, useRef } from "react";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import ButtonToggleDropdown from "./ButtonToggleDropdown";
import Option from "./Option";

const DropDownList = ({ label, options, active, onChangeValue }) => {
  const buttonRef = useRef();
  const dropDownRef = useRef();
  const [isToggleDropdown, setToggleDropdown] = useState(false);

  useOnClickOutside(dropDownRef, (event) => {
    if (!buttonRef.current.contains(event.target)) {
      setToggleDropdown(false);
    }
  });

  const handleToggleDropdown = () => {
    setToggleDropdown(!isToggleDropdown);
  };

  return (
    <div>
      <div className="select-component">
        <ButtonToggleDropdown
          buttonRef={buttonRef}
          active={active}
          label={label}
          onClick={handleToggleDropdown}
        />
        {isToggleDropdown && (
          <div className="select-component-list fix-width-2 right" ref={dropDownRef}>
            {options.map((option) => (
              <Option
                key={`${option.label}-${option.value}`}
                label={option.label}
                value={option.value}
                checked={option.isChecked}
                onChangeValue={onChangeValue}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDownList;
