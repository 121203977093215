import React, { createContext, useContext } from "react";
import AppConfig from "~src/config/AppConfig";

export interface AppContextProps {
  appConfig: AppConfig;
  assets: Record<string, any>;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);
export default AppContext;

export interface AppContextProviderProps {
  baseConfig?: Partial<AppConfig>;
  assets: Record<string, any>;
}

export function AppContextProvider({
  baseConfig,
  assets,
  children,
}: React.PropsWithChildren<AppContextProviderProps>) {
  const appConfig = new AppConfig(baseConfig);
  console.log("Using appConfig:", appConfig);

  return <AppContext.Provider value={{ appConfig, assets }}>{children}</AppContext.Provider>;
}

export function useAppContext(): AppContextProps {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("AppContext provider not found");
  }

  return context;
}
