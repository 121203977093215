import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "/src/components/form/button/Button"

interface Props {
  headerText?: string;
  bodyText: string;
  show: boolean;
  handleClose: () => void;
}

const InfoModal: React.FC<Props> = ({
  headerText,
  bodyText,
  show,
  handleClose
}) => {

  return (
    <Dialog 
      open={true} 
      aria-labelledby="responsive-dialog-title"
    >
      <div className="min-w-45r">
        { headerText &&
          <DialogTitle id="responsive-dialog-title">
            {headerText}
          </DialogTitle>}

        <DialogContent>
          <DialogContentText>
            {bodyText}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button 
            id="close-button-modal"
            size="xs"
            variant="none"
            color="primary"
            onClick={handleClose}
          >
            ปิด
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default InfoModal