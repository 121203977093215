import React, { useState, useEffect, useContext } from "react";
import { parseDate, dateFormat } from "/src/helpers/dateTime";
import idCardFormat from "/src/helpers/idCardFormat";
import renderLocation from "/src/helpers/renderLocation";
import { Group, Header, List } from "../SummaryList";
import { checkEmpty } from "/src/helpers/helpers";

import CheckoutContext from "/src/context/Checkout";

const Insured = ({ insured, category, includeWhTax }) => {
  const {
    company_name,
    company_title,
    company_tax,
    title,
    firstname,
    lastname,
    birthday,
    idcard,
    phone_number,
    office_phone_number,
    email,
    address,
    province,
    district,
    sub_district,
    postcode,
    beneficiary,
  } = insured;

  const { state: checkoutState, dispatch } = useContext(CheckoutContext);
  const insured_fullname = () => {
    return firstname === undefined
      ? `${title} ${firstname} ${lastname}`
      : `${title} ${company_name}`;
  };

  useEffect(() => {
    dispatch({
      type: "ADD",
      payload: {
        ...checkoutState,
        insured_category: category,
        order: {
          ...checkoutState.order,
          include_wh_tax: includeWhTax,
        },
      },
    });
  }, []);

  return (
    <Group>
      <Header
        icon={<span className="icon icon-document" />}
        label="ข้อมูลผู้เอาประกัน"
        color="secondary"
      />
      <List label="ประเภทผู้เอาประกัน" value={category == "person" ? "บุคคลธรรมดา" : "นิติบุคคล"} />
      {category == "legal_entity" ? (
        <>
          <List label="ชื่อบริษัทหรือองค์กร" value={`${company_title} ${company_name}`} />
          <List label="เลขประจำตัวผู้เสียภาษีของบริษัท/องค์กร" value={idCardFormat(company_tax)} />
        </>
      ) : null}

      {process.env.APP_NAME != "mti" && category == "legal_entity" ? (
        <Header
          icon={<span className="icon icon-document" />}
          label="ข้อมูลผู้เอาประกันเพิ่มเติม (บุคคล)"
          color="secondary"
        />
      ) : null}
      <List label="ชื่อ-นามสกุล ผู้เอาประกัน" value={`${title} ${firstname} ${lastname}`} />
      <List
        label="วัน เดือน ปีเกิด"
        value={
          category == "legal_entity"
            ? "-"
            : checkEmpty(dateFormat(parseDate(birthday), "dd/MM/yyyy"))
        }
      />
      <List label="เบอร์มือถือ" value={checkEmpty(phone_number)} />
      <List label="อีเมล" value={checkEmpty(email)} />
      <List label="เลขที่บัตรประชาชน" value={checkEmpty(idCardFormat(idcard))} />

      <List
        label="ที่อยู่"
        value={renderLocation({
          address,
          province,
          district,
          sub_district,
          postcode,
        })}
      />
      <List
        label="ผู้รับผลประโยชน์"
        value={
          beneficiary.receiver === "legal_heir"
            ? `ทายาทโดยธรรม`
            : beneficiary.full_name
        }
      />
    </Group>
  );
};

export default Insured;
