import React, { Fragment, useContext, useState, useMemo, useEffect } from "react";
import _ from "lodash";

import { TextField, Checkbox } from "/src/components/form";
import { useTranslation } from "react-i18next";
import { useAuthenticate } from "~src/helpers/authen";

import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "/src/helpers/formatNumber";
import multipleQuotationContext from "/src/context/MultipleQuotation";

import { messages } from "/src/helpers/messages";

import yaml from "/src/data";

const InsurerDetails = (props) => {
  const { t } = useTranslation();
  const { showPaidCommission } = useAuthenticate();
  const { packageObjs, setupQuotesData, errors, register } = props;
  const [state, dispatch] = useContext(multipleQuotationContext);
  const maxCompare = 3;
  const emptyCompareSlot = [];
  for (let i = 0; i < maxCompare - packageObjs.length; i++) {
    emptyCompareSlot.push(<div key={i} className="col-md-3" />);
  }

  const [discountValue, setDiscountValue] = useState([]);
  const [addonValue, setAddonValue] = useState([]);
  const [isAddOns, setIsAddons] = useState([]);
  const [totals, setTotals] = useState([]);
  const [showCheckCmi, setShowCheckCmi] = useState([]);

  const INSURERS_NOT_HAS_CMI = [];

  useEffect(() => {
    const addons = [...addonValue];
    const is_addons = [...isAddOns];
    const discounts = [...discountValue];
    const totalsList = [...totals];
    const showCheckCmis = [...showCheckCmi];
    packageObjs.map((p, i) => {
      const insurerNotHasCmi = INSURERS_NOT_HAS_CMI.includes(p.insurer_code);
      addons[i] = insurerNotHasCmi ? 0 : p?.cmi?.selling_price;
      is_addons[i] = !insurerNotHasCmi;
      showCheckCmis[i] = !insurerNotHasCmi;
      discounts[i] = 0;
      totalsList[i] = calulate(p.selling_price, addons[i], discounts[i]);

      setUpQuote(p.id, discounts[i], !insurerNotHasCmi, i);
    });
    setDiscountValue(discounts);
    setAddonValue(addons);
    setIsAddons(is_addons);
    setTotals(totalsList);
    setShowCheckCmi(showCheckCmis);
  }, [packageObjs]);

  const handleChangeDiscount = (e, index) => {
    const discounts = [...discountValue];
    const totalsList = [...totals];

    discounts[index] = e.target.value;
    totalsList[index] = calulate(
      packageObjs[index].selling_price,
      addonValue[index],
      discounts[index]
    );

    setUpQuote(packageObjs[index].id, discounts[index], isAddOns[index], index);

    setDiscountValue(discounts);
    setTotals(totalsList);
  };

  const handleToggleAddon = (index) => {
    const is_addons = [...isAddOns];
    const addon_values = [...addonValue];
    const discounts = [...discountValue];
    const totalsList = [...totals];

    is_addons[index] = !isAddOns[index];
    if (!is_addons[index]) {
      addon_values[index] = 0;
    } else {
      addon_values[index] = packageObjs[index].cmi?.selling_price;
    }

    setUpQuote(packageObjs[index].id, discounts[index], is_addons[index], index);

    totalsList[index] = calulate(
      packageObjs[index].selling_price,
      addon_values[index],
      discounts[index]
    );

    setIsAddons(is_addons);
    setAddonValue(addon_values);
    setTotals(totalsList);
  };

  function setUpQuote(id, discount, isAddon, index) {
    const quote = {
      product_id: id,
      discount,
      addon: isAddon ? "checked" : "",
    };

    return setupQuotesData(quote, index);
  }

  function calulate(selling_price, addons, discount) {
    const discountValue = discount !== "" ? discount : 0;
    return parseFloat(selling_price) + parseFloat(addons) - parseFloat(discountValue);
  }

  function renderCoverages(objectKey, obj, coveragesData) {
    switch (objectKey) {
      case "sum_insured":
        return formatNumberWithCurrency2(obj.sum_insured);
      case "repair_facility":
        return obj.details.repair_facility === "garage" ? "อู่" : "ศูนย์";
      case "passengers_in_vehicle_covered":
        return `${coveragesData}  คน`;
      default:
        return formatNumberWithCurrency2(coveragesData);
    }
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-3">
          <ul className="multiple-products-info">
            <li>{state.descriptions_insurer?.[0].header?.[0]}</li>
          </ul>
        </div>
        {packageObjs.map((product, index) => (
          <div key={index} className="col-md-3">
            <ul className="multiple-products-info">
              <li className="justify-content-center">
                <div className="img">
                  <img src={product.insurer_icon} />
                </div>
                <div>
                  <div className="text-base">{product.insurer_name}</div>
                  <div className="inline text-sm">
                    {`${yaml.policy_type[product.policy_type]} ${
                      yaml.repair_facility[product.details.repair_facility]
                    }`}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        ))}
      </div>

      <div className="row align-items-center">
        <div className="col-md-3">
          <ul className="multiple-products-info">
            <li>{state.descriptions_insurer?.[0].header?.[1]}</li>
          </ul>
        </div>
        {packageObjs.map((product, index) => (
          <div key={index} className="col-md-3">
            <ul className="multiple-products-info">
              <li className="coverages-value font-bold">
                {formatNumberWithCurrency(product.selling_price)}
              </li>
            </ul>
          </div>
        ))}
      </div>

      <div className="row align-items-center">
        <div className="col-md-3">
          <ul className="multiple-products-info">
            <li>{state.descriptions_insurer?.[0].header?.[2]}</li>
          </ul>
        </div>
        {packageObjs.map((product, index) => (
          <div key={index} className="col-md-3">
            <ul className="multiple-products-info">
              <li className="coverages-value">
                <div style={{ maxWidth: "195px" }}>
                  <TextField
                    style={{ textAlign: "center" }}
                    type="number"
                    name={`discount-${index}`}
                    id={`discount-${index}`}
                    value={discountValue[index]}
                    min="0"
                    step="0.01"
                    onChange={(e) => handleChangeDiscount(e, index)}
                    inputRef={register({
                      ...messages.required,
                      ...messages.floatingValid,
                      ...messages.maximumNumber(product.max_discount),
                    })}
                    error={!!errors[`discount-${index}`] && errors[`discount-${index}`].message}
                  />
                </div>
              </li>
            </ul>
          </div>
        ))}
      </div>

      {showPaidCommission && (
        <div className="row align-items-center">
          <div className="col-md-3">
            <ul className="multiple-products-info">
              <li>
                {state.descriptions_insurer?.[0].header?.[3]}
                <small>ค่าตอบแทนภาคสมัครใจ หักภาษี 5%</small>
              </li>
            </ul>
          </div>
          {packageObjs.map((product, index) => (
            <div key={index} className="col-md-3">
              <ul className="multiple-products-info">
                <li className="coverages-value">
                  {formatNumberWithCurrency(product.max_discount)}
                </li>
              </ul>
            </div>
          ))}
        </div>
      )}

      <div className="row align-items-center">
        <div className="col-md-3">
          <ul className="multiple-products-info">
            <li>{state.descriptions_insurer?.[0].header?.[4]}</li>
          </ul>
        </div>
        {packageObjs.map((product, index) => (
          <div key={index} className="col-md-3">
            <ul className="multiple-products-info">
              <li className="coverages-value">
                <div className="items-center">
                  {showCheckCmi[index] && (
                    <Checkbox
                      name="remove_cptl"
                      label={`ซื้อพร้อม พ.ร.บ. (${formatNumberWithCurrency(
                        product.cmi?.selling_price
                      )})`}
                      onChange={() => handleToggleAddon(index)}
                      // onInput={() => toggleCmiRequired()}
                      defaultChecked
                    />
                  )}
                </div>
              </li>
            </ul>
          </div>
        ))}
      </div>

      <div className="row align-items-center">
        <div className="col-md-3">
          <ul className="multiple-products-info">
            <li>
              {state.descriptions_insurer?.[0].header?.[5]}
              <small>รวมภาษีและอากรแสตมป์</small>
            </li>
          </ul>
        </div>
        {packageObjs.map((product, index) => (
          <div key={index} className="col-md-3">
            <ul className="multiple-products-info">
              <li className="coverages-value align-items-center">
                <div className="text-primary font-bold text-xl">
                  {formatNumberWithCurrency(totals[index])}
                </div>
                <div className="text-gray-600 font-medium text-sm ml-2">(ต่อปี)</div>
              </li>
            </ul>
          </div>
        ))}
      </div>

      {state.descriptions_coverages.map((description, index) => (
        <Fragment key={index}>
          <h2 className="text-lg">{description.header}</h2>
          <div className="row">
            <div className="col-md-3 custom-col">
              <ul className="multiple-products-cover">
                {description.subHeaders.map((subHeader, i) => (
                  <li key={`sub-${index}-${i}`}>{subHeader}</li>
                ))}
              </ul>
            </div>
            {packageObjs.map((o, k) => (
              <div key={`list-${index}-${k}`} className="col-md-3 custom-col">
                <ul className="multiple-products-cover">
                  {description.allowKeys.map((objectKey, ind) => (
                    <li key={`list-${index}-${k}-${ind}`} className="coverages-value">
                      {renderCoverages(objectKey, o, o.coverages[objectKey])}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {emptyCompareSlot}
          </div>
        </Fragment>
      ))}
    </>
  );
};

export default InsurerDetails;
