import React from "react";
import cn from "classnames";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';

type Role = "header" | "value" | "footer";
type ContentSize = "small" | "medium";

interface Props {
  role?: Role;
  values?: string[];
  isLoading?: boolean;
  contentSize?: ContentSize;
  yearMonth?: any;
  tooltip?: string[];
  onClick?: (value: string) => void;
}

export const TableRow: React.FC<Props> = ({
  role = "value",
  values,
  isLoading,
  contentSize = "medium",
  yearMonth,
  onClick,
  tooltip
}) => {
  const configuredClass: Record<Role, string> = {
    header: "bg-gray-300 text-gray-600 border-gray-500",
    value: "bg-white border-gray-100",
    footer:
      "bg-white border-gray-100 border-l font-bold text-primary-color p-5",
  };
  const configuedAlignmentClass: Record<Role, string> = {
    header: "text-center",
    value: "text-right",
    footer: "text-right"
  }
  const sizeClass: Record<ContentSize, string> = {
    small: "w-95",
    medium: "w-190"
  };

  const CustomTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      fontSize: 13
    }
  }))(Tooltip);

  return (
    <div className="flex flex-1 text-xs text-gray-800">
      {isLoading ? (
        <>
          <div className="sticky left-0 flex shadow-lg">
            <>
              <div
                className={cn(
                  "flex w-3 border text-left px-3 h-5",
                  configuredClass[role],

                )}
              >
                <div className="flex-1 m-1 bg-gray-400 animate-pulse" />
              </div>
              <div
                className={cn(
                  "flex w-24 border text-left px-3 h-5",
                  configuredClass[role]
                )}
              >
                <div className="flex-1 m-1 bg-gray-400 animate-pulse" />
              </div>
            </>
          </div>
          {Array.from({ length: 26 }).map((_, idx) => (
            <div
              key={idx}
              className={cn(
                "flex w-20 border text-right px-1 h-5",
                configuredClass[role],
                configuedAlignmentClass[role]
              )}
            >
              <div className="flex-1 m-1 bg-gray-400 animate-pulse" />
            </div>
          ))}
          <div className="sticky right-0 flex shadow-lg">
            <div
              className={cn(
                "flex w-20 border text-right px-3 h-5 tracking-wider",
                configuredClass[role],
                configuedAlignmentClass[role]
              )}
            >
              <div className="flex-1 m-1 bg-gray-400 animate-pulse" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="sticky left-0 flex shadow-lg">
            <>
              <div
                className={cn(
                  "w-190 border text-left p-1",
                  configuredClass[role]
                )}
              >
                {tooltip && tooltip?.[0] !== "" ? (
                  <CustomTooltip title={tooltip?.[0]} arrow>
                    <div>{values?.[0]}</div>
                  </CustomTooltip>
                ) : (
                  <div>{values?.[0]}</div>
                )}
              </div>
              <div
                className={cn(
                  "w-150 border text-left p-1",
                  configuredClass[role]
                )}
              >
                {tooltip && tooltip?.[1] !== "" ? (
                  <CustomTooltip title={tooltip?.[1]} arrow>
                    <div>{values?.[1]}</div>
                  </CustomTooltip>
                ) : (
                  <div>{values?.[1]}</div>
                )}
              </div>
            </>
          </div>
          {values.slice(2, values.length - 1).map((v, idx) => (
            <div key={idx}>
              {role === "footer" && v != "-" ? (
                <a
                  className="cursor-pointer"
                  onClick={() => onClick(yearMonth[idx])}
                >
                  <div
                    className={cn(
                      "border p-1 tracking-wider",
                      configuredClass[role],
                      sizeClass[contentSize],
                      configuedAlignmentClass[role],
                    )}
                  >
                    {tooltip && tooltip?.[idx + 2] ? (
                      <CustomTooltip title={tooltip?.[idx + 2]} arrow>
                        <div>{v}</div>
                      </CustomTooltip>
                    ) : (
                      <div>{v}</div>
                    )}
                  </div>
                </a>
              ) : (
                <div
                  className={cn(
                    "border p-1 tracking-wider",
                    configuredClass[role],
                    sizeClass[contentSize],
                    configuedAlignmentClass[role]
                  )}
                >
                  {tooltip && tooltip?.[idx + 2] !== "" ? (
                    <CustomTooltip title={tooltip?.[idx + 2]} arrow>
                      <div>{v}</div>
                    </CustomTooltip>
                  ) : (
                    <div>{v}</div>
                  )}
                </div>
              )}
            </div>
          ))}
          <div className="sticky right-0 flex shadow-lg">
            <div
              className={cn(
                "w-150 border text-right p-1",
                configuredClass[role]
              )}
            >
              {tooltip && tooltip?.[tooltip.length - 1] !== "" ? (
                <CustomTooltip title={tooltip?.[tooltip.length - 1]} arrow>
                  <div>{values?.[values.length - 1]}</div>
                </CustomTooltip>
              ) : (
                <div>{values?.[values.length - 1]}</div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
