import React from "react";

import "./badges.scss";
import { useState } from "react";

const Badges = ({ children, status, module = "order", onClick = undefined }) => {
  function renderDetails(status) {
    if (module == "order") {
      switch (status) {
        case "failed_payment":
          return (
            <span className={`badges info`}>
              * หากเป็นรายการชำระแล้ว <br /> โปรดติดต่อเจ้าหน้าที่เพื่อตรวจสอบ
            </span>
          );
        case "waiting_verify":
          return (
            <div className="download-pay-slip">
              <i className={`material-icons slip-icon`}>perm_media</i>
              <a role="button" onClick={() => setShowPayslipModal(!isShowPayslipModal)}>
                <u>ใบสลิปการโอน</u>
              </a>
            </div>
          );
        default:
          return "";
      }
    }
  }

  return (
    <>
      <div className={`badges ${status}`} onClick={onClick}>
        {children}
      </div>
      {renderDetails(status)}
    </>
  );
};

export default Badges;
