import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E6007E",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#54C1BB",
      dark: "#54ABC1",
      contrastText: "#FFFFFF",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
  },
  chip: {
    backgroundColor: "#DFDFDF",
    color: "#868686",
  },
  input: {
    backgroundColor: "#f3f0f0",
  },
  typography: {
    fontSize: 13,
    fontFamily: "Prompt",
    subtitle1: {
      fontSize: "2rem",
      fontWeight: 500,
      color: "#E60398",
    },
    h1: {
      fontSize: "1.8rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.4rem",
      fontWeight: 500,
    },
  },
});

export default theme;
