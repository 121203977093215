import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "~src/components/form/button";
import { messages } from "~src/helpers/messages";

import { Order } from "~src/types/order";
import { Payment } from "~src/types/payment";
// import { formatNumberWithCurrency } from "/src/helpers/formatNumber.js";

import "./paymentsAlertModal.scss";

export interface PaymentsAlertModalProps {
  messages: string[];
  onClose: () => void;
  order: Order;
  icon?: string;
  id?: string;
  payment?: Payment;
  title?: string;
  renderActions?: () => React.ReactNode;
}

export default function PaymentsAlertModal({
  messages,
  onClose,
  order,
  icon = "warning",
  id = "creditCardErrorModal",
  payment,
  title = "ERROR",
  renderActions,
}: PaymentsAlertModalProps) {
  const { t } = useTranslation();

  const renderDefaultButtons = useCallback(
    () => (
      <div className="result-button-block flex flex-row w-full justify-center">
        <Button color="primary" role="button" classNameOption="btn-close" onClick={() => onClose()}>
          ปิด
        </Button>
        {/* <input type="submit" value="Send Error Report" /> */}
      </div>
    ),
    [onClose]
  );

  return (
    <div id={id} className="payments-alert-modal modal">
      <div className="modal-overlay"></div>
      <div className="modal-box">
        <div className="header">
          {icon && (
            <div className="icon">
              <i className="error-icon material-icons">{icon}</i>
            </div>
          )}
          <h2>{title}</h2>
          <div className="buttons">
            <button className="material-icons close-icon" role="button" onClick={() => onClose()}>
              close
            </button>
          </div>
        </div>
        <br />
        <form className="error-report-form">
          <div className="alert-details flex flex-col w-full">
            <input type="hidden" name="order_id" value={order.order_id} />
            <input type="hidden" name="payment[token]" value={payment?.token} />
            <input type="hidden" name="messages" value={messages.join("\n")} />
            <ul className="message-lines">
              {messages.map((msg) => (
                <li key={msg}>{msg}</li>
              ))}
            </ul>
          </div>
          <div className="alert-footer flex flex-col w-full justify-center text-center items-stretch gap-3">
            <hr />
            <p className="flex flex-row justify-center text-gray-400">
              หากต้องการความช่วยเหลือ สามารถติดต่อได้ที่ Line: {t("lineAccount")} หรือ{" "}
              {t("contactMail")}
            </p>
            <div className="result-button-block flex flex-row w-full justify-center gap-x-2">
              <Button
                color="primary"
                role="button"
                classNameOption="btn-close"
                onClick={() => onClose()}
              >
                ปิด
              </Button>
              {/* <input type="submit" value="Send Error Report" /> */}
              {renderActions && renderActions()}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
