import React, { useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { messages } from "~src/helpers/messages";
import { deliveryAddressTypeOptions, deliveryTypeOptions } from "~src/helpers/options";
import { Checkbox, Switcher } from "../form";
import { Insured } from "~src/types/insured";
import { getInsuredDeliveryTypeAsArray, getDeliveryTypeAsNumber } from "~src/helpers/insuredHelper";
import AddressForm from "~src/pages/staff/OrderCustom/components/form/AddressForm";
import { CustomerAddress } from "~src/types/customerInfo";

interface DeliveryFormProps {
  index: number;
  fieldName: string;
  insuredsInfo: Insured;
}

const DeliveryForm: React.FC<DeliveryFormProps> = ({
  index,
  fieldName,
  insuredsInfo,
}): JSX.Element => {
  const { register, errors, control, setValue, clearErrors } = useFormContext();
  const deliveryTypes = getInsuredDeliveryTypeAsArray(insuredsInfo.delivery_type).map(String);
  const [deliveryType, setDeliveryType] = useState<string>(insuredsInfo?.delivery_address_type);
  const [deliveryAddress, setDeliveryAddress] = useState<CustomerAddress>(insuredsInfo?.addresses.find(a => a.address_type === "delivery"));

  const watchInsuredAddress = useWatch({
    control,
    name: `${fieldName}.addresses_attributes[0]`,
    defaultValue: insuredsInfo.addresses?.[0],
  });

  const watchInsuredEmail = useWatch({
    control,
    name: `${fieldName}[email]`,
    defaultValue: insuredsInfo?.email,
  });

  const watchInsuredDeliveryType = useWatch({
    control,
    name: `${fieldName}.[delivery_type]`,
    defaultValue: deliveryTypes,
  });

  const isDeliveryTypeChecked = (typeId: string) => {
    return deliveryTypes.includes(typeId);
  };

  useEffect(() => {
    if (deliveryType === "insured_address" && watchInsuredDeliveryType.includes("2")) {
      setValue(`${fieldName}.addresses_attributes[1].address`, watchInsuredAddress.address);
      setValue(`${fieldName}.addresses_attributes[1].postcode`, watchInsuredAddress.postcode);
      setValue(`${fieldName}.addresses_attributes[1].province`, watchInsuredAddress.province);
      setValue(`${fieldName}.addresses_attributes[1].district`, watchInsuredAddress.district);
      setValue(
        `${fieldName}.addresses_attributes[1].sub_district`,
        watchInsuredAddress.sub_district
      );

      clearErrors([
        `${fieldName}.addresses_attributes[1].address`,
        `${fieldName}.addresses_attributes[1].sub_district`,
        `${fieldName}.addresses_attributes[1].district`,
        `${fieldName}.addresses_attributes[1].province`,
        `${fieldName}.addresses_attributes[1].postcode`,
      ]);
    }
  }, [JSON.stringify(watchInsuredDeliveryType), JSON.stringify(watchInsuredAddress), deliveryType]);

  const handleChangeDeliveryAddress = (address: CustomerAddress) => {
    setDeliveryAddress(address);
  }

  return (
    <div className="grid grid-cols-4 col-span-5 gap-4 mt-4">
      {deliveryTypeOptions.map((option, optionIndex) => {
        return (
          option.value === 1 && (
            <div className="col-span-2" key={option.value}>
              <Checkbox
                id={`delivery_type_${fieldName}-${option.value}`}
                name={`${fieldName}.[delivery_type]`}
                label={option.label}
                value={option.value}
                inputRef={register(messages.required)}
                defaultChecked={isDeliveryTypeChecked(String(option.value))}
                checkedAria
              />

              {optionIndex === 0 && watchInsuredEmail && (
                <a href={`mailto:${watchInsuredEmail}`} className="ml-8 text-gray-400 underline">
                  {`(${watchInsuredEmail})`}
                </a>
              )}
            </div>
          )
        );
      })}

      <input type="hidden" name={`${fieldName}[address_type]`} value="delivery" ref={register} />
      <input
        type="hidden"
        name={`${fieldName}[delivery_type]`}
        value={getDeliveryTypeAsNumber(watchInsuredDeliveryType)}
        ref={register}
      />

      {errors.order?.insureds_attributes?.[index]?.delivery_type?.message && (
        <span className="text-xs text-red-600">
          {errors.order?.insureds_attributes?.[index]?.delivery_type?.message}
        </span>
      )}

      {watchInsuredDeliveryType.includes("2") && (
        <>
          <div className="col-span-4">
            <Switcher
              id={`delivery_type_switcher-${fieldName}`}
              name="delivery_type_switcher"
              active={deliveryType}
              onChange={(e) => setDeliveryType(e.target.value)}
              labels={deliveryAddressTypeOptions}
            />
            <input
              type="hidden"
              name={`${fieldName}[delivery_address_type]`}
              value={deliveryType}
              ref={register}
            />
          </div>
          <AddressForm
            readOnly={deliveryType === "insured_address"}
            name={`${fieldName}.addresses_attributes[1]`}
            setValue={setValue}
            register={register}
            clearErrors={clearErrors}
            errors={errors?.order?.insureds_attributes?.[index]?.addresses_attributes?.[1]}
            control={control}
            value={deliveryAddress}
            onChange={handleChangeDeliveryAddress}
          />
        </>
      )}
    </div>
  );
};

export default DeliveryForm;
