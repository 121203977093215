import { AxiosResponse } from "axios";
import { formatISO, parseJSON } from "date-fns";
import queryString from "query-string";
// TEST
// import { useWhatChanged } from "@simbathesailor/use-what-changed";

import { useCallback, useEffect, useMemo, useState } from "react";
import { OrderInfoCheckout, Order } from "~src/types/order";
import axios from "~src/helpers/configAxios";
import { FindOrdersParams } from "~src/models/params/FindOrdersParams";
import UpdateOrderParams from "~src/models/params/UpdateOrderParams";

import { convertPaymentResponse } from "./Payments";
import ProtectedAPIClient, {
  ProtectedAPIClientProps,
  useProtectedAPI,
  UseProtectedAPIOptions,
} from "./ProtectedAPIClient";
import APIResponse from "~src/models/APIResponse";
import InstallmentInfo from "~src/models/InstallmentInfo";
import { Payment } from "~src/types/payment";
import { parseDate } from "~src/helpers/date";
import { useAppContext } from "~src/context/App";
import { useAuthContext } from "~src/context/Auth";

export interface FindOrdersResponse extends APIResponse<Order[]> {
  current_page?: number;
  total_pages?: number;
  total_count?: number;
}

// TODO: Revise API interfaces later
export interface GetOrderResponse extends APIResponse<Order> {
  checked_addon?: boolean;
  addon_selling_price?: string;
  installment_info?: InstallmentInfo[];
}

export type OrdersAPIClientProps = ProtectedAPIClientProps;

export default class OrdersAPIClient extends ProtectedAPIClient<OrdersAPIClientProps> {
  constructor(props: OrdersAPIClientProps) {
    super(props);
  }

  public async findOrders(params: FindOrdersParams): Promise<FindOrdersResponse> {
    try {
      const url = this.getEndpointURL("orders");
      preprocessFindOrdersParams(params);

      const resp = await this.withAbort((abc) =>
        this._axios.get<APIResponse<Order[]>>(url, {
          params,
          signal: abc.signal,
        })
      );

      const orders = resp.data.content.map((orderResp) => ({
        ...orderResp,
        ...convertOrderResponse(orderResp),
      }));

      return {
        ...resp.data,
        content: orders,
      };
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public async downloadOrdersAsExcel(params: Partial<FindOrdersParams>): Promise<APIResponse<any>> {
    try {
      const url = this.getEndpointURL("orders/export_order_excel");
      const resp = await this.withAbort(({ signal }) =>
        this._axios.post<any, AxiosResponse<APIResponse<any>>>(url, {
          ...params,
          signal,
        })
      );
      return resp.data;
    } catch (err) {
      this.checkErrorResponse(err);
    }

    // DEPRECATED: Temporarily kept for reference
    // if (params.status.length !== 0) {
    //   data.append("order_status[]", params.status);
    // }
    // if (params.paymentMethod.length !== 0) {
    //   data.append("payment_method[]", params.paymentMethod);
    // }
  }

  public async getOrder(
    orderID: number,
    suffix = "info",
    params: Record<string, any> = {}
  ): Promise<GetOrderResponse> {
    try {
      const url = new URL(this.getEndpointURL(`orders/${orderID}`));
      if (suffix) {
        url.pathname = `${url.pathname}/${suffix}`;
      }
      url.search = queryString.stringify(params, {
        arrayFormat: "bracket",
      });

      const resp = await this.withAbort(({ signal }) =>
        this._axios.get<GetOrderResponse>(url.toString(), { signal })
      );

      return {
        ...resp.data,
        content: {
          ...resp.data.content,
          ...convertOrderResponse(resp.data.content),
        },
      };
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public async updateRefId(orderID: number): Promise<APIResponse<any>> {
    try {
      const url = this.getEndpointURL(`orders/${orderID}/ref_id`);

      const resp = await this.withAbort(({ signal }) =>
        this._axios.patch<any, AxiosResponse<APIResponse<any>>>(url, {}, { signal })
      );
      this.checkAPIResponse(resp);

      return resp.data;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  // TODO: Revise the URL names later
  public async addPayslip(orderID: number, data: FormData): Promise<APIResponse<any>> {
    try {
      const url = this.getEndpointURL(`orders/${orderID}/payments/payslip`);
      // DEPRECATED:
      // const url = `${this.props.appConfig.backendURL}/orders/${orderID}/payments/payslip`;

      const resp = await this.withAbort(({ signal }) =>
        this._axios.post<any, AxiosResponse<APIResponse<any>>>(url, data, { signal })
      );
      this.checkAPIResponse(resp);

      return resp.data;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public async confirmPayslip(orderID: number, data: FormData): Promise<APIResponse<any>> {
    try {
      const url = this.getEndpointURL(`orders/${orderID}/payments/payslip`);

      const resp = await this.withAbort(({ signal }) =>
        this._axios.patch<any, AxiosResponse<APIResponse<any>>>(url, data, { signal })
      );
      this.checkAPIResponse(resp);

      return resp.data;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public async createInstallment(orderID: number, data: FormData): Promise<APIResponse<Payment[]>> {
    try {
      const url = this.getEndpointURL(`orders/${orderID}/payments/installment`);

      const resp = await this.withAbort(({ signal }) =>
        this._axios.post<any, AxiosResponse<APIResponse<Payment[]>>>(url, data, { signal })
      );
      this.checkAPIResponse(resp);

      return resp.data;
    } catch (err) {
      // TEST
      console.error("createInstallment:", err);
      this.checkErrorResponse(err);
    }
  }

  public async updateOrder(
    orderID: number,
    data: UpdateOrderParams | FormData
  ): Promise<APIResponse<any>> {
    try {
      const url = this.getEndpointURL(`orders/${orderID}`);

      const resp = await this.withAbort(({ signal }) =>
        this._axios.patch<UpdateOrderParams | FormData, AxiosResponse<APIResponse<Order>>>(
          url,
          data,
          {
            signal,
            headers: {
              "Content-Type": "order" in data ? "application/json" : "multipart/form-data",
            },
          }
        )
      );

      return resp.data;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }
}

export function preprocessFindOrdersParams(params?: FindOrdersParams) {
  // TODO: Reconsolidate/Fix the wait_verify/waiting_verify status conflict
  if (params && params.order_status?.length) {
    params.order_status = params.order_status.map((s) =>
      s === "waiting_verify" ? "wait_verify" : s
    );
  }
}

export function convertOrderResponse(orderResp: Partial<Order>): Partial<Order> {
  const order = { ...orderResp };

  for (const fld of [
    "created_at",
    "updated_at",
    "expired_at",
    "addon_start_date",
    "addon_end_date",
    "cover_start_date",
    "cover_end_date",
    // "cmi_warning_date",
    // "vmi_warning_date",
  ]) {
    order[fld] = order[fld] ? parseDate(order[fld]) : undefined;
  }

  order.payments = orderResp.payments?.map((paymentResp) => convertPaymentResponse(paymentResp));

  return order;
}

export type OrdersAPIFactory = (props: OrdersAPIClientProps) => OrdersAPIClient;
export const defaultOrdersAPIFactory = (props: OrdersAPIClientProps) => new OrdersAPIClient(props);

export interface UseOrdersAPIOptions {
  apiFactory?: OrdersAPIFactory;
  onError?: (err: Error) => void;
}

export interface UseOrdersAPIState {
  ordersAPI?: OrdersAPIClient;
  error?: Error;
}

export function useOrdersAPI(opts: UseOrdersAPIOptions = {}): UseOrdersAPIState {
  const { onError } = opts;

  // TEST
  // useWhatChanged([opts.apiFactory, onError], "opts.apiFactory, onError", "", "useOrdersAPI");
  const apiFactory = useCallback(
    (baseProps: ProtectedAPIClientProps) => {
      const apiProps = { ...baseProps };

      if (opts.apiFactory) {
        return opts.apiFactory(apiProps);
      }

      return defaultOrdersAPIFactory(apiProps);
    },
    [opts.apiFactory]
  );

  const {
    api: ordersAPI,
    error,
    ...otherState
  } = useProtectedAPI<OrdersAPIClientProps, OrdersAPIClient>({
    apiFactory,
    onError,
  });

  return { ...otherState, ordersAPI, error };
}
