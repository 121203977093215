import "babel-polyfill";
// import axios from "./configAxios";
import { useState, useEffect, useMemo } from "react";
import { unstable_batchedUpdates } from "react-dom";
// TEST
// import { useWhatChanged } from "@simbathesailor/use-what-changed";

import OrdersAPIClient, { OrdersAPIClientProps, useOrdersAPI } from "~src/api/Orders";
import { useAppContext } from "~src/context/App";
import { useAuthContext } from "~src/context/Auth";
import { getInsuredDeliveryTypeAsArray } from "~src/helpers/insuredHelper";
import { Agent } from "~src/types/agent";
import { Insured } from "~src/types/insured";
import { Order, Status as OrderStatus } from "~src/types/order";

export interface UseOrderOptions {
  suffix?: string;
  queryParams?: Record<string, any>;
  apiFactory?: (props: OrdersAPIClientProps) => OrdersAPIClient;
  onError?: (err: Error) => void;
}

export interface UseOrderState {
  isLoading: boolean;
  order?: Order;
  checkedAddon: boolean;
  installmentInfo: any;
  ordersAPI: OrdersAPIClient;
  error?: Error;
  reload: () => void;
}

// TODO: Refactor/Consolidate this component to OrderContext
export default function useOrder(id: number, opts: UseOrderOptions = {}): UseOrderState {
  const { suffix, queryParams, apiFactory, onError } = opts;

  const { session } = useAuthContext();

  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState<Order | undefined>();
  const [checkedAddon, setCheckoutAddon] = useState(false);
  const [installmentInfo, setInstallmentInfo] = useState(null);
  const [error, setError] = useState<Error | undefined>();

  // TEST
  // useWhatChanged([onError], "onError", "", "useOrder#1");
  // TODO: Refactor/Consolidate flow here
  const { ordersAPI } = useOrdersAPI({ apiFactory, onError });

  // TEST
  // useWhatChanged(
  //   [ordersAPI, session.isInitializing],
  //   "ordersAPI, session.isInitializing",
  //   "",
  //   "useOrder#2"
  // );
  useEffect(() => {
    if (ordersAPI && !session.isInitializing) {
      setIsLoading(true);
      fetchData();
    }
  }, [ordersAPI, session.isInitializing]);

  async function fetchData() {
    try {
      const respData = await ordersAPI.getOrder(id, suffix, queryParams);
      // axios.get(`${process.env.API_URL}${url}`, params)
      // .get(`http://localhost:3000/order`, payload)
      const order = respData.content;
      order.insureds = order.insureds.map((insured) => ({
        ...insured,
        delivery_type: getInsuredDeliveryTypeAsArray(insured.delivery_type),
      }));
      unstable_batchedUpdates(() => {
        setIsLoading(false);
        setOrder(order);
        setCheckoutAddon(respData.checked_addon ?? false);
        setInstallmentInfo(respData.installment_info);
      });
    } catch (err) {
      if (ordersAPI.isAbortError(err)) {
        return;
      }
      unstable_batchedUpdates(() => {
        setIsLoading(false);
        setError(err);
      });
      onError && onError(err);
    }
  }

  // DEPRECATED: Use ~src/helpers/insuredHelper#getInsuredDeliveryTypeAsArray instead
  // const formatDeliveryType = (insured: Insured) => {
  //   if (insured.delivery_type) {
  //     insured.delivery_type = Array.isArray(insured.delivery_type)
  //       ? insured.delivery_type
  //       : insured.delivery_type === 3
  //       ? [1, 2]
  //       : [insured.delivery_type];
  //   } else {
  //     insured.delivery_type = [];
  //   }

  //   return insured.delivery_type;
  // };

  return {
    isLoading,
    order,
    checkedAddon,
    installmentInfo,
    ordersAPI,
    error,
    reload: () => fetchData(),
  };
}
