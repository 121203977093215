import React, { useState, useMemo, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-ui/core";
import { Edit, Close, LocalShippingOutlined } from "@mui/icons-material";

import { useToasts } from "react-toast-notifications";
import { JacketUrl, PolicyCopyUrl, ReceiptUrl } from "./DocumentInfo";
import List from "./List";
import {
  Button,
  DatePickerField,
  SelectField,
  TextField,
  Checkbox,
  ButtonCheckbox,
} from "~src/components/form";
import ProductTypeIcon from "~src/components/ProductTypeIcon";
import PolicyStatus from "~src/components/badges/policy/PolicyStatus";
import Preloading from "~src/components/loading/Preloading";

import dataYml from "~/src/data.yaml";
import OrdersAPIClient, { OrdersAPIClientProps, useOrdersAPI } from "~src/api/Orders";
import { useAuthenticate } from "~src/helpers/authen";
import { useAppContext } from "~src/context/App";
import { useAuthContext } from "~src/context/Auth";
import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "~src/helpers/formatNumber";
import {
  covarageDateFormat,
  checkEmpty,
  checkCmiPrice,
  toCamelCaseKeys,
} from "~src/helpers/helpers";
import useFetch from "~src/hooks/useFetch";
import { parseDate, dateFormat, toJsDate, addOneYear } from "~src/helpers/dateTime";
import { messages } from "~src/helpers/messages";
import axios from "~src/helpers/configAxios";
import { Beneficiary } from "~src/types/insured";
import { Order, OrderPaymentsSummary, OrderPolicy } from "~src/types/order";
import { Quote } from "~src/types/quote";
import PolicyTrackingModal from "../modal/PolicyTrackingModal";
import { ProductType } from "~src/types/product";
import { Policy } from "~src/types/policy";

export interface InsuranceInfoProps {
  order: Order;
  quote: Quote;
  paymentsSummary: OrderPaymentsSummary;
  policies: OrderPolicy[];
  productType?: ProductType;
  contract: string;
  details: any;
  criteria: any;
  beneficiary?: Beneficiary;
  allowEdit: boolean;
  allowEditCMI: boolean;
  addOrRemoveCMI;
  handleToggleCMI;
  agentConfirmed: boolean;
  staffConfirmed: boolean;
  apiFactory?: (props: OrdersAPIClientProps) => OrdersAPIClient;
  onError?: (err: Error) => void;
}

const InsuranceInfo = ({
  order,
  quote,
  beneficiary,
  paymentsSummary,
  policies,
  productType,
  contract,
  details,
  criteria,
  allowEdit,
  allowEditCMI,
  addOrRemoveCMI,
  handleToggleCMI,
  agentConfirmed,
  staffConfirmed,
  apiFactory,
  onError,
}: InsuranceInfoProps) => {
  const { ordersAPI } = useOrdersAPI({ apiFactory, onError });
  const orderId = order.order_id;
  const orderNumber = order.number;
  const isCustomOrder = order.order_type == "custom";

  const { t } = useTranslation();
  const { session } = useAuthContext();
  const { permissions } = session;
  const { response, error, isLoading } = useFetch(`/api/insurers`);
  const policyItems = policies.filter((p) => p.type !== "cmi");
  const cmiItems = policies.filter((p) => p.type === "cmi");
  const cmiPaymentDetail = paymentsSummary.line_itemas.find((p) => p.policy_type === "cmi");
  const allowEditDateAll = permissions.some(
    (p) => p.slug === "ORDER" && p.action === "EDIT_DATE_ALL"
  );

  const [openEditInsurance, setOpenEditInsurance] = useState(false);
  const [confirm, setConfirm] = useState(staffConfirmed);
  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const [cmiPrice, setCmiPrice] = useState(checkCmiPrice(cmiPaymentDetail, criteria?.vehicle_type));

  const { coverStartDate, addonStartDate, coverEndDate, addonEndDate } = useMemo(
    () => toCamelCaseKeys(details),
    [details]
  );
  const [isOpenTracking, setIsOpenTracking] = useState(false);
  const currentClickPolicyId = useRef(undefined);
  const currentClickOrderNumber = useRef(undefined);
  const trackingCount = useRef(0);

  const [state, setState] = useState({
    coverStartDate: toJsDate(coverStartDate),
    coverEndDate: toJsDate(coverEndDate),
    addonStartDate: toJsDate(addonStartDate),
    addonEndDate: toJsDate(addonEndDate),
  });
  const [insurerCode, setInsurerCode] = useState(quote.insurer_code);
  const [sumInsured, setSumInsured] = useState(quote.sum_insured);
  const [sellingPrice, setSellingPrice] = useState(quote.selling_price);
  const [insurerOptions, setInsurerOptions] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const { addToast } = useToasts();

  const { accessType } = useAuthenticate();

  useEffect(() => {
    if (!isLoading) {
      const insurers = response.content;
      const options = insurers.map((insurer) => {
        return { value: insurer.code, label: insurer.name, icon: insurer.icon };
      });
      setInsurerOptions(options);
    }
  }, [response, error, isLoading]);

  const isPolicyCustomized = () => {
    return (
      sellingPrice != quote.selling_price ||
      sumInsured != quote.sum_insured ||
      insurerCode != quote.insurer_code
    );
  };

  const getInsurerIcon = (insurerCode) => {
    return insurerOptions.find((insurer) => insurer.value == insurerCode).icon;
  };

  const onOpenEditInsuranceCard = (isOpen) => {
    setState({
      coverStartDate: toJsDate(coverStartDate),
      coverEndDate: toJsDate(coverEndDate),
      addonStartDate: toJsDate(addonStartDate),
      addonEndDate: toJsDate(addonEndDate),
    });
    setInsurerCode(quote.insurer_code);
    setSumInsured(quote.sum_insured);
    setSellingPrice(quote.selling_price);
    setOpenEditInsurance(isOpen);
  };

  const addonCmi = (status) => {
    handleToggleCMI(status);
    setState({
      ...state,
      addonStartDate: state.coverStartDate,
      addonEndDate: state.coverEndDate,
    });
  };

  const onCoverDateChanged = (value, key) => {
    const params = {
      ...state,
      [key]: toJsDate(value),
    };

    if (key == "coverStartDate") params.coverEndDate = addOneYear(toJsDate(value));
    setState(params);
  };

  const onAddonDateChanged = (value, key) => {
    const params = {
      ...state,
      [key]: toJsDate(value),
    };

    if (key == "addonStartDate") params.addonEndDate = addOneYear(toJsDate(value));
    setState(params);
  };

  const getUpdateData = () => {
    const formElement = document.querySelector("form");
    const formData = new FormData(formElement);
    if (!isPolicyCustomized()) {
      formData.delete("selling_price");
      formData.delete("sum_insured");
      formData.delete("insurer_code");
    }
    return formData;
  };

  const postUpdateQuote = (data, quoteId) => {
    const url = `/api/quotes/${quoteId}`;
    return axios.patch(url, data);
  };

  const onSubmit = () => {
    const updateData = getUpdateData();
    postUpdateQuote(updateData, quote.id)
      .then(() => {
        setOpenEditInsurance(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        console.log(`problem!!!!`);
        onError && onError(error);
      });
  };

  const handleConfirmOrder = (event) => {
    const staffChecked = event.target.checked;
    setConfirm(staffChecked);

    setLoadingScreen(true);
    updateOrderData(staffChecked);
  };

  const updateOrderData = async (staffChecked) => {
    try {
      const data = { staff_confirmed: staffChecked, order: { id: orderId } }; // id: orderId
      await ordersAPI.updateOrder(orderId, data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingScreen(false);
    }
  };

  const renderPolicyStatus = () => {
    const items: JSX.Element[] = [];

    policyItems.forEach((policy) => {
      items.push(
        <ul className="list-disc">
          <li className="mb-10">
            <strong>{policy?.number}</strong>
            <PolicyStatus status={policy?.status} modalBodyText={policy.message} />
            {!!policy && !!policy?.url && (
              <div className="flex text-sm aling-items-center">
                <PolicyCopyUrl policyUrl={policy.url} />
              </div>
            )}
          </li>
        </ul>
      );
    });

    return <List label={`เลขที่กรมธรรม์ (${policyItems.length})`} value={items} />;
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <div>
        <PolicyTrackingModal
          open={isOpenTracking}
          queryKey={`tracking-${trackingCount.current.toString()}`}
          onClose={() => setIsOpenTracking(false)}
          addToast={addToast}
          orderNumber={currentClickOrderNumber.current}
          policyId={currentClickPolicyId.current}
        />
        <div className="flex justify-between detail-header bg">
          <h6 className="bg">
            {productType === "travel" ? "ข้อมูลประกันเดินทาง" : "ข้อมูลประกันภัยรถยนต์ภาคสมัครใจ"}
          </h6>
          <div className="flex items-center">
            {openEditInsurance && (
              <Button type="submit" color="primary" variant="none">
                บันทึก
              </Button>
            )}
            {allowEdit && (
              <IconButton onClick={() => onOpenEditInsuranceCard(!openEditInsurance)}>
                {openEditInsurance ? <Close /> : <Edit />}
              </IconButton>
            )}
          </div>
        </div>

        <div className="list-content">
          {!isCustomOrder && isPolicyCustomized() && (
            <div className="justify-end mt-1 text-xs text-right text-red-500 row">
              การแก้ไขบริษัทประกันภัย ทุนประกัน หรือเบี้ยประกันภัยรวมภาษีอากร
              จะทำให้แผนประกันภัยเป็นแบบ Custom
            </div>
          )}
          <List
            label="บริษัทประกันภัย"
            value={
              openEditInsurance ? (
                <div className="flex flex-row items-center gap-1">
                  <img src={getInsurerIcon(insurerCode)} className="h-7.5r" />
                  <SelectField
                    id="insurer_code"
                    name="insurer_code"
                    value={insurerCode}
                    onChange={(e) => setInsurerCode(e.target.value)}
                    inputRef={register(messages.requiredSelect)}
                    error={errors.insurer_code?.message}
                    options={insurerOptions}
                  />
                </div>
              ) : (
                <div className="flex align-items-center">
                  <span className="inline-block mr-2">
                    <img src={quote.insurer_icon} width="30" />
                  </span>
                  <span>{quote.insurer_name}</span>
                </div>
              )
            }
          />
          <List
            label="ประเภทประกันภัย"
            value={
              <>
                <ProductTypeIcon type={productType} />{" "}
                {dataYml.product_type[productType][quote.policy_type]}
                {`${
                  dataYml.repair_facility[quote.repair_facility] !== undefined
                    ? ` / ${dataYml.repair_facility[quote.repair_facility]}`
                    : ""
                }`}
              </>
            }
          />
          <List label="แผนประกันภัย" value={quote.product_name} />

          {process.env.APP_NAME === "mti" && policyItems[0]?.type === "1" && (
            <List label="เลขรับแจ้ง (NOS)" value={policyItems[0]?.nos_number || "-"} />
          )}

          {productType !== "travel" ? (
            <>
              {contract === "renew" && (
                <List label="เลขที่กรมธรรม์เดิม" value={checkEmpty(quote.from_policy_number)} />
              )}
              <List
                label="เลขที่กรมธรรม์"
                value={
                  <>
                    {quote.policy_type === "cmi"
                      ? checkEmpty(cmiItems.length > 0 ? cmiItems[0].number : "")
                      : checkEmpty(policyItems.length > 0 ? policyItems[0].number : "")}
                  </>
                }
              />

              <List
                label="สถานะกรมธรรม์"
                value={
                  <>
                    {policyItems.length > 0 && (
                      <PolicyStatus
                        status={policyItems[0]?.status}
                        modalBodyText={policyItems[0].message}
                      />
                    )}
                    {quote.policy_type === "cmi" && cmiItems.length > 0 && (
                      <PolicyStatus
                        status={cmiItems[0]?.status}
                        modalBodyText={cmiItems[0].message}
                      />
                    )}

                    {quote.policy_type === "cmi" && cmiItems.length > 0 && (
                      <>
                        {cmiItems[0]?.url !== undefined && (
                          <div className="flex text-sm aling-items-center">
                            <PolicyCopyUrl policyUrl={cmiItems[0].url} />
                          </div>
                        )}
                        {cmiItems[0]?.jacket_url !== undefined && (
                          <div className="flex text-sm aling-items-center">
                            <JacketUrl jacketUrl={cmiItems[0].jacket_url} />
                          </div>
                        )}
                        {cmiItems[0]?.receipt_url && t("receiptAgentDashBoard") && (
                          <div className="flex text-sm aling-items-center">
                            <ReceiptUrl receiptUrl={cmiItems[0].receipt_url} />
                          </div>
                        )}
                        {t("trackingPolicy") && cmiItems[0]?.status === "success" && (
                          <button
                            type="button"
                            className="flex mt-2 text-xs text-secondary-1"
                            onClick={() => {
                              trackingCount.current += 1;
                              currentClickOrderNumber.current = orderNumber;
                              currentClickPolicyId.current = cmiItems[0].id;
                              setIsOpenTracking(true);
                            }}
                          >
                            <LocalShippingOutlined
                              className="mr-1 text-secondary-1"
                              fontSize="small"
                            />
                            สถานะการจัดส่งกรมธรรม์
                          </button>
                        )}
                      </>
                    )}

                    {!!policyItems[0] && !!policyItems[0]?.url && (
                      <div className="flex text-sm aling-items-center">
                        <PolicyCopyUrl policyUrl={policyItems[0].url} />
                      </div>
                    )}

                    {!!policyItems[0] && !!policyItems[0]?.jacket_url && (
                      <div className="flex text-sm aling-items-center">
                        <JacketUrl jacketUrl={policyItems[0].jacket_url} />
                      </div>
                    )}
                    {!!policyItems[0] &&
                      !!policyItems[0]?.receipt_url &&
                      t("receiptAgentDashBoard") && (
                        <div className="flex text-sm aling-items-center">
                          <ReceiptUrl receiptUrl={policyItems[0].receipt_url} />
                        </div>
                      )}
                    {t("trackingPolicy") && policyItems[0]?.status === "success" && (
                      <button
                        type="button"
                        className="flex mt-2 text-xs text-secondary-1"
                        onClick={() => {
                          trackingCount.current += 1;
                          currentClickOrderNumber.current = orderNumber;
                          currentClickPolicyId.current = policyItems[0].id;
                          setIsOpenTracking(true);
                        }}
                      >
                        <LocalShippingOutlined className="mr-1 text-secondary-1" fontSize="small" />
                        สถานะการจัดส่งกรมธรรม์
                      </button>
                    )}
                  </>
                }
              />
            </>
          ) : (
            <>{renderPolicyStatus()}</>
          )}

          <List label="ประเภทงาน" value={dataYml.order.contract[contract]} />
          {openEditInsurance ? (
            <>
              <List
                label="วันที่เริ่มต้นคุ้มครองกรมธรรม์"
                value={
                  <DatePickerField
                    type="text"
                    id="order[details][cover_start_date]"
                    name="order[details][cover_start_date]"
                    minDate={allowEditDateAll ? null : parseDate(dateFormat(new Date()))}
                    callbackvalue={(value) => onCoverDateChanged(value, "coverStartDate")}
                    inputRef={register({
                      ...messages.required,
                      ...(allowEditDateAll ? null : messages.validateCoverageDate),
                    })}
                    error={errors.order?.details?.cover_start_date?.message}
                    value={dateFormat(state.coverStartDate)}
                  />
                }
              />
              <List
                label="วันที่สิ้นสุดคุ้มครองกรมธรรม์"
                value={
                  <DatePickerField
                    type="text"
                    id="order[details][cover_end_date]"
                    name="order[details][cover_end_date]"
                    minDate={state.coverStartDate}
                    callbackvalue={(value) => onCoverDateChanged(value, "coverEndDate")}
                    inputRef={register({
                      ...messages.required,
                      ...(allowEditDateAll ? null : messages.validateCoverageDate),
                    })}
                    error={errors.order?.details?.cover_end_date?.message}
                    value={dateFormat(state.coverEndDate)}
                  />
                }
              />
            </>
          ) : (
            <List
              label="วันที่คุ้มครองกรมธรรม์"
              value={covarageDateFormat(details.cover_start_date, details.cover_end_date)}
            />
          )}
          <List
            label="ทุนประกัน"
            value={
              openEditInsurance ? (
                <TextField
                  type="number"
                  value={sumInsured}
                  name="sum_insured"
                  inputRef={register}
                  onChange={(e) => setSumInsured(e.target.value)}
                />
              ) : (
                formatNumberWithCurrency2(quote.sum_insured)
              )
            }
          />
          <List
            label="เบี้ยประกันภัยรวมภาษีอากร"
            value={
              openEditInsurance ? (
                <TextField
                  type="number"
                  value={sellingPrice}
                  name="selling_price"
                  inputRef={register}
                  onChange={(e) => setSellingPrice(e.target.value)}
                />
              ) : (
                formatNumberWithCurrency(quote.selling_price)
              )
            }
            bold
          />
          {productType !== "travel" && (
            <List label="ผู้รับผลประโยชน์" value={dataYml.beneficiary[beneficiary.receiver]} />
          )}
        </div>
        {productType !== "travel" && (
          <>
            <div className="flex items-center justify-between w-full mt-4 bg">
              <h6 className="">ข้อมูลประกันภัยรถยนต์ภาคบังคับ (พ.ร.บ.)</h6>
              {openEditInsurance && (
                <>
                  {cmiPrice && allowEditCMI && (
                    <ButtonCheckbox
                      label={`พ.ร.บ. ${formatNumberWithCurrency(cmiPrice)}`}
                      className={{ label: "mb-0" }}
                      id="checked_addon"
                      name="checked_addon"
                      inputRef={register()}
                      onChange={() => addonCmi(addOrRemoveCMI.checked_addon)}
                      active={addOrRemoveCMI.checked_addon}
                      checked={addOrRemoveCMI.checked_addon}
                      value={addOrRemoveCMI.checked_addon}
                    />
                  )}
                  {t("trackingPolicy") && cmiItems[0]?.status === "success" && (
                    <button
                      type="button"
                      className="flex mt-2 text-xs text-secondary-1"
                      onClick={() => {
                        trackingCount.current += 1;
                        currentClickOrderNumber.current = orderNumber;
                        currentClickPolicyId.current = cmiItems[0].id;
                        setIsOpenTracking(true);
                      }}
                    >
                      <LocalShippingOutlined className="mr-1 text-secondary-1" fontSize="small" />
                      สถานะการจัดส่งกรมธรรม์
                    </button>
                  )}
                </>
              )}
            </div>
            <List
              label="เลขที่กรมธรรม์"
              value={checkEmpty(cmiItems.length > 0 ? cmiItems[0].number : "")}
            />
            <List label="ประเภทงาน" value={dataYml.order.contract[contract]} />
            {openEditInsurance ? (
              <>
                <List
                  label="วันที่เริ่มต้นคุ้มครองกรมธรรม์"
                  value={
                    <DatePickerField
                      type="text"
                      id="order[details][addon_start_date]"
                      name="order[details][addon_start_date]"
                      minDate={allowEditDateAll ? null : parseDate(dateFormat(new Date()))}
                      callbackvalue={(value) => onAddonDateChanged(value, "addonStartDate")}
                      inputRef={register({
                        ...messages.required,
                        ...(allowEditDateAll ? null : messages.validateCoverageDate),
                      })}
                      error={errors.order?.details?.addon_start_date?.message}
                      value={dateFormat(state.addonStartDate)}
                    />
                  }
                />
                <List
                  label="วันที่สิ้นสุดคุ้มครองกรมธรรม์"
                  value={
                    <DatePickerField
                      type="text"
                      id="order[details][addon_end_date]"
                      name="order[details][addon_end_date]"
                      minDate={state.coverStartDate}
                      callbackvalue={(value) => onAddonDateChanged(value, "addonEndDate")}
                      inputRef={register({
                        ...messages.required,
                        ...(allowEditDateAll ? null : messages.validateCoverageDate),
                      })}
                      error={errors.order?.details?.addon_end_date?.message}
                      value={dateFormat(state.addonEndDate)}
                    />
                  }
                />
              </>
            ) : (
              <List
                label="วันที่คุ้มครองกรมธรรม์"
                value={covarageDateFormat(details.addon_start_date, details.addon_end_date)}
              />
            )}
            <List
              label="เบี้ยประกันภัยรวมภาษีอากร"
              value={formatNumberWithCurrency(cmiPrice)}
              bold
            />
          </>
        )}

        {accessType === "STAFF" && (
          <>
            <div className="flex justify-between detail-header bg mt-4">
              <h6 className="bg">ยืนยันข้อมูลการสั่งซื้อ</h6>
            </div>

            {t("staffCheckboxConfirm") && (
              <div className="list-content mt-4">
                <Checkbox
                  id="staff_confirmed"
                  name="staff_confirmed"
                  label={dataYml.order.staff_confirmed}
                  onChange={(e) => handleConfirmOrder(e)}
                  checked={confirm}
                />
              </div>
            )}
          </>
        )}
      </div>

      {loadingScreen && <Preloading />}
    </form>
  );
};

export default InsuranceInfo;
