import React from "react";
import "./radio.scss";
const Radio = ({ id, label, inputRef = undefined, ...rest }) => {
  return (
    <label
      htmlFor={id}
      className="custom-control-radio custom-checkbox custom-control-inline h-fit"
    >
      {label}
      <input type="radio" ref={inputRef} id={id} {...rest} />
      <span className="checkmark"></span>
    </label>
  );
};

export default Radio;
