import _map from "lodash/map";
import _filter from "lodash/filter";
import React, { useEffect, useState } from "react";

import UploadImages from "~src/components/form/uploadField/UploadImages";
import { removeFileFromFileList } from "~src/helpers/helpers";
import { messages } from "~src/helpers/messages";

interface UploadVehicleDocumentProps {
  id: string;
  name: string;
  errors: any;
  vehicleDocuments: Array<Object>;
  //TODO: Check this with react-hook-form interfaces
  register: (target: HTMLElement, opts?: any) => any;
}

const UploadVehicleDocument: React.FC<UploadVehicleDocumentProps> = ({
  id,
  name,
  errors,
  vehicleDocuments,
  register,
}) => {
  const [vehicleDocumentFiles, setVehicleDocumentFiles] = useState<File[] | Object[]>([]);
  const [deleteFileIds, setDeleteFileIds] = useState<Array<string>>([]);
  const [previewImages, setPreviewImages] = useState<Array<string>>([]);

  useEffect(() => {
    let tempPreviewImages = [];

    if (vehicleDocumentFiles?.length > 0) {
      const extractFileField = (file) => ({
        url: URL.createObjectURL(file),
        name: file.name,
        type: file.type,
        imageFrom: "local",
      });

      tempPreviewImages.push(..._map(vehicleDocumentFiles || [], extractFileField));
    }

    if (vehicleDocuments?.length > 0) {
      const isFilePresent = (doc) => !deleteFileIds.includes(doc["blob_id"]);
      const extractFileField = (doc) => ({
        url: doc["url"],
        blobId: doc["blob_id"],
        name: doc["filename"],
        type: doc["dataType"],
        imageFrom: "external",
      });

      let vehicleImagePresent = _filter(vehicleDocuments || [], isFilePresent);
      tempPreviewImages.push(..._map(vehicleImagePresent || [], extractFileField));
    }

    setPreviewImages(tempPreviewImages);
  }, [vehicleDocumentFiles, deleteFileIds]);

  const handleChangeUpload = (e) => {
    const { files } = e.target;

    setVehicleDocumentFiles([...files]);
  };

  const handleDeleteFile = (inputRef, index) => {
    const tempVehicleImage = [...vehicleDocumentFiles];
    tempVehicleImage.splice(index, 1);

    setVehicleDocumentFiles(tempVehicleImage);
    removeFileFromFileList(inputRef, index);
  };

  const handleDeleteAttachedFile = (blobId) => {
    const tempDeleteFileIds = [...deleteFileIds];
    tempDeleteFileIds.push(blobId);

    setDeleteFileIds(tempDeleteFileIds);
  };

  return (
    <div className="block">
      <div id="div-upload-document-image" className="animated fadeIn">
        <p className="my-10 text-lg">
          แนบสำเนาบัตรประชาชน{" "}
          <small className="block" style={{ fontSize: "0.8rem" }}>
            หมายเหตุ : รองรับชนิดของไฟล์เป็น .PNG, .JPG, .JPEG, .PDF เท่านั้น
          </small>
        </p>

        <input type="hidden" name={`${id}_deleted_ids`} ref={register} value={deleteFileIds} />

        <UploadImages
          id={id}
          name={name}
          buttonLabel="เพิ่มเอกสาร"
          // files={vehicleDocumentFiles || []}
          previewImages={previewImages}
          errors={errors}
          multiple={true}
          handleChangeUpload={handleChangeUpload}
          handleDeleteFile={handleDeleteFile}
          handleDeleteAttachedFile={handleDeleteAttachedFile}
          //TODO: Check this with react-hook-form interfaces
          registerValidate={(target, opts) =>
            register(target, {
              ...opts,
              ...messages.validateImagesFileType,
            })
          }
        />
      </div>
    </div>
  );
};

export default UploadVehicleDocument;
