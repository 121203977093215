import React, { useRef } from "react";
import {
  Box,
  FormGroup,
  Paper,
  Stack,
  TextField,
  FormControlLabel,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Divider,
  IconButton,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAppDispatch, useAppSelector } from "~src/store";
import EditorToolbar, { modules, formats } from "~src/components/EditorToolbar";
import {
  changeRole,
  setDetail,
  setFormData,
  setEffectiveDate,
  padaFormSelector,
  clearError,
  addNewQuestion,
  deleteQuestion,
  setQustionValue,
  pdpaQuestionsSelector,
} from "./pdpaSlice";
import { fetchCreatePDPA, fetchUpdatePDPA } from "./apis";
import { parse } from "date-fns";
import { showErrorNotification, notificationSelector } from "../notification";
import Preloading from "~src/components/loading/Preloading";

const ContractForm = () => {
  const form = useAppSelector(padaFormSelector);
  const questions = useAppSelector(pdpaQuestionsSelector);
  const notification = useAppSelector(notificationSelector);
  const dispatch = useAppDispatch();

  const [preload, setPreload] = React.useState(false);

  React.useEffect(() => {
    if (!notification.open && form.status === "rejected") {
      dispatch(clearError());
    }
  }, [form.status, notification.open, dispatch]);

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as "hero" | "knight";
    dispatch(changeRole(value));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    dispatch(setFormData({ name, value }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPreload(true);

    const { effective_date, ...rest } = form.data;

    const [day, month, year] = effective_date.split("/");
    const payload = {
      ...rest,
      effective_date: `${year}-${month}-${day}`,
    };

    if (form.mode === "create") {
      dispatch(fetchCreatePDPA(payload));
    } else {
      const { id } = form.data;
      dispatch(fetchUpdatePDPA({ id, payload }));
    }
  };

  if (form.status === "rejected") {
    dispatch(showErrorNotification({ message: form.error }));
  }

  return (
    <Paper elevation={0} sx={{ mt: 3, p: 4 }}>
      <>
        {preload && <Preloading />}
        <Box component="form" onSubmit={onSubmit}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={2} direction="row" alignItems="center">
              <TextField
                label="Version"
                name="version"
                onChange={handleChange}
                value={form.data.version}
              />

              <DatePicker
                label="Effective Date"
                format="dd/MM/yyyy"
                value={parse(form.data.effective_date, "dd/MM/yyyy", new Date())}
                onChange={(newValue) => dispatch(setEffectiveDate(newValue))}
              />
            </Stack>

            {/* <Box sx={{ my: 2 }}>
              <FormControl>
                <FormLabel>Agent role</FormLabel>
                <RadioGroup row name="role" value={form.data.role} onChange={handleChangeRole}>
                  <FormControlLabel value="hero" control={<Radio />} label="Hero" />
                  <FormControlLabel value="knight" control={<Radio />} label="Knight" />
                </RadioGroup>
              </FormControl>
            </Box> */}

            <FormGroup sx={{ minHeight: 300, my: 2, "& .ql-container": { minHeight: 300 } }}>
              <EditorToolbar />
              <ReactQuill
                formats={formats}
                modules={modules}
                theme="snow"
                value={form.data.detail}
                onChange={(value) => dispatch(setDetail(value))}
                style={{ minHeight: 300 }}
              />
            </FormGroup>

            <Divider sx={{ my: 4 }} />

            {questions.map((o, index) => (
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ display: !o._destroy ? "flex" : "none", mb: 4 }}
              >
                <React.Fragment key={index}>
                  <Grid item lg={10}>
                    <TextField
                      label={`${form.mode === "create" ? "New Contition" : "Contition"}`}
                      fullWidth
                      onChange={(e) => dispatch(setQustionValue({ index, value: e.target.value }))}
                      value={o.question}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <IconButton type="button" onClick={() => dispatch(deleteQuestion(index))}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              </Grid>
            ))}

            <Button
              variant="outlined"
              color="inherit"
              sx={{ my: 3 }}
              onClick={() => dispatch(addNewQuestion())}
            >
              Add new condition
            </Button>

            <Divider sx={{ my: 4 }} />

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained" size="large" disableElevation>
                Submit
              </Button>
            </Box>
          </LocalizationProvider>
        </Box>
      </>
    </Paper>
  );
};

export default ContractForm;
