import History from "history";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Redirect } from "react-router-dom";

import { FormGroup, TextField, ButtonRadius } from "~src/components/form";
import Loading from "~src/components/loading";

import LoginAPIClient from "~src/api/Login";
import { useAppContext } from "~src/context/App";
import { useAuthContext } from "~src/context/Auth";
import { saveToken, useAuthenticate } from "~src/helpers/authen.js";

import "./login.scss";
// import useLogo from "/assets/logo/gettgo-hero-logo.svg";

const redirectToStafffDashboard = (resource) => {
  resource.push("/staffs/dashboard");
};

export interface LoginPageProps {
  location: History.Location<any>;
  // validateAuth: () => boolean;
}

export default function LoginPage({ location }: LoginPageProps) {
  // TEST
  const { goBack, nextURL } = location.state || {};

  const [user, setUser] = useState({
    login: "",
    password: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  // const { auth, validateAuth } = useAuthenticate();
  const { t } = useTranslation();
  const history = useHistory();

  const { appConfig } = useAppContext();
  const { session, dispatch, validateAuth } = useAuthContext();

  const loginAPI = useMemo(() => new LoginAPIClient({ appConfig }), [appConfig]);

  useEffect(() => {
    // cleanup
    return () => {
      loginAPI.cleanup();
    };
  }, []);

  useEffect(() => {
    if (session.isLoggedIn) {
      if (!validateAuth()) {
        console.warn("Session Invalidated");
        dispatch({ type: "LOGOUT" });
        return;
      }

      if (goBack) {
        history.goBack();
        return;
      }
      if (nextURL) {
        history.push(nextURL);
        return;
      }

      const accessType = session.permissions.find(p => p.slug == "ACCESS");

      let pathname = "";
      switch (accessType.action) {
        case "AGENT": pathname = "/dashboard"; break;
        case "DIRECT": pathname = "/all-policies"; break;
        case "STAFF": pathname = "/staffs"; break;
        default: pathname = "/";
      }

      history.push({
        pathname: pathname,
      });
    }
  }, [session.isLoggedIn, session.username, session.role]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);
    (async function () {
      try {
        const resp = await loginAPI.login({ user });
        const { data } = resp;
        setErrMsg("");

        data.referal_code = data.username;
        data.username = data.username;
        saveToken(data);
        setLoading(false);
        dispatch({
          type: "LOGIN",
          payload: {
            ...session,
            id: data.id,
            username: data.username,
            email: data.email,
            title: data.title,
            firstname: data.firstname,
            lastname: data.lastname,
            role: data.role,
            permissions: data.permissions,
            recommend_code: data.recommend_code,
          },
        });
      } catch (err) {
        console.error(err);
        setErrMsg("Something went wrong. Please contact engineer.");
        dispatch({
          type: "LOGIN_ERROR",
          payload: {
            ...session,
          },
        });
        setLoading(false);
      }
    })();
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="login-page" style={{ backgroundImage: `url(${t("backgroundLogin")})` }}>
        <div className="login-form animated zoomIn">
          <div className="login-wrapper">
            <div className="login-wrapper-logo ">
              <img src={`${process.env.BUCKET_URL}/logo.svg`} alt={t("fullAppName")} />
            </div>
            <hr />

            {errMsg ? (
              <div className="p-4 text-center error-txt">
                <span>ไม่พบข้อมูลผู้ใช้หรือรหัสผ่านในระบบ</span>
              </div>
            ) : (
              ""
            )}
            <form onSubmit={handleSubmit}>
              <FormGroup label="ชื่อผู้ใช้งาน" labelType="block" htmlForLabel="login">
                <TextField
                  name="login"
                  id="login"
                  type="text"
                  value={user.login}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup label="รหัสผ่าน" labelType="block" htmlForLabel="password">
                <TextField
                  name="password"
                  id="password"
                  type="password"
                  value={user.password}
                  onChange={handleChange}
                />
              </FormGroup>

              <div className="mt-3">
                <p className="remark-text">
                  พบปัญหาการใช้งานกรุณาติดต่อได้ที่ LINE ID: {t("lineAccount")}
                </p>
              </div>

              <FormGroup>
                <ButtonRadius type="submit" color="secondary" fullwidth disabled={loading}>
                  ลงชื่อเข้าใช้งาน
                  <i className="material-icons" style={{ marginLeft: 4 }}>
                    double_arrow
                  </i>
                </ButtonRadius>
              </FormGroup>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
