import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { FormGroup, SelectField, TextField, Switcher } from "~/src/components/form";
import { messages } from "~src/helpers/messages";
import {
  insuredTypeOption,
  titleRenewTHOptions,
  titleRenewLegalEntityTHOptions,
} from "~src/helpers/options";
import LegalEntityForm from "../form/LegalEntityForm";
import PersonalForm from "../form/PersonalForm";
import AddressForm from "../form/AddressForm";
import WhTaxForm from "../form/WhTaxForm";

const InsuredInfoPanel: React.FC = (): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [insuredType, setInsuredType] = useState<string>(insuredTypeOption[0].value);
  const { errors, control, register, setValue, clearErrors } = useFormContext();

  return (
    <div className="p-4 w-full bg-white shadow rounded-5">
      <h6 className="flex items-center justify-between p-1 px-2 text-sm font-bold bg-gray-100">
        ข้อมูลผู้รับประกัน
        <button
          type="button"
          onClick={() => setExpanded(!expanded)}
          className="flex items-center justify-center w-6 h-6 bg-white shadow outline-none rounded-5 focus:outline-none"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </button>
      </h6>
      <div className={classNames(expanded ? "block" : "hidden")}>
        <div className="grid mt-4 px-2 grid-cols-5 gap-4 tablet:grid-cols-1">
          <FormGroup label="ประเภทผู้เอาประกัน" className="col-span-5" require>
            <Switcher
              id="insure_type_switcher"
              name="insure_type_switcher"
              active={insuredType}
              labels={insuredTypeOption}
              onChange={(e) => setInsuredType(e.target.value)}
            />
            <input type="hidden" name="order.insured_category" value={insuredType} ref={register} />
          </FormGroup>

          {insuredType === "legal_entity" && (
            <div className="animated fadeIn grid grid-cols-5 col-span-5 gap-4">
              <WhTaxForm name="order" register={register} errors={errors?.order} />
              <LegalEntityForm
                name="order[insureds_attributes][]"
                register={register}
                errors={errors?.order?.insureds_attributes}
              />
            </div>
          )}

          <FormGroup label="คำนำหน้าชื่อ" require>
            <SelectField
              name="order[insureds_attributes][].title"
              style={{ minHeight: "38px" }}
              inputRef={register(messages.required)}
              error={errors?.order?.insureds_attributes?.title?.message}
              options={
                insuredType === "legal_entity"
                  ? titleRenewLegalEntityTHOptions
                  : titleRenewTHOptions
              }
            />
          </FormGroup>
          <FormGroup label="ชื่อจริง" className="col-span-2" require>
            <TextField
              name="order[insureds_attributes][].firstname"
              inputRef={register(messages.required)}
              error={errors?.order?.insureds_attributes?.firstname?.message}
            />
          </FormGroup>
          <FormGroup label="นามสกุล" className="col-span-2" require>
            <TextField
              name="order[insureds_attributes][].lastname"
              inputRef={register(messages.required)}
              error={errors?.order?.insureds_attributes?.lastname?.message}
            />
          </FormGroup>

          {insuredType === "person" && (
            <div className="animated fadeIn grid grid-cols-5 col-span-5 gap-4">
              <PersonalForm
                name="order[insureds_attributes][]"
                register={register}
                errors={errors?.order?.insureds_attributes}
                control={control}
              />
            </div>
          )}

          <div className="grid grid-cols-4 col-span-5 gap-4">
            <AddressForm
              name="order[insureds_attributes][].addresses_attributes[0]"
              setValue={setValue}
              register={register}
              clearErrors={clearErrors}
              errors={errors?.order?.insureds_attributes?.addresses_attributes?.[0]}
              control={control}
            />
            <input
              type="hidden"
              name="order[insureds_attributes][].addresses_attributes[0][address_type]"
              value="insured"
              ref={register}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuredInfoPanel;
