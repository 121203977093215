import React from "react";

import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert, Publish, Person, Edit, DeleteForever, GetApp } from "@mui/icons-material";

import LicenseInfo from "../../../../models/LicenseInfo";

import { checkEmpty, dateFormat, getInsuredByCategory } from "../../../../helpers/helpers";
import { last } from "lodash";

interface AgentTableProps {
  isLoading: boolean;
  agents: any;
  className?: string;
  onUploadExcel?: any;
  onDownloadExcel?: any;
  onOpenAgent?: any;
  onEditAgent?: any;
  onDeleteAgent?: any;
  onAddAgent?: any;
}

const AgentTable: React.FC<AgentTableProps> = ({
  isLoading,
  agents,
  className = "",
  onUploadExcel,
  onDownloadExcel,
  onOpenAgent,
  onEditAgent,
  onDeleteAgent,
  onAddAgent,
}) => {
  const [listMenuElement, setListMenuElement] = React.useState(null);
  const [agentMenuElement, setAgentMenuElement] = React.useState({});
  const isMenuEnabled = false;

  const handleOpenListMenu = (e) => {
    setListMenuElement(e.currentTarget);
  };

  const handleCloseListMenu = () => {
    setListMenuElement(null);
  };

  const handleSelectListMenu = (callback) => {
    setListMenuElement(null);
    callback();
  };

  const handleOpenAgentMenu = (e) => {
    const menuId = e.currentTarget.id;
    setAgentMenuElement({
      ...agentMenuElement,
      [menuId]: e.currentTarget,
    });
  };

  const handleCloseAgentMenu = () => {
    setAgentMenuElement({});
  };

  const handleSelectAgentMenu = (callback, agent) => {
    setAgentMenuElement({});
    callback(agent);
  };

  const getStatusCell = (status) => {
    switch (status) {
      case "active":
        return <span className="text-green-800">Active</span>;
      case "inactive":
        return <span className="text-red-800">Inactive</span>;
      case "waiting_approval":
        return <span className="text-gray-700">Waiting Approval</span>;
      default:
        return <span className="text-gray-400">Unknown</span>;
    }
  };

  const getAgentRow = (agent, index) => {
    const license: LicenseInfo = last(agent.licenses);

    return (
      <tr className="mb-1 transition duration-300 bg-white hover:bg-primary-color hover:bg-opacity-50">
        <td className="px-1 py-2 font-normal">{checkEmpty(agent.username)}</td>
        <td className="px-1 py-2 font-normal">{getInsuredByCategory(agent)}</td>
        <td className="px-1 py-2 font-normal">{checkEmpty(agent.role?.name)}</td>
        <td className="px-1 py-2 font-normal">{checkEmpty(license?.license_no)}</td>
        <td className="px-1 py-2 font-normal">{dateFormat(license?.expired_date)}</td>
        <td className="px-1 py-2 font-normal">{checkEmpty(agent.mobile_no)}</td>
        <td className="px-1 py-2 font-normal">{checkEmpty(agent.email)}</td>
        <td className="px-1 py-2 font-normal">{dateFormat(agent.created_at)}</td>
        <td className="px-1 py-2 font-normal">{getStatusCell(agent.status)}</td>
        <td>
          <IconButton id={index} component="span" ria-haspopup="true" onClick={handleOpenAgentMenu}>
            <MoreVert />
          </IconButton>
          <Menu
            id={index}
            anchorEl={agentMenuElement[index]}
            keepMounted
            open={Boolean(agentMenuElement[index])}
            onClose={handleCloseAgentMenu}
          >
            <MenuItem
              onClick={() => {
                handleSelectAgentMenu(onOpenAgent, agent);
              }}
            >
              <Person className="mr-1" />
              รายละเอียด
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSelectAgentMenu(onEditAgent, agent);
              }}
            >
              <Edit className="mr-1" />
              แก้ไข
            </MenuItem>
            {isMenuEnabled && (
              <>
                <MenuItem
                  onClick={() => {
                    handleSelectAgentMenu(onDeleteAgent, agent);
                  }}
                >
                  <span className="text-red-600">
                    <DeleteForever className="mr-1" />
                    ลบ
                  </span>
                </MenuItem>
              </>
            )}
          </Menu>
        </td>
      </tr>
    );
  };

  return (
    <table className={`table-auto text-left ${className}`}>
      <thead className="text-1xs text-custom-gray-md">
        <tr>
          <th className="px-1 py-2 font-normal">รหัส Hero</th>
          <th className="px-1 py-2 font-normal">ชื่อ-สกุล</th>
          <th className="px-1 py-2 font-normal">ประเภท</th>
          <th className="px-1 py-2 font-normal">เลขที่ใบอนุญาต</th>
          <th className="px-1 py-2 font-normal">วันหมดอายุ</th>
          <th className="px-1 py-2 font-normal">เบอร์โทร</th>
          <th className="px-1 py-2 font-normal">Email</th>
          <th className="px-1 py-2 font-normal">วันที่สมัครสมาชิก</th>
          <th className="px-1 py-2 font-normal">สถานะ</th>
          <th className="w-4">
            <IconButton component="span" aria-haspopup="true" onClick={handleOpenListMenu}>
              <MoreVert />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={listMenuElement}
              keepMounted
              open={Boolean(listMenuElement)}
              onClose={handleCloseListMenu}
            >
              <MenuItem onClick={() => handleSelectListMenu(onAddAgent)}>
                <Person className="mr-1" />
                เพิ่มข้อมูลตัวแทน
              </MenuItem>
              <MenuItem onClick={() => handleSelectListMenu(onUploadExcel)}>
                <Publish className="mr-1" />
                อัพโหลด Excel
              </MenuItem>
              <MenuItem onClick={() => handleSelectListMenu(onDownloadExcel)}>
                <GetApp className="mr-1" />
                ดาวน์โหลด Excel
              </MenuItem>
            </Menu>
          </th>
        </tr>
      </thead>
      <tbody className="text-xs divide-y-4 divide-custom-gray-sm">
        {isLoading ? (
          <tr className="mb-1 bg-white">
            <td colSpan={10} className="px-1 py-2 text-center">
              กำลังโหลด...
            </td>
          </tr>
        ) : (
          <>
            {agents.length == 0 ? (
              <tr className="mb-1 bg-white">
                <td colSpan={10} className="px-1 py-2 text-center">
                  ไม่พบข้อมูล
                </td>
              </tr>
            ) : (
              <>
                {agents.map((agent, index) => (
                  <>{getAgentRow(agent, index)}</>
                ))}
              </>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};

export default AgentTable;
