import React from "react";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import useAxios, { usePatch } from "../../../hooks/useAxios";
import SectionHeader from "../../../components/layout/staff/SectionHeader";
import Search from "./components/Search";
import Tab from "./components/Tab";
import Table from "./table/RenewClosedTable";
import { Order } from "../../../types/order";
import { urlStringify } from "../../../helpers/helpers";
import { PostResponse } from "../../../types/jsonResponse";

interface Data {
  current_page: number;
  total_count: number;
  total_pages: number;
  content: Order[];
}
interface PostParam {
  status: "renew_closed";
}

const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const RenewClose = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });
  const keyword = queryParams?.keyword ?? "";
  const [searchValue, setSearchValue] = React.useState<string>(
    keyword.toString()
  );
  const response = useAxios<Data>(
    `/api/orders?status=renew&order_status[]=closed&${urlStringify(
      Object.assign(
        {
          page: DEFAULT_PAGE,
          per_page: PER_PAGE,
          keyword: keyword,
        },
        queryParams
      )
    )}`
  );

  const [updateRenewStatus, callApiUpdateRenewStatus] =
    usePatch<PostResponse, PostParam>();

  React.useEffect(() => {
    if (updateRenewStatus.status === "loaded") {
      window.location.reload();
    }
  }, [updateRenewStatus]);

  const handlePageClick = (page: { selected: number }): void => {
    const selectedPage = page.selected + 1;
    let queries = urlStringify({
      page: selectedPage,
      per_page: PER_PAGE,
      keyword: keyword,
    });
    window.location.href = `${location.pathname}?${queries}`;
  };

  const handleSearch = (e: React.FormEvent): void => {
    e.preventDefault();
    const queries = urlStringify({
      keyword: searchValue,
      per_page: PER_PAGE,
      page: 1,
    });
    window.location.href = `${location.pathname}?${queries}`;
  };

  const handleUpdateRenewStatus = (orderId: number): void => {
    callApiUpdateRenewStatus(`/api/renewals/${orderId}/update/status`, {
      status: "renew_closed",
    });
  };

  return (
    <div className="p-4">
      <SectionHeader
        title="ใบเตือนต่ออายุ"
        breadcrumbs={[{ url: "#", label: "กรมธรรม์", current: true }]}
        searchComponent={
          <Search
            value={searchValue}
            onInputChange={(e) => setSearchValue(e.target.value)}
            onSubmit={handleSearch}
          />
        }
      />
      <Tab />
      <div className="relative p-4 bg-white shadow z-1">
        <Table
          loadStatus={response.status}
          data={response.data?.content}
          onUpdateRenewClick={handleUpdateRenewStatus}
        />
        {response.status === "loaded" && response.data?.total_pages > 1 && (
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            initialPage={response.data?.current_page - 1}
            pageCount={response.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"gm-paginate"}
            // subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            disableInitialCallback={true}
          />
        )}
      </div>
    </div>
  );
};

export default RenewClose;
