import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import "./button.scss";

const Button = props => {
  const {
    children,
    fullwidth,
    color,
    variant,
    component,
    size,
    classNameOption,
    inputRef,
    ...rest
  } = props;
  const fullWidth = !!fullwidth ? "btn-block" : "";

  let setColor = "";
  let setVariant = "";
  let setSize = "";
  let setComponent = [];
  switch (color) {
    case "primary":
      setColor = "btn-primary";
      break;
    case "secondary":
      setColor = "btn-secondary";
      break;
    default:
      setVariant = "";
      break;
  }

  switch (variant) {
    case "default":
      setVariant = "btn-default";
      break;
    case "outlined":
      setVariant = "btn-outlined";
      break;
    case "none":
      setVariant = "btn-none";
      break;
    default:
      setVariant = "btn-default";
      break;
  }

  switch (size) {
    case "default":
      setSize = "";
      break;
    case "xs":
      setSize = "btn-xs";
      break;
    case "lg":
      setSize = "btn-lg";
      break;
    default:
      setSize = "";
      break;
  }

  switch (component) {
    case "link":
      setComponent = (
        <Link
          {...rest}
          ref={inputRef}
          className={classnames([
            "btn",
            setVariant,
            fullWidth,
            setColor,
            setSize,
            classNameOption
          ])}
        >
          <span>{children}</span>
        </Link>
      );
      break;
    default:
      setComponent = (
        <button
          {...rest}
          ref={inputRef}
          className={classnames([
            "btn",
            setVariant,
            fullWidth,
            setColor,
            setSize,
            classNameOption
          ])}
        >
          <span>{children}</span>
        </button>
      );
      break;
  }

  return <>{setComponent}</>;
};

export default Button;
