import _filter from "lodash/filter";
import _map from "lodash/map";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import { ButtonRadius, UploadField } from "~src/components/form";
import FormGroup from "~src/components/form/fromGroup/FormGroup";
import SecureLinkImg from "~src/components/secureLinkImg";
import { useAppContext } from "~src/context/App";

import { createAttachmentFromFile } from "~src/helpers/attachments";
import { messages } from "~src/helpers/messages";
import { Attachment } from "~src/types/attachment";

import "./imageUploadModal.scss";
// import placeholderImage from "~assets/image-not-available.png";

//TODO: Revise this later
const createImageFromURL = (url?: string) => (url ? { url, dataType: "image/?" } : undefined);

//TODO: Refactor/Consolidate this with ~src/components/form/uploadField/UploadImages to support multiple images

export interface ImageUploadModalProps {
  onClose: () => void;
  image?: Attachment;
  imageURL?: string;
  readOnly?: boolean;
  iconName?: string;
  title?: string;
  formID?: string;
  uploadFieldName?: string;
  uploadFieldLabel?: string;
  onUpload?: (data: FormData) => Promise<any>;
  onError?: (err: Error) => void;
}

export default function ImageUploadModal({
  onClose,
  image,
  imageURL,
  readOnly,
  iconName,
  title,
  formID = "imageUploadModalForm",
  uploadFieldName = "file",
  uploadFieldLabel,
  onUpload,
  onError,
  children,
}: React.PropsWithChildren<ImageUploadModalProps>) {
  const { assets } = useAppContext();
  // HACK: Workaround for Parcel bundler Error: Cannot find module 'assets/...' with some asset files
  const { imageNotAvailable: placeholderImage } = assets;
  const { register, errors, handleSubmit } = useForm<any>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [previewImageSrc, setPreviewImageSrc] = useState<any>(undefined);
  const [formElement, setFormElement] = useState<HTMLFormElement | undefined>();
  //TODO: Revise this later
  const [imageAttachment, setImageAttachment] = useState<Attachment | undefined>(
    image || createImageFromURL(imageURL)
  );
  const [imageFile, setImageFile] = useState<File | Object | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitBtnRef = useRef<HTMLFormElement>();

  useDeepCompareEffectNoCheck(() => {
    setImageAttachment(image || createImageFromURL(imageURL));
  }, [image, imageURL]);

  useDeepCompareEffectNoCheck(() => {
    const _attachment = imageFile ? createAttachmentFromFile(imageFile) : undefined;
    _attachment && setImageAttachment(_attachment);
  }, [imageFile]);

  const handleChangeUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;

    setImageFile(files[0]);

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      setPreviewImageSrc(e.target.result);
    };

    fileReader.readAsDataURL(files[0]);
  };

  async function handleUploadSubmit(_, evt: React.BaseSyntheticEvent) {
    const data = new FormData(evt.target);

    try {
      setIsSubmitting(true);
      const _respData = onUpload ? await onUpload(data) : undefined;
      setIsSubmitting(false);
    } catch (err) {
      console.error(err);
      onError && onError(err);
      setIsSubmitting(false);
    }
  }

  return (
    <div className="imageUploadModal modal">
      <div className="modal-overlay" onClick={() => onClose()}></div>
      <div className="modal-box">
        <div className="header">
          {iconName && (
            <div className="icon">
              <i className="material-icons">{iconName}</i>
            </div>
          )}
          <h2 className="title">{title}</h2>
          <div className="buttons">
            <button className="material-icons close-icon" role="button" onClick={() => onClose()}>
              close
            </button>
          </div>
        </div>
        <div className="image-frame scrollable-content">
          {(!imageFile && image?.url) ? (
              <SecureLinkImg
                  src={image.url || placeholderImage}
                  alt={imageAttachment?.filename || "Payslip Image"}
              />
          ) : (
              <>
                {previewImageSrc && (
                    <img src={previewImageSrc} alt={imageAttachment?.filename || "Payslip Image"}/>
                )}
              </>
          )}

          {imageAttachment?.filename && <p>{imageAttachment.filename}</p>}
        </div>
        {!readOnly && (
          <div className="image-upload-form flex flex-col">
            <form
              id={formID}
              autoComplete="off"
              ref={(el) => setFormElement(el)}
              onSubmit={handleSubmit(handleUploadSubmit)}
              encType="multipart/form-data"
            >
              {children}
              <FormGroup label={uploadFieldLabel} htmlForLabel={`${formID}_file`}>
                <UploadField
                  id={`${formID}_file`}
                  name={uploadFieldName}
                  accept="image/*"
                  inputRef={register(messages.required)}
                  onChange={handleChangeUpload}
                  errors={errors}
                />
              </FormGroup>
              <ButtonRadius
                color="primary"
                type="submit"
                inputRef={submitBtnRef}
                disabled={!imageFile || !formElement || !onUpload || isSubmitting}
              >
                <i className="material-icons">add_photo_alternate</i> Upload
              </ButtonRadius>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
