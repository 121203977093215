import { formatISO, parseJSON } from "date-fns";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";

import { useToasts } from "react-toast-notifications";
import SectionHeader from "~src/components/layout/staff/SectionHeader";
import ErrorPage from "~src/components/errorPage";
import OrdersListView from "~src/components/orders/OrdersListView";

// import Order from "~src/models/Order";
import { Order } from "~src/types/order";
import AgentOrdersAPIClient from "~src/api/agent/Orders";
import { OrdersAPIClientProps } from "~src/api/Orders";
import { OrdersContextProvider } from "~src/context/Orders";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { toISODate } from "~src/helpers/date";
import usePaginatedURL from "~src/hooks/usePaginatedURL";
import { FindOrdersParams } from "~src/models/params/FindOrdersParams";
import { PaginationParams } from "~src/models/params/PaginationParams";

import "./index.scss";
import SearchForm from "~src/components/form/SearchForm";
import useInput from "~src/hooks/useInput";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

export interface AgentOrdersPageProps {
  location: Location;
}

export default function AgentOrdersPage(props: AgentOrdersPageProps) {
  // TODO: Revise this later
  return (
    <PageContextProvider withQuery location={props.location}>
      <AgentOrdersPageInner {...props} />
    </PageContextProvider>
  );
}

const ordersAPIFactory = (props) => new AgentOrdersAPIClient(props);

type AgentOrdersPageInnerProps = AgentOrdersPageProps;

var AgentOrdersPageInner = ({}: AgentOrdersPageInnerProps) => {
  const { queryParams, setQueryParams, onError: onPageError } = usePageContext();
  const { searchParams, setSearchParams, paginationParams, setPaginationParams } = usePaginatedURL({
    queryParams,
    setQueryParams,
    defaultPage: DEFAULT_PAGE,
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });
  const { addToast } = useToasts();

  const { value: keyword, bind: handleChangeKeyword } = useInput(searchParams.keyword ?? "");

  // TODO: Revise this later
  const [ordersContextParams, setOrdersContextParams] = useState(
    getSearchParamsWithDefaults(searchParams, paginationParams)
  );
  useDeepCompareEffect(() => {
    setOrdersContextParams(getSearchParamsWithDefaults(searchParams, paginationParams));
  }, [searchParams, paginationParams]);

  // const [isError, setIsError] = useState(false);
  // const [errorMsg, setErrorMsg] = useState("");
  // const ordersAPIRef = useRef<AgentOrdersAPIClient>();

  function handleSearch(params: Partial<FindOrdersParams>) {
    // TEST
    console.log("Current search params:", params);
    const { created_from, created_to, page, per_page, ...otherParams } = params;
    setSearchParams({
      ...searchParams,
      ...otherParams,
      created_from: created_from ? toISODate(created_from) : undefined,
      created_to: created_to ? toISODate(created_to) : undefined,
    });
    setPaginationParams({
      page: page || paginationParams.page,
      per_page: per_page || paginationParams.per_page,
    });
  }

  const handleSearchBtnClick = (_evt: React.MouseEvent<HTMLElement>) => {
    handleSearch({ ...searchParams, keyword });
  };

  const handleSearchBarKeyPress = (evt: React.KeyboardEvent<HTMLElement>) => {
    if (evt.key === "Enter") handleSearch({ ...searchParams, keyword });
  };

  function handleError(err: Error) {
    addToast(
      <div>
        <h4>ERROR</h4>
        <p>{err.message}</p>
      </div>,
      {
        appearance: "error",
        autoDismiss: true,
      }
    );
    // console.error(err);
    // setIsError(true);
    // setErrorMsg(err.message || "Error");
    onPageError(err);
  }

  return (
    <OrdersContextProvider
      searchParams={ordersContextParams}
      apiFactory={ordersAPIFactory}
      onError={handleError}
      loadOnInit={true}
    >
      <div className="view-order">
        <OrdersListView
          currentParams={ordersContextParams}
          onSearch={handleSearch}
          onError={handleError}
          renderSectionHeader={() => (
            <SectionHeader
              title="รายการคำสั่งซื้อ"
              breadcrumbs={[{ url: "#", label: "รายการซื้อขาย", current: true }]}
              searchComponent={
                <SearchForm
                  type="text"
                  placeholder="ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่คำสั่งซื้อ"
                  {...handleChangeKeyword}
                  handleClick={handleSearchBtnClick}
                  handleKeyPress={handleSearchBarKeyPress}
                />
              }
            />
          )}
        />
      </div>
    </OrdersContextProvider>
  );
};

function getSearchParamsWithDefaults(
  filters: Partial<FindOrdersParams> | undefined,
  paginationParams: PaginationParams
) {
  return {
    ...filters,
    keyword: filters?.keyword ?? "",
    payment_method: filters?.payment_method ?? [],
    order_status: filters?.order_status ?? [],
    created_from: filters?.created_from ?? null,
    created_to: filters?.created_to ?? null,
    expiration_filter: true,
    page: paginationParams.page,
    per_page: paginationParams.per_page,
  };
}
