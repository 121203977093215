import React from "react";

interface Props {
  label: string;
  active: boolean;
  icon: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const TabButton: React.FC<Props> = ({ label, active, icon, onClick }) => {
  return (
    <button type="button" className={`tab-btn ${active && "active"}`} onClick={onClick}>
      {icon}
      {label}
    </button>
  );
};

export default TabButton;
