import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonRadius } from "~/src/components/form";
import HighLightText from "~/src/components/highlightText";
import CommonModal from "~/src/components/modal/common"

type Props = {
  open: boolean
  qrIcon: string
  onClose?: () => void
}

const QrCodeModal: React.FC<Props> = ({ open, qrIcon, onClose }): JSX.Element => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(open)

  const TextData = [
    { highlight: false, text: 'กรุณาสแกน QR Code', newline: true  },
    { highlight: false, text: 'หรือ' },
    { highlight: true, text: 'เพิ่มเพื่อนด้วยไอดีไลน์ ' },
    { highlight: false, text: t('lineAccount') },
  ]

  const handleClose = (): void => {
    onClose?.()
    setIsOpen(false)
  }

  return (
    <CommonModal closeComponent={<></>} open={isOpen} persistent={false} onClose={handleClose}>
      <div className="animate-fade-in duration-700 flex flex-col justify-center items-center gap-20">
        <div className="text-center text-base text-primary">
          <HighLightText headerText={TextData} />
        </div>

        <img src={qrIcon} width={200} height={200} />

        <ButtonRadius onClick={handleClose} classNameOption="w-40" color="secondary">
          เรียบร้อย
        </ButtonRadius>
      </div>
    </CommonModal>
  )
}



export default QrCodeModal

