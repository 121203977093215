import React from "react";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import "/src/components/loading/placeholder-loading.scss";
import { Button } from "/src/components/form";
import useInsurerIcons from "/src/helpers/useInsurerIcons";
import yaml from "/src/data";

const CompareProducts = ({ packageObjs, fnRemove, loaded }) => {
  const { search } = location;

  return (
    <>
      {packageObjs.map((obj, idx) => {
        {
          return loaded ? (
            <div key={idx} className="col-md-3">
              <div className="compare-page-packages-list">
                <button
                  type="button"
                  className="compare-page-packages-list_close"
                  onClick={() => fnRemove(obj.id, idx)}
                >
                  <i className="material-icons">close</i>
                </button>
                <div className="compare-page-packages-list-box">
                  <div className="insure-details">
                    <div className="img">
                      <img src={obj.insurer_icon} />
                    </div>
                    <div className="mt-2">
                      <div className="text-base font-medium">
                        {obj.insurer_name}
                      </div>
                      <div className="text-xs">{`${
                        yaml.policy_type[obj.policy_type]
                      } ${
                        yaml.repair_facility[obj.details.repair_facility]
                      }`}</div>
                    </div>
                  </div>
                  <div className="insure-details">
                    <div></div>
                    <div className="secondary-color text-xl font-semibold text-right">
                      {formatNumberWithCurrency(obj.standard_price)}
                      <span className="text-gray-400 block text-xs -mt-1">
                        ต่อปี
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div key={idx} className="col-md-3">
              <div className="compare-page-packages-list">
                <div className="ph-card-header"></div>
                <div className="ph-card-content">
                  <div></div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default CompareProducts;
