import "babel-polyfill";
import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
// TEST
// import { useWhatChanged } from "@simbathesailor/use-what-changed";

import OrdersAPIClient, {
  GetOrderResponse,
  OrdersAPIClientProps,
  useOrdersAPI,
} from "~src/api/Orders";
import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "~src/helpers";
import { getCheckoutSummaryStateFromOrderResponse } from "~src/helpers/checkout";
import { CheckoutSummaryState } from "~src/models/CheckoutState";
import { Insured } from "~src/types/insured";
import { Order, OrderPaymentsSummary } from "~src/types/order";
import { Quotation } from "~src/types/quotation";
import { Quote } from "~src/types/quote";

// TODO: Refactor/Consolidate Checkout data interfaces later

export interface UseProductOrderOptions {
  ordersApiFactory?: (props: OrdersAPIClientProps) => OrdersAPIClient;
  // ordersAPI?: OrdersAPIClient;
  onError?: (err: Error) => void;
}

export interface UseProductOrderState {
  isOrderAvailable: boolean;
  order: Order;
  orderNumber: string;
  insureds;
  quotationInfo;
  quoteInfo;
  details;
  deliveryAddress;
  getLabelSteppers;
  getSummaryInfo;
  insuredCategory;
  hasCmiAddon;
  cmiObjs;
  contract;
  attachImagesStatus;
  vehicleDocuments;
}

//TODO: Refactor/Consolidate this into ~src/hooks/useOrder
export default function useProductOrder(orderID: number, opts: UseProductOrderOptions = {}) {
  const { ordersApiFactory, onError } = opts;

  const [isOrderAvailable, setIsOrderAvaialble] = useState(false);
  const [orderResponse, setOrderResponse] = useState<GetOrderResponse | undefined>();
  const [order, setOrder] = useState<Order | undefined>();
  const [orderNumber, setOrderNumber] = useState("");
  const [contract, setContract] = useState("");
  const [insuredCategory, setInsuredCategory] = useState<string | undefined>();
  const [details, setDetails] = useState<any>({});
  const [insureds, setInsureds] = useState<Insured[]>([]);
  const [quotationInfo, setQuotationInfo] = useState<Partial<Quotation>>({});
  const [quoteInfo, setQuoteInfo] = useState<Quote[]>([]);
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [paymentInfo, setPaymentInfo] = useState<Partial<OrderPaymentsSummary>>({});
  const [hasCmiAddon, setHasCmiAddon] = useState(false);
  const [installmentInfo, setInstallmentInfo] = useState<any[]>([]);
  const [attachImagesStatus, setAttachImagesStatus] = useState("");
  const [vehicleDocuments, setVehicleDocuments] = useState([]);
  const [cmiObjs, setCmiObjs] = useState({
    addon_selling_price: "",
    checked_addon: false,
  });

  const [productType, setProductType] = useState("");
  const [processStep, setProcessStep] = useState([]);
  const [includeWhTax, setIncludeWhTax] = useState(true);

  const { ordersAPI } = useOrdersAPI({
    apiFactory: ordersApiFactory,
    onError,
  });

  // TEST
  // useWhatChanged([ordersAPI], "ordersAPI", "", "useProductOrder#1");
  useEffect(() => {
    ordersAPI && fetchOrder();
  }, [ordersAPI]);

  async function fetchOrder() {
    try {
      const respData = await ordersAPI.getOrder(orderID, "info");
      // const url = `${process.env.API_URL}/api/orders/${orderId}/info`;
      // await axios
      //   .get(url)
      //   .then((response) => unstable_batchedUpdates(() => {

      unstable_batchedUpdates(() => {
        if (respData.content?.order_id) {
          const _order = respData.content;
          const {
            number,
            insured_category,
            details,
            insureds,
            quotation,
            quotes,
            // delivery_address,
            payments_summary,
            contract,
            car_inspection_type,
            vehicle_documents,
            include_wh_tax,
          } = _order;

          const { addon_selling_price, checked_addon, installment_info } = respData;
          unstable_batchedUpdates(() => {
            setOrderResponse(respData);
            setOrder(_order);
            setInsuredCategory(insured_category);
            setDetails(details);
            setQuotationInfo(quotation);
            setInsureds(formatInsuredsDeliveryType(insureds));
            setQuoteInfo(quotes);
            setPaymentInfo(payments_summary);
            setOrderNumber(number);
            setHasCmiAddon(quotes[0].addon === undefined ? false : true);
            setCmiObjs({
              addon_selling_price,
              checked_addon,
            });
            setInstallmentInfo(installment_info);
            setContract(contract);
            setProductType(quotation.product_type);
            setProcessStep(getStepProcess(quotation.product_type));
            setIsOrderAvaialble(true);
            setAttachImagesStatus(car_inspection_type);
            setVehicleDocuments(vehicle_documents);
            setIncludeWhTax(include_wh_tax);
          });
        }
      });
    } catch (err) {
      console.error(`Problem fetching the order ${orderID} !!!`, err);
      opts.onError && opts.onError(err);
    }
  }

  function formatInsuredsDeliveryType(insureds) {
    insureds.forEach((insured) => {
      if (insured.delivery_type) {
        insured.delivery_type = insured.delivery_type === 3 ? [1, 2] : [insured.delivery_type];
      } else {
        insured.delivery_type = [];
      }
    });
    return insureds;
  }

  function getStepProcess(producType) {
    switch (producType) {
      case "motor":
        return [
          { label: "ข้อมูลผู้ถือกรมธรรม์" },
          { label: "ข้อมูลรถยนต์" },
          { label: "ยืนยันข้อมูล" },
          { label: "ชำระเงิน" },
        ];
      case "vaccine":
        return [
          { label: "ข้อมูลผู้ถือกรมธรรม์" },
          { label: "ยืนยันข้อมูล" },
          { label: "ชำระเงิน" },
        ];
      default:
        return [];
    }
  }

  function getLabelSteppers(stepIndex) {
    processStep[stepIndex]["active"] = true;

    return processStep;
  }

  function getDetails() {
    let result = {
      carDefinition: "",
      insuredId: insureds[0].id,
      beneficiaryId: insureds[0].beneficiary.id,
    };

    switch (productType) {
      case "motor":
        let criteria = quotationInfo.criteria;
        result.carDefinition = `${criteria?.car_brand} ${criteria?.car_model} ปี ${criteria?.year}`;
        break;
      case "travel":
        break;
    }

    return result;
  }

  function getSummaryInfo(): CheckoutSummaryState {
    return getCheckoutSummaryStateFromOrderResponse(orderResponse);

    // const result: CheckoutSummaryState = {
    //   orderNumber,
    //   productType,
    //   installmentInfo,
    // };
    // const { carDefinition: insuranceProductDetails, insuredId, beneficiaryId } = getDetails();

    // let mainQuote: Partial<Quote> | undefined, addonQuote: Partial<Quote> | undefined;

    // mainQuote = quoteInfo[0];
    // if (mainQuote.addon) {
    //   addonQuote = mainQuote.addon;
    // } else {
    //   addonQuote = { selling_price: "0" };
    // }

    // result.subTitle = quotationInfo.criteria?.insurance_class;
    // result.details = insuranceProductDetails;
    // result.sumSellingPrice = formatNumberWithCurrency(paymentInfo.sum_selling_price, "");
    // result.discount = formatNumberWithCurrency(paymentInfo.sum_discount, "");
    // result.commission = formatNumberWithCurrency(paymentInfo.commission_gain, "");
    // result.creditCardFee = formatNumberWithCurrency(paymentInfo.credit_card_fee, "");
    // result.paymentTotalAmount = formatNumberWithCurrency(paymentInfo.payment_total_amount, "");
    // result.paymentTotalAmountIncludedCCFee = formatNumberWithCurrency(
    //   paymentInfo.payment_total_amount_included_cc_fee,
    //   ""
    // );
    // result.sellingPrice = mainQuote.selling_price;
    // result.sellingPriceAddon = addonQuote.selling_price;
    // result.policyType = mainQuote.policy_type;

    // switch (productType) {
    //   case "motor":
    //     // let majorityQuote = quoteInfo.filter((q) => q.policy_type != "cmi")[0];
    //     let majorityQuote = quoteInfo[0];
    //     result.productId = majorityQuote.product_id;
    //     result.insurerCode = majorityQuote.insurer_code;
    //     result.insurerName = majorityQuote.insurer_name;
    //     result.insurerIcon = majorityQuote.insurer_icon;
    //     result.title = majorityQuote.product_name;
    //     result.repairFacility = majorityQuote.repair_facility;
    //     break;
    // }

    // return result;
  }

  return {
    isOrderAvailable,
    order,
    orderNumber,
    insureds,
    quotationInfo,
    quoteInfo,
    details,
    deliveryAddress,
    getLabelSteppers,
    getSummaryInfo,
    insuredCategory,
    hasCmiAddon,
    cmiObjs,
    contract,
    attachImagesStatus,
    vehicleDocuments,
    includeWhTax,
  };
}
