import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "~src/store";
import {
  fetchBanners,
  fetchCreateBanner,
  fetchDeleteBanner,
  fetchPublishBanner,
  fetchSorterBanner,
  fetchUpdateBanner,
} from "./apis/banners";

const initialState = {
  status: "idle",
  actionStatus: "idle",
  total: 0,
  data: [],
  modal: {
    type: "create",
    open: false,
    editId: null,
  },
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    updateData: (state, { payload }) => {
      const newData = [];
      payload.forEach((o: any, index: number) => {
        const dataCount = payload.length - 1;
        newData.push({ ...o, order: dataCount - index });
      });
      return { ...state, data: payload };
    },
    openCreateModal: (state) => {
      state.modal.open = true;
      state.modal.type = "create";
    },
    openEditModal: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.modal.open = true;
      state.modal.type = "edit";
      state.modal.editId = payload.id;
    },
    closeModal: (state) => {
      state.modal.open = false;
      state.modal.editId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchBanners.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.status = "fulfilled";
        state.total = payload?.total;
        state.data = payload?.content || [];
      })
      .addCase(fetchBanners.rejected, (state) => {
        state.status = "rejected";
        state.data = [];
      });

    builder
      .addCase(fetchCreateBanner.pending, (state) => {
        state.actionStatus = "pending";
      })
      .addCase(fetchCreateBanner.fulfilled, () => {
        window.location.reload();
      });

    builder
      .addCase(fetchUpdateBanner.pending, (state) => {
        state.actionStatus = "pending";
      })
      .addCase(fetchUpdateBanner.fulfilled, () => {
        window.location.reload();
      });

    builder
      .addCase(fetchPublishBanner.pending, (state) => {
        state.actionStatus = "pending";
      })
      .addCase(fetchPublishBanner.fulfilled, () => {
        window.location.reload();
      });

    builder
      .addCase(fetchDeleteBanner.pending, (state) => {
        state.actionStatus = "pending";
      })
      .addCase(fetchDeleteBanner.fulfilled, () => {
        window.location.reload();
      });

    builder.addCase(fetchSorterBanner.pending, (state) => {
      state.actionStatus = "pending";
    });

    builder.addCase(fetchSorterBanner.fulfilled, (state) => {
      state.actionStatus = "idle";
    });
  },
});

export const selectBannerFetchStatus = (state: RootState) => state.banner.status;
export const selectBannerData = (state: RootState) => state.banner.data;
export const selectBannerTotal = (state: RootState) => state.banner.total;
export const selectModalType = (state: RootState) => state.banner.modal.type;
export const selectModalOpen = (state: RootState) => state.banner.modal.open;
export const selectActionStatus = (state: RootState) => state.banner.actionStatus;
export const selectBannerDataId = (state: RootState) => {
  if (state.banner.modal.editId) {
    return state.banner.data.filter((o) => o.id === state.banner.modal.editId)?.[0];
  }
};

export const { updateData, openCreateModal, openEditModal, closeModal } = bannerSlice.actions;
export default bannerSlice.reducer;
