import React from "react";
import { OrderPolicy } from "../../../../types/order";

const usePolicy = (
  policies: OrderPolicy[], 
  type?: string
): OrderPolicy => {
  const policy = React.useMemo(
    () => policies?.filter((o) => o.type === type),
    [policies]
  );

  return policy?.[0];
};

export default usePolicy;
