import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
// TEST
// import { useWhatChanged } from "@simbathesailor/use-what-changed";

import AppConfig from "~src/config/AppConfig";
import { useAppContext } from "~src/context/App";
import { useAuthContext } from "~src/context/Auth";
import APIResponse from "~src/models/APIResponse";
import APIError from "~src/models/APIError";
import { logout } from "../utils/logout";

//TODO: Replace /src/helpers/configAxios.js with this

export type RedirectAction = (url: string) => void;

export interface ProtectedAPIClientProps {
  appConfig: AppConfig;
  tokenSupplier: () => string;
}

export default class ProtectedAPIClient<TProps extends ProtectedAPIClientProps> {
  protected props: TProps;
  protected _axios: AxiosInstance;
  private abortControllers: AbortController[] = [];

  constructor(props: TProps) {
    this.props = props;
    this._axios = axios.create({
      baseURL: props.appConfig.apiURL,
      //TODO: Revise Authen with backend CORS policies later
      // withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    this._axios.interceptors.request.use((config) => this.onBeforeRequest(config));
    this._axios.interceptors.response.use((resp) => this.onResponse(resp));
  }

  public async cleanup() {
    this.abortControllers.forEach((abc) => abc.abort(`${this.constructor.name}: cleaning up`));
    this.abortControllers = [];
  }

  // Utility method to check aborts
  public isAbortError(error: any) {
    //: error is DOMException
    return (
      error && (error.__CANCEL__ || ["Cancel", "AbortError"].includes(error.constructor?.name))
    );
  }

  protected getEndpointURL(endpoint: string) {
    return `${this.props.appConfig.apiURL}/${endpoint}`;
  }

  protected withAbort<T>(op: (abc: AbortController) => T) {
    const abc = new AbortController();
    this.abortControllers.push(abc);

    return op(abc);
  }

  protected checkAPIResponse<T>(resp: AxiosResponse<APIResponse<T>>) {
    if (
      !resp.data?.success &&
      (!resp.data?.status ||
        resp.data.status !== "success" ||
        (typeof resp.data.status === "number" && resp.data.status >= 400))
    ) {
      let errMsgs = resp.data?.messages || resp.data?.message;
      errMsgs = Array.isArray(errMsgs) ? errMsgs : errMsgs ? [`${errMsgs}`] : [];

      throw new APIError(`Got failure status from API: ${errMsgs.join("\n")}`, { response: resp });
    }
  }

  /// Override this as needed
  protected checkErrorResponse<T>(err: AxiosError<any> | any) {
    if ("isAxiosError" in err && err.isAxiosError && "response" in err) {
      const respData = err.response?.data;
      const errMsg = `[${err.response?.status}] ${respData?.message || respData?.error}`;
      if (err.response && err.response.status >= 500) {
        console.error(errMsg, err);
      }
      else if (err.response && err.response.status === 401) {
        logout()
      }
      throw new APIError<T>(errMsg, { cause: err });
    }

    throw err;
  }

  protected checkRedirectResponse(resp: AxiosResponse<any>): string | undefined {
    // HACK: Redirections with statuses 30x don't work with Axios/XHR and CORS
    if ((resp.status >= 300 && resp.status < 400) || resp.status === 201) {
      const nextURL = resp.headers["location"];
      nextURL && console.log("Got redirect response from API:", nextURL);
      return nextURL;
    }
  }

  /// Override this as needed
  protected onResponse(resp: AxiosResponse<any>) {
    return resp;
  }

  protected onBeforeRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    const headers = config.headers;
    const token = this.props.tokenSupplier();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return {
      ...config,
      headers,
    };
  }
}

export type APIFactory<
  TProps extends ProtectedAPIClientProps,
  TAPI extends ProtectedAPIClient<TProps>
> = (props: ProtectedAPIClientProps) => TAPI;

export interface UseProtectedAPIOptions<
  TProps extends ProtectedAPIClientProps,
  TAPI extends ProtectedAPIClient<TProps>
> {
  // props?: Omit<TProps, "appConfig" | "tokenSupplier">;
  apiFactory: APIFactory<TProps, TAPI>;
  onError?: (err: Error) => void;
}

export interface UseProtectedAPIState<
  TProps extends ProtectedAPIClientProps,
  TAPI extends ProtectedAPIClient<TProps>
> {
  api?: TAPI;
  error?: Error;
}

export function useProtectedAPI<
  TProps extends ProtectedAPIClientProps = ProtectedAPIClientProps,
  TAPI extends ProtectedAPIClient<TProps> = ProtectedAPIClient<TProps>
>(opts: UseProtectedAPIOptions<TProps, TAPI>): UseProtectedAPIState<TProps, TAPI> {
  const { appConfig } = useAppContext();
  const { session, validateAuth } = useAuthContext();

  // TEST
  // const deps1 = [opts.apiFactory, session];
  // useWhatChanged(deps1, "opts.props, opts.apiFactory, session");

  // TODO: Revise/Refactor this later
  const createAPI = useCallback(() => {
    const apiToken = session.apiToken ?? "";
    //TEST
    if (!apiToken) {
      console.warn("createAPI: apiToken not found:", session);
    }

    const clientProps = {
      appConfig,
      tokenSupplier: () => apiToken,
    } as TProps;

    //TEST
    console.log(
      "Creating API:",
      opts.apiFactory,
      "; clientProps:",
      clientProps
      // "; call stack:",
      // new Error("test")
    );

    return opts.apiFactory(clientProps);
  }, [opts.apiFactory, session.apiToken]);

  const [apiClient, setAPIClient] = useState<TAPI>(() => createAPI());
  const [error, setError] = useState<Error | undefined>();

  // TEST
  // const deps2 = [createAPI, opts.onError, session];
  // useWhatChanged(deps2, "createAPI, opts.onError, session");

  useDeepCompareEffectNoCheck(() => {
    let _apiClient: TAPI | undefined;
    if (!session.isInitializing && validateAuth()) {
      try {
        _apiClient = createAPI();
        setAPIClient(_apiClient);
      } catch (err) {
        setError(err);
        opts.onError && opts.onError(err);
      }
    }

    //cleanup;
    return async () => {
      _apiClient && (await _apiClient.cleanup().catch((err) => console.error(err)));
    };
  }, [createAPI, opts.onError, session]);

  return {
    api: apiClient,
    error,
  };
}
