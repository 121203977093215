import React, { useEffect, useState } from "react";
import QueryString from "query-string";
import { useForm } from "react-hook-form";
import QuotationHeader from "../single/QuotationHeader";
import QuotationHeaderDetail from "../single/QuotationHeaderDetail";
import CustomerForm from "../single/CustomerForm";
import useAxios from "~src/hooks/useAxios";
import { TravelProductDto } from "~src/types/travel";
import TravelProduct from "~src/models/TravelProduct";

const CreateQuotation: React.FC = (): JSX.Element => {
  const { search } = window.location;
  const queries = QueryString.parse(search);
  const type = queries.type as string;

  const searchResponse = useAxios<TravelProductDto>(
    `http://localhost:3001/content?id=${queries.product_id}`
  );
  const [packageObj, setPackageObj] = useState<TravelProduct>();

  useEffect(() => {
    if (searchResponse.status === "loaded" && searchResponse.data) {
      setPackageObj(new TravelProduct(searchResponse.data[0]));
    }
  }, [searchResponse]);

  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="create-quotation">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <QuotationHeader />
        <div className="relative create-quotation-component">
          <div className="flex">
            <div className="col-md-9">
              <QuotationHeaderDetail type={type} packageObj={packageObj} />
            </div>
            <div className="col-md-3">
              <div className="mb-2 text-base text-gray-600">
                <CustomerForm register={register} errors={errors} />
              </div>
            </div>
          </div>
          <hr />
          <div className="details">
            <h6 className="ml-1 text-xl">รายละเอียดความคุ้มครอง</h6>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateQuotation;
