import React from "react";

import AgentMenu from "./AgentMenu";
import StaffMenu from "./StaffMenu";

import { useAuthContext } from "../../../context/Auth";
import { useAuthenticate } from "~src/helpers/authen";

export interface SidebarProps {
  setShow?: (state: boolean) => void;
}

const Sidebar = ({ setShow: _setShow }: SidebarProps) => {
  const { session } = useAuthContext();
  const { accessType } = useAuthenticate();

  return (
    <div className="fixed z-10 flex w-60 h-full bg-secondary-color overflow-hidden overscroll-contain">
      {accessType === "STAFF" && <StaffMenu />}
      {(accessType === "AGENT" || accessType === "DIRECT") && <AgentMenu />}
    </div>
  );
};

export default Sidebar;
