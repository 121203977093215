import React from "react";
import "./index.scss";
const Tabs = ({ children }) => {
  return <div className="hero-tabs">{children}</div>;
};

const Tab = ({ 
  label = "", 
  active = false, 
  children = null, 
  ...rest 
}) => {
  return (
    <button className={`tab ${active ? "active" : ""}`} {...rest}>
      {label}
      {children}
    </button>
  );
};

const TabPanel = ({ active, children }) => {
  return (
    <div className={`hero-tab-panel animated fadeIn ${!active && "hidden"}`}>
      <div className={`tab-panel ${active ? "active" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export { Tabs, Tab, TabPanel };
