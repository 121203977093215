import React from "react";
import { useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { mainTypeMotor } from "~/src/states/orderCustom";

const RemarkPanel: React.FC = (): JSX.Element => {
  const { remarkDetail } = useRecoilValue(mainTypeMotor);
  const { register } = useFormContext();

  return (
    <div className="p-4 w-full bg-white shadow rounded-5">
      <h6 className="flex items-center justify-between p-1 px-2 text-sm font-bold bg-gray-100">
        {[`เพิ่มเติม`, ...(remarkDetail ?? [])].join(" / ")}
      </h6>

      <textarea
        className="w-full border mt-4 p-2 rounded-md min-h-24 focus:outline-none"
        name="order.remark"
        maxLength={500}
        ref={register}
      />
    </div>
  );
};

export default RemarkPanel;