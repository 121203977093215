import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "~src/store";
import { unEscapeHtml } from "~src/helpers/helpers";
import { pdpaViewSelector, closeAgentViewModal } from "./pdpaSlice";
import { fetchAcceptPdpa } from "./apis";

interface Props {
  canClose?: boolean;
}

const AgentViewPdpaModal: React.FC<Props> = ({ canClose }) => {
  const [loading, setLoading] = React.useState(false);
  const [answers, setAnswers] = React.useState([]);
  const { open, data, status } = useAppSelector(pdpaViewSelector);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (status === "fulfilled") {
      const answersList = data?.answers
        ? data.answers.map((answer) => ({
            id: answer.pdpa_question_id,
            answer: answer.answer ? "1" : "0",
          }))
        : data.questions.map((question) => ({ id: question.id, answer: "" }));

      setAnswers(answersList);

      console.log(answersList);
    }
  }, [status, data]);

  const handleChangeAnswer = (index: number, event) => {
    const updateAnswers = [...answers];
    const value = event.target.value;
    updateAnswers[index] = { ...updateAnswers[index], answer: value };
    setAnswers(updateAnswers);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const filteredAnswers = answers.filter((q) => q.answer !== "");
    if (filteredAnswers.length < answers.length) {
      alert("กรุณาตอบคำถามให้ครบ");
      return;
    }

    setLoading(true);

    const params = {
      id: data.id,
      questions: answers,
    };

    dispatch(fetchAcceptPdpa(params));
  };

  return (
    <Dialog open={open} maxWidth={"lg"} scroll="body">
      <DialogContent sx={{ p: 5 }}>
        {status === "fulfilled" && (
          <>
            <Box
              className="detail-wrapper"
              dangerouslySetInnerHTML={{ __html: unEscapeHtml(data.detail) }}
            />

            <Box component="form" onSubmit={onSubmit}>
              <Box>
                {data.questions.map((o, index) => (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Typography>{o.question}</Typography>
                    <RadioGroup
                      name={`answer[${o.id}]`}
                      value={answers[index]?.answer || ""}
                      onChange={(event) => handleChangeAnswer(index, event)}
                      row
                    >
                      <FormControlLabel control={<Radio />} value="1" label="ยินยอม" />
                      <FormControlLabel control={<Radio />} value="0" label="ไม่ยินยอม" />
                    </RadioGroup>
                  </Box>
                ))}
              </Box>

              <Stack justifyContent="center" direction="row" spacing={3} sx={{ mt: 4 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disableElevation
                  // disabled={loading}
                >
                  ตกลง
                </Button>
                {canClose && (
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="inherit"
                    disableElevation
                    onClick={() => dispatch(closeAgentViewModal())}
                  >
                    ปิด
                  </Button>
                )}
              </Stack>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AgentViewPdpaModal;
