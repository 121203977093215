import _ from "lodash";
import React, { useState, useEffect, useReducer } from "react";
import { matchPath } from "react-router";

import useProductOrder from "./product_order";
import Preloading from "/src/components/loading/Preloading";
import BasicInfoForm from "/src/components/agent/checkout/BasicInfoForm";
import Stepper from "/src/components/stepper/Stepper";
import InsurancePlanSummary from "/src/components/insurancePlanSummary/insurancePlanSummary";

import { useAgentOrdersAPI } from "/src/api/agent/Orders";
import { CheckoutProvider } from "/src/context/Checkout";
import { PageContextProvider, usePageContext } from "/src/context/Page";
import CheckoutReducer, { initCheckoutState } from "/src/reducer/CheckoutReducer";

import "./info.scss";

export default function InfoStep1(props) {
  return (
    <PageContextProvider>
      <InfoStep1Inner {...props} />
    </PageContextProvider>
  );
}

var InfoStep1Inner = (props) => {
  const orderId = matchPath(props.location.pathname, {
    path: "/checkout/:order_id/step-1",
    exact: true,
  }).params.order_id;

  const { onError } = usePageContext();

  const {
    isOrderAvailable,
    insureds,
    quotationInfo,
    quoteInfo,
    deliveryAddress,
    getLabelSteppers,
    getSummaryInfo,
    insuredCategory,
    hasCmiAddon,
    cmiObjs,
    contract,
    includeWhTax,
  } = useProductOrder(orderId, { onError });

  const [checkoutState, checkoutDispatcher] = useReducer(
    CheckoutReducer,
    initCheckoutState(quotationInfo.product_type)
  );

  return (
    <>
      {isOrderAvailable ? (
        <CheckoutProvider value={{ state: checkoutState, dispatch: checkoutDispatcher }}>
          <div className="motor-info-page">
            <div className="row">
              <div className="col-md-12">
                <h1>
                  {" "}
                  <i className="material-icons">lock </i> ยืนยันคำสั่งซื้อ{" "}
                </h1>
                <Stepper steppers={getLabelSteppers(0)} />
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <BasicInfoForm
                  orderId={orderId}
                  insured={insureds[0]}
                  insuredCategory={insuredCategory}
                  contract={contract}
                  includeWhTax={includeWhTax}
                  // TODO: Revise this later
                  productDetail={{
                    type: quotationInfo.product_type,
                    vehicleType: quotationInfo.criteria?.vehicle_type,
                    addOrRemoveCMI: cmiObjs,
                    policyType: quoteInfo[0]?.policy_type,
                  }}
                />
              </div>
              <div className="col-md-4">
                <InsurancePlanSummary
                  addon={hasCmiAddon}
                  quoteInfo={quoteInfo}
                  addOrRemoveCMI={cmiObjs}
                  summary={getSummaryInfo()}
                  includeWhTax={includeWhTax}
                  insuredCategory={insuredCategory}
                />
              </div>
            </div>
          </div>
        </CheckoutProvider>
      ) : (
        <Preloading />
      )}
    </>
  );
};
