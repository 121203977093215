import { AxiosResponse } from "axios";
import { useCallback } from "react";
// TEST
// import { useWhatChanged } from "@simbathesailor/use-what-changed";

import OrdersAPIClient, { OrdersAPIClientProps } from "../Orders";
import { ProtectedAPIClientProps, useProtectedAPI } from "../ProtectedAPIClient";
import APIResponse from "~src/models/APIResponse";
import CreateOrderParams from "~src/models/params/CreateOrderParams";
import { Order } from "~src/types/order";

export interface AgentOrdersAPIClientProps extends OrdersAPIClientProps {}

export default class AgentOrdersAPIClient extends OrdersAPIClient {
  constructor(props: AgentOrdersAPIClientProps) {
    super(props);
  }

  public async placeOrder(quotationID: number, quoteID: number): Promise<APIResponse<Order>> {
    try {
      const url = this.getEndpointURL(`quotaions/place_order`);
      const reqData = {
        quotation_id: quotationID,
        quote_ids: quoteID,
      };

      const resp = await this.withAbort(({ signal }) =>
        this._axios.post<CreateOrderParams, AxiosResponse<APIResponse<Order>>>(url, reqData, {
          signal,
        })
      );
      this.checkAPIResponse(resp);

      return resp.data;
    } catch (err) {
      this.checkErrorResponse(err);
    }
  }

  public getCheckoutURL(orderID: number): string {
    return `/checkout/${orderID}/step-1`;
  }
}

export const defaultAgentOrdersAPIFactory = (props: AgentOrdersAPIClientProps) =>
  new AgentOrdersAPIClient(props);

export interface UseAgentOrdersAPIOptions {
  apiFactory?: (props: AgentOrdersAPIClientProps) => AgentOrdersAPIClient;
  onError?: (err: Error) => void;
}

export interface UseAgentOrdersAPIState {
  ordersAPI?: AgentOrdersAPIClient;
  error?: Error;
}

export function useAgentOrdersAPI(opts: UseAgentOrdersAPIOptions = {}): UseAgentOrdersAPIState {
  const { onError } = opts;

  // TEST
  // useWhatChanged([opts.apiFactory], "opts.apiFactory", "", "useAgentOrdersAPI#1");

  const apiFactory = useCallback(
    (baseProps: ProtectedAPIClientProps) => {
      const apiProps = { ...baseProps };

      if (opts.apiFactory) {
        return opts.apiFactory(apiProps);
      }

      return defaultAgentOrdersAPIFactory(apiProps);
    },
    [opts.apiFactory]
  );

  // TEST
  // useWhatChanged([apiFactory, onError], "apiFactory, onError", "", "useAgentOrdersAPI#2");

  const {
    api: ordersAPI,
    error,
    ...otherState
  } = useProtectedAPI<AgentOrdersAPIClientProps, AgentOrdersAPIClient>({
    apiFactory,
    onError,
  });

  return { ...otherState, ordersAPI, error };
}
