import React from "react";
import Fade from "react-reveal/Fade";
import { Parallax } from "react-parallax";
import IMG from "../../../../assets/homepage/mainimg-top-min.png";
import "./css/dashboard.scss";

const dashboard = () => {
  return (
    <div className="homepage-dashboard-section">
      <Parallax
        strength={1000}
        renderLayer={(percentage) => (
          <>
            <div
              className="o3dball-2"
              style={{
                backgroundPositionY: percentage * 200,
              }}
            />
            <div
              className="o3dball-1"
              style={{
                backgroundPositionY: percentage * 200,
              }}
            />
          </>
        )}
      >
        <Fade bottom>
          <div className="px-20 lg:px-0">
            <img src={IMG} className="inline-block dashboard-img" />
          </div>
        </Fade>
      </Parallax>
    </div>
  );
};

export default dashboard;
