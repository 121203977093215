import React from "react";

import { IconButton } from "@material-ui/core";
import { Edit, Add } from "@mui/icons-material";

import { checkEmpty } from "../../../../helpers/helpers";

interface PartnerTableProps {
  isLoading: boolean;
  partners: any;
  className?: string;
  onAddPartner?: any;
  onEditPartner?: any;
}

const PartnerTable: React.FC<PartnerTableProps> = ({
  isLoading,
  partners,
  className = "",
  onAddPartner,
  onEditPartner,
}) => {
  const getPartnerRow = (partner) => {
    return (
      <tr className="mb-1 transition duration-300 bg-white hover:bg-primary-color hover:bg-opacity-50">
        <td className="px-1 py-2 font-normal">{checkEmpty(partner.code)}</td>
        <td className="px-1 py-2 font-normal">{checkEmpty(partner.name)}</td>
        <td className="px-1 py-2 font-normal">{checkEmpty(partner.email)}</td>
        <td className="px-1 py-2 font-normal">{checkEmpty(partner.partner_type.name)}</td>
        <th className="w-4">
          <IconButton component="span" aria-haspopup="true" onClick={() => onEditPartner(partner)}>
            <Edit />
          </IconButton>
        </th>
      </tr>
    );
  };

  return (
    <table className={`table-auto text-left ${className}`}>
      <thead className="text-1xs text-custom-gray-md">
        <tr>
          <th className="px-1 py-2 font-normal">รหัส Partner</th>
          <th className="px-1 py-2 font-normal">ชื่อ</th>
          <th className="px-1 py-2 font-normal">Email</th>
          <th className="px-1 py-2 font-normal">ประเภท</th>
          <th className="w-4">
            <IconButton component="span" aria-haspopup="true" onClick={onAddPartner}>
              <Add />
            </IconButton>
          </th>
        </tr>
      </thead>
      <tbody className="text-xs divide-y-4 divide-custom-gray-sm">
        {isLoading ? (
          <tr className="mb-1 bg-white">
            <td colSpan={8} className="px-1 py-2 text-center">
              กำลังโหลด...
            </td>
          </tr>
        ) : (
          <>
            {partners.length == 0 ? (
              <tr className="mb-1 bg-white">
                <td colSpan={8} className="px-1 py-2 text-center">
                  ไม่พบข้อมูล
                </td>
              </tr>
            ) : (
              <>
                {partners.map((partner) => (
                  <>{getPartnerRow(partner)}</>
                ))}
              </>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};

export default PartnerTable;
