import Cookies from "js-cookie";

const removeCookies = (): void => {
  if (localStorage.getItem("showOnMobile") !== null) {
    localStorage.removeItem("showOnMobile");
  }

  const cookieKeys: string[] = [
    "access_token",
    "email",
    "id",
    "username",
    "title",
    "firstname",
    "lastname",
    "role",
    "permissions",
  ];

  cookieKeys.forEach((k) => {
    Cookies.remove(k);
  });
};

export const logout = (redirectPath: string = "/"): void => {
  removeCookies();
  window.location.href = redirectPath;
};
