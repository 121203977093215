import React from "react";

import SectionComponent from "/src/components/staff/Section";
import InsuredForm from "./InsuredForm";
import VehicleForm from "./VehicleForm";
import PolicyForm from "./PolicyForm";

import { Button } from "/src/components/form";

import "./motor-form.scss";

const MotorForm = (props) => {
  return (
    <div className="renew-motor-form">
      <h2>สร้างใบแจ้งเตือนต่ออายุ</h2>
      <div className="overflow-scoll">
        <SectionComponent
          title="ข้อมูลผู้เอาประกัน"
          component={
            <InsuredForm register={props.register} errors={props.errors} data={props.data} />
          }
        />
        <SectionComponent
          title="ข้อมูลรถยนต์"
          component={
            <VehicleForm register={props.register} errors={props.errors} data={props.data} />
          }
        />
        <SectionComponent
          title="ข้อมูลกรมธรรม์"
          component={
            <PolicyForm register={props.register} errors={props.errors} data={props.data} />
          }
        />
      </div>
      <div className="renew-motor-form-btn">
        <Button type="reset">ยกเลิก</Button>
        <Button type="submit" color="primary">
          บันทึกใบแจ้งเตือน
        </Button>
      </div>
    </div>
  );
};

MotorForm.propTypes = {};

export default MotorForm;
