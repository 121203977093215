import React from "react";
import QueryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  fetchPlaceOrderAction,
  fetchTravelProductsAction,
  placeOrderState,
  quotationState,
  setTravelInfo,
  travelInfoState,
  travelProductsState,
} from "./redux";
import TravelInfo from "./components/TravelInfo";
import CardLoading from "~src/components/loading/CardLoading";
import ProductList from "./ProductList";
import Preloading from "~src/components/loading/Preloading";
import { useTranslation } from "react-i18next";

const Search = () => {
  const location = useLocation();
  const querires = QueryString.parse(location.search);
  const queryParams = new URLSearchParams(location.search).toString();
  const travelProduct = useAppSelector(travelProductsState);
  const travelInfo = useAppSelector(travelInfoState);
  const quotation = useAppSelector(quotationState);
  const placeOrder = useAppSelector(placeOrderState);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (travelProduct.status === "idle") dispatch(fetchTravelProductsAction(queryParams));
    else if (travelProduct.status === "succeeded") {
      dispatch(
        setTravelInfo({
          ...querires,
        })
      );
    }
  }, [travelProduct, queryParams, dispatch]);

  React.useEffect(() => {
    if (quotation.status === "succeeded") {
      dispatch(
        fetchPlaceOrderAction({
          quotation_id: quotation.data.id,
          quote_ids: [quotation.data.quotes?.[0].id],
          token: travelInfo.token,
        })
      );
    }
  }, [quotation]);

  React.useEffect(() => {
    if (quotation.status === "succeeded" && placeOrder.status === "succeeded") {
      window.location.href = `/public/travels/checkout/${placeOrder.data.order_id}/step-1?token=${travelInfo.token}`;
    }
  }, [quotation, placeOrder]);

  return (
    <>
      {(quotation.status === "loading" || placeOrder.status === "loading") && <Preloading />}
      <TravelInfo />
      <div className="col-md-12">
        <div className="row">
          {travelProduct.status === "loading" && (
            <CardLoading items={8} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" />
          )}
          {travelProduct.status === "succeeded" && <ProductList />}
        </div>
      </div>

      {travelProduct.status === "failed" || travelProduct.data.length === 0 ? (
        <div className="col-12">
          <div>
            <h5 className="my-4 mt-20 text-center">ขออภัยครับ ไม่พบแผนประกันภัยที่ท่านค้นหา</h5>
            <p className="text-center">
              กรุณาติดต่อ Line: {t("lineAccount")} หรือ โทร {t("phoneNumberFormat")} เวลา:{" "}
              {t("timeToContact")} ({t("dayToContact")})
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Search;
