import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "../../helpers/configAxios";

import { IconButton } from "@material-ui/core";
import { Edit, Close } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

import Preloading from "../../components/loading/Preloading";
import { Button } from "~src/components/form";

interface RemarkInfoProps {
  remark: string;
  allowEdit?: boolean;
  orderId: number;
}

interface IFormInputs {
  order: {
    remark: string;
  };
}

const RemarkInfo: React.FC<RemarkInfoProps> = ({
  remark = "",
  allowEdit = false,
  orderId,
  ...props
}) => {
  let history = useHistory();

  const [preLoading, setPreLoading] = useState(false);
  const [editRemark, setEditRemark] = useState(remark);
  const [openEditRemark, setOpenEditRemark] = useState(false);
  const { handleSubmit } = useForm<IFormInputs>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const formSubmitId = "editRemark";

  const onOpenEditRemark = (isEdit: boolean): void => {
    if (openEditRemark) setEditRemark(remark);

    setOpenEditRemark(isEdit);
  };

  const onSubmit = (): void => {
    const formElement: HTMLFormElement = document.querySelector(`form[id="${formSubmitId}"]`);
    const formData: FormData = new FormData(formElement);
    setPreLoading(true);
    postUpdateRemark(orderId, formData)
      .then(() => {
        history.go(0);
      })
      .catch(() => {
        setPreLoading(false);
      });
  };

  const postUpdateRemark = (orderId: number, data: object): any => {
    const url = `/api/orders/${orderId}?checked_remark=true`;
    return axios.patch(url, data);
  };

  return (
    <div className="" {...props}>
      <div className="flex justify-between bg-custom-gray-sm2">
        <div className="p-2 text-lg">เพิ่มเติม</div>
        {allowEdit && (
          <div className="flex items-center">
            {openEditRemark && (
              <Button color="primary" variant="none" type="submit" form={formSubmitId}>
                บันทึก
              </Button>
            )}
            <IconButton
              className="focus:outline-none"
              onClick={() => onOpenEditRemark(!openEditRemark)}
            >
              {openEditRemark ? <Close /> : <Edit />}
            </IconButton>
          </div>
        )}
      </div>
      <div className="mt-3 min-h-25.5">
        {openEditRemark ? (
          <form id={formSubmitId} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <textarea
              className="w-full border-2 rounded-md min-h-24 focus:outline-none"
              name={`order[remark]`}
              value={editRemark}
              onChange={(evt) => setEditRemark(evt.target.value)}
            />
          </form>
        ) : (
          <div>{editRemark}</div>
        )}
      </div>
      {preLoading && <Preloading />}
    </div>
  );
};

export default RemarkInfo;
