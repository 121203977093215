import axios from "/src/helpers/configAxios";
import React, { useState, useEffect, useContext, useMemo } from "react";
import CurrencyFormat from "react-currency-format";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";

import { CoverageModal } from "/src/components/modal";
import { CoverageModalCmi } from "/src/components/modal/coverage/coverageModal";

import yaml from "/src/data";
import CheckoutContext from "/src/context/Checkout";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import formingCoveragesFor from "/src/helpers/formingCoverage";
import useInsurerIcons from "/src/helpers/useInsurerIcons";
import { useAuthenticate } from "~src/helpers/authen";

import "./insurancePlanSummary.scss";

const InsurancePlanSummary2 = (props) => {
  const {
    productType,
    productId,
    insurerCode,
    insurerIcon,
    insurerName,
    title,
    subTitle,
    details,
    sumSellingPrice,
    discount,
    commission,
    creditCardFee,
    paymentTotalAmount,
    paymentTotalAmountIncludedCCFee,
    sellingPrice,
    sellingPriceAddon,
    policyType,
    repairFacility,
    installmentInfo,
    estimateWhTax,
    estimateAddonWhTax,
  } = props.summary;
  const CREDIT_CARD_FEE_RATE = 0.02;

  const { addon } = props;
  const { t } = useTranslation();
  const { showPaidCommission } = useAuthenticate();
  const { quoteInfo, currentTab, activeInstallmentCardIndex, insuredCategory, includeWhTax } =
    props;

  const { addon_selling_price, checked_addon } = props.addOrRemoveCMI;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModelCmi, setIsOpenModelCmi] = useState(false);
  const [fullCoverages, setFullCoverages] = useState([]);
  const [productInfo, setProductInfo] = useState("");
  const [state, setState] = useState({
    shown: true,
    showAdditionalDetails: true,
  });
  const [whTax, setWhTax] = useState(0);

  // const { state: checkoutState, dispatch } = useContext(CheckoutContext);

  const abortFetchCoverageDataRef = React.useRef();

  const fullInsurerName = yaml.insurers[insurerCode];
  let productDescription = "";
  switch (productType) {
    case "motor":
      productDescription = `${yaml.policy_type[policyType]} ${yaml.repair_facility[repairFacility]}`;

      break;
  }

  useEffect(() => {
    setWhTax(calculateEstimatedWhValue(includeWhTax, insuredCategory));
    const abc = new AbortController();

    abortFetchCoverageDataRef.current = abc.abort.bind(abc);
    fetchCoverageData(abc);

    // cleanup
    return () => {
      abortFetchCoverageDataRef.current &&
        abortFetchCoverageDataRef.current("InsurancePlanSummary2 unmounted");
      abortFetchCoverageDataRef.current = undefined;
    };
  }, []);

  function fetchCoverageData(abortController) {
    const { signal } = abortController || {};

    const url = `${process.env.API_URL}/api/products/${productId}`;
    axios
      .get(url, { signal })
      .then((response) => {
        const { content } = response.data;
        unstable_batchedUpdates(() => {
          setProductInfo(content);
          setFullCoverages(formingCoveragesFor("motor", content));
        });
      })
      .catch((error) => {
        handleFetchError(error);
      });
  }

  const handleChange = () => {
    if (state.shown) {
      setState({
        ...state,
        shown: false,
        showAdditionalDetails: false,
      });
    } else {
      setState({
        ...state,
        shown: true,
        showAdditionalDetails: true,
      });
    }
  };

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleOpenModalCMI = () => {
    setIsOpenModelCmi(true);
  };

  const getEstimatedCreditCardFee = () => {
    return paymentTotalAmount * CREDIT_CARD_FEE_RATE;
  };

  const calculateEstimatedWhValue = (includeWhTax, insuredCategory) => {
    return includeWhTax && insuredCategory === "legal_entity"
      ? checked_addon
        ? parseFloat(estimateWhTax) + parseFloat(estimateAddonWhTax)
        : parseFloat(estimateWhTax)
      : 0;
  };

  const getDiscount = useMemo(() => {
    switch (currentTab) {
      case "installment":
        return formatNumberWithCurrency(
          installmentInfo[activeInstallmentCardIndex]?.discount_amount
        );
      default:
        return formatNumberWithCurrency(discount);
    }
  }, [currentTab, activeInstallmentCardIndex]);

  const paymentToBePaid = useMemo(() => {
    switch (currentTab) {
      case "cc":
        return formatNumberWithCurrency(paymentTotalAmountIncludedCCFee);
      case "installment":
        return formatNumberWithCurrency(
          installmentInfo[activeInstallmentCardIndex]?.first_month?.payment_amount
        );
      default:
        return formatNumberWithCurrency(paymentTotalAmount);
    }
  }, [currentTab, activeInstallmentCardIndex]);

  return (
    <>
      <div className="motor-discount-conponent">
        <div className="plan-header">สรุปแผนประกันที่คุณเลือก</div>
        <div className="motor-component-card">
          <div className="mb-4 motor-component">
            <div className="items-center mb-2 row">
              <div className="col-md-3">
                <img className="image" src={insurerIcon} />
              </div>
              <div className="col-md-8">
                <div className="pb-2">{insurerName}</div>
                <div className="text-xs font-thin font-secondary">
                  {quoteInfo[0].policy_type !== "cmi" ? productDescription : "พ.ร.บ."}
                </div>
              </div>
            </div>
            {quoteInfo[0].policy_type !== "cmi" && (
              <button className="w-full text-xs" onClick={handleOpenModal}>
                <div className="flex justify-between pl-12 pr-12 text-detail">
                  <i className="material-icons">notes</i>
                  อ่านรายละเอียดความคุ้มครอง
                </div>
              </button>
            )}
            {quoteInfo[0].policy_type === "cmi" && (
              <button className="w-full text-xs" onClick={handleOpenModalCMI}>
                <div className="flex justify-between pl-12 pr-12 text-detail">
                  <i className="material-icons">notes</i>
                  อ่านรายละเอียดความคุ้มครอง
                </div>
              </button>
            )}
          </div>
          <div className="flex justify-between pb-2 font-primary">
            <div className="w-full px-2 py-1">{`ยี่ห้อ/รุ่น: `}</div>
            <div className="w-full py-1 xl:pl-20">{details}</div>
          </div>
          <hr />
          {checked_addon ? (
            <div className="flex justify-between px-2 py-1 font-thin">
              <div>เบี้ยประกันภัยรวม</div>
              <div>{formatNumberWithCurrency(sellingPrice)}</div>
            </div>
          ) : null}
          {checked_addon ? (
            <div className="flex justify-between px-2 py-1 font-thin">
              <div>เบี้ยพ.ร.บ.</div>
              <div>{formatNumberWithCurrency(sellingPriceAddon)}</div>
            </div>
          ) : null}
          <div className="flex justify-between px-2 py-1 font-thin">
            <div>เบี้ยประกันภัยรวมทั้งสิ้น</div>
            <div>{formatNumberWithCurrency(sumSellingPrice)}</div>
          </div>
          {showPaidCommission && (
            <div className="flex justify-between px-2 py-1">
              <div className="font-light">ส่วนลดลูกค้า</div>
              <div>{getDiscount}</div>
            </div>
          )}
          {process.env.APP_NAME !== "mti" && insuredCategory === "legal_entity" && t("whTax") && (
            <div className="flex justify-between px-2 py-1">
              <div className="font-light">ภาษีหัก ณ ที่จ่าย 1%</div>
              <div>
                {whTax > 0 && "-"} {`${formatNumberWithCurrency(whTax)}`}
              </div>
            </div>
          )}
          <div className="flex justify-between px-2 py-1 font-light">
            {currentTab === "cc" && (
              <>
                <div>ค่าธรรมเนียมบัตรเครดิต 2%</div>
                <div>
                  {creditCardFee > 0 ? (
                    <>{formatNumberWithCurrency(creditCardFee)}</>
                  ) : (
                    <>
                      <span className="mr-2 line-through">
                        {formatNumberWithCurrency(getEstimatedCreditCardFee(), "")}
                      </span>
                      0.00 ฿
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          <hr />
          <div className="flex justify-between pb-2">
            <div className="text-base font-bold">เบี้ยประกันที่ต้องชำระ</div>
            <div className="text-xl font-bold text-primary">{paymentToBePaid}</div>
          </div>
          <div className="flex justify-between pb-2 -mt-4 text-sm font-thin">
            <div>รวมภาษีและอากรแสตมป์</div>
            {currentTab != "installment" && <div>ต่อปี</div>}
          </div>
        </div>
      </div>
      {isOpenModal && (
        <CoverageModal
          handleClose={handleCloseModal}
          productInfo={productInfo}
          coverages={fullCoverages}
          details={details}
        />
      )}

      {isOpenModelCmi && (
        <CoverageModalCmi
          handleClose={() => setIsOpenModelCmi(false)}
          productInfo={productInfo}
          coverages={fullCoverages}
          details={details}
        />
      )}
    </>
  );
};

function validateFetchReponse(response) {
  if (response.ok) {
    return response.json();
  }
  return Promise.reject(`error: ${response.status}`);
}
function handleFetchError(error) {
  // TODO: handling fetch error here
  console.log("error", error);
}

export default InsurancePlanSummary2;
