import React from "react";
import { TextField, FormGroup } from "/src/components/form";

const Payslip = ({
  id,
  name,
  errors, 
  register
}) => {
  return (
    <>
      <h4 className="mt-6">แนบเอกสารการชำระเงิน </h4>
      <p className="details">
        กรุณาแนบเอกสารหลักฐานการชำระเงิน
        เพื่อดำเนินการตรวจสอบในขั้นตอนต่อไปค่ะ
      </p>
      <div className="mt-4">
        <FormGroup htmlForLabel="payslip">
          <TextField
            type="file"
            id={id}
            name={name}
            accept="image/*"
            inputRef={register}
            error={errors}
          />
        </FormGroup>
      </div>
    </>
  )
}

export default Payslip