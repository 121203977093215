import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
// import useDeepCompareEffect from "use-deep-compare-effect";

import { useAppContext } from "./App";
import { useAuthContext } from "./Auth";
import OrdersAPIClient, {
  FindOrdersResponse,
  OrdersAPIClientProps,
  useOrdersAPI,
} from "~src/api/Orders";
import useLoading from "~src/hooks/useLoading";
import { FindOrdersParams } from "~src/models/params/FindOrdersParams";
import { Order } from "~src/types/order";
import useDeepCompareEffect, { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

export interface OrdersContextProps<TAPI extends OrdersAPIClient = OrdersAPIClient> {
  isLoading: boolean;
  orders: Order[];
  error?: Error;
  reload: () => void;
  response: FindOrdersResponse;
  ordersAPI: OrdersAPIClient;
}

const OrdersContext = createContext<OrdersContextProps | undefined>(undefined);
export default OrdersContext;

export interface OrdersContextProviderProps<TAPI extends OrdersAPIClient = OrdersAPIClient> {
  searchParams: FindOrdersParams;
  loadOnInit?: boolean;
  apiFactory?: (props: OrdersAPIClientProps) => TAPI;
  // searchInterceptor?: (params: FindOrdersParams) => boolean;
  onError?: (err: Error) => void;
}

export function OrdersContextProvider<TAPI extends OrdersAPIClient = OrdersAPIClient>({
  searchParams,
  loadOnInit = true,
  apiFactory,
  // searchInterceptor,
  onError,
  children,
}: React.PropsWithChildren<OrdersContextProviderProps<TAPI>>) {
  const { appConfig } = useAppContext();
  const { session } = useAuthContext();

  const [orders, setOrders] = useState<Order[]>([]);
  // TODO: Refactor this later
  const { ordersAPI } = useOrdersAPI({ apiFactory, onError });

  const {
    data: respData,
    error,
    isLoading,
    reload,
  } = useLoading(() => ordersAPI.findOrders(searchParams), {
    isReady: !!ordersAPI && !session.isInitializing,
    loadOnInit,
  });

  useDeepCompareEffect(() => {
    reload();
  }, [searchParams]);

  useDeepCompareEffectNoCheck(() => {
    error && onError && onError(error);
    if (isLoading || !respData) {
      return;
    }

    //TODO: Revise the types later
    setOrders(respData.content as Order[]);
  }, [isLoading, respData, error]);

  return (
    <OrdersContext.Provider
      value={{ isLoading, orders, error, reload, response: respData, ordersAPI }}
    >
      {children}
    </OrdersContext.Provider>
  );
}

export function useOrdersContext<
  TAPI extends OrdersAPIClient = OrdersAPIClient
>(): OrdersContextProps<TAPI> {
  const context = useContext(OrdersContext);
  if (!context) {
    throw new Error("OrdersContext provider not found");
  }

  return context as OrdersContextProps<TAPI>;
}
