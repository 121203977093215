import React, { useRef, useState, useContext, useEffect } from "react";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";
import { Link, useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import QueryString from "query-string";
import yaml from "/src/data";
import "./search-card.scss";
import Commission from "../../card/Commission";

const SearchCard = ({ packageObj, isShowCommission, compareIds, querires }) => {
  const coverages = packageObj.coverages;
  const [insurerImg, setInurerImg] = useState("");
  const [active, setActive] = useState(false);
  const [queriesParams, setQueryParams] = useState(querires);
  //   const [state, dispatch] = useContext(compareContext);
  let compareRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (typeof compareIds === "string") {
      compareIds = [compareIds];
    }
    const activeCompare = _.find(compareIds, function (o) {
      return o == packageObj.id;
    });

    setActive(!!activeCompare);
  }, []);

  function handleCompare(id) {
    const params = queriesParams;
    compareIds.push(id.toString());
    params.compares = compareIds;
    const querires = QueryString.stringify(params);
    window.location.href = "/compares?" + querires;
  }

  return (
    <div className={active ? "search-card zoom-in" : "search-card"}>
      <div className="card-header">
        <div className="img">
          <img src={packageObj.insurer_img} />
        </div>
        <div className="insurer">
          <div className="insurer-name">
            {yaml.insurers[packageObj.insurer_code]}
          </div>
          <div className="insurer-type">{`${
            yaml.policy_type[packageObj.insurer_class]
          } ${yaml.repair_facility[packageObj.repair_facility]}`}</div>
        </div>
      </div>
      <div className="card-price">
        <div className="price">
          <span>{formatNumberWithCurrency(packageObj.standard_price)}</span>
          ต่อปี
        </div>
        <div className="actions">
          <button
            ref={compareRef}
            className={active ? "active" : ""}
            onClick={() => (active ? false : handleCompare(packageObj.id))}
          >
            <i className="material-icons">equalizer</i>
            <span>เปรียบเทียบ</span>
          </button>
        </div>
        {/* {isShowCommission ? (
          <div className="comission">
            <span>{formatNumberWithCurrency(packageObj.commission)}</span>
          </div>
        ) : (
          ""
        )} */}
        {isShowCommission && <Commission value={packageObj.commission} />}
      </div>
      <div className="card-coverage">
        <ul>
          <li className="key">ทุนประกัน</li>
          <li className="value">
            {formatNumberWithCurrency(coverages.sum_insured)}
          </li>
        </ul>
        <ul>
          <li className="key">ค่าเสียหายส่วนแรก</li>
          <li className="value">
            {formatNumberWithCurrency(coverages.excess_damage_coverage)}
          </li>
        </ul>
        <ul>
          <li className="key">ประเภทการซ่อม</li>
          <li className="value">
            {packageObj.repair_facility === "garage" ? "อู่" : "ศูนย์"}
          </li>
        </ul>
        <ul>
          <li className="key">น้ำท่วม</li>
          <li className="value">
            {formatNumberWithCurrency(coverages.natural_disaster_coverage)}
          </li>
        </ul>
        <ul>
          <li className="key">ทรัพย์สินบุคคลภายนอก</li>
          <li className="value">
            {formatNumberWithCurrency(
              coverages.third_party_property_damage_coverage_per_incident
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SearchCard;
