import Decimal from "decimal.js";
import React, { useMemo, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";

import ButtonRadius from "../form/button/ButtonRadius";
import ShowPaySlipButton from "../payments/ShowPaySlipButton";

import statusYml from "~/src/status.yml";
import { useAppContext } from "~src/context/App";
import { renderYaml } from "~src/helpers";
import { reformatDateEN } from "~src/helpers/date";
import { formatNumberWithCurrency } from "~src/helpers/formatNumber";
import { Payment } from "~src/types/payment";
import { useAuthenticate } from "~src/helpers/authen.js";

export interface InstallmentTableProps {
  totalAmount: Decimal;
  commissionAmount: Decimal;
  discountAmount: Decimal;
  totalTerms: number;
  paidTerms: number;
  payments: Payment[];
  isStatusHidden?: boolean;
  onShowGeneratePaymentLinkModal?: (payment: Payment) => void;
  onShowPaySlipModal?: (payment: Payment) => void;
  onShowConfirmPayslipModal?: (payment: Payment) => void;
  className?: string;
}

export default function InstallmentTable({
  totalAmount,
  commissionAmount,
  discountAmount,
  totalTerms,
  paidTerms,
  payments,
  isStatusHidden,
  onShowGeneratePaymentLinkModal,
  onShowPaySlipModal,
  onShowConfirmPayslipModal,
  className,
}: InstallmentTableProps) {
  const { appConfig } = useAppContext();
  const [payableSlipIndex, setPayableSlipIndex] = useState<number>(-1);

  useEffect(() => {
    setPayableSlipIndex(
      payments.findIndex((payment) => payment.status == "pending" && !payment.payslip_url)
    );
  }, [
    JSON.stringify(payments)
  ])

  const hasDiscount = useMemo(
    () => discountAmount && discountAmount.greaterThan(0),
    [discountAmount]
  );
  const nDataCols = useMemo(() => (isStatusHidden ? 2 : 3), [isStatusHidden]);

  const { accessType } = useAuthenticate();

  return (
    <table
      className={`table-auto text-center border-white bg-white-color animated fadeIn ${className}`}
    >
      <tbody>
        <tr className="bg-secondary-color text-white">
          <td rowSpan={payments.length + 1} className="border-2 border-white font-bold break-words">
            <p>เบี้ยประกันภัย</p>
            <p>{formatNumberWithCurrency(totalAmount, "")}</p>
            <p>
              ({paidTerms}/{totalTerms})
            </p>
          </td>
          <td className="border-2 border-white">
            <p className="bg-secondary-color text-white border-white font-bold break-words">
              วันครบกำหนดชำระ
            </p>
          </td>
          <td className="border-2 border-white">
            <p className="bg-secondary-color text-white border-white font-bold break-words">
              จำนวน
            </p>
          </td>
          {!isStatusHidden && (
            <td className="border-2 border-white">
              <p className="bg-secondary-color text-white border-white font-bold break-words">
                สถานะ
              </p>
            </td>
          )}
        </tr>
        {payments.map((payment, idx) => (
          <tr key={idx}>
            <td className="border-white font-bold break-words">
              {reformatDateEN(payment.installment_due_at)}
            </td>
            <td className="border-white font-bold break-words">
              {formatNumberWithCurrency(payment.installment_amount, "")}
            </td>
            {!isStatusHidden && (
              <td className="border-white font-bold break-words">
                <p>
                  {payment.status && renderYaml(statusYml.paymentStatus[payment.status])}{" "}
                  {payment.status === "pending" && (
                    <>
                      {/* TODO: Permanently enable this after deploying Installment+Payment Link feature */}
                      {(appConfig.paymentLinksEnabled && idx == payableSlipIndex) && (
                        <>
                          <ButtonRadius
                            classNameOption="payment-method mx-2 my-1"
                            color="primary"
                            size="xxs"
                            disabled={!onShowGeneratePaymentLinkModal}
                            onClick={() => onShowGeneratePaymentLinkModal(payment)}
                          >
                            <i className={"material-icons"}>add_link</i>
                          </ButtonRadius>
                        </>
                      )}
                    </>
                  )}
                  {payment.payslip_url ? (
                    <>
                      <ShowPaySlipButton
                        title={payment.pay_method === "bank_transfer" ? "ใบสลิปการโอน" : "ใบสลิป"}
                        disabled={!onShowPaySlipModal}
                        onClick={() => onShowPaySlipModal && onShowPaySlipModal(payment)}
                      />
                      {(payment.status === "pending" 
                        && onShowConfirmPayslipModal
                        && accessType === "STAFF") &&
                        <button
                          className="bg-primary text-white rounded-full mt-1 mb-2 px-2 py-1 text-xs"
                          disabled={!onShowConfirmPayslipModal}
                          onClick={() => onShowConfirmPayslipModal && onShowConfirmPayslipModal(payment)}
                        >
                          ยืนยันชำระ
                        </button>
                      }
                    </>
                  ) : (
                    <>
                      {idx == payableSlipIndex && (
                        <ButtonRadius
                          classNameOption="payment-method mx-2 my-1"
                          color="primary"
                          size="xxs"
                          disabled={!onShowPaySlipModal}
                          onClick={() => onShowPaySlipModal && onShowPaySlipModal(payment)}
                        >
                          <i className={`material-icons slip-icon`}>perm_media</i> แนบสลิป
                        </ButtonRadius>
                      )}
                    </>
                  )}
                </p>
                <p>{payment.status === "paid" && reformatDateEN(payment.paid_at)}</p>
              </td>
            )}
          </tr>
        ))}
        {commissionAmount && (
          <tr>
            <td className="bg-secondary-color text-white border-2 border-white font-bold break-words">
              ค่าตอบแทนหลังปรับลด
            </td>
            <td colSpan={nDataCols} className="bg-gray-200 border-white font-bold break-words">
              <p> {formatNumberWithCurrency(commissionAmount, "")} </p>
            </td>
          </tr>
        )}
        {hasDiscount && (
          <tr>
            <td className="bg-secondary-color text-white border-2 border-white font-bold break-words">
              หักส่วนลด
            </td>
            <td colSpan={nDataCols} className="border-white font-bold break-words text-red-500">
              <p>
                {" "}
                {hasDiscount && <>-</>}
                {formatNumberWithCurrency(discountAmount, "")}{" "}
              </p>
            </td>
          </tr>
        )}
        {commissionAmount && hasDiscount && (
          <tr>
            <td className="bg-secondary-color text-white border-2 border-white font-bold break-words">
              ค่าตอบแทนสุทธิ
            </td>
            <td colSpan={nDataCols} className="bg-gray-200 border-white font-bold break-words">
              <p> {formatNumberWithCurrency(commissionAmount.sub(discountAmount), "")} </p>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
