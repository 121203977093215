import { AxiosResponse } from "axios";
import useAxios from "../../../../hooks/useAxios";
import axios from "../../../../helpers/configAxios";

interface Response {
  status: "success";
}

interface Brand extends Response {
  brands: string[];
}

export interface Provinces extends Response {
  provinces: string[];
}

interface Model extends Response {
  models: string[];
}

interface Years extends Response {
  years: number[];
}

interface SubModels extends Response {
  sub_models: SubModel[];
}

interface Vehicles extends Response {
  vehicles: Vehicle[];
}

export interface SubModel {
  sub_model: string;
  code_name: string;
}

interface Vehicle {
  brand: string;
  cc: string;
  code_name: string;
  combined_weight: number;
  doors: string;
  enable_flag: boolean;
  model: string;
  model_with_cc: string;
  no_sub_model_max_sum_insured: number;
  no_sub_model_min_sum_insured: number;
  no_sub_model_suggest_sum_insured: number;
  seating_capacity: number;
  smart_max_sum_insured: number;
  smart_min_sum_insured: number;
  smart_step_sum_insured: number;
  smart_suggest_sum_insured: number;
  sub_model: string;
  sum_insured: number;
  vehicle_group: string;
  vehicle_type: string;
  year: number;
}

export const useCarBrands = (): string[] => {
  const { status, data } = useAxios<Brand>("/api/vehicles");
  if (status === "loaded") return data.brands;
};

export const getCarBrands = (): Promise<AxiosResponse<Brand>> => {
  return axios.get(`/api/vehicles/`);
};

export const getCarModels = async (car_brand: string): Promise<AxiosResponse<Model>> => {
  return axios.get(`/api/vehicles/${encodeURIComponent(car_brand)}`);
};

export const getYears = async (
  car_brand: string,
  car_model: string
): Promise<AxiosResponse<Years>> => {
  return axios.get(
    `/api/vehicles/${encodeURIComponent(car_brand)}/${encodeURIComponent(car_model)}`
  );
};

export const getCodeNames = async (
  car_brand: string,
  car_model: string,
  year: string
): Promise<AxiosResponse<SubModels>> => {
  return axios.get(
    `/api/vehicles/${encodeURIComponent(car_brand)}/${encodeURIComponent(
      car_model
    )}/${encodeURIComponent(year)}`
  );
};

export const useProvinces = (): string[] => {
  const { status, data } = useAxios<Provinces>("/api/locations");
  if (status === "loaded") return data.provinces;
};

export const getCarInfo = async (
  car_brand?: string,
  car_model?: string,
  year?: string,
  car_submodel?: string
): Promise<AxiosResponse<Vehicles>> => {
  const params = {
    car_brand,
    car_model,
    year,
    car_submodel,
  };

  return axios.post(`/api/vehicles/info`, JSON.stringify(params));
};
