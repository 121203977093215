import React from "react";
import { Link } from "react-router-dom";
import "./button.scss";

const LinkButton = props => {
  const { children, fullwidth, color, ...rest } = props;
  const fullWidth = !!fullwidth ? "btn-block" : "";

  let setColor = "";
  switch (color) {
    case "primary":
      setColor = "btn-primary";
      break;
    case "secondary":
      setColor = "btn-secondary";
      break;
    case "grey":
      setColor = "btn-grey";
      break;
    default:
      setColor = "";
      break;
  }
  return (
    <Link {...rest} className={`link-btn ${fullWidth} ${setColor}`}>
      <span>{children}</span>
    </Link>
  );
};

export default LinkButton;
