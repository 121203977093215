import React from "react";
import { parseDate, dateFormat } from "/src/helpers/dateTime";
import { Group, Header, List } from "../SummaryList";

const Car = ({ car, quoteInfo }) => {
  const {
    model_desc,
    plate_number,
    chassis_number,
    plate_province,
    cover_start_date,
    cover_end_date,
  } = car;
  return (
    <Group>
      <Header
        icon={<span className="icon icon-motor" />}
        label="ข้อมูลรถยนต์"
        color="secondary"
      />
      <List label="รุ่นรถยนต์" value={model_desc} />
      <List label="ทะเบียนรถยนต์" value={plate_number} />
      <List label="จังหวัดที่จดทะเบียน" value={plate_province} />
      <List label="หมายเลขตัวถังรถยนต์ (คัสซี)" value={chassis_number} />

      {quoteInfo[0].policy_type !== "cmi" ? (
        <List
          label={`วันที่คุ้มครอง (1ปี)`}
          value={
            car.cover_start_date !== undefined &&
            `${dateFormat(parseDate(car.cover_start_date), "dd/MM/yyyy")} -
        ${dateFormat(parseDate(car.cover_end_date), "dd/MM/yyyy")}`
          }
        />
      ) : null}
    </Group>
  );
};

export default Car;
