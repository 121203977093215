const initCompareState = {
  compareIds: [],
  compareLists: [],
};

const compareReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case "ADD":
      return {
        compareIds: action.payload.compareIds,
        compareLists: action.payload.compareLists
      };
    case "REMOVE":
        //console.log(action.payload.compareLists[0].id)
      return {
        compareIds: action.payload.compareIds,
        compareLists: action.payload.compareLists
      };
    case "RESET":
      return {
        ...initCompareState
      };
    default:
      return state;
  }
};

export { initCompareState, compareReducer };
