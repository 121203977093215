import React from "react";
import { formatNumberWithCurrency2 } from "~src/helpers";
import { useAppSelector } from "~src/store";
import { TravelCoveragesOptional } from "~src/types/travel";
import { coverageMappingOptional } from "../functions";
import { coverageModalState } from "../redux";

type Props = {
  prefixNum?: string | null;
  keyMap: keyof TravelCoveragesOptional;
};

const CoverageOptionalList: React.FC<Props> = ({ prefixNum, keyMap }) => {
  const state = useAppSelector(coverageModalState);
  const { coverages } = state.data;

  function displayValue(value: number): number | "-" {
    if (value === 0 || !value) {
      return "-";
    }

    return formatNumberWithCurrency2(value);
  }

  return (
    <div className="row pt-2 font-light">
      <div className="col-8">
        {prefixNum} {coverageMappingOptional[keyMap]}
      </div>
      <div className="col-4 text-right">{displayValue(Number(coverages.optional[keyMap]))}</div>
    </div>
  );
};

export default CoverageOptionalList;
