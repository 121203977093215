import React from "react";
import { useFormContext } from "react-hook-form";

import { useAppSelector } from "~src/store";
import { agentInfoSelector } from "./registerSlice";
import AddressForm from "./AddressForm";

const AddressInfo = () => {
  const agentInfo = useAppSelector(agentInfoSelector);
  const methods = useFormContext();

  React.useEffect(() => {
    methods.setValue("address", agentInfo.address);
    methods.setValue("road", agentInfo.road);
    methods.setValue("sub_district", agentInfo.sub_district);
    methods.setValue("district", agentInfo.district);
    methods.setValue("province", agentInfo.province);
    methods.setValue("postcode", agentInfo.postcode);
  }, [agentInfo]);

  return (
    <div>
      <div className="flex items-center justify-between w-full col-span-1 col-start-1 text-xl bg-custom-gray-sm mb-3">
        <span className="p-2">ที่อยู่ติดต่อได้</span>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-2 gap-x-3">
        <div className="col-span-2 grid grid-cols-4 gap-4">
          <AddressForm />
        </div>
      </div>
    </div>
  );
};

export default AddressInfo;
