import yaml from "/src/data.yaml";
import { toJsDate, isYouth, isBirthdateEqualNow } from "/src/helpers/dateTime";
import { isBefore, isToday, differenceInYears } from "date-fns";
import _some from "lodash/some";
import locationJson from "/src/assets/database.json";

export const messages = {
  required: {
    required: yaml.error_msg.required,
  },
  requiredSelect: {
    required: yaml.error_msg.required,
  },
  englishCharacter: {
    pattern: {
      value: /^[A-Za-z][A-Za-z]*$/i,
      message: yaml.error_msg.englishCharacter,
    },
  },
  englishThaiCharacter: {
    pattern: {
      value: /^[ก-๙A-Za-z._ ]*$/i,
      message: yaml.error_msg.englishThaiCharacter,
    },
  },
  email: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: yaml.error_msg.email,
    },
  },
  mobileNumber: {
    // ^[0-9]{10}$
    pattern: {
      value: /[0]{1}[\d]{8,9}$/i,
      message: yaml.error_msg.phone,
    },
  },
  password: {
    pattern: {
      value: /^.{6,128}$/i,
      message: yaml.error_msg.passwordLength,
    },
  },
  validateIdCard: {
    validate: (value) => {
      if (!validateID(value)) return yaml.error_msg.idCard;
    },
  },
  validateCompanyTax: {
    validate: (value) => {
      if (!validateID(value)) return yaml.error_msg.idCompanyTaxCard;
    },
  },
  validateBirthdayEqualNow: {
    validate: (value) => {
      if (isBirthdateEqualNow(value)) return yaml.error_msg.bithdayNow;
    },
  },
  validateBirthday: {
    validate: (value) => {
      if (isYouth(value)) return yaml.error_msg.youth;
    },
  },
  validateCoverageDate: {
    validate: (value) => {
      const today = new Date();
      const coverDate = toJsDate(value);
      if (!isToday(coverDate) && isBefore(coverDate, today)) {
        return yaml.error_msg.expiryDate;
      }
    },
  },
  validateImagesFileType: {
    validate: (files) => {
      if (_some(files, (file) => !file.name.match(/^.*\.(jpg|JPG|png|PNG|pdf|PDF|JPEG)$/i))) {
        return yaml.error_msg.fileTypes;
      }
    },
  },
  uploadFileRequired: {
    required: yaml.error_msg.uploadFileRequired,
  },
  uploadDocRequired: {
    required: yaml.error_msg.uploadDocRequired,
  },
  allowFileType: {
    pattern: {
      value: /^.*\.(jpg|JPG|png|PNG|pdf|PDF|JPEG)$/i,
      message: yaml.error_msg.fileTypes,
    },
  },
  allowImageFileType: {
    pattern: {
      value: /^.*\.(jpg|JPG|png|PNG|JPEG)$/i,
      message: yaml.error_msg.imageFileTypes,
    },
  },
  allowExcelFileType: {
    pattern: {
      value: /^.*\.(xls|XLS|xlsx|XLSX)$/i,
      message: yaml.error_msg.excelFileTypes,
    },
  },
  floatingValid: {
    pattern: {
      value: /^\d*\.?\d*$/,
      message: yaml.error_msg.number,
    },
  },
  maximumNumber: (max) => {
    return {
      max: {
        value: max,
        message: yaml.error_msg.maximumNumber,
      },
    };
  },
  validateSubDistrict: {
    validate: (value) => {
      if (filterAddress(value, "sub_district")) return yaml.error_msg.required;
    },
  },
  validateDistrict: {
    validate: (value) => {
      if (filterAddress(value, "district")) return yaml.error_msg.required;
    },
  },
  validateProvince: {
    validate: (value) => {
      if (filterAddress(value, "province")) return yaml.error_msg.required;
    },
  },
};

function validateID(id) {
  id = id.split("").reverse().join("");
  if (id.length !== 13) {
    return false;
  }
  for (var i = 1, total = 0; i < 13; i++) {
    total += id.charAt(i) * (i + 1);
  }
  if (id.charAt(0) == (11 - (total % 11)) % 10) return true;
  return false;
}

function filterAddress(code, name) {
  return locationJson.filter((lc) => lc?.[name] === code).length === 0;
}
